import { PageRangeInfo } from '@insights/models';
import { AlertService, ContentService, NavigationService } from '@insights/services';
import { SectionUtils, caseInsensitiveAccentInsensitiveCompare } from '@insights/utils';
import { ContentDefinitionUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { AccountModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { booleanToYesNo } from '@shared/services/Utils';
import { CalendarStore, ContentStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { differenceInCalendarDays, startOfDay, subDays } from 'date-fns';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { chain, flatMap, groupBy, uniqBy } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  AppPaginatedViewModel,
  AppPaginationViewModel,
  PaginatedViewModel,
  PaginationViewModel
} from './PaginatedViewModel';

export interface SectionsCourseOccurrencesTaskInfo {
  student: AccountModel;
  task: ContentDefinitionModel;
}

export interface SectionsCourseOccurrencesStatusInfo {
  readonly minDay: Day;
  readonly maxDay: Day;
  readonly sections: SectionsCourseOccurrencesStatusSectionInfo[];
  readonly schoolDays: SchoolDay[];
}

export interface SectionsCourseOccurrencesStatusSectionInfo {
  readonly section: SectionModel;
  readonly numberOfStudents: number;
}

export interface SectionsCourseOccurrencesStatusPageInfo {
  readonly schoolDays: SchoolDay[];
  readonly dayInfos: SectionsCourseOccurrencesStatusDayInfo[];
}

export interface SectionsCourseOccurrencesStatusDayInfo {
  readonly schoolDay: SchoolDay;
  readonly section: SectionModel;
  readonly hasCourseOccurrence: boolean;
  readonly publishedTasksCount: number;
  readonly studentsWithOwnTasksCount: number;
}

export interface SectionsCourseOccurrencesStatusViewModel extends PaginatedViewModel {
  readonly configId: string;
  readonly sectionIds: string[];
  readonly data: IPromiseBasedObservable<SectionsCourseOccurrencesStatusInfo>;
  readonly currentPageInfo: IPromiseBasedObservable<SectionsCourseOccurrencesStatusPageInfo>;
  readonly isExporting: boolean;

  showDetail(sectionId: string, schoolDay: SchoolDay): Promise<void>;
  exportToCsv(): Promise<void>;
}

export class AppSectionsCourseOccurrencesStatusViewModel
  extends AppPaginatedViewModel
  implements SectionsCourseOccurrencesStatusViewModel
{
  @observable private _isExporting = false;

  constructor(
    public readonly configId: string,
    public readonly sectionIds: string[],
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _calendarStore: CalendarStore,
    private readonly _contentStore: ContentStore,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _alertService: AlertService,
    private readonly _contentService: ContentService,
    paginationViewModel?: PaginationViewModel
  ) {
    super(paginationViewModel);
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<SectionsCourseOccurrencesStatusInfo> {
    return fromPromise(this.loadData());
  }

  @computed
  get currentPageInfo(): IPromiseBasedObservable<SectionsCourseOccurrencesStatusPageInfo> {
    return fromPromise(this.loadCurrentPage(this.pagination?.currentPage));
  }

  @computed
  get isExporting(): boolean {
    return this._isExporting;
  }

  async showDetail(sectionId: string, schoolDay: SchoolDay): Promise<void> {
    await this._navigationService.navigateToSectionCourseOccurrenceDetail(this.configId, sectionId, schoolDay);
  }

  @action
  async exportToCsv() {
    this._isExporting = true;

    try {
      const [config, students, studentsById, sections] = await Promise.all([
        this._schoolYearConfigurationStore.getConfigSummary(this.configId),
        this._schoolYearConfigurationStore.getStudentsForSectionIds(this.configId, this.sectionIds, false),
        this._schoolYearConfigurationStore.getStudentsById(this.configId, false),
        this._schoolYearConfigurationStore.getSectionsByIds(this.configId, this.sectionIds)
      ]);

      const tasks = await this._contentStore.getContents({
        configId: this.configId,
        sectionIds: this.sectionIds,
        accountIds: students.map((s) => s.id),
        kindsToInclude: ['task'],
        includeCompleted: true,
        fromDay: config.startDay,
        toDay: config.endDay
      });

      const tasksInfo: SectionsCourseOccurrencesTaskInfo[] = tasks.map((task) => ({
        task,
        student: studentsById[task.ownerId]
      }));

      const csvConfig = mkConfig({
        filename: 'sections-tasks-' + sections.map((section) => section.importId).join(','),
        useKeysAsHeaders: true
      });

      const taskRows = chain(tasksInfo)
        .map((taskInfo) => this.getTaskRow(taskInfo))
        .orderBy([(taskRow) => taskRow.sortableDueDay, 'TaskTitle', 'StudentName'])
        .value();

      download(csvConfig)(generateCsv(csvConfig)(taskRows.map((taskRow) => this.clearSortableFields(taskRow))));
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.metrics;

      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isExporting = false));
    }
  }

  /**
   * Remove from each task row values that were only for ordering.
   * @param taskRow The task row to clean up
   * @private
   */
  private clearSortableFields(taskRow: Record<string, string>) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    delete taskRow['sortableDueDay'];
    return taskRow;
  }

  private getTaskRow(taskInfo: SectionsCourseOccurrencesTaskInfo) {
    const { student, task } = taskInfo;
    const strings = this._localizationService.localizedStrings.models.contents;
    const csvStrings = this._localizationService.localizedStrings.insights.viewModels.csvExports;

    const dueDayString = task.dueDay.asDateString;

    return {
      [csvStrings.studentName]: `${student.lastName}, ${student.firstName}`,
      [csvStrings.studentId]: student.id,
      [csvStrings.gradeLevel]: student.gradeLevel,
      [csvStrings.title]: ContentDefinitionUtils.getDisplayTitleForContent(
        task,
        this._localizationService.localizedStrings
      ),
      [csvStrings.icon]: strings.defaultTitle(task.icon),
      [csvStrings.dueDay]: dueDayString,
      [csvStrings.seen]: booleanToYesNo(!task.isUnread, true),
      [csvStrings.planned]: booleanToYesNo(!task.plannedDay.isSame(task.assignmentDay), true),
      /**
       * For replica tasks, true when the number of steps differs from the master task
       * or one of the step has a different number of character that the corresponding master step.
       * For master tasks, true when there are steps present.
       */
      [csvStrings.stepsAdded]: booleanToYesNo(
        task.isSlave
          ? task.steps.length !== task.masterContent?.stepsList.length ||
              task.steps.some((step, i) => step.title.length !== task.masterContent?.stepsList[i].title.length)
          : task.steps.length > 0,
        true
      ),
      [csvStrings.completed]: booleanToYesNo(task.state === 'completed', true),
      // This special non-localized key gets removed at the last minute.
      ['sortableDueDay']: dueDayString
    };
  }

  /**
   * Loads the config information (start and end day) and all the sections
   */
  private async loadData(): Promise<SectionsCourseOccurrencesStatusInfo> {
    const [config, sections, schoolDays] = await Promise.all([
      this._schoolYearConfigurationStore.getConfigSummary(this.configId),
      this._schoolYearConfigurationStore.getSectionsByIds(this.configId, this.sectionIds),
      this._calendarStore.getSchoolDays(this.configId, this.sectionIds)
    ]);

    if (this.pagination == null) {
      this.setPagination(new AppPaginationViewModel(config.startDay, config.endDay));
    }

    return {
      minDay: config.startDay,
      maxDay: config.endDay,
      schoolDays: schoolDays,
      sections: (
        await Promise.all(
          sections.map<Promise<SectionsCourseOccurrencesStatusSectionInfo>>(async (section) => {
            const studentsForSection = await this._schoolYearConfigurationStore.getStudentsForSection(
              this.configId,
              section,
              false
            );

            return {
              section,
              numberOfStudents: studentsForSection.length
            };
          })
        )
      ).sort((a, b) => {
        if (!a.section.title.trim()) {
          return 1;
        }
        if (!b.section.title.trim()) {
          return -1;
        }

        return (
          caseInsensitiveAccentInsensitiveCompare(
            SectionUtils.formatTitle(a.section),
            SectionUtils.formatTitle(b.section)
          ) ||
          caseInsensitiveAccentInsensitiveCompare(a.section.sectionNumber, b.section.sectionNumber, undefined, true)
        );
      })
    };
  }

  private async loadCurrentPage(pageRange?: PageRangeInfo): Promise<SectionsCourseOccurrencesStatusPageInfo> {
    if (pageRange == null) {
      return {
        schoolDays: [],
        dayInfos: []
      };
    }

    const [tasksInfo, schoolDays, tasks] = await Promise.all([
      this.data,
      this._calendarStore.getSchoolDays(this.configId, this.sectionIds),
      this._contentStore.getContents({
        configId: this.configId,
        sectionIds: this.sectionIds,
        kindsToInclude: ['task'],
        includeCompleted: true,
        fromDay: pageRange.startDay,
        toDay: pageRange.endDay
      })
    ]);

    const tasksBySectionId = groupBy(tasks, (c) => c.sectionId);

    const startDay = startOfDay(pageRange.startDay.asDate);
    const endDay = startOfDay(pageRange.endDay.asDate);
    const daysDiff = differenceInCalendarDays(endDay, startDay);

    const days = Array.from<object, Day>(
      { length: daysDiff + 1 },
      (_, i) => Day.fromDate(subDays(endDay, daysDiff - i))!
    );

    const weekSchoolDays = schoolDays.filter((schoolDay) =>
      schoolDay.day.isWithin(pageRange.startDay, pageRange.endDay)
    );

    return {
      schoolDays: weekSchoolDays,
      dayInfos: flatMap(
        await Promise.all(
          days
            .filter((day) => weekSchoolDays.find((sd) => sd.day.isSame(day)))
            .map<Promise<SectionsCourseOccurrencesStatusDayInfo[]>>(
              async (day) =>
                await Promise.all(
                  tasksInfo.sections.map<Promise<SectionsCourseOccurrencesStatusDayInfo>>(async (sectionInfo) => {
                    const students = await this._schoolYearConfigurationStore.getStudentsForSectionId(
                      this.configId,
                      sectionInfo.section.id,
                      false
                    );

                    const sectionTasks = tasksBySectionId[sectionInfo.section.id];
                    const dayTasks = sectionTasks?.filter((c) => c.dueDay.isSame(day));

                    // We already made sure that the school day exists
                    const schoolDay = weekSchoolDays.find((sd) => sd.day.isSame(day))!;

                    return {
                      schoolDay: schoolDay,
                      section: sectionInfo.section,
                      hasCourseOccurrence: schoolDay?.hasCourseOccurrence(sectionInfo.section.id) ?? false,
                      publishedTasksCount: dayTasks?.filter((t) => t.isMasterPublishedToSection).length ?? 0,
                      studentsWithOwnTasksCount: uniqBy(
                        dayTasks?.filter(
                          (t) =>
                            !t.isPublished &&
                            students.find((student) => student.id === t.ownerId) != null &&
                            sectionInfo.section.teacherIds.find((teacherId) => teacherId === t.ownerId) == null
                        ) ?? [],
                        (c) => c.ownerId
                      ).length
                    };
                  })
                )
            )
        )
      )
    };
  }
}
