import {
  LocalizedText as PBLocalizedText,
  OnboardingProcess as PBOnboardingProcess,
  OnboardingStepSummary as PBOnboardingStepSummary
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { computed, makeObservable } from 'mobx';
import {
  EditableModelEx,
  EditableStringProperty,
  EditableValuePropertyEx,
  FullyEditableListProperty
} from '../../editables';
import { OnboardingStatus } from '../../types';
import { protobufFromOnboardingStatus } from '../../types/EnumConversion';
import { OnboardingProcess, OnboardingStepSummary, OnboardingText } from '../interfaces';
import { EditableOnboardingStepSummary } from './EditableOnboardingStepSummary';
import { EditableOnboardingText } from './EditableOnboardingText';

export class EditableOnboardingProcess extends EditableModelEx<PBOnboardingProcess> implements OnboardingProcess {
  private _templateName: EditableStringProperty<PBOnboardingProcess>;
  private _status: EditableValuePropertyEx<OnboardingStatus, PBOnboardingProcess>;
  private _description: FullyEditableListProperty<
    PBLocalizedText,
    OnboardingText,
    EditableOnboardingText,
    PBOnboardingProcess
  >;
  private _clientId: EditableStringProperty<PBOnboardingProcess>;
  private _agentId: EditableStringProperty<PBOnboardingProcess>;
  private _steps: FullyEditableListProperty<
    PBOnboardingStepSummary,
    OnboardingStepSummary,
    EditableOnboardingStepSummary,
    PBOnboardingProcess
  >;

  constructor(
    private readonly _originalProcess: OnboardingProcess,
    isNew = false
  ) {
    super(_originalProcess.toProtobuf(), isNew);

    makeObservable(this);

    this.setFields([
      (this._templateName = new EditableStringProperty(
        _originalProcess.templateName,
        (pb, value) => (pb.templateName = value),
        { trim: true }
      )),
      (this._status = new EditableValuePropertyEx(
        _originalProcess.status,
        (pb, value) => (pb.status = protobufFromOnboardingStatus(value))
      )),
      (this._description = new FullyEditableListProperty(
        _originalProcess.description.map((t) => new EditableOnboardingText(t)),
        (pb, values) => (pb.localizedDescriptions = values)
      )),
      (this._clientId = new EditableStringProperty(_originalProcess.clientId, (pb, value) => (pb.clientId = value))),
      (this._agentId = new EditableStringProperty(_originalProcess.agentId, (pb, value) => (pb.agentId = value))),
      (this._steps = new FullyEditableListProperty(
        _originalProcess.steps.map((s) => new EditableOnboardingStepSummary(s)),
        (pb, values) => (pb.steps = values)
      ))
    ]);
  }

  get id(): string {
    return this._originalProcess.id;
  }

  get configId(): string {
    return this._originalProcess.configId;
  }

  @computed
  get templateName(): string {
    return this._templateName.value;
  }

  set templateName(value: string) {
    this._templateName.value = value;
  }

  get isCustomized(): boolean {
    return this._originalProcess.isCustomized;
  }

  @computed
  get description(): OnboardingText[] {
    return this._description.values;
  }

  @computed
  get editableDescription(): EditableOnboardingText[] {
    return this._description.values;
  }

  @computed
  get status(): OnboardingStatus {
    return this._status.value;
  }

  set status(value: OnboardingStatus) {
    this._status.value = value;
  }

  @computed
  get clientId(): string {
    return this._clientId.value;
  }

  set clientId(value: string) {
    this._clientId.value = value;
  }

  @computed
  get agentId(): string {
    return this._agentId.value;
  }

  set agentId(value: string) {
    this._agentId.value = value;
  }

  @computed
  get followerIds(): string[] {
    return this._originalProcess.followerIds;
  }

  @computed
  get steps(): OnboardingStepSummary[] {
    return this._steps.values;
  }

  @computed
  get editableSteps(): EditableOnboardingStepSummary[] {
    return this._steps.values;
  }

  get nextTargetDate(): Date | undefined {
    return this._originalProcess.nextTargetDate;
  }

  get finalTargetDate(): Date | undefined {
    return this._originalProcess.finalTargetDate;
  }
}
