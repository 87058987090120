import { MicrosoftTeamsMappingsViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowIcon from '@mui/icons-material/ForwardOutlined';
import {
  Box,
  IconButton,
  InputLabel,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { AllContentWorkloadLevels } from '@shared/models/types';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { ContentWorkloadLevelSelector } from './ContentWorkloadLevelSelector';
import { MicrosoftTeamsAssignmentCategoryInput } from './MicrosoftTeamsAssignmentCategoryInput';
import { OptionalContentIconSelector } from './OptionalContentIconSelector';

export interface MicrosoftTeamsAssignmentCategoryMappingsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: MicrosoftTeamsMappingsViewModel;
}

export const MicrosoftTeamsAssignmentCategoryMappings = observer(
  (props: MicrosoftTeamsAssignmentCategoryMappingsProps) => {
    const { localizationService } = useInsightsServices();
    const { sx = [], className, style, viewModel } = props;
    const strings = localizationService.localizedStrings.insights.components.connectors;

    return (
      <Root sx={sx} className={className} style={style}>
        <TableHead>
          <TableRow>
            <TableCell className={clsx('cell', 'groupCell')}>
              <InputLabel shrink>{strings.microsoftTeamsAssignmentCategoryTitle}</InputLabel>
            </TableCell>
            <TableCell className={clsx('cell', 'iconCell')}>
              <InputLabel shrink>{strings.iconTitle}</InputLabel>
            </TableCell>
            <TableCell className={clsx('cell', 'workloadCell')}>
              <InputLabel shrink>{strings.workloadLevelTitle}</InputLabel>
            </TableCell>
            <TableCell className={clsx('cell', 'actionCell')} />
          </TableRow>
        </TableHead>
        <TableBody>
          {viewModel.mappings.map((m, i) => (
            <TableRow key={`category-row-${i}-${m.categoryName}`}>
              <TableCell className={clsx('cell', 'groupCell')}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography flex={1}>{m.categoryName}</Typography>
                  <ArrowIcon className="arrow" />
                </Box>
              </TableCell>
              <TableCell className={clsx('cell', 'iconCell')}>
                <OptionalContentIconSelector
                  selectedIcon={m.representation?.icon}
                  workloadLevel={m.representation?.workloadLevel}
                  onChange={(icon) => viewModel.setIcon(m, icon)}
                />
              </TableCell>
              <TableCell className={clsx('cell', 'workloadCell')}>
                {m.representation?.icon != null && (
                  <ContentWorkloadLevelSelector
                    selectedLevel={m.representation.workloadLevel}
                    availableLevels={AllContentWorkloadLevels}
                    onChange={(level) => viewModel.setWorkloadLevel(m, level)}
                  />
                )}
              </TableCell>
              <TableCell className={clsx('cell', 'actionCell')}>
                <IconButton onClick={() => m.markAsDeleted()}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow className="nextRow">
            <TableCell className={clsx('cell', 'groupCell')}>
              {/* Until we get an API to fetch all assignment categories, we must go
                  with a text-based mapping. Keeping this for reference. */}
              <MicrosoftTeamsAssignmentCategoryInput
                categoryName=""
                onAdd={(name) => viewModel.addMappingByName(name)}
              />
            </TableCell>
            <TableCell className={clsx('cell', 'gradingCell')} />
            <TableCell className={clsx('cell', 'iconCell')} />
            <TableCell className={clsx('cell', 'workloadCell')} />
            <TableCell className={clsx('cell', 'actionCell')} />
          </TableRow>
        </TableFooter>
      </Root>
    );
  }
);

const Root = styled(Table)(({ theme }) => ({
  '.cell': {
    padding: '2px 8px 2px 0px',
    border: 'none',
    verticalAlign: 'bottom'
  },
  '.groupCell': {
    width: '30%'
  },
  '.gradingCell': {
    width: '30%'
  },
  '.iconCell': {
    width: '15%'
  },
  '.workloadCell': {
    width: '15%'
  },
  '.actionCell': {
    width: '10%'
  },
  '.nextRow': {
    backgroundColor: grey[200]
  },
  '.arrow': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));
