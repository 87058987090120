import { AlertService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface AddOnboardingStepViewModel {
  readonly availableStepNames: string[];
  selectedStepName: string;

  readonly canAdd: boolean;
}

export interface AddOnboardingStepDialogViewModel {
  readonly data: IPromiseBasedObservable<AddOnboardingStepViewModel>;
  readonly canAdd: boolean;

  add(): Promise<void>;
  cancel(): void;
}

class AppAddOnboardingStepViewModel implements AddOnboardingStepViewModel {
  @observable private _selectedStepName = '';

  constructor(readonly availableStepNames: string[]) {
    makeObservable(this);
  }

  @computed
  get selectedStepName() {
    return this._selectedStepName;
  }

  set selectedStepName(value: string) {
    this._selectedStepName = value;
  }

  @computed
  get canAdd() {
    return this._selectedStepName.length > 0;
  }
}

export class AppAddOnboardingStepDialogViewModel implements AddOnboardingStepDialogViewModel {
  @observable private _innerViewModel?: AddOnboardingStepViewModel;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _onSuccess: (stepName?: string) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<AddOnboardingStepViewModel> {
    return fromPromise(this.loadData());
  }

  @computed
  get canAdd() {
    return this._innerViewModel?.canAdd ?? false;
  }

  async add(): Promise<void> {
    try {
      const step = await this._onboardingStore.createStep(this._innerViewModel!.selectedStepName, this._configId);
      this._onSuccess(step.templateName);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    }
  }

  cancel() {
    this._onCancel();
  }

  private async loadData(): Promise<AddOnboardingStepViewModel> {
    const templateNames = await this._onboardingStore.getStepTemplateNames();

    const inner = new AppAddOnboardingStepViewModel(templateNames);

    return runInAction(() => (this._innerViewModel = inner));
  }
}
