import { Section as PBSection } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { Memoize } from 'fast-typescript-memoize';
import { compact, flatMap, uniq } from 'lodash';
import { BaseModel, SerializableModel } from '../Model';
import { Color, Role } from '../types';
import { colorFromProtobuf, roleFromProtobuf } from '../types/EnumConversion';
import { SectionSchedule, SectionScheduleModel } from './SectionSchedule';
import { TrackedModel } from './TrackedModel';

export interface SectionModel extends SerializableModel<PBSection>, TrackedModel {
  readonly id: string;
  readonly isDeleted: boolean;
  readonly importId: string;
  readonly title: string;
  readonly shortTitle: string;
  readonly gradeLevel: string;
  readonly sectionNumber: string;
  readonly associatedSectionNumbers: string[];
  readonly color: Color;
  readonly defaultRoomName: string;
  readonly defaultTeacherId: string;
  readonly autoEnrollRoles: Role[];
  readonly autoEnrollTags: string[];
  readonly isFree: boolean;
  readonly isSystemDefault: boolean;
  readonly isLocked: boolean;
  readonly schedules: SectionScheduleModel[];

  // Commodity properties
  readonly teacherIds: string[];
}

export class Section extends BaseModel<PBSection> implements SectionModel {
  constructor(pb: PBSection) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  get isDeleted(): boolean {
    return this._pb.isDeleted;
  }

  get importId(): string {
    return this._pb.importId;
  }

  get title(): string {
    return this._pb.title;
  }

  get shortTitle(): string {
    return this._pb.shortTitle;
  }

  get gradeLevel(): string {
    return this._pb.gradeLevel;
  }

  get sectionNumber(): string {
    return this._pb.sectionNumber;
  }

  get associatedSectionNumbers(): string[] {
    return this._pb.associatedSectionNumbers;
  }

  get color(): Color {
    return colorFromProtobuf(this._pb.color);
  }

  get defaultRoomName(): string {
    return this._pb.defaultRoomName;
  }

  get defaultTeacherId(): string {
    return this._pb.defaultTeacherId;
  }

  get autoEnrollRoles(): Role[] {
    return this._pb.autoEnrollRoles.map((r) => roleFromProtobuf(r));
  }

  get autoEnrollTags(): string[] {
    return this._pb.autoEnrollTags;
  }

  get isFree(): boolean {
    return this._pb.isFree;
  }

  get isSystemDefault(): boolean {
    return this._pb.isSystemDefault;
  }

  get isLocked(): boolean {
    return this._pb.isLocked;
  }

  @Memoize()
  get schedules(): SectionScheduleModel[] {
    return this._pb.schedules.map((s) => new SectionSchedule(s));
  }

  get manualChanges(): string[] {
    return this._pb.manualChanges;
  }

  toProtobuf(): PBSection {
    return this._pb.clone();
  }

  //
  // Protobuf property not exposed by this client model:
  //
  //   - occurrenceCustomizationsList

  @Memoize()
  get teacherIds(): string[] {
    return uniq(compact([this.defaultTeacherId, ...flatMap(this.schedules.map((schedule) => schedule.teacherIds))]));
  }
}
