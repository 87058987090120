export interface ErrorBoundaryStrings {
  readonly title: string;
  readonly subtitle: string;
  readonly goToHomepage: string;
  readonly logout: string;
}

export const EnglishErrorBoundaryString: ErrorBoundaryStrings = {
  title: 'An error occurred',
  subtitle: 'The Studyo team as been informed of the problem.',
  goToHomepage: 'Go to homepage',
  logout: 'Logout'
};

export const FrenchErrorBoundaryString: ErrorBoundaryStrings = {
  title: 'Une erreur est survenue.',
  subtitle: 'L’équipe de Studyo a été informée du problème.',
  goToHomepage: 'Retourner à la page principale',
  logout: 'Déconnexion'
};
