export interface ContentPublishStrings {
  readonly cancel: string;
  readonly publish: string;
  readonly close: string;
  readonly noSection: string;

  readonly publishTo: string;
  readonly publishToNone: string;
  readonly publishToNoneDescription: string;
  readonly publishToAll: string;
  readonly publishToAllDescription: string;
  readonly publishToSome: string;
  readonly publishToSomeDescription: string;
  readonly publishToSomeVisibleToParentsTeachersDescription: string;

  readonly longTimeSpanAlertTitle: string;
  readonly longTimeSpanAlertDescription: string;

  readonly searchStudents: string;
  readonly numberOfSelectedStudent: (selected: number, total: number) => string;
  readonly selectAllStudents: string;

  readonly unsavedChangesTitle: string;
  readonly unsavedChangesDescription: string;
  readonly unsavedChangesStayTitle: string;
  readonly unsavedChangesDiscardTitle: string;

  readonly savingTitle: string;
  readonly savingDescription: string;
  readonly savedTitle: string;
  readonly savedDescription: string;
  readonly saveErrorTitle: string;
  readonly saveErrorDescription: string;
  readonly savePermanentErrorDescription: string;
}

export const EnglishContentPublishStrings: ContentPublishStrings = {
  cancel: 'Cancel',
  publish: 'Publish',
  close: 'Close',
  noSection: 'Free',

  publishTo: 'Publish to…',
  publishToNone: 'None',
  publishToNoneDescription: 'This task won’t be published.',
  publishToAll: 'All',
  publishToAllDescription:
    'This task will automatically appear in the planner of new students subscribed to this section.',
  publishToSome: 'Some students',
  publishToSomeDescription: 'Select students which will see this task. New students won’t see it.',
  publishToSomeVisibleToParentsTeachersDescription: 'Visible to parents and teachers.',

  longTimeSpanAlertTitle: 'Long lasting task',
  longTimeSpanAlertDescription:
    'It is strongly recommended that the announcement date be no more than 14 days prior to the due date. This simplifies the task view for students and helps them plan more efficiently. Do you wish to continue?',

  searchStudents: 'Search students…',
  numberOfSelectedStudent: (selected, total) => `${selected} out of ${total} selected`,
  selectAllStudents: 'Select all',

  unsavedChangesTitle: 'Unsaved changes',
  unsavedChangesDescription: 'The changes made will be lost. Are you sure you wish to cancel?',
  unsavedChangesDiscardTitle: 'Discard',
  unsavedChangesStayTitle: 'No',

  savingTitle: 'Please wait…',
  savingDescription: 'Publishing task',
  savedTitle: 'Success',
  savedDescription: 'The task was published successfully!',
  saveErrorTitle: 'Oops, something went wrong!',
  saveErrorDescription: 'An error occurred while publishing your task. Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented the save. Please reload and try again.'
};

export const FrenchContentPublishStrings: ContentPublishStrings = {
  cancel: 'Annuler',
  publish: 'Publier',
  close: 'Fermer',
  noSection: 'Libre',

  publishTo: 'Publier à…',
  publishToNone: 'Aucun',
  publishToNoneDescription: 'Cette tâche ne sera pas publiée.',
  publishToAll: 'Tous',
  publishToAllDescription:
    'Cette tâche apparaîtra automatiquement dans l’agenda des nouveaux élèves inscrits au cours.',
  publishToSome: 'Certains',
  publishToSomeDescription:
    'Choisir les élèves qui doivent recevoir cette tâche dans leur agenda.' +
    ' Les nouveaux élèves inscrits ne la verront pas',
  publishToSomeVisibleToParentsTeachersDescription: 'Visible aux parents et aux enseignants.',

  longTimeSpanAlertTitle: 'Tâche de longue durée',
  longTimeSpanAlertDescription:
    "Il est fortement recommandé d’avoir une date d’annonce d'au plus 14 jours avant la date d’échéance. Ceci permet d’alléger la vue des tâches des élèves et les aider à mieux planifier. Voulez-vous continuer?",

  searchStudents: 'Rechercher des élèves…',
  numberOfSelectedStudent: (selected, total) =>
    `${selected} élève${selected > 1 ? 's' : ''} sur ${total} sélectionné${selected > 1 ? 's' : ''}`,
  selectAllStudents: 'Tout sélectionner',

  unsavedChangesTitle: 'Changements non-sauvegardés',
  unsavedChangesDescription: 'Les changements effectués seront perdus. Souhaitez-vous annuler?',
  unsavedChangesStayTitle: 'Non',
  unsavedChangesDiscardTitle: 'Abandonner',

  savingTitle: 'Veuillez patienter…',
  savingDescription: 'Publication de la tâche en cours',
  savedTitle: 'Succès',
  savedDescription: 'La tâche a été publiée!',
  saveErrorTitle: 'Oups, une erreur est survenue!',
  saveErrorDescription: 'La tâche n’a pu être publiée. Veuillez réessayer.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la sauvegarde. Veuillez rafraichir et réessayer.'
};
