import { ContentIcon, ContentWorkloadLevel } from '@shared/models/types';

export interface ContentDefinitionStrings {
  defaultNotesTitle: string;
  defaultTitle: (iconKind: ContentIcon) => string;
  workloadLevelString: (level: ContentWorkloadLevel) => string;
}

export const EnglishContentDefinitionStrings: ContentDefinitionStrings = {
  defaultNotesTitle: 'Notes',
  defaultTitle: englishLocalizedDefaultTitle,
  workloadLevelString: englishWorkloadLevelString
};

export const FrenchContentDefinitionStrings: ContentDefinitionStrings = {
  defaultNotesTitle: 'Notes',
  defaultTitle: frenchLocalizedDefaultTitle,
  workloadLevelString: frenchWorkloadLevelString
};

function englishLocalizedDefaultTitle(contentIcon: ContentIcon): string {
  switch (contentIcon) {
    case 'homework':
      return 'Homework';
    case 'exam':
      return 'Exam';
    case 'oral':
      return 'Oral';
    case 'reading':
      return 'Reading';
    case 'lab':
      return 'Lab';
    case 'personal':
      return 'Personal';
    case 'teamwork':
      return 'Teamwork';
    case 'activity':
      return 'Activity';
    case 'message':
      return 'Message';
    case 'minitest':
      return 'Mini test';
    case 'play':
      return 'Play';
    case 'music':
      return 'Music';
    case 'reminder':
      return 'Reminder';
    case 'sport':
      return 'Sport';
    case 'study':
      return 'Study';
    case 'tutoring':
      return 'Tutoring';
    case 'project':
      return 'Project';
    case 'recuperation':
      return 'Remediation';
    case 'dance':
      return 'Dance';
    case 'library':
      return 'Library';
    case 'signature':
      return 'Signature';
    case 'movie':
      return 'Movie';
    case 'survey':
      return 'Survey';
    case 'videoconference':
      return 'Video conference';
    case 'art':
      return 'Art';
    case 'workplan':
      return 'Work plan';
  }
}

function englishWorkloadLevelString(level: ContentWorkloadLevel): string {
  switch (level) {
    case 'none':
      return 'Reminder';
    case 'regular':
      return 'Regular';
    case 'medium':
      return 'Medium';
    case 'major':
      return 'Major';
    default:
      console.warn(`Unexpected ContentWorkloadLevel found ${level}. Defaulting to regular`);
      return 'Regular';
  }
}

function frenchLocalizedDefaultTitle(contentIcon: ContentIcon): string {
  switch (contentIcon) {
    case 'homework':
      return 'Devoir';
    case 'exam':
      return 'Examen';
    case 'oral':
      return 'Présentation orale';
    case 'reading':
      return 'Lecture';
    case 'lab':
      return 'Laboratoire';
    case 'personal':
      return 'Personnel';
    case 'teamwork':
      return 'Travail dʼéquipe';
    case 'activity':
      return 'Activité';
    case 'message':
      return 'Message';
    case 'minitest':
      return 'Mini test';
    case 'play':
      return 'Théâtre';
    case 'music':
      return 'Musique';
    case 'reminder':
      return 'Rappel';
    case 'sport':
      return 'Sport';
    case 'study':
      return 'Études';
    case 'tutoring':
      return 'Tutorat';
    case 'project':
      return 'Projet';
    case 'recuperation':
      return 'Récupération';
    case 'dance':
      return 'Danse';
    case 'library':
      return 'Bibliothèque';
    case 'signature':
      return 'Signature';
    case 'movie':
      return 'Film';
    case 'survey':
      return 'Sondage';
    case 'videoconference':
      return 'Conférence vidéo';
    case 'art':
      return 'Art';
    case 'workplan':
      return 'Plan de travail';
  }
}

function frenchWorkloadLevelString(level: ContentWorkloadLevel): string {
  switch (level) {
    case 'none':
      return 'Rappel';
    case 'regular':
      return 'Régulière';
    case 'medium':
      return 'Moyenne';
    case 'major':
      return 'Majeure';
    default:
      console.warn(`Unexpected ContentWorkloadLevel found ${level}. Defaulting to regular`);
      return 'Régulière';
  }
}
