import { Box, SxProps } from '@mui/material';
import { SpecialDaySymbol } from '@shared/models/types';
import { TintedImage } from '@shared/rxp/tinted-image';
import { observer } from 'mobx-react-lite';
import { useSharedServices } from '../../UseSharedServicesHook.ts';

export interface SpecialDaySymbolImageProps {
  sx?: SxProps;
  className?: string;
  symbol?: SpecialDaySymbol;
  color?: string;
  squareSize?: number; // Also affects width, defaults to 24
  alwaysDisplaySymbol?: boolean; // If true, it will display a symbol even if it is none.
}

export const SpecialDaySymbolImage = observer((props: SpecialDaySymbolImageProps) => {
  const { imageService } = useSharedServices();
  const { sx = [], className, symbol, color, alwaysDisplaySymbol, squareSize = 24 } = props;

  const assetFromSpecialDaySymbol = (icon: SpecialDaySymbol = 'none', alwaysDisplaySymbol = false) => {
    const { symbols } = imageService.studyoImages.specialDays;

    switch (icon) {
      case 'circle':
        return symbols.circle;
      case 'close-bracket':
        return symbols.closeBracket;
      case 'hollow-square':
        return symbols.hollowSquare;
      case 'open-bracket':
        return symbols.openBracket;
      case 'seal':
        return symbols.seal;
      case 'square':
        return symbols.square;
      case 'star':
        return symbols.star;
      case 'symmetric-star':
        return symbols.symetricStar;
      case 'upended-star':
        return symbols.upendedStar;
      case 'none':
        if (alwaysDisplaySymbol) {
          return symbols.noneVisible;
        }
        return undefined;
      default:
        console.warn('Unexpected special day symbol enum value, defaulting to none');
        return undefined;
    }
  };

  const iconSource = assetFromSpecialDaySymbol(symbol, alwaysDisplaySymbol);

  // SpecialDaySymbol type is `none`.
  if (iconSource == null) {
    return <Box sx={{ ...sx, height: squareSize, width: squareSize }} className={className} />;
  }

  return (
    <TintedImage
      source={iconSource}
      sx={{ ...sx, height: squareSize, width: squareSize }}
      className={className}
      color={symbol !== 'none' ? color : undefined}
    />
  );
});
