import { Editor } from '@insights/viewmodels';
import { Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { InsightsButton } from '../InsightsButton';

export interface EditableDialogActionsProps {
  edition: Editor;
  hasChanges: boolean;
  doneLabel?: string;
}

export const EditableDialogActions = observer(({ edition, hasChanges, doneLabel }: EditableDialogActionsProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.components.calendar;
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'end'
      }}
    >
      <Stack
        spacing={1}
        sx={{
          flex: 1,
          p: 2
        }}
      >
        {edition.validationMessages.map((m, index) => (
          <Typography
            key={`validation-message-${index}`}
            variant="caption"
            sx={{ color: (theme) => theme.palette.error.main }}
          >
            {m}
          </Typography>
        ))}
      </Stack>
      <InsightsButton sx={{ m: 1 }} isDisabled={edition.isApplying} onClick={() => void edition.cancel()}>
        {strings.cancel}
      </InsightsButton>
      <InsightsButton
        sx={{ m: 1 }}
        isDefault
        isDisabled={!hasChanges || edition.isApplying}
        isExecuting={edition.isApplying}
        showSpinnerOnExecuting
        onClick={() => void edition.apply(navigate)}
      >
        {doneLabel ?? strings.done}
      </InsightsButton>
    </Stack>
  );
});
