export interface WorkloadMetricViewsStrings {
  close: string;
  csvExportTooltip: string;
  csvExportStudentTasksTooltip: string;
  editAssessmentPlanningDatesButtonTooltip: string;
  filterTooltipContent: string;
  grade: string;
  loadingDataErrorMessage: string;
  loadingDataMessage: string;
  numberOfOverloadedStudents: string;
  numberOfStudentsAtThreshold: string;
  overloadedStudents: string;
  publishedTasksTitle: string;
  section: string;
  sectionNotOccursPublished: string;
  sectionOccursNoPublished: string;
  sectionOccursPublished: string;
  showLegend: string;
  showOnlyExams: string;
  showOnlyImportantTasks: string;
  studentsAtThreshold: string;
  viewPlanner: string;
  workload: string;

  localizedNumberOfStudents: (count: number) => string;
  localizedNumberOfSections: (count: number) => string;
}

export const EnglishWorkloadMetricViewsStrings: WorkloadMetricViewsStrings = {
  close: 'Close',
  csvExportTooltip: 'Export all published tasks for this school year to a CSV file',
  csvExportStudentTasksTooltip: "Export this week's published tasks, by student, to a CSV file",
  editAssessmentPlanningDatesButtonTooltip: 'Edit the assessment planning dates',
  filterTooltipContent: 'By default, tasks of the following types are included, when marked as important:',
  grade: 'Grade',
  loadingDataErrorMessage: 'An error occurred while loading the data. Please reload.',
  loadingDataMessage: 'Loading data... please hang on!',
  numberOfOverloadedStudents: 'Number of students that are overloaded',
  numberOfStudentsAtThreshold: 'Number of student at threshold',
  overloadedStudents: 'Overloaded students',
  publishedTasksTitle: 'Published Tasks',
  section: 'Section',
  sectionNotOccursPublished: 'Section does not occur - Published tasks',
  sectionOccursNoPublished: 'Section occurs - No published tasks',
  sectionOccursPublished: 'Section occurs - Published tasks',
  showLegend: 'Show legend...',
  showOnlyExams: 'Only show exams',
  showOnlyImportantTasks: 'Only show important tasks',
  studentsAtThreshold: 'Students at threshold',
  viewPlanner: 'View planner',
  workload: 'Workload',

  localizedNumberOfStudents: englishLocalizedNumberOfStudents,
  localizedNumberOfSections: englishLocalizedNumberOfSections
};

export const FrenchWorkloadMetricViewsStrings: WorkloadMetricViewsStrings = {
  close: 'Fermer',
  csvExportTooltip: 'Exporter toutes les tâches publiées pour cette année scolaire dans un fichier CSV',
  csvExportStudentTasksTooltip: 'Exporter les tâches publiées de la semaine, par élève, dans un fichier CSV',
  editAssessmentPlanningDatesButtonTooltip: 'Éditer les dates de planification des évaluations',
  filterTooltipContent:
    'Par défaut, les tâches des types suivants sont incluses, lorsqu’identifiées comme importantes:',
  grade: 'Niveau',
  loadingDataErrorMessage: 'Une erreur est survenue lors du chargement des données. Merci de recharger.',
  loadingDataMessage: 'Chargement des données. Merci de patienter',
  numberOfOverloadedStudents: 'Nombre d’élèves en surcharge',
  numberOfStudentsAtThreshold: 'Nombre d’élèves ayant atteint la limite',
  overloadedStudents: 'Élèves surchargés',
  publishedTasksTitle: 'Tâches publiées',
  section: 'Groupe-matière',
  sectionNotOccursPublished: 'Pas de période de cours - Tâches publiées',
  sectionOccursNoPublished: 'Un cours a lieu - Pas de tâches publiées',
  sectionOccursPublished: 'Un cours a lieu - Tâches publiées',
  showLegend: 'Afficher la légende...',
  showOnlyExams: 'Afficher seulement les examens',
  showOnlyImportantTasks: 'Afficher seulement les tâches importantes',
  studentsAtThreshold: 'Élèves atteignant la limite',
  viewPlanner: 'Voir agenda',
  workload: 'Charge de travail',

  localizedNumberOfStudents: frenchLocalizedNumberOfStudents,
  localizedNumberOfSections: frenchLocalizedNumberOfSections
};

function englishLocalizedNumberOfStudents(count: number) {
  return count === 1 ? count + ' student' : count + ' students';
}

function frenchLocalizedNumberOfStudents(count: number) {
  return count <= 1 ? count + ' élève' : count + ' élèves';
}

function englishLocalizedNumberOfSections(count: number) {
  return count === 1 ? count + ' section' : count + ' sections';
}

function frenchLocalizedNumberOfSections(count: number) {
  return count <= 1 ? count + ' groupe-matière' : count + ' groupes-matière';
}
