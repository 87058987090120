import { SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../../UseInsightsServicesHook';
import { AdminDashboard } from './AdminDashboard';
import { ParentDashboard } from './ParentDashboard';
import { StudentDashboard } from './StudentDashboard';
import { TeacherDashboard } from './TeacherDashboard';

export interface DashboardProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const Dashboard = observer((props: DashboardProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.dashboards;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createDashboard(configId), [configId]);

  const baseProps = { sx, className, style };

  switch (viewModel.highestUserRole) {
    case 'super-admin':
    case 'admin':
    case 'school-staff':
    case 'studyo-staff':
      return <AdminDashboard {...baseProps} viewModel={viewModelFactory.createAdminDashboard(viewModel.configId)} />;

    case 'teacher':
      return (
        <TeacherDashboard {...baseProps} viewModel={viewModelFactory.createTeacherDashboard(viewModel.configId)} />
      );

    case 'parent':
      return <ParentDashboard {...baseProps} viewModel={viewModelFactory.createParentDashboard(viewModel.configId)} />;

    case 'student':
      return (
        <StudentDashboard {...baseProps} viewModel={viewModelFactory.createStudentDashboard(viewModel.configId)} />
      );

    default:
      return (
        <Typography {...baseProps} variant="h4">
          {strings.comingSoon}
        </Typography>
      );
  }
});
