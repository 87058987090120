import AddIcon from '@mui/icons-material/Add';
import { IconButton, Input, InputAdornment, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';

export interface MicrosoftTeamsAssignmentCategoryInputProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  categoryName: string;
  onAdd: (name: string) => void;
}

export const MicrosoftTeamsAssignmentCategoryInput = observer((props: MicrosoftTeamsAssignmentCategoryInputProps) => {
  const { sx = [], className, style, categoryName, onAdd } = props;
  const [name, setName] = useState(categoryName);

  function addMapping() {
    onAdd(name);
    setName('');
  }

  return (
    <Input
      sx={sx}
      className={className}
      style={style}
      fullWidth
      value={name}
      onChange={(e) => setName(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton disabled={name.length === 0} onClick={() => addMapping()}>
            <AddIcon />
          </IconButton>
        </InputAdornment>
      }
      onKeyDown={(e) => {
        if (e.key === 'Enter' && name.length > 0) {
          addMapping();
        }
      }}
    />
  );
});
