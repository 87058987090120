import { TransformationIndexedSourcesViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid2, IconButton, MenuItem, SxProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface TransformationIndexedSourcesProps {
  sx?: SxProps;
  viewModel: TransformationIndexedSourcesViewModel;
  className?: string;
  style?: CSSProperties;
}

export const TransformationIndexedSources = observer((props: TransformationIndexedSourcesProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <Box sx={sx} className={className} style={style}>
      <Box
        sx={{
          my: 1
        }}
      >
        <Typography variant="caption" sx={{ color: (theme) => theme.palette.primary.main }}>
          {strings.indexedSourcesLabel}
        </Typography>
        {viewModel.indexedSources.length > 0 ? (
          viewModel.indexedSources.map((source) => (
            <Box
              key={`index-source-${source.label}`}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  flex: 1
                }}
              >
                <Typography>{strings.indexedSourceDescription(source.label, source.columnIndex)}</Typography>
              </Box>
              <IconButton onClick={() => viewModel.removeIndexedSource(source)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          ))
        ) : (
          <Typography>{strings.noIndexedSource}</Typography>
        )}
      </Box>
      <Box
        sx={{
          ml: 2,
          mb: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Grid2
          container
          spacing={1}
          sx={{
            flex: 1
          }}
        >
          <Grid2 size={{ xs: 6 }}>
            <TextField
              select
              fullWidth
              label={strings.sourceLabelLabel}
              slotProps={{ inputLabel: { shrink: true } }}
              value={viewModel.nextIndexedSourceLabel}
              onChange={(e) => (viewModel.nextIndexedSourceLabel = e.target.value)}
            >
              {viewModel.availableSources.map((source) => (
                <MenuItem key={`next-indexed-source-label${source.label}`} value={source.label}>
                  {`${source.label} - ${source.name} (${source.columnCount})`}
                </MenuItem>
              ))}
            </TextField>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <TextField
              fullWidth
              type="number"
              label={strings.indexedColumnLabel}
              slotProps={{ inputLabel: { shrink: true } }}
              value={viewModel.nextIndexedSourceColumnIndex === -1 ? '' : viewModel.nextIndexedSourceColumnIndex}
              onChange={(e) => (viewModel.nextIndexedSourceColumnIndex = Number(e.target.value))}
            />
          </Grid2>
        </Grid2>

        <IconButton disabled={!viewModel.canAddNextIndexedSource} onClick={() => viewModel.addNextIndexedSource()}>
          <AddIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
});
