import { NavigationService } from '@insights/services';
import {
  BlackbaudSkyExternalAccountDetails,
  BlackbaudSkySchoolLevel,
  BlackbaudSkySchoolYear
} from '@shared/models/connectors';
import { LocalizationService } from '@shared/resources/services';
import { BlackbaudSkyConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface BlackbaudSkyAccountSetupViewModel extends ExternalAccountEditionViewModel {
  yearLabel: string;
  levelId: bigint;
  readonly levelName: string;
  portalAddress: string;

  readonly availableYears: BlackbaudSkySchoolYear[];
  readonly availableLevels: BlackbaudSkySchoolLevel[];
}

export interface BlackbaudSkyAccountSetupDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly settings: IPromiseBasedObservable<BlackbaudSkyAccountSetupViewModel>;
}

export class AppBlackbaudSkyAccountSetupViewModel
  extends BaseExternalAccountEditionViewModel
  implements BlackbaudSkyAccountSetupViewModel
{
  @observable private _yearLabel?: string;
  @observable private _levelId?: bigint;
  @observable private _portalAddress?: string;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _blackbaudSkyStore: BlackbaudSkyConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    public readonly availableYears: BlackbaudSkySchoolYear[],
    public readonly availableLevels: BlackbaudSkySchoolLevel[],
    private readonly _originalDetails: BlackbaudSkyExternalAccountDetails
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get yearLabel() {
    return this._yearLabel ?? this._originalDetails.schoolYearLabel;
  }

  set yearLabel(value: string) {
    this._yearLabel = value;
    this.onChange();
  }

  @computed
  get levelId() {
    return this._levelId ?? this._originalDetails.schoolLevelId;
  }

  set levelId(value: bigint) {
    this._levelId = value;
    this.onChange();
  }

  @computed
  get levelName() {
    const level = this.availableLevels.find((l) => l.id === this._levelId);
    return level?.name ?? '';
  }

  @computed
  get portalAddress() {
    return this._portalAddress ?? this._originalDetails.portalAddress;
  }

  set portalAddress(value: string) {
    this._portalAddress = value;
    this.onChange();
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this.hasChanges) {
      console.error('Applying without changes, ignoring...');
      this._onSuccess();
      return;
    }

    this.beginApplying();

    try {
      await this._blackbaudSkyStore.updateSetup(
        this._configId,
        this._externalAccountId,
        this.yearLabel,
        this.levelId,
        this.portalAddress
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._yearLabel = undefined;
    this._levelId = undefined;
    this._portalAddress = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this.hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}

export class AppBlackbaudSkyAccountSetupDialogViewModel implements BlackbaudSkyAccountSetupDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _blackbaudSkyStore: BlackbaudSkyConnectorStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get settings(): IPromiseBasedObservable<BlackbaudSkyAccountSetupViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<BlackbaudSkyAccountSetupViewModel> {
    const [details, years, levels] = await Promise.all([
      this._blackbaudSkyStore.getAccountDetails(this.externalAccountId),
      this._blackbaudSkyStore.getSchoolYears(this.externalAccountId),
      this._blackbaudSkyStore.getSchoolLevels(this.externalAccountId)
    ]);

    return new AppBlackbaudSkyAccountSetupViewModel(
      this._localizationService,
      this._blackbaudSkyStore,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      years,
      levels,
      details
    );
  }
}
