import { MultipleValues } from '@insights/viewmodels';
import { Checkbox, FormControl, InputLabel, MenuItem, Select, SxProps, Typography } from '@mui/material';
import { AllAutoEnrollableRoles, Role } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { Column, Row } from './layout';
import { MultipleValuesControl } from './utils';

export interface EditableAutoEnrollRolesProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  values: Role[] | MultipleValues;
  onChange: (values: Role[]) => void;
  disabled?: boolean;
}

export const EditableAutoEnrollRoles = observer((props: EditableAutoEnrollRolesProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, values, onChange, disabled } = props;
  const strings = localizationService.localizedStrings.insights.components;

  return (
    <Column sx={sx} className={className} style={style}>
      <MultipleValuesControl
        value={values}
        defaultValue={[]}
        label={strings.autoEnrollRoles}
        disabled={disabled}
        renderControl={(value) => (
          <FormControl>
            <InputLabel htmlFor="roles-select" shrink={true}>
              {strings.autoEnrollRoles}
            </InputLabel>
            <Select
              value={value}
              disabled={disabled}
              multiple
              inputProps={{ id: 'roles-select', placeholder: strings.none }}
              onChange={(event) => onChange(event.target.value as unknown as Role[])}
              renderValue={() => (
                <Row>
                  {value.map((role, index) => (
                    <>
                      {index > 0 && <Typography key={`role-selected-comma-${index}`}>,&nbsp;</Typography>}
                      <Typography
                        key={`role-selected-${index}`}
                        sx={{
                          fontWeight: '500'
                        }}
                      >
                        {strings.getDisplayableRole(role)}
                      </Typography>
                    </>
                  ))}
                </Row>
              )}
            >
              {AllAutoEnrollableRoles.map((role) => (
                <MenuItem key={`role-select-item-${role}`} value={role}>
                  <Checkbox size="small" checked={value.includes(role)} />
                  <Typography
                    sx={{
                      fontWeight: '500'
                    }}
                  >
                    {strings.getDisplayableRole(role)}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Column>
  );
});
