import { WorkloadImpactSummary } from '@buf/studyo_studyo.bufbuild_es/studyo/type_workload_pb';

export interface WorkloadStrings {
  readonly cancelButton: string;
  readonly closeButton: string;
  readonly proceedButton: string;
  publishingImpact: (impact: WorkloadImpactSummary, count: number, group?: string) => string;
  readonly title: string;
}

export const EnglishWorkloadStrings: WorkloadStrings = {
  cancelButton: 'Cancel',
  closeButton: 'Close',
  proceedButton: 'Proceed anyway',
  publishingImpact: (impact, count, group) => {
    // count > 0 if daily or weekly
    if (group == null || group.length === 0) {
      switch (impact) {
        case WorkloadImpactSummary.NONE:
          return 'No impact on student workload';
        case WorkloadImpactSummary.DAILY_EXCEEDED:
          return `${count} student${count > 1 ? 's' : ''} would exceed their daily workload limit`;
        case WorkloadImpactSummary.DAILY_REACHED:
          return `${count} student${count > 1 ? 's' : ''} would reach their daily workload limit`;
        case WorkloadImpactSummary.WEEKLY_EXCEEDED:
          return `${count} student${count > 1 ? 's' : ''} would exceed their weekly workload limit`;
        case WorkloadImpactSummary.WEEKLY_REACHED:
          return `${count} student${count > 1 ? 's' : ''} would reach their weekly workload limit`;
        default:
          return 'Unknown workload impact';
      }
    } else {
      switch (impact) {
        case WorkloadImpactSummary.NONE:
          return `No impact on student workload in group ${group}`;
        case WorkloadImpactSummary.DAILY_EXCEEDED:
          return `${count} student${count > 1 ? 's' : ''} in group ${group} would exceed their daily workload limit`;
        case WorkloadImpactSummary.DAILY_REACHED:
          return `${count} student${count > 1 ? 's' : ''} in group ${group} would reach their daily workload limit`;
        case WorkloadImpactSummary.WEEKLY_EXCEEDED:
          return `${count} student${count > 1 ? 's' : ''} in group ${group} would exceed their weekly workload limit`;
        case WorkloadImpactSummary.WEEKLY_REACHED:
          return `${count} student${count > 1 ? 's' : ''} in group ${group} would reach their weekly workload limit`;
        default:
          return 'Unknown workload impact';
      }
    }
  },
  title: 'Impact on student workload'
};

export const FrenchWorkloadStrings: WorkloadStrings = {
  cancelButton: 'Annuler',
  closeButton: 'Fermer',
  proceedButton: 'Continuer malgré tout',
  publishingImpact: (impact, count, group) => {
    // count > 0 if daily or weekly
    if (group == null || group.length === 0) {
      switch (impact) {
        case WorkloadImpactSummary.NONE:
          return 'Aucun impact sur la charge des élèves';
        case WorkloadImpactSummary.DAILY_EXCEEDED:
          return count === 1
            ? 'Un élève dépassera la limite de charge journalière'
            : `${count} élèves dépasseront la limite de charge journalière`;
        case WorkloadImpactSummary.DAILY_REACHED:
          return count === 1
            ? 'Un élève atteindra la limite de charge journalière'
            : `${count} élèves atteindront la limite de charge journalière`;
        case WorkloadImpactSummary.WEEKLY_EXCEEDED:
          return count === 1
            ? 'Un élève dépassera la limite de charge hebdomadaire'
            : `${count} élèves dépasseront la limite de charge hebdomadaire`;
        case WorkloadImpactSummary.WEEKLY_REACHED:
          return count === 1
            ? 'Un élève atteindra la limite de charge hebdomadaire'
            : `${count} élèves atteindront la limite de charge hebdomadaire`;
        default:
          return 'Impact de charge inconnu';
      }
    } else {
      switch (impact) {
        case WorkloadImpactSummary.NONE:
          return `Aucun impact sur la charge des élèves du groupe ${group}`;
        case WorkloadImpactSummary.DAILY_EXCEEDED:
          return count === 1
            ? `Un élève du groupe ${group} dépassera la limite de charge journalière`
            : `${count} élèves du groupe ${group} dépasseront la limite de charge journalière`;
        case WorkloadImpactSummary.DAILY_REACHED:
          return count === 1
            ? `Un élève du groupe ${group} atteindra la limite de charge journalière`
            : `${count} élèves du groupe ${group} atteindront la limite de charge journalière`;
        case WorkloadImpactSummary.WEEKLY_EXCEEDED:
          return count === 1
            ? `Un élève du groupe ${group} dépassera la limite de charge hebdomadaire`
            : `${count} élèves du groupe ${group} dépasseront la limite de charge hebdomadaire`;
        case WorkloadImpactSummary.WEEKLY_REACHED:
          return count === 1
            ? `Un élève du groupe ${group} atteindra la limite de charge hebdomadaire`
            : `${count} élèves du groupe ${group} atteindront la limite de charge hebdomadaire`;
        default:
          return 'Impact de charge inconnu';
      }
    }
  },
  title: 'Impact sur la charge des élèves'
};
