import { AlertService, NavigationService } from '@insights/services';
import {
  AutomatedImport,
  AutomatedTransformationImport,
  EditableAutomatedImport,
  EditableAutomatedTransformationImport,
  EditableEntityCountTolerance,
  ImportSession,
  Transformation
} from '@shared/models/import';
import { AllEntityListKinds } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { v4 as uuidV4 } from 'uuid';

export interface AutomatedTransformationImportInfo {
  readonly id: string;
  readonly automatedTransformationImport: AutomatedTransformationImport;
  readonly transformation?: Transformation;
}

export interface AutomatedTransformationImportListViewModel {
  readonly automatedTransformationImports: AutomatedTransformationImportInfo[];

  addAutomatedTransformationImport(): Promise<void>;
  editAutomatedTransformationImport(index: number): Promise<void>;
  reorderAutomatedTransformationImport(oldIndex: number, newIndex: number): Promise<void>;
  deleteAutomatedTransformationImport(index: number): Promise<void>;
}

export class AppAutomatedTransformationImportListViewModel implements AutomatedTransformationImportListViewModel {
  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _importSession: ImportSession,
    private readonly _automatedImport: AutomatedImport,
    private readonly _onUpdate: (automatedImport: AutomatedImport) => Promise<void>
  ) {
    makeObservable(this);
  }

  @computed
  get automatedTransformationImports(): AutomatedTransformationImportInfo[] {
    return this._automatedImport.transformations.map((automatedTransformationImport) => ({
      id: uuidV4(),
      automatedTransformationImport,
      transformation: this._importSession.transformations.find((t) => t.label === automatedTransformationImport.label)
    }));
  }

  async addAutomatedTransformationImport(): Promise<void> {
    const editable = new EditableAutomatedImport(this._automatedImport);
    const transformation = EditableAutomatedTransformationImport.createNew();
    this.addDefaultEntityTolerances(transformation);

    const result = await this._navigationService.navigateToAutomatedTransformationImportCreateOrEdit(
      this._importSession,
      transformation
    );

    if (result !== 'cancelled') {
      editable.addTransformation(transformation);
      await this._onUpdate(editable);
    }
  }

  async editAutomatedTransformationImport(index: number): Promise<void> {
    const editable = new EditableAutomatedImport(this._automatedImport);
    const transformation = editable.transformations[index];
    this.addDefaultEntityTolerances(transformation);

    const result = await this._navigationService.navigateToAutomatedTransformationImportCreateOrEdit(
      this._importSession,
      transformation
    );

    if (result !== 'cancelled') {
      await this._onUpdate(editable);
    }
  }

  async reorderAutomatedTransformationImport(oldIndex: number, newIndex: number): Promise<void> {
    const editable = new EditableAutomatedImport(this._automatedImport);
    editable.moveTransformation(oldIndex, newIndex);

    await this._onUpdate(editable);
  }

  async deleteAutomatedTransformationImport(index: number): Promise<void> {
    const editable = new EditableAutomatedImport(this._automatedImport);
    const transformation = editable.transformations[index];

    const strings = this._localizationService.localizedStrings.insights.viewModels.import;
    const result = await this._alertService.showConfirmation({
      title: strings.confirmDeleteAutomatedTransformationImportTitle,
      message: strings.confirmDeleteAutomatedTransformationImportMessage(transformation.label)
    });

    if (result !== 'cancelled') {
      transformation.markAsDeleted();
      await this._onUpdate(editable);
    }
  }

  private addDefaultEntityTolerances(transformation: EditableAutomatedTransformationImport) {
    AllEntityListKinds.forEach((listKind) => {
      if (transformation.entityCountTolerances.find((t) => t.listKind === listKind) == null) {
        transformation.addEntityCountTolerance(EditableEntityCountTolerance.createNew(listKind));
      }
    });
  }
}
