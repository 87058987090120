import { AlertService, NavigationService } from '@insights/services';
import { EditableExternalFileSource, ExternalFileSource } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface ExternalFileSourcesViewModel {
  readonly externalSources: ExternalFileSource[];

  addExternalFileSource(): Promise<void>;
  editExternalFileSource(source: ExternalFileSource): Promise<void>;
  deleteExternalFileSource(id: string): Promise<void>;
}

export class AppExternalFileSourcesViewModel implements ExternalFileSourcesViewModel {
  @observable private readonly _sources: ExternalFileSource[];

  constructor(
    private readonly _importerStore: ImporterStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    externalSources: ExternalFileSource[]
  ) {
    makeObservable(this);
    this._sources = externalSources;
  }

  @computed
  get externalSources() {
    return this._sources;
  }

  @action
  async addExternalFileSource(): Promise<void> {
    const source = await this._navigationService.navigateToExternalFileSourceEdition(
      EditableExternalFileSource.createNew()
    );

    if (source !== 'cancelled') {
      runInAction(() => this._sources.push(source));
    }
  }

  @action
  async editExternalFileSource(source: ExternalFileSource): Promise<void> {
    const index = this._sources.indexOf(source);

    if (index < 0) {
      throw new Error('Unknown external source');
    }

    const newSource = await this._navigationService.navigateToExternalFileSourceEdition(
      new EditableExternalFileSource(source)
    );

    if (newSource !== 'cancelled') {
      runInAction(() => (this._sources[index] = newSource));
    }
  }

  @action
  async deleteExternalFileSource(id: string): Promise<void> {
    const index = this._sources.findIndex((s) => s.id == id);

    if (index < 0) {
      throw new Error('Unknown external source');
    }

    const strings = this._localizationService.localizedStrings.insights.viewModels.settings;
    const result = await this._alertService.showConfirmation({
      title: strings.confirmDeleteExternalFileSourceTitle,
      message: strings.confirmDeleteExternalFileSourceMessage
    });

    if (result !== 'cancelled') {
      try {
        await this._importerStore.deleteExternalFileSource(id);
        runInAction(() => this._sources.splice(index));
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedErrorMessage + (error as Error).message
        });
      }
    }
  }
}
