import {
  ClassroomCourseWorkMappingModel,
  ClassroomExternalAccountDetails,
  GrpcClassroomExternalAccountDetails
} from '@shared/models/connectors';
import { GoogleClassroomTransport } from '../../../transports';
import { ClassroomConnectorStore, ConnectorsStore } from '../../interfaces';
import { AppBaseStore } from '../AppBaseStore';

export class AppClassroomConnectorStore extends AppBaseStore implements ClassroomConnectorStore {
  constructor(
    private readonly _transport: GoogleClassroomTransport,
    connectorStore: ConnectorsStore
  ) {
    super('AppClassroomConnectorStore', false, connectorStore);
  }

  async getGoogleAccountDetails(externalAccountId: string): Promise<ClassroomExternalAccountDetails> {
    const details = await this._transport.fetchClassroomExternalAccountDetails(externalAccountId);

    return new GrpcClassroomExternalAccountDetails(details);
  }

  async createOrUpdateGoogleAccount(
    configId: string,
    externalAccountId: string,
    accountId: string,
    googleUserId: string,
    googleEmail: string,
    authCode: string
  ): Promise<string> {
    const response = await this._transport.createOrUpdateClassroomExternalAccount(
      externalAccountId,
      configId,
      accountId,
      googleUserId,
      googleEmail,
      authCode
    );
    this.invalidate();

    return response.externalAccountId;
  }

  async updateGoogleAccountSettings(
    configId: string,
    externalAccountId: string,
    matchingExpression: string,
    courseWorkMappings: ClassroomCourseWorkMappingModel[],
    shouldIgnoreUnmatchedCourseWork: boolean,
    shouldFetchCourseAliases: boolean,
    shouldRemoveMatchedKeyword: boolean
  ): Promise<void> {
    await this._transport.updateClassroomExternalAccountSettings(
      externalAccountId,
      configId,
      matchingExpression,
      courseWorkMappings.map((cwm) => cwm.toProtobuf()),
      shouldIgnoreUnmatchedCourseWork,
      shouldFetchCourseAliases,
      shouldRemoveMatchedKeyword
    );
    this.invalidate();
  }

  async sendBrokenConnectionEmail(externalAccountId: string, language: string): Promise<void> {
    await this._transport.sendBrokenConnectionEmail(externalAccountId, language);
  }
}
