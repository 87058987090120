import { css } from '@emotion/css';
import { InsightsButton, ObservablePresenter, SectionName, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { SectionUtils } from '@insights/utils';
import { WorkloadEmbeddedFilters } from '@insights/viewmodels';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  SxProps,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme
} from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface WorkloadEmbeddedFilterDialogProps extends DialogResult<WorkloadEmbeddedFilters> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  teacherId: string;
  initialFilters?: WorkloadEmbeddedFilters;
}

export const WorkloadEmbeddedFilterDialog = observer((props: WorkloadEmbeddedFilterDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx = [], style, initialFilters, configId, teacherId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.account;
  const theme = useTheme();

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createWorkloadEmbeddedFilter(configId, teacherId, onSuccess!, onCancel!, initialFilters)
  );

  const contentClassName = css({ minHeight: 100, paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) });

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        maxWidth="xs"
        fullWidth={true}
        scroll="paper"
        onClose={() => viewModel.close()}
        className={className}
        style={style}
      >
        <DialogTitle>{strings.workloadEmbeddedFilterDialogTitle}</DialogTitle>

        <DialogContent dividers classes={{ root: contentClassName }}>
          <ObservablePresenter
            className={contentClassName}
            data={viewModel.data}
            render={(data) => (
              <Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="caption">
                    {strings.workloadEmbeddedFilterDisplayedSectionsFitlerTitle}
                  </Typography>
                  <Box flex={1} />
                  <Checkbox
                    onChange={(_, checked) => viewModel.toggleAllSections(checked)}
                    edge={false}
                    checked={viewModel.areAllSectionsSelected}
                    indeterminate={!viewModel.areAllSectionsSelected && !viewModel.areNoSectionsSelected}
                  />
                </Box>

                <List dense disablePadding sx={{ marginRight: -2 }}>
                  {data.sections.map((section) => (
                    <ListItem
                      key={section.id}
                      disableGutters
                      dense
                      secondaryAction={
                        <Checkbox
                          checked={viewModel.isSectionSelected(section.id)}
                          onChange={(_, checked) => viewModel.toggleSection(section.id, checked)}
                        />
                      }
                    >
                      <ListItemText disableTypography>
                        <SectionName
                          title={SectionUtils.formatTitle(section)}
                          color={section.color}
                          subInformation1={section.sectionNumber}
                        />
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          />
        </DialogContent>

        <DialogActions>
          <InsightsButton onClick={() => viewModel.clear()}>{strings.resetFilter}</InsightsButton>

          <Box flex={1} />

          <InsightsButton isDefault onClick={() => viewModel.close()}>
            {strings.close}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
