import { css } from '@emotion/css';
import { BehaviourSummaryFilterItem, useViewModelRef } from '@insights/components';
import { BehaviourSummaryItemType } from '@insights/models';
import { LightInsightsMuiTheme } from '@insights/theme';
import { StudentsFilters } from '@insights/viewmodels';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme
} from '@mui/material';
import { OQValue } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface StudentsFilterDialogProps extends DialogResult<StudentsFilters> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  initialFilters?: StudentsFilters;
}

export const StudentsFilterDialog = observer((props: StudentsFilterDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx = [], style, initialFilters, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.account;
  const theme = useTheme();

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createStudentsFilter(onSuccess!, onCancel!, initialFilters)
  );

  function updateFilter(type: BehaviourSummaryItemType, filter?: OQValue) {
    const behaviourFilters = { ...viewModel.filters.behaviour };

    if (type === 'app-open') {
      behaviourFilters.appOpenFilter = filter;
    }

    if (type === 'task-completion') {
      behaviourFilters.taskCompletionFilter = filter;
    }

    viewModel.filters = {
      ...viewModel.filters,
      behaviour: behaviourFilters
    };
  }

  const contentClassName = css({ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) });

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        maxWidth="xs"
        fullWidth={true}
        scroll="paper"
        onClose={() => viewModel.close()}
        className={className}
        style={style}
      >
        <DialogTitle>{strings.studentFiltersDialogTitle}</DialogTitle>

        <DialogContent dividers classes={{ root: contentClassName }}>
          <Box>
            <Typography variant="caption">{strings.studentBehaviourFilterTitle}</Typography>

            <BehaviourSummaryFilterItem
              sx={{ my: 1 }}
              type="app-open"
              filter={viewModel.filters.behaviour.appOpenFilter}
              onFilterChanged={(filter) => updateFilter('app-open', filter)}
            />
            <BehaviourSummaryFilterItem
              sx={{ my: 1 }}
              type="task-completion"
              filter={viewModel.filters.behaviour.taskCompletionFilter}
              onFilterChanged={(filter) => updateFilter('task-completion', filter)}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => viewModel.clear()}>
            {strings.clearFilter}
          </Button>

          <Box flex={1} />

          <Button variant="outlined" onClick={() => viewModel.close()}>
            {strings.close}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
