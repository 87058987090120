import { SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface ClassroomMatchingExpressionSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  matchingValue: string;
  onChange: (value: string) => void;
}

export const ClassroomMatchingExpressionSelector = observer((props: ClassroomMatchingExpressionSelectorProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, matchingValue, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <TextField
      sx={sx}
      className={className}
      label={strings.classroomMatchingExpressionLabel}
      helperText={strings.classroomMatchingExpressionNotice}
      value={matchingValue}
      onChange={(event) => onChange(event.target.value)}
      fullWidth={false}
    />
  );
});
