import { css } from '@emotion/css';
import { InsightsButton, ObservablePresenter, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { AllConnectionStatuses, ConnectionStatus, ExternalAssociationListFilters } from '@insights/viewmodels';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  ThemeProvider,
  createTheme,
  useTheme
} from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { DialogResult } from '@shared/services';
import { find } from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ExternalAssociationListFiltersDialogProps extends DialogResult<ExternalAssociationListFilters> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  initialFilters?: ExternalAssociationListFilters;
}

export const ExternalAssociationListFiltersDialog = observer((props: ExternalAssociationListFiltersDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx = [], style, initialFilters, configId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;
  const dateFormats = localizationService.localizedStrings.models.dateFormats;
  const theme = useTheme();

  const contentClassName = css({ minHeight: 100, paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) });

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createExternalAssociationsFilters(configId, onSuccess!, onCancel!, initialFilters)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        maxWidth="xs"
        fullWidth={true}
        scroll="paper"
        onClose={() => viewModel.close()}
        className={className}
        style={style}
      >
        <DialogTitle>{strings.externalAssociationListFiltersDialogTitle}</DialogTitle>

        <DialogContent dividers classes={{ root: contentClassName }}>
          <ObservablePresenter
            className={contentClassName}
            data={viewModel.data}
            render={(data) => (
              <Box>
                <FormControl fullWidth sx={{ pb: 1 }}>
                  <InputLabel htmlFor="teacher-select" shrink>
                    {strings.selectTeacherLabel}
                  </InputLabel>
                  <Select
                    value={viewModel.filters.teacherId ?? ''}
                    inputProps={{ id: 'teacher-select' }}
                    displayEmpty
                    onChange={(e) => viewModel.setTeacherId(e.target.value)}
                  >
                    <MenuItem value="" key="teacher-any">
                      {strings.anyTeacher}
                    </MenuItem>
                    {data.availableTeachers.map((teacher) => (
                      <MenuItem key={`teacher-${teacher.id}`} value={teacher.id}>
                        {AccountUtils.getDisplayLastFirstName(teacher)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ pb: 1 }}>
                  <InputLabel htmlFor="term-select" shrink>
                    {strings.selectTermLabel}
                  </InputLabel>
                  <Select
                    value={viewModel.filters.term?.tag ?? ''}
                    inputProps={{ id: 'term-select' }}
                    displayEmpty
                    onChange={(e) => viewModel.setTerm(find(data.availableTerms, { tag: e.target.value }))}
                  >
                    <MenuItem value="" key="term-any">
                      {strings.anyTerm}
                    </MenuItem>
                    {data.availableTerms.map((term) => (
                      <MenuItem key={`term-${term.tag}`} value={term.tag}>
                        {`${term.tag} : ${term.startDay.formattedString(
                          dateFormats.short
                        )} - ${term.endDay.formattedString(dateFormats.short)}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ pb: 1 }}>
                  <InputLabel htmlFor="connection-status-select" shrink>
                    {strings.selectConnectionStatusLabel}
                  </InputLabel>
                  <Select
                    value={viewModel.filters.connectionStatus}
                    inputProps={{ id: 'connection-status-select' }}
                    onChange={(e) => viewModel.setConnectionStatus(e.target.value as ConnectionStatus)}
                  >
                    {AllConnectionStatuses.map((status) => (
                      <MenuItem value={status} key={`connection-status-${status}`}>
                        {strings.getConnectionStatusLabel(status)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          />
        </DialogContent>

        <DialogActions>
          <InsightsButton onClick={() => viewModel.clear()}>{strings.resetFilters}</InsightsButton>

          <Box flex={1} />

          <InsightsButton isDefault onClick={() => viewModel.close()}>
            {strings.closeFilters}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
