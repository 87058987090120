export interface DailyOptionMenuStrings {
  readonly title: string;
  readonly close: string;
  readonly periodHeightSectionTitle: string;
  readonly smallPeriodHeightOptionTitle: string;
  readonly mediumPeriodHeightOptionTitle: string;
  readonly largePeriodHeightOptionTitle: string;
  readonly veryLargePeriodHeightOptionTitle: string;
  readonly contentDisplaySectionTitle: string;
  readonly taskDisplaySectionTitle: string;
  readonly noteDisplaySectionTitle: string;
  readonly contentPresentationSectionTitle: string;
  readonly iconTitlePresentationSectionTitle: string;
  readonly detailedPresentationSectionTitle: string;
  readonly displayedElementsTitle: string;
  readonly displayAnnouncedDayTitle: string;
}

export const EnglishDailyOptionMenuStrings: DailyOptionMenuStrings = {
  title: 'Options',
  close: 'Close',
  // period height
  periodHeightSectionTitle: 'Period Height',
  smallPeriodHeightOptionTitle: 'Small',
  mediumPeriodHeightOptionTitle: 'Medium',
  largePeriodHeightOptionTitle: 'Large',
  veryLargePeriodHeightOptionTitle: 'Very Large',
  // content types
  contentDisplaySectionTitle: 'Displayed Content Types',
  taskDisplaySectionTitle: 'Tasks',
  noteDisplaySectionTitle: 'Notes',
  // content presentation
  contentPresentationSectionTitle: 'Content Presentation',
  iconTitlePresentationSectionTitle: 'Default',
  detailedPresentationSectionTitle: 'Detailed',
  // displayed elements
  displayedElementsTitle: 'Displayed Elements',
  displayAnnouncedDayTitle: 'Show announcement day icon'
};

export const FrenchDailyOptionMenuStrings: DailyOptionMenuStrings = {
  title: 'Options',
  close: 'Fermer',
  // period height
  periodHeightSectionTitle: 'Hauteur des périodes',
  smallPeriodHeightOptionTitle: 'Petite',
  mediumPeriodHeightOptionTitle: 'Moyenne',
  largePeriodHeightOptionTitle: 'Grande',
  veryLargePeriodHeightOptionTitle: 'Très grande',
  // content types
  contentDisplaySectionTitle: 'Type de contenu affiché',
  taskDisplaySectionTitle: 'Tâches',
  noteDisplaySectionTitle: 'Notes',
  // content presentation
  contentPresentationSectionTitle: 'Présentation du contenu',
  iconTitlePresentationSectionTitle: 'Par défault',
  detailedPresentationSectionTitle: 'Détaillée',
  // displayed elements
  displayedElementsTitle: 'Éléments affichés',
  displayAnnouncedDayTitle: 'Afficher l’icône de date d’annonce'
};
