import { ManageableRole } from '@insights/enums';
import { EditableAccountInfo } from '@insights/models';
import { AccountService, NavigationService } from '@insights/services';
import { CustomFilterUtils, NamedFilter, caseInsensitiveAccentInsensitiveCompare } from '@insights/utils';
import { Role } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { CsvExportViewModelStrings } from '@shared/resources/strings/insights/viewModels/CsvExportViewModelStrings';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import _ from 'lodash';

export interface EditableAccountsViewModel {
  readonly accounts: EditableAccountInfo[];
  readonly manageableRole: ManageableRole;
  readonly acceptedRoles: Role[];

  readonly namedFilters: NamedFilter<EditableAccountInfo>[];

  addAccount(): Promise<void>;
  editAccount(account: EditableAccountInfo): Promise<void>;
  editSelectedSections(account: EditableAccountInfo): Promise<void>;
  editChildren(account: EditableAccountInfo): Promise<void>;
  manageTeacherPlanning(account: EditableAccountInfo): Promise<void>;
  exportToCsv(): void;

  navigateToPlanner(accountId: string): Promise<void>;
}

export class AppEditableAccountsViewModel implements EditableAccountsViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    readonly accounts: EditableAccountInfo[],
    readonly manageableRole: ManageableRole,
    readonly acceptedRoles: Role[]
  ) {}

  get namedFilters(): NamedFilter<EditableAccountInfo>[] {
    return [
      {
        prefix: 'admin:',
        isMatch: this.isAdminMatch.bind(this)
      },
      {
        prefix: 'user:',
        isMatch: this.isUserMatch.bind(this)
      },
      {
        prefix: 'level:',
        isMatch: this.isLevelMatch.bind(this)
      },
      {
        prefix: 'locked:',
        isMatch: this.isLockedMatch.bind(this)
      }
    ];
  }

  async addAccount(): Promise<void> {
    await this._navigationService.navigateToAccountCreation(this._configId, this.acceptedRoles);
  }

  async editAccount(account: EditableAccountInfo): Promise<void> {
    await this._navigationService.navigateToAccountEdition(account.account.configId, account.account.id);
  }

  async editSelectedSections(account: EditableAccountInfo): Promise<void> {
    await this._navigationService.navigateToAccountSectionsEdition(account.account.configId, account.account.id);
  }

  async editChildren(account: EditableAccountInfo): Promise<void> {
    if (account.account.role !== 'parent') {
      throw new Error('Invalid account used for editing children.');
    }

    await this._navigationService.navigateToParentChildrenEdition(account.account.configId, account.account.id);
  }

  async manageTeacherPlanning(account: EditableAccountInfo): Promise<void> {
    if (account.account.role !== 'teacher') {
      throw new Error('Invalid account used for teacher planning.');
    }

    await this._navigationService.navigateToTeacherPlanning(account.account.configId, account.account.id);
  }

  exportToCsv(): void {
    const csvConfig = mkConfig({
      filename: this.manageableRole + '-' + this._configId,
      useKeysAsHeaders: true
    });
    const strings = this._localizationService.localizedStrings.insights.viewModels.csvExports;

    switch (this.manageableRole) {
      // For the moment, there is no need to export more from parents.
      case 'parent':
      case 'teacher': {
        const basicData = this.accounts
          .filter((a) => !a.account.isDeleted)
          .map((a) => this.getExportedBasicData(a, strings));
        download(csvConfig)(generateCsv(csvConfig)(basicData));
        break;
      }

      case 'student': {
        const studentData = _.flatten(
          this.accounts.filter((a) => !a.account.isDeleted).map((a) => this.getExportedStudentData(a, strings))
        );
        download(csvConfig)(generateCsv(csvConfig)(studentData));
        break;
      }

      default:
        throw new Error('Unsupported account type');
    }
  }

  navigateToPlanner(accountId: string): Promise<void> {
    return this._navigationService.navigateToPlannerExternal(this._configId, accountId);
  }

  private isAdminMatch(info: EditableAccountInfo, value: string): boolean {
    return info.account.isAdmin === CustomFilterUtils.valueAsBoolean(value, true);
  }

  private isUserMatch(info: EditableAccountInfo, value: string): boolean {
    return info.account.userId?.length > 0 === CustomFilterUtils.valueAsBoolean(value, true);
  }

  private isLevelMatch(info: EditableAccountInfo, value: string): boolean {
    return (
      info.account.role === 'student' && caseInsensitiveAccentInsensitiveCompare(info.account.gradeLevel, value) === 0
    );
  }

  private isLockedMatch(info: EditableAccountInfo, value: string): boolean {
    return info.account.isLocked === CustomFilterUtils.valueAsBoolean(value, true);
  }

  private getExportedBasicData(info: EditableAccountInfo, strings: CsvExportViewModelStrings) {
    return {
      [strings.id]: info.account.managedIdentifier || info.account.id,
      [strings.firstName]: info.account.firstName,
      [strings.lastName]: info.account.lastName,
      [strings.email]: info.account.email
    };
  }

  private getExportedStudentData(info: EditableAccountInfo, strings: CsvExportViewModelStrings) {
    return info.sections.sections.length > 0
      ? info.sections.sections.map((section) => ({
          [strings.id]: info.account.managedIdentifier || info.account.id,
          [strings.firstName]: info.account.firstName,
          [strings.lastName]: info.account.lastName,
          [strings.email]: info.account.email,
          [strings.gradeLevel]: info.account.gradeLevel,
          [strings.sectionId]: section.section?.importId ?? `? %{section.section.id}`
        }))
      : [
          {
            [strings.id]: info.account.managedIdentifier || info.account.id,
            [strings.firstName]: info.account.firstName,
            [strings.lastName]: info.account.lastName,
            [strings.email]: info.account.email,
            [strings.gradeLevel]: info.account.gradeLevel,
            [strings.sectionId]: ''
          }
        ];
  }
}
