import { alpha } from '@mui/material';
import { amber, blue, green, grey, red, yellow } from '@mui/material/colors';
import { InsightsTheme } from './InsightsTheme';

const InsightsThemeColors = {
  clear: 'rgba(0, 0, 0, 0)',
  white: '#FFF',
  black: '#000',

  gray1: grey[50],
  gray2: grey[200],
  gray3: grey[300],
  gray4: grey[500],
  gray5: grey[700],

  blue1: blue[500],
  blue2: blue[800],

  yellow1: yellow[50],
  yellow2: yellow[500],

  green1: green[500],
  green2: green[300],

  red1: red[500],
  red2: red[800],

  orange1: amber[500]
};

export const WebInsightsTheme: InsightsTheme = {
  kind: 'light',
  mainAccentColor: '#415767',
  mainTextColor: InsightsThemeColors.black,
  mainWarningTextColor: InsightsThemeColors.yellow2,
  mainErrorTextColor: InsightsThemeColors.red1,
  secondaryTextColor: alpha(InsightsThemeColors.black, 0.6),

  baseThicknessValue: 8,

  fontSizes: {
    large: 24,
    medium: 20,
    normal: 16,
    small: 14,
    extraSmall: 12
  },

  periods: {
    contentBoxBackgroundColor: InsightsThemeColors.white,
    freePeriodColor: InsightsThemeColors.gray2,
    freePeriodTextColor: InsightsThemeColors.black,
    freePeriodShadowColor: InsightsThemeColors.gray4,
    skippedPeriodColor: InsightsThemeColors.gray3,
    periodWithSectionTextColor: InsightsThemeColors.white
  },

  contents: {
    icons: {
      assignmentColor: InsightsThemeColors.gray2,
      completedColor: InsightsThemeColors.green1,
      completedOverlayColor: InsightsThemeColors.green1,
      importantColor: InsightsThemeColors.red1,
      normalBackgroundColor: InsightsThemeColors.white,
      normalColor: InsightsThemeColors.gray5,
      notesBackgroundColor: InsightsThemeColors.yellow1,
      personalColor: InsightsThemeColors.gray4,
      sharedColor: InsightsThemeColors.blue2,
      unreadColor: InsightsThemeColors.blue2,
      workloadMajorBackgroundColor: InsightsThemeColors.red1,
      workloadMajorIconColor: InsightsThemeColors.white,
      workloadMediumIconColor: InsightsThemeColors.red1,
      publishErrorColor: InsightsThemeColors.red1
    },

    states: {
      active: InsightsThemeColors.green2,
      late: InsightsThemeColors.red2,
      today: InsightsThemeColors.orange1
    }
  },

  extensions: {
    horizontalPagedView: {
      arrowColor: InsightsThemeColors.gray4
    }
  }
};
