export interface StepListStrings {
  noTitle: string;
  reorderScreenTitle: string;
  removeDate: string;
  stepDateTitle: string;
  stepTitlePlaceholder: string;
}

export const EnglishStepListStrings: StepListStrings = {
  noTitle: 'Untitled',
  reorderScreenTitle: 'Reorder steps',
  removeDate: 'Remove Date',
  stepDateTitle: 'Select Date',
  stepTitlePlaceholder: 'Step title'
};

export const FrenchStepListStrings: StepListStrings = {
  noTitle: 'Sans titre',
  reorderScreenTitle: 'Réordonner',
  removeDate: 'Supprimer la date',
  stepDateTitle: 'Choisir la date',
  stepTitlePlaceholder: 'Nom de l’étape'
};
