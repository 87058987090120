import { Box, SxProps, styled } from '@mui/material';
import clsx from 'clsx';
import { CSSProperties, ReactNode } from 'react';
import { HorizontalAlignment, VerticalAlignment, Wrap } from './Types';

export interface ColumnProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  horizontalContentAlignment?: HorizontalAlignment;
  verticalContentAlignment?: VerticalAlignment;
  wrap?: Wrap;
  hideOverflow?: boolean;
  children: ReactNode;
}

export function Column(props: ColumnProps) {
  const wrap = getWrapClassName(props.wrap);
  const horizontalAlignment = getHorizontalContentAlignmentClassName(props.horizontalContentAlignment);
  const verticalAlignment = getVerticalContentAlignmentClassName(props.verticalContentAlignment);
  const overflow = props.hideOverflow ? 'overflowHidden' : '';

  return (
    <Root
      className={clsx(wrap, horizontalAlignment, verticalAlignment, overflow, props.className)}
      style={props.style}
      sx={props.sx}
    >
      {props.children}
    </Root>
  );
}

function getWrapClassName(wrap: Wrap | undefined) {
  switch (wrap) {
    case 'wrap':
      return 'wrap';

    case 'wrap-reverse':
      return 'wrapReverse';

    case 'no-wrap':
    default:
      return 'noWrap';
  }
}

function getHorizontalContentAlignmentClassName(alignment: HorizontalAlignment | undefined) {
  switch (alignment) {
    case 'center':
      return 'horizontalContentAlignmentCenter';

    case 'right':
      return 'horizontalContentAlignmentRight';

    case 'left':
      return 'horizontalContentAlignmentLeft';

    case 'space-around':
      return 'horizontalContentAlignmentSpaceAround';

    case 'space-between':
      return 'horizontalContentAlignmentSpaceBetween';

    case 'space-evenly':
      return 'horizontalContentAlignmentSpaceEvenly';

    case 'stretch':
    default:
      return 'horizontalContentAlignmentStretch';
  }
}

function getVerticalContentAlignmentClassName(alignment: VerticalAlignment | undefined) {
  switch (alignment) {
    case 'center':
      return 'verticalContentAlignmentCenter';

    case 'bottom':
      return 'verticalContentAlignmentBottom';

    case 'stretch':
      return 'verticalContentAlignmentStretch';

    case 'space-around':
      return 'verticalContentAlignmentSpaceAround';

    case 'space-between':
      return 'verticalContentAlignmentSpaceBetween';

    case 'space-evenly':
      return 'verticalContentAlignmentSpaceEvenly';

    case 'top':
    default:
      return 'verticalContentAlignmentTop';
  }
}

const Root = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,

  '&.overflowHidden': {
    overflow: 'hidden'
  },
  '&.wrap': {
    flexWrap: 'wrap'
  },
  '&.wrapReverse': {
    flexWrap: 'wrap-reverse'
  },
  '&.noWrap': {
    flexWrap: 'nowrap'
  },
  '&.horizontalContentAlignmentLeft': {
    alignItems: 'flex-start'
  },
  '&.horizontalContentAlignmentRight': {
    alignItems: 'flex-end'
  },
  '&.horizontalContentAlignmentCenter': {
    alignItems: 'center'
  },
  '&.horizontalContentAlignmentStretch': {
    alignItems: 'stretch'
  },
  '&.horizontalContentAlignmentSpaceAround': {
    alignItems: 'space-around'
  },
  '&.horizontalContentAlignmentSpaceEvenly': {
    alignItems: 'space-evenly'
  },
  '&.horizontalContentAlignmentSpaceBetween': {
    alignItems: 'space-between'
  },
  '&.verticalContentAlignmentTop': {
    justifyContent: 'flex-start'
  },
  '&.verticalContentAlignmentBottom': {
    justifyContent: 'flex-end'
  },
  '&.verticalContentAlignmentCenter': {
    justifyContent: 'center'
  },
  '&.verticalContentAlignmentStretch': {
    justifyContent: 'stretch'
  },
  '&.verticalContentAlignmentSpaceAround': {
    justifyContent: 'space-around'
  },
  '&.verticalContentAlignmentSpaceEvenly': {
    justifyContent: 'space-evenly'
  },
  '&.verticalContentAlignmentSpaceBetween': {
    justifyContent: 'space-between'
  }
}));
