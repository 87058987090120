import { SxProps, Typography, useTheme } from '@mui/material';
import { SchoolDay } from '@shared/models/calendar';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { Column, Container } from './layout';

export interface SchoolWeekHeaderProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  schoolDay: SchoolDay;
  displayType: 'column-header' | 'page-header';
}

export const SchoolWeekHeader = observer((props: SchoolWeekHeaderProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, displayType, schoolDay } = props;
  const strings = localizationService.localizedStrings.insights.components;
  const theme = useTheme();

  return (
    <>
      {displayType === 'column-header' && (
        <Column sx={sx} className={className} style={style} horizontalContentAlignment="center">
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.text.secondary, fontWeight: 500, lineHeight: 'normal' }}
          >
            {strings.week}
          </Typography>
        </Column>
      )}

      {displayType === 'page-header' && (
        <Column sx={sx} className={className} style={style} horizontalContentAlignment="right">
          <Container sx={{ ml: 1 }}>
            <Typography sx={{ color: theme.palette.text.secondary, lineHeight: 'normal' }}>{strings.weekOf}</Typography>
          </Container>
          <Typography sx={{ fontSize: '1.25em', lineHeight: 'normal' }}>
            {schoolDay.day.formattedString(localizationService.localizedStrings.models.dateFormats.mediumUnabridged)}
          </Typography>
        </Column>
      )}
    </>
  );
});
