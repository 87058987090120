import {
  LocalizedText as PBLocalizedText,
  OnboardingStep as PBOnboardingStep
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { computed, makeObservable } from 'mobx';
import {
  EditableModelEx,
  EditableStringProperty,
  EditableValuePropertyEx,
  FullyEditableListProperty
} from '../../editables';
import { OnboardingParticipantKind, OnboardingStatus } from '../../types';
import { protobufFromOnboardingParticipantKind, protobufFromOnboardingStatus } from '../../types/EnumConversion';
import { OnboardingStep, OnboardingText } from '../interfaces';
import { EditableOnboardingText } from './EditableOnboardingText';

export class EditableOnboardingStep extends EditableModelEx<PBOnboardingStep> implements OnboardingStep {
  private _participants: EditableValuePropertyEx<OnboardingParticipantKind, PBOnboardingStep>;
  private _title: FullyEditableListProperty<PBLocalizedText, OnboardingText, EditableOnboardingText, PBOnboardingStep>;
  private _description: FullyEditableListProperty<
    PBLocalizedText,
    OnboardingText,
    EditableOnboardingText,
    PBOnboardingStep
  >;
  private _targetDays: EditableValuePropertyEx<number, PBOnboardingStep>;
  private _isRepeatable: EditableValuePropertyEx<boolean, PBOnboardingStep>;
  private _dependantStepName: EditableStringProperty<PBOnboardingStep>;
  private _dependantQuestionName: EditableStringProperty<PBOnboardingStep>;
  private _dependantQuestionAnswer: EditableStringProperty<PBOnboardingStep>;
  private _status: EditableValuePropertyEx<OnboardingStatus, PBOnboardingStep>;
  private _clientId: EditableStringProperty<PBOnboardingStep>;
  private _agentId: EditableStringProperty<PBOnboardingStep>;

  constructor(
    private readonly _originalStep: OnboardingStep,
    isNew = false
  ) {
    super(_originalStep.toProtobuf(), isNew);

    makeObservable(this);

    this.setFields([
      (this._participants = new EditableValuePropertyEx(
        _originalStep.participants,
        (pb, value) => (pb.participantKind = protobufFromOnboardingParticipantKind(value))
      )),
      (this._title = new FullyEditableListProperty(
        _originalStep.title.map((t) => new EditableOnboardingText(t)),
        (pb, values) => (pb.localizedTitles = values)
      )),
      (this._description = new FullyEditableListProperty(
        _originalStep.description.map((t) => new EditableOnboardingText(t)),
        (pb, values) => (pb.localizedDescriptions = values)
      )),
      (this._targetDays = new EditableValuePropertyEx(
        _originalStep.targetDays,
        (pb, value) => (pb.targetDays = value)
      )),
      (this._isRepeatable = new EditableValuePropertyEx(
        _originalStep.isRepeatable,
        (pb, value) => (pb.isRepeatable = value)
      )),
      (this._dependantStepName = new EditableStringProperty(
        _originalStep.dependantStepName,
        (pb, value) => (pb.dependantStepName = value)
      )),
      (this._dependantQuestionName = new EditableStringProperty(
        _originalStep.dependantQuestionName,
        (pb, value) => (pb.dependantQuestionName = value)
      )),
      (this._dependantQuestionAnswer = new EditableStringProperty(
        _originalStep.dependantQuestionAnswer,
        (pb, value) => (pb.dependantQuestionAnswer = value)
      )),
      (this._status = new EditableValuePropertyEx(
        _originalStep.status,
        (pb, value) => (pb.status = protobufFromOnboardingStatus(value))
      )),
      (this._clientId = new EditableStringProperty(_originalStep.clientId, (pb, value) => (pb.clientId = value))),
      (this._agentId = new EditableStringProperty(_originalStep.agentId, (pb, value) => (pb.agentId = value)))
    ]);
  }

  get id() {
    return this._originalStep.id;
  }

  get configId() {
    return this._originalStep.configId;
  }

  get templateName() {
    return this._originalStep.templateName;
  }

  get isCustomized() {
    return this._originalStep.isCustomized;
  }

  get isBlocked() {
    return this._originalStep.isBlocked;
  }

  @computed
  get participants() {
    return this._participants.value;
  }

  set participants(value: OnboardingParticipantKind) {
    this._participants.value = value;
  }

  @computed
  get title(): OnboardingText[] {
    return this._title.values;
  }

  @computed
  get editableTitle(): EditableOnboardingText[] {
    return this._title.values;
  }

  @computed
  get description(): OnboardingText[] {
    return this._description.values;
  }

  @computed
  get editableDescription(): EditableOnboardingText[] {
    return this._description.values;
  }

  @computed
  get targetDays() {
    return this._targetDays.value;
  }

  set targetDays(value: number) {
    this._targetDays.value = value;
  }

  get questions() {
    return this._originalStep.questions;
  }

  @computed
  get isRepeatable() {
    return this._isRepeatable.value;
  }

  set isRepeatable(value: boolean) {
    this._isRepeatable.value = value;
  }

  @computed
  get dependantStepName() {
    return this._dependantStepName.value;
  }

  set dependantStepName(value: string) {
    this._dependantStepName.value = value;
  }

  @computed
  get dependantQuestionName(): string {
    return this._dependantQuestionName.value;
  }

  set dependantQuestionName(value: string) {
    this._dependantQuestionName.value = value;
  }

  @computed
  get dependantQuestionAnswer(): string {
    return this._dependantQuestionAnswer.value;
  }

  set dependantQuestionAnswer(value: string) {
    this._dependantQuestionAnswer.value = value;
  }

  get isDependantLocked(): boolean {
    return this._originalStep.isDependantLocked;
  }

  @computed
  get status(): OnboardingStatus {
    return this._status.value;
  }

  set status(value: OnboardingStatus) {
    this._status.value = value;
  }

  @computed
  get clientId(): string {
    return this._clientId.value;
  }

  set clientId(value: string) {
    this._clientId.value = value;
  }

  @computed
  get agentId(): string {
    return this._agentId.value;
  }

  set agentId(value: string) {
    this._agentId.value = value;
  }

  get followerIds(): string[] {
    return this._originalStep.followerIds;
  }

  get targetDate(): Date | undefined {
    return this._originalStep.targetDate;
  }

  get isForcedVisible(): boolean {
    return this._originalStep.isForcedVisible;
  }
}
