export interface ImpersonateStudentSelectionStrings {
  readonly title: string;
  readonly stopImpersonating: string;
  readonly searchPlaceholder: string;
  readonly noStudents: string;
  readonly close: string;
}

export const EnglishImpersonateStudentSelectionStrings: ImpersonateStudentSelectionStrings = {
  title: 'Open a student’s planner',
  stopImpersonating: 'Return to my planner',
  searchPlaceholder: 'Search student…',
  noStudents: 'No students',
  close: 'Close'
};

export const FrenchImpersonateStudentSelectionStrings: ImpersonateStudentSelectionStrings = {
  title: 'Ouvrir l’agenda d’un élève',
  stopImpersonating: 'Retourner à mon agenda',
  searchPlaceholder: 'Rechercher un élève…',
  noStudents: 'Aucun élève',
  close: 'Fermer'
};
