import {
  AccountEdition,
  Column,
  Container,
  Expanded,
  InsightsButton,
  ObservableActionsPresenter,
  ObservablePresenter,
  Row,
  Scroll,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material';
import { Role } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { Observer, observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface AccountEditionDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  accountId: string;
  roles: Role[];
}

export const AccountEditionDialog = observer((props: AccountEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], accountId, configId, roles, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.account;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createAccountEdition(configId, accountId, roles, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog sx={sx} open={true} maxWidth="sm" fullWidth={true} onClose={() => void viewModel.close()}>
        <DialogTitle sx={{ pb: 0 }}>{strings.createOrEditAccount(accountId.length)}</DialogTitle>
        <DialogContent dividers>
          <ObservablePresenter
            data={viewModel.data}
            render={(data) => (
              <Observer>
                {() => (
                  <Column>
                    <Expanded>
                      <Scroll
                        direction="y"
                        sx={{
                          // This is to reset the bottom margin of the last card
                          '& >:last-child': {
                            marginBottom: 2
                          }
                        }}
                      >
                        <AccountEdition viewModel={data} />
                      </Scroll>
                    </Expanded>
                  </Column>
                )}
              </Observer>
            )}
          />
        </DialogContent>

        <DialogActions>
          <ObservableActionsPresenter
            data={viewModel.data}
            render={(data) => (
              <Row verticalContentAlignment="center" horizontalContentAlignment="right">
                <Container sx={{ m: 1 }}>
                  <Column horizontalContentAlignment="left">
                    {data.messages.map((m) => (
                      <Typography key={m} variant="caption" sx={{ color: (theme) => theme.palette.error.main }}>
                        {m}
                      </Typography>
                    ))}
                  </Column>
                </Container>

                <InsightsButton sx={{ m: 1 }} onClick={() => void viewModel.close()}>
                  {strings.cancel}
                </InsightsButton>
                <InsightsButton
                  sx={{ m: 1 }}
                  isDefault
                  onClick={() => void data.apply()}
                  isDisabled={!data.editableAccount.hasChanges}
                >
                  {strings.createOrSaveAccount(accountId.length)}
                </InsightsButton>
              </Row>
            )}
            renderError={() => (
              <InsightsButton isDefault onClick={() => void viewModel.close()}>
                {strings.close}
              </InsightsButton>
            )}
          />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
