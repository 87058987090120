import InfoIcon from '@mui/icons-material/Info';
import { SxProps, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Container, Row } from './layout';

export interface InformationTextProps {
  sx?: SxProps;
  className?: string;
  text: string;
}

export function InformationText(props: InformationTextProps) {
  const { className, sx = [], text } = props;

  return (
    <Row sx={sx} className={className} verticalContentAlignment="center">
      <InfoIcon sx={{ color: blue[700] }} />

      <Container sx={{ ml: 0.5, mt: '3px' }}>
        <Typography sx={{ cursor: 'default', color: (theme) => theme.palette.text.secondary }} variant="subtitle2">
          {text}
        </Typography>
      </Container>
    </Row>
  );
}
