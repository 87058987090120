import { SchemaSuffixGroup as PBSchemaSuffixGroup } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { computed, makeObservable } from 'mobx';
import { BaseModel } from '../../Model';
import { SchemaSuffix, SchemaSuffixGroup } from '../interfaces';
import { GrpcSchemaSuffix } from './GrpcSchemaSuffix';

export class GrpcSchemaSuffixGroup extends BaseModel<PBSchemaSuffixGroup> implements SchemaSuffixGroup {
  constructor(pb: PBSchemaSuffixGroup) {
    super(pb);
    makeObservable(this);
  }

  get localizedDescription(): string {
    return this._pb.description;
  }

  @computed
  get suffixes(): SchemaSuffix[] {
    return this._pb.suffixes.map((s) => new GrpcSchemaSuffix(s));
  }
}
