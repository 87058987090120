import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_pb';
import { BlackbaudSchoolLevel } from './interfaces/BlackbaudSchoolLevel';

export class GrpcBlackbaudSchoolLevel implements BlackbaudSchoolLevel {
  constructor(private readonly pb: BC.SchoolLevel) {}

  get id() {
    return this.pb.id;
  }

  get name() {
    return this.pb.name;
  }
}
