import { AlertService } from '@insights/services';
import { EditableOnboardingProcess } from '@shared/models/onboarding/implementations';
import { OnboardingProcess } from '@shared/models/onboarding/interfaces';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { makeObservable } from 'mobx';

export interface OnboardingProcessEditionDialogViewModel {
  readonly editableProcess: EditableOnboardingProcess;
  readonly canSave: boolean;

  save(alsoUpdateTemplate: boolean): Promise<void>;
  cancel(): void;
  uploadFile(dataUrl: string, fileName?: string): Promise<void>;
}

export class AppOnboardingProcessEditionDialogViewModel implements OnboardingProcessEditionDialogViewModel {
  private _editableProcess: EditableOnboardingProcess;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    process: OnboardingProcess,
    private readonly _onSuccess: (process: OnboardingProcess) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._editableProcess = new EditableOnboardingProcess(process, false);
  }

  get editableProcess() {
    return this._editableProcess;
  }

  readonly canSave = true;

  async save(alsoUpdateTemplate: boolean) {
    try {
      const process = await this._onboardingStore.updateProcess(
        this._editableProcess.templateName,
        this._editableProcess.configId,
        this._editableProcess.description,
        this._editableProcess.steps.map((s) => s.templateName),
        alsoUpdateTemplate
      );
      this._onSuccess(process);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    }
  }

  cancel() {
    this._onCancel();
  }

  async uploadFile(dataUrl: string, fileName?: string): Promise<void> {
    const fileUrl = await this._onboardingStore.uploadResourceFile(dataUrl, this._editableProcess.configId, fileName);
    await navigator.clipboard.writeText(fileUrl);
  }
}
