import { OnDestroy, OnInit, ScreenOrientation, ScreenService, ScreenType } from '@shared/services';
import { action, computed, makeObservable, observable } from 'mobx';

export class WebScreenService implements ScreenService, OnInit, OnDestroy {
  @observable private _screenType: ScreenType = ScreenType.extraLarge;
  @observable private _orientation: ScreenOrientation = 'landscape';

  private _extraSmallMediaQuery?: MediaQueryList;
  private _smallMediaQuery?: MediaQueryList;
  private _mediumMediaQuery?: MediaQueryList;
  private _largeMediaQuery?: MediaQueryList;
  private _extraLargeMediaQuery?: MediaQueryList;
  private _landscapeMediaQuery?: MediaQueryList;
  private _portraitMediaQuery?: MediaQueryList;

  constructor() {
    makeObservable(this);
  }

  @computed
  get screenType(): ScreenType {
    return this._screenType;
  }

  @computed
  get orientation(): ScreenOrientation {
    return this._orientation;
  }

  onInit() {
    if (!window) {
      console.warn(`There is no 'window'`);
      return;
    }

    this._extraSmallMediaQuery = window.matchMedia('(min-width: 0px) and (max-width: 575px)');
    this._smallMediaQuery = window.matchMedia('(min-width: 576px) and (max-width: 767px)');
    this._mediumMediaQuery = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
    this._largeMediaQuery = window.matchMedia('(min-width: 992px) and (max-width: 1199px)');
    this._extraLargeMediaQuery = window.matchMedia('(min-width: 1200px)');

    this._portraitMediaQuery = window.matchMedia('(orientation: portrait)');
    this._landscapeMediaQuery = window.matchMedia('(orientation: landscape)');

    this._extraSmallMediaQuery.addEventListener('change', () => this.screenSizeTest());
    this._smallMediaQuery.addEventListener('change', () => this.screenSizeTest());
    this._mediumMediaQuery.addEventListener('change', () => this.screenSizeTest());
    this._largeMediaQuery.addEventListener('change', () => this.screenSizeTest());
    this._extraLargeMediaQuery.addEventListener('change', () => this.screenSizeTest());

    this._portraitMediaQuery.addEventListener('change', (e) => this.screenOrientationTest(e));
    this._landscapeMediaQuery.addEventListener('change', (e) => this.screenOrientationTest(e));

    this.screenSizeTest();
  }

  onDestroy() {
    if (this._extraSmallMediaQuery != null) {
      this._extraSmallMediaQuery.removeEventListener('change', () => this.screenSizeTest());
    }
    if (this._smallMediaQuery != null) {
      this._smallMediaQuery.removeEventListener('change', () => this.screenSizeTest());
    }
    if (this._mediumMediaQuery != null) {
      this._mediumMediaQuery.removeEventListener('change', () => this.screenSizeTest());
    }
    if (this._largeMediaQuery != null) {
      this._largeMediaQuery.removeEventListener('change', () => this.screenSizeTest());
    }
    if (this._extraLargeMediaQuery != null) {
      this._extraLargeMediaQuery.removeEventListener('change', () => this.screenSizeTest());
    }
    if (this._portraitMediaQuery != null) {
      this._portraitMediaQuery.removeEventListener('change', () => this.screenSizeTest());
    }
    if (this._landscapeMediaQuery != null) {
      this._landscapeMediaQuery.removeEventListener('change', () => this.screenSizeTest());
    }
  }

  @action
  private screenSizeTest() {
    if (this._extraLargeMediaQuery!.matches) {
      this._screenType = ScreenType.extraLarge;
    } else if (this._largeMediaQuery!.matches) {
      this._screenType = ScreenType.large;
    } else if (this._mediumMediaQuery!.matches) {
      this._screenType = ScreenType.medium;
    } else if (this._smallMediaQuery!.matches) {
      this._screenType = ScreenType.small;
    } else if (this._extraSmallMediaQuery!.matches) {
      this._screenType = ScreenType.extraSmall;
    }
  }

  @action
  private screenOrientationTest(e: MediaQueryListEvent) {
    if (e.media === this._landscapeMediaQuery!.media) {
      this._orientation = 'landscape';
    } else if (e.media === this._portraitMediaQuery!.media) {
      this._orientation = 'portrait';
    }
  }
}
