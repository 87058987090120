import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { Memoize } from 'fast-typescript-memoize';
import { BaseModel } from '../../Model';
import { OnboardingParticipantKind, OnboardingStatus } from '../../types';
import { onboardingParticipantKindFromProtobuf, onboardingStatusFromProtobuf } from '../../types/EnumConversion';
import { OnboardingQuestion, OnboardingStep, OnboardingText } from '../interfaces';
import { GrpcOnboardingQuestion } from './GrpcOnboardingQuestion';
import { GrpcOnboardingText } from './GrpcOnboardingText';

export class GrpcOnboardingStep extends BaseModel<PB.OnboardingStep> implements OnboardingStep {
  constructor(pb: PB.OnboardingStep) {
    super(pb);
  }

  get id(): string {
    return this._pb.id;
  }

  get configId(): string {
    return this._pb.configId;
  }

  get templateName(): string {
    return this._pb.templateName;
  }

  get isCustomized(): boolean {
    return this._pb.isCustomized;
  }

  get participants(): OnboardingParticipantKind {
    return onboardingParticipantKindFromProtobuf(this._pb.participantKind);
  }

  @Memoize()
  get title(): OnboardingText[] {
    return this._pb.localizedTitles.map((t) => new GrpcOnboardingText(t));
  }

  @Memoize()
  get description(): OnboardingText[] {
    return this._pb.localizedDescriptions.map((t) => new GrpcOnboardingText(t));
  }

  @Memoize()
  get questions(): OnboardingQuestion[] {
    return this._pb.questions.map((q) => new GrpcOnboardingQuestion(q));
  }

  get targetDays(): number {
    return this._pb.targetDays;
  }

  get isRepeatable(): boolean {
    return this._pb.isRepeatable;
  }

  get dependantStepName(): string {
    return this._pb.dependantStepName;
  }

  get dependantQuestionName(): string {
    return this._pb.dependantQuestionName;
  }

  get dependantQuestionAnswer(): string {
    return this._pb.dependantQuestionAnswer;
  }

  get isDependantLocked(): boolean {
    return this._pb.isDependantLocked;
  }

  get status(): OnboardingStatus {
    return onboardingStatusFromProtobuf(this._pb.status);
  }

  get isBlocked(): boolean {
    return this._pb.isBlocked;
  }

  get clientId(): string {
    return this._pb.clientId;
  }

  get agentId(): string {
    return this._pb.agentId;
  }

  get followerIds(): string[] {
    return this._pb.followerIds;
  }

  get targetDate(): Date | undefined {
    return this._pb.targetDate?.toDate();
  }

  get isForcedVisible(): boolean {
    return this._pb.isForcedVisible;
  }
}
