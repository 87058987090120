import { OnboardingProcessViewModel, OnboardingStepSummaryViewModel } from '@insights/viewmodels';
import { default as CustomizedIcon, default as RenameIcon } from '@mui/icons-material/CallSplit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material';
import { ReorderableList } from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { MaxLayoutWidth } from '../../Constants';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { MoreActionMenu } from '../MoreActionMenu';
import { OnboardingOwnership } from './OnboardingOwnership';
import { OnboardingStatusChip } from './OnboardingStatusChip';
import { OnboardingStepSummaryView } from './OnboardingStepSummary';
import { OnboardingTypographySelector } from './OnboardingTypographySelector';

export interface OnboardingProcessCardProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: OnboardingProcessViewModel;
}

interface SortableStepListProps {
  items: OnboardingStepSummaryViewModel[];
  onOrderChanged: (oldIndex: number, newIndex: number) => void;
}

const SortableStepList = ({ items, onOrderChanged }: SortableStepListProps) => (
  <Box>
    <ReorderableList
      items={items}
      renderItem={(value) => (
        <OnboardingStepSummaryView
          step={value}
          canEdit={true}
          canSort={true}
          style={{ marginBottom: 10 }}
          key={`onboarding-step-${value.id}`}
        />
      )}
      onOrderChanged={onOrderChanged}
    />
  </Box>
);

export const OnboardingProcessCard = observer((props: OnboardingProcessCardProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <Card sx={sx} className={className} style={style}>
      <CardHeader
        title={
          <OnboardingOwnership
            agent={viewModel.agent}
            client={viewModel.client}
            followers={viewModel.followers}
            disabled={!viewModel.canEdit}
            onClick={() => viewModel.selectOwners()}
          />
        }
        action={
          <Box display="flex" flexDirection="row" alignItems="center">
            <OnboardingStatusChip
              status={viewModel.status}
              nextDate={viewModel.nextTargetDate}
              targetDate={viewModel.finalTargetDate}
              disabled={!viewModel.canEdit}
              onChange={(status) => viewModel.updateStatus(status)}
            />

            {viewModel.isCustomized && viewModel.canEdit && (
              <Tooltip title={strings.customizedProcessNotice}>
                <CustomizedIcon sx={{ ml: 1 }} color="error" />
              </Tooltip>
            )}

            {viewModel.canEdit && (
              <MoreActionMenu>
                <MenuItem onClick={() => void viewModel.navigateToEditProcess()}>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText>{strings.editProcessLabel}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => void viewModel.renameProcess()}>
                  <ListItemIcon>
                    <RenameIcon />
                  </ListItemIcon>
                  <ListItemText>{strings.getRenameTemplateLabel(viewModel.templateName)}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => void viewModel.deleteProcess()}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>{strings.deleteProcessLabel}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => void viewModel.copyProcess()}>
                  <ListItemIcon>
                    <CopyIcon />
                  </ListItemIcon>
                  <ListItemText>{strings.copyProcessLabel}</ListItemText>
                </MenuItem>
              </MoreActionMenu>
            )}
          </Box>
        }
      />
      <Divider />
      <CardContent
        sx={{
          maxWidth: MaxLayoutWidth - 50 // Arbitrary, trial and error
        }}
      >
        <OnboardingTypographySelector
          sx={{ mb: 2 }}
          texts={viewModel.description}
          variableResolver={viewModel.variableResolver}
        />
        <Typography variant="h6">{strings.stepsTitle}</Typography>
        {viewModel.canSort ? (
          <>
            <SortableStepList
              items={viewModel.steps}
              onOrderChanged={(oldIndex, newIndex) => void viewModel.reorderStep(oldIndex, newIndex)}
            />
            {/* canSort implies canEdit */}
            <Button sx={{ mt: 2 }} variant="contained" onClick={() => void viewModel.navigateToAddStep()}>
              {strings.addStepLabel}
            </Button>
          </>
        ) : (
          <Box>
            {viewModel.steps.map((step, index) => (
              <OnboardingStepSummaryView
                key={`onboarding-step-${index}`}
                step={step}
                canEdit={viewModel.canEdit}
                canSort={false}
                style={{ marginBottom: 10 }}
              />
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
});
