import { SectionEditionViewModel } from '@insights/viewmodels';
import { Autocomplete, SxProps, TextField, useTheme } from '@mui/material';
import { EditableSectionSchedule } from '@shared/models/config';
import { Time } from '@shared/models/types';
import { BrowserInfo, detect } from 'detect-browser';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Row } from '../layout';
import { Autosuggest, FieldNavigationManager } from '../utils';

export interface EditableSectionScheduleTimeProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SectionEditionViewModel;
  schedule: EditableSectionSchedule;
}

export const EditableSectionScheduleTime = observer((props: EditableSectionScheduleTimeProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel, schedule } = props;
  const strings = localizationService.localizedStrings.insights.components.sections;
  const theme = useTheme();

  const browserInfo = detect();
  const browser = browserInfo instanceof BrowserInfo ? browserInfo : undefined;
  const timeControlStyle = browser?.name === 'safari' ? { maxWidth: 50 } : undefined;
  const invalidFields = schedule.invalidFields;

  return (
    <Row sx={sx} className={className} style={style} verticalContentAlignment="bottom">
      <FieldNavigationManager
        primaryControl={(p) => (
          <Autocomplete
            sx={{ mr: 1, flex: 1 }}
            fullWidth
            disableClearable
            value={{ value: schedule.isMaster, label: schedule.isMaster ? strings.period : strings.specificTime }}
            options={[
              {
                label: strings.period,
                value: true
              },
              {
                label: strings.specificTime,
                value: false
              }
            ]}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(_, value) => (schedule.isMaster = value.value)}
            renderInput={(params) => (
              <TextField {...params} {...p} sx={{ '& .MuiInputBase-input': { ...theme.typography.body2 } }} />
            )}
          />
        )}
        secondaryControl={(props) => (
          <>
            {schedule.isMaster ? (
              <Row verticalContentAlignment="bottom" sx={{ flex: 2.5 }}>
                <Autosuggest
                  sx={{ mr: 1, flex: 1 }}
                  fullWidth
                  value={schedule.editableMasterSchedule.periodTag}
                  options={viewModel.availablePeriodTags.map((value) => ({ value }))}
                  onInputChange={(_, v) => (schedule.editableMasterSchedule.periodTag = v)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ '& .MuiInputBase-input': { ...theme.typography.body2 } }}
                      fullWidth
                      label={strings.periodTag}
                      slotProps={{ inputLabel: { shrink: true } }}
                      error={invalidFields.includes('period-tag')}
                    />
                  )}
                />
                <Autosuggest
                  sx={{ mr: 1, flex: 1 }}
                  fullWidth
                  value={schedule.editableMasterSchedule.scheduleTag}
                  options={viewModel.availableScheduleTags.map((value) => ({ value }))}
                  onInputChange={(_, v) => (schedule.editableMasterSchedule.scheduleTag = v)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ '& .MuiInputBase-input': { ...theme.typography.body2 } }}
                      fullWidth
                      label={strings.scheduleTag}
                      slotProps={{ inputLabel: { shrink: true } }}
                    />
                  )}
                />
              </Row>
            ) : (
              <Row verticalContentAlignment="bottom" sx={{ flex: 3 }}>
                <TextField
                  sx={{ mr: 1, flex: 1, ...timeControlStyle }}
                  type="time"
                  value={schedule.editableCustomSchedule.startTime.asString}
                  label={strings.start}
                  onChange={(event) => {
                    const time = Time.fromString(event.target.value);

                    if (time != null) {
                      schedule.editableCustomSchedule.startTime = time;
                    }
                  }}
                  slotProps={{ input: { sx: { ...theme.typography.body2 } }, inputLabel: { shrink: true } }}
                  error={invalidFields.includes('custom-start-time')}
                  {...props}
                />
                <TextField
                  sx={{ mr: 1, flex: 1, ...timeControlStyle }}
                  type="time"
                  value={schedule.editableCustomSchedule.endTime.asString}
                  label={strings.end}
                  onChange={(event) => {
                    const time = Time.fromString(event.target.value);

                    if (time != null) {
                      schedule.editableCustomSchedule.endTime = time;
                    }
                  }}
                  slotProps={{ input: { sx: { ...theme.typography.body2 } }, inputLabel: { shrink: true } }}
                  error={invalidFields.includes('custom-end-time')}
                />
                <TextField
                  sx={{ mr: 1, flex: 1 }}
                  value={schedule.editableCustomSchedule.displayPeriodTag}
                  label={strings.displayedTag}
                  onChange={(event) => (schedule.editableCustomSchedule.displayPeriodTag = event.target.value)}
                  slotProps={{ input: { sx: { ...theme.typography.body2 } }, inputLabel: { shrink: true } }}
                  error={invalidFields.includes('custom-display-tag')}
                />
              </Row>
            )}
          </>
        )}
      />
    </Row>
  );
});
