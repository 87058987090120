export interface MessageDialogStrings {
  defaultTitle: string;
  defaultOkButtonCaption: string;
}

export const EnglishMessageDialogStrings: MessageDialogStrings = {
  defaultTitle: 'Information',
  defaultOkButtonCaption: 'Ok'
};

export const FrenchMessageDialogStrings: MessageDialogStrings = {
  defaultTitle: 'Information',
  defaultOkButtonCaption: 'Ok'
};
