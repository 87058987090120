import * as GC from '@buf/studyo_studyo.bufbuild_es/studyo/type_classroom_pb';
import { ContentDefinition_ContentIcon as PBContentIcon } from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableValuePropertyEx } from '../editables';
import { ContentIcon, ContentWorkloadLevel } from '../types';
import { protobufFromContentIcon, protobufFromContentWorkloadLevel } from '../types/EnumConversion';
import { GrpcClassroomContentRepresentation } from './GrpcClassroomContentRepresentation';
import { ClassroomContentRepresentationModel } from './interfaces';

export class EditableClassroomContentRepresentation
  extends EditableModelEx<GC.ContentRepresentation>
  implements ClassroomContentRepresentationModel
{
  private _icon: EditableValuePropertyEx<ContentIcon, GC.ContentRepresentation>;
  private _workloadLevel: EditableValuePropertyEx<ContentWorkloadLevel, GC.ContentRepresentation>;

  static createNew(): EditableClassroomContentRepresentation {
    const pb = new GC.ContentRepresentation();
    pb.icon = PBContentIcon.HOMEWORK;

    return new EditableClassroomContentRepresentation(new GrpcClassroomContentRepresentation(pb), true);
  }

  constructor(
    readonly originalRepresentation: ClassroomContentRepresentationModel,
    readonly isNew = false
  ) {
    super(originalRepresentation.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._icon = new EditableValuePropertyEx(
        originalRepresentation.icon,
        (pb, value) => (pb.icon = protobufFromContentIcon(value))
      )),
      (this._workloadLevel = new EditableValuePropertyEx(
        originalRepresentation.workloadLevel,
        (pb, value) => (pb.workloadLevel = protobufFromContentWorkloadLevel(value))
      ))
    ]);
  }

  @computed
  get icon() {
    return this._icon.value;
  }

  set icon(value: ContentIcon) {
    this._icon.value = value;
  }

  @computed
  get workloadLevel() {
    return this._workloadLevel.value;
  }

  set workloadLevel(value: ContentWorkloadLevel) {
    this._workloadLevel.value = value;
  }
}
