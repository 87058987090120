import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { BaseModel } from '../../Model';
import { OnboardingTextFormat } from '../../types';
import { onboardingTextFormatFromProtobuf } from '../../types/EnumConversion';
import { OnboardingText } from '../interfaces/OnboardingText';

export class GrpcOnboardingText extends BaseModel<PB.LocalizedText> implements OnboardingText {
  constructor(pb: PB.LocalizedText) {
    super(pb);
  }

  get languageCode(): string {
    return this._pb.languageCode;
  }

  get format(): OnboardingTextFormat {
    return onboardingTextFormatFromProtobuf(this._pb.format);
  }

  get value(): string {
    return this._pb.value;
  }
}
