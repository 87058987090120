import { AlertService, NavigationService } from '@insights/services';
import { AutomatedImport, ImportSession } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { NavigateFunction } from 'react-router-dom';
import { AppAutomatedImportHistoryViewModel, AutomatedImportHistoryViewModel } from './AutomatedImportHistoryViewModel';
import {
  AppAutomatedTransformationImportListViewModel,
  AutomatedTransformationImportListViewModel
} from './AutomatedTransformationImportListViewModel';

export interface AutomatedImportDetailsViewModel {
  readonly automatedImport: AutomatedImport;
  readonly transformations: AutomatedTransformationImportListViewModel;
  readonly history: AutomatedImportHistoryViewModel;

  editAutomatedImport(): Promise<void>;
}

export interface AutomatedImportDetailsScreenViewModel {
  readonly data: IPromiseBasedObservable<AutomatedImportDetailsViewModel>;

  deleteAutomatedImport(navigate: NavigateFunction): Promise<void>;
}

export class AppAutomatedImportDetailsViewModel implements AutomatedImportDetailsViewModel {
  @observable private _automatedImport: AutomatedImport;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _importerStore: ImporterStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _importSession: ImportSession,
    automatedImport: AutomatedImport
  ) {
    makeObservable(this);
    this._automatedImport = automatedImport;
  }

  @computed
  get automatedImport() {
    return this._automatedImport;
  }

  @computed
  get transformations() {
    return new AppAutomatedTransformationImportListViewModel(
      this._importerStore,
      this._navigationService,
      this._alertService,
      this._localizationService,
      this._importSession,
      this._automatedImport,
      this.updateAutomatedImport.bind(this)
    );
  }

  @computed
  get history() {
    return new AppAutomatedImportHistoryViewModel(
      this._navigationService,
      this._importerStore,
      this._alertService,
      this._localizationService,
      this._importSession,
      this._automatedImport
    );
  }

  async editAutomatedImport(): Promise<void> {
    const result = await this._navigationService.navigateToAutomatedImportCreateOrEdit(
      this._configId,
      this._importSession.id,
      this.automatedImport
    );

    if (result !== 'cancelled') {
      try {
        const automatedImport = await this._importerStore.getAutomatedImport(
          this._configId,
          this._importSession.id,
          this.automatedImport.id
        );

        runInAction(() => (this._automatedImport = automatedImport));
      } catch (error) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.import;
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedErrorMessage + (error as Error).message
        });
      }
    }
  }

  @action
  private async updateAutomatedImport(automatedImport: AutomatedImport) {
    try {
      const result = await this._importerStore.createOrUpdateAutomatedImport(
        this._importSession.configId,
        this._importSession.id,
        automatedImport
      );

      runInAction(() => (this._automatedImport = result));
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.import;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    }
  }
}

export class AppAutomatedImportDetailsScreenViewModel implements AutomatedImportDetailsScreenViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _importerStore: ImporterStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _importSessionId: string,
    private readonly _automatedImportId: string
  ) {}

  get data() {
    return fromPromise(this.loadData());
  }

  async deleteAutomatedImport(navigate: NavigateFunction): Promise<void> {
    try {
      await this._importerStore.deleteAutomatedImport(this._configId, this._importSessionId, this._automatedImportId);
      await this._navigationService.navigateToImportSession(this._configId, this._importSessionId, navigate);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.import;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    }
  }

  private async loadData(): Promise<AutomatedImportDetailsViewModel> {
    const [importSession, automatedImport] = await Promise.all([
      this._importerStore.getImportSession(this._importSessionId, false),
      this._importerStore.getAutomatedImport(this._configId, this._importSessionId, this._automatedImportId)
    ]);

    return new AppAutomatedImportDetailsViewModel(
      this._navigationService,
      this._importerStore,
      this._alertService,
      this._localizationService,
      this._configId,
      importSession,
      automatedImport
    );
  }
}
