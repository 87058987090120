import {
  Column,
  Container,
  Expanded,
  InsightsButton,
  ObservableActionsPresenter,
  ObservablePresenter,
  Row,
  Scroll,
  SectionTeachersEdition,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { SectionUtils } from '@insights/utils';
import { SectionTeachersEditionViewModel } from '@insights/viewmodels';
import { createTheme, Dialog, DialogActions, DialogContent, DialogTitle, SxProps, ThemeProvider } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SectionTeachersEditionDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  sectionId: string;
}

export const SectionTeachersEditionDialog = observer((props: SectionTeachersEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], configId, sectionId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createSectionTeachersEdition(configId, sectionId, onSuccess!, onCancel!)
  );
  const data = viewModel.data;

  const close = () => void viewModel.close();

  function renderDialogTitle(data?: SectionTeachersEditionViewModel) {
    if (data == null) {
      return strings.editSectionTeachers();
    } else {
      const sectionTitle = SectionUtils.formatTitle(data.section, strings.noSectionTitle);
      const teacherName =
        data.defaultTeacher == null
          ? strings.noDefaultTeacher
          : AccountUtils.getDisplayLastFirstName(data.defaultTeacher, strings.noName);
      return strings.editSectionTeachers(sectionTitle, teacherName);
    }
  }

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog open maxWidth="lg" fullWidth PaperProps={{ sx: { height: '100vh' } }} sx={sx} onClose={() => close()}>
        <DialogTitle sx={{ pb: 0 }}>
          {data.case({
            pending: renderDialogTitle as (t?: unknown) => string,
            fulfilled: renderDialogTitle,
            rejected: renderDialogTitle
          })}
        </DialogTitle>

        <DialogContent>
          <ObservablePresenter
            data={data}
            render={(data) => (
              <Container>
                <Column>
                  <Expanded>
                    <Scroll
                      direction="y"
                      sx={{
                        // This is to reset the bottom margin of the last card
                        '& >:last-child': {
                          marginBottom: 2
                        }
                      }}
                    >
                      <SectionTeachersEdition viewModel={data} />
                    </Scroll>
                  </Expanded>
                </Column>
              </Container>
            )}
          />
        </DialogContent>

        <DialogActions>
          <ObservableActionsPresenter
            data={viewModel.data}
            render={(data) => (
              <Expanded>
                <Row verticalContentAlignment="center">
                  <Expanded />
                  <InsightsButton sx={{ ml: 1 }} onClick={close} isExecuting={data.isSaving}>
                    {strings.cancel}
                  </InsightsButton>
                  <InsightsButton
                    sx={{ ml: 1 }}
                    onClick={() => void data.apply()}
                    isDisabled={!data.hasChanges}
                    isExecuting={data.isSaving}
                    showSpinnerOnExecuting={true}
                    isSubmit={true}
                  >
                    {strings.save}
                  </InsightsButton>
                </Row>
              </Expanded>
            )}
            renderError={() => <InsightsButton onClick={() => close()}>{strings.close}</InsightsButton>}
          />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
