import { css } from '@emotion/css';
import { ObservablePresenter, SectionName, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { SectionUtils } from '@insights/utils';
import { TeacherDetailsFilters } from '@insights/viewmodels';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  SxProps,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme
} from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface TeacherDetailsFilterDialogProps extends DialogResult<TeacherDetailsFilters> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  teacherId: string;
  initialFilters?: TeacherDetailsFilters;
}

export const TeacherDetailsFilterDialog = observer((props: TeacherDetailsFilterDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx = [], style, initialFilters, teacherId, onCancel, onSuccess, configId } = props;
  const strings = localizationService.localizedStrings.insights.views.account;
  const theme = useTheme();

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createTeacherDetailsFilter(configId, teacherId, onSuccess!, onCancel!, initialFilters)
  );

  const contentClassName = css({ minHeight: 100, paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) });

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        maxWidth="xs"
        fullWidth={true}
        scroll="paper"
        onClose={() => viewModel.close()}
        className={className}
        style={style}
      >
        <DialogTitle>{strings.teacherDetailsFilterDialogTitle}</DialogTitle>

        <DialogContent dividers classes={{ root: contentClassName }}>
          <ObservablePresenter
            className={contentClassName}
            data={viewModel.data}
            render={(data) => (
              <Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="caption">{strings.teacherDetailsFilterDisplayedSectionsFitlerTitle}</Typography>
                  <Box flex={1} />
                  <Checkbox
                    onChange={(e, checked) => viewModel.toggleAllSections(checked)}
                    edge={false}
                    checked={viewModel.areAllSectionsSelected}
                    indeterminate={!viewModel.areAllSectionsSelected && !viewModel.areNoSectionsSelected}
                  />
                </Box>

                <List dense disablePadding>
                  {data.sections.map((section) => (
                    <ListItem
                      key={section.id}
                      disableGutters
                      dense
                      secondaryAction={
                        <Checkbox
                          checked={viewModel.isSectionSelected(section.id)}
                          onChange={(e, checked) => viewModel.toggleSection(section.id, checked)}
                        />
                      }
                    >
                      <ListItemText disableTypography>
                        <SectionName
                          title={SectionUtils.formatTitle(section)}
                          color={section.color}
                          subInformation1={section.sectionNumber}
                        />
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => viewModel.clear()}>
            {strings.resetFilter}
          </Button>

          <Box flex={1} />

          <Button variant="outlined" onClick={() => viewModel.close()}>
            {strings.close}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
