import { CalendarAccountViewModel } from '@insights/viewmodels';
import { SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';

export interface CalendarAccountProps {
  sx?: SxProps;
  className?: string;
  viewModel: CalendarAccountViewModel;
}

export const CalendarAccount = observer((props: CalendarAccountProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Column sx={sx} className={className}>
      <TextField
        sx={{ m: 1 }}
        label={strings.accountName}
        value={viewModel.accountName}
        onChange={(event) => (viewModel.accountName = event.target.value)}
      />
      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
