import { ManageableRole } from '@insights/enums';

export interface AccountViewsStrings {
  importIdentifierRequiredMessage: string;
  cancel: string;
  resetFilter: string;
  close: string;
  save: string;
  clearFilter: string;
  copySelectedSectionsButtonText: string;
  copySelectedSectionsTitle: string;
  copySelectedSectionsTooltip: string;
  editParentChildrenTitle: string;
  loadingRecordsMessage: string;
  loadingRecordsErrorMessage: string;
  studentFiltersDialogTitle: string;
  studentBehaviourFilterTitle: string;
  teacherDetailsFilterDialogTitle: string;
  teacherDetailsFilterDisplayedSectionsFitlerTitle: string;
  teacherDashboardFilterDialogTitle: string;
  teacherDashboardFilterDisplayedSectionsFitlerTitle: string;
  workloadEmbeddedFilterDialogTitle: string;
  workloadEmbeddedFilterDisplayedSectionsFitlerTitle: string;

  accountListTitle: (role: ManageableRole) => string;
  createOrEditAccount: (accountIdLength: number) => string;
  createOrSaveAccount: (accountIdLength: number) => string;
}

export const EnglishAccountViewsStrings: AccountViewsStrings = {
  importIdentifierRequiredMessage: `You must provide an 'Import Identifier'`,
  cancel: 'Cancel',
  resetFilter: 'Reset',
  close: 'Close',
  save: 'Save',
  clearFilter: 'Clear',
  copySelectedSectionsButtonText: 'Copy sections from another student',
  copySelectedSectionsTitle: 'Copy sections from another student',
  copySelectedSectionsTooltip: 'Copy sections',
  editParentChildrenTitle: 'Edit children',
  loadingRecordsErrorMessage: 'An error occurred while loading records.',
  loadingRecordsMessage: 'Loading records. Please wait…',
  studentFiltersDialogTitle: 'Filters',
  studentBehaviourFilterTitle: 'Behaviours',
  teacherDetailsFilterDialogTitle: 'Filters',
  teacherDetailsFilterDisplayedSectionsFitlerTitle: 'Displayed Sections',
  teacherDashboardFilterDialogTitle: 'Filters',
  teacherDashboardFilterDisplayedSectionsFitlerTitle: 'Displayed Sections',
  workloadEmbeddedFilterDialogTitle: 'Filters',
  workloadEmbeddedFilterDisplayedSectionsFitlerTitle: 'Displayed Sections',

  accountListTitle: englishAccountListTitle,
  createOrEditAccount: englishCreateOrEditAccount,
  createOrSaveAccount: englishCreateOrSaveAccount
};

export const FrenchAccountViewsStrings: AccountViewsStrings = {
  importIdentifierRequiredMessage: `Vous devez préciser un 'ID d’importation'`,
  cancel: 'Annuler',
  resetFilter: 'Réinitialiser',
  close: 'Fermer',
  save: 'Enregistrer',
  clearFilter: 'Effacer',
  copySelectedSectionsButtonText: 'Copier groupes-matière d’un autre élève',
  copySelectedSectionsTitle: 'Copier des groupes-matière d’un autre élève',
  copySelectedSectionsTooltip: 'Copier les groupes-matière',
  editParentChildrenTitle: 'Modifier les enfants',
  loadingRecordsErrorMessage: 'Une erreur est survenue au chargement des fiches.',
  loadingRecordsMessage: 'Chargement des fiches. Merci de patienter…',
  studentFiltersDialogTitle: 'Filtres',
  studentBehaviourFilterTitle: 'Comportements',
  teacherDetailsFilterDialogTitle: 'Filtres',
  teacherDetailsFilterDisplayedSectionsFitlerTitle: 'Groupes-matière affichés',
  teacherDashboardFilterDialogTitle: 'Filtres',
  teacherDashboardFilterDisplayedSectionsFitlerTitle: 'Groupes-matière affichés',
  workloadEmbeddedFilterDialogTitle: 'Filtres',
  workloadEmbeddedFilterDisplayedSectionsFitlerTitle: 'Groupes-matière affichés',

  accountListTitle: frenchAccountListTitle,
  createOrEditAccount: frenchCreateOrEditAccount,
  createOrSaveAccount: frenchCreateOrSaveAccount
};

function englishAccountListTitle(role: ManageableRole) {
  switch (role) {
    case 'parent':
      return 'Parents';
    case 'student':
      return 'Students';
    case 'teacher':
      return 'Teachers';
    case 'staff':
      return 'Staff';
  }
}

function frenchAccountListTitle(role: ManageableRole) {
  switch (role) {
    case 'parent':
      return 'Parents';
    case 'student':
      return 'Élèves';
    case 'teacher':
      return 'Enseignants';
    case 'staff':
      return 'Staff';
  }
}

function englishCreateOrEditAccount(accountIdLength: number) {
  return accountIdLength === 0 ? 'Create Record' : 'Edit Record';
}

function frenchCreateOrEditAccount(accountIdLength: number) {
  return accountIdLength === 0 ? 'Créer une fiche' : 'Modifier la fiche';
}

function englishCreateOrSaveAccount(accountIdLength: number) {
  return accountIdLength === 0 ? 'Create' : 'Save';
}

function frenchCreateOrSaveAccount(accountIdLength: number) {
  return accountIdLength === 0 ? 'Créer' : 'Enregistrer';
}
