import { AlertService } from '@insights/services';
import { EditableExternalFileSource, ExternalFileSource } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface ExternalFileSourceEditionViewModel {
  readonly editableSource: EditableExternalFileSource;
  readonly isNew: boolean;

  readonly hasPasswordPlaceholder: boolean;
  readonly canApply: boolean;
  readonly isApplying: boolean;

  apply(): Promise<void>;
  cancel(): void;
}

export class AppExternalFileSourceEditionViewModel implements ExternalFileSourceEditionViewModel {
  @observable private _isApplying = false;

  constructor(
    private readonly _importerStore: ImporterStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    readonly editableSource: EditableExternalFileSource,
    private readonly _onSuccess: (source: ExternalFileSource) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  get isNew() {
    return this.editableSource.shouldBeCreated;
  }

  @computed
  get hasPasswordPlaceholder() {
    return this.editableSource.password === '********';
  }

  @computed
  get canApply() {
    return (
      this.editableSource.hasChanges &&
      !this.hasPasswordPlaceholder &&
      this.editableSource.baseUrl.length > 0 &&
      this.editableSource.username.length > 0 &&
      this.editableSource.password.length > 0
    );
  }

  @computed
  get isApplying() {
    return this._isApplying;
  }

  @action
  async apply(): Promise<void> {
    this._isApplying = true;

    try {
      const source = await this._importerStore.createOrUpdateExternalFileSource(this.editableSource);
      this._onSuccess(source);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.settings;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  cancel() {
    this._onCancel();
  }
}
