import { AutomatedImport, EditableAutomatedImport } from '@shared/models/import';
import { ImporterStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface AutomatedImportCreateOrEditDialogViewModel {
  readonly automatedImport: EditableAutomatedImport;

  readonly canApply: boolean;
  readonly isExecuting: boolean;
  readonly hasError: boolean;

  apply(): Promise<void>;
  cancel(): void;
}

export class AppAutomatedImportCreateOrEditDialogViewModel implements AutomatedImportCreateOrEditDialogViewModel {
  public readonly automatedImport: EditableAutomatedImport;
  @observable private _isExecuting = false;
  @observable private _hasError = false;

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _onSuccess: (value: AutomatedImport) => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _importSessionId: string,
    existing?: AutomatedImport
  ) {
    makeObservable(this);

    this.automatedImport =
      existing == null
        ? EditableAutomatedImport.createNew(_configId, _importSessionId)
        : new EditableAutomatedImport(existing);
  }

  @computed
  get canApply() {
    // We allow no days, as a way to temporarily disable it without deleting it.
    return this.automatedImport.name.length > 0;
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @computed
  get hasError() {
    return this._hasError;
  }

  @action
  async apply() {
    this._isExecuting = true;
    this._hasError = false;

    try {
      const result = await this._importSessionStore.createOrUpdateAutomatedImport(
        this._configId,
        this._importSessionId,
        this.automatedImport
      );

      this._onSuccess(result);
    } catch (error) {
      console.error(error);

      runInAction(() => {
        this._isExecuting = false;
        this._hasError = true;
      });
    }
  }

  cancel() {
    this._onCancel();
  }
}
