import { ImportDataResponse } from '@buf/studyo_studyo.bufbuild_es/studyo/services/importer_pb';
import { Memoize } from 'fast-typescript-memoize';
import { BaseModel } from '../../Model';
import { ImportData, Incident, SourceRow } from '../interfaces';
import { GrpcIncident } from './GrpcIncident';
import { GrpcSourceRow } from './GrpcSourceRow';

export class GrpcImportData extends BaseModel<ImportDataResponse> implements ImportData {
  constructor(pb: ImportDataResponse) {
    super(pb);
  }

  get isSuccessful(): boolean {
    return this._pb.isSuccessful;
  }

  get isVerificationOnly(): boolean {
    return this._pb.isVerificationOnly;
  }

  @Memoize()
  get addedEntities(): SourceRow[] {
    return this._pb.addedEntities.map((s) => new GrpcSourceRow(s));
  }

  @Memoize()
  get replacedEntities(): SourceRow[] {
    return this._pb.replacedEntities.map((s) => new GrpcSourceRow(s));
  }

  @Memoize()
  get removedEntities(): SourceRow[] {
    return this._pb.removedEntities.map((s) => new GrpcSourceRow(s));
  }

  @Memoize()
  get skippedEntities(): SourceRow[] {
    return this._pb.skippedEntities.map((s) => new GrpcSourceRow(s));
  }

  @Memoize()
  get incidents(): Incident[] {
    return this._pb.incidents.map((i) => new GrpcIncident(i));
  }
}
