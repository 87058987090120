import { css } from '@emotion/css';
import { AuthorizationRoleCondition, Label, RouterLink } from '@insights/components';
import { RouteParamNames, RouteTemplates } from '@insights/Routes';
import { SchoolYearConfigurationScreenInfo } from '@insights/viewmodels';
import { Box, Button, Chip, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { orange } from '@mui/material/colors';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { Locale } from '@shared/resources/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SchoolYearConfigurationInformationProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  data: SchoolYearConfigurationScreenInfo;
  onEditIndividualAccount?: (accountId: string) => Promise<void>;
}

export const SchoolYearConfigurationInformation = observer((props: SchoolYearConfigurationInformationProps) => {
  const { localizationService, reactRouterRouteService } = useInsightsServices();
  const { className, sx = [], style, data, onEditIndividualAccount } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;
  const theme = useTheme();

  const displayTitle = SchoolYearConfigurationUtils.displayTitle(data.schoolYearConfiguration);
  const stateLabel = strings.getConfigStateLabel(data.schoolYearConfiguration.state);
  // Only used when isDemo is true, no need to be empty otherwise.
  const demoLabel = strings.getDemoSchoolLabel(data.schoolYearConfiguration.demoDay);

  const tagClassname = css({
    '& .MuiChip-label': {
      fontSize: theme.typography.caption.fontSize
    }
  });

  return (
    <Stack sx={sx} className={className} style={style} spacing={2}>
      {/* School name */}
      <Stack direction="row" alignItems="flex-end">
        <Box
          sx={{
            flex: 1
          }}
        >
          <Label>{strings.schoolNameFieldLabel}</Label>
          <Typography>{data.schoolYearConfiguration.schoolName}</Typography>
        </Box>

        {data.schoolYearConfiguration.isDemo && (
          <AuthorizationRoleCondition allowedRoles={['super-admin']}>
            <Chip variant="outlined" color="info" label={demoLabel} />
          </AuthorizationRoleCondition>
        )}
      </Stack>
      {/* School title */}
      {Boolean(displayTitle) && (
        <Stack>
          <Label>{strings.schoolTitleFieldLabel}</Label>
          <Typography>{displayTitle}</Typography>
        </Stack>
      )}
      {/* State */}
      <Stack sx={data.schoolYearConfiguration.state === 'active' ? undefined : { backgroundColor: orange[100] }}>
        <Label>{strings.stateFieldLabel}</Label>
        <Typography>{stateLabel}</Typography>
      </Stack>
      {/* Comments */}
      {Boolean(data.schoolYearConfiguration.comments) && (
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <Stack>
            <Label>{strings.commentsFieldLabel}</Label>
            <Typography>{data.schoolYearConfiguration.comments}</Typography>
          </Stack>
        </AuthorizationRoleCondition>
      )}
      {/* Expected number of students */}
      {data.schoolYearConfiguration.expectedStudentCount > 0 && (
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <Stack>
            <Label>{strings.expectedStudentCountFieldLabel}</Label>
            <Typography>{data.schoolYearConfiguration.expectedStudentCount}</Typography>
          </Stack>
        </AuthorizationRoleCondition>
      )}
      {/* Onboarding code */}
      {Boolean(data.schoolYearConfiguration.managedOnboardCode) && (
        <Stack direction="row">
          <Stack flex={1}>
            <Label>{strings.onboardingCodeFieldLabel}</Label>
            <Typography>{data.schoolYearConfiguration.managedOnboardCode}</Typography>
          </Stack>
          {data.schoolYearConfiguration.onboardingCodeEmailDomain.length > 0 && (
            <Stack flex={1}>
              <Label>{strings.onboardingCodeEmailDomainLabel}</Label>
              <Typography>{data.schoolYearConfiguration.onboardingCodeEmailDomain}</Typography>
            </Stack>
          )}
        </Stack>
      )}
      {/* Timezone */}
      {Boolean(data.schoolYearConfiguration.timezone) && (
        <Stack>
          <Label>{strings.timezoneLabel}</Label>
          <Typography>{data.schoolYearConfiguration.timezone}</Typography>
        </Stack>
      )}
      {/* Language */}
      <Stack>
        <Label>{strings.languageLabel}</Label>
        <Typography>{strings.getLocaleDisplayName(data.schoolYearConfiguration.language as Locale)}</Typography>
      </Stack>
      {/* Previous school year */}
      {data.schoolYearConfiguration.previousConfigurationId.length > 0 && (
        <RouterLink
          variant="body1"
          to={reactRouterRouteService.resolveLocation(RouteTemplates.manageGeneral, [
            {
              name: RouteParamNames.configId,
              value: data.schoolYearConfiguration.previousConfigurationId
            }
          ])}
        >
          {strings.previousSchoolYearLink}
        </RouterLink>
      )}
      {/* Tags */}
      <AuthorizationRoleCondition allowedRoles={['super-admin']}>
        {data.schoolYearConfiguration.tags.length > 0 && (
          <Stack direction="row" spacing={1}>
            {data.schoolYearConfiguration.tags.map((tag, i) => (
              <Chip key={`tag-${i}`} className={tagClassname} variant="outlined" color="info" label={tag} />
            ))}
          </Stack>
        )}
      </AuthorizationRoleCondition>
      {/* Individual account */}
      {data.individualAccount != null && (
        <Button
          sx={{ mt: 2 }}
          variant="outlined"
          onClick={() => void onEditIndividualAccount?.(data.individualAccount!.id)}
        >
          {strings.editIndividualAccount}
        </Button>
      )}
    </Stack>
  );
});
