import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  TextField,
  ThemeProvider
} from '@mui/material';
import { ExternalAccount } from '@shared/models/connectors';
import { Time } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface EditThrottleSettingsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  externalAccount: ExternalAccount;
}

export const EditThrottleSettingsDialog = observer((props: EditThrottleSettingsDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], externalAccount, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createEditThrottleSettings(externalAccount, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog sx={sx} open={true} onClose={() => void viewModel.cancel()} maxWidth="xs" fullWidth={true}>
        <DialogTitle>{strings.throttleSettings}</DialogTitle>

        <DialogContent dividers>
          <Box display="flex" flexDirection="column">
            <TextField
              type="number"
              sx={{ mt: 1 }}
              label={strings.skippedSyncCycles}
              value={viewModel.skippedSyncCycleCount}
              onChange={(event) => {
                const count = Number(event.target.value);
                if (!Number.isNaN(count)) {
                  viewModel.skippedSyncCycleCount = count;
                }
              }}
              helperText={strings.skippedSyncCyclesHelperText(viewModel.skippedSyncCycleCount)}
            />
            <TextField
              type="number"
              sx={{ mt: 1 }}
              label={strings.millisecondsBetweenSyncsLabel}
              value={viewModel.millisecondsBetweenSyncs}
              onChange={(event) => {
                const count = Number(event.target.value);
                if (!Number.isNaN(count)) {
                  viewModel.millisecondsBetweenSyncs = count;
                }
              }}
            />
            <TextField
              type="time"
              sx={{ mt: 1 }}
              label={strings.syncPauseTime}
              value={viewModel.syncPauseTime?.asString}
              onChange={(event) => (viewModel.syncPauseTime = Time.fromString(event.target.value))}
              slotProps={{ input: { inputProps: { step: 300 } } }}
            />
            <TextField
              type="time"
              sx={{ mt: 1 }}
              label={strings.syncResumeTime}
              value={viewModel.syncResumeTime?.asString}
              onChange={(event) => (viewModel.syncResumeTime = Time.fromString(event.target.value))}
              slotProps={{ input: { inputProps: { step: 300 } } }}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
            {strings.cancelButton}
          </InsightsButton>
          <InsightsButton
            isSubmit
            showSpinnerOnExecuting
            isExecuting={viewModel.isSubmitting}
            onClick={() => void viewModel.save()}
          >
            {strings.saveButton}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
