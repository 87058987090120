import { NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppVeracrossV3AccountViewModel, VeracrossV3AccountViewModel } from './VeracrossV3AccountViewModel';

export interface VeracrossV3AccountDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly account: IPromiseBasedObservable<VeracrossV3AccountViewModel>;
}

export class AppVeracrossV3AccountDialogViewModel implements VeracrossV3AccountDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get account(): IPromiseBasedObservable<VeracrossV3AccountViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<VeracrossV3AccountViewModel> {
    if (this.externalAccountId === 'new') {
      return new AppVeracrossV3AccountViewModel(
        this._localizationService,
        this._connectorsStore.veracrossV3,
        this._onSuccess,
        this._onCancel,
        this.configId,
        '',
        'Veracross v3',
        '',
        '',
        '',
        new Date().getFullYear(),
        ''
      );
    }

    const [account, details] = await Promise.all([
      this._connectorsStore.getExternalAccount(this.configId, this.externalAccountId),
      this._connectorsStore.veracrossV3.getVeracrossAccountDetails(this.externalAccountId)
    ]);

    return new AppVeracrossV3AccountViewModel(
      this._localizationService,
      this._connectorsStore.veracrossV3,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      account.name,
      details.clientId,
      details.clientSecret,
      details.schoolRoute,
      details.schoolyear,
      details.portalUrl
    );
  }
}
