import { AccountService, NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppGoogleAccountViewModel, GoogleAccountViewModel } from './GoogleAccountViewModel';

export interface GoogleAccountDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly account: IPromiseBasedObservable<GoogleAccountViewModel>;
}

export class AppGoogleAccountDialogViewModel implements GoogleAccountDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get account(): IPromiseBasedObservable<GoogleAccountViewModel> {
    return fromPromise(this.loadData());
  }

  private get isNewAccount() {
    return this.externalAccountId === 'new';
  }

  private async loadData(): Promise<GoogleAccountViewModel> {
    if (this.isNewAccount) {
      const accounts = await this._connectorsStore.getExternalAccounts(this.configId);
      const existingAccount = accounts.find(
        (a) => a.kind === 'google' && a.email.length > 0 && a.email == this._accountService.userEmail
      );

      return new AppGoogleAccountViewModel(
        this._localizationService,
        this._connectorsStore.classroom,
        () => this.navigateToNextStep(),
        this._onCancel,
        this.configId,
        '',
        this._accountService.getAccountIdForConfigRole(this.configId, 'teacher') ?? '',
        undefined,
        existingAccount
      );
    }

    const [account, details] = await Promise.all([
      this._connectorsStore.getExternalAccount(this.configId, this.externalAccountId),
      this._connectorsStore.classroom.getGoogleAccountDetails(this.externalAccountId)
    ]);

    return new AppGoogleAccountViewModel(
      this._localizationService,
      this._connectorsStore.classroom,
      () => this.navigateToNextStep(),
      this._onCancel,
      this.configId,
      this.externalAccountId,
      account.accountId,
      details.isConnectedSuccessfully
    );
  }

  private async navigateToNextStep() {
    this._onSuccess();
    // No more next step.
    return Promise.resolve();
  }
}
