import { SectionsViewModel } from '@insights/viewmodels';
import { AccountModel } from '@shared/models/config';
import { OQProfile } from '@shared/models/metrics';
import { OQValue } from '@shared/models/types';
import { MaterialTableData } from './MaterialTableData';

// For the moment, this migration away from "info" models to view-models will be gradual.
// It's important that we do not import from root folders above, as view-models also reference
// local models.
export interface EditableAccountInfo extends MaterialTableData {
  account: AccountModel;
  sections: SectionsViewModel;
  parents: AccountModel[];
  children: AccountModel[];
  pendingChildren: AccountModel[];
  isReadOnly?: boolean;
}

export interface AccountInfo extends MaterialTableData {
  account: AccountModel;
  sections?: SectionsViewModel;
  oqProfile?: OQProfile;
  invitesParent?: OQValue;
}

export function accountInfoFromModel(account: AccountModel): AccountInfo {
  return { id: account.id, account };
}
