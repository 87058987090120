import { SectionEditionViewModel } from '@insights/viewmodels';
import { Autocomplete, SxProps, TextField, useTheme } from '@mui/material';
import { AllSectionScheduleDayCase, EditableSectionSchedule } from '@shared/models/config';
import { AllDayOfWeek, Day } from '@shared/models/types';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Row } from '../layout';
import { FieldNavigationManager } from '../utils';

export interface EditableSectionScheduleDayProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SectionEditionViewModel;
  schedule: EditableSectionSchedule;
}

export const EditableSectionScheduleDay = observer((props: EditableSectionScheduleDayProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel, schedule } = props;
  const strings = localizationService.localizedStrings.insights.components.sections;
  const dayOfWeekStrings = localizationService.localizedStrings.models.dayOfWeek;
  const invalidFields = schedule.invalidFields;
  const cycleDayOptions = _.range(1, viewModel.daysPerCycle + 1).map((cycleDay) => ({
    label: cycleDay.toString(),
    value: cycleDay
  }));
  const theme = useTheme();

  return (
    <Row sx={sx} className={className} style={style} verticalContentAlignment="bottom">
      <FieldNavigationManager
        primaryControl={(p) => (
          <Autocomplete
            sx={{ mr: 1, flex: 1 }}
            fullWidth
            disableClearable
            value={{ value: schedule.dayCase, label: strings.dayCase(schedule.dayCase) }}
            options={AllSectionScheduleDayCase.map((dayCase) => ({
              label: strings.dayCase(dayCase),
              value: dayCase
            }))}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(_, value) => (schedule.dayCase = value?.value ?? 'cycle-day')}
            renderInput={(params) => (
              <TextField {...params} {...p} sx={{ '& .MuiInputBase-input': { ...theme.typography.body2 } }} />
            )}
          />
        )}
        secondaryControl={(p) => (
          <>
            {schedule.dayCase === 'cycle-day' && (
              <Autocomplete
                sx={{ flex: 1 }}
                fullWidth
                disableClearable
                value={{ value: schedule.cycleDay, label: schedule.cycleDay?.toString() ?? strings.none }}
                options={cycleDayOptions}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(_, value) => (schedule.cycleDay = value?.value)}
                onInputChange={(_, value) => {
                  const match = cycleDayOptions.find((o) => o.label === value);

                  if (match != null) {
                    schedule.cycleDay = match.value;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...p}
                    sx={{ '& .MuiInputBase-input': { ...theme.typography.body2 } }}
                    error={invalidFields.includes('cycle-day')}
                  />
                )}
              />
            )}
            {schedule.dayCase === 'day-of-week' && (
              <Autocomplete
                sx={{ flex: 1 }}
                fullWidth
                disableClearable
                value={{
                  value: schedule.dayOfWeek,
                  label:
                    schedule.dayOfWeek == null ? strings.none : dayOfWeekStrings.localizedDayOfWeek(schedule.dayOfWeek)
                }}
                options={AllDayOfWeek.map((dow) => ({
                  label: dayOfWeekStrings.localizedDayOfWeek(dow),
                  value: dow
                }))}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(_, value) => (schedule.dayOfWeek = value?.value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...p}
                    sx={{ '& .MuiInputBase-input': { ...theme.typography.body2 } }}
                    error={invalidFields.includes('day-of-week')}
                  />
                )}
              />
            )}
            {schedule.dayCase === 'specific-day' && (
              <TextField
                sx={{ flex: 1 }}
                fullWidth
                type="date"
                required
                label=""
                value={schedule.day!.asDateString}
                slotProps={{ input: { sx: { ...theme.typography.body2 } } }}
                onChange={(event) => {
                  const day = Day.fromDateString(event.target.value);
                  if (day != null) {
                    schedule.day = day;
                  }
                }}
                error={invalidFields.includes('day')}
                {...p}
              />
            )}
          </>
        )}
      />
    </Row>
  );
});
