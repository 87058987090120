import { EntityCountTolerance as PBEntityCountTolerance } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { EditableModelEx, EditableValuePropertyEx } from '@shared/models/editables';
import { EntityListKind } from '@shared/models/types';
import { protobufFromEntityListKind } from '@shared/models/types/EnumConversion';
import { computed, makeObservable } from 'mobx';
import { EntityCountTolerance } from '../interfaces';
import { GrpcEntityCountTolerance } from './GrpcAutomatedImport';

export class EditableEntityCountTolerance
  extends EditableModelEx<PBEntityCountTolerance>
  implements EntityCountTolerance
{
  private readonly _listKind: EditableValuePropertyEx<EntityListKind, PBEntityCountTolerance>;
  private readonly _minimumCount: EditableValuePropertyEx<number, PBEntityCountTolerance>;
  private readonly _maximumCount: EditableValuePropertyEx<number, PBEntityCountTolerance>;

  static createNew(listKind: EntityListKind) {
    const pb = new PBEntityCountTolerance();
    pb.listKind = protobufFromEntityListKind(listKind);
    pb.maximumCount = 99999;

    return new EditableEntityCountTolerance(new GrpcEntityCountTolerance(pb), true);
  }

  constructor(originalTolerance: EntityCountTolerance, isNew?: boolean) {
    super(originalTolerance.toProtobuf(), isNew);

    makeObservable(this);

    this._listKind = this.addValueField(
      originalTolerance.listKind,
      (pb, value) => (pb.listKind = protobufFromEntityListKind(value))
    );
    this._minimumCount = this.addValueField(originalTolerance.minimumCount, (pb, value) => (pb.minimumCount = value));
    this._maximumCount = this.addValueField(originalTolerance.maximumCount, (pb, value) => (pb.maximumCount = value));
  }

  @computed
  get listKind() {
    return this._listKind.value;
  }

  set listKind(value: EntityListKind) {
    this._listKind.value = value;
  }

  @computed
  get minimumCount() {
    return this._minimumCount.value;
  }

  set minimumCount(value: number) {
    this._minimumCount.value = value;
  }

  @computed
  get maximumCount() {
    return this._maximumCount.value;
  }

  set maximumCount(value: number) {
    this._maximumCount.value = value;
  }
}
