import { Locale } from '@shared/resources/services';

export function caseInsensitiveAccentInsensitiveCompare(a: string, b: string, locale?: Locale, numeric?: boolean) {
  return a.localeCompare(b, locale, { numeric: numeric === true, sensitivity: 'base' });
}

export function asIs(strings: TemplateStringsArray) {
  return strings.raw[0];
}

export function cleanDiacritics(value: string): string {
  return value.normalize('NFD').replace(/\p{Diacritic}/gu, '');
}
