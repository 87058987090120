import { InsightsImages, OrganizationalBehavioursImages } from '@shared/resources/images/insights';
import logoDark from '@shared/web/images/assets/insights/ui_logo_dark.svg';
import logoLight from '@shared/web/images/assets/insights/ui_logo_light.svg';
import { WebOrganizationalBehavioursImages } from './WebOrganizationalBehavioursImages';

export class WebInsightsImages implements InsightsImages {
  private readonly _organizationalBehaviours = new WebOrganizationalBehavioursImages();

  get organizationalBehaviours(): OrganizationalBehavioursImages {
    return this._organizationalBehaviours;
  }

  get logoDark() {
    return logoDark;
  }

  get logoLight() {
    return logoLight;
  }
}
