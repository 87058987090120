import * as T from '@buf/studyo_studyo.bufbuild_es/studyo/services/tools_pb';
import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_tools_pb';
import { Tools } from '@buf/studyo_studyo.connectrpc_es/studyo/services/tools_connect';
import {
  AccountsHandlingOptions,
  ContentsHandlingOptions,
  SectionsHandlingOptions,
  ToolsTransport
} from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class ToolsGrpcTransport extends BaseGrpcTransport implements ToolsTransport {
  async copyConfiguration(
    configId: string,
    accountsHandling: AccountsHandlingOptions,
    sectionsHandling: SectionsHandlingOptions,
    contentsHandling: ContentsHandlingOptions,
    newName: string,
    languageCode: string
  ): Promise<string> {
    const pbAccounts = new T.AccountsHandling();
    pbAccounts.baseAccountEmail = accountsHandling.baseAccountEmail ?? '';
    pbAccounts.shouldAnonymizeAccounts = accountsHandling.shouldAnonymize ?? false;
    pbAccounts.shouldDetachAccounts = accountsHandling.shouldDetach ?? false;
    pbAccounts.shouldGenerateAccounts = accountsHandling.shouldGenerate ?? false;
    pbAccounts.shouldKeepAccounts = accountsHandling.shouldKeep ?? false;

    const pbSections = new T.SectionsHandling();
    pbSections.periodTags = sectionsHandling.periodTags ?? [];
    pbSections.shouldAssignSections = sectionsHandling.shouldAssign ?? false;
    pbSections.shouldDetachSections = sectionsHandling.shouldDetach ?? false;
    pbSections.shouldGenerateSections = sectionsHandling.shouldGenerate ?? false;
    pbSections.shouldKeepSections = sectionsHandling.shouldKeep ?? false;

    const pbContents = new T.ContentsHandling();
    pbContents.schoolDaysOffset = contentsHandling.schoolDaysOffset ?? 0;
    pbContents.shouldAnonymizeContents = contentsHandling.shouldAnonymize ?? false;
    pbContents.shouldGenerateContents = contentsHandling.shouldGenerate ?? false;
    pbContents.shouldKeepContents = contentsHandling.shouldKeep ?? false;

    const request = new T.CopyConfigurationRequest();
    request.configId = configId;
    request.accounts = pbAccounts;
    request.sections = pbSections;
    request.contents = pbContents;
    request.newSchoolName = newName;
    request.language = languageCode;

    const response = await this.performRequest(Tools, Tools.methods.copyConfiguration, request, {
      shouldRetryWithFreshAccessToken: false
    });

    return response.newConfigId;
  }

  async createNewConfiguration(
    previousConfigId?: string,
    configOptions?: PB.ConfigOptions,
    calendarOptions?: PB.CalendarOptions,
    sectionOptions?: PB.SectionOptions,
    accountOptions?: PB.AccountOptions,
    importSessionOptions?: PB.ImportSessionOptions,
    onboardingOptions?: PB.OnboardingOptions
  ): Promise<T.CreateNewConfigurationResponse> {
    const request = new T.CreateNewConfigurationRequest({
      previousConfigId,
      configOptions,
      calendarOptions,
      sectionOptions,
      accountOptions,
      importSessionOptions,
      onboardingOptions
    });

    const response = await this.performRequest(Tools, Tools.methods.createNewConfiguration, request, {
      shouldRetryWithFreshAccessToken: false
    });

    return response;
  }
}
