import { AlertService } from '@insights/services';
import { EditableOnboardingQuestion } from '@shared/models/onboarding/implementations';
import { OnboardingQuestion, OnboardingStep } from '@shared/models/onboarding/interfaces';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { DependableQuestions } from './DependableQuestions';

export interface OnboardingQuestionEditionViewModel {
  readonly editableQuestion: EditableOnboardingQuestion;
  readonly dependableQuestionNamesInStep: string[];
  readonly otherDependableQuestionNames: DependableQuestions[];
  readonly canSave: boolean;

  uploadFile(dataUrl: string, fileName?: string): Promise<void>;
}

export interface OnboardingQuestionEditionDialogViewModel {
  readonly data: IPromiseBasedObservable<OnboardingQuestionEditionViewModel>;
  readonly canSave: boolean;

  save(alsoUpdateTemplate: boolean): Promise<void>;
  cancel(): void;
}

export class AppOnboardingQuestionEditionViewModel implements OnboardingQuestionEditionViewModel {
  constructor(
    private readonly _onboardingStore: OnboardingStore,
    readonly editableQuestion: EditableOnboardingQuestion,
    readonly dependableQuestionNamesInStep: string[],
    readonly otherDependableQuestionNames: DependableQuestions[]
  ) {
    makeObservable(this);
  }

  readonly canSave = true;

  async uploadFile(dataUrl: string, fileName?: string): Promise<void> {
    const fileUrl = await this._onboardingStore.uploadResourceFile(dataUrl, this.editableQuestion.configId, fileName);
    await navigator.clipboard.writeText(fileUrl);
  }
}

export class AppOnboardingQuestionEditionDialogViewModel implements OnboardingQuestionEditionDialogViewModel {
  @observable private _innerViewModel?: OnboardingQuestionEditionViewModel;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _question: OnboardingQuestion,
    private readonly _processName: string,
    private readonly _stepName: string,
    private readonly _onSuccess: (step?: OnboardingStep) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<OnboardingQuestionEditionViewModel> {
    return fromPromise(this.loadData());
  }

  @computed
  get canSave(): boolean {
    return this._innerViewModel?.canSave ?? false;
  }

  async save(alsoUpdateTemplate: boolean) {
    if (this._innerViewModel == null) {
      return;
    }

    try {
      const editableQuestion = this._innerViewModel.editableQuestion;

      const step = await this._onboardingStore.updateQuestion(
        editableQuestion.templateName,
        editableQuestion.configId,
        this._stepName,
        editableQuestion.description,
        editableQuestion.kind,
        editableQuestion.choices,
        editableQuestion.isRequired,
        editableQuestion.dependantQuestionName,
        editableQuestion.dependantQuestionAnswer,
        editableQuestion.isHiddenWhenDependant,
        alsoUpdateTemplate
      );
      this._onSuccess(step);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    }
  }

  cancel() {
    this._onCancel();
  }

  private async loadData(): Promise<OnboardingQuestionEditionViewModel> {
    const process = await this._onboardingStore.getProcess(this._processName, this._question.configId);

    const thisStepQuestionNames = process.steps.find((s) => s.templateName === this._stepName)?.questionNames ?? [];
    const otherStepsQuestionNames = process.steps
      .filter((s) => s.templateName !== this._stepName)
      .map((s) => ({
        stepName: s.templateName,
        questionNames: s.questionNames
      }));

    const inner = new AppOnboardingQuestionEditionViewModel(
      this._onboardingStore,
      new EditableOnboardingQuestion(this._question),
      thisStepQuestionNames,
      otherStepsQuestionNames
    );

    return runInAction(() => (this._innerViewModel = inner));
  }
}
