import { AccountService, NavigationService } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { OnboardingStepSummary, OnboardingText } from '@shared/models/onboarding/interfaces';
import { OnboardingParticipantKind, OnboardingStatus } from '@shared/models/types';
import { compact } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';
import { OnboardingProcessViewModel } from './OnboardingProcessViewModel';
import { DefaultOnboardingVariableResolver, OnboardingVariableResolver } from './OnboardingVariableResolver';

export interface OnboardingStepSummaryViewModel {
  readonly configId: string;
  readonly processName: string;

  readonly id: string;
  readonly stepId: string;
  readonly templateName: string;
  readonly status: OnboardingStatus;
  readonly targetDate: Date | undefined;
  readonly title: OnboardingText[];
  readonly participants: OnboardingParticipantKind;
  readonly isLocked: boolean;
  readonly dependantQuestionName: string;
  readonly dependantQuestionAnswer: string;
  readonly isBlocked: boolean;

  readonly agent: AccountModel | undefined;
  readonly client: AccountModel | undefined;
  readonly followers: AccountModel[];

  readonly canNavigate: boolean;

  readonly variableResolver: OnboardingVariableResolver;

  deleteStep(): Promise<void>;
  navigateToStep(navigate: NavigateFunction): Promise<void>;
}

export class AppOnboardingStepSummaryViewModel implements OnboardingStepSummaryViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _configId: string,
    private readonly _process: OnboardingProcessViewModel,
    private readonly _step: OnboardingStepSummary,
    private readonly _accountsById: Record<string, AccountModel>
  ) {
    makeObservable(this);
  }

  get configId() {
    return this._configId;
  }

  get processName() {
    return this._process.templateName;
  }

  get id() {
    return this.templateName;
  }

  get stepId() {
    return this._step.id;
  }

  get templateName() {
    return this._step.templateName;
  }

  get status() {
    return this._step.status;
  }

  get targetDate() {
    return this._step.targetDate;
  }

  get title() {
    return this._step.title;
  }

  get participants() {
    return this._step.participants;
  }

  get isLocked() {
    return this._step.isDependantLocked;
  }

  get dependantQuestionName() {
    return this._step.dependantQuestionName;
  }

  get dependantQuestionAnswer() {
    return this._step.dependantQuestionAnswer;
  }

  get isBlocked() {
    return this._step.isBlocked;
  }

  get canNavigate() {
    return (
      this._accountService.isAllowed(['super-admin']) ||
      this._accountService.isAccount(
        compact<string>([this._step.agentId, this._step.clientId, this._process.agent?.id])
      ) ||
      (this._step.participants !== 'studyo-only' && this._accountService.isAllowed(['admin']))
    );
  }

  get agent() {
    return this.participants !== 'client-only' ? this._accountsById[this._step.agentId] : undefined;
  }

  get client() {
    return this.participants !== 'studyo-only' ? this._accountsById[this._step.clientId] : undefined;
  }

  get followers(): AccountModel[] {
    return this._step.followerIds.map((id) => this._accountsById[id]);
  }

  @computed
  get variableResolver() {
    return new DefaultOnboardingVariableResolver(this._configId);
  }

  deleteStep(): Promise<void> {
    return this._process.deleteStep(this);
  }

  async navigateToStep(navigate: NavigateFunction): Promise<void> {
    await this._navigationService.navigateToOnboardingStep(
      this._configId,
      this._process.templateName,
      this._step.templateName,
      navigate
    );
  }
}
