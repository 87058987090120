export interface CreateSchoolStrings {
  readonly accountOptions: string;
  readonly calendarOptions: string;
  readonly generalOptions: string;
  readonly importOptions: string;
  readonly issuesWarning: string;
  readonly onboardingOptions: string;
  readonly sectionOptions: string;
  readonly trainingSectionTitle: string;
  readonly unexpectedErrorMessage: string;
  readonly unexpectedErrorTitle: string;
  readonly unexpectedResponseMessage: string;
  readonly unexpectedResponseTitle: string;
}

export const EnglishCreateSchoolStrings: CreateSchoolStrings = {
  accountOptions: 'Accounts',
  calendarOptions: 'Calendar',
  generalOptions: 'General',
  importOptions: 'Import Recipes',
  issuesWarning: 'Issues found in these sections: ',
  onboardingOptions: 'Onboarding',
  sectionOptions: 'Sections',
  trainingSectionTitle: 'Studyo Training',
  unexpectedErrorMessage: 'An unexpected error occurred:\n',
  unexpectedErrorTitle: 'Error',
  unexpectedResponseMessage:
    'An unexpected response was returned by the server. The call succeeded but no new configuration was provided.',
  unexpectedResponseTitle: 'Error'
};

export const FrenchCreateSchoolStrings: CreateSchoolStrings = {
  accountOptions: 'fiches',
  calendarOptions: 'calendrier',
  generalOptions: 'général',
  importOptions: 'recettes d’importation',
  issuesWarning: 'Problèmes dans ces sections: ',
  onboardingOptions: 'embarquement',
  sectionOptions: 'groupes-matière',
  trainingSectionTitle: 'Formation Studyo',
  unexpectedErrorMessage: 'Une erreur inattendue est survenue:\n',
  unexpectedErrorTitle: 'Erreur',
  unexpectedResponseMessage:
    'Une réponse inattendue fut retournée par le serveur. L’appel a réussi mais aucune nouvelle configuration ne fut fournie.',
  unexpectedResponseTitle: 'Erreur'
};
