import { NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { BlackbaudConnectorStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  AppBlackbaudAccountSettingsViewModel,
  BlackbaudAccountSettingsViewModel
} from './BlackbaudAccountSettingsViewModel';

export interface BlackbaudAccountSettingsDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly settings: IPromiseBasedObservable<BlackbaudAccountSettingsViewModel>;
}

export class AppBlackbaudAccountSettingsDialogViewModel implements BlackbaudAccountSettingsDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _blackbaudStore: BlackbaudConnectorStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get settings(): IPromiseBasedObservable<BlackbaudAccountSettingsViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<BlackbaudAccountSettingsViewModel> {
    const [details, years, levels] = await Promise.all([
      this._blackbaudStore.getBlackbaudAccountDetails(this.externalAccountId),
      this._blackbaudStore.getBlackbaudSchoolYears(this.externalAccountId),
      this._blackbaudStore.getBlackbaudSchoolLevels(this.externalAccountId)
    ]);

    return new AppBlackbaudAccountSettingsViewModel(
      this._localizationService,
      this._blackbaudStore,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      years,
      levels,
      details
    );
  }
}
