import { Localization } from '@material-table/core';

export type MaterialTableStrings = Localization;
export const EnglishMaterialTableStrings: MaterialTableStrings = {
  body: {
    emptyDataSourceMessage: 'No records to display',
    addTooltip: 'Add',
    deleteTooltip: 'Delete',
    editTooltip: 'Edit',
    filterRow: {
      filterTooltip: 'Filter'
    },
    editRow: {
      deleteText: 'Are you sure you want to delete this row?',
      cancelTooltip: 'Cancel',
      saveTooltip: 'Save'
    }
  },
  grouping: {
    placeholder: 'Drag headers…'
  },
  header: {
    actions: 'Actions'
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} of {count}',
    labelRows: 'rows',
    labelRowsPerPage: 'Rows per page:',
    firstAriaLabel: 'First Page',
    firstTooltip: 'First Page',
    previousAriaLabel: 'Previous Page',
    previousTooltip: 'Previous Page',
    nextAriaLabel: 'Next Page',
    nextTooltip: 'Next Page',
    lastAriaLabel: 'Last Page',
    lastTooltip: 'Last Page'
  },
  toolbar: {
    addRemoveColumns: 'Add or remove columns',
    nRowsSelected: '{0} row(s) selected',
    showColumnsTitle: 'Show Columns',
    showColumnsAriaLabel: 'Show Columns',
    exportTitle: 'Export',
    exportAriaLabel: 'Export',
    exportCSVName: 'Export as CSV',
    searchTooltip: 'Search',
    searchPlaceholder: 'Search'
  }
};

export const FrenchMaterialTableStrings: MaterialTableStrings = {
  body: {
    emptyDataSourceMessage: 'Aucun enregistrement',
    addTooltip: 'Ajouter',
    deleteTooltip: 'Effacer',
    editTooltip: 'Éditer',
    filterRow: {
      filterTooltip: 'Filtrer'
    },
    editRow: {
      deleteText: 'Êtes-vous certain de vouloir effacer cette ligne?',
      cancelTooltip: 'Annuler',
      saveTooltip: 'Sauvegarder'
    }
  },
  grouping: {
    placeholder: 'Glisser les entêtes…'
  },
  header: {
    actions: 'Actions'
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRows: 'pages',
    labelRowsPerPage: 'Lignes par page:',
    firstAriaLabel: 'Première page',
    firstTooltip: 'Première page',
    previousAriaLabel: 'Page précédante',
    previousTooltip: 'Page précédante',
    nextAriaLabel: 'Prochaine page',
    nextTooltip: 'Prochaine page',
    lastAriaLabel: 'Dernière page',
    lastTooltip: 'Dernière page'
  },
  toolbar: {
    addRemoveColumns: 'Ajouter ou retirer des colonnes',
    nRowsSelected: '{0} ligne(s) sélectionnée(s)',
    showColumnsTitle: 'Afficher colonnes',
    showColumnsAriaLabel: 'Afficher colonnes',
    exportTitle: 'Exporter',
    exportAriaLabel: 'Exporter',
    exportCSVName: 'Exporter en CSV',
    searchTooltip: 'Rechercher',
    searchPlaceholder: 'Rechercher'
  }
};
