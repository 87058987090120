import { SchoologyAccountViewModel } from '@insights/viewmodels';
import { SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { MinLayoutWidth } from '../../Constants';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';

export interface SchoologyAccountProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoologyAccountViewModel;
}

export const SchoologyAccount = observer((props: SchoologyAccountProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Column className={className} sx={{ ...sx, minWidth: MinLayoutWidth }}>
      <TextField
        sx={{ m: 1 }}
        label={strings.accountName}
        value={viewModel.accountName}
        onChange={(event) => (viewModel.accountName = event.target.value)}
      />
      <TextField
        sx={{ m: 1 }}
        label={strings.serverUrl}
        value={viewModel.serverUrl}
        onChange={(event) => (viewModel.serverUrl = event.target.value)}
      />
      <TextField
        sx={{ m: 1 }}
        label={strings.consumerKey}
        value={viewModel.consumerKey}
        onChange={(event) => (viewModel.consumerKey = event.target.value)}
      />
      <TextField
        sx={{ m: 1 }}
        label={strings.consumerSecret}
        type="password"
        value={viewModel.consumerSecret}
        autoComplete="new-password"
        onChange={(event) => (viewModel.consumerSecret = event.target.value)}
      />
      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
