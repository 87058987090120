import { AttachmentStrings, EnglishAttachmentStrings, FrenchAttachmentStrings } from './AttachmentStrings';
import {
  ContentDistributeStrings,
  EnglishContentDistributeStrings,
  FrenchContentDistributeStrings
} from './ContentDistributeStrings';
import { ContentListsStrings, EnglishContentListsStrings, FrenchContentListsStrings } from './ContentListsStrings';
import {
  ContentPublishStrings,
  EnglishContentPublishStrings,
  FrenchContentPublishStrings
} from './ContentPublishStrings';
import { ContentRepeatStrings, EnglishContentRepeatStrings, FrenchContentRepeatStrings } from './ContentRepeatStrings';
import {
  EnglishLinkedTasksDeleteStrings,
  FrenchLinkedTasksDeleteStrings,
  LinkedTasksDeleteStrings
} from './LinkedTasksDeleteStrings';
import {
  EnglishLinkedTasksPublishStrings,
  FrenchLinkedTasksPublishStrings,
  LinkedTasksPublishStrings
} from './LinkedTasksPublishStrings';
import { EnglishNoteEditStrings, FrenchNoteEditStrings, NoteEditStrings } from './NoteEditStrings';
import { EnglishStepListStrings, FrenchStepListStrings, StepListStrings } from './StepListStrings';
import { EnglishTaskDueBoxStrings, FrenchTaskDueBoxStrings, TaskDueBoxStrings } from './TaskDueBoxStrings';
import { EnglishTaskEditStrings, FrenchTaskEditStrings, TaskEditStrings } from './TaskEditStrings';
import { EnglishTaskInfoStrings, FrenchTaskInfoStrings, TaskInfoStrings } from './TaskInfoStrings';
import { EnglishWorkloadStrings, FrenchWorkloadStrings, WorkloadStrings } from './WorkloadStrings';

export interface ContentsStrings {
  notesTitle: string;
  taskSectionSelectionNone: string;

  readonly attachments: AttachmentStrings;
  readonly contentPublish: ContentPublishStrings;
  readonly contentRepeat: ContentRepeatStrings;
  readonly contentDistribute: ContentDistributeStrings;
  readonly noteEdit: NoteEditStrings;
  readonly stepList: StepListStrings;
  readonly lists: ContentListsStrings;
  readonly linkedTasksDelete: LinkedTasksDeleteStrings;
  readonly linkedTasksPublish: LinkedTasksPublishStrings;
  readonly taskDueBox: TaskDueBoxStrings;
  readonly taskInfo: TaskInfoStrings;
  readonly taskEdit: TaskEditStrings;
  readonly workload: WorkloadStrings;
}

export const EnglishContentsStrings: ContentsStrings = {
  notesTitle: 'Notes',
  taskSectionSelectionNone: 'None',

  attachments: EnglishAttachmentStrings,
  contentPublish: EnglishContentPublishStrings,
  contentRepeat: EnglishContentRepeatStrings,
  contentDistribute: EnglishContentDistributeStrings,
  noteEdit: EnglishNoteEditStrings,
  stepList: EnglishStepListStrings,
  lists: EnglishContentListsStrings,
  linkedTasksDelete: EnglishLinkedTasksDeleteStrings,
  linkedTasksPublish: EnglishLinkedTasksPublishStrings,
  taskDueBox: EnglishTaskDueBoxStrings,
  taskInfo: EnglishTaskInfoStrings,
  taskEdit: EnglishTaskEditStrings,
  workload: EnglishWorkloadStrings
};

export const FrenchContentsStrings: ContentsStrings = {
  notesTitle: 'Notes',
  taskSectionSelectionNone: 'Aucun',

  attachments: FrenchAttachmentStrings,
  contentPublish: FrenchContentPublishStrings,
  contentRepeat: FrenchContentRepeatStrings,
  contentDistribute: FrenchContentDistributeStrings,
  noteEdit: FrenchNoteEditStrings,
  stepList: FrenchStepListStrings,
  lists: FrenchContentListsStrings,
  linkedTasksDelete: FrenchLinkedTasksDeleteStrings,
  linkedTasksPublish: FrenchLinkedTasksPublishStrings,
  taskDueBox: FrenchTaskDueBoxStrings,
  taskInfo: FrenchTaskInfoStrings,
  taskEdit: FrenchTaskEditStrings,
  workload: FrenchWorkloadStrings
};
