import { Feature as PBFeature } from '@buf/studyo_studyo.bufbuild_es/studyo/type_subscriptions_pb';
import { BaseModel, SerializableModel } from '../Model';
import { SubscriptionEntitlement } from '../types';
import { subscriptionEntitlementFromProtobuf } from '../types/EnumConversion';

export interface FeatureModel extends SerializableModel<PBFeature> {
  readonly entitlement: SubscriptionEntitlement;
  readonly stripePriceId: string;
}

export class Feature extends BaseModel<PBFeature> implements FeatureModel {
  constructor(pb: PBFeature) {
    super(pb);
  }

  get entitlement() {
    return subscriptionEntitlementFromProtobuf(this._pb.entitlement);
  }

  get stripePriceId(): string {
    return this._pb.stripePriceId;
  }
}
