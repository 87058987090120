import { NavigationService } from '@insights/services';
import { AccountUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { AccountModel, SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { LocalizationService } from '@shared/resources/services';
import { CalendarStore, ContentStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable, observable } from 'mobx';
import { IPromiseBasedObservable, computedFn, fromPromise } from 'mobx-utils';

export interface SectionCourseOccurrenceDetailInfo {
  readonly schoolYearConfig: SchoolYearConfigurationModel;
  readonly section: SectionModel;
  readonly sectionsById: Record<string, SectionModel>;
  readonly publishedTaskCount: number;
  readonly studentWithTasksCount: number;
  readonly studentWithoutTasksCount: number;
  readonly studentCount: number;
  readonly students: SectionCourseOccurrenceDetailStudentInfo[];
}

export interface SectionCourseOccurrenceDetailStudentInfo {
  readonly student: AccountModel;
  readonly displayName: string;
  getFilteredTasks(): ContentDefinitionModel[];
}

export interface SectionCourseOccurrenceDetailViewModel {
  readonly configId: string;
  readonly sectionId: string;
  readonly schoolDay: SchoolDay;
  readonly detailInfo: IPromiseBasedObservable<SectionCourseOccurrenceDetailInfo>;
  showPublishedTasks: boolean;

  close(): void;
}

export class AppSectionCourseOccurrenceDetailViewModel implements SectionCourseOccurrenceDetailViewModel {
  @observable private _showPublishedTasks = true;

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _contentStore: ContentStore,
    private readonly _calendarStore: CalendarStore,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly sectionId: string,
    public readonly schoolDay: SchoolDay
  ) {
    makeObservable(this);
  }

  @computed
  get detailInfo(): IPromiseBasedObservable<SectionCourseOccurrenceDetailInfo> {
    return fromPromise(this.loadDetailInfo());
  }

  @computed
  get showPublishedTasks(): boolean {
    return this._showPublishedTasks;
  }

  set showPublishedTasks(value: boolean) {
    this._showPublishedTasks = value;
  }

  close() {
    this._onSuccess();
  }

  private async loadDetailInfo(): Promise<SectionCourseOccurrenceDetailInfo> {
    const studentsForSection = await this._schoolYearConfigurationStore.getStudentsForSectionId(
      this.configId,
      this.sectionId,
      false
    );

    const [schoolYearConfig, section, sectionsById, studentTasks, publishedTasks] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this.configId),
      this._schoolYearConfigurationStore.getSection(this.configId, this.sectionId),
      this._schoolYearConfigurationStore.getSectionsById(this.configId),
      this._contentStore.getContents({
        configId: this.configId,
        sectionIds: [this.sectionId],
        accountIds: studentsForSection.map((s) => s.id),
        kindsToInclude: ['task'],
        includeCompleted: true,
        fromDay: this.schoolDay.day,
        toDay: this.schoolDay.day
      }),
      this._contentStore.getContents({
        configId: this.configId,
        sectionIds: [this.sectionId],
        kindsToInclude: ['task'],
        includeCompleted: true,
        publishedState: 'publishedMaster',
        fromDay: this.schoolDay.day,
        toDay: this.schoolDay.day
      })
    ]);

    const studentCount = studentsForSection.length;
    const studentWithTasksCount = studentTasks.filter(
      (t) => !t.isPublished && section.teacherIds.find((teacherId) => teacherId === t.ownerId) == null
    ).length;
    const studentWithoutTasksCount = Math.max(0, studentCount - studentWithTasksCount);

    return {
      schoolYearConfig,
      section,
      sectionsById,
      studentCount: studentCount,
      studentWithTasksCount: studentWithTasksCount,
      studentWithoutTasksCount: studentWithoutTasksCount,
      publishedTaskCount: publishedTasks.filter((t) => t.isMasterPublishedToSection).length,
      students: studentsForSection.map<SectionCourseOccurrenceDetailStudentInfo>((student) => ({
        student: student,
        displayName: AccountUtils.getDisplayLastFirstName(
          student,
          this._localizationService.localizedStrings.insights.noName
        ),
        getFilteredTasks: computedFn(() =>
          studentTasks.filter(
            (task) => task.ownerId === student.id && (!task.isPublished || task.isPublished === this.showPublishedTasks)
          )
        )
      }))
    };
  }
}
