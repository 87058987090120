import { css } from '@emotion/css';
import { InformationText, InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid2,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { AllGradeLevelSources } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { Thresholds } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface EditSchoolYearConfigurationThresholdsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  schoolYearConfiguration: SchoolYearConfigurationModel;
  sectionsGradeLevels: string[];
  studentsGradeLevels: string[];
}

export const EditSchoolYearConfigurationThresholdsDialog = observer(
  (props: EditSchoolYearConfigurationThresholdsDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const {
      className,
      sx = [],
      style,
      schoolYearConfiguration,
      sectionsGradeLevels,
      studentsGradeLevels,
      onCancel,
      onSuccess
    } = props;
    const strings = localizationService.localizedStrings.insights.views.managedGeneral;
    const configStrings = localizationService.localizedStrings.models.schoolYearConfig;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createEditSchoolYearConfigurationThresholds(
        schoolYearConfiguration,
        sectionsGradeLevels,
        studentsGradeLevels,
        onSuccess!,
        onCancel!
      )
    );

    const cellClassName = css({ padding: '2px 8px 2px 0px', border: 'none' });
    const gradeLevelCellClassName = css({ width: '33%' });
    const thresholdCellClassName = css({ width: '33%' });

    return (
      <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
        <Dialog
          sx={sx}
          open={true}
          onClose={() => void viewModel.cancel()}
          maxWidth="sm"
          fullWidth={true}
          className={className}
          style={style}
        >
          <form
            noValidate
            onSubmit={(e) => {
              // This is to prevent the page from reloading on submit
              e.preventDefault();
              return false;
            }}
          >
            <DialogTitle>{strings.editThresholdsDialogTitle}</DialogTitle>
            <DialogContent dividers>
              <Grid2 container spacing={3}>
                {/* Grade Level Source */}
                <Grid2 size={{ xs: 12 }}>
                  <FormControl fullWidth error={Boolean(viewModel.getFieldError('gradeLevelSource'))}>
                    <InputLabel htmlFor="gradelevelsource-select" shrink>
                      {strings.gradeLevelSourceTitle}
                    </InputLabel>
                    <Select
                      value={viewModel.getFieldValue('gradeLevelSource')}
                      inputProps={{ id: 'gradelevelsource-select' }}
                      onChange={(e) => viewModel.setFieldValue('gradeLevelSource', e.target.value)}
                    >
                      {AllGradeLevelSources.map((source) => (
                        <MenuItem value={source} key={source}>
                          <Typography>{configStrings.gradeLevelSourceTitle(source)}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid2>

                <Grid2 container size={{ xs: 12 }} spacing={2}>
                  {/* Default Daily Thresholds */}
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      type="number"
                      slotProps={{
                        input: { inputProps: { min: 0, max: Thresholds.maxThreshold } },
                        inputLabel: { shrink: true }
                      }}
                      placeholder={`${Thresholds.defaultDailyThreshold}`}
                      label={strings.defaultDailyThresholdFieldLabel}
                      value={viewModel.getFieldValue('defaultDailyThreshold') || undefined}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        const adjustedValue = Math.max(Math.min(value, Thresholds.maxThreshold), 0);

                        viewModel.setFieldValue('defaultDailyThreshold', adjustedValue);

                        e.target.value = adjustedValue > 0 ? `${adjustedValue}` : '';
                      }}
                    />
                  </Grid2>

                  {/* Default Daily Thresholds */}
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      type="number"
                      slotProps={{
                        input: { inputProps: { min: 0, max: Thresholds.maxThreshold } },
                        inputLabel: { shrink: true }
                      }}
                      placeholder={`${Thresholds.defaultWeeklyThreshold}`}
                      label={strings.defaultWeeklyThresholdFieldLabel}
                      value={viewModel.getFieldValue('defaultWeeklyThreshold') || undefined}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        const adjustedValue = Math.max(Math.min(value, Thresholds.maxThreshold), 0);

                        viewModel.setFieldValue('defaultWeeklyThreshold', adjustedValue);

                        e.target.value = adjustedValue > 0 ? `${adjustedValue}` : '';
                      }}
                    />
                  </Grid2>

                  {/* Thresholds by grade level */}
                  <Grid2 size={{ xs: 12 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={clsx(cellClassName, gradeLevelCellClassName)}>
                            <InputLabel shrink>{strings.workloadManagerThresholdsGradeLevelColumnTitle}</InputLabel>
                          </TableCell>
                          <TableCell className={clsx(cellClassName, thresholdCellClassName)}>
                            <InputLabel shrink>{strings.workloadManagerThresholdsDailyThresholdColumnTitle}</InputLabel>
                          </TableCell>
                          <TableCell className={clsx(cellClassName, thresholdCellClassName)}>
                            <InputLabel shrink>
                              {strings.workloadManagerThresholdsWeeklyThresholdColumnTitle}
                            </InputLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {viewModel.gradeLevels.map((gradeLevel) => (
                          <TableRow key={gradeLevel}>
                            <TableCell className={clsx(cellClassName, gradeLevelCellClassName)}>
                              <Typography variant="body1">{gradeLevel}</Typography>
                            </TableCell>
                            <TableCell className={clsx(cellClassName, thresholdCellClassName)}>
                              <Input
                                type="number"
                                inputProps={{
                                  min: 0,
                                  max: Thresholds.maxThreshold
                                }}
                                fullWidth
                                placeholder={`${
                                  (viewModel.getFieldValue('defaultDailyThreshold') as number) ||
                                  Thresholds.defaultDailyThreshold
                                }`}
                                value={viewModel.getDailyWorkloadThreshold(gradeLevel)}
                                onChange={(e) => {
                                  const value = Number(e.target.value);
                                  const adjustedValue = Math.max(Math.min(value, Thresholds.maxThreshold), 0);

                                  viewModel.setDailyWorkloadThreshold(gradeLevel, adjustedValue);

                                  e.target.value = adjustedValue > 0 ? `${adjustedValue}` : '';
                                }}
                              />
                            </TableCell>
                            <TableCell className={clsx(cellClassName, thresholdCellClassName)}>
                              <Input
                                type="number"
                                fullWidth
                                inputProps={{
                                  min: 0,
                                  max: Thresholds.maxThreshold
                                }}
                                placeholder={`${
                                  (viewModel.getFieldValue('defaultWeeklyThreshold') as number) ||
                                  Thresholds.defaultWeeklyThreshold
                                }`}
                                value={viewModel.getWeeklyWorkloadThreshold(gradeLevel)}
                                onChange={(e) => {
                                  const value = Number(e.target.value);
                                  const adjustedValue = Math.max(Math.min(value, Thresholds.maxThreshold), 0);

                                  viewModel.setWeeklyWorkloadThreshold(gradeLevel, adjustedValue);

                                  e.target.value = adjustedValue > 0 ? `${adjustedValue}` : '';
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid2>
                </Grid2>
              </Grid2>
            </DialogContent>
            <DialogActions>
              <InformationText text={strings.getThresholdHelperText(Thresholds.maxThreshold)} />

              <Box
                sx={{
                  flex: 1
                }}
              />

              <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
                {strings.cancelButtonCaption}
              </InsightsButton>
              <InsightsButton
                isSubmit
                showSpinnerOnExecuting
                isExecuting={viewModel.isSubmitting}
                onClick={() => void viewModel.save()}
              >
                {strings.saveButtonCaption}
              </InsightsButton>
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>
    );
  }
);
