import { OnboardingCodeKind } from '../types';

export interface OnboardingCodeValidationModel {
  readonly isValid: boolean;
  readonly kind: OnboardingCodeKind;
  readonly requiredEmailDomain: string;
}

export class OnboardingCodeValidation implements OnboardingCodeValidationModel {
  constructor(
    readonly isValid: boolean,
    readonly kind: OnboardingCodeKind,
    readonly requiredEmailDomain: string
  ) {}
}
