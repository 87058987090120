import { arrayMoveImmutable } from 'array-move';
import { action, computed, makeObservable, observable } from 'mobx';
import { Day, Time } from '../types';
import { BaseEditableProperty } from './BaseEditableProperties';
import { ChangeablePropertyEx } from './ChangeableProperty';

export class EditablePropertyEx<T, THost> implements BaseEditableProperty<T, THost> {
  @observable private _value?: T;
  @observable private _changeIteration = 0;

  constructor(
    public readonly originalValue: T,
    private readonly _apply: (host: THost, value: T) => void,
    private readonly _areEqual: (first: T, second: T) => boolean,
    private readonly _converter?: (value: T) => T
  ) {
    makeObservable(this);
  }

  @computed
  get value(): T {
    return this._value ?? this.originalValue;
  }

  set value(v: T) {
    // We don't convert here, as this gets called continuously while the field id edited.
    // Take trimming for example: One wouldn't be able to type "One Two". Would end up "OneTwo".
    if (this._areEqual(v, this.originalValue)) {
      this._value = undefined;
    } else {
      this._value = v;
    }

    this._changeIteration++;
  }

  @computed
  get isChanged() {
    return this._value !== undefined;
  }

  @computed
  get changeIteration() {
    return this._changeIteration;
  }

  applyTo(host: THost): void {
    if (this.isChanged) {
      // It's only when applying the value that optional conversion occurs.
      const value = this._converter == null ? this.value : this._converter(this.value);
      this._apply(host, value);
    }
  }

  @action
  reset() {
    this._value = undefined;
  }

  @action
  copyFrom(other: ChangeablePropertyEx<THost>) {
    const realOther = other as EditablePropertyEx<T, THost>;

    if (realOther == null) {
      throw new Error('Invalid operation. Trying to apply property from a different type.');
    }

    this.value = realOther.value;
  }
}

export class EditableNullablePropertyEx<T, THost> implements BaseEditableProperty<T | undefined, THost> {
  @observable private _value: T | undefined;
  @observable private _isChanged = false;
  @observable private _isDisabled = false;
  @observable private _changeIteration = 0;

  constructor(
    public readonly originalValue: T | undefined,
    private readonly _apply: (host: THost, value: T | undefined) => void,
    private readonly _areEqual: (first: T | undefined, second: T | undefined) => boolean,
    private readonly _originalIsDisabled = false
  ) {
    makeObservable(this);
    this._isDisabled = _originalIsDisabled;
  }

  @computed
  get value(): T | undefined {
    return this._isDisabled ? undefined : this._isChanged ? this._value : this.originalValue;
  }

  set value(v: T | undefined) {
    if (this._areEqual(v, this.originalValue)) {
      this._value = undefined;
      this._isChanged = false;
    } else {
      this._value = v;
      this._isChanged = true;
    }

    this._changeIteration++;
  }

  @computed
  get isChanged() {
    // If disabled and originally disabled, must not consider new value.
    return this._isDisabled != this._originalIsDisabled || (!this._isDisabled && this._isChanged);
  }

  @computed
  get changeIteration() {
    return this._changeIteration;
  }

  @computed
  get isDisabled() {
    return this._isDisabled;
  }

  set isDisabled(value: boolean) {
    this._isDisabled = value;
  }

  applyTo(host: THost): void {
    if (this.isChanged) {
      this._apply(host, this.value);
    }
  }

  @action
  reset() {
    this._value = undefined;
    this._isChanged = false;
    this._isDisabled = this._originalIsDisabled;
  }

  @action
  copyFrom(other: ChangeablePropertyEx<THost>) {
    const realOther = other as EditableNullablePropertyEx<T, THost>;

    if (realOther == null) {
      throw new Error('Invalid operation. Trying to apply property from a different type.');
    }

    this.value = realOther.value;
    this.isDisabled = realOther.isDisabled;
  }
}

// Add other value-like types as needed
export class EditableValuePropertyEx<
  TValue extends string | bigint | number | boolean,
  THost
> extends EditablePropertyEx<TValue, THost> {
  constructor(originalValue: TValue, apply: (host: THost, value: TValue) => void) {
    super(originalValue, apply, (first, second) => first === second);
  }
}

export interface EditableStringOptions {
  trim: boolean;
}

export class EditableStringProperty<THost> extends EditablePropertyEx<string, THost> {
  constructor(originalValue: string, apply: (host: THost, value: string) => void, options?: EditableStringOptions) {
    super(
      originalValue,
      apply,
      (first, second) => first === second,
      options?.trim != null ? (v) => v.trim() : undefined
    );
  }
}

// Add other value-like types as needed
export class EditableValueArrayPropertyEx<TValue extends string | number | boolean, THost> extends EditablePropertyEx<
  TValue[],
  THost
> {
  constructor(originalValues: TValue[], apply: (host: THost, values: TValue[]) => void) {
    super(
      originalValues,
      apply,
      (first, second) => first.length === second.length && first.every((value, index) => value === second[index])
    );
  }

  // Value arrays cannot be manipulated. They're fully set or nothing. That's because it would be counter-performant to monitor
  // if any item at any given index has changed.
}

export class EditableStringArrayProperty<THost> extends EditablePropertyEx<string[], THost> {
  constructor(
    originalValues: string[],
    apply: (host: THost, values: string[]) => void,
    options?: EditableStringOptions
  ) {
    super(
      originalValues,
      apply,
      (first, second) => first.length === second.length && first.every((value, index) => value === second[index]),
      options?.trim != null ? (values) => values.map((value) => value.trim()) : undefined
    );
  }

  // Value arrays cannot be manipulated. They're fully set or nothing. That's because it would be counter-performant to monitor
  // if any item at any given index has changed.
}

export class EditableLiveStringArrayProperty<THost> implements BaseEditableProperty<string[], THost> {
  @observable private _isChanged = false;
  @observable private _values: string[];
  @observable private _changeIteration = 0;

  constructor(
    public readonly originalValue: string[],
    private readonly _apply: (host: THost, values: string[]) => void
  ) {
    makeObservable(this);
    this._values = this.originalValue.slice();
  }

  @computed
  get value(): string[] {
    return this._values;
  }

  set value(values: string[]) {
    this._isChanged =
      values.length !== this.originalValue.length || !values.every((v, i) => v === this.originalValue[i]);
    this._values = values;

    this._changeIteration++;
  }

  @action
  setItem(value: string, index: number) {
    this._values[index] = value;
    this._isChanged =
      this._values.length !== this.originalValue.length || !this._values.every((v, i) => v === this.originalValue[i]);

    this._changeIteration++;
  }

  @action
  moveItem(oldIndex: number, newIndex: number) {
    this.value = arrayMoveImmutable(this._values, oldIndex, newIndex);
  }

  @action
  removeItem(index: number) {
    const copy = this._values.slice();
    copy.splice(index, 1);

    this.value = copy;
  }

  @computed
  get isChanged() {
    return this._isChanged;
  }

  @computed
  get changeIteration() {
    return this._changeIteration;
  }

  applyTo(host: THost): void {
    if (this.isChanged) {
      this._apply(host, this._values);
    }
  }

  @action
  reset() {
    this._values = this.originalValue;
    this._isChanged = false;

    this._changeIteration++;
  }

  @action
  copyFrom(other: ChangeablePropertyEx<THost>) {
    const realOther = other as EditablePropertyEx<string[], THost>;

    if (realOther == null) {
      throw new Error('Invalid operation. Trying to apply property from a different type.');
    }

    this.value = realOther.value;
  }
}

// Add other value-like types as needed
export class EditableNullableValuePropertyEx<
  TValue extends string | number | boolean,
  THost
> extends EditableNullablePropertyEx<TValue, THost> {
  constructor(
    originalValue: TValue | undefined,
    apply: (host: THost, value: TValue | undefined) => void,
    originalIsDisabled = false
  ) {
    super(originalValue, apply, (first, second) => first === second, originalIsDisabled);
  }
}

// Add other value-like types as needed
export class EditableNullableValueArrayPropertyEx<
  TValue extends string | number | boolean,
  THost
> extends EditablePropertyEx<(TValue | undefined)[], THost> {
  constructor(originalValues: (TValue | undefined)[], apply: (host: THost, values: (TValue | undefined)[]) => void) {
    super(
      originalValues,
      apply,
      (first, second) => first.length === second.length && first.every((value, index) => value === second[index])
    );
  }
}

// Add more "specialty" properties as needed.
export class EditableDayPropertyEx<THost> extends EditablePropertyEx<Day, THost> {
  constructor(originalDay: Day, apply: (host: THost, value: Day) => void) {
    super(originalDay, apply, (first, second) => first.isSame(second));
  }
}

export class EditableTimePropertyEx<THost> extends EditablePropertyEx<Time, THost> {
  constructor(
    originalTime: Time,
    apply: (host: THost, value: Time) => void,
    private readonly _defaultHour?: number,
    private readonly _defaultMinute?: number
  ) {
    super(originalTime, apply, (first, second) => first.isSame(second));
  }
}

export class EditableNullableDayPropertyEx<THost> extends EditableNullablePropertyEx<Day, THost> {
  constructor(
    originalDay: Day | undefined,
    apply: (host: THost, value: Day | undefined) => void,
    originalIsDisabled = false
  ) {
    super(
      originalDay,
      apply,
      (first, second) =>
        (first === undefined && second === undefined) ||
        (first !== undefined && second !== undefined && first.isSame(second)),
      originalIsDisabled
    );
  }
}

export class EditableNullableTimePropertyEx<THost> extends EditableNullablePropertyEx<Time, THost> {
  constructor(
    originalTime: Time | undefined,
    apply: (host: THost, value: Time | undefined) => void,
    originalIsDisabled = false
  ) {
    super(
      originalTime,
      apply,
      (first, second) =>
        (first === undefined && second === undefined) ||
        (first !== undefined && second !== undefined && first.isSame(second)),
      originalIsDisabled
    );
  }
}

export class EditableNullableDatePropertyEx<THost> extends EditableNullablePropertyEx<Date, THost> {
  constructor(
    originalDate: Date | undefined,
    apply: (host: THost, value: Date | undefined) => void,
    originalIsDisabled = false
  ) {
    super(
      originalDate,
      apply,
      (first, second) =>
        (first === undefined && second === undefined) ||
        (first !== undefined && second !== undefined && first.getTime() === second.getTime()),
      originalIsDisabled
    );
  }
}
