import { ProfileMenuImages, SettingsImages } from '@shared/resources/images/studyo';
import gearAccountImage from '@shared/web/images/assets/studyo/gear_account_icon.svg';
import headerConfettiImage from '@shared/web/images/assets/studyo/header_confetti.svg';
import headerSupportImage from '@shared/web/images/assets/studyo/header_support.svg';
import { WebProfileMenuImages } from './WebProfileMenuImages';

export class WebSettingsImages implements SettingsImages {
  private readonly _profileMenu = new WebProfileMenuImages();

  get profileMenu(): ProfileMenuImages {
    return this._profileMenu;
  }

  get accountIcon() {
    return gearAccountImage;
  }

  get headerConfetti() {
    return headerConfettiImage;
  }

  get headerSupport() {
    return headerSupportImage;
  }
}
