import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/services/onboarding_pb';
import * as OPB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { Onboarding } from '@buf/studyo_studyo.connectrpc_es/studyo/services/onboarding_connect';
import { Timestamp } from '@bufbuild/protobuf';
import { OnboardingTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class OnboardingGrpcTransport extends BaseGrpcTransport implements OnboardingTransport {
  // TEMPLATES
  async getProcessTemplateNames(): Promise<string[]> {
    const request = new PB.GetProcessTemplateNamesRequest();

    const response = await this.performRequest(Onboarding, Onboarding.methods.getProcessTemplateNames, request);

    return response.processNames;
  }

  async getStepTemplateNames(): Promise<string[]> {
    const request = new PB.GetStepTemplateNamesRequest();

    const response = await this.performRequest(Onboarding, Onboarding.methods.getStepTemplateNames, request);

    return response.stepNames;
  }

  async getQuestionTemplateNames(): Promise<string[]> {
    const request = new PB.GetQuestionTemplateNamesRequest();

    const response = await this.performRequest(Onboarding, Onboarding.methods.getQuestionTemplateNames, request);

    return response.questionNames;
  }

  async getQuestionTemplates(): Promise<OPB.OnboardingQuestion[]> {
    const request = new PB.GetQuestionTemplatesRequest();

    const response = await this.performRequest(Onboarding, Onboarding.methods.getQuestionTemplates, request);

    return response.questionTemplates;
  }

  async createOrUpdateProcessTemplate(
    name: string,
    descriptions: OPB.LocalizedText[],
    stepNames: string[]
  ): Promise<OPB.OnboardingProcess> {
    const request = new PB.CreateOrUpdateProcessTemplateRequest();
    request.processName = name;
    request.description = descriptions;
    request.stepNames = stepNames;

    const response = await this.performRequest(Onboarding, Onboarding.methods.createOrUpdateProcessTemplate, request);

    const pbProcess = response.process;

    if (pbProcess == null) {
      throw new Error(
        'Unexpected result from backend: A CreateOrUpdateProcessTemplate request did not return a process.'
      );
    }

    return pbProcess;
  }

  async createOrUpdateStepTemplate(
    name: string,
    participants: OPB.ParticipantKind,
    titles: OPB.LocalizedText[],
    descriptions: OPB.LocalizedText[],
    targetDays: number,
    questionNames: string[],
    isRepeatable: boolean,
    dependantStepName: string,
    dependantQuestionName: string,
    dependantQuestionAnswer: string
  ): Promise<OPB.OnboardingStep> {
    const request = new PB.CreateOrUpdateStepTemplateRequest();
    request.stepName = name;
    request.participantKind = participants;
    request.localizedTitles = titles;
    request.localizedDescriptions = descriptions;
    request.targetDays = targetDays;
    request.questionNames = questionNames;
    request.isRepeatable = isRepeatable;
    request.dependantStepName = dependantStepName;
    request.dependantQuestionName = dependantQuestionName;
    request.dependantQuestionAnswer = dependantQuestionAnswer;

    const response = await this.performRequest(Onboarding, Onboarding.methods.createOrUpdateStepTemplate, request);

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: A CreateOrUpdateStepTemplate request did not return a step.');
    }

    return pbStep;
  }

  async createOrUpdateQuestionTemplate(
    name: string,
    descriptions: OPB.LocalizedText[],
    kind: OPB.AnswerKind,
    choices: OPB.AnswerChoice[],
    isRequired: boolean,
    dependantQuestionName: string,
    dependantQuestionAnswer: string,
    isHiddenWhenDependant: boolean
  ): Promise<OPB.OnboardingQuestion> {
    const request = new PB.CreateOrUpdateQuestionTemplateRequest();
    request.questionName = name;
    request.localizedDescriptions = descriptions;
    request.answerKind = kind;
    request.choices = choices;
    request.isRequired = isRequired;
    request.dependantQuestionName = dependantQuestionName;
    request.dependantQuestionAnswer = dependantQuestionAnswer;
    request.isHiddenWhenDependant = isHiddenWhenDependant;

    const response = await this.performRequest(Onboarding, Onboarding.methods.createOrUpdateQuestionTemplate, request);

    const pbQuestion = response.question;

    if (pbQuestion == null) {
      throw new Error(
        'Unexpected result from backend: A CreateOrUpdateQuestionTemplate request did not return a question.'
      );
    }

    return pbQuestion;
  }

  // PROCESSES
  async getProcess(processName: string, configId: string): Promise<OPB.OnboardingProcess> {
    console.log(`Fetching onboarding process named "${processName}" for [configId: ${configId}]...`);

    const request = new PB.GetProcessRequest();
    request.processName = processName;
    request.configId = configId;

    const response = await this.performRequest(Onboarding, Onboarding.methods.getProcess, request);

    console.log('Successfully fetched onboarding process.');

    const pbProcess = response.process;

    if (pbProcess == null) {
      throw new Error('Unexpected result from backend: A GetProcess request did not return a process.');
    }

    return pbProcess;
  }

  async getProcesses(configId: string): Promise<OPB.OnboardingProcess[]> {
    const request = new PB.GetProcessesRequest();
    request.configId = configId;

    const response = await this.performRequest(Onboarding, Onboarding.methods.getProcesses, request);

    return response.processes;
  }

  async createProcess(processName: string, configId: string): Promise<OPB.OnboardingProcess> {
    const request = new PB.CreateProcessRequest();
    request.processName = processName;
    request.configId = configId;

    const response = await this.performRequest(Onboarding, Onboarding.methods.createProcess, request);

    const pbProcess = response.process;

    if (pbProcess == null) {
      throw new Error('Unexpected result from backend: A CreateProcess request did not return a process.');
    }

    return pbProcess;
  }

  async updateProcess(
    processName: string,
    configId: string,
    descriptions: OPB.LocalizedText[],
    stepNames: string[],
    alsoUpdateTemplate: boolean
  ): Promise<OPB.OnboardingProcess> {
    const request = new PB.UpdateProcessRequest();
    request.processName = processName;
    request.configId = configId;
    request.localizedDescriptions = descriptions;
    request.stepNames = stepNames;
    request.shouldAlsoUpdateProcessTemplate = alsoUpdateTemplate;

    const response = await this.performRequest(Onboarding, Onboarding.methods.updateProcess, request);

    const pbProcess = response.process;

    if (pbProcess == null) {
      throw new Error('Unexpected result from backend: An UpdateProcess request did not return a process.');
    }

    return pbProcess;
  }

  async updateProcessStatus(processName: string, configId: string, status: OPB.Status): Promise<OPB.OnboardingProcess> {
    const request = new PB.UpdateProcessStatusRequest();
    request.processName = processName;
    request.configId = configId;
    request.newStatus = status;

    const response = await this.performRequest(Onboarding, Onboarding.methods.updateProcessStatus, request);

    const pbProcess = response.process;

    if (pbProcess == null) {
      throw new Error('Unexpected result from backend: An UpdateProcessStatus request did not return a process.');
    }

    return pbProcess;
  }

  async updateProcessAssignees(
    processName: string,
    configId: string,
    clientId: string | undefined,
    agentId: string | undefined,
    followerIds: string[]
  ): Promise<OPB.OnboardingProcess> {
    const request = new PB.UpdateProcessAssigneesRequest();
    request.processName = processName;
    request.configId = configId;
    request.clientId = clientId ?? '';
    request.agentId = agentId ?? '';
    request.followerIds = followerIds;

    const response = await this.performRequest(Onboarding, Onboarding.methods.updateProcessAssignees, request);

    const pbProcess = response.process;

    if (pbProcess == null) {
      throw new Error('Unexpected result from backend: An UpdateProcessAssignees request did not return a process.');
    }

    return pbProcess;
  }

  async renameProcess(processName: string, configId: string, newName: string): Promise<OPB.OnboardingProcess> {
    const request = new PB.RenameProcessRequest();
    request.processName = processName;
    request.configId = configId;
    request.newName = newName;

    const response = await this.performRequest(Onboarding, Onboarding.methods.renameProcess, request);

    const pbProcess = response.process;

    if (pbProcess == null) {
      throw new Error('Unexpected result from backend: A RenameProcess request did not return a process.');
    }

    return pbProcess;
  }

  async deleteProcess(processName: string, configId: string): Promise<void> {
    const request = new PB.DeleteProcessRequest();
    request.processName = processName;
    request.configId = configId;

    await this.performRequest(Onboarding, Onboarding.methods.deleteProcess, request);
  }

  // STEPS
  async getStep(stepName: string, configId: string): Promise<OPB.OnboardingStep> {
    const request = new PB.GetStepRequest();
    request.stepName = stepName;
    request.configId = configId;

    const response = await this.performRequest(Onboarding, Onboarding.methods.getStep, request);

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: A GetStep request did not return a step.');
    }

    return pbStep;
  }

  async createStep(stepName: string, configId: string): Promise<OPB.OnboardingStep> {
    const request = new PB.CreateStepRequest();
    request.stepName = stepName;
    request.configId = configId;

    const response = await this.performRequest(Onboarding, Onboarding.methods.createStep, request);

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: A CreateStep request did not return a step.');
    }

    return pbStep;
  }

  async updateStep(
    stepName: string,
    configId: string,
    processName: string,
    participantKind: OPB.ParticipantKind,
    titles: OPB.LocalizedText[],
    descriptions: OPB.LocalizedText[],
    targetDays: number,
    questionNames: string[],
    isRepeatable: boolean,
    dependantStepName: string,
    dependantQuestionName: string,
    dependantQuestionAnswer: string,
    alsoUpdateTemplate: boolean
  ): Promise<OPB.OnboardingStep> {
    const request = new PB.UpdateStepRequest();
    request.stepName = stepName;
    request.configId = configId;
    request.processName = processName;
    request.participantKind = participantKind;
    request.localizedTitles = titles;
    request.localizedDescriptions = descriptions;
    request.targetDays = targetDays;
    request.questionNames = questionNames;
    request.isRepeatable = isRepeatable;
    request.dependantStepName = dependantStepName;
    request.dependantQuestionName = dependantQuestionName;
    request.dependantQuestionAnswer = dependantQuestionAnswer;
    request.shouldAlsoUpdateStepTemplate = alsoUpdateTemplate;

    const response = await this.performRequest(Onboarding, Onboarding.methods.updateStep, request);

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: An UpdateStep request did not return a step.');
    }

    return pbStep;
  }

  async updateStepStatus(
    stepName: string,
    configId: string,
    processName: string,
    status: OPB.Status
  ): Promise<OPB.OnboardingStep> {
    const request = new PB.UpdateStepStatusRequest();
    request.stepName = stepName;
    request.configId = configId;
    request.processName = processName;
    request.newStatus = status;

    const response = await this.performRequest(Onboarding, Onboarding.methods.updateStepStatus, request);

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: An UpdateStepStatus request did not return a step.');
    }

    return pbStep;
  }

  async updateStepTargetDate(
    stepName: string,
    configId: string,
    processName: string,
    newTargetDate: Timestamp
  ): Promise<OPB.OnboardingStep> {
    const request = new PB.UpdateStepTargetDateRequest();
    request.stepName = stepName;
    request.configId = configId;
    request.processName = processName;
    request.newTargetDate = newTargetDate;

    const response = await this.performRequest(Onboarding, Onboarding.methods.updateStepTargetDate, request);

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: An UpdateStepTargetDate request did not return a step.');
    }

    return pbStep;
  }

  async repeatStep(stepName: string, configId: string, processName: string): Promise<OPB.OnboardingStep> {
    const request = new PB.RepeatStepRequest();
    request.stepName = stepName;
    request.configId = configId;
    request.processName = processName;

    const response = await this.performRequest(Onboarding, Onboarding.methods.repeatStep, request);

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: A RepeatStep request did not return a step.');
    }

    return pbStep;
  }

  async updateStepAssignees(
    stepName: string,
    configId: string,
    processName: string,
    clientId: string | undefined,
    agentId: string | undefined,
    followerIds: string[]
  ): Promise<OPB.OnboardingStep> {
    const request = new PB.UpdateStepAssigneesRequest();
    request.stepName = stepName;
    request.configId = configId;
    request.processName = processName;
    request.clientId = clientId ?? '';
    request.agentId = agentId ?? '';
    request.followerIds = followerIds;

    const response = await this.performRequest(Onboarding, Onboarding.methods.updateStepAssignees, request);

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: An UpdateStepAssignees request did not return a step.');
    }

    return pbStep;
  }

  async renameStep(stepName: string, configId: string, newName: string): Promise<OPB.OnboardingStep> {
    const request = new PB.RenameStepRequest();
    request.stepName = stepName;
    request.configId = configId;
    request.newName = newName;

    const response = await this.performRequest(Onboarding, Onboarding.methods.renameStep, request);

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: A RenameStep request did not return a step.');
    }

    return pbStep;
  }

  async deleteStep(stepName: string, configId: string): Promise<void> {
    const request = new PB.DeleteStepRequest();
    request.stepName = stepName;
    request.configId = configId;

    await this.performRequest(Onboarding, Onboarding.methods.deleteStep, request);
  }

  async updateStepVisibility(
    stepName: string,
    configId: string,
    processName: string,
    isForcedVisible: boolean
  ): Promise<OPB.OnboardingStep> {
    const request = new PB.UpdateStepVisibilityRequest();
    request.stepName = stepName;
    request.configId = configId;
    request.processName = processName;
    request.isForcedVisible = isForcedVisible;

    const response = await this.performRequest(Onboarding, Onboarding.methods.updateStepVisibility, request);

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: An UpdateStepVisibility request did not return a step.');
    }

    return pbStep;
  }

  // QUESTIONS
  async createQuestion(
    questionName: string,
    configId: string,
    stepName: string,
    kind: OPB.AnswerKind
  ): Promise<{ question: OPB.OnboardingQuestion; step: OPB.OnboardingStep }> {
    const request = new PB.CreateQuestionRequest();
    request.questionName = questionName;
    request.configId = configId;
    request.stepName = stepName;
    request.kind = kind;

    const response = await this.performRequest(Onboarding, Onboarding.methods.createQuestion, request);

    const pbQuestion = response.question;

    if (pbQuestion == null) {
      throw new Error('Unexpected result from backend: A CreateQuestion request did not return a question.');
    }

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: A CreateQuestion request did not return a step.');
    }

    return { question: pbQuestion, step: pbStep };
  }

  async updateQuestion(
    questionName: string,
    configId: string,
    stepName: string,
    descriptions: OPB.LocalizedText[],
    kind: OPB.AnswerKind,
    choices: OPB.AnswerChoice[],
    isRequired: boolean,
    dependantQuestionName: string,
    dependantQuestionAnswer: string,
    isHiddenWhenDependant: boolean,
    alsoUpdateTemplate: boolean
  ): Promise<{ question: OPB.OnboardingQuestion; step: OPB.OnboardingStep }> {
    const request = new PB.UpdateQuestionRequest();
    request.questionName = questionName;
    request.configId = configId;
    request.stepName = stepName;
    request.localizedDescriptions = descriptions;
    request.answerKind = kind;
    request.choices = choices;
    request.isRequired = isRequired;
    request.dependantQuestionName = dependantQuestionName;
    request.dependantQuestionAnswer = dependantQuestionAnswer;
    request.isHiddenWhenDependant = isHiddenWhenDependant;
    request.shouldAlsoUpdateQuestionTemplate = alsoUpdateTemplate;

    const response = await this.performRequest(Onboarding, Onboarding.methods.updateQuestion, request);

    const pbQuestion = response.question;

    if (pbQuestion == null) {
      throw new Error('Unexpected result from backend: An UpdateQuestion request did not return a question.');
    }

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: An UpdateQuestion request did not return a step.');
    }

    return { question: pbQuestion, step: pbStep };
  }

  async updateAnswer(
    questionName: string,
    configId: string,
    stepName: string,
    answer: OPB.OnboardingAnswer
  ): Promise<{ question: OPB.OnboardingQuestion; step: OPB.OnboardingStep }> {
    const request = new PB.UpdateAnswerRequest();
    request.questionName = questionName;
    request.configId = configId;
    request.stepName = stepName;
    request.answer = answer;

    const response = await this.performRequest(Onboarding, Onboarding.methods.updateAnswer, request);

    const pbQuestion = response.question;

    if (pbQuestion == null) {
      throw new Error('Unexpected result from backend: An UpdateAsnwer request did not return a question.');
    }

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: An UpdateQuestion request did not return a step.');
    }

    return { question: pbQuestion, step: pbStep };
  }

  async prepareUploadFile(
    questionName: string,
    configId: string,
    stepName: string,
    fileName: string
  ): Promise<PB.PrepareUploadFileResponse> {
    const request = new PB.PrepareUploadFileRequest();
    request.questionName = questionName;
    request.configId = configId;
    request.stepName = stepName;
    request.fileName = fileName;

    return await this.performRequest(Onboarding, Onboarding.methods.prepareUploadFile, request);
  }

  async renameQuestion(
    questionName: string,
    configId: string,
    stepName: string,
    newQuestionName: string
  ): Promise<{ question: OPB.OnboardingQuestion; step: OPB.OnboardingStep }> {
    const request = new PB.RenameQuestionRequest();
    request.questionName = questionName;
    request.configId = configId;
    request.stepName = stepName;
    request.newQuestionName = newQuestionName;

    const response = await this.performRequest(Onboarding, Onboarding.methods.renameQuestion, request);

    const pbQuestion = response.question;

    if (pbQuestion == null) {
      throw new Error('Unexpected result from backend: A RenameQuestion request did not return a question.');
    }

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: A RenameQuestion request did not return a step.');
    }

    return { question: pbQuestion, step: pbStep };
  }

  async deleteQuestion(questionName: string, configId: string, stepName: string): Promise<OPB.OnboardingStep> {
    const request = new PB.DeleteQuestionRequest();
    request.questionName = questionName;
    request.configId = configId;
    request.stepName = stepName;

    const response = await this.performRequest(Onboarding, Onboarding.methods.deleteQuestion, request);

    const pbStep = response.step;

    if (pbStep == null) {
      throw new Error('Unexpected result from backend: A DeleteQuestion request did not return a step.');
    }

    return pbStep;
  }

  async getSensitiveAnswer(
    questionName: string,
    configId: string,
    stepName: string,
    includePreviousAnswers: boolean
  ): Promise<PB.GetSensitiveAnswerResponse> {
    const request = new PB.GetSensitiveAnswerRequest();
    request.questionName = questionName;
    request.configId = configId;
    request.stepName = stepName;
    request.includePreviousAnswers = includePreviousAnswers;

    const response = await this.performRequest(Onboarding, Onboarding.methods.getSensitiveAnswer, request);

    return response;
  }

  // COMMENTS
  async getComments(
    processName: string,
    stepName: string | undefined,
    configId: string
  ): Promise<OPB.OnboardingComment[]> {
    const request = new PB.GetCommentsRequest();
    request.processName = processName;
    request.stepName = stepName ?? '';
    request.configId = configId;

    const response = await this.performRequest(Onboarding, Onboarding.methods.getComments, request);

    return response.comments;
  }

  async addOrEditComment(comment: OPB.OnboardingComment): Promise<OPB.OnboardingComment> {
    const request = new PB.AddOrEditCommentRequest();
    request.comment = comment;

    const response = await this.performRequest(Onboarding, Onboarding.methods.addOrEditComment, request);

    const newComment = response.comment;

    if (newComment == null) {
      throw new Error('Unexpected result from backend: An AddOrEditComment request did not return a comment.');
    }

    return newComment;
  }

  async deleteComment(commentId: string): Promise<void> {
    const request = new PB.DeleteCommentRequest();
    request.commentId = commentId;

    await this.performRequest(Onboarding, Onboarding.methods.deleteComment, request);
  }

  async getSchoolHistory(configId: string, languageCode: string): Promise<OPB.OnboardingHistoryEntry[]> {
    const request = new PB.GetSchoolHistoryRequest();
    request.configId = configId;
    request.languageCode = languageCode ?? '';

    const response = await this.performRequest(Onboarding, Onboarding.methods.getSchoolHistory, request);

    return response.entries;
  }

  // DASHBOARD
  async getDashboard(
    processStatuses: OPB.Status[],
    stepStatuses: OPB.Status[],
    minimumDate: Timestamp | undefined
  ): Promise<OPB.Dashboard> {
    const request = new PB.GetDashboardRequest();
    request.processStatuses = processStatuses;
    request.stepStatuses = stepStatuses;
    request.minimumDate = minimumDate;

    const response = await this.performRequest(Onboarding, Onboarding.methods.getDashboard, request);

    const dashboard = response.dashboard;

    if (dashboard == null) {
      throw new Error('Unexpected result from backend: A GetDashboard request did not return a dashboard.');
    }

    return dashboard;
  }

  async searchDashboard(
    searchTerm: string,
    processStatuses: OPB.Status[],
    stepStatuses: OPB.Status[],
    minimumDate: Timestamp | undefined
  ): Promise<OPB.Dashboard> {
    const request = new PB.SearchDashboardRequest();
    request.searchTerm = searchTerm;
    request.processStatuses = processStatuses;
    request.stepStatuses = stepStatuses;
    request.minimumDate = minimumDate;

    const response = await this.performRequest(Onboarding, Onboarding.methods.searchDashboard, request);

    // For now, we do not support unbound templates
    const dashboard = response.dashboard;

    if (dashboard == null) {
      throw new Error('Unexpected result from backend: A SearchDashboard request did not return a dashboard.');
    }

    return dashboard;
  }

  async getDashboardComments(minimumDate: Timestamp): Promise<OPB.DashboardProcessComments[]> {
    const request = new PB.GetCommentsDashboardRequest();
    request.minimumDate = minimumDate;

    const response = await this.performRequest(Onboarding, Onboarding.methods.getCommentsDashboard, request);

    const processesComments = response.processesComments;

    return processesComments;
  }

  // GENERAL
  async prepareUploadResourceFile(configId: string, fileName: string): Promise<PB.PrepareUploadResourceFileResponse> {
    const request = new PB.PrepareUploadResourceFileRequest();
    request.configId = configId;
    request.fileName = fileName;

    return await this.performRequest(Onboarding, Onboarding.methods.prepareUploadResourceFile, request);
  }
}
