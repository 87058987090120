import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_sky_pb';
import { BlackbaudSkySchoolLevel } from './interfaces';

export class GrpcBlackbaudSkySchoolLevel implements BlackbaudSkySchoolLevel {
  constructor(private readonly pb: BC.SchoolLevel) {}

  get id() {
    return this.pb.id;
  }

  get name() {
    return this.pb.name;
  }

  get abbreviation() {
    return this.pb.abbreviation;
  }
}
