import * as GC from '@buf/studyo_studyo.bufbuild_es/studyo/type_classroom_pb';
import { AccountService } from '@insights/services';
import {
  ClassroomCourseWorkMappingModel,
  ClassroomExternalAccountSettings,
  EditableClassroomCourseWorkMapping
} from '@shared/models/connectors';
import { FullyEditableListProperty } from '@shared/models/editables';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface GoogleAccountSettingsViewModel {
  matchingExpression: string;
  isIgnoringUnmatchedCourseWork: boolean;
  isRemovingMatchedKeyword: boolean;
  isFetchingCourseAliases: boolean;

  readonly courseWorkMappings: EditableClassroomCourseWorkMapping[];
  addCourseWorkMapping(matchedValue: string): void;

  readonly hasChanges: boolean;
  readonly error: string | undefined;
  readonly canApplyToAll: boolean;
  readonly isApplying: boolean;

  applyChanges(): Promise<void>;
  applyChangesToAll(): Promise<void>;
  resetChanges(): void;
  cancelChanges(): void;
}

export class AppGoogleAccountSettingsViewModel implements GoogleAccountSettingsViewModel {
  @observable private _matchingExpression?: string;
  @observable private _isIgnoringUnmatchedCourseWork?: boolean;
  @observable private _isRemovingMatchedKeyword?: boolean;
  @observable private _isFetchingCourseAliases?: boolean;
  @observable private _hasChanges = false;
  @observable private _error: string | undefined;

  @observable private _isApplying = false;

  // We use an editable field to hold changes, but it doesn't have a host to apply to.
  private _mappings: FullyEditableListProperty<
    GC.CourseWorkMapping,
    ClassroomCourseWorkMappingModel,
    EditableClassroomCourseWorkMapping,
    unknown
  >;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _accountService: AccountService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    private readonly _isNewAccount: boolean,
    private readonly _originalSettings: ClassroomExternalAccountSettings
  ) {
    makeObservable(this);
    this._mappings = new FullyEditableListProperty(
      _originalSettings.courseWorkMappings.map((m) => new EditableClassroomCourseWorkMapping(m)),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );

    if (_isNewAccount) {
      // The default expression matches words in parenthesis at the end of the course work title.
      this._matchingExpression = '\\((\\w+)\\)$';
    }
  }

  @computed
  get matchingExpression() {
    return this._matchingExpression ?? this._originalSettings.matchingExpression;
  }

  set matchingExpression(value: string) {
    this._matchingExpression = value;
    this._hasChanges = true;
    this._error = undefined;
  }

  @computed
  get isIgnoringUnmatchedCourseWork() {
    return this._isIgnoringUnmatchedCourseWork ?? this._originalSettings.isIgnoringUnmatchedCourseWork;
  }

  set isIgnoringUnmatchedCourseWork(value: boolean) {
    this._isIgnoringUnmatchedCourseWork = value;
    this._hasChanges = true;
    this._error = undefined;
  }

  @computed
  get isRemovingMatchedKeyword() {
    return this._isRemovingMatchedKeyword ?? this._originalSettings.isRemovingMatchedKeyword;
  }

  set isRemovingMatchedKeyword(value: boolean) {
    this._isRemovingMatchedKeyword = value;
    this._hasChanges = true;
    this._error = undefined;
  }

  @computed
  get isFetchingCourseAliases() {
    return this._isFetchingCourseAliases ?? this._originalSettings.isFetchingCourseAliases;
  }

  set isFetchingCourseAliases(value: boolean) {
    this._isFetchingCourseAliases = value;
    this._hasChanges = true;
    this._error = undefined;
  }

  @computed
  get courseWorkMappings() {
    return this._mappings.values;
  }

  @action
  addCourseWorkMapping(matchedValue: string) {
    this._mappings.addItem(EditableClassroomCourseWorkMapping.createNew(matchedValue));
  }

  @computed
  get hasChanges() {
    return this._hasChanges || this._mappings.isChanged;
  }

  @computed
  get error() {
    return this._error;
  }

  get canApplyToAll() {
    return this._accountService.isAllowed(['admin', 'super-admin']);
  }

  @computed
  get isApplying() {
    return this._isApplying;
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    // We allow applying even when there are no changes, because it's a two-step edition.
    if (!this.hasChanges) {
      this._onSuccess();
      return;
    }

    this._isApplying = true;

    try {
      await this._connectorsStore.classroom.updateGoogleAccountSettings(
        this._configId,
        this._externalAccountId,
        this.matchingExpression,
        this.courseWorkMappings,
        this.isIgnoringUnmatchedCourseWork,
        this.isFetchingCourseAliases,
        this.isRemovingMatchedKeyword
      );

      this._onSuccess();
    } catch (error) {
      // We let the original error message bubble out.
      runInAction(() => (this._error = `${strings.serverError} ${(error as Error).message}`));
      throw error;
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  @action
  async applyChangesToAll(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this.canApplyToAll) {
      // This should be prevented in the view.
      this._error = strings.unexpectedError;
      return;
    }

    // We apply to all even if no changes.
    this._isApplying = true;

    try {
      const accounts = await this._connectorsStore.getExternalAccounts(this._configId);

      for (const account of accounts) {
        if (account.kind === 'google') {
          await this._connectorsStore.classroom.updateGoogleAccountSettings(
            this._configId,
            account.id,
            this.matchingExpression,
            this.courseWorkMappings,
            this.isIgnoringUnmatchedCourseWork,
            this.isFetchingCourseAliases,
            this.isRemovingMatchedKeyword
          );
        }
      }

      this._onSuccess();
    } catch (error) {
      // We let the original error message bubble out.
      runInAction(() => (this._error = `${strings.serverError} ${(error as Error).message}`));
      throw error;
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  @action
  resetChanges() {
    this._mappings.reset();
    this._matchingExpression = undefined;
    this._isIgnoringUnmatchedCourseWork = undefined;
    this._hasChanges = false;
    this._error = undefined;
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this.hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
