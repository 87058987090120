import { TeamsAccountDetailsModel } from '@shared/models/connectors/interfaces';
import { LocalizationService } from '@shared/resources/services';
import { MicrosoftTeamsConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface MicrosoftTeamsAccountSettingsViewModel extends ExternalAccountEditionViewModel {
  customClassesFilter: string;
}

export class AppMicrosoftTeamsAccountSettingsViewModel
  extends BaseExternalAccountEditionViewModel
  implements MicrosoftTeamsAccountSettingsViewModel
{
  @observable private _customClassesFilter?: string;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _teamsStore: MicrosoftTeamsConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    private readonly _originalDetails: TeamsAccountDetailsModel
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get customClassesFilter(): string {
    return this._customClassesFilter ?? this._originalDetails.customClassesFilter;
  }

  set customClassesFilter(value: string) {
    this._customClassesFilter = value;
    this.onChange();
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this.hasChanges) {
      console.error('Applying without changes. Ignoring...');
      this._onSuccess();
      return;
    }

    this.beginApplying();

    try {
      await this._teamsStore.updateTeamsAccountSettings(
        this._configId,
        this._externalAccountId,
        this._originalDetails.mappings,
        this._originalDetails.ignoreUnmappedCategories,
        this.customClassesFilter
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._customClassesFilter = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this.hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
