import { BlackbaudAccountSettingsViewModel } from '@insights/viewmodels';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Typography
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';

export interface BlackbaudAccountSettingsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: BlackbaudAccountSettingsViewModel;
}

export const BlackbaudAccountSettings = observer((props: BlackbaudAccountSettingsProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Column className={className} style={style}>
      <FormControl sx={{ ml: 2 }}>
        <InputLabel htmlFor="year-select">{strings.yearLabel}</InputLabel>
        <Select
          value={viewModel.yearLabel}
          onChange={(event) => (viewModel.yearLabel = String(event.target.value))}
          inputProps={{ id: 'year-select' }}
        >
          {viewModel.availableYears.map((year) => (
            <MenuItem key={`year-${year.id}`} value={year.label}>
              <Typography>{year.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ ml: 2 }}>
        <InputLabel htmlFor="level-select">{strings.levelLabel}</InputLabel>
        <Select
          value={viewModel.levelId}
          onChange={(event) => (viewModel.levelId = BigInt(event.target.value as bigint))}
          inputProps={{ id: 'level-select' }}
        >
          <MenuItem key="level-none" value="0">
            <Typography>{strings.noLevel}</Typography>
          </MenuItem>
          {viewModel.availableLevels.map((level) => (
            <MenuItem key={`level-${level.id}`} value={Number(level.id)}>
              <Typography>{level.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ ml: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={viewModel.includeNonLeadSections}
              onChange={(event) => (viewModel.includeNonLeadSections = event.target.checked)}
            />
          }
          label={strings.blackbaudIncludeNonLeadSections}
        />
        <FormHelperText>{strings.blackbaudNonLeadSectionsWarning}</FormHelperText>
      </FormControl>

      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
