import {
  CourseOccurrenceCustomizationModel,
  MoveCourseOccurrencesParamsModel,
  SchoolDay
} from '@shared/models/calendar';
import { SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { Day } from '@shared/models/types';
import { IComputedValue, computed } from 'mobx';
import { GeneratorTransport, SchoolTransport } from '../../transports';
import { CalendarStore } from '../interfaces';
import { AppBaseStore } from './AppBaseStore';

export class AppCalendarStore extends AppBaseStore implements CalendarStore {
  private _generatorTransport: GeneratorTransport;
  private _schoolTransport: SchoolTransport;

  constructor(generator: GeneratorTransport, school: SchoolTransport) {
    super('AppCalendarStore');
    this._generatorTransport = generator;
    this._schoolTransport = school;
  }

  getSchoolDays(configId: string, sectionIds: string[], preferredScheduleTag?: string): Promise<SchoolDay[]> {
    return this.getMemoizedSchoolDays(configId, sectionIds, preferredScheduleTag).get();
  }

  getSchoolDayForDay(configId: string, sectionIds: string[], day: Day): Promise<SchoolDay | undefined> {
    return this.getMemoizedSchoolDay(configId, sectionIds, day).get();
  }

  async getVolatileSchoolDays(
    config: SchoolYearConfigurationModel,
    sections: SectionModel[],
    preferredScheduleTag?: string
  ): Promise<SchoolDay[]> {
    // This call is never memoized.
    const pbSchoolDays = await this._generatorTransport.fetchVolatileSchoolDays(
      config.toProtobufWithoutSections(),
      sections.map((section) => section.toProtobuf()),
      preferredScheduleTag
    );
    return pbSchoolDays.map((pb) => new SchoolDay(pb));
  }

  async moveCourseOccurrences(params: MoveCourseOccurrencesParamsModel): Promise<void> {
    await this._schoolTransport.moveCourseOccurrences(params);

    // This "schools" call affects the "generator" results!
    this.invalidate();
  }

  async customizeCourseOccurrence(
    configId: string,
    sectionId: string,
    customization: CourseOccurrenceCustomizationModel
  ): Promise<void> {
    await this._schoolTransport.customizeCourseOccurrence(configId, sectionId, customization.toProtobuf());

    // This "schools" call affects the "generator" results!
    this.invalidate();
  }

  private getMemoizedSchoolDays = this.memoize(
    (configId: string, sectionIds: string[], preferredScheduleTag?: string): IComputedValue<Promise<SchoolDay[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const schoolDaysResult = await this._generatorTransport.fetchSchoolDays(
              configId,
              sectionIds,
              preferredScheduleTag
            );
            return schoolDaysResult.result.map((sd) => new SchoolDay(sd));
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedSchoolDay = this.memoize(
    (configId: string, sectionIds: string[], day: Day): IComputedValue<Promise<SchoolDay | undefined>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const pbSchoolDays = await this.getSchoolDays(configId, sectionIds);
          return pbSchoolDays.find((sd) => sd.day.isSame(day));
        })
      )
  );
}
