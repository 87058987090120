export interface BrowserDetectorStrings {
  readonly title: string;
  readonly subtitle: string;
}

export const EnglishBrowserDetectorStrings: BrowserDetectorStrings = {
  title: 'Unsupported Browser',
  subtitle: 'Your browser is not supported by Studyo. Please use one of the following to continue.'
};

export const FrenchBrowserDetectorStrings: BrowserDetectorStrings = {
  title: 'Navigateur non-supporté',
  subtitle:
    'Votre navigateur n’est pas supporté par Studyo. Veuillez utiliser un des navigateurs suivants pour continuer.'
};
