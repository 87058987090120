export interface GearMenuShareStrings {
  addToMyCalendar: string;
  close: string;
  copyToClipboard: string;
  copyToClipboardSuccess: string;
  copyUrl: string;
  genericErrorMessage: string;
  includeFreePeriods: string;
  invalidEmailErrorMessage: string;
  invite: string;
  inviteMyParent: string;
  inviteSent: string;

  inviteLoadingTitle: string;
  inviteLoadingMessage: string;
  inviteSuccessTitle: string;
  inviteSuccessMessage: string;
  inviteErrorTitle: string;

  mailSubject: string;
  parentEmailAddressPlaceholder: string;
  pleaseEnterParentEmail: string;
  sendByEmail: string;
  share: string;
  shareCalendarLink: string;

  mailBody: (webcalUrl: string, httpUrl: string) => string;
}

export const EnglishGearMenuShareStrings: GearMenuShareStrings = {
  addToMyCalendar: 'Add to my calendar',
  close: 'Close',
  copyToClipboard: 'Copy!',
  copyToClipboardSuccess: 'Copied to clipboard',
  copyUrl: '… or you can copy the calendar URL:',
  genericErrorMessage: 'An error occurred, please try again.',
  includeFreePeriods: 'Include free periods',
  invalidEmailErrorMessage: 'The email format entered is invalid',
  invite: 'Invite',
  inviteMyParent: 'Invite my parent',
  inviteSent: 'Invitation sent!',

  inviteLoadingTitle: 'Please wait...',
  inviteLoadingMessage: 'Inviting your parent',
  inviteSuccessTitle: 'Success',
  inviteSuccessMessage: 'Invitation sent!',
  inviteErrorTitle: 'Oops, something went wrong!',

  mailSubject: 'Subscribe to my Studyo planner',
  parentEmailAddressPlaceholder: `Your parent's email address`,
  pleaseEnterParentEmail: `Please enter your parent's email address.`,
  sendByEmail: 'Send by email',
  share: 'Share',
  shareCalendarLink: 'Share my calendar',

  mailBody: englishMailBody
};

export const FrenchGearMenuShareStrings: GearMenuShareStrings = {
  addToMyCalendar: 'Ajouter à mon calendrier',
  close: 'Fermer',
  copyToClipboard: 'Copier!',
  copyToClipboardSuccess: 'Copié dans le presse-papier',
  copyUrl: '… ou bien vous pouvez copier le lien suivant:',
  genericErrorMessage: 'Une erreur est survenue, veuillez réessayer.',
  includeFreePeriods: 'Inclure les périodes libres',
  invalidEmailErrorMessage: `Le format de l’adresse courriel entrée est invalide`,
  invite: 'Inviter',
  inviteSent: 'Invitation envoyée!',
  inviteMyParent: 'Inviter mon parent',

  inviteLoadingTitle: 'Veuillez patienter...',
  inviteLoadingMessage: 'Inviter votre parent',
  inviteSuccessTitle: 'Succès',
  inviteSuccessMessage: 'Invitation envoyée!',
  inviteErrorTitle: 'Oops, une erreur est survenue!',

  mailSubject: 'Abonne-toi à mon agenda Studyo',
  parentEmailAddressPlaceholder: 'Adresse courriel du parent',
  pleaseEnterParentEmail: `Veuillez entrer l’adresse courriel du parent.`,
  sendByEmail: 'Envoyer par courriel',
  share: 'Partager',
  shareCalendarLink: 'Partager mon calendrier',

  mailBody: frenchMailBody
};

function englishMailBody(webcalUrl: string, httpUrl: string) {
  return `
  Click or copy this link to subscribe to my Studyo planner in your calendar:

  ${webcalUrl}


  or copy and paste this URL to subscribe (required for some calendars):

  ${httpUrl}`;
}

function frenchMailBody(webcalUrl: string, httpUrl: string) {
  return `
  Tu peux maintenant t'abonner à mon agenda Studyo dans ton calendrier en cliquant ou copiant ce lien:

  ${webcalUrl}


  Tu peux aussi copier et coller le lien suivant selon le calendrier que tu utilises:

  ${httpUrl}`;
}
