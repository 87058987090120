import { ManageBacAccountSettingsViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  IconButton,
  Input,
  InputAdornment,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';

export interface ManageBacTermIdsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: ManageBacAccountSettingsViewModel;
}

export const ManageBacTermIds = observer((props: ManageBacTermIdsProps) => {
  const { sx = [], className, style, viewModel } = props;

  return (
    <Root sx={sx} className={className} style={style}>
      <TableBody>
        {viewModel.termIds.map((id) => (
          <TableRow key={id}>
            <TableCell className={clsx('cell', 'termIdCell')}>
              <Typography>{Number(id)}</Typography>
            </TableCell>
            <TableCell className={clsx('cell', 'actionCell')}>
              <IconButton onClick={() => viewModel.removeTermId(id)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow className={'nextRow'}>
          <TableCell className={clsx('cell', 'termIdCell')}>
            <Input
              fullWidth
              type="number"
              value={viewModel.addingTermId}
              onChange={(e) => {
                const value = BigInt(e.currentTarget.value);

                if (!Number.isNaN(value)) {
                  viewModel.addingTermId = value;
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => viewModel.addTermId()} disabled={!viewModel.canAddTermId}>
                    <AddIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter' && viewModel.canAddTermId) {
                  viewModel.addTermId();
                }
              }}
            />
          </TableCell>
          <TableCell className={clsx('cell', 'actionCell')} />
        </TableRow>
      </TableFooter>
    </Root>
  );
});

const Root = styled(Table)(() => ({
  '.cell': {
    padding: '2px 8px 2px 0px',
    border: 'none'
  },
  '.termIdCell': {
    width: '80%'
  },
  '.actionCell': {
    width: '20%'
  },
  '.nextRow': {
    backgroundColor: grey[200]
  }
}));
