import { css } from '@emotion/css';
import { LightInsightsMuiTheme } from '@insights/theme';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  SxProps,
  ThemeProvider,
  Tooltip,
  createTheme,
  useTheme
} from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AccountModel } from '@shared/models/config';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties, Fragment } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';
import { useViewModelRef } from '../../../components';

export interface WorkloadManagerStudentListDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  students: AccountModel[];
}

export const WorkloadManagerStudentListDialog = observer((props: WorkloadManagerStudentListDialogProps) => {
  const { localizationService, settingsStore, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style, students, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createWorkloadManagerStudentListDialog(students, onSuccess!, onCancel!)
  );

  const theme = useTheme();
  const contentClassName = css({ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) });

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
        onClose={() => viewModel.close()}
        className={className}
        style={style}
      >
        <DialogTitle>{strings.localizedNumberOfStudents(viewModel.students.length)}</DialogTitle>

        <DialogContent dividers classes={{ root: contentClassName }}>
          <List disablePadding>
            {viewModel.students.map((s, index, items) => (
              <Fragment key={s.id}>
                <ListItem
                  disableGutters
                  secondaryAction={
                    !settingsStore.isEmbedded && (
                      <Tooltip title={strings.viewPlanner}>
                        <IconButton edge="end" onClick={() => void viewModel.viewPlanner(s.id)}>
                          <OpenInNewIcon />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                >
                  <ListItemText
                    primary={AccountUtils.getDisplayLastFirstName(s)}
                    secondary={
                      <Link target="_top" href={`mailto:${s.email}`}>
                        {s.email}
                      </Link>
                    }
                  ></ListItemText>
                </ListItem>
                {index < items.length - 1 && <Divider />}
              </Fragment>
            ))}
          </List>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => viewModel.close()}>
            {strings.close}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
