import { ContentService, NavigationService } from '@insights/services';
import { SchoolDay } from '@shared/models/calendar';
import { ContentStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import {
  AppWorkloadManagerDetailViewModelBase,
  WorkloadManagerDetailViewModelBase,
  WorkloadManagerInfo
} from './WorkloadManagerDetailViewModelBase';

export interface WorkloadManagerByGradeDetailInfo {
  readonly overThresholdConflicts: WorkloadManagerInfo | undefined;
  readonly atThresholdConflicts: WorkloadManagerInfo | undefined;
}

export interface WorkloadManagerByGradeDetailViewModel
  extends WorkloadManagerDetailViewModelBase<WorkloadManagerByGradeDetailInfo> {
  readonly gradeLevel: string;
  readonly gradeLevelStudentCount: number;
}

export class AppWorkloadManagerByGradeDetailViewModel
  extends AppWorkloadManagerDetailViewModelBase<WorkloadManagerByGradeDetailInfo>
  implements WorkloadManagerByGradeDetailViewModel
{
  constructor(
    onSuccess: () => void,
    onCancel: () => void,
    contentStore: ContentStore,
    schoolYearConfigurationStore: SchoolYearConfigurationStore,
    navigationService: NavigationService,
    contentService: ContentService,
    configId: string,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    includeExamOnly: boolean,
    studentIdsAtThreshold: string[],
    studentIdsOverThreshold: string[],
    public readonly gradeLevel: string,
    public readonly gradeLevelStudentCount: number
  ) {
    super(
      contentStore,
      schoolYearConfigurationStore,
      navigationService,
      contentService,
      onSuccess,
      onCancel,
      configId,
      fromDay,
      toDay,
      includeExamOnly,
      studentIdsAtThreshold,
      studentIdsOverThreshold
    );
  }

  protected async loadData(): Promise<WorkloadManagerByGradeDetailInfo> {
    const [overThresholdConflicts, atThresholdConflicts] = await Promise.all([
      this.computeConflicts(this.studentIdsOverThreshold),
      this.computeConflicts(this.studentIdsAtThreshold)
    ]);

    return {
      overThresholdConflicts,
      atThresholdConflicts
    };
  }
}
