import { EditableImportSession, ImportSession } from '@shared/models/import';
import { ImporterStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface ImportSessionEditDialogViewModel {
  name: string;
  description: string;

  readonly canSave: boolean;
  readonly isExecuting: boolean;
  readonly hasError: boolean;
  save: () => Promise<void>;
  cancel: () => void;
}

export class AppImportSessionEditDialogViewModel implements ImportSessionEditDialogViewModel {
  private readonly _editableSession: EditableImportSession;
  @observable private _isExecuting = false;
  @observable private _error: Error | undefined;

  constructor(
    private readonly _importSessionStore: ImporterStore,
    session: ImportSession,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._editableSession = new EditableImportSession(session);
  }

  @computed
  get name(): string {
    return this._editableSession.name;
  }

  set name(value: string) {
    this._editableSession.name = value;
  }

  @computed
  get description(): string {
    return this._editableSession.description;
  }

  set description(value: string) {
    this._editableSession.description = value;
  }

  @computed
  get canSave() {
    return this._editableSession.name.length > 0;
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @computed
  get hasError() {
    return this._error != null;
  }

  @action
  async save() {
    this._isExecuting = true;
    this._error = undefined;

    try {
      await this._importSessionStore.createOrUpdateImportSession(this._editableSession, false);

      runInAction(() => {
        this._isExecuting = false;
        this._error = undefined;
      });

      this._onSuccess();
    } catch (error) {
      runInAction(() => {
        this._isExecuting = false;
        this._error = error as Error;
      });
    }
  }

  cancel() {
    this._onCancel();
  }
}
