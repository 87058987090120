import {
  AccountSettings as PBAccountSettings,
  SectionColorSetting as PBSectionColorSetting
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_account_pb';
import { action, computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableStringArrayProperty, FullyEditableListProperty } from '../editables';
import { AccountSettingsModel } from './AccountSettings';
import { EditableSectionColorSetting } from './EditableSectionColorSetting';
import { SectionColorSettingModel } from './SectionColorSetting';

export class EditableAccountSettings extends EditableModelEx<PBAccountSettings> implements AccountSettingsModel {
  private _selectedSectionIds: EditableStringArrayProperty<PBAccountSettings>;
  private _sectionColors: FullyEditableListProperty<
    PBSectionColorSetting,
    SectionColorSettingModel,
    EditableSectionColorSetting,
    PBAccountSettings
  >;

  // Though the source account could have a null settings, we never work with null settings in edition.
  constructor(
    private readonly _originalAccountSettings: AccountSettingsModel,
    isNew = false
  ) {
    super(_originalAccountSettings.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._selectedSectionIds = new EditableStringArrayProperty(
        _originalAccountSettings.selectedSectionIds,
        (pb, values) => (pb.selectedSectionIds = values),
        {
          trim: true
        }
      )),
      (this._sectionColors = new FullyEditableListProperty(
        _originalAccountSettings.sectionColors.map((sc) => new EditableSectionColorSetting(sc)),
        (pb, values) => (pb.sectionColors = values)
      ))
    ]);
  }

  @computed
  get selectedSectionIds() {
    return this._selectedSectionIds.value;
  }

  set selectedSectionIds(values: string[]) {
    this._selectedSectionIds.value = values;
  }

  @computed
  get sectionColors() {
    return this._sectionColors.values;
  }

  @computed
  get editableSectionColors() {
    return this._sectionColors.values;
  }

  @action
  addSectionColor(sectionColor: EditableSectionColorSetting) {
    this._sectionColors.addItem(sectionColor);
  }

  // TODO: Delete colors

  protected createProtobuf() {
    return new PBAccountSettings();
  }
}
