import { BehaviourSummaryItemSize } from '@insights/models';
import { Box, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { OQValue } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { BehaviourSummaryItem } from './BehaviourSummaryItem';

export interface BehaviourSupplementProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  size?: BehaviourSummaryItemSize;
  invitesParent: OQValue;
}

export const BehaviourSupplement = observer((props: BehaviourSupplementProps) => {
  const { className, sx = [], style, size = 'medium', invitesParent } = props;

  const marginInfo = useMemo(() => {
    switch (size) {
      case 'small':
        return {
          margin: '-2px',
          padding: '2px'
        };

      default:
        return {
          margin: 0,
          padding: 0.5
        };
    }
  }, [size]);

  return (
    <Box
      sx={{ ...sx, border: `1px solid ${grey[300]}`, backgroundColor: grey[100], minWidth: 'min-content' }}
      className={className}
      style={style}
      borderRadius={50}
      padding={0.5}
    >
      <Box margin={marginInfo.margin} display="flex">
        <Box padding={marginInfo.padding}>
          <BehaviourSummaryItem type="parent-invitation" value={invitesParent} size={size} />
        </Box>
      </Box>
    </Box>
  );
});
