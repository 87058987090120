import { EditableTransformationColumn, OperationParameter } from '@shared/models/import';
import { computed, makeObservable } from 'mobx';

export interface EditableTransformationParameterViewModel {
  value: string;
  readonly name: string;
  readonly description: string;
  readonly isRequired: boolean;
  readonly formatHelper: string;
}

export class AppEditableTransformationParameterViewModel implements EditableTransformationParameterViewModel {
  constructor(
    private readonly _editableColumn: EditableTransformationColumn,
    private readonly _info: OperationParameter,
    private readonly _parameterIndex: number,
    private readonly _onChange: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get value() {
    return this._editableColumn.parameters[this._parameterIndex];
  }

  set value(value: string) {
    this._editableColumn.setParameterByIndex(value, this._parameterIndex);
    this._onChange();
  }

  get name() {
    return this._info.name;
  }

  get description() {
    return this._info.description;
  }

  get isRequired() {
    return !this._info.isOptional;
  }

  get formatHelper() {
    if (this._info.isMultiple) {
      return `${this._info.expectedType}|${this._info.expectedType}|…`;
    }

    return `${this._info.expectedType}`;
  }
}
