import { GoogleAccountViewModel } from '@insights/viewmodels';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { GoogleAccountConnected } from './GoogleAccountConnected.tsx';
import { GoogleAccountNotConnected } from './GoogleAccountNotConnected.tsx';

export interface GoogleAccountProps {
  sx?: SxProps;
  className?: string;
  viewModel: GoogleAccountViewModel;
}

export const GoogleAccount = observer((props: GoogleAccountProps) => {
  const { viewModel, sx = [], className } = props;
  return viewModel.isConnectedSuccessfully ? (
    <GoogleAccountConnected viewModel={viewModel} sx={sx} className={className} />
  ) : (
    <GoogleAccountNotConnected viewModel={viewModel} sx={sx} className={className} />
  );
});
