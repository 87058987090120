import { Column, Expanded, InsightsButton, Row } from '@insights/components';
import { AllCommandKinds, ExternalAssociationListViewModel } from '@insights/viewmodels';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  SxProps,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface AutoMatchAssociationsDialogProps {
  sx?: SxProps;
  // We work with the same view-model as the list, as it's a stateful view-model with pending changes.
  viewModel: ExternalAssociationListViewModel;
}

export const AutoMatchAssociationsDialog = observer((props: AutoMatchAssociationsDialogProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;
  const canApply = viewModel.matchCount > 0;

  return (
    <Root sx={sx} open={viewModel.isAutoMatching} maxWidth="lg" fullWidth>
      <DialogTitle>{strings.autoMatchTitle}</DialogTitle>
      <DialogContent>
        <Column>
          <Typography variant="body2" className="instructions">
            {strings.autoMatchInstructions}
          </Typography>

          {/* Available commands */}
          <Typography variant="body2">{strings.autoMatchCommands}</Typography>
          <Row wrap="wrap" className="commandDescriptions">
            {AllCommandKinds.map((command, i) => (
              <Row key={`command-description-${command}`}>
                <Typography variant="caption">\\{command}</Typography>
                <Typography
                  variant="caption"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                  className="commandDescription"
                >
                  {strings.getAutoMatchCommandDescription(command)}
                  {i !== AllCommandKinds.length - 1 && ','}
                </Typography>
              </Row>
            ))}
          </Row>

          <Row horizontalContentAlignment="space-evenly">
            <Column className="group">
              <TextField
                label={strings.autoMatchSectionPatternLabel}
                value={viewModel.autoMatchSectionPattern}
                onChange={(event) => (viewModel.autoMatchSectionPattern = event.target.value)}
                helperText={
                  strings.autoMatchSectionPatternHelp + viewModel.variables.map((v) => `{${v.name}}`).join(', ')
                }
                className="field"
              />
              <Column className="examples">
                {viewModel.examples.map((example, i) => (
                  <Typography variant="body2" key={`example-${i}`}>
                    {example}
                  </Typography>
                ))}
              </Column>
            </Column>
            <Column className="group">
              <TextField
                label={strings.autoMatchExternalSectionPatternLabel}
                value={viewModel.autoMatchExternalSectionPattern}
                onChange={(event) => (viewModel.autoMatchExternalSectionPattern = event.target.value)}
                helperText={
                  strings.autoMatchExternalSectionPatternHelp +
                  viewModel.externalVariables.map((v) => `{${v.name}}`).join(', ')
                }
                className="field"
              />
              <Column className="examples">
                {viewModel.externalExamples.map((example, i) => (
                  <Typography variant="body2" key={`external-example-${i}`}>
                    {example}
                  </Typography>
                ))}
              </Column>
            </Column>
          </Row>
          <Typography>
            {strings.autoMatchCount}
            {viewModel.matchCount}
          </Typography>
          <FormControlLabel
            label={strings.autoMatchSkipAlreadyMatched}
            control={
              <Checkbox
                checked={viewModel.shouldSkipAlreadyMatched}
                onChange={(event) => (viewModel.shouldSkipAlreadyMatched = event.target.checked)}
              />
            }
          />
        </Column>
        <Popover
          open={viewModel.isAutoMatchHistoryOpen}
          anchorEl={viewModel.autoMatchHistoryAnchor}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={() => viewModel.toggleAutoMatchHistory()}
        >
          <Paper>
            <MenuList>
              {viewModel.autoMatchHistory.map((entry, i) => (
                <MenuItem onClick={() => viewModel.selectAutoMatchEntry(entry)} key={`auto-match-history-${i}`}>
                  <Typography variant="body2">{entry.studyoPattern}</Typography>
                  <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.secondary }}>
                    &nbsp;↔️&nbsp;
                  </Typography>
                  <Typography variant="body2">{entry.externalPattern}</Typography>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Popover>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className="button"
          disabled={viewModel.autoMatchHistory.length === 0}
          onClick={(e) => viewModel.toggleAutoMatchHistory(e.currentTarget)}
        >
          {strings.autoMatchHistoryButton}
        </Button>
        <Expanded />
        <InsightsButton className="button" onClick={() => viewModel.cancelAutoMatch()}>
          {strings.cancelButton}
        </InsightsButton>
        <InsightsButton className="button" isDisabled={!canApply} isDefault onClick={() => viewModel.applyAutoMatch()}>
          {strings.applyButton}
        </InsightsButton>
      </DialogActions>
    </Root>
  );
});

const Root = styled(Dialog)(({ theme }) => ({
  '.headerButton': {
    margin: theme.spacing(1)
  },
  '.instructions': {
    marginBottom: theme.spacing(2)
  },
  '.commandDescription': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  '.commandDescriptions': {
    marginBottom: theme.spacing(2)
  },
  '.group': {
    marginRight: theme.spacing(2),
    flexGrow: 1
  },
  '.field': {
    marginBottom: theme.spacing(1)
  },
  '.examples': {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  '.button': { margin: theme.spacing(1) }
}));
