export interface LogoutStrings {
  title: string;
  informationText: string;
}

export const EnglishLogoutStrings: LogoutStrings = {
  title: 'Logging out',
  informationText: 'Please wait while you are being logged out…'
};

export const FrenchLogoutStrings: LogoutStrings = {
  title: 'Déconnexion',
  informationText: 'Veuillez patienter pendant la déconnexion…'
};
