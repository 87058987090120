import { SchoolYearConfiguration_SpecialDay as PBSpecialDay } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { BaseModel, SerializableModel } from '../Model';
import { Color, CycleDayEffect, SpecialDaySymbol } from '../types';
import { colorFromProtobuf, cycleDayEffectFromProtobuf, specialDaySymbolFromProtobuf } from '../types/EnumConversion';

export interface SpecialDayModel extends SerializableModel<PBSpecialDay> {
  readonly id: string;
  readonly title: string;
  readonly cycleDay: number;
  readonly cycleDayEffect: CycleDayEffect;
  readonly symbol: SpecialDaySymbol;
  readonly symbolColor: Color;
  readonly scheduleIds: string[];
  readonly isTitleVisible: boolean;
  readonly isSymbolVisible: boolean;
  readonly isEndOfTerm: boolean;
  readonly scheduleTagExceptions: string[];
}

export class SpecialDay extends BaseModel<PBSpecialDay> implements SpecialDayModel {
  constructor(pb: PBSpecialDay) {
    super(pb);
  }

  get id(): string {
    return this._pb.id;
  }

  get title(): string {
    return this._pb.title;
  }

  get symbol(): SpecialDaySymbol {
    return specialDaySymbolFromProtobuf(this._pb.symbol);
  }

  get symbolColor(): Color {
    return colorFromProtobuf(this._pb.symbolColor);
  }

  get isEndOfTerm(): boolean {
    return this._pb.isEndOfTerm;
  }

  get cycleDay(): number {
    return this._pb.cycleDay;
  }

  get cycleDayEffect(): CycleDayEffect {
    return cycleDayEffectFromProtobuf(this._pb.cycleDayEffect);
  }

  get scheduleIds(): string[] {
    return this._pb.scheduleIds;
  }

  get isTitleVisible(): boolean {
    return this._pb.isTitleVisible;
  }

  get isSymbolVisible(): boolean {
    return this._pb.isSymbolVisible;
  }

  get scheduleTagExceptions(): string[] {
    return this._pb.scheduleTagExceptions;
  }
}
