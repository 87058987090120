import { SectionModel } from '@shared/models/config';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, computedFn, fromPromise } from 'mobx-utils';

export interface TeacherDetailsFiltersInfo {
  sections: SectionModel[];
}

export interface TeacherDetailsFilters {
  excludedSectionIds: string[];
}

export interface TeacherDetailsFilterDialogViewModel {
  readonly data: IPromiseBasedObservable<TeacherDetailsFiltersInfo>;
  filters: TeacherDetailsFilters;

  readonly areAllSectionsSelected: boolean;
  readonly areNoSectionsSelected: boolean;

  isSectionSelected: (sectionId: string) => boolean;
  toggleSection: (sectionId: string, selected: boolean) => void;
  toggleAllSections: (selected: boolean) => void;

  clear: () => void;
  close: () => void;
}

export const EmptyTeacherDetailsFilters: TeacherDetailsFilters = {
  excludedSectionIds: []
};

export class AppTeacherDetailsFilterDialogViewModel implements TeacherDetailsFilterDialogViewModel {
  @observable private _filters: TeacherDetailsFilters;
  @observable private _allSectionIds: string[] = [];

  constructor(
    private readonly _configId: string,
    private readonly _teacherId: string,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _onSuccess: (filters: TeacherDetailsFilters) => void,
    private readonly _onCancel: () => void,
    initialFilters?: TeacherDetailsFilters
  ) {
    makeObservable(this);
    this._filters = initialFilters ?? EmptyTeacherDetailsFilters;
  }

  get data(): IPromiseBasedObservable<TeacherDetailsFiltersInfo> {
    return fromPromise(this.loadData());
  }

  @computed.struct
  get filters(): TeacherDetailsFilters {
    return this._filters;
  }

  set filters(value: TeacherDetailsFilters) {
    this._filters = value;
  }

  @computed
  get areAllSectionsSelected(): boolean {
    return this.filters.excludedSectionIds.length === 0;
  }

  @computed
  get areNoSectionsSelected(): boolean {
    return this.filters.excludedSectionIds.length === this._allSectionIds.length;
  }

  isSectionSelected = computedFn((sectionId: string): boolean => {
    return !(this.filters.excludedSectionIds.includes(sectionId) ?? false);
  });

  toggleSection(sectionId: string, selected: boolean) {
    let excludedSectionIds: string[] = [];

    if (selected) {
      excludedSectionIds = this.filters.excludedSectionIds?.filter((id) => id !== sectionId) || [];
    } else {
      excludedSectionIds = [...this.filters.excludedSectionIds, sectionId];
    }

    this.filters = {
      ...this.filters,
      excludedSectionIds: excludedSectionIds
    };
  }

  toggleAllSections(selected: boolean): void {
    const excludedSectionIds: string[] = selected ? [] : this._allSectionIds;

    this.filters = {
      ...this.filters,
      excludedSectionIds: excludedSectionIds
    };
  }

  @action
  clear() {
    this._filters = EmptyTeacherDetailsFilters;
  }

  close() {
    this._onSuccess(this.filters);
  }

  private async loadData(): Promise<TeacherDetailsFiltersInfo> {
    const sections = await this._schoolYearConfigurationStore.getTaughtSectionsForTeacherId(
      this._configId,
      this._teacherId
    );

    runInAction(() => (this._allSectionIds = sections.map((section) => section.id)));

    return {
      sections
    };
  }
}
