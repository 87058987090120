import { caseInsensitiveAccentInsensitiveCompare } from '@insights/utils';
import { SchoolCalendarSpecialDayViewModel } from '@insights/viewmodels';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Typography
} from '@mui/material';

import { ScheduleModel } from '@shared/models/config';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column, Row } from '../layout';

export interface EditableSpecialDaySchedulesProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarSpecialDayViewModel;
  schedules: ScheduleModel[];
}

export const EditableSpecialDaySchedules = observer((props: EditableSpecialDaySchedulesProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, schedules, style, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Column sx={sx} className={className} style={style}>
      <FormControl sx={{ mr: 2, minWidth: 300 }}>
        <InputLabel htmlFor="schedules-select" shrink={true}>
          {strings.bellTimes}
        </InputLabel>
        <Select
          variant="filled"
          value={viewModel.schedules.map((s) => s.id)}
          multiple
          inputProps={{ id: 'schedules-select', placeholder: strings.none }}
          onChange={(event) => viewModel.setScheduleIds(event.target.value as unknown as string[])}
          renderValue={() => (
            <Row>
              {/* In the order of selection */}
              {viewModel.schedules.map((schedule, index) => (
                <>
                  {index > 0 && <Typography key={`schedules-selected-comma-${index}`}>,&nbsp;</Typography>}
                  <Typography key={`schedules-selected-${schedule.id}`}>{schedule.title}</Typography>
                </>
              ))}
            </Row>
          )}
        >
          {/* Sorted by title */}
          {schedules
            .slice()
            .sort((a, b) => caseInsensitiveAccentInsensitiveCompare(a.title, b.title))
            .map((schedule) => (
              <MenuItem key={`schedules-select-item-${schedule.id}`} value={schedule.id}>
                <Checkbox size="small" checked={viewModel.schedules.find((s) => s.id === schedule.id) != null} />
                <Typography>{schedule.title}</Typography>
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControlLabel
        control={
          <Checkbox
            value="is-end-of-term"
            checked={viewModel.isEndOfTerm}
            onChange={(event) => (viewModel.isEndOfTerm = event.target.checked)}
          />
        }
        label={strings.endOfTermMarker}
      />
    </Column>
  );
});
