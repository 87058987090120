export interface ContentRepeatStrings {
  readonly cancel: string;
  readonly repeat: string;

  readonly repeatKind: string;
  readonly repeatEveryOccurrence: string;
  readonly repeatEveryDay: string;
  readonly repeatEveryDayOfWeek: string;
  readonly repeatEveryCycleDay: string;

  readonly weekCountLabel: string;
  readonly weekCount: (weeks: number) => string;
  readonly weekStart: string;
  readonly daysOfWeek: string;
  readonly cycleDay: string;
  readonly untilDate: string;
  readonly noDaysOfWeek: string;

  readonly savingTitle: string;
  readonly savingDescription: string;
  readonly savedTitle: string;
  readonly savedDescription: string;
  readonly saveErrorTitle: string;
  readonly saveErrorDescription: string;
  readonly savePermanentErrorDescription: string;
}

export const EnglishContentRepeatStrings: ContentRepeatStrings = {
  cancel: 'Cancel',
  repeat: 'Repeat',

  repeatKind: 'Repeat…',
  repeatEveryOccurrence: 'Every occurrence',
  repeatEveryDay: 'Every day',
  repeatEveryDayOfWeek: 'By week',
  repeatEveryCycleDay: 'On a specific cycle day',

  weekCountLabel: 'Every',
  // w > 0
  weekCount: (w) => (w === 1 ? 'week' : `${w} weeks`),
  weekStart: 'Sunday is the beginning of each week',
  daysOfWeek: 'Days of week',
  cycleDay: 'Cycle day',
  untilDate: 'Repeat until',
  noDaysOfWeek: 'Select at least one',

  savingTitle: 'Please wait…',
  savingDescription: 'Creating multiple copies of this task.',
  savedTitle: 'Success',
  savedDescription: 'The copies were created successfully!',
  saveErrorTitle: 'Oops, something went wrong!',
  saveErrorDescription: 'An error occurred while repeating this task. Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented the save. Please reload and try again.'
};

export const FrenchContentRepeatStrings: ContentRepeatStrings = {
  cancel: 'Annuler',
  repeat: 'Répéter',

  repeatKind: 'Répéter…',
  repeatEveryOccurrence: 'À chaque occurrence',
  repeatEveryDay: 'À chaque jour',
  repeatEveryDayOfWeek: 'Par semaine',
  repeatEveryCycleDay: 'Chaque jour X du cycle',

  weekCountLabel: 'Chaque',
  // w > 0
  weekCount: (w) => (w === 1 ? 'semaine' : `${w} semaines`),
  weekStart: 'Dimanche est le début de chaque semaine',
  daysOfWeek: 'Jours de la semaine',
  cycleDay: 'Jour du cycle',
  untilDate: 'Répéter jusqu´au',
  noDaysOfWeek: 'En choisir au moins un',

  savingTitle: 'Veuillez patienter…',
  savingDescription: 'Création des copies de cette tâche en cours',
  savedTitle: 'Succès',
  savedDescription: 'Les copies ont été créées!',
  saveErrorTitle: 'Oups, une erreur est survenue!',
  saveErrorDescription: 'La tâche n’a pu être répétée. Veuillez réessayer.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la sauvegarde. Veuillez rafraichir et réessayer.'
};
