import { ActiveUsersPeriod } from '@shared/models/types';

export enum PeriodDisplay {
  Daily,
  Weekly,
  Monthly
}

export function activeUsersPeriodFromPeriodDisplay(period: PeriodDisplay): ActiveUsersPeriod {
  switch (period) {
    case PeriodDisplay.Daily:
      return 'daily';

    case PeriodDisplay.Weekly:
      return 'weekly';

    case PeriodDisplay.Monthly:
      return 'monthly';

    default:
      throw new Error('Unsupported period');
  }
}
