export interface EditViewModelStrings {
  cancelButtonCaption: string;
  discardButtonCaption: string;
  pendingChangesMessage: string;
  saveErrorMessage: string;

  demoErrorMessage: (error: Error) => string;
}

export const EnglishEditViewModelStrings: EditViewModelStrings = {
  cancelButtonCaption: 'Cancel',
  discardButtonCaption: 'Discard',
  pendingChangesMessage: 'There are some pending changes. Are you sure you want to discard them?',
  saveErrorMessage: 'An error occurred while saving. Please try again or contact support.',

  demoErrorMessage: (error) => `Saving is not allowed in demo mode: ${error.message}`
};

export const FrenchEditViewModelStrings: EditViewModelStrings = {
  cancelButtonCaption: 'Annuler',
  discardButtonCaption: 'Abandonner',
  pendingChangesMessage: 'Il y a des changements en cours. Êtes-vous certain de vouloir les abandonner?',
  saveErrorMessage: 'Une erreur est survenue durant la sauvegarde. Veuillez réessayer ou contacter le support.',

  demoErrorMessage: (error) => `La sauvegarde n’est pas autorisée en mode démo: ${error.message}`
};
