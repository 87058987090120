import { ExternalAccountKind } from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import { Integration, ExternalAccountKind as ModelExternalAccountKind } from '@shared/models/types';
import { protobufFromExternalAccountKind } from '@shared/models/types/EnumConversion';

export function getExternalAccountKindFromIntegration(integration: Integration): ModelExternalAccountKind | undefined {
  switch (integration) {
    case 'blackbaud':
      return 'blackbaud';
    case 'blackbaud-sky-connector':
      return 'blackbaud-sky';
    case 'canvas':
      return 'canvas';
    case 'google-classroom':
    case 'google-classroom-connector':
      return 'google';
    case 'schoology-connector':
      return 'schoology';
    case 'veracross-connector':
      return 'veracross';
    case 'veracross-v3-connector':
      return 'veracross-v3';
    case 'managebac-connector':
      return 'managebac';
    case 'microsoft-teams-connector':
      return 'microsoft-teams';
    case 'moodle-connector':
      return 'moodle';
    case 'studyo-internal':
      return 'studyo-internal';
    case 'ical':
      return 'calendars';
    default:
      return undefined;
  }
}

export function getBufExternalAccountKindFromIntegration(integration: Integration): ExternalAccountKind | undefined {
  const kind = getExternalAccountKindFromIntegration(integration);
  return kind == null ? undefined : protobufFromExternalAccountKind(kind);
}
