import { AccountEditionViewModel } from '@insights/viewmodels';
import { Box, styled, SxProps, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { AccountUtils } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column, Container, Expanded, Row } from '../layout';
import { AccountSectionList } from './AccountSectionList';
import { AccountSectionPicker } from './AccountSectionPicker';

export interface AccountSectionsEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: AccountEditionViewModel;
}

export const AccountSectionsEdition = observer((props: AccountSectionsEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, viewModel, style } = props;
  const strings = localizationService.localizedStrings.insights.components.accounts;

  return (
    <Root sx={sx} className={className} horizontalContentAlignment="stretch">
      <Row>
        <Typography variant="h6">{strings.sectionsEditionTitle}</Typography>
        &nbsp;&nbsp;
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          {AccountUtils.getDisplayFirstLastName(viewModel.editableAccount)}
        </Typography>
      </Row>
      {viewModel.editableAccount.role === 'teacher' && (
        <Box className="teacherNotification">
          <Typography variant="h5">{strings.teacherSelectedSectionsWarningTitle}</Typography>
          <Typography variant="subtitle1">{strings.teacherSelectedSectionsWarning}</Typography>
          <Typography variant="subtitle1">{strings.teacherSelectedSectionsWarning2}</Typography>
        </Box>
      )}
      {viewModel.hasMissingSection && (
        <Typography variant="body2" color={'error'}>
          {strings.missingSectionWarning}
        </Typography>
      )}
      <Row style={style}>
        <Expanded>
          <AccountSectionList account={viewModel} />
        </Expanded>
        <Expanded style={{ flex: 2.5 }}>
          <Container sx={{ ml: 1 }}>
            <AccountSectionPicker account={viewModel} />
          </Container>
        </Expanded>
      </Row>
    </Root>
  );
});

const Root = styled(Column)(({ theme }) => ({
  '.teacherNotification': {
    padding: theme.spacing(2),
    backgroundColor: red[500],
    color: theme.palette.common.white
  }
}));
