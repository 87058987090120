import {
  EnglishPeriodPrioritySelectorStrings,
  FrenchPeriodPrioritySelectorStrings,
  PeriodPrioritySelectorStrings
} from './PeriodPrioritySelectorStrings';
import { EnglishSaveDialogStrings, FrenchSaveDialogStrings, SaveDialogStrings } from './SaveDialogStrings';

export interface UtilsStrings {
  readonly saveDialog: SaveDialogStrings;
  readonly dateSwitcherGoToToday: string;
  readonly dayInfoGoToDay: string;
  readonly noInternetIndicator: string;
  readonly updateAvailable: string;
  readonly updateAvailableAndroid: string;
  readonly alertDialogCancel: string;
  readonly alertDialogOk: string;

  readonly periodPrioritySelector: PeriodPrioritySelectorStrings;
}

export const EnglishUtilsStrings: UtilsStrings = {
  saveDialog: EnglishSaveDialogStrings,
  dateSwitcherGoToToday: 'Go to today',
  dayInfoGoToDay: 'Go to that day',
  noInternetIndicator: 'No internet connection available',
  updateAvailable: 'An update is available. Click here to reload and install the new version.',
  updateAvailableAndroid: 'An update is available via the Play Store.',
  alertDialogCancel: 'Cancel',
  alertDialogOk: 'OK',

  periodPrioritySelector: EnglishPeriodPrioritySelectorStrings
};

export const FrenchUtilsStrings: UtilsStrings = {
  saveDialog: FrenchSaveDialogStrings,
  dateSwitcherGoToToday: 'Aller à aujourd’hui',
  dayInfoGoToDay: 'Aller à cette journée',
  noInternetIndicator: 'Aucune connexion internet disponible',
  updateAvailable: 'Une nouvelle version est disponible. Cliquez ici pour relancer et installer la nouvelle version.',
  updateAvailableAndroid: 'Une mise à jour est disponible sur le Play Store.',
  alertDialogCancel: 'Annuler',
  alertDialogOk: 'Ok',

  periodPrioritySelector: FrenchPeriodPrioritySelectorStrings
};
