import { GenericImages } from '@shared/resources/images/studyo';
import gearSyncImage from '@shared/web/images/assets/studyo/gear_sync.svg?url';
import actionSheetImage from '@shared/web/images/assets/studyo/ui_action_sheet.svg?url';
import addImage from '@shared/web/images/assets/studyo/ui_add.png';
import arrowDownImage from '@shared/web/images/assets/studyo/ui_arrow_down.svg?url';
import arrowLeftImage from '@shared/web/images/assets/studyo/ui_arrow_left.svg?url';
import arrowReorderImage from '@shared/web/images/assets/studyo/ui_arrow_reorder.svg?url';
import arrowRightImage from '@shared/web/images/assets/studyo/ui_arrow_right.svg?url';
import arrowUpImage from '@shared/web/images/assets/studyo/ui_arrow_up.svg?url';
import backAndroidImage from '@shared/web/images/assets/studyo/ui_back_android.png';
import backIosImage from '@shared/web/images/assets/studyo/ui_back_ios.png';
import calendarImage from '@shared/web/images/assets/studyo/ui_calendar.svg?url';
import cameraImage from '@shared/web/images/assets/studyo/ui_camera.svg?url';
import cancelAndroidImage from '@shared/web/images/assets/studyo/ui_cancel_android.png';
import checkmarkImage from '@shared/web/images/assets/studyo/ui_checkmark.svg?url';
import copyImage from '@shared/web/images/assets/studyo/ui_copy.svg?url';
import disclosureImage from '@shared/web/images/assets/studyo/ui_disclosure.png';
import dragHandleImage from '@shared/web/images/assets/studyo/ui_drag_handle.svg?url';
import editImage from '@shared/web/images/assets/studyo/ui_edit.svg?url';
import envelopeImage from '@shared/web/images/assets/studyo/ui_envelope.svg?url';
import errorImage from '@shared/web/images/assets/studyo/ui_error.svg?url';
import gearImage from '@shared/web/images/assets/studyo/ui_gear.svg?url';
import googleImage from '@shared/web/images/assets/studyo/ui_google.svg?url';
import infoImage from '@shared/web/images/assets/studyo/ui_info.svg?url';
import insightsLogoImage from '@shared/web/images/assets/studyo/ui_insights_logo.svg?url';
import logoTextOnlyDarkImage from '@shared/web/images/assets/studyo/ui_logo_text_only_dark.svg?url';
import logoTextOnlyLightImage from '@shared/web/images/assets/studyo/ui_logo_text_only_light.svg?url';
import moreImage from '@shared/web/images/assets/studyo/ui_more.svg?url';
import onboardingBackgroundImage from '@shared/web/images/assets/studyo/ui_onboarding_background.png';
import optionsImage from '@shared/web/images/assets/studyo/ui_options.svg?url';
import removeObjectImage from '@shared/web/images/assets/studyo/ui_remove_object.svg?url';
import searchImage from '@shared/web/images/assets/studyo/ui_search.svg?url';
import studyoGoLogoDarkImage from '@shared/web/images/assets/studyo/ui_studyo_go_logo_dark.svg?url';
import studyoGoLogoLightImage from '@shared/web/images/assets/studyo/ui_studyo_go_logo_light.svg?url';
import todayLeftImage from '@shared/web/images/assets/studyo/ui_today_left.svg?url';
import todayRightImage from '@shared/web/images/assets/studyo/ui_today_right.svg?url';

export class WebGenericImages implements GenericImages {
  get actionSheet() {
    return actionSheetImage;
  }

  get add() {
    return addImage;
  }

  get arrowReorder() {
    return arrowReorderImage;
  }

  get arrowLeft() {
    return arrowLeftImage;
  }

  get arrowRight() {
    return arrowRightImage;
  }

  get arrowUp() {
    return arrowUpImage;
  }

  get arrowDown() {
    return arrowDownImage;
  }

  get backAndroid() {
    return backAndroidImage;
  }
  get backIOS() {
    return backIosImage;
  }

  get camera() {
    return cameraImage;
  }

  get calendar() {
    return calendarImage;
  }

  get cancelAndroid() {
    return cancelAndroidImage;
  }

  get checkmark() {
    return checkmarkImage;
  }

  get copy() {
    return copyImage;
  }

  get disclosure() {
    return disclosureImage;
  }

  get dragHandle() {
    return dragHandleImage;
  }

  get edit() {
    return editImage;
  }

  get envelope() {
    return envelopeImage;
  }

  get error() {
    return errorImage;
  }

  get gear() {
    return gearImage;
  }

  get googleLogo() {
    return googleImage;
  }

  get insightsLogo() {
    return insightsLogoImage;
  }

  get studyoGoLogoDark() {
    return studyoGoLogoDarkImage;
  }

  get studyoGoLogoLight() {
    return studyoGoLogoLightImage;
  }

  get infoIcon() {
    return infoImage;
  }

  get logoTextOnlyDark() {
    return logoTextOnlyDarkImage;
  }

  get logoTextOnlyLight() {
    return logoTextOnlyLightImage;
  }

  get more() {
    return moreImage;
  }

  get onboardingBackground() {
    return onboardingBackgroundImage;
  }

  get optionMenu() {
    return optionsImage;
  }

  get reload() {
    return gearSyncImage;
  }

  get search() {
    return searchImage;
  }

  get skipped() {
    return removeObjectImage;
  }

  get todayArrowLeft() {
    return todayLeftImage;
  }

  get todayArrowRight() {
    return todayRightImage;
  }
}
