import { EditableImportSession, EditableSourceFile, ImportSession } from '@shared/models/import';
import { ImporterStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface OrderedHeaderViewModel {
  readonly id: string;
  value: string;
  readonly isExisting: boolean;

  remove(): void;
}

export interface ImportSessionFileOrderedHeadersDialogViewModel {
  readonly currentHeaders: string | undefined;
  readonly headers: OrderedHeaderViewModel[];

  readonly canSave: boolean;
  readonly isExecuting: boolean;
  readonly hasError: boolean;

  addHeader(): void;
  addAllHeaders(): void;
  moveHeader(oldIndex: number, newIndex: number): void;

  save: () => Promise<void>;
  cancel: () => void;
}

export class AppOrderedHeaderViewModel implements OrderedHeaderViewModel {
  constructor(
    public readonly id: string,
    private readonly _editableFile: EditableSourceFile,
    private readonly _index: number,
    private readonly _currentHeaders: Set<string>
  ) {
    makeObservable(this);
  }

  @computed
  get value(): string {
    return this._editableFile.orderedHeaders[this._index];
  }

  set value(value: string) {
    this._editableFile.setOrderedHeaderByIndex(value, this._index);
  }

  @computed
  get isExisting(): boolean {
    return this._currentHeaders.has(this.value);
  }

  @action
  remove(): void {
    this._editableFile.removeOrderedHeader(this._index);
  }
}

export class AppImportSessionFileOrderedHeadersDialogViewModel
  implements ImportSessionFileOrderedHeadersDialogViewModel
{
  private readonly _editableSession: EditableImportSession;
  private readonly _editableFile: EditableSourceFile;
  @observable private _isExecuting = false;
  @observable private _error: Error | undefined;
  private readonly _currentHeaders: string[] | undefined;
  private readonly _currentHeadersSet: Set<string>;

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _configId: string,
    session: ImportSession,
    sourceFileLabel: string,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._editableSession = new EditableImportSession(session);
    const editableFile = this._editableSession.expectedFiles.find((f) => f.label === sourceFileLabel);

    if (editableFile == null) {
      throw new Error('Cannot find a file with that label.');
    }

    this._editableFile = editableFile;

    const data = session.data.find((data) => data.label === sourceFileLabel);
    this._currentHeaders = data?.columnNames;
    this._currentHeadersSet = new Set(this._currentHeaders ?? []);
  }

  @computed
  get headers(): OrderedHeaderViewModel[] {
    return this._editableFile.orderedHeaders.map(
      (h, i) => new AppOrderedHeaderViewModel(String(i), this._editableFile, i, this._currentHeadersSet)
    );
  }

  @action
  addHeader(): void {
    const suggestedName =
      this._currentHeaders?.find((h) => !this._editableFile.orderedHeaders.includes(h)) ?? 'New column';

    this._editableFile.orderedHeaders = this._editableFile.orderedHeaders.slice().concat(suggestedName);
  }

  @action
  addAllHeaders(): void {
    const missingHeaders = this._currentHeaders?.filter((h) => !this._editableFile.orderedHeaders.includes(h)) ?? [];

    if (missingHeaders.length > 0) {
      this._editableFile.orderedHeaders = this._editableFile.orderedHeaders.slice().concat(missingHeaders);
    }
  }

  @action
  moveHeader(oldIndex: number, newIndex: number): void {
    this._editableFile.moveOrderedHeader(oldIndex, newIndex);
  }

  @computed
  get currentHeaders(): string | undefined {
    return this._currentHeaders?.join(', ');
  }

  @computed
  get canSave() {
    return this._editableFile.hasChanges;
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @computed
  get hasError() {
    return this._error != null;
  }

  @action
  async save() {
    if (!this._editableFile.hasChanges) {
      return;
    }

    this._isExecuting = true;
    this._error = undefined;

    try {
      // We simply need to save the session.
      await this._importSessionStore.createOrUpdateImportSession(this._editableSession, false);

      runInAction(() => {
        this._isExecuting = false;
        this._error = undefined;
      });

      this._onSuccess();
    } catch (error) {
      runInAction(() => {
        this._isExecuting = false;
        this._error = error as Error;
      });
    }
  }

  cancel() {
    this._onCancel();
  }
}
