import * as IPB from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/internal_pb';
import { InternalConnector } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/internal_connect';
import { StudyoInternalConnectorTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class StudyoInternalConnectorGrpcTransport
  extends BaseGrpcTransport
  implements StudyoInternalConnectorTransport
{
  async createOrUpdateExternalAccount(
    externalAccountId: string | undefined,
    configId: string,
    accountName: string
  ): Promise<IPB.CreateOrUpdateExternalAccountResponse> {
    console.log('Creating or updating Studyo Internal Connector external account...');

    const request = new IPB.CreateOrUpdateExternalAccountRequest();
    request.externalAccountId = externalAccountId ?? '';
    request.configId = configId;
    request.accountName = accountName;

    const response = await this.performRequest(
      InternalConnector,
      InternalConnector.methods.createOrUpdateExternalAccount,
      request
    );

    console.log('Successfully created or updated Studyo Internal Connector external account.');

    return response;
  }
}
