import { caseInsensitiveAccentInsensitiveCompare } from '@insights/utils';
import { AccountModel, TermModel } from '@shared/models/config';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export type ConnectionStatus =
  | 'any'
  | 'connected'
  | 'connected-success'
  | 'connected-error'
  | 'connected-orphan'
  | 'connected-unowned'
  | 'not-connected';

export const AllConnectionStatuses: ConnectionStatus[] = [
  'any',
  'not-connected',
  'connected',
  'connected-success',
  'connected-error',
  'connected-unowned',
  'connected-orphan'
];

export interface ExternalAssociationListFilters {
  readonly term?: TermModel;
  readonly teacherId?: string;
  readonly connectionStatus: ConnectionStatus;
}

export interface ExternalAssociationListFiltersDialogInfo {
  readonly availableTerms: TermModel[];
  readonly availableTeachers: AccountModel[];
}

export interface ExternalAssociationListFiltersDialogViewModel {
  readonly data: IPromiseBasedObservable<ExternalAssociationListFiltersDialogInfo>;
  filters: ExternalAssociationListFilters;

  setTerm(term?: TermModel): void;
  setTeacherId(teacherId?: string): void;
  setConnectionStatus(status: ConnectionStatus): void;

  clear: () => void;
  close: () => void;
}

export const EmptyExternalAssociationListFilters: ExternalAssociationListFilters = {
  teacherId: '',
  term: undefined,
  connectionStatus: 'any'
};

export class AppExternalAssociationListFiltersDialogViewModel implements ExternalAssociationListFiltersDialogViewModel {
  @observable private _filters: ExternalAssociationListFilters;

  constructor(
    private readonly _configId: string,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _onSuccess: (filters: ExternalAssociationListFilters) => void,
    private readonly _onCancel: () => void,
    initialFilters?: ExternalAssociationListFilters
  ) {
    makeObservable(this);
    this._filters = initialFilters ?? EmptyExternalAssociationListFilters;
  }

  get data(): IPromiseBasedObservable<ExternalAssociationListFiltersDialogInfo> {
    return fromPromise(this.loadData());
  }

  @computed.struct
  get filters(): ExternalAssociationListFilters {
    return this._filters;
  }

  set filters(value: ExternalAssociationListFilters) {
    this._filters = value;
  }

  @action
  setTerm(term?: TermModel): void {
    this._filters = {
      term: term,
      teacherId: this._filters.teacherId,
      connectionStatus: this._filters.connectionStatus
    };
  }

  @action
  setTeacherId(teacherId?: string): void {
    this._filters = {
      term: this._filters.term,
      teacherId: teacherId,
      connectionStatus: this._filters.connectionStatus
    };
  }

  @action
  setConnectionStatus(status: ConnectionStatus): void {
    this._filters = {
      term: this._filters.term,
      teacherId: this._filters.teacherId,
      connectionStatus: status
    };
  }

  @action
  clear() {
    this._filters = EmptyExternalAssociationListFilters;
  }

  close() {
    this._onSuccess(this.filters);
  }

  private async loadData(): Promise<ExternalAssociationListFiltersDialogInfo> {
    const [config, teachers] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this._configId),
      this._schoolYearConfigurationStore.getTeachers(this._configId, false)
    ]);

    // Sort in place
    teachers.sort(
      (a, b) =>
        caseInsensitiveAccentInsensitiveCompare(a.lastName, b.lastName) ||
        caseInsensitiveAccentInsensitiveCompare(a.firstName, b.firstName)
    );

    return {
      availableTerms: config.terms,
      availableTeachers: teachers
    };
  }
}
