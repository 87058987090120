import { ContentDefinition_ContentIcon as PBContentIcon } from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import * as MB from '@buf/studyo_studyo.bufbuild_es/studyo/type_managebac_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableValuePropertyEx } from '../editables';
import { ContentIcon, ContentWorkloadLevel } from '../types';
import { protobufFromContentIcon, protobufFromContentWorkloadLevel } from '../types/EnumConversion';
import { GrpcManageBacContentRepresentation } from './GrpcManageBacContentRepresentation';
import { ManageBacContentRepresentationModel } from './interfaces';

export class EditableManageBacContentRepresentation
  extends EditableModelEx<MB.ContentRepresentation>
  implements ManageBacContentRepresentationModel
{
  private _icon: EditableValuePropertyEx<ContentIcon, MB.ContentRepresentation>;
  private _workloadLevel: EditableValuePropertyEx<ContentWorkloadLevel, MB.ContentRepresentation>;

  static createNew(): EditableManageBacContentRepresentation {
    const pb = new MB.ContentRepresentation();
    pb.icon = PBContentIcon.HOMEWORK;

    return new EditableManageBacContentRepresentation(new GrpcManageBacContentRepresentation(pb), true);
  }

  constructor(
    readonly originalRepresentation: ManageBacContentRepresentationModel,
    readonly isNew = false
  ) {
    super(originalRepresentation.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._icon = new EditableValuePropertyEx(
        originalRepresentation.icon,
        (pb, value) => (pb.icon = protobufFromContentIcon(value))
      )),
      (this._workloadLevel = new EditableValuePropertyEx(
        originalRepresentation.workloadLevel,
        (pb, value) => (pb.workloadLevel = protobufFromContentWorkloadLevel(value))
      ))
    ]);
  }

  @computed
  get icon() {
    return this._icon.value;
  }

  set icon(value: ContentIcon) {
    this._icon.value = value;
  }

  @computed
  get workloadLevel() {
    return this._workloadLevel.value;
  }

  set workloadLevel(value: ContentWorkloadLevel) {
    this._workloadLevel.value = value;
  }
}
