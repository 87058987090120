import {
  AuthorizationRoleCondition,
  ObservablePresenter,
  OnboardingProcessView,
  PageHeaderBar
} from '@insights/components';
import ShowIcon from '@mui/icons-material/Visibility';
import HideIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MaxLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface OnboardingProcessesScreenProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const OnboardingProcessesScreen = observer((props: OnboardingProcessesScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const params = useParams();
  const configId = params.configId ?? '';
  const processName = params.processName ?? undefined;
  const viewModel = useMemo(
    () => viewModelFactory.createOnboardingProcessesScreen(configId, processName),
    [configId, processName]
  );

  return (
    <Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar>
        <AuthorizationRoleCondition allowedRoles={['super-admin', 'studyo-staff']}>
          <Tooltip title={strings.hideLockedContentTooltip}>
            <IconButton sx={{ mt: 1 }} onClick={() => viewModel.toggleLockedContentVisibility()}>
              {viewModel.isLockedContentVisible ? <HideIcon fontSize="small" /> : <ShowIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
        </AuthorizationRoleCondition>
      </PageHeaderBar>

      <Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, height: '100%', width: '100%', overflow: 'auto' }}
          data={viewModel.processes}
          loadingMessage={strings.loadingProcessMessage}
          errorMessage={strings.loadingProcessErrorMessage}
          render={(processes) => (
            <Box display="flex" flexDirection="column">
              <Box height="100%" width="100%" maxWidth={MaxLayoutWidth}>
                {processes.length === 0 && (
                  <Box>
                    <Typography variant="h6">{strings.noProcessesTitle}</Typography>
                    <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                      <Button sx={{ mr: 1 }} variant="contained" onClick={() => void viewModel.addProcess()}>
                        {strings.addProcessLabel}
                      </Button>
                    </AuthorizationRoleCondition>
                  </Box>
                )}
                {processes.map((p) => (
                  <OnboardingProcessView key={`process-${p.id}`} viewModel={p} />
                ))}
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
