export interface TeacherMetricViewsStrings {
  connectedTeachers: string;
  dailyActiveTeachers: string;
  exportNotesTooltip: string;
  filterButtonTooltip: string;
  publishedTaskTitle: string;
  publishedTaskTooltip: string;
  loadingActiveTeachersErrorMessage: string;
  loadingActiveTeachersMessage: string;
  loadingTeacherAccountsErrorMessage: string;
  loadingTeacherAccountsMessage: string;
  loadingTeacherDetailsErrorMessage: string;
  loadingTeacherDetailsMessage: string;
  loadingTeachersErrorMessage: string;
  loadingTeacherSessionsPerDayErrorMessage: string;
  loadingTeacherSessionsPerDayMessage: string;
  loadingTeachersMessage: string;
  monthlyActiveTeachers: string;
  schoolNotStartedMessage: string;
  noActiveTeachers: string;
  noName: string;
  numberOfConnectedTeachers: string;
  numberOfDailyActiveTeachers: string;
  numberOfMonthlyActiveTeachers: string;
  numberOfWeeklyActiveTeachers: string;
  openedStudyoTitle: string;
  openedStudyoTooltip: string;
  pastMonth: string;
  pastWeek: string;
  sessionsPerDay: string;
  sessionsPerDayEmptyMessage: string;
  sessionsPerDaySubcaption: string;
  taughtSections: string;
  teachers: string;
  teachersTotal: string;
  thisYear: string;
  viewPlanner: string;
  weeklyActiveTeachers: string;
}

export const EnglishTeacherMetricViewsStrings: TeacherMetricViewsStrings = {
  connectedTeachers: 'connected teachers',
  dailyActiveTeachers: 'Daily Active Teachers',
  exportNotesTooltip: 'Export all my notes',
  filterButtonTooltip: 'Filter',
  publishedTaskTitle: 'Published a task',
  publishedTaskTooltip: 'Published a task',
  loadingActiveTeachersErrorMessage: 'An error has occurred while loading active teachers. Please reload.',
  loadingActiveTeachersMessage: 'Loading active teachers. Please wait...',
  loadingTeacherAccountsErrorMessage: 'An error has occurred while loading teacher accounts. Please reload.',
  loadingTeacherAccountsMessage: 'Loading the teacher accounts',
  loadingTeacherDetailsErrorMessage: 'An error has occurred while loading teacher details. Please reload.',
  loadingTeacherDetailsMessage: 'Loading teacher details. Please wait...',
  loadingTeachersErrorMessage: 'An error has occurred while loading teacher details',
  loadingTeacherSessionsPerDayErrorMessage: 'An error has occurred while loading sessions per day. Please reload.',
  loadingTeacherSessionsPerDayMessage: 'Loading sessions per day. Please wait...',
  loadingTeachersMessage: 'Loading teachers. Please wait...',
  monthlyActiveTeachers: 'Monthly Active Teachers',
  schoolNotStartedMessage: 'School has not started yet.',
  noActiveTeachers: 'There are no active teachers.',
  noName: '(no name)',
  numberOfConnectedTeachers: 'Number of connected teachers',
  numberOfDailyActiveTeachers: 'Number of active teachers per day',
  numberOfMonthlyActiveTeachers: 'Number of active teachers per month',
  numberOfWeeklyActiveTeachers: 'number of active teachers per week',
  openedStudyoTitle: 'Opened Studyo',
  openedStudyoTooltip: 'opened Studyo',
  pastMonth: 'Past Month',
  pastWeek: 'Past Week',
  sessionsPerDay: 'Sessions per Day',
  sessionsPerDayEmptyMessage: 'There are no sessions',
  sessionsPerDaySubcaption: 'Number of times per day teacher opened Studyo',
  taughtSections: 'Taught Sections',
  teachers: 'Teachers',
  teachersTotal: 'teacher records',
  thisYear: 'This Year',
  viewPlanner: 'View planner',
  weeklyActiveTeachers: 'Weekly Active Teachers'
};

export const FrenchTeacherMetricViewsStrings: TeacherMetricViewsStrings = {
  connectedTeachers: 'enseignants actifs',
  dailyActiveTeachers: 'Enseignants actifs quotidiens',
  exportNotesTooltip: 'Exporter toutes mes notes',
  filterButtonTooltip: 'Filtrer',
  publishedTaskTitle: 'Ont publié une tâche',
  publishedTaskTooltip: 'ont publié une tâche',
  loadingActiveTeachersErrorMessage:
    'Une erreur est survenue lors du chargement des enseignants actifs. Merci de recharger.',
  loadingActiveTeachersMessage: 'Chargement des enseignants actifs. Merci de patienter',
  loadingTeacherAccountsErrorMessage:
    'Une erreur est survenue lors du chargement des enseignants actifs. Merci de recharger.',
  loadingTeacherAccountsMessage: 'Chargement des enseignants actifs',
  loadingTeacherDetailsErrorMessage:
    'Une erreur est survenue lors du chargement des détails d’enseignants. Merci de recharger.',
  loadingTeacherDetailsMessage: 'Chargement des détails d’enseignants. Merci de patienter...',
  loadingTeachersErrorMessage: 'Une erreur est survenue lors du chargement des enseignants',
  loadingTeacherSessionsPerDayErrorMessage:
    'Une erreur est survenue lors du chargement des sessions quotidiennes. Merci de recharger.',
  loadingTeacherSessionsPerDayMessage: 'Chargement des sessions quotidiennes... Merci de patienter',
  loadingTeachersMessage: 'Chargement des enseignants. Merci de patienter...',
  monthlyActiveTeachers: 'Enseignants Actifs Mensuels',
  schoolNotStartedMessage: 'L’école n’a pas encore débuté.',
  noActiveTeachers: 'Il n’y a pas d’enseignants actifs.',
  noName: '(sans nom)',
  numberOfConnectedTeachers: 'Nombre d’enseignants connectés',
  numberOfDailyActiveTeachers: 'Nombre d’enseignants quotidiens',
  numberOfMonthlyActiveTeachers: 'Nombre d’enseignants mensuels',
  numberOfWeeklyActiveTeachers: 'Nombre d’enseignants hebdomadaires',
  openedStudyoTitle: 'Ont ouvert Studyo',
  openedStudyoTooltip: 'ont ouvert Studyo',
  pastMonth: 'Dernier mois',
  pastWeek: 'Dernière semaine',
  sessionsPerDay: 'Sessions par jour',
  sessionsPerDayEmptyMessage: 'Il n’y a pas de sessions',
  sessionsPerDaySubcaption: 'Nombre d’ouvertures de Studyo',
  taughtSections: 'Groupes-matière enseignés',
  teachers: 'Enseignants',
  // This was copied from old strings, I believe total teachers would be cleaner.
  teachersTotal: 'fiches enseignant',
  thisYear: 'Cette année',
  viewPlanner: 'Voir agenda',
  weeklyActiveTeachers: 'Enseignants hebdomadaires'
};
