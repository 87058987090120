import { OQHistory as PBOQHistory } from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';
import { Memoize } from 'fast-typescript-memoize';
import { OQDataPoint } from './OQDataPoint';

export class OQHistory {
  private _pb: PBOQHistory;

  constructor(pb: PBOQHistory) {
    this._pb = pb;
  }

  @Memoize()
  get opensTheAppList(): OQDataPoint[] {
    return this._pb.opensTheApp.map((dp) => new OQDataPoint(dp));
  }

  @Memoize()
  get marksTasksAsDoneList(): OQDataPoint[] {
    return this._pb.marksTasksAsDone.map((dp) => new OQDataPoint(dp));
  }
}
