import { ImageService, LocalizationService, ThemeService } from '@shared/resources/services';
import { ThemeKind } from '@shared/resources/themes';
import {
  AuthenticationService,
  KillSwitchService,
  ModalService,
  ScreenService,
  StartUpService,
  Storage
} from '@shared/services';
import { MultiAnalyticsService } from '@shared/services/analytics';
import {
  AppCalendarStore,
  AppConnectorsStore,
  AppContentStore,
  AppImporterStore,
  AppMetricsStore,
  AppOnboardingStore,
  AppSchoolYearConfigurationStore,
  AppUserStore,
  CalendarStore,
  ConnectorsStore,
  ContentStore,
  ImporterStore,
  MetricsStore,
  OnboardingStore,
  SchoolYearConfigurationStore,
  UserStore
} from '@shared/services/stores';
import { GrpcTransports, ToolsGrpcTransport, ToolsTransport } from '@shared/services/transports';
import {
  AppIOSAccessTokenProvider,
  AppReactRouterRouteService,
  ReactRouterRouteService,
  WebAuthenticationService,
  WebImageService,
  WebKillSwitchService,
  WebLocalStorage,
  WebLocalizationService
} from '@shared/web/services';
import { WebMixpanelAnalyticsService } from '@shared/web/services/WebMixpanelAnalyticsService';
import { RouteNames } from './Routes';
import {
  AccountService,
  AlertService,
  AppAccountService,
  AppInsightsStartUpService,
  AppSubscriptionsService,
  ContentService,
  InsightsAnalyticsService,
  InsightsEnvironmentService,
  InsightsThemeService,
  InsightsWebScreenService,
  NavigationService,
  SettingsStore,
  SubscriptionsService,
  WebEnvironmentService,
  WebNavigationService,
  WebSettingsStore
} from './services';
import { AppContentService } from './services/ContentService';
import { InsightsWebIntercomAnalyticsService } from './services/InsightsWebIntercomAnalyticsService';
import { WebAlertService } from './services/WebAlertService';

export interface InsightsServiceProvider {
  readonly accountService: AccountService;
  readonly alertService: AlertService;
  readonly analyticsService: InsightsAnalyticsService;
  readonly authenticationService: AuthenticationService;
  readonly calendarStore: CalendarStore;
  readonly connectorsStore: ConnectorsStore;
  readonly contentService: ContentService;
  readonly contentStore: ContentStore;
  readonly environmentService: InsightsEnvironmentService;
  readonly imageService: ImageService;
  readonly importerStore: ImporterStore;
  readonly killSwitchService: KillSwitchService;
  readonly localStorage: Storage;
  readonly localizationService: LocalizationService;
  readonly metricsStore: MetricsStore;
  readonly navigationService: NavigationService;
  readonly onboardingStore: OnboardingStore;
  readonly reactRouterRouteService: ReactRouterRouteService;
  readonly schoolYearConfigurationStore: SchoolYearConfigurationStore;
  readonly screenService: ScreenService;
  readonly settingsStore: SettingsStore;
  readonly subscriptionsService: SubscriptionsService;
  readonly themeService: ThemeService;
  readonly toolsTransport: ToolsTransport;
  // Some transports can be used directly by view-models or views.
  readonly userStore: UserStore;
  readonly startUpService: StartUpService;
}

export class AppInsightsServiceProvider implements InsightsServiceProvider {
  readonly accountService: AccountService;
  readonly alertService: AlertService;
  readonly analyticsService: InsightsAnalyticsService;
  readonly authenticationService: AuthenticationService;
  readonly calendarStore: CalendarStore;
  readonly connectorsStore: ConnectorsStore;
  readonly contentService: ContentService;
  readonly contentStore: ContentStore;
  readonly environmentService: InsightsEnvironmentService;
  readonly imageService: ImageService;
  readonly importerStore: ImporterStore;
  readonly killSwitchService: KillSwitchService;
  readonly localStorage: Storage;
  readonly localizationService: LocalizationService;
  readonly metricsStore: MetricsStore;
  readonly navigationService: NavigationService;
  readonly onboardingStore: OnboardingStore;
  readonly reactRouterRouteService: ReactRouterRouteService;
  readonly schoolYearConfigurationStore: SchoolYearConfigurationStore;
  readonly screenService: ScreenService;
  readonly settingsStore: SettingsStore;
  readonly subscriptionsService: SubscriptionsService;
  readonly themeService: ThemeService;
  readonly toolsTransport: ToolsTransport;
  // Some transports can be used directly by view-models or views.
  readonly userStore: UserStore;
  readonly startUpService: StartUpService;

  constructor(modalService: ModalService) {
    this.environmentService = new WebEnvironmentService();

    const intercomAnalyticsService = new InsightsWebIntercomAnalyticsService(this.environmentService);
    const mixpanelAnalyticsService = new WebMixpanelAnalyticsService(this.environmentService);
    this.analyticsService = new MultiAnalyticsService([intercomAnalyticsService, mixpanelAnalyticsService]);
    this.localStorage = new WebLocalStorage();

    const defaultTheme: ThemeKind = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    this.themeService = new InsightsThemeService(defaultTheme, this.localStorage);

    // this.modalService = new AppModalService(serviceContainer, themeService);
    this.reactRouterRouteService = new AppReactRouterRouteService(RouteNames);
    this.navigationService = new WebNavigationService(
      () => this.reactRouterRouteService,
      () => modalService,
      this.environmentService
    );
    this.imageService = new WebImageService();
    this.localizationService = new WebLocalizationService(this.localStorage);

    const iOSAccessTokenProvider = new AppIOSAccessTokenProvider();
    this.authenticationService = new WebAuthenticationService(
      this.environmentService,
      iOSAccessTokenProvider,
      () => this.settingsStore
    );

    // This one is stand-alone.
    this.toolsTransport = new ToolsGrpcTransport(this.authenticationService, this.environmentService);

    const transports = new GrpcTransports(this.authenticationService, this.environmentService);
    this.calendarStore = new AppCalendarStore(transports.generator, transports.school);
    this.contentStore = new AppContentStore(transports.content);
    this.settingsStore = new WebSettingsStore(this.localStorage);
    this.schoolYearConfigurationStore = new AppSchoolYearConfigurationStore(
      transports.school,
      transports.content,
      false,
      true,
      () => this.settingsStore.demoMode
    );
    this.userStore = new AppUserStore(() => this.localStorage, transports.user);
    this.metricsStore = new AppMetricsStore(transports.metrics);
    this.importerStore = new AppImporterStore(transports.importer, this.schoolYearConfigurationStore);
    this.onboardingStore = new AppOnboardingStore(transports.onboarding);
    this.connectorsStore = new AppConnectorsStore(
      transports.connectorManager,
      transports.blackbaud,
      transports.blackbaudSky,
      transports.calendars,
      transports.canvas,
      transports.classroom,
      transports.manageBac,
      transports.teams,
      transports.moodle,
      transports.schoology,
      transports.studyoInternal,
      transports.veracross,
      transports.veracrossV3
    );

    const screenService = new InsightsWebScreenService(this.settingsStore);
    this.screenService = screenService;
    screenService.onInit();

    this.alertService = new WebAlertService(() => modalService);

    this.accountService = new AppAccountService(
      this.calendarStore,
      this.connectorsStore,
      this.contentStore,
      this.importerStore,
      this.metricsStore,
      this.schoolYearConfigurationStore,
      this.userStore,
      this.settingsStore,
      this.authenticationService,
      this.analyticsService
    );
    this.contentService = new AppContentService(this.contentStore, this.schoolYearConfigurationStore);
    this.subscriptionsService = new AppSubscriptionsService(transports.subscriptions);

    this.killSwitchService = new WebKillSwitchService(this.environmentService);

    // Always last.
    this.startUpService = new AppInsightsStartUpService(
      this.localizationService,
      this.accountService,
      this.settingsStore
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
    (window as any).setAccessToken = (token: string) => iOSAccessTokenProvider.setAccessToken(token);
  }
}
