import { ImportDataDetailsResultsViewModel } from '@insights/viewmodels';
import { FileCopy } from '@mui/icons-material';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  CardHeader,
  IconButton,
  Snackbar,
  Stack,
  SxProps,
  Typography
} from '@mui/material';
import { Incident } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { InsightsButton } from '../InsightsButton';
import { ImportDataIncident } from './ImportDataIncident';
import { ImportDataIncidentIcon } from './ImportDataIncidentIcon';
import { ImportSessionSourceRowList } from './ImportSessionSourceRowList';

export interface ImportDataDetailsResultsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: ImportDataDetailsResultsViewModel;
}

export const ImportDataDetailsResults = observer((props: ImportDataDetailsResultsProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.components.import;
  const title = viewModel.isVerificationOnly
    ? strings.testImportData(viewModel.targetSchema)
    : strings.importData(viewModel.targetSchema);
  const [showToast, setShowToast] = useState(false);

  function onCopySummary(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    void navigator.clipboard.writeText(viewModel.readableGlobalIncidentsSummary);
    setShowToast(true);
    e.stopPropagation();
  }

  return (
    <Box sx={sx} className={className} style={style}>
      <CardHeader
        title={title}
        action={
          <Box display="flex" flexDirection="row">
            <InsightsButton isDefault isDisabled={!viewModel.canImport} onClick={() => void viewModel.import()}>
              {strings.importDataButton(viewModel.isDestructive)}
            </InsightsButton>
          </Box>
        }
      />

      {!viewModel.isVerificationOnly && renderAlert(viewModel.isSuccessful, localizationService)}

      {/* Global Incidents */}
      {viewModel.highestGlobalIncidentSeverity && (
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandIcon />}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <ImportDataIncidentIcon severity={viewModel.highestGlobalIncidentSeverity} />
              <Typography variant="subtitle1">{strings.globalIncidents}</Typography>
              <IconButton onClick={onCopySummary}>
                <FileCopy fontSize="small" />
              </IconButton>
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 0, py: 2 }}>
            <Stack direction="column" ml={2}>
              {renderGlobalIncidents(viewModel.globalIncidents)}
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Source incidents and rows (in same expander) */}
      <Accordion elevation={0} disabled={viewModel.sourceRows.length === 0}>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <Typography variant="subtitle1">{`${strings.sourceRows} (${viewModel.sourceRows.length})`}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 2 }}>
          <Stack direction="column" ml={2}>
            {renderSourceIncidents(viewModel.sourceIncidents)}
          </Stack>

          <ImportSessionSourceRowList
            rows={viewModel.sourceRows}
            columnNames={viewModel.sourceColumnNames}
            showIncidents
            title=""
            tableStateKey="ImportDataDetailsSourceRows"
          />
        </AccordionDetails>
      </Accordion>

      {/* Added rows */}
      <Accordion elevation={0} disabled={viewModel.addedRows.length === 0}>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <Typography variant="subtitle1">{`${strings.addedRows} (${viewModel.addedRows.length})`}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 2 }}>
          <ImportSessionSourceRowList
            rows={viewModel.addedRows}
            columnNames={viewModel.columnNames}
            showIncidents
            title=""
            tableStateKey="ImportDataDetailsAddedRows"
          />
        </AccordionDetails>
      </Accordion>

      {/* Updated rows */}
      <Accordion elevation={0} disabled={viewModel.updatedRows.length === 0}>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <Typography variant="subtitle1">{`${strings.updatedRows} (${viewModel.updatedRows.length})`}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 2 }}>
          <ImportSessionSourceRowList
            rows={viewModel.updatedRows}
            columnNames={viewModel.columnNames}
            showIncidents
            title=""
            tableStateKey="ImportDataDetailsUpdatedRows"
          />
        </AccordionDetails>
      </Accordion>

      {/* Removed rows */}
      <Accordion elevation={0} disabled={viewModel.removedRows.length === 0}>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <Typography variant="subtitle1">{`${strings.removedRows} (${viewModel.removedRows.length})`}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 2 }}>
          <ImportSessionSourceRowList
            rows={viewModel.removedRows}
            columnNames={viewModel.columnNames}
            showIncidents
            title=""
            tableStateKey="ImportDataDetailsRemovedRows"
          />
        </AccordionDetails>
      </Accordion>

      {/* Skipped rows */}
      <Accordion elevation={0} disabled={viewModel.skippedRows.length === 0}>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <Typography variant="subtitle1">{`${strings.skippedRows} (${viewModel.skippedRows.length})`}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 2 }}>
          <ImportSessionSourceRowList
            rows={viewModel.skippedRows}
            columnNames={viewModel.columnNames}
            showIncidents
            title=""
            tableStateKey="ImportDataDetailsSkippedRows"
          />
        </AccordionDetails>
      </Accordion>
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        message={strings.summaryCopiedToClipboardToast}
      />
    </Box>
  );
});

function renderGlobalIncidents(globalIncidents: Incident[]) {
  return globalIncidents.map((incident, i) => (
    <ImportDataIncident key={`incident-${i}`} incident={incident} colorMode="icon-incident-color" />
  ));
}

function renderSourceIncidents(sourceIncidents: Incident[]) {
  return sourceIncidents.map((incident, i) => (
    <ImportDataIncident key={`incident-${i}`} incident={incident} colorMode="icon-incident-color" />
  ));
}

function renderAlert(isSuccessful: boolean, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.components.import;

  return isSuccessful ? (
    <Alert severity="success">{strings.importDataSuccess}</Alert>
  ) : (
    <Alert severity="error">{strings.importDataError}</Alert>
  );
}
