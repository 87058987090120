import { css } from '@emotion/css';
import { RouterLink } from '@insights/components';
import { ForbiddenViewModel } from '@insights/viewmodels';
import { Box, Link, SxProps, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteParamNames, RouteTemplates } from '../Routes';
import { useInsightsServices } from '../UseInsightsServicesHook';

export interface ForbiddenProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: ForbiddenViewModel;
}

export const Forbidden = observer((props: ForbiddenProps) => {
  const { accountService, localizationService, reactRouterRouteService } = useInsightsServices();
  const { className, style, sx } = props;

  const theme = useTheme();
  const strings = localizationService.localizedStrings.insights.views.forbidden;
  const linkClassName = css({ color: theme.palette.secondary.dark });

  const navigate = useNavigate();

  const openIntercom = () => {
    if (window.Intercom != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Intercom('showNewMessage');
    }
  };

  return (
    <Box sx={sx} className={className} style={style} display="flex" flexDirection="column" padding={3}>
      <Box marginBottom={3}>
        <Typography variant="h4">{strings.title}</Typography>
      </Box>

      <Box marginBottom={3}>
        <Typography variant="body1">
          {strings.errorOnOurPartText}
          <Link variant="body1" className={linkClassName} onClick={openIntercom}>
            {strings.errorOnOurPartLetUsKnowLink}
          </Link>
          .
        </Typography>
      </Box>

      <Typography variant="body1">{strings.usefulLinks}</Typography>

      <RouterLink
        variant="body1"
        className={linkClassName}
        to={reactRouterRouteService.resolveLocation(RouteTemplates.dashboard, [
          {
            name: RouteParamNames.configId,
            value: accountService.currentConfigId!
          }
        ])}
      >
        {strings.usefulLinksDashboard}
      </RouterLink>

      <Link variant="body1" className={linkClassName} onClick={() => navigate(-1)}>
        {strings.usefulLinksGoBack}
      </Link>
    </Box>
  );
});
