import { ObservablePresenter, SessionsPerDayChart } from '@insights/components';
import { DailyAccountSessionsViewModel } from '@insights/viewmodels';
import { Box, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { MinSmallChartHeight, TeachersColor } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface TeacherSessionsPerDayProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: DailyAccountSessionsViewModel;
}

export const TeacherSessionsPerDay = observer((props: TeacherSessionsPerDayProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.teachers;

  return (
    <Box sx={{ ...sx, height: MinSmallChartHeight, minWidth: 500 }} className={className} style={style}>
      <ObservablePresenter
        sx={{ width: '100%', height: '100%' }}
        data={viewModel.data}
        indicatorsSize="normal"
        loadingMessage={strings.loadingTeacherSessionsPerDayMessage}
        errorMessage={strings.loadingTeacherSessionsPerDayErrorMessage}
        render={(data) => {
          if (data.length === 0) {
            return (
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <Typography variant="h5">{strings.sessionsPerDayEmptyMessage}</Typography>
              </Box>
            );
          }

          return (
            <SessionsPerDayChart
              caption={strings.sessionsPerDay}
              subcaption={strings.sessionsPerDaySubcaption}
              sessions={data}
              color={TeachersColor}
            />
          );
        }}
      />
    </Box>
  );
});
