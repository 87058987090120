import {
  EnglishMonthlyFilterOptionStrings,
  FrenchMonthlyFilterOptionStrings,
  MonthlyFilterOptionsStrings
} from './MonthlyFilterOptionsStrings';
import {
  EnglishMonthlyOptionMenuStrings,
  FrenchMonthlyOptionMenuStrings,
  MonthlyOptionMenuStrings
} from './MonthlyOptionMenuStrings';

export interface MonthlyStrings {
  readonly filterOptions: MonthlyFilterOptionsStrings;
  readonly optionMenu: MonthlyOptionMenuStrings;

  readonly sectionFilterTitle: string;
  readonly sectionFilterClose: string;
}

export const EnglishMonthlyStrings: MonthlyStrings = {
  filterOptions: EnglishMonthlyFilterOptionStrings,
  optionMenu: EnglishMonthlyOptionMenuStrings,

  sectionFilterTitle: 'Filter Sections',
  sectionFilterClose: 'Close'
};

export const FrenchMonthlyStrings: MonthlyStrings = {
  filterOptions: FrenchMonthlyFilterOptionStrings,
  optionMenu: FrenchMonthlyOptionMenuStrings,

  sectionFilterTitle: 'Filtrer les cours',
  sectionFilterClose: 'Fermer'
};
