import { MicrosoftTeamsMappingsViewModel } from '@insights/viewmodels';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';
import { MicrosoftTeamsAssignmentCategoryMappings } from './MicrosoftTeamsAssignmentCategoryMappings';
import { MicrosoftTeamsUnmappedAssignmentCategories } from './MicrosoftTeamsUnmappedAssignmentCategories';

export interface MicrosoftTeamsMappingsProps {
  sx?: SxProps;
  className?: string;
  viewModel: MicrosoftTeamsMappingsViewModel;
}

export const MicrosoftTeamsMappings = observer((props: MicrosoftTeamsMappingsProps) => {
  const { sx = [], className, viewModel } = props;

  return (
    <Column sx={sx} className={className}>
      <MicrosoftTeamsAssignmentCategoryMappings sx={{ m: 0, mb: 1 }} viewModel={viewModel} />

      <MicrosoftTeamsUnmappedAssignmentCategories
        sx={{ m: 0, mb: 1 }}
        isIgnoringUnmappedCategories={viewModel.isIgnoringUnmappedCategories}
        onChange={(value) => (viewModel.isIgnoringUnmappedCategories = value)}
      />

      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
