import {
  Concatenation as PBConcatenation,
  ImportSession as PBImportSession,
  ImportSourceKind as PBImportSourceKind,
  SourceFile as PBSourceFile,
  Transformation as PBTransformation
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { action, computed, makeObservable } from 'mobx';
import {
  EditableModelEx,
  EditableStringProperty,
  EditableValuePropertyEx,
  FullyEditableListProperty
} from '../../editables';
import { ImportSourceKind } from '../../types';
import { protobufFromImportSourceKind } from '../../types/EnumConversion';
import { Concatenation, ImportSession, SourceFile, Transformation } from '../interfaces';
import { EditableConcatenation } from './EditableConcatenation';
import { EditableSourceFile } from './EditableSourceFile';
import { EditableTransformation } from './EditableTransformation';
import { GrpcImportSession } from './GrpcImportSession';

export class EditableImportSession extends EditableModelEx<PBImportSession> implements ImportSession {
  private readonly _name: EditableStringProperty<PBImportSession>;
  private readonly _description: EditableStringProperty<PBImportSession>;
  private readonly _sourceKind: EditableValuePropertyEx<ImportSourceKind, PBImportSession>;
  private readonly _managerEmail: EditableStringProperty<PBImportSession>;
  private readonly _expectedFiles: FullyEditableListProperty<
    PBSourceFile,
    SourceFile,
    EditableSourceFile,
    PBImportSession
  >;
  private readonly _concatenations: FullyEditableListProperty<
    PBConcatenation,
    Concatenation,
    EditableConcatenation,
    PBImportSession
  >;
  private readonly _transformations: FullyEditableListProperty<
    PBTransformation,
    Transformation,
    EditableTransformation,
    PBImportSession
  >;

  static createNew(configId: string) {
    const pb = new PBImportSession();
    pb.configId = configId;
    pb.sourceKind = PBImportSourceKind.OtherSourceKind;

    return new EditableImportSession(new GrpcImportSession(pb), true);
  }

  constructor(
    private readonly _originalSession: ImportSession,
    isNew = false
  ) {
    super(_originalSession.toProtobuf(), isNew);

    makeObservable(this);

    this._name = this.addStringField(_originalSession.name, (host, value) => (host.name = value), { trim: true });
    this._description = this.addStringField(_originalSession.description, (host, value) => (host.description = value), {
      trim: true
    });
    this._sourceKind = this.addValueField<ImportSourceKind>(
      _originalSession.sourceKind,
      (host, value) => (host.sourceKind = protobufFromImportSourceKind(value))
    );
    this._managerEmail = this.addStringField(
      _originalSession.managerEmail,
      (host, value) => (host.managerEmail = value),
      { trim: true }
    );
    this._expectedFiles = this.addEditableListField<PBSourceFile, SourceFile, EditableSourceFile>(
      _originalSession.expectedFiles.map((f) => new EditableSourceFile(f)),
      (host, values) => (host.expectedFiles = values)
    );
    this._concatenations = this.addEditableListField<PBConcatenation, Concatenation, EditableConcatenation>(
      _originalSession.concatenations.map((c) => new EditableConcatenation(c)),
      (host, values) => (host.concatenations = values)
    );
    this._transformations = this.addEditableListField<PBTransformation, Transformation, EditableTransformation>(
      _originalSession.transformations.map((t) => new EditableTransformation(t)),
      (host, values) => (host.transformations = values)
    );
  }

  get id() {
    return this._originalSession.id;
  }

  get configId() {
    return this._originalSession.configId;
  }

  @computed
  get name() {
    return this._name.value;
  }

  set name(value: string) {
    this._name.value = value;
  }

  @computed
  get description() {
    return this._description.value;
  }

  set description(value: string) {
    this._description.value = value;
  }

  @computed
  get sourceKind() {
    return this._sourceKind.value;
  }

  set sourceKind(value: ImportSourceKind) {
    this._sourceKind.value = value;
  }

  @computed
  get managerEmail() {
    return this._managerEmail.value;
  }

  set managerEmail(value: string) {
    this._managerEmail.value = value;
  }

  @computed
  get expectedFiles() {
    return this._expectedFiles.values;
  }

  @action
  addExpectedFile(file: EditableSourceFile) {
    this._expectedFiles.addItem(file);
  }

  @computed
  get transformations() {
    return this._transformations.values;
  }

  @action
  addTransformation(transformation: EditableTransformation) {
    this._transformations.addItem(transformation);
  }

  @computed
  get concatenations() {
    return this._concatenations.values;
  }

  @action
  addConcatenation(concatenation: EditableConcatenation) {
    this._concatenations.addItem(concatenation);
  }

  get data() {
    return this._originalSession.data;
  }

  copyForSchool(): ImportSession {
    // No need to implement this for now.
    throw new Error('Cannot create a copy of an import session already being edited');
  }
}
