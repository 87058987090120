import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  Checkbox,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import { AutomatedImport } from '@shared/models/import';
import { AllDayOfWeek, DayOfWeek, Time } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface AutomatedImportCreateOrEditDialogProps extends DialogResult<AutomatedImport> {
  sx?: SxProps;
  configId: string;
  importSessionId: string;
  automatedImport?: AutomatedImport;
  className?: string;
}

export const AutomatedImportCreateOrEditDialog = observer((props: AutomatedImportCreateOrEditDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx = [], configId, importSessionId, automatedImport, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;
  const dowStrings = localizationService.localizedStrings.models.dayOfWeek;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createAutomatedImportCreateOrEdit(onSuccess!, onCancel!, configId, importSessionId, automatedImport)
  );

  const isCreating = viewModel.automatedImport.shouldBeCreated;

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        onClose={() => viewModel.cancel()}
        maxWidth="xs"
        fullWidth={true}
        className={className}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <DialogTitle>
            {isCreating ? strings.createAutomatedImportTitle : strings.editAutomatedImportTitle}
          </DialogTitle>

          <DialogContent dividers>
            <Box height="100%" width="100%" display="flex" flexDirection="column" overflow="visible">
              <TextField
                sx={{ mb: 2 }}
                label={strings.automatedImportNameLabel}
                value={viewModel.automatedImport.name}
                fullWidth
                required
                autoFocus
                onChange={(e) => (viewModel.automatedImport.name = e.target.value)}
              />

              <FormControl sx={{ mb: 1 }}>
                <InputLabel id="days-selector">{strings.automatedImportDaysLabel}</InputLabel>
                <Select
                  value={viewModel.automatedImport.days}
                  multiple
                  labelId="days-selector"
                  onChange={(event) => (viewModel.automatedImport.days = event.target.value as unknown as DayOfWeek[])}
                  renderValue={() => (
                    <Typography>
                      {viewModel.automatedImport.days.map((dow) => dowStrings.localizedDayOfWeek(dow)).join(', ')}
                    </Typography>
                  )}
                >
                  {AllDayOfWeek.map((dow) => (
                    <MenuItem key={`dow-${dow}`} value={dow}>
                      <Checkbox size="small" checked={viewModel.automatedImport.days.includes(dow)} />
                      <Typography>{dowStrings.localizedDayOfWeek(dow)}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                type="time"
                required
                label={strings.automatedImportTimeLabel}
                value={viewModel.automatedImport.time?.asString ?? null}
                onChange={(event) => (viewModel.automatedImport.time = Time.fromString(event.target.value))}
                slotProps={{ input: { slotProps: { input: { step: 300 } } } }}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            {viewModel.hasError && (
              <Typography
                flex={1}
                variant="caption"
                sx={{ color: (theme) => theme.palette.error.main }}
                style={{ textAlign: 'end' }}
              >
                {strings.unexpectedAutomatedImportError}
              </Typography>
            )}
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => viewModel.cancel()}>
              {strings.cancelAutomatedImportButtonLabel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={!viewModel.canApply}
              isExecuting={viewModel.isExecuting}
              onClick={() => void viewModel.apply()}
            >
              {isCreating ? strings.createAutomatedImportButtonLabel : strings.editAutomatedImportButtonLabel}
            </InsightsButton>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
});
