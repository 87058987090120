import * as CPB from '@buf/studyo_studyo.bufbuild_es/studyo/services/contents_pb';
import {
  ContentDefinition,
  ContentDefinition_Attachment
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { Contents } from '@buf/studyo_studyo.connectrpc_es/studyo/services/contents_connect';
import { ContentAttachmentUploadUrlResponse } from '@shared/models/content';
import { Day } from '@shared/models/types';
import {
  protobufFromContentIcon,
  protobufFromContentKind,
  protobufFromContentPublishedState,
  protobufFromContentWorkloadLevel
} from '@shared/models/types/EnumConversion';
import { ContentTransport, FetchContentsParameters, SyncTokenResult } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class ContentGrpcTransport extends BaseGrpcTransport implements ContentTransport {
  async fetchContent(contentId: string): Promise<ContentDefinition> {
    console.log(`Fetching content for contentId: ${contentId}...`);

    const request = new CPB.GetContentRequest();
    request.contentId = contentId;

    const response = await this.performRequest(Contents, Contents.methods.getContent, request);

    console.log(`Successfully fetched content for contentId: ${contentId}...`);

    const pbContent = response.content;

    if (pbContent == null) {
      throw new Error('Unexpected result from backend: A GetContent request did not return a content definition.');
    }

    return pbContent;
  }

  async fetchMultipleContents(contentIds: string[]): Promise<ContentDefinition[]> {
    console.log('Fetching multiple contents for contentIds:', contentIds);

    const request = new CPB.GetMultipleContentsRequest();
    request.contentIds = contentIds;

    const response = await this.performRequest(Contents, Contents.methods.getMultipleContents, request);

    console.log('Successfully fetched multiple contents for contentIds:', contentIds);

    const pbContents = response.contents;

    if (pbContents == null) {
      throw new Error(
        'Unexpected result from backend: A GetMultipleContents request did not return content definitions.'
      );
    }

    return pbContents;
  }

  async fetchContents(parameters: FetchContentsParameters): Promise<SyncTokenResult<ContentDefinition[]>> {
    console.log(
      `Fetching contents with parameters: [
        configId: ${parameters.configId},
        accountIds: ${parameters.accountIds?.join(',')},
        sectionIds: ${parameters.sectionIds?.join(',')},
        includeCompleted: ${parameters.includeCompleted},
        includeCancelled: ${parameters.includeCancelled},
        workloadLevelsToInclude: ${parameters.workloadLevelsToInclude?.join(',')},
        iconsToInclude: ${parameters.iconsToInclude?.join(',')},
        kindsToInclude: ${parameters.kindsToInclude?.join(',')},
        publishedState: ${parameters.publishedState},
        fromDay: ${parameters.fromDay?.asString},
        toDay: ${parameters.toDay?.asString},
        syncToken: ${parameters.syncToken}]...`
    );

    const request = new CPB.GetContentsRequest();
    request.configId = parameters.configId;

    if (parameters.accountIds != null) {
      request.accountIds = parameters.accountIds;
    }

    if (parameters.sectionIds != null) {
      request.sectionIds = parameters.sectionIds;
    }

    request.includeCompletedTasks = parameters.includeCompleted ?? false;
    request.includeCancelledTasks = parameters.includeCancelled ?? false;

    if (parameters.workloadLevelsToInclude != null) {
      request.workloadLevelsToInclude = parameters.workloadLevelsToInclude.map((wl) =>
        protobufFromContentWorkloadLevel(wl)
      );
    }

    if (parameters.iconsToInclude != null) {
      request.iconsToInclude = parameters.iconsToInclude.map((icon) => protobufFromContentIcon(icon));
    }

    if (parameters.kindsToInclude != null) {
      request.kindsToInclude = parameters.kindsToInclude.map((kind) => protobufFromContentKind(kind));
    }

    request.publishedState = protobufFromContentPublishedState(parameters.publishedState ?? 'all');

    if (parameters.fromDay) {
      request.dueOnOrAfter = parameters.fromDay.asPB;
    }

    if (parameters.toDay) {
      request.dueOnOrBefore = parameters.toDay.asPB;
    }

    if (parameters.syncToken != null) {
      request.syncToken = parameters.syncToken;
    }
    const response = await this.performRequest(Contents, Contents.methods.getContents, request);

    console.log(
      `Successfully fetched contents with parameters: [
        configId: ${parameters.configId},
        accountIds: ${parameters.accountIds?.join(',')},
        sectionIds: ${parameters.sectionIds?.join(',')},
        includeCompleted: ${parameters.includeCompleted},
        includeCancelled: ${parameters.includeCancelled},
        workloadLevelsToInclude: ${parameters.workloadLevelsToInclude?.join(',')},
        iconsToInclude: ${parameters.iconsToInclude?.join(',')},
        kindsToInclude: ${parameters.kindsToInclude?.join(',')},
        publishedState: ${parameters.publishedState},
        fromDay: ${parameters.fromDay?.asString},
        toDay: ${parameters.toDay?.asString},
        syncToken: ${parameters.syncToken}]...`
    );

    return {
      result: response.contents,
      syncToken: response.syncToken
    };
  }

  async saveOrUpdateContent(content: ContentDefinition): Promise<ContentDefinition> {
    console.log(`Saving content with id ${content.id}`);

    const request = new CPB.CreateOrUpdateContentRequest();
    request.content = content;

    const response = await this.performRequest(Contents, Contents.methods.createOrUpdateContent, request);

    console.log(`Successfully saved or updated content with id ${content.id}`);

    return response.content!;
  }

  async publishContent(content: ContentDefinition): Promise<ContentDefinition> {
    console.log(`Publishing content with id ${content.id}`);

    const request = new CPB.PublishContentRequest();
    request.content = content;
    request.target = content.publishTarget;

    const response = await this.performRequest(Contents, Contents.methods.publishContent, request);

    console.log(`Successfully published content with id ${content.id}`);

    return response.content!;
  }

  async markContentAsRead(contentId: string): Promise<ContentDefinition> {
    const request = new CPB.MarkContentAsReadRequest({ contentId });

    const response = await this.performRequest(Contents, Contents.methods.markContentAsRead, request);

    return response.content!;
  }

  async updateContentAttachment(
    configId: string,
    ownerId: string,
    attachment: ContentDefinition_Attachment
  ): Promise<ContentDefinition_Attachment> {
    console.log(`Updating attachment with id [${attachment.id}]`);

    const request = new CPB.UpdateAttachmentRequest();
    request.configId = configId;
    request.ownerId = ownerId;
    request.attachment = attachment;

    const response = await this.performRequest(Contents, Contents.methods.updateAttachment, request);

    console.log(`Successfully updated attachment with id [${attachment.id}]`);

    const responseAttachment = response.attachment;
    if (responseAttachment == null) {
      throw new Error('Updating attachment returned undefined.');
    }

    return responseAttachment;
  }

  async getUploadUrlForContentAttachment(
    configId: string,
    ownerId: string,
    attachment: ContentDefinition_Attachment
  ): Promise<ContentAttachmentUploadUrlResponse> {
    console.log(`Getting an upload url for attachment of kind [${attachment.kind}] with id ${attachment.id}`);

    const request = new CPB.GetAttachmentUploadUrlRequest();
    request.attachment = attachment;
    request.configId = configId;
    request.ownerId = ownerId;

    const response = await this.performRequest(Contents, Contents.methods.getAttachmentUploadUrl, request);

    console.log(
      `Successfully got an upload url for attachment of kind [${attachment.kind}] with id [${attachment.id}]`
    );

    return {
      contentType: response.contentType,
      uploadUrl: response.uploadUrl,
      downloadUrl: response.downloadUrl
    };
  }

  async getPublishingTaskWorkloadImpact(
    configId: string,
    sectionId: string,
    dueDay: Day,
    targetAccountIds: string[] = [],
    ignoredMasterTaskId?: string
  ): Promise<CPB.GetPublishingTaskWorkloadImpactResponse> {
    const request = new CPB.GetPublishingTaskWorkloadImpactRequest({
      configId,
      sectionId,
      dueDay: dueDay.asPB,
      targetAccountIds,
      ignoredMasterTaskId,
      includePersonalTasks: false
    });

    return await this.performRequest(Contents, Contents.methods.getPublishingTaskWorkloadImpact, request);
  }
}
