// Inspiration: https://stackoverflow.com/a/9040526/114623

/**
 * This class is used to parse the results of the GetConfigSectionSchedulingConflicts API.
 * When we use this class for that purpose, "labels" correspond to "section ids".
 *
 * @export
 * @class SymmetricBoolMatrix
 */
export class SymmetricBoolMatrix {
  private readonly _isEmpty: boolean;
  private readonly _rowCount: number;
  private readonly _indexByLabel = new Map<string, number>();

  constructor(
    private readonly _labels: string[],
    private readonly _boolArray: boolean[]
  ) {
    if (_labels.length < 2) {
      this._isEmpty = true;
      this._rowCount = 0;
    } else {
      this._isEmpty = false;
      this._rowCount = _labels.length;
      for (let i = 0; i < _labels.length; i++) {
        this._indexByLabel.set(_labels[i], i);
      }
    }
  }

  public GetLabelIntersectionValue(rowLabel: string, columnLabel: string): boolean {
    if (this._isEmpty) {
      return false;
    }

    return this._boolArray[this.IndexFromRowAndColLabels(rowLabel, columnLabel)];
  }

  public GetLabelsIntersectingWith(label: string): string[] {
    if (this._isEmpty) {
      return [];
    }

    const labels: string[] = [];

    for (const colLabel of this._labels) {
      if (colLabel !== label && this.GetLabelIntersectionValue(label, colLabel)) {
        labels.push(colLabel);
      }
    }

    return labels;
  }

  private IndexFromRowAndColLabels(rowLabel: string, columnLabel: string): number {
    if (this._isEmpty) {
      return 0;
    }

    const row = this._indexByLabel.get(rowLabel);
    if (row == null) {
      throw new Error("rowLabel doesn't exist in the source data.");
    }

    const col = this._indexByLabel.get(columnLabel);
    if (col == null) {
      throw new Error("colLabel doesn't exist in the source data.");
    }

    return this.IndexFromRowAndCol(row, col);
  }

  private IndexFromRowAndCol(row: number, col: number): number {
    if (this._isEmpty) {
      return 0;
    }

    if (row < 0 || row >= this._rowCount) {
      throw new Error('row is out of range.');
    }
    if (col < 0 || col >= this._rowCount) {
      throw new Error('col is out of range.');
    }

    if (row <= col) {
      return row * this._rowCount - (row * (row + 1)) / 2 + col;
    }

    return col * this._rowCount - (col * (col + 1)) / 2 + row;
  }
}
