import { PeriodDisplay } from '@insights/enums';
import { SxProps, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { MouseEvent } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { Row } from './layout';

export interface PeriodDisplaySelectorProps {
  sx?: SxProps;
  className?: string;
  selectedPeriod: PeriodDisplay;
  onSelectedPeriodChanged: (period: PeriodDisplay) => void;
}

export const PeriodDisplaySelector = (props: PeriodDisplaySelectorProps) => {
  const { localizationService } = useInsightsServices();
  const onPeriodChanged = (event: MouseEvent<HTMLElement>, value: PeriodDisplay) => {
    // Prevent no selection by fallbacking to the initial period.
    if (value == null) {
      value = props.selectedPeriod;
    }

    props.onSelectedPeriodChanged(value);
  };

  const strings = localizationService.localizedStrings.insights.components;

  return (
    <Row sx={props.sx} verticalContentAlignment="center" className={props.className}>
      <Typography variant="body2" gutterBottom={false} noWrap={true}>
        {strings.metric}
      </Typography>
      <ToggleButtonGroup
        exclusive={true}
        sx={{ ml: 1 }}
        value={props.selectedPeriod}
        onChange={onPeriodChanged}
        size="small"
      >
        <ToggleButton value={PeriodDisplay.Daily}>{strings.daily}</ToggleButton>
        <ToggleButton value={PeriodDisplay.Weekly}>{strings.weekly}</ToggleButton>
        <ToggleButton value={PeriodDisplay.Monthly}>{strings.monthly}</ToggleButton>
      </ToggleButtonGroup>
    </Row>
  );
};
