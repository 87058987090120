import { AlertService, NavigationService } from '@insights/services';
import { EditableImportSession } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppEditableTransformationViewModel, EditableTransformationViewModel } from './EditableTransformationViewModel';

export interface ImportSessionTransformationDetailsScreenViewModel {
  readonly sessionId: string;
  readonly label: string;
  readonly transformation: IPromiseBasedObservable<EditableTransformationViewModel>;
}

export class AppImportSessionTransformationDetailsScreenViewModel
  implements ImportSessionTransformationDetailsScreenViewModel
{
  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    public readonly configId: string,
    public readonly sessionId: string,
    public readonly label: string
  ) {
    makeObservable(this);
  }

  @computed
  get transformation(): IPromiseBasedObservable<EditableTransformationViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<EditableTransformationViewModel> {
    const session = await this._importSessionStore.getImportSession(this.sessionId, true);
    const editableSession = new EditableImportSession(session);

    const [baseOperations, schemas] = await Promise.all([
      this._importSessionStore.getOperations(),
      this._importSessionStore.getSchemas(this._localizationService.currentLocale)
    ]);

    return new AppEditableTransformationViewModel(
      this._importSessionStore,
      this._localizationService,
      this._alertService,
      editableSession,
      this.label,
      baseOperations,
      schemas
    );
  }
}
