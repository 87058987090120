import { NavigationService } from '@insights/services';
import { EditableSchoolYearConfiguration } from '@shared/models/config';
import { Integration } from '@shared/models/types';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface ChangeIntegrationsDialogViewModel {
  readonly configId: string;
  readonly data: IPromiseBasedObservable<ChangeIntegrationsViewModel>;
}

export interface ChangeIntegrationsViewModel {
  integrations: Integration[];
  readonly availableIntegrations: Integration[];

  readonly hasChanges: boolean;
  readonly isApplying: boolean;
  readonly errorMessage: string;

  apply(): Promise<void>;
  cancel(): void;
}

export class AppChangeIntegrationsDialogViewModel implements ChangeIntegrationsDialogViewModel {
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<ChangeIntegrationsViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<ChangeIntegrationsViewModel> {
    const config = await this._schoolYearConfigurationStore.getConfig(this.configId);
    const editableConfig = new EditableSchoolYearConfiguration(config);

    return new AppChangeIntegrationsViewModel(
      this._schoolYearConfigurationStore,
      this._navigationService,
      this._onSuccess,
      this._onCancel,
      editableConfig
    );
  }
}

export class AppChangeIntegrationsViewModel implements ChangeIntegrationsViewModel {
  @observable private _isApplying = false;
  @observable private _errorMessage = '';

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _editableConfig: EditableSchoolYearConfiguration
  ) {
    makeObservable(this);
  }

  @computed
  get integrations(): Integration[] {
    return this._editableConfig.enabledIntegrations;
  }

  set integrations(value: Integration[]) {
    this._editableConfig.enabledIntegrations = value;
  }

  get availableIntegrations(): Integration[] {
    // Legacy integrations must not be offered anymore, but we still want to see
    // them in schools with those active.
    const integrations: Integration[] = [
      'google-classroom-connector',
      'blackbaud-sky-connector',
      'canvas',
      'microsoft-teams-connector',
      'schoology-connector',
      'veracross-v3-connector',
      'managebac-connector',
      'moodle-connector',
      'studyo-internal',
      'ical'
    ];

    return _.uniq(integrations.concat(this._editableConfig.enabledIntegrations));
  }

  @computed
  get hasChanges(): boolean {
    return this._editableConfig.hasChanges;
  }

  @computed
  get isApplying() {
    return this._isApplying;
  }

  @computed
  get errorMessage() {
    return this._errorMessage;
  }

  @action
  async apply(): Promise<void> {
    this._isApplying = true;
    this._errorMessage = '';

    try {
      await this._schoolYearConfigurationStore.saveConfig(this._editableConfig);
      this._onSuccess();
      // No need to turn isApplying back to false
    } catch (error) {
      runInAction(() => {
        this._isApplying = false;
        this._errorMessage = (error as Error).message;
      });
    }
  }

  cancel() {
    this._onCancel();
  }
}
