import {
  ExternalAccountKind,
  ExternalContentMapping,
  ExternalContentMappingKeyword,
  ExternalContentRepresentation,
  ExternalGradingCondition,
  KeywordMappingKind
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import {
  ContentDefinition_ContentIcon,
  ContentDefinition_WorkloadLevel
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { asIs } from '@insights/utils';
import { LocalizationService } from '@shared/resources/services';
import {
  ConnectorsStore,
  SchoolYearConfigurationStore,
  getBufExternalAccountKindFromIntegration
} from '@shared/services/stores';
import { compact } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

// For now, this feature only targets Google Classroom by default.
const DefaultAccountKinds: ExternalAccountKind[] = [ExternalAccountKind.Google];

const LastWordExpression = asIs`\s(\S+)$`;
const LastWordInParenthesisExpression = asIs`\((\S+)\)$`;
const EndOfTitleExpression = asIs`(\S+)$`;
const LastCharacterExpression = asIs`([\uD83C-\uDBFF][\uDC00-\uDFFF]|[\u2702-\u27B0]|[\uF680-\uF6C0]|[\u24C2-\uF251]|[\u2122-\u2199]|[\u00A9-\u9999]|\S)$`;

export interface RegularExpressionSuggestion {
  readonly expression: string;
  readonly description: string;
}

export interface ExternalContentRepresentationViewModel {
  icon: ContentDefinition_ContentIcon;
  workloadLevel: ContentDefinition_WorkloadLevel;
}

export interface ExternalContentMappingKeywordViewModel {
  keyword: string;
  gradingCondition: ExternalGradingCondition;

  readonly representation?: ExternalContentRepresentationViewModel;

  setIcon(icon: ContentDefinition_ContentIcon | 'none'): void;
}

export interface ExternalContentMappingsViewModel {
  keywordMappingKind: KeywordMappingKind;
  shouldRemoveMatchedKeyword: boolean;
  shouldIgnoreUnmatchedContent: boolean;

  readonly suggestedExpressions: RegularExpressionSuggestion[];
  keywordMappingExpression: string;
  readonly selectedExpressionDescription: string;

  readonly availableAccountKinds: ExternalAccountKind[];
  externalAccountKinds: ExternalAccountKind[];

  readonly keywords: ExternalContentMappingKeywordViewModel[];

  readonly hasChanges: boolean;
  readonly isApplying: boolean;
  readonly errorMessage: string;

  addKeyword(keyword: string): void;
  deleteKeyword(index: number): void;

  apply(): Promise<void>;
}

export interface ExternalContentMappingsDialogViewModel {
  readonly configId: string;
  readonly data: IPromiseBasedObservable<ExternalContentMappingsViewModel>;

  apply(): Promise<void>;
  cancel(): void;
}

export class AppExternalContentRepresentationViewModel implements ExternalContentRepresentationViewModel {
  @observable private _icon: ContentDefinition_ContentIcon | undefined;
  @observable private _workloadLevel: ContentDefinition_WorkloadLevel | undefined;

  constructor(private readonly _representation: ExternalContentRepresentation) {
    makeObservable(this);
  }

  @computed
  get icon() {
    return this._icon ?? this._representation.icon;
  }

  set icon(value: ContentDefinition_ContentIcon) {
    this._icon = value;
    this._representation.icon = value;
  }

  @computed
  get workloadLevel() {
    return this._workloadLevel ?? this._representation.workloadLevel;
  }

  set workloadLevel(value: ContentDefinition_WorkloadLevel) {
    this._workloadLevel = value;
    this._representation.workloadLevel = value;
  }
}

export class AppExternalContentMappingKeywordViewModel implements ExternalContentMappingKeywordViewModel {
  @observable private _keyword: string | undefined;
  @observable private _gradingCondition: ExternalGradingCondition | undefined;
  @observable private _representation: ExternalContentRepresentationViewModel | undefined;

  constructor(private readonly _keywordMapping: ExternalContentMappingKeyword) {
    makeObservable(this);

    if (this._keywordMapping.contentRepresentation != null) {
      this._representation = new AppExternalContentRepresentationViewModel(this._keywordMapping.contentRepresentation);
    }
  }

  @computed
  get keyword() {
    return this._keyword ?? this._keywordMapping.keyword;
  }

  set keyword(value: string) {
    this._keyword = value;
    this._keywordMapping.keyword = value;
  }

  @computed
  get gradingCondition() {
    return this._gradingCondition ?? this._keywordMapping.gradingCondition;
  }

  set gradingCondition(value: ExternalGradingCondition) {
    this._gradingCondition = value;
    this._keywordMapping.gradingCondition = value;
  }

  @computed
  get representation() {
    return this._representation;
  }

  @action
  setIcon(icon: ContentDefinition_ContentIcon | 'none') {
    if (icon == 'none') {
      this._representation = undefined;
      this._keywordMapping.contentRepresentation = undefined;
    } else {
      if (this._representation == null) {
        this._keywordMapping.contentRepresentation = new ExternalContentRepresentation({
          icon,
          workloadLevel: this.getDefaultWorkloadLevel(icon)
        });
        this._representation = new AppExternalContentRepresentationViewModel(
          this._keywordMapping.contentRepresentation
        );
      } else {
        this._representation.icon = icon;
      }
    }
  }

  private getDefaultWorkloadLevel(icon: ContentDefinition_ContentIcon): ContentDefinition_WorkloadLevel {
    switch (icon) {
      case ContentDefinition_ContentIcon.EXAM:
        return ContentDefinition_WorkloadLevel.MAJOR;
      case ContentDefinition_ContentIcon.MINITEST:
        return ContentDefinition_WorkloadLevel.MEDIUM;
      case ContentDefinition_ContentIcon.MESSAGE:
      case ContentDefinition_ContentIcon.RECUPERATION:
      case ContentDefinition_ContentIcon.REMINDER:
        return ContentDefinition_WorkloadLevel.NONE;
      default:
        return ContentDefinition_WorkloadLevel.REGULAR;
    }
  }
}

export class AppExternalContentMappingsViewModel implements ExternalContentMappingsViewModel {
  private readonly _mapping: ExternalContentMapping;

  @observable private _keywordMappingKind: KeywordMappingKind | undefined;
  @observable private _shouldRemoveMatchedKeyword: boolean | undefined;
  @observable private _shouldIgnoreUnmatchedContent: boolean | undefined;
  @observable private _keywordMappingExpression: string | undefined;
  @observable private _externalAccountKinds: ExternalAccountKind[] | undefined;
  @observable private _keywordViewModels: ExternalContentMappingKeywordViewModel[];
  @observable private _isApplying = false;
  @observable private _errorMessage = '';

  constructor(
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _localizationService: LocalizationService,
    private readonly _onSuccess: () => void,
    readonly availableAccountKinds: ExternalAccountKind[],
    private readonly _originalMapping: ExternalContentMapping
  ) {
    makeObservable(this);
    this._mapping = _originalMapping.clone();
    this._keywordViewModels = this._mapping.keywordMappings.map(
      (k) => new AppExternalContentMappingKeywordViewModel(k)
    );
  }

  @computed
  get keywordMappingKind() {
    return this._keywordMappingKind ?? this._mapping.keywordMappingKind;
  }

  set keywordMappingKind(value: KeywordMappingKind) {
    this._keywordMappingKind = value;
    this._mapping.keywordMappingKind = value;
  }

  @computed
  get shouldRemoveMatchedKeyword() {
    return this._shouldRemoveMatchedKeyword ?? this._originalMapping.shouldRemoveMatchedKeyword;
  }

  set shouldRemoveMatchedKeyword(value: boolean) {
    this._shouldRemoveMatchedKeyword = value;
    this._mapping.shouldRemoveMatchedKeyword = value;
  }

  @computed
  get shouldIgnoreUnmatchedContent() {
    return this._shouldIgnoreUnmatchedContent ?? this._originalMapping.shouldIgnoreUnmatchedContent;
  }

  set shouldIgnoreUnmatchedContent(value: boolean) {
    this._shouldIgnoreUnmatchedContent = value;
    this._mapping.shouldIgnoreUnmatchedContent = value;
  }

  @computed
  get suggestedExpressions() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    return [
      { expression: LastWordExpression, description: strings.lastWordExpressionLabel },
      { expression: LastWordInParenthesisExpression, description: strings.lastWordInParenthesisExpressionLabel },
      { expression: EndOfTitleExpression, description: strings.endOfTitleExpressionLabel },
      { expression: LastCharacterExpression, description: strings.lastCharacterExpressionLabel }
    ];
  }

  @computed
  get keywordMappingExpression() {
    return this._keywordMappingExpression ?? this._originalMapping.keywordMappingExpression;
  }

  set keywordMappingExpression(value: string) {
    this._keywordMappingExpression = value;
    this._mapping.keywordMappingExpression = value;
  }

  @computed
  get selectedExpressionDescription() {
    return (
      this.suggestedExpressions.find((e) => e.expression == this.keywordMappingExpression)?.description ??
      this._localizationService.localizedStrings.insights.viewModels.connectors.customExpressionLabel
    );
  }

  @computed
  get externalAccountKinds() {
    return this._externalAccountKinds ?? this._originalMapping.externalAccountKinds;
  }

  set externalAccountKinds(values: ExternalAccountKind[]) {
    this._externalAccountKinds = values;
    this._mapping.externalAccountKinds = values;
  }

  @computed
  get keywords() {
    return this._keywordViewModels;
  }

  // For now, always possible to save.
  hasChanges = true;

  @computed
  get isApplying() {
    return this._isApplying;
  }

  @computed
  get errorMessage() {
    return this._errorMessage;
  }

  @action
  addKeyword(keyword: string): void {
    const keywordMapping = new ExternalContentMappingKeyword({
      keyword,
      gradingCondition: ExternalGradingCondition.ANY
    });
    const keywordMappingViewModel = new AppExternalContentMappingKeywordViewModel(keywordMapping);

    this._keywordViewModels.push(keywordMappingViewModel);
    this._mapping.keywordMappings.push(keywordMapping);
  }

  @action
  deleteKeyword(index: number) {
    this._keywordViewModels.splice(index, 1);
    this._mapping.keywordMappings.splice(index, 1);
  }

  @action
  async apply(): Promise<void> {
    this._isApplying = true;
    this._errorMessage = '';

    try {
      if ((this._mapping.id?.length ?? 0) === 0) {
        await this._connectorsStore.addExternalContentMapping(this._mapping);
      } else {
        await this._connectorsStore.updateExternalContentMapping(this._mapping);
      }
      this._onSuccess();
      // No need to turn isApplying back to false
    } catch (error) {
      runInAction(() => {
        this._isApplying = false;
        this._errorMessage = (error as Error).message;
      });
    }
  }
}

export class AppExternalContentMappingsDialogViewModel implements ExternalContentMappingsDialogViewModel {
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _localizationService: LocalizationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<ExternalContentMappingsViewModel> {
    return fromPromise(this.loadData());
  }

  async apply(): Promise<void> {
    if (this.data.state === 'fulfilled') {
      await this.data.value.apply();
    }
  }

  cancel() {
    this._onCancel();
  }

  private async loadData(): Promise<ExternalContentMappingsViewModel> {
    const [summary, mappings] = await Promise.all([
      this._schoolYearConfigurationStore.getConfigSummary(this.configId),
      this._connectorsStore.getExternalContentMappings(this.configId)
    ]);

    const accountKinds = compact(
      summary.enabledIntegrations.map((integration) => getBufExternalAccountKindFromIntegration(integration))
    );

    if (mappings.length > 1) {
      console.error('More than one external content mapping, using first');
    }

    const mapping = mappings.length === 0 ? this.createDefaultExternalContentMapping(accountKinds) : mappings[0];

    return new AppExternalContentMappingsViewModel(
      this._connectorsStore,
      this._localizationService,
      this._onSuccess,
      accountKinds,
      mapping
    );
  }

  private createDefaultExternalContentMapping(accountKinds: ExternalAccountKind[]) {
    const defaultAccountKinds = accountKinds.filter((k) => DefaultAccountKinds.includes(k));

    return new ExternalContentMapping({
      configId: this.configId,
      keywordMappingKind: KeywordMappingKind.TITLE_KEYWORD,
      shouldRemoveMatchedKeyword: true,
      keywordMappingExpression: LastWordExpression,
      externalAccountKinds: defaultAccountKinds,
      keywordMappings: [
        {
          keyword: '*',
          gradingCondition: ExternalGradingCondition.ANY,
          contentRepresentation: {
            icon: ContentDefinition_ContentIcon.EXAM,
            workloadLevel: ContentDefinition_WorkloadLevel.MAJOR
          }
        }
      ]
    });
  }
}
