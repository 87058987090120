import { CanvasAccountSetupViewModel } from '@insights/viewmodels';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';

export interface CanvasAccountSetupProps {
  sx?: SxProps;
  className?: string;
  viewModel: CanvasAccountSetupViewModel;
}

export const CanvasAccountSetup = observer((props: CanvasAccountSetupProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Root sx={sx} className={className}>
      <FormControl className={'control'}>
        <InputLabel htmlFor="sub-account-select">{strings.subAccountLabel}</InputLabel>
        <Select
          value={viewModel.subAccountId}
          onChange={(e) => (viewModel.subAccountId = BigInt(e.target.value as bigint))}
          inputProps={{ id: 'sub-account-select' }}
        >
          <MenuItem key="sub-account-id-0" value="0">
            <Typography>{strings.noSubAccount}</Typography>
          </MenuItem>
          {viewModel.availableSubAccounts.map((account) => (
            <MenuItem key={`sub-account-id-${account.id}`} value={Number(account.id)}>
              <Typography>{account.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={'control'}>
        <InputLabel htmlFor="terms-select">{strings.termsLabel}</InputLabel>
        <Select
          className={'select'}
          multiple
          fullWidth
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            }
          }}
          value={viewModel.termIds.map((id) => id.toString())}
          onChange={(e) => (viewModel.termIds = (e.target.value as string[]).map((v) => BigInt(v)))}
          renderValue={() => viewModel.termNames}
          inputProps={{ id: 'terms-select' }}
        >
          {viewModel.availableTerms.map((term) => (
            <MenuItem key={`term-id-${term.id}`} value={term.id.toString()}>
              <Checkbox size="small" checked={viewModel.termIds.includes(term.id)} />
              <Typography variant="body1">{term.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={viewModel.shouldDisplaySections}
            onChange={(event) => (viewModel.shouldDisplaySections = event.target.checked)}
          />
        }
        label={strings.shouldDisplaySections}
      />
      <Typography variant="caption">{strings.shouldDisplaySectionsNotice}</Typography>

      <TextField
        className={'control'}
        label={strings.requiredCourseCodePrefixLabel}
        value={viewModel.requiredCourseCodePrefix}
        onChange={(event) => (viewModel.requiredCourseCodePrefix = event.target.value)}
      />

      <ExternalAccountEditionActions viewModel={viewModel} />
    </Root>
  );
});

const Root = styled(Column)(({ theme }) => ({
  control: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  select: {
    maxWidth: theme.breakpoints.values.md
  }
}));
