import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { computed, makeObservable } from 'mobx';
import { BaseModel } from '../../Model';
import { Account } from '../../config';
import { configStateFromProtobuf } from '../../types/EnumConversion';
import { DashboardProcess } from '../interfaces';
import { GrpcDashboardStep } from './GrpcDashboardStep';
import { GrpcOnboardingProcess } from './GrpcOnboardingProcess';

export class GrpcDashboardProcess extends BaseModel<PB.DashboardProcess> implements DashboardProcess {
  constructor(pb: PB.DashboardProcess) {
    super(pb);
    makeObservable(this);
  }

  @computed
  get process() {
    const pbProcess = this._pb.process;
    return pbProcess && new GrpcOnboardingProcess(pbProcess);
  }

  @computed
  get steps() {
    return this._pb.steps.map((s) => new GrpcDashboardStep(s));
  }

  get schoolName() {
    return this._pb.schoolName;
  }

  get schoolStartDate() {
    return this._pb.schoolStartDate;
  }

  get configState() {
    return configStateFromProtobuf(this._pb.configState);
  }

  @computed
  get accounts() {
    return this._pb.accounts.map((a) => new Account(a));
  }
}
