export interface TaskComponentsStrings {
  at: string;
  atPeriod: string;
  close: string;
  currentWeek: string;
  dueOn: string;
  dueOnLabel: string;
  firstWeek: string;
  lastWeek: string;
  nextWeek: string;
  noName: string;
  noTeacher: string;
  notesLabel: string;
  previousWeek: string;
  publishedOn: string;
  publishedOnLabel: string;
  titleLabel: string;
  viewDetails: string;

  localizedNumberOfStudents(numberOfCycleDays: number): string;
  localizedNumberOfClippedConflictGroups(numberOfClippedGroups: number): string;
}

export const EnglishTaskComponentsStrings: TaskComponentsStrings = {
  at: 'at',
  atPeriod: 'at period',
  close: 'Close',
  currentWeek: 'Current week',
  dueOn: 'Due on',
  dueOnLabel: 'Due on',
  firstWeek: 'First week',
  lastWeek: 'Last week',
  nextWeek: 'Next week',
  noName: '(no name)',
  noTeacher: '(no teacher)',
  notesLabel: 'Notes',
  previousWeek: 'Previous week',
  publishedOn: 'Published on',
  publishedOnLabel: 'Published on',
  titleLabel: 'Title',
  viewDetails: 'View details',

  localizedNumberOfStudents: EnglishLocalizedStudent,
  localizedNumberOfClippedConflictGroups: EnglishLocalizedClippedConflicts
};

export const FrenchTaskComponentsStrings: TaskComponentsStrings = {
  at: 'à',
  atPeriod: 'à la période',
  close: 'Fermer',
  currentWeek: 'Semaine actuelle',
  dueOn: 'Due le',
  dueOnLabel: 'Due le',
  firstWeek: '1e sem.',
  lastWeek: 'Dern. sem.',
  nextWeek: 'Prochaine semaine',
  noName: '(sans nom)',
  noTeacher: '(sans enseignant)',
  notesLabel: 'Notes',
  previousWeek: 'Sem. précédente',
  publishedOn: 'Publiée le',
  publishedOnLabel: 'Publiée le',
  titleLabel: 'Titre',
  viewDetails: 'Voir les détails',

  localizedNumberOfStudents: FrenchLocalizedStudent,
  localizedNumberOfClippedConflictGroups: FrenchLocalizedClippedConflicts
};

function EnglishLocalizedStudent(numberOfStudents: number): string {
  return Math.abs(numberOfStudents) === 1 ? numberOfStudents + ' student' : numberOfStudents + ' students';
}

function EnglishLocalizedClippedConflicts(numberOfClippedGroups: number): string {
  // Never 0. Should not be < 5 but still expect it.
  return numberOfClippedGroups === 1 ? 'One other group not shown' : `${numberOfClippedGroups} other groups not shown`;
}

function FrenchLocalizedStudent(numberOfStudents: number): string {
  return Math.abs(numberOfStudents) <= 1 ? numberOfStudents + ' élève' : numberOfStudents + ' élèves';
}

function FrenchLocalizedClippedConflicts(numberOfClippedGroups: number): string {
  // Never 0. Should not be < 5 but still expect it.
  return numberOfClippedGroups === 1
    ? 'Un autre groupe pas affiché'
    : `${numberOfClippedGroups} autres groupes pas affichés`;
}
