import * as CPB from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/calendars_pb';
import { Calendars } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/calendars_connect';
import { CalendarsTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class CalendarsGrpcTransport extends BaseGrpcTransport implements CalendarsTransport {
  async createOrUpdateCalendarExternalAccount(
    externalAccountId: string | undefined,
    configId: string,
    accountName: string
  ): Promise<CPB.CreateOrUpdateExternalAccountResponse> {
    console.log('Creating or updating iCal Calendar external account...');

    const request = new CPB.CreateOrUpdateExternalAccountRequest();
    request.externalAccountId = externalAccountId ?? '';
    request.configId = configId;
    request.accountName = accountName;

    const response = await this.performRequest(Calendars, Calendars.methods.createOrUpdateExternalAccount, request);

    console.log('Successfully created or updated iCal Calendar external account.');

    return response;
  }
}
