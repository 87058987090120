export interface DateFormats {
  long: string;
  longTime: string;
  medium: string;
  mediumUnabridged: string;
  mediumUnabridgedWithLongTime: string;
  mediumUnabridgedWithTime: string;
  monthYear: string;
  monthYearUnabridged: string;
  short: string;
  shortUnabridged: string;
  time: string;
  tiny: string;
  year: string;
  dayOfWeek: string;
  duration: string;
}

export const EnglishDateFormatStrings: DateFormats = {
  long: 'EEEE MMMM d, yyyy',
  longTime: 'h:mm:ss a',
  medium: 'MMM d, yyyy',
  mediumUnabridged: 'MMMM d, yyyy',
  mediumUnabridgedWithLongTime: 'MMMM d, yyyy – h:mm:ss a',
  mediumUnabridgedWithTime: 'MMMM d, yyyy – h:mm a',
  monthYear: 'MMM yyyy',
  monthYearUnabridged: 'MMMM yyyy',
  short: 'MMM d',
  shortUnabridged: 'MMMM d',
  time: 'h:mm a',
  tiny: 'd',
  year: 'yyyy',
  dayOfWeek: 'EEEE',
  duration: 'H:mm'
};

export const FrenchDateFormatStrings: DateFormats = {
  long: 'EEEE d MMMM yyyy',
  longTime: 'H:mm:ss',
  medium: 'd MMM yyyy',
  mediumUnabridged: 'd MMMM yyyy',
  mediumUnabridgedWithLongTime: 'd MMMM yyyy – H:mm:ss',
  mediumUnabridgedWithTime: 'd MMMM yyyy – H:mm',
  monthYear: 'MMM yyyy',
  monthYearUnabridged: 'MMMM yyyy',
  short: 'd MMM',
  shortUnabridged: 'd MMMM',
  time: 'H:mm',
  tiny: 'd',
  year: 'yyyy',
  dayOfWeek: 'EEEE',
  duration: 'H:mm'
};
