import { SourceIndex as PBSourceIndex } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { BaseModel } from '../../Model';
import { SourceIndex } from '../interfaces';

export class GrpcSourceIndex extends BaseModel<PBSourceIndex> implements SourceIndex {
  constructor(pb: PBSourceIndex) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get label(): string {
    return this._pb.label;
  }

  get columnIndex(): number {
    return this._pb.columnIndex;
  }
}
