import * as TPB from '@buf/studyo_studyo.bufbuild_es/studyo/type_teams_pb';
import { EditableTeamsAssignmentCategoryMapping, EditableTeamsContentRepresentation } from '@shared/models/connectors';
import {
  TeamsAccountDetailsModel,
  TeamsAssignmentCategoryMappingModel,
  TeamsAssignmentCategoryModel
} from '@shared/models/connectors/interfaces';
import { FullyEditableListProperty } from '@shared/models/editables';
import { ContentIcon, ContentWorkloadLevel } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { MicrosoftTeamsConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface MicrosoftTeamsMappingsViewModel extends ExternalAccountEditionViewModel {
  isIgnoringUnmappedCategories: boolean;

  readonly mappings: EditableTeamsAssignmentCategoryMapping[];
  readonly allCategories: TeamsAssignmentCategoryModel[];
  readonly availableCategories: TeamsAssignmentCategoryModel[];
  addMapping(category: TeamsAssignmentCategoryModel): void;
  addMappingByName(categoryName: string): void;
  setIcon(mapping: EditableTeamsAssignmentCategoryMapping, icon?: ContentIcon): void;
  setWorkloadLevel(mapping: EditableTeamsAssignmentCategoryMapping, workloadLevel: ContentWorkloadLevel): void;
}

export class AppMicrosoftTeamsMappingsViewModel
  extends BaseExternalAccountEditionViewModel
  implements MicrosoftTeamsMappingsViewModel
{
  @observable private _isIgnoringUnmappedCategories?: boolean;

  // We use an editable field to hold changes, but it doesn't have a host to apply to.
  private _mappings: FullyEditableListProperty<
    TPB.AssignmentCategoryMapping,
    TeamsAssignmentCategoryMappingModel,
    EditableTeamsAssignmentCategoryMapping,
    unknown
  >;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _teamsStore: MicrosoftTeamsConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    public readonly allCategories: TeamsAssignmentCategoryModel[],
    private readonly _originalDetails: TeamsAccountDetailsModel
  ) {
    super();
    makeObservable(this);
    this._mappings = new FullyEditableListProperty(
      _originalDetails.mappings.map((m) => new EditableTeamsAssignmentCategoryMapping(m)),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }

  @computed
  get isIgnoringUnmappedCategories() {
    return this._isIgnoringUnmappedCategories ?? this._originalDetails.ignoreUnmappedCategories;
  }

  set isIgnoringUnmappedCategories(value: boolean) {
    this._isIgnoringUnmappedCategories = value;
    this.onChange();
  }

  @computed
  get mappings() {
    return this._mappings.values;
  }

  @action
  addMapping(category: TeamsAssignmentCategoryModel) {
    this._mappings.addItem(EditableTeamsAssignmentCategoryMapping.createNew(category.id, category.name));
  }

  @action
  addMappingByName(categoryName: string) {
    this._mappings.addItem(EditableTeamsAssignmentCategoryMapping.createNew('', categoryName));
  }

  @computed
  get availableCategories() {
    const selectedCategoryIds = new Set(this.mappings.map((m) => m.categoryId));

    return this.allCategories.filter((c) => !selectedCategoryIds.has(c.id));
  }

  @action
  setIcon(mapping: EditableTeamsAssignmentCategoryMapping, icon?: ContentIcon) {
    if (mapping.representation == null) {
      if (icon == null) {
        return;
      }

      mapping.representation = EditableTeamsContentRepresentation.createNew(icon, this.getDefaultWorkloadLevel(icon));
    } else if (icon == null) {
      mapping.representation = undefined;
    } else {
      mapping.editableRepresentation.icon = icon;
    }
  }

  @action
  setWorkloadLevel(mapping: EditableTeamsAssignmentCategoryMapping, workloadLevel: ContentWorkloadLevel) {
    if (mapping.representation == null) {
      console.error('It should be impossible to set a workload without setting an icon first');
      mapping.representation = EditableTeamsContentRepresentation.createNew('homework', workloadLevel);
    } else {
      mapping.editableRepresentation.workloadLevel = workloadLevel;
    }
  }

  get hasChanges() {
    return this._hasChanges || this._mappings.isChanged;
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this.hasChanges) {
      console.error('Applying without changes. Ignoring...');
      this._onSuccess();
      return;
    }

    this.beginApplying();

    try {
      await this._teamsStore.updateTeamsAccountSettings(
        this._configId,
        this._externalAccountId,
        this.mappings,
        this.isIgnoringUnmappedCategories,
        this._originalDetails.customClassesFilter
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._mappings.reset();
    this._isIgnoringUnmappedCategories = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this.hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }

  private getDefaultWorkloadLevel(icon: ContentIcon): ContentWorkloadLevel {
    switch (icon) {
      case 'exam':
        return 'major';
      case 'minitest':
        return 'medium';
      case 'reminder':
        return 'none';
      default:
        return 'regular';
    }
  }
}
