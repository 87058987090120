import { SpecialDaysImages, SymbolsImages } from '@shared/resources/images/studyo';
import { WebSymbolsImages } from './WebSymbolsImages';

export class WebSpecialDaysImages implements SpecialDaysImages {
  private readonly _symbols = new WebSymbolsImages();

  get symbols(): SymbolsImages {
    return this._symbols;
  }
}
