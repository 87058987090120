import { Operation as PBOperation } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { Memoize } from 'fast-typescript-memoize';
import { BaseModel } from '../../Model';
import { Operation, OperationParameter } from '../interfaces';
import { GrpcOperationParameter } from './GrpcOperationParameter';

export class GrpcOperation extends BaseModel<PBOperation> implements Operation {
  constructor(pb: PBOperation) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get name(): string {
    return this._pb.name;
  }

  get description(): string {
    return this._pb.description;
  }

  @Memoize()
  get parameters(): OperationParameter[] {
    return this._pb.parameters.map((p) => new GrpcOperationParameter(p));
  }
}
