import { NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { MicrosoftTeamsConnectorStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  AppMicrosoftTeamsAccountSettingsViewModel,
  MicrosoftTeamsAccountSettingsViewModel
} from './MicrosoftTeamsAccountSettingsViewModel';

export interface MicrosoftTeamsAccountSettingsDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly settings: IPromiseBasedObservable<MicrosoftTeamsAccountSettingsViewModel>;
}

export class AppMicrosoftTeamsAccountSettingsDialogViewModel implements MicrosoftTeamsAccountSettingsDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _teamsStore: MicrosoftTeamsConnectorStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get settings(): IPromiseBasedObservable<MicrosoftTeamsAccountSettingsViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<MicrosoftTeamsAccountSettingsViewModel> {
    const details = await this._teamsStore.getTeamsAccountDetails(this.configId, this.externalAccountId);

    return new AppMicrosoftTeamsAccountSettingsViewModel(
      this._localizationService,
      this._teamsStore,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      details
    );
  }
}
