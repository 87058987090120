import { ExternalAccount } from '@shared/models/connectors';
import { LocalizationService } from '@shared/resources/services';
import { ClassroomConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface GoogleAccountViewModel {
  readonly existingAccount: ExternalAccount | undefined;
  readonly isConnectedSuccessfully: boolean;
  readonly accountId: string;

  readonly googleAuthCode: string;
  updateGoogleInfo(authCode: string): void;
  redoConnection(): void;

  error: string;

  applyChanges(): Promise<void>;
  cancelChanges(): void;
}

export class AppGoogleAccountViewModel implements GoogleAccountViewModel {
  @observable private _isConnectedSuccessfully: boolean;
  @observable private _googleAuthCode?: string;

  @observable private _error?: string;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _classroomStore: ClassroomConnectorStore,
    private readonly _onNext: (externalAccountId: string) => Promise<void>,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    public readonly accountId: string,
    isConnectedSuccessfully?: boolean,
    private readonly _existingAccount?: ExternalAccount
  ) {
    makeObservable(this);
    this._isConnectedSuccessfully = isConnectedSuccessfully ?? false;
  }

  get existingAccount() {
    return this._existingAccount;
  }

  @computed
  get isConnectedSuccessfully() {
    return this._isConnectedSuccessfully;
  }

  @computed
  get googleAuthCode() {
    return this._googleAuthCode ?? '';
  }

  @action
  updateGoogleInfo(authCode: string) {
    this._googleAuthCode = authCode;
    this._error = undefined;
  }

  @action
  redoConnection() {
    this._isConnectedSuccessfully = false;
  }

  @computed
  get error() {
    return this._error ?? '';
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this._googleAuthCode == null) {
      this._error = strings.unexpectedError;
      return;
    }

    try {
      const newId = await this._classroomStore.createOrUpdateGoogleAccount(
        this._configId,
        this._externalAccountId,
        this.accountId,
        '',
        '',
        this._googleAuthCode
      );

      await this._onNext(newId);
    } catch (error) {
      // Because this is an admin screen, we let the original error message bubble out.
      runInAction(() => (this._error = strings.serverError + (error as Error).message));
    }
  }

  cancelChanges() {
    this._onCancel();
  }
}
