import { ObservablePresenter } from '@insights/components';
import { Box, Button, SxProps, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface MicrosoftTeamsAuthorizationRedirectScreenProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const MicrosoftTeamsAuthorizationRedirectScreen = observer(
  (props: MicrosoftTeamsAuthorizationRedirectScreenProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { sx = [], className, style } = props;
    const strings = localizationService.localizedStrings.insights.views.settings;
    const theme = useTheme();
    const navigate = useNavigate();

    const location = useLocation();
    const search = location.search;
    const viewModel = useMemo(() => viewModelFactory.createMicrosoftTeamsRedirect(search), [search]);

    return (
      <Box sx={sx} className={className} style={style} display="flex" flexDirection="column">
        <Box flex={1}>
          <ObservablePresenter
            sx={{ height: '100%', width: '100%', p: 2 }}
            data={viewModel.data}
            loadingMessage={strings.completingTeamsAuthorizationMessage}
            errorMessage={strings.completingTeamsAuthorizationErrorMessage}
            render={(data) => (
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Typography variant="h4">{strings.completedTeamsAuthorizationTitle}</Typography>
                <Typography
                  sx={{
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                    maxWidth: theme.breakpoints.values.sm
                  }}
                  variant="subtitle1"
                  align="center"
                >
                  {strings.completedTeamsAuthorizationMessage}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => void data.navigateToExternalAccounts(navigate)}
                >
                  {strings.returnToExternalAccountsButton}
                </Button>
              </Box>
            )}
          />
        </Box>
      </Box>
    );
  }
);
