import { SchoolYearConfigurationSummary } from '@shared/models/config';
import { ImportSession } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface ImportSessionImportFromSchoolDialogViewModel {
  readonly years: number[];
  selectedYear: number;
  readonly configurations: IPromiseBasedObservable<SchoolYearConfigurationSummary[]>;

  selectedConfiguration: SchoolYearConfigurationSummary | undefined;

  readonly sessions: IPromiseBasedObservable<ImportSession[]>;

  selectedSession: ImportSession | undefined;

  readonly canImport: boolean;
  readonly isImporting: boolean;
  readonly hasError: boolean;

  import: () => Promise<void>;
  cancel: () => void;
}

export class AppImportSessionImportFromSchoolDialogViewModel implements ImportSessionImportFromSchoolDialogViewModel {
  @observable private _selectedYear: number;
  @observable private _selectedConfiguration: SchoolYearConfigurationSummary | undefined;
  @observable private _selectedSession: ImportSession | undefined;

  @observable private _isImporting = false;
  @observable private _hasError = false;

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _importSessionStore: ImporterStore,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private _onSuccess: () => void,
    private _onCancel: () => void
  ) {
    makeObservable(this);
    this._selectedYear = new Date().getFullYear() - 1;
  }

  @computed
  get years(): number[] {
    const currentYear = new Date().getFullYear();
    return [-3, -2, -1, 0, 1].map((i) => currentYear + i);
  }

  @computed
  get selectedYear(): number {
    return this._selectedYear;
  }

  set selectedYear(value: number) {
    if (value != this._selectedYear) {
      this._selectedYear = value;
      this._hasError = false;

      // We reset the school selection as well.
      this.selectedConfiguration = undefined;
    }
  }

  @computed
  get configurations(): IPromiseBasedObservable<SchoolYearConfigurationSummary[]> {
    return fromPromise(this._schoolYearConfigurationStore.getConfigs(this._selectedYear));
  }

  @computed
  get selectedConfiguration(): SchoolYearConfigurationSummary | undefined {
    return this._selectedConfiguration;
  }

  set selectedConfiguration(value: SchoolYearConfigurationSummary | undefined) {
    this._selectedConfiguration = value;
    this._hasError = false;

    // We reset the session selection as well.
    this.selectedSession = undefined;
  }

  @computed
  get sessions(): IPromiseBasedObservable<ImportSession[]> {
    if (this._selectedConfiguration == null) {
      return fromPromise(Promise.resolve([]));
    }

    return fromPromise(this._importSessionStore.getImportSessions(this._selectedConfiguration.id));
  }

  @computed
  get selectedSession(): ImportSession | undefined {
    return this._selectedSession;
  }

  set selectedSession(value: ImportSession | undefined) {
    this._selectedSession = value;
    this._hasError = false;
  }

  @computed
  get canImport(): boolean {
    return this._selectedSession != null;
  }

  @computed
  get isImporting(): boolean {
    return this._isImporting;
  }

  @computed
  get hasError(): boolean {
    return this._hasError;
  }

  async import() {
    if (this._selectedSession == null) {
      return;
    }

    runInAction(() => {
      this._isImporting = true;
      this._hasError = false;
    });

    try {
      const session = this._selectedSession.copyForSchool(this._configId);
      await this._importSessionStore.createOrUpdateImportSession(session, false);

      this._onSuccess();
    } catch (error) {
      console.error(error);
      runInAction(() => (this._hasError = true));
    } finally {
      runInAction(() => (this._isImporting = false));
    }
  }

  cancel() {
    this._onCancel();
  }
}
