import { styled, SxProps } from '@mui/material';
import DarkLogo from '@shared/web/images/assets/insights/ui_logo_dark.svg?react';
import LightLogo from '@shared/web/images/assets/insights/ui_logo_light.svg?react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { RouteTemplates } from '../Routes';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { RouterLink } from './RouterLink';

export interface ApplicationNameProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  size?: 'extra-large' | 'large' | 'normal' | 'small' | 'tiny';
  color: 'dark' | 'light';
}

export const ApplicationName = observer((props: ApplicationNameProps) => {
  const { reactRouterRouteService } = useInsightsServices();
  const { sx = [], className, style, color, size } = props;

  const logoClass =
    size === 'extra-large'
      ? 'extraLargeLogo'
      : size === 'large'
        ? 'largeLogo'
        : size === 'small'
          ? 'smallLogo'
          : size === 'tiny'
            ? 'tinyLogo'
            : 'normalLogo';

  return (
    <Root
      sx={sx}
      to={reactRouterRouteService.resolveLocation(RouteTemplates.landing)}
      color="inherit"
      underline="none"
      className={className}
      style={style}
    >
      {color === 'dark' && <DarkLogo className={clsx('logo', logoClass)} />}
      {color === 'light' && <LightLogo className={clsx('logo', logoClass)} />}
    </Root>
  );
});

const Root = styled(RouterLink)(() => ({
  display: 'flex',

  '.logo': {
    outline: 'none',
    width: 'auto'
  },
  '.extraLargeLogo': {
    height: 120
  },
  '.largeLogo': {
    height: 64
  },
  '.normalLogo': {
    height: 56
  },
  '.smallLogo': {
    height: 40
  },
  '.tinyLogo': {
    height: 24
  }
}));
