import {
  EnglishWeeklyOptionMenuStrings,
  FrenchWeeklyOptionMenuStrings,
  WeeklyOptionMenuStrings
} from './WeeklyOptionMenuStrings';

export interface WeeklyStrings {
  readonly optionMenu: WeeklyOptionMenuStrings;
}

export const EnglishWeeklyStrings: WeeklyStrings = {
  optionMenu: EnglishWeeklyOptionMenuStrings
};

export const FrenchWeeklyStrings: WeeklyStrings = {
  optionMenu: FrenchWeeklyOptionMenuStrings
};
