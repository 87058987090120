import {
  Section_Schedule as PBSchedule,
  Section as PBSection
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { Memoize } from 'fast-typescript-memoize';
import _ from 'lodash';
import { action, computed, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import {
  EditableStringArrayProperty,
  EditableStringProperty,
  EditableValueArrayPropertyEx,
  EditableValuePropertyEx,
  FullyEditableListProperty
} from '../editables';
import { Color, Role } from '../types';
import { protobufFromColor, protobufFromRole } from '../types/EnumConversion';
import { EditableSectionSchedule } from './EditableSectionSchedule';
import { Section, SectionModel } from './Section';
import { SectionScheduleModel } from './SectionSchedule';
import { TrackedEditableModel } from './TrackedEditableModel';

export class EditableSection extends TrackedEditableModel<PBSection, SectionModel> implements SectionModel {
  private _importId: EditableStringProperty<PBSection>;
  private _title: EditableStringProperty<PBSection>;
  private _shortTitle: EditableStringProperty<PBSection>;
  private _gradeLevel: EditableStringProperty<PBSection>;
  private _sectionNumber: EditableStringProperty<PBSection>;
  private _associatedSectionNumbers: EditableStringArrayProperty<PBSection>;
  private _color: EditableValuePropertyEx<Color, PBSection>;
  private _defaultRoomName: EditableStringProperty<PBSection>;
  private _defaultTeacherId: EditableStringProperty<PBSection>;
  private _autoEnrollRoles: EditableValueArrayPropertyEx<Role, PBSection>;
  private _autoEnrollTags: EditableStringArrayProperty<PBSection>;
  private _isFree: EditableValuePropertyEx<boolean, PBSection>;
  private _isSystemDefault: EditableValuePropertyEx<boolean, PBSection>;
  private _isLocked: EditableValuePropertyEx<boolean, PBSection>;
  private _schedules: FullyEditableListProperty<PBSchedule, SectionScheduleModel, EditableSectionSchedule, PBSection>;

  static createNew(color?: Color): EditableSection {
    const pb = new PBSection();
    pb.id = uuidv4();

    if (color != null) {
      pb.color = protobufFromColor(color);
    }

    // All other values are left empty.
    return new EditableSection(new Section(pb), true);
  }

  constructor(
    private readonly _originalSection: SectionModel,
    isNew = false
  ) {
    super(_originalSection, (s, v) => (s.manualChanges = v), isNew);
    makeObservable(this);

    this._importId = this.addStringField(_originalSection.importId, (pb, value) => (pb.importId = value), {
      trim: true
    });
    this._title = this.addStringField(_originalSection.title, (pb, value) => (pb.title = value), {
      trim: true
    });
    this._shortTitle = this.addStringField(_originalSection.shortTitle, (pb, value) => (pb.shortTitle = value), {
      trim: true
    });
    this._gradeLevel = this.addStringField(_originalSection.gradeLevel, (pb, value) => (pb.gradeLevel = value), {
      trim: true
    });
    this._sectionNumber = this.addStringField(
      _originalSection.sectionNumber,
      (pb, value) => (pb.sectionNumber = value),
      {
        trim: true
      }
    );
    this._associatedSectionNumbers = this.addStringArrayField(
      _originalSection.associatedSectionNumbers,
      (pb, values) => (pb.associatedSectionNumbers = values),
      {
        trim: true
      }
    );
    this._color = this.addValueField(_originalSection.color, (pb, value) => (pb.color = protobufFromColor(value)));
    this._defaultRoomName = this.addStringField(
      _originalSection.defaultRoomName,
      (pb, value) => (pb.defaultRoomName = value),
      {
        trim: true
      }
    );
    this._defaultTeacherId = this.addStringField(
      _originalSection.defaultTeacherId,
      (pb, value) => (pb.defaultTeacherId = value),
      {
        trim: true
      }
    );
    this._autoEnrollRoles = this.addValueArrayField(
      _originalSection.autoEnrollRoles,
      (pb, values) => (pb.autoEnrollRoles = values.map(protobufFromRole))
    );
    this._autoEnrollTags = this.addStringArrayField(
      _originalSection.autoEnrollTags,
      (pb, values) => (pb.autoEnrollTags = values)
    );
    this._isFree = this.addValueField(_originalSection.isFree, (pb, value) => (pb.isFree = value));
    this._isSystemDefault = this.addValueField(
      _originalSection.isSystemDefault,
      (pb, value) => (pb.isSystemDefault = value)
    );
    this._isLocked = this.addValueField(_originalSection.isLocked, (pb, value) => (pb.isLocked = value));
    this._schedules = this.addEditableListField(
      _originalSection.schedules.map((schedule) => new EditableSectionSchedule(schedule)),
      (pb, values) => (pb.schedules = values)
    );
  }

  get id(): string {
    return this._originalSection.id;
  }

  get isDeleted(): boolean {
    return this._originalSection.isDeleted;
  }

  @computed
  get importId(): string {
    return this._importId.value;
  }

  set importId(value: string) {
    this._importId.value = value;
    this.addManualChanges('importId');
  }

  @computed
  get title(): string {
    return this._title.value;
  }

  set title(value: string) {
    this._title.value = value;
    this.addManualChanges('title');
  }

  @computed
  get shortTitle(): string {
    return this._shortTitle.value;
  }

  set shortTitle(value: string) {
    this._shortTitle.value = value;
    this.addManualChanges('shortTitle');
  }

  @computed
  get gradeLevel(): string {
    return this._gradeLevel.value;
  }

  set gradeLevel(value: string) {
    this._gradeLevel.value = value;
    this.addManualChanges('gradeLevel');
  }

  @computed
  get sectionNumber(): string {
    return this._sectionNumber.value;
  }

  set sectionNumber(value: string) {
    this._sectionNumber.value = value;
    this.addManualChanges('sectionNumber');
  }

  @computed
  get associatedSectionNumbers(): string[] {
    return this._associatedSectionNumbers.value;
  }

  set associatedSectionNumbers(value: string[]) {
    this._associatedSectionNumbers.value = value;
    this.addManualChanges('associatedSectionNumbers');
  }

  @computed
  get color(): Color {
    return this._color.value;
  }

  set color(value: Color) {
    this._color.value = value;
    this.addManualChanges('color');
  }

  @computed
  get defaultRoomName(): string {
    return this._defaultRoomName.value;
  }

  set defaultRoomName(value: string) {
    this._defaultRoomName.value = value;
    this.addManualChanges('defaultRoomName');
  }

  @computed
  get defaultTeacherId(): string {
    return this._defaultTeacherId.value;
  }

  set defaultTeacherId(value: string) {
    this._defaultTeacherId.value = value;
    this.addManualChanges('defaultTeacherId');
  }

  get hasDefaultTeacherChanged() {
    return this._defaultTeacherId.isChanged;
  }

  get originalDefaultTeacherId() {
    return this._defaultTeacherId.originalValue;
  }

  @computed
  get autoEnrollRoles(): Role[] {
    return this._autoEnrollRoles.value;
  }

  set autoEnrollRoles(value: Role[]) {
    this._autoEnrollRoles.value = value;
    this.addManualChanges('autoEnrollRoles');
  }

  @computed
  get autoEnrollTags(): string[] {
    return this._autoEnrollTags.value;
  }

  set autoEnrollTags(value: string[]) {
    this._autoEnrollTags.value = value;
    this.addManualChanges('autoEnrollTags');
  }

  @computed
  get isFree(): boolean {
    return this._isFree.value;
  }

  set isFree(value: boolean) {
    this._isFree.value = value;
    this.addManualChanges('isFree');
  }

  @computed
  get isSystemDefault(): boolean {
    return this._isSystemDefault.value;
  }

  set isSystemDefault(value: boolean) {
    this._isSystemDefault.value = value;
    this.addManualChanges('isSystemDefault');
  }

  @computed
  get isLocked(): boolean {
    return this._isLocked.value;
  }

  set isLocked(value: boolean) {
    this._isLocked.value = value;
    this.addManualChanges('isLocked');
  }

  @computed
  get schedules(): SectionScheduleModel[] {
    return this._schedules.values;
  }

  @computed
  get editableSchedules(): EditableSectionSchedule[] {
    return this._schedules.values;
  }

  @computed
  get allEditableSchedules(): EditableSectionSchedule[] {
    return this._schedules.allValues;
  }

  @action
  addSchedule(schedule: EditableSectionSchedule) {
    this._schedules.addItem(schedule);
  }

  @Memoize()
  get teacherIds(): string[] {
    return _.uniq(
      _.compact([this.defaultTeacherId, ..._.flatMap(this.schedules.map((schedule) => schedule.teacherIds))])
    );
  }
}
