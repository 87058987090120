import { ObservablePresenter, SessionsPerDayChart, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  SxProps,
  ThemeProvider,
  Typography
} from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { MinMediumChartHeight, StudentsColor, TeachersColor } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SessionsPerDayDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  accountId: string;
  accountType: 'student' | 'teacher';
}

export const SessionsPerDayDialog = observer((props: SessionsPerDayDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, accountType, style, accountId, configId, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sessionsPerDayDialog;
  const accountStrings =
    accountType === 'student'
      ? localizationService.localizedStrings.insights.views.metrics.students
      : localizationService.localizedStrings.insights.views.metrics.teachers;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createDailyAccountSessions(configId, accountId)
  );
  const color = accountType === 'student' ? StudentsColor : TeachersColor;

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        scroll="paper"
        onClose={() => onSuccess!()}
        className={className}
        style={style}
      >
        <DialogContent sx={{ height: MinMediumChartHeight }}>
          <ObservablePresenter
            sx={{ width: '100%', height: '100%' }}
            data={viewModel.data}
            loadingMessage={strings.loadingMessage}
            errorMessage={strings.loadingErrorMessage}
            render={(data) => {
              if (data.length === 0) {
                return (
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <Typography variant="h5">{accountStrings.sessionsPerDayEmptyMessage}</Typography>
                  </Box>
                );
              }

              return (
                <SessionsPerDayChart
                  caption={accountStrings.sessionsPerDay}
                  subcaption={accountStrings.sessionsPerDaySubcaption}
                  sessions={data}
                  color={color}
                />
              );
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => onSuccess!()}>
            {strings.closeButtonCaption}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
