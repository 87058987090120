import { OperationParameter as PBOperationParameter } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { BaseModel } from '../../Model';
import { OperationParameter } from '../interfaces';

export class GrpcOperationParameter extends BaseModel<PBOperationParameter> implements OperationParameter {
  constructor(pb: PBOperationParameter) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get name(): string {
    return this._pb.name;
  }

  get description(): string {
    return this._pb.description;
  }

  get expectedType(): string {
    return this._pb.expectedType;
  }

  get permittedValues(): string[] {
    return this._pb.permittedValues;
  }

  get isOptional(): boolean {
    return this._pb.isOptional;
  }

  get isMultiple(): boolean {
    return this._pb.isMultiple;
  }
}
