import CollapseIcon from '@mui/icons-material/CloseFullscreen';
import ExpandIcon from '@mui/icons-material/OpenInFull';
import { IconButton, InputAdornment, SxProps, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { EditableOnboardingAnswer, OnboardingAnswer } from '@shared/models/onboarding';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';
import { LastAnswerTooltip } from './LastAnswerTooltip';

export interface MultiLineTextFieldQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  answer: EditableOnboardingAnswer;
  lastYearAnswer?: OnboardingAnswer;
  disabled?: boolean;
  showLargerEdit?: boolean;
}

export const MultiLineTextFieldQuestion = observer((props: MultiLineTextFieldQuestionProps) => {
  const { className, style, answer, lastYearAnswer, disabled = false, showLargerEdit: showPopupEdit = false } = props;

  const [isLarger, setIsLarger] = useState(false);

  // We work with styles because we want the input to be read-only
  // but not disabled, to allow text selection.
  const inputStyle: CSSProperties = disabled
    ? {
        fontFamily: isLarger ? 'monospace' : 'inherit',
        fontWeight: 400,
        color: grey[500]
      }
    : {
        fontFamily: isLarger ? 'monospace' : 'inherit',
        fontWeight: 400
      };

  return (
    <TextField
      className={className}
      style={style}
      sx={isLarger ? { maxWidth: 'unset !important', pr: 2 } : undefined}
      slotProps={{
        input: {
          readOnly: disabled,
          style: inputStyle,
          endAdornment: (
            <InputAdornment position="end">
              {showPopupEdit && (
                <IconButton onClick={() => setIsLarger(!isLarger)} onMouseDown={(e) => e.preventDefault()} edge="end">
                  {isLarger ? <CollapseIcon /> : <ExpandIcon />}
                </IconButton>
              )}
              {lastYearAnswer != null && lastYearAnswer.largeTextValue.length > 0 && (
                <LastAnswerTooltip
                  sx={{ ml: showPopupEdit ? 2 : 0 }}
                  answer={lastYearAnswer.largeTextValue}
                  onCopy={() => (answer.largeTextValue = lastYearAnswer.largeTextValue)}
                />
              )}
            </InputAdornment>
          )
        }
      }}
      fullWidth
      value={answer.largeTextValue}
      multiline
      rows={isLarger ? 20 : 5}
      onChange={(e) => (answer.largeTextValue = e.target.value)}
    />
  );
});
