import { AccountService, NavigationService } from '@insights/services';
import { caseInsensitiveAccentInsensitiveCompare } from '@insights/utils';
import { AccountModel } from '@shared/models/config';
import { Memoize } from 'fast-typescript-memoize';

export interface WorkloadManagerStudentListDialogViewModel {
  readonly students: AccountModel[];

  viewPlanner: (studentId: string) => Promise<void>;
  close: () => void;
}

export class AppWorkloadManagerStudentListDialogViewModel implements WorkloadManagerStudentListDialogViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _accountService: AccountService,
    private readonly _students: AccountModel[],
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {}

  @Memoize()
  get students(): AccountModel[] {
    return this._students
      .slice()
      .sort(
        (a, b) =>
          caseInsensitiveAccentInsensitiveCompare(a.lastName, b.lastName) ||
          caseInsensitiveAccentInsensitiveCompare(a.firstName, b.firstName)
      );
  }

  async viewPlanner(studentId: string): Promise<void> {
    if (this._accountService.currentConfigId == null) {
      console.error('Tried to call `navigateToPlannerExternal` without a configId');
      return;
    }

    await this._navigationService.navigateToPlannerExternal(this._accountService.currentConfigId, studentId);
  }

  close() {
    this._onSuccess();
  }
}
