import { EnvironmentService } from '@shared/services';
import {
  AnalyticsConfigInfo,
  AnalyticsEvent,
  AnalyticsPage,
  AnalyticsService,
  AnalyticsUserInfo
} from '@shared/services/analytics';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
  }
}

export interface IntercomEventProperties {
  eventCategory: string;
  eventLabel?: string;
  activeScreen?: string;
}

export abstract class WebIntercomAnalyticsService<
  TPage extends AnalyticsPage<string>,
  TEvent extends AnalyticsEvent<string>,
  TEventProperties extends IntercomEventProperties
> implements AnalyticsService<TPage, TEvent>
{
  protected _userInfo: AnalyticsUserInfo | undefined;
  protected _configInfo: AnalyticsConfigInfo | undefined;

  constructor(private readonly _environmentService: EnvironmentService) {
    // This will take care of calling Intercom('boot')
    this.updateIntercomUserInfo();
  }

  setUserInfo(info: AnalyticsUserInfo) {
    if (this._userInfo == null || this._userInfo.userId !== info.userId) {
      this._userInfo = info;
      this.updateIntercomUserInfo();
    }
  }

  clearUserInfo() {
    if (this._userInfo != null) {
      this._userInfo = undefined;
      this.updateIntercomUserInfo();
    }
  }

  setConfigInfo(info: AnalyticsConfigInfo) {
    if (this._configInfo == null || this._configInfo.accountId !== info.accountId) {
      this._configInfo = info;
      this.updateIntercomUserInfo();
    }
  }

  clearConfigInfo() {
    if (this._configInfo != null) {
      this._configInfo = undefined;
      this.updateIntercomUserInfo();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  trackPage(page: TPage): void {}

  trackEvent(event: TEvent): void {
    // Build the properties to send with the event
    const properties = this.buildEventProperties(event);

    // Set the properties that are specific to events
    properties.eventCategory = event.action.category;
    properties.eventLabel = event.label;

    if (window.Intercom) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Intercom('trackEvent', event.action.name, properties);
    }
  }

  protected abstract buildEventProperties(event: TEvent): TEventProperties;

  private updateIntercomUserInfo() {
    if (this._userInfo == null) {
      if (window.Intercom) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.Intercom('shutdown');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.Intercom('boot', {
          app_id: this._environmentService.intercomAppId
        });
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let data: any = {
        user_id: this._userInfo.userId,
        user_hash: this._userInfo.intercomHash,
        email: this._userInfo.email,
        userName: this._userInfo.userName
      };

      if (this._configInfo != null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data = {
          ...data,
          accountId: this._configInfo.accountId,
          accountRole: this._configInfo.accountRole,
          configId: this._configInfo.configId,
          schoolName: this._configInfo.schoolName,
          name: this._configInfo.accountFullName
        };

        if (this._configInfo.accountRole !== 'individual') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          data.companies = [
            {
              company_id: this._configInfo.configId,
              name: this._configInfo.schoolName
            }
          ];
        }
      }

      if (window.Intercom) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.Intercom('update', data);
      }
    }
  }
}
