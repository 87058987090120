export interface ForbiddenStrings {
  readonly title: string;
  readonly subtitle: string;
  readonly goToHomepageButtonTitle: string;
}

export const EnglishForbiddenString: ForbiddenStrings = {
  title: 'Not Authorized',
  subtitle: 'You are not authorized to view this content',
  goToHomepageButtonTitle: 'Go to homepage'
};

export const FrenchForbiddenString: ForbiddenStrings = {
  title: 'Accès refusé',
  subtitle: 'Vous n’êtes pas autorisé à voir ce contenu',
  goToHomepageButtonTitle: 'Retourner à la page principale'
};
