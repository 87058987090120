import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';
import { RouteParamNames, RouteTemplates } from '../../Routes.ts';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export const Landing = observer(() => {
  const { accountService, reactRouterRouteService } = useInsightsServices();
  // If the user is a root admin, always redirect to the school configurations selection page,
  // even if we're in demo mode.
  if (accountService.isRootAdmin) {
    return <Navigate to={reactRouterRouteService.resolveLocation(RouteTemplates.schoolConfigurations)} replace />;
  }

  // Redirect to the latest non-completed config, if any.
  const latestConfig = accountService.defaultConfiguration;
  if (latestConfig != null) {
    const location =
      latestConfig.schoolConfiguration.state === 'preparing'
        ? reactRouterRouteService.resolveLocation(RouteTemplates.manageOnboarding, [
            {
              name: RouteParamNames.configId,
              value: latestConfig.schoolConfiguration.id
            }
          ])
        : reactRouterRouteService.resolveLocation(RouteTemplates.dashboard, [
            {
              name: RouteParamNames.configId,
              value: latestConfig.schoolConfiguration.id
            }
          ]);

    return <Navigate to={location} replace />;
  }

  return <Navigate to={reactRouterRouteService.resolveLocation(RouteTemplates.noSchoolConfigurations)} replace />;
});
