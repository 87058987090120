import { Typography } from '@mui/material';
import { SpecialDayModel } from '@shared/models/config';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface DisplayCycleDayProps {
  specialDay: SpecialDayModel;
}

export const DisplayCycleDay = observer((props: DisplayCycleDayProps) => {
  const { specialDay } = props;
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.components.calendar;

  const cycleDay =
    specialDay.cycleDay === 0
      ? specialDay.cycleDayEffect === 'preserve'
        ? strings.preserveShort
        : strings.none
      : `${specialDay.cycleDay}`;

  return <Typography variant="caption">{cycleDay}</Typography>;
});
