import { Stack, SxProps, Typography, TypographyVariant } from '@mui/material';
import { Incident } from '@shared/models/import';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { ImportDataIncidentIcon, IncidentColor, IncidentSize } from './ImportDataIncidentIcon';

export interface ImportDataIncidentProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  incident: Incident;
  color?: IncidentColor;
  colorMode?: 'no-incident-color' | 'icon-incident-color' | 'full-incident-color';
  size?: IncidentSize;
}

export const ImportDataIncident = observer(
  ({
    incident,
    className,
    style,
    sx = [],
    color = 'inherit',
    size = 'normal',
    colorMode = 'no-incident-color'
  }: ImportDataIncidentProps) => {
    const codeVariant: TypographyVariant = useMemo(() => {
      switch (size) {
        case 'normal':
          return 'subtitle2';
        case 'small':
          return 'caption';
        default:
          return 'subtitle2';
      }
    }, [size]);

    const messageVariant: TypographyVariant = useMemo(() => {
      switch (size) {
        case 'normal':
          return 'body1';
        case 'small':
          return 'caption';
        default:
          return 'body1';
      }
    }, [size]);

    const iconColor = colorMode !== 'no-incident-color' ? color : undefined;
    const textColor = colorMode === 'full-incident-color' ? color : undefined;

    return (
      <Stack sx={sx} className={className} style={style} direction="row" spacing={1} alignItems="flex-start">
        <Stack direction="row" spacing={1} alignItems="center">
          <ImportDataIncidentIcon severity={incident.severity} color={iconColor} size={size} />
          <Typography variant={codeVariant} color={textColor}>
            {incident.code}
          </Typography>
        </Stack>
        <Stack spacing={0}>
          <Typography variant={messageVariant} color={textColor}>
            {incident.message}
          </Typography>
          <Typography variant="caption" color={textColor}>
            {incident.relatedIdentifiers.join(', ')}
          </Typography>
        </Stack>
      </Stack>
    );
  }
);
