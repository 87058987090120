import { ContentBoxImages } from '@shared/resources/images/studyo';
import contentBoxAddFilledImage from '@shared/web/images/assets/studyo/ui_content_box_add_filled.svg?url';
import contentBoxAddOutlinedImage from '@shared/web/images/assets/studyo/ui_content_box_add_outlined.svg?url';
import contentBoxMoreFilledImage from '@shared/web/images/assets/studyo/ui_content_box_more_filled.svg?url';
import contentBoxMoreOutlinedImage from '@shared/web/images/assets/studyo/ui_content_box_more_outlined.svg?url';

export class WebContentBoxImages implements ContentBoxImages {
  get addFilled() {
    return contentBoxAddFilledImage;
  }

  get addOutlined() {
    return contentBoxAddOutlinedImage;
  }

  get moreFilled() {
    return contentBoxMoreFilledImage;
  }

  get moreOutlined() {
    return contentBoxMoreOutlinedImage;
  }
}
