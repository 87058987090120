import { AlertService } from '@insights/services';
import { EditableSchoolYearConfiguration, SchoolYearConfigurationModel } from '@shared/models/config';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { isAfter } from 'date-fns';
import { computed, makeObservable } from 'mobx';
import { AppEditViewModelBase, EditViewModelBase } from '../../EditViewModelBase';

export type SchoolYearConfigurationDateFields = 'startDay' | 'endDay' | 'teacherAccessDate' | 'studentAccessDate';

export type EditSchoolYearConfigurationDatesDialogViewModel = EditViewModelBase<SchoolYearConfigurationDateFields>;

export class AppEditSchoolYearConfigurationDatesDialogViewModel
  extends AppEditViewModelBase<SchoolYearConfigurationDateFields>
  implements EditSchoolYearConfigurationDatesDialogViewModel
{
  private readonly _editableSchoolYearConfiguration: EditableSchoolYearConfiguration;

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _schoolYearConfiguration: SchoolYearConfigurationModel,
    alertService: AlertService,
    localizationService: LocalizationService,
    onSuccess: () => void,
    onCancel: () => void
  ) {
    super(alertService, localizationService, onSuccess, onCancel);
    makeObservable(this);
    this._editableSchoolYearConfiguration = new EditableSchoolYearConfiguration(_schoolYearConfiguration);
  }

  @computed
  get hasChanges(): boolean {
    return this._editableSchoolYearConfiguration.hasChanges;
  }

  getFieldValueCore(field: SchoolYearConfigurationDateFields): Date | undefined {
    switch (field) {
      case 'startDay':
        return this._editableSchoolYearConfiguration.startDay.asDate;
      case 'endDay':
        return this._editableSchoolYearConfiguration.endDay.asDate;
      case 'teacherAccessDate':
        return this._editableSchoolYearConfiguration.teacherAvailabilityDate;
      case 'studentAccessDate':
        return this._editableSchoolYearConfiguration.studentAvailabilityDate;
      default:
        throw new Error('Unkwown field');
    }
  }

  setFieldValueCore(field: SchoolYearConfigurationDateFields, value: Date | undefined) {
    switch (field) {
      case 'startDay':
        this._editableSchoolYearConfiguration.startDay = Day.fromDate(value)!;
        this.validateStartAndEndDay();
        break;
      case 'endDay':
        this._editableSchoolYearConfiguration.endDay = Day.fromDate(value)!;
        this.validateStartAndEndDay();
        break;
      case 'teacherAccessDate':
        this._editableSchoolYearConfiguration.teacherAvailabilityDate = value;
        this.validateTeacherAccessDate();
        break;
      case 'studentAccessDate':
        this._editableSchoolYearConfiguration.studentAvailabilityDate = value;
        this.validateStudentAccessDate();
        break;
      default:
        throw new Error('Unkwown field');
    }
  }

  protected async saveCore(): Promise<void> {
    await this._schoolYearConfigurationStore.saveConfig(this._editableSchoolYearConfiguration);
  }

  protected validateFields() {
    this.validateStartAndEndDay();
    this.validateTeacherAccessDate();
    this.validateStudentAccessDate();
  }

  private validateStartAndEndDay() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.schoolYearConfiguration;

    const startDayFieldName = 'startDay';
    const startDayFieldValue = this.getFieldValue(startDayFieldName) as Date;
    let startDayFieldError: string | undefined = undefined;

    const endDayFieldName = 'endDay';
    const endDayFieldValue = this.getFieldValue(endDayFieldName) as Date;
    let endDayFieldError: string | undefined = undefined;

    if (isAfter(startDayFieldValue, endDayFieldValue)) {
      startDayFieldError = strings.startDateAfterEndDate;
      endDayFieldError = strings.endDateBeforeStartDate;
    }

    this.setError(startDayFieldName, startDayFieldError);
    this.setError(endDayFieldName, endDayFieldError);
  }

  private validateTeacherAccessDate() {
    const fieldName = 'teacherAccessDate';
    const fieldError: string | undefined = undefined;

    // TODO Add validation logic

    this.setError(fieldName, fieldError);
  }

  private validateStudentAccessDate() {
    const fieldName = 'studentAccessDate';
    const fieldError: string | undefined = undefined;

    // TODO Add validation logic

    this.setError(fieldName, fieldError);
  }
}
