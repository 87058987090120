import { SectionScheduleDayCase } from '@shared/models/config';

export interface SectionComponentsStrings {
  addSectionTooltip: string;
  associatedSectionNumbers: string;
  associatedSectionNumbersDescription: string;
  autoEnrollConflictWarning: string;
  autoEnrollTagsLabel: string;
  autoEnrollSectionTitle: string;
  availableStudents: string;
  availableTeachers: string;
  batchEditSections: string;
  changeScheduleTeachers: string;
  copyId: string;
  copyIdNotification: string;
  cycleDay: string;
  day: string;
  dayOfWeek: string;
  defaultRoomName: string;
  defaultTeacher: string;
  defaultTeacherTooltip: string;
  disableSectionSelectionTooltip: string;
  displayedTag: string;
  editDetails: string;
  editSchedules: string;
  editStudents: string;
  editTeachers: string;
  emptyAutoEnrollTag: string;
  enableSectionSelectionTooltip: string;
  end: string;
  exportToCsv: string;
  free: string;
  generateRandomImportIdButton: string;
  grade: string;
  gradeLevel: string;
  group: string;
  importIdentifier: string;
  importIdentifierEmptyError: string;
  importIdentifierMultiError: string;
  importIdentifierUniqueError: string;
  invalidTeacher: string;
  lockedFormControlLabel: string;
  manageConflicts: string;
  moreTeachers: (count: number) => string;
  none: string;
  notTeachingAllSchedulesInfo: string;
  period: string;
  periodTag: string;
  room: string;
  schedules: string;
  scheduleTag: string;
  sectionOrGroupNumber: string;
  selectedStudents: string;
  selectedTeachers: string;
  selectedTeachersNotice: string;
  shortTitle: string;
  specificDay: string;
  specificTime: string;
  start: string;
  studentCount: string;
  systemDefault: string;
  teachers: string;
  term: string;
  time: string;
  title: string;
  titleEmptyError: string;
  titleMultiError: string;
  toggleSelectStudentsTooltip: string;
  toggleSelectTeachersTooltip: string;
  unknownSection: string;

  dayCase(dayCase: SectionScheduleDayCase): string;
  getReadableAutoEnrollTag(tag: string): string;
  getScheduleCountPerTerm(termTag: string, scheduleCount: number): string;
}

export const EnglishSectionComponentsStrings: SectionComponentsStrings = {
  addSectionTooltip: 'Add section',
  associatedSectionNumbers: 'Associated groups (homeroom)',
  associatedSectionNumbersDescription: 'Separate each value with a comma (,)',
  autoEnrollConflictWarning: 'You must either select roles or tags, but not both.',
  autoEnrollTagsLabel: 'Tags (levels)',
  autoEnrollSectionTitle: 'Auto-enrollment',
  availableStudents: 'Available students',
  availableTeachers: 'Available teachers',
  batchEditSections: 'Edit selected sections',
  changeScheduleTeachers: 'Also change all schedules',
  cycleDay: 'Cycle day',
  copyId: 'Copy ID to clipboard',
  copyIdNotification: 'The ID was copied to the clipboard!',
  day: 'Day',
  dayOfWeek: 'Day of week',
  defaultRoomName: 'Default Room Name',
  defaultTeacher: 'Default Teacher',
  defaultTeacherTooltip: 'This is the default teacher.',
  disableSectionSelectionTooltip: 'Disable section selection',
  displayedTag: 'Displayed tag',
  editDetails: 'Edit details',
  editSchedules: 'Edit schedules',
  editStudents: 'Edit students',
  editTeachers: 'Edit teachers',
  emptyAutoEnrollTag: 'Tags cannot be empty',
  enableSectionSelectionTooltip: 'Enable section selection',
  end: 'End',
  exportToCsv: 'Export as CSV',
  free: 'Free',
  generateRandomImportIdButton: 'New',
  grade: 'Grade',
  gradeLevel: 'Grade Level',
  group: 'Group',
  importIdentifier: 'Import Identifier (SIS code)',
  importIdentifierEmptyError: 'You must enter an import identifier (SIS id), or generate a new one.',
  importIdentifierMultiError: 'Some import identifiers are empty or have duplicates.',
  importIdentifierUniqueError: 'This import identifier is already used by another section.',
  invalidTeacher: '(Invalid)',
  lockedFormControlLabel: 'Locked (cannot be deleted when importing data)',
  manageConflicts: 'Manage schedule conflicts',
  moreTeachers: (count) => `and ${count} more`,
  none: 'None',
  notTeachingAllSchedulesInfo: 'This teacher is not assigned to all schedules.',
  period: 'Period',
  periodTag: 'Period tag',
  room: 'Room',
  schedules: 'Schedules',
  scheduleTag: 'Schedule tag',
  sectionOrGroupNumber: 'Section/Group Number',
  selectedStudents: 'Selected students',
  selectedTeachers: 'Selected teachers',
  selectedTeachersNotice: 'They are not teaching this section. They only are members, just like students.',
  shortTitle: 'Short Title',
  specificDay: 'Specific day',
  specificTime: 'Specific Time',
  start: 'Start',
  studentCount: 'Student Count',
  systemDefault: 'System Default',
  teachers: 'Teachers',
  term: 'Term',
  time: 'Time',
  title: 'Title',
  titleEmptyError: 'You must enter a title.',
  titleMultiError: 'Some titles are empty.',
  toggleSelectStudentsTooltip: 'View students',
  toggleSelectTeachersTooltip: 'View teachers (they will be considered students in that section)',
  unknownSection: '[Unknown section]',

  dayCase(dayCase: SectionScheduleDayCase): string {
    switch (dayCase) {
      case 'cycle-day':
        return EnglishSectionComponentsStrings.cycleDay;
      case 'day-of-week':
        return EnglishSectionComponentsStrings.dayOfWeek;
      case 'specific-day':
        return EnglishSectionComponentsStrings.specificDay;
      default:
        return dayCase;
    }
  },
  getReadableAutoEnrollTag(tag: string): string {
    switch (tag) {
      case 'gradeLevel':
        return 'Grade level';
      // New tags can be added for special support on the backend. We must accept unknown values.
      default:
        return tag;
    }
  },
  getScheduleCountPerTerm(termTag: string, scheduleCount: number): string {
    return scheduleCount === 0
      ? `${termTag} term: no schedules`
      : scheduleCount > 1
        ? `${termTag} term: ${scheduleCount} schedules`
        : `${termTag} term: ${scheduleCount} schedule`;
  }
};

export const FrenchSectionComponentsStrings: SectionComponentsStrings = {
  addSectionTooltip: 'Ajouter un groupe-matière',
  associatedSectionNumbers: 'Groupes associés (foyers)',
  associatedSectionNumbersDescription: 'Séparer chaque valeur par une virgule (,)',
  autoEnrollConflictWarning: 'Vous devez choisir des rôles ou des tags, mais pas les deux.',
  autoEnrollTagsLabel: 'Tags (niveaux)',
  autoEnrollSectionTitle: 'Auto-inscription',
  availableStudents: 'Élèves disponibles',
  availableTeachers: 'Enseignants disponibles',
  batchEditSections: 'Modifier les groupes-matière sélectionnés',
  changeScheduleTeachers: 'Changer aussi les horaires',
  copyId: 'Copier l’identifiant',
  copyIdNotification: 'L’identifiant est copié dans le presse-papier!',
  cycleDay: 'Jour-cycle',
  day: 'Jour',
  dayOfWeek: 'Jour de la semaine',
  defaultRoomName: 'Local par défaut',
  defaultTeacher: 'Enseignant par défaut',
  defaultTeacherTooltip: 'Enseignant par défaut',
  disableSectionSelectionTooltip: 'Désactiver la sélection de groupes-matière',
  displayedTag: 'Étiquette d’affichage',
  editDetails: 'Modifier les détails',
  editSchedules: 'Modifier les horaires',
  editStudents: 'Modifier les élèves',
  editTeachers: 'Modifier les enseignants',
  emptyAutoEnrollTag: 'Les tags ne peuvent être vides',
  enableSectionSelectionTooltip: 'Activer la sélection de groupes-matière',
  end: 'Fin',
  exportToCsv: 'Exporter en CSV',
  free: 'Libre',
  generateRandomImportIdButton: 'Nouveau',
  grade: 'Niveau',
  gradeLevel: 'Niveau',
  group: 'Groupe',
  importIdentifier: 'Identifiant d’importation (code SIS)',
  importIdentifierEmptyError: 'Vous devez entrer un identifiant d’importation ou en générer un nouveau.',
  importIdentifierMultiError: 'Certains identifiants d’importation sont vides ou en double.',
  importIdentifierUniqueError: 'Cet identifiant d’importation est déjà utilisé par un autre groupe-matière.',
  invalidTeacher: '(non-valide)',
  lockedFormControlLabel: 'Verrouillé (ne peut être effacé par une importation)',
  manageConflicts: 'Gérer les conflits d’horaire',
  moreTeachers: (count) => `et ${count} autres`, // count never 1.
  none: 'Aucun',
  notTeachingAllSchedulesInfo: 'Cet enseignant n’est pas assigné à toutes les périodes.',
  period: 'Période',
  periodTag: 'Étiquette',
  room: 'Local',
  schedules: 'Horaires',
  scheduleTag: 'Tag d’horaire',
  sectionOrGroupNumber: 'Numéro de groupe',
  selectedStudents: 'Élèves sélectionnés',
  selectedTeachers: 'Enseignants sélectionnés',
  selectedTeachersNotice: 'Ils n’enseignent pas cette matière. Ils y assistent, comme un élève.',
  shortTitle: 'Titre court',
  specificDay: 'Jour spécifique',
  specificTime: 'Heure spécifique',
  start: 'Début',
  studentCount: 'Élèves',
  systemDefault: 'Valeur par défaut',
  teachers: 'Enseignants',
  term: 'Étape',
  time: 'Heure',
  title: 'Titre',
  titleEmptyError: 'Vous devez entrer un titre.',
  titleMultiError: 'Certains titres sont vides.',
  toggleSelectStudentsTooltip: 'Voir les élèves',
  toggleSelectTeachersTooltip: 'Voir les enseignants (comme s’ils étaient élèves de ce groupe-matière)',
  unknownSection: '[Groupe-matière inconnu]',

  dayCase(dayCase: SectionScheduleDayCase): string {
    switch (dayCase) {
      case 'cycle-day':
        return FrenchSectionComponentsStrings.cycleDay;
      case 'day-of-week':
        return FrenchSectionComponentsStrings.dayOfWeek;
      case 'specific-day':
        return FrenchSectionComponentsStrings.specificDay;
      default:
        return dayCase;
    }
  },
  getReadableAutoEnrollTag(tag: string): string {
    switch (tag) {
      case 'gradeLevel':
        return 'Niveau';
      // New tags can be added for special support on the backend. We must accept unknown values.
      default:
        return tag;
    }
  },
  getScheduleCountPerTerm(termTag: string, scheduleCount: number): string {
    return scheduleCount === 0
      ? `Semestre ${termTag}: aucun horaire`
      : scheduleCount > 1
        ? `Semestre ${termTag}: ${scheduleCount} horaires`
        : `Semestre ${termTag}: ${scheduleCount} horaire`;
  }
};
