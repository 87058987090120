import { AlertService } from '@insights/services';
import { EditableImportSession, EditableSourceFile, ImportSession } from '@shared/models/import';
import { FileEncoding, FileKind } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface ImportSessionAddOrEditExpectedFileDialogViewModel {
  readonly isNew: boolean;

  label: string;
  readonly labelError: string;
  name: string;
  description: string;
  kind: FileKind;
  encoding: FileEncoding;
  hasHeader: boolean;
  sheetName: string;
  topRowsSkipped: number;

  readonly showEncoding: boolean;
  readonly showHasHeader: boolean;
  readonly showSheetName: boolean;
  readonly showTopRowsSkipped: boolean;
  readonly canAdd: boolean;
  readonly isExecuting: boolean;

  saveFile(): Promise<void>;
  cancel(): void;
}

export class AppImportSessionAddOrEditExpectedFileDialogViewModel
  implements ImportSessionAddOrEditExpectedFileDialogViewModel
{
  private readonly _editableSession: EditableImportSession;
  private readonly _editableFile: EditableSourceFile;
  @observable private _hasHeader = true;
  @observable private _isExecuting = false;

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _localizationService: LocalizationService,
    private readonly _alertService: AlertService,
    private readonly _configId: string,
    session: ImportSession,
    private readonly _existingFileLabel: string,
    private readonly _onSuccess: (session?: ImportSession) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._editableSession = new EditableImportSession(session);

    if (_existingFileLabel.length === 0) {
      this._editableFile = EditableSourceFile.createNew();
      this._editableSession.addExpectedFile(this._editableFile);
    } else {
      const existingFile = this._editableSession.expectedFiles.find((f) => f.label === _existingFileLabel);

      if (existingFile == null) {
        throw new Error('Could not find a file to edit with that label.');
      }

      this._editableFile = existingFile;
    }
  }

  get isNew() {
    return this._existingFileLabel.length === 0;
  }

  @computed
  get label() {
    return this._editableFile.label;
  }

  set label(value: string) {
    this._editableFile.label = value.toUpperCase();
  }

  @computed
  get labelError() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.import;
    if (
      this._editableSession.expectedFiles.filter((f) => f !== this._editableFile).find((f) => f.label === this.label) !=
      null
    ) {
      return strings.labelInUseByOtherFile;
    } else if (this._editableSession.concatenations.find((c) => c.label === this.label) != null) {
      return strings.labelInUseByConcatenation;
    } else if (this._editableSession.transformations.find((t) => t.label === this.label) != null) {
      return strings.labelInUseByTransformation;
    }

    return '';
  }

  @computed
  get name() {
    return this._editableFile.name;
  }

  set name(value: string) {
    this._editableFile.name = value;
  }

  @computed
  get description() {
    return this._editableFile.description;
  }

  set description(value: string) {
    this._editableFile.description = value;
  }

  @computed
  get kind() {
    return this._editableFile.kind;
  }

  set kind(value: FileKind) {
    this._editableFile.kind = value;
  }

  @computed
  get encoding() {
    return this._editableFile.expectedEncoding;
  }

  set encoding(value: FileEncoding) {
    this._editableFile.expectedEncoding = value;
  }

  @computed
  get hasHeader() {
    return this._editableFile.hasHeader;
  }

  set hasHeader(value: boolean) {
    this._editableFile.hasHeader = value;
  }

  @computed
  get sheetName(): string {
    return this._editableFile.sheetName;
  }

  set sheetName(value: string) {
    this._editableFile.sheetName = value;
  }

  @computed
  get topRowsSkipped(): number {
    return this._editableFile.topRowsSkipped;
  }

  set topRowsSkipped(value: number) {
    if (!Number.isNaN(value) && value >= 0) {
      this._editableFile.topRowsSkipped = value;
    }
  }

  @computed
  get showEncoding() {
    return this.kind === 'csv' || this.kind === 'xml' || this.kind === 'text';
  }

  @computed
  get showHasHeader() {
    return this.kind === 'csv' || this.kind === 'excel';
  }

  @computed
  get showSheetName(): boolean {
    return this.kind === 'excel';
  }

  @computed
  get showTopRowsSkipped(): boolean {
    // Can't support this with CSV for now.
    return this.kind === 'excel' || this.kind === 'text';
  }

  @computed
  get canAdd() {
    return this.label.length > 0 && this.name.length > 0;
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @action
  async saveFile(): Promise<void> {
    if (!this.canAdd) {
      return;
    }

    this._isExecuting = true;

    try {
      // No need for the data in the origin screen.
      const newSession = await this._importSessionStore.createOrUpdateImportSession(this._editableSession, false);

      this._onSuccess(newSession);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.import;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isExecuting = false));
    }
  }

  cancel(): void {
    this._onCancel();
  }
}
