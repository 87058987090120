import { ExternalAccountKind, Integration } from '@shared/models/types';
import { ExternalImages } from '@shared/resources/images/studyo';
import blackbaudColorImage from '@shared/web/images/assets/studyo/external_task_blackbaud_color.svg?url';
import blackbaudSkyColorImage from '@shared/web/images/assets/studyo/external_task_blackbaud_sky_color.png';
import blackbaudSkyColorLargeImage from '@shared/web/images/assets/studyo/external_task_blackbaud_sky_color_large.png';
import canvasColorImage from '@shared/web/images/assets/studyo/external_task_canvas_color.svg?url';
import classroomColorImage from '@shared/web/images/assets/studyo/external_task_classroom_color.svg?url';
import genericWhiteImage from '@shared/web/images/assets/studyo/external_task_generic_white.svg?url';
import managebacColorImage from '@shared/web/images/assets/studyo/external_task_managebac_color.png';
import managebacColorLargeImage from '@shared/web/images/assets/studyo/external_task_managebac_color_large.png';
import moodleColorImage from '@shared/web/images/assets/studyo/external_task_moodle_color.png';
import moodleColorLargeImage from '@shared/web/images/assets/studyo/external_task_moodle_color_large.png';
import schoologyColorImage from '@shared/web/images/assets/studyo/external_task_schoology_color.png';
import schoologyColorLargeImage from '@shared/web/images/assets/studyo/external_task_schoology_color_large.png';
import studyoInternalColorImage from '@shared/web/images/assets/studyo/external_task_studyo_internal_color.svg?url';
import teamsColorImage from '@shared/web/images/assets/studyo/external_task_teams_color.svg?url';
import veracrossColorImage from '@shared/web/images/assets/studyo/external_task_veracross_color.svg?url';

export class WebExternalImages implements ExternalImages {
  get blackbaudColor() {
    return blackbaudColorImage;
  }

  get blackbaudSkyColorLarge() {
    return blackbaudSkyColorLargeImage;
  }

  get blackbaudSkyColor() {
    return blackbaudSkyColorImage;
  }

  get canvasColor() {
    return canvasColorImage;
  }

  get googleClassroomColor() {
    return classroomColorImage;
  }

  get unknown() {
    return genericWhiteImage;
  }

  get veracrossColor() {
    return veracrossColorImage;
  }

  get schoologyColorLarge() {
    return schoologyColorLargeImage;
  }

  get schoologyColor() {
    return schoologyColorImage;
  }

  get manageBacColorLarge() {
    return managebacColorLargeImage;
  }

  get manageBacColor() {
    return managebacColorImage;
  }

  get microsoftTeamsColor() {
    return teamsColorImage;
  }

  get moodleColorLarge() {
    return moodleColorLargeImage;
  }

  get moodleColor() {
    return moodleColorImage;
  }

  get studyoInternalColor() {
    return studyoInternalColorImage;
  }

  getIntegrationImage(integration: Integration) {
    switch (integration) {
      case 'blackbaud':
        return this.blackbaudColor;
      case 'blackbaud-sky-connector':
        return this.blackbaudSkyColor;
      case 'canvas':
        return this.canvasColor;
      case 'google-classroom-connector':
        return this.googleClassroomColor;
      case 'ical':
        // Default icon is fine.
        return this.unknown;
      case 'schoology-connector':
        return this.schoologyColor;
      case 'veracross-connector':
      case 'veracross-v3-connector':
        return this.veracrossColor;
      case 'managebac-connector':
        return this.manageBacColor;
      case 'microsoft-teams-connector':
        return this.microsoftTeamsColor;
      case 'moodle-connector':
        return this.moodleColor;
      case 'studyo-internal':
        return this.studyoInternalColor;
      default:
        // Older integrations are not supported.
        return this.unknown;
    }
  }

  getExternalAccountImage(kind: ExternalAccountKind) {
    switch (kind) {
      case 'blackbaud':
        return this.blackbaudColor;
      case 'blackbaud-sky':
        return this.blackbaudSkyColorLarge;
      case 'calendars':
        // Default icon is fine.
        return this.unknown;
      case 'canvas':
        return this.canvasColor;
      case 'google':
        return this.googleClassroomColor;
      case 'schoology':
        return this.schoologyColorLarge;
      case 'veracross':
      case 'veracross-v3':
        return this.veracrossColor;
      case 'managebac':
        return this.manageBacColorLarge;
      case 'microsoft-teams':
        return this.microsoftTeamsColor;
      case 'moodle':
        return this.moodleColorLarge;
      case 'studyo-internal':
        return this.studyoInternalColor;
      default:
        return this.unknown;
    }
  }
}
