import * as MB from '@buf/studyo_studyo.bufbuild_es/studyo/type_managebac_pb';
import { Memoize } from 'fast-typescript-memoize';
import { BaseModel } from '../Model';
import { GrpcManageBacContentRepresentation } from './GrpcManageBacContentRepresentation';
import { ManageBacAssignmentKindMappingModel } from './interfaces';

export class GrpcManageBacAssignmentKindMapping
  extends BaseModel<MB.AssignmentKindMapping>
  implements ManageBacAssignmentKindMappingModel
{
  constructor(pb: MB.AssignmentKindMapping) {
    super(pb);
  }

  get kind() {
    return this._pb.kind;
  }

  @Memoize()
  get representation() {
    const representation = this._pb.representation;

    return representation && new GrpcManageBacContentRepresentation(representation);
  }
}
