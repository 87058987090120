import { EditableImportSession, EditableSourceFile, ImportSession } from '@shared/models/import';
import { ImporterStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface ImportSessionFileManualLinkDialogViewModel {
  link: string;

  readonly canSave: boolean;
  readonly isExecuting: boolean;
  readonly hasError: boolean;
  save: () => Promise<void>;
  cancel: () => void;
}

export class AppImportSessionFileManualLinkDialogViewModel implements ImportSessionFileManualLinkDialogViewModel {
  private readonly _editableSession: EditableImportSession;
  private readonly _editableFile: EditableSourceFile;
  private readonly _originalUrl: string;
  @observable private _isExecuting = false;
  @observable private _error: Error | undefined;

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _configId: string,
    session: ImportSession,
    sourceFileLabel: string,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._editableSession = new EditableImportSession(session);
    const editableFile = this._editableSession.expectedFiles.find((f) => f.label === sourceFileLabel);

    if (editableFile == null) {
      throw new Error('Cannot find a file with that label.');
    }

    this._editableFile = editableFile;
    this._originalUrl = editableFile.url;
  }

  @computed
  get link() {
    return this._editableFile.url;
  }

  set link(value: string) {
    this._editableFile.url = value;
  }

  @computed
  get canSave() {
    return this._editableFile.hasChanges;
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @computed
  get hasError() {
    return this._error != null;
  }

  @action
  async save() {
    if (!this._editableFile.hasChanges) {
      return;
    }

    this._isExecuting = true;
    this._error = undefined;

    try {
      // We need to keep the original url in previous urls.
      if (this._originalUrl.length > 0) {
        this._editableFile.addPreviousUrl(this._originalUrl);
      }

      // We simply need to save the session.
      await this._importSessionStore.createOrUpdateImportSession(this._editableSession, false);

      runInAction(() => {
        this._isExecuting = false;
        this._error = undefined;
      });

      this._onSuccess();
    } catch (error) {
      runInAction(() => {
        this._isExecuting = false;
        this._error = error as Error;
      });
    }
  }

  cancel() {
    this._onCancel();
  }
}
