import { SectionInfo } from '@insights/models';
import { SectionModel } from '@shared/models/config';
import { Locale } from '@shared/resources/services';
import { caseInsensitiveAccentInsensitiveCompare } from './StringUtils';

export const SectionUtils = {
  formatTitle: (section: SectionModel, fallbackValue = '[No title]') => {
    const title = section.title.trim();

    if (!title) {
      return fallbackValue;
    }

    return title;
  },

  compareTitles: (a: SectionInfo, b: SectionInfo, locale: Locale) => {
    if (a.section == null) {
      return b.section == null ? 0 : 1;
    } else if (b.section == null) {
      return -1;
    }

    if (!a.section.title.trim() && a.students && !a.students.length) {
      return 1;
    }

    if (!b.section.title.trim() && b.students && !b.students.length) {
      return -1;
    }

    return (
      caseInsensitiveAccentInsensitiveCompare(
        SectionUtils.formatTitle(a.section),
        SectionUtils.formatTitle(b.section),
        locale
      ) || caseInsensitiveAccentInsensitiveCompare(a.section.importId, b.section.importId, undefined, true)
    );
  },

  compareTeachers: (a: SectionInfo, b: SectionInfo, locale?: Locale) => {
    const ta = a.teachers != null ? a.teachers[0]?.account : undefined;
    const tb = b.teachers != null ? b.teachers[0]?.account : undefined;

    if (ta == null) {
      return tb == null ? 0 : -1;
    } else if (tb == null) {
      return 1;
    }

    return (
      caseInsensitiveAccentInsensitiveCompare(ta.lastName, tb.lastName, locale) ||
      caseInsensitiveAccentInsensitiveCompare(ta.firstName, tb.firstName, locale)
    );
  },

  compareGrades: (a: SectionInfo, b: SectionInfo, locale?: Locale) => {
    if (a.section == null) {
      return b.section == null ? 0 : 1;
    } else if (b.section == null) {
      return -1;
    }

    return caseInsensitiveAccentInsensitiveCompare(a.section.gradeLevel, b.section.gradeLevel, locale, true);
  },

  compareGroups: (a: SectionInfo, b: SectionInfo, locale?: Locale) => {
    if (a.section == null) {
      return b.section == null ? 0 : 1;
    } else if (b.section == null) {
      return -1;
    }

    return caseInsensitiveAccentInsensitiveCompare(a.section.sectionNumber, b.section.sectionNumber, locale, true);
  },

  compareDefaultRooms: (a: SectionInfo, b: SectionInfo, locale?: Locale) => {
    if (a.section == null) {
      return b.section == null ? 0 : 1;
    } else if (b.section == null) {
      return -1;
    }

    return caseInsensitiveAccentInsensitiveCompare(a.section.defaultRoomName, b.section.defaultRoomName, locale, true);
  },

  compareAssociatedSectionNumbers: (a: SectionInfo, b: SectionInfo, locale?: Locale) => {
    if (a.section == null) {
      return b.section == null ? 0 : 1;
    } else if (b.section == null) {
      return -1;
    }

    return caseInsensitiveAccentInsensitiveCompare(
      a.section.associatedSectionNumbers.join(','),
      b.section.associatedSectionNumbers.join(','),
      locale,
      true
    );
  }
};
