import { AlertService, NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppAutomatedImportListViewModel } from './AutomatedImportListViewModel';
import { AppImportSessionDataListViewModel } from './ImportSessionDataListViewModel';
import { AppImportSessionDetailsViewModel, ImportSessionDetailsViewModel } from './ImportSessionDetailsViewModel';
import { AppImportSessionFileListViewModel } from './ImportSessionFileListViewModel';

export interface ImportSessionDetailsScreenViewModel {
  readonly sessionId: string;
  readonly session: IPromiseBasedObservable<ImportSessionDetailsViewModel>;

  deleteSession(): Promise<void>;
}

export class AppImportSessionDetailsScreenViewModel implements ImportSessionDetailsScreenViewModel {
  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _alertService: AlertService,
    public readonly configId: string,
    public readonly sessionId: string
  ) {
    makeObservable(this);
  }

  @computed
  get session(): IPromiseBasedObservable<ImportSessionDetailsViewModel> {
    return fromPromise(this.loadData());
  }

  async deleteSession(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.import;

    if (
      (await this._alertService.showConfirmation({
        title: strings.confirmDeleteSessionTitle,
        message: strings.confirmDeleteSessionMessage,
        okButtonCaption: strings.yesLabel,
        cancelButtonCaption: strings.noLabel
      })) !== 'cancelled'
    ) {
      await this._importSessionStore.deleteImportSession(this.configId, this.sessionId);
    }
  }

  private async loadData(): Promise<ImportSessionDetailsViewModel> {
    const [session, schemas, imports] = await Promise.all([
      this._importSessionStore.getImportSession(this.sessionId, true),
      this._importSessionStore.getSchemas(this._localizationService.currentLocale),
      this._importSessionStore.getAutomatedImports(this.configId, this.sessionId)
    ]);

    return new AppImportSessionDetailsViewModel(
      session,
      new AppImportSessionFileListViewModel(
        this._importSessionStore,
        this._navigationService,
        this._alertService,
        this._localizationService,
        session
      ),
      new AppImportSessionDataListViewModel(
        this._importSessionStore,
        this._navigationService,
        this._alertService,
        this._localizationService,
        this.configId,
        this.sessionId,
        session,
        schemas
      ),
      new AppAutomatedImportListViewModel(
        this._importSessionStore,
        this._navigationService,
        this._alertService,
        this._localizationService,
        session,
        imports
      )
    );
  }
}
