import { EnglishErrorBoundaryString, ErrorBoundaryStrings, FrenchErrorBoundaryString } from './ErrorBoundaryStrings';
import { EnglishMaterialTableStrings, FrenchMaterialTableStrings, MaterialTableStrings } from './MaterialTableStrings';
import { EnglishRoutesStrings, FrenchRoutesStrings, RoutesStrings } from './RoutesStrings';
import { ComponentsStrings, EnglishComponentsStrings, FrenchComponentsStrings } from './components/ComponentsStrings';
import { EnglishViewModelsStrings, FrenchViewModelsStrings, ViewModelsStrings } from './viewModels/ViewModelsStrings';
import { EnglishViewsStrings, FrenchViewsStrings, ViewsStrings } from './views/ViewsStrings';

export interface InsightsStrings {
  readonly components: ComponentsStrings;
  readonly viewModels: ViewModelsStrings;
  readonly views: ViewsStrings;
  readonly routes: RoutesStrings;
  readonly materialTable: MaterialTableStrings;
  readonly errorBoundary: ErrorBoundaryStrings;

  readonly applicationName: string;
  readonly version: (v: string) => string;
  readonly copyright: (year: number) => string;

  readonly initializingMessage: string;
  readonly loggingInMessage: string;
  readonly loggingOutMessage: string;
  readonly login: string;
  readonly loginError: string;
  readonly noName: string;
  readonly privacyPolicyAlertText: string;
  readonly privacyPolicyConfirmMessage: string;
  readonly signUp: string;

  readonly updateAvailable: string;
}

export const EnglishInsightsStrings: InsightsStrings = {
  components: EnglishComponentsStrings,
  viewModels: EnglishViewModelsStrings,
  views: EnglishViewsStrings,
  routes: EnglishRoutesStrings,
  materialTable: EnglishMaterialTableStrings,
  errorBoundary: EnglishErrorBoundaryString,

  applicationName: 'Studyo Insights',
  version: (v) => `Version ${v}`,
  copyright: (year) => `© Intuitic Inc. 2013-${year}`,

  initializingMessage: 'Initializing. Please wait…',
  loggingInMessage: 'Logging in. Please wait…',
  loggingOutMessage: 'Logging out. Please wait…',
  login: 'Log In',
  loginError: 'An error occurred while logging in',
  noName: '(no name)',
  privacyPolicyAlertText: 'You must agree to the terms in order to create an account:',
  privacyPolicyConfirmMessage: `I have read and agree to Studyo's <a href="https://studyo.co/privacy" target="_blank" style="color: inherit">privacy policy</a> and <a href="https://studyo.co/tos" target="_blank" rel="noreferrer" style="color: inherit">terms of service</a>.`,
  signUp: 'Create an account',

  updateAvailable: 'An update is available. Click here to reload and install the new version.'
};

export const FrenchInsightsStrings: InsightsStrings = {
  components: FrenchComponentsStrings,
  viewModels: FrenchViewModelsStrings,
  views: FrenchViewsStrings,
  routes: FrenchRoutesStrings,
  materialTable: FrenchMaterialTableStrings,
  errorBoundary: FrenchErrorBoundaryString,

  applicationName: 'Studyo Insights',
  version: (v) => `Version ${v}`,
  copyright: (year) => `© Intuitic Inc. 2013-${year}`,

  initializingMessage: 'Chargement en cours…',
  loggingInMessage: 'Connexion en cours. Un instant…',
  loggingOutMessage: 'Déconnexion en cours. Un instant…',
  login: 'Connexion',
  loginError: 'Une erreur de connexion est survenue',
  noName: '(sans nom)',
  privacyPolicyAlertText: 'Vous devez consentir à nos politiques pour créer un compte:',
  privacyPolicyConfirmMessage: `J’ai lu et j’accepte la <a href="https://studyo.co/fr/privacy" target="_blank" style="color: inherit">politique de confidentialité</a> et les <a href="https://studyo.co/fr/tos" target="_blank" rel="noreferrer" style="color: inherit">conditions d’utilisation</a> de Studyo.`,
  signUp: 'Créer un compte',

  updateAvailable: 'Une nouvelle version est disponible. Cliquez ici pour relancer et installer la nouvelle version.'
};
