import { AccountService, NavigationService, SettingsStore } from '@insights/services';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { ConfigState } from '@shared/models/types';
import { Locale, LocalizationService } from '@shared/resources/services';
import { Storage } from '@shared/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { action, computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { NavigateFunction } from 'react-router-dom';
import { StorageKeys } from '../../Constants';

export interface UserMenuInfo {
  username: string;
  initials: string;
  hasSchoolConfigurations: boolean;
}

export interface UserMenuViewModel {
  readonly schoolYearConfiguration: IPromiseBasedObservable<SchoolYearConfigurationModel | undefined>;
  readonly currentConfigId?: string;
  readonly currentConfigTitle: string;
  readonly currentConfigState: ConfigState;
  readonly userInfo: UserMenuInfo;
  readonly demoMode: boolean;
  readonly currentLocale: Locale;

  logout: () => Promise<void>;
  navigateToManageSchool: (configId: string, navigate: NavigateFunction) => Promise<void>;
  navigateToUserSchoolConfigurations: () => Promise<void>;
  navigateToSchoolConfigurations: (navigate: NavigateFunction) => Promise<void>;
  navigateToOnboardingDashboard: (navigate: NavigateFunction) => Promise<void>;
  navigateToUsers: (navigate: NavigateFunction) => Promise<void>;
  navigateToSettings: (navigate: NavigateFunction) => Promise<void>;
  toggleDemoMode: () => void;
  switchLanguage: () => Promise<void>;
}

export class AppUserMenuViewModel implements UserMenuViewModel {
  constructor(
    private readonly schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly settingsStore: SettingsStore,
    private readonly navigationService: NavigationService,
    private readonly accountService: AccountService,
    private readonly localizationService: LocalizationService,
    private readonly localStorage: Storage
  ) {
    makeObservable(this);
  }

  private _currentConfigTitle = '';
  private _currentConfigState: ConfigState = 'active';

  @computed
  get schoolYearConfiguration(): IPromiseBasedObservable<SchoolYearConfigurationModel | undefined> {
    const currentConfigId = this.accountService.currentConfigId;

    if (currentConfigId == null) {
      return fromPromise(Promise.resolve(undefined));
    }

    return fromPromise(this.schoolYearConfigurationStore.getConfig(currentConfigId));
  }

  @computed
  get currentConfigId(): string | undefined {
    switch (this.schoolYearConfiguration.state) {
      case 'pending':
        return undefined;
      case 'rejected':
        return undefined;
      case 'fulfilled':
        return this.schoolYearConfiguration.value?.id;
    }
  }

  @computed
  get currentConfigTitle(): string {
    switch (this.schoolYearConfiguration.state) {
      case 'pending':
        return this._currentConfigTitle;
      case 'rejected':
        return '';
      case 'fulfilled': {
        const value = this.schoolYearConfiguration.value;
        this._currentConfigTitle =
          value != null ? `${value.schoolName} - ${SchoolYearConfigurationUtils.displayTitle(value)}` : '';
        return this._currentConfigTitle;
      }
    }
  }

  @computed
  get currentConfigState(): ConfigState {
    switch (this.schoolYearConfiguration.state) {
      case 'pending':
        return this._currentConfigState;
      case 'rejected':
        return 'active';
      case 'fulfilled': {
        const value = this.schoolYearConfiguration.value;
        this._currentConfigState = value?.state ?? 'active';
        return this._currentConfigState;
      }
    }
  }

  @computed
  get currentLocale(): Locale {
    return this.localizationService.currentLocale;
  }

  @computed
  get userInfo(): UserMenuInfo {
    return this.loadUserInfo();
  }

  @computed
  get demoMode(): boolean {
    return this.settingsStore.demoMode;
  }

  async logout(): Promise<void> {
    await this.accountService.logout();
  }

  @action
  toggleDemoMode(): void {
    this.settingsStore.demoMode = !this.settingsStore.demoMode;
  }

  async switchLanguage(): Promise<void> {
    const newLocale = this.localizationService.currentLocale === 'en' ? 'fr' : 'en';
    this.localizationService.setCurrentLocale(newLocale);

    await this.localStorage.set(StorageKeys.locale, newLocale);
  }

  navigateToManageSchool(configId: string, navigate: NavigateFunction): Promise<void> {
    // Even though we hold an "active configId", this function requires an id as we
    // could expose more than one school in the menu one day.
    return this.navigationService.navigateToManageSchool(configId, navigate);
  }

  navigateToSchoolConfigurations(navigate: NavigateFunction): Promise<void> {
    return this.navigationService.navigateToSchoolConfigurations(navigate);
  }

  navigateToOnboardingDashboard(navigate: NavigateFunction): Promise<void> {
    return this.navigationService.navigateToOnboardingDashboard(navigate);
  }

  async navigateToUserSchoolConfigurations(): Promise<void> {
    await this.navigationService.navigateToUserSchoolConfigurations();
  }

  navigateToUsers(navigate: NavigateFunction): Promise<void> {
    return this.navigationService.navigateToUsers(navigate);
  }

  navigateToSettings(navigate: NavigateFunction): Promise<void> {
    return this.navigationService.navigateToSettings(navigate);
  }

  private loadUserInfo(): UserMenuInfo {
    const username = this.accountService.userDisplayName;
    const initials = this.computeInitials(username);
    const visibleSchoolConfigurations = this.accountService.visibleSchoolConfigurations;

    return {
      username,
      initials,
      hasSchoolConfigurations: visibleSchoolConfigurations.length > 0
    };
  }

  private computeInitials(username: string): string {
    const split = username.split(' ', 2);
    return split.map((v) => v[0]).join('');
  }
}
