import {
  SourceIndex as PBSourceIndex,
  Transformation as PBTransformation,
  TransformationColumn as PBTransformationColumn
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { action, computed, makeObservable } from 'mobx';
import {
  EditableModelEx,
  EditableStringArrayProperty,
  EditableStringProperty,
  EditableValueArrayPropertyEx,
  FullyEditableListProperty
} from '../../editables';
import { SourceIndex, Transformation, TransformationColumn } from '../interfaces';
import { EditableSourceIndex } from './EditableSourceIndex';
import { EditableTransformationColumn } from './EditableTransformationColumn';
import { GrpcTransformation } from './GrpcTransformation';

export class EditableTransformation extends EditableModelEx<PBTransformation> implements Transformation {
  private readonly _name: EditableStringProperty<PBTransformation>;
  private readonly _label: EditableStringProperty<PBTransformation>;
  private readonly _sourceLabel: EditableStringProperty<PBTransformation>;
  private readonly _description: EditableStringProperty<PBTransformation>;
  private readonly _columns: FullyEditableListProperty<
    PBTransformationColumn,
    TransformationColumn,
    EditableTransformationColumn,
    PBTransformation
  >;
  private readonly _targetSchema: EditableStringProperty<PBTransformation>;
  private readonly _schemaFieldIndexes: EditableValueArrayPropertyEx<number, PBTransformation>;
  private readonly _indexedSources: FullyEditableListProperty<
    PBSourceIndex,
    SourceIndex,
    EditableSourceIndex,
    PBTransformation
  >;
  private readonly _suggestedImportOptions: EditableStringArrayProperty<PBTransformation>;

  static createNew() {
    const pb = new PBTransformation();

    return new EditableTransformation(new GrpcTransformation(pb), true);
  }

  constructor(
    private _originalTransformation: Transformation,
    isNew = false
  ) {
    super(_originalTransformation.toProtobuf(), isNew);

    makeObservable(this);

    this._name = this.addStringField(_originalTransformation.name, (host, value) => (host.name = value), {
      trim: true
    });
    this._label = this.addStringField(_originalTransformation.label, (host, value) => (host.label = value), {
      trim: true
    });
    this._sourceLabel = this.addStringField(
      _originalTransformation.sourceLabel,
      (host, value) => (host.sourceLabel = value),
      { trim: true }
    );
    this._description = this.addStringField(
      _originalTransformation.description,
      (host, value) => (host.description = value)
    );
    this._columns = this.addEditableListField<
      PBTransformationColumn,
      TransformationColumn,
      EditableTransformationColumn
    >(
      _originalTransformation.columns.map((c) => new EditableTransformationColumn(c)),
      (host, values) => (host.columns = values)
    );
    this._targetSchema = this.addStringField(
      _originalTransformation.targetSchema,
      (host, value) => (host.targetSchema = value)
    );
    this._schemaFieldIndexes = this.addValueArrayField<number>(
      _originalTransformation.schemaFieldIndexes,
      (host, values) => (host.schemaFieldIndexes = values)
    );
    this._indexedSources = this.addEditableListField<PBSourceIndex, SourceIndex, EditableSourceIndex>(
      _originalTransformation.indexedSources.map((s) => new EditableSourceIndex(s)),
      (host, values) => (host.indexedSources = values)
    );
    this._suggestedImportOptions = this.addStringArrayField(
      _originalTransformation.suggestedImportOptions,
      (host, values) => (host.suggestedImportOptions = values)
    );
  }

  @computed
  get name() {
    return this._name.value;
  }

  set name(value: string) {
    this._name.value = value;
  }

  @computed
  get label() {
    return this._label.value;
  }

  set label(value: string) {
    this._label.value = value;
  }

  @computed
  get sourceLabel() {
    return this._sourceLabel.value;
  }

  set sourceLabel(value: string) {
    this._sourceLabel.value = value;
  }

  @computed
  get description(): string {
    return this._description.value;
  }

  set description(value: string) {
    this._description.value = value;
  }

  @computed
  get indexedSources() {
    return this._indexedSources.values;
  }

  @action
  addIndexedSource(source: EditableSourceIndex) {
    this._indexedSources.addItem(source);
  }

  @computed
  get columns() {
    return this._columns.values;
  }

  @action
  addColumn(column: EditableTransformationColumn) {
    this._columns.addItem(column);
  }

  @action
  moveColumn(oldIndex: number, newIndex: number) {
    this._columns.moveItem(oldIndex, newIndex);
  }

  @computed
  get targetSchema() {
    return this._targetSchema.value;
  }

  set targetSchema(value: string) {
    this._targetSchema.value = value;
  }

  @computed
  get schemaFieldIndexes() {
    return this._schemaFieldIndexes.value;
  }

  set schemaFieldIndexes(value: number[]) {
    this._schemaFieldIndexes.value = value;
  }

  @computed
  get suggestedImportOptions(): string[] {
    return this._suggestedImportOptions.value;
  }

  set suggestedImportOptions(value: string[]) {
    this._suggestedImportOptions.value = value;
  }
}
