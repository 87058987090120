import { SxProps, Typography } from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { ContentDefinitionUtils, DataStructureUtils } from '@shared/components/utils';
import { SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { Container, Expanded, Row } from './layout';

export interface TaskInformationOneLineProps {
  sx?: SxProps;
  className?: string;
  displayTitle: string;
  notes: string;
  task: ContentDefinitionModel;
  size: 'normal' | 'small';
  sectionsById: Record<string, SectionModel>;
  schoolYearConfig: SchoolYearConfigurationModel;
}

export function TaskInformationOneLine({
  sx = [],
  className,
  displayTitle,
  notes,
  size,
  schoolYearConfig,
  sectionsById,
  task
}: TaskInformationOneLineProps) {
  const sectionsByIdMap = DataStructureUtils.convertDictionaryToMap<SectionModel>(sectionsById);
  const taskIconPublishKind = ContentDefinitionUtils.getTaskIconPublishKind(task, sectionsByIdMap, schoolYearConfig);

  return (
    <Row sx={sx} className={className} verticalContentAlignment="center">
      <TaskIcon
        sx={{ flexShrink: 0 }}
        icon={task.icon}
        workloadLevel={task.workloadLevel}
        squareSize={20}
        publishedKind={taskIconPublishKind}
        externalSource={task.externalContent != null ? task.externalContent.sourceIntegration : undefined}
      />

      <Expanded hideOverflow>
        <Container sx={{ ml: 0.5 }} hideOverflow>
          <Row verticalContentAlignment="center" hideOverflow>
            <Typography
              variant={size === 'normal' ? 'body1' : 'body2'}
              sx={{ fontWeight: '400', lineHeight: 'normal' }}
              noWrap
            >
              {displayTitle}
            </Typography>

            <Expanded hideOverflow>
              <Container sx={{ ml: 2 }} hideOverflow>
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    lineHeight: 'normal',
                    // Any second line would peek
                    maxHeight: 28
                  }}
                  noWrap
                >
                  {notes}
                </Typography>
              </Container>
            </Expanded>
          </Row>
        </Container>
      </Expanded>
    </Row>
  );
}
