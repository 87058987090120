import { SxProps, Typography, useTheme } from '@mui/material';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { Column, Container } from './layout';

export interface DemoIndicatorProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const DemoIndicator = (props: DemoIndicatorProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style } = props;
  const theme = useTheme();

  return (
    <Column sx={sx} className={className} style={style}>
      <Container sx={{ p: 1, backgroundColor: theme.palette.secondary.main }}>
        <Typography variant="caption" align="center">
          {localizationService.localizedStrings.insights.components.demoMode}
        </Typography>
      </Container>
    </Column>
  );
};
