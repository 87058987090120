import {
  Column,
  Expanded,
  InsightsButton,
  ManageSectionConflicts,
  ObservableActionsPresenter,
  ObservablePresenter,
  Row,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Dialog, DialogActions, DialogContent, DialogTitle, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { Day } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ManageSectionConflictsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  sectionId: string;
  startingDay?: Day;
}

export const ManageSectionConflictsDialog = observer((props: ManageSectionConflictsDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style, sectionId, configId, startingDay, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createManageSectionConflicts(configId, sectionId, startingDay, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="xl"
        fullWidth={true}
        PaperProps={{ sx: { height: '100vh' } }}
        onClose={() => viewModel.close()}
      >
        <DialogTitle>{strings.manageSectionConflictsTitle}</DialogTitle>
        <DialogContent>
          <ObservablePresenter
            sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
            data={viewModel.data}
            render={(data) => <ManageSectionConflicts viewModel={data} sx={{ width: '100%', height: '100%' }} />}
          />
        </DialogContent>
        <DialogActions>
          <ObservableActionsPresenter
            data={viewModel.data}
            render={() => (
              <Column>
                <Row verticalContentAlignment="center">
                  <Expanded />
                  <InsightsButton sx={{ m: 1 }} isDefault onClick={() => viewModel.close()}>
                    {strings.close}
                  </InsightsButton>
                </Row>
              </Column>
            )}
            renderError={() => <InsightsButton onClick={() => viewModel.close()}>{strings.close}</InsightsButton>}
          />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
