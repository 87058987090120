import { StudentDailyTaskCounters } from '@shared/models/metrics';
import { MetricsStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface StudentDailyTaskCountersViewModel {
  readonly configId: string;
  readonly studentId: string;

  readonly data: IPromiseBasedObservable<StudentDailyTaskCounters[]>;
}

export class AppStudentDailyTaskCountersViewModel implements StudentDailyTaskCountersViewModel {
  constructor(
    private readonly metricsStore: MetricsStore,
    public readonly configId: string,
    public readonly studentId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<StudentDailyTaskCounters[]> {
    return fromPromise(this.metricsStore.getStudentDailyTaskMetrics(this.configId, this.studentId));
  }
}
