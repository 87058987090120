import { AccountService, AlertService, NavigationService } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { EditableOnboardingComment } from '@shared/models/onboarding/implementations';
import { OnboardingComment } from '@shared/models/onboarding/interfaces';
import { OnboardingStatus } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import { AppOnboardingCommentViewModel, OnboardingCommentViewModel } from './OnboardingCommentViewModel';

export interface OnboardingCommentsViewModel {
  readonly comments: OnboardingCommentViewModel[];
  readonly nextComment?: OnboardingCommentViewModel;
  readonly isProcessCompleted: boolean;

  viewHistory: () => Promise<void>;
}

export class EmptyOnboardingCommentsViewModel implements OnboardingCommentsViewModel {
  get comments(): OnboardingCommentViewModel[] {
    return [];
  }

  get nextComment(): OnboardingCommentViewModel {
    throw new Error('Not supported');
  }

  isProcessCompleted = false;

  viewHistory(): Promise<void> {
    return Promise.resolve();
  }
}

export class AppOnboardingCommentsViewModel implements OnboardingCommentsViewModel {
  @observable private readonly _comments: OnboardingCommentViewModel[];
  @observable private _nextComment: OnboardingCommentViewModel | undefined;
  private readonly _accountId: string;
  private readonly _isStudyoUser: boolean;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _processName: string,
    private readonly _processStatus: OnboardingStatus,
    private readonly _stepName: string,
    private readonly _agentId: string,
    private readonly _configId: string,
    private readonly _accountsById: Record<string, AccountModel>,
    comments: OnboardingComment[],
    canEdit: boolean,
    private readonly _isStepBlocked: boolean | undefined,
    private readonly _refreshRequired: () => void
  ) {
    makeObservable(this);
    this._accountId =
      _accountService.getAccountIdForConfigRole(_configId, 'teacher') ??
      _accountService.getAccountIdForConfigRole(_configId, 'studyo-staff') ??
      _accountService.getAccountIdForConfigRole(_configId, 'school-staff') ??
      '';
    this._isStudyoUser = _accountService.isRootAdmin || this._accountId === _agentId;

    this._comments = comments.map(
      (c) =>
        new AppOnboardingCommentViewModel(
          _onboardingStore,
          _alertService,
          _localizationService,
          _accountsById,
          this._accountId,
          this._isStudyoUser,
          new EditableOnboardingComment(c),
          canEdit,
          undefined,
          undefined,
          () => this.onCommentDeleted()
        )
    );

    if (canEdit) {
      this._nextComment = new AppOnboardingCommentViewModel(
        _onboardingStore,
        _alertService,
        _localizationService,
        _accountsById,
        this._accountId,
        this._isStudyoUser,
        this.createNextComment(),
        true,
        _isStepBlocked,
        (comment) => this.onCommentAdded(comment)
      );
    }
  }

  @computed
  get comments() {
    return this._comments;
  }

  @computed
  get nextComment() {
    return this._nextComment;
  }

  @computed
  get isProcessCompleted(): boolean {
    return this._processStatus === 'completed';
  }

  @action
  async viewHistory(): Promise<void> {
    await this._navigationService.navigateToOnboardingHistory(this._configId);
  }

  @action
  private onCommentAdded(comment: OnboardingComment) {
    // If the blocking state of the step changed, we need to refresh.
    if (comment.effect === 'blocks-step' || comment.effect === 'unblocks-step') {
      this._refreshRequired();
    } else {
      const viewModel = new AppOnboardingCommentViewModel(
        this._onboardingStore,
        this._alertService,
        this._localizationService,
        this._accountsById,
        this._accountId,
        this._isStudyoUser,
        new EditableOnboardingComment(comment),
        true,
        undefined,
        undefined,
        () => this.onCommentDeleted()
      );

      this._comments.push(viewModel);

      this._nextComment = new AppOnboardingCommentViewModel(
        this._onboardingStore,
        this._alertService,
        this._localizationService,
        this._accountsById,
        this._accountId,
        this._isStudyoUser,
        this.createNextComment(),
        true,
        this._isStepBlocked,
        (comment) => this.onCommentAdded(comment)
      );
    }
  }

  private onCommentDeleted() {
    this._refreshRequired();
  }

  private createNextComment(): EditableOnboardingComment {
    return EditableOnboardingComment.createNew(
      this._processName,
      this._stepName,
      this._configId,
      this._accountId,
      this._isStudyoUser ? 'markdown' : 'plain-text'
    );
  }
}
