import { AccountService, SettingsStore } from '@insights/services';
import { UrlUtils } from '@insights/utils';
import { AccountUtils } from '@shared/components/utils';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { AccountModel, SchoolYearConfigurationSummary, SectionModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { Storage } from '@shared/services';
import { ReactRouterRouteService } from '@shared/web/services';
import { chain } from 'lodash';
import { observer } from 'mobx-react-lite';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { useRef } from 'react';
import { Location, useLocation, useParams } from 'react-router-dom';
import { StorageKeys } from '../Constants';
import { RouteNames, RouteTemplates } from '../Routes';
import { useInsightsServices } from '../UseInsightsServicesHook';

const TitleSeperator = ' | ';

interface RouteInfo {
  config?: SchoolYearConfigurationSummary;
  account?: AccountModel;
  section?: SectionModel;
}

export const BrowserHistoryListener = observer(() => {
  const {
    accountService,
    schoolYearConfigurationStore,
    localizationService,
    reactRouterRouteService,
    localStorage,
    settingsStore
  } = useInsightsServices();
  const location = useLocation();
  const params = useParams();

  async function loadRouteInfo(): Promise<RouteInfo> {
    let config: SchoolYearConfigurationSummary | undefined;
    let account: AccountModel | undefined;
    let section: SectionModel | undefined;

    if (accountService.isLoggedIn && accountService.currentConfigId != null) {
      config = await schoolYearConfigurationStore.getConfigSummary(accountService.currentConfigId);

      // TODO: Put back when we have the new stores (no parallel calls)
      // const accountId =
      //   reactRouterRouteService.tryGetRouteParam(RouteTemplates.teacherDetails, RouteParamNames.teacherId) ||
      //   reactRouterRouteService.tryGetRouteParam(RouteTemplates.studentDetails, RouteParamNames.studentId) ||
      //   undefined;

      // if (accountId != null) {
      //   account = await schoolYearConfigurationStore.getAccount(accountService.currentConfigId, accountId);
      // }

      // const sectionId =
      //   reactRouterRouteService.tryGetRouteParam(RouteTemplates.sectionDetails, RouteParamNames.sectionId) ||
      //   undefined;

      // if (sectionId != null) {
      //   section = await schoolYearConfigurationStore.getSection(accountService.currentConfigId, sectionId);
      // }
    }

    return {
      config,
      account,
      section
    };
  }

  const routeInfo = useRef<IPromiseBasedObservable<RouteInfo>>(fromPromise(loadRouteInfo()));

  const configId = params.configId ?? '';
  updateCurrentConfigId(configId, accountService);
  updateUseAccessTokenProvider(location, settingsStore);
  void updateLanguage(location, localStorage, localizationService);
  updateIsEmbedded(location, settingsStore, reactRouterRouteService);

  routeInfo.current.case({
    pending: () => updateTabTitle(location, {}, localizationService, reactRouterRouteService),
    fulfilled: (routeInfo) => updateTabTitle(location, routeInfo, localizationService, reactRouterRouteService),
    rejected: () => updateTabTitle(location, {}, localizationService, reactRouterRouteService)
  });

  return null;
});

function updateUseAccessTokenProvider(location: Location, settingsStore: SettingsStore) {
  settingsStore.useIOSAccessTokenProvider = UrlUtils.getUseAccessTokenProvider(location);
}

function updateIsEmbedded(
  location: Location,
  settingsStore: SettingsStore,
  reactRouterRouteService: ReactRouterRouteService
) {
  settingsStore.isEmbedded = reactRouterRouteService.isRouteActive(
    RouteTemplates.embeddedWorkloadManager,
    false,
    location
  );
}

function updateCurrentConfigId(configId: string, accountService: AccountService) {
  accountService.currentConfigId = configId.length > 0 ? configId : undefined;
}

async function updateLanguage(location: Location, localStore: Storage, localizationService: LocalizationService) {
  const locale = UrlUtils.getLanguage(location);

  if (locale != null) {
    localizationService.setCurrentLocale(locale);
    await localStore.set(StorageKeys.locale, locale);
  }
}

function updateTabTitle(
  location: Location,
  routeInfo: RouteInfo,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService
) {
  const insightsStrings = localizationService.localizedStrings.insights;
  const routeStrings = insightsStrings.routes;

  const routeName = chain(RouteNames)
    .filter((r) => reactRouterRouteService.isRouteActive(r.routeTemplate, true, location))
    .map((r) =>
      reactRouterRouteService.getRouteName(r.routeTemplate, {
        ...routeStrings
      })
    )
    .head()
    .value();

  let title = insightsStrings.applicationName;

  if (routeName != null) {
    title += `${TitleSeperator}${routeName}`;
  }

  if (routeInfo.config != null) {
    title += `${TitleSeperator}${routeInfo.config.schoolName} (${SchoolYearConfigurationUtils.displayTitle(
      routeInfo.config
    )})`;
  }

  if (routeInfo.account != null) {
    title += `${TitleSeperator}${AccountUtils.getDisplayLastFirstName(routeInfo.account)}`;
  }

  if (routeInfo.section != null) {
    title += `${TitleSeperator}${routeInfo.section.title}`;
  }

  window.document.title = title;
}
