import { BehaviourSummaryItemSize, BehaviourSummaryItemType } from '@insights/models';
import TaskCompletionIcon from '@mui/icons-material/Check';
import ParentInvitationIcon from '@mui/icons-material/Share';
import AppOpenIcon from '@mui/icons-material/Visibility';
import { Box, SxProps, Tooltip } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { OQValue } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties, MouseEvent, useMemo } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { BehaviourSummaryItemTooltip } from './BehaviourSummaryItemTooltip.tsx';

interface SizeInfo {
  iconVisible: boolean;
  padding: number | string;
  iconSize: 'inherit' | 'medium' | 'small' | 'large';
}

export interface BehaviourSummaryItemProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  type: BehaviourSummaryItemType;
  value?: OQValue;
  size?: BehaviourSummaryItemSize;
  showTooltip?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

export const BehaviourSummaryItem = observer((props: BehaviourSummaryItemProps) => {
  const { accountService } = useInsightsServices();
  const { sx = [], className, style, onClick, size = 'medium', type, showTooltip = true, value } = props;

  const Icon = useMemo(() => {
    switch (type) {
      case 'app-open':
        return AppOpenIcon;

      case 'task-completion':
        return TaskCompletionIcon;

      case 'parent-invitation':
        return ParentInvitationIcon;

      default:
        throw new Error('Unknown type');
    }
  }, [type]);

  const sizeInfo: SizeInfo = useMemo(() => {
    switch (size) {
      case 'small':
        return {
          iconVisible: false,
          padding: '6px',
          iconSize: 'small'
        };

      case 'large':
        return {
          iconVisible: true,
          padding: 0.5,
          iconSize: 'large'
        };

      default:
        return {
          iconVisible: true,
          padding: 0.5,
          iconSize: 'medium'
        };
    }
  }, [size]);

  const colorValue = useMemo(() => {
    if (!accountService.isFeatureAllowed('organizational-quotient')) {
      return grey[300];
    }

    switch (value) {
      case 'green':
        return green[400];

      case 'yellow':
        return orange[400];

      case 'red':
        return red[400];

      case 'none':
      default:
        return grey[300];
    }
  }, [value, accountService.isFeatureAllowed('organizational-quotient')]);

  return (
    <Box sx={sx} className={className} style={style} onClick={onClick}>
      <Tooltip title={showTooltip ? <BehaviourSummaryItemTooltip type={type} /> : ''}>
        <Box
          minWidth="min-content"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          bgcolor={colorValue}
          borderRadius={50}
          padding={sizeInfo.padding}
        >
          {sizeInfo.iconVisible && <Icon fontSize={sizeInfo.iconSize} />}
        </Box>
      </Tooltip>
    </Box>
  );
});
