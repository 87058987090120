export interface SchoolYearConfigurationStrings {
  cancelPurgeButton: string;
  clearCacheResultError: (error: Error) => string;
  clearCacheResultSuccess: string;
  clearCacheResultTitle: string;
  confirmPurgeButton: string;
  confirmPurgeDeletedAccountsMessage: string;
  confirmPurgeDeletedAccountsTitle: string;
  createDemoCopyErrorMessage: (error: Error) => string;
  createDemoCopyErrorTitle: string;
  createNextYearErrorMessage: (error: Error) => string;
  createNextYearErrorTitle: string;
  requiredField: string;
  startDateAfterEndDate: string;
  endDateBeforeStartDate: string;
  fieldTooLong: (length: number) => string;
  getNewSchoolName: (name: string) => string;
  purgeDeletedAccountsErrorMessage: (error: Error) => string;
  purgeDeletedAccountsErrorTitle: string;
  purgeDeletedAccountsSuccessMessage: (count: number) => string;
  purgeDeletedAccountsSuccessTitle: string;
}

export const EnglishSchoolYearConfigurationStrings: SchoolYearConfigurationStrings = {
  cancelPurgeButton: 'Cancel',
  clearCacheResultError: (error) => `An error occurred clearing the cache: ${error.message}`,
  clearCacheResultSuccess: 'The cache was cleared successfully. Please refresh your page.',
  clearCacheResultTitle: 'Clearing the cache',
  confirmPurgeButton: 'Yes, remove them!',
  confirmPurgeDeletedAccountsMessage:
    'Are you sure you want to permanently remove all accounts currently marked as deleted? Locked accounts are not affected.',
  confirmPurgeDeletedAccountsTitle: 'Please Confirm',
  createDemoCopyErrorMessage: (error) => `An error occurred while creating the demo school: ${error.message}`,
  createDemoCopyErrorTitle: 'Unexpected Error',
  createNextYearErrorMessage: (error) =>
    `An unexpected error occurred while fetching accounts, in order to create next year's school: ${error.message}`,
  createNextYearErrorTitle: 'Oops',
  requiredField: 'This field is required',
  startDateAfterEndDate: 'The start date must be before the end date',
  endDateBeforeStartDate: 'The end date must be after the start date',
  fieldTooLong: (length: number) => `This field cannot exceed ${length} characters`,
  getNewSchoolName: (name: string) => `Copy of ${name}`,
  purgeDeletedAccountsErrorMessage: (error) =>
    `An unexpected error occurred while removing deleted accounts permanently: ${error.message}`,
  purgeDeletedAccountsErrorTitle: 'Oops',
  purgeDeletedAccountsSuccessMessage: (count) => {
    switch (count) {
      case 0:
        return 'There where no deleted accounts to remove.';
      case 1:
        return 'One deleted account was removed permanently.';
      default:
        return `${count} deleted accounts were removed permanently.`;
    }
  },
  purgeDeletedAccountsSuccessTitle: 'Done'
};

export const FrenchSchoolYearConfigurationStrings: SchoolYearConfigurationStrings = {
  cancelPurgeButton: 'Annuler',
  clearCacheResultError: (error) => `Une erreur est survenue à l’effacement de la mémoire tampon:\n${error.message}`,
  clearCacheResultSuccess: 'La mémoire tampon a été vidée correctement. Veuillez rafraîchir votre page.',
  clearCacheResultTitle: 'Vider la mémoire tampon',
  confirmPurgeButton: 'Oui, retirez-les!',
  confirmPurgeDeletedAccountsMessage:
    'Êtes-vous certain de vouloir retirer de façon permanente les fiches marquées comme effacées? Les fiches verrouillées ne sont pas affectées.',
  confirmPurgeDeletedAccountsTitle: 'Veuillez confirmer',
  createDemoCopyErrorMessage: (error) => `Une erreur est survenue à la création de l’école démo: ${error.message}`,
  createDemoCopyErrorTitle: 'Erreur imprévue',
  createNextYearErrorMessage: (error) =>
    `Une erreur imprévue est survenue à la lecture des fiches, pour la création de l’école de la prochaine année: ${error.message}`,
  createNextYearErrorTitle: 'Oups',
  requiredField: 'Ce champ est requis',
  startDateAfterEndDate: 'La date de début doit être avant la date de fin',
  endDateBeforeStartDate: 'La date de fin doit être après la date de début',
  fieldTooLong: (length: number) => `Ce champ ne peut dépasser ${length} caractères`,
  getNewSchoolName: (name: string) => `Copie de ${name}`,
  purgeDeletedAccountsErrorMessage: (error) =>
    `Une erreur imprévue est survenue au nettoyage des fiches effacées: ${error.message}`,
  purgeDeletedAccountsErrorTitle: 'Oups',
  purgeDeletedAccountsSuccessMessage: (count) => {
    switch (count) {
      case 0:
        return 'Aucune fiche effacée n’a été trouvée.';
      case 1:
        return 'Une fiche marquée comme effacée fut retirée de façon permanente.';
      default:
        return `${count} fiches marquées comme effacées furent retirées de façon permanente.`;
    }
  },
  purgeDeletedAccountsSuccessTitle: 'Complété'
};
