import { Box, Divider, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, ReactNode } from 'react';

export interface PageHeaderDetailBarProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

export const PageHeaderDetailBar = observer((props: PageHeaderDetailBarProps) => {
  const { className, sx = [], style, children } = props;

  return (
    <Box sx={{ ...sx, backgroundColor: 'white' }} className={className} style={style}>
      <Box paddingX={3} paddingY={2}>
        {children}
      </Box>
      <Divider />
    </Box>
  );
});
