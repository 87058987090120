export interface LandingScreenStrings {
  readonly errorTitle: string;
  readonly errorDescription: string;

  readonly noAccountTitle: string;
  readonly noAccountDescription: string;

  readonly logout: string;
  readonly support: string;
}

export const EnglishLandingScreenStrings: LandingScreenStrings = {
  errorTitle: 'Oops! An error occurred',
  errorDescription: "Please logout and try again. If that still doesn't work, contact us.",

  noAccountTitle: 'No account',
  noAccountDescription: 'Please use the web or iOS app to configure your planner for the first time.',

  logout: 'Logout',
  support: 'Support'
};

export const FrenchLandingScreenStrings: LandingScreenStrings = {
  errorTitle: 'Oups! Une erreur est survenue',
  errorDescription: 'Veuillez vous déconnecter et réessayer. Si le problème persiste, contactez nous.',

  noAccountTitle: 'Aucun compte',
  noAccountDescription: 'Veuillez utiliser l’app web ou iOS pour configurer votre agenda une première fois.',

  logout: 'Se déconnecter',
  support: 'Support'
};
