import { Message } from '@bufbuild/protobuf';
import _ from 'lodash';
import { IObservableArray, action, computed, makeObservable, observable } from 'mobx';
import { SerializableModel } from '../Model';
import { Time } from '../types';
import { ChangeablePropertyEx } from './ChangeableProperty';
import {
  EditableChildNullablePropertyEx,
  EditableChildPropertyEx,
  FullyEditableListProperty
} from './EditableModelProperties';
import {
  EditableLiveStringArrayProperty,
  EditableNullableDatePropertyEx,
  EditableNullableTimePropertyEx,
  EditableStringArrayProperty,
  EditableStringOptions,
  EditableStringProperty,
  EditableTimePropertyEx,
  EditableValueArrayPropertyEx,
  EditableValuePropertyEx
} from './EditableProperties';

interface ExportableModelEx<TProtobuf extends Message<TProtobuf>> extends SerializableModel<TProtobuf> {
  readonly isEditable: boolean;
  readonly hasChanges: boolean;
  readonly hasFieldChanges: boolean;
  readonly shouldBeCreated: boolean;
  readonly shouldBeDeleted: boolean;
  readonly changeIteration: number;

  resetChanges(): void;
}

export abstract class EditableModelEx<TProtobuf extends Message<TProtobuf>> implements ExportableModelEx<TProtobuf> {
  private _fields: IObservableArray<ChangeablePropertyEx<TProtobuf>> = observable.array();
  private _pb: TProtobuf;
  @observable private _shouldBeDeleted = false;

  protected constructor(
    pb: TProtobuf,
    private readonly _isNew = false
  ) {
    makeObservable(this);
    // Fields are not injected at construction, because it causes an ugly pattern in derived classes.
    // Derived classes must call setFields from their constructor once each field is created.
    this._pb = pb;
    this.setFields([]);
  }

  // eslint-disable-next-line @typescript-eslint/class-literal-property-style
  get isEditable() {
    return true;
  }

  @computed
  get hasChanges() {
    return this._shouldBeDeleted || this._isNew || this._fields.some((field) => field.isChanged);
  }

  @computed
  get hasFieldChanges() {
    return this._fields.some((field) => field.isChanged);
  }

  get shouldBeCreated() {
    return this._isNew;
  }

  @computed
  get shouldBeDeleted(): boolean {
    return this._shouldBeDeleted;
  }

  @computed
  get changeIteration(): number {
    return this._fields.map((field) => field.changeIteration).reduce((total, current) => total + current, 0);
  }

  @action
  markAsDeleted(): void {
    // TODO: Some gymnastic needed for deleted new items. ;)
    this._shouldBeDeleted = true;
  }

  @action
  markAsNotDeleted(): void {
    // TODO: Some gymnastic needed for deleted new items. ;)
    this._shouldBeDeleted = false;
  }

  @action
  resetChanges(): void {
    this._shouldBeDeleted = false;
    this._fields.forEach((field) => field.reset());
    // If it's new, it's still new!
  }

  toProtobuf(): TProtobuf {
    const pb = this._pb.clone();

    this._fields.forEach((field) => field.applyTo(pb));
    return pb;
  }

  copyChangesFrom(other: EditableModelEx<TProtobuf>): void {
    if (other.shouldBeCreated && !this.shouldBeCreated) {
      // You cannot apply a created model to an existing one.
      throw new Error('Invalid operation. Cannot apply created model.');
    }

    if (other.shouldBeDeleted) {
      this.markAsDeleted();
      // We still update each field.
    }

    const otherFields = other._fields;

    if (this._fields == null || otherFields == null || this._fields.length !== otherFields.length) {
      throw new Error('Invalid operation. Editable models are incompatible.');
    }

    _.zip(otherFields, this._fields).forEach(([otherField, thisField]) => thisField!.copyFrom(otherField!));
  }

  protected addStringField(
    originalValue: string,
    apply: (host: TProtobuf, value: string) => void,
    options?: EditableStringOptions
  ): EditableStringProperty<TProtobuf> {
    const field = new EditableStringProperty(originalValue, apply, options);
    this._fields?.push(field);
    return field;
  }

  protected addValueField<TValue extends string | number | bigint | boolean>(
    originalValue: TValue,
    apply: (host: TProtobuf, value: TValue) => void
  ): EditableValuePropertyEx<TValue, TProtobuf> {
    const field = new EditableValuePropertyEx(originalValue, apply);
    this._fields?.push(field);
    return field;
  }

  protected addTimeField(
    originalValue: Time,
    apply: (host: TProtobuf, value: Time) => void
  ): EditableTimePropertyEx<TProtobuf> {
    const field = new EditableTimePropertyEx(originalValue, apply);
    this._fields.push(field);
    return field;
  }

  protected addNullableTimeField(
    originalValue: Time | undefined,
    apply: (host: TProtobuf, value: Time | undefined) => void
  ): EditableNullableTimePropertyEx<TProtobuf> {
    const field = new EditableNullableTimePropertyEx(originalValue, apply);
    this._fields.push(field);
    return field;
  }

  protected addStringArrayField(
    originalValues: string[],
    apply: (host: TProtobuf, values: string[]) => void,
    options?: EditableStringOptions
  ): EditableStringArrayProperty<TProtobuf> {
    const field = new EditableStringArrayProperty(originalValues, apply, options);
    this._fields.push(field);
    return field;
  }

  protected addLiveStringArrayField(
    originalValues: string[],
    apply: (host: TProtobuf, values: string[]) => void
  ): EditableLiveStringArrayProperty<TProtobuf> {
    const field = new EditableLiveStringArrayProperty(originalValues, apply);
    this._fields.push(field);
    return field;
  }

  protected addValueArrayField<TValue extends string | number | boolean>(
    originalValues: TValue[],
    apply: (host: TProtobuf, values: TValue[]) => void
  ): EditableValueArrayPropertyEx<TValue, TProtobuf> {
    const field = new EditableValueArrayPropertyEx(originalValues, apply);
    this._fields.push(field);
    return field;
  }

  protected addNullableDateField(
    originalDate: Date | undefined,
    apply: (host: TProtobuf, value: Date | undefined) => void,
    originalIsDisabled = false
  ): EditableNullableDatePropertyEx<TProtobuf> {
    const field = new EditableNullableDatePropertyEx(originalDate, apply, originalIsDisabled);
    this._fields.push(field);
    return field;
  }

  protected addEditableListField<
    TInnerProtobuf extends Message<TInnerProtobuf>,
    TModel extends SerializableModel<TInnerProtobuf>,
    TEditable extends EditableModelEx<TInnerProtobuf> & TModel
  >(
    originalEditableChildren: TEditable[],
    apply: (host: TProtobuf, values: TInnerProtobuf[]) => void
  ): FullyEditableListProperty<TInnerProtobuf, TModel, TEditable, TProtobuf> {
    const field = new FullyEditableListProperty<TInnerProtobuf, TModel, TEditable, TProtobuf>(
      originalEditableChildren,
      apply
    );
    this._fields.push(field);
    return field;
  }

  protected addNullableChildField<
    TInnerProtobuf extends Message<TInnerProtobuf>,
    TModel extends SerializableModel<TInnerProtobuf>,
    TEditable extends EditableModelEx<TInnerProtobuf> & TModel
  >(
    originalValue: TModel | undefined,
    createEditable: (model: TModel | undefined) => TEditable,
    apply: (host: TProtobuf, value: TInnerProtobuf | undefined) => void,
    isOriginalDisabled?: boolean
  ): EditableChildNullablePropertyEx<TInnerProtobuf, TModel, TEditable, TProtobuf> {
    const field = new EditableChildNullablePropertyEx(
      originalValue,
      createEditable,
      apply,
      isOriginalDisabled === true
    );
    this._fields.push(field);
    return field;
  }

  protected addChildField<
    TInnerProtobuf extends Message<TInnerProtobuf>,
    TModel extends SerializableModel<TInnerProtobuf>,
    TEditable extends EditableModelEx<TInnerProtobuf> & TModel
  >(
    originalValue: TModel,
    createEditable: (model: TModel) => TEditable,
    apply: (host: TProtobuf, value: TInnerProtobuf) => void
  ): EditableChildPropertyEx<TInnerProtobuf, TModel, TEditable, TProtobuf> {
    const field = new EditableChildPropertyEx(originalValue, createEditable, apply);
    this._fields.push(field);
    return field;
  }

  protected setFields(fields: ChangeablePropertyEx<TProtobuf>[]) {
    if (this._fields.length > 0) {
      throw new Error("Invalid operation. Fields should be set once in the derived class' constructor.");
    }

    this._fields = observable.array<ChangeablePropertyEx<TProtobuf>>(fields);
  }
}
