export interface CalendarViewsStrings {
  bellTimes: string;
  errorMessage: string;
  loadingMessage: string;
  specialDays: string;
  terms: string;
}

export const EnglishCalendarViewsStrings: CalendarViewsStrings = {
  bellTimes: 'Bell Times',
  errorMessage: 'An error occurred while loading calendar',
  loadingMessage: 'Loading calendar. Please wait…',
  specialDays: 'Special Days',
  terms: 'Terms'
};

export const FrenchCalendarViewsStrings: CalendarViewsStrings = {
  bellTimes: 'Horaires',
  errorMessage: 'Une erreur est survenue en chargeant le calendrier',
  loadingMessage: 'Chargement du calendrier. Un instant svp…',
  specialDays: 'Jours spéciaux',
  terms: 'Étapes'
};
