export interface WeeklyOptionMenuStrings {
  readonly title: string;
  readonly close: string;
  readonly contentDisplaySectionTitle: string;
  readonly taskDisplaySectionTitle: string;
  readonly noteDisplaySectionTitle: string;
  readonly periodHeightSectionTitle: string;
  readonly smallPeriodHeightOptionTitle: string;
  readonly mediumPeriodHeightOptionTitle: string;
  readonly largePeriodHeightOptionTitle: string;
  readonly veryLargePeriodHeightOptionTitle: string;
  readonly otherOptionsSectionTitle: string;
  readonly showWeekendsOptionTitle: string;
}

export const EnglishWeeklyOptionMenuStrings: WeeklyOptionMenuStrings = {
  title: 'Options',
  close: 'Close',
  // content types
  contentDisplaySectionTitle: 'Displayed Content Types',
  taskDisplaySectionTitle: 'Tasks',
  noteDisplaySectionTitle: 'Notes',
  // period height
  periodHeightSectionTitle: 'Period Height',
  smallPeriodHeightOptionTitle: 'Small',
  mediumPeriodHeightOptionTitle: 'Medium',
  largePeriodHeightOptionTitle: 'Large',
  veryLargePeriodHeightOptionTitle: 'Very Large',
  // other
  otherOptionsSectionTitle: 'Others',
  showWeekendsOptionTitle: 'Show weekends'
};

export const FrenchWeeklyOptionMenuStrings: WeeklyOptionMenuStrings = {
  title: 'Options',
  close: 'Fermer',
  // content types
  contentDisplaySectionTitle: 'Type de contenu affiché',
  taskDisplaySectionTitle: 'Tâches',
  noteDisplaySectionTitle: 'Notes',
  // period height
  periodHeightSectionTitle: 'Hauteur des périodes',
  smallPeriodHeightOptionTitle: 'Petite',
  mediumPeriodHeightOptionTitle: 'Moyenne',
  largePeriodHeightOptionTitle: 'Grande',
  veryLargePeriodHeightOptionTitle: 'Très grande',
  // other
  otherOptionsSectionTitle: 'Autres',
  showWeekendsOptionTitle: 'Afficher les fins de semaine'
};
