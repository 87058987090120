import { AlertService, NavigationService } from '@insights/services';
import { AutomatedImport, AutomatedImportResult, ImportSession } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { orderBy } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface AutomatedImportHistoryViewModel {
  readonly history: AutomatedImportResult[];
  readonly isExecuting: boolean;

  executeManually(): Promise<void>;
  viewResultDetails(result: AutomatedImportResult): Promise<void>;
}

export class AppAutomatedImportHistoryViewModel implements AutomatedImportHistoryViewModel {
  @observable private _isExecuting = false;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _importerStore: ImporterStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _importSession: ImportSession,
    private readonly _automatedImport: AutomatedImport
  ) {
    makeObservable(this);
  }

  get history(): AutomatedImportResult[] {
    return orderBy(this._automatedImport.history, (r) => r.startTime, 'desc');
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @action
  async executeManually(): Promise<void> {
    this._isExecuting = true;

    try {
      await this._importerStore.executeAutomatedImport(
        this._automatedImport.configId,
        this._automatedImport.importSessionId,
        this._automatedImport.id
      );
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.import;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isExecuting = false));
    }
  }

  async viewResultDetails(result: AutomatedImportResult): Promise<void> {
    await this._navigationService.navigateToAutomatedImportResultDetails(
      this._importSession,
      this._automatedImport,
      result
    );
  }
}
