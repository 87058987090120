import { ExternalAssociationViewModel } from '@insights/viewmodels';
import { Box, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { Column } from '../layout';

export interface ExternalAssociationListItemLastUpdateProps {
  sx?: SxProps;
  className?: string;
  association: ExternalAssociationViewModel;
}

export const ExternalAssociationListItemLastUpdate = observer(
  ({ sx = [], className, association }: ExternalAssociationListItemLastUpdateProps) => {
    const theme = useTheme();
    if (!association.hasAssociation) {
      return null;
    }

    const color =
      association.lastUpdateResult == null
        ? theme.palette.text.secondary
        : association.lastUpdateResult
          ? theme.palette.text.primary
          : theme.palette.error.main;

    const tooltip = association.lastUpdateTooltip;

    return (
      <Tooltip
        title={
          tooltip && (
            <Column>
              <Typography
                variant="caption"
                sx={{
                  whiteSpace: 'pre-line'
                }}
              >
                {tooltip}
              </Typography>
            </Column>
          )
        }
      >
        <Box
          className={className}
          sx={[
            {
              maxWidth: 250
            },
            ...(isSxArray(sx) ? sx : [sx])
          ]}
        >
          <Column>
            <Typography
              noWrap
              variant="caption"
              sx={{
                color: color
              }}
            >
              {association.lastUpdateMessage}
            </Typography>
            <Typography
              noWrap
              variant="caption"
              sx={{
                color: color
              }}
            >
              {association.lastUpdateReadable}
            </Typography>
          </Column>
        </Box>
      </Tooltip>
    );
  }
);
