import { SectionName, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { PublishedTasksDetailSectionInfo, PublishedTasksDetailTaskInfo } from '@insights/viewmodels';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  InputLabel,
  SxProps,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme
} from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { ContentDefinitionUtils, DataStructureUtils, SectionUtils } from '@shared/components/utils';
import { SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { DialogResult } from '@shared/services';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface PublishedTaskDetailDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  sectionInfo: PublishedTasksDetailSectionInfo;
  taskInfo: PublishedTasksDetailTaskInfo;
  sectionsById: Record<string, SectionModel>;
  schoolYearConfig: SchoolYearConfigurationModel;
}

export const PublishedTaskDetailDialog = observer((props: PublishedTaskDetailDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const {
    sx = [],
    className,
    schoolYearConfig,
    sectionsById,
    style,
    sectionInfo,
    taskInfo,
    onCancel,
    onSuccess
  } = props;
  const strings = localizationService.localizedStrings.insights.components.tasks;
  const theme = useTheme();

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createPublishedTaskDetail(sectionInfo, taskInfo, onSuccess!, onCancel!)
  );

  const task = viewModel.taskInfo.task;
  const notes = task.isSlave ? task.masterContent!.notes : task.notes;
  const sectionsByIdMap = DataStructureUtils.convertDictionaryToMap<SectionModel>(sectionsById);
  const taskIconPublishKind = ContentDefinitionUtils.getTaskIconPublishKind(task, sectionsByIdMap, schoolYearConfig);

  const close = () => {
    void viewModel.close();
  };

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
        onClose={() => close()}
        className={className}
        style={style}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <SectionName
              color={viewModel.sectionInfo.section.color}
              title={viewModel.sectionInfo.section.title}
              subInformation1={viewModel.sectionInfo.section.sectionNumber}
              subInformation2={SectionUtils.getSectionShortTeacherInfo(
                viewModel.sectionInfo.section,
                viewModel.sectionInfo.teachers,
                strings.noTeacher
              )}
            />

            <Box
              sx={{
                flex: 1
              }}
            />

            <TaskIcon
              icon={task.icon}
              workloadLevel={task.workloadLevel}
              squareSize={32}
              publishedKind={taskIconPublishKind}
              externalSource={task.externalContent != null ? task.externalContent.sourceIntegration : undefined}
            />
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Grid2 container spacing={2}>
            {/* Title */}
            <Grid2 size={{ xs: 12 }}>
              <InputLabel shrink>{strings.titleLabel}</InputLabel>
              <Typography variant="body1">
                <span style={{ fontWeight: '400' }}>
                  {ContentDefinitionUtils.getDisplayTitleForContent(task, localizationService.localizedStrings)}
                </span>
              </Typography>
            </Grid2>

            {/* Published on */}
            <Grid2 size={{ xs: 6 }}>
              <InputLabel shrink>{strings.publishedOnLabel}</InputLabel>
              <Typography>
                <span style={{ marginRight: theme.spacing(0.5), fontWeight: '400' }}>
                  {format(
                    viewModel.taskInfo.publishedAt,
                    localizationService.localizedStrings.models.dateFormats.shortUnabridged
                  )}
                </span>

                <span>{strings.at}</span>

                <span style={{ marginRight: theme.spacing(0.5), fontWeight: '400' }}>
                  {format(viewModel.taskInfo.publishedAt, localizationService.localizedStrings.models.dateFormats.time)}
                </span>
              </Typography>
            </Grid2>

            {/* Due on */}
            <Grid2 size={{ xs: 6 }}>
              <InputLabel shrink>{strings.dueOnLabel}</InputLabel>
              <Typography>
                <span style={{ fontWeight: '400' }}>
                  {format(
                    viewModel.taskInfo.task.dueDay.asDate,
                    localizationService.localizedStrings.models.dateFormats.shortUnabridged
                  )}
                </span>

                {viewModel.taskInfo.task.duePeriodTag && (
                  <>
                    <span style={{ marginLeft: theme.spacing(0.5) }}>{strings.atPeriod}</span>

                    <span style={{ marginLeft: theme.spacing(0.5), fontWeight: '400' }}>
                      {viewModel.taskInfo.task.duePeriodTag}
                    </span>
                  </>
                )}
              </Typography>
            </Grid2>

            {notes && (
              <Grid2 size={{ xs: 12 }}>
                <InputLabel shrink>{strings.notesLabel}</InputLabel>
                <Typography>{notes}</Typography>
              </Grid2>
            )}
          </Grid2>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => close()}>
            {strings.close}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
