import { AlertService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface AddOnboardingProcessViewModel {
  readonly availableProcessNames: string[];
  selectedProcessName: string;

  readonly canAdd: boolean;
}

export interface AddOnboardingProcessDialogViewModel {
  readonly data: IPromiseBasedObservable<AddOnboardingProcessViewModel>;
  readonly canAdd: boolean;

  add(): Promise<void>;
  cancel(): void;
}

class AppAddOnboardingProcessViewModel implements AddOnboardingProcessViewModel {
  @observable private _selectedProcessName = '';

  constructor(readonly availableProcessNames: string[]) {
    makeObservable(this);
  }

  @computed
  get selectedProcessName() {
    return this._selectedProcessName;
  }

  set selectedProcessName(value: string) {
    this._selectedProcessName = value;
  }

  @computed
  get canAdd() {
    return this._selectedProcessName.length > 0;
  }
}

export class AppAddOnboardingProcessDialogViewModel implements AddOnboardingProcessDialogViewModel {
  @observable private _innerViewModel?: AddOnboardingProcessViewModel;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _onSuccess: (processName?: string) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<AddOnboardingProcessViewModel> {
    return fromPromise(this.loadData());
  }

  @computed
  get canAdd() {
    return this._innerViewModel?.canAdd ?? false;
  }

  private async loadData(): Promise<AddOnboardingProcessViewModel> {
    const templateNames = await this._onboardingStore.getProcessTemplateNames();

    const inner = new AppAddOnboardingProcessViewModel(templateNames);

    return runInAction(() => (this._innerViewModel = inner));
  }

  async add(): Promise<void> {
    try {
      const process = await this._onboardingStore.createProcess(
        this._innerViewModel!.selectedProcessName,
        this._configId
      );
      this._onSuccess(process.templateName);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    }
  }

  cancel() {
    this._onCancel();
  }
}
