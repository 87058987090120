import { css } from '@emotion/css';
import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { MaximumLinkTitleLength, MaximumLinkUrlLength } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  Input,
  InputLabel,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider
} from '@mui/material';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { DialogResult } from '@shared/services';
import clsx from 'clsx';
import { chain } from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useRef } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface EditSchoolYearConfigurationLinksDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  schoolYearConfiguration: SchoolYearConfigurationModel;
}

export const EditSchoolYearConfigurationLinksDialog = observer((props: EditSchoolYearConfigurationLinksDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx = [], style, schoolYearConfiguration, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createEditSchoolYearConfigurationLinks(schoolYearConfiguration, onSuccess!, onCancel!)
  );

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const fileReader = useRef(new FileReader());
  const fileName = useRef<string | undefined>(undefined);

  useEffect(() => {
    fileReader.current.onload = () =>
      viewModel.uploadFile(fileReader.current.result as string, fileName.current ?? 'noname');
  }, []);

  const links = chain(viewModel.links)
    .filter((link) => !link.shouldBeDeleted)
    .orderBy((link) => link.sortPosition)
    .value();

  const openFileInput = () => {
    requestAnimationFrame(() => {
      if (fileInputRef.current != null) {
        fileInputRef.current.click();
      }
    });
  };

  const onUploadFile = (files: FileList | null) => {
    if (files != null) {
      const file = files[0];

      if (file != null) {
        fileName.current = file.name;
        fileReader.current.readAsDataURL(file);
      }
    }
  };

  const cellClassName = css({ padding: '2px 8px 2px 0px', border: 'none' });
  const positionCellClassName = css({ width: '20%' });
  const titleCellClassName = css({ width: '30%' });
  const urlCellClassName = css({ width: '50%' });

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="md"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <DialogTitle>{strings.editLinksDialogTitle}</DialogTitle>
          <DialogContent dividers>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={clsx([cellClassName, positionCellClassName])}>
                        <InputLabel shrink>{strings.linkPositionColumnTitle}</InputLabel>
                      </TableCell>
                      <TableCell className={clsx([cellClassName, titleCellClassName])}>
                        <InputLabel shrink>{strings.linkTitleColumnTitle}</InputLabel>
                      </TableCell>
                      <TableCell className={clsx([cellClassName, urlCellClassName])}>
                        <InputLabel shrink>{strings.linkUrlColumnTitle}</InputLabel>
                      </TableCell>
                      <TableCell className={cellClassName} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {links.map((link, i) => {
                      return (
                        <TableRow key={`link-${i}`}>
                          <TableCell className={clsx([cellClassName, positionCellClassName])}>
                            <Input
                              type="number"
                              fullWidth
                              value={link.sortPosition}
                              onChange={(event) => {
                                const position = Number(event.target.value);

                                if (Number.isInteger(position)) {
                                  link.sortPosition = position;
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell className={clsx([cellClassName, titleCellClassName])}>
                            <Input
                              fullWidth
                              value={link.text}
                              onChange={(event) => (link.text = event.target.value)}
                              inputProps={{
                                maxLength: MaximumLinkTitleLength
                              }}
                            />
                          </TableCell>
                          <TableCell className={clsx([cellClassName, urlCellClassName])}>
                            <Input
                              fullWidth
                              value={link.url}
                              onChange={(event) => (link.url = event.target.value)}
                              inputProps={{ maxLength: MaximumLinkUrlLength }}
                            />
                          </TableCell>
                          <TableCell className={cellClassName}>
                            {!link.shouldBeDeleted && (
                              <IconButton edge={false} onClick={() => link.markAsDeleted()}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid2>
            </Grid2>
          </DialogContent>
          <DialogActions>
            <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => viewModel.addLink()}>
              {strings.addLinkButtonCaption}
            </InsightsButton>
            <input
              type="file"
              id="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={(e) => onUploadFile(e.target.files)}
            />
            <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => openFileInput()}>
              {strings.uploadFileButtonCaption}
            </InsightsButton>

            <Box
              sx={{
                flex: 1
              }}
            />

            <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
              {strings.cancelButtonCaption}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isExecuting={viewModel.isSubmitting}
              onClick={() => void viewModel.save()}
            >
              {strings.saveButtonCaption}
            </InsightsButton>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
});
