import { css } from '@emotion/css';
import { Box, Link, SxProps, Typography, useTheme } from '@mui/material';
import { Browser, BrowserInfo, detect } from 'detect-browser';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { ApplicationName } from './ApplicationName';

const SupportedBrowser: Browser[] = [
  'chrome',
  'crios',
  'edge',
  'edge-chromium',
  'firefox',
  'ios',
  'safari',
  'ios-webview'
];

export interface BrowserDetectorProps extends PropsWithChildren {
  sx?: SxProps;
  className?: string;
}

export const BrowserDetector = observer((props: BrowserDetectorProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, children } = props;
  const strings = localizationService.localizedStrings.insights.components.browserDetector;
  const theme = useTheme();

  const browserInfo = detect();
  const browser = browserInfo instanceof BrowserInfo ? browserInfo : undefined;
  if (browser != null && SupportedBrowser.includes(browser.name)) {
    return <>{children}</>;
  }

  const linkClassName = css({ color: theme.palette.secondary.main });

  const openIntercom = () => {
    if (window.Intercom != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Intercom('showNewMessage');
    }
  };

  return (
    <Box sx={sx} className={className} display="flex" flexDirection="column" padding={3}>
      <Box marginBottom={3}>
        <ApplicationName size="extra-large" color="light" />
      </Box>

      <Box marginBottom={3}>
        <Typography variant="h4">{strings.title}</Typography>
      </Box>

      <Box marginBottom={3}>
        <Typography variant="body1">
          {strings.errorOnOurPartText}
          <Link variant="body1" className={linkClassName} onClick={openIntercom}>
            {strings.errorOnOurPartLetUsKnowLink}
          </Link>
          .
        </Typography>
      </Box>

      <Typography variant="body1">{strings.useOneOfTheFollowingBrowser}</Typography>

      <Link variant="body1" href="https://www.google.com/chrome/" className={linkClassName}>
        Google Chrome
      </Link>

      <Link variant="body1" href="http://www.mozilla.org/firefox/" className={linkClassName}>
        Firefox
      </Link>

      {browser != null && (browser.os === 'Mac OS' || browser.os === 'iOS') && (
        <Link variant="body1" href="https://support.apple.com/downloads/safari/" className={linkClassName}>
          Safari
        </Link>
      )}

      {browser != null && browser.os === 'Windows 10' && (
        <Link variant="body1" href="https://www.microsoft.com/en-us/windows/microsoft-edge/" className={linkClassName}>
          Microsoft Edge
        </Link>
      )}
    </Box>
  );
});
