import { LocalizationService } from '@shared/resources/services';
import { CalendarsConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface CalendarAccountViewModel extends ExternalAccountEditionViewModel {
  accountName: string;
}

export class AppCalendarAccountViewModel
  extends BaseExternalAccountEditionViewModel
  implements CalendarAccountViewModel
{
  @observable private _accountName?: string;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _calendarsStore: CalendarsConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    private readonly _originalAccountName: string
  ) {
    super();

    makeObservable(this);

    // If it's a new account, we must allow saving it as is.
    if (_externalAccountId == null || _externalAccountId.length === 0) {
      this._hasChanges = true;
    }
  }

  @computed
  get accountName() {
    return this._accountName ?? this._originalAccountName;
  }

  set accountName(value: string) {
    this._accountName = value;
    this.onChange();
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this._hasChanges) {
      console.error('Applying without changes. Ignoring...');
      this._onSuccess();
      return;
    }

    this.beginApplying();

    try {
      await this._calendarsStore.createOrUpdateCalendarAccount(
        this._configId,
        this._externalAccountId,
        this.accountName
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._accountName = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this._hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
