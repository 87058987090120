import { GoogleAccountSettingsViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowIcon from '@mui/icons-material/ForwardOutlined';
import {
  Box,
  IconButton,
  InputLabel,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from '@mui/material';
import { EditableClassroomContentRepresentation, EditableClassroomCourseWorkMapping } from '@shared/models/connectors';
import { AllContentWorkloadLevels, ContentIcon } from '@shared/models/types';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { ClassroomGradingConditionSelector } from './ClassroomGradingConditionSelector';
import { ClassroomMatchingValueSelector } from './ClassroomMatchingValueSelector';
import { ContentWorkloadLevelSelector } from './ContentWorkloadLevelSelector';
import { OptionalContentIconSelector } from './OptionalContentIconSelector';

export interface ClassroomCourseWorkMappingsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: GoogleAccountSettingsViewModel;
}

export const ClassroomCourseWorkMappings = observer((props: ClassroomCourseWorkMappingsProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, viewModel, sx } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  function updateIcon(mapping: EditableClassroomCourseWorkMapping, icon: ContentIcon | undefined): void {
    if (icon == null) {
      mapping.representation = undefined;
    } else {
      if (mapping.representation == null) {
        mapping.representation = EditableClassroomContentRepresentation.createNew();
      }

      mapping.editableRepresentation.icon = icon!;
    }
  }

  return (
    <Root sx={sx} className={className} style={style}>
      <TableHead>
        <TableRow>
          <TableCell className={clsx('cell', 'groupCell')}>
            <InputLabel shrink>{strings.classroomMatchingValueTitle}</InputLabel>
          </TableCell>
          <TableCell className={clsx('cell', 'gradingCell')}>
            <InputLabel shrink>{strings.classroomGradingConditionTitle}</InputLabel>
          </TableCell>
          <TableCell className={clsx('cell', 'iconCell')}>
            <InputLabel shrink>{strings.iconTitle}</InputLabel>
          </TableCell>
          <TableCell className={clsx('cell', 'workloadCell')}>
            <InputLabel shrink>{strings.workloadLevelTitle}</InputLabel>
          </TableCell>
          <TableCell className={clsx('cell', 'actionCell')} />
        </TableRow>
      </TableHead>
      <TableBody>
        {viewModel.courseWorkMappings.map((m, i) => (
          <TableRow key={`course-work-mapping-${i}`}>
            <TableCell className={clsx('cell', 'groupCell')}>
              <ClassroomMatchingValueSelector
                matchingValue={m.matchingValue}
                onChange={(value) => (m.matchingValue = value ?? '')}
              />
            </TableCell>
            <TableCell className={clsx('cell', 'gradingCell')}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <ClassroomGradingConditionSelector
                  selectedGradingCondition={m.gradingCondition}
                  onChange={(condition) => (m.gradingCondition = condition)}
                />
                <ArrowIcon className={'arrow'} />
              </Box>
            </TableCell>
            <TableCell className={clsx('cell', 'iconCell')}>
              <OptionalContentIconSelector
                selectedIcon={m.representation?.icon}
                workloadLevel={m.representation?.workloadLevel}
                onChange={(icon) => updateIcon(m, icon)}
              />
            </TableCell>
            <TableCell className={clsx('cell', 'workloadCell')}>
              {m.representation?.icon != null && (
                <ContentWorkloadLevelSelector
                  selectedLevel={m.representation.workloadLevel}
                  availableLevels={AllContentWorkloadLevels}
                  onChange={(level) => (m.representation!.workloadLevel = level)}
                />
              )}
            </TableCell>
            <TableCell className={clsx('cell', 'actionCell')}>
              <IconButton onClick={() => m.markAsDeleted()}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow className={'nextRow'}>
          <TableCell className={clsx('cell', 'groupCell')}>
            <IconButton onClick={() => viewModel.addCourseWorkMapping('')}>
              <AddIcon fontSize="small" />
            </IconButton>
          </TableCell>
          <TableCell className={clsx('cell', 'gradingCell')} />
          <TableCell className={clsx('cell', 'iconCell')} />
          <TableCell className={clsx('cell', 'workloadCell')} />
          <TableCell className={clsx('cell', 'actionCell')} />
        </TableRow>
      </TableFooter>
    </Root>
  );
});

const Root = styled(Table)(({ theme }) => ({
  '.cell': {
    padding: '2px 8px 2px 0px',
    border: 'none',
    verticalAlign: 'bottom'
  },
  '.groupCell': {
    width: '30%'
  },
  '.gradingCell': {
    width: '30%'
  },
  '.iconCell': {
    width: '15%'
  },
  '.workloadCell': {
    width: '15%'
  },
  '.actionCell': {
    width: '10%'
  },
  '.nextRow': {},
  '.arrow': {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));
