import { AlertService } from '@insights/services';
import { AutoMatchEntry, ExternalAccount } from '@shared/models/connectors';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface ScheduledAutoMatchSettingsDialogViewModel {
  readonly autoMatchHistory: AutoMatchEntry[];

  readonly canScheduleAutoMatch: boolean;
  isScheduledAutoMatchEnabled: boolean;

  readonly isRunning: boolean;
  readonly canSave: boolean;
  readonly isSaving: boolean;

  run(): Promise<void>;
  save(): Promise<void>;
  cancel(): void;
}

export class AppScheduledAutoMatchSettingsDialogViewModel implements ScheduledAutoMatchSettingsDialogViewModel {
  @observable private _isScheduledAutoMatchEnabled: boolean;
  @observable private _isRunning = false;
  @observable private _isSaving = false;

  constructor(
    private readonly _externalAccount: ExternalAccount,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService
  ) {
    makeObservable(this);
    this._isScheduledAutoMatchEnabled = _externalAccount.isScheduledAutoMatchEnabled;
  }

  @computed
  get autoMatchHistory() {
    return this._externalAccount.autoMatchHistory;
  }

  @computed
  get canScheduleAutoMatch() {
    return this._externalAccount.autoMatchHistory.length > 0;
  }

  @computed
  get isScheduledAutoMatchEnabled() {
    return this._isScheduledAutoMatchEnabled;
  }

  set isScheduledAutoMatchEnabled(value: boolean) {
    this._isScheduledAutoMatchEnabled = value;
  }

  @computed
  get isRunning() {
    return this._isRunning;
  }

  @computed
  get canSave() {
    // For now, the history can't be changed.
    return this._isScheduledAutoMatchEnabled !== this._externalAccount.isScheduledAutoMatchEnabled;
  }

  @computed
  get isSaving() {
    return this._isSaving;
  }

  @action
  async run(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;
    this._isRunning = true;

    try {
      const { added, confirmed } = await this._connectorsStore.runAutoMatch(
        this._externalAccount.id,
        this._externalAccount.configId
      );

      await this._alertService.showMessage({
        title: strings.runAutoMatchResultsTitle,
        message: strings.runAutoMatchResultsMessage(added, confirmed)
      });
    } catch (error) {
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + '\n' + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isRunning = false));
    }
  }

  @action
  async save(): Promise<void> {
    this._isSaving = true;

    try {
      // For now, the history can't be changed.
      await this._connectorsStore.setScheduledAutoMatch(
        this._externalAccount.id,
        this._isScheduledAutoMatchEnabled,
        this._externalAccount.autoMatchHistory
      );
      this._onSuccess();
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + '\n' + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isSaving = false));
    }
  }

  cancel(): void {
    this._onCancel();
  }
}
