import { AccountService, AlertService, NavigationService } from '@insights/services';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { ToolsTransport } from '@shared/services/transports/interfaces/ToolsTransport';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

export interface SchoolYearConfigurationDemoCopyDialogViewModel {
  newSchoolName: string;
  baseAccountEmail: string;
  languageCode: string;

  readonly canCreateCopy: boolean;
  readonly isCreatingCopy: boolean;

  cancel: () => void;
  create: (navigate: NavigateFunction) => Promise<void>;
}

export class AppSchoolYearConfigurationDemoCopyDialogViewModel
  implements SchoolYearConfigurationDemoCopyDialogViewModel
{
  @observable private _newSchoolName: string;
  @observable private _baseAccountEmail: string;
  @observable private _languageCode: string;
  @observable private _isCreatingCopy = false;

  constructor(
    private readonly _toolsTransport: ToolsTransport,
    accountService: AccountService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _schoolYearConfiguration: SchoolYearConfigurationModel,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._newSchoolName =
      _localizationService.localizedStrings.insights.viewModels.schoolYearConfiguration.getNewSchoolName(
        _schoolYearConfiguration.schoolName
      );
    this._baseAccountEmail = accountService.userEmail;
    this._languageCode = _schoolYearConfiguration.language || _localizationService.currentLocale;
  }

  @computed
  get newSchoolName() {
    return this._newSchoolName;
  }

  set newSchoolName(value: string) {
    this._newSchoolName = value;
  }

  @computed
  get baseAccountEmail() {
    return this._baseAccountEmail;
  }

  set baseAccountEmail(value: string) {
    this._baseAccountEmail = value;
  }

  @computed
  get languageCode() {
    return this._languageCode;
  }

  set languageCode(value: string) {
    this._languageCode = value;
  }

  @computed
  get canCreateCopy() {
    return this._newSchoolName.length > 0 && this._baseAccountEmail.length > 0 && this._languageCode.length > 0;
  }

  @computed
  get isCreatingCopy() {
    return this._isCreatingCopy;
  }

  cancel() {
    this._onCancel();
  }

  @action
  async create(navigate: NavigateFunction): Promise<void> {
    this._isCreatingCopy = true;

    try {
      const newConfigId = await this._toolsTransport.copyConfiguration(
        this._schoolYearConfiguration.id,
        {
          baseAccountEmail: this._baseAccountEmail,
          shouldAnonymize: true,
          shouldDetach: true,
          shouldKeep: true
        },
        { shouldKeep: true },
        {},
        this._newSchoolName,
        this._languageCode
      );

      // A new configuration was created server-side. We must disregard memoized configurations.
      // We don't have granular invalidation.
      this._schoolYearConfigurationStore.invalidate();

      // Close the dialog before navigating, for a better experience.
      runInAction(() => (this._isCreatingCopy = false));
      this._onSuccess();

      await this._navigationService.navigateToManageSchool(newConfigId, navigate);
    } catch (error) {
      // Stop the "creating" animation before showing that other pop-up.
      runInAction(() => (this._isCreatingCopy = false));

      const strings = this._localizationService.localizedStrings.insights.viewModels.schoolYearConfiguration;
      await this._alertService.showMessage({
        title: strings.createDemoCopyErrorTitle,
        message: strings.createDemoCopyErrorMessage(error as Error)
      });
    }
  }
}
