import { Day as PBDay } from '@buf/studyo_studyo.bufbuild_es/studyo/type_pb';
import {
  addDays,
  addMonths as addMonthsToDate,
  addWeeks,
  startOfWeek as dateStartOfWeek,
  differenceInCalendarDays,
  endOfMonth,
  endOfWeek,
  format as formatDate,
  startOfMonth,
  subDays
} from 'date-fns';
import { Memoize } from 'fast-typescript-memoize';
import { AllDayOfWeek, DayOfWeek, getDayOfWeekNumber } from './Enums';

export class Day {
  private _pb: PBDay;

  static fromPB(pb?: PBDay): Day | undefined {
    return pb ? new Day(pb) : undefined;
  }

  static create(value: { day: number; month: number; year: number }): Day {
    const pbDate = new PBDay();
    pbDate.year = value.year;
    pbDate.month = value.month;
    pbDate.day = value.day;

    const date: Day = new Day(pbDate);
    date.validate();
    return date;
  }

  static fromDateString(value?: string): Day | undefined {
    if (value == null || value.length === 0) {
      return undefined;
    }

    // The value should be timezone-agnostic. Since creating a new Date from it
    // will transform it to a local date and time, we construct a Day from the
    // UTC values.
    const date = new Date(value);

    const pbDay = new PBDay();
    pbDay.year = date.getUTCFullYear();
    pbDay.month = date.getUTCMonth() + 1;
    pbDay.day = date.getUTCDate();

    return new Day(pbDay);
  }

  static fromDate(value: Date | undefined): Day | undefined {
    if (value == null) {
      return undefined;
    }

    const pbDay = new PBDay();
    pbDay.year = value.getFullYear();
    pbDay.month = value.getMonth() + 1;
    pbDay.day = value.getDate();

    return new Day(pbDay);
  }

  constructor(pb: PBDay) {
    this._pb = pb;
  }

  @Memoize()
  get asPB(): PBDay {
    const pb = new PBDay();

    pb.year = this.year;
    pb.month = this.month;
    pb.day = this.day;

    return pb;
  }

  @Memoize()
  get asDate(): Date {
    return new Date(this.year, this.month - 1, this.day);
  }

  get asString(): string {
    const year = this.year.toString().padStart(4, '0');
    const month = this.month.toString().padStart(2, '0');
    const day = this.day.toString().padStart(2, '0');

    return `${year}/${month}/${day}`;
  }

  get year(): number {
    return this._pb.year;
  }

  get month(): number {
    return this._pb.month;
  }

  get day(): number {
    return this._pb.day;
  }

  get dayOfWeek(): DayOfWeek {
    return AllDayOfWeek[this.dayOfWeekNumber];
  }

  get dayOfWeekNumber(): number {
    return this.asUTCDate.getUTCDay();
  }

  get isWeekend(): boolean {
    const dow = this.dayOfWeek;
    return dow === 'saturday' || dow === 'sunday';
  }

  @Memoize()
  get asUTCDate(): Date {
    this.validate();

    // This might seems weird (and it is), but performance wise it's blasting fast.
    // While iterating on all school days, the computation time was around 1 second caused
    // by this property. The delay was the same when using asDayjs or `new Date(year, month, day)`.
    // However, by instantiating a new date object with a string, the delay was around 0.01 seconds
    // which is billion times better. Also, Date is much much much better at parsing date than dayjs
    // is (see https://jsperf.com/date-constructors-by-various-librairies).
    return new Date(this.asDateString + 'T00:00:00Z');
  }

  get asDateString(): string {
    this.validate();

    // Month and day need to be represented by 2 digits in date string.
    const monthValue: string = this.month < 10 ? '0' + this.month : '' + this.month;
    const dayValue: string = this.day < 10 ? '0' + this.day : '' + this.day;

    return this.year + '-' + monthValue + '-' + dayValue;
  }

  formattedString(format: string): string {
    return formatDate(this.asDate, format);
  }

  compare(other: Day): 1 | 0 | -1 {
    if (this.year < other.year) {
      return -1;
    } else if (this.year > other.year) {
      return 1;
    } else if (this.month < other.month) {
      return -1;
    } else if (this.month > other.month) {
      return 1;
    } else if (this.day < other.day) {
      return -1;
    } else if (this.day > other.day) {
      return 1;
    } else {
      return 0;
    }
  }

  isWithin(start: Day, end: Day): boolean {
    return this.compare(start) >= 0 && this.compare(end) <= 0;
  }

  isSame(other: Day): boolean {
    return this.compare(other) === 0;
  }

  isBefore(other: Day): boolean {
    return this.compare(other) < 0;
  }

  isAfter(other: Day): boolean {
    return this.compare(other) > 0;
  }

  isSameOrBefore(other: Day): boolean {
    return this.compare(other) <= 0;
  }

  isSameOrAfter(other: Day): boolean {
    return this.compare(other) >= 0;
  }

  addDays(delta: number): Day {
    return Day.fromDate(addDays(this.asDate, delta))!;
  }

  addWeeks(delta: number): Day {
    return Day.fromDate(addWeeks(this.asDate, delta))!;
  }

  addMonths(delta: number): Day {
    return Day.fromDate(addMonthsToDate(this.asDate, delta))!;
  }

  substractDays(delta: number): Day {
    return Day.fromDate(subDays(this.asDate, delta))!;
  }

  /**
   * Gets the number of days between this Day and another target Day.
   * A negative value indicates that other Day occurs before this Day.
   * @param other The other Day to compare with.
   */
  dayCountUntil(other: Day) {
    // TODO: Confirm this is fixed: https://github.com/iamkun/dayjs/issues/384
    return differenceInCalendarDays(other.asDate, this.asDate);
  }

  /**
   * Gets a Day at the beginning of the same week as this Day.
   * @param startOfWeek Optional parameter indicating the week day to start with. When not
   * provided, it uses system's locale (weekStart).
   */
  firstDayOfWeek(startOfWeek?: DayOfWeek) {
    return Day.fromDate(
      dateStartOfWeek(this.asDate, {
        weekStartsOn: startOfWeek != null ? dayOfWeekToNumber(startOfWeek) : undefined
      })
    )!;
  }

  /**
   * Gets a Day at the beginning of the same month as this Day.
   */
  firstDayOfMonth() {
    return Day.fromDate(startOfMonth(this.asDate))!;
  }

  /**
   * Gets a Day at the end of the same week as this Day.
   * @param startOfWeek Optional parameter indicating the week day to start with. When not
   * provided, it uses system's locale (weekStart).
   */
  lastDayOfWeek(startOfWeek?: DayOfWeek) {
    return Day.fromDate(
      endOfWeek(this.asDate, { weekStartsOn: startOfWeek != null ? dayOfWeekToNumber(startOfWeek) : undefined })
    )!;
  }

  /**
   * Gets a Day at the end of the same month as this Day.
   */
  lastDayOfMonth() {
    return Day.fromDate(endOfMonth(this.asDate))!;
  }

  nextDayForDayOfWeek(dow: DayOfWeek): Day {
    const targetDowNumber = getDayOfWeekNumber(dow);
    const diff = targetDowNumber - this.dayOfWeekNumber;
    return this.addDays(diff < 0 ? diff + 7 : diff);
  }

  previousDayForDayOfWeek(dow: DayOfWeek): Day {
    const targetDowNumber = getDayOfWeekNumber(dow);
    const diff = targetDowNumber - this.dayOfWeekNumber;
    return this.addDays(diff > 0 ? diff - 7 : diff);
  }

  private validate(): void {
    if (this.year === 0 || this.month === 0 || this.day === 0) {
      // TODO should send error?
      // throw new InvalidDateError('This Day contains undefined attributes.');
    }
  }
}

function dayOfWeekToNumber(dow: DayOfWeek) {
  switch (dow) {
    case 'sunday':
      return 0;
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;
  }
}
