import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_pb';
import { EditableBlackbaudKindMapping } from '@shared/models/connectors';
import {
  BlackbaudAssignmentKind,
  BlackbaudExternalAccountDetails,
  BlackbaudKindMappingModel
} from '@shared/models/connectors/interfaces';
import { FullyEditableListProperty } from '@shared/models/editables';
import { LocalizationService } from '@shared/resources/services';
import { BlackbaudConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface BlackbaudMappingsViewModel extends ExternalAccountEditionViewModel {
  readonly kindMappings: EditableBlackbaudKindMapping[];
  readonly availableKinds: BlackbaudAssignmentKind[];

  readonly nextAssignmentKind: BlackbaudAssignmentKind | undefined;
  addKindMapping(kind: BlackbaudAssignmentKind): void;
}

export class AppBlackbaudMappingsViewModel
  extends BaseExternalAccountEditionViewModel
  implements BlackbaudMappingsViewModel
{
  // We use an editable field to hold changes, but it doesn't have a host to apply to.
  private _kindMappings: FullyEditableListProperty<
    BC.KindMapping,
    BlackbaudKindMappingModel,
    EditableBlackbaudKindMapping,
    unknown
  >;
  @observable private _nextKind: BlackbaudAssignmentKind | undefined;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _blackbaudStore: BlackbaudConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    public readonly availableKinds: BlackbaudAssignmentKind[],
    private readonly _originalDetails: BlackbaudExternalAccountDetails
  ) {
    super();
    makeObservable(this);
    this._kindMappings = new FullyEditableListProperty(
      _originalDetails.kindMappings.map((m) => new EditableBlackbaudKindMapping(m)),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }

  @computed
  get kindMappings() {
    return this._kindMappings.values;
  }

  @computed
  get nextAssignmentKind() {
    return this._nextKind;
  }

  @action
  addKindMapping(kind: BlackbaudAssignmentKind) {
    this._kindMappings.addItem(EditableBlackbaudKindMapping.createNew(kind.id, kind.name));
    this._nextKind = undefined;
  }

  get hasChanges() {
    return this._hasChanges || this._kindMappings.isChanged;
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this.hasChanges) {
      console.error('Applying without changes, ignoring...');
      this._onSuccess();
      return;
    }

    if (this._kindMappings.values.some((m) => m.isIncomplete)) {
      this.addError(strings.incompleteKindMappings);
      return;
    }

    this.beginApplying();

    try {
      await this._blackbaudStore.updateBlackbaudAccountSettings(
        this._configId,
        this._externalAccountId,
        this._originalDetails.yearLabel,
        this._originalDetails.levelId,
        this._originalDetails.levelName,
        this.kindMappings,
        this._originalDetails.includeNonLeadSections
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._kindMappings.reset();
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this.hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
