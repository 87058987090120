import { CustomizableViewModel } from '@insights/viewmodels';
import { SxProps } from '@mui/material';
import { DayConfigurationModel } from '@shared/models/config';
import { observer } from 'mobx-react-lite';
import { CSSProperties, ReactElement } from 'react';
import { Column } from '../layout';
import { CustomizationsListDayConfiguration } from './CustomizationsListDayConfiguration.tsx';
import { CustomizationsListTable } from './CustomizationsListTable.tsx';

export interface CustomizationsListProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: CustomizableViewModel;
  color?: 'light' | 'dark';
  renderRightComponent?: (dc: DayConfigurationModel) => ReactElement;
  variant?: 'list' | 'table';
}

export const CustomizationsList = observer((props: CustomizationsListProps) => {
  const { sx = [], className, viewModel, style, variant = 'list', color = 'light', renderRightComponent } = props;
  const textColor = color === 'light' ? 'white' : 'black';

  switch (variant) {
    case 'table':
      return (
        <CustomizationsListTable
          sx={sx}
          className={className}
          style={style}
          viewModel={viewModel}
          textColor={textColor}
          renderRightComponent={renderRightComponent}
        />
      );

    default:
      return (
        <Column sx={sx} className={className} style={style} horizontalContentAlignment="left">
          {viewModel.dayConfigurations.map((dc) => (
            <CustomizationsListDayConfiguration
              key={dc.id}
              dayConfiguration={dc}
              variant="list"
              renderRightComponent={renderRightComponent}
              viewModel={viewModel}
              textColor={textColor}
            />
          ))}
        </Column>
      );
  }
});
