import { SchoolSubscription, SchoolSubscriptionModel } from '@shared/models/subscriptions';
import { SubscriptionEntitlement } from '@shared/models/types';
import { protobufFromSubscriptionEntitlement } from '@shared/models/types/EnumConversion';
import { AppBaseStore } from '@shared/services/stores';
import { SubscriptionsTransport } from '@shared/services/transports';

export interface SubscriptionsService {
  getSchoolSubscription(configId: string): Promise<SchoolSubscriptionModel | undefined>;
  addSchoolRequiredFeature(
    configId: string,
    entitlement: SubscriptionEntitlement,
    stripePriceId: string
  ): Promise<SchoolSubscriptionModel>;
  removeSchoolRequiredFeature(configId: string, entitlement: SubscriptionEntitlement): Promise<SchoolSubscriptionModel>;
}

export class AppSubscriptionsService extends AppBaseStore implements SubscriptionsService {
  constructor(private readonly _transport: SubscriptionsTransport) {
    super('SubscriptionsService');
  }

  getSchoolSubscription(configId: string): Promise<SchoolSubscription | undefined> {
    return this.withInvalidate(async () => {
      const pb = await this._transport.getSchoolSubscription(configId);
      return pb != null ? new SchoolSubscription(pb) : undefined;
    });
  }

  async addSchoolRequiredFeature(
    configId: string,
    entitlement: SubscriptionEntitlement,
    stripePriceId: string
  ): Promise<SchoolSubscriptionModel> {
    const pb = await this._transport.addSchoolRequiredFeature(
      configId,
      protobufFromSubscriptionEntitlement(entitlement),
      stripePriceId
    );
    this.invalidate();
    return new SchoolSubscription(pb);
  }

  async removeSchoolRequiredFeature(
    configId: string,
    entitlement: SubscriptionEntitlement
  ): Promise<SchoolSubscriptionModel> {
    const pb = await this._transport.removeSchoolRequiredFeature(
      configId,
      protobufFromSubscriptionEntitlement(entitlement)
    );
    this.invalidate();
    return new SchoolSubscription(pb);
  }
}
