import {
  AuthorizationRoleCondition,
  ErrorIndicator,
  LoadingIndicator,
  ObservablePresenter,
  RequiresFeatureCondition,
  SchoolDayHeader,
  SchoolWeekHeader,
  SectionName,
  WeekPagingNavigation
} from '@insights/components';
import {
  PublishedTasksByGradeInfo,
  PublishedTasksByGradePageInfo,
  PublishedTasksByGradeViewModel
} from '@insights/viewmodels';
import { mdiCalendarEdit } from '@mdi/js';
import Icon from '@mdi/react';
import ExportIcon from '@mui/icons-material/ArrowDownward';
import {
  Box,
  IconButton,
  Switch,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { DateUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { ContentDefinitionModel } from '@shared/models/content';
import { AdminAuthorizationRoles } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { MinLayoutWidth, MinTinyChartHeight } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface PublishedTasksByGradeProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: PublishedTasksByGradeViewModel;
  displayWeekNavigation?: boolean;
}

export const PublishedTasksByGrade = observer((props: PublishedTasksByGradeProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], style, viewModel, displayWeekNavigation = true } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;
  const theme = useTheme();

  return (
    <Root sx={sx} className={className} style={style}>
      <RequiresFeatureCondition
        requiredFeature="all-published-tasks-widget"
        featureNotAvailableContent={{
          fill: true,
          padding: 2,
          title: strings.publishedTasksTitle,
          titleVariant: 'h6'
        }}
      >
        <ObservablePresenter
          className="container"
          data={viewModel.data}
          indicatorsSize="normal"
          loadingMessage={strings.loadingDataMessage}
          errorMessage={strings.loadingDataErrorMessage}
          render={(configData) => (
            <Box display="flex" flexDirection="column">
              <Box marginLeft={3} marginBottom={2} display="flex" flexDirection="row" alignItems="center">
                <Typography variant="h6">{strings.publishedTasksTitle}</Typography>

                <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                  <Tooltip title={strings.editAssessmentPlanningDatesButtonTooltip}>
                    <IconButton onClick={() => void viewModel.editAssessmentPlanningDates()}>
                      <Icon path={mdiCalendarEdit} size={1} color={theme.palette.text.secondary} />
                    </IconButton>
                  </Tooltip>
                </AuthorizationRoleCondition>

                <Box flex={1} />

                <Typography variant="body1">{strings.showOnlyImportantTasks}</Typography>
                <Switch
                  checked={viewModel.importantTasksOnly}
                  onChange={(_, checked) => (viewModel.importantTasksOnly = checked)}
                />

                <Tooltip title={strings.csvExportTooltip}>
                  <IconButton disabled={viewModel.isExporting} onClick={() => void viewModel.exportToCsv()}>
                    {viewModel.isExporting ? <LoadingIndicator size="tiny" /> : <ExportIcon />}
                  </IconButton>
                </Tooltip>
              </Box>

              <Box flex={1}>
                {viewModel.pageData.case({
                  pending: () => renderEmptyTable(viewModel, configData, localizationService),
                  rejected: () => <ErrorIndicator message={strings.loadingDataErrorMessage} />,
                  fulfilled: (data) => renderTable(viewModel, configData, data, localizationService)
                })}
              </Box>

              <Box
                className={'footer'}
                marginTop={2}
                marginLeft={3}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Box flex={1} />

                {/* Show a loading indicator when changing the current page */}
                {viewModel.pageData.state === 'pending' && <LoadingIndicator size="tiny" />}

                {displayWeekNavigation && <WeekPagingNavigation pagination={viewModel.pagination} />}
              </Box>
            </Box>
          )}
        />
      </RequiresFeatureCondition>
    </Root>
  );
});

function renderEmptyTable(
  viewModel: PublishedTasksByGradeViewModel,
  configData: PublishedTasksByGradeInfo,
  localizationService: LocalizationService
) {
  const pageRange = viewModel.pagination?.currentPage;
  if (pageRange != null) {
    return renderTable(
      viewModel,
      configData,
      {
        schoolDays: configData.schoolDays.filter((schoolDay) =>
          schoolDay.day.isWithin(pageRange.startDay, pageRange.endDay)
        ),
        dayInfos: [],
        weekInfos: []
      },
      localizationService
    );
  }

  return null;
}

function renderTable(
  viewModel: PublishedTasksByGradeViewModel,
  configData: PublishedTasksByGradeInfo,
  pageData: PublishedTasksByGradePageInfo,
  localizationService: LocalizationService
) {
  return (
    <Table>
      {renderTableHeader(configData, pageData, localizationService)}
      {renderTableBody(viewModel, configData, pageData, localizationService)}
    </Table>
  );
}

function renderTableHeader(
  _configData: PublishedTasksByGradeInfo,
  pageData: PublishedTasksByGradePageInfo,
  localizationService: LocalizationService
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <TableHead>
      <TableRow className={'tableRow'}>
        <TableCell className={'gradeLevelCell'}>
          <Typography variant="subtitle2" className={'headerText'}>
            {strings.grade}
          </Typography>
        </TableCell>

        {pageData.schoolDays
          .filter((schoolDay) => !DateUtils.isWeekend(schoolDay.day))
          .map((schoolDay) => (
            <TableCell key={schoolDay.day.asDateString} className={'tableDayCell'}>
              <SchoolDayHeader schoolDay={schoolDay} displayType="column-header" />
            </TableCell>
          ))}

        <TableCell className={clsx('tableDayCell', 'weekCell')}>
          <SchoolWeekHeader schoolDay={pageData.schoolDays[0]} displayType="column-header" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function renderTableBody(
  viewModel: PublishedTasksByGradeViewModel,
  configData: PublishedTasksByGradeInfo,
  pageData: PublishedTasksByGradePageInfo,
  localizationService: LocalizationService
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;
  const materialTableStrings = localizationService.localizedStrings.insights.materialTable;

  return (
    <TableBody>
      {configData.gradeLevels.length === 0 && (
        <TableRow>
          <TableCell colSpan={99} align="center">
            <Typography variant="body2">{materialTableStrings.body?.emptyDataSourceMessage ?? ''}</Typography>
          </TableCell>
        </TableRow>
      )}
      {configData.gradeLevels.length > 0 &&
        configData.gradeLevels.map((gradeLevelInfo) => {
          const weekInfo = pageData.weekInfos.find(
            (i) => i.schoolDay == null && i.gradeLevel === gradeLevelInfo.gradeLevel
          );

          const publishedTasksForWeekCount = weekInfo?.publishedTasks.length ?? 0;

          return (
            <TableRow key={gradeLevelInfo.gradeLevel} className={'tableRow'}>
              <TableCell className={'gradeLevelCell'}>
                <SectionName
                  color="darker-bmgray"
                  title={gradeLevelInfo.gradeLevel}
                  subInformation1={strings.localizedNumberOfSections(gradeLevelInfo.sections.size)}
                />
              </TableCell>

              {pageData.schoolDays
                .filter((schoolDay) => !DateUtils.isWeekend(schoolDay.day))
                .map((schoolDay) => {
                  const dayInfo = pageData.dayInfos.find(
                    (d) => d.schoolDay!.day.isSame(schoolDay.day) && d.gradeLevel === gradeLevelInfo.gradeLevel
                  );
                  const publishedTasksCount = dayInfo?.publishedTasks.length ?? 0;

                  return (
                    <TableCell
                      key={`${gradeLevelInfo.gradeLevel} - ${schoolDay.day.asDateString}`}
                      className={clsx('tableDayCell', publishedTasksCount > 0 && 'tableDayWithValueCell')}
                      onClick={() =>
                        dayInfo &&
                        publishedTasksCount > 0 &&
                        void showDetail(
                          viewModel,
                          gradeLevelInfo.gradeLevel,
                          gradeLevelInfo.sections.size,
                          dayInfo.schoolDay!,
                          dayInfo.schoolDay!,
                          dayInfo.publishedTasks
                        )
                      }
                    >
                      {publishedTasksCount > 0 && <Typography variant="body1">{publishedTasksCount}</Typography>}
                    </TableCell>
                  );
                })}

              <TableCell
                className={clsx('tableDayCell', 'weekCell', publishedTasksForWeekCount > 0 && 'tableDayWithValueCell')}
                onClick={() =>
                  weekInfo &&
                  publishedTasksForWeekCount > 0 &&
                  void showDetail(
                    viewModel,
                    gradeLevelInfo.gradeLevel,
                    gradeLevelInfo.sections.size,
                    pageData.schoolDays[0],
                    pageData.schoolDays[pageData.schoolDays.length - 1],
                    weekInfo.publishedTasks
                  )
                }
              >
                {publishedTasksForWeekCount > 0 && (
                  <Typography variant="body1">{publishedTasksForWeekCount}</Typography>
                )}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
}

async function showDetail(
  viewModel: PublishedTasksByGradeViewModel,
  gradeLevel: string,
  gradeLevelSectionCount: number,
  fromDay: SchoolDay,
  toDay: SchoolDay,
  tasks: ContentDefinitionModel[]
): Promise<void> {
  await viewModel.showDetail(viewModel.configId, gradeLevel, gradeLevelSectionCount, fromDay, toDay, tasks);
}

const Root = styled(Box)(({ theme }) => ({
  '.container': {
    minHeight: MinTinyChartHeight,
    minWidth: MinLayoutWidth
  },

  '.table': {
    tableLayout: 'fixed'
  },
  '.tableRow': {
    verticalAlign: 'center',
    height: '50px'
  },
  '.gradeLevelCell': {
    width: '20%',
    cursor: 'default'
  },
  '.tableDayCell': {
    textAlign: 'center',
    paddingRight: theme.spacing(2),
    width: 'calc(80%/6)',
    cursor: 'default'
  },
  '.tableDayWithValueCell': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[50]
    }
  },
  '.weekCell': {
    borderLeft: `1px solid ${grey[300]}`
  },
  '.headerText': {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    lineHeight: 'normal'
  },
  '.footer': {
    minHeight: 30
  }
}));
