import { LightInsightsMuiTheme } from '@insights/theme';
import { ThemeOptions } from '@mui/material';
import { AppThemeService } from '@shared/resources/services';
import { ThemeKind } from '@shared/resources/themes';
import { Storage } from '@shared/services';
import { computed, makeObservable } from 'mobx';

export class InsightsThemeService extends AppThemeService {
  constructor(defaultKind: ThemeKind, storage: Storage) {
    super(defaultKind, storage);
    makeObservable(this);
  }
  @computed
  get availableKinds(): ThemeKind[] {
    return ['light'];
  }

  protected getMaterialThemeForKind(): ThemeOptions {
    return LightInsightsMuiTheme;
  }
}
