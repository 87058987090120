export interface SectionsViewModelStrings {
  confirmSkipConflictingOccurrencesMessage: (count: number, targetName: string) => string;
  confirmSkipConflictingOccurrencesTitle: string;
  confirmSkipDayOccurrencesMessage: (count: number, targetName: string) => string;
  confirmSkipDayOccurrencesTitle: string;
  scheduleFieldInError: string;
  skippingOccurrencesMessages: string;
  teacherRequiredMessage: string;
  teacherRequiredTitle: string;
  unexpectedErrorMessage: string;
  unexpectedErrorTitle: string;
  unknownIdsErrorMessage: string;
  unknownIdsErrorTitle: string;
}

export const EnglishSectionsViewModelStrings: SectionsViewModelStrings = {
  confirmSkipConflictingOccurrencesMessage: (count, targetName) =>
    `Are you sure you want to skip ${count} class occurrence${count > 1 ? 's' : ''} conflicting with ${targetName} on that day?`,
  confirmSkipConflictingOccurrencesTitle: 'Please confirm',
  confirmSkipDayOccurrencesMessage: (count, targetName) =>
    `Are you sure you want to skip all class occurrences (${count}) other than ${targetName} on that day?`,
  confirmSkipDayOccurrencesTitle: 'Please confirm',
  scheduleFieldInError: 'Please verify fields in red. A value is missing or invalid.',
  skippingOccurrencesMessages: 'Skipping occurrences…',
  teacherRequiredMessage:
    'Cannot restore this occurrence automatically. The next occurrence is also skipped. The teacher for that section can restore occurrences in Studyo from their "Teacher" view. You can also contact support for help.',
  teacherRequiredTitle: 'Cannot proceed',
  unexpectedErrorMessage: 'An unexpected error occurred. Please try again later.\n\nError: ',
  unexpectedErrorTitle: 'Oops',
  unknownIdsErrorMessage: 'At least one id could not be recognized as a student identifier.',
  unknownIdsErrorTitle: 'Oops'
};

export const FrenchSectionsViewModelStrings: SectionsViewModelStrings = {
  confirmSkipConflictingOccurrencesMessage: (count, targetName) =>
    `Êtes-vous certain de vouloir sauter ${count} occurrence${count > 1 ? 's' : ''} de classe en conflit avec ${targetName} ce jour-là?`,
  confirmSkipConflictingOccurrencesTitle: 'Veuillez confirmer',
  confirmSkipDayOccurrencesMessage: (count, targetName) =>
    `Êtes-vous certain de vouloir sauter toutes les occurrences de classe (${count}) autre que ${targetName} ce jour-là?`,
  confirmSkipDayOccurrencesTitle: 'Veuillez confirmer',
  scheduleFieldInError: 'Veuillez vérifier les champs en rouge. Une valuer est absente ou invalide.',
  skippingOccurrencesMessages: 'Saut des occurrences…',
  teacherRequiredMessage:
    'Impossible de restaurer cette occurrence. La prochaine occurrence est aussi sautée. L’enseignant de ce groupe-matière peut restaurer les occurrences dans Studyo à partir de sa vue "Prof". Vous pouvez aussi contacter le support pour de l’aide.',
  teacherRequiredTitle: 'Impossible de procéder',
  unexpectedErrorMessage: 'Une erreur inattendue est survenue. Veuillez essayer plus tard.\n\nErreur: ',
  unexpectedErrorTitle: 'Oups',
  unknownIdsErrorMessage: 'Au moins un identifiant n’a pu être reconnu comme un identifiant d’élève.',
  unknownIdsErrorTitle: 'Oups'
};
