export interface NotFoundStrings {
  readonly title: string;
  readonly subtitle: string;
  readonly goHomeButtonTitle: string;
}

export const EnglishNotFoundStrings: NotFoundStrings = {
  title: 'Not Found',
  subtitle: 'The page you are looking for doesn’t exist',
  goHomeButtonTitle: 'Go to homepage'
};

export const FrenchNotFoundStrings: NotFoundStrings = {
  title: 'Page Introuvable',
  subtitle: 'La page que vous tentez d’accéder n’existe pas',
  goHomeButtonTitle: 'Retourner à la page principale'
};
