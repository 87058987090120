import { AccountService, AlertService, NavigationService } from '@insights/services';
import { SectionModel } from '@shared/models/config';
import { ExternalSection } from '@shared/models/connectors';
import { AdminAuthorizationRoles } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { differenceInMilliseconds } from 'date-fns';
import _, { Dictionary } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  AppExternalAssociationListViewModel,
  ExternalAssociationListViewModel
} from './ExternalAssociationListViewModel';
import { AppExternalAssociationViewModel } from './ExternalAssociationViewModel';

export interface ExternalAssociationsScreenViewModel {
  readonly associationList: IPromiseBasedObservable<ExternalAssociationListViewModel>;
}

export class AppExternalAssociationsScreenViewModel implements ExternalAssociationsScreenViewModel {
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _accountService: AccountService,
    private readonly _configId: string,
    private readonly _externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get associationList(): IPromiseBasedObservable<ExternalAssociationListViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<ExternalAssociationListViewModel> {
    // We work with the sections the associated account teaches.
    // If the user is a super-admin and has no teacher account, we use all sections they can see.
    const [externalAccount, allSectionsById] = await Promise.all([
      this._connectorsStore.getExternalAccount(this._configId, this._externalAccountId),
      this._schoolYearConfigurationStore.getSectionsById(this._configId)
    ]);

    const currentAccountId = this._accountService.getAccountIdForConfigRole(this._configId, 'teacher');

    let sections: SectionModel[] = [];
    const isAdmin = this._accountService.isAllowed(AdminAuthorizationRoles);

    if (isAdmin) {
      // Admins always see every section, and the default teacher filter will narrow
      // to the owner of that external account, if any. It does mean a local admin in
      // Studyo might be able to see external classes they shouldn't, but that's not
      // a security issue, the visible information is narrow.
      sections = await this._schoolYearConfigurationStore.getSections(this._configId);
    } else if (currentAccountId != null) {
      sections = await this._schoolYearConfigurationStore.getTaughtSectionsForTeacherId(
        this._configId,
        currentAccountId
      );
    }

    const [config, accountsById, associations] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this._configId),
      this._schoolYearConfigurationStore.getAccountsById(this._configId, true),
      this._connectorsStore.getExternalAssociations(this._configId, this._externalAccountId)
    ]);

    // Done after getExternalAssociations for memoization.
    const associationsBySectionId = await this._connectorsStore.getExternalAssociationsBySectionId(
      this._configId,
      this._externalAccountId
    );

    let externalSections: ExternalSection[] = [];
    let externalSectionsById: Dictionary<ExternalSection> = {};
    let isDataIncomplete = false;

    try {
      if (externalAccount.kind != 'calendars') {
        externalSections = await this._connectorsStore.getExternalSections(this._externalAccountId);
        externalSectionsById = _.keyBy(externalSections, (s) => s.id);
      }
    } catch {
      console.error('Could not retrieve external sections.');
      isDataIncomplete = true;
    }

    const editableSections = sections.map((section) => {
      const association = associationsBySectionId[section.id];

      return new AppExternalAssociationViewModel(
        this._connectorsStore,
        this._alertService,
        this._localizationService,
        externalAccount,
        association,
        this._configId,
        section,
        externalSectionsById,
        accountsById
      );
    });

    const mappedSectionIds = new Set(_.compact(editableSections.map((es) => es.section?.id)));
    const extraSections = associations
      .filter((association) => !mappedSectionIds.has(association.sectionId))
      .map((association) => {
        const section = allSectionsById[association.sectionId];

        return new AppExternalAssociationViewModel(
          this._connectorsStore,
          this._alertService,
          this._localizationService,
          externalAccount,
          association,
          this._configId,
          section,
          externalSectionsById,
          accountsById
        );
      });

    const defaultFilterTeacherId =
      isAdmin && currentAccountId != externalAccount.accountId ? externalAccount.accountId : undefined;

    const maxTerm = _.orderBy(
      config.terms,
      (t) => differenceInMilliseconds(t.endDay.asDate, t.startDay.asDate),
      'desc'
    )[0];
    const minDay = maxTerm?.startDay ?? config.startDay;
    const maxDay = maxTerm?.endDay ?? config.endDay;

    return new AppExternalAssociationListViewModel(
      this._navigationService,
      this._alertService,
      this._localizationService,
      this._connectorsStore,
      this._accountService,
      this._configId,
      externalAccount,
      editableSections.concat(extraSections),
      externalSections,
      minDay,
      maxDay,
      accountsById,
      isDataIncomplete,
      defaultFilterTeacherId
    );
  }
}
