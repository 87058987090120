import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_pb';
import { BlackbaudAssignmentKind } from './interfaces';

export class GrpcBlackbaudAssignmentKind implements BlackbaudAssignmentKind {
  constructor(private readonly pb: BC.AssignmentKind) {}

  get id() {
    return this.pb.id;
  }

  get name() {
    return this.pb.name;
  }
}
