import * as VPB from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/veracross_v3_pb';
import { Veracross } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/veracross_v3_connect';
import { VeracrossV3Transport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class VeracrossV3GrpcTransport extends BaseGrpcTransport implements VeracrossV3Transport {
  async fetchVeracrossExternalAccountDetails(
    externalAccountId: string
  ): Promise<VPB.GetExternalAccountDetailsResponse> {
    console.log(`Fetching Veracross external account details for [externalAccountId: ${externalAccountId}]...`);

    const request = new VPB.GetExternalAccountDetailsRequest({ externalAccountId });
    const response = await this.performRequest(Veracross, Veracross.methods.getExternalAccountDetails, request);

    console.log('Successfully fetched Veracross external account.');

    return response;
  }

  async createOrUpdateVeracrossExternalAccount(
    externalAccountId: string | undefined,
    configId: string,
    clientId: string,
    clientSecret: string,
    schoolRoute: string,
    schoolYear: number,
    portalUrl: string,
    accountName: string
  ): Promise<VPB.CreateOrUpdateExternalAccountResponse> {
    console.log('Creating or updating Veracross external account...');

    const request = new VPB.CreateOrUpdateExternalAccountRequest({
      externalAccountId,
      configId,
      clientId,
      clientSecret,
      schoolRoute,
      schoolYear,
      portalUrl,
      accountName
    });

    const response = await this.performRequest(Veracross, Veracross.methods.createOrUpdateExternalAccount, request);

    console.log('Successfully created or updated Veracross external account.');

    return response;
  }
}
