import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { ImportSessionOptionsViewModel } from '@insights/viewmodels';
import { Card, CardContent, Checkbox, FormControlLabel, Stack, SxProps, Typography } from '@mui/material';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

export interface CreateSchoolImportsProps {
  sx?: SxProps;
  className?: string;
  viewModel: ImportSessionOptionsViewModel;
}

export const CreateSchoolImports = observer(({ sx = [], className, viewModel }: CreateSchoolImportsProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.createSchool;

  return (
    <Card sx={sx} className={className}>
      <CardContent sx={{ width: '100%' }}>
        <Stack spacing={2}>
          <Typography variant="h5">{strings.importsSection}</Typography>
          <Stack spacing="0px">
            {viewModel.importSessions.length > 1 && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={viewModel.keepAllImportSessions}
                    onChange={(e) => (viewModel.keepAllImportSessions = e.target.checked)}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontStyle: 'italic',
                      color: (theme) => theme.palette.text.secondary
                    }}
                  >
                    {strings.keepAllImportSessionsLabel}
                  </Typography>
                }
              />
            )}
            {viewModel.importSessions.map((s) => (
              <FormControlLabel
                key={`import-session-${s.id}`}
                control={
                  <Checkbox
                    checked={viewModel.keptImportSessionIds.includes(s.id)}
                    onChange={(event) => {
                      if (event.target.checked) {
                        viewModel.keptImportSessionIds = _.union(viewModel.keptImportSessionIds, [s.id]);
                      } else {
                        viewModel.keptImportSessionIds = viewModel.keptImportSessionIds.filter((id) => id != s.id);
                      }
                    }}
                  />
                }
                label={s.name}
              />
            ))}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
});
