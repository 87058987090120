import { amber, blue, purple, red, teal } from '@mui/material/colors';

// This will serve as min size for displayed components.
// This is a "standard" used in the industry to design for mobile.
export const MinLayoutWidth = 320;

// This will serve as the min height for charts.
export const MinTinyChartHeight = 200;
export const MinSmallChartHeight = 300;
export const MinMediumChartHeight = 400;
export const MinLargeChartHeight = 500;

// Some screens must not be too large.
export const MaxLayoutWidth = 1050;

// Chart colors
export const StudentsColor = blue[500];
export const StudentsDarkColor = blue[700];
export const StudentsLightColor = blue[200];
export const TeachersColor = purple[500];
export const TeachersDarkColor = purple[700];
export const TeachersLightColor = purple[200];
export const ParentsColor = teal[500];
export const ParentsDarkColor = teal[700];
export const ParentsLightColor = teal[200];

export const DefaultTablePageSizes = [10, 25, 50];
export const DefaultTablePageSize = 10;

export const SmallTablePageSizes = [5, 50];
export const SmallTablePageSize = 5;

export const WorkloadManagerStudentAtThresholdColor = amber[400];
export const WorkloadManagerStudentAtThresholdHoveredColor = amber[300];
export const WorkloadManagerStudentOverThresholdColor = red[400];
export const WorkloadManagerStudentOverThresholdHoveredColor = red[300];

export const Thresholds = {
  maxThreshold: 500,
  defaultDailyThreshold: 2,
  defaultWeeklyThreshold: 3
};

export const DefaultPageLength = 7;

export const StorageKeys = {
  locale: 'locale'
};
