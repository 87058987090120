import * as TC from '@buf/studyo_studyo.bufbuild_es/studyo/type_teams_pb';
import { TeamsAssignmentCategoryModel } from './interfaces';

export class GrpcTeamsAssignmentCategory implements TeamsAssignmentCategoryModel {
  constructor(private readonly _pb: TC.AssignmentCategory) {}

  get id() {
    return this._pb.id;
  }

  get name() {
    return this._pb.name;
  }
}
