import { SchoolSubscription as PBSchoolSubscription } from '@buf/studyo_studyo.bufbuild_es/studyo/type_subscriptions_pb';
import { computed, makeObservable } from 'mobx';
import { BaseModel, SerializableModel } from '../Model';
import { Feature, FeatureModel } from './Feature';

export interface SchoolSubscriptionModel extends SerializableModel<PBSchoolSubscription> {
  readonly requiredFeatures: FeatureModel[];
}

export class SchoolSubscription extends BaseModel<PBSchoolSubscription> implements SchoolSubscriptionModel {
  constructor(pb: PBSchoolSubscription) {
    super(pb);
    makeObservable(this);
  }

  @computed
  get requiredFeatures(): FeatureModel[] {
    return this._pb.requiredFeatures.map((f) => new Feature(f));
  }
}
