import MoreIcon from '@mui/icons-material/MoreVert';
import { Badge, BadgeProps, Box, IconButton, Menu, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Children, PropsWithChildren, useRef, useState } from 'react';

export interface MoreActionMenuProps extends PropsWithChildren {
  sx?: SxProps;
  disabled?: boolean;
  badgeProps?: Partial<BadgeProps>;
}

export const MoreActionMenu = observer((props: MoreActionMenuProps) => {
  const { children, disabled, sx = [], badgeProps } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const filteredChildren = Children.map(children, (c) => c)?.filter((c) => c != null) ?? [];

  return (
    <>
      {filteredChildren.length > 0 && (
        <>
          <IconButton sx={sx} ref={buttonRef} disabled={disabled} onClick={() => setIsOpen(true)} size="large">
            <Badge {...badgeProps}>
              <MoreIcon />
            </Badge>
          </IconButton>
          <Menu anchorEl={buttonRef.current} open={isOpen} onClose={() => setIsOpen(false)}>
            {filteredChildren.map((c, i) => (
              <Box key={i} sx={{ outline: 'none' }} onClick={() => setIsOpen(false)}>
                {c}
              </Box>
            ))}
          </Menu>
        </>
      )}
    </>
  );
});
