import { ActiveUsers, ObservablePresenter } from '@insights/components';
import { PeriodDisplay } from '@insights/enums';
import { ActiveStudentsViewModel } from '@insights/viewmodels';
import { Box, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { MinMediumChartHeight, StudentsColor, StudentsLightColor } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ActiveStudentsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: ActiveStudentsViewModel;
}

export const ActiveStudents = observer((props: ActiveStudentsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.students;

  const periodCaption = useMemo(() => {
    switch (viewModel.periodDisplay) {
      case PeriodDisplay.Daily:
        return strings.dailyActiveStudents;

      case PeriodDisplay.Weekly:
        return strings.weeklyActiveStudents;

      case PeriodDisplay.Monthly:
        return strings.monthlyActiveStudents;

      default:
        return '';
    }
  }, [viewModel.periodDisplay, localizationService.currentLocale]);

  const periodSubcaption = useMemo(() => {
    switch (viewModel.periodDisplay) {
      case PeriodDisplay.Daily:
        return strings.numberOfDailyActiveStudents;

      case PeriodDisplay.Weekly:
        return strings.numberOfWeeklyActiveStudents;

      case PeriodDisplay.Monthly:
        return strings.numberOfMonthlyActiveStudents;

      default:
        return '';
    }
  }, [viewModel.periodDisplay, localizationService.currentLocale]);

  return (
    <Box sx={{ ...sx, height: MinMediumChartHeight, minWidth: 600 }} className={className} style={style}>
      <ObservablePresenter
        sx={{ width: '100%', height: '100%' }}
        data={viewModel.data}
        loadingMessage={strings.loadingActiveStudentsMessage}
        errorMessage={strings.loadingActiveStudentsErrorMessage}
        render={(data) => {
          if (!data.hasSchoolStarted) {
            return (
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <Typography variant="h5">{strings.schoolNotStartedMessage}</Typography>
              </Box>
            );
          }

          if (data.activeStudentsAny.length === 0 && data.activeStudentsInteracted.length === 0) {
            return (
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <Typography variant="h5">{strings.noActiveStudentsMessage}</Typography>
              </Box>
            );
          }

          return (
            <ActiveUsers
              caption={periodCaption}
              subcaption={periodSubcaption}
              interactedUsersData={data.activeStudentsInteracted}
              interactedUsersTooltipSuffix={strings.interactedWithTaskTooltip}
              interactedUsersColor={StudentsColor}
              interactedUsersLegendTitle={strings.interactedWithTaskTitle}
              anyUsersData={data.activeStudentsAny}
              anyUsersTooltipSuffix={strings.openedStudyoTooltip}
              anyUsersColor={StudentsLightColor}
              anyUsersLegendTitle={strings.openedStudyoTitle}
              periodDisplay={viewModel.periodDisplay}
              maximumValue={data.configGlobalStats.accounts.studentClaimed}
            />
          );
        }}
      />
    </Box>
  );
});
