import {
  AutomatedTransformationImport as PBAutomatedTransformationImport,
  EntityCountTolerance as PBEntityCountTolerance,
  IncidentTolerance as PBIncidentTolerance
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import {
  EditableModelEx,
  EditableStringProperty,
  EditableValuePropertyEx,
  FullyEditableListProperty
} from '@shared/models/editables';
import { computed, makeObservable } from 'mobx';
import { AutomatedTransformationImport, EntityCountTolerance, IncidentTolerance } from '../interfaces';
import { EditableEntityCountTolerance } from './EditableEntityCountTolerance';
import { EditableIncidentTolerance } from './EditableIncidentTolerance';
import { GrpcAutomatedTransformationImport } from './GrpcAutomatedImport';

export class EditableAutomatedTransformationImport
  extends EditableModelEx<PBAutomatedTransformationImport>
  implements AutomatedTransformationImport
{
  private readonly _label: EditableStringProperty<PBAutomatedTransformationImport>;
  private readonly _incidentTolerances: FullyEditableListProperty<
    PBIncidentTolerance,
    IncidentTolerance,
    EditableIncidentTolerance,
    PBAutomatedTransformationImport
  >;
  private readonly _entityCountTolerances: FullyEditableListProperty<
    PBEntityCountTolerance,
    EntityCountTolerance,
    EditableEntityCountTolerance,
    PBAutomatedTransformationImport
  >;
  private readonly _isCompleteData: EditableValuePropertyEx<boolean, PBAutomatedTransformationImport>;

  static createNew() {
    const pb = new PBAutomatedTransformationImport();
    return new EditableAutomatedTransformationImport(new GrpcAutomatedTransformationImport(pb), true);
  }

  constructor(originalAutomatedTransformation: AutomatedTransformationImport, isNew?: boolean) {
    super(originalAutomatedTransformation.toProtobuf(), isNew);

    makeObservable(this);

    this._label = this.addStringField(originalAutomatedTransformation.label, (pb, value) => (pb.label = value), {
      trim: true
    });
    this._incidentTolerances = this.addEditableListField(
      originalAutomatedTransformation.incidentTolerances.map((i) => new EditableIncidentTolerance(i)),
      (pb, values) => (pb.incidentTolerances = values)
    );
    this._entityCountTolerances = this.addEditableListField(
      originalAutomatedTransformation.entityCountTolerances.map((i) => new EditableEntityCountTolerance(i)),
      (pb, values) => (pb.entityTolerances = values)
    );
    this._isCompleteData = this.addValueField(
      originalAutomatedTransformation.isCompleteData,
      (pb, value) => (pb.isCompleteData = value)
    );
  }

  @computed
  get label() {
    return this._label.value;
  }

  set label(value: string) {
    this._label.value = value;
  }

  @computed
  get incidentTolerances() {
    return this._incidentTolerances.values;
  }

  addIncidentTolerance(tolerance: EditableIncidentTolerance) {
    this._incidentTolerances.addItem(tolerance);
  }

  @computed
  get entityCountTolerances() {
    return this._entityCountTolerances.values;
  }

  addEntityCountTolerance(tolerance: EditableEntityCountTolerance) {
    this._entityCountTolerances.addItem(tolerance);
  }

  @computed
  get isCompleteData() {
    return this._isCompleteData.value;
  }

  set isCompleteData(value: boolean) {
    this._isCompleteData.value = value;
  }
}
