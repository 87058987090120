import { BaseLocalizationService } from '@shared/resources/services';
import { Storage } from '@shared/services';

export class WebLocalizationService extends BaseLocalizationService {
  constructor(localStorage: Storage) {
    super(localStorage);

    this.setDefaultLocale(window.navigator.language);
  }
}
