import { AlertService } from '@insights/services';
import { OnboardingQuestion, OnboardingStep } from '@shared/models/onboarding/interfaces';
import { OnboardingQuestionKind } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { DefaultOnboardingVariableResolver, OnboardingVariableResolver } from './OnboardingVariableResolver';

export interface AddOnboardingQuestionViewModel {
  readonly availableQuestions: OnboardingQuestion[];
  selectedQuestionName: string;
  readonly selectedQuestion?: OnboardingQuestion;
  selectedAnswerKind: OnboardingQuestionKind;
  readonly isNewNameSelected: boolean;

  readonly canAdd: boolean;

  readonly variableResolver: OnboardingVariableResolver;
}

export interface AddOnboardingQuestionDialogViewModel {
  readonly data: IPromiseBasedObservable<AddOnboardingQuestionViewModel>;
  readonly canAdd: boolean;

  add(): Promise<void>;
  cancel(): void;
}

class AppAddOnboardingQuestionViewModel implements AddOnboardingQuestionViewModel {
  @observable private _selectedQuestionName = '';
  @observable private _selectedAnswerKind: OnboardingQuestionKind = 'simple-text';

  constructor(
    private readonly _configId: string,
    readonly availableQuestions: OnboardingQuestion[]
  ) {
    makeObservable(this);
  }

  @computed
  get selectedQuestionName() {
    return this._selectedQuestionName;
  }

  set selectedQuestionName(value: string) {
    this._selectedQuestionName = value;
  }

  @computed
  get selectedQuestion() {
    return this.availableQuestions.find((q) => q.templateName === this._selectedQuestionName);
  }

  @computed
  get selectedAnswerKind() {
    return this._selectedAnswerKind;
  }

  set selectedAnswerKind(value: OnboardingQuestionKind) {
    this._selectedAnswerKind = value;
  }

  @computed
  get isNewNameSelected() {
    return this._selectedQuestionName.length > 0 && this.selectedQuestion == null;
  }

  @computed
  get canAdd() {
    return this._selectedQuestionName.length > 0;
  }

  @computed
  get variableResolver() {
    return new DefaultOnboardingVariableResolver(this._configId);
  }
}

export class AppAddOnboardingQuestionDialogViewModel implements AddOnboardingQuestionDialogViewModel {
  @observable private _innerViewModel?: AddOnboardingQuestionViewModel;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _stepName: string,
    private readonly _onSuccess: (step?: OnboardingStep) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<AddOnboardingQuestionViewModel> {
    return fromPromise(this.loadData());
  }

  @computed
  get canAdd() {
    return this._innerViewModel?.canAdd ?? false;
  }

  private async loadData(): Promise<AddOnboardingQuestionViewModel> {
    const templates = await this._onboardingStore.getQuestionTemplates();

    const inner = new AppAddOnboardingQuestionViewModel(this._configId, templates);

    return runInAction(() => (this._innerViewModel = inner));
  }

  async add(): Promise<void> {
    try {
      const step = await this._onboardingStore.createQuestion(
        this._innerViewModel!.selectedQuestionName,
        this._configId,
        this._stepName,
        this._innerViewModel!.selectedAnswerKind
      );
      this._onSuccess(step);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    }
  }

  cancel() {
    this._onCancel();
  }
}
