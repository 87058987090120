import { Typography } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import { forwardRef } from 'react';
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom';

const ReactRouterLinkAdapter = forwardRef<HTMLAnchorElement, ReactRouterLinkProps>((p, ref) => (
  <ReactRouterLink {...p} ref={ref} />
));
ReactRouterLinkAdapter.displayName = 'ReactRouterLinkAdapter';

export function RouterLink(props: LinkProps & ReactRouterLinkProps) {
  return <Link {...props} component={ReactRouterLinkAdapter} />;
}

export interface OptionalRouterLinkProps {
  readonly disabled?: boolean;
}

export function OptionalRouterLink(props: LinkProps & ReactRouterLinkProps & OptionalRouterLinkProps) {
  if (props.disabled) {
    return <Typography variant={props.variant}>{props.children}</Typography>;
  }

  return <Link {...props} component={ReactRouterLinkAdapter} />;
}
