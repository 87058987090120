import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { Memoize } from 'fast-typescript-memoize';
import { BaseModel } from '../../Model';
import { OnboardingStatus } from '../../types';
import { onboardingStatusFromProtobuf } from '../../types/EnumConversion';
import { OnboardingProcess, OnboardingStepSummary, OnboardingText } from '../interfaces';
import { GrpcOnboardingStepSummary } from './GrpcOnboardingStepSummary';
import { GrpcOnboardingText } from './GrpcOnboardingText';

export class GrpcOnboardingProcess extends BaseModel<PB.OnboardingProcess> implements OnboardingProcess {
  constructor(pb: PB.OnboardingProcess) {
    super(pb);
  }

  get id(): string {
    return this._pb.id;
  }

  get configId(): string {
    return this._pb.configId;
  }

  get templateName(): string {
    return this._pb.templateName;
  }

  get isCustomized(): boolean {
    return this._pb.isCustomized;
  }

  @Memoize()
  get description(): OnboardingText[] {
    return this._pb.localizedDescriptions.map((d) => new GrpcOnboardingText(d));
  }

  @Memoize()
  get steps(): OnboardingStepSummary[] {
    return this._pb.steps.map((s) => new GrpcOnboardingStepSummary(s));
  }

  get status(): OnboardingStatus {
    return onboardingStatusFromProtobuf(this._pb.status);
  }

  get clientId(): string {
    return this._pb.clientId;
  }

  get agentId(): string {
    return this._pb.agentId;
  }

  get followerIds(): string[] {
    return this._pb.followerIds;
  }

  get nextTargetDate(): Date | undefined {
    return this._pb.nextTargetDate?.toDate();
  }

  get finalTargetDate(): Date | undefined {
    return this._pb.finalTargetDate?.toDate();
  }
}
