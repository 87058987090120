import { Label } from '@insights/components';
import { SchoolYearConfigurationScreenInfo } from '@insights/viewmodels';
import { Grid2, Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, Fragment } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SchoolYearConfigurationWorkloadManagerThresholdsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  data: SchoolYearConfigurationScreenInfo;
}

export const SchoolYearConfigurationWorkloadManagerThresholds = observer(
  (props: SchoolYearConfigurationWorkloadManagerThresholdsProps) => {
    const { localizationService } = useInsightsServices();
    const { className, sx = [], style, data } = props;
    const strings = localizationService.localizedStrings.insights.views.managedGeneral;
    const configStrings = localizationService.localizedStrings.models.schoolYearConfig;

    return (
      <Stack sx={sx} className={className} style={style} spacing={2}>
        {/* Grade level source */}
        <Stack>
          <Label>{strings.gradeLevelSourceTitle}</Label>
          <Typography>{configStrings.gradeLevelSourceTitle(data.schoolYearConfiguration.gradeLevelSource)}</Typography>
        </Stack>

        <Grid2 container size={{ xs: 12 }}>
          {/* Grade Level Column Title */}
          <Grid2 size={{ xs: 4, xl: 3 }}>
            <Label>{strings.gradeLevelColumnTitle}</Label>
          </Grid2>
          {/* Daily Threshold Column Title */}
          <Grid2 size={{ xs: 4, xl: 3 }}>
            <Label>{strings.dailyThresholdColumnTitle}</Label>
          </Grid2>
          {/* Weekly Threshold Column Title */}
          <Grid2 size={{ xs: 4, xl: 3 }}>
            <Label>{strings.weeklyThresholdColumnTitle}</Label>
          </Grid2>
          {/* Filler */}
          <Grid2 size={{ xs: false, xl: 3 }} />

          {data.thresholds.map((thresholdInfo) => (
            <Fragment key={thresholdInfo.gradeLevel}>
              {/* Grade Level */}
              <Grid2 size={{ xs: 4, xl: 3 }}>
                <Typography>{thresholdInfo.gradeLevel}</Typography>
              </Grid2>
              {/* Daily Threshold */}
              <Grid2 size={{ xs: 4, xl: 3 }}>
                <Typography>{thresholdInfo.dailyThreshold}</Typography>
              </Grid2>
              {/* Weekly Threshold */}
              <Grid2 size={{ xs: 4, xl: 3 }}>
                <Typography>{thresholdInfo.weeklyThreshold}</Typography>
              </Grid2>
              {/* Filler */}
              <Grid2 size={{ xs: false, xl: 3 }} />
            </Fragment>
          ))}
        </Grid2>
      </Stack>
    );
  }
);
