import { SchoolCalendarEditCycleDaysViewModel } from '@insights/viewmodels';
import { SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column } from '../layout';

export interface EditableCycleDayCountProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarEditCycleDaysViewModel;
}

export const EditableCycleDayCount = observer((props: EditableCycleDayCountProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, style, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Column sx={sx} className={className} style={style} horizontalContentAlignment="left">
      <TextField
        type="number"
        required
        label={strings.daysPerCycle}
        value={viewModel.daysPerCycle}
        onChange={(event) => {
          const count = Number(event.target.value);
          if (!Number.isNaN(count)) {
            viewModel.daysPerCycle = count;
          }
        }}
      />
    </Column>
  );
});
