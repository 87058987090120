import { AddAttachmentsImages } from '@shared/resources/images/studyo';
import attachmentCameraImage from '@shared/web/images/assets/studyo/attachment_camera.svg?url';
import attachmentDownloadPlaceholderImage from '@shared/web/images/assets/studyo/attachment_download_placeholder.svg?url';
import attachmentDropboxImage from '@shared/web/images/assets/studyo/attachment_dropbox.svg?url';
import attachmentGlobeImage from '@shared/web/images/assets/studyo/attachment_globe.svg?url';
import attachmentGoogleDriveImage from '@shared/web/images/assets/studyo/attachment_google_drive.svg?url';
import attachmentLinkImage from '@shared/web/images/assets/studyo/attachment_link.svg?url';
import attachmentPaperClipImage from '@shared/web/images/assets/studyo/attachment_paper_clip.svg?url';
import attachmentPaperClipSmallImage from '@shared/web/images/assets/studyo/attachment_paper_clip_small.svg?url';
import attachmentPhotoLibraryImage from '@shared/web/images/assets/studyo/attachment_photo_library.svg?url';

export class WebAddAttachmentsImages implements AddAttachmentsImages {
  get camera() {
    return attachmentCameraImage;
  }

  get dropbox() {
    return attachmentDropboxImage;
  }

  get googleDrive() {
    return attachmentGoogleDriveImage;
  }

  get importPicture() {
    return attachmentPhotoLibraryImage;
  }

  get link() {
    return attachmentLinkImage;
  }

  get paperClip() {
    return attachmentPaperClipImage;
  }

  get paperClipSmall() {
    return attachmentPaperClipSmallImage;
  }

  get baseLink() {
    return attachmentGlobeImage;
  }

  get cloudDownload() {
    return attachmentDownloadPlaceholderImage;
  }
}
