import {
  AutomatedImport,
  ExternalFileSource,
  GrpcAutomatedImport,
  GrpcExternalFileSource,
  GrpcImportData,
  GrpcImportSession,
  GrpcIncident,
  GrpcOperation,
  GrpcSchema,
  GrpcSourceData,
  ImportData,
  ImportSession,
  Incident,
  Operation,
  Schema,
  SourceData,
  SourceFileUploadUrlResponse,
  Transformation
} from '@shared/models/import';
import { IComputedValue, computed } from 'mobx';
import { ImporterTransport } from '../../transports';
import { ImporterStore, SchoolYearConfigurationStore } from '../interfaces';
import { AppBaseStore } from './AppBaseStore';

export class AppImporterStore extends AppBaseStore implements ImporterStore {
  constructor(
    private readonly _transport: ImporterTransport,
    private readonly _schoolStore: SchoolYearConfigurationStore
  ) {
    super('AppImporterStore');
  }

  getImportSessions(configId: string): Promise<ImportSession[]> {
    return this.getMemoizedImportSessions(configId).get();
  }

  getImportSession(sessionId: string, includeData: boolean): Promise<ImportSession> {
    return this.getMemoizedImportSession(sessionId, includeData).get();
  }

  getOperations(): Promise<Operation[]> {
    return this.getMemoizedOperations().get();
  }

  getSchemas(languageCode: string): Promise<Schema[]> {
    return this.getMemoizedSchemas(languageCode).get();
  }

  async createOrUpdateImportSession(session: ImportSession, shouldReturnData: boolean): Promise<ImportSession> {
    const pbSession = session.toProtobuf();
    // Never push the data back to the server, useless bandwidth.
    pbSession.data = [];

    const createdPBSession = await this._transport.createOrUpdateImportSession(pbSession, shouldReturnData);
    return new GrpcImportSession(createdPBSession);
  }

  async deleteImportSession(configId: string, sessionId: string): Promise<void> {
    await this._transport.deleteImportSession(configId, sessionId);
  }

  async uploadData(
    configId: string,
    fileName: string,
    fileLabel: string,
    sessionId: string
  ): Promise<SourceFileUploadUrlResponse> {
    return this._transport.uploadData(configId, fileName, fileLabel, sessionId);
  }

  async transformData(
    mainSource: SourceData,
    lookupSources: SourceData[],
    transformation: Transformation
  ): Promise<SourceData> {
    const pbSources = lookupSources.map((s) => s.toProtobuf()).concat([mainSource.toProtobuf()]);
    const pbTransformation = transformation.toProtobuf();

    const pbUpdatedData = await this._transport.transformData(pbSources, pbTransformation);

    return new GrpcSourceData(pbUpdatedData);
  }

  async importData(
    configId: string,
    sourceSessionId: string,
    data: SourceData,
    isVerificationOnly: boolean,
    isCompleteData: boolean,
    allowedIncidentCodes: string[] = [],
    options: string[] = []
  ): Promise<ImportData> {
    const pbImportData = await this._transport.importData(
      configId,
      sourceSessionId,
      data.toProtobuf(),
      isVerificationOnly,
      isCompleteData,
      allowedIncidentCodes,
      options
    );

    // This transaction can affect school data.
    if (!isVerificationOnly && pbImportData.isSuccessful && !pbImportData.isVerificationOnly) {
      this._schoolStore.invalidate();
      // It also updates the source data's last import time, but if we invalidate this
      // store, the screen displaying import results will refresh before it can display
      // the outcome. The caller can call clear if they want.
    }

    return new GrpcImportData(pbImportData);
  }

  async getExternalFileSources(): Promise<ExternalFileSource[]> {
    const pbSources = await this._transport.fetchExternalFileSources();

    return pbSources.map((pb) => new GrpcExternalFileSource(pb));
  }

  async createOrUpdateExternalFileSource(source: ExternalFileSource): Promise<ExternalFileSource> {
    const pbSource = await this._transport.createOrUpdateExternalFileSource(source.toProtobuf());

    return new GrpcExternalFileSource(pbSource);
  }

  async deleteExternalFileSource(id: string): Promise<void> {
    await this._transport.deleteExternalFileSource(id);
  }

  async getAutomatedImports(configId: string, importSessionId: string) {
    const pbImports = await this._transport.fetchAutomatedImports(configId, importSessionId);

    return pbImports.map((i) => new GrpcAutomatedImport(i));
  }

  async getAutomatedImport(
    configId: string,
    importSessionId: string,
    automatedImportId: string
  ): Promise<AutomatedImport> {
    const pbImport = await this._transport.fetchAutomatedImport(configId, importSessionId, automatedImportId);

    return new GrpcAutomatedImport(pbImport);
  }

  async createOrUpdateAutomatedImport(
    configId: string,
    importSessionId: string,
    automatedImport: AutomatedImport
  ): Promise<AutomatedImport> {
    const pbImport = await this._transport.createOrUpdateAutomatedImport(
      configId,
      importSessionId,
      automatedImport.toProtobuf()
    );

    return new GrpcAutomatedImport(pbImport);
  }

  async deleteAutomatedImport(configId: string, importSessionId: string, automatedImportId: string): Promise<void> {
    await this._transport.deleteAutomatedImport(configId, importSessionId, automatedImportId);
  }

  async executeAutomatedImport(configId: string, importSessionId: string, automatedImportId: string): Promise<void> {
    await this._transport.executeAutomatedImport(configId, importSessionId, automatedImportId, true);
    // This affects imports and their hashes.
    this.invalidate();
  }

  async getIncidentCodes(languageCode: string): Promise<Incident[]> {
    return this.getMemoizedIncidentCodes(languageCode).get();
  }

  private getMemoizedImportSessions = this.memoize(
    (configId: string): IComputedValue<Promise<ImportSession[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const pbSessions = await this._transport.fetchImportSessions(configId);
          return pbSessions.map((pb) => new GrpcImportSession(pb));
        })
      )
  );

  private getMemoizedImportSession = this.memoize(
    (sessionId: string, includeData: boolean): IComputedValue<Promise<ImportSession>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const pbSession = await this._transport.fetchImportSession(sessionId, includeData);
          return new GrpcImportSession(pbSession);
        })
      )
  );

  private getMemoizedOperations = this.memoize(
    (): IComputedValue<Promise<Operation[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const pbOperations = await this._transport.fetchImportOperations();
          return pbOperations.map((pb) => new GrpcOperation(pb));
        })
      )
  );

  private getMemoizedSchemas = this.memoize(
    (languageCode: string): IComputedValue<Promise<Schema[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const pbSchemas = await this._transport.fetchImportSchemas(languageCode);
          return pbSchemas.map((pb) => new GrpcSchema(pb));
        })
      )
  );

  private getMemoizedIncidentCodes = this.memoize(
    (languageCode: string): IComputedValue<Promise<Incident[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const pbIncidents = await this._transport.fetchIncidentCodes(languageCode);
          return pbIncidents.map((pb) => new GrpcIncident(pb));
        })
      )
  );
}
