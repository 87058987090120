import { ExternalAssociationViewModel, ExternalSectionInfo, UnknownExternalSection } from '@insights/viewmodels';
import { MTableToolbar } from '@material-table/core';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/LinkOff';
import { Box, Input, InputAdornment, Popover, SxProps, Typography, styled, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { ExternalSection } from '@shared/models/connectors';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { InsightsMaterialTable } from '../InsightsMaterialTable';
import { SectionName } from '../SectionName';
import { Column } from '../layout';
import { SectionSchedulesSummary } from '../sections';

const TableStateKey = 'EditableExternalSection';

export interface EditableExternalSectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: ExternalAssociationViewModel;
  externalSections: ExternalSectionInfo[];
  onChange: (externalSectionId?: string) => void;
}

export const EditableExternalSection = observer((props: EditableExternalSectionProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, viewModel, externalSections, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;
  const section = viewModel.section;
  const orphanTitle = section == null ? strings.orphanAssociationTitle : '';

  const [popoverTarget, setPopoverTarget] = useState<HTMLDivElement | undefined>(undefined);
  const isOpen = popoverTarget != undefined;

  const theme = useTheme();

  function openPopover(target: HTMLDivElement) {
    setPopoverTarget(target);
  }

  function closePopover() {
    setPopoverTarget(undefined);
  }

  function selectSection(section: ExternalSection | undefined) {
    if (section == null) {
      onChange('');
    } else {
      onChange(section.id);
    }
    closePopover();
  }

  function getExternalSectionDisplayTitle(section?: ExternalSection | UnknownExternalSection) {
    return (
      section &&
      (section === 'unknown'
        ? localizationService.localizedStrings.insights.components.connectors.unknownExternalSection
        : `${section.title} [${section.code}]`)
    );
  }

  return (
    <Root sx={sx} className={className}>
      <Input
        sx={{
          fontSize: theme.typography.fontSize,
          fontWeight: theme.typography.fontWeightLight,
          backgroundColor: isOpen ? '#eee' : undefined
        }}
        readOnly
        value={getExternalSectionDisplayTitle(viewModel.externalSection) ?? strings.none}
        onClick={(event) => {
          if (viewModel.isBroken) {
            if (viewModel.hasAssociation) {
              viewModel.setExternalSection(undefined);
            }
          } else {
            openPopover(event.currentTarget);
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            {viewModel.isBroken ? viewModel.hasAssociation ? <DeleteIcon /> : <></> : <ExpandIcon />}
          </InputAdornment>
        }
        error={viewModel.isBroken}
      />
      <Popover
        open={isOpen}
        anchorEl={popoverTarget}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => closePopover()}
        slotProps={{ paper: { style: { width: '75%' } } }}
      >
        <Column sx={{ px: 1 }}>
          <InsightsMaterialTable
            stateKey={TableStateKey}
            // This is to disable the card contour
            components={{
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>,
              Toolbar: (p) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  {section && (
                    <SectionName
                      sx={{ flex: 1 }}
                      title={section.title}
                      color={section.color}
                      subInformation1={section.importId}
                      subInformation2={section.sectionNumber}
                    />
                  )}
                  {section && <SectionSchedulesSummary schedules={section.schedules} className={'schedules'} />}
                  {viewModel.teachers[0] && (
                    <Typography sx={{ ml: 1 }} variant="h6">
                      {AccountUtils.getDisplayLastFirstName(viewModel.teachers[0], '')}
                    </Typography>
                  )}
                  <MTableToolbar {...p} />
                </Box>
              )
            }}
            title={orphanTitle}
            columns={[
              {
                field: 'section.title',
                title: strings.externalSectionTitle
              },
              {
                field: 'section.code',
                title: strings.externalSectionCode
              },
              {
                field: 'section.group',
                title: strings.externalSectionGroup
              },
              {
                field: 'section.level',
                title: strings.externalSectionLevel
              },
              {
                field: 'section.term',
                title: strings.externalSectionTerm
              },
              {
                field: 'section.id',
                title: strings.externalSectionId
              },
              {
                field: 'section.extraData',
                title: strings.externalSectionExtraData
              }
            ]}
            data={externalSections}
            onRowClick={(e, selectedRow) => selectSection(selectedRow?.section)}
            actions={[
              {
                icon: 'link_off',
                tooltip: strings.detachTooltip,
                isFreeAction: true,
                disabled: viewModel.externalSection == null,
                onClick: () => selectSection(undefined)
              }
            ]}
            options={{
              paging: true,
              pageSize: 8,
              header: true,
              draggable: false,
              search: true,
              searchFieldAlignment: 'left',
              padding: 'dense',
              rowStyle: (rowData: ExternalSectionInfo) => ({
                fontSize: theme.typography.caption.fontSize,
                backgroundColor: rowData.section == viewModel.externalSection ? theme.palette.secondary.light : '#FFF'
              })
            }}
          />
        </Column>
      </Popover>
    </Root>
  );
});

const Root = styled(Column)(({ theme }) => ({
  '.schedules': {
    marginRight: theme.spacing(2)
  }
}));
