import { Message } from '@bufbuild/protobuf';

export interface SerializableModel<TProtobuf extends Message<TProtobuf>> {
  toProtobuf(): TProtobuf;
}

// Can't have "BaseModel<TProtobuf extends Message, TModel extends SerializableModel<TProtobuf> implements TModel".
// Typescript doesn't allow "implements" on dynamic types.
export class BaseModel<TProtobuf extends Message<TProtobuf>> implements SerializableModel<TProtobuf> {
  constructor(protected readonly _pb: TProtobuf) {}

  toProtobuf(): TProtobuf {
    return this._pb.clone();
  }
}
