import * as CPB from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/classroom_pb';
import * as TCPB from '@buf/studyo_studyo.bufbuild_es/studyo/type_classroom_pb';
import { GoogleClassroom } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/classroom_connect';
import { GoogleClassroomTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class GoogleClassroomGrpcTransport extends BaseGrpcTransport implements GoogleClassroomTransport {
  async fetchClassroomExternalAccountDetails(
    externalAccountId: string
  ): Promise<CPB.GetExternalAccountDetailsResponse> {
    console.log('Fetching Classroom external account...');

    const request = new CPB.GetExternalAccountDetailsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(
      GoogleClassroom,
      GoogleClassroom.methods.getExternalAccountDetails,
      request
    );

    console.log('Successfully fetched Classroom external account.');

    return response;
  }

  async createOrUpdateClassroomExternalAccount(
    externalAccountId: string | undefined,
    configId: string,
    accountId: string,
    googleUserId: string,
    googleEmail: string,
    authCode: string
  ): Promise<CPB.CreateOrUpdateExternalAccountResponse> {
    console.log('Creating or updating Google Classroom external account...');

    const request = new CPB.CreateOrUpdateExternalAccountRequest();
    request.externalAccountId = externalAccountId ?? '';
    request.configId = configId;
    request.accountId = accountId;
    request.googleUserId = googleUserId;
    request.googleEmail = googleEmail;
    request.authCode = authCode;

    const response = await this.performRequest(
      GoogleClassroom,
      GoogleClassroom.methods.createOrUpdateExternalAccount,
      request
    );

    console.log('Successfully created or updated Google Classroom external account.');

    return response;
  }

  async updateClassroomExternalAccountSettings(
    externalAccountId: string,
    configId: string,
    matchingExpression: string,
    courseWorkMappings: TCPB.CourseWorkMapping[],
    shouldIgnoreUnmatchedCourseWork: boolean,
    shouldFetchCourseAliases: boolean,
    shouldRemoveMatchedKeyword: boolean
  ): Promise<CPB.UpdateExternalAccountSettingsResponse> {
    console.log('Updating Classroom external account settings...');

    const request = new CPB.UpdateExternalAccountSettingsRequest();
    request.externalAccountId = externalAccountId;
    request.configId = configId;
    request.matchExpression = matchingExpression;
    request.courseWorkMappings = courseWorkMappings;
    request.shouldIgnoreUnmatchedCourseWork = shouldIgnoreUnmatchedCourseWork;
    request.shouldFetchCourseAliases = shouldFetchCourseAliases;
    request.shouldRemoveMatchedKeyword = shouldRemoveMatchedKeyword;

    const response = await this.performRequest(
      GoogleClassroom,
      GoogleClassroom.methods.updateExternalAccountSettings,
      request
    );

    console.log('Successfully updated Classroom external account settings.');

    return response;
  }

  async sendBrokenConnectionEmail(externalAccountId: string, language: string): Promise<void> {
    const request = new CPB.SendBrokenConnectionEmailRequest();
    request.externalAccountId = externalAccountId;
    request.language = language;

    await this.performRequest(GoogleClassroom, GoogleClassroom.methods.sendBrokenConnectionEmail, request);
  }
}
