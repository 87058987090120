import { AccountService, AlertService, NavigationService, SettingsStore } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppOnboardingCommentsViewModel } from './OnboardingCommentsViewModel';
import { AppOnboardingStepViewModel, OnboardingStepViewModel } from './OnboardingStepViewModel';

export interface OnboardingStepScreenViewModel {
  readonly step: IPromiseBasedObservable<OnboardingStepViewModel>;
  readonly isLockedContentVisible: boolean;

  toggleLockedContentVisibility: () => void;
}

export class AppOnboardingStepScreenViewModel implements OnboardingStepScreenViewModel {
  @observable private _changeIteration = 0;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _settings: SettingsStore,
    private readonly _configId: string,
    private readonly _processName: string,
    private readonly _stepName: string
  ) {
    makeObservable(this);
  }

  @computed
  get step(): IPromiseBasedObservable<OnboardingStepViewModel> {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this._changeIteration;
    return fromPromise(this.loadData());
  }

  @computed
  get isLockedContentVisible(): boolean {
    return !this._settings.onboardingPreferences.hideLockedContent;
  }

  @action
  toggleLockedContentVisibility(): void {
    this._settings.onboardingPreferences.hideLockedContent = !this._settings.onboardingPreferences.hideLockedContent;
  }

  private async loadData(): Promise<OnboardingStepViewModel> {
    const [process, step, comments, teachersAndStaffById] = await Promise.all([
      this._onboardingStore.getProcess(this._processName, this._configId),
      this._onboardingStore.getStep(this._stepName, this._configId),
      this._onboardingStore.getStepComments(this._processName, this._stepName, this._configId),
      this._schoolYearConfigurationStore.getTeachersAndStaffById(this._configId, false)
    ]);

    const commentsViewModel = new AppOnboardingCommentsViewModel(
      this._onboardingStore,
      this._accountService,
      this._navigationService,
      this._alertService,
      this._localizationService,
      this._processName,
      process.status,
      this._stepName,
      step.agentId,
      this._configId,
      teachersAndStaffById,
      comments,
      true,
      step.isBlocked,
      () => runInAction(() => this._changeIteration++)
    );

    return new AppOnboardingStepViewModel(
      this._onboardingStore,
      this._accountService,
      this._navigationService,
      this._alertService,
      this._localizationService,
      this._settings,
      this._configId,
      this._processName,
      teachersAndStaffById,
      step,
      commentsViewModel,
      process.status === 'archived'
    );
  }
}
