import { EnvironmentService } from '@shared/services';
import {
  AnalyticsConfigInfo,
  AnalyticsEvent,
  AnalyticsPage,
  AnalyticsService,
  AnalyticsUserInfo
} from '@shared/services/analytics';
import mixpanel from 'mixpanel-browser';

export class WebMixpanelAnalyticsService<TPage extends AnalyticsPage<string>, TEvent extends AnalyticsEvent<string>>
  implements AnalyticsService<TPage, TEvent>
{
  private readonly isMixpanelAvailable: boolean;

  constructor(environmentService: EnvironmentService) {
    this.isMixpanelAvailable = environmentService.mixpanelToken != null;

    if (this.isMixpanelAvailable) {
      mixpanel.init(environmentService.mixpanelToken!, {
        track_pageview: false,
        persistence: 'localStorage'
      });
    }
  }

  setUserInfo(userInfo: AnalyticsUserInfo): void {
    if (this.isMixpanelAvailable) {
      mixpanel.identify(userInfo.userId);
      mixpanel.people.set({
        $email: userInfo.email,
        userName: userInfo.userName
      });
    }
  }

  clearUserInfo(): void {
    if (this.isMixpanelAvailable) {
      mixpanel.reset();
    }
  }

  setConfigInfo(info: AnalyticsConfigInfo): void {
    if (this.isMixpanelAvailable) {
      mixpanel.register(info);
    }
  }

  clearConfigInfo(): void {
    if (this.isMixpanelAvailable) {
      mixpanel.reset();
    }
  }

  trackPage(page: TPage): void {
    if (this.isMixpanelAvailable) {
      mixpanel.track_pageview({ pageName: page.name });
    }
  }

  trackEvent(): void {
    // To avoid ballooning Mixpanel costs, we don't send events to Mixpanel.
  }
}
