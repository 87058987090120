import { Message } from '@bufbuild/protobuf';
import { arrayMoveImmutable } from 'array-move';
import _ from 'lodash';
import { IObservableArray, action, computed, makeObservable, observable } from 'mobx';
import { SerializableModel } from '../Model';
import { BaseEditableArrayProperty, BaseEditableProperty } from './BaseEditableProperties';
import { ChangeablePropertyEx } from './ChangeableProperty';
import { EditableModelEx } from './EditableModels';

export class EditableChildPropertyEx<
  TProtobuf extends Message<TProtobuf>,
  TModel extends SerializableModel<TProtobuf>,
  TEditable extends EditableModelEx<TProtobuf> & TModel,
  THost
> implements BaseEditableProperty<TModel, THost>
{
  @observable private _editable?: TEditable;
  @observable private _changeIteration = 0;

  constructor(
    public readonly originalValue: TModel,
    private readonly _createEditable: (model: TModel) => TEditable,
    private readonly _apply: (host: THost, value: TProtobuf) => void
  ) {
    makeObservable(this);
  }

  @computed
  get value(): TModel {
    return this._editable ?? this.originalValue;
  }

  set value(value: TModel) {
    let editable = value as unknown as TEditable;

    if (!editable.isEditable) {
      editable = this._createEditable(value);
    }

    this._editable = editable;
    this._changeIteration++;
  }

  @computed
  get isChanged() {
    // If the model was materialized into an editable, we assume it changed.
    return this._editable !== undefined;
  }

  @computed
  get changeIteration() {
    return this._changeIteration;
  }

  @action
  getEditableValue(): TEditable {
    if (this._editable === undefined) {
      this._editable = this._createEditable(this.originalValue);
    }

    return this._editable;
  }

  applyTo(host: THost): void {
    if (this._editable !== undefined) {
      this._apply(host, this._editable.toProtobuf());
    }
  }

  @action
  reset() {
    this._editable = undefined;
  }

  @action
  copyFrom(other: ChangeablePropertyEx<THost>) {
    const realOther = other as EditableChildPropertyEx<TProtobuf, TModel, TEditable, THost>;

    if (realOther == null) {
      throw new Error('Invalid operation. Trying to apply property from a different type.');
    }

    this.value = realOther.value;
  }
}

export class EditableChildNullablePropertyEx<
  TProtobuf extends Message<TProtobuf>,
  TModel extends SerializableModel<TProtobuf>,
  TEditable extends EditableModelEx<TProtobuf> & TModel,
  THost
> implements BaseEditableProperty<TModel | undefined, THost>
{
  @observable private _editable: TEditable;
  @observable private _isChanged = false;
  @observable private _isDisabled = false;
  @observable private _changeIteration = 0;

  constructor(
    private readonly _originalValue: TModel | undefined,
    private readonly _createEditable: (model: TModel | undefined) => TEditable,
    private readonly _apply: (host: THost, value: TProtobuf | undefined) => void,
    private readonly _isOriginalDisabled: boolean
  ) {
    makeObservable(this);
    this._editable = _createEditable(_originalValue);
    this._isDisabled = _isOriginalDisabled;
  }

  @computed
  get value(): TModel | undefined {
    return this._isDisabled ? undefined : this._editable;
  }

  set value(model: TModel | undefined) {
    this._isDisabled = model == null;
    this._editable = this._createEditable(model);
    this._isChanged = model != this._originalValue;
    this._changeIteration++;
  }

  @computed
  get editableValue(): TEditable {
    return this._editable;
  }

  @computed
  get isChanged() {
    // If disabled and originally disabled, must not consider new value.
    return (
      this._isDisabled != this._isOriginalDisabled ||
      (!this._isDisabled && (this._isChanged || this._editable.hasChanges))
    );
  }

  @computed
  get changeIteration() {
    return this._changeIteration;
  }

  @computed
  get isDisabled() {
    return this._isDisabled;
  }

  set isDisabled(value: boolean) {
    this._isDisabled = value;
  }

  applyTo(host: THost): void {
    if (this.isChanged) {
      this._apply(host, this._isDisabled ? undefined : this._editable.toProtobuf());
    }
  }

  @action
  reset() {
    this._editable = this._createEditable(this._originalValue);
    this._isChanged = false;
    this._isDisabled = this._isOriginalDisabled;
  }

  @action
  copyFrom(other: ChangeablePropertyEx<THost>) {
    const realOther = other as EditableChildNullablePropertyEx<TProtobuf, TModel, TEditable, THost>;

    if (realOther == null) {
      throw new Error('Invalid operation. Trying to apply property from a different type.');
    }

    this.value = realOther.value;
    this.isDisabled = realOther.isDisabled;
  }
}

// It's very important that child lists of models don't create editable instances of every child.
// This property helps deal with models not yet transformed in their editable form.
export class EditableListPropertyEx<
  TProtobuf extends Message<TProtobuf>,
  TModel extends SerializableModel<TProtobuf>,
  TEditable extends EditableModelEx<TProtobuf> & TModel,
  THost
> implements BaseEditableArrayProperty<TModel, THost>
{
  // Each of these values can either be a regular model, or an editable version.
  private readonly _values: IObservableArray<TModel>;
  @observable private _isSorted = false;

  constructor(
    private readonly _originalChildren: TModel[],
    private readonly _createEditable: (model: TModel, isNew: boolean) => TEditable,
    private readonly _apply: (host: THost, values: TProtobuf[]) => void
  ) {
    makeObservable(this);
    this._values = observable.array(_originalChildren);
  }

  @computed
  get values(): TModel[] {
    return this._values.filter((value) => !this.itemShouldBeDeleted(value));
  }

  @computed
  get allValues(): TModel[] {
    return this._values;
  }

  @computed
  get deletedValues(): TModel[] {
    return this._values.filter((value) => this.itemShouldBeDeleted(value));
  }

  @action
  getEditableValue(item: TModel): TEditable {
    const index = this._values.indexOf(item);

    if (index < 0) {
      throw new Error('Invalid operation. Could not find model to be edited.');
    }

    return this.ensureEditable(item, index);
  }

  @action
  getEditableValueByIndex(index: number): TEditable {
    if (index < 0 || index >= this._values.length) {
      throw new Error('Invalid index.');
    }

    return this.ensureEditable(this._values[index], index);
  }

  /**
   * Get an editable model for every value. Use this method with care.
   * @param includeDeleted
   */
  @action
  getEditableValues(includeDeleted = false): TEditable[] {
    // Sometimes we don't have a choice and must edit every item
    // Avoid an if in the loop.
    if (!includeDeleted) {
      return _.compact(
        this._values.map((item, index) => {
          const editable = this.ensureEditable(item, index);

          if (editable.shouldBeDeleted) {
            return undefined;
          }

          return editable;
        })
      );
    } else {
      return this._values.map((item, index) => this.ensureEditable(item, index));
    }
  }

  @action
  editValidValues(
    filter: (item: TModel) => boolean,
    editAction: (editableItem: TEditable) => void,
    includeDeleted = false
  ) {
    this._values.forEach((item, index) => {
      if ((includeDeleted || !this.itemShouldBeDeleted(item)) && filter(item)) {
        editAction(this.ensureEditable(item, index));
      }
    });
  }

  @action
  editAllValidValues(editAction: (editableItem: TEditable) => void, includeDeleted = false) {
    this._values.forEach((item, index) => {
      if (includeDeleted || !this.itemShouldBeDeleted(item)) {
        editAction(this.ensureEditable(item, index));
      }
    });
  }

  @computed
  get isChanged() {
    // Deleted items report "hasChanges" as true, but not created items.
    return this._isSorted || this._values.some((value) => this.itemIsChanged(value));
  }

  @computed
  get changeIteration() {
    return this.values.map((value) => this.itemChangeIteration(value)).reduce((total, current) => total + current, 0);
  }

  @action
  addItem(item: TModel): void {
    let editable = item as unknown as TEditable;

    if (!editable.isEditable) {
      editable = this._createEditable(item, true);
    }

    if (!editable.shouldBeCreated) {
      throw new Error('Invalid operation. Cannot add an item not marked as "should be created".');
    }

    this._values.push(editable);
  }

  @action
  sortBy(selector: (item: TModel) => string | number) {
    this._values.replace(_.sortBy(this._values, selector));

    // Because sorting does not affect items, we must remember this change. For now,
    // we don't care if it actually changed something.
    this._isSorted = true;
  }

  applyTo(host: THost): void {
    const pbs = this._values.filter((value) => !this.itemShouldBeDeleted(value)).map((value) => value.toProtobuf());
    this._apply(host, pbs);
  }

  @action
  reset(): void {
    this._values.replace(this._originalChildren);
    this._isSorted = false;
  }

  @action
  copyFrom(other: ChangeablePropertyEx<THost>) {
    const realOther = other as EditableListPropertyEx<TProtobuf, TModel, TEditable, THost>;

    // Replace all values, deleted or not.
    this._values.replace(realOther._values);
    // The change might only be the sorting.
    this._isSorted = realOther._isSorted;
  }

  itemShouldBeDeleted(item: TModel): boolean {
    const editable = item as unknown as TEditable;

    if (!editable.isEditable) {
      return false;
    }

    return editable.shouldBeDeleted;
  }

  itemIsChanged(item: TModel): boolean {
    const editable = item as unknown as TEditable;

    if (!editable.isEditable) {
      return false;
    }

    return editable.hasChanges || editable.shouldBeCreated;
  }

  itemChangeIteration(item: TModel): number {
    const editable = item as unknown as TEditable;

    if (!editable.isEditable) {
      return 0;
    }

    return editable.changeIteration;
  }

  @action
  private ensureEditable(item: TModel, index: number): TEditable {
    let editable = item as unknown as TEditable;

    if (!editable.isEditable) {
      editable = this._createEditable(item, false);
      this._values[index] = editable;
    }

    return editable;
  }
}

// But sometimes, live edition of lists require that all children already be editable.
// Use this property with care.
export class FullyEditableListProperty<
  TProtobuf extends Message<TProtobuf>,
  TModel extends SerializableModel<TProtobuf>,
  TEditable extends EditableModelEx<TProtobuf> & TModel,
  THost
> implements BaseEditableArrayProperty<TModel, THost>
{
  // Each of these values can either be a regular model, or an editable version.
  private readonly _values: IObservableArray<TEditable>;
  @observable private _isReordered = false;

  constructor(
    private readonly _originalEditableChildren: TEditable[],
    private readonly _apply: (host: THost, values: TProtobuf[]) => void
  ) {
    makeObservable(this);
    this._values = observable.array(_originalEditableChildren);
  }

  @computed
  get values(): TEditable[] {
    return this._values.filter((value) => !value.shouldBeDeleted);
  }

  @computed
  get allValues(): TEditable[] {
    return this._values;
  }

  @computed
  get deletedValues(): TEditable[] {
    return this._values.filter((value) => value.shouldBeDeleted);
  }

  @computed
  get isChanged() {
    // Deleted items report "hasChanges" as true, but not created items.
    return this._isReordered || this._values.some((value) => value.hasChanges);
  }

  @computed
  get changeIteration() {
    return this.values.map((value) => value.changeIteration).reduce((total, current) => total + current, 0);
  }

  @action
  addItem(item: TEditable): void {
    if (!item.shouldBeCreated) {
      throw new Error('Invalid operation. Cannot add an item not marked as "should be created".');
    }

    this._values.push(item);
  }

  @action
  moveItem(oldIndex: number, newIndex: number, ignoreDeletedItems = true) {
    if (ignoreDeletedItems) {
      this._values.forEach((item, index) => {
        if (item.shouldBeDeleted) {
          if (oldIndex >= index) {
            oldIndex++;
          }
          if (newIndex >= index) {
            newIndex++;
          }
        }
      });
    }

    this._values.replace(arrayMoveImmutable(this._values, oldIndex, newIndex));

    // Because moving items does not actually affect items, we must remember this change.
    this._isReordered = true;
  }

  @action
  swapItems(firstIndex: number, secondIndex: number, ignoreDeletedItems = true) {
    if (ignoreDeletedItems) {
      this._values.forEach((item, index) => {
        if (item.shouldBeDeleted) {
          if (firstIndex >= index) {
            firstIndex++;
          }
          if (secondIndex >= index) {
            secondIndex++;
          }
        }
      });
    }

    this._values.replace(
      this._values.map((item, index) =>
        index == firstIndex ? this._values[secondIndex] : index == secondIndex ? this._values[firstIndex] : item
      )
    );

    // Because moving items does not actually affect items, we must remember this change.
    this._isReordered = true;
  }

  @action
  sortBy(selector: (item: TModel) => string | number) {
    this._values.replace(_.sortBy(this._values, selector));

    // Because moving items does not actually affect items, we must remember this change.
    // We don't care if it actually changed something.
    this._isReordered = true;
  }

  applyTo(host: THost): void {
    const pbs = this._values.filter((value) => !value.shouldBeDeleted).map((value) => value.toProtobuf());
    this._apply(host, pbs);
  }

  @action
  reset(): void {
    this._values.replace(this._originalEditableChildren);
    this._values.forEach((value) => value.resetChanges());
    this._isReordered = false;
  }

  @action
  copyFrom(other: ChangeablePropertyEx<THost>) {
    const realOther = other as FullyEditableListProperty<TProtobuf, TModel, TEditable, THost>;

    // Replace all values, deleted or not.
    this._values.replace(realOther._values);
    // The change might only be the order.
    this._isReordered = realOther._isReordered;
  }
}
