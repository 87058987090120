import { Color } from './types';

export const CourseTextColor = 'white';
export const CourseUndefinedColor = '#C7C7C7';

// Task Highlight colors
export const TaskActiveColor = 'rgb(126, 210.885, 32.895)';
export const TaskTodayColor = 'rgb(245, 166, 35)';
export const TaskLateColor = 'rgb(208, 2, 27)';

export const SectionColors = new Map<Color, string>([
  ['orange', 'rgb(244, 142, 50)'],
  ['royal-blue', 'rgb(56, 104, 203)'],
  ['mauve', 'rgb(154, 79, 215)'],
  ['violet', 'rgb(174, 40, 154)'],
  ['bright-green', 'rgb(65, 171, 42)'],
  ['lime-green', 'rgb(159, 204, 42)'],
  ['leaf-green', 'rgb(78, 124, 26)'],
  ['terracotta', 'rgb(174, 82, 57)'],
  ['olive', 'rgb(170, 168, 90)'],
  ['bright-red', 'rgb(209, 15, 72)'],
  ['pale-blue', 'rgb(91, 189, 245)'],
  ['kaki', 'rgb(201, 144, 38)'],
  ['dark-red', 'rgb(156, 9, 9)'],
  ['teal', 'rgb(0, 170, 142)'],
  ['bright-blue', 'rgb(0, 133, 244)'],
  ['bright-pink', 'rgb(246, 66, 154)'],
  ['pale-pink', 'rgb(255, 173, 253)'],
  ['lavender', 'rgb(169, 175, 252)'],
  ['peach', 'rgb(255, 170, 170)'],
  ['dark-blue', 'rgb(0, 39, 186)'],
  ['light-bmgray', 'rgb(141, 141, 141)'],
  ['medium-bmgray', 'rgb(109, 109, 109)'],
  ['darker-bmgray', 'rgb(94, 94, 94)'],
  ['darkest-bmgray', 'rgb(66, 66, 66)'],
  ['yellow', 'rgb(255, 211, 59)']
]);

export const SectionShadowColors = new Map<Color, string>([
  ['orange', 'rgb(235, 111, 34)'],
  ['royal-blue', 'rgb(33, 77, 183)'],
  ['mauve', 'rgb(122, 60, 199)'],
  ['violet', 'rgb(145, 29, 123)'],
  ['bright-green', 'rgb(35, 143, 30)'],
  ['lime-green', 'rgb(128, 185, 37)'],
  ['leaf-green', 'rgb(50, 93, 16)'],
  ['terracotta', 'rgb(144, 54, 35)'],
  ['olive', 'rgb(141, 139, 62)'],
  ['bright-red', 'rgb(167, 14, 56)'],
  ['pale-blue', 'rgb(56, 165, 240)'],
  ['kaki', 'rgb(179, 113, 27)'],
  ['dark-red', 'rgb(124, 5, 5)'],
  ['teal', 'rgb(0, 142, 110)'],
  ['bright-blue', 'rgb(0, 105, 239)'],
  ['bright-pink', 'rgb(239, 50, 124)'],
  ['pale-pink', 'rgb(253, 147, 252)'],
  ['lavender', 'rgb(140, 148, 252)'],
  ['peach', 'rgb(255, 142, 142)'],
  ['dark-blue', 'rgb(0, 19, 111)'],
  ['light-bmgray', 'rgb(109, 109, 109)'],
  ['medium-bmgray', 'rgb(78, 78, 78)'],
  ['darker-bmgray', 'rgb(64, 64, 64)'],
  ['darkest-bmgray', 'rgb(41, 41, 41)'],
  ['yellow', 'rgb(240, 200, 30)']
]);
