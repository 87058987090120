import { Storage } from '@shared/services';
import { AppBaseStore, BaseStore } from '@shared/services/stores';
import { Dictionary, chain } from 'lodash';
import { makeObservable, observable, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';

const PageSizeStorageKey = 'TablePreferencesPageSize';
const SortingStorageKey = 'TablePreferencesSorting';

interface TableStateSorting {
  columnId: number;
  direction: 'asc' | 'desc';
}

export interface TablePreferences extends BaseStore {
  initialize: () => Promise<void>;

  getPageSize: (tableKey: string) => number | undefined;
  savePageSize: (tableKey: string, pageSize: number) => Promise<void>;

  getCurrentPage: (tableKey: string) => number | undefined;
  saveCurrentPage: (tableKey: string, currentPage: number) => Promise<void>;

  getSorting: (tableKey: string) => { columnId: number; direction: 'asc' | 'desc' } | undefined;
  saveSorting: (tableKey: string, sorting: { columnId: number; direction: 'asc' | 'desc' }) => Promise<void>;

  getSearchText: (tableKey: string) => string | undefined;
  saveSearchText: (tableKey: string, searchText: string) => Promise<void>;
}

export class AppTablePreferences extends AppBaseStore implements TablePreferences {
  @observable _tableStateCurrentPageMap = new Map<string, number>();
  @observable _tableStateSearchTextMap = new Map<string, string>();
  @observable _tableStatePageSizeMap = new Map<string, Dictionary<number>>();
  @observable _tableStateSortingMap = new Map<string, Dictionary<TableStateSorting>>();

  constructor(private readonly _storage: Storage) {
    super(`AppTablePrefences`);
    makeObservable(this);
  }

  async initialize(): Promise<void> {
    await this.loadFromStorage();
  }

  getPageSize = computedFn((tableKey: string): number | undefined => {
    const pageSizes = this._tableStatePageSizeMap.get(PageSizeStorageKey);

    return pageSizes?.[tableKey];
  });

  async savePageSize(tableKey: string, pageSize: number): Promise<void> {
    const pageSizes = this._tableStatePageSizeMap.get(PageSizeStorageKey) ?? {};
    runInAction(() => {
      pageSizes[tableKey] = pageSize;
      this._tableStatePageSizeMap.set(PageSizeStorageKey, pageSizes);
    });

    const persistedPageSizes = (await this._storage.get<Dictionary<number>>(PageSizeStorageKey)) ?? {};
    persistedPageSizes[tableKey] = pageSize;
    await this._storage.set(PageSizeStorageKey, persistedPageSizes);

    this.invalidate();
  }

  getCurrentPage = computedFn((tableKey: string): number | undefined => {
    return this._tableStateCurrentPageMap.get(tableKey);
  });

  async saveCurrentPage(tableKey: string, currentPage: number): Promise<void> {
    runInAction(() => this._tableStateCurrentPageMap.set(tableKey, currentPage));
    this.invalidate();
    return Promise.resolve();
  }

  getSorting = computedFn((tableKey: string): { columnId: number; direction: 'asc' | 'desc' } | undefined => {
    const sortings = this._tableStateSortingMap.get(SortingStorageKey);

    return sortings?.[tableKey];
  });

  async saveSorting(tableKey: string, sorting: TableStateSorting): Promise<void> {
    const sortings = this._tableStateSortingMap.get(SortingStorageKey) ?? {};
    runInAction(() => {
      sortings[tableKey] = sorting;
      this._tableStateSortingMap.set(SortingStorageKey, sortings);
    });

    const persistedSortings = (await this._storage.get<Dictionary<string>>(SortingStorageKey)) ?? {};
    persistedSortings[tableKey] = JSON.stringify(sorting);
    await this._storage.set(SortingStorageKey, persistedSortings);

    this.invalidate();
  }

  getSearchText = computedFn((tableKey: string): string | undefined => {
    return this._tableStateSearchTextMap.get(tableKey);
  });

  async saveSearchText(tableKey: string, searchText: string): Promise<void> {
    runInAction(() => this._tableStateSearchTextMap.set(tableKey, searchText));
    this.invalidate();
    return Promise.resolve();
  }

  async clear() {
    await Promise.all([
      this.clearTableStateCurrentPages(),
      this.clearTableStatePageSizes(),
      this.clearTableStateSearchTexts(),
      this.clearTableStateSortings()
    ]);

    await super.clear();
  }

  private async clearTableStatePageSizes(): Promise<void> {
    runInAction(() => this._tableStatePageSizeMap.clear());
    await this._storage.delete(PageSizeStorageKey);
  }

  private async clearTableStateCurrentPages(): Promise<void> {
    runInAction(() => this._tableStateCurrentPageMap.clear());
    return Promise.resolve();
  }

  private async clearTableStateSortings(): Promise<void> {
    runInAction(() => this._tableStateSortingMap.clear());
    await this._storage.delete(SortingStorageKey);
    return Promise.resolve();
  }

  private async clearTableStateSearchTexts(): Promise<void> {
    runInAction(() => this._tableStateSearchTextMap.clear());
    return Promise.resolve();
  }

  private async loadFromStorage(): Promise<void> {
    const pageSizes = (await this._storage.get<Dictionary<number>>(PageSizeStorageKey)) ?? {};
    runInAction(() => this._tableStatePageSizeMap.set(PageSizeStorageKey, pageSizes));

    const sortings = (await this._storage.get<Dictionary<string>>(SortingStorageKey)) ?? {};
    runInAction(() => {
      const mappedSortings = chain(sortings)
        .mapValues((v) => JSON.parse(v) as TableStateSorting)
        .value();

      this._tableStateSortingMap.set(SortingStorageKey, mappedSortings);
    });
  }
}
