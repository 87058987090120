export enum ScreenType {
  extraLarge = 5,
  large = 4,
  medium = 3,
  small = 2,
  extraSmall = 1
}

export type ScreenOrientation = 'landscape' | 'portrait';

export interface ScreenService {
  readonly screenType: ScreenType;
  readonly orientation: ScreenOrientation;
}
