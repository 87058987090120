import {
  AutomatedImport as PBAutomatedImport,
  AutomatedImportResult as PBAutomatedImportResult,
  AutomatedTransformationImport as PBAutomatedTransformationImport,
  AutomatedTransformationImportResult as PBAutomatedTransformationImportResult,
  EntityCountTolerance as PBEntityCountTolerance,
  EntityListResult as PBEntityListResult,
  IncidentTolerance as PBIncidentTolerance
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { BaseModel } from '@shared/models/Model';
import { Time } from '@shared/models/types';
import { dayOfWeekFromProtobuf, entityListKindFromProtobuf } from '@shared/models/types/EnumConversion';
import {
  AutomatedImport,
  AutomatedImportResult,
  AutomatedTransformationImport,
  AutomatedTransformationImportResult,
  EntityCountTolerance,
  EntityListResult,
  IncidentTolerance
} from '../interfaces';
import { GrpcIncident } from './GrpcIncident';

export class GrpcEntityCountTolerance extends BaseModel<PBEntityCountTolerance> implements EntityCountTolerance {
  constructor(pb: PBEntityCountTolerance) {
    super(pb);
  }

  get listKind() {
    return entityListKindFromProtobuf(this._pb.listKind);
  }

  get minimumCount() {
    return this._pb.minimumCount;
  }

  get maximumCount() {
    return this._pb.maximumCount;
  }
}

export class GrpcIncidentTolerance extends BaseModel<PBIncidentTolerance> implements IncidentTolerance {
  constructor(pb: PBIncidentTolerance) {
    super(pb);
  }

  get code() {
    return this._pb.code;
  }

  get minimumCount() {
    return this._pb.minimumCount;
  }

  get maximumCount() {
    return this._pb.maximumCount;
  }

  get incidentText() {
    return this._pb.incidentText;
  }
}

export class GrpcAutomatedTransformationImport
  extends BaseModel<PBAutomatedTransformationImport>
  implements AutomatedTransformationImport
{
  constructor(pb: PBAutomatedTransformationImport) {
    super(pb);
  }

  get label() {
    return this._pb.label;
  }

  get incidentTolerances() {
    return this._pb.incidentTolerances.map((t) => new GrpcIncidentTolerance(t));
  }

  get entityCountTolerances() {
    return this._pb.entityTolerances.map((t) => new GrpcEntityCountTolerance(t));
  }

  get isCompleteData() {
    return this._pb.isCompleteData;
  }
}

export class GrpcEntityListResult extends BaseModel<PBEntityListResult> implements EntityListResult {
  constructor(pb: PBEntityListResult) {
    super(pb);
  }

  get listKind() {
    return entityListKindFromProtobuf(this._pb.listKind);
  }

  get entityCount() {
    return this._pb.entityCount;
  }

  get incidents() {
    return this._pb.incidents.map((i) => new GrpcIncident(i));
  }
}

export class GrpcAutomatedTransformationResult
  extends BaseModel<PBAutomatedTransformationImportResult>
  implements AutomatedTransformationImportResult
{
  constructor(pb: PBAutomatedTransformationImportResult) {
    super(pb);
  }

  get label() {
    return this._pb.label;
  }

  get startTime() {
    return this._pb.startTime!.toDate();
  }

  get endTime() {
    return this._pb.endTime!.toDate();
  }

  get isSuccessful() {
    return this._pb.isSuccessful;
  }

  get globalIncidents() {
    return this._pb.globalIncidents.map((i) => new GrpcIncident(i));
  }

  get entityListResults() {
    return this._pb.entityListResults.map((r) => new GrpcEntityListResult(r));
  }

  get isSkipped() {
    return this._pb.isSkipped;
  }
}

export class GrpcAutomatedImportResult extends BaseModel<PBAutomatedImportResult> implements AutomatedImportResult {
  constructor(pb: PBAutomatedImportResult) {
    super(pb);
  }

  get startTime() {
    return this._pb.startTime!.toDate();
  }

  get endTime() {
    return this._pb.endTime!.toDate();
  }

  get isFullySuccessful() {
    return this._pb.isFullySuccessful;
  }

  get transformationResults() {
    return this._pb.transformationResults.map((r) => new GrpcAutomatedTransformationResult(r));
  }
}

export class GrpcAutomatedImport extends BaseModel<PBAutomatedImport> implements AutomatedImport {
  constructor(pb: PBAutomatedImport) {
    super(pb);
  }

  get id() {
    return this._pb.id;
  }

  get configId() {
    return this._pb.configId;
  }

  get importSessionId() {
    return this._pb.importSessionId;
  }

  get name() {
    return this._pb.name;
  }

  get days() {
    return this._pb.days.map((day) => dayOfWeekFromProtobuf(day));
  }

  get time() {
    return this._pb.time != null ? new Time(this._pb.time) : undefined;
  }

  get transformations() {
    return this._pb.transformations.map((t) => new GrpcAutomatedTransformationImport(t));
  }

  get history() {
    return this._pb.history.map((r) => new GrpcAutomatedImportResult(r));
  }
}
