import { ScreenType } from '@shared/services';
import { WebScreenService } from '@shared/web/services';
import { autorun } from 'mobx';
import { SettingsStore } from './SettingsStore';

export class InsightsWebScreenService extends WebScreenService {
  constructor(private readonly _settingsStore: SettingsStore) {
    super();
  }

  onInit() {
    super.onInit();

    autorun(
      () =>
        (this._settingsStore.sidenavCollapsed =
          this.orientation === 'portrait' || this.screenType < ScreenType.extraLarge)
    );
  }
}
