import { CreateConfigStrings, EnglishCreateConfigStrings, FrenchCreateConfigStrings } from './CreateConfigStrings';
import { EnglishLandingScreenStrings, FrenchLandingScreenStrings, LandingScreenStrings } from './LandingScreenStrings';
import {
  EnglishLearnAboutTodayStrings,
  FrenchLearnAboutTodayStrings,
  LearnAboutTodayStrings
} from './LearnAboutTodayStrings';
import { EnglishLoginStrings, FrenchLoginStrings, LoginStrings } from './LoginStrings';
import { EnglishLogoutStrings, FrenchLogoutStrings, LogoutStrings } from './LogoutStrings';
import { EnglishUseCodeStrings, FrenchUseCodeStrings, UseCodeStrings } from './UseCodeStrings';

export interface OnboardingStrings {
  readonly createConfig: CreateConfigStrings;
  readonly landing: LandingScreenStrings;
  readonly learnAboutToday: LearnAboutTodayStrings;
  readonly login: LoginStrings;
  readonly logout: LogoutStrings;
  readonly useCode: UseCodeStrings;
}

export const EnglishOnboardingStrings: OnboardingStrings = {
  createConfig: EnglishCreateConfigStrings,
  landing: EnglishLandingScreenStrings,
  learnAboutToday: EnglishLearnAboutTodayStrings,
  login: EnglishLoginStrings,
  logout: EnglishLogoutStrings,
  useCode: EnglishUseCodeStrings
};

export const FrenchOnboardingStrings: OnboardingStrings = {
  createConfig: FrenchCreateConfigStrings,
  landing: FrenchLandingScreenStrings,
  learnAboutToday: FrenchLearnAboutTodayStrings,
  login: FrenchLoginStrings,
  logout: FrenchLogoutStrings,
  useCode: FrenchUseCodeStrings
};
