import { AlertService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { isDemoError } from '@shared/services/stores/implementations/DemoSchoolInterceptor';
import { chain } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';

export interface EditViewModelBase<TFields extends string> {
  readonly isSubmitting: boolean;
  readonly hasErrors: boolean;
  readonly hasChanges: boolean;

  getFieldValue: (field: TFields) => unknown;
  setFieldValue: (field: TFields, value: unknown) => void;
  getFieldError: (field: TFields) => string | undefined;

  save: () => Promise<void>;
  cancel: () => Promise<void>;
}

export abstract class AppEditViewModelBase<TFields extends string> implements EditViewModelBase<TFields> {
  @observable private _errors: Record<string, string | undefined> | undefined;
  @observable private _isSubmitting = false;

  protected constructor(
    protected readonly _alertService: AlertService,
    protected readonly _localizationService: LocalizationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  abstract get hasChanges(): boolean;

  @computed
  get hasErrors(): boolean {
    return this._errors != null ? chain(Object.values(this._errors)).compact().value().length > 0 : false;
  }

  @computed
  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  getFieldValue = computedFn((field: TFields): unknown => this.getFieldValueCore(field));
  setFieldValue = (field: TFields, value: unknown): void => this.setFieldValueCore(field, value);

  getFieldError = computedFn((field: TFields): string | undefined => {
    return this._errors?.[field];
  });

  async save(): Promise<void> {
    this.validateFields();

    if (this.hasErrors) {
      return;
    }

    try {
      runInAction(() => (this._isSubmitting = true));

      await this.saveCore();

      this._onSuccess();
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.edit;
      await this._alertService.showMessage({
        message: isDemoError(error as Error) ? strings.demoErrorMessage(error as Error) : strings.saveErrorMessage
      });
    } finally {
      runInAction(() => (this._isSubmitting = false));
    }
  }

  async cancel(): Promise<void> {
    if (this.isSubmitting) {
      return;
    }

    let canClose = true;

    if (this.hasChanges) {
      const result = await this._alertService.showConfirmation({
        message: this._localizationService.localizedStrings.insights.viewModels.edit.pendingChangesMessage,
        okButtonCaption: this._localizationService.localizedStrings.insights.viewModels.edit.discardButtonCaption,
        cancelButtonCaption: this._localizationService.localizedStrings.insights.viewModels.edit.cancelButtonCaption
      });

      canClose = result != 'cancelled';
    }

    if (canClose) {
      this._onCancel();
    }
  }

  protected abstract getFieldValueCore(field: TFields): unknown;
  protected abstract setFieldValueCore(field: TFields, value: unknown): void;
  protected abstract saveCore(): Promise<void>;
  protected abstract validateFields(): void;

  @action
  protected setError(field: TFields, error: string | undefined) {
    this._errors = { ...this._errors, [field]: error };
  }

  @action
  protected useIsSubmitting() {
    this._isSubmitting = true;

    return () => runInAction(() => (this._isSubmitting = false));
  }
}
