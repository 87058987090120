export interface LearnAboutTodayStrings {
  readonly subtitle: string;
  readonly openToday: string;
  readonly logout: string;
  readonly goBack: string;
  readonly url: string;
}

export const EnglishLearnAboutTodayStrings: LearnAboutTodayStrings = {
  subtitle:
    'Studyo Go is our new task planning app for individuals. As with Studyo, you can add your school schedule and plan your tasks and time.',
  openToday: 'Open Studyo Go!',
  logout: 'Log out',
  goBack: 'Go back',
  url: 'https://go.studyo.app'
};

export const FrenchLearnAboutTodayStrings: LearnAboutTodayStrings = {
  subtitle:
    'Studyo Go est notre nouvelle application de planification de tâches pour individus. Comme avec Studyo, vous pourrez créer votre horaire, planifier vos tâches et gérer votre temps.',
  openToday: 'Lancez Studyo Go!',
  logout: 'Déconnexion',
  goBack: 'Retour',
  url: 'https://go.studyo.app'
};
