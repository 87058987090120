import { MaterialTableData } from '@insights/models';
import MaterialTable, { Action, MaterialTableProps, OrderByCollection } from '@material-table/core';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../UseInsightsServicesHook';

export interface InsightsAction<TRowData extends MaterialTableData> extends Action<TRowData> {
  key?: string;
}

export interface TableRef {
  onAllSelected: (checked: boolean) => void;
}

export interface InsightsMaterialTableProps<TRowData extends MaterialTableData> extends MaterialTableProps<TRowData> {
  stateKey: string;
  actions?: (InsightsAction<TRowData> | ((rowData: TRowData) => InsightsAction<TRowData>))[];
}

const DefaultPageSize = 10;

export const InsightsMaterialTable = observer(
  <TRowData extends MaterialTableData>(props: InsightsMaterialTableProps<TRowData>) => {
    const { settingsStore } = useInsightsServices();
    const { columns, options, stateKey } = props;

    const tablePreferences = settingsStore.tablePreferences;
    const columnSorting = tablePreferences.getSorting(stateKey);

    if (columnSorting != null) {
      columns.forEach((column, index) => {
        if (column.sorting === true) {
          column.defaultSort = columnSorting.columnId === index ? columnSorting.direction : undefined;
        }
      });
    }

    const handleOnChangePage = (pageNumber: number) => {
      const tablePreferences = settingsStore.tablePreferences;
      void tablePreferences.saveCurrentPage(stateKey, pageNumber);
    };

    const handleOnChangeRowsPerPage = (rowsPerPage: number) => {
      const tablePreferences = settingsStore.tablePreferences;
      void tablePreferences.savePageSize(stateKey, rowsPerPage);
    };

    const handleOnOrderChange = (columns: OrderByCollection[]) => {
      const tablePreferences = settingsStore.tablePreferences;
      void Promise.all(
        columns.map((column) =>
          tablePreferences.saveSorting(stateKey, {
            columnId: column.orderBy,
            direction: column.orderDirection as 'asc' | 'desc'
          })
        )
      );
    };

    const handleOnSearchChange = (searchText: string) => {
      const tablePreferences = settingsStore.tablePreferences;
      void tablePreferences.saveSearchText(stateKey, searchText);
    };

    return (
      <MaterialTable
        {...props}
        columns={columns}
        options={{
          ...options,
          pageSize: tablePreferences.getPageSize(stateKey) ?? options?.pageSize ?? DefaultPageSize,
          initialPage: tablePreferences.getCurrentPage(stateKey) ?? options?.initialPage,
          searchText: tablePreferences.getSearchText(stateKey) ?? options?.searchText
        }}
        onPageChange={(page) => handleOnChangePage(page)}
        onRowsPerPageChange={(pageSize) => handleOnChangeRowsPerPage(pageSize)}
        onOrderCollectionChange={(columns) => handleOnOrderChange(columns)}
        onSearchChange={(searchText) => handleOnSearchChange(searchText)}
      />
    );
  }
);
