import { Box, SxProps } from '@mui/material';
import { CSSProperties } from 'react';
import { Row } from './layout';

export interface IndicatorDotProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  color: string;
  isFilled: boolean;
}

export const IndicatorDot = ({ className, sx = [], style, color, isFilled }: IndicatorDotProps) => {
  const dotStyle: CSSProperties = {
    backgroundColor: isFilled ? color : 'transparent',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: color
  };

  return (
    <Row sx={sx} className={className} style={style}>
      <Box
        style={dotStyle}
        sx={{
          flex: 1,
          width: '100%',
          height: '100%',
          borderRadius: '50%'
        }}
      />
    </Row>
  );
};
