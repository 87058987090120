import { NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { CanvasConnectorStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppCanvasAccountSettingsViewModel, CanvasAccountSettingsViewModel } from './CanvasAccountSettingsViewModel';

export interface CanvasAccountSettingsDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly settings: IPromiseBasedObservable<CanvasAccountSettingsViewModel>;
}

export class AppCanvasAccountSettingsDialogViewModel implements CanvasAccountSettingsDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _canvasStore: CanvasConnectorStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get settings(): IPromiseBasedObservable<CanvasAccountSettingsViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<CanvasAccountSettingsViewModel> {
    const [details, groups] = await Promise.all([
      this._canvasStore.getCanvasAccountDetails(this.externalAccountId),
      this._canvasStore.getCanvasAssignmentGroupNames(this.externalAccountId)
    ]);

    return new AppCanvasAccountSettingsViewModel(
      this._localizationService,
      this._canvasStore,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      groups,
      details
    );
  }
}
