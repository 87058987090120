import { Box, SxProps, Typography } from '@mui/material';
import { CSSProperties } from 'react';
import { RouterLink } from './RouterLink';
import { Thresholds, ThresholdsInformation } from './ThresholdsInformation.tsx';

export interface SectionNameTwoLineProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title: string;
  titleStyle?: CSSProperties;
  subInformation1?: string;
  subInformation2?: string;
  subInformationStyle?: CSSProperties;
  thresholds?: Thresholds;
  sectionColor?: string;
  linkTo?: string;
}

export function SectionNameTwoLine({
  sx = [],
  className,
  style,
  title,
  titleStyle,
  subInformation1,
  subInformation2,
  subInformationStyle,
  thresholds,
  sectionColor,
  linkTo
}: SectionNameTwoLineProps) {
  return (
    <Box
      sx={sx}
      className={className}
      style={style}
      display="flex"
      flexDirection="row"
      alignItems="center"
      flex={1}
      paddingLeft={1}
      borderLeft={`4px solid ${sectionColor}`}
    >
      <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center">
        {/* Display the title as a link if `linkTo` was specified */}
        {linkTo != null && (
          <RouterLink style={titleStyle} to={linkTo}>
            <Typography sx={{ color: (theme) => theme.palette.primary.main }} style={titleStyle} noWrap>
              {title}
            </Typography>
          </RouterLink>
        )}

        {/* Display the title as a text if `linkTo` was not specified */}
        {linkTo == null && (
          <Typography style={titleStyle} noWrap>
            {title}
          </Typography>
        )}

        {(subInformation1 != null || subInformation2 != null || thresholds != null) && (
          <Box display="flex" flexDirection="row" alignItems="center">
            {(subInformation1 != null || subInformation2 != null) && (
              <Box whiteSpace="nowrap" textOverflow="ellipsis">
                {subInformation1 != null && (
                  <Typography
                    display="inline"
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                    style={subInformationStyle}
                    noWrap
                  >
                    {subInformation1}
                  </Typography>
                )}

                {subInformation2 != null && (
                  <>
                    {subInformation1 != null && (
                      <Typography
                        display="inline"
                        sx={{ color: (theme) => theme.palette.text.secondary }}
                        style={subInformationStyle}
                        noWrap
                      >
                        {' – '}
                      </Typography>
                    )}
                    <Typography
                      display="inline"
                      sx={{ color: (theme) => theme.palette.text.secondary }}
                      style={subInformationStyle}
                      noWrap
                    >
                      {subInformation2}
                    </Typography>
                  </>
                )}
              </Box>
            )}

            {thresholds != null && (
              <>
                <Box flex={1} />
                <Box
                  marginLeft={subInformation1 != null || subInformation2 != null ? 1 : undefined}
                  minWidth="min-content"
                >
                  <ThresholdsInformation thresholds={thresholds} />
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
