export interface SessionsPerDayDialogStrings {
  closeButtonCaption: string;
  loadingErrorMessage: string;
  loadingMessage: string;
}

export const EnglishSessionsPerDayDialogStrings: SessionsPerDayDialogStrings = {
  closeButtonCaption: 'Close',
  loadingErrorMessage: 'An error occurred while loading the data. Please reload.',
  loadingMessage: 'Loading data. Please wait…'
};

export const FrenchSessionsPerDayDialogStrings: SessionsPerDayDialogStrings = {
  closeButtonCaption: 'Fermer',
  loadingErrorMessage: 'Une erreur est survenue lors du chargement des données. Merci de recharger.',
  loadingMessage: 'Chargement des données. Merci de patienter…'
};
