export interface SettingsViewModelStrings {
  confirmDeleteExternalFileSourceMessage: string;
  confirmDeleteExternalFileSourceTitle: string;
  unexpectedErrorMessage: string;
  unexpectedErrorTitle: string;
}

export const EnglishSettingsViewModelStrings: SettingsViewModelStrings = {
  confirmDeleteExternalFileSourceMessage: 'Are you sure you want to delete this external file source?',
  confirmDeleteExternalFileSourceTitle: 'Please confirm',
  unexpectedErrorMessage: 'An unexpected error occurred: ',
  unexpectedErrorTitle: 'Oops'
};

export const FrenchSettingsViewModelStrings: SettingsViewModelStrings = {
  confirmDeleteExternalFileSourceMessage: 'Êtes-vous certain de vouloir effacer cette source externe de fichiers?',
  confirmDeleteExternalFileSourceTitle: 'Veuillez confirmer',
  unexpectedErrorMessage: 'Une erreur inattendue est survenue: ',
  unexpectedErrorTitle: 'Oups'
};
