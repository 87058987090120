import { ExternalFileSources, ObservablePresenter, PageHeaderBar } from '@insights/components';
import { Box, Grid2, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface GlobalSettingsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const GlobalSettings = observer((props: GlobalSettingsProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.settings;

  const viewModel = useMemo(() => viewModelFactory.createGlobalSettings(), []);

  return (
    <Box
      className={className}
      style={style}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <PageHeaderBar />
      <Box
        sx={{
          flex: 1
        }}
      >
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%' }}
          data={viewModel.data}
          loadingMessage={strings.loadingOptionsMessage}
          errorMessage={strings.loadingOptionsErrorMessage}
          render={(data) => (
            <Box
              sx={{
                overflow: 'auto'
              }}
            >
              <Grid2 container>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <ExternalFileSources viewModel={data.externalSources} />
                </Grid2>
              </Grid2>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
