import { EditableTerm } from '@shared/models/config';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { CalendarViewModelsStrings } from '@shared/resources/strings/insights/viewModels/CalendarViewModelsStrings';
import { action, computed, makeObservable } from 'mobx';
import * as C from './Constants';
import { ValidatableViewModel } from './Editor';

export interface SchoolCalendarTermViewModel extends ValidatableViewModel {
  readonly editableTerm: EditableTerm;

  tag: string;
  startDay: Day;
  endDay: Day;

  readonly isNew: boolean;
  readonly isDeleted: boolean;

  delete(): void;
  resetChanges(): void;
  clone(): SchoolCalendarTermViewModel;
}

export class AppSchoolCalendarTermViewModel implements SchoolCalendarTermViewModel {
  private readonly _locale: CalendarViewModelsStrings;

  constructor(
    private readonly _localizationService: LocalizationService,
    public readonly editableTerm: EditableTerm
  ) {
    makeObservable(this);
    this._locale = _localizationService.localizedStrings.insights.viewModels.calendar;
  }

  @computed
  get tag() {
    return this.editableTerm.tag;
  }

  set tag(value: string) {
    this.editableTerm.tag = value;
  }

  @computed
  get startDay() {
    return this.editableTerm.startDay;
  }

  set startDay(value: Day) {
    this.editableTerm.startDay = value;
  }

  @computed
  get endDay() {
    return this.editableTerm.endDay;
  }

  set endDay(value: Day) {
    this.editableTerm.endDay = value;
  }

  @computed
  get hasChanges() {
    return this.editableTerm.hasChanges;
  }

  get isNew() {
    return this.editableTerm.shouldBeCreated;
  }

  @computed
  get isDeleted() {
    return this.editableTerm.shouldBeDeleted;
  }

  @action
  delete() {
    this.editableTerm.markAsDeleted();
  }

  @action
  resetChanges() {
    this.editableTerm.resetChanges();
  }

  clone(): SchoolCalendarTermViewModel {
    return new AppSchoolCalendarTermViewModel(this._localizationService, this.editableTerm.clone());
  }

  validate(): string[] {
    const messages: string[] = [];

    if (this.tag.length === 0) {
      messages.push(this._locale.emptyTagError);
    } else if (this.tag.length > C.MaximumTermTagLength) {
      messages.push(this._locale.tagTooLongError + C.MaximumTermTagLength + this._locale.characters + '.');
    }

    if (this.startDay.isAfter(this.endDay)) {
      messages.push(this._locale.startDateAfterEndDate);
    }

    return messages;
  }
}
