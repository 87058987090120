import { SchoolYearConfiguration_DayConfiguration as PBDayConfiguration } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { BaseModel, SerializableModel } from '../Model';
import { Day, DayOfWeek } from '../types';
import { dayOfWeekFromProtobuf } from '../types/EnumConversion';

export interface DayConfigurationModel extends SerializableModel<PBDayConfiguration> {
  readonly id: string;
  readonly scheduleId: string;
  readonly specialDayId: string;
  readonly dayOfWeek?: DayOfWeek;
  readonly cycleDay?: number;
  readonly day?: Day;
}

export class DayConfiguration extends BaseModel<PBDayConfiguration> implements DayConfigurationModel {
  constructor(pb: PBDayConfiguration) {
    super(pb);
  }

  get id(): string {
    return this._pb.id;
  }

  get scheduleId(): string {
    return this._pb.scheduleId;
  }

  get specialDayId(): string {
    return this._pb.specialDayId;
  }

  get dayOfWeek(): DayOfWeek | undefined {
    if (this._pb.appliesTo.case === 'dayOfWeek') {
      return dayOfWeekFromProtobuf(this._pb.appliesTo.value);
    }

    return undefined;
  }

  get cycleDay(): number | undefined {
    if (this._pb.appliesTo.case === 'cycleDay') {
      return this._pb.appliesTo.value;
    }

    return undefined;
  }

  get day(): Day | undefined {
    if (this._pb.appliesTo.case === 'day') {
      return Day.fromPB(this._pb.appliesTo.value);
    }

    return undefined;
  }
}
