import { ImportSession } from '@shared/models/import';
import { AutomatedImportListViewModel } from './AutomatedImportListViewModel';
import { ImportSessionDataListViewModel } from './ImportSessionDataListViewModel';
import { ImportSessionFileListViewModel } from './ImportSessionFileListViewModel';

export interface ImportSessionDetailsViewModel {
  readonly title: string;
  readonly fileList: ImportSessionFileListViewModel;
  readonly dataList: ImportSessionDataListViewModel;
  readonly automatedImportList: AutomatedImportListViewModel;
}

export class AppImportSessionDetailsViewModel implements ImportSessionDetailsViewModel {
  constructor(
    private readonly _session: ImportSession,
    public readonly fileList: ImportSessionFileListViewModel,
    public readonly dataList: ImportSessionDataListViewModel,
    public readonly automatedImportList: AutomatedImportListViewModel
  ) {}

  get title() {
    return this._session.name;
  }
}
