import { SectionScheduleModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';

export class SectionScheduleUtils {
  static getScheduleDigest(schedule: SectionScheduleModel, localizationService: LocalizationService): string {
    let digest = '';

    if (schedule.termTag.length > 0) {
      digest = `#${schedule.termTag} `;
    }

    if (schedule.cycleDay != null) {
      digest = digest + `d:${schedule.cycleDay} `;
    } else if (schedule.dayOfWeek != null) {
      digest =
        digest +
        `d:${localizationService.localizedStrings.models.dayOfWeek.shortLocalizedDayOfWeek(schedule.dayOfWeek)} `;
    } else if (schedule.day != null) {
      digest = digest + `d:${schedule.day.asDateString} `;
    }

    if (schedule.masterSchedule != null) {
      if (schedule.masterSchedule.periodTag.length > 0) {
        digest = digest + `p:${schedule.masterSchedule.periodTag} `;
      }

      if (schedule.masterSchedule.scheduleTag.length > 0) {
        digest = digest + `s:${schedule.masterSchedule.scheduleTag} `;
      }
    } else if (schedule.customSchedule != null) {
      digest = digest + `@${schedule.customSchedule.startTime.asString}-${schedule.customSchedule.endTime.asString} `;

      if (schedule.customSchedule.displayPeriodTag.length > 0) {
        digest = digest + `dp:${schedule.customSchedule.displayPeriodTag} `;
      }
    }

    return digest;
  }
}
