import { NavigationService } from '@insights/services';
import { BlackbaudSkyTokenInformation } from '@shared/models/connectors';
import { LocalizationService } from '@shared/resources/services';
import { BlackbaudSkyConnectorStore, ConnectorsStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface BlackbaudSkyAccountViewModel extends ExternalAccountEditionViewModel {
  readonly isNew: boolean;
  readonly tokens: BlackbaudSkyTokenInformation | undefined;
  accountName: string;

  repeatAuthorization: () => Promise<void>;
}

export interface BlackbaudSkyAccountDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly account: IPromiseBasedObservable<BlackbaudSkyAccountViewModel>;
}

export class AppBlackbaudSkyAccountViewModel
  extends BaseExternalAccountEditionViewModel
  implements BlackbaudSkyAccountViewModel
{
  @observable private _accountName?: string;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _blackbaudSkyStore: BlackbaudSkyConnectorStore,
    private readonly _onSuccess: (externalAccountId: string) => Promise<void>,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    private readonly _originalAccountName: string,
    readonly tokens: BlackbaudSkyTokenInformation | undefined
  ) {
    super();
    makeObservable(this);
  }

  get isNew(): boolean {
    return this._externalAccountId.length === 0;
  }

  get hasNext(): boolean | undefined {
    return this.isNew;
  }

  get hasChanges(): boolean {
    return this._hasChanges || this.isNew;
  }

  @computed
  get accountName() {
    return this._accountName ?? this._originalAccountName;
  }

  set accountName(value: string) {
    this._accountName = value;
    this.onChange();
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this.hasChanges) {
      console.error('Applying without changes. Ignoring...');
      await this._onSuccess(this._externalAccountId);
      return;
    }

    this.beginApplying();

    try {
      const newId = await this._blackbaudSkyStore.createOrUpdateAccount(
        this._externalAccountId,
        this._configId,
        this.accountName
      );

      await this._onSuccess(newId);
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._accountName = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this._hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }

  async repeatAuthorization(): Promise<void> {
    window.location.href = await this._blackbaudSkyStore.getAuthorizationUrl(this._externalAccountId, this._configId);
  }
}

export class AppBlackbaudSkyAccountDialogViewModel implements BlackbaudSkyAccountDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get account(): IPromiseBasedObservable<BlackbaudSkyAccountViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<BlackbaudSkyAccountViewModel> {
    const blackbaudSkyStore = this._connectorsStore.blackbaudSky;

    if (this.externalAccountId === 'new') {
      return new AppBlackbaudSkyAccountViewModel(
        this._localizationService,
        blackbaudSkyStore,
        (id) => this.navigateToNextStep(id),
        () => this._onCancel(),
        this.configId,
        '',
        'Blackbaud SKY',
        undefined
      );
    }

    const [account, details] = await Promise.all([
      this._connectorsStore.getExternalAccount(this.configId, this.externalAccountId),
      blackbaudSkyStore.getAccountDetails(this.externalAccountId)
    ]);

    return new AppBlackbaudSkyAccountViewModel(
      this._localizationService,
      blackbaudSkyStore,
      (id) => this.navigateToNextStep(id),
      this._onCancel,
      this.configId,
      this.externalAccountId,
      account.name,
      details.tokens
    );
  }

  private async navigateToNextStep(externalAccountId: string): Promise<void> {
    if (this.externalAccountId === 'new') {
      window.location.href = await this._connectorsStore.blackbaudSky.getAuthorizationUrl(
        externalAccountId,
        this.configId
      );
      return;
    }

    this._onSuccess();
  }
}
