import { Section_Schedule_Master as PBScheduleMaster } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { BaseModel, SerializableModel } from '../Model';

export interface SectionScheduleMasterModel extends SerializableModel<PBScheduleMaster> {
  readonly scheduleTag: string;
  readonly periodTag: string;
}

export class SectionScheduleMaster extends BaseModel<PBScheduleMaster> implements SectionScheduleMasterModel {
  constructor(pb: PBScheduleMaster) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get scheduleTag(): string {
    return this._pb.scheduleTag;
  }

  get periodTag(): string {
    return this._pb.periodTag;
  }
}
