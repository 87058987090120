import { SchoolYearConfiguration_AssessmentPlanningSettings as PBAssessmentPlanningSettings } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';

import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableNullableDayPropertyEx, EditableValuePropertyEx } from '../editables';
import { Day } from '../types';
import { AssessmentPlanningSettings, AssessmentPlanningSettingsModel } from './AssessmentPlanningSettings';

export class EditableAssessmentPlanningSettings
  extends EditableModelEx<PBAssessmentPlanningSettings>
  implements AssessmentPlanningSettingsModel
{
  private _gradeLevel: EditableValuePropertyEx<string, PBAssessmentPlanningSettings>;
  private _examsAndImportantVisibilityDay: EditableNullableDayPropertyEx<PBAssessmentPlanningSettings>;

  static createNew(gradeLevel: string) {
    const pb = new PBAssessmentPlanningSettings();
    pb.gradeLevel = gradeLevel;

    return new EditableAssessmentPlanningSettings(new AssessmentPlanningSettings(pb), true);
  }

  constructor(
    private readonly _originalAssessmentPlanningSettings: AssessmentPlanningSettingsModel,
    isNew = false
  ) {
    super(_originalAssessmentPlanningSettings.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._gradeLevel = new EditableValuePropertyEx(
        _originalAssessmentPlanningSettings.gradeLevel,
        (pb, value) => (pb.gradeLevel = value)
      )),
      (this._examsAndImportantVisibilityDay = new EditableNullableDayPropertyEx(
        _originalAssessmentPlanningSettings.examsAndImportantVisibilityDay,
        (pb, value) => {
          pb.examsAndImportantVisibilityDay = value?.asPB;
        }
      ))
    ]);
  }

  @computed
  get gradeLevel(): string {
    return this._gradeLevel.value;
  }

  set gradeLevel(value: string) {
    this._gradeLevel.value = value;
  }

  @computed
  get examsAndImportantVisibilityDay(): Day | undefined {
    return this._examsAndImportantVisibilityDay.value;
  }

  set examsAndImportantVisibilityDay(value: Day | undefined) {
    this._examsAndImportantVisibilityDay.value = value;
  }
}
