import {
  AutomatedImport,
  AutomatedImportResult,
  AutomatedTransformationImportResult,
  ImportSession,
  Transformation
} from '@shared/models/import';
import { computed, makeObservable } from 'mobx';

export interface AutomatedTransformationImportResultInfo {
  transformation?: Transformation;
  result: AutomatedTransformationImportResult;
}

export interface AutomatedImportResultDetailsDialogViewModel {
  readonly automatedImport: AutomatedImport;
  readonly result: AutomatedImportResult;
  readonly transformationResults: AutomatedTransformationImportResultInfo[];

  cancel(): void;
}

export class AppAutomatedImportResultDetailsDialogViewModel implements AutomatedImportResultDetailsDialogViewModel {
  constructor(
    private readonly _onCancel: () => void,
    private readonly _importSession: ImportSession,
    readonly automatedImport: AutomatedImport,
    readonly result: AutomatedImportResult
  ) {
    makeObservable(this);
  }

  @computed
  get transformationResults() {
    return this.result.transformationResults.map<AutomatedTransformationImportResultInfo>((tr) => ({
      transformation: this._importSession.transformations.find((t) => t.label === tr.label),
      result: tr
    }));
  }

  cancel(): void {
    this._onCancel();
  }
}
