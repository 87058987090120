import { OnboardingStepEditionViewModel } from '@insights/viewmodels';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid2,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SxProps,
  TextField,
  styled
} from '@mui/material';
import { AllOnboardingParticipantKinds, OnboardingParticipantKind } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { OnboardingTextsEdition } from './OnboardingTextsEdition';

export interface OnboardingStepEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: OnboardingStepEditionViewModel;
}

export const OnboardingStepEdition = observer((props: OnboardingStepEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, viewModel, sx } = props;
  const { editableStep, dependableStepNames, dependableQuestionNames } = viewModel;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const titleRows = editableStep.editableTitle[0].format === 'plain-text' ? 1 : 3;
  const hasDependantQuestion = editableStep.dependantQuestionName.length > 0;

  return (
    <Root sx={sx} className={className} style={style}>
      <FormControl className="formControl" fullWidth>
        <InputLabel htmlFor="participants-select" shrink>
          {strings.participantsLabel}
        </InputLabel>
        <Select
          className="select"
          inputProps={{ id: 'participants-select' }}
          value={editableStep.participants}
          onChange={(e) => (editableStep.participants = e.target.value as OnboardingParticipantKind)}
        >
          {AllOnboardingParticipantKinds.map((p) => (
            <MenuItem key={`participant-${p}`} value={p}>
              {strings.getParticipantKindLabel(p)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <OnboardingTextsEdition
        className="texts"
        title={strings.titleTitle}
        texts={editableStep.editableTitle}
        rows={titleRows}
        variant="h5"
        enforcePlainText
      />
      <OnboardingTextsEdition
        className="texts"
        title={strings.descriptionTitle}
        texts={editableStep.editableDescription}
        onFileUpload={(d, f) => viewModel.uploadFile(d, f)}
      />
      <Box>
        <TextField
          fullWidth
          className="numberWithCheckbox"
          slotProps={{ input: { inputProps: { style: { backgroundColor: '#eef' } } }, inputLabel: { shrink: true } }}
          type="number"
          label={strings.targetDaysLabel}
          value={editableStep.targetDays === -1 ? '' : editableStep.targetDays}
          disabled={editableStep.targetDays === -1}
          onChange={(e) => {
            const value = Number(e.target.value);

            if (!Number.isNaN(value)) {
              editableStep.targetDays = Math.max(0, value);
            }
          }}
        />
      </Box>
      <FormControlLabel
        className="checkboxAfterNumber"
        label={strings.noTargetDayLabel}
        control={
          <Checkbox
            checked={editableStep.targetDays === -1}
            onChange={(e) => (editableStep.targetDays = e.target.checked ? -1 : 3)}
          />
        }
      />
      <FormControl className="formControl" fullWidth>
        <InputLabel htmlFor="dependant-step-name-select" shrink>
          {strings.dependantStepNameLabel}
        </InputLabel>
        <Select
          className="select"
          inputProps={{ id: 'dependant-step-name-select' }}
          value={editableStep.dependantStepName}
          onChange={(e) => (editableStep.dependantStepName = e.target.value)}
        >
          <MenuItem key="no-dependable-step-name" value="">
            {strings.noDependantStepLabel}
          </MenuItem>
          {dependableStepNames.map((name) => (
            <MenuItem key={`dependable-step-name-${name}`} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid2
        container
        spacing={1}
        sx={{
          alignItems: 'flex-end'
        }}
      >
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="dependant-question-name-select" shrink>
              {strings.dependantQuestionNameLabel}
            </InputLabel>
            <Select
              className="select"
              inputProps={{ id: 'dependant-question-name-select' }}
              value={editableStep.dependantQuestionName}
              onChange={(e) => (editableStep.dependantQuestionName = e.target.value)}
            >
              <MenuItem key="no-dependable-question-name" value="">
                {strings.noDependantQuestionLabel}
              </MenuItem>
              {dependableQuestionNames.map((names, index) => [
                <ListSubheader key={`selectGroupHeader-${index}`} className="selectGroupHeader">
                  {strings.questionsInOtherStepLabel(names.stepName)}
                </ListSubheader>,
                names.questionNames.map((name) => (
                  <MenuItem key={`dependable-question-name-${name}`} value={name}>
                    {name}
                  </MenuItem>
                ))
              ])}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            fullWidth
            slotProps={{ input: { inputProps: { style: { backgroundColor: '#eef' } } }, inputLabel: { shrink: true } }}
            disabled={!hasDependantQuestion}
            label={strings.dependantQuestionAnswerLabel}
            value={editableStep.dependantQuestionAnswer}
            onChange={(e) => (editableStep.dependantQuestionAnswer = e.target.value)}
          />
        </Grid2>
      </Grid2>
      <FormControl fullWidth>
        <FormControlLabel
          label={strings.isRepeatableLabel}
          control={
            <Checkbox
              checked={editableStep.isRepeatable}
              onChange={(e) => (editableStep.isRepeatable = Boolean(e.target.checked))}
            />
          }
        />
      </FormControl>
    </Root>
  );
});

const Root = styled(Box)(({ theme }) => ({
  '.formControl': {
    marginBottom: theme.spacing(1)
  },
  '.select': {
    backgroundColor: '#eef',
    maxWidth: 400
  },
  '.selectGroupHeader': {
    margin: 0,
    lineHeight: '2em',
    paddingTop: theme.spacing(1)
  },
  '.numberWithCheckbox': {
    maxWidth: 400
  },
  '.checkboxAfterNumber': {
    marginBottom: theme.spacing(2)
  },
  '.texts': {
    marginBottom: theme.spacing(1)
  }
}));
