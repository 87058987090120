export interface CalendarComponentsStrings {
  bellTimes: string;
  cancel: string;
  color: string;
  commentsLabel: string;
  customLabels: string;
  cycleDay: string;
  cycleDayEffect: string;
  cycleDaysEnumeration: string;
  datesSectionTitle: string;
  daysPerCycle: string;
  defaultDailyWorkloadThresholdTitle: string;
  defaultWeeklyWorkloadThresholdTitle: string;
  done: string;
  doNotAffectSequence: string;
  dragOnCalendarToClear: string;
  duration: string;
  editCycleDaysTitle: string;
  editDatesAndMetadataTitle: string;
  editDayCustomizations: string;
  editSchedule: string;
  editSpecialDay: string;
  editTerm: string;
  effect: string;
  emptyDayCustomizations: string;
  end: string;
  endDate: string;
  endOfTermMarker: string;
  endTime: string;
  exceptionEffect: string;
  exceptionEffectShort: string;
  hidden: string;
  hiddenNoSchool: string;
  import: string;
  importFromButton: string;
  importBellTimesLabel: string;
  importBellTimesNone: string;
  importBellTimesAdd: string;
  importBellTimesReplace: string;
  importBellTimesWarning: string;
  importDayConfigurationsAll: string;
  importDayConfigurationsLabel: string;
  importDayConfigurationsNone: string;
  importDayConfigurationsSchedules: string;
  importDayConfigurationsSpecialDays: string;
  importFromSchoolTitle: string;
  importSpecialDaysLabel: string;
  importSpecialDaysNone: string;
  importSpecialDaysAdd: string;
  importSpecialDaysReplace: string;
  importSpecialDaysWarning: string;
  importTermsLabel: string;
  importTermsNone: string;
  importTermsAdd: string;
  importTermsReplace: string;
  incrementOccurrenceCount: string;
  incrementOccurrenceCountTooltip: string;
  invalidScheduleId: string;
  isDemoLabel: string;
  keepCurrent: string;
  legacyTag: string;
  linksTitle: string;
  linksSortOrderTitle: string;
  linksTitleTitle: string;
  linksLinkTitle: string;
  metadataSectionTitle: string;
  newScheduleTag: (tag: string) => string;
  none: string;
  noSchedulesFound: string;
  noSchool: string;
  noSpecialDaysFound: string;
  occurrencesLabel: string;
  onboardingCodeLabel: string;
  period: string;
  periodTag: string;
  preserve: string;
  preserveShort: string;
  pushCycleDays: string;
  remove: string;
  removeDayCustomization: string;
  restartSequence: string;
  restartShort: string;
  save: string;
  scheduleTagExceptions: string;
  schoolNameLabel: string;
  schoolTitleLabel: string;
  search: string;
  shiftToNextShort: string;
  showSpecialDaySymbol: string;
  showSpecialDayTitle: string;
  skipShort: string;
  sourceSchoolLabel: string;
  start: string;
  startDate: string;
  startTime: string;
  studentAccessDate: string;
  studentTime: string;
  symbol: string;
  tag: string;
  tags: string;
  teachersAccessDate: string;
  teacherTime: string;
  termsNotice: string;
  time: string;
  title: string;
  to: string;
  unknownDayConfig: string;
  weeksOffsetLabel: string;
  workloadThresholdsSectionTitle: string;
  workloadThresholdsGradeLevelColumnTitle: string;
  workloadThresholdsDailyThresholdColumnTitle: string;
  workloadThresholdsWeeklyThresholdColumnTitle: string;
  yearLabel: string;

  cycleDayLongTitle(label: string): string;
  localizedNumberOfCycleDays(numberOfCycleDays: number): string;
}

export const EnglishCalendarComponentsStrings: CalendarComponentsStrings = {
  bellTimes: 'Bell Times',
  cancel: 'Cancel',
  color: 'Color',
  commentsLabel: 'Comments',
  customLabels: 'Custom Labels',
  cycleDay: 'Cycle Day',
  cycleDayEffect: 'Cycle Day Effect',
  cycleDaysEnumeration: 'Cycle days ',
  datesSectionTitle: 'Dates',
  daysPerCycle: 'Days per cycle',
  defaultDailyWorkloadThresholdTitle: 'Default Daily Threshold',
  defaultWeeklyWorkloadThresholdTitle: 'Default Weekly Threshold',
  done: 'Done',
  doNotAffectSequence: 'Skip cycle day (preserve sequence)',
  dragOnCalendarToClear: 'Drag on calendar to clear',
  duration: 'Duration',
  editCycleDaysTitle: 'Edit Cycle Days',
  editDatesAndMetadataTitle: 'Edit Dates and Metadata',
  editDayCustomizations: 'Edit Day Customizations',
  editSchedule: 'Edit Schedule',
  editSpecialDay: 'Edit Special Day',
  editTerm: 'Edit Term',
  effect: 'Effect',
  emptyDayCustomizations: 'No day configuration',
  end: 'End',
  endDate: 'End Date',
  endOfTermMarker: 'This is an End-of-Term marker',
  endTime: 'End Time',
  exceptionEffect: 'Cycle day exception',
  exceptionEffectShort: 'Exception',
  hidden: 'hidden',
  hiddenNoSchool: 'hidden + no school',
  import: 'Import',
  importFromButton: 'Import from…',
  importBellTimesLabel: 'Import bell times?',
  importBellTimesNone: 'Do not import',
  importBellTimesAdd: 'Add to existing bell times',
  importBellTimesReplace: 'Replace existing bell times',
  importBellTimesWarning: 'Existing special days will try to match new bell times by name',
  importDayConfigurationsAll: 'Import day configurations for bell times and special days',
  importDayConfigurationsLabel: 'Import day configurations?',
  importDayConfigurationsNone: 'Do not import',
  importDayConfigurationsSchedules: 'Import only bell times configurations',
  importDayConfigurationsSpecialDays: 'Import only special day configurations',
  importFromSchoolTitle: 'Import from another school',
  importSpecialDaysLabel: 'Import special days?',
  importSpecialDaysNone: 'Do not import',
  importSpecialDaysAdd: 'Add to existing special days',
  importSpecialDaysReplace: 'Replace existing special days',
  importSpecialDaysWarning: 'New special days will try to match bell times by name',
  importTermsLabel: 'Import terms?',
  importTermsNone: 'Do not import',
  importTermsAdd: 'Add to existing terms',
  importTermsReplace: 'Replace existing terms',
  incrementOccurrenceCount: 'Occ.',
  incrementOccurrenceCountTooltip: 'Counts as section occurrences',
  invalidScheduleId: '(unknown id)',
  isDemoLabel: 'Demo School',
  keepCurrent: 'Keep Current',
  legacyTag: 'Legacy Tag',
  linksTitle: 'Links',
  linksSortOrderTitle: 'Sort Order',
  linksTitleTitle: 'Title',
  linksLinkTitle: 'Link',
  metadataSectionTitle: 'Metadata',
  newScheduleTag: (tag) => `Create '${tag}' schedule tag`,
  none: 'None',
  noSchedulesFound: 'No schedules found for the search term',
  noSchool: 'no school',
  noSpecialDaysFound: 'No special days found for the search term',
  occurrencesLabel: 'Occurrences',
  onboardingCodeLabel: 'Onboarding Code',
  period: 'Period',
  periodTag: 'Period Tag',
  preserve: 'Preserve cycle day',
  preserveShort: 'Preserve',
  pushCycleDays: 'Shift cycle day (push sequence)',
  remove: 'No cycle day',
  removeDayCustomization: 'Remove',
  restartSequence: 'Restart (reset the sequence)',
  restartShort: 'Restart',
  save: 'Save',
  scheduleTagExceptions: 'Impacted schedule tags',
  schoolNameLabel: 'School Name',
  schoolTitleLabel: 'School Title (optional)',
  search: 'Search…',
  shiftToNextShort: 'Shift',
  showSpecialDaySymbol: 'Show special day symbol in Studyo',
  showSpecialDayTitle: 'Show special day title in Studyo',
  skipShort: 'Skip',
  sourceSchoolLabel: 'School to import from',
  start: 'Start',
  startDate: 'Start Date',
  startTime: 'Start Time',
  studentAccessDate: 'Student Access Date',
  studentTime: 'Time',
  symbol: 'Symbol',
  tag: 'Tag',
  tags: 'Tags',
  teachersAccessDate: 'Teachers Access Date',
  teacherTime: 'Time',
  termsNotice:
    'WARNING: Rename or delete these terms with precaution. They are not visible to users and do not represent markers in the calendar. They are used as boundaries for section schedules.',
  time: 'Time',
  title: 'Title',
  to: 'to',
  unknownDayConfig: 'Unknown day configuration',
  weeksOffsetLabel: 'Number of weeks between configurations',
  workloadThresholdsSectionTitle: 'Workload Manager Thresholds',
  workloadThresholdsGradeLevelColumnTitle: 'Grade Level',
  workloadThresholdsDailyThresholdColumnTitle: 'Daily Threshold',
  workloadThresholdsWeeklyThresholdColumnTitle: 'Weekly Threshold',
  yearLabel: 'Year',

  cycleDayLongTitle: EnglishCycleDayLongTitle,
  localizedNumberOfCycleDays: EnglishLocalizedCycleDay
};

export const FrenchCalendarComponentsStrings: CalendarComponentsStrings = {
  bellTimes: 'Horaires',
  cancel: 'Annuler',
  color: 'Couleur',
  commentsLabel: 'Remarques',
  customLabels: 'Étiquette',
  cycleDay: 'Jour-cycle',
  cycleDayEffect: 'Effet Jour-cycle',
  cycleDaysEnumeration: 'Jours-cycle ',
  datesSectionTitle: 'Dates',
  daysPerCycle: 'Jours par cycle',
  defaultDailyWorkloadThresholdTitle: 'Seuil quotidien par défaut',
  defaultWeeklyWorkloadThresholdTitle: 'Seuil hebdomadaire par défaut',
  done: 'Terminé',
  doNotAffectSequence: 'Sauter le jour-cycle (préserver la séquence)',
  dragOnCalendarToClear: 'Glisser sur le calendrier pour effacer',
  duration: 'Durée',
  editCycleDaysTitle: 'Changer les jours-cycle',
  editDatesAndMetadataTitle: 'Changer les dates et les métadonnées',
  editDayCustomizations: 'Changer les configurations du jour',
  editSchedule: 'Modifier l’horaire',
  editSpecialDay: 'Modifier le jour-cycle',
  editTerm: 'Modifier l’étape',
  effect: 'Effet',
  emptyDayCustomizations: 'Pas de configuration du jour',
  end: 'Fin',
  endDate: 'Date de fin',
  endOfTermMarker: 'Ceci est un marqueur de fin d’étape',
  endTime: 'Heure fin',
  exceptionEffect: 'Exception de jour-cycle',
  exceptionEffectShort: 'Exception',
  hidden: 'caché',
  hiddenNoSchool: 'caché + pas d’école',
  import: 'Importer',
  importFromButton: 'Importer de…',
  importBellTimesLabel: 'Importer les horaires?',
  importBellTimesNone: 'Ne pas importer',
  importBellTimesAdd: 'Ajouter aux horaires actuels',
  importBellTimesReplace: 'Remplacer les horaires actuels',
  importBellTimesWarning:
    'Le lien entre les jours spéciaux actuels et leurs horaires sera préservé (par nom) si possible.',
  importDayConfigurationsAll: 'Importer les configurations de jour spéciaux et d’horaires',
  importDayConfigurationsLabel: 'Importer les configurations de jour?',
  importDayConfigurationsNone: 'Ne pas importer',
  importDayConfigurationsSchedules: 'Importer les configurations d’horaires',
  importDayConfigurationsSpecialDays: 'Importer les configurations de jours spéciaux',
  importFromSchoolTitle: 'Importer d’une autre école',
  importSpecialDaysLabel: 'Importer les jours spéciaux?',
  importSpecialDaysNone: 'Ne pas importer',
  importSpecialDaysAdd: 'Ajouter aux jours spéciaux actuels',
  importSpecialDaysReplace: 'Remplacer les jours spéciaux actuels',
  importSpecialDaysWarning:
    'Le lien entre les nouveaux jours spéciaux et leurs horaires sera préservé (par nom) si possible.',
  importTermsLabel: 'Importer les étapes?',
  importTermsNone: 'Ne pas importer',
  importTermsAdd: 'Ajouter aux étapes actuelless',
  importTermsReplace: 'Remplacer les étapes actuelles',
  incrementOccurrenceCount: 'Occ.',
  incrementOccurrenceCountTooltip: 'Incrémente l’occurrence des groupes-matière',
  invalidScheduleId: '(id inconnu)',
  isDemoLabel: 'École démo',
  keepCurrent: 'Conserver',
  legacyTag: 'Étiquette (ancien)',
  linksTitle: 'Liens',
  linksSortOrderTitle: 'Position',
  linksTitleTitle: 'Titre',
  linksLinkTitle: 'Lien',
  metadataSectionTitle: 'Métadonnées',
  newScheduleTag: (tag) => `Créer l’étiquette '${tag}'`,
  none: 'Aucun',
  noSchedulesFound: 'Aucun horaire ne correspond à cette recherche',
  noSchool: 'pas d’école',
  noSpecialDaysFound: 'Aucun jour spécial ne correspond à cette recherche',
  occurrencesLabel: 'Occurrences',
  onboardingCodeLabel: 'Code d’école',
  period: 'Période',
  periodTag: 'Étiquette de période',
  preserve: 'Préserver la séquence',
  preserveShort: 'Préserver',
  pushCycleDays: 'Décaler le jour-cycle (repousser la séquence)',
  remove: 'Pas de jour-cycle',
  removeDayCustomization: 'Retirer',
  restartSequence: 'Redémarrer (réinitialiser la séquence)',
  restartShort: 'Redémarrer',
  save: 'Enregistrer',
  scheduleTagExceptions: 'Étiquettes de cédule affectées',
  schoolNameLabel: 'Nom de l’école',
  schoolTitleLabel: 'Titre (facultatif)',
  search: 'Recherche…',
  shiftToNextShort: 'Décaler',
  showSpecialDaySymbol: 'Afficher le symbole dans Studyo',
  showSpecialDayTitle: 'Afficher le titre dans Studyo',
  skipShort: 'Sauter',
  sourceSchoolLabel: 'École à importer',
  start: 'Début',
  startDate: 'Date de début',
  startTime: 'Heure de début',
  studentAccessDate: 'Date disponible aux élèves',
  studentTime: 'Heure',
  symbol: 'Symbole',
  tag: 'Étiquette',
  tags: 'Étiquettes',
  teachersAccessDate: 'Date disponible aux enseignants',
  teacherTime: 'Heure',
  termsNotice:
    'ATTENTION: N’effacez ni ne renommez ces étapes sans précaution. Elles ne sont pas visibles aux utilisateurs et ne représentent pas des marqueurs dans le calendrier. Elles sont utilisées dans les cédules des groupes-matière.',
  time: 'Heure',
  title: 'Titre',
  to: 'à',
  unknownDayConfig: 'Configuration de jour inconnue',
  weeksOffsetLabel: 'Nombre de semaines entre les configurations',
  workloadThresholdsSectionTitle: 'Seuils pour la charge de travail',
  workloadThresholdsGradeLevelColumnTitle: 'Niveau scolaire',
  workloadThresholdsDailyThresholdColumnTitle: 'Seuil quotidien',
  workloadThresholdsWeeklyThresholdColumnTitle: 'Seuil hebdomadaire',
  yearLabel: 'Année',

  cycleDayLongTitle: FrenchCycleDayLongTitle,
  localizedNumberOfCycleDays: FrenchLocalizedCycleDay
};

function EnglishLocalizedCycleDay(numberOfCycleDays: number): string {
  return numberOfCycleDays > 1 ? numberOfCycleDays + ' Cycle Days' : numberOfCycleDays + ' Cycle Day';
}

function FrenchLocalizedCycleDay(numberOfCycleDays: number) {
  return numberOfCycleDays > 1 ? numberOfCycleDays + ' Jours-cycles' : numberOfCycleDays + ' Jour-cycle';
}

function EnglishCycleDayLongTitle(label: string) {
  return `Cycle day ${label}`;
}

function FrenchCycleDayLongTitle(label: string) {
  return `Jour cycle ${label}`;
}
