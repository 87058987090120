import { LayoutType, ObservablePresenter, ValuePresenter } from '@insights/components';
import { ConfigGlobalStatsViewModel } from '@insights/viewmodels';
import { Box, SxProps } from '@mui/material';
import { ConfigGlobalStats } from '@shared/models/metrics';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { MinLayoutWidth, MinTinyChartHeight, ParentsColor, ParentsLightColor } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ParentAccountsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: ConfigGlobalStatsViewModel;
}

export const ParentAccounts = observer((props: ParentAccountsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.parents;

  return (
    <Box sx={{ ...sx, height: MinTinyChartHeight, minWidth: MinLayoutWidth }} className={className} style={style}>
      <ObservablePresenter
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        data={viewModel.data}
        indicatorsSize="small"
        loadingMessage={strings.loadingParentMessage}
        errorMessage={strings.parentErrorMessage}
        render={(data) => (
          <ValuePresenter
            caption={strings.parents}
            subcaption={strings.numberOfConnectedParents}
            maximum={data.accounts.parentInvited}
            value={data.accounts.parentClaimed}
            valueColor={ParentsColor}
            referenceValue={data.accounts.parentInvitesAccepted}
            referenceValueColor={ParentsLightColor}
            layoutType={LayoutType.Horizontal}
            tooltipText={getTooltipText(data, localizationService)}
          />
        )}
      />
    </Box>
  );
});

function getTooltipText(configGlobalStats: ConfigGlobalStats, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.views.metrics.parents;

  return `
      <table>
        <tr>
          <td style="font-weight: 500; text-align: right;">${configGlobalStats.accounts.parentClaimed}</td>
          <td>${strings.connectedParents}</td>
        </tr>
        <tr>
          <td style="font-weight: 500; text-align: right;">${configGlobalStats.accounts.parentInvitesAccepted}</td>
          <td>${strings.invitationsAccepted}</td>
        </tr>
        <tr>
          <td style="font-weight: 500; text-align: right;">${configGlobalStats.accounts.parentInvited}</td>
          <td>${strings.invitedParents}</td>
        </tr>
      </table>
    `;
}
