import { ContentService, NavigationService } from '@insights/services';
import { SchoolDay } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { ContentStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import {
  AppWorkloadManagerDetailViewModelBase,
  WorkloadManagerDetailViewModelBase,
  WorkloadManagerInfo
} from './WorkloadManagerDetailViewModelBase';

export interface WorkloadManagerBySectionDetailInfo {
  readonly section: SectionModel;
  readonly sectionStudentCount: number;
  readonly overThresholdConflicts: WorkloadManagerInfo | undefined;
  readonly atThresholdConflicts: WorkloadManagerInfo | undefined;
}

export interface WorkloadManagerBySectionDetailViewModel
  extends WorkloadManagerDetailViewModelBase<WorkloadManagerBySectionDetailInfo> {
  readonly sectionId: string;
}

export class AppWorkloadManagerBySectionDetailViewModel
  extends AppWorkloadManagerDetailViewModelBase<WorkloadManagerBySectionDetailInfo>
  implements WorkloadManagerBySectionDetailViewModel
{
  constructor(
    onSuccess: () => void,
    onCancel: () => void,
    contentStore: ContentStore,
    schoolYearConfigurationStore: SchoolYearConfigurationStore,
    navigationService: NavigationService,
    contentService: ContentService,
    configId: string,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    includeExamOnly: boolean,
    studentIdsAtThreshold: string[],
    studentIdsOverThreshold: string[],
    public readonly sectionId: string
  ) {
    super(
      contentStore,
      schoolYearConfigurationStore,
      navigationService,
      contentService,
      onSuccess,
      onCancel,
      configId,
      fromDay,
      toDay,
      includeExamOnly,
      studentIdsAtThreshold,
      studentIdsOverThreshold
    );
  }

  protected async loadData(): Promise<WorkloadManagerBySectionDetailInfo> {
    const [section, sectionStudents, overThresholdConflicts, atThresholdConflicts] = await Promise.all([
      this._schoolYearConfigurationStore.getSection(this.configId, this.sectionId),
      this._schoolYearConfigurationStore.getStudentsForSectionId(this.configId, this.sectionId, false),
      this.computeConflicts(this.studentIdsOverThreshold),
      this.computeConflicts(this.studentIdsAtThreshold)
    ]);

    return {
      section: section,
      sectionStudentCount: sectionStudents.length,
      overThresholdConflicts,
      atThresholdConflicts
    };
  }
}
