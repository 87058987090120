import { NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppVeracrossAccountViewModel, VeracrossAccountViewModel } from './VeracrossAccountViewModel';

export interface VeracrossAccountDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly account: IPromiseBasedObservable<VeracrossAccountViewModel>;
}

export class AppVeracrossAccountDialogViewModel implements VeracrossAccountDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get account(): IPromiseBasedObservable<VeracrossAccountViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<VeracrossAccountViewModel> {
    if (this.externalAccountId === 'new') {
      return new AppVeracrossAccountViewModel(
        this._localizationService,
        this._connectorsStore.veracross,
        this._onSuccess,
        this._onCancel,
        this.configId,
        '',
        'Veracross',
        'https://api.veracross.com/{schoolname}',
        '',
        ''
      );
    }

    const [account, details] = await Promise.all([
      this._connectorsStore.getExternalAccount(this.configId, this.externalAccountId),
      this._connectorsStore.veracross.getVeracrossAccountDetails(this.externalAccountId)
    ]);

    return new AppVeracrossAccountViewModel(
      this._localizationService,
      this._connectorsStore.veracross,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      account.name,
      details.serverUrl,
      details.username,
      details.password
    );
  }
}
