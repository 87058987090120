import { Box, SxProps } from '@mui/material';
import { Observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import ReactAutoSizer from 'react-virtualized-auto-sizer';
import { isSxArray } from '../utils';

export interface AutoSizerProps {
  sx?: SxProps;
  className?: string;
  children: (params: { width: number; height: number }) => ReactNode;
}

export function AutoSizer(props: AutoSizerProps) {
  const { sx = [], className, children } = props;

  // eslint-disable-next-line @typescript-eslint/dot-notation
  if (sx?.['width'] != null || sx?.['height'] != null) {
    throw new Error('Cannot set "width" or "height" of an <AutoSizer>\'s sx property');
  }

  return (
    <ReactAutoSizer disableWidth={false}>
      {({ width = 0, height = 0 }) => (
        <Box
          className={className}
          sx={[
            {
              display: 'flex',
              flexDirection: 'column',
              width: width,
              height: height
            },
            ...(isSxArray(sx) ? sx : [sx])
          ]}
        >
          {children({ width, height })}
        </Box>
      )}
    </ReactAutoSizer>
  );
}

export function ObserverAutoSizer(props: AutoSizerProps) {
  const { sx = [], className, children } = props;

  // eslint-disable-next-line @typescript-eslint/dot-notation
  if (sx?.['width'] != null || sx?.['height'] != null) {
    throw new Error('Cannot set "width" or "height" of an <ObserverAutoSizer>\'s sx property');
  }

  return (
    <ReactAutoSizer disableWidth={false}>
      {({ width = 0, height = 0 }) => (
        <Observer>
          {() => (
            <Box
              className={className}
              sx={[
                {
                  display: 'flex',
                  flexDirection: 'column',
                  width: width,
                  height: height
                },
                ...(isSxArray(sx) ? sx : [sx])
              ]}
            >
              {children({ width, height })}
            </Box>
          )}
        </Observer>
      )}
    </ReactAutoSizer>
  );
}
