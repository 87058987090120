import { ContentService, NavigationService, SettingsStore } from '@insights/services';
import { caseInsensitiveAccentInsensitiveCompare } from '@insights/utils';
import { SchoolDay } from '@shared/models/calendar';
import {
  AccountModel,
  SchoolYearConfigurationModel,
  SectionModel,
  WorkloadThresholdModel
} from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { CalendarStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import _, { Dictionary, chain, flatMap, groupBy, uniq } from 'lodash';
import { Thresholds } from '../../../Constants';
import { AppPaginationViewModel, PaginationViewModel } from './PaginatedViewModel';
import { AppWorkloadManagerViewModelBase, WorkloadManagerViewModelBase } from './WorkloadManagerViewModelBase';

export interface WorkloadManagerByGradeInfo {
  readonly minDay: Day;
  readonly maxDay: Day;
  readonly gradeLevels: WorkloadManagerByGradeGradeInfo[];
  readonly schoolDays: SchoolDay[];
}

export interface WorkloadManagerByGradeGradeInfo {
  readonly gradeLevel: string;
  readonly dailyThreshold: number;
  readonly weeklyThreshold: number;
  readonly sections: Map<string, SectionModel>;
  readonly students: Map<string, AccountModel>;
}

export interface WorkloadManagerByGradePageInfo {
  readonly schoolDays: SchoolDay[];
  readonly dayInfos: WorkloadManagerByGradeColumnInfo[];
  readonly weekInfos: WorkloadManagerByGradeColumnInfo[];
}

export interface WorkloadManagerByGradeColumnInfo {
  readonly gradeLevel: string;
  readonly schoolDay?: SchoolDay;
  readonly studentIdsAtThreshold: string[];
  readonly studentIdsOverThreshold: string[];
}

export interface WorkloadManagerByGradeViewModel
  extends WorkloadManagerViewModelBase<WorkloadManagerByGradeInfo, WorkloadManagerByGradePageInfo> {
  showDetail(
    gradeLevel: string,
    gradeLeveStudentCount: number,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    studentIdsAtThreshold: string[],
    studentIdsOverThreshold: string[]
  ): Promise<void>;
}

export class AppWorkloadManagerByGradeViewModel
  extends AppWorkloadManagerViewModelBase<WorkloadManagerByGradeInfo, WorkloadManagerByGradePageInfo>
  implements WorkloadManagerByGradeViewModel
{
  constructor(
    public readonly configId: string,
    private readonly _calendarStore: CalendarStore,
    private readonly _contentService: ContentService,
    navigationService: NavigationService,
    schoolYearConfigurationStore: SchoolYearConfigurationStore,
    settingsStore: SettingsStore,
    paginationViewModel?: PaginationViewModel
  ) {
    super(configId, navigationService, schoolYearConfigurationStore, settingsStore, paginationViewModel);
  }

  async showDetail(
    gradeLevel: string,
    gradeLevelStudentCount: number,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    studentIdsAtThreshold: string[],
    studentIdsOverThreshold: string[]
  ): Promise<void> {
    await this._navigationService.navigateToWorkloadManagerByGradeDetail(
      this.configId,
      gradeLevel,
      gradeLevelStudentCount,
      fromDay,
      toDay,
      this.examOnly,
      studentIdsAtThreshold,
      studentIdsOverThreshold
    );
  }

  protected async loadData(): Promise<WorkloadManagerByGradeInfo> {
    const [config, sections, sectionsById, schoolDays, students] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this.configId),
      this._schoolYearConfigurationStore.getSections(this.configId),
      this._schoolYearConfigurationStore.getSectionsById(this.configId),
      this._calendarStore.getSchoolDays(this.configId, []),
      this._schoolYearConfigurationStore.getStudents(this.configId, false)
    ]);

    if (this.pagination == null) {
      this.setPagination(new AppPaginationViewModel(config.startDay, config.endDay));
    }

    return {
      minDay: config.startDay,
      maxDay: config.endDay,
      schoolDays: schoolDays,
      gradeLevels: chain(
        config.gradeLevelSource === 'account'
          ? this.getGradeInfosPerAccount(config, sections, sectionsById, students)
          : await this.getGradeInfosPerSection(config, sections)
      )
        // Remove grade levels without students
        .filter((gradeLevelInfo) => gradeLevelInfo.students.size > 0)
        .sort((a, b) => {
          if (!a.gradeLevel.trim()) {
            return 1;
          }
          if (!b.gradeLevel.trim()) {
            return -1;
          }

          return caseInsensitiveAccentInsensitiveCompare(a.gradeLevel, b.gradeLevel, undefined, true);
        })
        .value()
    };
  }

  protected async loadPageData(): Promise<WorkloadManagerByGradePageInfo> {
    const currentPage = this.pagination?.currentPage;

    if (currentPage == null) {
      return {
        schoolDays: [],
        dayInfos: [],
        weekInfos: []
      };
    }

    const examOnly = this.examOnly;
    const data = await this.data;

    // IMPORTANT: The setTimeout is temporary, and it is there to work around the UI hang we are
    //            currently experimenting with the processing of the page data. This should be removed
    //            after the refactor to a more synchronous approach.
    return new Promise((resolve) => {
      const fetchData = async () => {
        // Get the student ids of all the students of all the displayed grade levels
        const studentIds = uniq(flatMap(data.gradeLevels.map((gradeLevel) => [...gradeLevel.students.keys()])));

        const studentTasksForWeek = await this._contentService.getImportantSlaveTasks(
          this.configId,
          studentIds,
          currentPage.startDay,
          currentPage.endDay,
          examOnly
        );

        const schoolDays = data.schoolDays.filter((sd) => sd.day.isWithin(currentPage.startDay, currentPage.endDay));
        const studentTasksByDay = groupBy(studentTasksForWeek, (t) => t.dueDay.asString);

        resolve({
          schoolDays: schoolDays,
          dayInfos: this.computeDayInfos(schoolDays, data.gradeLevels, studentTasksByDay),
          weekInfos: this.computeWeekInfos(data.gradeLevels, studentTasksForWeek)
        });
      };

      setTimeout(() => void fetchData(), 200);
    });
  }

  private computeDayInfos(
    schoolDays: SchoolDay[],
    gradeLevels: WorkloadManagerByGradeGradeInfo[],
    tasksByDay: Dictionary<ContentDefinitionModel[]>
  ): WorkloadManagerByGradeColumnInfo[] {
    return flatMap(
      schoolDays.map<WorkloadManagerByGradeColumnInfo[]>((sd) => {
        return gradeLevels.map<WorkloadManagerByGradeColumnInfo>((gradeLevelInfo) => {
          // Get the tasks for the day
          const tasksForDay = tasksByDay[sd.day.asString] || [];

          const { studentIdsAtThreshold, studentIdsOverThreshold } = this.computeThresholds(
            tasksForDay,
            gradeLevelInfo.dailyThreshold,
            gradeLevelInfo.students
          );

          return {
            schoolDay: sd,
            gradeLevel: gradeLevelInfo.gradeLevel,
            studentIdsAtThreshold: studentIdsAtThreshold,
            studentIdsOverThreshold: studentIdsOverThreshold
          };
        });
      })
    );
  }

  private computeWeekInfos(
    gradeLevels: WorkloadManagerByGradeGradeInfo[],
    tasksForWeek: ContentDefinitionModel[]
  ): WorkloadManagerByGradeColumnInfo[] {
    return gradeLevels.map<WorkloadManagerByGradeColumnInfo>((gradeLevelInfo) => {
      const { studentIdsAtThreshold, studentIdsOverThreshold } = this.computeThresholds(
        tasksForWeek,
        gradeLevelInfo.weeklyThreshold,
        gradeLevelInfo.students
      );

      return {
        gradeLevel: gradeLevelInfo.gradeLevel,
        studentIdsAtThreshold: studentIdsAtThreshold,
        studentIdsOverThreshold: studentIdsOverThreshold
      };
    });
  }

  private getGradeInfosPerAccount(
    config: SchoolYearConfigurationModel,
    sections: SectionModel[],
    sectionsById: Record<string, SectionModel>,
    students: AccountModel[]
  ): WorkloadManagerByGradeGradeInfo[] {
    const studentsByGradeLevel = chain(students)
      .filter((student) => student.gradeLevel.length > 0)
      .groupBy((student) => student.gradeLevel)
      .value();

    const gradeLevels = Object.keys(studentsByGradeLevel);

    const thresholdsByGrade = new Map(config.workloadThresholds.map((t) => [t.gradeLevel, t]));

    return gradeLevels.map<WorkloadManagerByGradeGradeInfo>((gradeLevel) => {
      const studentsForGradeLevel = studentsByGradeLevel[gradeLevel];

      // Make sure to ignore unknown selected section ids
      const sectionsForGradeLevel = _.compact(
        this.getAccountGradeLevelSectionIds(gradeLevel, studentsByGradeLevel, sections).map(
          (sectionId) => sectionsById[sectionId]
        )
      );

      const thresholds = thresholdsByGrade.get(gradeLevel);

      return this.getWorkloadManagerGradeInfo(
        gradeLevel,
        config,
        thresholds,
        sectionsForGradeLevel,
        studentsForGradeLevel
      );
    });
  }

  private async getGradeInfosPerSection(
    config: SchoolYearConfigurationModel,
    sections: SectionModel[]
  ): Promise<WorkloadManagerByGradeGradeInfo[]> {
    const sectionsByGradeLevel = chain(sections)
      .filter((section) => section.gradeLevel != null && section.gradeLevel.length > 0)
      .groupBy((section) => section.gradeLevel)
      .value();

    const gradeLevels = Object.keys(sectionsByGradeLevel);

    const thresholdsByGrade = new Map(config.workloadThresholds.map((t) => [t.gradeLevel, t]));

    return await Promise.all(
      gradeLevels.map<Promise<WorkloadManagerByGradeGradeInfo>>(async (gradeLevel) => {
        const studentsForGradeLevel = await this._schoolYearConfigurationStore.getStudentsForGradeLevel(
          this.configId,
          gradeLevel,
          false
        );

        const sectionsForGradeLevel = sectionsByGradeLevel[gradeLevel];

        const thresholds = thresholdsByGrade.get(gradeLevel);

        return this.getWorkloadManagerGradeInfo(
          gradeLevel,
          config,
          thresholds,
          sectionsForGradeLevel,
          studentsForGradeLevel
        );
      })
    );
  }

  private getWorkloadManagerGradeInfo(
    gradeLevel: string,
    config: SchoolYearConfigurationModel,
    thresholds: WorkloadThresholdModel | undefined,
    sections: SectionModel[],
    students: AccountModel[]
  ): WorkloadManagerByGradeGradeInfo {
    return {
      gradeLevel: gradeLevel,
      dailyThreshold: (thresholds?.dailyThreshold ?? config.dailyWorkloadThreshold) || Thresholds.defaultDailyThreshold,
      weeklyThreshold:
        (thresholds?.weeklyThreshold ?? config.weeklyWorkloadThreshold) || Thresholds.defaultWeeklyThreshold,
      sections: new Map(sections.map((s) => [s.id, s])),
      students: new Map(students.map((s) => [s.id, s]))
    };
  }

  private getAccountGradeLevelSectionIds(
    gradeLevel: string,
    studentsByGradeLevel: Dictionary<AccountModel[]>,
    sections: SectionModel[]
  ): string[] {
    const students = studentsByGradeLevel[gradeLevel];

    let gradeLevelSectionIds = chain(students)
      .map((student) => student.selectedSectionIds)
      .flatMap()
      .uniq()
      .value();

    const autoEnrollSectionIds = chain(sections)
      .filter(
        (section) =>
          section.autoEnrollRoles.includes('student') || section.autoEnrollTags.includes(`gradeLevel=${gradeLevel}`)
      )
      .map((section) => section.id)
      .uniq()
      .value();

    if (autoEnrollSectionIds.length > 0) {
      gradeLevelSectionIds = uniq(gradeLevelSectionIds.concat(autoEnrollSectionIds));
    }

    return gradeLevelSectionIds;
  }
}
