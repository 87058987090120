import { SchemaField as PBSchemaField } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { BaseModel } from '../../Model';
import { SchemaField } from '../interfaces';

export class GrpcSchemaField extends BaseModel<PBSchemaField> implements SchemaField {
  constructor(pb: PBSchemaField) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get name(): string {
    return this._pb.name;
  }

  get description(): string {
    return this._pb.description;
  }

  get expectedType(): string {
    return this._pb.expectedType;
  }
}
