import { action, computed, makeObservable, observable } from 'mobx';

export interface StartUpService {
  readonly isReady: boolean;
  start(): Promise<void>;
}

export abstract class BaseStartUpService implements StartUpService {
  @observable private _isReady = false;
  private _start: Promise<void> | undefined;

  protected constructor() {
    makeObservable(this);
  }

  @computed
  get isReady() {
    return this._isReady;
  }

  async start(): Promise<void> {
    if (this._start == null) {
      this._start = this.innerFetch();
    }

    await this._start;
  }

  private async innerFetch() {
    try {
      if (process.env.NODE_ENV !== 'development') {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const emptyFn = () => {};
        console.debug = emptyFn;
        console.log = emptyFn;
        console.dir = emptyFn;
        console.group = emptyFn;
      }

      await this.initServices();
    } catch (error) {
      console.error('Failed to start up the services');
      console.error(error);
    } finally {
      // Error or not, we must "unlock" the app.
      this.setAsReady();
    }
  }

  protected abstract initServices(): Promise<void>;

  @action
  protected setAsReady() {
    this._isReady = true;
  }
}
