import { IconImages } from '@shared/resources/images/studyo';
import activityPrivateImage from '@shared/web/images/assets/studyo/task_icon_activity_private.svg?url';
import artPrivateImage from '@shared/web/images/assets/studyo/task_icon_art_private.svg?url';
import dancePrivateImage from '@shared/web/images/assets/studyo/task_icon_dance_private.svg?url';
import examPrivateImage from '@shared/web/images/assets/studyo/task_icon_exam_private.svg?url';
import homeworkPrivateImage from '@shared/web/images/assets/studyo/task_icon_homework_private.svg?url';
import labPrivateImage from '@shared/web/images/assets/studyo/task_icon_lab_private.svg?url';
import libraryPrivateImage from '@shared/web/images/assets/studyo/task_icon_library_private.svg?url';
import messagePrivateImage from '@shared/web/images/assets/studyo/task_icon_message_private.svg?url';
import minitestPrivateImage from '@shared/web/images/assets/studyo/task_icon_minitest_private.svg?url';
import moviePrivateImage from '@shared/web/images/assets/studyo/task_icon_movie_private.svg?url';
import musicPrivateImage from '@shared/web/images/assets/studyo/task_icon_music_private.svg?url';
import noteImage from '@shared/web/images/assets/studyo/task_icon_note.svg?url';
import oralPrivateImage from '@shared/web/images/assets/studyo/task_icon_oral_private.svg?url';
import personalPrivateImage from '@shared/web/images/assets/studyo/task_icon_personal_private.svg?url';
import workPlanPrivateImage from '@shared/web/images/assets/studyo/task_icon_plan_private.svg?url';
import playPrivateImage from '@shared/web/images/assets/studyo/task_icon_play_private.svg?url';
import projectPrivateImage from '@shared/web/images/assets/studyo/task_icon_project_private.svg?url';
import readingPrivateImage from '@shared/web/images/assets/studyo/task_icon_reading_private.svg?url';
import recuperationPrivateImage from '@shared/web/images/assets/studyo/task_icon_recuperation_private.svg?url';
import reminderPrivateImage from '@shared/web/images/assets/studyo/task_icon_reminder_private.svg?url';
import signaturePrivateImage from '@shared/web/images/assets/studyo/task_icon_signature_private.svg?url';
import sportPrivateImage from '@shared/web/images/assets/studyo/task_icon_sport_private.svg?url';
import studyPrivateImage from '@shared/web/images/assets/studyo/task_icon_study_private.svg?url';
import surveyPrivateImage from '@shared/web/images/assets/studyo/task_icon_survey_private.svg?url';
import teamworkPrivateImage from '@shared/web/images/assets/studyo/task_icon_teamwork_private.svg?url';
import tutoringPrivateImage from '@shared/web/images/assets/studyo/task_icon_tutoring_private.svg?url';
import videoConferencePrivateImage from '@shared/web/images/assets/studyo/task_icon_video_conference_private.svg?url';

export class WebPrivateIconImages implements IconImages {
  get activity() {
    return activityPrivateImage;
  }

  get dance() {
    return dancePrivateImage;
  }

  get exam() {
    return examPrivateImage;
  }

  get homework() {
    return homeworkPrivateImage;
  }

  get lab() {
    return labPrivateImage;
  }

  get library() {
    return libraryPrivateImage;
  }

  get message() {
    return messagePrivateImage;
  }

  get minitest() {
    return minitestPrivateImage;
  }

  get movie() {
    return moviePrivateImage;
  }

  get music() {
    return musicPrivateImage;
  }

  // Private version of the note icon won't happen
  get note() {
    return noteImage;
  }

  get oral() {
    return oralPrivateImage;
  }

  get personal() {
    return personalPrivateImage;
  }

  get play() {
    return playPrivateImage;
  }

  get project() {
    return projectPrivateImage;
  }

  get reading() {
    return readingPrivateImage;
  }

  get recuperation() {
    return recuperationPrivateImage;
  }

  get reminder() {
    return reminderPrivateImage;
  }

  get signature() {
    return signaturePrivateImage;
  }

  get sport() {
    return sportPrivateImage;
  }

  get study() {
    return studyPrivateImage;
  }

  get survey() {
    return surveyPrivateImage;
  }

  get teamwork() {
    return teamworkPrivateImage;
  }

  get tutoring() {
    return tutoringPrivateImage;
  }

  get videoconference() {
    return videoConferencePrivateImage;
  }

  get art() {
    return artPrivateImage;
  }

  get workplan() {
    return workPlanPrivateImage;
  }
}
