import { AccountService } from '@insights/services';
import { AuthorizationRole } from '@shared/models/types';
import { computed, makeObservable } from 'mobx';

export interface DashboardViewModel {
  readonly configId: string;
  highestUserRole: AuthorizationRole | undefined;
}

export class AppDashboardViewModel implements DashboardViewModel {
  constructor(
    private readonly _accountService: AccountService,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get highestUserRole(): AuthorizationRole | undefined {
    return this._accountService.highestUserRole;
  }
}
