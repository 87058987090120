import { GrpcSchoologyExternalAccountDetails, SchoologyExternalAccountDetails } from '@shared/models/connectors';
import { SchoologyTransport } from '../../../transports';
import { ConnectorsStore, SchoologyConnectorStore } from '../../interfaces';
import { AppBaseStore } from '../AppBaseStore';

export class AppSchoologyConnectorStore extends AppBaseStore implements SchoologyConnectorStore {
  constructor(
    private readonly _transport: SchoologyTransport,
    connectorStore: ConnectorsStore
  ) {
    super('AppSchoologyConnectorStore', false, connectorStore);
  }

  async getSchoologyAccountDetails(externalAccountId: string): Promise<SchoologyExternalAccountDetails> {
    const details = await this._transport.fetchSchoologyExternalAccountDetails(externalAccountId);

    return new GrpcSchoologyExternalAccountDetails(details);
  }

  async createOrUpdateSchoologyAccount(
    configId: string,
    externalAccountId: string,
    accountName: string,
    serverUrl: string,
    consumerKey: string,
    consumerSecret: string
  ): Promise<void> {
    await this._transport.createOrUpdateSchoologyExternalAccount(
      externalAccountId,
      configId,
      serverUrl,
      consumerKey,
      consumerSecret,
      accountName
    );
    this.invalidate();
  }
}
