import * as MB from '@buf/studyo_studyo.bufbuild_es/studyo/type_managebac_pb';
import { computed, makeObservable } from 'mobx';
import { EditableChildNullablePropertyEx, EditableModelEx, EditableStringProperty } from '../editables';
import { EditableManageBacContentRepresentation } from './EditableManageBacContentRepresentation';
import { GrpcManageBacAssignmentKindMapping } from './GrpcManageBacAssignmentKindMapping';
import { ManageBacAssignmentKindMappingModel, ManageBacContentRepresentationModel } from './interfaces';

export class EditableManageBacAssignmentKindMapping
  extends EditableModelEx<MB.AssignmentKindMapping>
  implements ManageBacAssignmentKindMappingModel
{
  private _kind: EditableStringProperty<MB.AssignmentKindMapping>;
  private _representation: EditableChildNullablePropertyEx<
    MB.ContentRepresentation,
    ManageBacContentRepresentationModel,
    EditableManageBacContentRepresentation,
    MB.AssignmentKindMapping
  >;

  static createNew(kind: string): EditableManageBacAssignmentKindMapping {
    const pb = new MB.AssignmentKindMapping();
    pb.kind = kind;

    return new EditableManageBacAssignmentKindMapping(new GrpcManageBacAssignmentKindMapping(pb), true);
  }

  constructor(
    readonly originalMapping: ManageBacAssignmentKindMappingModel,
    readonly isNew = false
  ) {
    super(originalMapping.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._kind = new EditableStringProperty(originalMapping.kind, (pb, value) => (pb.kind = value))),
      (this._representation = new EditableChildNullablePropertyEx(
        originalMapping.representation,
        (model) =>
          model == null
            ? EditableManageBacContentRepresentation.createNew()
            : new EditableManageBacContentRepresentation(model),
        (pb, pbValue) => {
          if (pbValue == null) {
            pb.representation = undefined;
          } else {
            pb.representation = pbValue;
          }
        },
        originalMapping.representation == null
      ))
    ]);
  }

  @computed
  get kind() {
    return this._kind.value;
  }

  set kind(value: string) {
    this._kind.value = value;
  }

  @computed
  get representation() {
    return this._representation.value;
  }

  set representation(value: ManageBacContentRepresentationModel | undefined) {
    this._representation.value = value;
  }

  @computed
  get editableRepresentation() {
    return this._representation.editableValue;
  }
}
