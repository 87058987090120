import { ConfigGlobalStats_AccountStats as PBAccountStats } from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';

export class AccountStats {
  private _pb: PBAccountStats;

  constructor(pb: PBAccountStats) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  get teacherTotal(): number {
    return this._pb.teacherTotal;
  }

  get teacherClaimed(): number {
    return this._pb.teacherClaimed;
  }

  get studentTotal(): number {
    return this._pb.studentTotal;
  }

  get studentClaimed(): number {
    return this._pb.studentClaimed;
  }

  get parentTotal(): number {
    return this._pb.parentTotal;
  }

  get parentClaimed(): number {
    return this._pb.parentClaimed;
  }

  get parentInvitesPending(): number {
    return this._pb.parentInvitesPending;
  }

  get parentInvitesAccepted(): number {
    return this._pb.parentInvitesAccepted;
  }

  //
  // Calculated Properties
  //

  get parentInvited(): number {
    return this.parentInvitesAccepted + this.parentInvitesPending;
  }
}
