import { css } from '@emotion/css';
import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { observer } from 'mobx-react-lite';

export interface BehaviorAggregationTooltipInfo {
  title: string;
  grey?: string;
  green?: string;
  yellow?: string;
  red?: string;
}

export interface BehaviorAggregationTooltipProps {
  sx?: SxProps;
  className?: string;
  tooltip: BehaviorAggregationTooltipInfo | undefined;
}

export const BehaviorAggregationTooltip = observer(
  ({ tooltip, sx = [], className }: BehaviorAggregationTooltipProps) => {
    const theme = useTheme();
    if (tooltip == null) {
      return null;
    }

    const tooltipBoxClassName = css([
      {
        marginRight: theme.spacing(1),
        width: 12,
        height: 12
      }
    ]);

    return (
      <Box sx={sx} className={className}>
        <Typography fontWeight="500" variant="body2">
          {tooltip.title}
        </Typography>
        {tooltip.grey != null && (
          <Box display="flex" alignItems="center">
            <Box className={tooltipBoxClassName} bgcolor={grey[300]} borderRadius={50}>
              &nbsp;
            </Box>
            <Typography variant="body2">{tooltip.grey}</Typography>
          </Box>
        )}
        {tooltip.green != null && (
          <Box display="flex" alignItems="center">
            <Box className={tooltipBoxClassName} bgcolor={green[400]} borderRadius={50}>
              &nbsp;
            </Box>
            <Typography variant="body2">{tooltip.green}</Typography>
          </Box>
        )}
        {tooltip.yellow != null && (
          <Box display="flex" alignItems="center">
            <Box className={tooltipBoxClassName} bgcolor={orange[400]} borderRadius={50}>
              &nbsp;
            </Box>
            <Typography variant="body2">{tooltip.yellow}</Typography>
          </Box>
        )}
        {tooltip.red != null && (
          <Box display="flex" alignItems="center">
            <Box className={tooltipBoxClassName} bgcolor={red[400]} borderRadius={50}>
              &nbsp;
            </Box>
            <Typography variant="body2">{tooltip.red}</Typography>
          </Box>
        )}
      </Box>
    );
  }
);
