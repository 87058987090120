import type { Message, MethodInfo, PartialMessage, ServiceType } from '@bufbuild/protobuf';
import { Interceptor, Transport } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { AccessTokenProvider, EnvironmentService } from '@shared/services';
import { isUnauthenticatedError } from './ConnectErrors';

interface ReadRequestParams {
  shouldRetryWithFreshAccessToken?: boolean;
  isAuthenticated?: boolean;
}

export abstract class BaseGrpcTransport {
  protected readonly _transport: Transport;

  public constructor(
    private readonly _accessTokenProvider: AccessTokenProvider,
    environmentService: EnvironmentService
  ) {
    // https://github.com/SafetyCulture/grpc-web-devtools#connect-web
    // __CONNECT_WEB_DEVTOOLS__ is loaded in as a script, so it is not guaranteed to be loaded before your code.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const interceptors: Interceptor[] =
      window?.__CONNECT_WEB_DEVTOOLS__ != null ? [window.__CONNECT_WEB_DEVTOOLS__] : [];
    // To get around the fact that __CONNECT_WEB_DEVTOOLS__ might not be loaded, we can listen for a custom event,
    // and then push the interceptor to our array once loaded.
    window?.addEventListener('connect-web-dev-tools-ready', () => {
      if (window?.__CONNECT_WEB_DEVTOOLS__ != null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        interceptors.push(window.__CONNECT_WEB_DEVTOOLS__);
      }
    });

    this._transport = createGrpcWebTransport({ baseUrl: environmentService.apiV3Url, interceptors: interceptors });
  }

  /**
   * Send an authenticated request to the api that does not modify or create data.
   * If the request fails, the accessToken will be force-refreshed. Then, if it still fails, the error will be thrown.
   * @param service The service the use for the request.
   * @param method The method the use for the request
   * @param request The request object.
   * @param options Additional options fo the request.
   * @private
   * @return Promise<TResponse> The api request response.
   */
  protected async performRequest<I extends Message<I>, O extends Message<O>>(
    service: ServiceType,
    method: MethodInfo<I, O>,
    request: PartialMessage<I>,
    options: ReadRequestParams = {}
  ): Promise<O> {
    const { shouldRetryWithFreshAccessToken, isAuthenticated = true } = options;

    let headers: HeadersInit = {};
    if (isAuthenticated) {
      const accessToken = await this._accessTokenProvider.getFreshAccessToken(shouldRetryWithFreshAccessToken === true);

      headers = {
        Authorization: `Bearer ${accessToken}`
      };
    }

    try {
      const response = await this._transport.unary(
        service,
        method,
        undefined, // AbortSignal
        undefined, // Timeout
        headers,
        request
      );
      return response.message;
    } catch (err) {
      console.log(`API call "${method.name}" failed with ${(err as Error).message}.`);

      if (isUnauthenticatedError(err) && isAuthenticated && shouldRetryWithFreshAccessToken === undefined) {
        console.log(`Retrying with a force-refreshed access token...`);
        return await this.performRequest(service, method, request, {
          ...options,
          shouldRetryWithFreshAccessToken: true
        });
      }

      throw err;
    }
  }
}
