import { AppStorageModel, AppStorageProperty, StorageModel, StorageProperty } from '@insights/utils';
import { Storage } from '@shared/services';
import { computed, makeObservable } from 'mobx';

const HideLockedContentStorageKey = 'OnboardingPreferencesHideLockedContent';

export interface OnboardingPreferences extends StorageModel {
  hideLockedContent: boolean;
}

export class AppOnboardingPreferences extends AppStorageModel implements OnboardingPreferences {
  private readonly _hideLockedContent: StorageProperty<boolean>;

  constructor(storage: Storage) {
    super(storage);

    makeObservable(this);

    this.setStorageProperties([
      (this._hideLockedContent = new AppStorageProperty<boolean>(storage, HideLockedContentStorageKey, true))
    ]);
  }

  @computed
  get hideLockedContent(): boolean {
    return this._hideLockedContent.value;
  }

  set hideLockedContent(value: boolean) {
    this._hideLockedContent.value = value;
  }
}
