import { ExternalFileSource as PBExternalFileSource } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { BaseModel } from '../../Model';
import { ExternalFileSource } from '../interfaces';

export class GrpcExternalFileSource extends BaseModel<PBExternalFileSource> implements ExternalFileSource {
  constructor(pb: PBExternalFileSource) {
    super(pb);
  }

  get id() {
    return this._pb.id;
  }

  get baseUrl() {
    return this._pb.baseUrl;
  }

  get port() {
    return this._pb.port;
  }

  get username() {
    return this._pb.username;
  }

  get password() {
    return this._pb.password;
  }
}
