import { Message } from '@bufbuild/protobuf';
import _ from 'lodash';
import { IObservableArray, action, computed, makeObservable, observable } from 'mobx';
import { Day, Time } from './types';

interface ChangeableProperty<THost> {
  readonly isChanged: boolean;

  apply(host: THost): void;
  reset(): void;

  applyFrom(other: ChangeableProperty<THost>): void;
}

export class EditableProperty<T, THost> implements ChangeableProperty<THost> {
  @observable private _value?: T;

  constructor(
    public readonly originalValue: T,
    private readonly _apply: (host: THost, value: T) => void,
    private readonly _areEqual: (first: T, second: T) => boolean
  ) {
    makeObservable(this);
  }

  @computed
  get value() {
    return this._value ?? this.originalValue;
  }

  set value(v: T) {
    if (this._areEqual(v, this.originalValue)) {
      this._value = undefined;
    } else {
      this._value = v;
    }
  }

  @computed
  get isChanged() {
    return this._value !== undefined;
  }

  apply(host: THost): void {
    if (this.isChanged) {
      this._apply(host, this._value!);
    }
  }

  @action
  reset() {
    this._value = undefined;
  }

  @action
  applyFrom(other: ChangeableProperty<THost>) {
    if (!other.isChanged) {
      return;
    }

    const realOther = other as EditableProperty<T, THost>;

    if (realOther == null) {
      throw new Error('Invalid operation. Trying to apply property from a different type.');
    }

    this.value = realOther.value;
  }
}

export class EditableDynamicProperty<T, THost> implements ChangeableProperty<THost> {
  @observable private _value?: T;

  constructor(
    private _originalValue: T | undefined,
    private readonly _apply: (host: THost, value: T) => void,
    private readonly _areEqual: (first: T, second: T) => boolean
  ) {
    makeObservable(this);
  }

  get originalValue(): T {
    if (this._originalValue === undefined) {
      this._originalValue = this.createDefaultValue();
    }

    return this._originalValue;
  }

  @computed
  get value() {
    return this._value ?? this.originalValue;
  }

  set value(v: T) {
    if (this._areEqual(v, this.originalValue)) {
      this._value = undefined;
    } else {
      this._value = v;
    }
  }

  @computed
  get isChanged() {
    return this._value !== undefined;
  }

  apply(host: THost): void {
    if (this.isChanged) {
      this._apply(host, this._value!);
    }
  }

  @action
  reset() {
    this._value = undefined;
  }

  @action
  applyFrom(other: ChangeableProperty<THost>) {
    if (!other.isChanged) {
      return;
    }

    const realOther = other as EditableProperty<T, THost>;

    if (realOther == null) {
      throw new Error('Invalid operation. Trying to apply property from a different type.');
    }

    this.value = realOther.value;
  }

  protected createDefaultValue(): T {
    throw new Error('Derived classes must override this method.');
  }
}

export class EditableChildProperty<TProtobuf extends Message<TProtobuf>, T extends EditableModel<TProtobuf>, THost>
  implements ChangeableProperty<THost>
{
  @observable private _value?: T;

  constructor(
    public readonly originalValue: T,
    private readonly _apply: (host: THost, value: TProtobuf) => void,
    private readonly _areEqual: (first: T, second: T) => boolean
  ) {
    makeObservable(this);
  }

  @computed
  get value() {
    return this._value ?? this.originalValue;
  }

  set value(v: T) {
    if (this._areEqual(v, this.originalValue)) {
      this._value = undefined;
    } else {
      this._value = v;
    }
  }

  @computed
  get isChanged() {
    return this._value !== undefined || this.originalValue.hasChanges;
  }

  apply(host: THost): void {
    if (this._value !== undefined) {
      this._apply(host, this._value.toProtobuf());
    } else if (this.originalValue.hasChanges) {
      this._apply(host, this.originalValue.toProtobuf());
    }
  }

  @action
  reset() {
    this._value = undefined;
    this.originalValue.resetChanges();
  }

  @action
  applyFrom(other: ChangeableProperty<THost>) {
    if (!other.isChanged) {
      return;
    }

    const realOther = other as EditableChildProperty<TProtobuf, T, THost>;

    if (realOther == null) {
      throw new Error('Invalid operation. Trying to apply property from a different type.');
    }

    this.value = realOther.value;
  }
}

export class EditableNullableProperty<T, THost> implements ChangeableProperty<THost> {
  @observable private _value: T | undefined;
  @observable private _isChanged = false;
  @observable private _isDisabled = false;

  constructor(
    public readonly originalValue: T | undefined,
    private readonly _apply: (host: THost, value: T | undefined) => void,
    private readonly _areEqual: (first: T | undefined, second: T | undefined) => boolean,
    private readonly _originalIsDisabled = false
  ) {
    makeObservable(this);
    this._isDisabled = _originalIsDisabled;
  }

  @computed
  get value(): T | undefined {
    return this._isDisabled ? undefined : this._isChanged ? this._value : this.originalValue;
  }

  set value(v: T | undefined) {
    if (this._areEqual(v, this.originalValue)) {
      this._value = undefined;
      this._isChanged = false;
    } else {
      this._value = v;
      this._isChanged = true;
    }
  }

  @computed
  get isChanged() {
    // If disabled and originally disabled, must not consider new value.
    return this._isDisabled != this._originalIsDisabled || (!this._isDisabled && this._isChanged);
  }

  @computed
  get isDisabled() {
    return this._isDisabled;
  }

  set isDisabled(value: boolean) {
    this._isDisabled = value;
  }

  apply(host: THost): void {
    if (this.isChanged) {
      this._apply(host, this.value);
    }
  }

  @action
  reset() {
    this._value = undefined;
    this._isChanged = false;
    this._isDisabled = this._originalIsDisabled;
  }

  @action
  applyFrom(other: ChangeableProperty<THost>) {
    if (!other.isChanged) {
      return;
    }

    const realOther = other as EditableNullableProperty<T, THost>;

    if (realOther == null) {
      throw new Error('Invalid operation. Trying to apply property from a different type.');
    }

    this.value = realOther.value;
    this.isDisabled = realOther.isDisabled;
  }
}

export class EditableChildNullableProperty<
  TProtobuf extends Message<TProtobuf>,
  T extends EditableModel<TProtobuf>,
  THost
> implements ChangeableProperty<THost>
{
  @observable private _value: T | undefined;
  @observable private _isChanged = false;
  @observable private _isDisabled = false;

  constructor(
    public readonly originalValue: T | undefined,
    private readonly _apply: (host: THost, value: TProtobuf | undefined) => void,
    private readonly _areEqual: (first: T | undefined, second: T | undefined) => boolean,
    private readonly _originalIsDisabled = false
  ) {
    makeObservable(this);
    this._isDisabled = _originalIsDisabled;
  }

  @computed
  get value(): T | undefined {
    return this._isDisabled ? undefined : this._isChanged ? this._value : this.originalValue;
  }

  set value(v: T | undefined) {
    if (this._areEqual(v, this.originalValue)) {
      this._value = undefined;
      this._isChanged = false;
    } else {
      this._value = v;
      this._isChanged = true;
    }
  }

  @computed
  get isChanged() {
    // If disabled and originally disabled, must not consider new value.
    return (
      this._isDisabled != this._originalIsDisabled ||
      (!this._isDisabled && (this._isChanged || this.originalValue?.hasChanges === true))
    );
  }

  @computed
  get isDisabled() {
    return this._isDisabled;
  }

  set isDisabled(value: boolean) {
    this._isDisabled = value;
  }

  apply(host: THost): void {
    if (this._isChanged) {
      this._apply(host, this.value == null ? undefined : this.value.toProtobuf());
    } else if (this.originalValue?.hasChanges === true) {
      this._apply(host, this.originalValue.toProtobuf());
    }
  }

  @action
  reset() {
    this._value = undefined;
    this._isChanged = false;
    this._isDisabled = this._originalIsDisabled;

    if (this.originalValue != null) {
      this.originalValue.resetChanges();
    }
  }

  @action
  applyFrom(other: ChangeableProperty<THost>) {
    if (!other.isChanged) {
      return;
    }

    const realOther = other as EditableChildNullableProperty<TProtobuf, T, THost>;

    if (realOther == null) {
      throw new Error('Invalid operation. Trying to apply property from a different type.');
    }

    this.value = realOther.value;
    this.isDisabled = realOther.isDisabled;
  }
}

// Add other value-like types as needed here -----v
export class EditableValueProperty<TValue extends string | number | boolean, THost> extends EditableProperty<
  TValue,
  THost
> {
  constructor(originalValue: TValue, apply: (host: THost, value: TValue) => void) {
    super(originalValue, apply, (first, second) => first === second);
  }
}

// Same here ------------------------------------------v
export class EditableValueArrayProperty<TValue extends string | number | boolean, THost> extends EditableProperty<
  TValue[],
  THost
> {
  constructor(originalValues: TValue[], apply: (host: THost, values: TValue[]) => void) {
    super(
      originalValues,
      apply,
      (first, second) => first.length === second.length && first.every((value, index) => value === second[index])
    );
  }
}

// Add here ----------------------------------------------v
export class EditableNullableValueProperty<
  TValue extends string | number | boolean,
  THost
> extends EditableNullableProperty<TValue, THost> {
  constructor(
    originalValue: TValue | undefined,
    apply: (host: THost, value: TValue | undefined) => void,
    originalIsDisabled = false
  ) {
    super(originalValue, apply, (first, second) => first === second, originalIsDisabled);
  }
}

// Also here --------------------------------------------------v
export class EditableNullableValueArrayProperty<
  TValue extends string | number | boolean,
  THost
> extends EditableProperty<(TValue | undefined)[], THost> {
  constructor(originalValues: (TValue | undefined)[], apply: (host: THost, values: (TValue | undefined)[]) => void) {
    super(
      originalValues,
      apply,
      (first, second) => first.length === second.length && first.every((value, index) => value === second[index])
    );
  }
}

// Add more "specialty" properties as needed.
export class EditableDayProperty<THost> extends EditableProperty<Day, THost> {
  constructor(originalDay: Day, apply: (host: THost, value: Day) => void) {
    super(originalDay, apply, (first, second) => first.isSame(second));
  }
}

export class EditableTimeProperty<THost> extends EditableDynamicProperty<Time, THost> {
  constructor(
    originalTime: Time | undefined,
    apply: (host: THost, value: Time) => void,
    private readonly _defaultHour?: number,
    private readonly _defaultMinute?: number
  ) {
    super(originalTime, apply, (first, second) => first.isSame(second));
  }

  protected createDefaultValue(): Time {
    return Time.create({
      hour: this._defaultHour ?? 8,
      minute: this._defaultMinute ?? 0
    });
  }
}

export class EditableNullableDayProperty<THost> extends EditableNullableProperty<Day, THost> {
  constructor(
    originalDay: Day | undefined,
    apply: (host: THost, value: Day | undefined) => void,
    originalIsDisabled = false
  ) {
    super(
      originalDay,
      apply,
      (first, second) =>
        (first === undefined && second === undefined) ||
        (first !== undefined && second !== undefined && first.isSame(second)),
      originalIsDisabled
    );
  }
}

export class EditableNullableTimeProperty<THost> extends EditableNullableProperty<Time, THost> {
  constructor(
    originalTime: Time | undefined,
    apply: (host: THost, value: Time | undefined) => void,
    originalIsDisabled = false
  ) {
    super(
      originalTime,
      apply,
      (first, second) =>
        (first === undefined && second === undefined) ||
        (first !== undefined && second !== undefined && first.isSame(second)),
      originalIsDisabled
    );
  }
}

export class EditableNullableDateProperty<THost> extends EditableNullableProperty<Date, THost> {
  constructor(
    originalDate: Date | undefined,
    apply: (host: THost, value: Date | undefined) => void,
    originalIsDisabled = false
  ) {
    super(
      originalDate,
      apply,
      (first, second) =>
        (first === undefined && second === undefined) ||
        (first !== undefined && second !== undefined && first.getTime() === second.getTime()),
      originalIsDisabled
    );
  }
}

export class EditableListProperty<
  TProtobuf extends Message<TProtobuf>,
  TChildEditable extends ExportableModel<TProtobuf>,
  THost
> implements ChangeableProperty<THost>
{
  @observable private _values: IObservableArray<TChildEditable>;
  @observable private _isSorted = false;

  constructor(
    private readonly _originalChildren: TChildEditable[],
    private readonly _apply: (host: THost, values: TProtobuf[]) => void,
    private readonly _shouldHideDeleted = false
  ) {
    makeObservable(this);
    this._values = observable.array(_originalChildren);
  }

  @computed
  get values() {
    return this._shouldHideDeleted ? this._values.filter((value) => !value.shouldBeDeleted) : this._values;
  }

  @computed
  get isChanged() {
    // Deleted items report "hasChanges" as true, but not created items.
    return this._values.some((value) => value.hasChanges || value.shouldBeCreated) || this._isSorted;
  }

  @action
  addItem(item: TChildEditable): void {
    // There's no such thing as "moving" an existing item.
    // It must be deleted from a source, and a copy added to a destination.
    if (!item.shouldBeCreated) {
      throw new Error('Invalid operation. Cannot add an item not marked as "should be created".');
    }

    this._values.push(item);
  }

  @action
  sortBy(selector: (item: TChildEditable) => string | number) {
    this._values.replace(_.sortBy(this._values, selector));

    // Because sorting does not affect items, we must remember this change. For now,
    // we don't care if it actually changed something.
    this._isSorted = true;
  }

  @action
  apply(host: THost): void {
    const pbs = this._values.filter((value) => !value.shouldBeDeleted).map((value) => value.toProtobuf());
    this._apply(host, pbs);
  }

  @action
  reset(): void {
    this._values.replace(this._originalChildren);
    this._values.forEach((item) => item.resetChanges());
    this._isSorted = false;
  }

  @action
  applyFrom(other: ChangeableProperty<THost>) {
    if (!other.isChanged) {
      return;
    }

    const realOther = other as EditableListProperty<TProtobuf, TChildEditable, THost>;

    if (realOther == null) {
      throw new Error('Invalid operation. Trying to apply property from a different type.');
    }

    // Replace all values, deleted or not.
    this._values.replace(realOther._values);
    // The change might only be the sorting.
    this._isSorted = realOther._isSorted;
  }
}

interface ExportableModel<TProtobuf extends Message<TProtobuf>> {
  readonly hasChanges: boolean;
  readonly shouldBeCreated: boolean;
  readonly shouldBeDeleted: boolean;

  resetChanges(): void;
  toProtobuf(): TProtobuf;

  applyFrom(other: ExportableModel<TProtobuf>): void;
}

export abstract class EditableModel<TProtobuf extends Message<TProtobuf>> implements ExportableModel<TProtobuf> {
  private _fields?: IObservableArray<ChangeableProperty<TProtobuf>>;
  @observable private _pb?: TProtobuf;
  @observable private _shouldBeDeleted = false;

  protected constructor(
    pb?: TProtobuf,
    private readonly _isNew = false
  ) {
    makeObservable(this);
    // Fields are not injected at construction, because it causes an ugly pattern in derived classes.
    // Derived classes must call setFields from their constructor once each field is created.
    this._pb = pb;
    this.setFields([]);
  }

  @computed
  get hasChanges() {
    return this._shouldBeDeleted || this._fields!.some((field) => field.isChanged);
  }

  get shouldBeCreated() {
    return this._isNew;
  }

  @computed
  get shouldBeDeleted(): boolean {
    return this._shouldBeDeleted;
  }

  @action
  public markAsDeleted(): void {
    // TODO: Some gymnastic needed for deleted new items. ;)
    this._shouldBeDeleted = true;
  }

  @action
  public resetChanges(): void {
    this._shouldBeDeleted = false;
    this._fields!.forEach((field) => field.reset());
    // If it's new, it's still new!
  }

  toProtobuf(): TProtobuf {
    if (this._pb == null) {
      this._pb = this.createProtobuf();
    }

    const pb = this._pb.clone();

    this._fields!.forEach((field) => field.apply(pb));
    return pb;
  }

  applyFrom(other: EditableModel<TProtobuf>): void {
    if (other.shouldBeCreated && !this.shouldBeCreated) {
      // You cannot apply a created model to an existing one.
      throw new Error('Invalid operation. Cannot apply created model.');
    }

    if (other.shouldBeDeleted) {
      this.markAsDeleted();
      // We still update each field.
    }

    const otherFields = other._fields;

    if (this._fields == null || otherFields == null || this._fields.length !== otherFields.length) {
      throw new Error('Invalid operation. Editable models are incompatible.');
    }

    _.zip(otherFields, this._fields).forEach(([otherField, thisField]) => thisField!.applyFrom(otherField!));
  }

  protected setFields(fields: ChangeableProperty<TProtobuf>[]) {
    if (this._fields != null && this._fields.length > 0) {
      throw new Error("Invalid operation. Fields should be set once in the derived class' constructor.");
    }

    this._fields = observable.array<ChangeableProperty<TProtobuf>>(fields);
  }

  protected createProtobuf(): TProtobuf {
    throw new Error('Derived classes must override this method when a null message can be originally provided.');
  }
}
