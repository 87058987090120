import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/services/subscriptions_pb';
import * as SPB from '@buf/studyo_studyo.bufbuild_es/studyo/type_subscriptions_pb';
import { Subscriptions } from '@buf/studyo_studyo.connectrpc_es/studyo/services/subscriptions_connect';
import { SubscriptionsTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class SubscriptionsGrpcTransport extends BaseGrpcTransport implements SubscriptionsTransport {
  async getSchoolSubscription(configId: string): Promise<SPB.SchoolSubscription | undefined> {
    const request = new PB.GetSchoolSubscriptionRequest();
    request.configId = configId;

    const response = await this.performRequest(Subscriptions, Subscriptions.methods.getSchoolSubscription, request);

    return response.subscription;
  }

  async addSchoolRequiredFeature(
    configId: string,
    entitlement: SPB.SubscriptionEntitlement,
    stripePriceId: string
  ): Promise<SPB.SchoolSubscription> {
    const request = new PB.AddRequiredFeatureRequest();
    request.configId = configId;
    request.entitlement = entitlement;
    request.stripePriceId = stripePriceId;

    const response = await this.performRequest(Subscriptions, Subscriptions.methods.addRequiredFeature, request);

    const subscription = response.subscription;

    if (subscription == null) {
      throw new Error('Unexpected empty subscription received after adding required feature');
    }

    return subscription;
  }

  async removeSchoolRequiredFeature(
    configId: string,
    entitlement: SPB.SubscriptionEntitlement
  ): Promise<SPB.SchoolSubscription> {
    const request = new PB.RemoveRequiredFeatureRequest();
    request.configId = configId;
    request.entitlement = entitlement;

    const response = await this.performRequest(Subscriptions, Subscriptions.methods.removeRequiredFeature, request);

    const subscription = response.subscription;

    if (subscription == null) {
      throw new Error('Unexpected empty subscription received after removing required feature');
    }

    return subscription;
  }

  async getCheckoutUrl(
    configId: string,
    accountId: string,
    entitlement: SPB.SubscriptionEntitlement,
    successReturnUrl: string,
    cancelreturnUrl: string
  ): Promise<string> {
    const request = new PB.GetCheckoutUrlRequest();
    request.configId = configId;
    request.accountId = accountId;
    request.entitlement = entitlement;
    request.successReturnUrl = successReturnUrl;
    request.cancelReturnUrl = cancelreturnUrl;

    const response = await this.performRequest(Subscriptions, Subscriptions.methods.getCheckoutUrl, request, {
      isAuthenticated: false
    });

    return response.checkoutUrl;
  }
}
