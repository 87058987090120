import { AuthorizationRole } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';

export interface AuthorizationRoleConditionProps extends PropsWithChildren {
  /**
   * An array of all the allowed roles.
   *
   * **NOTE:** If your are interested in roles other that `super-admin`,
   * you must specify the `configId` or else, you will always have
   * `false` as a result.
   */
  allowedRoles: AuthorizationRole[];

  /**
   * An array of all roles to block.
   *
   * **NOTE:** Useful to display something for a minor role (e.g teacher)
   * only when a more important role is not present (e.g. admin).
   */
  blockedRoles?: AuthorizationRole[];

  /**
   * This allows enabling a feature while in demo mode. This bypasses the
   * user's roles and directly checks if user is a root admin.
   */
  allowRootAdmins?: boolean;

  /**
   * An array of specific account ids to allow, whatever if their role is
   * part of the allowedRoles property. Blocked roles still apply.
   *
   * **NOTE**: This can be used in the onboarding to allow an assigned client
   * access to something otherwise only visible to admins.
   */
  allowedAccountIds?: string[];
}

export const AuthorizationRoleCondition = observer((props: AuthorizationRoleConditionProps) => {
  const { accountService } = useInsightsServices();
  const {
    allowedRoles,
    allowedAccountIds = [],
    blockedRoles = [],
    allowRootAdmins = false,

    children
  } = props;

  const isAllowed =
    (accountService.isAllowed(allowedRoles) || accountService.isAccount(allowedAccountIds)) &&
    (blockedRoles.length === 0 || !accountService.isAllowed(blockedRoles));

  if (!isAllowed && (!allowRootAdmins || !accountService?.isRootAdmin)) {
    return null;
  }

  return <>{children}</>;
});
