import { AlertService } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { EditableOnboardingComment } from '@shared/models/onboarding/implementations';
import { OnboardingComment } from '@shared/models/onboarding/interfaces';
import { OnboardingTextFormat } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { DefaultOnboardingVariableResolver, OnboardingVariableResolver } from './OnboardingVariableResolver';

export interface OnboardingCommentViewModel {
  readonly from?: AccountModel;
  readonly date?: Date;
  readonly parsedMessage: string;
  readonly format: OnboardingTextFormat;
  readonly isPrivate: boolean;

  readonly canEdit: boolean;
  readonly isEditing: boolean;
  readonly editableComment: EditableOnboardingComment;

  readonly isNew: boolean;
  readonly isMine: boolean;
  readonly isStudyoUser: boolean;
  readonly isStepBlocked: boolean | undefined;
  readonly canCancel: boolean;
  readonly canSave: boolean;

  edit(): void;
  delete(): Promise<void>;
  save(): Promise<void>;
  cancel(): void;

  uploadFile(dataUrl: string, fileName?: string): Promise<void>;
}

export class AppOnboardingCommentViewModel implements OnboardingCommentViewModel {
  private _variableResolver: OnboardingVariableResolver;
  @observable private readonly _editableComment: EditableOnboardingComment;
  @observable private _isEditing: boolean;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _accountsById: Record<string, AccountModel>,
    private readonly _accountId: string,
    readonly isStudyoUser: boolean,
    editableComment: EditableOnboardingComment,
    public readonly canEdit: boolean,
    readonly isStepBlocked: boolean | undefined,
    private readonly _onSaved?: (comment: OnboardingComment) => void,
    private readonly _onDeleted?: () => void
  ) {
    makeObservable(this);
    this._variableResolver = new DefaultOnboardingVariableResolver(editableComment.configId);
    this._editableComment = editableComment;
    this._isEditing = editableComment.id.length === 0;
  }

  @computed
  get from() {
    return this._accountsById[this._editableComment.fromAccountId];
  }

  @computed
  get date() {
    return this._editableComment.createdAt;
  }

  @computed
  get parsedMessage() {
    return this._variableResolver.parseText(this._editableComment.message);
  }

  @computed
  get format() {
    return this._editableComment.format;
  }

  @computed
  get isPrivate() {
    return this._editableComment.isPrivate;
  }

  @computed
  get isEditing() {
    return this._isEditing;
  }

  @computed
  get editableComment() {
    return this._editableComment;
  }

  @computed
  get isNew() {
    return this._editableComment.id.length === 0;
  }

  @computed
  get isMine() {
    return this._editableComment.fromAccountId === this._accountId;
  }

  @computed
  get canCancel() {
    return this._isEditing && (!this.isNew || this.editableComment.message.length > 0);
  }

  @computed
  get canSave() {
    return this.editableComment.shouldBeCreated
      ? this.editableComment.message.length > 0
      : this.editableComment.hasChanges;
  }

  @action
  edit() {
    if (this.canEdit) {
      this._isEditing = true;
    }
  }

  @action
  async delete(): Promise<void> {
    if (!this.canEdit) {
      return;
    }

    const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
    const response = await this._alertService.showConfirmation({
      title: strings.confirmDeleteCommentTitle,
      message: strings.confirmDeleteCommentMessage,
      okButtonCaption: strings.deleteButtonLabel
    });

    if (response !== 'cancelled') {
      try {
        await this._onboardingStore.deleteComment(this._editableComment.id);

        this._onDeleted?.();
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      }
    }
  }

  @action
  async save(): Promise<void> {
    if (!this.canEdit) {
      return;
    }

    try {
      const comment = await this._onboardingStore.addOrEditComment(this._editableComment);

      this._onSaved?.(comment);

      if (!this.isNew) {
        runInAction(() => (this._isEditing = false));
      }
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    }
  }

  @action
  cancel(): void {
    if (!this.canEdit) {
      return;
    }

    if (this.isNew) {
      // Simply clear the message.
      this._editableComment.message = '';
    } else {
      this._editableComment.resetChanges();
      this._isEditing = false;
    }
  }

  async uploadFile(dataUrl: string, fileName?: string): Promise<void> {
    const fileUrl = await this._onboardingStore.uploadResourceFile(dataUrl, this._editableComment.configId, fileName);
    await navigator.clipboard.writeText(fileUrl);
  }
}
