import { NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { BlackbaudConnectorStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppBlackbaudMappingsViewModel, BlackbaudMappingsViewModel } from './BlackbaudMappingsViewModel';

export interface BlackbaudMappingsDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly mappings: IPromiseBasedObservable<BlackbaudMappingsViewModel>;
}

export class AppBlackbaudMappingsDialogViewModel implements BlackbaudMappingsDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _blackbaudStore: BlackbaudConnectorStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get mappings(): IPromiseBasedObservable<BlackbaudMappingsViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<BlackbaudMappingsViewModel> {
    const [details, assignmentKinds] = await Promise.all([
      this._blackbaudStore.getBlackbaudAccountDetails(this.externalAccountId),
      this._blackbaudStore.getBlackbaudAssignmentKinds(this.externalAccountId)
    ]);

    return new AppBlackbaudMappingsViewModel(
      this._localizationService,
      this._blackbaudStore,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      assignmentKinds,
      details
    );
  }
}
