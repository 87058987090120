export interface LoginStrings {
  readonly welcome: string;
  readonly userMustLogin: string;
  readonly login: string;
  readonly loginError: string;
  readonly signUp: string;
  readonly privacyPolicyAlertText: string;
  readonly privacyPolicyConfirmMessage: string;
  readonly french: string;
  readonly english: string;
}

export const EnglishLoginStrings: LoginStrings = {
  welcome: 'Welcome!',
  userMustLogin: 'To access Studyo, you first need to be logged in.',
  login: 'Login',
  loginError: 'An error occurred while trying to log you in.',
  signUp: 'Create an account',
  privacyPolicyAlertText: 'You must agree to the terms in order to create an account:',
  privacyPolicyConfirmMessage: `I have read and agree to Studyo's <a href="https://studyo.co/privacy" target="_blank" style="color: inherit">privacy policy</a> and <a href="https://studyo.co/tos" target="_blank" rel="noreferrer" style="color: inherit">terms of service</a>.`,

  french: 'Français',
  english: 'English'
};

export const FrenchLoginStrings: LoginStrings = {
  welcome: 'Bienvenue!',
  userMustLogin: 'Pour accéder à Studyo, vous devez d’abord vous connecter',
  login: 'Connexion',
  loginError: 'Une erreur est survenue lors de la connexion.',
  signUp: 'Créer un compte',
  privacyPolicyAlertText: 'Vous devez consentir à nos politiques pour créer un compte:',
  privacyPolicyConfirmMessage: `J’ai lu et j’accepte la <a href="https://studyo.co/fr/privacy" target="_blank" style="color: inherit">politique de confidentialité</a> et les <a href="https://studyo.co/fr/tos" target="_blank" rel="noreferrer" style="color: inherit">conditions d’utilisation</a> de Studyo.`,

  french: 'Français',
  english: 'English'
};
