import {
  BlackbaudExternalAccountDetails,
  BlackbaudSchoolLevel,
  BlackbaudSchoolYear
} from '@shared/models/connectors/interfaces';
import { LocalizationService } from '@shared/resources/services';
import { BlackbaudConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface BlackbaudAccountSettingsViewModel extends ExternalAccountEditionViewModel {
  yearLabel: string;
  levelId: bigint;
  readonly levelName: string;
  includeNonLeadSections: boolean;

  readonly availableYears: BlackbaudSchoolYear[];
  readonly availableLevels: BlackbaudSchoolLevel[];
}

export class AppBlackbaudAccountSettingsViewModel
  extends BaseExternalAccountEditionViewModel
  implements BlackbaudAccountSettingsViewModel
{
  @observable private _yearLabel?: string;
  @observable private _levelId?: bigint;
  @observable private _levelName?: string;
  @observable private _includeNonLeadSections?: boolean;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _blackbaudStore: BlackbaudConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    public readonly availableYears: BlackbaudSchoolYear[],
    public readonly availableLevels: BlackbaudSchoolLevel[],
    private readonly _originalDetails: BlackbaudExternalAccountDetails
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get yearLabel() {
    return this._yearLabel ?? this._originalDetails.yearLabel;
  }

  set yearLabel(value: string) {
    this._yearLabel = value;
    this.onChange();
  }

  @computed
  get levelId() {
    return this._levelId ?? this._originalDetails.levelId;
  }

  set levelId(value: bigint) {
    const level = this.availableLevels.find((level) => level.id === value);

    this._levelId = value;
    this._levelName = level?.name;
    this.onChange();
  }

  @computed
  get levelName() {
    return this._levelName ?? this._originalDetails.levelName;
  }

  @computed
  get includeNonLeadSections() {
    return this._includeNonLeadSections ?? this._originalDetails.includeNonLeadSections;
  }

  set includeNonLeadSections(value: boolean) {
    this._includeNonLeadSections = value;
    this.onChange();
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this.hasChanges) {
      console.error('Applying without changes, ignoring...');
      this._onSuccess();
      return;
    }

    this.beginApplying();

    try {
      await this._blackbaudStore.updateBlackbaudAccountSettings(
        this._configId,
        this._externalAccountId,
        this.yearLabel,
        this.levelId,
        this.levelName,
        this._originalDetails.kindMappings,
        this.includeNonLeadSections
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._yearLabel = undefined;
    this._levelId = undefined;
    this._levelName = undefined;
    this._includeNonLeadSections = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this.hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
