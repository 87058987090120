import { GrpcTeamsAssignmentCategory, GrpcTeamsExternalAccountDetails } from '@shared/models/connectors';
import {
  TeamsAccountDetailsModel,
  TeamsAssignmentCategoryMappingModel,
  TeamsAssignmentCategoryModel
} from '@shared/models/connectors/interfaces';
import { MicrosoftTeamsTransport } from '../../../transports';
import { ConnectorsStore, MicrosoftTeamsConnectorStore } from '../../interfaces';
import { AppBaseStore } from '../AppBaseStore';

export class AppMicrosoftTeamsConnectorStore extends AppBaseStore implements MicrosoftTeamsConnectorStore {
  constructor(
    private readonly _transport: MicrosoftTeamsTransport,
    connectorStore: ConnectorsStore
  ) {
    super('AppMicrosoftTeamsConnectorStore', false, connectorStore);
  }

  async getTeamsAccountDetails(configId: string, externalAccountId: string): Promise<TeamsAccountDetailsModel> {
    const details = await this._transport.fetchTeamsExternalAccountDetails(externalAccountId);
    return new GrpcTeamsExternalAccountDetails(details);
  }

  async createOrUpdateTeamsAccount(
    configId: string,
    externalAccountId: string,
    accountName: string,
    tenantId: string
  ): Promise<void> {
    await this._transport.createOrUpdateTeamsExternalAccount(externalAccountId, configId, accountName, tenantId);
    this.invalidate();
  }

  async updateTeamsAccountSettings(
    configId: string,
    externalAccountId: string,
    mappings: TeamsAssignmentCategoryMappingModel[],
    ignoreUnmappedCategories: boolean,
    customClassesFilter: string
  ): Promise<void> {
    await this._transport.updateTeamsExternalAccountSettings(
      externalAccountId,
      configId,
      mappings.map((m) => m.toProtobuf()),
      ignoreUnmappedCategories,
      customClassesFilter
    );
    this.invalidate();
  }

  async getAssignmentCategories(externalAccountId: string): Promise<TeamsAssignmentCategoryModel[]> {
    const categories = await this._transport.fetchTeamsAssignmentCategories(externalAccountId);
    return categories.map((c) => new GrpcTeamsAssignmentCategory(c));
  }
}
