import { Box, SxProps } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { OrderedSpecialDaySymbols, SpecialDaySymbol } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { SpecialDaySymbolImage } from './SpecialDaySymbolImage';

export type SpecialDaySymbolGridDisplayKind = 'stacked' | 'grid' | 'linear';

export interface SpecialDaySymbolAndColor {
  symbol: SpecialDaySymbol;
  color?: string;
}

export interface SpecialDaySymbolGridProps {
  sx?: SxProps;
  className?: string;
  displayKind?: SpecialDaySymbolGridDisplayKind;
  symbols: SpecialDaySymbolAndColor[];
  squareSize?: number;
  alwaysDisplaySymbol?: boolean;
  displayNoneSymbol?: boolean;
  maxSymbolCount?: number;
}

/**
 *
 * A view which display all symbols of a special day together in multiple layout.
 */

export const SpecialDaySymbolGrid = observer((props: SpecialDaySymbolGridProps) => {
  const {
    sx = [],
    className,
    symbols,
    alwaysDisplaySymbol,
    maxSymbolCount,
    displayNoneSymbol,
    displayKind,
    squareSize
  } = props;
  const filteredSymbols = displayNoneSymbol === false ? symbols.filter((s) => s.symbol !== 'none') : symbols;

  const symbolSize = squareSize ?? 36;

  const renderStack = (symbols: SpecialDaySymbolAndColor[], symbolSize: number) => {
    return (
      <Box sx={{ ...sx, position: 'relative', width: symbolSize, height: symbolSize }} className={className}>
        {OrderedSpecialDaySymbols.filter((s) => filteredSymbols.find((symbol) => symbol.symbol === s) != null)
          .map((s) => symbols.find((symbol) => symbol.symbol === s))
          .filter((symbol) => symbol != null)
          .map((symbol) => (
            <Box key={symbol.symbol} sx={{ position: 'absolute', left: 0, top: 0 }}>
              <SpecialDaySymbolImage
                squareSize={symbolSize}
                symbol={symbol?.symbol}
                color={symbol?.color ?? SectionColors.get('medium-bmgray')}
                alwaysDisplaySymbol={alwaysDisplaySymbol}
              />
            </Box>
          ))}
      </Box>
    );
  };

  const renderGrid = (_symbols: SpecialDaySymbolAndColor[], symbolSize: number) => {
    const sliceEnd = maxSymbolCount ?? 4;

    return (
      <Box
        className={className}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          ...sx,
          width: symbolSize * 2 + 4,
          height: symbolSize * 2 + 4
        }}
      >
        {filteredSymbols.slice(0, sliceEnd).map((symbol, index) => (
          <Box key={`${symbol.symbol}${index}`} sx={{ width: symbolSize, height: symbolSize, margin: '1px' }}>
            <SpecialDaySymbolImage
              squareSize={symbolSize}
              symbol={symbol.symbol}
              color={symbol.color ?? SectionColors.get('medium-bmgray')}
              alwaysDisplaySymbol={alwaysDisplaySymbol}
            />
          </Box>
        ))}
      </Box>
    );
  };

  const renderLinear = (_symbols: SpecialDaySymbolAndColor[], symbolSize: number) => {
    const sliceEnd = maxSymbolCount ?? 4;

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          ...sx
        }}
      >
        {filteredSymbols.slice(0, sliceEnd).map((symbol, index) => (
          <Box key={`${symbol.symbol}${index}`} sx={{ width: symbolSize, height: symbolSize, mx: '1px', mb: '1px' }}>
            <SpecialDaySymbolImage
              squareSize={symbolSize}
              symbol={symbol.symbol}
              color={symbol.color ?? SectionColors.get('medium-bmgray')}
              alwaysDisplaySymbol={alwaysDisplaySymbol}
            />
          </Box>
        ))}
      </Box>
    );
  };

  switch (displayKind) {
    case 'grid':
      return renderGrid(symbols, symbolSize);

    case 'linear':
      return renderLinear(symbols, symbolSize);

    default:
      return renderStack(symbols, symbolSize);
  }
});
