import {
  Column,
  Container,
  Expanded,
  InsightsButton,
  ObservableActionsPresenter,
  ObservablePresenter,
  Row,
  Scroll,
  SectionStudentsEdition,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { SectionUtils } from '@insights/utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider,
  createTheme
} from '@mui/material';
import { DialogResult } from '@shared/services';
import { Observer, observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SectionStudentsEditionDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  sectionId: string;
}

export const SectionStudentsEditionDialog = observer((props: SectionStudentsEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], sectionId, className, style, configId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createSectionStudentsEdition(configId, sectionId, onSuccess!, onCancel!)
  );
  const data = viewModel.data;

  const close = () => {
    void viewModel.close();
  };

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        PaperProps={{ sx: { height: '100vh' } }}
        onClose={() => close()}
      >
        <DialogTitle sx={{ pb: 0 }}>
          {data.case({
            pending: () => strings.editSectionStudents,
            fulfilled: (d) => strings.editSectionStudents + ' – ' + SectionUtils.formatTitle(d.section),
            rejected: () => strings.editSectionStudents
          })}
        </DialogTitle>

        <DialogContent>
          <ObservablePresenter
            data={viewModel.data}
            render={(d) => (
              <Observer>
                {() => (
                  <Container>
                    <Column>
                      <Expanded>
                        <Scroll
                          direction="y"
                          sx={{
                            // This is to reset the bottom margin of the last card
                            '& >:last-child': {
                              marginBottom: 2
                            }
                          }}
                        >
                          <SectionStudentsEdition viewModel={d} />
                        </Scroll>
                      </Expanded>
                    </Column>
                  </Container>
                )}
              </Observer>
            )}
          />
        </DialogContent>

        <DialogActions>
          <ObservableActionsPresenter
            data={viewModel.data}
            render={(data) => (
              <Column>
                <Row verticalContentAlignment="center">
                  <Button onClick={() => void data.importFromOtherSection()} disabled={data.isViewingTeachers}>
                    {strings.importFromSection}
                  </Button>
                  <Button onClick={() => void data.addFromIds()} disabled={data.isViewingTeachers}>
                    {strings.addStudentsFromIds}
                  </Button>
                  <Expanded />
                  <InsightsButton sx={{ ml: 1 }} onClick={() => close()} isExecuting={data.isSaving}>
                    {strings.cancel}
                  </InsightsButton>
                  <InsightsButton
                    sx={{ ml: 1 }}
                    onClick={() => void data.apply()}
                    isSubmit
                    isDisabled={!data.hasChanges}
                    isExecuting={data.isSaving}
                    showSpinnerOnExecuting
                    percentage={data.savingPercent}
                  >
                    {strings.save}
                  </InsightsButton>
                </Row>
              </Column>
            )}
            renderError={() => <InsightsButton onClick={() => close()}>{strings.close}</InsightsButton>}
          />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
