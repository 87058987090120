import { css } from '@emotion/css';
import { DateTimePickerLocalizationProvider, InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  InputLabel,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { Day } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface EditSchoolYearConfigurationAssessmentPlanningDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  schoolYearConfiguration: SchoolYearConfigurationModel;
  style?: CSSProperties;
}

export const EditSchoolYearConfigurationAssessmentPlanningDialog = observer(
  (props: EditSchoolYearConfigurationAssessmentPlanningDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { className, sx = [], style, schoolYearConfiguration, onCancel, onSuccess } = props;
    const strings = localizationService.localizedStrings.insights.views.managedGeneral;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createEditSchoolYearConfigurationAssessmentPlanning(schoolYearConfiguration, onSuccess!, onCancel!)
    );

    const cellClassName = css({ padding: '2px 8px 2px 0px', border: 'none' });
    const gradeLevelCellClassName = css({ width: '33%' });
    const visibilityDayCellClassName = css({ width: '33%' });

    return (
      <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
        <DateTimePickerLocalizationProvider>
          <Dialog
            sx={sx}
            open={true}
            onClose={() => void viewModel.cancel()}
            maxWidth="sm"
            fullWidth={true}
            className={className}
            style={style}
          >
            <form
              noValidate
              onSubmit={(e) => {
                // This is to prevent the page from reloading on submit
                e.preventDefault();
                return false;
              }}
            >
              <DialogTitle>{strings.editAssessmentPlanningDialogTitle}</DialogTitle>
              <DialogContent dividers>
                <Grid2 container spacing={2}>
                  {/* Visibility Date by grade level */}
                  <Grid2 size={{ xs: 12 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={clsx(cellClassName, gradeLevelCellClassName)}>
                            <InputLabel shrink>{strings.assessmentPlanningGradeLevelColumnTitle}</InputLabel>
                          </TableCell>
                          <TableCell className={clsx(cellClassName, visibilityDayCellClassName)}>
                            <InputLabel shrink>{strings.assessmentPlanningVisibilityDateColumnTitle}</InputLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {viewModel.gradeLevels.map((gradeLevel) => {
                          const day = viewModel.getVisibilityDate(gradeLevel);
                          const value = day != null ? day.asDate : null;

                          return (
                            <TableRow key={gradeLevel}>
                              <TableCell className={clsx(cellClassName, gradeLevelCellClassName)}>
                                <Typography variant="body1">{gradeLevel}</Typography>
                              </TableCell>
                              <TableCell className={clsx(cellClassName, visibilityDayCellClassName)}>
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      placeholder: viewModel.defaultDay.formattedString(
                                        localizationService.localizedStrings.models.dateFormats.mediumUnabridged
                                      )
                                    },
                                    actionBar: {
                                      actions: ['clear', 'cancel', 'accept']
                                    }
                                  }}
                                  localeText={{
                                    okButtonLabel: strings.okButtonCaption,
                                    cancelButtonLabel: strings.cancelButtonCaption,
                                    clearButtonLabel: strings.clearButtonCaption
                                  }}
                                  format={localizationService.localizedStrings.models.dateFormats.mediumUnabridged}
                                  value={value}
                                  minDate={viewModel.minimumDay.asDate}
                                  maxDate={viewModel.maximumDay.asDate}
                                  onChange={(e) =>
                                    viewModel.setVisibilityDate(gradeLevel, Day.fromDate(e ?? undefined))
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Grid2>
                </Grid2>
              </DialogContent>
              <DialogActions>
                <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
                  {strings.cancelButtonCaption}
                </InsightsButton>
                <InsightsButton
                  isSubmit
                  showSpinnerOnExecuting
                  isExecuting={viewModel.isSubmitting}
                  onClick={() => void viewModel.save()}
                >
                  {strings.saveButtonCaption}
                </InsightsButton>
              </DialogActions>
            </form>
          </Dialog>
        </DateTimePickerLocalizationProvider>
      </ThemeProvider>
    );
  }
);
