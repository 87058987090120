export interface ContentListsStrings {
  readonly editNote: string;
  readonly editContents: string;
  readonly pasteTask: string;
  readonly paste: string;
  readonly addTask: string;
  readonly allDayTasksTitle: string;
  readonly noSectionPeriodTitle: string;
  readonly close: string;
  readonly cancel: string;
  readonly noContentCellTitle: (canEdit: boolean) => string;
  readonly periodTagLabel: (tag: string) => string;
}

export const EnglishContentListsStrings: ContentListsStrings = {
  editNote: 'Edit note',
  editContents: 'Edit…',
  pasteTask: 'Paste task',
  paste: 'Paste',
  addTask: 'Add a task',
  allDayTasksTitle: 'All Day Tasks',
  noSectionPeriodTitle: 'Free',
  close: 'Close',
  cancel: 'Cancel',
  noContentCellTitle: (canEdit) => (canEdit ? 'Add a task…' : 'No task'),
  periodTagLabel: (tag) => `Period ${tag}`
};

export const FrenchContentListsStrings: ContentListsStrings = {
  editNote: 'Modifier la note',
  editContents: 'Éditer…',
  pasteTask: 'Coller la tâche',
  paste: 'Coller',
  addTask: 'Ajouter une tâche',
  allDayTasksTitle: 'Tâches quotidiennes',
  noSectionPeriodTitle: 'Libre',
  close: 'Fermer',
  cancel: 'Annuler',
  noContentCellTitle: (canEdit) => (canEdit ? 'Ajouter une tâche…' : 'Aucune tâche'),
  periodTagLabel: (tag) => `Période ${tag}`
};
