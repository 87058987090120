import { SourceIndex as PBSourceIndex } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableStringProperty, EditableValuePropertyEx } from '../../editables';
import { SourceIndex } from '../interfaces';
import { GrpcSourceIndex } from './GrpcSourceIndex';

export class EditableSourceIndex extends EditableModelEx<PBSourceIndex> implements SourceIndex {
  private readonly _label: EditableStringProperty<PBSourceIndex>;
  private readonly _columnIndex: EditableValuePropertyEx<number, PBSourceIndex>;

  static createNew() {
    const pb = new PBSourceIndex();

    return new EditableSourceIndex(new GrpcSourceIndex(pb), true);
  }

  constructor(
    private readonly _originalSourceIndex: SourceIndex,
    isNew = false
  ) {
    super(_originalSourceIndex.toProtobuf(), isNew);

    makeObservable(this);

    this._label = this.addStringField(_originalSourceIndex.label, (host, value) => (host.label = value), {
      trim: true
    });
    this._columnIndex = this.addValueField<number>(
      this._originalSourceIndex.columnIndex,
      (host, value) => (host.columnIndex = value)
    );
  }

  @computed
  get label() {
    return this._label.value;
  }

  set label(value: string) {
    this._label.value = value;
  }

  @computed
  get columnIndex() {
    return this._columnIndex.value;
  }

  set columnIndex(value: number) {
    this._columnIndex.value = value;
  }
}
