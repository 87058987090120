import { MaximumSpecialDayTitleLength, SchoolCalendarSpecialDayViewModel } from '@insights/viewmodels';
import { Checkbox, FormControlLabel, SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column } from '../layout';

export interface EditableSpecialDayTitleProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarSpecialDayViewModel;
}

export const EditableSpecialDayTitle = observer((props: EditableSpecialDayTitleProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, style, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Column sx={sx} className={className} style={style} horizontalContentAlignment="stretch">
      <TextField
        label={strings.title}
        fullWidth
        value={viewModel.title}
        onChange={(event) => (viewModel.title = event.target.value)}
        slotProps={{ input: { inputProps: { maxLength: MaximumSpecialDayTitleLength } } }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={viewModel.isTitleVisible}
            onChange={(event) => (viewModel.isTitleVisible = event.target.checked)}
          />
        }
        label={strings.showSpecialDayTitle}
      />
    </Column>
  );
});
