import { Box, Link, SxProps, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface NoSchoolConfigurationsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const NoSchoolConfigurations = observer((props: NoSchoolConfigurationsProps) => {
  const { localizationService, navigationService } = useInsightsServices();
  const { className, style, sx } = props;
  const theme = useTheme();
  const strings = localizationService.localizedStrings.insights.views.noSchoolConfigurations;

  const openIntercom = () => {
    if (window.Intercom != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Intercom('showNewMessage');
    }
  };

  return (
    <Box sx={sx} className={className} style={style} display="flex" flexDirection="column" padding={3}>
      <Box marginBottom={3}>
        <Typography variant="h4">{strings.title}</Typography>
      </Box>

      <Box marginBottom={3}>
        <Typography variant="body1">
          {strings.errorOnOurPartText}
          <Link variant="body1" sx={{ color: theme.palette.secondary.dark }} onClick={openIntercom}>
            {strings.errorOnOurPartLetUsKnowLink}
          </Link>
          .
        </Typography>
      </Box>

      <Typography variant="body1">{strings.usefulLinks}</Typography>

      <Link
        variant="body1"
        sx={{ color: theme.palette.secondary.dark }}
        onClick={() => void navigationService.navigateToStudyo()}
      >
        {strings.usefulLinksStudyo}
      </Link>
    </Box>
  );
});
