import { SchoolYearConfiguration_Term as PBTerm } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { computed, makeObservable } from 'mobx';
import { EditableDayPropertyEx, EditableModelEx, EditableStringProperty } from '../editables';
import { Day } from '../types';
import { Term, TermModel } from './Term';

export class EditableTerm extends EditableModelEx<PBTerm> implements TermModel {
  private _tag: EditableStringProperty<PBTerm>;
  private _startDay: EditableDayPropertyEx<PBTerm>;
  private _endDay: EditableDayPropertyEx<PBTerm>;

  static createNew(tag: string, startDay: Day, endDay: Day): EditableTerm {
    const pb = new PBTerm();
    pb.tag = tag;
    pb.startDay = startDay.asPB;
    pb.endDay = endDay.asPB;

    return new EditableTerm(new Term(pb), true);
  }

  static cloneAsNew(original: TermModel): EditableTerm {
    const pb = original.toProtobuf();
    // No id.
    return new EditableTerm(new Term(pb), true);
  }

  constructor(originalTerm: TermModel, isNew = false) {
    super(originalTerm.toProtobuf(), isNew);

    makeObservable(this);

    this.setFields([
      (this._tag = new EditableStringProperty(originalTerm.tag, (pb, value) => (pb.tag = value), {
        trim: true
      })),
      (this._startDay = new EditableDayPropertyEx(originalTerm.startDay, (pb, value) => (pb.startDay = value.asPB))),
      (this._endDay = new EditableDayPropertyEx(originalTerm.endDay, (pb, value) => (pb.endDay = value.asPB)))
    ]);
  }

  @computed
  get tag() {
    return this._tag.value;
  }

  set tag(value: string) {
    this._tag.value = value;
  }

  @computed
  get startDay() {
    return this._startDay.value;
  }

  set startDay(value: Day) {
    this._startDay.value = value;
  }

  @computed
  get endDay() {
    return this._endDay.value;
  }

  set endDay(value: Day) {
    this._endDay.value = value;
  }

  clone(): EditableTerm {
    const pb = this.toProtobuf();
    return new EditableTerm(new Term(pb));
  }
}
