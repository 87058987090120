import { TeacherPlanningViewModel } from '@insights/viewmodels';
import { Box, Checkbox, FormControlLabel, Popover, styled, TextField, Typography } from '@mui/material';
import { Day } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { InsightsButton } from '../InsightsButton';

export interface TeacherPlanningMoveRightPopoverProps {
  viewModel: TeacherPlanningViewModel;
  anchorElement?: Element;
}

export const TeacherPlanningMoveRightPopover = observer((props: TeacherPlanningMoveRightPopoverProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, anchorElement } = props;
  const strings = localizationService.localizedStrings.insights.components.accounts;

  return (
    <Root
      open={viewModel.movingRightCell != null}
      anchorEl={anchorElement}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
      <Box minWidth={400} maxWidth={600} padding={2} display="flex" flexDirection="column">
        <Typography className={'control'} variant="h6">
          {strings.modifyPeriodTitle}
        </Typography>

        <TextField
          className={'control'}
          fullWidth
          label={strings.moveCurrentTitleLabel}
          placeholder={strings.moveCurrentTitlePlaceholder(viewModel.defaultTitle)}
          value={viewModel.currentTitle}
          onChange={(event) => (viewModel.currentTitle = event.target.value)}
        />
        <TextField
          className={'control'}
          fullWidth
          label={strings.moveCurrentRoomNameLabel}
          value={viewModel.currentRoomName}
          onChange={(event) => (viewModel.currentRoomName = event.target.value)}
        />

        <FormControlLabel
          className={'control'}
          label={strings.moveCurrentSkip}
          control={
            <Checkbox
              color="primary"
              checked={viewModel.skipCurrent}
              onChange={(e) => (viewModel.skipCurrent = e.target.checked)}
            />
          }
        />

        <FormControlLabel
          className={'controlWithNotice'}
          label={strings.moveTitlesRight}
          control={
            <Checkbox
              color="primary"
              checked={viewModel.moveTitlesRight}
              onChange={(e) => (viewModel.moveTitlesRight = e.target.checked)}
            />
          }
        />
        <Typography className={'subWarning'} variant="body2" sx={{ color: (theme) => theme.palette.error.main }}>
          {strings.moveTitlesRightWarning}
        </Typography>
        <Typography className={'subNotice'} variant="body2" sx={{ color: (theme) => theme.palette.primary.main }}>
          {strings.moveTitlesRightNotice}
        </Typography>

        <TextField
          className={'subControl'}
          fullWidth
          type="date"
          disabled={!viewModel.moveTitlesRight}
          label={strings.moveTitlesRightUntil}
          slotProps={{ inputLabel: { style: { marginLeft: 32 } } }}
          value={viewModel.moveUntil.asDateString}
          onChange={(event) => {
            const day = Day.fromDateString(event.target.value);
            if (day != null) {
              viewModel.moveUntil = day;
            }
          }}
        />

        <Box display="flex" flexDirection="row-reverse" paddingTop={2}>
          <InsightsButton className={'button'} onClick={() => viewModel.cancel()}>
            {strings.cancel}
          </InsightsButton>
          <InsightsButton className={'button'} isDefault onClick={() => void viewModel.confirmMoveRight()}>
            {strings.modify}
          </InsightsButton>
        </Box>
      </Box>
    </Root>
  );
});

const Root = styled(Popover)(({ theme }) => ({
  '.control': {
    marginBottom: theme.spacing(1)
  },
  '.controlWithNotice': {},
  '.subControl': {
    marginBottom: theme.spacing(1),
    paddingLeft: 32
  },
  '.subWarning': {
    marginBottom: theme.spacing(0.5),
    paddingLeft: 32
  },
  '.subNotice': {
    marginBottom: theme.spacing(2),
    paddingLeft: 32
  },
  '.button': {
    marginLeft: theme.spacing(1)
  }
}));
