import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  TextField,
  ThemeProvider
} from '@mui/material';
import { EditableExternalFileSource, ExternalFileSource } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface ExternalFileSourceEditionDialogProps extends DialogResult<ExternalFileSource> {
  sx?: SxProps;
  editableSource: EditableExternalFileSource;
}

export const ExternalFileSourceEditionDialog = observer((props: ExternalFileSourceEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], editableSource, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.settings;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createExternalFileSourceEdition(editableSource, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog sx={sx} open maxWidth="sm" fullWidth onClose={() => void viewModel.cancel()}>
        <DialogTitle>
          {viewModel.isNew ? strings.addExternalFileSourceTitle : strings.editExternalFileSourceTitle}
        </DialogTitle>

        <DialogContent dividers>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              // This is to reset the bottom margin of the last field
              '& >:last-child': {
                marginBottom: 0
              }
            }}
          >
            <TextField
              value={viewModel.editableSource.baseUrl}
              label={strings.baseUrlLabel}
              sx={{ mb: 2 }}
              onChange={(event) => (viewModel.editableSource.baseUrl = event.target.value)}
            />
            <TextField
              value={viewModel.editableSource.port}
              type="number"
              label={strings.portLabel}
              sx={{ mb: 2 }}
              onChange={(event) => {
                const port = Number(event.target.value);
                if (!Number.isNaN(port)) {
                  viewModel.editableSource.port = port;
                }
              }}
            />
            <TextField
              value={viewModel.editableSource.username}
              label={strings.usernameLabel}
              autoComplete="new-password"
              sx={{ mb: 2 }}
              onChange={(event) => (viewModel.editableSource.username = event.target.value)}
            />
            <TextField
              type="password"
              value={viewModel.editableSource.password}
              label={strings.passwordLabel}
              helperText={viewModel.hasPasswordPlaceholder ? strings.updatePasswordNotice : ''}
              autoComplete="new-password"
              sx={{ mb: 2 }}
              onChange={(event) => (viewModel.editableSource.password = event.target.value)}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <InsightsButton isDisabled={viewModel.isApplying} onClick={() => void viewModel.cancel()}>
            {strings.cancelButton}
          </InsightsButton>
          <InsightsButton
            isDefault
            isDisabled={!viewModel.canApply || viewModel.isApplying}
            onClick={() => void viewModel.apply()}
          >
            {strings.applyButton}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
