export interface TimelineFilterOptionStrings {
  readonly close: string;
  readonly reset: string;
  readonly title: string;

  readonly includeTitle: string;
  readonly published: string;
  readonly notPublished: string;

  readonly showTitle: string;
  readonly exams: string;
  readonly homework: string;
  readonly others: string;

  readonly minimumWorkloadTitle: string;

  readonly onlyTitle: string;
  readonly withAttachments: string;
  readonly notCompleted: string;
}

export const EnglishTimelineFilterOptionStrings: TimelineFilterOptionStrings = {
  close: 'Close',
  reset: 'Reset filters',
  title: 'Task Filters',

  includeTitle: 'Include',
  published: 'Published',
  notPublished: 'Not Published',

  showTitle: 'Show',
  exams: 'Exams',
  homework: 'Homework',
  others: 'Others',

  minimumWorkloadTitle: 'Minimum Workload',

  // The wording has been inverted but it doesn't affect the implementation.
  onlyTitle: 'Hide',
  withAttachments: 'Tasks without attachments',
  notCompleted: 'Completed tasks'
};

export const FrenchTimelineFilterOptionStrings: TimelineFilterOptionStrings = {
  close: 'Fermer',
  reset: 'Enlever les filtres',
  title: 'Filtrer les tâches',

  includeTitle: 'Inclure',
  published: 'Publiées',
  notPublished: 'Personnelles',

  showTitle: 'Afficher',
  exams: 'Examens',
  homework: 'Devoirs',
  others: 'Autres',

  minimumWorkloadTitle: 'Charge minimale',

  // The wording has been inverted but it doesn't affect the implementation.
  onlyTitle: 'Cacher',
  withAttachments: 'Tâches sans pièce jointe',
  notCompleted: 'Tâches complétées'
};
