import { AlertService, NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppExternalFileSourcesViewModel, ExternalFileSourcesViewModel } from './ExternalFileSourcesViewModel';

export interface GlobalSettingsViewModel {
  externalSources: ExternalFileSourcesViewModel;
}

export interface GlobalSettingsScreenViewModel {
  readonly data: IPromiseBasedObservable<GlobalSettingsViewModel>;
}

export class AppGlobalSettingsViewModel implements GlobalSettingsViewModel {
  constructor(readonly externalSources: ExternalFileSourcesViewModel) {}
}

export class AppGlobalSettingsScreenViewModel implements GlobalSettingsScreenViewModel {
  constructor(
    private readonly _importerStore: ImporterStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService
  ) {}

  get data(): IPromiseBasedObservable<GlobalSettingsViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<GlobalSettingsViewModel> {
    const externalSources = await this._importerStore.getExternalFileSources();

    return new AppGlobalSettingsViewModel(
      new AppExternalFileSourcesViewModel(
        this._importerStore,
        this._navigationService,
        this._alertService,
        this._localizationService,
        externalSources
      )
    );
  }
}
