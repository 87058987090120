import { Button, styled, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { GoogleAccountViewModel } from '../../viewmodels';
import { InsightsButton } from '../InsightsButton.tsx';
import { Column, Expanded, Row } from '../layout';

export interface GoogleAccountConnectedProps {
  sx?: SxProps;
  className?: string;
  viewModel: GoogleAccountViewModel;
}

export const GoogleAccountConnected = observer(({ sx = [], className, viewModel }: GoogleAccountConnectedProps) => {
  const { imageService, localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Root sx={sx} className={className}>
      <Typography variant="h6">{strings.googleAlreadyConnectedHeader}</Typography>
      <Column className={'stepSection'} horizontalContentAlignment="stretch">
        <Typography variant="body2" style={{ marginBottom: 8 }}>
          {strings.googleAlreadyConnectedMessage}
        </Typography>
        <Row verticalContentAlignment="center">
          <Button className={'button'} variant="outlined" onClick={() => viewModel.redoConnection()}>
            <img style={{ height: 24, width: 24, marginRight: 4 }} src={imageService.studyoImages.generic.googleLogo} />
            <Typography className={'authorizeLabel'}>{strings.googleRedoConnectionButton}</Typography>
          </Button>
        </Row>
      </Column>
      <Row horizontalContentAlignment="stretch" verticalContentAlignment="center">
        <Expanded />
        <InsightsButton className={'button'} onClick={() => viewModel.cancelChanges()}>
          {strings.cancel}
        </InsightsButton>
      </Row>
    </Root>
  );
});

const Root = styled(Column)(({ theme }) => ({
  '.stepSection': {
    margin: theme.spacing(1)
  },
  '.button': {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  '.authorizeLabel': {
    textTransform: 'uppercase'
  }
}));
