import { Checkbox, FormControl, FormControlLabel, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface ClassroomRemoveMatchedKeywordProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  isRemovingMatchedKeyword: boolean;
  onChange: (shouldRemoveMatchedKeyword: boolean) => void;
}

export const ClassroomRemoveMatchedKeyword = observer((props: ClassroomRemoveMatchedKeywordProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, isRemovingMatchedKeyword, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <FormControl sx={sx} className={className} style={style}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isRemovingMatchedKeyword}
            onChange={(event) => onChange(event.target.checked)}
          />
        }
        label={strings.classroomRemoveMatchedKeywordLabel}
      />
    </FormControl>
  );
});
