import { OQProfile as PBOQProfile } from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';
import { OQValue } from '../types';
import { oqValueFromProtobuf } from '../types/EnumConversion';

export class OQProfile {
  private _pb: PBOQProfile;

  constructor(pb: PBOQProfile) {
    this._pb = pb;
  }

  get opensTheApp(): OQValue {
    return oqValueFromProtobuf(this._pb.opensTheApp);
  }

  get marksTasksAsDone(): OQValue {
    return oqValueFromProtobuf(this._pb.marksTasksAsDone);
  }
}
