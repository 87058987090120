import { SectionInfo } from '@insights/models';
import { CustomFilterUtils, SectionUtils } from '@insights/utils';
import { SectionsViewModel } from '@insights/viewmodels';
import { Action, MTableAction, Column as TableColumn } from '@material-table/core';
import EnrollmentIcon from '@mui/icons-material/HowToReg';
import { Box, ListItemText, MenuItem, Snackbar, SxProps, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { LocalizationService } from '@shared/resources/services';
import { ScreenType } from '@shared/services';
import { ReactRouterRouteService } from '@shared/web/services';
import { observer } from 'mobx-react-lite';
import moize from 'moize';
import { CSSProperties, useRef, useState } from 'react';
import { DefaultTablePageSizes } from '../Constants';
import { RouteParamNames, RouteTemplates } from '../Routes';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { InsightsMaterialTable } from './InsightsMaterialTable';
import { MoreActionMenu } from './MoreActionMenu';
import { SectionListTeachersCell } from './SectionListTeachersCell';
import { SectionName } from './SectionName';
import { Container, Expanded, Row } from './layout';

const TableStateKey = 'SectionList';

export interface SectionListProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title?: string;
  configId: string;
  sections: SectionsViewModel;
  usePagination?: boolean;
}

export const SectionList = observer((props: SectionListProps) => {
  const { accountService, localizationService, reactRouterRouteService, screenService } = useInsightsServices();
  const { sx = [], configId, className, style, title, sections, usePagination = true } = props;
  const insightsStrings = localizationService.localizedStrings.insights;
  const sectionsStrings = insightsStrings.components.sections;
  const canCopyIds = accountService.isAllowed(['super-admin']);
  const theme = useTheme();
  const [showToast, setShowToast] = useState(false);

  async function copyIdToClipboard(section: SectionInfo): Promise<void> {
    if (section.section == null) {
      return;
    }

    await navigator.clipboard.writeText(section.section.id);
    setShowToast(true);
  }

  // We can memoize this info as it doesn't change.
  const getSearchableFields = useRef(
    moize(
      (s: SectionInfo) =>
        s.section != null
          ? [
              SectionUtils.formatTitle(s.section),
              s.section.gradeLevel,
              s.section.sectionNumber,
              s.section.defaultRoomName,
              s.section.importId,
              ...(s.teachers?.map((t) => AccountUtils.getDisplayLastFirstName(t.account)) ?? '')
            ]
          : [],
      {
        isSerialized: true,
        serializer: (s: SectionInfo[]) => [s[0].id]
      }
    )
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const actions: Action<SectionInfo>[] = [{ icon: 'more_vert', onClick: () => {} }];

  const columns = [
    getTitleColumn(configId, getSearchableFields.current, localizationService, reactRouterRouteService),
    getTeachersColumn(configId, localizationService),
    getGradeColumn(localizationService, screenService.screenType < ScreenType.extraLarge),
    getGroupColumn(localizationService, screenService.screenType < ScreenType.large),
    getRoomColumn(localizationService, screenService.screenType < ScreenType.extraLarge),
    getStudentCountColumn(localizationService, screenService.screenType < ScreenType.extraLarge)
  ];

  return (
    <Root sx={sx} className={className} style={style}>
      <Container>
        <InsightsMaterialTable
          stateKey={TableStateKey}
          // This is to disable the card contour
          components={{
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>,
            Action: (p) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (!p.action.isFreeAction) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const data = p.data as SectionInfo;

                return (
                  <Expanded>
                    <Row horizontalContentAlignment="right">
                      <MoreActionMenu>
                        {canCopyIds && (
                          <MenuItem onClick={() => void copyIdToClipboard(data)}>
                            <ListItemText primary={sectionsStrings.copyId} />
                          </MenuItem>
                        )}
                      </MoreActionMenu>
                    </Row>
                  </Expanded>
                );
              }

              return <MTableAction {...p} />;
            }
          }}
          title={title ?? ''}
          columns={columns}
          actions={actions}
          data={sections.sections}
          options={{
            pageSize: 50,
            pageSizeOptions: DefaultTablePageSizes,
            rowStyle: { backgroundColor: '#fff', verticalAlign: 'top' },
            headerStyle: { fontSize: theme.typography.body2.fontSize },
            emptyRowsWhenPaging: false,
            paging: usePagination,
            actionsColumnIndex: -1,
            draggable: false
          }}
          localization={{
            ...localizationService.localizedStrings.insights.materialTable,
            header: { actions: '' }
          }}
        />
      </Container>
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        message={sectionsStrings.copyIdNotification}
      />
    </Root>
  );
});

function getTitleColumn(
  configId: string,
  getSearchableFields: (section: SectionInfo) => string[],
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService
): TableColumn<SectionInfo> {
  const insightsStrings = localizationService.localizedStrings.insights;
  const sectionsStrings = insightsStrings.components.sections;

  return {
    title: sectionsStrings.title,
    defaultSort: 'asc',
    customSort: (a: SectionInfo, b: SectionInfo) => SectionUtils.compareTitles(a, b, localizationService.currentLocale),
    customFilterAndSearch: (filter: string, section: SectionInfo) =>
      CustomFilterUtils.customFilterAndSearch(filter, section, getSearchableFields),
    render: (rowData: SectionInfo) => {
      const linkTo =
        rowData.section != null
          ? reactRouterRouteService.resolveLocation(RouteTemplates.sectionDetails, [
              { name: RouteParamNames.configId, value: configId },
              { name: RouteParamNames.sectionId, value: rowData.id }
            ])
          : undefined;

      return (
        <Container sx={{ my: 0.5 }} className="item">
          <SectionName
            title={rowData.section != null ? SectionUtils.formatTitle(rowData.section) : sectionsStrings.unknownSection}
            color={rowData.section?.color}
            linkTo={linkTo}
          />
        </Container>
      );
    }
  };
}

function getTeachersColumn(configId: string, localizationService: LocalizationService): TableColumn<SectionInfo> {
  const sectionsStrings = localizationService.localizedStrings.insights.components.sections;

  return {
    title: sectionsStrings.teachers,
    width: '15%',
    customSort: (a: SectionInfo, b: SectionInfo) =>
      SectionUtils.compareTeachers(a, b, localizationService.currentLocale),
    render: (rowData: SectionInfo) => <SectionListTeachersCell configId={configId} sectionInfo={rowData} />
  };
}

function getGradeColumn(localizationService: LocalizationService, hidden?: boolean): TableColumn<SectionInfo> {
  const insightsStrings = localizationService.localizedStrings.insights;
  const sectionsStrings = insightsStrings.components.sections;

  return {
    title: sectionsStrings.grade,
    width: '15%',
    customSort: (a: SectionInfo, b: SectionInfo) => SectionUtils.compareGrades(a, b, localizationService.currentLocale),
    render: (rowData: SectionInfo) => {
      return (
        <Container sx={{ my: 0.5 }} className="item">
          <Row verticalContentAlignment="center">
            <Typography variant="body1">{rowData.section?.gradeLevel}</Typography>
          </Row>
        </Container>
      );
    },
    hidden
  };
}

function getGroupColumn(localizationService: LocalizationService, hidden?: boolean): TableColumn<SectionInfo> {
  const insightsStrings = localizationService.localizedStrings.insights;
  const sectionsStrings = insightsStrings.components.sections;

  return {
    title: sectionsStrings.group,
    width: '15%',
    customSort: (a: SectionInfo, b: SectionInfo) => SectionUtils.compareGroups(a, b, localizationService.currentLocale),
    render: (rowData: SectionInfo) => {
      return (
        <Container sx={{ my: 0.5 }} className="item">
          <Row verticalContentAlignment="center">
            <Typography variant="body1">{rowData.section?.sectionNumber}</Typography>
          </Row>
        </Container>
      );
    },
    hidden
  };
}

function getRoomColumn(localizationService: LocalizationService, hidden?: boolean): TableColumn<SectionInfo> {
  const insightsStrings = localizationService.localizedStrings.insights;
  const sectionsStrings = insightsStrings.components.sections;

  return {
    title: sectionsStrings.room,
    width: '15%',
    customSort: (a: SectionInfo, b: SectionInfo) =>
      SectionUtils.compareDefaultRooms(a, b, localizationService.currentLocale),
    render: (rowData: SectionInfo) => {
      return (
        <Container sx={{ my: 0.5 }} className="item">
          <Row verticalContentAlignment="center">
            <Typography variant="body1">{rowData.section?.defaultRoomName}</Typography>
          </Row>
        </Container>
      );
    },
    hidden
  };
}

function getStudentCountColumn(localizationService: LocalizationService, hidden?: boolean): TableColumn<SectionInfo> {
  const componentsStrings = localizationService.localizedStrings.insights.components;

  return {
    title: componentsStrings.sections.studentCount,
    type: 'numeric',
    width: '15%',
    customSort: (a: SectionInfo, b: SectionInfo) =>
      (a.students != null ? a.students.length : 0) - (b.students != null ? b.students.length : 0),
    render: (rowData: SectionInfo) => {
      const hasAutoEnrollment =
        rowData.section != null &&
        (rowData.section.autoEnrollRoles.length > 0 || rowData.section.autoEnrollTags.length > 0);

      return (
        <Container sx={{ my: 0.5 }} className="item">
          <Row verticalContentAlignment="center" horizontalContentAlignment="right">
            {hasAutoEnrollment && (
              <Tooltip
                title={componentsStrings.getAutoEnrollmentRolesAndTags(
                  rowData.section.autoEnrollRoles,
                  rowData.section.autoEnrollTags
                )}
              >
                <EnrollmentIcon fontSize="small" style={{ marginRight: 5 }} />
              </Tooltip>
            )}
            <Typography variant="body1">{rowData.students != null ? rowData.students.length : 0}</Typography>
          </Row>
        </Container>
      );
    },
    hidden
  };
}

const Root = styled(Box)(() => ({
  '.item': {
    minHeight: 30
  },
  '.schedules': {
    margin: 0
  }
}));
