export interface ThresholdsComponentStrings {
  readonly dailyThresholdTooltipLabel: string;
  readonly weeklyThresholdTooltipLabel: string;
}

export const EnglishThresholdsComponentStrings: ThresholdsComponentStrings = {
  dailyThresholdTooltipLabel: 'Daily threshold',
  weeklyThresholdTooltipLabel: 'Weekly threshold'
};

export const FrenchThresholdsComponentStrings: ThresholdsComponentStrings = {
  dailyThresholdTooltipLabel: 'Seuil quotidien',
  weeklyThresholdTooltipLabel: 'Seuil hebdomadaire'
};
