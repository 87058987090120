import {
  InsightsButton,
  ObservablePresenter,
  RenameOrCopyOnboardingTemplate,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { createTheme, Dialog, DialogActions, DialogContent, DialogTitle, SxProps, ThemeProvider } from '@mui/material';
import { OnboardingTemplateKind } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface RenameOrCopyOnboardingTemplateDialogProps extends DialogResult<string> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  templateKind: OnboardingTemplateKind;
  currentName: string;
  configId: string;
  stepId?: string;
  isCopy?: boolean;
}

export const RenameOrCopyOnboardingTemplateDialog = observer((props: RenameOrCopyOnboardingTemplateDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const {
    className,
    style,
    isCopy = false,
    sx = [],
    currentName,
    templateKind,
    configId,
    stepId,
    onCancel,
    onSuccess
  } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createRenameOrCopyOnboardingTemplateDialog(
      templateKind,
      currentName,
      configId,
      stepId,
      isCopy ?? false,
      onSuccess!,
      onCancel!
    )
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => viewModel.cancel()}
      >
        <DialogTitle>{viewModel.dialogTitle}</DialogTitle>
        <DialogContent>
          <ObservablePresenter
            data={viewModel.data}
            render={(data) => <RenameOrCopyOnboardingTemplate viewModel={data} />}
          />
        </DialogContent>
        <DialogActions>
          <InsightsButton onClick={() => void viewModel.cancel()}>{strings.cancelLabel}</InsightsButton>
          <InsightsButton isDefault isDisabled={!viewModel.canConfirm} onClick={() => viewModel.confirm()}>
            {isCopy ? strings.copyButtonLabel : strings.renameButtonLabel}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
