import { DateTimePickerLocalizationProvider, InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  Checkbox,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  SxProps,
  ThemeProvider
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { ExternalAccount } from '@shared/models/connectors';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ErrorNotificationSettingsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  externalAccount: ExternalAccount;
}

export const EditErrorNotificationSettingsDialog = observer((props: ErrorNotificationSettingsDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], externalAccount, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createEditErrorNotificationSettings(externalAccount, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <DateTimePickerLocalizationProvider>
        <Dialog sx={sx} open={true} onClose={() => void viewModel.cancel()} maxWidth="sm" fullWidth={true}>
          <DialogTitle>{strings.errorNotificationSettings}</DialogTitle>

          <DialogContent dividers>
            <Box display="flex" flexDirection="column" overflow="visible">
              <DateTimePicker
                slotProps={{
                  textField: {
                    fullWidth: true
                  },
                  actionBar: {
                    actions: ['clear', 'cancel', 'accept']
                  }
                }}
                localeText={{
                  okButtonLabel: strings.okButton,
                  cancelButtonLabel: strings.cancelButton,
                  clearButtonLabel: strings.clearButton
                }}
                ampm={localizationService.currentLocale !== 'fr'}
                format={localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime}
                label={strings.snoozeErrorsUntilFieldLabel}
                value={viewModel.snoozeErrorsUntil ?? null}
                minDate={viewModel.minSnoozeErrorsUntilDate}
                onChange={(e) => (viewModel.snoozeErrorsUntil = e ?? undefined)}
              />
              <FormControlLabel
                sx={{ mt: 1 }}
                label={strings.pauseSyncWhileSnoozed}
                control={
                  <Checkbox
                    color="primary"
                    checked={viewModel.pauseSyncWhenSnoozed}
                    onChange={(e) => (viewModel.pauseSyncWhenSnoozed = e.target.checked)}
                  />
                }
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
              {strings.cancelButton}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isExecuting={viewModel.isSubmitting}
              onClick={() => void viewModel.save()}
            >
              {strings.saveButton}
            </InsightsButton>
          </DialogActions>
        </Dialog>
      </DateTimePickerLocalizationProvider>
    </ThemeProvider>
  );
});
