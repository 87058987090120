import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enUS as EnAdapter, fr as FrAdapter } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export type DateTimePickerLocalizationProviderProps = PropsWithChildren;

export const DateTimePickerLocalizationProvider = observer(({ children }: DateTimePickerLocalizationProviderProps) => {
  const { localizationService } = useInsightsServices();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localizationService.currentLocale === 'en' ? EnAdapter : FrAdapter}
    >
      {children}
    </LocalizationProvider>
  );
});
