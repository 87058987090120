import { RenameOrCopyOnboardingTemplateViewModel } from '@insights/viewmodels';
import { Box, SxProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface RenameOrCopyOnboardingTemplateProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: RenameOrCopyOnboardingTemplateViewModel;
}

export const RenameOrCopyOnboardingTemplate = observer((props: RenameOrCopyOnboardingTemplateProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <Box sx={sx} className={className} style={style}>
      <Typography variant="caption">{strings.currentTemplateNameLabel}</Typography>
      <Typography>{viewModel.currentTemplateName}</Typography>

      <TextField
        sx={{ mt: 2 }}
        fullWidth
        label={strings.newTemplateNameLabel}
        value={viewModel.newTemplateName}
        onChange={(e) => (viewModel.newTemplateName = e.target.value)}
        helperText={viewModel.message}
      />
    </Box>
  );
});
