import { VeracrossV3AccountViewModel } from '@insights/viewmodels';
import { SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';

export interface VeracrossV3AccountProps {
  sx?: SxProps;
  className?: string;
  viewModel: VeracrossV3AccountViewModel;
}

export const VeracrossV3Account = observer((props: VeracrossV3AccountProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Column sx={sx} className={className}>
      <TextField
        sx={{ m: 1 }}
        label={strings.accountName}
        value={viewModel.accountName}
        onChange={(event) => (viewModel.accountName = event.target.value)}
      />
      <TextField
        sx={{ m: 1 }}
        label={strings.clientId}
        value={viewModel.clientId}
        onChange={(event) => (viewModel.clientId = event.target.value)}
      />
      <TextField
        sx={{ m: 1 }}
        label={strings.clientSecret}
        type="password"
        value={viewModel.clientSecret}
        autoComplete="off"
        onChange={(event) => (viewModel.clientSecret = event.target.value)}
      />
      <TextField
        sx={{ m: 1 }}
        label={strings.schoolRoute}
        value={viewModel.schoolRoute}
        onChange={(event) => (viewModel.schoolRoute = event.target.value)}
      />
      <TextField
        type="number"
        sx={{ m: 1 }}
        label={strings.schoolYear}
        value={viewModel.schoolYear}
        onChange={(event) => {
          const year = Number(event.target.value);
          if (!Number.isNaN(year)) {
            viewModel.schoolYear = year;
          }
        }}
      />
      <TextField
        sx={{ m: 1 }}
        label={strings.portalUrl}
        value={viewModel.portalUrl}
        onChange={(event) => (viewModel.portalUrl = event.target.value)}
      />
      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
