import { css } from '@emotion/css';
import { SxProps, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { AdminAuthorizationRoles, OnboardingTextFormat } from '@shared/models/types';
import { Attributes, Interweave } from 'interweave';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import ReactMarkdown from 'react-markdown';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';

export interface OnboardingTypographyProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  text: string;
  format: OnboardingTextFormat;
  variant?: Variant;
  disabled?: boolean;
}

export const OnboardingTypography = observer((props: OnboardingTypographyProps) => {
  const { localizationService } = useInsightsServices();
  const { text, variant, disabled, format } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const disabledClassName = css([{ opacity: disabled ? 0.38 : undefined }]);

  switch (format) {
    case 'plain-text':
      return (
        <Typography whiteSpace="pre-line" variant={variant} className={disabledClassName}>
          {text}
        </Typography>
      );

    case 'markdown':
      return <ReactMarkdown className={disabledClassName}>{text}</ReactMarkdown>;

    case 'html': {
      const attributes: Attributes | undefined = disabled ? { style: { opacity: 0.38 } } : undefined;
      return <Interweave attributes={attributes} content={text} />;
    }

    default:
      return (
        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
          <Typography sx={{ color: (theme) => theme.palette.secondary.main }}>{strings.invalidText}</Typography>
        </AuthorizationRoleCondition>
      );
  }
});
