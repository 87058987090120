import { Checkbox, FormControl, FormControlLabel, FormHelperText, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface CanvasUnmappedAssignmentGroupsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  isIgnoringUnmappedGroups: boolean;
  onChange: (isIgnoringUnmappedGroups: boolean) => void;
}

export const CanvasUnmappedAssignmentGroups = observer((props: CanvasUnmappedAssignmentGroupsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, isIgnoringUnmappedGroups, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <FormControl sx={sx} className={className} style={style}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isIgnoringUnmappedGroups}
            onChange={(event) => onChange(event.target.checked)}
          />
        }
        label={strings.canvasIgnoringUnmappedGroupsLabel}
      />
      <FormHelperText>
        {isIgnoringUnmappedGroups
          ? strings.canvasIgnoringUnmappedGroupsNotice
          : strings.canvasNotIgnoringUnmappedGroupsNotice}
      </FormHelperText>
    </FormControl>
  );
});
