import { SourceRow as PBSourceRow } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { Memoize } from 'fast-typescript-memoize';
import { BaseModel } from '../../Model';
import { Incident, SourceRow } from '../interfaces';
import { GrpcIncident } from './GrpcIncident';

export class GrpcSourceRow extends BaseModel<PBSourceRow> implements SourceRow {
  constructor(pb: PBSourceRow) {
    super(pb);
  }

  get columns(): string[] {
    return this._pb.columns;
  }

  @Memoize()
  get incidents(): Incident[] {
    return this._pb.incidents.map((i) => new GrpcIncident(i));
  }
}
