import { EditableTransformationColumnViewModel } from '@insights/viewmodels';
import { ListItemText, MenuItem, Select, SxProps, TextField, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface EditableTargetSchemaFieldProps {
  sx?: SxProps;
  className?: string;
  column: EditableTransformationColumnViewModel;
}

export const EditableTargetSchemaField = observer((props: EditableTargetSchemaFieldProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, column } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  // When there are no available schemas, we allow free text to be entered, so that
  // resulting columns have a title.
  const hasSchema = column.availableSchemaFields.length > 0;

  return hasSchema ? (
    <Select
      sx={{ ...sx, mb: '10px' }}
      className={className}
      autoFocus={column.focusedField === 'schema'}
      multiple
      fullWidth
      value={column.schemaFields}
      disabled={column.isSubstitution}
      onChange={(e) => column.setSchemaFields(e.target.value as string[])}
      onFocus={() => column.setFocusedField('schema')}
      renderValue={() => (
        <Typography>{column.schemaFields.length === 0 ? strings.noSchema : column.schemaFields.join(', ')}</Typography>
      )}
      displayEmpty
    >
      {column.availableSchemaFields.map((f, i) => (
        <MenuItem key={`schema-field-${i}`} value={f.name}>
          <Tooltip title={f.description}>
            <ListItemText primary={f.name} />
          </Tooltip>
        </MenuItem>
      ))}
    </Select>
  ) : (
    <TextField
      sx={sx}
      className={className}
      fullWidth
      label={strings.targetColumnNames}
      helperText={strings.targetColumnNamesHint}
      slotProps={{ inputLabel: { shrink: true } }}
      value={column.schemaFieldsAsColumnNames}
      onChange={(e) => (column.schemaFieldsAsColumnNames = e.target.value)}
    />
  );
});
