import { CalendarsTransport } from '../../../transports';
import { CalendarsConnectorStore, ConnectorsStore } from '../../interfaces';
import { AppBaseStore } from '../AppBaseStore';

export class AppCalendarsConnectorStore extends AppBaseStore implements CalendarsConnectorStore {
  constructor(
    private readonly _transport: CalendarsTransport,
    connectorStore: ConnectorsStore
  ) {
    super('AppCalendarsConnectorStore', false, connectorStore);
  }

  async createOrUpdateCalendarAccount(configId: string, externalAccountId: string, accountName: string): Promise<void> {
    await this._transport.createOrUpdateCalendarExternalAccount(externalAccountId, configId, accountName);
    this.invalidate();
  }
}
