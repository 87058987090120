import { ConfigGlobalStats } from '@shared/models/metrics';
import { MetricsStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface ConfigGlobalStatsViewModel {
  readonly configId: string;

  readonly data: IPromiseBasedObservable<ConfigGlobalStats>;
}

export interface StudentConfigGlobalStats {
  readonly stats: ConfigGlobalStats;
  readonly expectedStudentCount: number;
}

export interface StudentConfigGlobalStatsViewModel {
  readonly configId: string;

  readonly data: IPromiseBasedObservable<StudentConfigGlobalStats>;
}

export class AppConfigGlobalStatsViewModel implements ConfigGlobalStatsViewModel {
  constructor(
    private readonly metricsStore: MetricsStore,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<ConfigGlobalStats> {
    return fromPromise(this.metricsStore.getConfigGlobalStats(this.configId));
  }
}

export class AppStudentConfigGlobalStatsViewModel implements StudentConfigGlobalStatsViewModel {
  constructor(
    private readonly metricsStore: MetricsStore,
    private readonly schoolsStore: SchoolYearConfigurationStore,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<StudentConfigGlobalStats> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<StudentConfigGlobalStats> {
    const [stats, summary] = await Promise.all([
      this.metricsStore.getConfigGlobalStats(this.configId),
      this.schoolsStore.getConfigSummary(this.configId)
    ]);

    return {
      stats,
      expectedStudentCount: summary.expectedStudentCount
    };
  }
}
