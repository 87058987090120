import { ManageBacMappingsViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowIcon from '@mui/icons-material/ForwardOutlined';
import {
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { EditableManageBacAssignmentKindMapping } from '@shared/models/connectors';
import { EditableManageBacContentRepresentation } from '@shared/models/connectors/EditableManageBacContentRepresentation';
import { AllContentWorkloadLevels, ContentIcon } from '@shared/models/types';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { ContentWorkloadLevelSelector } from './ContentWorkloadLevelSelector';
import { OptionalContentIconSelector } from './OptionalContentIconSelector';

export interface ManageBacAssignmentKindMappingsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: ManageBacMappingsViewModel;
}

export const ManageBacAssignmentKindMappings = observer((props: ManageBacAssignmentKindMappingsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  function updateIcon(mapping: EditableManageBacAssignmentKindMapping, icon: ContentIcon | undefined): void {
    if (icon == null) {
      mapping.representation = undefined;
    } else {
      if (mapping.representation == null) {
        mapping.representation = EditableManageBacContentRepresentation.createNew();
      }

      mapping.editableRepresentation.icon = icon!;
    }
  }

  return (
    <Root sx={sx} className={className} style={style}>
      <TableHead>
        <TableRow>
          <TableCell className={clsx('cell', 'kindCell')}>
            <InputLabel shrink>{strings.kindNameTitle}</InputLabel>
          </TableCell>
          <TableCell className={clsx('cell', 'actionCell')} />
          <TableCell className={clsx('cell', 'iconCell')}>
            <InputLabel shrink>{strings.iconTitle}</InputLabel>
          </TableCell>
          <TableCell className={clsx('cell', 'importanceCell')}>
            <InputLabel shrink>{strings.workloadLevelTitle}</InputLabel>
          </TableCell>
          <TableCell className={clsx('cell', 'actionCell')} />
        </TableRow>
      </TableHead>
      <TableBody>
        {viewModel.assignmentKindMappings.map((m, index) => (
          <TableRow key={`mapping-${index}`}>
            <TableCell className={clsx('cell', 'kindCell')}>
              <Typography>{m.kind}</Typography>
            </TableCell>
            <TableCell className={clsx('cell', 'actionCell')}>
              <ArrowIcon className={'arrow'} />
            </TableCell>
            <TableCell className={clsx('cell', 'iconCell')}>
              <OptionalContentIconSelector
                selectedIcon={m.representation?.icon}
                workloadLevel={m.representation?.workloadLevel}
                onChange={(icon) => updateIcon(m, icon)}
              />
            </TableCell>
            <TableCell className={clsx('cell', 'importanceCell')}>
              {m.representation?.icon != null && (
                <ContentWorkloadLevelSelector
                  selectedLevel={m.representation.workloadLevel}
                  availableLevels={AllContentWorkloadLevels}
                  onChange={(level) => (m.representation!.workloadLevel = level)}
                />
              )}
            </TableCell>
            <TableCell className={clsx('cell', 'actionCell')}>
              <IconButton onClick={() => m.markAsDeleted()}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow className={'nextRow'}>
          <TableCell className={clsx('cell', 'kindCell')}>
            <Input
              fullWidth
              value={viewModel.addingKindName}
              onChange={(e) => (viewModel.addingKindName = e.currentTarget.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => viewModel.addAssignmentKindMapping()} disabled={!viewModel.canAddKind}>
                    <AddIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter' && viewModel.canAddKind) {
                  viewModel.addAssignmentKindMapping();
                }
              }}
            />
          </TableCell>
          <TableCell className={clsx('cell', 'actionCell')} />
          <TableCell className={clsx('cell', 'iconCell')} />
          <TableCell className={clsx('cell', 'importanceCell')} />
          <TableCell className={clsx('cell', 'actionCell')} />
        </TableRow>
      </TableFooter>
    </Root>
  );
});

const Root = styled(Table)(() => ({
  '.cell': {
    padding: '2px 8px 2px 0px',
    border: 'none',
    verticalAlign: 'bottom'
  },
  '.kindCell': {
    width: '40%'
  },
  '.iconCell': {
    width: '20%'
  },
  '.importanceCell': {
    width: '20%'
  },
  '.actionCell': {
    width: '10%'
  },
  '.nextRow': {
    backgroundColor: grey[200]
  },
  '.arrow': {}
}));
