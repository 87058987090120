import * as TC from '@buf/studyo_studyo.bufbuild_es/studyo/type_teams_pb';
import { computed, makeObservable } from 'mobx';
import { EditableChildNullablePropertyEx, EditableModelEx, EditableStringProperty } from '../editables';
import { EditableTeamsContentRepresentation } from './EditableTeamsContentRepresentation';
import { GrpcTeamsAssignmentCategoryMapping } from './GrpcTeamsAssignmentCategoryMapping';
import { TeamsAssignmentCategoryMappingModel, TeamsContentRepresentationModel } from './interfaces';

export class EditableTeamsAssignmentCategoryMapping
  extends EditableModelEx<TC.AssignmentCategoryMapping>
  implements TeamsAssignmentCategoryMappingModel
{
  private _categoryId: EditableStringProperty<TC.AssignmentCategoryMapping>;
  private _categoryName: EditableStringProperty<TC.AssignmentCategoryMapping>;
  private _representation: EditableChildNullablePropertyEx<
    TC.ContentRepresentation,
    TeamsContentRepresentationModel,
    EditableTeamsContentRepresentation,
    TC.AssignmentCategoryMapping
  >;

  static createNew(categoryId: string, categoryName: string): EditableTeamsAssignmentCategoryMapping {
    const pb = new TC.AssignmentCategoryMapping();
    pb.categoryId = categoryId;
    pb.categoryName = categoryName;

    return new EditableTeamsAssignmentCategoryMapping(new GrpcTeamsAssignmentCategoryMapping(pb), true);
  }

  constructor(
    readonly originalMapping: TeamsAssignmentCategoryMappingModel,
    readonly isNew = false
  ) {
    super(originalMapping.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._categoryId = new EditableStringProperty(
        originalMapping.categoryId,
        (pb, value) => (pb.categoryId = value)
      )),
      (this._categoryName = new EditableStringProperty(
        originalMapping.categoryName,
        (pb, value) => (pb.categoryName = value),
        {
          trim: true
        }
      )),
      (this._representation = new EditableChildNullablePropertyEx(
        originalMapping.representation,
        (model) =>
          model == null
            ? EditableTeamsContentRepresentation.createNew()
            : new EditableTeamsContentRepresentation(model),
        (pb, pbValue) => {
          pb.contentRepresentation = pbValue;
        },
        originalMapping.representation == null
      ))
    ]);
  }

  @computed
  get categoryId() {
    return this._categoryId.value;
  }

  set categoryId(value: string) {
    this._categoryId.value = value;
  }

  @computed
  get categoryName() {
    return this._categoryName.value;
  }

  set categoryName(value: string) {
    this._categoryName.value = value;
  }

  @computed
  get representation() {
    return this._representation.value;
  }

  set representation(value: TeamsContentRepresentationModel | undefined) {
    this._representation.value = value;
  }

  @computed
  get editableRepresentation() {
    return this._representation.editableValue;
  }
}
