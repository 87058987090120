import { LightInsightsMuiTheme } from '@insights/theme';
import { CustomizableViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  Tabs as MuiTabs,
  Paper,
  styled,
  Tab,
  ThemeProvider,
  Tooltip,
  Typography
} from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { InsightsButton } from '../InsightsButton';
import { useViewModelRef } from '../utils';
import { CustomizationsList } from './CustomizationsList';
import { Schedules } from './Schedules';
import { SpecialDays } from './SpecialDays';

type Tabs = 'specialDays' | 'schedules';

export interface EditableDayCustomizationDialogProps extends DialogResult<void> {
  customizable: CustomizableViewModel;
}

export const EditableDayCustomizationDialog = observer(
  ({ customizable, onSuccess, onCancel }: EditableDayCustomizationDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createEditableCalendarDayCustomization(customizable, onSuccess!, onCancel!)
    );

    const [selectedTab, setSelectedTab] = useState<Tabs>('specialDays');
    const strings = localizationService.localizedStrings.insights.components.calendar;
    const viewStrings = localizationService.localizedStrings.insights.views.calendar;

    const save = () => viewModel.save();
    const cancel = () => viewModel.cancel();

    return (
      <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
        <Root open maxWidth="lg" fullWidth onClose={cancel}>
          <DialogTitle>{strings.editDayCustomizations}</DialogTitle>

          <DialogContent>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 4 }}>
                <Card className={'card'}>
                  <CardHeader title={viewModel.title} />
                  <CardContent className={'cardContent'}>
                    {viewModel.hasDayConfigurations ? (
                      <CustomizationsList
                        viewModel={viewModel.customizable}
                        color={'dark'}
                        renderRightComponent={(dc) => (
                          <Tooltip title={strings.removeDayCustomization}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                              }}
                            >
                              <IconButton className={'iconButton'} onClick={() => viewModel.removeDayConfiguration(dc)}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        )}
                        variant={'table'}
                      />
                    ) : (
                      <Typography variant={'subtitle2'} sx={{ color: (theme) => theme.palette.text.secondary }}>
                        {strings.emptyDayCustomizations}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid2>

              <Grid2 size={{ xs: 8 }}>
                <Paper sx={{ height: '100%', width: '100%', padding: 2 }}>
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <MuiTabs value={selectedTab} onChange={(_, value: Tabs) => setSelectedTab(value)}>
                      <Tab label={viewStrings.specialDays} value={'specialDays'} />
                      <Tab label={strings.bellTimes} value={'schedules'} />
                    </MuiTabs>
                    <Box
                      className={'cardContent'}
                      sx={{
                        height: '100%',
                        width: '100%',
                        overflow: 'auto'
                      }}
                    >
                      {selectedTab === 'specialDays' && (
                        <SpecialDays
                          viewModel={viewModel.calendar}
                          specialDays={viewModel.specialDays}
                          renderRowActions={(specialDay, isDeleted) =>
                            !isDeleted && (
                              <IconButton className={'iconButton'} onClick={() => viewModel.addSpecialDay(specialDay)}>
                                <AddIcon />
                              </IconButton>
                            )
                          }
                          columnsToExclude={['cycleDay', 'bellTimes']}
                        />
                      )}

                      {selectedTab === 'schedules' && (
                        <Schedules
                          viewModel={viewModel.calendar}
                          schedules={viewModel.schedules}
                          renderRowActions={(schedule, isDeleted) =>
                            !isDeleted && (
                              <IconButton className={'iconButton'} onClick={() => viewModel.addSchedule(schedule)}>
                                <AddIcon />
                              </IconButton>
                            )
                          }
                          columnsToExclude={['period', 'start', 'end']}
                        />
                      )}
                    </Box>
                  </Box>
                </Paper>
              </Grid2>
            </Grid2>
          </DialogContent>

          <DialogActions>
            <InsightsButton onClick={cancel}>{strings.cancel}</InsightsButton>
            <InsightsButton isDefault onClick={save}>
              {strings.done}
            </InsightsButton>
          </DialogActions>
        </Root>
      </ThemeProvider>
    );
  }
);

const Root = styled(Dialog)(() => ({
  '.iconButton': {
    padding: 4,
    marginRight: 4
  },
  '.card': {
    display: 'inherit',
    marginTop: 0,
    marginBottom: 0
  },
  '.cardContent': {
    overflow: 'auto',
    height: 300
  }
}));
