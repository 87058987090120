import { FormControl, FormControlLabel, Radio, RadioGroup, SxProps, Typography } from '@mui/material';
import { QuestionChoice } from '@shared/models/onboarding/interfaces';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ReadOnlySingleChoiceQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  choices: QuestionChoice[];
  answer?: string;
}

export const ReadOnlySingleChoiceQuestion = observer((props: ReadOnlySingleChoiceQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], choices, answer } = props;
  const languageCode = localizationService.currentLocale;

  return (
    <FormControl sx={sx} className={className} style={style}>
      <RadioGroup>
        {choices.map((choice) => (
          <FormControlLabel
            key={`q-ro-c-${choice.value}`}
            value={choice.value}
            control={<Radio sx={{ paddingY: '6px' }} disabled checked={choice.value === answer} />}
            label={
              <Typography fontWeight="400">
                {choice.label.find((l) => l.languageCode === languageCode)?.value ?? ''}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
});
