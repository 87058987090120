import { AccountStrings, EnglishAccountStrings, FrenchAccountStrings } from './AccountStrings';
import {
  ContentDefinitionStrings,
  EnglishContentDefinitionStrings,
  FrenchContentDefinitionStrings
} from './ContentDefinitionStrings';
import { DateFormats, EnglishDateFormatStrings, FrenchDateFormatStrings } from './DateFormats';
import { DayOfWeekStrings, EnglishDayOfWeekStrings, FrenchDayOfWeekStrings } from './DayOfWeekStrings';
import { DayStrings, EnglishDayStrings, FrenchDayStrings } from './DayStrings';
import {
  EnglishSchoolYearConfigurationStrings,
  FrenchSchoolYearConfigurationStrings,
  SchoolYearConfigurationStrings
} from './SchoolYearConfigurationStrings';
import { EnglishTimeFormatStrings, FrenchTimeFormatStrings, TimeFormats } from './TimeFormats';

export interface ModelsStrings {
  readonly account: AccountStrings;
  readonly contents: ContentDefinitionStrings;
  readonly dateFormats: DateFormats;
  readonly day: DayStrings;
  readonly dayOfWeek: DayOfWeekStrings;
  readonly schoolYearConfig: SchoolYearConfigurationStrings;
  readonly timeFormats: TimeFormats;
}

export const EnglishModelsStrings: ModelsStrings = {
  account: EnglishAccountStrings,
  contents: EnglishContentDefinitionStrings,
  dateFormats: EnglishDateFormatStrings,
  day: EnglishDayStrings,
  dayOfWeek: EnglishDayOfWeekStrings,
  schoolYearConfig: EnglishSchoolYearConfigurationStrings,
  timeFormats: EnglishTimeFormatStrings
};

export const FrenchModelsStrings: ModelsStrings = {
  account: FrenchAccountStrings,
  contents: FrenchContentDefinitionStrings,
  dateFormats: FrenchDateFormatStrings,
  day: FrenchDayStrings,
  dayOfWeek: FrenchDayOfWeekStrings,
  schoolYearConfig: FrenchSchoolYearConfigurationStrings,
  timeFormats: FrenchTimeFormatStrings
};
