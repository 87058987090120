import {
  AccountOptions,
  CalendarOptions,
  ConfigOptions,
  ImportSessionOptions,
  OnboardingOptions,
  SectionOptions
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_tools_pb';
import { AccountService, AlertService, NavigationService } from '@insights/services';
import { SuggestedTags } from '@insights/utils';
import { AccountModel, SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { ImportSession } from '@shared/models/import';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { dateService } from '@shared/services';
import { ImporterStore, OnboardingStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { ToolsTransport } from '@shared/services/transports';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { NavigateFunction } from 'react-router-dom';

interface SubmittableViewModel {
  readonly canSubmit: boolean;
}

interface DemoAwareViewModel {
  isDemo: boolean;
}

interface BaseOptionsViewModel<TOptions> extends SubmittableViewModel {
  toApiOptions(): TOptions | undefined;
}

export interface GeneralOptionsViewModel extends BaseOptionsViewModel<ConfigOptions> {
  schoolName: string;
  comments: string;
  readonly suggestedTags: string[];
  tags: string[];
  startDay: Day;
  endDay: Day;
  language: string;

  readonly canBeDemo: boolean;
  isDemo: boolean;

  readonly hasValidSchoolName: boolean;
  readonly hasValidDays: boolean;
}

export interface CalendarOptionsViewModel extends BaseOptionsViewModel<CalendarOptions> {
  readonly canKeepCalendar: boolean;
  shouldKeepCalendar: boolean;

  daysPerCycle: number;
  periodsPerDay: number;
  minutesPerPeriod: number;

  readonly hasValidDaysPerCycle: boolean;
  readonly hasValidPeriodsPerDay: boolean;
  readonly hasValidMinutesPerPeriod: boolean;
}

export interface AccountOutlineViewModel extends SubmittableViewModel {
  firstName: string;
  lastName: string;
  email: string;

  readonly isOptional: boolean;
  readonly isEmpty: boolean;
}

export interface AccountOptionsViewModel extends BaseOptionsViewModel<AccountOptions> {
  readonly canKeepAccounts: boolean;

  shouldKeepTeacherAccounts: boolean;
  readonly teacherAccounts: AccountModel[];

  readonly schoolAdminAccounts: AccountModel[];
  keepAllSchoolAdminAccounts: boolean;
  keptSchoolAdminAccountIds: string[];
  readonly shouldCreateNewAdminAccount: boolean;
  readonly newSchoolAdminAccount: AccountOutlineViewModel;
  readonly staffAdminAccount: AccountOutlineViewModel;
  readonly studyoBotAccount: AccountOutlineViewModel;
}

export interface SectionOutlineViewModel extends SubmittableViewModel {
  title: string;
  shortTitle: string;

  readonly isOptional: boolean;
  readonly isEmpty: boolean;
}

export interface SectionOptionsViewModel extends BaseOptionsViewModel<SectionOptions> {
  readonly canKeepSections: boolean;

  shouldCreateTrainingSection: boolean;
  readonly trainingSection: SectionOutlineViewModel;

  readonly lockedSections: SectionModel[];
  keepAllLockedSections: boolean;
  keptLockedSectionIds: string[];
  keepSectionSchedules: boolean;
}

export interface ImportSessionOptionsViewModel extends BaseOptionsViewModel<ImportSessionOptions> {
  readonly canImportSessions: boolean;
  readonly importSessions: ImportSession[];
  keepAllImportSessions: boolean;
  keptImportSessionIds: string[];
}

export interface OnboardingOptionsViewModel extends BaseOptionsViewModel<OnboardingOptions> {
  readonly processTemplateNames: string[];
  selectedProcessTemplateName: string;
}

export interface CreateSchoolViewModel extends SubmittableViewModel {
  readonly isCopy: boolean;

  readonly generalOptions: GeneralOptionsViewModel;
  readonly calendarOptions: CalendarOptionsViewModel;
  readonly accountOptions: AccountOptionsViewModel;
  readonly sectionOptions: SectionOptionsViewModel;
  readonly importOptions: ImportSessionOptionsViewModel;
  readonly onboardingOptions: OnboardingOptionsViewModel;

  readonly isSubmitting: boolean;

  createSchool(navigate: NavigateFunction): Promise<void>;
}

export interface LoadingCreateSchoolViewModel {
  readonly data: IPromiseBasedObservable<CreateSchoolViewModel>;
}

export class AppGeneralOptionsViewModel implements GeneralOptionsViewModel {
  @observable private _schoolName: string;
  @observable private _comments: string;
  @observable private _tags: string[];
  @observable private _startDay: Day;
  @observable private _endDay: Day;
  @observable private _language: string;
  @observable private _isDemo = false;

  constructor(
    private readonly _sourceConfig: SchoolYearConfigurationModel | undefined,
    currentLocale: string
  ) {
    makeObservable(this);

    this._schoolName = _sourceConfig?.schoolName ?? '';
    this._comments = _sourceConfig?.comments ?? '';
    this._tags = _sourceConfig?.tags ?? [];
    this._language = _sourceConfig?.language ?? currentLocale;

    this._startDay =
      _sourceConfig?.startDay.addWeeks(52) ??
      // Last Monday of August.
      Day.create({
        day: 31,
        month: 8,
        year: dateService.today.year
      }).firstDayOfWeek('monday');
    this._endDay =
      _sourceConfig?.endDay.addWeeks(52) ??
      // Last Friday of next June.
      Day.create({
        day: 30,
        month: 6,
        year: this._startDay.year + 1
      }).firstDayOfWeek('friday');
  }

  @computed
  get canSubmit() {
    return this.hasValidSchoolName && this.hasValidDays;
  }

  @computed
  get schoolName() {
    return this._schoolName;
  }

  set schoolName(value: string) {
    this._schoolName = value;
  }

  @computed
  get hasValidSchoolName() {
    return this.schoolName.length > 0;
  }

  @computed
  get comments() {
    return this._comments;
  }

  set comments(value: string) {
    this._comments = value;
  }

  get suggestedTags() {
    return SuggestedTags;
  }

  @computed
  get tags() {
    return this._tags;
  }

  set tags(values: string[]) {
    this._tags = values;
  }

  @computed
  get startDay() {
    return this._startDay;
  }

  set startDay(value: Day) {
    this._startDay = value;
  }

  @computed
  get endDay() {
    return this._endDay;
  }

  set endDay(value: Day) {
    this._endDay = value;
  }

  get canBeDemo() {
    return this._sourceConfig == null;
  }

  @computed
  get isDemo() {
    return this.canBeDemo && this._isDemo;
  }

  set isDemo(value: boolean) {
    this._isDemo = value;
  }

  @computed
  get hasValidDays() {
    return this.startDay.isBefore(this.endDay);
  }

  @computed
  get language() {
    return this._language;
  }

  set language(value: string) {
    this._language = value;
  }

  toApiOptions(): ConfigOptions | undefined {
    return new ConfigOptions({
      schoolName: this.schoolName,
      comments: this.comments,
      tags: this.tags,
      startDay: this.startDay,
      endDay: this.endDay,
      language: this.language,
      isDemoSchool: this.isDemo
    });
  }
}

export class AppCalendarOptionsViewModel implements CalendarOptionsViewModel {
  @observable private _shouldKeepCalendar: boolean;
  @observable private _daysPerCycle: number;
  @observable private _periodsPerDay = 6;
  @observable private _minutesPerPeriod = 75;

  constructor(private readonly _sourceConfig: SchoolYearConfigurationModel | undefined) {
    makeObservable(this);

    this._shouldKeepCalendar = _sourceConfig != null;
    this._daysPerCycle = _sourceConfig?.daysPerCycle ?? 5;
  }

  @computed
  get canSubmit() {
    return this.hasValidDaysPerCycle && this.hasValidPeriodsPerDay && this.hasValidMinutesPerPeriod;
  }

  get canKeepCalendar() {
    return this._sourceConfig != null;
  }

  @computed
  get shouldKeepCalendar() {
    return this.canKeepCalendar && this._shouldKeepCalendar;
  }

  set shouldKeepCalendar(value: boolean) {
    this._shouldKeepCalendar = value;
  }

  @computed
  get daysPerCycle() {
    return this._daysPerCycle;
  }

  set daysPerCycle(value: number) {
    if (value >= 0) {
      this._daysPerCycle = value;
    }
  }

  @computed
  get hasValidDaysPerCycle() {
    return this.daysPerCycle > 0 && this.daysPerCycle <= 90;
  }

  @computed
  get periodsPerDay() {
    return this._periodsPerDay;
  }

  set periodsPerDay(value: number) {
    if (value >= 0) {
      this._periodsPerDay = value;
    }
  }

  @computed
  get hasValidPeriodsPerDay() {
    return this.periodsPerDay > 0 && this.periodsPerDay <= 12;
  }

  @computed
  get minutesPerPeriod() {
    return this._minutesPerPeriod;
  }

  set minutesPerPeriod(value: number) {
    if (value >= 0) {
      this._minutesPerPeriod = value;
    }
  }

  @computed
  get hasValidMinutesPerPeriod() {
    // If periods are not valid, ignore minutes validity for now.
    return (
      !this.hasValidPeriodsPerDay || (this.minutesPerPeriod >= 5 && this.minutesPerPeriod * this.periodsPerDay <= 960)
    );
  }

  toApiOptions(): CalendarOptions | undefined {
    return !this.shouldKeepCalendar
      ? new CalendarOptions({
          daysPerCycle: this.daysPerCycle,
          periodsPerDay: this.periodsPerDay,
          minutesPerPeriod: this.minutesPerPeriod
        })
      : undefined;
  }
}

export class AppAccountOutlineViewModel implements AccountOutlineViewModel, DemoAwareViewModel {
  @observable private _firstName: string;
  @observable private _lastName: string;
  @observable private _email: string;
  @observable private _isDemo = false;

  constructor(firstName?: string, lastName?: string, email?: string) {
    makeObservable(this);

    this._firstName = firstName ?? '';
    this._lastName = lastName ?? '';
    this._email = email ?? '';
  }

  @computed
  get canSubmit() {
    return this._isDemo
      ? this._firstName.length > 0 === this._lastName.length > 0 &&
          this._firstName.length > 0 === this._email.length > 0
      : this._firstName.length > 0 && this._lastName.length > 0 && this._email.length > 0;
  }

  @computed
  get firstName() {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  @computed
  get lastName() {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  @computed
  get email() {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  @computed
  get isOptional() {
    return this._isDemo;
  }

  @computed
  get isEmpty() {
    return this._firstName.length === 0 && this._lastName.length === 0 && this._email.length === 0;
  }

  @computed
  get isDemo() {
    return this._isDemo;
  }

  set isDemo(value: boolean) {
    this._isDemo = value;
  }
}

export class AppAccountOptionsViewModel implements AccountOptionsViewModel, DemoAwareViewModel {
  private readonly _schoolAdminAccounts: AccountModel[];
  private readonly _teacherAccounts: AccountModel[];
  private readonly _newSchoolAdminAccount: AppAccountOutlineViewModel;
  private readonly _staffAdminAccount: AppAccountOutlineViewModel;
  private readonly _studyoBotAccount: AppAccountOutlineViewModel;
  @observable private _shouldKeepTeacherAccounts: boolean;
  @observable private _keptSchoolAdminAccountIds: string[];
  @observable private _isDemo = false;

  constructor(
    private readonly _sourceAccounts: AccountModel[],
    currentUserEmail: string
  ) {
    makeObservable(this);

    this._schoolAdminAccounts = _sourceAccounts.filter((a) => a.role === 'teacher' && a.isAdmin);
    this._keptSchoolAdminAccountIds = this._schoolAdminAccounts.map((a) => a.id);

    this._teacherAccounts = _sourceAccounts.filter(
      (a) =>
        a.role === 'teacher' &&
        !a.isAdmin &&
        !a.managedIdentifier.startsWith('STUDYO') &&
        a.email != 'support@studyo.co'
    );
    this._shouldKeepTeacherAccounts = this._teacherAccounts.length > 0;

    this._newSchoolAdminAccount = new AppAccountOutlineViewModel();

    const staffAdminAccount = _sourceAccounts.find((a) => a.role === 'studyo-staff' && a.isAdmin);
    this._staffAdminAccount = new AppAccountOutlineViewModel(
      staffAdminAccount?.firstName ?? this.getSuggestedStaffFirstName(currentUserEmail),
      staffAdminAccount?.lastName ?? this.getSuggestedStaffLastName(currentUserEmail),
      staffAdminAccount?.email ?? currentUserEmail
    );
    // No localization required for now.
    this._studyoBotAccount = new AppAccountOutlineViewModel('Studyo', 'Bot', 'support@studyo.co');
  }

  @computed
  get canSubmit() {
    return (
      (!this.shouldCreateNewAdminAccount || this.newSchoolAdminAccount.canSubmit) &&
      this.staffAdminAccount.canSubmit &&
      this.studyoBotAccount.canSubmit
    );
  }

  get canKeepAccounts() {
    return this._sourceAccounts.length > 0;
  }

  @computed
  get shouldKeepTeacherAccounts() {
    return this._shouldKeepTeacherAccounts;
  }

  set shouldKeepTeacherAccounts(value: boolean) {
    this._shouldKeepTeacherAccounts = value;
  }

  get teacherAccounts() {
    return this._teacherAccounts;
  }

  @computed
  private get keptTeacherAccountIds() {
    return this.shouldKeepTeacherAccounts ? this.teacherAccounts.map((a) => a.id) : [];
  }

  @computed
  get shouldCreateNewAdminAccount() {
    return !this.canKeepAccounts || this.keptSchoolAdminAccountIds.length === 0;
  }

  get newSchoolAdminAccount() {
    return this._newSchoolAdminAccount;
  }

  get schoolAdminAccounts() {
    return this._schoolAdminAccounts;
  }

  @computed
  get keepAllSchoolAdminAccounts() {
    return (
      this._keptSchoolAdminAccountIds.length > 0 &&
      this._keptSchoolAdminAccountIds.length === this._schoolAdminAccounts.length
    );
  }

  set keepAllSchoolAdminAccounts(value: boolean) {
    if (value) {
      this._keptSchoolAdminAccountIds = this._schoolAdminAccounts.map((a) => a.id);
    } else {
      this._keptSchoolAdminAccountIds = [];
    }
  }

  @computed
  get keptSchoolAdminAccountIds() {
    return this._keptSchoolAdminAccountIds;
  }

  set keptSchoolAdminAccountIds(values: string[]) {
    this._keptSchoolAdminAccountIds = values;
  }

  get staffAdminAccount() {
    return this._staffAdminAccount;
  }

  get studyoBotAccount() {
    return this._studyoBotAccount;
  }

  @computed
  get isDemo() {
    return this._isDemo;
  }

  set isDemo(value: boolean) {
    this._isDemo = value;
    this._newSchoolAdminAccount.isDemo = value;
    this._staffAdminAccount.isDemo = value;
    this._studyoBotAccount.isDemo = value;
  }

  toApiOptions(): AccountOptions | undefined {
    return new AccountOptions({
      keptAdminAccountIds: this.keptSchoolAdminAccountIds,
      keptTeacherAccountIds: this.keptTeacherAccountIds,
      newAdminAccountOutline:
        this.shouldCreateNewAdminAccount && !this.newSchoolAdminAccount.isEmpty
          ? this.newSchoolAdminAccount
          : undefined,
      studyoAdminAccountOutline: !this.staffAdminAccount.isEmpty ? this.staffAdminAccount : undefined,
      trainingAccountOutline: !this.studyoBotAccount.isEmpty ? this.studyoBotAccount : undefined
    });
  }

  // Root admins don't have an identity, so we have these static mappings.
  private getSuggestedStaffFirstName(userEmail: string) {
    switch (userEmail.toLowerCase()) {
      case 'pascal@studyo.co':
        return 'Pascal';
      case 'renaud@studyo.co':
        return 'Renaud';
      case 'pierreluc@studyo.co':
        return 'Pierre-Luc';
      case 'martin@studyo.co':
        return 'Martin';
    }

    return undefined;
  }

  private getSuggestedStaffLastName(userEmail: string) {
    switch (userEmail.toLowerCase()) {
      case 'pascal@studyo.co':
        return 'Bourque';
      case 'renaud@studyo.co':
        return 'Boisjoly';
      case 'pierreluc@studyo.co':
        return 'Ledoux';
      case 'martin@studyo.co':
        return 'Plante';
    }

    return undefined;
  }
}

export class AppSectionOutlineViewModel implements SectionOutlineViewModel, DemoAwareViewModel {
  @observable private _title: string;
  @observable private _shortTitle: string;
  @observable private _isDemo = false;

  constructor(title?: string, shortTitle?: string) {
    makeObservable(this);

    this._title = title ?? '';
    this._shortTitle = shortTitle ?? '';
  }

  @computed
  get canSubmit() {
    return this._isDemo
      ? this._title.length > 0 === this._shortTitle.length > 0
      : this._title.length > 0 && this._shortTitle.length > 0;
  }

  @computed
  get title() {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  @computed
  get shortTitle() {
    return this._shortTitle;
  }

  set shortTitle(value: string) {
    this._shortTitle = value;
  }

  @computed
  get isOptional() {
    return this._isDemo;
  }

  @computed
  get isEmpty() {
    return this._title.length === 0 && this._shortTitle.length === 0;
  }

  @computed
  get isDemo() {
    return this._isDemo;
  }

  set isDemo(value: boolean) {
    this._isDemo = value;
  }
}

export class AppSectionOptionsViewModel implements SectionOptionsViewModel, DemoAwareViewModel {
  private readonly _lockedSections: SectionModel[];
  @observable private _trainingSection: AppSectionOutlineViewModel;
  @observable private _keptLockedSectionIds: string[];
  @observable private _keepSectionSchedules = true;
  @observable private _shouldCreateTrainingSection = true;
  @observable private _isDemo = false;

  constructor(
    private readonly _localizationService: LocalizationService,
    sourceConfig: SchoolYearConfigurationModel | undefined,
    initialLanguage: string
  ) {
    makeObservable(this);

    this._lockedSections = sourceConfig?.sections.filter((s) => s.isLocked && !s.importId.startsWith('STUDYO')) ?? [];
    this._keptLockedSectionIds = this._lockedSections.map((s) => s.id);

    this._trainingSection = this.createTrainingSection(initialLanguage);
  }

  @computed
  get canSubmit() {
    return !this.shouldCreateTrainingSection || this.trainingSection.canSubmit;
  }

  get canKeepSections() {
    return this._lockedSections.length > 0;
  }

  get lockedSections() {
    return this._lockedSections;
  }

  @computed
  get keepAllLockedSections() {
    return this._keptLockedSectionIds.length > 0 && this._keptLockedSectionIds.length === this._lockedSections.length;
  }

  set keepAllLockedSections(value: boolean) {
    if (value) {
      this._keptLockedSectionIds = this._lockedSections.map((s) => s.id);
    } else {
      this._keptLockedSectionIds = [];
    }
  }

  @computed
  get keptLockedSectionIds() {
    return this._keptLockedSectionIds;
  }

  set keptLockedSectionIds(values: string[]) {
    this._keptLockedSectionIds = values;
  }

  @computed
  get keptLockedSections() {
    return this.lockedSections.filter((s) => this.keptLockedSectionIds.includes(s.id));
  }

  @computed
  get keepSectionSchedules() {
    return this.keptLockedSectionIds.length > 0 && this._keepSectionSchedules;
  }

  set keepSectionSchedules(value: boolean) {
    this._keepSectionSchedules = value;
  }

  @computed
  get shouldCreateTrainingSection() {
    return this._shouldCreateTrainingSection;
  }

  set shouldCreateTrainingSection(value: boolean) {
    this._shouldCreateTrainingSection = value;
  }

  @computed
  get trainingSection() {
    return this._trainingSection;
  }

  @computed
  get isDemo() {
    return this._isDemo;
  }

  set isDemo(value: boolean) {
    this._isDemo = value;
    this._trainingSection.isDemo = value;
  }

  @action
  onLanguageChanged(language: string) {
    this._trainingSection = this.createTrainingSection(language);
  }

  toApiOptions(): SectionOptions | undefined {
    return new SectionOptions({
      keepSectionSchedules: this.keepSectionSchedules,
      keptLockedSectionIds: this.keptLockedSectionIds,
      // keptUnlockedSectionIds not supported yet
      trainingSectionOutline:
        this.shouldCreateTrainingSection && !this.trainingSection.isEmpty ? this.trainingSection : undefined
    });
  }

  private createTrainingSection(language: string) {
    const strings = this._localizationService.resolveStrings(language === 'fr' ? 'fr' : 'en').insights.viewModels
      .createSchool;
    return new AppSectionOutlineViewModel(strings.trainingSectionTitle, 'STU');
  }
}

export class AppImportSessionOptionsViewModel implements ImportSessionOptionsViewModel {
  @observable private _keptImportSessionIds: string[];

  constructor(readonly importSessions: ImportSession[]) {
    makeObservable(this);

    this._keptImportSessionIds = importSessions.map((s) => s.id);
  }

  canSubmit = true;

  @computed
  get canImportSessions() {
    return this.importSessions.length > 0;
  }

  @computed
  get keepAllImportSessions() {
    return this._keptImportSessionIds.length > 0 && this._keptImportSessionIds.length === this.importSessions.length;
  }

  set keepAllImportSessions(value: boolean) {
    if (value) {
      this._keptImportSessionIds = this.importSessions.map((s) => s.id);
    } else {
      this._keptImportSessionIds = [];
    }
  }

  @computed
  get keptImportSessionIds() {
    return this._keptImportSessionIds;
  }

  set keptImportSessionIds(values: string[]) {
    this._keptImportSessionIds = values;
  }

  toApiOptions(): ImportSessionOptions | undefined {
    return new ImportSessionOptions({
      keptImportSessionIds: this.keptImportSessionIds
    });
  }
}

export class AppOnboardingOptionsViewModel implements OnboardingOptionsViewModel {
  @observable private _selectedProcessTemplateName = '';

  constructor(readonly processTemplateNames: string[]) {
    makeObservable(this);
  }

  canSubmit = true;

  @computed
  get selectedProcessTemplateName() {
    return this._selectedProcessTemplateName;
  }

  set selectedProcessTemplateName(value: string) {
    this._selectedProcessTemplateName = value;
  }

  toApiOptions(): OnboardingOptions | undefined {
    return new OnboardingOptions({
      processTemplateName: this.selectedProcessTemplateName
    });
  }
}

export class AppCreateSchoolViewModel implements CreateSchoolViewModel {
  private readonly _generalOptions: AppGeneralOptionsViewModel;
  private readonly _calendarOptions: AppCalendarOptionsViewModel;
  private readonly _accountOptions: AppAccountOptionsViewModel;
  private readonly _sectionOptions: AppSectionOptionsViewModel;
  private readonly _importOptions: AppImportSessionOptionsViewModel;
  private readonly _onboardingOptions: AppOnboardingOptionsViewModel;
  @observable private _isSubmitting = false;

  constructor(
    private readonly _toolsTransport: ToolsTransport,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _sourceConfig: SchoolYearConfigurationModel | undefined,
    sourceAccounts: AccountModel[],
    sourceImportSessions: ImportSession[],
    processTemplateNames: string[],
    currentUserEmail: string
  ) {
    makeObservable(this);

    this._generalOptions = new AppGeneralOptionsViewModel(_sourceConfig, _localizationService.currentLocale);
    this._calendarOptions = new AppCalendarOptionsViewModel(_sourceConfig);
    this._accountOptions = new AppAccountOptionsViewModel(sourceAccounts, currentUserEmail);
    this._sectionOptions = new AppSectionOptionsViewModel(
      _localizationService,
      _sourceConfig,
      this._generalOptions.language
    );
    this._importOptions = new AppImportSessionOptionsViewModel(sourceImportSessions);
    this._onboardingOptions = new AppOnboardingOptionsViewModel(processTemplateNames);

    reaction(
      () => this._generalOptions.isDemo,
      (isDemo) => {
        this.accountOptions.isDemo = isDemo;
        this._sectionOptions.isDemo = isDemo;
      }
    );

    reaction(
      () => this._generalOptions.language,
      (language) => this._sectionOptions.onLanguageChanged(language)
    );
  }

  @computed
  get canSubmit() {
    return (
      this.generalOptions.canSubmit &&
      this.calendarOptions.canSubmit &&
      this.accountOptions.canSubmit &&
      this.sectionOptions.canSubmit &&
      (this.importOptions?.canSubmit ?? true) &&
      this.onboardingOptions.canSubmit
    );
  }

  get isCopy() {
    return this._sourceConfig != null;
  }

  get generalOptions() {
    return this._generalOptions;
  }

  get calendarOptions() {
    return this._calendarOptions;
  }

  get accountOptions() {
    return this._accountOptions;
  }

  get sectionOptions() {
    return this._sectionOptions;
  }

  get importOptions() {
    return this._importOptions;
  }

  get onboardingOptions() {
    return this._onboardingOptions;
  }

  @computed
  get isSubmitting() {
    return this._isSubmitting;
  }

  async createSchool(navigate: NavigateFunction): Promise<void> {
    if (!this.canSubmit) {
      throw new Error('Something is preventing creation of the school');
    }

    const strings = this._localizationService.localizedStrings.insights.viewModels.createSchool;

    this._isSubmitting = true;

    try {
      const result = await this._toolsTransport.createNewConfiguration(
        this._sourceConfig?.id,
        this._generalOptions.toApiOptions(),
        this._calendarOptions.toApiOptions(),
        this._sectionOptions.toApiOptions(),
        this._accountOptions.toApiOptions(),
        this._importOptions.toApiOptions(),
        this._onboardingOptions.toApiOptions()
      );

      if (result.config == null) {
        await this._alertService.showMessage({
          title: strings.unexpectedResponseTitle,
          message: strings.unexpectedResponseMessage
        });
        return;
      }

      // If we have an onboarding template name, we navigate to the school's onboarding,
      // otherwise to general settings.
      if (this._onboardingOptions.selectedProcessTemplateName.length > 0) {
        await this._navigationService.navigateToOnboardingProcess(
          result.config.id,
          this._onboardingOptions.selectedProcessTemplateName,
          navigate
        );
      } else {
        await this._navigationService.navigateToManageSchool(result.config.id, navigate);
      }
    } catch (error) {
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isSubmitting = false));
    }
  }
}

export class AppLoadingCreateSchoolViewModel implements LoadingCreateSchoolViewModel {
  constructor(
    private readonly _toolsTransport: ToolsTransport,
    private readonly _schoolStore: SchoolYearConfigurationStore,
    private readonly _importerStore: ImporterStore,
    private readonly _onboardingStore: OnboardingStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _sourceConfigId?: string
  ) {}

  get data(): IPromiseBasedObservable<CreateSchoolViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<CreateSchoolViewModel> {
    let sourceConfig: SchoolYearConfigurationModel | undefined;
    let sourceAccounts: AccountModel[] = [];
    let sourceImports: ImportSession[] = [];

    if (this._sourceConfigId != null && this._sourceConfigId.length > 0) {
      [sourceConfig, sourceAccounts, sourceImports] = await Promise.all([
        this._schoolStore.getConfig(this._sourceConfigId),
        this._schoolStore.getAccounts(this._sourceConfigId, false),
        this._importerStore.getImportSessions(this._sourceConfigId)
      ]);
    }

    const processTemplateNames = await this._onboardingStore.getProcessTemplateNames();

    return new AppCreateSchoolViewModel(
      this._toolsTransport,
      this._navigationService,
      this._alertService,
      this._localizationService,
      sourceConfig,
      sourceAccounts,
      sourceImports,
      processTemplateNames,
      this._accountService.userEmail
    );
  }
}
