import { SchoolYearConfiguration_Link as PBLink } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { BaseModel, SerializableModel } from '../Model';

export interface LinkModel extends SerializableModel<PBLink> {
  readonly sortPosition: number;
  readonly text: string;
  readonly url: string;
}

export class Link extends BaseModel<PBLink> {
  constructor(pb: PBLink) {
    super(pb);
  }

  get sortPosition(): number {
    return this._pb.sortPosition;
  }

  get text(): string {
    return this._pb.text;
  }

  get url(): string {
    return this._pb.url;
  }
}
