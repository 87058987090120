export interface SettingsViewStrings {
  addExternalFileSourceButton: string;
  addExternalFileSourceTitle: string;
  applyButton: string;
  baseUrlLabel: string;
  cancelButton: string;
  cancelledBlackbaudSkyAuthorizationMessage: string;
  cancelledBlackbaudSkyAuthorizationTitle: string;
  completedBlackbaudSkyAuthorizationMessage: string;
  completedBlackbaudSkyAuthorizationNextStepsMessage: string;
  completedBlackbaudSkyAuthorizationTitle: string;
  completedBlackbaudSkyAuthorizationPartiallyMessage: string;
  completedBlackbaudSkyAuthorizationPartiallyTitle: string;
  completedTeamsAuthorizationMessage: string;
  completedTeamsAuthorizationTitle: string;
  completingBlackbaudSkyAuthorizationErrorMessage: string;
  completingBlackbaudSkyAuthorizationMessage: string;
  completingTeamsAuthorizationErrorMessage: string;
  completingTeamsAuthorizationMessage: string;
  editExternalFileSourceTitle: string;
  externalSourcesTitle: string;
  loadingOptionsErrorMessage: string;
  loadingOptionsMessage: string;
  passwordLabel: string;
  portLabel: string;
  returnToExternalAccountsButton: string;
  updatePasswordNotice: string;
  usernameLabel: string;
}

export const EnglishSettingsViewStrings: SettingsViewStrings = {
  addExternalFileSourceButton: 'Add Source',
  addExternalFileSourceTitle: 'Add a new external file source',
  applyButton: 'Save',
  baseUrlLabel: 'Base server address',
  cancelButton: 'Cancel',
  cancelledBlackbaudSkyAuthorizationMessage:
    'Authorization for your Blackbaud Sky connector was cancelled. You can repeat the process by editing your Blackbaud Sky external account.',
  cancelledBlackbaudSkyAuthorizationTitle: 'Blackbaud Sky authorization cancelled',
  completedBlackbaudSkyAuthorizationMessage:
    'Your Blackbaud Sky connector is successfully authorized. You can now update its settings and link Blackbaud and Studyo sections to synchronize assignments.',
  completedBlackbaudSkyAuthorizationNextStepsMessage:
    'Under external accounts, on your Blackbaud Sky account line, use the settings button (✂️) to select the school year and levels you wish to access, and the mappings button (✂️) to change how Blackbaud assignments are mapped to Studyo tasks.',
  completedBlackbaudSkyAuthorizationTitle: 'Blackbaud Sky connected!',
  completedBlackbaudSkyAuthorizationPartiallyMessage:
    "Your Blackbaud Sky connector was successfully authorized, but it seems we don't have access to all APIs. Please make sure that the user performing the authorization has full read access to your school information.",
  completedBlackbaudSkyAuthorizationPartiallyTitle: 'Blackbaud Sky connected, but…',
  completedTeamsAuthorizationMessage:
    'Your Microsoft Teams connector is successfully authorized. You can now update its settings and link Teams classes to Studyo sections to synchronize assignments.',
  completedTeamsAuthorizationTitle: 'Microsoft Teams Connected!',
  completingBlackbaudSkyAuthorizationErrorMessage: 'An unexpected error occurred.',
  completingBlackbaudSkyAuthorizationMessage: 'Completing authorization of your Blackbaud Sky connector…',
  completingTeamsAuthorizationErrorMessage: 'An unexpected error occurred.',
  completingTeamsAuthorizationMessage: 'Completing authorization of your Microsoft Teams connector…',
  editExternalFileSourceTitle: 'Edit an external file source',
  externalSourcesTitle: 'External sources of files',
  loadingOptionsErrorMessage: 'An unexpected error occurred.',
  loadingOptionsMessage: 'Loading options…',
  passwordLabel: 'Password',
  portLabel: 'Port number (0 = default)',
  returnToExternalAccountsButton: 'Return to External Accounts',
  updatePasswordNotice: 'You must re-enter the password',
  usernameLabel: 'User name'
};

export const FrenchSettingsViewStrings: SettingsViewStrings = {
  addExternalFileSourceButton: 'Ajouter une source',
  addExternalFileSourceTitle: 'Ajout d’une source externe de fichiers',
  applyButton: 'Enregistrer',
  baseUrlLabel: 'Adresse de base du serveur',
  cancelButton: 'Annuler',
  cancelledBlackbaudSkyAuthorizationMessage:
    'L’autorisation pour votre connecteur Blackbaud Sky fut annulée. Vous pouvez réessayer cette autorisation en éditant votre compte externe Blackbaud Sky.',
  cancelledBlackbaudSkyAuthorizationTitle: 'Autorisation de Blackbaud Sky annulée',
  completedBlackbaudSkyAuthorizationMessage:
    'Votre connecteur Blackbaud Sky a été autorisé avec succès. Vous pouvez maintenant ajuster ses options et lier les sections Blackbaud aux groupes-matière Studyo pour syncroniser les devoirs.',
  completedBlackbaudSkyAuthorizationNextStepsMessage:
    'Dans la section des comptes externes, à la ligne de votre compte Blackbaud Sky, utilisez le bouton des réglages (✂️) pour choisir l’année scolaire et les niveaux à utiliser, et le bouton des filtres (✂️) pour ajuster comment les devoirs Blackbaud sont transformés en tâches Studyo.',
  completedBlackbaudSkyAuthorizationTitle: 'Blackbaud Sky connecté!',
  completedBlackbaudSkyAuthorizationPartiallyMessage:
    'Votre connecteur Blackbaud Sky a été autorisé, mais il semble que nous n’ayons pas accès à tous les APIs. Assurez-vous que la personne qui fait l’autorisation possède les droits en lecture à vos informations scolaires.',
  completedBlackbaudSkyAuthorizationPartiallyTitle: 'Blackbaud Sky connecté, mais…',
  completedTeamsAuthorizationMessage:
    'Votre connecteur Microsoft Teams a été configuré avec succès. Vous pouvez maintenant lier des classes Teams avec des groupes-matière Studyo.',
  completedTeamsAuthorizationTitle: 'Microsoft Teams connecté!',
  completingBlackbaudSkyAuthorizationErrorMessage: 'Une erreur inattendue est survenue.',
  completingBlackbaudSkyAuthorizationMessage: 'Configuration de votre connecteur Blackbaud Sky…',
  completingTeamsAuthorizationErrorMessage: 'Une erreur inattendue est survenue.',
  completingTeamsAuthorizationMessage: 'Configuration de votre connecteur Microsoft Teams…',
  editExternalFileSourceTitle: 'Édition d’une source externe de fichiers',
  externalSourcesTitle: 'Sources externes de fichiers',
  loadingOptionsErrorMessage: 'Une erreur inattendue est survenue.',
  loadingOptionsMessage: 'Chargement des options…',
  passwordLabel: 'Mot de passe',
  portLabel: 'Numéro de port (0 = défaut)',
  returnToExternalAccountsButton: 'Retourner aux comptes externes',
  updatePasswordNotice: 'Vous devez entrer le mot de passe à nouveau',
  usernameLabel: 'Nom d’usager'
};
