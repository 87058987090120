import { EditableSchoolYearConfiguration } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { computed, makeObservable } from 'mobx';
import * as C from './Constants';
import { ValidatableViewModel } from './Editor';

export interface SchoolCalendarEditCycleDaysViewModel extends ValidatableViewModel {
  readonly editableConfig: EditableSchoolYearConfiguration;

  daysPerCycle: number;
  cycleDayTitles: string[];
}

export class AppSchoolCalendarEditCycleDaysViewModel implements SchoolCalendarEditCycleDaysViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    public readonly editableConfig: EditableSchoolYearConfiguration
  ) {
    makeObservable(this);
  }

  @computed
  get daysPerCycle() {
    return this.editableConfig.daysPerCycle;
  }

  set daysPerCycle(value: number) {
    this.editableConfig.daysPerCycle = value;
  }

  @computed
  get cycleDayTitles() {
    return this.editableConfig.cycleDayTitles;
  }

  set cycleDayTitles(values: string[]) {
    this.editableConfig.cycleDayTitles = values;
  }

  @computed
  get hasChanges() {
    return this.editableConfig.hasChanges;
  }

  validate(): string[] {
    const messages: string[] = [];

    const strings = this._localizationService.localizedStrings.insights.viewModels.calendar;

    if (this.daysPerCycle < C.MinimumDaysPerCycle) {
      messages.push(strings.minimumNumberOfDaysPerCycle + C.MinimumDaysPerCycle + '.');
    } else if (this.daysPerCycle > C.MaximumDaysPerCycle) {
      messages.push(strings.maximumNumberOfDaysPerCycle + C.MaximumDaysPerCycle + '.');
    }

    if (this.cycleDayTitles.some((t) => t.length > C.MaximumCycleDayTitleLength)) {
      messages.push(strings.cycleDayTitleCharLimit + C.MaximumCycleDayTitleLength + strings.characters);
    }

    return messages;
  }
}
