import { ObservablePresenter, ValueVariationPresenter } from '@insights/components';
import { ConfigGlobalStatsViewModel } from '@insights/viewmodels';
import { Box, SxProps } from '@mui/material';
import { ConfigGlobalStats } from '@shared/models/metrics';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

type ActiveStudentsVariationMode = 'past-week' | 'past-month' | 'this-year';

export interface ActiveStudentsVariationProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  mode: ActiveStudentsVariationMode;
  viewModel: ConfigGlobalStatsViewModel;
}

export const ActiveStudentsVariation = observer((props: ActiveStudentsVariationProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel, mode } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.students;

  const caption = useMemo(() => {
    switch (mode) {
      case 'past-week':
        return strings.pastWeek;

      case 'past-month':
        return strings.pastMonth;

      case 'this-year':
        return strings.thisYear;

      default:
        throw new Error('Unknown mode');
    }
  }, [mode, localizationService.currentLocale]);

  return (
    <Box sx={sx} className={className} style={style}>
      <ObservablePresenter
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        data={viewModel.data}
        indicatorsSize="small"
        render={(data) => {
          const values = getValues(data, mode);
          return (
            <ValueVariationPresenter caption={caption} value={values.value} variationPercentage={values.variation} />
          );
        }}
      />
    </Box>
  );
});

function getValues(configGlobalStats: ConfigGlobalStats, mode: ActiveStudentsVariationMode) {
  switch (mode) {
    case 'past-week':
      return {
        value: configGlobalStats.activeStudents.lastWeek,
        variation: configGlobalStats.activeStudents.lastWeekVariation
      };

    case 'past-month':
      return {
        value: configGlobalStats.activeStudents.lastMonth,
        variation: configGlobalStats.activeStudents.lastMonthVariation
      };

    case 'this-year':
      return {
        value: configGlobalStats.activeStudents.currentSchoolYear,
        variation: undefined
      };

    default:
      throw new Error('Unknown mode');
  }
}
