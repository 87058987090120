import { MicrosoftTeamsAccountSettingsViewModel } from '@insights/viewmodels';
import { SxProps } from '@mui/material';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react-lite';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';
import { MicrosoftTeamsCustomClassesFilter } from './MicrosoftTeamsCustomClassesFilter';

export interface MicrosoftTeamsAccountSettingsProps {
  sx?: SxProps;
  className?: string;
  viewModel: MicrosoftTeamsAccountSettingsViewModel;
  localizationService?: LocalizationService;
}

export const MicrosoftTeamsAccountSettings = observer((props: MicrosoftTeamsAccountSettingsProps) => {
  const { viewModel, sx = [], className } = props;

  return (
    <Column sx={sx} className={className}>
      <MicrosoftTeamsCustomClassesFilter
        filter={viewModel.customClassesFilter}
        onChange={(value) => (viewModel.customClassesFilter = value)}
      />

      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
