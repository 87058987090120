export interface ImportViewModelsStrings {
  concatenationName: (name: string) => string;
  confirmDeleteAutomatedImportMessage: (name: string) => string;
  confirmDeleteAutomatedImportTitle: string;
  confirmDeleteAutomatedTransformationImportMessage: (label: string) => string;
  confirmDeleteAutomatedTransformationImportTitle: string;
  confirmDeleteConcatenationMessage: (name: string, label: string) => string;
  confirmDeleteConcatenationTitle: string;
  confirmDeleteExpectedFileMessage: (name: string, label: string) => string;
  confirmDeleteExpectedFileTitle: string;
  confirmDeleteSessionMessage: string;
  confirmDeleteSessionTitle: string;
  confirmDeleteTransformationMessage: (name: string, label: string) => string;
  confirmDeleteTransformationTitle: string;
  directDataPreviousConfigLabel: string;
  directDataSelfConfigLabel: string;
  directDataTableParentsLabel: string;
  directDataTableSchedulesLabel: string;
  directDataTableSectionsLabel: string;
  directDataTableStudentsLabel: string;
  directDataTableTeachersLabel: string;
  fileName: (name: string) => string;
  importDataErrorMessage: string;
  labelInUseByConcatenation: string;
  labelInUseByFile: string;
  labelInUseByOtherConcatenation: string;
  labelInUseByOtherFile: string;
  labelInUseByOtherTransformation: string;
  labelInUseByTransformation: string;
  noLabel: string;
  none: string;
  renderTextDirectly: string;
  transformationName: (name: string) => string;
  unexpectedErrorMessage: string;
  unexpectedErrorTitle: string;
  yesLabel: string;
}

export const EnglishImportViewModelsStrings: ImportViewModelsStrings = {
  concatenationName: (name) => `Concatenation ${name}`,
  confirmDeleteAutomatedImportMessage: (name) => `Are you sure you want to delete automated import named '${name}'?`,
  confirmDeleteAutomatedImportTitle: 'Please confirm',
  confirmDeleteAutomatedTransformationImportMessage: (label) =>
    `Are you sure you want to delete automated transformation import for label ${label}?`,
  confirmDeleteAutomatedTransformationImportTitle: 'Please confirm',
  confirmDeleteConcatenationMessage: (name, label) =>
    `Are you sure you want to delete concatenation named '${name}' with label ${label}?`,
  confirmDeleteConcatenationTitle: 'Please confirm',
  confirmDeleteExpectedFileMessage: (name, label) =>
    `Are you sure you want to delete expected file named '${name}' with label ${label}?`,
  confirmDeleteExpectedFileTitle: 'Please confirm',
  confirmDeleteSessionMessage: 'Are you sure you want to delete this import session?',
  confirmDeleteSessionTitle: 'Please confirm',
  confirmDeleteTransformationMessage: (name, label) =>
    `Are you sure you want to delete transformation named '${name}' with label ${label}?`,
  confirmDeleteTransformationTitle: 'Please confirm',
  directDataPreviousConfigLabel: "Last year's configuration",
  directDataSelfConfigLabel: 'This configuration',
  directDataTableParentsLabel: 'Parents',
  directDataTableSchedulesLabel: 'Section schedules',
  directDataTableSectionsLabel: 'Sections',
  directDataTableStudentsLabel: 'Students',
  directDataTableTeachersLabel: 'Teachers',
  fileName: (name) => `File ${name}`,
  importDataErrorMessage: 'An error occurred while importing data. Please try again or contact support.',
  labelInUseByConcatenation: 'This label is already in use by a concatenation',
  labelInUseByFile: 'This label is already in use by an expected file',
  labelInUseByOtherConcatenation: 'This label is already in use by another concatenation',
  labelInUseByOtherFile: 'This label is already in use by another file',
  labelInUseByOtherTransformation: 'This label is already in use by another transformation',
  labelInUseByTransformation: 'This label is already in use by a transformation',
  noLabel: 'No',
  none: 'None',
  renderTextDirectly: 'Render text directly',
  transformationName: (name) => `Transformation ${name}`,
  unexpectedErrorMessage: 'An unexpected error occurred: ',
  unexpectedErrorTitle: 'Oops',
  yesLabel: 'Yes'
};

export const FrenchImportViewModelsStrings: ImportViewModelsStrings = {
  concatenationName: (name) => `Concaténation ${name}`,
  confirmDeleteAutomatedImportMessage: (name) =>
    `Êtes-vous certain de vouloir effacer l’importation automatisée nommée '${name}'?`,
  confirmDeleteAutomatedImportTitle: 'Veuillez confirmer',
  confirmDeleteAutomatedTransformationImportMessage: (label) =>
    `Êtes-vous certain de vouloir effacer l’importation automatisée de la transformation avec l’étiquette ${label}?`,
  confirmDeleteAutomatedTransformationImportTitle: 'Veuillez confirmer',
  confirmDeleteConcatenationMessage: (name, label) =>
    `Êtes-vous certain de vouloir effacer la concaténation nommée '${name}' avec l’étiquette ${label}?`,
  confirmDeleteConcatenationTitle: 'Veuillez confirmer',
  confirmDeleteExpectedFileMessage: (name, label) =>
    `Êtes-vous certain de vouloir effacer le fichier attendu nommé '${name}' avec l’étiquette ${label}?`,
  confirmDeleteExpectedFileTitle: 'Veuillez confirmer',
  confirmDeleteSessionMessage: 'Êtes-vous certain de vouloir effacer cette session d’importation?',
  confirmDeleteSessionTitle: 'Veuillez confirmer',
  confirmDeleteTransformationMessage: (name, label) =>
    `Êtes-vous certain de vouloir effacer la transformation nommée '${name}' avec l’étiquette ${label}?`,
  confirmDeleteTransformationTitle: 'Veuillez confirmer',
  directDataPreviousConfigLabel: 'La configuration de l’an passé',
  directDataSelfConfigLabel: 'Cette configuration',
  directDataTableParentsLabel: 'Parents',
  directDataTableSchedulesLabel: 'Horaires de groupes-matière',
  directDataTableSectionsLabel: 'Groupes-matière',
  directDataTableStudentsLabel: 'Élèves',
  directDataTableTeachersLabel: 'Enseignants',
  fileName: (name) => `Fichier ${name}`,
  importDataErrorMessage:
    'Une erreur est survenue durant l’importation des données. Veuillez réessayer ou contacter le support.',
  labelInUseByConcatenation: 'Cette étiquette est déjà utilisée par une concaténation',
  labelInUseByFile: 'Cette étiquette est déjà utilisée par un fichier',
  labelInUseByOtherConcatenation: 'Cette étiquette est déjà utilisée par une autre concaténation',
  labelInUseByOtherFile: 'Cette étiquette est déjà utilisée par un autre fichier',
  labelInUseByOtherTransformation: 'Cette étiquette est déjà utilisée par une autre transformation',
  labelInUseByTransformation: 'Cette étiquette est déjà utilisée par une transformation',
  noLabel: 'Non',
  none: 'Aucun',
  renderTextDirectly: 'Afficher le texte directement',
  transformationName: (name) => `Transformation ${name}`,
  unexpectedErrorMessage: 'Une erreur inattendue est survenue: ',
  unexpectedErrorTitle: 'Oups',
  yesLabel: 'Oui'
};
