import { AlertService, NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppAutomatedImportListViewModel } from './AutomatedImportListViewModel';
import {
  AppImportSessionListViewModel,
  ImportSessionInfo,
  ImportSessionListViewModel
} from './ImportSessionListViewModel';

export interface ImportSessionsScreenViewModel {
  readonly configId: string;
  readonly sessionList: IPromiseBasedObservable<ImportSessionListViewModel>;

  addSession: () => Promise<void>;
  importSessionFromOtherSchool: () => Promise<void>;
}

export class AppImportSessionsScreenViewModel implements ImportSessionsScreenViewModel {
  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get sessionList(): IPromiseBasedObservable<ImportSessionListViewModel> {
    return fromPromise(this.loadData());
  }

  async addSession() {
    const result = await this._navigationService.navigateToImportSessionCreate(this.configId);

    if (result !== 'cancelled') {
      this.invalidateData();
    }
  }

  async importSessionFromOtherSchool(): Promise<void> {
    const result = await this._navigationService.navigateToImportSessionImportFromOtherSchool(this.configId);

    if (result !== 'cancelled') {
      this.invalidateData();
    }
  }

  private async loadData(): Promise<ImportSessionListViewModel> {
    const sessions = await this._importSessionStore.getImportSessions(this.configId);
    const automatedImportsPerSession = await Promise.all(
      sessions.map((s) => this._importSessionStore.getAutomatedImports(this.configId, s.id))
    );

    return new AppImportSessionListViewModel(
      this._navigationService,
      this.configId,
      () => this.invalidateData(),
      _.zip(sessions, automatedImportsPerSession).map<ImportSessionInfo>((pair) => ({
        session: pair[0]!,
        automatedImports: new AppAutomatedImportListViewModel(
          this._importSessionStore,
          this._navigationService,
          this._alertService,
          this._localizationService,
          pair[0]!,
          pair[1]!
        )
      }))
    );
  }

  private invalidateData() {
    this._importSessionStore.invalidate();
  }
}
