import { CourseOccurrenceConfiguration_Customization } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModel, EditableValueProperty } from '../EditableModel';
import { CourseOccurrence } from './CourseOccurrence';
import { CourseOccurrenceCustomization, CourseOccurrenceCustomizationModel } from './CourseOccurrenceCustomization';

export class EditableCourseOccurrenceCustomization
  extends EditableModel<CourseOccurrenceConfiguration_Customization>
  implements CourseOccurrenceCustomizationModel
{
  private _skipped: EditableValueProperty<boolean, CourseOccurrenceConfiguration_Customization>;
  private _title: EditableValueProperty<string, CourseOccurrenceConfiguration_Customization>;
  private _roomName: EditableValueProperty<string, CourseOccurrenceConfiguration_Customization>;

  static createNew(occurrence: CourseOccurrence): EditableCourseOccurrenceCustomization {
    const pb = new CourseOccurrenceConfiguration_Customization();
    pb.ordinal = occurrence.ordinal;
    pb.skipped = occurrence.skipped;
    pb.title = occurrence.customTitle;
    pb.roomName = occurrence.customRoomName;
    return new EditableCourseOccurrenceCustomization(new CourseOccurrenceCustomization(pb), true);
  }

  constructor(
    private readonly _originalCustomization: CourseOccurrenceCustomizationModel,
    public readonly isNew = false
  ) {
    super(_originalCustomization.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._skipped = new EditableValueProperty(_originalCustomization.skipped, (pb, value) => (pb.skipped = value))),
      (this._title = new EditableValueProperty(_originalCustomization.title, (pb, value) => (pb.title = value))),
      (this._roomName = new EditableValueProperty(
        _originalCustomization.roomName,
        (pb, value) => (pb.roomName = value)
      ))
    ]);
  }

  //
  // Read-only properties
  //

  get ordinal() {
    return this._originalCustomization.ordinal;
  }

  //
  // Editable properties
  //

  @computed
  get skipped() {
    return this._skipped.value;
  }

  set skipped(value: boolean) {
    this._skipped.value = value;
  }

  @computed
  get title() {
    return this._title.value;
  }

  set title(value: string) {
    this._title.value = value;
  }

  @computed
  get roomName() {
    return this._roomName.value;
  }

  set roomName(value: string) {
    this._roomName.value = value;
  }
}
