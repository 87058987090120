import { AccountInfo, accountInfoFromModel } from '@insights/models';
import { AlertService, NavigationService } from '@insights/services';
import { AccountModel, EditableAccount, SectionModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface LoadingSectionStudentsEditionViewModel {
  readonly configId: string;
  readonly sectionId: string;

  readonly data: IPromiseBasedObservable<SectionStudentsEditionViewModel>;

  close(): void;
}

export interface SectionStudentsEditionViewModel {
  readonly section: SectionModel;
  readonly selectedAccounts: AccountInfo[];
  readonly availableAccounts: AccountInfo[];
  isViewingTeachers: boolean;

  readonly hasChanges: boolean;
  readonly isSaving: boolean;
  readonly savingPercent: number;
  readonly messages: string[];

  addAccount(account: AccountModel): void;
  removeAccount(account: AccountModel): void;
  importFromOtherSection(): Promise<void>;
  addFromIds(): Promise<void>;

  apply(): Promise<void>;
}

export class AppLoadingSectionStudentsEditionViewModel implements LoadingSectionStudentsEditionViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly sectionId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<SectionStudentsEditionViewModel> {
    return fromPromise(this.loadData());
  }

  close() {
    this._onCancel();
  }

  private async loadData(): Promise<SectionStudentsEditionViewModel> {
    const [section, teachers, students] = await Promise.all([
      this._schoolYearConfigurationStore.getSection(this.configId, this.sectionId),
      this._schoolYearConfigurationStore.getTeachers(this.configId, true),
      this._schoolYearConfigurationStore.getStudents(this.configId, false)
    ]);

    return new AppSectionStudentsEditionViewModel(
      this._localizationService,
      this._navigationService,
      this._alertService,
      this._schoolYearConfigurationStore,
      section,
      teachers,
      students,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.sectionId
    );
  }
}

export class AppSectionStudentsEditionViewModel implements SectionStudentsEditionViewModel {
  private readonly _studentsById: Record<string, AccountModel>;
  private readonly _teachersById: Record<string, AccountModel>;

  @observable private _addedAccountIds = new Set<string>();
  @observable private _removedAccountIds = new Set<string>();
  @observable private _selectedStudentIds: Set<string>;
  @observable private _selectedTeacherIds: Set<string>;

  @observable private _isViewingTeachers = false;
  @observable private _isSaving = false;
  @observable private _savingPercent = 0;
  @observable private _messages: string[] = [];

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    public readonly section: SectionModel,
    private readonly _teachers: AccountModel[],
    private readonly _students: AccountModel[],
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _sectionId: string
  ) {
    makeObservable(this);
    this._studentsById = _.keyBy(_students, (s) => s.id);
    this._teachersById = _.keyBy(_teachers, (t) => t.id);

    this._selectedStudentIds = new Set(
      _students.filter((s) => s.selectedSectionIds.includes(section.id)).map((s) => s.id)
    );
    this._selectedTeacherIds = new Set(
      _teachers.filter((t) => t.selectedSectionIds.includes(section.id)).map((t) => t.id)
    );
  }

  @computed
  get selectedAccounts() {
    return (
      this._isViewingTeachers
        ? Array.from(this._selectedTeacherIds).map((id) => this._teachersById[id])
        : Array.from(this._selectedStudentIds).map((id) => this._studentsById[id])
    ).map(accountInfoFromModel);
  }

  @computed
  get availableAccounts() {
    return (
      this._isViewingTeachers
        ? this._teachers.filter((t) => !this._selectedTeacherIds.has(t.id))
        : this._students.filter((s) => !this._selectedStudentIds.has(s.id))
    ).map(accountInfoFromModel);
  }

  @computed
  get isViewingTeachers() {
    return this._isViewingTeachers;
  }

  set isViewingTeachers(value: boolean) {
    this._isViewingTeachers = value;
  }

  @computed
  get hasChanges(): boolean {
    return this._addedAccountIds.size > 0 || this._removedAccountIds.size > 0;
  }

  @computed
  get isSaving(): boolean {
    return this._isSaving;
  }

  @computed
  get savingPercent(): number {
    return this._isSaving ? this._savingPercent : 0;
  }

  @computed
  get messages(): string[] {
    return this._messages;
  }

  @action
  addAccount(account: AccountModel) {
    if (account.role === 'student') {
      if (this._selectedStudentIds.has(account.id)) {
        console.error('An already added account is being added again.');
        return;
      }

      this._selectedStudentIds.add(account.id);
    } else {
      if (this._selectedTeacherIds.has(account.id)) {
        console.error('An already added account is being added again.');
        return;
      }

      this._selectedTeacherIds.add(account.id);
    }

    // We keep track of actual changes in separate sets.
    // Try to undo removal first.
    if (!this._removedAccountIds.delete(account.id)) {
      // We really need to add it.
      this._addedAccountIds.add(account.id);
    }
  }

  @action
  removeAccount(account: AccountModel) {
    if (account.role === 'student') {
      if (!this._selectedStudentIds.has(account.id)) {
        console.error('An already removed account is being removed again.');
        return;
      }

      this._selectedStudentIds.delete(account.id);
    } else {
      if (!this._selectedTeacherIds.has(account.id)) {
        console.error('An already removed account is being removed again.');
        return;
      }

      this._selectedTeacherIds.delete(account.id);
    }

    // We keep track of actual changes in separate sets.
    // Try to undo addition first.
    if (!this._addedAccountIds.delete(account.id)) {
      // We really need to remove it.
      this._removedAccountIds.add(account.id);
    }
  }

  @action
  async apply(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.metrics;

    if (!this.hasChanges) {
      console.error('Invalid operation: It should not be possible to call apply without changes.');
      return;
    }

    this._savingPercent = 0;
    this._isSaving = true;
    this._messages = [];

    try {
      const total = this._addedAccountIds.size + this._removedAccountIds.size;
      let saved = 0;

      for (const id of this._addedAccountIds.values()) {
        const account = this._studentsById[id] || this._teachersById[id];
        const editableAccount = new EditableAccount(account);
        const editableSettings = editableAccount.getEditableSettings();

        editableSettings.selectedSectionIds = [...editableSettings.selectedSectionIds, this.section.id];

        // Do not invalidate store just yet
        await this._schoolYearConfigurationStore.saveAccount(editableAccount, true);

        runInAction(() => (this._savingPercent = (++saved * 100) / total));
      }

      for (const id of this._removedAccountIds.values()) {
        const account = this._studentsById[id] || this._teachersById[id];
        const editableAccount = new EditableAccount(account);
        const editableSettings = editableAccount.getEditableSettings();

        editableSettings.selectedSectionIds = editableSettings.selectedSectionIds.filter(
          (sid) => sid !== this.section.id
        );

        // Do not invalidate store just yet
        await this._schoolYearConfigurationStore.saveAccount(editableAccount, true);

        runInAction(() => (this._savingPercent = (++saved * 100) / total));
      }

      // Invalidate only once completed
      this._schoolYearConfigurationStore.invalidate();
      this._onSuccess();
    } catch (error) {
      runInAction(() => {
        this._messages = [strings.unexpectedError + (error as Error).message];
      });
    } finally {
      runInAction(() => {
        this._isSaving = false;
      });
    }
  }

  async importFromOtherSection(): Promise<void> {
    const studentIds = await this._navigationService.navigateToSectionStudentsEditionSectionSelection(
      this._configId,
      this._sectionId
    );

    if (studentIds != 'cancelled') {
      const studentsById = await this._schoolYearConfigurationStore.getStudentsById(this._configId, false);

      for (const id of studentIds) {
        const student = studentsById[id];
        if (!this._selectedStudentIds.has(id) && student != null) {
          this.addAccount(student);
        }
      }
    }
  }

  async addFromIds(): Promise<void> {
    const studentIds = await this._navigationService.navigateToSectionStudentsEditionIdsSelection();

    if (studentIds != 'cancelled') {
      const studentsByManagedId = await this._schoolYearConfigurationStore.getStudentsByManagedId(
        this._configId,
        false
      );
      const studentsByEmail = await this._schoolYearConfigurationStore.getStudentsByEmail(this._configId, false);
      const unknownIds: string[] = [];

      await Promise.all(
        studentIds.map((id) => {
          const student = studentsByManagedId[id] ?? studentsByEmail[id];

          if (student == null) {
            unknownIds.push(id);
          } else if (!this._selectedStudentIds.has(student.id)) {
            this.addAccount(student);
          }
        })
      );

      if (unknownIds.length > 0) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.sections;
        await this._alertService.showMessage({
          title: strings.unknownIdsErrorTitle,
          message: strings.unknownIdsErrorMessage
        });
      }
    }
  }
}
