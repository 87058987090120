import { AppStorageModel, AppStorageProperty, StorageModel, StorageProperty } from '@insights/utils';
import { Storage } from '@shared/services';
import { computed, makeObservable } from 'mobx';

const ExamOnlyStorageKey = 'WorkloadPreferencesExamOnly';
const ImportantTasksOnlyStorageKey = 'WorkloadPreferencesImportantTasksOnly';

export interface WorkloadPreferences extends StorageModel {
  examOnly: boolean;
  importantTasksOnly: boolean;
}

export class AppWorkloadPreferences extends AppStorageModel implements WorkloadPreferences {
  private readonly _examOnly: StorageProperty<boolean>;
  private readonly _importantTasksOnly: StorageProperty<boolean>;

  constructor(storage: Storage) {
    super(storage);

    makeObservable(this);

    this.setStorageProperties([
      (this._examOnly = new AppStorageProperty<boolean>(storage, ExamOnlyStorageKey, false)),
      (this._importantTasksOnly = new AppStorageProperty<boolean>(storage, ImportantTasksOnlyStorageKey, false))
    ]);
  }

  @computed
  get examOnly(): boolean {
    return this._examOnly.value;
  }

  set examOnly(value: boolean) {
    this._examOnly.value = value;
  }

  @computed
  get importantTasksOnly(): boolean {
    return this._importantTasksOnly.value;
  }

  set importantTasksOnly(value: boolean) {
    this._importantTasksOnly.value = value;
  }
}
