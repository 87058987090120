import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

export const DefaultAnimationDuration = 350;
export const DefaultAnimationEasing = am4core.ease.expOut;

export function amChartTheme(target: unknown) {
  // Set the fontFamily for all the components of the chart
  if (target instanceof am4core.Container) {
    target.fontFamily = 'Roboto';
  }

  // Configure the animations for the different components of the chart
  if (target instanceof am4core.Component) {
    target.interpolationDuration = DefaultAnimationDuration;
    target.interpolationEasing = DefaultAnimationEasing;

    target.rangeChangeDuration = DefaultAnimationDuration;
    target.rangeChangeEasing = DefaultAnimationEasing;

    target.sequencedInterpolation = false;
  }

  if (target instanceof am4core.Tooltip) {
    target.animationDuration = DefaultAnimationDuration;
    target.animationEasing = DefaultAnimationEasing;
  }

  if (target instanceof am4core.SpriteState) {
    target.transitionDuration = DefaultAnimationDuration;
    target.transitionEasing = DefaultAnimationEasing;
  }

  // Configure defaults for a LineSeries
  if (target instanceof am4charts.LineSeries) {
    target.strokeWidth = 3;
    target.simplifiedProcessing = true;
  }

  // Configure defaults for a LineSeries
  if (target instanceof am4charts.ColumnSeries) {
    target.simplifiedProcessing = true;
  }

  if (target instanceof am4charts.XYChart) {
    if (target.zoomOutButton != null) {
      target.zoomOutButton.disabled = true;
    }
  }

  // Configure the colors
  if (target instanceof am4core.ColorSet) {
    // Remove the default palette. This will be done chart by chart.
    target.list = [];
  }
}
