import { PublishedTasksDetailTaskInfo } from '@insights/viewmodels';
import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { TaskInformation } from './TaskInformation';

export interface PublishedTasksDetailTaskItemProps {
  sx?: SxProps;
  className?: string;
  onClick?: () => void;
  schoolYearConfig: SchoolYearConfigurationModel;
  sectionsById: Record<string, SectionModel>;
  style?: CSSProperties;
  taskInfo: PublishedTasksDetailTaskInfo;
}

export const PublishedTasksDetailTaskItem = observer((props: PublishedTasksDetailTaskItemProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, schoolYearConfig, sectionsById, style, taskInfo, onClick } = props;
  const strings = localizationService.localizedStrings.insights.components.tasks;
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...sx,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: grey[100]
        },
        overflow: 'hidden'
      }}
      className={className}
      style={style}
      display="flex"
      flexDirection="row"
      alignItems="center"
      onClick={() => onClick && onClick()}
    >
      <Box flex={1} paddingY={0.5} overflow="hidden">
        <TaskInformation
          task={taskInfo.task}
          type="two-lines"
          size="small"
          disableTooltip
          schoolYearConfig={schoolYearConfig}
          sectionsById={sectionsById}
        />
      </Box>

      {/* Dates */}
      <Box width="250px" marginLeft={2}>
        {/* Published at */}
        <Typography variant="body2" noWrap>
          <span>{strings.publishedOn}</span>

          <span style={{ marginLeft: theme.spacing(1), marginRight: theme.spacing(1), fontWeight: '400' }}>
            {format(taskInfo.publishedAt, localizationService.localizedStrings.models.dateFormats.short)}
          </span>

          <span>{strings.at}</span>

          <span style={{ marginLeft: theme.spacing(1), fontWeight: '400' }}>
            {format(taskInfo.publishedAt, localizationService.localizedStrings.models.dateFormats.time)}
          </span>
        </Typography>

        {/* Due day */}

        <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }} noWrap fontStyle="italic">
          <span>{strings.dueOn}</span>

          <span style={{ marginLeft: theme.spacing(1), fontWeight: '400' }}>
            {format(taskInfo.task.dueDay.asDate, localizationService.localizedStrings.models.dateFormats.short)}
          </span>

          {taskInfo.task.duePeriodTag && (
            <>
              <span style={{ marginLeft: theme.spacing(1) }}>{strings.atPeriod}</span>

              <span style={{ marginLeft: theme.spacing(1), fontWeight: '400' }}>{taskInfo.task.duePeriodTag}</span>
            </>
          )}
        </Typography>
      </Box>
    </Box>
  );
});
