import { NavigationService } from '@insights/services';
import { CanvasSubAccount } from '@shared/models/connectors';
import { LocalizationService } from '@shared/resources/services';
import { CanvasConnectorStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppCanvasAccountSetupViewModel, CanvasAccountSetupViewModel } from './CanvasAccountSetupViewModel';

export interface CanvasAccountSetupDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly settings: IPromiseBasedObservable<CanvasAccountSetupViewModel>;
}

export class AppCanvasAccountSetupDialogViewModel implements CanvasAccountSetupDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _canvasStore: CanvasConnectorStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get settings(): IPromiseBasedObservable<CanvasAccountSetupViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<CanvasAccountSetupViewModel> {
    const [details, terms] = await Promise.all([
      this._canvasStore.getCanvasAccountDetails(this.externalAccountId),
      this._canvasStore.getCanvasTerms(this.externalAccountId)
    ]);

    let accounts: CanvasSubAccount[] = [];

    try {
      // We ignore errors fetching accounts, just in case.
      accounts = await this._canvasStore.getCanvasSubAccounts(this.externalAccountId);
    } catch {
      console.error('Failed to get sub-accounts. Assuming none.');
    }

    return new AppCanvasAccountSetupViewModel(
      this._localizationService,
      this._canvasStore,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      accounts,
      terms,
      details
    );
  }
}
