import * as VM from '@insights/viewmodels';
import { SchoolDay } from '@shared/models/calendar';
import { AccountModel, SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { ExternalAccount } from '@shared/models/connectors';
import { ContentDefinitionModel } from '@shared/models/content';
import {
  AutomatedImport,
  AutomatedImportResult,
  AutomatedTransformationImport,
  EditableAutomatedTransformationImport,
  EditableExternalFileSource,
  ExternalFileSource,
  ImportData,
  ImportSession,
  Schema,
  SchemaImportOption,
  SourceData
} from '@shared/models/import';
import { OnboardingProcess, OnboardingQuestion, OnboardingStep } from '@shared/models/onboarding/interfaces';
import {
  Day,
  OnboardingParticipantKind,
  OnboardingTemplateKind,
  Role,
  SubscriptionEntitlement
} from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { Storage } from '@shared/services';
import {
  CalendarStore,
  ConnectorsStore,
  ContentStore,
  ImporterStore,
  MetricsStore,
  OnboardingStore,
  SchoolYearConfigurationStore,
  UserStore
} from '@shared/services/stores';
import { ToolsTransport } from '@shared/services/transports/interfaces/ToolsTransport';
import { InsightsServiceContainer } from '../InsightsServiceContainer.ts';
import { AccountService } from './AccountService';
import { AlertService } from './AlertService';
import { ContentService } from './ContentService';
import { InsightsEnvironmentService } from './InsightsEnvironmentService';
import { NavigationService } from './NavigationService';
import { SettingsStore } from './SettingsStore';
import { SubscriptionsService } from './SubscriptionsService';

export interface ViewModelFactory {
  createActiveStudents: (configId: string) => VM.ActiveStudentsViewModel;
  createActiveTeachers: (configId: string) => VM.ActiveTeachersViewModel;
  createConfigGlobalStats: (configId: string) => VM.ConfigGlobalStatsViewModel;
  createStudentConfigGlobalStats: (configId: string) => VM.StudentConfigGlobalStatsViewModel;
  createDailyAccountSessions: (configId: string, accountId: string) => VM.DailyAccountSessionsViewModel;
  createDashboard: (configId: string) => VM.DashboardViewModel;
  createAdminDashboard: (configId: string) => VM.AdminDashboardViewModel;
  createParentDashboard: (configId: string) => VM.ParentDashboardViewModel;
  createStudentDashboard: (configId: string) => VM.StudentDashboardViewModel;
  createTeacherDashboard: (configId: string) => VM.TeacherDashboardViewModel;
  createTeacherDashboardFilter: (
    configId: string,
    teacherId: string,
    onSuccess: (filters: VM.TeacherDashboardFilters) => void,
    onCancel: () => void,
    initialFilters?: VM.TeacherDashboardFilters
  ) => VM.TeacherDashboardFilterDialogViewModel;
  createForbidden: () => VM.ForbiddenViewModel;
  createNoSchoolConfigurations: () => VM.NoSchoolConfigurationsViewModel;
  createImportSessions: (configId: string) => VM.ImportSessionsScreenViewModel;
  createImportSessionSourceData: (sessionId: string, label: string) => VM.ImportSessionSourceDataScreenViewModel;
  createImportSessionDetails: (configId: string, sessionId: string) => VM.ImportSessionDetailsScreenViewModel;
  createImportSessionTransformation: (
    configId: string,
    sessionId: string,
    transformationId: string
  ) => VM.ImportSessionTransformationDetailsScreenViewModel;
  createImportSessionCreate: (
    configId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ImportSessionCreateScreenViewModel;
  createImportSessionEdit: (
    session: ImportSession,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ImportSessionEditDialogViewModel;
  createImportSessionImportFromSchool: (
    configId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ImportSessionImportFromSchoolDialogViewModel;
  createImportSessionAddOrEditExpectedFile: (
    configId: string,
    session: ImportSession,
    fileLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ImportSessionAddOrEditExpectedFileDialogViewModel;
  createImportSessionFileEdit: (
    configId: string,
    session: ImportSession,
    fileLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ImportSessionFileEditDialogViewModel;
  createImportSessionFileProcessLink: (
    configId: string,
    session: ImportSession,
    fileLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ImportSessionFileProcessLinkDialogViewModel;
  createImportSessionFileDirectDataLink: (
    configId: string,
    session: ImportSession,
    fileLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ImportSessionFileDirectDataDialogViewModel;
  createImportSessionFileManualLink: (
    configId: string,
    session: ImportSession,
    fileLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ImportSessionFileManualLinkDialogViewModel;
  createImportSessionFileOrderedHeaders: (
    configId: string,
    session: ImportSession,
    fileLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ImportSessionFileOrderedHeadersDialogViewModel;
  createImportSessionAddOrEditConcatenation: (
    configId: string,
    session: ImportSession,
    concatenationLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ImportSessionAddOrEditConcatenationDialogViewModel;
  createImportSessionAddOrEditTransformation: (
    configId: string,
    session: ImportSession,
    transformationLabel: string,
    schemas: Schema[],
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ImportSessionAddOrEditTransformationDialogViewModel;
  createImportDataDetails: (configId: string, sessionId: string, label: string) => VM.ImportDataDetailsScreenViewModel;
  createImportDataIncidentsSelection: (
    configId: string,
    sourceSessionId: string,
    importData: ImportData,
    data: SourceData,
    isCompleteData: boolean,
    options: SchemaImportOption[],
    onSuccess: (value: ImportData) => void,
    onCancel: () => void
  ) => VM.ImportDataIncidentsSelectionScreenViewModel;
  createAutomatedImportCreateOrEdit: (
    onSuccess: (value: AutomatedImport) => void,
    onCancel: () => void,
    configId: string,
    importSessionId: string,
    automatedImport?: AutomatedImport
  ) => VM.AutomatedImportCreateOrEditDialogViewModel;
  createAutomatedImportDetails(
    configId: string,
    sessionId: string,
    automatedImportId: string
  ): VM.AutomatedImportDetailsScreenViewModel;
  createAutomatedImportResultDetails(
    onCancel: () => void,
    importSession: ImportSession,
    automatedImport: AutomatedImport,
    result: AutomatedImportResult
  ): VM.AutomatedImportResultDetailsDialogViewModel;
  createAutomatedTransformationImportViewModel(
    onSuccess: () => void,
    onCancel: () => void,
    importSession: ImportSession,
    automatedTransformationImport: AutomatedTransformationImport
  ): VM.AutomatedTransformationImportDialogViewModel;
  createLogin: () => VM.LoginViewModel;
  createLogout: () => VM.LogoutViewModel;
  createCreateSchool: (sourceConfigId?: string) => VM.LoadingCreateSchoolViewModel;
  createNotFound: () => VM.NotFoundViewModel;
  createSchoolConfiguration: (configId: string) => VM.SchoolConfigurationViewModel;
  createSchoolConfigurations: () => VM.SchoolConfigurationsViewModel;
  createOnboardingDashboard: () => VM.OnboardingDashboardViewModel;
  createOnboardingCommentsDashboard: () => VM.OnboardingCommentsDashboardViewModel;
  createUsers: () => VM.UsersViewModel;
  createGlobalSettings: () => VM.GlobalSettingsScreenViewModel;
  createMicrosoftTeamsRedirect: (query: string) => VM.MicrosoftTeamsAuthorizationRedirectScreenViewModel;
  createBlackbaudSkyRedirect: (query: string) => VM.BlackbaudSkyAuthorizationRedirectScreenViewModel;
  createSectionDetails: (configId: string, sectionId: string) => VM.SectionDetailsViewModel;
  createSectionEdition: (
    configId: string,
    sectionIds: string[],
    isEditingSchedules: boolean,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.LoadingSectionEditionViewModel;
  createManageSectionConflicts: (
    configId: string,
    sectionId: string,
    startingDay: Day | undefined,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ManageSectionConflictsDialogViewModel;
  createStudentBehaviourHistory: (configId: string, studentId: string) => VM.StudentBehaviourHistoryViewModel;
  createSectionStudentsEdition: (
    configId: string,
    sectionId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.LoadingSectionStudentsEditionViewModel;
  createSectionStudentsEditionSectionSelection: (
    configId: string,
    sectionId: string,
    onSuccess: (studentIds: string[]) => void,
    onCancel: () => void
  ) => VM.LoadingSectionStudentsEditionSectionSelectionViewModel;
  createSectionStudentsEditionIdsSelection: (
    onSuccess: (studentIds: string[]) => void,
    onCancel: () => void
  ) => VM.SectionStudentsEditionIdsSelectionViewModel;
  createSectionTeachersEdition: (
    configId: string,
    sectionId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.LoadingSectionTeachersEditionViewModel;
  createAccountEdition: (
    configId: string,
    accountId: string,
    roles: Role[],
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.LoadingAccountEditionViewModel;
  createAccountSectionEditionCopy: (
    configId: string,
    accountId: string,
    onSuccess: (sectiondIds: string[]) => void,
    onCancel: () => void
  ) => VM.LoadingAccountsSectionsEditionCopyViewModel;
  createTeacherPlanning: (
    configId: string,
    accountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.TeacherPlanningDialogViewModel;
  createParentChildrenEdition: (
    configId: string,
    parentId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.LoadingParentChildrenEditionViewModel;
  createSections: (configId: string, shouldLimitAccess?: boolean) => VM.LoadingSectionsViewModel;
  createSectionCourseOccurrenceDetail: (
    configId: string,
    sectionId: string,
    schoolDay: SchoolDay,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.SectionCourseOccurrenceDetailViewModel;
  createStudentDailyTaskCounters: (configId: string, studentId: string) => VM.StudentDailyTaskCountersViewModel;
  createStudentDetails: (configId: string, studentId: string) => VM.StudentDetailsViewModel;
  createSectionsCourseOccurrencesStatus: (
    configId: string,
    sectionIds: string[],
    paginationViewModel?: VM.PaginationViewModel
  ) => VM.SectionsCourseOccurrencesStatusViewModel;
  createStudents: (configId: string, shouldLimitAccess?: boolean) => VM.StudentsViewModel;
  createStudentsFilter: (
    onSuccess: (filters: VM.StudentsFilters) => void,
    onCancel: () => void,
    initialFilters?: VM.StudentsFilters
  ) => VM.StudentsFilterDialogViewModel;
  createTeacherDetails: (configId: string, teacherId: string) => VM.TeacherDetailsViewModel;
  createTeacherDetailsFilter: (
    configId: string,
    teacherId: string,
    onSuccess: (filters: VM.TeacherDetailsFilters) => void,
    onCancel: () => void,
    initialFilters?: VM.TeacherDetailsFilters
  ) => VM.TeacherDetailsFilterDialogViewModel;
  createTeachers: (configId: string, shouldLimitAccess?: boolean) => VM.TeachersViewModel;
  createPublishedTasksByGrade: (
    configId: string,
    paginationViewModel?: VM.PaginationViewModel
  ) => VM.PublishedTasksByGradeViewModel;
  createPublishedTasksByGradeDetail: (
    configId: string,
    gradeLevel: string,
    gradeLevelStudentCount: number,
    tasks: ContentDefinitionModel[],
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.PublishedTasksByGradeDetailViewModel;
  createPublishedTasksBySection: (
    configId: string,
    sectionIds: string[],
    paginationViewModel?: VM.PaginationViewModel
  ) => VM.PublishedTasksBySectionViewModel;
  createPublishedTasksBySectionDetail: (
    configId: string,
    section: SectionModel,
    sectionStudentCount: number,
    tasks: ContentDefinitionModel[],
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.PublishedTasksBySectionDetailViewModel;
  createPublishedTaskDetail: (
    sectionInfo: VM.PublishedTasksDetailSectionInfo,
    taskInfo: VM.PublishedTasksDetailTaskInfo,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.PublishedTaskDetailViewModel;
  createUserMenu: () => VM.UserMenuViewModel;
  createUserSchoolConfigurations: (onSuccess: () => void, onCancel: () => void) => VM.UserSchoolConfigurationsViewModel;
  createWorkload: (configId: string) => VM.WorkloadViewModel;
  createWorkloadEmbedded: (configId: string, teacherId: string) => VM.WorkloadEmbeddedViewModel;
  createWorkloadEmbeddedFilter: (
    configId: string,
    teacherId: string,
    onSuccess: (filters: VM.WorkloadEmbeddedFilters) => void,
    onCancel: () => void,
    initialFilters?: VM.WorkloadEmbeddedFilters
  ) => VM.WorkloadEmbeddedFilterDialogViewModel;
  createWorkloadManagerByGrade: (
    configId: string,
    paginationViewModel?: VM.PaginationViewModel
  ) => VM.WorkloadManagerByGradeViewModel;
  createWorkloadManagerByGradeDetail: (
    configId: string,
    gradeLevel: string,
    gradeLevelStudentCount: number,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    includeExamOnly: boolean,
    studentIdsAtThreshold: string[],
    studentIdsOverThreshold: string[],
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.WorkloadManagerByGradeDetailViewModel;
  createWorkloadManagerBySection: (
    configId: string,
    sectionIds: string[],
    paginationViewModel?: VM.PaginationViewModel
  ) => VM.WorkloadManagerBySectionViewModel;
  createWorkloadManagerBySectionDetail: (
    configId: string,
    sectionId: string,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    includeExamOnly: boolean,
    studentIdsAtThreshold: string[],
    studentIdsOverThreshold: string[],
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.WorkloadManagerBySectionDetailViewModel;
  createWorkloadManagerStudentListDialog: (
    students: AccountModel[],
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.WorkloadManagerStudentListDialogViewModel;
  createWorkloadManagerTaskDetails: (
    taskInfo: VM.WorkloadManagerTaskInfo,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.WorkloadManagerTaskDetailsViewModel;

  // Connectors
  createExternalAccounts: (configId: string) => VM.ExternalAccountsScreenViewModel;
  createExternalAssociations: (configId: string, externalAccountId: string) => VM.ExternalAssociationsScreenViewModel;
  createExternalAssociationsFilters: (
    configId: string,
    onSuccess: (filters: VM.ExternalAssociationListFilters) => void,
    onCancel: () => void,
    initialFilters?: VM.ExternalAssociationListFilters
  ) => VM.ExternalAssociationListFiltersDialogViewModel;
  createBlackbaudAccount: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.BlackbaudAccountDialogViewModel;
  createBlackbaudAccountSettings: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.BlackbaudAccountSettingsDialogViewModel;
  createBlackbaudMappings: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.BlackbaudMappingsDialogViewModel;
  createBlackbaudSkyAccount: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.BlackbaudSkyAccountDialogViewModel;
  createBlackbaudSkyAccountSetup: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.BlackbaudSkyAccountSetupDialogViewModel;
  createBlackbaudSkyMappings: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.BlackbaudSkyAccountMappingsDialogViewModel;
  createCalendarAccount: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.CalendarAccountDialogViewModel;
  createCanvasAccount: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.CanvasAccountDialogViewModel;
  createCanvasAccountSetup: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.CanvasAccountSetupDialogViewModel;
  createCanvasAccountSettings: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.CanvasAccountSettingsDialogViewModel;
  createGoogleAccount: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.GoogleAccountDialogViewModel;
  createGoogleAccountSettings: (
    configId: string,
    externalAccountId: string,
    isNewAccount: boolean,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.GoogleAccountSettingsDialogViewModel;
  createSchoologyAccount: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.SchoologyAccountDialogViewModel;
  createVeracrossAccount: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.VeracrossAccountDialogViewModel;
  createVeracrossV3Account: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.VeracrossV3AccountDialogViewModel;
  createManageBacAccount: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ManageBacAccountDialogViewModel;
  createManageBacAccountSettings: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ManageBacAccountSettingsDialogViewModel;
  createManageBacMappings: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ManageBacMappingsDialogViewModel;
  createMicrosoftTeamsAccount: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.MicrosoftTeamsAccountViewModel;
  createMicrosoftTeamsAccountSettings: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.MicrosoftTeamsAccountSettingsDialogViewModel;
  createMicrosoftTeamsMappings: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.MicrosoftTeamsMappingsDialogViewModel;
  createMoodleAccount: (
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.MoodleAccountDialogViewModel;
  createChangeIntegrations: (
    configId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ChangeIntegrationsDialogViewModel;
  createExternalContentMappings: (
    configId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ExternalContentMappingsDialogViewModel;
  createEditErrorNotificationSettings: (
    externalAccount: ExternalAccount,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.EditErrorNotificationSettingsDialogViewModel;
  createEditThrottleSettings: (
    externalAccount: ExternalAccount,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.EditThrottleSettingsDialogViewModel;
  createScheduledAutoMatchSettings: (
    externalAccount: ExternalAccount,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.ScheduledAutoMatchSettingsDialogViewModel;

  // Manage
  createOnboardingProcessesScreen: (configId: string, processName?: string) => VM.OnboardingProcessesScreenViewModel;
  createOnboardingStepScreen: (
    configId: string,
    processName: string,
    stepName: string
  ) => VM.OnboardingStepScreenViewModel;
  createOnboardingProcessEditionDialog: (
    process: OnboardingProcess,
    onSuccess: (process: OnboardingProcess) => void,
    onCancel: () => void
  ) => VM.OnboardingProcessEditionDialogViewModel;
  createAddOnboardingProcessDialog: (
    configId: string,
    onSuccess: (processName?: string) => void,
    onCancel: () => void
  ) => VM.AddOnboardingProcessDialogViewModel;
  createOnboardingStepEditionDialog: (
    step: OnboardingStep,
    processName: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.OnboardingStepEditionDialogViewModel;
  createAddOnboardingStepDialog: (
    configId: string,
    onSuccess: (stepName?: string) => void,
    onCancel: () => void
  ) => VM.AddOnboardingStepDialogViewModel;
  createOnboardingQuestionEditionDialog: (
    question: OnboardingQuestion,
    processName: string,
    stepName: string,
    onSuccess: (step?: OnboardingStep) => void,
    onCancel: () => void
  ) => VM.OnboardingQuestionEditionDialogViewModel;
  createAddOnboardingQuestionDialog: (
    configId: string,
    stepName: string,
    onSuccess: (step?: OnboardingStep) => void,
    onCancel: () => void
  ) => VM.AddOnboardingQuestionDialogViewModel;
  createSelectOnboardingOwnershipDialog: (
    configId: string,
    processName: string,
    currentClientId: string | undefined,
    currentAgentId: string | undefined,
    currentFollowerIds: string[],
    participantKind: OnboardingParticipantKind,
    canApplyToAllSteps: boolean,
    onSuccess: (selection: VM.SelectedOnboardingOwnership) => void,
    onCancel: () => void
  ) => VM.SelectOnboardingOwnershipDialogViewModel;
  createRenameOrCopyOnboardingTemplateDialog: (
    templateKind: OnboardingTemplateKind,
    currentName: string,
    configId: string,
    stepId: string | undefined,
    isCopy: boolean,
    onSuccess: (newName?: string) => void,
    onCancel: () => void
  ) => VM.RenameOrCopyOnboardingTemplateDialogViewModel;
  createOnboardingHistoryDialog: (
    configId: string,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.OnboardingHistoryDialogViewModel;
  createSchoolYearConfigurationScreen: (configId: string) => VM.SchoolYearConfigurationScreenViewModel;
  createSchoolYearConfigurationDemoCopy: (
    schoolYearConfiguration: SchoolYearConfigurationModel,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.SchoolYearConfigurationDemoCopyDialogViewModel;
  createEditSchoolYearConfigurationAssessmentPlanning: (
    schoolYearConfiguration: SchoolYearConfigurationModel,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.EditSchoolYearConfigurationAssessmentPlanningDialogViewModel;
  createEditSchoolYearConfigurationDates: (
    schoolYearConfiguration: SchoolYearConfigurationModel,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.EditSchoolYearConfigurationDatesDialogViewModel;
  createEditSchoolYearConfigurationInformation: (
    schoolYearConfiguration: SchoolYearConfigurationModel,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.EditSchoolYearConfigurationInformationDialogViewModel;
  createEditSchoolYearConfigurationLinks: (
    schoolYearConfiguration: SchoolYearConfigurationModel,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.EditSchoolYearConfigurationLinksDialogViewModel;
  createEditSchoolYearConfigurationThresholds: (
    schoolYearConfiguration: SchoolYearConfigurationModel,
    sectionsGradeLevels: string[],
    studentsGradeLevels: string[],
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.EditSchoolYearConfigurationThresholdsDialogViewModel;

  createSchoolCalendar: (configId: string) => VM.SchoolCalendarScreenViewModel;
  createEditableCalendarDayCustomization: (
    schoolCalendarDayCustomization: VM.CustomizableViewModel,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.EditableDayCustomizationDialogViewModel;

  createEditableStudents: (configId: string, shouldLimitAccess?: boolean) => VM.EditableAccountsScreenViewModel;
  createEditableParents: (configId: string, shouldLimitAccess?: boolean) => VM.EditableAccountsScreenViewModel;
  createEditableTeachers: (configId: string, shouldLimitAccess?: boolean) => VM.EditableAccountsScreenViewModel;
  createEditableStaff: (configId: string, shouldLimitAccess?: boolean) => VM.EditableAccountsScreenViewModel;

  createFeatures: (configId: string) => VM.FeaturesViewModel;
  createEditableFeatures: (
    schoolYearConfiguration: SchoolYearConfigurationModel,
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.EditFeaturesDialogViewModel;
  createAddEntitlement: (
    configId: string,
    availableEntitlements: SubscriptionEntitlement[],
    onSuccess: () => void,
    onCancel: () => void
  ) => VM.AddEntitlementDialogViewModel;

  createExternalFileSourceEdition: (
    editableSource: EditableExternalFileSource,
    onSuccess: (source: ExternalFileSource) => void,
    onCancel: () => void
  ) => VM.ExternalFileSourceEditionViewModel;
}

export class AppViewModelFactory implements ViewModelFactory {
  createActiveStudents(configId: string): VM.ActiveStudentsViewModel {
    return new VM.AppActiveStudentsViewModel(
      this.getMetricsStore(),
      this.getSchoolYearConfigurationStore(),
      this.getSettingsStore(),
      configId
    );
  }

  createActiveTeachers(configId: string): VM.ActiveTeachersViewModel {
    return new VM.AppActiveTeachersViewModel(
      this.getMetricsStore(),
      this.getSchoolYearConfigurationStore(),
      this.getSettingsStore(),
      configId
    );
  }

  createConfigGlobalStats(configId: string): VM.ConfigGlobalStatsViewModel {
    return new VM.AppConfigGlobalStatsViewModel(this.getMetricsStore(), configId);
  }

  createStudentConfigGlobalStats(configId: string): VM.StudentConfigGlobalStatsViewModel {
    return new VM.AppStudentConfigGlobalStatsViewModel(
      this.getMetricsStore(),
      this.getSchoolYearConfigurationStore(),
      configId
    );
  }

  createDailyAccountSessions(configId: string, accountId: string): VM.DailyAccountSessionsViewModel {
    return new VM.AppDailyAccountSessionsViewModel(this.getMetricsStore(), configId, accountId);
  }

  createDashboard(configId: string): VM.DashboardViewModel {
    return new VM.AppDashboardViewModel(this.getAccountService(), configId);
  }

  createAdminDashboard(configId: string): VM.AdminDashboardViewModel {
    return new VM.AppAdminDashboardViewModel(this.getSettingsStore(), configId);
  }

  createParentDashboard(configId: string): VM.ParentDashboardViewModel {
    return new VM.AppParentDashboardViewModel(configId);
  }

  createStudentDashboard(configId: string): VM.StudentDashboardViewModel {
    return new VM.AppStudentDashboardViewModel(configId);
  }

  createStudentBehaviourHistory(configId: string, studentId: string): VM.StudentBehaviourHistoryViewModel {
    return new VM.AppStudentBehaviourHistoryViewModel(configId, studentId, this.getMetricsStore());
  }

  createTeacherDashboard(configId: string): VM.TeacherDashboardViewModel {
    return new VM.AppTeacherDashboardViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getSettingsStore(),
      this.getContentStore(),
      this.getCalendarStore(),
      this.getAccountService(),
      this.getNavigationService(),
      this.getLocalizationService(),
      this.getAlertService(),
      configId
    );
  }

  createTeacherDetailsFilter(
    configId: string,
    teacherId: string,
    onSuccess: (filters: VM.TeacherDetailsFilters) => void,
    onCancel: () => void,
    initialFilters?: VM.TeacherDetailsFilters
  ): VM.TeacherDetailsFilterDialogViewModel {
    return new VM.AppTeacherDetailsFilterDialogViewModel(
      configId,
      teacherId,
      this.getSchoolYearConfigurationStore(),
      onSuccess,
      onCancel,
      initialFilters
    );
  }

  createForbidden(): VM.ForbiddenViewModel {
    return new VM.AppForbiddenViewModel();
  }

  createNoSchoolConfigurations(): VM.NoSchoolConfigurationsViewModel {
    return new VM.AppNoSchoolConfigurationsViewModel();
  }

  createImportSessions(configId: string): VM.ImportSessionsScreenViewModel {
    return new VM.AppImportSessionsScreenViewModel(
      this.getImporterStore(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService(),
      configId
    );
  }

  createImportSessionSourceData(sessionId: string, label: string): VM.ImportSessionSourceDataScreenViewModel {
    return new VM.AppImportSessionSourceDataScreenViewModel(this.getImporterStore(), sessionId, label);
  }

  createImportSessionDetails(configId: string, sessionId: string): VM.ImportSessionDetailsScreenViewModel {
    return new VM.AppImportSessionDetailsScreenViewModel(
      this.getImporterStore(),
      this.getNavigationService(),
      this.getLocalizationService(),
      this.getAlertService(),
      configId,
      sessionId
    );
  }

  createImportSessionTransformation(
    configId: string,
    sessionId: string,
    transformationId: string
  ): VM.ImportSessionTransformationDetailsScreenViewModel {
    return new VM.AppImportSessionTransformationDetailsScreenViewModel(
      this.getImporterStore(),
      this.getLocalizationService(),
      this.getNavigationService(),
      this.getAlertService(),
      configId,
      sessionId,
      transformationId
    );
  }

  createImportSessionCreate(
    configId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ImportSessionCreateScreenViewModel {
    return new VM.AppImportSessionCreateScreenViewModel(
      this.getAccountService(),
      this.getImporterStore(),
      this.getLocalizationService(),
      configId,
      onSuccess,
      onCancel
    );
  }

  createImportSessionEdit(
    session: ImportSession,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ImportSessionEditDialogViewModel {
    return new VM.AppImportSessionEditDialogViewModel(this.getImporterStore(), session, onSuccess, onCancel);
  }

  createImportSessionImportFromSchool(
    configId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ImportSessionImportFromSchoolDialogViewModel {
    return new VM.AppImportSessionImportFromSchoolDialogViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getImporterStore(),
      this.getLocalizationService(),
      configId,
      onSuccess,
      onCancel
    );
  }

  createImportSessionAddOrEditExpectedFile(
    configId: string,
    session: ImportSession,
    fileLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ImportSessionAddOrEditExpectedFileDialogViewModel {
    return new VM.AppImportSessionAddOrEditExpectedFileDialogViewModel(
      this.getImporterStore(),
      this.getLocalizationService(),
      this.getAlertService(),
      configId,
      session,
      fileLabel,
      onSuccess,
      onCancel
    );
  }

  createImportSessionFileEdit(
    configId: string,
    session: ImportSession,
    fileLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ImportSessionFileEditDialogViewModel {
    return new VM.AppImportSessionFileEditDialogViewModel(
      this.getImporterStore(),
      configId,
      session,
      fileLabel,
      onSuccess,
      onCancel
    );
  }

  createImportSessionFileProcessLink(
    configId: string,
    session: ImportSession,
    fileLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ImportSessionFileProcessLinkDialogViewModel {
    return new VM.AppImportSessionFileProcessLinkDialogViewModel(
      this.getImporterStore(),
      this.getOnboardingStore(),
      configId,
      session,
      fileLabel,
      onSuccess,
      onCancel
    );
  }

  createImportSessionFileDirectDataLink(
    configId: string,
    session: ImportSession,
    fileLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ImportSessionFileDirectDataDialogViewModel {
    return new VM.AppImportSessionFileDirectDataDialogViewModel(
      this.getImporterStore(),
      this.getSchoolYearConfigurationStore(),
      this.getLocalizationService(),
      configId,
      session,
      fileLabel,
      onSuccess,
      onCancel
    );
  }

  createImportSessionFileManualLink(
    configId: string,
    session: ImportSession,
    fileLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ImportSessionFileManualLinkDialogViewModel {
    return new VM.AppImportSessionFileManualLinkDialogViewModel(
      this.getImporterStore(),
      configId,
      session,
      fileLabel,
      onSuccess,
      onCancel
    );
  }

  createImportSessionFileOrderedHeaders(
    configId: string,
    session: ImportSession,
    fileLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ImportSessionFileOrderedHeadersDialogViewModel {
    return new VM.AppImportSessionFileOrderedHeadersDialogViewModel(
      this.getImporterStore(),
      configId,
      session,
      fileLabel,
      onSuccess,
      onCancel
    );
  }

  createImportSessionAddOrEditConcatenation(
    configId: string,
    session: ImportSession,
    concatenationLabel: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ImportSessionAddOrEditConcatenationDialogViewModel {
    return new VM.AppImportSessionAddOrEditConcatenationDialogViewModel(
      this.getImporterStore(),
      this.getLocalizationService(),
      this.getAlertService(),
      configId,
      session,
      concatenationLabel,
      onSuccess,
      onCancel
    );
  }

  createImportSessionAddOrEditTransformation(
    configId: string,
    session: ImportSession,
    transformationLabel: string,
    schemas: Schema[],
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ImportSessionAddOrEditTransformationDialogViewModel {
    return new VM.AppImportSessionAddOrEditTransformationDialogViewModel(
      this.getImporterStore(),
      this.getLocalizationService(),
      this.getAlertService(),
      configId,
      session,
      transformationLabel,
      schemas,
      onSuccess,
      onCancel
    );
  }

  createImportDataDetails(configId: string, sessionId: string, label: string): VM.ImportDataDetailsScreenViewModel {
    return new VM.AppImportDataDetailsScreenViewModel(
      this.getImporterStore(),
      this.getNavigationService(),
      this.getLocalizationService(),
      configId,
      sessionId,
      label
    );
  }

  createImportDataIncidentsSelection(
    configId: string,
    sourceSessionId: string,
    importData: ImportData,
    data: SourceData,
    isCompleteData: boolean,
    options: SchemaImportOption[],
    onSuccess: (value: ImportData) => void,
    onCancel: () => void
  ): VM.ImportDataIncidentsSelectionScreenViewModel {
    return new VM.AppImportDataIncidentsSelectionScreenViewModel(
      this.getImporterStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      configId,
      sourceSessionId,
      importData,
      data,
      isCompleteData,
      options,
      onSuccess,
      onCancel
    );
  }

  createAutomatedImportCreateOrEdit(
    onSuccess: (value: AutomatedImport) => void,
    onCancel: () => void,
    configId: string,
    importSessionId: string,
    automatedImport: AutomatedImport | undefined
  ): VM.AutomatedImportCreateOrEditDialogViewModel {
    return new VM.AppAutomatedImportCreateOrEditDialogViewModel(
      this.getImporterStore(),
      onSuccess,
      onCancel,
      configId,
      importSessionId,
      automatedImport
    );
  }

  createAutomatedImportDetails(
    configId: string,
    sessionId: string,
    automatedImportId: string
  ): VM.AutomatedImportDetailsScreenViewModel {
    return new VM.AppAutomatedImportDetailsScreenViewModel(
      this.getNavigationService(),
      this.getImporterStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      configId,
      sessionId,
      automatedImportId
    );
  }

  createAutomatedImportResultDetails(
    onCancel: () => void,
    importSession: ImportSession,
    automatedImport: AutomatedImport,
    result: AutomatedImportResult
  ): VM.AutomatedImportResultDetailsDialogViewModel {
    return new VM.AppAutomatedImportResultDetailsDialogViewModel(onCancel, importSession, automatedImport, result);
  }

  createAutomatedTransformationImportViewModel(
    onSuccess: () => void,
    onCancel: () => void,
    importSession: ImportSession,
    transformationImport: EditableAutomatedTransformationImport
  ): VM.AutomatedTransformationImportDialogViewModel {
    return new VM.AppAutomatedTransformationImportDialogViewModel(
      this.getImporterStore(),
      this.getLocalizationService(),
      onSuccess,
      onCancel,
      importSession,
      transformationImport
    );
  }

  createLogin(): VM.LoginViewModel {
    return new VM.AppLoginViewModel(
      this.getAccountService(),
      this.getNavigationService(),
      this.getLocalizationService(),
      this.getLocalStorage(),
      this.getSettingsStore(),
      this.getEnvironmentService(),
      this.getAlertService()
    );
  }

  createLogout(): VM.LogoutViewModel {
    return new VM.AppLogoutViewModel(this.getAccountService(), this.getNavigationService());
  }

  createCreateSchool(sourceConfigId?: string): VM.LoadingCreateSchoolViewModel {
    return new VM.AppLoadingCreateSchoolViewModel(
      this.getToolsTransport(),
      this.getSchoolYearConfigurationStore(),
      this.getImporterStore(),
      this.getOnboardingStore(),
      this.getAccountService(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService(),
      sourceConfigId
    );
  }

  createNotFound(): VM.NotFoundViewModel {
    return new VM.AppNotFoundViewModel(this.getAccountService());
  }

  createSchoolConfiguration(configId: string): VM.SchoolConfigurationViewModel {
    return new VM.AppSchoolConfigurationViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getSettingsStore(),
      configId
    );
  }

  createSchoolConfigurations(): VM.SchoolConfigurationsViewModel {
    return new VM.AppSchoolConfigurationsViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getSettingsStore(),
      this.getNavigationService(),
      this.getMetricsStore()
    );
  }

  createOnboardingDashboard(): VM.OnboardingDashboardViewModel {
    return new VM.AppOnboardingDashboardViewModel(
      this.getOnboardingStore(),
      this.getAccountService(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService(),
      this.getSettingsStore()
    );
  }

  createOnboardingCommentsDashboard(): VM.OnboardingCommentsDashboardViewModel {
    return new VM.AppOnboardingCommentsDashboardViewModel(
      this.getOnboardingStore(),
      this.getAccountService(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService(),
      this.getSettingsStore()
    );
  }

  createUsers(): VM.UsersViewModel {
    return new VM.AppUsersViewModel(
      this.getUserStore(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService()
    );
  }

  createGlobalSettings(): VM.GlobalSettingsScreenViewModel {
    return new VM.AppGlobalSettingsScreenViewModel(
      this.getImporterStore(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService()
    );
  }

  createMicrosoftTeamsRedirect(query: string): VM.MicrosoftTeamsAuthorizationRedirectScreenViewModel {
    const params = new URLSearchParams(query);

    return new VM.AppMicrosoftTeamsAuthorizationRedirectScreenViewModel(
      this.getConnectorsStore().teams,
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService(),
      params.get('state') ?? '',
      params.get('tenant') ?? ''
    );
  }

  createBlackbaudSkyRedirect(query: string): VM.BlackbaudSkyAuthorizationRedirectScreenViewModel {
    const params = new URLSearchParams(query);

    return new VM.AppBlackbaudSkyAuthorizationRedirectScreenViewModel(
      this.getConnectorsStore().blackbaudSky,
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService(),
      params.get('state') ?? '',
      params.get('code') ?? '',
      params.get('error') ?? ''
    );
  }

  createSectionCourseOccurrenceDetail(
    configId: string,
    sectionId: string,
    schoolDay: SchoolDay,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.SectionCourseOccurrenceDetailViewModel {
    return new VM.AppSectionCourseOccurrenceDetailViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getContentStore(),
      this.getCalendarStore(),
      this.getNavigationService(),
      this.getLocalizationService(),
      onSuccess,
      onCancel,
      configId,
      sectionId,
      schoolDay
    );
  }

  createSectionDetails(configId: string, sectionId: string): VM.SectionDetailsViewModel {
    return new VM.AppSectionDetailsViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getMetricsStore(),
      this.getLocalizationService(),
      this.getAlertService(),
      configId,
      sectionId
    );
  }

  createSectionEdition(
    configId: string,
    sectionIds: string[],
    isEditingSchedules: boolean,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.LoadingSectionEditionViewModel {
    return new VM.AppLoadingSectionEditionViewModel(
      this.getLocalizationService(),
      this.getSchoolYearConfigurationStore(),
      this.getContentStore(),
      this.getAccountService(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      sectionIds,
      isEditingSchedules
    );
  }

  createManageSectionConflicts(
    configId: string,
    sectionId: string,
    startingDay: Day | undefined,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ManageSectionConflictsDialogViewModel {
    return new VM.AppManageSectionConflictsDialogViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getCalendarStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      configId,
      sectionId,
      startingDay,
      onSuccess,
      onCancel
    );
  }

  createSectionStudentsEdition(
    configId: string,
    sectionId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.LoadingSectionStudentsEditionViewModel {
    return new VM.AppLoadingSectionStudentsEditionViewModel(
      this.getLocalizationService(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getSchoolYearConfigurationStore(),
      onSuccess,
      onCancel,
      configId,
      sectionId
    );
  }

  createSectionStudentsEditionSectionSelection(
    configId: string,
    sectionId: string,
    onSuccess: (studentIds: string[]) => void,
    onCancel: () => void
  ): VM.LoadingSectionStudentsEditionSectionSelectionViewModel {
    return new VM.AppLoadingSectionStudentsEditionSectionSelectionViewModel(
      this.getSchoolYearConfigurationStore(),
      onSuccess,
      onCancel,
      configId,
      sectionId
    );
  }

  createSectionStudentsEditionIdsSelection(
    onSuccess: (studentIds: string[]) => void,
    onCancel: () => void
  ): VM.SectionStudentsEditionIdsSelectionViewModel {
    return new VM.AppSectionStudentsEditionIdsSelectionViewModel(onSuccess, onCancel);
  }

  createSectionTeachersEdition(
    configId: string,
    sectionId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.LoadingSectionTeachersEditionViewModel {
    return new VM.AppLoadingSectionTeachersEditionViewModel(
      this.getAlertService(),
      this.getLocalizationService(),
      this.getSchoolYearConfigurationStore(),
      configId,
      sectionId,
      onSuccess,
      onCancel
    );
  }

  createAccountEdition(
    configId: string,
    accountId: string,
    roles: Role[],
    onSuccess: () => void,
    onCancel: () => void
  ): VM.LoadingAccountEditionViewModel {
    return new VM.AppLoadingAccountEditionViewModel(
      this.getAlertService(),
      this.getLocalizationService(),
      this.getSchoolYearConfigurationStore(),
      this.getUserStore(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      accountId,
      roles
    );
  }

  createAccountSectionEditionCopy(
    configId: string,
    accountId: string,
    onSuccess: (sectiondIds: string[]) => void,
    onCancel: () => void
  ): VM.LoadingAccountsSectionsEditionCopyViewModel {
    return new VM.AppLoadingAccountSectionsEditionCopyViewModel(
      this.getNavigationService(),
      this.getSchoolYearConfigurationStore(),
      this.getLocalizationService(),
      onSuccess,
      onCancel,
      configId,
      accountId
    );
  }

  createTeacherPlanning(
    configId: string,
    accountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.TeacherPlanningDialogViewModel {
    return new VM.AppTeacherPlanningDialogViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getCalendarStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      configId,
      accountId,
      undefined,
      onSuccess,
      onCancel
    );
  }

  createParentChildrenEdition(
    configId: string,
    parentId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.LoadingParentChildrenEditionViewModel {
    return new VM.AppLoadingParentChildrenEditionViewModel(
      this.getLocalizationService(),
      this.getNavigationService(),
      this.getSchoolYearConfigurationStore(),
      onSuccess,
      onCancel,
      configId,
      parentId
    );
  }

  createSections(configId: string, shouldLimitAccess?: boolean): VM.LoadingSectionsViewModel {
    return new VM.AppLoadingSectionsViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getAccountService(),
      this.getNavigationService(),
      this.getLocalizationService(),
      configId,
      shouldLimitAccess
    );
  }

  createStudentDailyTaskCounters(configId: string, studentId: string): VM.StudentDailyTaskCountersViewModel {
    return new VM.AppStudentDailyTaskCountersViewModel(this.getMetricsStore(), configId, studentId);
  }

  createStudentDetails(configId: string, studentId: string): VM.StudentDetailsViewModel {
    return new VM.AppStudentDetailsViewModel(
      this.getMetricsStore(),
      this.getSchoolYearConfigurationStore(),
      this.getNavigationService(),
      this.getLocalizationService(),
      configId,
      studentId
    );
  }

  createSectionsCourseOccurrencesStatus(
    configId: string,
    sectionIds: string[],
    paginationViewModel?: VM.PaginationViewModel
  ): VM.SectionsCourseOccurrencesStatusViewModel {
    return new VM.AppSectionsCourseOccurrencesStatusViewModel(
      configId,
      sectionIds,
      this.getSchoolYearConfigurationStore(),
      this.getCalendarStore(),
      this.getContentStore(),
      this.getNavigationService(),
      this.getLocalizationService(),
      this.getAlertService(),
      this.getContentService(),
      paginationViewModel
    );
  }

  createStudents(configId: string, shouldLimitAccess?: boolean): VM.StudentsViewModel {
    return new VM.AppStudentsViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getMetricsStore(),
      this.getSettingsStore(),
      this.getAccountService(),
      this.getNavigationService(),
      this.getLocalizationService(),
      this.getAlertService(),
      configId,
      shouldLimitAccess
    );
  }

  createStudentsFilter(
    onSuccess: (filters: VM.StudentsFilters) => void,
    onCancel: () => void,
    initialFilters?: VM.StudentsFilters
  ): VM.StudentsFilterDialogViewModel {
    return new VM.AppStudentsFilterDialogViewModel(onSuccess, onCancel, initialFilters);
  }

  createTeacherDetails(configId: string, teacherId: string): VM.TeacherDetailsViewModel {
    return new VM.AppTeacherDetailsViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getSettingsStore(),
      this.getContentStore(),
      this.getCalendarStore(),
      this.getNavigationService(),
      this.getLocalizationService(),
      this.getAccountService(),
      this.getAlertService(),
      configId,
      teacherId
    );
  }

  createTeacherDashboardFilter(
    configId: string,
    teacherId: string,
    onSuccess: (filters: VM.TeacherDashboardFilters) => void,
    onCancel: () => void,
    initialFilters?: VM.TeacherDashboardFilters
  ): VM.TeacherDashboardFilterDialogViewModel {
    return new VM.AppTeacherDashboardFilterDialogViewModel(
      configId,
      teacherId,
      this.getSchoolYearConfigurationStore(),
      onSuccess,
      onCancel,
      initialFilters
    );
  }

  createTeachers(configId: string, shouldLimitAccess?: boolean): VM.TeachersViewModel {
    return new VM.AppTeachersViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getAccountService(),
      this.getNavigationService(),
      configId,
      shouldLimitAccess
    );
  }

  createPublishedTasksByGrade(
    configId: string,
    paginationViewModel?: VM.PaginationViewModel
  ): VM.PublishedTasksByGradeViewModel {
    return new VM.AppPublishedTasksByGradeViewModel(
      configId,
      this.getSchoolYearConfigurationStore(),
      this.getCalendarStore(),
      this.getSettingsStore(),
      this.getNavigationService(),
      this.getContentService(),
      this.getAlertService(),
      this.getLocalizationService(),
      paginationViewModel
    );
  }

  createPublishedTasksByGradeDetail(
    configId: string,
    gradeLevel: string,
    gradeLevelStudentCount: number,
    tasks: ContentDefinitionModel[],
    onSuccess: () => void,
    onCancel: () => void
  ): VM.PublishedTasksByGradeDetailViewModel {
    return new VM.AppPublishedTasksByGradeDetailViewModel(
      gradeLevel,
      gradeLevelStudentCount,
      this.getSchoolYearConfigurationStore(),
      tasks,
      configId,
      onSuccess,
      onCancel,
      this.getNavigationService()
    );
  }

  createPublishedTasksBySection(
    configId: string,
    sectionIds: string[],
    paginationViewModel?: VM.PaginationViewModel
  ): VM.PublishedTasksBySectionViewModel {
    return new VM.AppPublishedTasksBySectionViewModel(
      configId,
      sectionIds,
      this.getLocalizationService(),
      this.getAlertService(),
      this.getSchoolYearConfigurationStore(),
      this.getCalendarStore(),
      this.getSettingsStore(),
      this.getNavigationService(),
      this.getContentService(),
      this.getContentStore(),
      paginationViewModel
    );
  }

  createPublishedTasksBySectionDetail(
    configId: string,
    section: SectionModel,
    sectionStudentCount: number,
    tasks: ContentDefinitionModel[],
    onSuccess: () => void,
    onCancel: () => void
  ): VM.PublishedTasksBySectionDetailViewModel {
    return new VM.AppPublishedTasksBySectionDetailViewModel(
      section,
      sectionStudentCount,
      this.getSchoolYearConfigurationStore(),
      tasks,
      configId,
      onSuccess,
      onCancel,
      this.getNavigationService()
    );
  }

  createPublishedTaskDetail(
    sectionInfo: VM.PublishedTasksDetailSectionInfo,
    taskInfo: VM.PublishedTasksDetailTaskInfo,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.PublishedTaskDetailViewModel {
    return new VM.AppPublishedTaskDetailViewModel(sectionInfo, taskInfo, onSuccess, onCancel);
  }

  createUserMenu(): VM.UserMenuViewModel {
    return new VM.AppUserMenuViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getSettingsStore(),
      this.getNavigationService(),
      this.getAccountService(),
      this.getLocalizationService(),
      this.getLocalStorage()
    );
  }

  createUserSchoolConfigurations(onSuccess: () => void, onCancel: () => void): VM.UserSchoolConfigurationsViewModel {
    return new VM.AppUserSchoolConfigurationsViewModel(
      this.getAccountService(),
      this.getNavigationService(),
      onSuccess,
      onCancel
    );
  }

  createWorkload(configId: string): VM.WorkloadViewModel {
    return new VM.AppWorkloadViewModel(configId, this.getSchoolYearConfigurationStore());
  }

  createWorkloadEmbedded(configId: string, teacherId: string): VM.WorkloadEmbeddedViewModel {
    return new VM.AppWorkloadEmbeddedViewModel(
      configId,
      teacherId,
      this.getSchoolYearConfigurationStore(),
      this.getSettingsStore(),
      this.getNavigationService()
    );
  }

  createWorkloadEmbeddedFilter(
    configId: string,
    teacherId: string,
    onSuccess: (filters: VM.WorkloadEmbeddedFilters) => void,
    onCancel: () => void,
    initialFilters?: VM.WorkloadEmbeddedFilters
  ): VM.WorkloadEmbeddedFilterDialogViewModel {
    return new VM.AppWorkloadEmbeddedFilterDialogViewModel(
      configId,
      teacherId,
      this.getSchoolYearConfigurationStore(),
      onSuccess,
      onCancel,
      initialFilters
    );
  }

  createWorkloadManagerByGrade(
    configId: string,
    paginationViewModel?: VM.PaginationViewModel
  ): VM.WorkloadManagerByGradeViewModel {
    return new VM.AppWorkloadManagerByGradeViewModel(
      configId,
      this.getCalendarStore(),
      this.getContentService(),
      this.getNavigationService(),
      this.getSchoolYearConfigurationStore(),
      this.getSettingsStore(),
      paginationViewModel
    );
  }

  createWorkloadManagerByGradeDetail(
    configId: string,
    gradeLevel: string,
    gradeLevelStudentCount: number,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    includeExamOnly: boolean,

    studentIdsAtThreshold: string[],
    studentIdsOverThreshold: string[],

    onSuccess: () => void,
    onCancel: () => void
  ): VM.WorkloadManagerByGradeDetailViewModel {
    return new VM.AppWorkloadManagerByGradeDetailViewModel(
      onSuccess,
      onCancel,
      this.getContentStore(),
      this.getSchoolYearConfigurationStore(),
      this.getNavigationService(),
      this.getContentService(),
      configId,
      fromDay,
      toDay,
      includeExamOnly,
      studentIdsAtThreshold,
      studentIdsOverThreshold,
      gradeLevel,
      gradeLevelStudentCount
    );
  }

  createWorkloadManagerBySection(
    configId: string,
    sectionIds: string[],
    paginationViewModel?: VM.PaginationViewModel
  ): VM.WorkloadManagerBySectionViewModel {
    return new VM.AppWorkloadManagerBySectionViewModel(
      configId,
      sectionIds,
      this.getCalendarStore(),
      this.getContentService(),
      this.getNavigationService(),
      this.getSchoolYearConfigurationStore(),
      this.getSettingsStore(),
      paginationViewModel
    );
  }

  createWorkloadManagerBySectionDetail(
    configId: string,
    sectionId: string,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    includeExamOnly: boolean,
    studentIdsAtThreshold: string[],
    studentIdsOverThreshold: string[],
    onSuccess: () => void,
    onCancel: () => void
  ): VM.WorkloadManagerBySectionDetailViewModel {
    return new VM.AppWorkloadManagerBySectionDetailViewModel(
      onSuccess,
      onCancel,
      this.getContentStore(),
      this.getSchoolYearConfigurationStore(),
      this.getNavigationService(),
      this.getContentService(),
      configId,
      fromDay,
      toDay,
      includeExamOnly,
      studentIdsAtThreshold,
      studentIdsOverThreshold,
      sectionId
    );
  }

  createWorkloadManagerStudentListDialog(
    students: AccountModel[],
    onSuccess: () => void,
    onCancel: () => void
  ): VM.WorkloadManagerStudentListDialogViewModel {
    return new VM.AppWorkloadManagerStudentListDialogViewModel(
      this.getNavigationService(),
      this.getAccountService(),
      students,
      onSuccess,
      onCancel
    );
  }

  createWorkloadManagerTaskDetails(
    taskInfo: VM.WorkloadManagerTaskInfo,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.WorkloadManagerTaskDetailsViewModel {
    return new VM.AppWorkloadManagerTaskDetailsViewModel(taskInfo, onSuccess, onCancel);
  }

  //
  // Connectors
  //

  createExternalAccounts(configId: string): VM.ExternalAccountsScreenViewModel {
    return new VM.AppExternalAccountsScreenViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getConnectorsStore(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService(),
      this.getAccountService(),
      configId
    );
  }

  createExternalAssociations(configId: string, externalAccountId: string): VM.ExternalAssociationsScreenViewModel {
    return new VM.AppExternalAssociationsScreenViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getConnectorsStore(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService(),
      this.getAccountService(),
      configId,
      externalAccountId
    );
  }

  createExternalAssociationsFilters(
    configId: string,
    onSuccess: (filters: VM.ExternalAssociationListFilters) => void,
    onCancel: () => void,
    initialFilters?: VM.ExternalAssociationListFilters
  ): VM.ExternalAssociationListFiltersDialogViewModel {
    return new VM.AppExternalAssociationListFiltersDialogViewModel(
      configId,
      this.getSchoolYearConfigurationStore(),
      onSuccess,
      onCancel,
      initialFilters
    );
  }

  createBlackbaudAccount(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.BlackbaudAccountDialogViewModel {
    return new VM.AppBlackbaudAccountDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createBlackbaudAccountSettings(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.BlackbaudAccountSettingsDialogViewModel {
    return new VM.AppBlackbaudAccountSettingsDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore().blackbaud,
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createBlackbaudMappings(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.BlackbaudMappingsDialogViewModel {
    return new VM.AppBlackbaudMappingsDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore().blackbaud,
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createBlackbaudSkyAccount(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.BlackbaudSkyAccountDialogViewModel {
    return new VM.AppBlackbaudSkyAccountDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createBlackbaudSkyAccountSetup(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.BlackbaudSkyAccountSetupDialogViewModel {
    return new VM.AppBlackbaudSkyAccountSetupDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore().blackbaudSky,
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createBlackbaudSkyMappings(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.BlackbaudSkyAccountMappingsDialogViewModel {
    return new VM.AppBlackbaudSkyAccountMappingsDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore().blackbaudSky,
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createCalendarAccount(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.CalendarAccountDialogViewModel {
    return new VM.AppCalendarAccountDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createCanvasAccount(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.CanvasAccountDialogViewModel {
    return new VM.AppCanvasAccountDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createCanvasAccountSetup(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.CanvasAccountSetupDialogViewModel {
    return new VM.AppCanvasAccountSetupDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore().canvas,
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createCanvasAccountSettings(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.CanvasAccountSettingsDialogViewModel {
    return new VM.AppCanvasAccountSettingsDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore().canvas,
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createGoogleAccount(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.GoogleAccountDialogViewModel {
    return new VM.AppGoogleAccountDialogViewModel(
      this.getLocalizationService(),
      this.getSchoolYearConfigurationStore(),
      this.getConnectorsStore(),
      this.getAccountService(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createGoogleAccountSettings(
    configId: string,
    externalAccountId: string,
    isNewAccount: boolean,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.GoogleAccountSettingsDialogViewModel {
    return new VM.AppGoogleAccountSettingsDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore(),
      this.getNavigationService(),
      this.getAccountService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId,
      isNewAccount
    );
  }

  createSchoologyAccount(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.SchoologyAccountDialogViewModel {
    return new VM.AppSchoologyAccountDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createVeracrossAccount(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.VeracrossAccountDialogViewModel {
    return new VM.AppVeracrossAccountDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createVeracrossV3Account(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.VeracrossV3AccountDialogViewModel {
    return new VM.AppVeracrossV3AccountDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createManageBacAccount(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ManageBacAccountDialogViewModel {
    return new VM.AppManageBacAccountDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createManageBacAccountSettings(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ManageBacAccountSettingsDialogViewModel {
    return new VM.AppManageBacAccountSettingsDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore().manageBac,
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createManageBacMappings(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ManageBacMappingsDialogViewModel {
    return new VM.AppManageBacMappingsDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore().manageBac,
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createMicrosoftTeamsAccount(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.MicrosoftTeamsAccountViewModel {
    return new VM.AppMicrosoftTeamsAccountViewModel(
      this.getLocalizationService(),
      this.getEnvironmentService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createMicrosoftTeamsAccountSettings(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.MicrosoftTeamsAccountSettingsDialogViewModel {
    return new VM.AppMicrosoftTeamsAccountSettingsDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore().teams,
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createMicrosoftTeamsMappings(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.MicrosoftTeamsMappingsDialogViewModel {
    return new VM.AppMicrosoftTeamsMappingsDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore().teams,
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createMoodleAccount(
    configId: string,
    externalAccountId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.MoodleAccountDialogViewModel {
    return new VM.AppMoodleAccountDialogViewModel(
      this.getLocalizationService(),
      this.getConnectorsStore(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId,
      externalAccountId
    );
  }

  createChangeIntegrations(
    configId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ChangeIntegrationsDialogViewModel {
    return new VM.AppChangeIntegrationsDialogViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getNavigationService(),
      onSuccess,
      onCancel,
      configId
    );
  }

  createExternalContentMappings(
    configId: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ExternalContentMappingsDialogViewModel {
    return new VM.AppExternalContentMappingsDialogViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getConnectorsStore(),
      this.getLocalizationService(),
      onSuccess,
      onCancel,
      configId
    );
  }

  createEditErrorNotificationSettings(
    externalAccount: ExternalAccount,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.EditErrorNotificationSettingsDialogViewModel {
    return new VM.AppEditErrorNotificationSettingsDialogViewModel(
      externalAccount,
      this.getConnectorsStore(),
      onSuccess,
      onCancel,
      this.getAlertService(),
      this.getLocalizationService()
    );
  }

  createEditThrottleSettings(
    externalAccount: ExternalAccount,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.EditThrottleSettingsDialogViewModel {
    return new VM.AppEditThrottleSettingsDialogViewModel(
      externalAccount,
      this.getConnectorsStore(),
      onSuccess,
      onCancel,
      this.getAlertService(),
      this.getLocalizationService()
    );
  }

  createScheduledAutoMatchSettings(
    externalAccount: ExternalAccount,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.ScheduledAutoMatchSettingsDialogViewModel {
    return new VM.AppScheduledAutoMatchSettingsDialogViewModel(
      externalAccount,
      this.getConnectorsStore(),
      onSuccess,
      onCancel,
      this.getAlertService(),
      this.getLocalizationService()
    );
  }

  //
  // Manage
  //

  createOnboardingProcessesScreen(configId: string, processName?: string): VM.OnboardingProcessesScreenViewModel {
    return new VM.AppOnboardingProcessesScreenViewModel(
      this.getOnboardingStore(),
      this.getAccountService(),
      this.getNavigationService(),
      this.getSchoolYearConfigurationStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      this.getSettingsStore(),
      configId,
      processName
    );
  }

  createOnboardingStepScreen(
    configId: string,
    processName: string,
    stepName: string
  ): VM.OnboardingStepScreenViewModel {
    return new VM.AppOnboardingStepScreenViewModel(
      this.getOnboardingStore(),
      this.getSchoolYearConfigurationStore(),
      this.getAccountService(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService(),
      this.getSettingsStore(),
      configId,
      processName,
      stepName
    );
  }

  createOnboardingProcessEditionDialog(
    process: OnboardingProcess,
    onSuccess: (process: OnboardingProcess) => void,
    onCancel: () => void
  ): VM.OnboardingProcessEditionDialogViewModel {
    return new VM.AppOnboardingProcessEditionDialogViewModel(
      this.getOnboardingStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      process,
      onSuccess,
      onCancel
    );
  }

  createAddOnboardingProcessDialog(
    configId: string,
    onSuccess: (processName?: string) => void,
    onCancel: () => void
  ): VM.AddOnboardingProcessDialogViewModel {
    return new VM.AppAddOnboardingProcessDialogViewModel(
      this.getOnboardingStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      configId,
      onSuccess,
      onCancel
    );
  }

  createOnboardingStepEditionDialog(
    step: OnboardingStep,
    processName: string,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.OnboardingStepEditionDialogViewModel {
    return new VM.AppOnboardingStepEditionDialogViewModel(
      this.getOnboardingStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      step,
      processName,
      onSuccess,
      onCancel
    );
  }

  createAddOnboardingStepDialog(
    configId: string,
    onSuccess: (stepName?: string) => void,
    onCancel: () => void
  ): VM.AddOnboardingStepDialogViewModel {
    return new VM.AppAddOnboardingStepDialogViewModel(
      this.getOnboardingStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      configId,
      onSuccess,
      onCancel
    );
  }

  createOnboardingQuestionEditionDialog(
    question: OnboardingQuestion,
    processName: string,
    stepName: string,
    onSuccess: (step?: OnboardingStep) => void,
    onCancel: () => void
  ): VM.OnboardingQuestionEditionDialogViewModel {
    return new VM.AppOnboardingQuestionEditionDialogViewModel(
      this.getOnboardingStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      question,
      processName,
      stepName,
      onSuccess,
      onCancel
    );
  }

  createAddOnboardingQuestionDialog(
    configId: string,
    stepName: string,
    onSuccess: (step?: OnboardingStep) => void,
    onCancel: () => void
  ): VM.AddOnboardingQuestionDialogViewModel {
    return new VM.AppAddOnboardingQuestionDialogViewModel(
      this.getOnboardingStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      configId,
      stepName,
      onSuccess,
      onCancel
    );
  }

  createSelectOnboardingOwnershipDialog(
    configId: string,
    processName: string,
    currentClientId: string | undefined,
    currentAgentId: string | undefined,
    currentFollowerIds: string[],
    participantKind: OnboardingParticipantKind,
    canApplyToAllSteps: boolean,
    onSuccess: (selection: VM.SelectedOnboardingOwnership) => void,
    onCancel: () => void
  ): VM.SelectOnboardingOwnershipDialogViewModel {
    return new VM.AppSelectOnboardingOwnershipDialogViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getOnboardingStore(),
      configId,
      processName,
      currentClientId,
      currentAgentId,
      currentFollowerIds,
      participantKind,
      canApplyToAllSteps,
      onSuccess,
      onCancel
    );
  }

  createRenameOrCopyOnboardingTemplateDialog(
    templateKind: OnboardingTemplateKind,
    currentName: string,
    configId: string,
    stepId: string | undefined,
    isCopy: boolean,
    onSuccess: (newName?: string) => void,
    onCancel: () => void
  ): VM.RenameOrCopyOnboardingTemplateDialogViewModel {
    return new VM.AppRenameOrCopyOnboardingTemplateDialogViewModel(
      this.getOnboardingStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      templateKind,
      currentName,
      configId,
      stepId,
      isCopy,
      onSuccess,
      onCancel
    );
  }

  createOnboardingHistoryDialog(configId: string, onSuccess: () => void): VM.OnboardingHistoryDialogViewModel {
    return new VM.AppOnboardingHistoryDialogViewModel(
      this.getOnboardingStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      configId,
      onSuccess
    );
  }

  createSchoolYearConfigurationScreen(configId: string): VM.SchoolYearConfigurationScreenViewModel {
    return new VM.AppSchoolYearConfigurationScreenViewModel(
      configId,
      this.getSchoolYearConfigurationStore(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService()
    );
  }

  createSchoolYearConfigurationDemoCopy(
    schoolYearConfiguration: SchoolYearConfigurationModel,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.SchoolYearConfigurationDemoCopyDialogViewModel {
    return new VM.AppSchoolYearConfigurationDemoCopyDialogViewModel(
      this.getToolsTransport(),
      this.getAccountService(),
      this.getSchoolYearConfigurationStore(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService(),
      schoolYearConfiguration,
      onSuccess,
      onCancel
    );
  }

  createEditSchoolYearConfigurationAssessmentPlanning(
    schoolYearConfiguration: SchoolYearConfigurationModel,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.EditSchoolYearConfigurationAssessmentPlanningDialogViewModel {
    return new VM.AppEditSchoolYearConfigurationAssessmentPlanningDialogViewModel(
      this.getSchoolYearConfigurationStore(),
      schoolYearConfiguration,
      this.getAlertService(),
      this.getLocalizationService(),
      onSuccess,
      onCancel
    );
  }

  createEditSchoolYearConfigurationDates(
    schoolYearConfiguration: SchoolYearConfigurationModel,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.EditSchoolYearConfigurationDatesDialogViewModel {
    return new VM.AppEditSchoolYearConfigurationDatesDialogViewModel(
      this.getSchoolYearConfigurationStore(),
      schoolYearConfiguration,
      this.getAlertService(),
      this.getLocalizationService(),
      onSuccess,
      onCancel
    );
  }

  createEditSchoolYearConfigurationInformation(
    schoolYearConfiguration: SchoolYearConfigurationModel,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.EditSchoolYearConfigurationInformationDialogViewModel {
    return new VM.AppEditSchoolYearConfigurationInformationDialogViewModel(
      this.getSchoolYearConfigurationStore(),
      schoolYearConfiguration,
      this.getAlertService(),
      this.getLocalizationService(),
      onSuccess,
      onCancel
    );
  }

  createEditSchoolYearConfigurationLinks(
    schoolYearConfiguration: SchoolYearConfigurationModel,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.EditSchoolYearConfigurationLinksDialogViewModel {
    return new VM.AppEditSchoolYearConfigurationLinksDialogViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getOnboardingStore(),
      schoolYearConfiguration,
      this.getAlertService(),
      this.getLocalizationService(),
      onSuccess,
      onCancel
    );
  }

  createEditSchoolYearConfigurationThresholds(
    schoolYearConfiguration: SchoolYearConfigurationModel,
    sectionsGradeLevels: string[],
    studentsGradeLevels: string[],
    onSuccess: () => void,
    onCancel: () => void
  ): VM.EditSchoolYearConfigurationThresholdsDialogViewModel {
    return new VM.AppEditSchoolYearConfigurationThresholdsDialogViewModel(
      this.getSchoolYearConfigurationStore(),
      schoolYearConfiguration,
      sectionsGradeLevels,
      studentsGradeLevels,
      this.getAlertService(),
      this.getLocalizationService(),
      onSuccess,
      onCancel
    );
  }

  createSchoolCalendar(configId: string): VM.SchoolCalendarScreenViewModel {
    return new VM.AppSchoolCalendarScreenViewModel(
      this.getLocalizationService(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getSchoolYearConfigurationStore(),
      this.getCalendarStore(),
      configId
    );
  }

  createEditableCalendarDayCustomization(
    customizable: VM.CustomizableViewModel,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.EditableDayCustomizationDialogViewModel {
    return new VM.AppEditableDayCustomizationDialogViewModel(customizable, onSuccess, onCancel);
  }

  createEditableStudents(configId: string, shouldLimitAccess?: boolean): VM.EditableAccountsScreenViewModel {
    return new VM.AppEditableStudentsScreenViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getAccountService(),
      this.getNavigationService(),
      this.getLocalizationService(),
      configId,
      shouldLimitAccess
    );
  }

  createEditableParents(configId: string, shouldLimitAccess?: boolean): VM.EditableAccountsScreenViewModel {
    return new VM.AppEditableParentsScreenViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getAccountService(),
      this.getNavigationService(),
      this.getLocalizationService(),
      configId,
      shouldLimitAccess
    );
  }

  createEditableTeachers(configId: string, shouldLimitAccess?: boolean): VM.EditableAccountsScreenViewModel {
    return new VM.AppEditableTeachersScreenViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getAccountService(),
      this.getNavigationService(),
      this.getLocalizationService(),
      configId,
      shouldLimitAccess
    );
  }

  createEditableStaff(configId: string, shouldLimitAccess?: boolean): VM.EditableAccountsScreenViewModel {
    return new VM.AppEditableStaffScreenViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getAccountService(),
      this.getNavigationService(),
      this.getLocalizationService(),
      configId,
      shouldLimitAccess
    );
  }

  createFeatures(configId: string): VM.FeaturesViewModel {
    return new VM.AppFeaturesViewModel(
      this.getSchoolYearConfigurationStore(),
      this.getSubscriptionsService(),
      this.getNavigationService(),
      this.getAlertService(),
      this.getLocalizationService(),
      configId
    );
  }

  createEditableFeatures(
    schoolYearConfiguration: SchoolYearConfigurationModel,
    onSuccess: () => void,
    onCancel: () => void
  ): VM.EditFeaturesDialogViewModel {
    return new VM.AppEditFeaturesDialogViewModel(
      this.getSchoolYearConfigurationStore(),
      schoolYearConfiguration,
      this.getAlertService(),
      this.getLocalizationService(),
      onSuccess,
      onCancel
    );
  }

  createAddEntitlement(
    configId: string,
    availableEntitlements: SubscriptionEntitlement[],
    onSuccess: () => void,
    onCancel: () => void
  ): VM.AddEntitlementDialogViewModel {
    return new VM.AppAddEntitlementDialogViewModel(
      this.getSubscriptionsService(),
      this.getAlertService(),
      this.getLocalizationService(),
      configId,
      availableEntitlements,
      onSuccess,
      onCancel
    );
  }
  createExternalFileSourceEdition(
    editableSource: EditableExternalFileSource,
    onSuccess: (source: ExternalFileSource) => void,
    onCancel: () => void
  ): VM.ExternalFileSourceEditionViewModel {
    return new VM.AppExternalFileSourceEditionViewModel(
      this.getImporterStore(),
      this.getAlertService(),
      this.getLocalizationService(),
      editableSource,
      onSuccess,
      onCancel
    );
  }

  private getContentService(): ContentService {
    return InsightsServiceContainer.services.contentService;
  }

  private getAccountService(): AccountService {
    return InsightsServiceContainer.services.accountService;
  }

  private getCalendarStore(): CalendarStore {
    return InsightsServiceContainer.services.calendarStore;
  }

  private getContentStore(): ContentStore {
    return InsightsServiceContainer.services.contentStore;
  }

  private getImporterStore(): ImporterStore {
    return InsightsServiceContainer.services.importerStore;
  }

  private getOnboardingStore(): OnboardingStore {
    return InsightsServiceContainer.services.onboardingStore;
  }

  private getMetricsStore(): MetricsStore {
    return InsightsServiceContainer.services.metricsStore;
  }

  private getNavigationService(): NavigationService {
    return InsightsServiceContainer.services.navigationService;
  }

  private getSchoolYearConfigurationStore(): SchoolYearConfigurationStore {
    return InsightsServiceContainer.services.schoolYearConfigurationStore;
  }

  private getSettingsStore(): SettingsStore {
    return InsightsServiceContainer.services.settingsStore;
  }

  private getLocalStorage(): Storage {
    return InsightsServiceContainer.services.localStorage;
  }

  private getUserStore(): UserStore {
    return InsightsServiceContainer.services.userStore;
  }

  private getConnectorsStore(): ConnectorsStore {
    return InsightsServiceContainer.services.connectorsStore;
  }

  private getLocalizationService(): LocalizationService {
    return InsightsServiceContainer.services.localizationService;
  }

  private getAlertService(): AlertService {
    return InsightsServiceContainer.services.alertService;
  }

  private getToolsTransport(): ToolsTransport {
    return InsightsServiceContainer.services.toolsTransport;
  }

  private getEnvironmentService(): InsightsEnvironmentService {
    return InsightsServiceContainer.services.environmentService;
  }

  private getSubscriptionsService(): SubscriptionsService {
    return InsightsServiceContainer.services.subscriptionsService;
  }
}
