import { GradeLevelSource } from '@shared/models/types';

export interface SchoolYearConfigurationStrings {
  gradeLevelSourceTitle(source: GradeLevelSource): string;
}

export const EnglishSchoolYearConfigurationStrings: SchoolYearConfigurationStrings = {
  gradeLevelSourceTitle: englishGradeLevelSourceTitle
};

function englishGradeLevelSourceTitle(source: GradeLevelSource): string {
  switch (source) {
    case 'account':
      return 'Account';
    case 'section':
      return 'Section';
  }
}

export const FrenchSchoolYearConfigurationStrings: SchoolYearConfigurationStrings = {
  gradeLevelSourceTitle: frenchGradeLevelSourceTitle
};

function frenchGradeLevelSourceTitle(source: GradeLevelSource): string {
  switch (source) {
    case 'account':
      return 'Fiche élève';
    case 'section':
      return 'Groupe-matière';
  }
}
