import { NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppSchoologyAccountViewModel, SchoologyAccountViewModel } from './SchoologyAccountViewModel';

export interface SchoologyAccountDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly account: IPromiseBasedObservable<SchoologyAccountViewModel>;
}

export class AppSchoologyAccountDialogViewModel implements SchoologyAccountDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get account(): IPromiseBasedObservable<SchoologyAccountViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<SchoologyAccountViewModel> {
    if (this.externalAccountId === 'new') {
      return new AppSchoologyAccountViewModel(
        this._localizationService,
        this._connectorsStore.schoology,
        this._onSuccess,
        this._onCancel,
        this.configId,
        '',
        'Schoology',
        'https://api.schoology.com',
        '',
        ''
      );
    }

    const [account, details] = await Promise.all([
      this._connectorsStore.getExternalAccount(this.configId, this.externalAccountId),
      this._connectorsStore.schoology.getSchoologyAccountDetails(this.externalAccountId)
    ]);

    return new AppSchoologyAccountViewModel(
      this._localizationService,
      this._connectorsStore.schoology,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      account.name,
      details.serverUrl,
      details.consumerKey,
      details.consumerSecret
    );
  }
}
