export interface NotFoundViewStrings {
  title: string;
  errorOnOurPartText: string;
  errorOnOurPartLetUsKnowLink: string;
  usefulLinks: string;
  usefulLinksDashboard: string;
  usefulLinksGoBack: string;
}

export const EnglishNotFoundViewStrings: NotFoundViewStrings = {
  title: 'Sorry, we cannot find the page you are looking for.',
  errorOnOurPartText: 'If you think this is an error on our part, please ', // The space at the end is important
  errorOnOurPartLetUsKnowLink: 'let us know',
  usefulLinks: 'The following links might be useful:',
  usefulLinksDashboard: 'Go to the dashboard',
  usefulLinksGoBack: 'Go to the previous page'
};

export const FrenchNotFoundViewStrings: NotFoundViewStrings = {
  title: 'Désolé, nous n’avons pas trouvé la page demandée.',
  errorOnOurPartText: 'Si vous pensez que c’est une erreur de notre part, svp ', // The space at the end is important
  errorOnOurPartLetUsKnowLink: 'commiquez avec nous',
  usefulLinks: 'Les liens suivants peuvent vous être utiles:',
  usefulLinksDashboard: 'Aller au tableau de bord',
  usefulLinksGoBack: 'Aller à la page précédente'
};
