import * as VPB from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/veracross_pb';
import { Veracross } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/veracross_connect';
import { VeracrossTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class VeracrossGrpcTransport extends BaseGrpcTransport implements VeracrossTransport {
  async fetchVeracrossExternalAccountDetails(
    externalAccountId: string
  ): Promise<VPB.GetExternalAccountDetailsResponse> {
    console.log(`Fetching Veracross external account details for [externalAccountId: ${externalAccountId}]...`);

    const request = new VPB.GetExternalAccountDetailsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(Veracross, Veracross.methods.getExternalAccountDetails, request);

    console.log('Successfully fetched Veracross external account.');

    return response;
  }

  async createOrUpdateVeracrossExternalAccount(
    externalAccountId: string | undefined,
    configId: string,
    serverUrl: string,
    username: string,
    password: string,
    accountName: string
  ): Promise<VPB.CreateOrUpdateExternalAccountResponse> {
    console.log('Creating or updating Veracross external account...');

    const request = new VPB.CreateOrUpdateExternalAccountRequest();
    request.externalAccountId = externalAccountId ?? '';
    request.configId = configId;
    request.serverUrl = serverUrl;
    request.username = username;
    request.password = password;
    request.accountName = accountName;

    const response = await this.performRequest(Veracross, Veracross.methods.createOrUpdateExternalAccount, request);

    console.log('Successfully created or updated Veracross external account.');

    return response;
  }
}
