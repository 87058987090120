import { SectionInfo, sectionInfoFromModel } from '@insights/models';
import { AlertService, NavigationService } from '@insights/services';
import { AccountModel, EditableAccount, EditableAccountProfile, SectionModel } from '@shared/models/config';
import { Role } from '@shared/models/types';
import { UserProfile } from '@shared/models/user';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore, UserStore } from '@shared/services/stores';
import { isDemoError } from '@shared/services/stores/implementations/DemoSchoolInterceptor';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { v4 as uuidv4 } from 'uuid';

export interface LoadingAccountEditionViewModel {
  readonly configId: string;
  readonly accountId: string;

  readonly data: IPromiseBasedObservable<AccountEditionViewModel>;

  close(): void;
}

export interface AccountEditionViewModel {
  // Tested pattern: editable models offer everything to get, set and observe.
  // The view-model directly exposes the editable model, and only adds helpers for minimal stuff.
  readonly editableAccount: EditableAccount;
  readonly editableProfile: EditableAccountProfile;

  readonly acceptedRoles: Role[];
  readonly sections: SectionModel[];
  readonly sectionsById: Record<string, SectionModel>;
  readonly teachersById: Record<string, AccountModel>;
  readonly availableSections: SectionInfo[];
  readonly selectedSections: SectionInfo[];
  readonly hasMissingSection: boolean;
  readonly hasPrivateNames: boolean;
  readonly allowedScheduleTags: string[];

  readonly userProfileData: IPromiseBasedObservable<UserProfile | undefined>;

  readonly isSaving: boolean;
  readonly canSave: boolean;
  readonly messages: string[];

  selectSection(sectionId: string): void;
  unselectSection(sectionId: string): void;

  copyStudentSections(): Promise<void>;
  deleteAccount(): Promise<void>;
  undeleteAccount(): Promise<void>;

  apply(): Promise<void>;
}

export class AppLoadingAccountEditionViewModel implements LoadingAccountEditionViewModel {
  constructor(
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _userStore: UserStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly accountId: string,
    private readonly _acceptedRoles: Role[]
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<AccountEditionViewModel> {
    return fromPromise(this.accountId.length > 0 ? this.loadAccount() : this.createAccount());
  }

  close() {
    this._onCancel();
  }

  private async loadAccount(): Promise<AccountEditionViewModel> {
    // Note: Knowing one of these calls "can" make the real "fetch all", the order could impact.
    //       For now, we ignore this, but it proves a central "configData" could make sense when
    //       managing.
    const [config, account, sections, sectionsById, teachersById] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this.configId),
      this._schoolYearConfigurationStore.getAccount(this.configId, this.accountId, true),
      this._schoolYearConfigurationStore.getSections(this.configId),
      this._schoolYearConfigurationStore.getSectionsById(this.configId),
      this._schoolYearConfigurationStore.getTeachersById(this.configId, true)
    ]);

    const autoEnrolledSections = await this.getAutoEnrolledSections(account);
    const editableAccount = new EditableAccount(account);

    return new AppAccountEditionViewModel(
      this._alertService,
      this._localizationService,
      this._schoolYearConfigurationStore,
      this._userStore,
      this._navigationService,
      this._onSuccess,
      this.configId,
      sections,
      sectionsById,
      teachersById,
      autoEnrolledSections,
      editableAccount,
      // We do not permit changing roles
      [account.role],
      config.allowedScheduleTags
    );
  }

  private async createAccount(): Promise<AccountEditionViewModel> {
    const role = this._acceptedRoles[0];

    if (role == null) {
      throw new Error('At least one role must be provided when creating a new account.');
    }

    // Note: Knowing one of these calls "can" make the real "fetch all", the order could impact.
    //       For now, we ignore this, but it proves a central "configData" could make sense when
    //       managing.
    const [config, sections, sectionsById, teachersById] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this.configId),
      this._schoolYearConfigurationStore.getSections(this.configId),
      this._schoolYearConfigurationStore.getSectionsById(this.configId),
      this._schoolYearConfigurationStore.getTeachersById(this.configId, true)
    ]);

    const editableAccount = EditableAccount.createNew(this.configId, role);
    const autoEnrolledSections = await this.getAutoEnrolledSections(editableAccount);

    editableAccount.isLocked = true;

    return new AppAccountEditionViewModel(
      this._alertService,
      this._localizationService,
      this._schoolYearConfigurationStore,
      this._userStore,
      this._navigationService,
      this._onSuccess,
      this.configId,
      sections,
      sectionsById,
      teachersById,
      autoEnrolledSections,
      editableAccount,
      this._acceptedRoles,
      config.allowedScheduleTags
    );
  }

  private async getAutoEnrolledSections(account: AccountModel): Promise<SectionModel[]> {
    switch (account.role) {
      case 'student':
        return await this._schoolYearConfigurationStore.getAutoEnrolledSectionsForStudent(this.configId, account);

      case 'teacher':
        return await this._schoolYearConfigurationStore.getAutoEnrolledSectionsForTeacher(this.configId, account);
    }

    return [];
  }
}

export class AppAccountEditionViewModel implements AccountEditionViewModel {
  @observable private _isSaving = false;
  @observable private _messages: string[] = [];

  readonly editableProfile: EditableAccountProfile;
  readonly hasPrivateNames: boolean;

  constructor(
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _userStore: UserStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _configId: string,
    public readonly sections: SectionModel[],
    public readonly sectionsById: Record<string, SectionModel>,
    public readonly teachersById: Record<string, AccountModel>,
    private readonly _autoEnrolledSections: SectionModel[],
    public readonly editableAccount: EditableAccount,
    public readonly acceptedRoles: Role[],
    public readonly allowedScheduleTags: string[]
  ) {
    makeObservable(this);
    this.editableProfile = editableAccount.getEditableProfile();
    this.hasPrivateNames =
      this.editableProfile.privateFirstName?.length > 0 || this.editableProfile.privateLastName?.length > 0;
  }

  @computed
  private get missingSectionIds(): string[] {
    return this.editableAccount.selectedSectionIds.filter(
      (id) => this.selectedSections.find((info) => info.id === id) == null
    );
  }

  @computed
  get userProfileData() {
    return fromPromise(this.getUserProfile(this.editableAccount.userId));
  }

  @computed
  get isSaving(): boolean {
    return this._isSaving;
  }

  @computed
  get canSave(): boolean {
    return this.hasMissingSection || this.editableAccount.hasChanges;
  }

  @computed
  get messages(): string[] {
    return this._messages;
  }

  @computed
  get availableSections(): SectionInfo[] {
    const taken = new Set(this.editableAccount.selectedSectionIds);

    return this.sections
      .filter(
        (section) => !taken.has(section.id) && this._autoEnrolledSections.find((s) => s.id === section.id) == null
      )
      .map<SectionInfo>(sectionInfoFromModel);
  }

  @computed
  get selectedSections(): SectionInfo[] {
    return _.compact(
      this.editableAccount.selectedSectionIds
        .map<SectionInfo>((id) => ({ id, section: this.sectionsById[id] }))
        .concat(
          this._autoEnrolledSections.map<SectionInfo>((section) => ({
            id: section.id,
            section,
            isAutoEnrolled: true
          }))
        )
    );
  }

  @computed
  get hasMissingSection(): boolean {
    return this.selectedSections.length < this.editableAccount.selectedSectionIds.length;
  }

  @action
  selectSection(sectionId: string): void {
    const editable = this.editableAccount.getEditableSettings();

    if (editable == null) {
      throw new Error('Invalid operation: An EditableAccount should always expose an EditableAccountSettings.');
    }

    // The original array is not an observable array, so we must always work with the setter.
    editable.selectedSectionIds = _.uniq(this.editableAccount.settings.selectedSectionIds.concat(sectionId));
  }

  @action
  unselectSection(sectionId: string): void {
    const editable = this.editableAccount.getEditableSettings();

    if (editable == null) {
      throw new Error('Invalid operation: An EditableAccount should always expose an EditableAccountSettings.');
    }

    editable.selectedSectionIds = this.editableAccount.settings.selectedSectionIds.filter((id) => id !== sectionId);
  }

  async copyStudentSections(): Promise<void> {
    const result = await this._navigationService.navigateToAccountSectionsEditionCopy(
      this._configId,
      this.editableAccount.id
    );

    if (result !== 'cancelled') {
      const currentSectionIds = this.selectedSections.map((s) => s.id);
      currentSectionIds.forEach((id) => this.unselectSection(id));
      result.forEach((id) => this.selectSection(id));
    }
  }

  @action
  async deleteAccount(): Promise<void> {
    if (this.isSaving) {
      return Promise.resolve();
    }

    const strings = this._localizationService.localizedStrings.insights.components.accounts;

    const result = await this._alertService.showConfirmation({
      message: strings.deleteAccountConfirmationMessage,
      okButtonCaption: strings.deleteAccountConfirmationYes,
      cancelButtonCaption: strings.deleteAccountConfirmationNo
    });

    if (result !== 'cancelled') {
      runInAction(() => {
        this._isSaving = true;
      });

      try {
        await this._schoolYearConfigurationStore.deleteAccount(this.editableAccount);

        this._onSuccess();
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.deleteAccountErrorTitle,
          message: isDemoError(error as Error)
            ? strings.deleteAccountDemoErrorMessage(error as Error)
            : strings.deleteAccountErrorMessage
        });
      } finally {
        runInAction(() => {
          this._isSaving = false;
        });
      }
    }
  }

  @action
  async undeleteAccount() {
    this._isSaving = true;

    try {
      await this._schoolYearConfigurationStore.undeleteAccount(this.editableAccount);

      this._onSuccess();
    } catch (e) {
      const strings = this._localizationService.localizedStrings.insights.components.accounts;
      await this._alertService.showMessage({
        title: strings.undeleteAccountErrorTitle,
        message: isDemoError(e as Error)
          ? strings.undeleteAccountDemoErrorMessage(e as Error)
          : strings.undeleteAccountErrorMessage
      });
    } finally {
      runInAction(() => {
        this._isSaving = false;
      });
    }
  }

  @action
  async apply(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.metrics;

    if (!this.canSave) {
      console.error('Invalid operation: It should not be possible to call apply without changes.');
      return;
    }

    this._isSaving = true;
    this._messages = this.validate();

    try {
      if (this._messages.length === 0) {
        this.normalize();
        // Remove missing sections
        this.missingSectionIds.forEach((id) => this.unselectSection(id));
        await this._schoolYearConfigurationStore.saveAccount(this.editableAccount);
        this._onSuccess();
      }
    } catch (error) {
      runInAction(() => {
        this._messages = [strings.unexpectedError + (error as Error).message];
      });
    } finally {
      runInAction(() => {
        this._isSaving = false;
      });
    }
  }

  private validate(): string[] {
    // Nothing special to validate. Empty managed identifiers are handled in normalize.
    return [];
  }

  private normalize() {
    if (this.editableAccount.managedIdentifier.length === 0) {
      this.editableAccount.managedIdentifier = uuidv4();
    }
  }

  private async getUserProfile(userId: string): Promise<UserProfile | undefined> {
    if (userId != null && userId.length > 0) {
      return await this._userStore.getUserProfileById(userId);
    }

    return undefined;
  }
}
