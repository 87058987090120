import { InsightsButton, OnboardingProcessEdition, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider,
  Typography
} from '@mui/material';
import { OnboardingProcess } from '@shared/models/onboarding/interfaces';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface OnboardingProcessEditionDialogProps extends DialogResult<OnboardingProcess> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  process: OnboardingProcess;
}

export const OnboardingProcessEditionDialog = observer((props: OnboardingProcessEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx = [], process, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createOnboardingProcessEditionDialog(process, onSuccess!, onCancel!)
  );

  const isCustomized = viewModel.editableProcess.isCustomized;

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        onClose={() => viewModel.cancel()}
      >
        <DialogTitle>{strings.editProcessTitle}</DialogTitle>
        <DialogContent>
          <OnboardingProcessEdition
            sx={{ mb: 1 }}
            editableProcess={viewModel.editableProcess}
            onFileUpload={(d, f) => viewModel.uploadFile(d, f)}
          />
          {isCustomized && (
            <Box display="flex" flexDirection="row-reverse" sx={{ mt: 1 }}>
              <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.error.main }}>
                {strings.customizedProcessNotice}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <InsightsButton onClick={() => viewModel.cancel()}>{strings.cancelLabel}</InsightsButton>
          <InsightsButton
            isDefault={isCustomized}
            isDisabled={!viewModel.canSave}
            onClick={() => void viewModel.save(false)}
          >
            {isCustomized ? strings.saveForThisSchoolButton : strings.saveOnlyForThisSchoolButton}
          </InsightsButton>
          <InsightsButton
            isDefault={!isCustomized}
            isDisabled={!viewModel.canSave || isCustomized}
            onClick={() => void viewModel.save(true)}
          >
            {strings.saveForAllButton}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
