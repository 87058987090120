import { EnvironmentService, KillSwitchModel, KillSwitchService } from '@shared/services';
import { computed, makeObservable, observable, runInAction } from 'mobx';

const CheckInterval = 60000; // 60 seconds

export class WebKillSwitchService implements KillSwitchService {
  private _handle: NodeJS.Timeout | undefined;
  @observable private _updateAvailable = false;

  constructor(private readonly _environmentService: EnvironmentService) {
    makeObservable(this);
    this.startAutoSync();

    document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this), false);
  }

  @computed
  get updateAvailable(): boolean {
    return this._updateAvailable;
  }

  async checkForUpdate(): Promise<boolean> {
    // Not checking if app needs update if we are in beta.
    if (this._environmentService.environmentName === 'beta') {
      return false;
    }

    try {
      const response = await fetch(this.killSwitchUrl, { cache: 'no-store' });

      if (!response.ok) {
        return false;
      }

      const data = (await response.json()) as KillSwitchModel;
      return this.currentBuildNumber == null || data.minimumBuildNumber > this.currentBuildNumber;
    } catch {
      return false;
    }
  }

  private get currentBuildNumber(): number {
    return +this._environmentService.buildNumber;
  }

  private get killSwitchUrl() {
    return this._environmentService.killSwitchUrl;
  }

  private startAutoSync() {
    if (document.visibilityState !== 'visible') {
      return;
    }

    this.stopAutoSync();

    // Do an initial check.
    void this.autoSyncCallback();
    // Schedule check at an interval.
    this._handle = setTimeout(() => void this.autoSyncCallback(), CheckInterval);
  }

  private stopAutoSync() {
    if (this._handle == null) {
      return;
    }

    clearInterval(this._handle);
    this._handle = undefined;
  }

  private handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
      this.startAutoSync();
    } else {
      this.stopAutoSync();
    }
  }

  private async autoSyncCallback() {
    const needsUpdate = await this.checkForUpdate();
    runInAction(() => (this._updateAvailable = needsUpdate));
  }
}
