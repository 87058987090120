export interface MetricViewModelsStrings {
  exportStudentsTooltip: string;
  noTasksToExport: string;
  noTasksToExportTitle: string;
  nothingToExport: string;
  nothingToExportTitle: string;
  unexpectedError: string;
  unexpectedErrorTitle: string;
}

export const EnglishMetricViewModelsStrings: MetricViewModelsStrings = {
  exportStudentsTooltip: 'Export student list to CSV',
  noTasksToExport: 'There are no tasks to export for this week.',
  noTasksToExportTitle: 'No tasks',
  nothingToExport: 'There is nothing to export.',
  nothingToExportTitle: 'Empty list',
  unexpectedError: 'An unexpected error occurred: ',
  unexpectedErrorTitle: 'Oops'
};

export const FrenchMetricViewModelsStrings: MetricViewModelsStrings = {
  exportStudentsTooltip: 'Exporter la liste d’élèves en CSV',
  noTasksToExport: 'Il n’y a aucune tâche à exporter pour cette semaine.',
  noTasksToExportTitle: 'Aucune tâche',
  nothingToExport: 'Il n’y a rien à exporter.',
  nothingToExportTitle: 'Liste vide',
  unexpectedError: 'Une erreur inattendue est survenue: ',
  unexpectedErrorTitle: 'Oups'
};
