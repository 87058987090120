import { get, sortBy } from 'lodash';

/**
 * Conditionally sort a collection.
 * @param collection The collection to sort.
 * @param condition The condition that has to be met for a row in order to apply the sort.
 * @param iteratees The iteratees with which to sort.
 * @see https://lodash.com/docs/4.17.15#sortBy for more details about `iteratees`.
 */
export function conditionalSortBy<T>(
  collection: T[],
  condition: (value: T) => boolean,
  iteratees: (string | ((value: T) => unknown))[]
): T[] {
  return sortBy(
    collection,
    iteratees.map(
      (iteratee) => (s: T) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        condition(s) ? (typeof iteratee === 'string' ? get(s, iteratee) : iteratee(s)) : undefined
    )
  );
}
