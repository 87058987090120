import { AuthenticationService } from '../AuthenticationService';
import { EnvironmentService } from '../EnvironmentService';
import * as Imp from './implementations';
import * as Interfaces from './interfaces';

export class GrpcTransports {
  // Main transports
  school: Interfaces.SchoolTransport;
  content: Interfaces.ContentTransport;
  user: Interfaces.UserTransport;
  generator: Interfaces.GeneratorTransport;
  metrics: Interfaces.MetricsTransport;

  // onboarding tools
  importer: Interfaces.ImporterTransport;
  onboarding: Interfaces.OnboardingTransport;

  // Connectors
  connectorManager: Interfaces.ConnectorManagerTransport;
  blackbaud: Interfaces.BlackbaudTransport;
  blackbaudSky: Interfaces.BlackbaudSkyTransport;
  calendars: Interfaces.CalendarsTransport;
  canvas: Interfaces.CanvasTransport;
  classroom: Interfaces.GoogleClassroomTransport;
  manageBac: Interfaces.ManageBacTransport;
  moodle: Interfaces.MoodleTransport;
  schoology: Interfaces.SchoologyTransport;
  studyoInternal: Interfaces.StudyoInternalConnectorTransport;
  subscriptions: Interfaces.SubscriptionsTransport;
  teams: Interfaces.MicrosoftTeamsTransport;
  veracross: Interfaces.VeracrossTransport;
  veracrossV3: Interfaces.VeracrossV3Transport;

  constructor(authenticationService: AuthenticationService, environmentService: EnvironmentService) {
    this.school = new Imp.SchoolGrpcTransport(authenticationService, environmentService);
    this.content = new Imp.ContentGrpcTransport(authenticationService, environmentService);
    this.user = new Imp.UserGrpcTransport(authenticationService, environmentService);
    this.generator = new Imp.GeneratorGrpcTransport(authenticationService, environmentService);
    this.metrics = new Imp.MetricsGrpcTransport(authenticationService, environmentService);
    this.importer = new Imp.ImporterGrpcTransport(authenticationService, environmentService);
    this.onboarding = new Imp.OnboardingGrpcTransport(authenticationService, environmentService);
    this.connectorManager = new Imp.ConnectorManagerGrpcTransport(authenticationService, environmentService);
    this.blackbaud = new Imp.BlackbaudGrpcTransport(authenticationService, environmentService);
    this.blackbaudSky = new Imp.BlackbaudSkyGrpcTransport(authenticationService, environmentService);
    this.calendars = new Imp.CalendarsGrpcTransport(authenticationService, environmentService);
    this.canvas = new Imp.CanvasGrpcTransport(authenticationService, environmentService);
    this.classroom = new Imp.GoogleClassroomGrpcTransport(authenticationService, environmentService);
    this.manageBac = new Imp.ManageBacGrpcTransport(authenticationService, environmentService);
    this.moodle = new Imp.MoodleGrpcTRansport(authenticationService, environmentService);
    this.schoology = new Imp.SchoologyGrpcTransport(authenticationService, environmentService);
    this.studyoInternal = new Imp.StudyoInternalConnectorGrpcTransport(authenticationService, environmentService);
    this.subscriptions = new Imp.SubscriptionsGrpcTransport(authenticationService, environmentService);
    this.teams = new Imp.MicrosoftTeamsGrpcTransport(authenticationService, environmentService);
    this.veracross = new Imp.VeracrossGrpcTransport(authenticationService, environmentService);
    this.veracrossV3 = new Imp.VeracrossV3GrpcTransport(authenticationService, environmentService);
  }
}
