import { EditableImportSession, EditableSourceFile, ImportSession } from '@shared/models/import';
import { ImporterStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface ImportSessionFile {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly data: any;
  readonly name: string;
}

export interface ImportSessionFileEditDialogViewModel {
  readonly canSave: boolean;
  readonly isExecuting: boolean;
  readonly hasError: boolean;
  readonly expectedSourceFilename: string;
  file: ImportSessionFile | undefined;
  save: () => Promise<void>;
  cancel: () => void;
}

export class AppImportSessionFileEditDialogViewModel implements ImportSessionFileEditDialogViewModel {
  private readonly _editableFile: EditableSourceFile;
  private readonly _editableSession: EditableImportSession;
  @observable private _file: ImportSessionFile | undefined;
  @observable private _isExecuting = false;
  @observable private _error: Error | undefined;

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _configId: string,
    session: ImportSession,
    sourceFileLabel: string,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._editableSession = new EditableImportSession(session);
    const editableFile = this._editableSession.expectedFiles.find((f) => f.label === sourceFileLabel);

    if (editableFile == null) {
      throw new Error('Cannot find a file with that label.');
    }

    this._editableFile = editableFile;
  }

  @computed
  get canSave() {
    return this._file != null;
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @computed
  get hasError() {
    return this._error != null;
  }

  @computed
  get expectedSourceFilename() {
    return this._editableFile.name;
  }

  @computed
  get file() {
    return this._file;
  }

  set file(value: ImportSessionFile | undefined) {
    this._file = value;
  }

  @action
  async save() {
    if (this._file?.data == null) {
      console.error('No file selected');
      return;
    }

    this._isExecuting = true;
    this._error = undefined;

    try {
      // Getting upload url from backend
      const uploadUrl = await this._importSessionStore.uploadData(
        this._configId,
        this._file.name,
        this._editableFile.label,
        this._editableSession.id
      );

      // Upload the file data to the url we got from the backend.
      await fetch(uploadUrl.uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': uploadUrl.contentType
        },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        body: this.dataUrlToBlob(this._file.data)
      });

      // Update url for source file and keeping previous
      if (this._editableFile.url != null && this._editableFile.url.length > 0) {
        this._editableFile.addPreviousUrl(this._editableFile.url);
      }
      this._editableFile.url = uploadUrl.downloadUrl;

      // Saving import session with updated source file. No need for the data in the
      // origin screen.
      await this._importSessionStore.createOrUpdateImportSession(this._editableSession, false);

      runInAction(() => {
        this._isExecuting = false;
        this._error = undefined;
      });

      this._onSuccess();
    } catch (error) {
      runInAction(() => {
        this._isExecuting = false;
        this._error = error as Error;
      });
    }
  }

  cancel() {
    this._onCancel();
  }

  // See https://stackoverflow.com/a/27781331
  // Important: do not use the XMLHttpRequest-based solution, as it is not compatible with Safari
  private dataUrlToBlob(dataUrl: string) {
    if (typeof dataUrl !== 'string') {
      throw new Error('Invalid argument: dataURI must be a string');
    }

    const newDataUrl = dataUrl.split(',');
    const type = newDataUrl[0].split(':')[1].split(';')[0],
      byteString = atob(newDataUrl[1]),
      byteStringLength = byteString.length,
      arrayBuffer = new ArrayBuffer(byteStringLength),
      intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteStringLength; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([intArray], {
      type
    });
  }
}
