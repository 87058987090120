import { AlertService } from '@insights/services';
import { EditableOnboardingStep } from '@shared/models/onboarding/implementations';
import { OnboardingStep } from '@shared/models/onboarding/interfaces';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { DependableQuestions } from './DependableQuestions';

export interface OnboardingStepEditionViewModel {
  readonly editableStep: EditableOnboardingStep;
  readonly dependableStepNames: string[];
  readonly dependableQuestionNames: DependableQuestions[];
  readonly canSave: boolean;

  uploadFile(dataUrl: string, fileName?: string): Promise<void>;
}

export interface OnboardingStepEditionDialogViewModel {
  readonly data: IPromiseBasedObservable<OnboardingStepEditionViewModel>;
  readonly canSave: boolean;

  save(alsoUpdateTemplate: boolean): Promise<void>;
  cancel(): void;
}

class AppOnboardingStepEditionViewModel implements OnboardingStepEditionViewModel {
  constructor(
    private readonly _onboardingStore: OnboardingStore,
    readonly editableStep: EditableOnboardingStep,
    readonly dependableStepNames: string[],
    readonly dependableQuestionNames: DependableQuestions[]
  ) {
    makeObservable(this);
  }

  readonly canSave = true;

  async uploadFile(dataUrl: string, fileName?: string): Promise<void> {
    const fileUrl = await this._onboardingStore.uploadResourceFile(dataUrl, this.editableStep.configId, fileName);
    await navigator.clipboard.writeText(fileUrl);
  }
}

export class AppOnboardingStepEditionDialogViewModel implements OnboardingStepEditionDialogViewModel {
  @observable private _innerViewModel?: OnboardingStepEditionViewModel;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _step: OnboardingStep,
    private readonly _processName: string,
    private readonly _onSuccess: (step: OnboardingStep) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<OnboardingStepEditionViewModel> {
    return fromPromise(this.loadData());
  }

  @computed
  get canSave(): boolean {
    return this._innerViewModel?.canSave ?? false;
  }

  async save(alsoUpdateTemplate: boolean) {
    if (this._innerViewModel == null) {
      return;
    }

    try {
      const editableStep = this._innerViewModel.editableStep;

      const step = await this._onboardingStore.updateStep(
        editableStep.templateName,
        editableStep.configId,
        this._processName,
        editableStep.participants,
        editableStep.title,
        editableStep.description,
        editableStep.targetDays,
        // Questions not editable here
        editableStep.questions.map((q) => q.templateName),
        editableStep.isRepeatable,
        editableStep.dependantStepName,
        editableStep.dependantQuestionName,
        editableStep.dependantQuestionAnswer,
        alsoUpdateTemplate
      );
      this._onSuccess(step);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    }
  }

  cancel() {
    this._onCancel();
  }

  private async loadData(): Promise<OnboardingStepEditionViewModel> {
    // We only suggest step names in the process.
    const process = await this._onboardingStore.getProcess(this._processName, this._step.configId);

    const dependableQuestions = process.steps
      .filter((step) => step.id !== this._step.id)
      .map((step) => ({
        stepName: step.templateName,
        questionNames: step.questionNames
      }));

    const inner = new AppOnboardingStepEditionViewModel(
      this._onboardingStore,
      new EditableOnboardingStep(this._step),
      process.steps.map((s) => s.templateName).filter((n) => n !== this._step.templateName),
      dependableQuestions
    );

    return runInAction(() => (this._innerViewModel = inner));
  }
}
