import { BlackbaudMappingsViewModel } from '@insights/viewmodels';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { Column } from '../layout';
import { BlackbaudAssignmentKindMappings } from './BlackbaudAssignmentKindMappings';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';

export interface BlackbaudMappingsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: BlackbaudMappingsViewModel;
}

export const BlackbaudMappings = observer((props: BlackbaudMappingsProps) => {
  const { sx = [], className, style, viewModel } = props;

  return (
    <Column sx={sx} className={className} style={style}>
      <BlackbaudAssignmentKindMappings sx={{ mb: 2 }} viewModel={viewModel} />
      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
