import { EditableAccountInfo } from '@insights/models';
import { AccountService, NavigationService } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { AppSectionsViewModel } from '../metrics';
import { AppEditableAccountsScreenViewModel } from './EditableAccountsScreenViewModel';

export class AppEditableStaffScreenViewModel extends AppEditableAccountsScreenViewModel {
  constructor(
    schoolYearConfigurationStore: SchoolYearConfigurationStore,
    accountService: AccountService,
    navigationService: NavigationService,
    localizationService: LocalizationService,
    configId: string,
    shouldLimitAccess?: boolean
  ) {
    super(
      schoolYearConfigurationStore,
      accountService,
      navigationService,
      localizationService,
      configId,
      'staff',
      shouldLimitAccess
    );
  }

  protected async loadAccountInfos(accounts: AccountModel[]): Promise<EditableAccountInfo[]> {
    return Promise.resolve(
      accounts.map<EditableAccountInfo>((account) => {
        return {
          id: account.id,
          account,
          sections: new AppSectionsViewModel(this._navigationService, this._localizationService, this.configId, []),
          parents: [],
          children: [],
          pendingChildren: []
        };
      })
    );
  }
}
