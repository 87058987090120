import { AddAttachmentStrings, EnglishAddAttachmentStrings, FrenchAddAttachmentStrings } from './AddAttachmentStrings';

export interface AttachmentStrings {
  readonly add: AddAttachmentStrings;

  // TODO: Reevaluate these
  addAttachmentTakePictureOption: string;
  addAttachmentImportPictureOption: string;

  attachmentListPhotoDisplayTitle: string;
  noAttachments: string;

  takePicture: string;
  uploadFile: string;
  addUrl: string;
  dropboxLink: string;
  googleDriveLink: string;
  googleDriveDocument: string;

  addButton: string;
  listScreenCancel: string;
  listScreenDone: string;
}

export const EnglishAttachmentStrings: AttachmentStrings = {
  add: EnglishAddAttachmentStrings,

  addAttachmentTakePictureOption: 'Take a new photo',
  addAttachmentImportPictureOption: 'Choose an existing photo',

  attachmentListPhotoDisplayTitle: 'Photo',
  noAttachments: 'No attachments',

  takePicture: 'Take a new photo',
  uploadFile: 'Upload file',
  addUrl: 'Link to an URL',
  dropboxLink: 'Dropbox link',
  googleDriveLink: 'Google Drive link',
  googleDriveDocument: 'Google Drive document',

  addButton: 'Add…',
  listScreenCancel: 'Cancel',
  listScreenDone: 'Done'
};

export const FrenchAttachmentStrings: AttachmentStrings = {
  add: FrenchAddAttachmentStrings,

  addAttachmentTakePictureOption: 'Prendre une nouvelle photo',
  addAttachmentImportPictureOption: 'Choisir une photo existante',

  attachmentListPhotoDisplayTitle: 'Photo',
  noAttachments: 'Aucun attachement',

  takePicture: 'Prendre une nouvelle photo',
  uploadFile: 'Fichier local',
  addUrl: 'Lien vers un URL',
  dropboxLink: 'Lien Dropbox',
  googleDriveLink: 'Lien Google Drive',
  googleDriveDocument: 'Document Google Drive',

  addButton: 'Ajouter…',
  listScreenCancel: 'Annuler',
  listScreenDone: 'OK'
};
