import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { CalendarOptionsViewModel } from '@insights/viewmodels';
import { Card, CardContent, Checkbox, FormControlLabel, Stack, SxProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

export interface CreateSchoolCalendarProps {
  sx?: SxProps;
  className?: string;
  viewModel: CalendarOptionsViewModel;
}

export const CreateSchoolCalendar = observer(({ sx = [], className, viewModel }: CreateSchoolCalendarProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.createSchool;

  return (
    <Card sx={sx} className={className}>
      <CardContent sx={{ width: '100%' }}>
        <Stack spacing={2}>
          <Typography variant="h5">{strings.calendarSection}</Typography>
          {viewModel.canKeepCalendar && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={viewModel.shouldKeepCalendar}
                  onChange={(e) => (viewModel.shouldKeepCalendar = e.target.checked)}
                />
              }
              label={strings.keepCalendarLabel}
            />
          )}
          {!viewModel.shouldKeepCalendar && (
            <Stack spacing={2}>
              <Typography variant="h6">{strings.newCalendarSubSection}</Typography>
              <Stack direction="row" spacing={2}>
                <TextField
                  sx={{ flex: 1 }}
                  type="number"
                  value={viewModel.daysPerCycle}
                  label={strings.daysPerCycle}
                  onChange={(event) => {
                    const count = Number(event.target.value);
                    if (!Number.isNaN(count)) {
                      viewModel.daysPerCycle = count;
                    }
                  }}
                  error={!viewModel.hasValidDaysPerCycle}
                  helperText={!viewModel.hasValidDaysPerCycle && strings.invalidDaysPerCycleWarning}
                />
                <TextField
                  sx={{ flex: 1 }}
                  type="number"
                  value={viewModel.periodsPerDay}
                  label={strings.periodsPerDay}
                  onChange={(event) => {
                    const count = Number(event.target.value);
                    if (!Number.isNaN(count)) {
                      viewModel.periodsPerDay = count;
                    }
                  }}
                  error={!viewModel.hasValidPeriodsPerDay}
                  helperText={!viewModel.hasValidPeriodsPerDay && strings.invalidPeriodsPerDayWarning}
                />
                <TextField
                  sx={{ flex: 1 }}
                  type="number"
                  value={viewModel.minutesPerPeriod}
                  label={strings.minutesPerPeriod}
                  onChange={(event) => {
                    const count = Number(event.target.value);
                    if (!Number.isNaN(count)) {
                      viewModel.minutesPerPeriod = count;
                    }
                  }}
                  error={!viewModel.hasValidMinutesPerPeriod}
                  helperText={!viewModel.hasValidMinutesPerPeriod && strings.invalidMinutesPerPeriodWarning}
                />
              </Stack>
              <Typography variant="caption">{strings.calendarNotice}</Typography>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
});
