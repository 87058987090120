import { Typography, TypographyProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';

export const Label = observer(({ sx = [], ...props }: TypographyProps) => (
  <Typography
    variant="caption"
    sx={[{ color: (theme) => theme.palette.text.secondary }, ...(isSxArray(sx) ? sx : [sx])]}
    {...props}
  />
));
