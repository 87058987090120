import { AlertService, SubscriptionsService } from '@insights/services';
import { SubscriptionEntitlement } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface AddEntitlementDialogViewModel {
  readonly availableEntitlements: SubscriptionEntitlement[];
  entitlement: SubscriptionEntitlement;
  stripePriceId: string;

  readonly canSave: boolean;
  readonly isSubmitting: boolean;

  save(): Promise<void>;
  cancel(): void;
}

export class AppAddEntitlementDialogViewModel implements AddEntitlementDialogViewModel {
  @observable private _entitlement: SubscriptionEntitlement;
  @observable private _stripePriceId = '';
  @observable private _isSubmitting = false;

  constructor(
    private readonly _subscriptionsService: SubscriptionsService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    readonly availableEntitlements: SubscriptionEntitlement[],
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._entitlement = availableEntitlements[0];
  }

  @computed
  get entitlement(): SubscriptionEntitlement {
    return this._entitlement;
  }

  set entitlement(value: SubscriptionEntitlement) {
    this._entitlement = value;
  }

  @computed
  get stripePriceId(): string {
    return this._stripePriceId;
  }

  set stripePriceId(value: string) {
    this._stripePriceId = value;
  }

  @computed
  get canSave(): boolean {
    return this._entitlement != null && this._stripePriceId.length > 0;
  }

  @computed
  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  @action
  async save(): Promise<void> {
    this._isSubmitting = true;

    try {
      await this._subscriptionsService.addSchoolRequiredFeature(this._configId, this._entitlement, this._stripePriceId);
      this._onSuccess();
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.features;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isSubmitting = false));
    }
  }

  cancel() {
    this._onCancel();
  }
}
