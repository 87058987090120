import { ManageableRole } from '@insights/enums';
import { AccountInfo } from '@insights/models';
import { AccountInfoUtils, CustomFilterUtils } from '@insights/utils';
import { ExportableViewModel, FilteredViewModel } from '@insights/viewmodels';
import { Action, MTableAction, Column as TableColumn } from '@material-table/core';
import { mdiAccount } from '@mdi/js';
import Icon from '@mdi/react';
import FilterIcon from '@mui/icons-material/FilterList';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Badge,
  Box,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Snackbar,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AdminOrTeacherAuthorizationRoles } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { ReactRouterRouteService } from '@shared/web/services';
import _ from 'lodash';
import { Observer, observer } from 'mobx-react-lite';
import moize from 'moize';
import { CSSProperties, useRef, useState } from 'react';
import { DefaultTablePageSizes } from '../Constants';
import { RouteParamNames, RouteTemplates } from '../Routes';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { BehaviourSummary } from './BehaviourSummary';
import { BehaviourSupplement } from './BehaviourSupplement';
import { InsightsMaterialTable } from './InsightsMaterialTable';
import { MoreActionMenu } from './MoreActionMenu';
import { OptionalRouterLink } from './RouterLink';
import { Column, Container, Expanded, Row } from './layout';

const AccountListTableKey = 'AccountListTable';

export interface AccountListProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title?: string;
  configId: string;
  accounts: AccountInfo[];
  filteredViewModel?: FilteredViewModel;
  exportableViewModel?: ExportableViewModel;
  accountType: ManageableRole;
}

export const AccountList = observer((props: AccountListProps) => {
  const { localizationService, accountService, reactRouterRouteService, navigationService } = useInsightsServices();
  const {
    className,
    sx = [],
    style,
    title,
    configId,
    accounts,
    accountType,
    filteredViewModel,
    exportableViewModel
  } = props;
  const insightsStrings = localizationService.localizedStrings.insights;
  const accountStrings = insightsStrings.components.accounts;
  const theme = useTheme();
  const [showToast, setShowToast] = useState(false);

  const tableStateKey = `${AccountListTableKey}-${accountType}`;
  const isStudent = accountType === 'student';
  const renderBehaviourSummary = isStudent;
  const renderGradeLevel = isStudent;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const actions: Action<AccountInfo>[] = [{ icon: 'more_vert', onClick: () => {} }];

  if (filteredViewModel != null && accountType === 'student') {
    actions.push({
      icon: 'filter_list',
      isFreeAction: true,
      tooltip: accountStrings.filterTooltip,
      onClick: () => void filteredViewModel?.showFilters()
    });
  }

  if (exportableViewModel?.canExport === true) {
    actions.push({
      icon: 'arrow_downward',
      isFreeAction: true,
      disabled: exportableViewModel.isExporting,
      tooltip: exportableViewModel.exportTooltip,
      onClick: () => void exportableViewModel.exportToCsv()
    });
  }

  // We can't use AuthorizationRoleCondition in a MoreActionMenu.
  const canViewTeacherPlanner = accountType === 'teacher' && accountService.isAllowed(AdminOrTeacherAuthorizationRoles);
  const canViewStudentPlanner = accountType === 'student' && accountService.isAllowed(AdminOrTeacherAuthorizationRoles);
  const canCopyIds = accountService.isAllowed(['super-admin']);

  function getRouteTemplateAndParamName(): {
    routeTemplate: string;
    accountIdParamName: string;
  } {
    switch (accountType) {
      case 'student':
        return {
          routeTemplate: RouteTemplates.studentDetails,
          accountIdParamName: RouteParamNames.studentId
        };
      case 'teacher':
        return {
          routeTemplate: RouteTemplates.teacherDetails,
          accountIdParamName: RouteParamNames.teacherId
        };
      case 'parent':
        // No parent details yet
        return { routeTemplate: '', accountIdParamName: '' };
      default:
        throw new Error('Unexpected account type');
    }
  }

  const getSearchableFields = useRef(
    moize((a: AccountInfo) => [AccountUtils.getDisplayLastFirstName(a.account), a.account.email])
  );

  async function copyIdToClipboard(account: AccountInfo): Promise<void> {
    await navigator.clipboard.writeText(account.account.id);
    setShowToast(true);
  }

  const columns = _.compact([
    renderBehaviourSummary ? renderBehaviourSummaryColumn(localizationService) : undefined,
    renderStudyoUserColumn(theme, localizationService),
    renderNameColumn(
      configId,
      33,
      theme,
      localizationService,
      reactRouterRouteService,
      getRouteTemplateAndParamName,
      getSearchableFields.current
    ),
    renderEmailColumn(33, localizationService),
    renderGradeLevel ? renderGradeLevelColumn(localizationService) : undefined
  ]);

  return (
    <Box sx={sx} className={className} style={style}>
      <Container>
        <InsightsMaterialTable
          stateKey={tableStateKey}
          // This is to disable the card contour
          components={{
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>,

            Action: (p) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (!p.action.isFreeAction) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const data = p.data as AccountInfo;

                return (
                  <Expanded>
                    <Row horizontalContentAlignment="right">
                      <MoreActionMenu>
                        {canViewTeacherPlanner && (
                          <MenuItem
                            onClick={() => void navigationService.navigateToPlannerExternal(configId, data.account.id)}
                          >
                            <ListItemText primary={accountStrings.viewPlanner} />
                            <Container sx={{ ml: 3 }}>
                              <ListItemIcon sx={{ m: 0 }}>
                                <OpenInNewIcon />
                              </ListItemIcon>
                            </Container>
                          </MenuItem>
                        )}
                        {canViewStudentPlanner && (
                          <MenuItem
                            onClick={() => void navigationService.navigateToPlannerExternal(configId, data.account.id)}
                          >
                            <ListItemText primary={accountStrings.viewPlanner} />
                            <Container sx={{ ml: 3 }}>
                              <ListItemIcon sx={{ m: 0 }}>
                                <OpenInNewIcon />
                              </ListItemIcon>
                            </Container>
                          </MenuItem>
                        )}
                        {canCopyIds && (
                          <MenuItem onClick={() => void copyIdToClipboard(data)}>
                            <ListItemText primary={accountStrings.copyId} />
                          </MenuItem>
                        )}
                      </MoreActionMenu>
                    </Row>
                  </Expanded>
                );
              }

              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (filteredViewModel != null && p.action.icon === 'filter_list') {
                return (
                  <Observer>
                    {() => (
                      <>
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment */}
                        <Tooltip title={p.action.tooltip}>
                          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment */}
                          <IconButton onClick={p.action.onClick}>
                            <Badge
                              badgeContent={filteredViewModel.filterCount}
                              color="primary"
                              showZero={false}
                              variant="dot"
                            >
                              <FilterIcon />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Observer>
                );
              }

              return <MTableAction {...p} />;
            }
          }}
          title={title ?? ''}
          columns={columns}
          actions={actions}
          data={accounts}
          options={{
            pageSize: 50,
            pageSizeOptions: DefaultTablePageSizes,
            rowStyle: { backgroundColor: '#fff', verticalAlign: 'top' },
            headerStyle: { fontSize: theme.typography.body2.fontSize },
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            draggable: false
          }}
          // Remove the "Actions" column title
          localization={{
            ...localizationService.localizedStrings.insights.materialTable,
            header: { actions: '' }
          }}
        />
      </Container>
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        message={accountStrings.copyIdNotification}
      />
    </Box>
  );
});

function renderBehaviourSummaryColumn(localizationService: LocalizationService): TableColumn<AccountInfo> {
  const strings = localizationService.localizedStrings.insights.components.accounts;

  return {
    title: strings.behavioursTitle,
    sorting: false,
    grouping: false,
    searchable: false,
    width: '1%',
    cellStyle: {
      maxWidth: 'min-content'
    },
    render: (rowData: AccountInfo) => (
      <Box
        sx={{
          marginY: 0.5,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 30
        }}
      >
        <BehaviourSummary
          size="small"
          appOpenValue={rowData.oqProfile?.opensTheApp}
          taskCompletionValue={rowData.oqProfile?.marksTasksAsDone}
        />
        {rowData.invitesParent != null && (
          <BehaviourSupplement sx={{ ml: 1 }} size="small" invitesParent={rowData.invitesParent} />
        )}
      </Box>
    )
  };
}

function renderStudyoUserColumn(theme: Theme, localizationService: LocalizationService): TableColumn<AccountInfo> {
  const strings = localizationService.localizedStrings.insights.components.accounts;

  return {
    sorting: false,
    grouping: false,
    searchable: false,
    width: '1%',
    cellStyle: {
      maxWidth: 32,
      paddingLeft: 0,
      paddingRight: 0
    },
    render: (rowData: AccountInfo) =>
      rowData.account.userId.length > 0 && (
        <Box
          sx={{
            marginY: 0.5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            minHeight: 30
          }}
        >
          <Tooltip title={strings.connectedUserTooltip}>
            <Icon path={mdiAccount} size={1} color={theme.palette.secondary.main} />
          </Tooltip>
        </Box>
      )
  };
}

function renderNameColumn(
  configId: string,
  widthPercent: number,
  theme: Theme,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService,
  getRouteTemplateAndParamName: () => { routeTemplate: string; accountIdParamName: string },
  getSearchableFields: (account: AccountInfo) => string[]
): TableColumn<AccountInfo> {
  const insightsStrings = localizationService.localizedStrings.insights;
  const accountStrings = insightsStrings.components.accounts;

  return {
    title: accountStrings.name,
    defaultSort: 'asc',
    width: `${widthPercent}%`,
    headerStyle: {
      paddingLeft: theme.spacing(0.5)
    },
    cellStyle: {
      paddingLeft: theme.spacing(0.5)
    },
    customSort: (a: AccountInfo, b: AccountInfo) =>
      AccountInfoUtils.compareNames(a, b, localizationService.currentLocale),
    customFilterAndSearch: (filter: string, account: AccountInfo) =>
      CustomFilterUtils.customFilterAndSearch(filter, account, getSearchableFields, [
        { prefix: 'admin:', isMatch: (a) => a.account.isAdmin }
      ]),
    render: (rowData: AccountInfo) => {
      const { routeTemplate, accountIdParamName } = getRouteTemplateAndParamName();

      return (
        <Container sx={{ mt: 0.5, mb: 0.5, minHeight: 30 }}>
          <Row verticalContentAlignment="center">
            <OptionalRouterLink
              variant="body1"
              to={reactRouterRouteService.resolveLocation(routeTemplate, [
                { name: RouteParamNames.configId, value: configId },
                { name: accountIdParamName, value: rowData.account.id }
              ])}
            >
              {AccountUtils.getDisplayLastFirstName(rowData.account, insightsStrings.noName)}
            </OptionalRouterLink>
          </Row>
        </Container>
      );
    }
  };
}

function renderEmailColumn(widthPercent: number, localizationService: LocalizationService): TableColumn<AccountInfo> {
  const strings = localizationService.localizedStrings.insights.components.accounts;

  return {
    title: strings.email,
    width: `${widthPercent}%`,
    cellStyle: {},
    customSort: (a: AccountInfo, b: AccountInfo) => AccountInfoUtils.compareEmails(a, b),
    render: (rowData: AccountInfo) => {
      return (
        <Container sx={{ mt: 0.5, mb: 0.5, minHeight: 30 }}>
          <Column>
            <Row verticalContentAlignment="center">
              <Link variant="body1" target="_top" href={`mailto:${rowData.account.email}`}>
                {rowData.account.email}
              </Link>
            </Row>
            {rowData.account.profile.publicEmail.length > 0 && (
              <Row verticalContentAlignment="center">
                <Link variant="body2" target="_top" href={`mailto:${rowData.account.profile.publicEmail}`}>
                  ✉️&nbsp;{rowData.account.profile.publicEmail}
                </Link>
              </Row>
            )}
          </Column>
        </Container>
      );
    }
  };
}

function renderGradeLevelColumn(localizationService: LocalizationService): TableColumn<AccountInfo> {
  const strings = localizationService.localizedStrings.insights.components.accounts;

  return {
    title: strings.gradeTitle,
    width: '1%',
    cellStyle: {
      maxWidth: 'min-content'
    },
    customSort: (a: AccountInfo, b: AccountInfo) =>
      AccountInfoUtils.compareGrades(a, b, localizationService.currentLocale),
    render: (rowData: AccountInfo) => {
      return (
        <Container sx={{ mt: 0.5, mb: 0.5, minHeight: 30 }}>
          <Row verticalContentAlignment="center">
            <Typography variant="body1">{rowData.account.gradeLevel}</Typography>
          </Row>
        </Container>
      );
    }
  };
}
