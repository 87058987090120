import { AuthenticationService, CompleteLoginResult, EnvironmentService } from '@shared/services';
import { action, computed, makeObservable, observable } from 'mobx';
import { InMemoryWebStorage, User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { IOSAccessTokenProvider } from './IOSAccessTokenProvider.ts';
import { SharedWebSettingsStore } from './SharedWebSettingsStore';

const UMSettings: UserManagerSettings = {
  client_id: '', // will be assigned in the constructor
  authority: '', // will be assigned in the constructor
  response_type: 'code',
  scope: 'openid profile email studyo.user_info studyo.api.v3',
  redirect_uri: window.location.origin + '/login?completion',
  post_logout_redirect_uri: window.location.origin + '/logout',
  automaticSilentRenew: true,
  loadUserInfo: false,
  silent_redirect_uri: window.location.origin + '/signin-silent-2.html'
};

export class WebAuthenticationService implements AuthenticationService {
  private _userManager!: UserManager;
  private _user?: User;

  @observable private _isAuthenticated = false;
  @observable private _isLoggingIn = false;

  @computed
  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  @computed
  get isLoggingIn() {
    return this._isLoggingIn;
  }

  constructor(
    environmentService: EnvironmentService,
    private readonly _iOSAccessTokenProvider: IOSAccessTokenProvider,
    private readonly _settingsStore: () => SharedWebSettingsStore
  ) {
    makeObservable(this);
    UMSettings.client_id = environmentService.authClientId;
    UMSettings.authority = environmentService.authServiceUrl;

    this.initializeUserManager();
  }

  async startSilentSigninFlow(): Promise<void> {
    if (this._settingsStore().useIOSAccessTokenProvider) {
      this.setIsAuthenticated(true);
      return;
    }

    this._userManager.startSilentRenew();

    try {
      this.setIsLoggingIn(true);

      await this._userManager.signinSilent();
    } catch (error) {
      console.error('An error occurred while signin in silently', error);
    } finally {
      this.setIsLoggingIn(false);
    }
  }

  async login(referrer?: string): Promise<boolean> {
    if (this._settingsStore().useIOSAccessTokenProvider) {
      this.setIsAuthenticated(true);
      return true;
    }

    try {
      this.setIsLoggingIn(true);

      let user: User | null = null;

      if (this.isAuthenticated) {
        console.error('The user is already logged in. Starting a silent login instead.');
        user = await this._userManager.signinSilent();
      } else {
        await this._userManager.signinRedirect({
          state: { referrer: referrer }
        });
      }

      return user != null;
    } catch (error) {
      console.error('An error occurred while logging in', error);
      return false;
    } finally {
      this.setIsLoggingIn(false);
    }
  }

  async completeLogin(): Promise<CompleteLoginResult> {
    try {
      // Set the same flag as login since we are still in the logging process.
      // When completeLogin is called, the app was reloaded so we need to reset the state.
      this.setIsLoggingIn(true);

      const user = await this._userManager.signinRedirectCallback();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment
      const state = user?.state as any;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      return { success: user != null, referrer: state?.referrer };
    } catch (error) {
      console.error('An error occurred while logging in', error);
      return { success: false };
    } finally {
      this.setIsLoggingIn(false);
    }
  }

  async logout(): Promise<void> {
    if (this._settingsStore().useIOSAccessTokenProvider) {
      return;
    }

    await this._userManager.signoutRedirect();
  }

  async completeLogout(): Promise<void> {
    try {
      await this._userManager.signoutRedirectCallback();
      await this._userManager.removeUser();

      this.setUser(undefined);
    } catch (error) {
      console.error('An error occurred while logging out', error);
    }
  }

  async getFreshAccessToken(forceRefresh: boolean): Promise<string | undefined> {
    if (this._settingsStore().useIOSAccessTokenProvider) {
      return await this._iOSAccessTokenProvider.getAccessToken();
    }

    if (this._user == null) {
      console.error('Trying to get a fresh access token while there is no authenticated user.');
      return undefined;
    }

    if (forceRefresh) {
      const user = await this._userManager.signinSilent();

      // NOTE: No need to update the `_user` private member as it will be done by the `_userManager` callbacks.

      return user?.access_token;
    }

    // The oidc-client library is configured to automatically renew access tokens when they are about
    // to expire, so nothing special to do here.
    return this._user.access_token;
  }

  private initializeUserManager() {
    let storage: Storage;

    try {
      storage = window.localStorage;
    } catch {
      // If there is a SecurityError, we use in memory storage. This is what OidcClient uses if window is undefined.
      storage = new InMemoryWebStorage();
    }

    const store = new WebStorageStateStore({ store: storage });
    const userManager = new UserManager({ ...UMSettings, stateStore: store });

    userManager.events.addUserLoaded(this.onUserLoaded);
    userManager.events.addUserUnloaded(this.onUserUnloaded);
    userManager.events.addSilentRenewError(this.onSilentRenewError);

    this._userManager = userManager;
  }

  @action
  private setIsLoggingIn(value: boolean) {
    this._isLoggingIn = value;
  }

  @action
  private setIsAuthenticated(value: boolean) {
    this._isAuthenticated = value;
  }

  @action
  private setUser(user?: User) {
    this._user = user;
    this._isAuthenticated = user != null;
  }

  private onUserLoaded = (user: User) => {
    this.setUser(user);
  };

  private onUserUnloaded = () => {
    console.log('User unloaded');

    this.setUser(undefined);
  };

  private onSilentRenewError = (error: Error) => {
    console.error('An error occurred while renewing silently the access token: ', error);
  };
}
