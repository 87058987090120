import { ImporterStore } from '@shared/services/stores';
import { find } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  AppImportSessionSourceDataViewModel,
  ImportSessionSourceDataViewModel
} from './ImportSessionSourceDataViewModel';

export interface ImportSessionSourceDataScreenViewModel {
  readonly data: IPromiseBasedObservable<ImportSessionSourceDataViewModel>;
}

export class AppImportSessionSourceDataScreenViewModel implements ImportSessionSourceDataScreenViewModel {
  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _sessionId: string,
    private readonly _label: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<ImportSessionSourceDataViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<ImportSessionSourceDataViewModel> {
    const session = await this._importSessionStore.getImportSession(this._sessionId, true);

    const data = find(session.data, { label: this._label })!;
    const file = find(session.expectedFiles, { label: this._label });
    const transformation = find(session.transformations, {
      label: this._label
    });

    return new AppImportSessionSourceDataViewModel(data, file, transformation);
  }
}
