import { ContentDefinition_MasterContentDefinition as PBMasterContentDefinition } from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { Memoize } from 'fast-typescript-memoize';
import { ContentWorkloadLevel } from '../types';
import { contentWorkloadLevelFromProtobuf } from '../types/EnumConversion';
import { ContentAttachment, ContentAttachmentModel } from './ContentAttachment';
import { ContentStep, ContentStepModel } from './ContentStep';

export interface MasterContentDefinitionModel {
  readonly id: string;
  readonly notes: string;
  readonly attachmentsList: ContentAttachmentModel[];
  readonly stepsList: ContentStepModel[];
  readonly workload: ContentWorkloadLevel;
}

export class MasterContentDefinition implements MasterContentDefinitionModel {
  private _pb: PBMasterContentDefinition;

  constructor(pb: PBMasterContentDefinition) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  get notes(): string {
    return this._pb.notes;
  }

  @Memoize()
  get attachmentsList(): ContentAttachmentModel[] {
    return this._pb.attachments.map((a) => new ContentAttachment(a));
  }

  @Memoize()
  get stepsList(): ContentStepModel[] {
    return this._pb.steps.map((s) => new ContentStep(s));
  }

  get workload(): ContentWorkloadLevel {
    return contentWorkloadLevelFromProtobuf(this._pb.workload);
  }
}
