import {
  AnswerChoice as PBAnswerChoice,
  LocalizedText as PBLocalizedText,
  OnboardingQuestion as PBOnboardingQuestion
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { action, computed, makeObservable } from 'mobx';
import {
  EditableModelEx,
  EditableStringProperty,
  EditableValuePropertyEx,
  FullyEditableListProperty
} from '../../editables';
import { OnboardingQuestionKind } from '../../types';
import { protobufFromOnboardingQuestionKind } from '../../types/EnumConversion';
import { OnboardingQuestion, OnboardingText, QuestionChoice } from '../interfaces';
import { EditableOnboardingText } from './EditableOnboardingText';
import { EditableQuestionChoice } from './EditableQuestionChoice';

export class EditableOnboardingQuestion extends EditableModelEx<PBOnboardingQuestion> implements OnboardingQuestion {
  private _templateName: EditableStringProperty<PBOnboardingQuestion>;
  private _description: FullyEditableListProperty<
    PBLocalizedText,
    OnboardingText,
    EditableOnboardingText,
    PBOnboardingQuestion
  >;
  private _kind: EditableValuePropertyEx<OnboardingQuestionKind, PBOnboardingQuestion>;
  private _choices: FullyEditableListProperty<
    PBAnswerChoice,
    QuestionChoice,
    EditableQuestionChoice,
    PBOnboardingQuestion
  >;
  private _isRequired: EditableValuePropertyEx<boolean, PBOnboardingQuestion>;
  private _dependantQuestionName: EditableStringProperty<PBOnboardingQuestion>;
  private _dependantQuestionAnswer: EditableStringProperty<PBOnboardingQuestion>;
  private _isHiddenWhenDependant: EditableValuePropertyEx<boolean, PBOnboardingQuestion>;

  constructor(
    private readonly _originalQuestion: OnboardingQuestion,
    isNew = false
  ) {
    super(_originalQuestion.toProtobuf(), isNew);

    makeObservable(this);

    this._templateName = this.addStringField(_originalQuestion.templateName, (pb, value) => (pb.templateName = value), {
      trim: true
    });
    this._description = this.addEditableListField(
      _originalQuestion.description.map((d) => new EditableOnboardingText(d)),
      (pb, values) => (pb.localizedDescriptions = values)
    );
    this._kind = this.addValueField(
      _originalQuestion.kind,
      (pb, value) => (pb.answerKind = protobufFromOnboardingQuestionKind(value))
    );
    this._choices = this.addEditableListField(
      _originalQuestion.choices.map((c) => new EditableQuestionChoice(c)),
      (pb, values) => (pb.choices = values)
    );
    this._isRequired = this.addValueField(_originalQuestion.isRequired, (pb, value) => (pb.isRequired = value));
    this._dependantQuestionName = this.addStringField(
      _originalQuestion.dependantQuestionName,
      (pb, value) => (pb.dependantQuestionName = value)
    );
    this._dependantQuestionAnswer = this.addStringField(
      _originalQuestion.dependantQuestionAnswer,
      (pb, value) => (pb.dependantQuestionAnswer = value)
    );
    this._isHiddenWhenDependant = this.addValueField(
      _originalQuestion.isHiddenWhenDependant,
      (pb, value) => (pb.isHiddenWhenDependant = value)
    );
  }

  get id(): string {
    return this._originalQuestion.id;
  }

  get configId(): string {
    return this._originalQuestion.configId;
  }

  @computed
  get templateName(): string {
    return this._templateName.value;
  }

  set templateName(value: string) {
    this._templateName.value = value;
  }

  get isCustomized(): boolean {
    return this._originalQuestion.isCustomized;
  }

  @computed
  get description(): OnboardingText[] {
    return this._description.values;
  }

  @computed
  get editableDescription(): EditableOnboardingText[] {
    return this._description.values;
  }

  @computed
  get kind(): OnboardingQuestionKind {
    return this._kind.value;
  }

  set kind(value: OnboardingQuestionKind) {
    this._kind.value = value;
  }

  @computed
  get choices(): QuestionChoice[] {
    return this._choices.values;
  }

  @computed
  get editableChoices(): EditableQuestionChoice[] {
    return this._choices.values;
  }

  @action
  addChoice() {
    this._choices.addItem(EditableQuestionChoice.createNew(this._choices.values.length + 1));
  }

  @computed
  get isRequired() {
    return this._isRequired.value;
  }

  set isRequired(value: boolean) {
    this._isRequired.value = value;
  }

  @computed
  get dependantQuestionName() {
    return this._dependantQuestionName.value;
  }

  set dependantQuestionName(value: string) {
    this._dependantQuestionName.value = value;
  }

  @computed
  get dependantQuestionAnswer() {
    return this._dependantQuestionAnswer.value;
  }

  set dependantQuestionAnswer(value: string) {
    this._dependantQuestionAnswer.value = value;
  }

  @computed
  get isHiddenWhenDependant() {
    return this._isHiddenWhenDependant.value;
  }

  set isHiddenWhenDependant(value: boolean) {
    this._isHiddenWhenDependant.value = value;
  }

  get isDependantLocked() {
    return this._originalQuestion.isDependantLocked;
  }

  get answer() {
    return this._originalQuestion.answer;
  }

  get previousAnswers() {
    return this._originalQuestion.previousAnswers;
  }

  get lastYearAnswer() {
    return this._originalQuestion.lastYearAnswer;
  }
}
