export interface TimeFormats {
  doubleDigits: string;
  singleDigit: string;
  compact: string;
}

export const EnglishTimeFormatStrings: TimeFormats = {
  doubleDigits: 'hh:mm a',
  singleDigit: 'h:mm a',
  compact: 'h'
};

export const FrenchTimeFormatStrings: TimeFormats = {
  doubleDigits: 'HH:mm',
  singleDigit: 'H:mm',
  compact: 'H'
};
