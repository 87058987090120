export interface DashboardMetricViewsStrings {
  accountsSectionHeader: string;
  activeStudentsHeader: string;
  activeStudentsFooter: string;
  activeTeachersHeader: string;
  activeTeachersFooter: string;
  comingSoon: string;
  connectedParentMessage: string;
  connectedParentTitle: string;
  connectedStudentMessage: string;
  connectedStudentTitle: string;
  errorMessage: string;
  loadingMessage: string;
  openStudyoButton: string;
}

export const EnglishDashboardMetricViewsStrings: DashboardMetricViewsStrings = {
  accountsSectionHeader: 'Accounts',
  activeStudentsHeader: 'Active Students',
  activeStudentsFooter: 'Students who have interacted with at least one task (completed, created, consulted)',
  activeTeachersHeader: 'Active Teachers',
  activeTeachersFooter: 'Teachers who have published at least one task',
  comingSoon: 'Coming Soon...',
  connectedParentMessage:
    'You are currently logged into Insights, the Studyo management platform (insights.studyo.app). Planners can be found at ',
  connectedParentTitle: "Looking for your child's planner?",
  connectedStudentMessage:
    'You are currently logged into Insights, the Studyo management platform (insights.studyo.app). Your planner can be found at ',
  connectedStudentTitle: 'Looking for your Studyo planner?',
  errorMessage: 'An error occurred while loading the data. Please reload.',
  loadingMessage: 'Loading data. Please wait...',
  openStudyoButton: 'Open Studyo'
};

export const FrenchDashboardMetricViewsStrings: DashboardMetricViewsStrings = {
  accountsSectionHeader: 'Comptes',
  activeStudentsHeader: 'Élèves actifs',
  activeStudentsFooter: 'Élèves ayant interagi avec au moins une tâche (compléter, créer, consulter)',
  activeTeachersHeader: 'Enseignants actifs',
  activeTeachersFooter: 'Enseignants ayant publié au moins une tâche',
  comingSoon: 'Disponible sous peu...',
  connectedParentMessage:
    'Vous être présentement dans Insights, la plateforme de gestion pour Studyo (insights.studyo.app). Les agendas sont disponibles à ',
  connectedParentTitle: 'Cherchez-vous l’agenda de votre enfant?',
  connectedStudentMessage:
    'Tu es présentement dans Insights, la plateforme de gestion pour Studyo (insights.studyo.app). Ton agenda est disponible à ',
  connectedStudentTitle: 'Cherches-tu ton agenda Studyo?',
  errorMessage: 'Une erreur de chargement a eu lieu. Merci de recharger.',
  loadingMessage: 'Chargement des données. Merci de patienter...',
  openStudyoButton: 'Ouvrir Studyo'
};
