import {
  Account as PBAccount,
  AccountProfile as PBAccountProfile,
  AccountSettings as PBAccountSettings
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_account_pb';
import _ from 'lodash';
import { action, computed, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import {
  EditableChildPropertyEx,
  EditableStringArrayProperty,
  EditableStringProperty,
  EditableValuePropertyEx
} from '../editables';
import { Color, Role } from '../types';
import { protobufFromRole } from '../types/EnumConversion';
import { Account, AccountModel } from './Account';
import { AccountProfileModel } from './AccountProfile';
import { AccountSettingsModel } from './AccountSettings';
import { EditableAccountProfile } from './EditableAccountProfile';
import { EditableAccountSettings } from './EditableAccountSettings';
import { TrackedEditableModel } from './TrackedEditableModel';

export class EditableAccount extends TrackedEditableModel<PBAccount, AccountModel> implements AccountModel {
  private _role: EditableValuePropertyEx<Role, PBAccount>;
  private _firstName: EditableStringProperty<PBAccount>;
  private _lastName: EditableStringProperty<PBAccount>;
  private _email: EditableStringProperty<PBAccount>;
  private _managedId: EditableStringProperty<PBAccount>;
  private _language: EditableStringProperty<PBAccount>;
  private _userId: EditableStringProperty<PBAccount>;
  private _profile: EditableChildPropertyEx<PBAccountProfile, AccountProfileModel, EditableAccountProfile, PBAccount>;
  private _settings: EditableChildPropertyEx<
    PBAccountSettings,
    AccountSettingsModel,
    EditableAccountSettings,
    PBAccount
  >;
  private _isAdmin: EditableValuePropertyEx<boolean, PBAccount>;
  private _gradeLevel: EditableStringProperty<PBAccount>;
  private _optedOutOfParentReports: EditableValuePropertyEx<boolean, PBAccount>;
  private _isLocked: EditableValuePropertyEx<boolean, PBAccount>;
  private _childrenIds: EditableStringArrayProperty<PBAccount>;
  private _childrenPendingIds: EditableStringArrayProperty<PBAccount>;
  private _preferredScheduleTag: EditableStringProperty<PBAccount>;

  static createNew(configId: string, role: Role) {
    const pb = new PBAccount();
    // Id is set by backend
    pb.configId = configId;
    pb.role = protobufFromRole(role);
    pb.settings = new PBAccountSettings();
    pb.managedIdentifier = uuidv4();

    // All other values are left empty.
    return new EditableAccount(new Account(pb), true);
  }

  static createNewFrom(original: AccountModel, configId: string) {
    if (original.isDeleted) {
      throw new Error('Cannot create a new account from a deleted account.');
    }

    const pb = new PBAccount();

    pb.managedIdentifier = original.managedIdentifier;
    pb.role = protobufFromRole(original.role);
    pb.firstName = original.firstName;
    pb.lastName = original.lastName;
    pb.email = original.email;
    pb.language = original.language;
    pb.isAdmin = original.isAdmin;
    pb.optedOutOfParentReports = original.optedOutOfParentReports;

    // We do not keep what relates to a different configuration.
    pb.configId = configId;
    pb.settings = new PBAccountSettings();

    // All other values are left empty.
    return new EditableAccount(new Account(pb), true);
  }

  constructor(
    private readonly _originalAccount: AccountModel,
    isNew = false
  ) {
    super(_originalAccount, (a, v) => (a.manualChanges = v), isNew);
    makeObservable(this);

    this._role = this.addValueField(_originalAccount.role, (pb, value) => (pb.role = protobufFromRole(value)));
    this._firstName = this.addStringField(_originalAccount.firstName, (pb, value) => (pb.firstName = value), {
      trim: true
    });
    this._lastName = this.addStringField(_originalAccount.lastName, (pb, value) => (pb.lastName = value), {
      trim: true
    });
    this._email = this.addStringField(_originalAccount.email, (pb, value) => (pb.email = value), {
      trim: true
    });
    this._managedId = this.addStringField(
      _originalAccount.managedIdentifier,
      (pb, value) => (pb.managedIdentifier = value),
      {
        trim: true
      }
    );
    this._language = this.addStringField(_originalAccount.language, (pb, value) => (pb.language = value), {
      trim: true
    });
    this._userId = this.addStringField(_originalAccount.userId, (pb, value) => (pb.userId = value), {
      trim: true
    });
    this._profile = this.addChildField(
      _originalAccount.profile,
      (model) => new EditableAccountProfile(model, false),
      (pb, value) => {
        pb.profile = value;
      }
    );
    this._settings = this.addChildField(
      _originalAccount.settings,
      (model) => new EditableAccountSettings(model, false),
      (pb, value) => {
        pb.settings = value;
      }
    );
    this._isAdmin = this.addValueField(_originalAccount.isAdmin, (pb, value) => (pb.isAdmin = value));
    this._gradeLevel = this.addStringField(_originalAccount.gradeLevel, (pb, value) => (pb.gradeLevel = value));
    this._optedOutOfParentReports = this.addValueField(
      _originalAccount.optedOutOfParentReports,
      (pb, value) => (pb.optedOutOfParentReports = value)
    );
    this._isLocked = this.addValueField(_originalAccount.isLocked, (pb, value) => (pb.isLocked = value));
    this._childrenIds = this.addStringArrayField(
      _originalAccount.childrenAccountIds,
      (pb, values) => (pb.childrenAccountIds = values),
      {
        trim: true
      }
    );
    this._childrenPendingIds = this.addStringArrayField(
      _originalAccount.childrenAccountPendingVerificationIds,
      (pb, values) => (pb.childrenAccountPendingVerificationIds = values),
      {
        trim: true
      }
    );
    this._preferredScheduleTag = this.addStringField(
      _originalAccount.preferredScheduleTag,
      (pb, value) => (pb.preferredScheduleTag = value)
    );
  }

  get id() {
    return this._originalAccount.id;
  }

  get configId() {
    return this._originalAccount.configId;
  }

  get isAutomatchPendingEmailVerification() {
    return this._originalAccount.isAutomatchPendingEmailVerification;
  }

  get isDeleted() {
    return this._originalAccount.isDeleted;
  }

  get syncToken() {
    return this._originalAccount.syncToken;
  }

  get allowNonVerifiedEmailAutomatch() {
    return this._originalAccount.allowNonVerifiedEmailAutomatch;
  }

  @computed
  get role() {
    return this._role.value;
  }

  set role(value: Role) {
    this._role.value = value;
  }

  @computed
  get firstName() {
    return this._firstName.value;
  }

  set firstName(value: string) {
    this._firstName.value = value;

    this.addManualChanges('firstName');
  }

  @computed
  get lastName() {
    return this._lastName.value;
  }

  set lastName(value: string) {
    this._lastName.value = value;

    this.addManualChanges('lastName');
  }

  @computed
  get email() {
    return this._email.value;
  }

  set email(value: string) {
    this._email.value = value;

    this.addManualChanges('email');
  }

  @computed
  get managedIdentifier() {
    return this._managedId.value;
  }

  set managedIdentifier(value: string) {
    this._managedId.value = value;

    this.addManualChanges('managedIdentifier');
  }

  @computed
  get language() {
    return this._language.value;
  }

  set language(value: string) {
    this._language.value = value;

    this.addManualChanges('language');
  }

  @computed
  get userId() {
    return this._userId.value;
  }

  set userId(value: string) {
    this._userId.value = value;

    this.addManualChanges('userId');
  }

  @computed
  get childrenAccountIds() {
    return this._childrenIds.value;
  }

  set childrenAccountIds(values: string[]) {
    this._childrenIds.value = values;

    this.addManualChanges('childrenAccountIds');
  }

  @computed
  get childrenAccountPendingVerificationIds() {
    return this._childrenPendingIds.value;
  }

  set childrenAccountPendingVerificationIds(values: string[]) {
    this._childrenPendingIds.value = values;

    this.addManualChanges('childrenAccountPendingVerificationIds');
  }

  @computed
  get settings() {
    return this._settings.value;
  }

  @action
  getEditableSettings() {
    return this._settings.getEditableValue();
  }

  @computed
  get profile() {
    return this._profile.value;
  }

  @action
  getEditableProfile() {
    return this._profile.getEditableValue();
  }

  @computed
  get isAdmin() {
    return this._isAdmin.value;
  }

  set isAdmin(value: boolean) {
    this._isAdmin.value = value;

    this.addManualChanges('isAdmin');
  }

  @computed
  get gradeLevel() {
    return this._gradeLevel.value;
  }

  set gradeLevel(value: string) {
    this._gradeLevel.value = value;

    this.addManualChanges('gradeLevel');
  }

  @computed
  get optedOutOfParentReports(): boolean {
    return this._optedOutOfParentReports.value;
  }

  set optedOutOfParentReports(value: boolean) {
    this._optedOutOfParentReports.value = value;

    this.addManualChanges('optedOutOfParentReports');
  }

  @computed
  get isLocked(): boolean {
    return this._isLocked.value;
  }

  set isLocked(value: boolean) {
    this._isLocked.value = value;

    // Not concerned by manual changes, it's obviously manual.
  }

  @computed
  get preferredScheduleTag(): string {
    return this._preferredScheduleTag.value;
  }

  set preferredScheduleTag(value: string) {
    this._preferredScheduleTag.value = value;
  }

  // Some commodity properties are required by the model.
  // TODO: Remove and favor "Utils".
  @computed
  get selectedSectionIds() {
    // We know it's not null here.
    return this._settings.value.selectedSectionIds;
  }

  @computed
  get customizedSectionColorsBySectionId(): Record<string, Color> {
    // Code copied from Account
    return _.chain(this._settings.value.sectionColors)
      .map((sc) => ({ sectionId: sc.sectionId, color: sc.color }))
      .keyBy('sectionId')
      .mapValues((o) => o.color)
      .value();
  }

  get canImpersonate() {
    // Roles can't change.
    return this._originalAccount.canImpersonate;
  }

  @computed
  get applicableAutoEnrollTags(): string[] {
    const gradeLevel = this.gradeLevel;

    if (gradeLevel.length === 0) {
      return [];
    }

    return [`gradeLevel=${gradeLevel}`];
  }

  @computed
  get visibleEmail(): string {
    return this.profile.publicEmail.length > 0 ? this.profile.publicEmail : this.email;
  }
}
