export interface ConfirmationDialogStrings {
  defaultTitle: string;
  defaultOkButtonCaption: string;
  defaultCancelButtonCaption: string;
}

export const EnglishConfirmationDialogStrings: ConfirmationDialogStrings = {
  defaultTitle: 'Confirmation',
  defaultOkButtonCaption: 'Yes',
  defaultCancelButtonCaption: 'No'
};

export const FrenchConfirmationDialogStrings: ConfirmationDialogStrings = {
  defaultTitle: 'Confirmation',
  defaultOkButtonCaption: 'Oui',
  defaultCancelButtonCaption: 'Non'
};
