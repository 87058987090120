import { Storage } from '@shared/services';
import { Locale as DateLocale, setDefaultOptions } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import { EnglishLocalizedStrings, FrenchLocalizedStrings, LocalizedStrings } from '../strings/LocalizedStrings';

// TODO (TASK 1098): Find a way to dynamically load the resources

export const LocaleKeys = ['en', 'fr'] as const;
export type Locale = (typeof LocaleKeys)[number];
export const AllLocales: Locale[] = ['en', 'fr'];

export const LocalePreferenceKey = 'locale';

export interface LocalizationService {
  readonly currentLocale: Locale;
  readonly browserLocale: Locale;
  readonly localizedStrings: LocalizedStrings;

  initialize: () => Promise<void>;
  setDefaultLocale: (locale: Locale) => void;
  setCurrentLocale: (locale: Locale) => void;

  // Accessing this directly can be useful when dealing with a known school or account language.
  // (e.g. Creating a new French school while the locale is English)
  resolveStrings: (locale: Locale) => LocalizedStrings;
}

export abstract class BaseLocalizationService implements LocalizationService {
  @observable private _currentDefaultLocale: Locale = 'en';
  @observable private _currentLocale: Locale | undefined;

  protected constructor(private readonly _localStorage: Storage) {
    makeObservable(this);

    autorun(() => {
      setDefaultOptions({ locale: this.currentDateLocale });
    });
  }

  @computed
  private get currentDateLocale(): DateLocale {
    switch (this.currentLocale) {
      case 'en':
        return enUS;
      case 'fr':
        return fr;
    }
  }

  @computed
  get currentLocale(): Locale {
    return this._currentLocale ?? this._currentDefaultLocale;
  }

  get browserLocale(): Locale {
    return this.normalizeLocale(window.navigator.language);
  }

  @computed
  get localizedStrings(): LocalizedStrings {
    return this.resolveStrings(this.currentLocale);
  }

  @action
  setDefaultLocale(locale: string) {
    this._currentDefaultLocale = this.normalizeLocale(locale);
  }

  @action
  setCurrentLocale(locale: string) {
    const normalizedLocale = this.normalizeLocale(locale);
    this._currentLocale = normalizedLocale;

    void this._localStorage.set<Locale>(LocalePreferenceKey, normalizedLocale);
  }

  async initialize() {
    const storedLocale = await this._localStorage.get<Locale>(LocalePreferenceKey);

    if (storedLocale != null) {
      runInAction(() => (this._currentLocale = storedLocale));
    }
  }

  private normalizeLocale(locale: string): Locale {
    const normalizedLocale = locale.substring(0, 2).toLowerCase();

    if (normalizedLocale === 'en') {
      return 'en';
    }

    if (normalizedLocale === 'fr') {
      return 'fr';
    }

    console.warn(`Unsupported locale. Not changing current locale.`);
    return this._currentDefaultLocale;
  }

  resolveStrings(locale: Locale): LocalizedStrings {
    switch (locale) {
      case 'en':
        return EnglishLocalizedStrings;

      case 'fr':
        return FrenchLocalizedStrings;
    }
  }
}
