import { RouteNameInfo } from '@shared/web/services';

export const RouteQueryParamNames = {
  sourceConfigId: 'sourceConfigId'
};

export const RouteParamNames = {
  configId: 'configId',
  teacherId: 'teacherId',
  studentId: 'studentId',
  sectionId: 'sectionId',
  sessionId: 'sessionId',
  automatedImportId: 'automatedImportId',
  automatedImportResultIndex: 'resultIndex',
  label: 'label',
  externalAccountId: 'externalAccountId',
  processId: 'processId',
  processName: 'processName',
  templateName: 'templateName',
  sourceConfigId: 'sourceConfigId'
};

export const RouteTemplates = {
  /**
   * @example `/`
   */
  landing: '/',
  /**
   * @example `/login`
   */
  login: '/login',
  /**
   * @example `/logout`
   */
  logout: '/logout',
  /**
   * @example `/new`
   */
  new: '/new',
  /**
   * @example `/new/123`
   */
  newFrom: `/new/:sourceConfigId`,
  /**
   * @example `/configs`
   */
  schoolConfigurations: '/configs',
  /**
   * @example `/onboarding`
   */
  onboardingDashboard: '/onboarding',
  /**
   * @example `/onboardingcomments`
   */
  onboardingDashboardComments: '/onboardingcomments',
  /**
   * @example `/users`
   */
  users: '/users',
  /**
   * @example `/settings`
   */
  settings: '/settings',
  /**
   * @example `/teamsconsent`
   */
  teamsConsent: '/teamsconsent',
  /**
   * @example `/blackbaud/callback`
   */
  blackbaudConsent: '/blackbaud/callback',
  /**
   * @example `/noschoolconfigurations`
   */
  noSchoolConfigurations: '/noschoolconfigurations',
  /**
   * @example `/configs/12345`
   */
  schoolRoot: `/configs/:configId`,

  // METRICS
  /**
   * @param `configId`
   * @example `/configs/12345/metrics`
   * @redirect dashboard
   */
  metricsRoot: `/configs/:configId/metrics`,
  /**
   * @param `configId`
   * @example `/configs/12345/metrics/dashboard`
   */
  dashboard: `/configs/:configId/metrics/dashboard`,
  /**
   * @param `configId`
   * @example `/configs/12345/metrics/workload`
   */
  workload: `/configs/:configId/metrics/workload`,
  /**
   * @param `configId`
   * @example `/configs/12345/metrics/teachers`
   */
  teachers: `/configs/:configId/metrics/teachers`,
  /**
   * @param `configId`
   * @param `teacherId`
   * @example `/configs/12345/metrics/teachers/67890`
   */
  teacherDetails: `/configs/:configId/metrics/teachers/:teacherId`,
  /**
   * @param `configId`
   * @example `/configs/12345/metrics/students`
   */
  students: `/configs/:configId/metrics/students`,
  /**
   * @param `configId`
   * @param `studentId`
   * @example `/configs/12345/metrics/students/67890`
   */
  studentDetails: `/configs/:configId/metrics/students/:studentId`,
  /**
   * @param `configId`
   * @example `/configs/12345/metrics/sections`
   */
  sections: `/configs/:configId/metrics/sections`,
  /**
   * @param `configId`
   * @param `sectionId`
   * @example `/configs/12345/metrics/sections/67890`
   */
  sectionDetails: `/configs/:configId/metrics/sections/:sectionId`,

  // MANAGE
  /**
   * @param `configId`
   * @example `/configs/12345/manage`
   * @redirect to general
   */
  manageRoot: `/configs/:configId/manage`,
  /**
   * @param `configId`
   * @example `/configs/12345/manage/general`
   */
  manageGeneral: `/configs/:configId/manage/general`,
  /**
   * @param `configId`
   * @example `/configs/12345/manage/onboarding`
   */
  manageOnboarding: `/configs/:configId/manage/onboarding`,
  /**
   * @param `configId`
   * @param `processName`
   * @example `/configs/12345/manage/onboarding/main`
   *
   * This route is not navigated to within Insights, but we still allow external links to it
   * in case we want to narrow on a single process. Normally, schools will only have one process.
   */
  manageOnboardingProcess: `/configs/:configId/manage/onboarding/:processName`,
  /**
   * @param `configId`
   * @param `processName`
   * @param `templateName`
   * @example `/configs/12345/manage/onboarding/main/intro`
   */
  manageOnboardingStep: `/configs/:configId/manage/onboarding/:processName/:templateName`,
  /**
   * @param `configId`
   * @example `/configs/12345/manage/sections`
   */
  manageSections: `/configs/:configId/manage/sections`,
  /**
   * @param `configId`
   * @example `/configs/12345/manage/teachers`
   */
  manageTeachers: `/configs/:configId/manage/teachers`,
  /**
   * @param `configId`
   * @example `/configs/12345/manage/students`
   */
  manageStudents: `/configs/:configId/manage/students`,
  /**
   * @param `configId`
   * @example `/configs/12345/manage/parents`
   */
  manageParents: `/configs/:configId/manage/parents`,
  /**
   * @param `configId`
   * @example `/configs/12345/manage/staff`
   */
  manageStaff: `/configs/:configId/manage/staff`,
  /**
   * @param `configId`
   * @example `/configs/12345/manage/calendar`
   */
  manageCalendar: `/configs/:configId/manage/calendar`,

  /**
   * @param `configId`
   * @example `/configs/12345/manage/externalaccounts`
   */
  manageExternalAccounts: `/configs/:configId/manage/externalaccounts`,
  /**
   * @param `configId`
   * @param `externalAccountId`
   * @example `/configs/12345/manage/externalaccounts/23456`
   */
  manageExternalAssociations: `/configs/:configId/manage/externalaccounts/associations/:externalAccountId`,

  /**
   * @param `configId`
   * @example `/configs/12345/manage/import`
   */
  manageImportSessions: `/configs/:configId/manage/import`,
  /**
   * @param `configId`
   * @param `sessionId`
   * @example `/configs/12345/manage/import/67890`
   */
  manageImportSessionDetails: `/configs/:configId/manage/import/:sessionId`,
  /**
   * @param `configId`
   * @param `sessionId`
   * @param `label`
   * @example `/configs/12345/manage/import/67890/data/source/Q`
   */
  manageImportSessionSourceData: `/configs/:configId/manage/import/:sessionId/source/:label`,
  /**
   * @param `configId`
   * @param `sessionId`
   * @param `label`
   * @example `/configs/12345/manage/import/67890/data/Q`
   */
  manageImportData: `/configs/:configId/manage/import/:sessionId/data/:label`,
  /**
   * @param `configId`
   * @param `sessionId`
   * @param `label`
   * @example `/configs/12345/manage/import/67890/transformations/abcde`
   */
  manageImportSessionTransformation: `/configs/:configId/manage/import/:sessionId/transformations/:label`,

  /**
   * @param `configId`
   * @param `sessionId`
   * @param `automatedImportId`
   * @example `/configs/12345/manage/import/67890/automated/abcde`
   */
  manageAutomatedImportDetails: `/configs/:configId/manage/import/:sessionId/automated/:automatedImportId`,
  /**
   * @param `configId`
   * @param `sessionId`
   * @param `automatedImportId`
   * @package 'resultIndex'
   * @example `/configs/12345/manage/import/67890/automated/abcde/result/1`
   */
  manageAutomatedImportResultDetails: `/configs/:configId/manage/import/:sessionId/automated/:automatedImportId/result/:resultIndex`,
  /**
   * @param `configId`
   * @example `/configs/12345/manage/features`
   */
  manageFeatures: `/configs/:configId/manage/features`,

  /**
   * @param `configId`
   * @param `teacherId`
   * @example `/configs/12345/embedded/workload/67890`
   */
  embeddedWorkloadManager: `/configs/:configId/embedded/workload/:teacherId`
};

export const RouteNames: RouteNameInfo[] = [
  { routeTemplate: RouteTemplates.landing, localizationKey: 'home' },
  { routeTemplate: RouteTemplates.dashboard, localizationKey: 'dashboard' },
  { routeTemplate: RouteTemplates.workload, localizationKey: 'workload' },
  { routeTemplate: RouteTemplates.login, localizationKey: 'login' },
  { routeTemplate: RouteTemplates.logout, localizationKey: 'logout' },
  { routeTemplate: RouteTemplates.new, localizationKey: 'new' },
  { routeTemplate: RouteTemplates.newFrom, localizationKey: 'new' },
  {
    routeTemplate: RouteTemplates.teacherDetails,
    localizationKey: 'teacherDetails'
  },
  { routeTemplate: RouteTemplates.teachers, localizationKey: 'teachers' },
  {
    routeTemplate: RouteTemplates.studentDetails,
    localizationKey: 'studentDetails'
  },
  { routeTemplate: RouteTemplates.students, localizationKey: 'students' },
  {
    routeTemplate: RouteTemplates.sectionDetails,
    localizationKey: 'sectionDetails'
  },
  { routeTemplate: RouteTemplates.sections, localizationKey: 'sections' },
  {
    routeTemplate: RouteTemplates.schoolConfigurations,
    localizationKey: 'schoolConfigurations'
  },
  {
    routeTemplate: RouteTemplates.onboardingDashboard,
    localizationKey: 'onboardingDashboard'
  },
  {
    routeTemplate: RouteTemplates.onboardingDashboardComments,
    localizationKey: 'onboardingDashboardComments'
  },
  { routeTemplate: RouteTemplates.users, localizationKey: 'users' },
  { routeTemplate: RouteTemplates.settings, localizationKey: 'settings' },
  {
    routeTemplate: RouteTemplates.teamsConsent,
    localizationKey: 'teamsConsent'
  },
  {
    routeTemplate: RouteTemplates.blackbaudConsent,
    localizationKey: 'blackbaudConsent'
  },
  {
    routeTemplate: RouteTemplates.manageGeneral,
    localizationKey: 'manageGeneral'
  },
  {
    routeTemplate: RouteTemplates.manageOnboarding,
    localizationKey: 'manageOnboarding'
  },
  {
    routeTemplate: RouteTemplates.manageOnboardingProcess,
    localizationKey: 'manageOnboarding'
  },
  {
    routeTemplate: RouteTemplates.manageOnboardingStep,
    localizationKey: 'manageOnboardingStep'
  },
  {
    routeTemplate: RouteTemplates.manageSections,
    localizationKey: 'manageSections'
  },
  {
    routeTemplate: RouteTemplates.manageTeachers,
    localizationKey: 'manageTeachers'
  },
  {
    routeTemplate: RouteTemplates.manageStudents,
    localizationKey: 'manageStudents'
  },
  {
    routeTemplate: RouteTemplates.manageParents,
    localizationKey: 'manageParents'
  },
  { routeTemplate: RouteTemplates.manageStaff, localizationKey: 'manageStaff' },
  {
    routeTemplate: RouteTemplates.manageCalendar,
    localizationKey: 'manageCalendar'
  },
  {
    routeTemplate: RouteTemplates.manageExternalAccounts,
    localizationKey: 'manageExternalAccounts'
  },
  {
    routeTemplate: RouteTemplates.manageFeatures,
    localizationKey: 'manageFeatures'
  },
  {
    routeTemplate: RouteTemplates.manageExternalAssociations,
    localizationKey: 'manageExternalAssociations'
  },
  {
    routeTemplate: RouteTemplates.manageImportSessions,
    localizationKey: 'manageImportSessions'
  },
  {
    routeTemplate: RouteTemplates.manageImportSessionDetails,
    localizationKey: 'manageImportSessionDetails'
  },
  {
    routeTemplate: RouteTemplates.manageImportSessionTransformation,
    localizationKey: 'manageImportSessionTransformation'
  },
  {
    routeTemplate: RouteTemplates.manageImportSessionSourceData,
    localizationKey: 'manageImportSessionSourceData'
  },
  {
    routeTemplate: RouteTemplates.manageImportData,
    localizationKey: 'manageImportData'
  },
  {
    routeTemplate: RouteTemplates.manageAutomatedImportDetails,
    localizationKey: 'manageAutomatedImportDetails'
  },
  {
    routeTemplate: RouteTemplates.manageAutomatedImportResultDetails,
    localizationKey: 'manageAutomatedImportResultDetails'
  }
];
