import { AlertService } from '@insights/services';
import { OnboardingTemplateKind } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface RenameOrCopyOnboardingTemplateViewModel {
  readonly currentTemplateName: string;
  newTemplateName: string;

  readonly message: string;
  readonly canConfirm: boolean;
}

export interface RenameOrCopyOnboardingTemplateDialogViewModel {
  readonly isCopy: boolean;
  readonly dialogTitle: string;
  readonly data: IPromiseBasedObservable<RenameOrCopyOnboardingTemplateViewModel>;
  readonly canConfirm: boolean;

  confirm(): void;
  cancel(): void;
}

class AppRenameOrCopyOnboardingTemplateViewModel implements RenameOrCopyOnboardingTemplateViewModel {
  @observable private _newTemplateName = '';
  @observable private _message = '';
  private _reservedTemplateNames: string[];

  constructor(
    private readonly _localizationService: LocalizationService,
    readonly currentTemplateName: string,
    reservedTemplateNames: string[]
  ) {
    makeObservable(this);
    this._reservedTemplateNames = reservedTemplateNames.map((name) => name.toLowerCase());
  }

  @computed
  get newTemplateName() {
    return this._newTemplateName;
  }

  set newTemplateName(value: string) {
    this._newTemplateName = value;

    if (this._reservedTemplateNames.includes(value.toLowerCase())) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      this._message = strings.templateNameAlreadyExistsMessage;
    } else {
      this._message = '';
    }
  }

  @computed
  get message() {
    return this._message;
  }

  @computed
  get canConfirm() {
    return this._newTemplateName.length > 0 && this._message.length === 0;
  }
}

export class AppRenameOrCopyOnboardingTemplateDialogViewModel implements RenameOrCopyOnboardingTemplateDialogViewModel {
  @observable private _innerViewModel?: RenameOrCopyOnboardingTemplateViewModel;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _templateKind: OnboardingTemplateKind,
    private readonly _currentName: string,
    private readonly _configId: string,
    private readonly _stepId: string | undefined,
    readonly isCopy: boolean,
    private readonly _onSuccess: (newName?: string) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  get dialogTitle() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;

    switch (this._templateKind) {
      case 'process':
        return this.isCopy ? strings.copyProcessTitle : strings.renameProcessTitle;
      case 'step':
        return this.isCopy ? strings.copyStepTitle : strings.renameStepTitle;
      case 'question':
        return this.isCopy ? strings.copyQuestionTitle : strings.renameQuestionTitle;
    }
  }

  @computed
  get data(): IPromiseBasedObservable<RenameOrCopyOnboardingTemplateViewModel> {
    return fromPromise(this.loadData());
  }

  @computed
  get canConfirm() {
    return this._innerViewModel?.canConfirm ?? false;
  }

  private getTemplateNames(): Promise<string[]> {
    switch (this._templateKind) {
      case 'process':
        return this._onboardingStore.getProcessTemplateNames();
      case 'step':
        return this._onboardingStore.getStepTemplateNames();
      case 'question':
        return this._onboardingStore.getQuestionTemplateNames();
    }
  }

  private async loadData(): Promise<RenameOrCopyOnboardingTemplateViewModel> {
    const templateNames = await this.getTemplateNames();

    const inner = new AppRenameOrCopyOnboardingTemplateViewModel(
      this._localizationService,
      this._currentName,
      templateNames
    );

    return runInAction(() => (this._innerViewModel = inner));
  }

  confirm() {
    if (this._innerViewModel == null) {
      return;
    }

    this._onSuccess(this._innerViewModel.newTemplateName);
  }

  cancel() {
    this._onCancel();
  }
}
