import { ManageableRole } from '@insights/enums';
import { AuthorizationRole } from '@shared/models/types';

export interface AccountStrings {
  roleTitleForManageableRole(role: ManageableRole, capitalize?: boolean): string;
  roleTitleForAuthorizationRole(role: AuthorizationRole): string;
}

export const EnglishAccountStrings: AccountStrings = {
  roleTitleForManageableRole: englishRoleTitleForManageableRole,
  roleTitleForAuthorizationRole: englishRoleTitleForAuthorizationRole
};

export const FrenchAccountStrings: AccountStrings = {
  roleTitleForManageableRole: frenchRoleTitleForManageableRole,
  roleTitleForAuthorizationRole: frenchRoleTitleForAuthorizationRole
};

function englishRoleTitleForManageableRole(role: ManageableRole, capitalize?: boolean): string {
  switch (role) {
    case 'parent':
      return capitalize ? 'Parent' : 'parent';
    case 'student':
      return capitalize ? 'Student' : 'student';
    case 'teacher':
      return capitalize ? 'Teacher' : 'teacher';
    case 'staff':
      return capitalize ? 'Staff' : 'staff';
    default:
      return 'n/a';
  }
}

function frenchRoleTitleForManageableRole(role: ManageableRole, capitalize?: boolean): string {
  switch (role) {
    case 'parent':
      return capitalize ? 'Parent' : 'parent';
    case 'student':
      return capitalize ? 'Élève' : 'élève';
    case 'teacher':
      return capitalize ? 'Enseignant' : 'enseignant';
    case 'staff':
      return capitalize ? 'Membre du personnel' : 'membre du personnel';
    default:
      return 'n.d.';
  }
}

function englishRoleTitleForAuthorizationRole(role: AuthorizationRole): string {
  switch (role) {
    case 'individual':
      return 'Individual';
    case 'parent':
      return 'Parent';
    case 'student':
      return 'Student';
    case 'teacher':
      return 'Teacher';
    case 'admin':
      return 'Administrator';
    case 'super-admin':
      return 'Super Administrator';
    case 'school-staff':
      return 'School Staff';
    case 'studyo-staff':
      return 'Studyo Staff';
    default:
      return 'n/a';
  }
}

function frenchRoleTitleForAuthorizationRole(role: AuthorizationRole): string {
  switch (role) {
    case 'individual':
      return 'Individuel';
    case 'parent':
      return 'Parent';
    case 'student':
      return 'Élève';
    case 'teacher':
      return 'Enseignant';
    case 'admin':
      return 'Administrateur';
    case 'super-admin':
      return 'Super administrateur';
    case 'school-staff':
      return 'Employé école';
    case 'studyo-staff':
      return 'Employé Studyo';
    default:
      return 'n.d.';
  }
}
