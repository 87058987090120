import { SectionModel } from '@shared/models/config';
import { AccountInfo } from './AccountInfo';
import { MaterialTableData } from './MaterialTableData';

export interface SectionInfo extends MaterialTableData {
  readonly section: SectionModel | undefined;
  readonly teachers?: AccountInfo[];
  readonly students?: AccountInfo[];
  readonly isAutoEnrolled?: boolean;
  readonly isLockedAsStudent?: boolean;
}

export function sectionInfoFromModel(section: SectionModel): SectionInfo {
  return { id: section.id, section };
}
