import { ImportDataIncident, InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  Checkbox,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  SxProps,
  ThemeProvider,
  Typography
} from '@mui/material';
import { ImportData, SchemaImportOption, SourceData } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ImportDataIncidentsSelectionScreenProps extends DialogResult<ImportData> {
  sx?: SxProps;
  configId: string;
  sessionId: string;
  importData: ImportData;
  data: SourceData;
  isCompleteData: boolean;
  options?: SchemaImportOption[];
}

export const ImportDataIncidentsSelectionScreen = observer((props: ImportDataIncidentsSelectionScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], configId, sessionId, data, importData, isCompleteData, options, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;
  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createImportDataIncidentsSelection(
      configId,
      sessionId,
      importData,
      data,
      isCompleteData,
      options ?? [],
      onSuccess!,
      onCancel!
    )
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog sx={sx} open={true} maxWidth="md" fullWidth onClose={() => viewModel.cancel()}>
        <DialogTitle>{strings.incidentsSelection}</DialogTitle>

        <DialogContent dividers>
          {viewModel.errors.length === 0 ? (
            <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.text.secondary }}>
              {viewModel.warnings.length === 0 ? strings.noIncident : strings.noIncidentToAllow}
            </Typography>
          ) : (
            <Box>
              <Typography variant="subtitle1">{strings.incidentsToAllowSelectionMessage}</Typography>
              {viewModel.errors.map((incident, i) => (
                <Box key={`incident-checkbox-${i}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={viewModel.isExecuting}
                        checked={viewModel.allowedErrors.includes(incident)}
                        onChange={(_, checked) =>
                          checked ? viewModel.addAllowedIncident(incident) : viewModel.removeAllowedIncident(incident)
                        }
                      />
                    }
                    label={<ImportDataIncident incident={incident} />}
                  />
                </Box>
              ))}
            </Box>
          )}
          {viewModel.warnings.length > 0 && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle1">{strings.otherIncidents}</Typography>
              {viewModel.warnings.map((incident, i) => (
                <ImportDataIncident
                  key={`incident-${i}`}
                  sx={{ my: 1 }}
                  incident={incident}
                  colorMode="no-incident-color"
                />
              ))}
            </Box>
          )}
          {viewModel.options.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">{strings.selectedImportOptions}</Typography>
              {viewModel.options.map((o) => (
                <Box key={`option-${o.name}`} display="flex" flexDirection="row" sx={{ mt: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: '700', minWidth: 200 }}>
                    {o.name}
                  </Typography>
                  <Typography variant="body2">{o.localizedDescription}</Typography>
                </Box>
              ))}
            </Box>
          )}
          {viewModel.isCompleteData ? (
            <Typography sx={{ color: (theme) => theme.palette.error.main }} variant="subtitle1" mt={2}>
              {strings.destructiveOnConfirmation}
            </Typography>
          ) : (
            <Typography variant="subtitle1" mt={2}>
              {strings.destructiveOffConfirmation}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => viewModel.cancel()}>
            {strings.cancel}
          </InsightsButton>
          <InsightsButton
            isSubmit
            showSpinnerOnExecuting
            isExecuting={viewModel.isExecuting}
            onClick={() => void viewModel.import()}
          >
            {strings.importDataButton}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
