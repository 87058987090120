import { LocalizationService } from '@shared/resources/services';
import { MoodleConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface MoodleAccountViewModel extends ExternalAccountEditionViewModel {
  accountName: string;
  serverUrl: string;
  accessToken: string;
}

export class AppMoodleAccountViewModel extends BaseExternalAccountEditionViewModel implements MoodleAccountViewModel {
  @observable private _accountName?: string;
  @observable private _serverUrl?: string;
  @observable private _accessToken?: string;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _moodleStore: MoodleConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    private readonly _originalAccountName: string,
    private readonly _originalServerUrl: string,
    private readonly _originalAccessToken: string
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get accountName() {
    return this._accountName ?? this._originalAccountName;
  }

  set accountName(value: string) {
    this._accountName = value;
    this.onChange();
  }

  @computed
  get serverUrl() {
    return this._serverUrl ?? this._originalServerUrl;
  }

  set serverUrl(value: string) {
    this._serverUrl = value;
    this.onChange();
  }

  @computed
  get accessToken() {
    return this._accessToken ?? this._originalAccessToken;
  }

  set accessToken(value: string) {
    this._accessToken = value;
    this.onChange();
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this._hasChanges) {
      console.error('Applying without changes. Ignoring...');
      this._onSuccess();
      return;
    }

    // The "original" access token is not really the token, but a placeholder.
    // When editing details, the user must repeat the token.
    if (this._accessToken === this._originalAccessToken) {
      this.addError(strings.enterAccessTokenAgain);
      return;
    }

    this.beginApplying();

    try {
      await this._moodleStore.createOrUpdateMoodleAccount(
        this._configId,
        this._externalAccountId,
        this.accountName,
        this.serverUrl,
        this.accessToken
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._accountName = undefined;
    this._serverUrl = undefined;
    this._accessToken = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this._hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
