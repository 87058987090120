import { AccountInfo } from '@insights/models';
import { CustomFilterUtils } from '@insights/utils';
import { SectionTeachersEditionViewModel } from '@insights/viewmodels';
import { Column, MTableToolbar } from '@material-table/core';
import AddIcon from '@mui/icons-material/Add';
import DefaultTeacherIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { Box, colors, Grid2, IconButton, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react-lite';
import moize from 'moize';
import { CSSProperties, PropsWithChildren, useRef } from 'react';
import { SmallTablePageSize, SmallTablePageSizes } from '../../Constants';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { InsightsMaterialTable } from '../InsightsMaterialTable';

const SelectedTableStateKey = 'SectionTeachersEditionSelected';
const AvailableTableStateKey = 'SectionTeachersEditionAvailable';

export interface SectionTeachersEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SectionTeachersEditionViewModel;
}

export const SectionTeachersEdition = observer((props: SectionTeachersEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.sections;
  const materialTableStrings = localizationService.localizedStrings.insights.materialTable;

  const searchableFields = useRef(
    moize((teacher: AccountInfo) => [
      AccountUtils.getDisplayLastFirstName(teacher.account),
      teacher.account.email,
      teacher.account.managedIdentifier
    ])
  );

  const selectedTableColumns: Column<AccountInfo>[] = [
    {
      render: (rowData) => (
        <TeacherIndicatorsColumn viewModel={viewModel} localizationService={localizationService} rowData={rowData} />
      )
    },
    {
      render: (rowData) => <TeacherNameColumn rowData={rowData} localizationService={localizationService} />
    },
    // Delete teacher
    {
      render: (rowData) => (
        <IconButton onClick={() => viewModel.removeTeacher(rowData.account)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )
    }
  ];

  const availableTableColumns: Column<AccountInfo>[] = [
    {
      render: (rowData) => <TeacherNameColumn rowData={rowData} localizationService={localizationService} />,
      customFilterAndSearch: (filter: string, account: AccountInfo) =>
        CustomFilterUtils.customFilterAndSearch(filter, account, searchableFields.current)
    },
    // Add teacher
    {
      render: (rowData) => (
        <IconButton size="small" onClick={() => void viewModel.addTeacher(rowData.account)}>
          <AddIcon fontSize="small" />
        </IconButton>
      )
    }
  ];

  return (
    <Grid2 sx={sx} className={className} style={style} container spacing={1}>
      {/* Selected teachers */}
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <InsightsMaterialTable
          stateKey={SelectedTableStateKey}
          // This is to disable the card contour
          components={{
            Container: TableContainer,
            Toolbar: TableToolbar
          }}
          title={strings.selectedTeachers}
          columns={selectedTableColumns}
          data={viewModel.selectedTeachers}
          options={{
            header: false,
            paging: false,
            search: false,
            padding: 'dense',
            draggable: false
          }}
          localization={materialTableStrings}
        />
      </Grid2>
      {/* Available teachers */}
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <InsightsMaterialTable
          stateKey={AvailableTableStateKey}
          // This is to disable the card contour
          components={{
            Container: TableContainer
          }}
          title={strings.availableTeachers}
          columns={availableTableColumns}
          data={viewModel.availableTeachers}
          options={{
            pageSize: SmallTablePageSize,
            pageSizeOptions: SmallTablePageSizes,
            header: false,
            emptyRowsWhenPaging: false,
            padding: 'dense',
            draggable: false
          }}
          localization={materialTableStrings}
        />
      </Grid2>
    </Grid2>
  );
});

function TeacherIndicatorsColumn({
  viewModel,
  localizationService,
  rowData
}: {
  viewModel: SectionTeachersEditionViewModel;
  localizationService: LocalizationService;
  rowData: AccountInfo;
}) {
  const strings = localizationService.localizedStrings.insights.components.sections;
  const isDefaultTeacher = viewModel.defaultTeacher?.id === rowData.id;
  const isTeachingAllSchedules = viewModel.allSchedulesTeacherIds.has(rowData.id);

  return (
    <>
      {isDefaultTeacher ? (
        <Tooltip title={strings.defaultTeacherTooltip}>
          <DefaultTeacherIcon sx={{ color: colors.green['500'], m: 1.5 }} />
        </Tooltip>
      ) : (
        <IconButton onClick={() => viewModel.setDefaultTeacher(rowData.id)}>
          <DefaultTeacherIcon sx={{ color: colors.grey['500'] }} />
        </IconButton>
      )}
      {!isTeachingAllSchedules && (
        <Tooltip title={strings.notTeachingAllSchedulesInfo}>
          <InfoIcon />
        </Tooltip>
      )}
    </>
  );
}

function TeacherNameColumn({
  rowData,
  localizationService
}: {
  rowData: AccountInfo;
  localizationService: LocalizationService;
}) {
  return (
    <Typography variant="body1">
      {AccountUtils.getDisplayLastFirstName(rowData.account, localizationService.localizedStrings.insights.noName)}
    </Typography>
  );
}

function TableContainer(props: object & PropsWithChildren) {
  const theme = useTheme();
  return <Box sx={{ backgroundColor: theme.palette.background.paper }}>{props.children}</Box>;
}

function TableToolbar(props: object) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          flex: 1
        }}
      >
        <MTableToolbar {...props} />
      </Box>
    </Box>
  );
}
