import * as MPB from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/moodle_pb';
import { Moodle } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/moodle_connect';
import { MoodleTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class MoodleGrpcTRansport extends BaseGrpcTransport implements MoodleTransport {
  async fetchMoodleExternalAccountDetails(externalAccountId: string): Promise<MPB.GetExternalAccountDetailsResponse> {
    console.log(`Fetching Moodle external account details for [externalAccountId: ${externalAccountId}]...`);

    const request = new MPB.GetExternalAccountDetailsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(Moodle, Moodle.methods.getExternalAccountDetails, request);

    console.log('Successfully fetched Moodle external account.');

    return response;
  }

  async createOrUpdateMoodleExternalAccount(
    externalAccountId: string | undefined,
    configId: string,
    serverUrl: string,
    accessToken: string,
    accountName: string
  ): Promise<MPB.CreateOrUpdateExternalAccountResponse> {
    console.log('Creating or updating Moodle external account...');

    const request = new MPB.CreateOrUpdateExternalAccountRequest();
    request.externalAccountId = externalAccountId ?? '';
    request.configId = configId;
    request.serverUrl = serverUrl;
    request.accessToken = accessToken;
    request.accountName = accountName;

    const response = await this.performRequest(Moodle, Moodle.methods.createOrUpdateExternalAccount, request);

    console.log('Successfully created or updated Moodle external account.');

    return response;
  }
}
