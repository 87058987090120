import { ModalService } from '@shared/services';
import { createContext } from 'react';
import { InsightsServiceProvider } from './InsightsWebServices.ts';
import { ViewModelFactory } from './services';

export type InsightsServicesContextValues = InsightsServiceProvider & {
  modalService: ModalService;
  viewModelFactory: ViewModelFactory;
};

// Passing casting to any to prevent setting a default value. The runtime should define these (app, storybook, etc.)
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any
export const InsightsServicesContext = createContext<InsightsServicesContextValues>({} as any);
