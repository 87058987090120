import { ExternalAssociation as PBExternalAssociation } from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import { Memoize } from 'fast-typescript-memoize';
import { Day } from '../types';
import { externalAccountKindFromProtobuf } from '../types/EnumConversion';
import { GrpcQueueStatus } from './GrpcQueueStatus';
import { ExternalAssociation } from './interfaces';

export class GrpcExternalAssociation implements ExternalAssociation {
  constructor(private readonly pb: PBExternalAssociation) {}

  get id() {
    return this.pb.id;
  }

  get kind() {
    return externalAccountKindFromProtobuf(this.pb.kind);
  }

  get lastUpdate() {
    const lastUpdate = this.pb.lastUpdate;
    return lastUpdate?.toDate();
  }

  get lastSuccessfulUpdate() {
    const lastSuccessfulUpdate = this.pb.lastSuccessfulUpdate;
    return lastSuccessfulUpdate?.toDate();
  }

  get lastUpdateResult() {
    return this.pb.lastUpdateResult;
  }

  get lastUpdateMessage() {
    return this.pb.lastUpdateMessage;
  }

  get configId() {
    return this.pb.configId;
  }

  get sectionId() {
    return this.pb.sectionId;
  }

  get ownerId() {
    return this.pb.ownerId;
  }

  get minimumDate() {
    return Day.fromPB(this.pb.minimumDate);
  }

  get maximumDate() {
    return Day.fromPB(this.pb.maximumDate);
  }

  get externalSectionId() {
    return this.pb.externalSectionId;
  }

  get externalAccountId() {
    return this.pb.externalAccountId;
  }

  @Memoize()
  get queueStatus() {
    const queueStatus = this.pb.queueStatus;

    return queueStatus && new GrpcQueueStatus(queueStatus);
  }

  // The extraData is not visible within apps.
}
