import {
  DailyOptionMenuStrings,
  EnglishDailyOptionMenuStrings,
  FrenchDailyOptionMenuStrings
} from './DailyOptionMenuStrings';

export interface DailyStrings {
  readonly optionMenu: DailyOptionMenuStrings;

  teacherEmailMenuTitle: string;
  teacherEmailMenuMessage: string;
}

export const EnglishDailyStrings: DailyStrings = {
  optionMenu: EnglishDailyOptionMenuStrings,

  teacherEmailMenuMessage: 'Select which teacher you want to join',
  teacherEmailMenuTitle: 'Email'
};

export const FrenchDailyStrings: DailyStrings = {
  optionMenu: FrenchDailyOptionMenuStrings,

  teacherEmailMenuMessage: 'Sélectionnez quel enseignant joindre',
  teacherEmailMenuTitle: 'Courriel'
};
