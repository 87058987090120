import { AuthorizationRoleCondition, ObservablePresenter, PageHeaderBar } from '@insights/components';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid2,
  IconButton,
  SxProps,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface FeaturesProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const Features = observer((props: FeaturesProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx = [], style } = props;
  const strings = localizationService.localizedStrings.insights.views.features;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createFeatures(configId), [configId]);

  return (
    <Root sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />
      <Box
        sx={{
          flex: 1
        }}
      >
        <ObservablePresenter
          sx={{ p: 1, height: '100%', width: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingMessage}
          errorMessage={strings.loadingErrorMessage}
          render={(data) => (
            <Grid2 container>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex'
                  }}
                >
                  <Card sx={{ flex: 1 }} className="card">
                    <CardHeader
                      title={strings.featureListCardTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                          <Tooltip title={strings.editFeaturesButtonTooltip}>
                            <IconButton onClick={() => void viewModel.editFeatures(data.schoolYearConfiguration)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <Box
                        sx={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          overflow: 'visible'
                        }}
                      >
                        {data.features.map((f) => (
                          <FormControlLabel
                            key={`feature-${f.feature}`}
                            classes={{
                              root: 'checkboxLabel',
                              label: 'checkboxLabel'
                            }}
                            control={
                              <Checkbox
                                classes={{ root: 'checkbox' }}
                                readOnly
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                              />
                            }
                            checked={f.isSelected}
                            label={strings.getFeatureLabel(f.feature)}
                            labelPlacement="start"
                          />
                        ))}
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex'
                  }}
                >
                  <Card sx={{ flex: 1 }} className="card">
                    <CardHeader
                      title={strings.requiredEntitlementsTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                          <Tooltip title={strings.addEntitlementButtonTooltip}>
                            <IconButton
                              onClick={() =>
                                void viewModel.addEntitlement(
                                  data.schoolYearConfiguration.id,
                                  data.availableEntitlements
                                )
                              }
                              disabled={data.availableEntitlements.length === 0}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <Box
                        sx={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          overflow: 'visible'
                        }}
                      >
                        {data.featureEntitlements.map((e) => (
                          <Box
                            key={e.stripePriceId}
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            <Box
                              sx={{
                                flex: 1
                              }}
                            >
                              <Typography>{strings.getEntitlementLabel(e.entitlement)}</Typography>
                            </Box>
                            <Typography>{e.stripePriceId}</Typography>
                            <IconButton
                              className="removeEntitlementButton"
                              onClick={() =>
                                void viewModel.removeEntitlement(data.schoolYearConfiguration.id, e.entitlement)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                        {data.featureEntitlements.length == 0 && <Typography>{strings.noEntitlements}</Typography>}
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid2>
            </Grid2>
          )}
        />
      </Box>
    </Root>
  );
});

const Root = styled(Box)(({ theme }) => ({
  '.card': {
    display: 'block'
  },
  '.checkboxLabel': {
    marginLeft: 0,
    flex: 1,
    cursor: 'default !important',
    '&:hover': {
      cursor: 'default !important'
    }
  },
  '.checkbox': {
    backgroundColor: 'transparent !important',
    cursor: 'default !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
      cursor: 'default !important'
    }
  },
  '.removeEntitlementButton': {
    marginLeft: theme.spacing(2)
  }
}));
