import { OnboardingQuestionViewModel } from '@insights/viewmodels';
import LastYearIcon from '@mui/icons-material/EventRepeat';
import { Checkbox, FormControlLabel, FormGroup, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface MultipleChoiceQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: OnboardingQuestionViewModel;
}

export const MultipleChoiceQuestion = observer((props: MultipleChoiceQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const languageCode = localizationService.currentLocale;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <FormGroup sx={sx} className={className} style={style}>
      <Stack>
        {viewModel.choices.map((choice) => (
          <FormControlLabel
            key={`q-${viewModel.templateName}-c-${choice.value}`}
            control={
              <Checkbox
                sx={{ paddingY: '6px' }}
                disabled={viewModel.isDisabled}
                checked={viewModel.answer.multipleChoicesValue.includes(choice.value)}
                onChange={(e) =>
                  e.target.checked
                    ? viewModel.answer.addMultipleChoicesValue(choice.value)
                    : viewModel.answer.removeMultipleChoicesValue(choice.value)
                }
              />
            }
            label={
              <Stack direction="row" spacing={2}>
                <Typography fontWeight="400">
                  {choice.label.find((l) => l.languageCode === languageCode)?.value ?? ''}
                </Typography>
                {viewModel.lastYearAnswer?.multipleChoicesValue?.includes(choice.value) && (
                  <Tooltip title={strings.lastYearAnswerTooltip}>
                    <LastYearIcon color="info" fontSize="small" />
                  </Tooltip>
                )}
              </Stack>
            }
          />
        ))}
      </Stack>
    </FormGroup>
  );
});
