import { AccountService, NavigationService } from '@insights/services';
import { caseInsensitiveAccentInsensitiveCompare, conditionalSortBy } from '@insights/utils';
import {
  AccountModel,
  EditableSchoolYearConfiguration,
  EditableSection,
  EditableSectionSchedule,
  SectionModel,
  TermModel
} from '@shared/models/config';
import { AllColors, AllDayOfWeek, Color, Role } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { ContentStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { Memoize } from 'fast-typescript-memoize';
import _, { isArray, isEqual, sortBy } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable @typescript-eslint/no-redundant-type-constituents */

export type SectionEditionFields = keyof SectionModel;

export type MultipleValues = 'multiple-values';

export interface LoadingSectionEditionViewModel {
  readonly configId: string;
  readonly sectionIds: string[];

  readonly data: IPromiseBasedObservable<SectionEditionViewModel>;

  close(): void;
}

export interface SectionEditionViewModel {
  readonly teachers: AccountModel[];
  readonly teachersById: Record<string, AccountModel>;
  readonly terms: TermModel[];
  readonly daysPerCycle: number;
  readonly hasDefaultTeacherChanged: boolean;
  readonly section: SectionModel;
  importId: string | MultipleValues;
  associatedSectionNumbers: string | MultipleValues;
  isLocked: boolean | MultipleValues;
  title: string | MultipleValues;
  shortTitle: string | MultipleValues;
  gradeLevel: string | MultipleValues;
  sectionNumber: string | MultipleValues;
  color: Color | MultipleValues;
  defaultTeacherId: string | MultipleValues;
  defaultRoomName: string | MultipleValues;
  autoEnrollRoles: Role[] | MultipleValues;
  autoEnrollTags: string[] | MultipleValues;
  hasAutoEnrollConflict: boolean;
  isSystemDefault: boolean | MultipleValues;
  isFree: boolean | MultipleValues;

  readonly availableAutoEnrollTags: string[];
  readonly availablePeriodTags: string[];
  readonly availableScheduleTags: string[];
  readonly allEditableSchedules: EditableSectionSchedule[];

  shouldChangeScheduleTeachers: boolean;

  readonly canTryDelete: boolean;
  readonly shouldBeCreated: boolean;
  readonly hasChanges: boolean;

  readonly isSaving: boolean;
  readonly messages: string[];
  readonly hasLiveErrors: boolean;
  getLiveError(field: SectionEditionFields): string | undefined;

  addSchedule(): void;

  apply(): Promise<void>;
  tryDelete(): Promise<void>;

  generateRandomImportId(): void;

  /**
   * Throw an error if there are more than one sections being edited.
   * Use in contexts where we don't want to batch edit sections
   */
  ensureIsSingle(): void;
}

export class AppLoadingSectionEditionViewModel implements LoadingSectionEditionViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _contentStore: ContentStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly sectionIds: string[],
    private readonly _isEditingSchedule: boolean
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<SectionEditionViewModel> {
    return fromPromise(this.sectionIds.length === 0 ? this.createData() : this.loadData());
  }

  close() {
    this._onCancel();
  }

  private async loadData(): Promise<SectionEditionViewModel> {
    const config = await this._schoolYearConfigurationStore.getConfig(this.configId);

    const [teachers, students] = this._accountService.isAllowed(['super-admin', 'admin'])
      ? await Promise.all([
          this._schoolYearConfigurationStore.getTeachers(this.configId, false),
          this._schoolYearConfigurationStore.getStudents(this.configId, false)
        ])
      : await Promise.all([
          ...this.sectionIds.map((sectionId) =>
            this._schoolYearConfigurationStore.getTeachersForSectionId(this.configId, sectionId, false)
          ),
          ...this.sectionIds.map((sectionId) =>
            this._schoolYearConfigurationStore.getStudentsForSectionId(this.configId, sectionId, false)
          )
        ]);

    const editableConfig = new EditableSchoolYearConfiguration(config);
    const editableSections: EditableSection[] = _(this.sectionIds)
      .map((sectionId) => editableConfig.getEditableSection(editableConfig.sections.find((s) => s.id === sectionId)))
      .compact()
      .value();

    if (editableSections.length === 0) {
      throw new Error('Cannot find requested sections.');
    }

    return new AppSectionEditionViewModel(
      this._localizationService,
      this._schoolYearConfigurationStore,
      this._contentStore,
      this._navigationService,
      teachers,
      students,
      editableConfig,
      this._onSuccess,
      this._onCancel,
      editableSections,
      true,
      this._isEditingSchedule
    );
  }

  private async createData(): Promise<SectionEditionViewModel> {
    const config = await this._schoolYearConfigurationStore.getConfig(this.configId);
    let teachers: AccountModel[] = [];
    let students: AccountModel[] = [];

    if (this._accountService.isAllowed(['super-admin', 'admin'])) {
      [teachers, students] = await Promise.all([
        this._schoolYearConfigurationStore.getTeachers(this.configId, true),
        this._schoolYearConfigurationStore.getStudents(this.configId, true)
      ]);
    } else {
      const teacherId = this._accountService.getAccountIdForConfigRole(this.configId, 'teacher');

      if (teacherId != null) {
        teachers = [await this._schoolYearConfigurationStore.getAccount(this.configId, teacherId, true)];
      }

      // No access to students.
    }

    const editableConfig = new EditableSchoolYearConfiguration(config);
    const editableSection = EditableSection.createNew(this.getNextColor(editableConfig.sections));
    // Sections created by users should be locked by default.
    editableSection.isLocked = true;
    editableConfig.addSection(editableSection);

    if (!this._accountService.isAllowed(['super-admin'])) {
      const teacherId = this._accountService.getAccountIdForConfigRole(this.configId, 'teacher');

      if (teacherId != null) {
        editableSection.defaultTeacherId = teacherId;
      }
    }

    return new AppSectionEditionViewModel(
      this._localizationService,
      this._schoolYearConfigurationStore,
      this._contentStore,
      this._navigationService,
      teachers,
      students,
      editableConfig,
      this._onSuccess,
      this._onCancel,
      [editableSection],
      false,
      this._isEditingSchedule
    );
  }

  private getNextColor(sections: SectionModel[]): Color {
    if (sections.length === 0) {
      return 'orange';
    }

    // We must work with color indexes to sort by that index.
    // We insert one value of each possible index, to have at least one of each possible color index.
    // 22 == hardcoded index of the first grays, which are not used in the cycle
    const colorIndexes = _.range(0, 20).concat(sections.map((s) => s.toProtobuf().color));
    const counts = _.countBy(colorIndexes, (i) => i);
    const sortedIndexes = _.sortBy(Object.keys(counts), (index: number) => counts[index] * 20 + index);
    return AllColors[sortedIndexes[0] as number];
  }
}

export class AppSectionEditionViewModel implements SectionEditionViewModel {
  @observable private _associatedSectionNumbers: string | MultipleValues;
  @observable private _shouldChangeScheduleTeachers = false;
  @observable private _isSaving = false;
  @observable private _messages: Set<string> = new Set<string>();
  @observable private _canTryDelete = false;
  @observable private _liveErrors: Map<SectionEditionFields, string> = new Map<SectionEditionFields, string>();

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _contentStore: ContentStore,
    private readonly _navigationService: NavigationService,
    private readonly _teachers: AccountModel[],
    private readonly _students: AccountModel[],
    private readonly _editableConfig: EditableSchoolYearConfiguration,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _editableSections: EditableSection[],
    canTryDelete: boolean,
    private readonly _isEditingSchedules: boolean
  ) {
    makeObservable(this);
    const associatedSectionNumbers = this.getValue<string[]>('associatedSectionNumbers');
    this._associatedSectionNumbers = isArray(associatedSectionNumbers)
      ? associatedSectionNumbers.join(', ')
      : associatedSectionNumbers;

    this._canTryDelete = canTryDelete;

    // Run validation right away in case section was saved earlier in a time we did not check these.
    this.validateImportId();
    this.validateTitle();
    this.validateAutoEnrollTags();
  }

  @computed
  get importId(): string | MultipleValues {
    return this.getValue('importId');
  }

  set importId(value: string | MultipleValues) {
    this.setValue('importId', value);
    this.validateImportId();
  }

  @computed
  get associatedSectionNumbers(): string | MultipleValues {
    return this._associatedSectionNumbers;
  }

  set associatedSectionNumbers(value: string | MultipleValues) {
    this.setValue('associatedSectionNumbers', value.split(','));
    this._associatedSectionNumbers = value;
  }

  @computed
  get isLocked(): boolean | MultipleValues {
    return this.getValue('isLocked');
  }

  set isLocked(value: boolean | MultipleValues) {
    this.setValue('isLocked', value);
  }

  @computed
  get title(): string | MultipleValues {
    return this.getValue('title');
  }

  set title(value: string | MultipleValues) {
    this.setValue('title', value);
    this.validateTitle();
  }

  @computed
  get shortTitle(): string | MultipleValues {
    return this.getValue('shortTitle');
  }

  set shortTitle(value: string | MultipleValues) {
    this.setValue('shortTitle', value);
  }

  @computed
  get gradeLevel(): string | MultipleValues {
    return this.getValue('gradeLevel');
  }

  set gradeLevel(value: string | MultipleValues) {
    this.setValue('gradeLevel', value);
  }

  @computed
  get sectionNumber(): string | MultipleValues {
    return this.getValue('sectionNumber');
  }

  set sectionNumber(value: string | MultipleValues) {
    this.setValue('sectionNumber', value);
  }

  @computed
  get color(): Color | MultipleValues {
    return this.getValue('color');
  }

  set color(value: Color | MultipleValues) {
    this.setValue('color', value);
  }

  @computed
  get defaultTeacherId(): string | MultipleValues {
    return this.getValue('defaultTeacherId');
  }

  set defaultTeacherId(value: string | MultipleValues) {
    this.setValue('defaultTeacherId', value);
  }

  @computed
  get defaultRoomName(): string | MultipleValues {
    return this.getValue('defaultRoomName');
  }

  set defaultRoomName(value: string | MultipleValues) {
    this.setValue('defaultRoomName', value);
  }

  @computed
  get autoEnrollRoles(): Role[] | MultipleValues {
    return this.getValue('autoEnrollRoles');
  }

  set autoEnrollRoles(value: Role[] | MultipleValues) {
    this.setValue('autoEnrollRoles', value);
  }

  @computed
  get autoEnrollTags(): string[] | MultipleValues {
    return this.getValue('autoEnrollTags');
  }

  set autoEnrollTags(value: string[] | MultipleValues) {
    this.setValue('autoEnrollTags', value);
    this.validateAutoEnrollTags();
  }

  @computed
  get hasAutoEnrollConflict(): boolean {
    return (
      (this.autoEnrollRoles === 'multiple-values' || this.autoEnrollRoles.length > 0) &&
      (this.autoEnrollTags === 'multiple-values' || this.autoEnrollTags.length > 0)
    );
  }

  @computed
  get isSystemDefault(): boolean | MultipleValues {
    return this.getValue('isSystemDefault');
  }

  set isSystemDefault(value: boolean | MultipleValues) {
    this.setValue('isSystemDefault', value);
  }

  @computed
  get isFree(): boolean | MultipleValues {
    return this.getValue('isFree');
  }

  set isFree(value: boolean | MultipleValues) {
    this.setValue('isFree', value);
  }

  get availableAutoEnrollTags() {
    // This list is very simple, for now.
    return ['gradeLevel'];
  }

  @computed
  get availablePeriodTags(): string[] {
    return _(this._editableConfig.schedules.map((s) => s.periods.map((p) => p.tag)))
      .flatten()
      .uniq()
      .value();
  }

  @computed
  get availableScheduleTags(): string[] {
    return _(this._editableConfig.schedules.map((s) => s.tag))
      .concat(this._editableConfig.allowedScheduleTags)
      .compact()
      .uniq()
      .value();
  }

  @computed
  get allEditableSchedules(): EditableSectionSchedule[] {
    return conditionalSortBy(this.getSingleValue('allEditableSchedules'), (value) => !value.shouldBeCreated, [
      'termTag',
      'dayCase',
      'cycleDay',
      (s) => (s.dayOfWeek == null ? undefined : AllDayOfWeek.indexOf(s.dayOfWeek)),
      (s) => s.day?.asDate.getUTCMilliseconds()
    ]);
  }

  @computed
  get shouldChangeScheduleTeachers() {
    return this._shouldChangeScheduleTeachers;
  }

  set shouldChangeScheduleTeachers(value: boolean) {
    this._shouldChangeScheduleTeachers = value;
  }

  @computed
  get canTryDelete(): boolean {
    return this._canTryDelete;
  }

  @computed
  get shouldBeCreated(): boolean {
    return this.getSingleValue('shouldBeCreated', false);
  }

  @computed
  get hasChanges(): boolean {
    return this._editableConfig.hasChanges;
  }

  @computed
  get hasLiveErrors(): boolean {
    return Array.from(this._liveErrors.values()).length > 0 || this.hasAutoEnrollConflict;
  }

  @computed
  get isSaving(): boolean {
    return this._isSaving;
  }

  @computed
  get messages(): string[] {
    return Array.from(this._messages);
  }

  @Memoize()
  get teachers() {
    return this._teachers
      .slice()
      .sort(
        (a, b) =>
          caseInsensitiveAccentInsensitiveCompare(a.lastName, b.lastName) ||
          caseInsensitiveAccentInsensitiveCompare(a.firstName, b.firstName)
      );
  }

  @Memoize()
  get teachersById() {
    return _.keyBy(this._teachers, (t) => t.id);
  }

  @Memoize()
  get terms() {
    return this._editableConfig.terms;
  }

  get daysPerCycle() {
    return this._editableConfig.daysPerCycle;
  }

  @computed
  get hasDefaultTeacherChanged() {
    return this.getSingleValue('hasDefaultTeacherChanged', true);
  }

  get section(): SectionModel {
    this.ensureIsSingle();
    return this._editableSections[0];
  }

  getLiveError(field: SectionEditionFields): string | undefined {
    return this._liveErrors.get(field);
  }

  @action
  addSchedule() {
    const termTag = this._editableConfig.terms.length === 1 ? this._editableConfig.terms[0].tag : undefined;
    this._editableSections.forEach((s) => {
      const schedule = EditableSectionSchedule.createNew(s.defaultTeacherId, termTag);
      s.addSchedule(schedule);
    });
  }

  @action
  async tryDelete(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.section;

    this._isSaving = true;
    this._messages.clear();

    try {
      const canDelete = await Promise.all(this._editableSections.map((s) => this.canDelete(s)));

      // If at least one section can't be deleted, don't delete anything
      if (canDelete.some((value) => !value)) {
        return;
      }

      if (!confirm(strings.deleteConfirmation)) {
        return;
      }

      this._editableSections.forEach((s) => s.markAsDeleted());

      await this._schoolYearConfigurationStore.saveConfig(this._editableConfig);
      this._onSuccess();
    } catch (error) {
      runInAction(() => {
        this._messages.clear();
        this._messages.add(strings.unexpectedError + (error as Error).message);
        this._editableSections.forEach((s) => s.markAsNotDeleted());
      });
    } finally {
      runInAction(() => {
        this._isSaving = false;
      });
    }
  }

  @action
  async apply(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.section;

    if (!this.hasChanges) {
      console.error('Invalid operation: It should not be possible to call apply without changes.');
      return;
    }

    // Because this view-model is used with both details and schedules edition, we can't prevent
    // applying changes for "live" errors (which are local constraints that might have been introduced
    // after adding a new requirement, like having an import id)). We trust that each dialog prevents
    // applying if applicable.

    this._isSaving = true;
    this._messages.clear();

    try {
      if (
        this._isEditingSchedules &&
        _.flatten(this._editableSections.map((es) => es.editableSchedules)).find(
          (ess) => ess.invalidFields.length > 0
        ) != null
      ) {
        this._messages.add(
          this._localizationService.localizedStrings.insights.viewModels.sections.scheduleFieldInError
        );
        return;
      }

      this._editableSections.forEach((editableSection) => {
        if (this._shouldChangeScheduleTeachers) {
          const oldId = editableSection.originalDefaultTeacherId;
          const newId = editableSection.defaultTeacherId;

          if (newId.length > 0) {
            editableSection.editableSchedules.forEach(
              (sc) => (sc.teacherIds = _.uniq(sc.teacherIds.filter((id) => id != oldId).concat(newId)))
            );
          } else {
            editableSection.editableSchedules.forEach(
              (sc) => (sc.teacherIds = sc.teacherIds.filter((id) => id != oldId))
            );
          }
        }
      });

      await this._schoolYearConfigurationStore.saveConfig(this._editableConfig);

      this._onSuccess();
    } catch (error) {
      runInAction(() => {
        this._messages.clear();
        this._messages.add(strings.unexpectedError + (error as Error).message);
      });
    } finally {
      runInAction(() => {
        this._isSaving = false;
      });
    }
  }

  @action
  generateRandomImportId(): void {
    // The UI hides the "generate" button if multiple values, but we could have multiple sections,
    // all with the same value (including empty since we allowed it in the past). We need to set
    // different values to each while still running validation. We can't use "this.importId = x"
    // nor "this.setValue('importId', x)".
    this._editableSections.forEach((s) => (s.importId = uuidv4()));
    this.validateImportId();
  }

  ensureIsSingle(): void {
    if (this._editableSections.length > 1) {
      throw new Error(
        `The SectionEditionViewModel was expected to contain one section but ${this._editableSections.length} sections were found.`
      );
    }
  }

  /**
   * Verify if the `editableSection` meets all the needed conditions to be allowed to be deleted.
   * @param editableSection The section to verify.
   * @private
   * @return boolean `true` if the `editableSection` can be deleted, `false` otherwise.
   */
  private async canDelete(editableSection: EditableSection): Promise<boolean> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.section;

    // No accounts have selected it.
    const accounts = await this._schoolYearConfigurationStore.withoutCaching.getStudentsForSectionId(
      this._editableConfig.id,
      editableSection.id,
      false /* exclude deleted accounts */
    );

    if (accounts.length > 0) {
      runInAction(() => {
        this._messages.add(strings.sectionInUseByStudents);
        this._canTryDelete = false;
      });
      return false;
    }

    // No active contents are assigned to it.
    const contents = await this._contentStore.withoutCaching.getContents({
      configId: this._editableConfig.id,
      sectionIds: [editableSection.id],
      includeCancelled: false,
      includeCompleted: false
    });

    if (contents.length > 0) {
      runInAction(() => {
        this._messages.add(strings.sectionReferencedByActiveContent);
        this._canTryDelete = false;
      });
      return false;
    }

    return true;
  }

  private validateImportId() {
    const strings = this._localizationService.localizedStrings.insights.components.sections;
    if (this.importId.length === 0) {
      this._liveErrors.set('importId', strings.importIdentifierEmptyError);
    } else if (this.importId !== 'multiple-values' && this.hasDuplicateImportId(this.importId)) {
      this._liveErrors.set('importId', strings.importIdentifierUniqueError);
    } else {
      // We do not validate multiple different values, and it's disabled when this happens.
      this._liveErrors.delete('importId');
    }
  }

  private validateTitle() {
    if (this.title.length === 0) {
      this._liveErrors.set(
        'title',
        this._localizationService.localizedStrings.insights.components.sections.titleEmptyError
      );
    } else {
      // We do not validate empty titles when multiple values.
      this._liveErrors.delete('title');
    }
  }

  private validateAutoEnrollTags() {
    if (
      this.autoEnrollTags !== 'multiple-values' &&
      this.autoEnrollTags.find((t) => t.indexOf('=') === t.length - 1) != null
    ) {
      this._liveErrors.set(
        'autoEnrollTags',
        this._localizationService.localizedStrings.insights.components.sections.emptyAutoEnrollTag
      );
    } else {
      this._liveErrors.delete('autoEnrollTags');
    }
  }

  private hasDuplicateImportId(importId: string): boolean {
    return this._editableConfig.sections.filter((s) => s.importId == importId).length > 1;
  }

  /**
   * Indicates if a EditableSection property has two or more different values.
   * @param property The property to check.
   * @private
   * @return boolean `true` if the property has multiple different values, `false` otherwise.
   */
  private hasMultipleValues(property: keyof EditableSection): boolean {
    const values = _(this._editableSections)
      .map(property)
      .uniqWith((a, b) => (isArray(a) && isArray(b) ? isEqual(sortBy(a), sortBy(b)) : isEqual(a, b)))
      .value();
    return values.length > 1;
  }

  /**
   * Get the value of an EditableSection property among `this._editableSections`.
   * If there are at least two EditableSections who have different values for the `property`, return `MultipleValues`
   * @param property The property for whom to get the value.
   * @private
   * @return T | MultipleValues The value if the `EditableSections` all have
   * the same value for that property, `MultipleValues` otherwise.
   */
  private getValue<T>(property: keyof EditableSection): T | MultipleValues {
    return this.hasMultipleValues(property) ? 'multiple-values' : (this._editableSections[0][property] as unknown as T);
  }

  /**
   * Get the value of an EditableSection assuming it's not a `MultipleValue`.
   * @param property The property for whom to get the value.
   * @param defaultValue The value to use when the `property` value of
   * the EditableSection is `MultipleValues`. Optional.
   * @throws When the `property` value of the EditableSection is `MultipleValues` and `defaultValue` is not defined.
   * @private
   * @return T The `property` value of the EditableSection
   * or`defaultValue` of `defaultValue` if the `property` returns a `MultipleValues`.
   */
  private getSingleValue<T>(property: keyof EditableSection, defaultValue?: T): T {
    const value = this.getValue<T>(property);
    if (value === 'multiple-values') {
      if (defaultValue != null) {
        return defaultValue;
      }
      throw new Error(`Property "${property}" should not have multiple values.`);
    }
    return value;
  }

  /**
   * Set the `property` value of all EditableSections with `value`.
   * @param property The property to set.
   * @param value The value of the property.
   * @throws When the `value` is `MultipleValues`.
   * @private
   */
  private setValue<T>(property: keyof EditableSection, value: T | MultipleValues) {
    if (value === 'multiple-values') {
      throw new Error('Cannot set a multiple-value value');
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this._editableSections.forEach((s) => (s[property] = value));
  }
}
