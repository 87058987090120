export interface OtherProfilesListStrings {
  readonly title: string;
  readonly close: string;
  readonly useCode: string;
}

export const EnglishOtherProfilesListStrings: OtherProfilesListStrings = {
  title: 'Other Profiles',
  close: 'Close',
  useCode: 'Use a school code'
};

export const FrenchOtherProfilesListStrings: OtherProfilesListStrings = {
  title: 'Autres profils',
  close: 'Fermer',
  useCode: 'Utiliser un code école'
};
