import { css } from '@emotion/css';
import { OnboardingProcessViewModel } from '@insights/viewmodels';
import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { MinLayoutWidth } from '../../Constants';
import { OnboardingCommentsCard } from './OnboardingCommentsCard';
import { OnboardingProcessCard } from './OnboardingProcessCard';

export interface OnboardingProcessViewProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: OnboardingProcessViewModel;
}

export const OnboardingProcessView = observer((props: OnboardingProcessViewProps) => {
  const { className, style, sx = [], viewModel } = props;
  const cardClassName = css([{ minWidth: MinLayoutWidth, display: 'block' }]);

  return (
    <Box sx={sx} className={className} style={style}>
      <OnboardingProcessCard className={cardClassName} viewModel={viewModel} />
      <OnboardingCommentsCard className={cardClassName} viewModel={viewModel.commentsViewModel} showHistoryButton />
    </Box>
  );
});
