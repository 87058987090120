import {
  ConfigurationImages,
  GenericImages,
  NavigationImages,
  SettingsImages,
  SpecialDaysImages,
  StudyoImages,
  TasksImages
} from '@shared/resources/images/studyo';
import { WebConfigurationImages } from './WebConfigurationImages';
import { WebGenericImages } from './WebGenericImages';
import { WebNavigationImages } from './WebNavigationImages';
import { WebSettingsImages } from './settings';
import { WebSpecialDaysImages } from './special_days';
import { WebTasksImages } from './tasks';

export class WebStudyoImages implements StudyoImages {
  private readonly _configuration = new WebConfigurationImages();
  private readonly _generic = new WebGenericImages();
  private readonly _navigation = new WebNavigationImages();
  private readonly _settings = new WebSettingsImages();
  private readonly _specialDays = new WebSpecialDaysImages();
  private readonly _tasks = new WebTasksImages();

  get configuration(): ConfigurationImages {
    return this._configuration;
  }

  get generic(): GenericImages {
    return this._generic;
  }

  get navigation(): NavigationImages {
    return this._navigation;
  }

  get settings(): SettingsImages {
    return this._settings;
  }

  get specialDays(): SpecialDaysImages {
    return this._specialDays;
  }

  get tasks(): TasksImages {
    return this._tasks;
  }
}
