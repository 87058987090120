import { TransformationColumn as PBTransformationColumn } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { action, computed, makeObservable } from 'mobx';
import {
  EditableLiveStringArrayProperty,
  EditableModelEx,
  EditableStringProperty,
  EditableValuePropertyEx
} from '../../editables';
import { TransformationColumn } from '../interfaces';
import { GrpcTransformationColumn } from './GrpcTransformationColumn';

export class EditableTransformationColumn
  extends EditableModelEx<PBTransformationColumn>
  implements TransformationColumn
{
  private readonly _operation: EditableStringProperty<PBTransformationColumn>;
  private readonly _parameters: EditableLiveStringArrayProperty<PBTransformationColumn>;
  private readonly _targetSchemaField: EditableStringProperty<PBTransformationColumn>;
  private readonly _isSubstitution: EditableValuePropertyEx<boolean, PBTransformationColumn>;
  private readonly _comment: EditableStringProperty<PBTransformationColumn>;

  static createNew() {
    const pb = new PBTransformationColumn();

    // This is the default "Literal" operation.
    pb.operation = '';
    pb.parameters = [''];

    return new EditableTransformationColumn(new GrpcTransformationColumn(pb), true);
  }

  constructor(
    private readonly _originalTransformationColumn: TransformationColumn,
    isNew = false
  ) {
    super(_originalTransformationColumn.toProtobuf(), isNew);
    makeObservable(this);

    this._operation = this.addStringField(
      _originalTransformationColumn.operation,
      (host, value) => (host.operation = value)
    );
    this._parameters = this.addLiveStringArrayField(
      _originalTransformationColumn.parameters,
      (host, values) => (host.parameters = values)
    );
    this._targetSchemaField = this.addStringField(
      _originalTransformationColumn.targetSchemaField,
      (host, value) => (host.targetSchemaField = value)
    );
    this._isSubstitution = this.addValueField<boolean>(
      _originalTransformationColumn.isSubstitution,
      (host, value) => (host.isSubstitution = value)
    );
    this._comment = this.addStringField(_originalTransformationColumn.comment, (host, value) => (host.comment = value));
  }

  @computed
  get operation() {
    return this._operation.value;
  }

  set operation(value: string) {
    this._operation.value = value;
  }

  @computed
  get parameters() {
    return this._parameters.value;
  }

  set parameters(values: string[]) {
    this._parameters.value = values;
  }

  @action
  setParameterByIndex(value: string, index: number) {
    this._parameters.setItem(value, index);
  }

  @computed
  get targetSchemaField() {
    return this._targetSchemaField.value;
  }

  set targetSchemaField(value: string) {
    this._targetSchemaField.value = value;
  }

  @computed
  get isSubstitution() {
    return this._isSubstitution.value;
  }

  set isSubstitution(value: boolean) {
    this._isSubstitution.value = value;
  }

  @computed
  get comment(): string {
    return this._comment.value;
  }

  set comment(value: string) {
    this._comment.value = value;
  }
}
