import { NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { MicrosoftTeamsConnectorStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppMicrosoftTeamsMappingsViewModel, MicrosoftTeamsMappingsViewModel } from './MicrosoftTeamsMappingsViewModel';

export interface MicrosoftTeamsMappingsDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly mappings: IPromiseBasedObservable<MicrosoftTeamsMappingsViewModel>;
}

export class AppMicrosoftTeamsMappingsDialogViewModel implements MicrosoftTeamsMappingsDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _teamsStore: MicrosoftTeamsConnectorStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get mappings(): IPromiseBasedObservable<MicrosoftTeamsMappingsViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<MicrosoftTeamsMappingsViewModel> {
    // We do not fetch assignment categories anymore, as this can be very long
    // since we have to fetch them for each class.
    const details = await this._teamsStore.getTeamsAccountDetails(this.configId, this.externalAccountId);

    return new AppMicrosoftTeamsMappingsViewModel(
      this._localizationService,
      this._teamsStore,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      [],
      details
    );
  }
}
