import { MultipleValues } from '@insights/viewmodels';
import { Checkbox, CheckboxProps, FormControlLabel, SxProps } from '@mui/material';
import { CSSProperties } from 'react';

export interface MultipleValuesCheckboxProps {
  sx?: SxProps;
  /**
   * The checkbox value.
   */
  value: boolean | MultipleValues;
  /**
   * The value to use when the `value` is `MultipleValues`. Default is `false`.
   */
  defaultValue?: boolean;
  /**
   * The form control label.
   */
  label: string;
  /**
   * Indicates to disable the checkbox. Default is `false`.
   */
  disabled?: boolean;
  /**
   * Additional properties to control the checkbox.
   */
  checkboxProps?: CheckboxProps;
  style?: CSSProperties;
  className?: string;

  /**
   * Callback when changing the checkbox value.
   * @param value The new value.
   */
  onChange: (value: boolean) => void;
}

export const MultipleValuesCheckbox = (props: MultipleValuesCheckboxProps) => {
  const { sx = [], value, defaultValue, label, style, onChange, checkboxProps, disabled, className } = props;

  return (
    <FormControlLabel
      sx={sx}
      style={style}
      className={className}
      disabled={disabled}
      control={
        <Checkbox
          checked={value === 'multiple-values' ? defaultValue : value}
          indeterminate={value === 'multiple-values'}
          disabled={disabled}
          onChange={(event) => onChange(event.target.checked)}
          {...checkboxProps}
        />
      }
      label={label}
    />
  );
};
