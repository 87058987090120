import { ContentDefinition, ContentDefinitionModel } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { chain, filter, forEach, uniqBy } from 'lodash';
import { IComputedValue, computed, makeObservable } from 'mobx';
import moize from 'moize';
import { ContentTransport } from '../../transports';
import { ContentStore, GetContentsParameters } from '../interfaces';
import { AppBaseStore } from './AppBaseStore';

export class AppContentStore extends AppBaseStore implements ContentStore {
  constructor(
    private readonly _transport: ContentTransport,
    bypassCaching = false
  ) {
    super('AppContentStore', bypassCaching);
    makeObservable(this);
  }

  @computed
  get withoutCaching(): ContentStore {
    if (this.isCachingBypassed) {
      return this;
    }

    return new AppContentStore(this._transport, true);
  }

  getContent(contentId: string): Promise<ContentDefinitionModel> {
    return this.getMemoizedContent(contentId).get();
  }

  getContentsForIds(contentIds: string[]): Promise<ContentDefinitionModel[]> {
    return this.getMemoizedContentsForIds(contentIds).get();
  }

  getContents(parameters: GetContentsParameters): Promise<ContentDefinitionModel[]> {
    return this.getMemoizedContents(parameters).get();
  }

  getActiveContents(
    configId: string,
    accountId: string,
    fromDay?: Day,
    toDay?: Day
  ): Promise<ContentDefinitionModel[]> {
    return this.getMemoizedActiveContents(configId, accountId, fromDay, toDay).get();
  }

  getCompletedContents(
    configId: string,
    accountId: string,
    fromDay?: Day,
    toDay?: Day
  ): Promise<ContentDefinitionModel[]> {
    return this.getMemoizedCompletedContents(configId, accountId, fromDay, toDay).get();
  }

  getCancelledContents(
    configId: string,
    accountId: string,
    fromDay?: Day,
    toDay?: Day
  ): Promise<ContentDefinitionModel[]> {
    return this.getMemoizedCancelledContents(configId, accountId, fromDay, toDay).get();
  }

  getVisibleContents(
    configId: string,
    accountId: string,
    fromDay?: Day,
    toDay?: Day
  ): Promise<ContentDefinitionModel[]> {
    return this.getMemoizedVisibleContents(configId, accountId, fromDay, toDay).get();
  }

  getVisibleContentsForDay(configId: string, accountId: string, day: Day): Promise<ContentDefinitionModel[]> {
    return this.getMemoizedVisibleContentsForDay(configId, accountId, day).get();
  }

  getVisibleContentsForPeriod(
    configId: string,
    accountId: string,
    day: Day,
    periodTag?: string,
    sectionId?: string
  ): Promise<ContentDefinitionModel[]> {
    return this.getMemoizedVisibleContentsForPeriod(configId, accountId, day, periodTag, sectionId).get();
  }

  private getMemoizedContent = this.memoize(
    (contentId: string): IComputedValue<Promise<ContentDefinitionModel>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbContent = await this._transport.fetchContent(contentId);

            return new ContentDefinition(pbContent);
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedContentsForIds = moize.deep(
    (contentIds: string[]): IComputedValue<Promise<ContentDefinitionModel[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbContents = await this._transport.fetchMultipleContents(contentIds);

            return chain(pbContents)
              .compact()
              .map((pbContent) => new ContentDefinition(pbContent))
              .value();
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedContents = moize.deep(
    (parameters: GetContentsParameters): IComputedValue<Promise<ContentDefinitionModel[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const contentsResult = await this._transport.fetchContents(parameters);

            return chain(contentsResult.result)
              .filter((pb) => !pb.isDeleted)
              .map((c) => new ContentDefinition(c))
              .value();
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedActiveContents = this.memoize(
    (
      configId: string,
      accountId: string,
      fromDay?: Day,
      toDay?: Day
    ): IComputedValue<Promise<ContentDefinitionModel[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const contentsResult = await this._transport.fetchContents({
              configId,
              accountIds: [accountId],
              fromDay,
              toDay
            });

            return chain(contentsResult.result)
              .filter((pb) => !pb.isDeleted)
              .map((c) => new ContentDefinition(c))
              .value();
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedCompletedContents = this.memoize(
    (
      configId: string,
      accountId: string,
      fromDay?: Day,
      toDay?: Day
    ): IComputedValue<Promise<ContentDefinitionModel[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const contentsResult = await this._transport.fetchContents({
              configId,
              accountIds: [accountId],
              includeCompleted: true,
              fromDay,
              toDay
            });

            return chain(contentsResult.result)
              .filter((pb) => !pb.isDeleted)
              .map((c) => new ContentDefinition(c))
              .value();
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedCancelledContents = this.memoize(
    (
      configId: string,
      accountId: string,
      fromDay?: Day,
      toDay?: Day
    ): IComputedValue<Promise<ContentDefinitionModel[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const contentsResult = await this._transport.fetchContents({
              configId,
              accountIds: [accountId],
              includeCancelled: true,
              fromDay,
              toDay
            });

            return chain(contentsResult.result)
              .filter((pb) => !pb.isDeleted)
              .map((c) => new ContentDefinition(c))
              .value();
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedVisibleContents = this.memoize(
    (
      configId: string,
      accountId: string,
      fromDay?: Day,
      toDay?: Day
    ): IComputedValue<Promise<ContentDefinitionModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const [active, completed] = await Promise.all([
            this.getActiveContents(configId, accountId, fromDay, toDay),
            this.getCompletedContents(configId, accountId, fromDay, toDay)
          ]);

          const allContents = [...active, ...completed];
          return uniqBy(allContents, (c) => c.id);
        })
      )
  );

  private getMemoizedVisibleContentsForDay = this.memoize(
    (configId: string, accountId: string, day: Day): IComputedValue<Promise<ContentDefinitionModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const allContents = await this.getVisibleContents(configId, accountId);
          return filter(allContents, (c) => c.assignmentDay.isSame(day) || c.dueDay.isSame(day));
        })
      )
  );

  private getMemoizedVisibleContentsForPeriod = this.memoize(
    (
      configId: string,
      accountId: string,
      day: Day,
      periodTag?: string,
      sectionId?: string
    ): IComputedValue<Promise<ContentDefinitionModel[]>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const schoolDayContents = await this.getVisibleContentsForDay(configId, accountId, day);

          const contents: ContentDefinitionModel[] = [];
          forEach(schoolDayContents, (c) => {
            const isDueDate = c.dueDay.isSame(day);

            if (periodTag == null) {
              if (isDueDate && c.duePeriodTag == '') {
                contents.push(c);
              }
              // TODO: Assignement if no period for section;
            } else {
              if (isDueDate && c.duePeriodTag === periodTag) {
                contents.push(c);
              } else if (!isDueDate && c.sectionId === sectionId) {
                contents.push(c);
              }
            }
          });

          return contents;
        })
      )
  );
}
