import { MultipleValues } from '@insights/viewmodels';
import { SxProps, TextField, TextFieldProps } from '@mui/material';
import { CSSProperties } from 'react';
import { MultipleValuesControl } from './MultipleValuesControl';

export interface MultipleValuesTextFieldProps {
  /**
   * The text field value.
   */
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  value: string | MultipleValues;
  /**
   * The field label.
   */
  label: string;
  /**
   * Indicates to disable the MultipleValuesControl and the TextField. Default is `false`.
   */
  disabled?: boolean;
  /**
   * Additional properties to control the TextField.
   */
  textFieldProps?: TextFieldProps;
  style?: CSSProperties;
  className?: string;
  sx?: SxProps;

  /**
   * Callback when changing the TextField value.
   * @param value The new value.
   */
  onChange: (value: string) => void;
}

export const MultipleValuesTextField = (props: MultipleValuesTextFieldProps) => {
  const { sx = [], value, label, disabled, style, onChange, textFieldProps, className } = props;

  return (
    <MultipleValuesControl
      sx={sx}
      value={value}
      disabled={disabled}
      defaultValue={''}
      label={label}
      style={style}
      className={className}
      renderControl={(v) => (
        <TextField
          label={label}
          style={style}
          className={className}
          value={v}
          disabled={disabled}
          onChange={(event) => onChange(event.target.value)}
          slotProps={{ inputLabel: { shrink: true } }}
          {...textFieldProps}
        />
      )}
    />
  );
};
