import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_sky_pb';
import { BlackbaudSkySchoolYear } from './interfaces';

export class GrpcBlackbaudSkySchoolYear implements BlackbaudSkySchoolYear {
  constructor(private readonly pb: BC.SchoolYear) {}

  get id() {
    return this.pb.id;
  }

  get label() {
    return this.pb.label;
  }

  get isCurrent() {
    return this.pb.isCurrent;
  }

  get isPublished() {
    return this.pb.isPublished;
  }

  get startDate() {
    const date = this.pb.startDate;
    return date?.toDate();
  }

  get endDate() {
    const date = this.pb.endDate;
    return date?.toDate();
  }
}
