export interface UserComponentsStrings {
  emailsDontMatchWarning: string;
  loadingUserProfile: string;
  loadingUserProfileError: string;
  rootAdminTooltip: string;
  unconfirmedEmailTooltip: string;
}

export const EnglishUserComponentsStrings: UserComponentsStrings = {
  emailsDontMatchWarning: 'Account email differs. Disconnect to allow account auto-match.',
  loadingUserProfile: 'Loading user profile…',
  loadingUserProfileError: '(Profile information not available)',
  rootAdminTooltip: 'Root administrator',
  unconfirmedEmailTooltip: 'Unconfirmed email'
};

export const FrenchUserComponentsStrings: UserComponentsStrings = {
  emailsDontMatchWarning: 'Courriel de fiche différent. Déconnectez pour permettre le lien automatique.',
  loadingUserProfile: 'Chargement du profil d’utilisateur…',
  loadingUserProfileError: '(Information du profil non-disponible)',
  rootAdminTooltip: 'Administrateur principal',
  unconfirmedEmailTooltip: 'Courriel non-confirmé'
};
