import {
  ChangeIntegrations,
  Column,
  Container,
  Expanded,
  InsightsButton,
  ObservablePresenter,
  Scroll,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { ChangeIntegrationsViewModel } from '@insights/viewmodels';
import { Dialog, DialogActions, DialogContent, DialogTitle, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ChangeIntegrationsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
}

export const ChangeIntegrationsDialog = observer((props: ChangeIntegrationsDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], onCancel, configId, onSuccess } = props;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createChangeIntegrations(configId, onSuccess!, onCancel!)
  );

  const strings = localizationService.localizedStrings.insights.views.connectors;
  const data = viewModel.data;
  const canApply = data.state === 'fulfilled' && data.value.hasChanges;
  const canCancel = data.state === 'fulfilled';

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog sx={sx} open={true} maxWidth="xs" fullWidth>
        <DialogTitle>{strings.changeIntegrationsTitle}</DialogTitle>
        <DialogContent>
          <ObservablePresenter
            data={data}
            loadingMessage={strings.loadingIntegrationsMessage}
            errorMessage={strings.loadingIntegrationsError}
            render={(data) => (
              <Scroll direction="y">
                <Container sx={{ p: 0.5 }}>
                  <Column>
                    <Expanded>
                      <ChangeIntegrations viewModel={data} />
                    </Expanded>
                  </Column>
                </Container>
              </Scroll>
            )}
          />
        </DialogContent>
        <DialogActions>
          <InsightsButton
            sx={{ m: 1 }}
            isDisabled={!canCancel}
            onClick={() => void (data.value as ChangeIntegrationsViewModel).cancel()}
          >
            {strings.cancelButton}
          </InsightsButton>
          <InsightsButton
            sx={{ m: 1 }}
            isDisabled={!canApply}
            isDefault
            onClick={() => void (data.value as ChangeIntegrationsViewModel).apply()}
          >
            {strings.applyButton}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
