import { ContentDefinitionUtils } from '@shared/components/utils';
import { ContentDefinitionModel } from '@shared/models/content';
import { Day, ImportantContentIcons, ImportantContentWorkloadLevels } from '@shared/models/types';
import { ContentStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import _, { uniq } from 'lodash';

export interface ContentService {
  /**
   * Gets the list of all the master tasks that are published to students.
   * This also includes the completed tasks.
   * @param configId The config Id to get the tasks from
   * @param sectionIds The section Ids to get the tasks from
   * @param fromDay The lower bound for the range of due day
   * @param toDay The upper bound for the range of due day
   * @param importantTasksOnly Determines if only the tasks flagged as important should be inlcuded
   */
  getMasterTasks(
    configId: string,
    sectionIds: string[],
    fromDay: Day,
    toDay: Day,
    importantTasksOnly: boolean
  ): Promise<ContentDefinitionModel[]>;

  /**
   * Gets the list of all the replica tasks published to students.
   * This also includes the completed tasks. Tasks that target a section without
   * schedules are excluded.
   * @param configId The config Id to get the tasks from
   * @param studentIds The student Ids to get the tasks from
   * @param studentsSectionIds
   * @param fromDay The lower bound for the range of due day
   * @param toDay The upper bound for the range of due day
   * @param importantTasksOnly Determines if only the tasks flagged as important should be inlcuded
   */
  getSlaveTasks(
    configId: string,
    studentIds: string[],
    studentsSectionIds: string[],
    fromDay: Day,
    toDay: Day,
    importantTasksOnly: boolean
  ): Promise<ContentDefinitionModel[]>;

  /**
   * Gets the list of all the master tasks that are published to students.
   * This includes exams, homeworks, minitests, orals and projects that are identified as
   * important. This also includes the completed tasks.
   * @param configId The config Id to get the tasks from
   * @param sectionIds The section Ids to get the tasks from
   * @param fromDay The lower bound for the range of due day
   * @param toDay The upper bound for the range of due day
   * @param examOnly
   */
  getImportantMasterTasks(
    configId: string,
    sectionIds: string[],
    fromDay: Day,
    toDay: Day,
    examOnly: boolean
  ): Promise<ContentDefinitionModel[]>;

  /**
   * Gets the list of all the replica tasks published to students.
   * This includes exams, homeworks, minitests, orals and projects that are identified as
   * important. This also includes the completed tasks. Tasks that target a section without
   * schedules are excluded.
   * @param configId The config Id to get the tasks from
   * @param studentIds
   * @param fromDay The lower bound for the range of due day
   * @param toDay The upper bound for the range of due day
   * @param examOnly
   * @param examOnly
   */
  getImportantSlaveTasks(
    configId: string,
    studentIds: string[],
    fromDay: Day,
    toDay: Day,
    examOnly: boolean
  ): Promise<ContentDefinitionModel[]>;
}

export class AppContentService implements ContentService {
  constructor(
    private readonly _contentStore: ContentStore,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore
  ) {}

  async getMasterTasks(
    configId: string,
    sectionIds: string[],
    fromDay: Day,
    toDay: Day,
    importantTasksOnly: boolean
  ): Promise<ContentDefinitionModel[]> {
    const contents = await this._contentStore.getContents({
      configId: configId,
      sectionIds: sectionIds,
      kindsToInclude: ['task'],
      includeCompleted: true,
      publishedState: 'publishedMaster',
      fromDay: fromDay,
      toDay: toDay,
      workloadLevelsToInclude: importantTasksOnly ? ImportantContentWorkloadLevels : undefined
    });

    return this.filterNoLoadWithDueDayInThePast(contents);
  }

  async getSlaveTasks(
    configId: string,
    studentIds: string[],
    studentsSectionIds: string[],
    fromDay: Day,
    toDay: Day,
    importantTasksOnly: boolean
  ): Promise<ContentDefinitionModel[]> {
    // We must also include auto-enrolled sections.
    const sections = await this._schoolYearConfigurationStore.getSections(configId);
    const levelEnrollTags = uniq(
      sections.filter((section) => section.gradeLevel.length > 0).map((section) => `gradeLevel=${section.gradeLevel}`)
    );
    const autoEnrolledSectionIds = sections
      .filter(
        (section) =>
          section.autoEnrollRoles.includes('student') ||
          section.autoEnrollTags.find((tag) => levelEnrollTags.includes(tag))
      )
      .map((section) => section.id);
    const sectionIds = uniq(studentsSectionIds.concat(autoEnrolledSectionIds));

    const contents = await this._contentStore.getContents({
      configId: configId,
      accountIds: studentIds,
      sectionIds: sectionIds,
      kindsToInclude: ['task'],
      includeCompleted: true,
      publishedState: 'publishedSlave',
      fromDay: fromDay,
      toDay: toDay,
      workloadLevelsToInclude: importantTasksOnly ? ImportantContentWorkloadLevels : undefined
    });

    return this.filterNoLoadWithDueDayInThePast(contents);
  }

  getImportantMasterTasks(
    configId: string,
    sectionIds: string[],
    fromDay: Day,
    toDay: Day,
    importantTasksOnly: boolean
  ): Promise<ContentDefinitionModel[]> {
    return this._contentStore.getContents({
      configId: configId,
      sectionIds: sectionIds,
      kindsToInclude: ['task'],
      includeCompleted: true,
      publishedState: 'publishedMaster',
      fromDay: fromDay,
      toDay: toDay,
      workloadLevelsToInclude: importantTasksOnly ? ImportantContentWorkloadLevels : undefined
    });
  }

  async getImportantSlaveTasks(
    configId: string,
    studentIds: string[],
    fromDay: Day,
    toDay: Day,
    examOnly: boolean
  ): Promise<ContentDefinitionModel[]> {
    const sections = await this._schoolYearConfigurationStore.getSections(configId);
    const sectionWithSchedulesIds = sections
      .filter((section) => section.schedules.length > 0)
      .map((section) => section.id);

    const tasks = await this._contentStore.getContents({
      configId: configId,
      accountIds: studentIds,
      sectionIds: sectionWithSchedulesIds,
      kindsToInclude: ['task'],
      publishedState: 'publishedSlave',
      iconsToInclude: examOnly ? ['exam'] : ImportantContentIcons,
      workloadLevelsToInclude: ImportantContentWorkloadLevels,
      includeCompleted: true,
      fromDay: fromDay,
      toDay: toDay
    });

    const studentsSectionsByStudentId = new Map(
      (
        await Promise.all(
          studentIds.map(async (studentId) => ({
            studentId,
            sections: await this._schoolYearConfigurationStore.getSectionsForStudentId(configId, studentId, true)
          }))
        )
      ).map((i) => [i.studentId, i.sections])
    );

    return tasks.filter((task) => {
      const studentSections = studentsSectionsByStudentId.get(task.ownerId) ?? [];
      return studentSections.find((section) => section.id === task.sectionId) != null;
    });
  }

  private filterNoLoadWithDueDayInThePast(contents: ContentDefinitionModel[]) {
    return _.filter(contents, (c) => !ContentDefinitionUtils.isNoLoadAndPastDue(c));
  }
}
