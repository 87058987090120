import { DayOfWeek } from '@shared/models/types';

export interface DayOfWeekStrings {
  localizedDayOfWeek(dow: DayOfWeek): string;
  shortLocalizedDayOfWeek(dow: DayOfWeek): string;
  singleLetterDayOfWeek(dow: DayOfWeek): string;
}

export const EnglishDayOfWeekStrings: DayOfWeekStrings = {
  localizedDayOfWeek: englishLocalizedDayOfWeek,
  shortLocalizedDayOfWeek: englishShortLocalizedDayOfWeek,
  singleLetterDayOfWeek: englishSingleLetterDayOfWeek
};

export const FrenchDayOfWeekStrings: DayOfWeekStrings = {
  localizedDayOfWeek: frenchLocalizedDayOfWeek,
  shortLocalizedDayOfWeek: frenchShortLocalizedDayOfWeek,
  singleLetterDayOfWeek: frenchSingleLetterDayOfWeek
};

function englishLocalizedDayOfWeek(dow: DayOfWeek): string {
  switch (dow) {
    case 'sunday':
      return 'Sunday';
    case 'monday':
      return 'Monday';
    case 'tuesday':
      return 'Tuesday';
    case 'wednesday':
      return 'Wednesday';
    case 'thursday':
      return 'Thursday';
    case 'friday':
      return 'Friday';
    case 'saturday':
      return 'Saturday';
  }
}

function englishShortLocalizedDayOfWeek(dow: DayOfWeek): string {
  switch (dow) {
    case 'sunday':
      return 'Sun';
    case 'monday':
      return 'Mon';
    case 'tuesday':
      return 'Tue';
    case 'wednesday':
      return 'Wed';
    case 'thursday':
      return 'Thu';
    case 'friday':
      return 'Fri';
    case 'saturday':
      return 'Sat';
  }
}

function englishSingleLetterDayOfWeek(dow: DayOfWeek): string {
  switch (dow) {
    case 'sunday':
      return 'S';
    case 'monday':
      return 'M';
    case 'tuesday':
      return 'T';
    case 'wednesday':
      return 'W';
    case 'thursday':
      return 'T';
    case 'friday':
      return 'F';
    case 'saturday':
      return 'S';
  }
}

function frenchLocalizedDayOfWeek(dow: DayOfWeek): string {
  switch (dow) {
    case 'sunday':
      return 'dimanche';
    case 'monday':
      return 'lundi';
    case 'tuesday':
      return 'mardi';
    case 'wednesday':
      return 'mercredi';
    case 'thursday':
      return 'jeudi';
    case 'friday':
      return 'vendredi';
    case 'saturday':
      return 'samedi';
  }
}

function frenchShortLocalizedDayOfWeek(dow: DayOfWeek): string {
  switch (dow) {
    case 'sunday':
      return 'dim.';
    case 'monday':
      return 'lun.';
    case 'tuesday':
      return 'mar.';
    case 'wednesday':
      return 'mer.';
    case 'thursday':
      return 'jeu.';
    case 'friday':
      return 'ven.';
    case 'saturday':
      return 'sam.';
  }
}

function frenchSingleLetterDayOfWeek(dow: DayOfWeek): string {
  switch (dow) {
    case 'sunday':
      return 'd';
    case 'monday':
      return 'l';
    case 'tuesday':
      return 'm';
    case 'wednesday':
      return 'm';
    case 'thursday':
      return 'j';
    case 'friday':
      return 'v';
    case 'saturday':
      return 's';
  }
}
