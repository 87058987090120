import {
  ObservablePresenter,
  PublishedTasksDetailTaskItem,
  SchoolDayHeader,
  SchoolWeekHeader,
  SectionName,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { cleanDiacritics, SectionUtils } from '@insights/utils';
import {
  Box,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { ContentDefinitionUtils, SectionUtils as SharedSectionUtils } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { SchoolDay } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { AllContentWorkloadLevels } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { chain } from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface PublishedTasksBySectionDetailProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  section: SectionModel;
  sectionStudentCount: number;
  fromDay: SchoolDay;
  toDay: SchoolDay;
  tasks: ContentDefinitionModel[];
}

export const PublishedTasksBySectionDetail = observer((props: PublishedTasksBySectionDetailProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const {
    sx = [],
    className,
    style,
    fromDay,
    toDay,
    tasks,
    section,
    sectionStudentCount,
    configId,
    onCancel,
    onSuccess
  } = props;
  const workloadStrings = localizationService.localizedStrings.insights.views.metrics.workload;
  const tasksStrings = localizationService.localizedStrings.insights.components.tasks;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createPublishedTasksBySectionDetail(configId, section, sectionStudentCount, tasks, onSuccess!, onCancel!)
  );

  const isWeek = !fromDay.day.isSame(toDay.day);

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        maxWidth="md"
        fullWidth={true}
        onClose={() => void viewModel.close()}
        scroll="paper"
        className={className}
        style={style}
      >
        <DialogTitle>
          {/* Ensure the overflow is visible so that the badge is displayed */}
          <Box display="flex" flexDirection="row" alignItems="center" overflow="visible">
            <SectionName
              color={viewModel.section.color}
              title={SectionUtils.formatTitle(viewModel.section)}
              subInformation1={viewModel.section.sectionNumber}
              subInformation2={workloadStrings.localizedNumberOfStudents(viewModel.sectionStudentCount)}
              size="medium"
            />

            <Box flex={1} />

            {!isWeek && <SchoolDayHeader schoolDay={fromDay} displayType="page-header" />}
            {isWeek && <SchoolWeekHeader schoolDay={fromDay} displayType="page-header" />}
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <ObservablePresenter
            sx={{ minHeight: 100 }}
            data={viewModel.data}
            render={(data) => (
              <Box height="100%" width="100%">
                {chain(data.sections)
                  .orderBy((sectionInfo) => [
                    cleanDiacritics(SectionUtils.formatTitle(sectionInfo.section)).toLowerCase(),
                    cleanDiacritics(sectionInfo.section.sectionNumber).toLowerCase()
                  ])
                  .map((sectionInfo, index) => (
                    <Box key={sectionInfo.section.id} marginTop={index > 0 ? 4 : 0}>
                      <Box bgcolor={grey[100]}>
                        <SectionName
                          title={SectionUtils.formatTitle(sectionInfo.section)}
                          subInformation1={sectionInfo.section.sectionNumber}
                          subInformation2={SharedSectionUtils.getSectionShortTeacherInfo(
                            sectionInfo.section,
                            sectionInfo.teachers,
                            tasksStrings.noTeacher
                          )}
                          color={sectionInfo.section.color}
                          mode="one-line"
                          fontWeight="400"
                        />
                      </Box>

                      <Box borderLeft={`4px solid ${SectionColors.get(sectionInfo.section.color)}`} paddingLeft={1}>
                        {chain(sectionInfo.tasks)
                          .orderBy(
                            (t) => [
                              AllContentWorkloadLevels.indexOf(t.task.workloadLevel),
                              cleanDiacritics(
                                ContentDefinitionUtils.getDisplayTitleForContent(
                                  t.task,
                                  localizationService.localizedStrings
                                )
                              ).toLowerCase()
                            ],
                            ['desc', 'asc']
                          )
                          .map((taskInfo) => (
                            <PublishedTasksDetailTaskItem
                              key={taskInfo.task.id}
                              sx={{ my: 0.5 }}
                              taskInfo={taskInfo}
                              onClick={() =>
                                void viewModel.showDetail(
                                  sectionInfo,
                                  taskInfo,
                                  data.schoolYearConfiguration,
                                  data.sectionsById
                                )
                              }
                              schoolYearConfig={data.schoolYearConfiguration}
                              sectionsById={data.sectionsById}
                            />
                          ))
                          .value()}
                      </Box>
                    </Box>
                  ))
                  .value()}
              </Box>
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => void viewModel.close()}>
            {workloadStrings.close}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
