import { AutoMatchEntry as PBAutoMatchEntry } from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';

import { AutoMatchEntry } from './interfaces';

export class GrpcAutoMatchEntry implements AutoMatchEntry {
  constructor(private readonly _pb: PBAutoMatchEntry) {}

  get studyoPattern(): string {
    return this._pb.studyoPattern;
  }

  get externalPattern(): string {
    return this._pb.externalPattern;
  }

  toProtobuf(): PBAutoMatchEntry {
    return this._pb.clone();
  }
}
