import { OQValue } from '@shared/models/types';
import { action, computed, makeObservable, observable } from 'mobx';

export interface StudentsFilters {
  behaviour: StudentsBehaviourFilters;
}

export interface StudentsBehaviourFilters {
  appOpenFilter?: OQValue;
  taskCompletionFilter?: OQValue;
  taskBreakdownFilter?: OQValue;
  planningFilter?: OQValue;
}

export interface StudentsFilterDialogViewModel {
  filters: StudentsFilters;

  clear: () => void;
  close: () => void;
}

export const EmptyStudentsFilters: StudentsFilters = {
  behaviour: {}
};

export class AppStudentsFilterDialogViewModel implements StudentsFilterDialogViewModel {
  @observable private _filters: StudentsFilters;

  constructor(
    private readonly _onSuccess: (filters: StudentsFilters) => void,
    private readonly _onCancel: () => void,
    initialFilters: StudentsFilters = EmptyStudentsFilters
  ) {
    makeObservable(this);
    this._filters = initialFilters;
  }

  @computed.struct
  get filters(): StudentsFilters {
    return this._filters;
  }

  set filters(value: StudentsFilters) {
    this._filters = value;
  }

  @action
  clear() {
    this.filters = EmptyStudentsFilters;
  }

  close() {
    this._onSuccess(this.filters);
  }
}
