import { GetPublishingTaskWorkloadImpactResponse } from '@buf/studyo_studyo.bufbuild_es/studyo/services/contents_pb';
import {
  UseOnboardingCodeResponse,
  ValidateOnboardingCodeResponse
} from '@buf/studyo_studyo.bufbuild_es/studyo/services/schools_pb';
import { Account, AccountSummary } from '@buf/studyo_studyo.bufbuild_es/studyo/type_account_pb';
import {
  CourseOccurrenceConfiguration_Customization,
  SchoolYearConfiguration,
  SchoolYearConfigurationSummary
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import {
  ContentDefinition,
  ContentDefinition_Attachment,
  ContentDefinition_AttachmentKind
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { MoveCourseOccurrencesParamsModel } from '@shared/models/calendar';
import { ContentAttachmentUploadUrlResponse } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { computed, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { ContentTransport, FetchContentsParameters, SchoolTransport, SyncTokenResult } from '../../transports';

export class DemoInterceptorError extends Error {}

export function isDemoError(error: Error): boolean {
  return error instanceof DemoInterceptorError;
}

export class DemoSchoolInterceptor implements SchoolTransport, ContentTransport {
  @observable private _isPreventingChanges = true;

  constructor(
    private readonly _configTransport: SchoolTransport,
    private readonly _contentTransport: ContentTransport
  ) {
    makeObservable(this);
  }

  @computed
  get isPreventingChanges() {
    return this._isPreventingChanges;
  }

  set isPreventingChanges(value: boolean) {
    this._isPreventingChanges = value;
  }

  moveCourseOccurrences(params: MoveCourseOccurrencesParamsModel): Promise<void> {
    // This is not supported when preventing changes, because we can't update
    // local data accordingly.
    if (!this._isPreventingChanges) {
      return this._configTransport.moveCourseOccurrences(params);
    }

    throw new DemoInterceptorError('Moving course occurrences is not supported in demo mode.');
  }

  customizeCourseOccurrence(
    configId: string,
    sectionId: string,
    customization: CourseOccurrenceConfiguration_Customization
  ): Promise<void> {
    // This is not supported when preventing changes, because we can't update
    // local data accordingly.
    if (!this._isPreventingChanges) {
      return this._configTransport.customizeCourseOccurrence(configId, sectionId, customization);
    }

    throw new DemoInterceptorError('Customizing course occurrences is not supported in demo mode.');
  }

  fetchConfig(configId: string, anonymize: boolean, syncToken?: string): Promise<SchoolYearConfiguration | undefined> {
    return this._configTransport.fetchConfig(configId, anonymize, syncToken);
  }

  fetchConfigSummary(configId: string, anonymize: boolean): Promise<SchoolYearConfigurationSummary> {
    return this._configTransport.fetchConfigSummary(configId, anonymize);
  }

  fetchConfigs(startYear: number, syncToken?: string): Promise<SyncTokenResult<SchoolYearConfigurationSummary[]>> {
    return this._configTransport.fetchConfigs(startYear, syncToken);
  }

  validateConfig(configId: string): Promise<string[]> {
    return this._configTransport.validateConfig(configId);
  }

  fetchAccount(accountId: string, anonymize: boolean): Promise<Account | undefined> {
    return this._configTransport.fetchAccount(accountId, anonymize);
  }

  fetchAccountSummary(accountId: string, anonymize: boolean): Promise<AccountSummary | undefined> {
    return this._configTransport.fetchAccountSummary(accountId, anonymize);
  }

  fetchAccounts(configId: string, anonymize: boolean, syncToken?: string): Promise<SyncTokenResult<Account[]>> {
    return this._configTransport.fetchAccounts(configId, anonymize, syncToken);
  }

  createOrUpdateConfig(config: SchoolYearConfiguration): Promise<SchoolYearConfiguration> {
    // Only called by Insights. Play fair if not preventing changes.
    if (!this._isPreventingChanges) {
      return this._configTransport.createOrUpdateConfig(config);
    }

    throw new DemoInterceptorError('Creating or updating a configuration is not supported in demo mode.');
  }

  createOrUpdateAccount(account: Account): Promise<Account> {
    if (!this._isPreventingChanges) {
      return this._configTransport.createOrUpdateAccount(account);
    }

    console.log('Preventing call to "createOrUpdateAccount".');
    const newAccount = account.clone();

    if (newAccount.id.length === 0) {
      newAccount.id = uuidv4();
    }

    // Setting a dummy sync token will prevent this entity from being later pushed successfully
    // to the server.
    newAccount.syncToken = uuidv4();

    return Promise.resolve(newAccount);
  }

  deleteAccount(account: Account): Promise<void> {
    // Only called by Insights. Play fair if not preventing changes.
    if (!this._isPreventingChanges) {
      return this._configTransport.deleteAccount(account);
    }

    throw new DemoInterceptorError('Deleting an account is not supported in demo mode.');
  }

  undeleteAccount(account: Account): Promise<void> {
    // Only called by Insights. Play fair if not preventing changes.
    if (!this._isPreventingChanges) {
      return this._configTransport.undeleteAccount(account);
    }

    throw new DemoInterceptorError('Undeleting an account is not supported in demo mode.');
  }

  purgeDeletedAccounts(configId: string): Promise<number> {
    // Only called by Insights. Play fair if not preventing changes.
    if (!this._isPreventingChanges) {
      return this._configTransport.purgeDeletedAccounts(configId);
    }

    throw new DemoInterceptorError('Purging deleted accounts is not supported in demo mode.');
  }

  useOnboardingCode(code: string): Promise<UseOnboardingCodeResponse> {
    // Though it's technically possible to onboard a new school while on another demo school,
    // we do not allow this while preventing changes.
    if (!this._isPreventingChanges) {
      return this._configTransport.useOnboardingCode(code);
    }

    throw new DemoInterceptorError('Using an onboarding code is not supported in demo mode.');
  }

  validateOnboardingCode(code: string): Promise<ValidateOnboardingCodeResponse> {
    return this._configTransport.validateOnboardingCode(code);
  }

  inviteParent(studentId: string, email: string): Promise<void> {
    // This does not affect the current Account instance. It creates (or updates) a parent account.
    return this._configTransport.inviteParent(studentId, email);
  }

  invalidateCachedConfig(configId: string): Promise<void> {
    // Only used by Insights.
    return this._configTransport.invalidateCachedConfig(configId);
  }

  fetchContent(contentId: string): Promise<ContentDefinition> {
    return this._contentTransport.fetchContent(contentId);
  }

  fetchMultipleContents(contentIds: string[]): Promise<ContentDefinition[]> {
    return this._contentTransport.fetchMultipleContents(contentIds);
  }

  fetchContents(parameters: FetchContentsParameters): Promise<SyncTokenResult<ContentDefinition[]>> {
    return this._contentTransport.fetchContents(parameters);
  }

  saveOrUpdateContent(content: ContentDefinition): Promise<ContentDefinition> {
    if (!this._isPreventingChanges) {
      return this._contentTransport.saveOrUpdateContent(content);
    }

    console.log('Preventing call to "saveOrUpdateContent".');
    const newContent = content.clone();

    if (newContent.id.length === 0) {
      newContent.id = uuidv4();
    }

    // Setting a dummy sync token will prevent this entity from being later pushed successfully
    // to the server.
    newContent.syncToken = uuidv4();

    return Promise.resolve(newContent);
  }

  publishContent(content: ContentDefinition): Promise<ContentDefinition> {
    if (!this._isPreventingChanges) {
      return this._contentTransport.publishContent(content);
    }

    console.log('Preventing call to "publishContent".');
    const newContent = content.clone();

    // Setting a dummy sync token will prevent this entity from being later pushed successfully
    // to the server.
    newContent.syncToken = uuidv4();

    return Promise.resolve(newContent);
  }

  async markContentAsRead(contentId: string): Promise<ContentDefinition> {
    if (!this._isPreventingChanges) {
      return await this._contentTransport.markContentAsRead(contentId);
    }

    console.log('Preventing call to "markContentAsRead".');

    // We don't have access to the cache.
    const content = await this._contentTransport.fetchContent(contentId);
    content.isUnread = false;
    return content;
  }

  updateContentAttachment(
    configId: string,
    ownerId: string,
    attachment: ContentDefinition_Attachment
  ): Promise<ContentDefinition_Attachment> {
    if (!this._isPreventingChanges) {
      return this._contentTransport.updateContentAttachment(configId, ownerId, attachment);
    }

    console.log('Preventing call to "updateContentAttachment".');
    const newAttachment = attachment.clone();

    newAttachment.thumbUrl = 'https://picsum.photos/128';

    if (newAttachment.kind === ContentDefinition_AttachmentKind.DOCUMENT_URL) {
      newAttachment.title = 'Lorem ipsum title';
    }

    return Promise.resolve(newAttachment);
  }

  getUploadUrlForContentAttachment(
    configId: string,
    ownerId: string,
    attachment: ContentDefinition_Attachment
  ): Promise<ContentAttachmentUploadUrlResponse> {
    // We allow uploading new attachments in demo mode, but these are lost storage once we refresh.
    return this._contentTransport.getUploadUrlForContentAttachment(configId, ownerId, attachment);
  }

  getPublishingTaskWorkloadImpact(
    configId: string,
    sectionId: string,
    dueDay: Day,
    targetAccountIds: string[],
    ignoredMasterTaskId?: string
  ): Promise<GetPublishingTaskWorkloadImpactResponse> {
    // The returned info might not be up to date, but no impact.
    return this._contentTransport.getPublishingTaskWorkloadImpact(
      configId,
      sectionId,
      dueDay,
      targetAccountIds,
      ignoredMasterTaskId
    );
  }
}
