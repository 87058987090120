import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { SchoolCalendarViewModel } from '../../viewmodels';
import { EditableCycleDayCount } from './EditableCycleDayCount';
import { EditableCycleDayTitles } from './EditableCycleDayTitles';
import { EditableDialogActions } from './EditableDialogActions';

export interface CycleDaysDialogProps {
  viewModel: SchoolCalendarViewModel;
}

export const CycleDaysDialog = observer(({ viewModel }: CycleDaysDialogProps) => {
  const { localizationService } = useInsightsServices();
  const edition = viewModel.cycleDaysEditor;
  const strings = localizationService.localizedStrings.insights.components.calendar;
  const editViewModel = edition.active;

  if (editViewModel == null) {
    return <div />;
  }

  return (
    <Dialog open={true} onClose={() => void edition.cancel()} maxWidth="sm" fullWidth={true}>
      <DialogTitle>{strings.editCycleDaysTitle}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <EditableCycleDayCount viewModel={editViewModel} style={{ marginBottom: 16 }} />
          <EditableCycleDayTitles viewModel={editViewModel} />
        </Box>
      </DialogContent>
      <DialogActions>
        <EditableDialogActions edition={edition} hasChanges={editViewModel.hasChanges} />
      </DialogActions>
    </Dialog>
  );
});
