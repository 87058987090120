import { LocalizationService } from '@shared/resources/services';
import { VeracrossV3ConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface VeracrossV3AccountViewModel extends ExternalAccountEditionViewModel {
  accountName: string;
  clientId: string;
  clientSecret: string;
  schoolRoute: string;
  schoolYear: number;
  portalUrl: string;
}

export class AppVeracrossV3AccountViewModel
  extends BaseExternalAccountEditionViewModel
  implements VeracrossV3AccountViewModel
{
  @observable private _accountName?: string;
  @observable private _clientId?: string;
  @observable private _clientSecret?: string;
  @observable private _schoolRoute?: string;
  @observable private _schoolYear?: number;
  @observable private _portalUrl?: string;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _veracrossStore: VeracrossV3ConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    private readonly _originalAccountName: string,
    private readonly _originalClientId: string,
    private readonly _originalClientSecret: string,
    private readonly _originalschoolRoute: string,
    private readonly _originalschoolYear: number,
    private readonly _originalPortalUrl: string
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get accountName() {
    return this._accountName ?? this._originalAccountName;
  }

  set accountName(value: string) {
    this._accountName = value;
    this.onChange();
  }

  @computed
  get clientId() {
    return this._clientId ?? this._originalClientId;
  }

  set clientId(value: string) {
    this._clientId = value;
    this.onChange();
  }

  @computed
  get clientSecret() {
    return this._clientSecret ?? this._originalClientSecret;
  }

  set clientSecret(value: string) {
    this._clientSecret = value;
    this.onChange();
  }

  @computed
  get schoolRoute() {
    return this._schoolRoute ?? this._originalschoolRoute;
  }

  set schoolRoute(value: string) {
    this._schoolRoute = value;
    this.onChange();
  }

  @computed
  get schoolYear() {
    return this._schoolYear ?? this._originalschoolYear;
  }

  set schoolYear(value: number) {
    this._schoolYear = value;
    this.onChange();
  }

  @computed
  get portalUrl() {
    return this._portalUrl ?? this._originalPortalUrl;
  }

  set portalUrl(value: string) {
    this._portalUrl = value;
    this.onChange();
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this._hasChanges) {
      console.error('Applying without changes. Ignoring...');
      this._onSuccess();
      return;
    }

    // The "original" secret is not really the secret, but a placeholder. When
    // editing details (other than the school year), the user must re-enter the
    // secret. Prevent at least dumb copy-paste.
    if (this._clientSecret === this._originalClientSecret) {
      this.addError(strings.enterClientSecretAgain);
      return;
    }

    this.beginApplying();

    try {
      await this._veracrossStore.createOrUpdateVeracrossAccount(
        this._configId,
        this._externalAccountId,
        this.accountName,
        this.clientId,
        this.clientSecret,
        this.schoolRoute,
        this.schoolYear,
        this.portalUrl
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._accountName = undefined;
    this._clientId = undefined;
    this._clientSecret = undefined;
    this._schoolRoute = undefined;
    this._schoolYear = undefined;
    this._portalUrl = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this._hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
