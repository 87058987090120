import { GrpcVeracrossExternalAccountDetails, VeracrossExternalAccountDetails } from '@shared/models/connectors';
import { VeracrossTransport } from '../../../transports';
import { ConnectorsStore, VeracrossConnectorStore } from '../../interfaces';
import { AppBaseStore } from '../AppBaseStore';

export class AppVeracrossConnectorStore extends AppBaseStore implements VeracrossConnectorStore {
  constructor(
    private readonly _transport: VeracrossTransport,
    connectorStore: ConnectorsStore
  ) {
    super('AppVeracrossConnectorStore', false, connectorStore);
  }

  async getVeracrossAccountDetails(externalAccountId: string): Promise<VeracrossExternalAccountDetails> {
    const details = await this._transport.fetchVeracrossExternalAccountDetails(externalAccountId);

    return new GrpcVeracrossExternalAccountDetails(details);
  }

  async createOrUpdateVeracrossAccount(
    configId: string,
    externalAccountId: string,
    accountName: string,
    serverUrl: string,
    username: string,
    password: string
  ): Promise<void> {
    await this._transport.createOrUpdateVeracrossExternalAccount(
      externalAccountId,
      configId,
      serverUrl,
      username,
      password,
      accountName
    );
    this.invalidate();
  }
}
