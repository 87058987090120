import { css } from '@emotion/css';
import { Autocomplete, AutocompleteProps, ChipTypeMap, createFilterOptions } from '@mui/material';
import { ElementType } from 'react';

export interface SuggestedOption {
  value: string;
  displayName?: string;
}

const filter = createFilterOptions<string | SuggestedOption>();

export interface AutosuggestProps<ChipComponent extends ElementType = ChipTypeMap['defaultComponent']>
  extends AutocompleteProps<SuggestedOption | string, false, false, true, ChipComponent> {
  getNewValueDisplayName?: (value: string) => string;
}

export const Autosuggest = ({
  sx = [],
  className,
  style,
  value,
  options,
  getNewValueDisplayName = (v) => v,
  renderInput,
  ...others
}: AutosuggestProps) => {
  const popperClassName = css([{ minWidth: 'min-content' }]);

  return (
    <Autocomplete
      sx={sx}
      className={className}
      style={style}
      classes={{ popper: popperClassName }}
      freeSolo
      options={options}
      value={value}
      isOptionEqualToValue={(o, v) => (typeof o === 'string' ? o : o.value) === (typeof v === 'string' ? v : v.value)}
      filterOptions={(o, p) => {
        const filtered = filter(o, p);
        const { inputValue } = p;

        if (inputValue.length > 0 && options.find((o) => (typeof o === 'string' ? o : o.value) == inputValue) == null) {
          filtered.push({ value: inputValue, displayName: getNewValueDisplayName(inputValue) });
        }

        return filtered;
      }}
      getOptionLabel={(o) => (typeof o === 'string' ? o : (o.displayName ?? o.value))}
      renderInput={renderInput}
      {...others}
    />
  );
};
