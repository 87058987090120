import {
  CurrentPageInfo,
  ObservablePresenter,
  PageFooter,
  PageHeaderBar,
  PageHeaderDetailBar,
  WeekPagingNavigation
} from '@insights/components';
import { TeacherDashboardViewModel } from '@insights/viewmodels';
import FilterIcon from '@mui/icons-material/FilterList';
import NotesIcon from '@mui/icons-material/NoteAlt';
import { Badge, Box, Card, Grid2, IconButton, Link, SxProps, Tooltip, Typography } from '@mui/material';
import { AccountUtils, isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect } from 'react';
import { useInsightsServices } from '../../../../UseInsightsServicesHook';
import { PublishedTasksBySection } from '../PublishedTasksBySection';
import { SectionsCourseOccurrencesStatus } from '../SectionsCourseOccurrencesStatus';
import { WorkloadManagerBySection } from '../WorkloadManagerBySection';

export interface TeacherDashboardProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: TeacherDashboardViewModel;
}

export const TeacherDashboard = observer((props: TeacherDashboardProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const { dashboards: dashboardStrings, teachers: teachersStrings } =
    localizationService.localizedStrings.insights.views.metrics;

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Teacher Dashboard' });
  });

  return (
    <Box
      className={className}
      style={style}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <PageHeaderBar />
      <Box
        sx={{
          flex: 1
        }}
      >
        <ObservablePresenter
          sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
          data={viewModel.data}
          loadingMessage={dashboardStrings.loadingMessage}
          errorMessage={dashboardStrings.errorMessage}
          render={(data) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                overflow: 'hidden'
              }}
            >
              {/* Teacher Info */}
              <PageHeaderDetailBar>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  {/* Teacher name and email */}
                  {data.accountInfo && (
                    <Box>
                      <Typography sx={{ fontSize: '2em', lineHeight: 'normal', fontWeight: 300 }}>
                        {AccountUtils.getDisplayLastFirstName(data.accountInfo.account, teachersStrings.noName)}
                      </Typography>
                      <Link variant="body1" target="_top" href={`mailto:${data.accountInfo.account.email}`}>
                        {data.accountInfo.account.email}
                      </Link>
                    </Box>
                  )}

                  <Box
                    sx={{
                      flex: 1
                    }}
                  />

                  {viewModel.exports?.canExportNotes === true && (
                    <Tooltip title={teachersStrings.exportNotesTooltip}>
                      <IconButton sx={{ marginRight: 2 }} onClick={() => void viewModel.exports!.exportNotes()}>
                        <NotesIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip title={teachersStrings.filterButtonTooltip}>
                    <IconButton onClick={() => void viewModel.showFilters()}>
                      <Badge badgeContent={data.filterCount} color="primary" showZero={false} variant="dot">
                        <FilterIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Box>
              </PageHeaderDetailBar>

              <Box
                sx={{
                  flex: 1,
                  overflow: 'auto',
                  padding: 2
                }}
              >
                <Grid2 container>
                  {/* Course Occurrences Status */}
                  <Grid2 size={{ xs: 12 }}>
                    <Card>
                      <SectionsCourseOccurrencesStatus
                        sx={{ width: '100%', height: '100%' }}
                        viewModel={viewModelFactory.createSectionsCourseOccurrencesStatus(
                          viewModel.configId,
                          data.displayedSectionIds,
                          viewModel.pagination
                        )}
                        displaySectionName={true}
                        displayWeekNavigation={false}
                      />
                    </Card>
                  </Grid2>

                  {/* Published Tasks */}
                  <Grid2 size={{ xs: 12 }}>
                    <Card>
                      <PublishedTasksBySection
                        sx={{ width: '100%', height: '100%' }}
                        viewModel={viewModelFactory.createPublishedTasksBySection(
                          viewModel.configId,
                          data.displayedSectionIds,
                          viewModel.pagination
                        )}
                        displaySectionName={true}
                        displayWeekNavigation={false}
                      />
                    </Card>
                  </Grid2>

                  {/* Workload Manager */}
                  <Grid2 size={{ xs: 12 }}>
                    <Card>
                      <WorkloadManagerBySection
                        sx={{ width: '100%', height: '100%' }}
                        viewModel={viewModelFactory.createWorkloadManagerBySection(
                          viewModel.configId,
                          data.displayedSectionIds,
                          viewModel.pagination
                        )}
                        displaySectionName={true}
                        displayWeekNavigation={false}
                      />
                    </Card>
                  </Grid2>
                </Grid2>
              </Box>

              <PageFooter dense>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <CurrentPageInfo pagination={viewModel.pagination} />

                  <Box
                    sx={{
                      flex: 1
                    }}
                  />

                  <WeekPagingNavigation pagination={viewModel.pagination} />
                </Box>
              </PageFooter>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
