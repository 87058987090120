import { Label } from '@insights/components';
import { SchoolYearConfigurationScreenInfo } from '@insights/viewmodels';
import { Grid2, Link, SxProps, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties, Fragment } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SchoolYearConfigurationLinksProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  data: SchoolYearConfigurationScreenInfo;
}

export const SchoolYearConfigurationLinks = observer((props: SchoolYearConfigurationLinksProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], style, data } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;

  return (
    <Grid2 sx={sx} className={className} style={style} container>
      {/* Position Column Title */}
      <Grid2 size={{ xs: 2, xl: 1 }}>
        <Label>{strings.linkPositionColumnTitle}</Label>
      </Grid2>
      {/* Title Column Title */}
      <Grid2 size={{ xs: 4, xl: 3 }}>
        <Label>{strings.linkTitleColumnTitle}</Label>
      </Grid2>
      {/* Link Column Title */}
      <Grid2 size={{ xs: 6, xl: 8 }}>
        <Label>{strings.linkUrlColumnTitle}</Label>
      </Grid2>

      {orderBy(data.schoolYearConfiguration.links, (l) => l.sortPosition).map((link) => (
        <Fragment key={link.sortPosition}>
          {/* Position */}
          <Grid2 size={{ xs: 2, xl: 1 }}>
            <Typography>{link.sortPosition}</Typography>
          </Grid2>
          {/* Title */}
          <Grid2 size={{ xs: 4, xl: 3 }}>
            <Typography>{link.text}</Typography>
          </Grid2>
          {/* Link */}
          <Grid2 size={{ xs: 6, xl: 8 }}>
            <Link href={link.url} target="_blank" variant="body1">
              {link.url}
            </Link>
          </Grid2>
        </Fragment>
      ))}
    </Grid2>
  );
});
