export const MinimumDaysPerCycle = 1;
export const MaximumDaysPerCycle = 50;
export const MaximumCycleDayTitleLength = 3;

export const MaximumScheduleTitleLength = 50;
export const MaximumScheduleTagLength = 10;
export const MaximumSchedulePeriodTagLength = 25;

export const MaximumSpecialDayTitleLength = 50;

export const MaximumTermTagLength = 10;

export const MaximumSectionImportIdLength = 25;
export const MaximumSectionTitleLength = 50;
export const MaximumSectionShortTitleLength = 3;
export const MaximumSectionGradeLevelLength = 10;
export const MaximumSectionGroupLength = 25;
export const MaximumAssociatedSectionNumbersLength = 100;
export const MaximumSectionRoomNameLength = 25;
export const MaximumSectionAutoEnrollTagLength = 25;

export const MaximumSchoolNameLength = 100;
export const MaximumSchoolTitleLength = 25;
export const MaximumCommentsLength = 4096;
export const MaximumOnboardingCodeLength = 25;
export const MaximumLinkTitleLength = 25;
export const MaximumLinkUrlLength = 4096;
