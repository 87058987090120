import { AlertService } from '@insights/services';
import {
  CourseOccurrence,
  EditableCourseOccurrenceCustomization,
  MoveCourseOccurrencesParamsModel,
  SchoolDay,
  SchoolDayPeriod
} from '@shared/models/calendar';
import { AccountModel, SectionModel } from '@shared/models/config';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { CalendarStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface TeacherPlanningCell {
  readonly schoolDay: SchoolDay;
  readonly period?: SchoolDayPeriod;
  readonly occurrence?: CourseOccurrence;
}

export interface TeacherPlanningRow {
  readonly section: SectionModel;
  readonly occurrences: TeacherPlanningCell[];
  readonly isFirst: boolean;
}

export interface TeacherPlanningViewModel {
  startingDay: Day;
  readonly currentDay: Day;
  readonly visibleSchoolDays: SchoolDay[];
  readonly visibleDays: Day[];
  readonly teacher: AccountModel;
  readonly rows: TeacherPlanningRow[];

  readonly previousDay?: Day;
  readonly nextDay?: Day;
  readonly previousStartOfWeek?: Day;
  readonly nextStartOfWeek?: Day;

  readonly movingRightCell?: TeacherPlanningCell;
  readonly movingLeftCell?: TeacherPlanningCell;
  moveTitlesRight: boolean;
  moveTitlesLeft: boolean;
  moveUntil: Day;
  skipCurrent: boolean;
  unskipCurrent: boolean;
  currentTitle: string;
  readonly defaultTitle: string;
  currentRoomName: string;

  startMoveRight(cell: TeacherPlanningCell): void;
  startMoveLeft(cell: TeacherPlanningCell): void;

  confirmMoveRight(): Promise<void>;
  confirmMoveLeft(): Promise<void>;
  cancel(): void;
}

export interface TeacherPlanningDialogViewModel {
  readonly data: IPromiseBasedObservable<TeacherPlanningViewModel>;

  close(): void;
}

export class AppTeacherPlanningViewModel implements TeacherPlanningViewModel {
  @observable private _schoolDays: SchoolDay[];
  @observable private _startingDayIndex: number;

  @observable private _movingRightCell?: TeacherPlanningCell;
  @observable private _movingLeftCell?: TeacherPlanningCell;
  @observable private _moveTitlesRight = false;
  @observable private _moveTitlesLeft = false;
  @observable private _moveUntil: Day;
  @observable private _skipCurrent = false;
  @observable private _unskipCurrent = false;
  @observable private _currentTitle = '';
  @observable private _currentRoomName = '';

  constructor(
    private readonly _calendarStore: CalendarStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _sectionsById: Record<string, SectionModel>,
    public readonly teacher: AccountModel,
    private readonly _sectionIds: string[],
    schoolDays: SchoolDay[],
    startingDay?: Day
  ) {
    makeObservable(this);
    this._schoolDays = schoolDays;
    this._startingDayIndex = this.getSchoolDayIndex(startingDay ?? Day.fromDate(new Date())!);
    this._moveUntil = schoolDays[schoolDays.length - 1]?.day ?? Day.fromDate(new Date());
  }

  @computed
  get startingDay(): Day {
    return this._schoolDays[this._startingDayIndex]?.day ?? Day.fromDate(new Date())!;
  }

  set startingDay(value: Day) {
    this._startingDayIndex = this.getSchoolDayIndex(value);
  }

  get currentDay(): Day {
    return Day.fromDate(new Date())!;
  }

  @computed
  get visibleSchoolDays(): SchoolDay[] {
    let index = this._schoolDays.findIndex((sd) => sd.day.isSame(this.startingDay));

    if (index === -1) {
      return [];
    }

    const schoolDays: SchoolDay[] = [];

    // We return up to 15 school days but the view is consuming what can fit in.
    while (index < this._schoolDays.length && schoolDays.length < 15) {
      const sd = this._schoolDays[index];

      if (sd.cycleDay !== 0) {
        schoolDays.push(sd);
      }

      index++;
    }

    return schoolDays;
  }

  @computed
  get visibleDays() {
    return this.visibleSchoolDays.map((sd) => sd.day);
  }

  @computed
  get maxPeriodPerDay(): Record<string, number> {
    const maxCountBySectionId: Record<string, number> = {};

    this._schoolDays.forEach((sd) => {
      const countsBySectionId = _.countBy(_.flatten(sd.periods.map((p) => p.courseOccurrences)), (co) => co.sectionId);

      this._sectionIds.forEach(
        (sectionId) =>
          (maxCountBySectionId[sectionId] = Math.max(
            maxCountBySectionId[sectionId] ?? 0,
            countsBySectionId[sectionId] ?? 0
          ))
      );
    });

    return maxCountBySectionId;
  }

  @computed
  get rows(): TeacherPlanningRow[] {
    return _.flatten(
      this.sortedSections.map((section) => {
        const rowCount = this.maxPeriodPerDay[section.id];

        if (rowCount === 0) {
          // This section never has occurrences.
          return [];
        }

        const visibleSchoolDays = this.visibleSchoolDays;
        const rows = _.range(0, rowCount).map<TeacherPlanningRow>((i) => ({
          section,
          occurrences: visibleSchoolDays.map((schoolDay) => ({ schoolDay })),
          isFirst: i === 0
        }));

        this.visibleSchoolDays.forEach((schoolDay, i) =>
          _.flatten(
            schoolDay.periods.map((period) =>
              period.courseOccurrences
                .filter((occurrence) => occurrence.sectionId === section.id)
                .map((occurrence) => ({ period, occurrence }))
            )
          ).forEach(
            (pair, j) =>
              (rows[j].occurrences[i] = {
                schoolDay,
                period: pair.period,
                occurrence: pair.occurrence
              })
          )
        );

        return rows;
      })
    );
  }

  @computed
  get previousDay(): Day | undefined {
    let index = this._startingDayIndex;

    while (--index >= 0) {
      const sd = this._schoolDays[index];

      if (sd.cycleDay > 0) {
        return sd.day;
      }
    }

    return undefined;
  }

  @computed
  get nextDay(): Day | undefined {
    let index = this._startingDayIndex;

    while (++index < this._schoolDays.length) {
      const sd = this._schoolDays[index];

      if (sd.cycleDay > 0) {
        return sd.day;
      }
    }

    return undefined;
  }

  @computed
  get previousStartOfWeek(): Day | undefined {
    let index = this._startingDayIndex;
    let state: 'on-starting-day' | 'on-first-weekend' | 'looking-for-same-week-start' | 'on-previous-week' =
      'on-starting-day';
    let previousSchoolDay = this._schoolDays[index];

    while (--index >= 0) {
      const sd = this._schoolDays[index];
      const isSchoolDay = sd.cycleDay > 0;

      switch (state) {
        case 'on-starting-day':
          // If the day before is not a weekend, we want to stop on the first day of the same week.
          if (!isSchoolDay) {
            state = 'on-first-weekend';
          } else {
            state = 'looking-for-same-week-start';
          }
          break;
        case 'looking-for-same-week-start':
          if (!isSchoolDay) {
            // The previous day was the one!
            return previousSchoolDay.day;
          }
          break;
        case 'on-first-weekend':
          if (isSchoolDay) {
            state = 'on-previous-week';
          }
          break;
        case 'on-previous-week':
          if (!isSchoolDay) {
            // The previous day was the one!
            return previousSchoolDay.day;
          }
      }

      previousSchoolDay = sd;
    }

    return undefined;
  }

  @computed
  get nextStartOfWeek(): Day | undefined {
    let index = this._startingDayIndex;
    let state: 'on-starting-week' | 'on-first-weekend' = 'on-starting-week';

    while (++index < this._schoolDays.length) {
      const sd = this._schoolDays[index];
      const isSchoolDay = sd.cycleDay > 0;

      switch (state) {
        case 'on-starting-week':
          if (!isSchoolDay) {
            state = 'on-first-weekend';
          }
          break;
        case 'on-first-weekend':
          if (isSchoolDay) {
            // We found the start of the next week.
            return sd.day;
          }
          break;
      }
    }

    return undefined;
  }

  @computed
  get movingRightCell(): TeacherPlanningCell | undefined {
    return this._movingRightCell;
  }

  @computed
  get movingLeftCell(): TeacherPlanningCell | undefined {
    return this._movingLeftCell;
  }

  @computed
  get moveTitlesRight(): boolean {
    return this._moveTitlesRight;
  }

  set moveTitlesRight(value: boolean) {
    this._moveTitlesRight = value;
  }

  @computed
  get moveTitlesLeft(): boolean {
    return this._moveTitlesLeft;
  }

  set moveTitlesLeft(value: boolean) {
    this._moveTitlesLeft = value;

    // We could update/empty _currentTitle when this is set, since it doesn't make sense
    // when moving left, but the text field is disabled and that's enough (ref: set_skipCurrent).
  }

  @computed
  get moveUntil(): Day {
    return this._moveUntil;
  }

  set moveUntil(value: Day) {
    if (value.isAfter(this.maximumUntilDay)) {
      value = this.maximumUntilDay;
    } else if (value.isBefore(this.minimumUntilDay)) {
      value = this.minimumUntilDay;
    }

    this._moveUntil = value;
  }

  @computed
  get skipCurrent(): boolean {
    return this._skipCurrent;
  }

  set skipCurrent(value: boolean) {
    this._skipCurrent = value;

    if (value) {
      this._currentTitle = '';
    } else {
      this._currentTitle =
        this._movingRightCell?.occurrence?.customTitle ??
        this._movingLeftCell?.occurrence?.customTitle ??
        this._currentTitle;
    }
  }

  @computed
  get unskipCurrent(): boolean {
    return this._unskipCurrent;
  }

  set unskipCurrent(value: boolean) {
    this._unskipCurrent = value;
  }

  @computed
  get currentTitle(): string {
    return this._currentTitle;
  }

  set currentTitle(value: string) {
    this._currentTitle = value;
  }

  @computed
  get defaultTitle(): string {
    if (this._movingRightCell?.occurrence != null) {
      return this._skipCurrent ? 'X' : this._movingRightCell.occurrence.normalizedOrdinal.toString();
    } else if (this._movingLeftCell?.occurrence != null) {
      // It's costly but it's computed. We need the next non-skipped occurrence.
      const occurrences = _.flatten(
        this._schoolDays.map((sd) =>
          _.flatten(
            sd.periods.map((p) =>
              p.courseOccurrences.filter((co) => co.sectionId === this._movingLeftCell!.occurrence!.sectionId)
            )
          )
        )
      );

      const nextOccurrence = occurrences.find(
        (o) => o.ordinal > this._movingLeftCell!.occurrence!.ordinal && !o.skipped
      );

      return nextOccurrence?.normalizedOrdinal.toString() ?? '';
    } else {
      return '';
    }
  }

  @computed
  get currentRoomName(): string {
    return this._currentRoomName;
  }

  set currentRoomName(value: string) {
    this._currentRoomName = value;
  }

  @computed
  private get sortedSections(): SectionModel[] {
    return _.orderBy(
      this._sectionIds.map((sectionId) => this._sectionsById[sectionId]),
      (s) => s.title
    );
  }

  @computed
  private get minimumUntilDay(): Day {
    // Though the first day doesn't make sense, we limit at that day to fit with visible days.
    return this._schoolDays[0]?.day ?? Day.fromDate(new Date());
  }

  @computed
  private get maximumUntilDay(): Day {
    return this._schoolDays[this._schoolDays.length - 1]?.day ?? Day.fromDate(new Date());
  }

  @action
  startMoveRight(cell: TeacherPlanningCell): void {
    if (cell.occurrence == null) {
      throw new Error('Unexpected cell content');
    }

    // Default values
    this._currentTitle = cell.occurrence.customTitle;
    this._currentRoomName = cell.occurrence.customRoomName;
    this._skipCurrent = false;
    this._moveTitlesRight = false;
    // We keep the last used "move until" day.

    this._movingRightCell = cell;
  }

  @action
  startMoveLeft(cell: TeacherPlanningCell): void {
    if (cell.occurrence == null) {
      throw new Error('Unexpected cell content');
    }

    // Default values
    this._currentTitle = cell.occurrence.customTitle;
    this._currentRoomName = cell.occurrence.customRoomName;
    this.unskipCurrent = false;
    this._moveTitlesLeft = false;
    // We keep the last used "move until" day.

    this._movingLeftCell = cell;
  }

  async confirmMoveRight(): Promise<void> {
    if (this._movingRightCell == null) {
      throw new Error('Unexpected state');
    }

    const cell = this._movingRightCell;

    try {
      if (this._moveTitlesRight) {
        const params: MoveCourseOccurrencesParamsModel = {
          configId: this._configId,
          sectionId: cell.occurrence!.sectionId,
          moveDirection: this._skipCurrent ? 'right-skip' : 'right',
          moveNotes: false,
          moveTasks: false,
          moveTitles: true,
          startOrdinal: cell.occurrence!.ordinal,
          untilDay: this._moveUntil
        };

        await this._calendarStore.moveCourseOccurrences(params);
      }

      // The best way to determine if an explicit call to customizeCourseOccurrence is
      // required would be to reload schooldays and compare properties, but that's costly.
      // Instead, we evaluate what they should be now and check if a value differs.
      const skipped = this._moveTitlesRight && this._skipCurrent;
      const title = this._moveTitlesRight ? (this._skipCurrent ? '' : '-') : cell.occurrence!.customTitle;
      // Room names don't get moved.
      const roomName = cell.occurrence!.customRoomName;

      if (skipped != this._skipCurrent || title != this._currentTitle || roomName != this._currentRoomName) {
        const editableCustomization = EditableCourseOccurrenceCustomization.createNew(cell.occurrence!);
        editableCustomization.skipped = this._skipCurrent;
        editableCustomization.title = this._currentTitle;
        editableCustomization.roomName = this._currentRoomName;

        await this._calendarStore.customizeCourseOccurrence(
          this._configId,
          cell.occurrence!.sectionId,
          editableCustomization
        );
      }

      this.cancel();
      await this.reloadSchoolDays();
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.sections;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    }
  }

  async confirmMoveLeft(): Promise<void> {
    if (this._movingLeftCell == null) {
      throw new Error('Unexpected state');
    }

    const cell = this._movingLeftCell;

    try {
      // If that occurrence is the last non-skipped, there's no need to move.
      const occurrences = _.flatten(
        this._schoolDays.map((sd) =>
          _.flatten(
            sd.periods.map((p) => p.courseOccurrences.filter((co) => co.sectionId === cell.occurrence!.sectionId))
          )
        )
      );

      const hasOtherNonSkippedOccurrences =
        occurrences.find((o) => o.ordinal > cell.occurrence!.ordinal && !o.skipped) != null;

      if (this._moveTitlesLeft && hasOtherNonSkippedOccurrences) {
        const params: MoveCourseOccurrencesParamsModel = {
          configId: this._configId,
          sectionId: cell.occurrence!.sectionId,
          moveDirection: 'left',
          moveNotes: false,
          moveTasks: false,
          moveTitles: true,
          // If that next ordinal is also skipped, the API will use the next non-skipped, as
          // skipped periods are never moved.
          startOrdinal: cell.occurrence!.ordinal + 1,
          untilDay: this._moveUntil
        };

        await this._calendarStore.moveCourseOccurrences(params);
      }

      if (
        !this._moveTitlesLeft ||
        !hasOtherNonSkippedOccurrences ||
        cell.occurrence!.customRoomName != this._currentRoomName
      ) {
        const editableCustomization = EditableCourseOccurrenceCustomization.createNew(cell.occurrence!);
        editableCustomization.skipped = !this._unskipCurrent;
        editableCustomization.title = this._moveTitlesLeft ? '' : this._currentTitle;
        editableCustomization.roomName = this._currentRoomName;

        await this._calendarStore.customizeCourseOccurrence(
          this._configId,
          cell.occurrence!.sectionId,
          editableCustomization
        );
      }

      this.cancel();
      await this.reloadSchoolDays();
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.sections;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    }
  }

  @action
  cancel(): void {
    this._movingRightCell = undefined;
    this._movingLeftCell = undefined;
  }

  private async reloadSchoolDays(): Promise<void> {
    const schoolDays = await this._calendarStore.getSchoolDays(
      this._configId,
      this._sectionIds,
      this.teacher.preferredScheduleTag
    );
    runInAction(() => (this._schoolDays = schoolDays));
  }

  private getSchoolDayIndex(day: Day): number {
    return Math.max(
      0,
      this._schoolDays.findIndex((sd) => sd.day.isSame(day))
    );
  }
}

export class AppTeacherPlanningDialogViewModel implements TeacherPlanningDialogViewModel {
  constructor(
    private readonly _schoolStore: SchoolYearConfigurationStore,
    private readonly _calendarStore: CalendarStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _accountId: string,
    private readonly _startingDay: Day | undefined,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {}

  get data(): IPromiseBasedObservable<TeacherPlanningViewModel> {
    return fromPromise(this.loadData());
  }

  close() {
    this._onSuccess();
  }

  private async loadData(): Promise<TeacherPlanningViewModel> {
    const [sectionsById, teacher, sections] = await Promise.all([
      this._schoolStore.getSectionsById(this._configId),
      this._schoolStore.getTeacher(this._configId, this._accountId, false),
      this._schoolStore.getTaughtSectionsForTeacherId(this._configId, this._accountId)
    ]);

    const sectionIds = sections.map((s) => s.id);
    const schoolDays = await this._calendarStore.getSchoolDays(
      this._configId,
      sectionIds,
      teacher.preferredScheduleTag
    );

    return new AppTeacherPlanningViewModel(
      this._calendarStore,
      this._alertService,
      this._localizationService,
      this._configId,
      sectionsById,
      teacher,
      sectionIds,
      schoolDays,
      this._startingDay
    );
  }
}
