import { SchoolYearConfigurationModel, SchoolYearConfigurationSummary } from '@shared/models/config';

export class SchoolYearConfigurationUtils {
  static displayTitle(config: SchoolYearConfigurationModel | SchoolYearConfigurationSummary): string {
    if (config.title.length > 0) {
      return config.title;
    }

    return this.getDatesTitle(config.startDay.year, config.endDay.year);
  }

  static getDatesTitle(startYear: number, endYear: number): string {
    return startYear === endYear ? `${startYear}` : `${startYear}-${endYear}`;
  }
}
