import { Capacitor } from '@capacitor/core';
import { Preferences as CapacitorPreferences } from '@capacitor/preferences';
import { AppLocalStorage } from '@shared/services';

export class WebLocalStorage extends AppLocalStorage {
  // Used if the localStorage is unavailable.
  private inMemoryStorage: Record<string, string> = {};

  protected async getJson(key: string): Promise<string | null> {
    if (Capacitor.isNativePlatform()) {
      const { value } = await CapacitorPreferences.get({ key });
      return value;
    }

    try {
      return localStorage.getItem(key);
    } catch {
      return this.inMemoryStorage[key];
    }
  }

  protected async setJson(key: string, json: string): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      await CapacitorPreferences.set({ key, value: json });
      return;
    }

    try {
      localStorage.setItem(key, json);
    } catch {
      this.inMemoryStorage[key] = json;
    }
  }

  protected async deleteKey(key: string): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      await CapacitorPreferences.remove({ key });
      return;
    }

    try {
      localStorage.removeItem(key);
    } catch {
      delete this.inMemoryStorage[key];
    }
  }
}
