import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DragEvent, ReactNode, useState } from 'react';
import { isSxArray } from '../../components/utils';
import { dragManager } from './DragManager.ts';

export interface DraggableProps<T> {
  sx?: SxProps;
  className?: string;
  children: ReactNode;
  isDraggable?: boolean;
  renderPreview?: () => ReactNode;
  data: T;
  type: string;
  canCopyMove?: boolean;
}

/**
 * Represents an element that can be dragged.
 * If wanted, the children can be changed when drag start to have a different preview.
 *
 * Props:
 *
 * * **isDraggable**: Whether the component can be dragged.
 * * **renderPreview**: View to display when drag starts. Can be null.
 * * **value**: The value that will be stored and accessible by a Droppable. If we want to pass an object,
 * simply do a JSON.stringify() to it.
 * * **type**: Type of content we are dragging. Only droppable with the same type will be able to handle
 * a drop of this element.
 *
 * @export
 * @class Draggable
 * @extends {React.Component<DraggableProps>}
 */

export const Draggable = observer(<T,>(props: DraggableProps<T>) => {
  const { sx = [], children, className, isDraggable = true, type, renderPreview, data, canCopyMove = false } = props;
  const [isDragging, setIsDragging] = useState(false);

  const renderChildren = () => {
    // If this can be dragged, is dragging and has a renderPreview passed, we render the
    // preview. If not we simply render the normal children.
    if (isDraggable && isDragging && renderPreview != null) {
      return renderPreview();
    } else {
      return children;
    }
  };

  const onDragStart = (e: DragEvent) => {
    // Must have data set in the dataTransfer for the drag to work on some browsers.
    e.dataTransfer.setData('🔥', '🦊');

    e.dataTransfer.dropEffect = canCopyMove ? 'move' : 'copy';
    e.dataTransfer.effectAllowed = canCopyMove ? 'copyMove' : 'copy';

    // Setting the data in the drag manager so that a Droppable can access it.
    dragManager.setDraggedData(type, data, canCopyMove);

    setIsDragging(true);
  };

  const onDragEnd = () => {
    setIsDragging(false);
    dragManager.clearDragData();
  };

  return (
    <Box
      className={className}
      draggable={isDraggable}
      onDragStart={isDraggable ? onDragStart : undefined}
      onDragEnd={onDragEnd}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      {renderChildren()}
    </Box>
  );
});
