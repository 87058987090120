import { PeriodDisplay } from '@insights/enums';
import { SettingsStore } from '@insights/services';
import { computed, makeObservable } from 'mobx';

export interface AdminDashboardViewModel {
  readonly configId: string;
  periodDisplay: PeriodDisplay;
}

export class AppAdminDashboardViewModel implements AdminDashboardViewModel {
  constructor(
    private readonly _settingsStore: SettingsStore,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get periodDisplay(): PeriodDisplay {
    return this._settingsStore.periodDisplay;
  }

  set periodDisplay(value: PeriodDisplay) {
    this._settingsStore.periodDisplay = value;
  }
}
