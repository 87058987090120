import { MenuItem, Select, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';

export interface CanvasAssignmentGroupSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  availableGroupNames: string[];
  selectedGroupName: string | undefined;
  onChange: (groupName: string | undefined) => void;
}

export const CanvasAssignmentGroupSelector = observer((props: CanvasAssignmentGroupSelectorProps) => {
  const { sx = [], className, style, availableGroupNames, selectedGroupName, onChange } = props;

  return (
    <Select
      sx={sx}
      className={className}
      style={style}
      fullWidth
      value={selectedGroupName}
      onChange={(event) => {
        const value = String(event.target.value);

        if (value != null && value.length > 0) {
          onChange(value);
        }
      }}
    >
      {availableGroupNames.map((name) => (
        <MenuItem key={`group-${name}`} value={name}>
          <Typography>{name}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
});
