import * as C from '@buf/studyo_studyo.bufbuild_es/studyo/type_canvas_pb';
import { Memoize } from 'fast-typescript-memoize';
import { BaseModel } from '../Model';
import { canvasGradingConditionFromProtobuf } from '../types/EnumConversion';
import { GrpcCanvasContentRepresentation } from './GrpcCanvasContentRepresentation';
import { CanvasAssignmentGroupMappingModel } from './interfaces/CanvasAssignmentGroupMappingModel';

export class GrpcCanvasAssignmentGroupMapping
  extends BaseModel<C.AssignmentGroupMapping>
  implements CanvasAssignmentGroupMappingModel
{
  constructor(pb: C.AssignmentGroupMapping) {
    super(pb);
  }

  get name() {
    return this._pb.assignmentGroupName;
  }

  get gradingCondition() {
    return canvasGradingConditionFromProtobuf(this._pb.gradingCondition);
  }

  @Memoize()
  get representation() {
    const representation = this._pb.contentRepresentation;

    return representation && new GrpcCanvasContentRepresentation(representation);
  }
}
