import { EditableSourceIndex, EditableTransformation } from '@shared/models/import';
import { action, computed, makeObservable, observable } from 'mobx';
import { IndexableSource } from './IndexableSource';

export interface TransformationIndexedSourcesViewModel {
  readonly availableSources: IndexableSource[];
  readonly indexedSources: EditableSourceIndex[];
  nextIndexedSourceLabel: string;
  nextIndexedSourceColumnIndex: number;
  readonly canAddNextIndexedSource: boolean;
  addNextIndexedSource(): void;
  removeIndexedSource(source: EditableSourceIndex): void;
}

export class AppTransformationIndexedSourcesViewModel implements TransformationIndexedSourcesViewModel {
  @observable private _editableSourceIndex: EditableSourceIndex;

  constructor(
    private readonly _editableTransformation: EditableTransformation,
    readonly availableSources: IndexableSource[]
  ) {
    makeObservable(this);
    this._editableSourceIndex = EditableSourceIndex.createNew();
  }

  @computed
  get indexedSources() {
    return this._editableTransformation.indexedSources;
  }

  @computed
  get nextIndexedSourceLabel() {
    return this._editableSourceIndex.label;
  }

  set nextIndexedSourceLabel(value: string) {
    this._editableSourceIndex.label = value;
  }

  @computed
  get nextIndexedSourceColumnIndex() {
    return this._editableSourceIndex.columnIndex;
  }

  set nextIndexedSourceColumnIndex(value: number) {
    // Block invalid values from <input>
    if (isNaN(value) || value < 0) {
      value = -1;
    }

    this._editableSourceIndex.columnIndex = value;
  }

  @computed
  get canAddNextIndexedSource() {
    return this.nextIndexedSourceLabel.length > 0 && this.nextIndexedSourceColumnIndex >= 0;
  }

  @action
  addNextIndexedSource() {
    if (this.canAddNextIndexedSource) {
      this._editableTransformation.addIndexedSource(this._editableSourceIndex);
      this._editableSourceIndex = EditableSourceIndex.createNew();
    }
  }

  @action
  removeIndexedSource(source: EditableSourceIndex): void {
    source.markAsDeleted();
  }
}
