import { GrpcMoodleExternalAccountDetails, MoodleExternalAccountDetails } from '@shared/models/connectors';
import { MoodleTransport } from '../../../transports';
import { ConnectorsStore, MoodleConnectorStore } from '../../interfaces';
import { AppBaseStore } from '../AppBaseStore';

export class AppMoodleConnectorStore extends AppBaseStore implements MoodleConnectorStore {
  constructor(
    private readonly _transport: MoodleTransport,
    connectorStore: ConnectorsStore
  ) {
    super('AppMoodleConnectorStore', false, connectorStore);
  }
  async getMoodleAccountDetails(externalAccountId: string): Promise<MoodleExternalAccountDetails> {
    const response = await this._transport.fetchMoodleExternalAccountDetails(externalAccountId);
    const details = response.accountDetails;

    if (details == null) {
      throw new Error('Unexpected empty account details response without any reported error.');
    }

    return new GrpcMoodleExternalAccountDetails(details);
  }

  async createOrUpdateMoodleAccount(
    configId: string,
    externalAccountId: string,
    accountName: string,
    serverUrl: string,
    accessToken: string
  ): Promise<void> {
    await this._transport.createOrUpdateMoodleExternalAccount(
      externalAccountId,
      configId,
      serverUrl,
      accessToken,
      accountName
    );
    this.invalidate();
  }
}
