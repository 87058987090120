import { AccountService, NavigationService } from '@insights/services';
import { NavigateFunction } from 'react-router-dom';

export interface LogoutViewModel {
  onInit(navigate: NavigateFunction): Promise<void>;
}

export class AppLogoutViewModel implements LogoutViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService
  ) {}

  async onInit(navigate: NavigateFunction) {
    try {
      await this._accountService.completeLogout();
      await this._navigationService.redirectToLanding(navigate);
    } catch {
      // Nothing to do.
    }
  }
}
