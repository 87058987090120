import * as SC from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/schoology_pb';
import { SchoologyExternalAccountDetails } from './interfaces/SchoologyExternalAccountDetails';

export class GrpcSchoologyExternalAccountDetails implements SchoologyExternalAccountDetails {
  constructor(private readonly pb: SC.GetExternalAccountDetailsResponse) {}

  get serverUrl() {
    return this.pb.serverUrl;
  }

  get consumerKey() {
    return this.pb.consumerKey;
  }

  get consumerSecret() {
    return this.pb.consumerSecret;
  }
}
