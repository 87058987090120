import * as TC from '@buf/studyo_studyo.bufbuild_es/studyo/type_teams_pb';
import { BaseModel } from '../Model';
import { contentIconFromProtobuf, contentWorkloadLevelFromProtobuf } from '../types/EnumConversion';
import { TeamsContentRepresentationModel } from './interfaces';

export class GrpcTeamsContentRepresentation
  extends BaseModel<TC.ContentRepresentation>
  implements TeamsContentRepresentationModel
{
  constructor(pb: TC.ContentRepresentation) {
    super(pb);
  }

  get icon() {
    return contentIconFromProtobuf(this._pb.icon);
  }

  get workloadLevel() {
    return contentWorkloadLevelFromProtobuf(this._pb.workloadLevel);
  }
}
