import {
  AutomatedImport as PBAutomatedImport,
  AutomatedTransformationImport as PBAutomatedTransformationImport
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import {
  EditableModelEx,
  EditableNullableTimePropertyEx,
  EditableStringProperty,
  EditableValueArrayPropertyEx,
  FullyEditableListProperty
} from '@shared/models/editables';
import { DayOfWeek, Time } from '@shared/models/types';
import { protobufFromDayOfWeek } from '@shared/models/types/EnumConversion';
import { computed, makeObservable } from 'mobx';
import { AutomatedImport, AutomatedTransformationImport } from '../interfaces';
import { EditableAutomatedTransformationImport } from './EditableAutomatedTransformationImport';
import { GrpcAutomatedImport } from './GrpcAutomatedImport';

export class EditableAutomatedImport extends EditableModelEx<PBAutomatedImport> implements AutomatedImport {
  private readonly _name: EditableStringProperty<PBAutomatedImport>;
  private readonly _days: EditableValueArrayPropertyEx<DayOfWeek, PBAutomatedImport>;
  private readonly _time: EditableNullableTimePropertyEx<PBAutomatedImport>;
  private readonly _transformations: FullyEditableListProperty<
    PBAutomatedTransformationImport,
    AutomatedTransformationImport,
    EditableAutomatedTransformationImport,
    PBAutomatedImport
  >;

  static createNew(configId: string, importSessionId: string) {
    const pb = new PBAutomatedImport();
    pb.configId = configId;
    pb.importSessionId = importSessionId;

    return new EditableAutomatedImport(new GrpcAutomatedImport(pb));
  }

  constructor(private readonly _originalAutomatedImport: AutomatedImport) {
    super(_originalAutomatedImport.toProtobuf(), _originalAutomatedImport.id.length > 0);

    makeObservable(this);

    this._name = this.addStringField(_originalAutomatedImport.name, (pb, value) => (pb.name = value), { trim: true });
    this._days = this.addValueArrayField(
      _originalAutomatedImport.days,
      (pb, values) => (pb.days = values.map((v) => protobufFromDayOfWeek(v)))
    );
    this._time = this.addNullableTimeField(_originalAutomatedImport.time, (pb, value) => (pb.time = value?.asPB));
    this._transformations = this.addEditableListField(
      _originalAutomatedImport.transformations.map((t) => new EditableAutomatedTransformationImport(t)),
      (pb, values) => (pb.transformations = values)
    );
  }

  get id() {
    return this._originalAutomatedImport.id;
  }

  get configId() {
    return this._originalAutomatedImport.configId;
  }

  get importSessionId() {
    return this._originalAutomatedImport.importSessionId;
  }

  @computed
  get name() {
    return this._name.value;
  }

  set name(value: string) {
    this._name.value = value;
  }

  @computed
  get days() {
    return this._days.value;
  }

  set days(value: DayOfWeek[]) {
    this._days.value = value;
  }

  @computed
  get time() {
    return this._time.value;
  }

  set time(value: Time | undefined) {
    this._time.value = value;
  }

  @computed
  get transformations() {
    return this._transformations.values;
  }

  addTransformation(transformation: EditableAutomatedTransformationImport) {
    this._transformations.addItem(transformation);
  }

  moveTransformation(oldIndex: number, newIndex: number) {
    this._transformations.moveItem(oldIndex, newIndex);
  }

  get history() {
    return this._originalAutomatedImport.history;
  }
}
