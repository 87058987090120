export interface AddAttachmentStrings {
  subtitle: string;
  capacitorSubtitle: string;
  title: string;

  close: string;
  errorMessage: string;
  chooseFromDevice: string;
  chooseURL: string;
  chooseGoogleDriveLink: string;
  disabledGoogleDrivePickerLine1: string;
  disabledGoogleDrivePickerLine2: string;

  addURLTitle: string;
  addURLPlaceholder: string;

  cancelURL: string;
  addURL: string;

  loadingTitle: string;
  loadingMessage: string;
  successTitle: string;
  successMessage: string;
  errorTitle: string;
  errorMessageDialog: string;
}

export const EnglishAddAttachmentStrings: AddAttachmentStrings = {
  subtitle: 'Drop a file here or import from…',
  capacitorSubtitle: 'Import from…',
  title: 'Add Attachment',

  close: 'Close',
  errorMessage: 'Oops! Something went wrong while trying to upload your attachment.',
  chooseFromDevice: 'My Device',
  chooseURL: 'URL',
  chooseGoogleDriveLink: 'Link to a Google Drive file',
  disabledGoogleDrivePickerLine1: 'Google Drive file picker is not working at the moment.',
  disabledGoogleDrivePickerLine2: 'We are working on a fix, thanks for your patience.',

  addURLTitle: 'Link to a website',
  addURLPlaceholder: 'Enter a URL…',

  cancelURL: 'Cancel',
  addURL: 'Add',

  loadingTitle: 'Please wait...',
  loadingMessage: 'Uploading attachment',
  successTitle: 'Success',
  successMessage: 'The attachment was uploaded successfully!',
  errorTitle: 'Oops, something went wrong!',
  errorMessageDialog: 'An error occurred while uploading your attachment. Please try again.'
};

export const FrenchAddAttachmentStrings: AddAttachmentStrings = {
  subtitle: 'Déposez un fichier ici ou importez depuis…',
  capacitorSubtitle: 'Importez depuis…',
  title: 'Ajouter une pièce jointe',

  close: 'Fermer',
  errorMessage: 'Oups! Une erreur est survenue lors du téléchargement de la pièce jointe.',
  chooseFromDevice: 'Mon appareil',
  chooseURL: 'Lien',
  chooseGoogleDriveLink: 'Lien vers un fichier Google Drive',
  disabledGoogleDrivePickerLine1: "Le sélecteur de fichiers Google Drive n'est pas fonctionnel en ce moment.",
  disabledGoogleDrivePickerLine2: 'Nous sommes en train de remédier au problème, merci de votre patience.',

  addURLTitle: 'Lien vers un site web',
  addURLPlaceholder: 'Entrez un lien…',

  cancelURL: 'Annuler',
  addURL: 'Ajouter',

  loadingTitle: 'Veuillez patienter...',
  loadingMessage: 'Chargement des pièces jointes',
  successTitle: 'Succès',
  successMessage: 'La pièce jointe a été chargée avec succès!',
  errorTitle: 'Oops, une erreur est survenue!',
  errorMessageDialog: "La pièce jointe n'a pu être chargée. Veuillez réessayer."
};
