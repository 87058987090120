import { SchoolCalendarSpecialDayViewModel } from '@insights/viewmodels';
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SpecialDaySymbolImage } from '@shared/components/special_day_symbols';
import { SectionColors } from '@shared/models/Colors';
import { AllColors, Color, OrderedSpecialDaySymbols, SpecialDaySymbol } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column, Row } from '../layout';

export interface EditableSpecialDaySymbolAndColorProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarSpecialDayViewModel;
}

export const EditableSpecialDaySymbolAndColor = observer((props: EditableSpecialDaySymbolAndColorProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, style, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Column sx={sx} className={className} style={style}>
      <Row>
        <FormControl sx={{ mr: 2, minWidth: 150, flex: 2 }}>
          <InputLabel htmlFor="symbol-select">{strings.symbol}</InputLabel>
          <Select
            variant="filled"
            value={viewModel.symbol}
            inputProps={{ id: 'symbol-select' }}
            onChange={(event) => (viewModel.symbol = event.target.value as SpecialDaySymbol)}
          >
            {OrderedSpecialDaySymbols.map((symbol) => (
              <MenuItem key={`symbol-select-item-${symbol}`} value={symbol}>
                {symbol === 'none' ? (
                  strings.none
                ) : (
                  <SpecialDaySymbolImage
                    squareSize={20}
                    symbol={symbol}
                    color={SectionColors.get(viewModel.symbolColor)}
                    alwaysDisplaySymbol={true}
                  />
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ mr: 2, minWidth: 150, flex: 3 }}>
          <InputLabel htmlFor="symbol-color-select">{strings.color}</InputLabel>
          <Select
            variant="filled"
            value={viewModel.symbolColor}
            inputProps={{ id: 'symbol-color-select' }}
            onChange={(event) => (viewModel.symbolColor = event.target.value as Color)}
            MenuProps={{
              sx: {
                '&& .MuiMenuItem-root': {
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor: 'white'
                },
                '&& .Mui-focusVisible': {
                  borderWidth: 2,
                  borderStyle: 'dotted',
                  borderColor: grey[500]
                },
                '&& .Mui-selected': {
                  borderWidth: 2,
                  borderStyle: 'dotted',
                  borderColor: 'black'
                }
              }
            }}
          >
            {AllColors.map((color) => ({
              value: color,
              color: SectionColors.get(color)
            })).map((info) => (
              <MenuItem sx={{ p: 0.25 }} key={`symbol-color-select-item-${info.value}`} value={info.value}>
                <Box style={{ backgroundColor: info.color, width: '100%', height: 20 }} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Row>

      <FormControlLabel
        control={
          <Checkbox
            value="is-symbol-visible"
            checked={viewModel.isSymbolVisible}
            onChange={(event) => (viewModel.isSymbolVisible = event.target.checked)}
          />
        }
        label={strings.showSpecialDaySymbol}
      />
    </Column>
  );
});
