export interface ProfileMenuStrings {
  readonly logout: string;
  readonly close: string;

  readonly studentNewSchoolYearMessage: string;
  readonly teacherNewSchoolYearMessage: string;
  readonly parentNewSchoolYearMessage: string;
  readonly useCode: string;

  readonly otherProfiles: string;
  readonly openStudentPlanner: string;
  readonly configureConfig: string;
  readonly selectSections: string;
  readonly sharePlanner: string;
  readonly schoolLinks: string;
  readonly preferences: string;
  readonly contactUs: string;
  readonly demoSchoolNotice: string;
  readonly demoSchoolPreventChangesLabel: string;
  readonly demoSchoolTodayLabel: string;
  readonly synchronize: string;
  readonly forceSynchronize: string;
  readonly synchronizing: string;
  readonly synchronizationError: string;
  readonly getLastSynchronizationString: (elapsedTime: string) => string;
}

export const EnglishProfileMenuStrings: ProfileMenuStrings = {
  logout: 'Logout',
  close: 'Close',

  studentNewSchoolYearMessage: 'Either wait for your school data, or enter the code provided by your school.',
  teacherNewSchoolYearMessage:
    'Your new school calendar will automatically appear when your school data has been imported.',
  parentNewSchoolYearMessage: 'Your child needs to invite you for the new school year.',
  useCode: 'Use a school code',

  otherProfiles: 'Other profiles',
  openStudentPlanner: 'View a student’s planner',
  configureConfig: 'Edit schedule',
  selectSections: 'Manage your classes',
  sharePlanner: 'Share your planner',
  schoolLinks: 'View school links',
  preferences: 'Adjust your personal settings',
  contactUs: 'I need help',
  demoSchoolNotice: 'This school is a demo school. Options below affect how it behaves.',
  demoSchoolPreventChangesLabel: 'Simulate all changes',
  demoSchoolTodayLabel: 'Simulated Today date:',
  synchronize: 'Sync your data',
  forceSynchronize: 'Force sync of data',
  synchronizing: 'Synchronizing…',
  synchronizationError: 'An error occurred. Please retry.',

  // eg.: Updated a few seconds ago
  getLastSynchronizationString: (elapsedTime: string): string => `Updated ${elapsedTime}`
};

export const FrenchProfileMenuStrings: ProfileMenuStrings = {
  logout: 'Se déconnecter',
  close: 'Fermer',

  studentNewSchoolYearMessage: 'Attendez que les données de votre école soient importées ou entrez un code école.',
  teacherNewSchoolYearMessage:
    'Votre nouveau calendrier apparaîtra automatiquement lorsque les données de votre école auront été importées.',
  parentNewSchoolYearMessage: 'Votre enfant doit vous réinviter pour la nouvelle année scolaire.',
  useCode: 'Utiliser un code école',

  otherProfiles: 'Autres profils',
  openStudentPlanner: 'Voir l’agenda d’un élève',
  configureConfig: 'Gérer mon horaire',
  selectSections: 'Éditer ma liste de cours',
  sharePlanner: 'Partager mon agenda',
  schoolLinks: 'Voir les liens de l’école',
  preferences: 'Ajuster mes préférences personnelles',
  contactUs: 'J’ai besoin d’aide',
  demoSchoolNotice: 'Cette école est une école démo. Les options ici-bas affectent le comportement de l’application.',
  demoSchoolPreventChangesLabel: 'Simuler tout changement',
  demoSchoolTodayLabel: 'Date simulée d’aujourd’hui:',
  synchronize: 'Synchroniser mes données',
  forceSynchronize: 'Forcer la synchronisation des données',
  synchronizing: 'Synchronisation en cours…',
  synchronizationError: 'Une erreur est survenue. Veuillez réessayer.',

  // eg.: Mise à jour il y a quelques secondes
  getLastSynchronizationString: (elapsedTime: string): string => `Mise à jour ${elapsedTime}`
};
