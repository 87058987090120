import { EditableTransformationColumnViewModel } from '@insights/viewmodels';
import { Checkbox, FormControlLabel, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface EditableIsSubstitutionProps {
  sx?: SxProps;
  className?: string;
  column: EditableTransformationColumnViewModel;
}

export const EditableIsSubstitution = observer((props: EditableIsSubstitutionProps) => {
  const { localizationService } = useInsightsServices();
  const { column, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <FormControlLabel
      sx={sx}
      className={className}
      label={strings.isSubstitutionLabel}
      control={
        <Checkbox
          autoFocus={column.focusedField === 'substitution'}
          checked={column.isSubstitution}
          disabled={column.schemaFields.length > 0}
          onChange={(e) => (column.isSubstitution = e.target.checked)}
          onFocus={() => column.setFocusedField('substitution')}
        />
      }
    />
  );
});
