import { Section_Schedule_Custom as PBScheduleCustom } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableStringProperty, EditableTimePropertyEx } from '../editables';
import { Time } from '../types';
import { SectionScheduleCustom, SectionScheduleCustomModel } from './SectionScheduleCustom';

export class EditableSectionScheduleCustom
  extends EditableModelEx<PBScheduleCustom>
  implements SectionScheduleCustomModel
{
  private _startTime: EditableTimePropertyEx<PBScheduleCustom>;
  private _endTime: EditableTimePropertyEx<PBScheduleCustom>;
  private _displayPeriodTag: EditableStringProperty<PBScheduleCustom>;

  static createNew(startHour: number) {
    const pb = new PBScheduleCustom();
    pb.startTime = Time.create({ hour: startHour, minute: 0 }).asPB;
    pb.endTime = Time.create({ hour: startHour + 1, minute: 0 }).asPB;

    return new EditableSectionScheduleCustom(new SectionScheduleCustom(pb), true);
  }

  constructor(_originalCustom: SectionScheduleCustomModel, isNew = false) {
    super(_originalCustom.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._startTime = new EditableTimePropertyEx(
        _originalCustom.startTime,
        (pb, value) => (pb.startTime = value.asPB),
        8
      )),
      (this._endTime = new EditableTimePropertyEx(
        _originalCustom.endTime,
        (pb, value) => (pb.endTime = value.asPB),
        9
      )),
      (this._displayPeriodTag = new EditableStringProperty(
        _originalCustom.displayPeriodTag,
        (pb, value) => (pb.displayPeriodTag = value),
        {
          trim: true
        }
      ))
    ]);
  }

  @computed
  get startTime(): Time {
    return this._startTime.value;
  }

  set startTime(value: Time) {
    this._startTime.value = value;
  }

  @computed
  get endTime(): Time {
    return this._endTime.value;
  }

  set endTime(value: Time) {
    this._endTime.value = value;
  }

  @computed
  get displayPeriodTag(): string {
    return this._displayPeriodTag.value;
  }

  set displayPeriodTag(value: string) {
    this._displayPeriodTag.value = value;
  }

  protected createProtobuf() {
    return new PBScheduleCustom();
  }
}
