import { Box, Button, CircularProgress, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, ReactNode } from 'react';

export interface InsightsButtonProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  showSpinnerOnExecuting?: boolean;
  isDefault?: boolean;
  isSubmit?: boolean;
  isExecuting?: boolean;
  isDisabled?: boolean;
  percentage?: number;
  children?: ReactNode;
}

export const InsightsButton = observer(
  ({
    sx = [],
    className,
    style,
    children,
    onClick,
    showSpinnerOnExecuting = false,
    isDefault = false,
    isSubmit = false,
    isExecuting = false,
    isDisabled = false,
    percentage
  }: InsightsButtonProps) => {
    function handleButtonClick() {
      if (onClick) {
        requestAnimationFrame(() => onClick());
      }
    }

    const displayAsDefault = isDefault || isSubmit;
    const displaySpinner = showSpinnerOnExecuting && isExecuting;

    return (
      <Box sx={sx} className={className} style={style}>
        <Button
          sx={{ minWidth: 100, boxShadow: displayAsDefault ? 'none' : undefined }}
          variant={displayAsDefault ? 'contained' : 'outlined'}
          type={isSubmit ? 'submit' : undefined}
          color={displayAsDefault ? 'primary' : undefined}
          onClick={() => handleButtonClick()}
          disabled={isExecuting || isDisabled}
        >
          {!displaySpinner && children}

          {displaySpinner &&
            (percentage != null ? (
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress size={24} color="inherit" variant="determinate" value={percentage} />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    component="div"
                    sx={{ fontSize: 10, color: (theme) => theme.palette.text.secondary }}
                  >{`${Math.round(percentage)}%`}</Typography>
                </Box>
              </Box>
            ) : (
              <CircularProgress size={24} color="inherit" variant="indeterminate" />
            ))}
        </Button>
      </Box>
    );
  }
);
