export interface TimelineOptionMenuStrings {
  readonly title: string;
  readonly close: string;

  readonly cellSizeSectionTitle: string;
  readonly largeCellSizeLabel: string;
  readonly regularCellSizeLabel: string;

  readonly displaySectionTitle: string;
  readonly limitTimelineWeeksLabel: string;
  readonly showAssignmentDayIconLabel: string;
}

export const EnglishTimelineOptionMenuStrings: TimelineOptionMenuStrings = {
  title: 'Options',
  close: 'Close',

  cellSizeSectionTitle: 'Cell size',
  largeCellSizeLabel: 'Large',
  regularCellSizeLabel: 'Regular',

  displaySectionTitle: 'Displayed Elements',
  limitTimelineWeeksLabel: 'Limit timelines to 3 weeks',
  showAssignmentDayIconLabel: 'Show announcement day icon'
};

export const FrenchTimelineOptionMenuStrings: TimelineOptionMenuStrings = {
  title: 'Options',
  close: 'Fermer',

  cellSizeSectionTitle: 'Taille de cellule',
  largeCellSizeLabel: 'Grande',
  regularCellSizeLabel: 'Normal',

  displaySectionTitle: 'Éléments affichés',
  limitTimelineWeeksLabel: 'Limiter la taille des lignes à 3 semaines',
  showAssignmentDayIconLabel: 'Afficher l’icône de date d’annonce'
};
