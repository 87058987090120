import * as GC from '@buf/studyo_studyo.bufbuild_es/studyo/type_classroom_pb';
import { Memoize } from 'fast-typescript-memoize';
import { GrpcClassroomCourseWorkMapping } from './GrpcClassroomCourseWorkMapping';
import { ClassroomExternalAccountSettings } from './interfaces';

export class GrpcClassroomExternalAccountSettings implements ClassroomExternalAccountSettings {
  // We tolerate an undefined protobuf message.
  constructor(private readonly _pb: GC.ExternalAccountSettings | undefined) {}

  get matchingExpression() {
    return this._pb?.matchExpression ?? '';
  }

  @Memoize()
  get courseWorkMappings() {
    return this._pb?.courseWorkMappings.map((m) => new GrpcClassroomCourseWorkMapping(m)) ?? [];
  }

  get isIgnoringUnmatchedCourseWork() {
    return this._pb?.isIgnoringUnmatchedCourseWork ?? false;
  }

  get isFetchingCourseAliases() {
    return this._pb?.isFetchingCourseAliases ?? false;
  }

  get isRemovingMatchedKeyword() {
    return this._pb?.isRemovingMatchedKeyword ?? false;
  }
}
