export interface PlannerPeriodEditStrings {
  readonly cancel: string;
  readonly save: string;
  readonly title: string;
  readonly classIsOccurringOption: string;
  readonly periodTitleLabel: string;
  readonly roomNameLabel: string;

  readonly savingTitle: string;
  readonly savingDescription: string;
  readonly savedTitle: string;
  readonly savedDescription: string;
  readonly saveErrorTitle: string;
  readonly saveErrorDescription: string;
  readonly savePermanentErrorDescription: string;
}

export const EnglishPlannerPeriodEditStrings: PlannerPeriodEditStrings = {
  cancel: 'Cancel',
  save: 'Save',
  title: 'Edit Period',
  classIsOccurringOption: 'Class is occurring',
  periodTitleLabel: 'Title',
  roomNameLabel: 'Room',

  savingTitle: 'Please wait…',
  savingDescription: 'Saving chenges',
  savedTitle: 'Success',
  savedDescription: 'The changes were saved successfully!',
  saveErrorTitle: 'Oops, something went wrong!',
  saveErrorDescription: 'An error occurred while saving. Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented the save. Please reload and try again.'
};

export const FrenchPlannerPeriodEditStrings: PlannerPeriodEditStrings = {
  cancel: 'Annuler',
  save: 'Sauvegarder',
  title: 'Modifier la période',
  classIsOccurringOption: 'La période a lieu',
  periodTitleLabel: 'Titre',
  roomNameLabel: 'Local',

  savingTitle: 'Veuillez patienter…',
  savingDescription: 'Sauvegarde des changements en cours',
  savedTitle: 'Succès',
  savedDescription: 'Les changements ont été sauvegardés avec succès!',
  saveErrorTitle: 'Oups, une erreur est survenue!',
  saveErrorDescription: 'Les changements n’ont pu être sauvegardés. Veuillez réessayer.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la sauvegarde. Veuillez rafraichir et réessayer.'
};
