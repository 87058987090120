import { EditableSchedule, EditableSchedulePeriod, SchoolYearConfigurationModel } from '@shared/models/config';
import { Color, Time } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import _ from 'lodash';
import { action, computed, makeObservable } from 'mobx';
import * as C from './Constants';
import { ValidatableViewModel } from './Editor';

export interface SchoolCalendarScheduleViewModel extends ValidatableViewModel {
  readonly editableSchedule: EditableSchedule;

  readonly id: string;
  title: string;
  tag: string;
  tags: string[];
  color: Color;
  isHidden: boolean;
  isNoSchool: boolean;

  readonly allowedTags: string[];

  readonly isNew: boolean;
  readonly isDeleted: boolean;

  addPeriod(): void;
  sortPeriods(): void;

  delete(): void;
  resetChanges(): void;
}

export class AppSchoolCalendarScheduleViewModel implements SchoolCalendarScheduleViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    public readonly editableSchedule: EditableSchedule,
    private readonly _config: SchoolYearConfigurationModel
  ) {
    makeObservable(this);
  }

  get id() {
    return this.editableSchedule.id;
  }

  @computed
  get title() {
    return this.editableSchedule.title;
  }

  set title(value: string) {
    this.editableSchedule.title = value;
  }

  @computed
  get tag() {
    return this.editableSchedule.tag;
  }

  set tag(value: string) {
    this.editableSchedule.tag = value;
  }

  @computed
  get tags(): string[] {
    return this.editableSchedule.tags;
  }

  set tags(values: string[]) {
    this.editableSchedule.tags = values;
  }

  @computed
  get color() {
    return this.editableSchedule.color;
  }

  set color(value: Color) {
    this.editableSchedule.color = value;
  }

  @computed
  get isHidden() {
    return this.editableSchedule.isHidden;
  }

  set isHidden(value: boolean) {
    this.isHidden = value;
  }

  @computed
  get isNoSchool() {
    return this.editableSchedule.isNoSchool;
  }

  set isNoSchool(value: boolean) {
    this.editableSchedule.isNoSchool = value;
  }

  @computed
  get allowedTags() {
    return this._config.allowedScheduleTags;
  }

  @computed
  get hasChanges() {
    return this.editableSchedule.hasChanges;
  }

  get isNew() {
    return this.editableSchedule.shouldBeCreated;
  }

  @computed
  get isDeleted() {
    return this.editableSchedule.shouldBeDeleted;
  }

  @action
  addPeriod() {
    const tag = this.getNextTag();
    const startTime = this.getNextStartTime();

    this.editableSchedule.addPeriod(EditableSchedulePeriod.createNew(tag, startTime));
  }

  @action
  sortPeriods() {
    this.editableSchedule.sortPeriods();
  }

  @action
  delete() {
    this.editableSchedule.markAsDeleted();
  }

  @action
  resetChanges() {
    this.editableSchedule.resetChanges();
  }

  validate(): string[] {
    const messages: string[] = [];
    const strings = this._localizationService.localizedStrings.insights.viewModels.calendar;

    // Though max lengths are set on inputs, we still verify them.

    if (this.title.length === 0) {
      messages.push(strings.emptyTitleError);
    } else if (this.title.length > C.MaximumScheduleTitleLength) {
      messages.push(strings.titleTooLongError + C.MaximumScheduleTitleLength + strings.characters + '.');
    }

    if (this.tag.length > C.MaximumScheduleTagLength) {
      messages.push(strings.tagTooLongError + C.MaximumScheduleTagLength + strings.characters + '.');
    }

    if (this.editableSchedule.periods.some((period) => period.tag.length === 0)) {
      messages.push(strings.emptyPeriodTagError);
    } else if (this.editableSchedule.periods.some((period) => period.tag.length > C.MaximumSchedulePeriodTagLength)) {
      messages.push(strings.tagsTooLongError + C.MaximumSchedulePeriodTagLength + strings.characters + '.');
    }

    if (this.editableSchedule.periods.some((period) => period.startTime.isAfter(period.endTime))) {
      messages.push(strings.periodsStartTimeBeforeEndTime);
    }

    return messages;
  }

  private getNextTag(): string {
    const maxNumber = _.max(
      this.editableSchedule.periods
        .filter((p) => !(p as EditableSchedulePeriod).shouldBeDeleted)
        .map((p) => Number(p.tag))
        .filter((n) => !Number.isNaN(n))
    );

    if (maxNumber == null) {
      return '1';
    } else {
      return `${maxNumber + 1}`;
    }
  }

  private getNextStartTime(): Time {
    const maxEndTime = _.maxBy(
      this.editableSchedule.periods.filter((p) => !(p as EditableSchedulePeriod).shouldBeDeleted).map((p) => p.endTime),
      (time) => time.hour * 60 + time.minute
    );

    if (maxEndTime == null) {
      // Our mornings start at 7!
      return Time.create({ hour: 7, minute: 0 });
    } else if (maxEndTime.hour === 23 && maxEndTime.minute >= 55) {
      return Time.create({ hour: 23, minute: 59 });
    } else {
      return maxEndTime.addMinutes(5);
    }
  }
}
