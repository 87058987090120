import { AddOnboardingStep, InsightsButton, ObservablePresenter, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { createTheme, Dialog, DialogActions, DialogContent, DialogTitle, SxProps, ThemeProvider } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface AddOnboardingStepDialogProps extends DialogResult<string> {
  sx?: SxProps;
  configId: string;
  className?: string;
  style?: CSSProperties;
}

export const AddOnboardingStepDialog = observer((props: AddOnboardingStepDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx = [], configId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createAddOnboardingStepDialog(configId, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => viewModel.cancel()}
      >
        <DialogTitle>{strings.addStepTitle}</DialogTitle>
        <DialogContent>
          <ObservablePresenter data={viewModel.data} render={(data) => <AddOnboardingStep viewModel={data} />} />
        </DialogContent>
        <DialogActions>
          <InsightsButton onClick={() => viewModel.cancel()}>{strings.cancelLabel}</InsightsButton>
          <InsightsButton isDefault isDisabled={!viewModel.canAdd} onClick={() => void viewModel.add()}>
            {strings.saveLabel}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
