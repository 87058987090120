import * as BPB from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/blackbaud_pb';
import * as TBPB from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_pb';
import { Blackbaud } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/blackbaud_connect';
import { BlackbaudTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class BlackbaudGrpcTransport extends BaseGrpcTransport implements BlackbaudTransport {
  async fetchBlackbaudExternalAccountDetails(
    externalAccountId: string
  ): Promise<BPB.GetExternalAccountDetailsResponse> {
    console.log('Fetching Blackbaud external account...');

    const request = new BPB.GetExternalAccountDetailsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest<
      BPB.GetExternalAccountDetailsRequest,
      BPB.GetExternalAccountDetailsResponse
    >(Blackbaud, Blackbaud.methods.getExternalAccountDetails, request);

    console.log('Successfully fetched Blackbaud external account.');

    return response;
  }

  async createOrUpdateBlackbaudExternalAccount(
    externalAccountId: string | undefined,
    configId: string,
    serverUrl: string,
    username: string,
    password: string,
    accountName: string
  ): Promise<BPB.CreateOrUpdateExternalAccountResponse> {
    console.log('Creating or updating Blackbaud external account...');

    const request = new BPB.CreateOrUpdateExternalAccountRequest();
    request.externalAccountId = externalAccountId ?? '';
    request.configId = configId;
    request.serverUrl = serverUrl;
    request.username = username;
    request.password = password;
    request.accountName = accountName;

    const response = await this.performRequest<
      BPB.CreateOrUpdateExternalAccountRequest,
      BPB.CreateOrUpdateExternalAccountResponse
    >(Blackbaud, Blackbaud.methods.createOrUpdateExternalAccount, request);

    console.log('Successfully created or updated Blackbaud external account.');

    return response;
  }

  async updateBlackbaudExternalAccountSettings(
    externalAccountId: string,
    configId: string,
    yearLabel: string,
    levelId: bigint,
    levelName: string,
    kindMappings: TBPB.KindMapping[],
    includeNonLeadSections: boolean
  ): Promise<BPB.UpdateExternalAccountSettingsResponse> {
    console.log('Updating Blackbaud external account settings...');

    const request = new BPB.UpdateExternalAccountSettingsRequest();
    request.externalAccountId = externalAccountId;
    request.configId = configId;
    request.yearLabel = yearLabel;
    request.levelId = levelId;
    request.levelName = levelName;
    request.kindMappings = kindMappings;
    request.includeNonLeadSections = includeNonLeadSections;

    const response = await this.performRequest(Blackbaud, Blackbaud.methods.updateExternalAccountSettings, request);

    console.log('Successfully updated Blackbaud external account settings.');

    return response;
  }

  async fetchBlackbaudSchoolYears(externalAccountId: string): Promise<TBPB.SchoolYear[]> {
    console.log('Fetching Blackbaud school years...');

    const request = new BPB.GetSchoolYearsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(Blackbaud, Blackbaud.methods.getSchoolYears, request);

    console.log('Successfully fetched Blackbaud school years.');

    return response.years;
  }

  async fetchBlackbaudSchoolLevels(externalAccountId: string): Promise<TBPB.SchoolLevel[]> {
    console.log('Fetching Blackbaud school levels...');

    const request = new BPB.GetSchoolLevelsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(Blackbaud, Blackbaud.methods.getSchoolLevels, request);

    console.log('Successfully fetched Blackbaud school levels.');

    return response.levels;
  }

  async fetchBlackbaudAssignmentKinds(externalAccountId: string): Promise<TBPB.AssignmentKind[]> {
    console.log('Fetching Blackbaud assignment kinds...');

    const request = new BPB.GetAssignmentKindsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(Blackbaud, Blackbaud.methods.getAssignmentKinds, request);

    console.log('Successfully fetched Blackbaud assignment kinds.');

    return response.kinds;
  }
}
