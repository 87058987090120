import { PeriodDisplay, activeUsersPeriodFromPeriodDisplay } from '@insights/enums';
import { SettingsStore } from '@insights/services';
import { ConfigGlobalStats, DataPoint } from '@shared/models/metrics';
import { Day } from '@shared/models/types/Day';
import { MetricsStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface ActiveStudentsData {
  hasSchoolStarted: boolean;
  configGlobalStats: ConfigGlobalStats;
  activeStudentsInteracted: DataPoint[];
  activeStudentsAny: DataPoint[];
}

export interface ActiveStudentsViewModel {
  readonly configId: string;
  readonly periodDisplay: PeriodDisplay;

  readonly data: IPromiseBasedObservable<ActiveStudentsData>;
}

export class AppActiveStudentsViewModel implements ActiveStudentsViewModel {
  constructor(
    private readonly metricsStore: MetricsStore,
    private readonly configStore: SchoolYearConfigurationStore,
    private readonly settingsStore: SettingsStore,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<ActiveStudentsData> {
    return fromPromise(this.loadData());
  }

  @computed
  get periodDisplay(): PeriodDisplay {
    return this.settingsStore.periodDisplay;
  }

  private async loadData(): Promise<ActiveStudentsData> {
    const periodDisplay = this.settingsStore.periodDisplay;

    const result = await Promise.all([
      this.metricsStore.getConfigGlobalStats(this.configId),
      this.metricsStore.getActiveStudentsInteracted(this.configId, activeUsersPeriodFromPeriodDisplay(periodDisplay)),
      this.metricsStore.getActiveStudentsAny(this.configId, activeUsersPeriodFromPeriodDisplay(periodDisplay)),
      this.configStore.getConfigSummary(this.configId)
    ]);

    return {
      hasSchoolStarted: result[3].startDay.isSameOrBefore(Day.fromDate(new Date())!),
      configGlobalStats: result[0],
      activeStudentsInteracted: result[1],
      activeStudentsAny: result[2]
    };
  }
}
