import { Transformation as PBTransformation } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { Memoize } from 'fast-typescript-memoize';
import { BaseModel } from '../../Model';
import { SourceIndex, Transformation, TransformationColumn } from '../interfaces';
import { GrpcSourceIndex } from './GrpcSourceIndex';
import { GrpcTransformationColumn } from './GrpcTransformationColumn';

export class GrpcTransformation extends BaseModel<PBTransformation> implements Transformation {
  constructor(pb: PBTransformation) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  // Important: Though the protobuf model exposes an id property, it's not filled.
  //            The label property becomes the unique key. ¯\_(ツ)_/¯

  get name(): string {
    return this._pb.name;
  }

  get label(): string {
    return this._pb.label;
  }

  get sourceLabel(): string {
    return this._pb.sourceLabel;
  }

  get description(): string {
    return this._pb.description;
  }

  get indexedSources(): SourceIndex[] {
    return this._pb.indexedSources.map((s) => new GrpcSourceIndex(s));
  }

  @Memoize()
  get columns(): TransformationColumn[] {
    return this._pb.columns.map((tc) => new GrpcTransformationColumn(tc));
  }

  get targetSchema(): string {
    return this._pb.targetSchema;
  }

  get schemaFieldIndexes(): number[] {
    return this._pb.schemaFieldIndexes;
  }

  get suggestedImportOptions(): string[] {
    return this._pb.suggestedImportOptions;
  }
}
