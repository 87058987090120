import { NavigationImages } from '@shared/resources/images/studyo';
import tabbarDashboardImage from '@shared/web/images/assets/studyo/tabbar_dashboard.svg?url';
import tabbarDayImage from '@shared/web/images/assets/studyo/tabbar_day.svg?url';
import tabbarMonthImage from '@shared/web/images/assets/studyo/tabbar_month.svg?url';
import tabbarNotesImage from '@shared/web/images/assets/studyo/tabbar_notes.svg?url';
import tabbarTimelineImage from '@shared/web/images/assets/studyo/tabbar_timeline.svg?url';
import tabbarWeekImage from '@shared/web/images/assets/studyo/tabbar_week.svg?url';
import tabbarYearImage from '@shared/web/images/assets/studyo/tabbar_year.svg?url';

export class WebNavigationImages implements NavigationImages {
  get viewDay() {
    return tabbarDayImage;
  }

  get viewMonth() {
    return tabbarMonthImage;
  }

  get viewNotes() {
    return tabbarNotesImage;
  }

  get viewPlanner() {
    return tabbarNotesImage;
  }

  get viewWorkload() {
    return tabbarDashboardImage;
  }

  get viewTimeline() {
    return tabbarTimelineImage;
  }

  get viewWeek() {
    return tabbarWeekImage;
  }

  get viewYear() {
    return tabbarYearImage;
  }
}
