import { LocalizationService } from '@shared/resources/services';
import { ManageBacConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface ManageBacAccountViewModel extends ExternalAccountEditionViewModel {
  accountName: string;
  serverUrl: string;
  authToken: string;
}

export class AppManageBacAccountViewModel
  extends BaseExternalAccountEditionViewModel
  implements ManageBacAccountViewModel
{
  @observable private _accountName?: string;
  @observable private _serverUrl?: string;
  @observable private _authToken?: string;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _manageBacStore: ManageBacConnectorStore,
    private readonly _onSuccess: (externalAccountId: string) => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    private readonly _originalAccountName: string,
    private readonly _originalServerUrl: string,
    private readonly _originalAuthToken: string
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get accountName() {
    return this._accountName ?? this._originalAccountName;
  }

  set accountName(value: string) {
    this._accountName = value;
    this.onChange();
  }

  @computed
  get serverUrl() {
    return this._serverUrl ?? this._originalServerUrl;
  }

  set serverUrl(value: string) {
    this._serverUrl = value;
    this.onChange();
  }

  @computed
  get authToken() {
    return this._authToken ?? this._originalAuthToken;
  }

  set authToken(value: string) {
    this._authToken = value;
    this.onChange();
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this._hasChanges) {
      console.error('Applying without changes. Ignoring...');
      this._onSuccess(this._externalAccountId);
      return;
    }

    // The "original" auth token is not really the token, but a placeholder.
    // When editing details, the user must repeat the token.
    if (this._authToken === this._originalAuthToken) {
      this.addError(strings.enterAuthenticationTokenAgain);
      return;
    }

    this.beginApplying();

    try {
      const newId = await this._manageBacStore.createOrUpdateManageBacAccount(
        this._configId,
        this._externalAccountId,
        this.accountName,
        this.serverUrl,
        this.authToken
      );

      this._onSuccess(newId);
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._accountName = undefined;
    this._serverUrl = undefined;
    this._authToken = undefined;
    this._hasChanges = false;
    this._errors = [];
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this._hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
