import { Box, Divider, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, ReactNode } from 'react';

export interface PageFooterProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  dense?: boolean;
  children?: ReactNode;
}

export const PageFooter = observer((props: PageFooterProps) => {
  const { className, sx = [], style, children, dense = false } = props;

  return (
    <Box sx={{ ...sx, backgroundColor: 'white' }} className={className} style={style}>
      <Divider />
      <Box paddingY={dense ? 0.5 : 2} paddingX={3}>
        {children}
      </Box>
    </Box>
  );
});
