import { DataPoint } from '@shared/models/metrics';
import { MetricsStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface DailyAccountSessionsViewModel {
  readonly configId: string;
  readonly accountId: string;

  readonly data: IPromiseBasedObservable<DataPoint[]>;
}

export class AppDailyAccountSessionsViewModel implements DailyAccountSessionsViewModel {
  constructor(
    private readonly metricsStore: MetricsStore,
    public readonly configId: string,
    public readonly accountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<DataPoint[]> {
    return fromPromise(this.metricsStore.getDailyAccountSessions(this.configId, this.accountId));
  }
}
