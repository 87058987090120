import { SchoolCalendarImportFromViewModel } from '@insights/viewmodels';
import { FormControl, InputLabel, MenuItem, Select, SxProps } from '@mui/material';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { ObservablePresenter } from '../ObservablePresenter';
import { Column } from '../layout';
import { ImportFromConfigurationConfigDetails } from './ImportFromConfigurationConfigDetails.tsx';
import { ImportFromConfigurationConfigList } from './ImportFromConfigurationConfigList.tsx';

export interface ImportFromConfigurationProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarImportFromViewModel;
}

export const ImportFromConfiguration = observer((props: ImportFromConfigurationProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, style, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  function getYears() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return _.range(2015, currentYear + 2);
  }

  return (
    <Column sx={sx} className={className} style={style}>
      <FormControl sx={{ pb: 2 }}>
        <InputLabel htmlFor="year-select">{strings.yearLabel}</InputLabel>
        <Select
          value={viewModel.year}
          inputProps={{ id: 'year-select' }}
          onChange={(event) => (viewModel.year = Number(event.target.value))}
        >
          {getYears().map((y) => (
            <MenuItem key={`year-select-${y}`} value={y}>
              {y}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ObservablePresenter
        data={viewModel.configurations}
        render={(data) => (
          <ImportFromConfigurationConfigList
            data={data}
            selectedConfigId={viewModel.sourceConfigId}
            onSelect={(id) => (viewModel.sourceConfigId = id)}
          />
        )}
      />

      <ObservablePresenter
        data={viewModel.sourceConfig}
        render={(data) => <ImportFromConfigurationConfigDetails viewModel={viewModel} data={data} />}
      />
    </Column>
  );
});
