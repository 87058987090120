import { SxProps, Typography } from '@mui/material';
import { CSSProperties } from 'react';
import { Column } from './layout';

export interface LabeledValuePresenterProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  label: string;
  value: string;
}

export function LabeledValuePresenter({ sx = [], className, style, label, value }: LabeledValuePresenterProps) {
  return (
    <Column sx={sx} className={className} style={style}>
      <Typography
        variant="body2"
        sx={{
          lineHeight: 'normal',
          fontWeight: '300',
          color: (theme) => theme.palette.text.secondary
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          fontSize: '1.5em',
          lineHeight: 'normal',
          fontWeight: '300'
        }}
      >
        {value}
      </Typography>
    </Column>
  );
}
