import { css } from '@emotion/css';
import { Box, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { Thresholds } from '../Constants';
import { useInsightsServices } from '../UseInsightsServicesHook';

export interface Thresholds {
  dailyThreshold: number;
  weeklyThreshold: number;
}

export interface ThresholdsInformationProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  thresholds: Thresholds;
}

export const ThresholdsInformation = observer((props: ThresholdsInformationProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], thresholds } = props;
  const theme = useTheme();

  const dailyThresholdDisplayValue =
    thresholds.dailyThreshold === Thresholds.maxThreshold ? '–' : `${thresholds.dailyThreshold}`;
  const weeklyThresholdDisplayValue =
    thresholds.weeklyThreshold === Thresholds.maxThreshold ? '–' : `${thresholds.weeklyThreshold}`;

  const tooltipClassName = css({
    backgroundColor: theme.palette.common.white,
    color: theme.palette.getContrastText(theme.palette.common.white),
    boxShadow: theme.shadows[1],
    maxWidth: 'none'
  });

  const popperClassName = css({ opacity: 1 });

  return (
    <Tooltip
      sx={sx}
      className={className}
      style={style}
      classes={{ tooltip: tooltipClassName, popper: popperClassName }}
      title={renderTooltip(dailyThresholdDisplayValue, weeklyThresholdDisplayValue, localizationService)}
    >
      <Box borderRadius="25px" border={`1px solid ${grey[300]}`}>
        <Box height="fit-content" display="flex" flexDirection="row">
          <Typography mx={1} variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }} noWrap>
            {dailyThresholdDisplayValue}
          </Typography>

          <Box borderRight={`1px solid ${grey[300]}`} />

          <Typography mx={1} variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }} noWrap>
            {weeklyThresholdDisplayValue}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
});

function renderTooltip(
  dailyThresholdDisplayValue: string,
  weeklyThresholdDisplayValue: string,
  localizationService: LocalizationService
) {
  const strings = localizationService.localizedStrings.insights.components.thresholds;

  return (
    <Box>
      {/* Daily threshold */}
      <Box display="flex" flexDirection="row">
        <Box marginRight={3}>
          <Typography>{strings.dailyThresholdTooltipLabel}</Typography>
        </Box>
        <Box flex={1} />
        <Typography fontWeight="400" align="right">
          {dailyThresholdDisplayValue}
        </Typography>
      </Box>

      {/* Weekly threshold */}
      <Box display="flex" flexDirection="row">
        <Box marginRight={3}>
          <Typography>{strings.weeklyThresholdTooltipLabel}</Typography>
        </Box>
        <Box flex={1} />
        <Typography fontWeight="400" align="right">
          {weeklyThresholdDisplayValue}
        </Typography>
      </Box>
    </Box>
  );
}
