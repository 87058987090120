import { RouterLink } from '@insights/components';
import { Box, Link, SxProps, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface NotFoundProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const NotFound = observer((props: NotFoundProps) => {
  const { accountService, localizationService, reactRouterRouteService } = useInsightsServices();
  const { className, style, sx } = props;

  const navigate = useNavigate();
  const theme = useTheme();
  const strings = localizationService.localizedStrings.insights.views.notFound;

  const openIntercom = () => {
    if (window.Intercom != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Intercom('showNewMessage');
    }
  };

  return (
    <Box sx={sx} className={className} style={style} display="flex" flexDirection="column" padding={3}>
      <Box marginBottom={3}>
        <Typography variant="h4">{strings.title}</Typography>
      </Box>

      <Box marginBottom={3}>
        <Typography variant="body1">
          {strings.errorOnOurPartText}
          <Link variant="body1" sx={{ color: theme.palette.secondary.dark }} onClick={openIntercom}>
            {strings.errorOnOurPartLetUsKnowLink}
          </Link>
          .
        </Typography>
      </Box>

      <Typography variant="body1">{strings.usefulLinks}</Typography>

      <RouterLink
        variant="body1"
        sx={{ color: theme.palette.secondary.dark }}
        to={reactRouterRouteService.resolveLocation(RouteTemplates.dashboard, [
          {
            name: RouteParamNames.configId,
            value: accountService.currentConfigId!
          }
        ])}
      >
        {strings.usefulLinksDashboard}
      </RouterLink>

      <Link variant="body1" sx={{ color: theme.palette.secondary.dark }} onClick={() => navigate(-1)}>
        {strings.usefulLinksGoBack}
      </Link>
    </Box>
  );
});
