import { BehaviourSummaryItemType } from '@insights/models';
import { Box, Menu, MenuItem, SxProps, Typography } from '@mui/material';
import { OQValue } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties, MouseEvent, useMemo, useState } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { BehaviourSummaryItem } from './BehaviourSummaryItem';

export interface BehaviourSummaryFilterItemProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  type: BehaviourSummaryItemType;
  filter?: OQValue;
  onFilterChanged?: (filter?: OQValue) => void;
}

export const BehaviourSummaryFilterItem = observer((props: BehaviourSummaryFilterItemProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], style, type, filter, onFilterChanged } = props;
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | undefined>(undefined);

  const title = useMemo(() => {
    const strings = localizationService.localizedStrings.insights.components.behaviourSummary;

    switch (type) {
      case 'app-open':
        return strings.appOpenFilterTitle;

      case 'task-completion':
        return strings.taskCompletionFilterTitle;

      default:
        throw new Error('Unknown type');
    }
  }, [type, localizationService.currentLocale]);

  const onChooseValueClick = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorElement(event.currentTarget);
  };

  const onValueClick = (value?: OQValue) => {
    if (onFilterChanged != null && filter !== value) {
      onFilterChanged(value);
    }

    setMenuAnchorElement(undefined);
  };

  return (
    <Box sx={sx} className={className} style={style}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="body1">{title}</Typography>

        <Box flex={1} />

        <BehaviourSummaryItem
          sx={{ cursor: 'pointer' }}
          type={type}
          value={filter}
          showTooltip={false}
          onClick={onChooseValueClick}
        />

        {/* The negative origin is to make the menu render on top of the icon */}
        <Menu
          anchorEl={menuAnchorElement}
          anchorOrigin={{ horizontal: -16, vertical: 'center' }}
          keepMounted
          open={Boolean(menuAnchorElement)}
          onClose={() => setMenuAnchorElement(undefined)}
        >
          <MenuItem onClick={() => onValueClick(undefined)} selected={!filter}>
            <BehaviourSummaryItem type={type} showTooltip={false} />
          </MenuItem>
          <MenuItem onClick={() => onValueClick('green')} selected={filter === 'green'}>
            <BehaviourSummaryItem type={type} value="green" showTooltip={false} />
          </MenuItem>
          <MenuItem onClick={() => onValueClick('yellow')} selected={filter === 'yellow'}>
            <BehaviourSummaryItem type={type} value="yellow" showTooltip={false} />
          </MenuItem>
          <MenuItem onClick={() => onValueClick('red')} selected={filter === 'red'}>
            <BehaviourSummaryItem type={type} value="red" showTooltip={false} />
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
});
