import DeleteIcon from '@mui/icons-material/Delete';
import { Grid2, IconButton, SxProps, TextField } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { EditableQuestionChoice } from '@shared/models/onboarding/implementations';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { OnboardingTextEdition } from './OnboardingTextEdition';

export interface OnboardingChoiceEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  choice: EditableQuestionChoice;
}

export const OnboardingChoiceEdition = observer((props: OnboardingChoiceEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], choice } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const enText = choice.editableLabel.find((t) => t.languageCode === 'en');
  const frText = choice.editableLabel.find((t) => t.languageCode === 'fr');

  return (
    <Grid2
      container
      className={className}
      style={style}
      spacing={1}
      sx={[
        {
          alignItems: 'flex-end'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      {enText && (
        <Grid2 size={{ xs: 6, sm: 4 }}>
          <OnboardingTextEdition label="" text={enText} enforcePlainText rows={1} />
        </Grid2>
      )}
      {frText && (
        <Grid2 size={{ xs: 6, sm: 4 }}>
          <OnboardingTextEdition label="" text={frText} enforcePlainText rows={1} />
        </Grid2>
      )}
      <Grid2 size={{ xs: 5, sm: 3 }}>
        <TextField
          fullWidth
          label={strings.questionChoiceValueLabel}
          value={choice.value}
          onChange={(e) => (choice.value = e.target.value)}
        />
      </Grid2>
      <Grid2 size={{ xs: 1 }}>
        <IconButton onClick={() => choice.markAsDeleted()}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Grid2>
    </Grid2>
  );
});
