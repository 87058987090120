export interface TaskDueBoxStrings {
  announced: string;
  planned: string;
  due: string;
  period: string;
  none: string;
}

export const EnglishTaskDueBoxStrings: TaskDueBoxStrings = {
  announced: 'announced',
  planned: 'planned',
  due: 'due',
  period: 'period',
  none: 'None'
};

export const FrenchTaskDueBoxStrings: TaskDueBoxStrings = {
  announced: 'annoncée',
  planned: 'planifiée',
  due: 'due',
  period: 'période',
  none: 'Aucune'
};
