import { DateTimePickerLocalizationProvider, InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  SxProps,
  ThemeProvider
} from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface EditSchoolYearConfigurationDatesDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  schoolYearConfiguration: SchoolYearConfigurationModel;
}

export const EditSchoolYearConfigurationDatesDialog = observer((props: EditSchoolYearConfigurationDatesDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style, schoolYearConfiguration, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createEditSchoolYearConfigurationDates(schoolYearConfiguration, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <DateTimePickerLocalizationProvider>
        <Dialog
          sx={sx}
          open={true}
          onClose={() => void viewModel.cancel()}
          maxWidth="sm"
          fullWidth={true}
          className={className}
          style={style}
        >
          <form
            noValidate
            onSubmit={(e) => {
              // This is to prevent the page from reloading on submit
              e.preventDefault();
              return false;
            }}
          >
            <DialogTitle>{strings.editDatesDialogTitle}</DialogTitle>
            <DialogContent dividers>
              <Grid2 container spacing={2}>
                {/* Start Day */}
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        required: true,
                        fullWidth: true,
                        error: viewModel.getFieldError('startDay') != null,
                        helperText: viewModel.getFieldError('startDay')
                      },
                      actionBar: {
                        actions: ['cancel', 'accept']
                      }
                    }}
                    localeText={{
                      okButtonLabel: strings.okButtonCaption,
                      cancelButtonLabel: strings.cancelButtonCaption
                    }}
                    format={localizationService.localizedStrings.models.dateFormats.mediumUnabridged}
                    label={strings.startDateFieldLabel}
                    value={(viewModel.getFieldValue('startDay') as Date | undefined) ?? null}
                    onChange={(e) => viewModel.setFieldValue('startDay', e ?? undefined)}
                  />
                </Grid2>

                {/* End Day */}
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        required: true,
                        fullWidth: true,
                        error: viewModel.getFieldError('endDay') != null,
                        helperText: viewModel.getFieldError('endDay')
                      },
                      actionBar: {
                        actions: ['cancel', 'accept']
                      }
                    }}
                    localeText={{
                      okButtonLabel: strings.okButtonCaption,
                      cancelButtonLabel: strings.cancelButtonCaption
                    }}
                    format={localizationService.localizedStrings.models.dateFormats.mediumUnabridged}
                    label={strings.endDateFieldLabel}
                    value={(viewModel.getFieldValue('endDay') as Date | undefined) ?? null}
                    onChange={(e) => viewModel.setFieldValue('endDay', e ?? undefined)}
                  />
                </Grid2>

                {/* Teacher Access Date */}
                <Grid2 size={{ xs: 12 }}>
                  <DateTimePicker
                    slotProps={{
                      textField: {
                        required: true,
                        fullWidth: true,
                        error: viewModel.getFieldError('teacherAccessDate') != null,
                        helperText: viewModel.getFieldError('teacherAccessDate')
                      },
                      actionBar: {
                        actions: ['clear', 'cancel', 'accept']
                      }
                    }}
                    localeText={{
                      okButtonLabel: strings.okButtonCaption,
                      cancelButtonLabel: strings.cancelButtonCaption,
                      clearButtonLabel: strings.clearButtonCaption
                    }}
                    ampm={localizationService.currentLocale !== 'fr'}
                    format={localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime}
                    label={strings.teachersAccessDate}
                    value={(viewModel.getFieldValue('teacherAccessDate') as Date | undefined) ?? null}
                    onChange={(e) => viewModel.setFieldValue('teacherAccessDate', e ?? undefined)}
                  />
                </Grid2>

                {/* Student Access Date */}
                <Grid2 size={{ xs: 12 }}>
                  <DateTimePicker
                    slotProps={{
                      textField: {
                        required: true,
                        fullWidth: true,
                        error: viewModel.getFieldError('studentAccessDate') != null,
                        helperText: viewModel.getFieldError('studentAccessDate')
                      },
                      actionBar: {
                        actions: ['clear', 'cancel', 'accept']
                      }
                    }}
                    localeText={{
                      okButtonLabel: strings.okButtonCaption,
                      cancelButtonLabel: strings.cancelButtonCaption,
                      clearButtonLabel: strings.clearButtonCaption
                    }}
                    ampm={localizationService.currentLocale !== 'fr'}
                    format={localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime}
                    label={strings.studentsAccessDate}
                    value={(viewModel.getFieldValue('studentAccessDate') as Date | undefined) ?? null}
                    onChange={(e) => viewModel.setFieldValue('studentAccessDate', e ?? undefined)}
                  />
                </Grid2>
              </Grid2>
            </DialogContent>
            <DialogActions>
              <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
                {strings.cancelButtonCaption}
              </InsightsButton>
              <InsightsButton
                isSubmit
                showSpinnerOnExecuting
                isExecuting={viewModel.isSubmitting}
                onClick={() => void viewModel.save()}
              >
                {strings.saveButtonCaption}
              </InsightsButton>
            </DialogActions>
          </form>
        </Dialog>
      </DateTimePickerLocalizationProvider>
    </ThemeProvider>
  );
});
