import { EditableTransformationColumnViewModel } from '@insights/viewmodels';
import InfoIcon from '@mui/icons-material/HelpOutline';
import { Box, FormControl, MenuItem, Select, SxProps, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface EditableOperationProps {
  sx?: SxProps;
  className?: string;
  column: EditableTransformationColumnViewModel;
}

export const EditableOperation = observer((props: EditableOperationProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, column } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <Box display="flex" flexDirection="row" alignItems="center" sx={sx} className={className} mb="10px">
      <FormControl fullWidth>
        <Select
          autoFocus={column.focusedField === 'operation'}
          fullWidth
          displayEmpty
          value={column.operation.name}
          onChange={(e) => column.setOperationByName(e.target.value)}
          onFocus={() => column.setFocusedField('operation')}
          renderValue={(v) => <Typography>{v || strings.noOperation}</Typography>}
        >
          {column.availableOperations.map((o) => (
            <MenuItem key={`operation-${o.name}`} value={o.name}>
              <Tooltip title={o.description}>
                <Typography>{o.name || strings.noOperation}</Typography>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Tooltip title={column.operation.description}>
        <InfoIcon sx={{ ml: 5, mr: 1 }} fontSize="small" color="secondary" />
      </Tooltip>
    </Box>
  );
});
