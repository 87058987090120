import {
  EnglishPeriodFilterOptionStrings,
  FrenchPeriodFilterOptionStrings,
  PeriodFilterOptionsStrings
} from './PeriodFilterOptionsStrings';
import {
  EnglishPeriodsOptionMenuStrings,
  FrenchPeriodsOptionMenuStrings,
  PeriodsOptionMenuStrings
} from './PeriodsOptionMenuStrings';

export interface PeriodsStrings {
  readonly optionMenu: PeriodsOptionMenuStrings;
  readonly filterOptions: PeriodFilterOptionsStrings;

  readonly noOccurrences: string;
  readonly occurrencesLeft: (occurrencesLeft: number) => string;

  readonly sectionFilterTitle: string;
  readonly sectionFilterClose: string;
  readonly contentFilterTitle: string;
  readonly contentFilterClose: string;
  readonly contentFilterButton: string;
  readonly sectionFilterButton: string;
}

export const EnglishPeriodsStrings: PeriodsStrings = {
  optionMenu: EnglishPeriodsOptionMenuStrings,
  filterOptions: EnglishPeriodFilterOptionStrings,

  noOccurrences: 'You have no periods for this section.',

  occurrencesLeft: (occurrencesLeft: number) => {
    return `${occurrencesLeft} to go`;
  },

  contentFilterTitle: 'Filter Contents',
  contentFilterClose: 'Close',
  sectionFilterTitle: 'Filter Sections',
  sectionFilterClose: 'Close',
  contentFilterButton: 'Tasks',
  sectionFilterButton: 'Section'
};

export const FrenchPeriodsStrings: PeriodsStrings = {
  optionMenu: FrenchPeriodsOptionMenuStrings,
  filterOptions: FrenchPeriodFilterOptionStrings,

  noOccurrences: 'Vous n’avez aucune période pour ce cours.',

  occurrencesLeft: (occurrencesLeft: number) => {
    return `${occurrencesLeft} à venir`;
  },

  contentFilterTitle: 'Filtres du contenu',
  contentFilterClose: 'Fermer',
  sectionFilterTitle: 'Filtrer les cours',
  sectionFilterClose: 'Fermer',
  contentFilterButton: 'Tâches',
  sectionFilterButton: 'Groupes'
};
