import { LayoutType, ObservablePresenter, ValuePresenter } from '@insights/components';
import { StudentConfigGlobalStatsViewModel } from '@insights/viewmodels';
import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { MinLayoutWidth, MinTinyChartHeight, StudentsColor } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface StudentAccountsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: StudentConfigGlobalStatsViewModel;
}

export const StudentAccounts = observer((props: StudentAccountsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.students;

  return (
    <Box sx={{ ...sx, height: MinTinyChartHeight, minWidth: MinLayoutWidth }} className={className} style={style}>
      <ObservablePresenter
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        data={viewModel.data}
        indicatorsSize="small"
        loadingMessage={strings.loadingStudentAccountsMessage}
        errorMessage={strings.loadingStudentAccountsErrorMessage}
        render={(data) => (
          <ValuePresenter
            caption={strings.students}
            subcaption={strings.numberOfConnectedStudents}
            maximum={data.expectedStudentCount || data.stats.accounts.studentTotal}
            value={data.stats.accounts.studentClaimed}
            valueColor={StudentsColor}
            layoutType={LayoutType.Horizontal}
            tooltipText={
              data.expectedStudentCount === 0
                ? `<table>
                  <tr>
                    <td style="font-weight: 500; text-align: right;">${data.stats.accounts.studentClaimed}</td>
                    <td>${strings.connectedStudents}</td>
                  </tr>
                  <tr>
                    <td style="font-weight: 500; text-align: right;">${data.stats.accounts.studentTotal}</td>
                    <td>${strings.studentsTotal}</td>
                  </tr>
                </table>`
                : `<table>
                <tr>
                  <td style="font-weight: 500; text-align: right;">${data.stats.accounts.studentClaimed}</td>
                  <td>${strings.connectedStudents}</td>
                </tr>
                <tr>
                  <td style="font-weight: 500; text-align: right;">${data.expectedStudentCount}</td>
                  <td>${strings.studentsExpected}</td>
                </tr>
                <tr>
                  <td style="font-weight: 500; text-align: right;">${data.stats.accounts.studentTotal}</td>
                  <td>${strings.studentsTotal}</td>
                </tr>
              </table>`
            }
          />
        )}
      />
    </Box>
  );
});
