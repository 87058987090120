import {
  CalendarViewModelsStrings,
  EnglishCalendarViewModelsStrings,
  FrenchCalendarViewModelsStrings
} from './CalendarViewModelsStrings';
import {
  ConnectorViewModelsStrings,
  EnglishConnectorViewModelsStrings,
  FrenchConnectorViewModelsStrings
} from './ConnectorViewModelsStrings';
import { CreateSchoolStrings, EnglishCreateSchoolStrings, FrenchCreateSchoolStrings } from './CreateSchoolStrings';
import {
  CsvExportViewModelStrings,
  EnglishCsvExportViewModelStrings,
  FrenchCsvExportViewModelStrings
} from './CsvExportViewModelStrings';
import { EditViewModelStrings, EnglishEditViewModelStrings, FrenchEditViewModelStrings } from './EditViewModelStrings';
import {
  EnglishFeaturesViewModelStrings,
  FeaturesViewModelStrings,
  FrenchFeaturesViewModelStrings
} from './FeaturesViewModelStrings';
import {
  EnglishImportViewModelsStrings,
  FrenchImportViewModelsStrings,
  ImportViewModelsStrings
} from './ImportViewModelsStrings';
import {
  EnglishMetricViewModelsStrings,
  FrenchMetricViewModelsStrings,
  MetricViewModelsStrings
} from './MetricViewModelsStrings';
import {
  EnglishOnboardingViewModelStrings,
  FrenchOnboardingViewModelStrings,
  OnboardingViewModelStrings
} from './OnboardingViewModelStrings';
import {
  EnglishSchoolCreationViewModelStrings,
  FrenchSchoolCreationViewModelStrings,
  SchoolCreationViewModelStrings
} from './SchoolCreationViewModelStrings';
import {
  EnglishSchoolYearConfigurationStrings,
  FrenchSchoolYearConfigurationStrings,
  SchoolYearConfigurationStrings
} from './SchoolYearConfigurationStrings';
import {
  EnglishSectionViewModelStrings,
  FrenchSectionViewModelStrings,
  SectionViewModelStrings
} from './SectionViewModelStrings';
import {
  EnglishSectionsViewModelStrings,
  FrenchSectionsViewModelStrings,
  SectionsViewModelStrings
} from './SectionsViewModelStrings';
import {
  EnglishSettingsViewModelStrings,
  FrenchSettingsViewModelStrings,
  SettingsViewModelStrings
} from './SettingsViewModelStrings';
import {
  EnglishUsersViewModelStrings,
  FrenchUsersViewModelStrings,
  UsersViewModelStrings
} from './UsersViewModelStrings';

export interface ViewModelsStrings {
  readonly calendar: CalendarViewModelsStrings;
  readonly connectors: ConnectorViewModelsStrings;
  readonly createSchool: CreateSchoolStrings;
  readonly csvExports: CsvExportViewModelStrings;
  readonly edit: EditViewModelStrings;
  readonly features: FeaturesViewModelStrings;
  readonly import: ImportViewModelsStrings;
  readonly metrics: MetricViewModelsStrings;
  readonly onboarding: OnboardingViewModelStrings;
  readonly settings: SettingsViewModelStrings;
  readonly schoolCreation: SchoolCreationViewModelStrings;
  readonly schoolYearConfiguration: SchoolYearConfigurationStrings;
  readonly section: SectionViewModelStrings;
  readonly sections: SectionsViewModelStrings;
  readonly users: UsersViewModelStrings;
}

export const EnglishViewModelsStrings: ViewModelsStrings = {
  calendar: EnglishCalendarViewModelsStrings,
  connectors: EnglishConnectorViewModelsStrings,
  createSchool: EnglishCreateSchoolStrings,
  csvExports: EnglishCsvExportViewModelStrings,
  edit: EnglishEditViewModelStrings,
  features: EnglishFeaturesViewModelStrings,
  import: EnglishImportViewModelsStrings,
  metrics: EnglishMetricViewModelsStrings,
  onboarding: EnglishOnboardingViewModelStrings,
  settings: EnglishSettingsViewModelStrings,
  schoolCreation: EnglishSchoolCreationViewModelStrings,
  schoolYearConfiguration: EnglishSchoolYearConfigurationStrings,
  section: EnglishSectionViewModelStrings,
  sections: EnglishSectionsViewModelStrings,
  users: EnglishUsersViewModelStrings
};

export const FrenchViewModelsStrings: ViewModelsStrings = {
  calendar: FrenchCalendarViewModelsStrings,
  connectors: FrenchConnectorViewModelsStrings,
  createSchool: FrenchCreateSchoolStrings,
  csvExports: FrenchCsvExportViewModelStrings,
  edit: FrenchEditViewModelStrings,
  features: FrenchFeaturesViewModelStrings,
  import: FrenchImportViewModelsStrings,
  metrics: FrenchMetricViewModelsStrings,
  onboarding: FrenchOnboardingViewModelStrings,
  settings: FrenchSettingsViewModelStrings,
  schoolCreation: FrenchSchoolCreationViewModelStrings,
  schoolYearConfiguration: FrenchSchoolYearConfigurationStrings,
  section: FrenchSectionViewModelStrings,
  sections: FrenchSectionsViewModelStrings,
  users: FrenchUsersViewModelStrings
};
