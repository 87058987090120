import { AlertService } from '@insights/services';
import { EditableSchoolYearConfiguration, SchoolYearConfigurationModel } from '@shared/models/config';
import { EditableWorkloadThreshold } from '@shared/models/config/EditableWorkloadThreshold';
import { GradeLevelSource } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { computedFn } from 'mobx-utils';
import { AppEditViewModelBase, EditViewModelBase } from '../../EditViewModelBase';

export type SchoolYearConfigurationThresholdsFields =
  | 'defaultDailyThreshold'
  | 'defaultWeeklyThreshold'
  | 'gradeLevelSource';

export interface EditSchoolYearConfigurationThresholdsDialogViewModel
  extends EditViewModelBase<SchoolYearConfigurationThresholdsFields> {
  readonly gradeLevels: string[];

  getDailyWorkloadThreshold(gradeLevel: string): number | undefined;
  setDailyWorkloadThreshold(gradeLevel: string, value: number | undefined): void;
  getWeeklyWorkloadThreshold(gradeLevel: string): number | undefined;
  setWeeklyWorkloadThreshold(gradeLevel: string, value: number | undefined): void;
}

export class AppEditSchoolYearConfigurationThresholdsDialogViewModel
  extends AppEditViewModelBase<SchoolYearConfigurationThresholdsFields>
  implements EditSchoolYearConfigurationThresholdsDialogViewModel
{
  private readonly _editableSchoolYearConfiguration: EditableSchoolYearConfiguration;

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _schoolYearConfiguration: SchoolYearConfigurationModel,
    private readonly _sectionsGradeLevels: string[],
    private readonly _studentsGradeLevels: string[],
    alertService: AlertService,
    localizationService: LocalizationService,
    onSuccess: () => void,
    onCancel: () => void
  ) {
    super(alertService, localizationService, onSuccess, onCancel);
    makeObservable(this);

    this._editableSchoolYearConfiguration = new EditableSchoolYearConfiguration(_schoolYearConfiguration);
  }

  @computed
  get gradeLevels(): string[] {
    return this._editableSchoolYearConfiguration.gradeLevelSource === 'account'
      ? this._studentsGradeLevels
      : this._sectionsGradeLevels;
  }

  @computed
  get hasChanges(): boolean {
    return this._editableSchoolYearConfiguration.hasChanges;
  }

  getDailyWorkloadThreshold = computedFn((gradeLevel: string): number | undefined => {
    const threshold = this.getEditableWorkloadThreshold(gradeLevel, false);
    return threshold?.dailyThreshold;
  });

  setDailyWorkloadThreshold(gradeLevel: string, value: number | undefined) {
    const hasValue = Boolean(value);
    const threshold = this.getEditableWorkloadThreshold(gradeLevel, hasValue);

    if (threshold == null) {
      return;
    }

    if (hasValue) {
      threshold.dailyThreshold = value!;
    } else {
      threshold.dailyThreshold = 0;

      if (threshold.dailyThreshold === 0 && threshold.weeklyThreshold === 0) {
        threshold.markAsDeleted();
      }
    }
  }

  getWeeklyWorkloadThreshold = computedFn((gradeLevel: string): number | undefined => {
    const threshold = this.getEditableWorkloadThreshold(gradeLevel, false);
    return threshold?.weeklyThreshold;
  });

  setWeeklyWorkloadThreshold(gradeLevel: string, value: number | undefined) {
    const hasValue = Boolean(value);
    const threshold = this.getEditableWorkloadThreshold(gradeLevel, hasValue);

    if (threshold == null) {
      return;
    }

    if (hasValue) {
      threshold.weeklyThreshold = value!;
    } else {
      threshold.weeklyThreshold = 0;

      if (threshold.dailyThreshold === 0 && threshold.weeklyThreshold === 0) {
        threshold.markAsDeleted();
      }
    }
  }

  getFieldValueCore(field: SchoolYearConfigurationThresholdsFields): number | GradeLevelSource {
    switch (field) {
      case 'defaultDailyThreshold':
        return this._editableSchoolYearConfiguration.dailyWorkloadThreshold;
      case 'defaultWeeklyThreshold':
        return this._editableSchoolYearConfiguration.weeklyWorkloadThreshold;
      case 'gradeLevelSource':
        return this._editableSchoolYearConfiguration.gradeLevelSource;
      default:
        throw new Error('Unknown field');
    }
  }

  setFieldValueCore(field: SchoolYearConfigurationThresholdsFields, value: number | GradeLevelSource) {
    switch (field) {
      case 'defaultDailyThreshold':
        this._editableSchoolYearConfiguration.dailyWorkloadThreshold = (value as number) || 0;
        this.validateDailyThreshold();
        break;
      case 'defaultWeeklyThreshold':
        this._editableSchoolYearConfiguration.weeklyWorkloadThreshold = (value as number) || 0;
        this.validateWeeklyThreshold();
        break;
      case 'gradeLevelSource':
        this._editableSchoolYearConfiguration.gradeLevelSource = value as GradeLevelSource;
        // Does not need validation
        break;

      default:
        throw new Error('Unknown field');
    }
  }

  protected async saveCore(): Promise<void> {
    await this._schoolYearConfigurationStore.saveConfig(this._editableSchoolYearConfiguration);
  }

  protected validateFields() {
    this.validateDailyThreshold();
    this.validateWeeklyThreshold();
  }

  private validateDailyThreshold() {
    const fieldName = 'defaultDailyThreshold';
    const fieldError: string | undefined = undefined;

    // TODO Add validation logic

    this.setError(fieldName, fieldError);
  }

  private validateWeeklyThreshold() {
    const fieldName = 'defaultWeeklyThreshold';
    const fieldError: string | undefined = undefined;

    // TODO Add validation logic

    this.setError(fieldName, fieldError);
  }

  private getEditableWorkloadThreshold(
    gradeLevel: string,
    createIfNotFound: boolean
  ): EditableWorkloadThreshold | undefined {
    let threshold = this._editableSchoolYearConfiguration.allWorkloadThresholds.find(
      (t) => t.gradeLevel === gradeLevel
    );

    if (threshold != null) {
      threshold.markAsNotDeleted();
    }

    if (threshold == null && createIfNotFound) {
      threshold = EditableWorkloadThreshold.createNew(gradeLevel);
      this._editableSchoolYearConfiguration.addWorkloadThreshold(threshold);
    }

    return threshold;
  }
}
