import {
  BlackbaudSkyAssignmentTypeMappingModel,
  BlackbaudSkyAuthorizationResult,
  BlackbaudSkyExternalAccountDetails,
  BlackbaudSkySchoolLevel,
  BlackbaudSkySchoolYear,
  GrpcBlackbaudSkySchoolLevel,
  GrpcBlackbaudSkySchoolYear,
  GrpcBlackbaudSkyTokenInformation
} from '@shared/models/connectors';
import { GrpcBlackbaudSkyExternalAccountDetails } from '@shared/models/connectors/GrpcBlackbaudSkyExternalAccountDetails';
import { BlackbaudSkyTransport } from '../../../transports';
import { BlackbaudSkyConnectorStore, ConnectorsStore } from '../../interfaces';
import { AppBaseStore } from '../AppBaseStore';

export class AppBlackbaudSkyConnectorStore extends AppBaseStore implements BlackbaudSkyConnectorStore {
  constructor(
    private readonly _transport: BlackbaudSkyTransport,
    connectorStore: ConnectorsStore
  ) {
    super('AppBlackbaudSkyConnectorStore', false, connectorStore);
  }

  async getAccountDetails(externalAccountId: string): Promise<BlackbaudSkyExternalAccountDetails> {
    const details = await this._transport.getExternalAccountDetails(externalAccountId);
    return new GrpcBlackbaudSkyExternalAccountDetails(details);
  }

  async createOrUpdateAccount(externalAccountId: string, configId: string, accountName: string): Promise<string> {
    const response = await this._transport.createOrUpdateExternalAccount(externalAccountId, configId, accountName);
    this.invalidate();
    return response.externalAccountId;
  }

  async getAuthorizationUrl(externalAccountId: string, configId: string): Promise<string> {
    return await this._transport.getAuthorizationUrl(
      externalAccountId,
      configId,
      `${window.location.origin}/blackbaud/callback`
    );
  }

  async completeAuthorization(
    state: string,
    authorizationCode: string,
    error: string
  ): Promise<BlackbaudSkyAuthorizationResult> {
    const result = await this._transport.completeAuthorization(state, authorizationCode, error);
    this.invalidate();

    const pbTokens = result.tokens;

    return {
      externalAccountId: result.externalAccountId,
      tokens: pbTokens != null ? new GrpcBlackbaudSkyTokenInformation(pbTokens) : undefined,
      configId: result.configId,
      hasAccess: result.hasAccess
    };
  }

  async updateSetup(
    configId: string,
    externalAccountId: string,
    schoolYearLabel: string,
    schoolLevelId: bigint,
    portalAddress: string
  ): Promise<void> {
    await this._transport.updateExternalAccountSetup(
      externalAccountId,
      configId,
      schoolYearLabel,
      schoolLevelId,
      portalAddress
    );
    this.invalidate();
  }

  async updateSettings(
    configId: string,
    externalAccountId: string,
    mappings: BlackbaudSkyAssignmentTypeMappingModel[],
    ignoreUnmappedAssignmentTypes: boolean
  ): Promise<void> {
    const pbMappings = mappings.map((m) => m.toProtobuf());
    await this._transport.updateExternalAccountSettings(
      externalAccountId,
      configId,
      pbMappings,
      ignoreUnmappedAssignmentTypes
    );
    this.invalidate();
  }

  async getSchoolYears(externalAccountId: string): Promise<BlackbaudSkySchoolYear[]> {
    const pbYears = await this._transport.getSchoolYears(externalAccountId);
    return pbYears.map((pb) => new GrpcBlackbaudSkySchoolYear(pb));
  }

  async getSchoolLevels(externalAccountId: string): Promise<BlackbaudSkySchoolLevel[]> {
    const pbLevels = await this._transport.getSchoolLevels(externalAccountId);
    return pbLevels.map((pb) => new GrpcBlackbaudSkySchoolLevel(pb));
  }
}
