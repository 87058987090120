import { SchoolYearConfiguration_WorkloadThreshold as PBWorkloadThreshold } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableStringProperty, EditableValuePropertyEx } from '../editables';
import { WorkloadThreshold, WorkloadThresholdModel } from './WorkloadThreshold';

export class EditableWorkloadThreshold extends EditableModelEx<PBWorkloadThreshold> implements WorkloadThresholdModel {
  private _gradeLevel: EditableStringProperty<PBWorkloadThreshold>;
  private _dailyThreshold: EditableValuePropertyEx<number, PBWorkloadThreshold>;
  private _weeklyThreshold: EditableValuePropertyEx<number, PBWorkloadThreshold>;

  static createNew(gradeLevel: string) {
    const pb = new PBWorkloadThreshold();
    pb.gradeLevel = gradeLevel;

    return new EditableWorkloadThreshold(new WorkloadThreshold(pb), true);
  }

  constructor(
    private readonly _originalWorkloadThreshold: WorkloadThresholdModel,
    isNew = false
  ) {
    super(_originalWorkloadThreshold.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._gradeLevel = new EditableStringProperty(
        _originalWorkloadThreshold.gradeLevel,
        (pb, value) => (pb.gradeLevel = value),
        {
          trim: true
        }
      )),
      (this._dailyThreshold = new EditableValuePropertyEx(
        _originalWorkloadThreshold.dailyThreshold,
        (pb, value) => (pb.dailyThreshold = value)
      )),
      (this._weeklyThreshold = new EditableValuePropertyEx(
        _originalWorkloadThreshold.weeklyThreshold,
        (pb, value) => (pb.weeklyThreshold = value)
      ))
    ]);
  }

  @computed
  get gradeLevel(): string {
    return this._gradeLevel.value;
  }

  set gradeLevel(value: string) {
    this._gradeLevel.value = value;
  }

  @computed
  get dailyThreshold(): number {
    return this._dailyThreshold.value;
  }

  set dailyThreshold(value: number) {
    this._dailyThreshold.value = value;
  }

  @computed
  get weeklyThreshold(): number {
    return this._weeklyThreshold.value;
  }

  set weeklyThreshold(value: number) {
    this._weeklyThreshold.value = value;
  }
}
