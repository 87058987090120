import { AccountService, AlertService, NavigationService, SettingsStore } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { DashboardProcessComments } from '@shared/models/onboarding/interfaces';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores/interfaces';
import { subDays } from 'date-fns';
import { keyBy } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { NavigateFunction } from 'react-router-dom';
import {
  AppOnboardingCommentsViewModel,
  AppOnboardingProcessViewModel,
  AppOnboardingStepSummaryViewModel,
  OnboardingCommentsViewModel,
  OnboardingProcessViewModel,
  OnboardingStepSummaryViewModel
} from './onboarding';

export interface OnboardingStepCommentsDashboardViewModel {
  readonly stepSummary: OnboardingStepSummaryViewModel;
  readonly comments: OnboardingCommentsViewModel;
}

export interface OnboardingProcessCommentsDashboardViewModel {
  readonly process: OnboardingProcessViewModel;
  readonly steps: OnboardingStepCommentsDashboardViewModel[];
  readonly schoolName: string;

  navigateToProcess(navigate: NavigateFunction): Promise<void>;
}

export interface OnboardingCommentsDashboardViewModel {
  readonly data: IPromiseBasedObservable<OnboardingProcessCommentsDashboardViewModel[]>;
}

export class AppOnboardingProcessCommentsDashboardViewModel implements OnboardingProcessCommentsDashboardViewModel {
  private readonly _accountsById: Record<string, AccountModel>;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _settings: SettingsStore,
    private readonly _processComments: DashboardProcessComments
  ) {
    makeObservable(this);
    this._accountsById = keyBy(_processComments.accounts, (a) => a.id);
  }

  @computed
  get process() {
    const process = this._processComments.process!;

    return new AppOnboardingProcessViewModel(
      this._onboardingStore,
      this._accountService,
      this._navigationService,
      this._alertService,
      this._localizationService,
      this._settings,
      process.configId,
      this._accountsById,
      process,
      new AppOnboardingCommentsViewModel(
        this._onboardingStore,
        this._accountService,
        this._navigationService,
        this._alertService,
        this._localizationService,
        process.templateName,
        process.status,
        '',
        process.agentId,
        process.clientId,
        this._accountsById,
        this._processComments.comments,
        false,
        undefined,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      )
    );
  }

  @computed
  get steps() {
    const process = this._processComments.process!;

    return this._processComments.steps.map((s) => ({
      stepSummary: new AppOnboardingStepSummaryViewModel(
        this._accountService,
        this._navigationService,
        process.configId,
        this.process,
        s.step!,
        this._accountsById
      ),
      comments: new AppOnboardingCommentsViewModel(
        this._onboardingStore,
        this._accountService,
        this._navigationService,
        this._alertService,
        this._localizationService,
        process.templateName,
        process.status,
        '',
        process.agentId,
        process.clientId,
        this._accountsById,
        s.comments,
        false,
        undefined,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      )
    }));
  }

  @computed
  get schoolName() {
    return this._processComments.schoolName;
  }

  navigateToProcess(navigate: NavigateFunction): Promise<void> {
    return this._navigationService.navigateToOnboardingProcess(
      this.process.configId,
      this.process.templateName,
      navigate
    );
  }
}

export class AppOnboardingCommentsDashboardViewModel implements OnboardingCommentsDashboardViewModel {
  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _settings: SettingsStore
  ) {
    makeObservable(this);
  }

  @computed
  get data() {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<OnboardingProcessCommentsDashboardViewModel[]> {
    const processesComments = await this._onboardingStore.getDashboardProcessesComments(subDays(new Date(), 7));

    return processesComments.map(
      (pc) =>
        new AppOnboardingProcessCommentsDashboardViewModel(
          this._onboardingStore,
          this._accountService,
          this._navigationService,
          this._alertService,
          this._localizationService,
          this._settings,
          pc
        )
    );
  }
}
