import { SchoolDay } from '@shared/models/calendar';
import { SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { ContentIcon, ContentWorkloadLevel, Day, Role } from '@shared/models/types';
import { LocalizedStrings } from '@shared/resources/strings/LocalizedStrings';
import { dateService } from '@shared/services';
import { AccountData, StudyoObjectId } from '@shared/services/stores';
import { TaskIconPublishKind } from '../../contents';
import { DateUtils } from './DateUtils';

export interface ContentCompareObject {
  content: ContentDefinitionModel;
  isAssignment: boolean;
}

export class ContentDefinitionUtils {
  /**
   * Returns the title to display for a specific content. It will take the title if one is specified
   * and will revert to the default title of the content icon kind.
   *
   * @param {ContentDefinitionModel} content
   * @param strings
   * @returns {string}
   */
  static getDisplayTitleForContent(content: ContentDefinitionModel, strings: LocalizedStrings): string {
    if (content.kind === 'note') {
      return strings.models.contents.defaultNotesTitle;
    }

    if (content.title !== '') {
      return content.title;
    }

    return strings.models.contents.defaultTitle(content.icon);
  }

  static isAssignedToDay(content: ContentDefinitionModel, day: Day): boolean {
    return !content.dueDay.isSame(day) && content.assignmentDay.isSame(day);
  }

  static compareLocalizedContents(
    content1: ContentCompareObject,
    content2: ContentCompareObject,
    strings: LocalizedStrings,
    locale: string
  ): number {
    // Target order:
    // 1. Active due tasks
    //    - Then by workload, then title
    // 2. Notes
    //    - Then by notes
    // 3. Assigned tasks
    //    - Then by workload, then title
    // 4. Completed due tasks
    //    - Then by workload, then title
    const content1Weight = this.getContentWeight(content1);
    const content2Weight = this.getContentWeight(content2);

    if (content1Weight !== content2Weight) {
      return content2Weight - content1Weight;
    }

    if (content1.content.kind === 'note') {
      return content1.content.notes.localeCompare(content2.content.notes, locale, {
        sensitivity: 'base'
      });
    }

    const displayTitleContent1 = ContentDefinitionUtils.getDisplayTitleForContent(content1.content, strings);
    const displayTitleContent2 = ContentDefinitionUtils.getDisplayTitleForContent(content2.content, strings);

    // Comparing tasks based on their localized title
    return displayTitleContent1.localeCompare(displayTitleContent2, locale, {
      sensitivity: 'base'
    });
  }

  static getContentWeight(content: ContentCompareObject): number {
    return this.getKindWeight(content) * this.workloadLevelWeightCount + this.getWorkloadLevelWeight(content);
  }

  static kindWeightCount = 4;
  static getKindWeight(content: ContentCompareObject): number {
    return content.isAssignment ? 1 : content.content.kind === 'note' ? 2 : content.content.state === 'active' ? 3 : 0;
  }

  static workloadLevelWeightCount = 5;
  static getWorkloadLevelWeight(content: ContentCompareObject): number {
    if (content.content.kind !== 'task') {
      return 0;
    }

    switch (content.content.workloadLevel) {
      case 'unknown':
        return 0;
      case 'none':
        return 1;
      case 'regular':
        return 2;
      case 'medium':
        return 3;
      case 'major':
        return 4;
      default:
        return 0;
    }
  }

  static getDisplayedNotes(content: ContentDefinitionModel) {
    return content.notes !== '' ? content.notes : (content.masterContent?.notes ?? '');
  }

  static getTaskIconPublishKind(
    content: ContentDefinitionModel,
    sectionsById: Map<StudyoObjectId, SectionModel>,
    schoolYearConfig: SchoolYearConfigurationModel
  ): TaskIconPublishKind {
    if (content.isPublished) {
      const isStudentFiltered = ContentDefinitionUtils.shouldBeFilteredByAssessmentPlanningForRole(
        content,
        'student',
        sectionsById,
        schoolYearConfig
      );

      return isStudentFiltered ? 'pre-publish' : 'published';
    } else {
      return 'none';
    }
  }

  static hasImportantWorkload(content: ContentDefinitionModel): boolean {
    const workload = content.workloadLevel;

    return workload === 'medium' || workload === 'major';
  }

  static hasImportantPublishedWorkload(content: ContentDefinitionModel): boolean {
    // We still consider the user's workload when either not published or published before we added
    // the published workload property.
    const workload =
      (content.masterContent?.workload ?? 'unknown') === 'unknown'
        ? content.workloadLevel
        : content.masterContent!.workload;

    return workload === 'medium' || workload === 'major';
  }

  static shouldBeFilteredByAssessmentPlanningForRole(
    content: ContentDefinitionModel,
    role: Role,
    sectionsById: Map<StudyoObjectId, SectionModel>,
    schoolYearConfig: SchoolYearConfigurationModel
  ): boolean {
    if (role === 'individual' || role === 'teacher') {
      return false;
    }

    if (!content.isPublished) {
      return false;
    }

    if (!ContentDefinitionUtils.hasImportantPublishedWorkload(content)) {
      return false;
    }

    const section = sectionsById.get(content.sectionId);

    if (section == null) {
      return false;
    }

    const settings = schoolYearConfig.assessmentPlanningSettings;
    const setting = settings.find((s) => s.gradeLevel === section.gradeLevel);

    if (setting == null) {
      return false;
    }

    const visibilityDay = setting.examsAndImportantVisibilityDay ?? schoolYearConfig.endDay;
    return content.dueDay.isSameOrAfter(visibilityDay);
  }

  static isNoLoadAndPastDue(content: ContentDefinitionModel): boolean {
    return content.workloadLevel === 'none' && content.dueDay.isBefore(dateService.today);
  }

  static isVisibleContent(content: ContentDefinitionModel): boolean {
    return content.state === 'active' || content.state === 'completed';
  }

  static announcementDate(
    announcedToday: boolean,
    dueDay: Day,
    firstSchoolDay: SchoolDay,
    lastSchoolDay: SchoolDay
  ): Day {
    if (announcedToday) {
      const today = dateService.today;

      if (today.isAfter(dueDay)) {
        return dueDay;
      } else if (today.isBefore(firstSchoolDay.day)) {
        return firstSchoolDay.day;
      } else if (today.isAfter(lastSchoolDay.day)) {
        return lastSchoolDay.day;
      } else {
        return today;
      }
    } else {
      return dueDay;
    }
  }

  static hasLongTimeSpan(content: ContentDefinitionModel, data: AccountData): boolean {
    if (content.kind === 'note') {
      return false;
    }

    const schoolDays = data.schoolDays.filter(
      (sd) => sd.cycleDay !== 0 && sd.day.isAfter(content.assignmentDay) && sd.day.isBefore(content.dueDay)
    );

    if (schoolDays.length < 10) {
      return false;
    }

    if (content.sectionId.length > 0) {
      const dueSchoolDay = data.schoolDaysByDay.get(content.dueDay.asString);
      const duePeriod =
        content.duePeriodTag.length > 0 ? dueSchoolDay?.periods.find((p) => p.tag === content.duePeriodTag) : undefined;

      const allOccurrences = data.getOccurrencesForSectionId(content.sectionId);

      const occurrences = allOccurrences.filter((occ) => {
        if (occ.day.isSameOrBefore(content.assignmentDay) || occ.day.isAfter(content.dueDay)) {
          return false;
        }

        if (!occ.day.isSame(content.dueDay)) {
          return true;
        }

        if (duePeriod != null) {
          return occ.period.startTime.isBefore(duePeriod.startTime);
        } else {
          return false;
        }
      });

      return occurrences.length > 3;
    } else {
      return true;
    }
  }

  static getDefaultWorkloadLevelForNewContentIcon(icon: ContentIcon): ContentWorkloadLevel | undefined {
    const contentIconsNoLoadDefault: ContentIcon[] = ['reminder', 'tutoring', 'recuperation'];
    const contentIconsMediumDefault: ContentIcon[] = ['exam'];

    if (contentIconsMediumDefault.includes(icon)) {
      return 'medium';
    } else if (contentIconsNoLoadDefault.includes(icon)) {
      return 'none';
    }

    return undefined;
  }

  static stringDescription(content: ContentDefinitionModel): string {
    return `
      Title: ${content.title}
      DueDay: ${content.dueDay.asDateString} at period ${content.duePeriodTag}
      Icon: ${content.icon}
      Workload: ${content.workloadLevel}
      SectionId: ${content.sectionId}
      Master? ${content.isMaster} Slave? ${content.isSlave}
    `;
  }

  private static getTimelineStateForDay(day: Day) {
    if (DateUtils.isToday(day)) {
      return 'today';
    } else if (DateUtils.isLate(day)) {
      return 'late';
    }

    return 'active';
  }
}
