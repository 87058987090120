import { Checkbox, FormControl, FormControlLabel, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface ManageBacUnmappedAssignmentKindsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  isIgnoringUnmappedKinds: boolean;
  onChange: (isIgnoringUnmappedKinds: boolean) => void;
}

export const ManageBacUnmappedAssignmentKinds = observer((props: ManageBacUnmappedAssignmentKindsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, isIgnoringUnmappedKinds, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <FormControl sx={sx} className={className} style={style}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isIgnoringUnmappedKinds}
            onChange={(event) => onChange(event.target.checked)}
          />
        }
        label={strings.manageBacIgnoringUnmappedKindsLabel}
      />
      <Typography variant="caption">
        {isIgnoringUnmappedKinds
          ? strings.manageBacIgnoringUnmappedKindsNotice
          : strings.manageBacNotIgnoringUnmappedKindsNotice}
      </Typography>
    </FormControl>
  );
});
