import { PublishedTasksDetailSectionInfo, PublishedTasksDetailTaskInfo } from './PublishedTasksDetailViewModelBase';

export interface PublishedTaskDetailViewModel {
  sectionInfo: PublishedTasksDetailSectionInfo;
  taskInfo: PublishedTasksDetailTaskInfo;

  close: () => void;
}

export class AppPublishedTaskDetailViewModel implements PublishedTaskDetailViewModel {
  constructor(
    public readonly sectionInfo: PublishedTasksDetailSectionInfo,
    public readonly taskInfo: PublishedTasksDetailTaskInfo,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {}

  close() {
    this._onSuccess();
  }
}
