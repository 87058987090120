import { AlertService } from '@insights/services';
import {
  EditableAssessmentPlanningSettings,
  EditableSchoolYearConfiguration,
  SchoolYearConfigurationModel
} from '@shared/models/config';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { chain } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { computedFn } from 'mobx-utils';
import { AppEditViewModelBase, EditViewModelBase } from '../../EditViewModelBase';

export type SchoolYearConfigurationAssessmentPlanningFields = 'defaultVisibilityDay';

export interface EditSchoolYearConfigurationAssessmentPlanningDialogViewModel
  extends EditViewModelBase<SchoolYearConfigurationAssessmentPlanningFields> {
  readonly gradeLevels: string[];
  readonly defaultDay: Day;
  readonly minimumDay: Day;
  readonly maximumDay: Day;

  getVisibilityDate(gradeLevel: string): Day | undefined;
  setVisibilityDate(gradeLevel: string, value: Day | undefined): void;
}

export class AppEditSchoolYearConfigurationAssessmentPlanningDialogViewModel
  extends AppEditViewModelBase<SchoolYearConfigurationAssessmentPlanningFields>
  implements EditSchoolYearConfigurationAssessmentPlanningDialogViewModel
{
  private readonly _editableSchoolYearConfiguration: EditableSchoolYearConfiguration;

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _schoolYearConfiguration: SchoolYearConfigurationModel,
    alertService: AlertService,
    localizationService: LocalizationService,
    onSuccess: () => void,
    onCancel: () => void
  ) {
    super(alertService, localizationService, onSuccess, onCancel);
    makeObservable(this);
    this._editableSchoolYearConfiguration = new EditableSchoolYearConfiguration(_schoolYearConfiguration);
  }

  @computed
  get gradeLevels(): string[] {
    return chain(this._editableSchoolYearConfiguration.sections)
      .map((section) => section.gradeLevel)
      .uniq()
      .compact()
      .orderBy((gradeLevel) => gradeLevel)
      .value();
  }

  @computed
  get hasChanges(): boolean {
    return this._editableSchoolYearConfiguration.hasChanges;
  }

  @computed
  get defaultDay(): Day {
    return this._schoolYearConfiguration.endDay;
  }

  @computed
  get minimumDay(): Day {
    return this._schoolYearConfiguration.startDay;
  }

  @computed
  get maximumDay(): Day {
    return this._schoolYearConfiguration.endDay;
  }

  getVisibilityDate = computedFn((gradeLevel: string): Day | undefined => {
    const setting = this.getAssessmentSettings(gradeLevel, false);
    return setting?.examsAndImportantVisibilityDay;
  });

  setVisibilityDate(gradeLevel: string, value: Day | undefined) {
    const hasValue = Boolean(value);
    const setting = this.getAssessmentSettings(gradeLevel, hasValue);

    if (setting == null) {
      return;
    }

    if (hasValue) {
      setting.examsAndImportantVisibilityDay = value;
    } else {
      setting.examsAndImportantVisibilityDay = undefined;
      setting.markAsDeleted();
    }
  }

  getFieldValueCore(field: SchoolYearConfigurationAssessmentPlanningFields) {
    switch (field) {
      case 'defaultVisibilityDay':
        break;

      default:
        throw new Error('Unknown field');
    }
  }

  setFieldValueCore() {
    // Shouldn't be called. There aren't any editable fields
  }

  async saveCore(): Promise<void> {
    await this._schoolYearConfigurationStore.saveConfig(this._editableSchoolYearConfiguration);
  }

  protected validateFields() {
    // Nothing to validate
  }

  private getAssessmentSettings(
    gradeLevel: string,
    createIfNotFound: boolean
  ): EditableAssessmentPlanningSettings | undefined {
    let setting = this._editableSchoolYearConfiguration.allAssessmentPlanningSettings.find(
      (s) => s.gradeLevel === gradeLevel
    );

    if (setting != null) {
      setting.markAsNotDeleted();
    }

    if (setting == null && createIfNotFound) {
      setting = EditableAssessmentPlanningSettings.createNew(gradeLevel);
      this._editableSchoolYearConfiguration.addAssessmentPlanningSettings(setting);
    }

    return setting;
  }
}
