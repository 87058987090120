import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { SectionOutlineViewModel } from '@insights/viewmodels';
import { Stack, SxProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

export interface CreateSchoolSectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: SectionOutlineViewModel;
  title: string;
}

export const CreateSchoolSection = observer(({ sx = [], className, viewModel, title }: CreateSchoolSectionProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.createSchool;

  return (
    <Stack sx={sx} className={className}>
      <Typography variant="h6">{title}</Typography>
      <Stack direction="row" spacing={2}>
        <TextField
          sx={{ flex: 2 }}
          value={viewModel.title}
          label={strings.sectionTitleLabel}
          onChange={(event) => (viewModel.title = event.target.value)}
        />
        <TextField
          sx={{ flex: 1 }}
          value={viewModel.shortTitle}
          label={strings.sectionShortTitleLabel}
          onChange={(event) => (viewModel.shortTitle = event.target.value)}
          slotProps={{ input: { inputProps: { maxLength: 3 } } }}
        />
      </Stack>
      {!viewModel.canSubmit && (
        <Typography variant="caption" sx={{ color: (theme) => theme.palette.error.main }}>
          {viewModel.isOptional ? strings.incompleteSectionWarning : strings.invalidSectionWarning}
        </Typography>
      )}
    </Stack>
  );
});
