import * as SPB from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/schoology_pb';
import { Schoology } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/schoology_connect';
import { SchoologyTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class SchoologyGrpcTransport extends BaseGrpcTransport implements SchoologyTransport {
  async fetchSchoologyExternalAccountDetails(
    externalAccountId: string
  ): Promise<SPB.GetExternalAccountDetailsResponse> {
    console.log('Fetching Schoology external account...');

    const request = new SPB.GetExternalAccountDetailsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(Schoology, Schoology.methods.getExternalAccountDetails, request);

    console.log('Successfully fetched Schoology external account.');

    return response;
  }

  async createOrUpdateSchoologyExternalAccount(
    externalAccountId: string | undefined,
    configId: string,
    serverUrl: string,
    consumerKey: string,
    consumerSecret: string,
    accountName: string
  ): Promise<SPB.CreateOrUpdateExternalAccountResponse> {
    console.log('Creating or updating Schoology external account...');

    const request = new SPB.CreateOrUpdateExternalAccountRequest();
    request.externalAccountId = externalAccountId ?? '';
    request.configId = configId;
    request.serverUrl = serverUrl;
    request.consumerKey = consumerKey;
    request.consumerSecret = consumerSecret;
    request.accountName = accountName;

    const response = await this.performRequest(Schoology, Schoology.methods.createOrUpdateExternalAccount, request);

    console.log('Successfully created or updated Schoology external account.');

    return response;
  }
}
