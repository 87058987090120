import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import { EditableTransformationColumnViewModel, EditableTransformationViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Cached';
import ResetIcon from '@mui/icons-material/CancelOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import DeleteIcon from '@mui/icons-material/Delete';
import ReorderIcon from '@mui/icons-material/Reorder';
import SaveIcon from '@mui/icons-material/SaveAlt';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  Paper,
  SxProps,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { ReorderableList } from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useLayoutEffect } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { InsightsButton } from '../InsightsButton';
import { EditableIsSubstitution } from './EditableIsSubstitution';
import { EditableOperation } from './EditableOperation';
import { EditableParameters } from './EditableParameters';
import { EditableTargetSchemaField } from './EditableTargetSchemaField';

export interface ImportSessionTransformationColumnListProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  transformation: EditableTransformationViewModel;
}

const SortableTransformationColumnRow = ({ value }: { value: EditableTransformationColumnViewModel }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: value.id });

  const containerStyle = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const dragHandleStyle: CSSProperties = {
    cursor: 'move',
    touchAction: 'none'
  };

  return (
    <Grid2
      ref={setNodeRef}
      container
      spacing={1}
      style={{
        ...containerStyle,
        borderBottom: '1px solid #bbb'
      }}
      sx={{
        alignItems: 'center'
      }}
    >
      <Grid2 size={{ xs: 2 }}>
        <EditableTargetSchemaField column={value} />
      </Grid2>
      <Grid2 size={{ xs: 2 }}>
        <EditableOperation column={value} />
      </Grid2>
      <Grid2 size={{ xs: 6 }}>
        <EditableParameters column={value} />
      </Grid2>
      <Grid2 size={{ xs: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              flex: 1
            }}
          >
            <EditableIsSubstitution column={value} />
          </Box>
          <Tooltip title={value.comment}>
            <IconButton onClick={() => value.editComment()}>
              <CommentIcon color={value.hasComment ? 'secondary' : 'disabled'} />
            </IconButton>
          </Tooltip>
          <IconButton onClick={() => value.delete()}>
            <DeleteIcon />
          </IconButton>

          <ReorderIcon sx={{ ml: 1 }} {...attributes} {...listeners} style={dragHandleStyle} />
        </Box>
      </Grid2>
    </Grid2>
  );
};

interface SortableTransformationColumnListProps {
  items: EditableTransformationColumnViewModel[];
  onOrderChange: (oldIndex: number, newIndex: number) => void;
}

const SortableTransformationColumnList = ({ items, onOrderChange }: SortableTransformationColumnListProps) => (
  <Box>
    <ReorderableList
      items={items}
      renderItem={(item) => <SortableTransformationColumnRow key={`transformation-column-${item.id}`} value={item} />}
      onOrderChanged={onOrderChange}
    />
  </Box>
);

export const ImportSessionTransformationColumnList = observer((props: ImportSessionTransformationColumnListProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, transformation } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.altKey && !e.shiftKey) {
      switch (e.code) {
        case 'KeyA':
          transformation.addColumn();
          break;
        case 'KeyU':
          void transformation.updateData();
          break;
        case 'KeyS':
          void transformation.saveChanges();
          break;
      }
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const columnHeaderClassName = css([{ fontWeight: 500 }]);

  return (
    <Paper sx={sx} className={className} style={style} variant="outlined">
      <Box
        sx={{
          m: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              flex: 1
            }}
          >
            <Typography variant="h6">{`${transformation.label} - ${transformation.name}`}</Typography>
          </Box>
          <Box
            sx={{
              mx: 1,
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Tooltip title={strings.addColumnTooltip}>
              {/* Avoid disabled immediate child of tooltip */}
              <Box>
                <IconButton disabled={transformation.isExecuting} onClick={() => transformation.addColumn()}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip title={strings.updateDataTooltip}>
              {/* Avoid disabled immediate child of tooltip */}
              <Box>
                <IconButton
                  disabled={transformation.isExecuting || !transformation.hasChangesSinceLastUpdate}
                  onClick={() => void transformation.updateData()}
                >
                  <UpdateIcon />
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip title={strings.resetChangesTooltip}>
              {/* Avoid disabled immediate child of tooltip */}
              <Box>
                <IconButton
                  disabled={transformation.isExecuting || !transformation.hasChanges}
                  onClick={() => transformation.resetChanges()}
                >
                  <ResetIcon />
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip title={strings.saveChangesTooltip}>
              {/* Avoid disabled immediate child of tooltip */}
              <Box>
                <IconButton
                  disabled={transformation.isExecuting || !transformation.hasChanges}
                  onClick={() => void transformation.saveChanges()}
                >
                  <SaveIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Grid2 container spacing={1}>
          <Grid2 size={{ xs: 2 }}>
            <Typography variant="body2" className={columnHeaderClassName}>
              {strings.targetSchema}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 2 }}>
            <Typography variant="body2" className={columnHeaderClassName}>
              {strings.operation}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <Typography variant="body2" className={columnHeaderClassName}>
              {strings.parameters}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 2 }}>
            <Typography variant="body2" className={columnHeaderClassName}>
              {strings.isSubstitution}
            </Typography>
          </Grid2>
        </Grid2>
        <SortableTransformationColumnList
          items={transformation.editableColumns}
          onOrderChange={(oldIndex, newIndex) => transformation.moveColumn(oldIndex, newIndex)}
        />
      </Box>
      <Dialog
        open={transformation.editingCommentColumn != null}
        fullWidth
        maxWidth="sm"
        onAbort={() => transformation.cancelComment()}
        onClose={() => transformation.cancelComment()}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <DialogTitle>{strings.editCommentTitle}</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              autoFocus
              onSubmit={() => transformation.applyComment()}
              value={transformation.editingComment}
              onChange={(e) => (transformation.editingComment = e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <InsightsButton onClick={() => transformation.cancelComment()}>{strings.cancel}</InsightsButton>
            <InsightsButton isSubmit onClick={() => transformation.applyComment()}>
              {strings.save}
            </InsightsButton>
          </DialogActions>
        </form>
      </Dialog>
    </Paper>
  );
});
