import { StudyoInternalConnectorTransport } from '../../../transports';
import { ConnectorsStore, StudyoInternalConnectorStore } from '../../interfaces';
import { AppBaseStore } from '../AppBaseStore';

export class AppStudyoInternalConnectorStore extends AppBaseStore implements StudyoInternalConnectorStore {
  constructor(
    private readonly _transport: StudyoInternalConnectorTransport,
    connectorStore: ConnectorsStore
  ) {
    super('AppStudyoInternalConnectorStore', false, connectorStore);
  }

  async createOrUpdateStudyoInternalAccount(
    configId: string,
    externalAccountId: string,
    accountName: string
  ): Promise<string> {
    const response = await this._transport.createOrUpdateExternalAccount(externalAccountId, configId, accountName);
    this.invalidate();

    return response.externalAccountId;
  }
}
