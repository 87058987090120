import { IconImages } from '@shared/resources/images/studyo';
import activityIcon from '@shared/web/images/assets/studyo/task_icon_activity.svg?url';
import artIcon from '@shared/web/images/assets/studyo/task_icon_art.svg?url';
import danceIcon from '@shared/web/images/assets/studyo/task_icon_dance.svg?url';
import examIcon from '@shared/web/images/assets/studyo/task_icon_exam.svg?url';
import homeworkIcon from '@shared/web/images/assets/studyo/task_icon_homework.svg?url';
import labIcon from '@shared/web/images/assets/studyo/task_icon_lab.svg?url';
import libraryIcon from '@shared/web/images/assets/studyo/task_icon_library.svg?url';
import messageIcon from '@shared/web/images/assets/studyo/task_icon_message.svg?url';
import minitestIcon from '@shared/web/images/assets/studyo/task_icon_minitest.svg?url';
import movieIcon from '@shared/web/images/assets/studyo/task_icon_movie.svg?url';
import musicIcon from '@shared/web/images/assets/studyo/task_icon_music.svg?url';
import noteIcon from '@shared/web/images/assets/studyo/task_icon_note.svg?url';
import oralIcon from '@shared/web/images/assets/studyo/task_icon_oral.svg?url';
import personalIcon from '@shared/web/images/assets/studyo/task_icon_personal.svg?url';
import workPlanIcon from '@shared/web/images/assets/studyo/task_icon_plan.svg?url';
import playIcon from '@shared/web/images/assets/studyo/task_icon_play.svg?url';
import projectIcon from '@shared/web/images/assets/studyo/task_icon_project.svg?url';
import readingIcon from '@shared/web/images/assets/studyo/task_icon_reading.svg?url';
import recuperationIcon from '@shared/web/images/assets/studyo/task_icon_recuperation.svg?url';
import reminderIcon from '@shared/web/images/assets/studyo/task_icon_reminder.svg?url';
import signatureIcon from '@shared/web/images/assets/studyo/task_icon_signature.svg?url';
import sportIcon from '@shared/web/images/assets/studyo/task_icon_sport.svg?url';
import studyIcon from '@shared/web/images/assets/studyo/task_icon_study.svg?url';
import surveyIcon from '@shared/web/images/assets/studyo/task_icon_survey.svg?url';
import teamworkIcon from '@shared/web/images/assets/studyo/task_icon_teamwork.svg?url';
import tutoringIcon from '@shared/web/images/assets/studyo/task_icon_tutoring.svg?url';
import videoConferenceIcon from '@shared/web/images/assets/studyo/task_icon_video_conference.svg?url';

export class WebRegularIconImages implements IconImages {
  get activity() {
    return activityIcon;
  }

  get dance() {
    return danceIcon;
  }

  get exam() {
    return examIcon;
  }

  get homework() {
    return homeworkIcon;
  }

  get lab() {
    return labIcon;
  }

  get library() {
    return libraryIcon;
  }

  get message() {
    return messageIcon;
  }

  get minitest() {
    return minitestIcon;
  }

  get movie() {
    return movieIcon;
  }

  get music() {
    return musicIcon;
  }

  get note() {
    return noteIcon;
  }

  get oral() {
    return oralIcon;
  }

  get personal() {
    return personalIcon;
  }

  get play() {
    return playIcon;
  }

  get project() {
    return projectIcon;
  }

  get reading() {
    return readingIcon;
  }

  get recuperation() {
    return recuperationIcon;
  }

  get reminder() {
    return reminderIcon;
  }

  get signature() {
    return signatureIcon;
  }

  get sport() {
    return sportIcon;
  }

  get study() {
    return studyIcon;
  }

  get survey() {
    return surveyIcon;
  }

  get teamwork() {
    return teamworkIcon;
  }

  get tutoring() {
    return tutoringIcon;
  }

  get videoconference() {
    return videoConferenceIcon;
  }

  get art() {
    return artIcon;
  }

  get workplan() {
    return workPlanIcon;
  }
}
