import { SectionInfo } from '@insights/models';
import { cleanDiacritics, CustomFilterUtils, SectionUtils } from '@insights/utils';
import { AccountEditionViewModel } from '@insights/viewmodels';
import { Column as TableColumn } from '@material-table/core';
import AddIcon from '@mui/icons-material/Add';
import AddAutoEnrolledIcon from '@mui/icons-material/AddCircle';
import { IconButton, styled, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { SmallTablePageSize, SmallTablePageSizes } from '../../Constants';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { InsightsMaterialTable } from '../InsightsMaterialTable';
import { SectionName } from '../SectionName';
import { Container, Row } from '../layout';

const TableStateKey = 'AccountSectionPicker';

export interface AccountSectionPickerProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  account: AccountEditionViewModel;
}

export const AccountSectionPicker = observer((props: AccountSectionPickerProps) => {
  const { localizationService } = useInsightsServices();
  const tableStateKey = `${TableStateKey}-${props.account.editableAccount.role}`;
  const theme = useTheme();
  const { sx = [], className, style, account } = props;
  const strings = localizationService.localizedStrings.insights.components.accounts;

  function renderColumns(): TableColumn<SectionInfo>[] {
    const columns: TableColumn<SectionInfo>[] = [
      {
        title: strings.title,
        defaultSort: 'asc',
        customSort: (a: SectionInfo, b: SectionInfo) =>
          SectionUtils.compareTitles(a, b, localizationService.currentLocale),
        customFilterAndSearch: (filter: string, section: SectionInfo) =>
          section.section != null &&
          CustomFilterUtils.customFilterAndSearch(filter, section, (s) => getSearchableFields(s), [
            { prefix: strings.grade, isMatch: filterByGrade },
            { prefix: strings.group, isMatch: filterByGroup }
          ]),
        render: ({ section }: SectionInfo) => {
          const teacher = section != null ? account.teachersById[section.defaultTeacherId] : undefined;

          return (
            <Container sx={{ my: 0.5 }} className="item">
              <Row verticalContentAlignment="center">
                <SectionName
                  title={section != null ? SectionUtils.formatTitle(section) : strings.unknownSection}
                  color={section?.color}
                  subInformation1={section?.importId}
                  subInformation2={teacher != null ? AccountUtils.getDisplayLastFirstName(teacher) : undefined}
                />
              </Row>
            </Container>
          );
        }
      },
      {
        title: strings.gradeTitle,
        customSort: (a: SectionInfo, b: SectionInfo) =>
          SectionUtils.compareGrades(a, b, localizationService.currentLocale),
        render: ({ section }: SectionInfo) => {
          return (
            <Container sx={{ my: 0.5 }} className="item">
              <Row verticalContentAlignment="center">
                <Typography variant="body1">{section?.gradeLevel}</Typography>
              </Row>
            </Container>
          );
        }
      },
      {
        title: strings.groupTitle,
        customSort: (a: SectionInfo, b: SectionInfo) =>
          SectionUtils.compareGroups(a, b, localizationService.currentLocale),
        render: ({ section }: SectionInfo) => {
          return (
            <Container sx={{ my: 0.5 }} className="item">
              <Row verticalContentAlignment="center">
                <Typography variant="body1">{section?.sectionNumber}</Typography>
              </Row>
            </Container>
          );
        }
      },
      {
        title: strings.associationTitle,
        customSort: (a: SectionInfo, b: SectionInfo) =>
          SectionUtils.compareAssociatedSectionNumbers(a, b, localizationService.currentLocale),
        render: ({ section }: SectionInfo) => {
          return (
            <Container sx={{ my: 0.5 }} className="item">
              <Row verticalContentAlignment="center">
                <Typography variant="body1">{section?.associatedSectionNumbers.join(',')}</Typography>
              </Row>
            </Container>
          );
        }
      },
      {
        title: '',
        cellStyle: { verticalAlign: 'middle' },
        render: ({ section }: SectionInfo) => {
          const hasAutoEnrollment =
            section != null && (section.autoEnrollRoles.length > 0 || section.autoEnrollTags.length > 0);

          return (
            <Row verticalContentAlignment="center">
              <IconButton disabled={section == null} onClick={() => account.selectSection(section?.id ?? '')}>
                {hasAutoEnrollment ? (
                  <Tooltip title={strings.hasAutoEnrollment}>
                    <AddAutoEnrolledIcon fontSize="small" />
                  </Tooltip>
                ) : (
                  <AddIcon fontSize="small" />
                )}
              </IconButton>
            </Row>
          );
        }
      }
    ];

    return columns;
  }

  function getSearchableFields({ section }: SectionInfo): string[] {
    const values = section != null ? [SectionUtils.formatTitle(section), section.importId] : [];
    const teacher = section != null ? props.account.teachersById[section.defaultTeacherId] : undefined;

    if (teacher != null) {
      values.push(AccountUtils.getDisplayLastFirstName(teacher));
    }

    return values;
  }

  const filterByGrade = ({ section }: SectionInfo, grade: string): boolean => {
    // Exact matches only
    return section != null && cleanDiacritics(section.gradeLevel).toLowerCase().includes(grade);
  };

  const filterByGroup = ({ section }: SectionInfo, group: string): boolean => {
    // Exact matches only
    return section != null && cleanDiacritics(section.sectionNumber).toLowerCase().includes(group);
  };

  return (
    <Root sx={sx} className={className} style={style}>
      <InsightsMaterialTable
        stateKey={tableStateKey}
        // This is to disable the card contour
        components={{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>
        }}
        title={strings.availableSections}
        columns={renderColumns()}
        data={account.availableSections}
        options={{
          pageSize: SmallTablePageSize,
          pageSizeOptions: SmallTablePageSizes,
          rowStyle: { backgroundColor: '#fff', verticalAlign: 'top' },
          headerStyle: { fontSize: theme.typography.body2.fontSize },
          emptyRowsWhenPaging: false,
          draggable: false
        }}
        localization={localizationService.localizedStrings.insights.materialTable}
      />
    </Root>
  );
});

const Root = styled(Container)(() => ({
  '.item': {
    minHeight: 30
  }
}));
