import { NavigationService } from '@insights/services';
import { AccountModel, SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface PublishedTasksDetailInfo {
  readonly schoolYearConfiguration: SchoolYearConfigurationModel;
  readonly sections: PublishedTasksDetailSectionInfo[];
  readonly sectionsById: Record<string, SectionModel>;
}

export interface PublishedTasksDetailSectionInfo {
  readonly section: SectionModel;
  readonly teachers: AccountModel[];
  readonly tasks: PublishedTasksDetailTaskInfo[];
}

export interface PublishedTasksDetailTaskInfo {
  readonly task: ContentDefinitionModel;
  readonly publishedAt: Date;
}

export interface PublishedTasksDetailViewModelBase {
  readonly configId: string;
  readonly data: IPromiseBasedObservable<PublishedTasksDetailInfo>;

  showDetail(
    sectionInfo: PublishedTasksDetailSectionInfo,
    taskInfo: PublishedTasksDetailTaskInfo,
    schoolYearConfiguration: SchoolYearConfigurationModel,
    sectionsById: Record<string, SectionModel>
  ): Promise<void>;
  close(): void;
}

export abstract class AppPublishedTasksDetailViewModelBase implements PublishedTasksDetailViewModelBase {
  protected constructor(
    public readonly configId: string,
    protected readonly _onSuccess: () => void,
    protected readonly _onCancel: () => void,
    protected readonly _tasks: ContentDefinitionModel[],
    private readonly _navigationService: NavigationService
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<PublishedTasksDetailInfo> {
    return fromPromise(this.loadData());
  }

  async showDetail(
    sectionInfo: PublishedTasksDetailSectionInfo,
    taskInfo: PublishedTasksDetailTaskInfo,
    schoolYearConfiguration: SchoolYearConfigurationModel,
    sectionsById: Record<string, SectionModel>
  ): Promise<void> {
    await this._navigationService.navigateToPublishedTaskDetail(
      sectionInfo,
      taskInfo,
      schoolYearConfiguration,
      sectionsById
    );
  }

  close() {
    this._onSuccess();
  }

  protected abstract loadData(): Promise<PublishedTasksDetailInfo>;
}
