import { SymbolsImages } from '@shared/resources/images/studyo';
import specialDayCircleImage from '@shared/web/images/assets/studyo/special_day_circle.svg?url';
import specialDayCloseBracketImage from '@shared/web/images/assets/studyo/special_day_close_bracket.svg?url';
import specialDayHollowSquareImage from '@shared/web/images/assets/studyo/special_day_hollow_square.svg?url';
import specialDayNoneVisibleImage from '@shared/web/images/assets/studyo/special_day_none_visible.svg?url';
import specialDayOpenBracketImage from '@shared/web/images/assets/studyo/special_day_open_bracket.svg?url';
import specialDaySealImage from '@shared/web/images/assets/studyo/special_day_seal.svg?url';
import specialDaySquareImage from '@shared/web/images/assets/studyo/special_day_square.svg?url';
import specialDayStarImage from '@shared/web/images/assets/studyo/special_day_star.svg?url';
import specialDaySymetricStarImage from '@shared/web/images/assets/studyo/special_day_symetric_star.svg?url';
import specialDayUpendedStarImage from '@shared/web/images/assets/studyo/special_day_upended_star.svg?url';

export class WebSymbolsImages implements SymbolsImages {
  get circle() {
    return specialDayCircleImage;
  }

  get closeBracket() {
    return specialDayCloseBracketImage;
  }

  get hollowSquare() {
    return specialDayHollowSquareImage;
  }

  get openBracket() {
    return specialDayOpenBracketImage;
  }

  get seal() {
    return specialDaySealImage;
  }

  get square() {
    return specialDaySquareImage;
  }

  get star() {
    return specialDayStarImage;
  }

  get symetricStar() {
    return specialDaySymetricStarImage;
  }

  get upendedStar() {
    return specialDayUpendedStarImage;
  }

  get noneVisible() {
    return specialDayNoneVisibleImage;
  }
}
