import * as GC from '@buf/studyo_studyo.bufbuild_es/studyo/type_classroom_pb';
import { Memoize } from 'fast-typescript-memoize';
import { BaseModel } from '../Model';
import { classroomGradingConditionFromProtobuf } from '../types/EnumConversion';
import { GrpcClassroomContentRepresentation } from './GrpcClassroomContentRepresentation';
import { ClassroomCourseWorkMappingModel } from './interfaces';

export class GrpcClassroomCourseWorkMapping
  extends BaseModel<GC.CourseWorkMapping>
  implements ClassroomCourseWorkMappingModel
{
  constructor(pb: GC.CourseWorkMapping) {
    super(pb);
  }

  get matchingValue() {
    return this._pb.matchingValue;
  }

  get gradingCondition() {
    return classroomGradingConditionFromProtobuf(this._pb.gradingCondition);
  }

  @Memoize()
  get representation() {
    const representation = this._pb.contentRepresentation;

    return representation && new GrpcClassroomContentRepresentation(representation);
  }
}
