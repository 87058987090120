import { LocalizedText as PBLocalizedText } from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableStringProperty, EditableValuePropertyEx } from '../../editables';
import { OnboardingTextFormat } from '../../types';
import { protobufFromOnboardingTextFormat } from '../../types/EnumConversion';
import { OnboardingText } from '../interfaces';

export class EditableOnboardingText extends EditableModelEx<PBLocalizedText> implements OnboardingText {
  private _languageCode: EditableStringProperty<PBLocalizedText>;
  private _format: EditableValuePropertyEx<OnboardingTextFormat, PBLocalizedText>;
  private _value: EditableStringProperty<PBLocalizedText>;

  constructor(originalText: OnboardingText, isNew = false) {
    super(originalText.toProtobuf(), isNew);

    makeObservable(this);

    this.setFields([
      (this._languageCode = new EditableStringProperty(
        originalText.languageCode,
        (pb, value) => (pb.languageCode = value),
        { trim: true }
      )),
      (this._format = new EditableValuePropertyEx(
        originalText.format,
        (pb, value) => (pb.format = protobufFromOnboardingTextFormat(value))
      )),
      (this._value = new EditableStringProperty(originalText.value, (pb, value) => (pb.value = value)))
    ]);
  }

  @computed
  get languageCode(): string {
    return this._languageCode.value;
  }

  set languageCode(value: string) {
    this._languageCode.value = value;
  }

  @computed
  get format(): OnboardingTextFormat {
    return this._format.value;
  }

  set format(value: OnboardingTextFormat) {
    this._format.value = value;
  }

  @computed
  get value(): string {
    return this._value.value;
  }

  set value(value: string) {
    this._value.value = value;
  }
}
