import { DocumentTypesImages } from '@shared/resources/images/studyo';
import attachmentLinkImage from '@shared/web/images/assets/studyo/attachment_link.svg?url';
import documentImage from '@shared/web/images/assets/studyo/doc_icon_document.svg?url';
import excelImage from '@shared/web/images/assets/studyo/doc_icon_excel.svg?url';
import imageImage from '@shared/web/images/assets/studyo/doc_icon_image.svg?url';
import keynoteImage from '@shared/web/images/assets/studyo/doc_icon_keynote.svg?url';
import musicImage from '@shared/web/images/assets/studyo/doc_icon_music.svg?url';
import numbersImage from '@shared/web/images/assets/studyo/doc_icon_numbers.svg?url';
import pagesImage from '@shared/web/images/assets/studyo/doc_icon_pages.svg?url';
import pdfImage from '@shared/web/images/assets/studyo/doc_icon_pdf.svg?url';
import powerpointImage from '@shared/web/images/assets/studyo/doc_icon_powerpoint.svg?url';
import presentationImage from '@shared/web/images/assets/studyo/doc_icon_presentation.svg?url';
import spreadsheetImage from '@shared/web/images/assets/studyo/doc_icon_spreadsheet.svg?url';
import videoImage from '@shared/web/images/assets/studyo/doc_icon_video.svg?url';
import wordImage from '@shared/web/images/assets/studyo/doc_icon_word.svg?url';
import wordProcessingImage from '@shared/web/images/assets/studyo/doc_icon_word_processing.svg?url';
import zipImage from '@shared/web/images/assets/studyo/doc_icon_zip.svg?url';

export class WebDocumentTypesImages implements DocumentTypesImages {
  get document() {
    return documentImage;
  }

  get excel() {
    return excelImage;
  }

  get image() {
    return imageImage;
  }

  get keynote() {
    return keynoteImage;
  }

  get link() {
    return attachmentLinkImage;
  }

  get music() {
    return musicImage;
  }

  get numbers() {
    return numbersImage;
  }

  get pages() {
    return pagesImage;
  }

  get pdf() {
    return pdfImage;
  }

  get powerpoint() {
    return powerpointImage;
  }

  get presentation() {
    return presentationImage;
  }

  get spreadsheet() {
    return spreadsheetImage;
  }

  get text() {
    return wordProcessingImage;
  }

  get video() {
    return videoImage;
  }

  get word() {
    return wordImage;
  }

  get zip() {
    return zipImage;
  }
}
