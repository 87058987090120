import {
  ObservablePresenter,
  OnboardingCommentView,
  OnboardingStatusChip,
  OnboardingStepSummaryView,
  PageHeaderBar,
  RouterLink
} from '@insights/components';
import { Box, Card, CardContent, CardHeader, Grid2, Stack, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface OnboardingCommentsDashboardProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const OnboardingCommentsDashboard = observer((props: OnboardingCommentsDashboardProps) => {
  const { localizationService, reactRouterRouteService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views;

  const viewModel = useMemo(() => viewModelFactory.createOnboardingCommentsDashboard(), []);

  return (
    <Box
      className={className}
      style={style}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <PageHeaderBar />
      <Box
        sx={{
          flex: 1
        }}
      >
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingSchoolConfigMessage}
          errorMessage={strings.loadingSchoolConfigErrorMessage}
          render={(data) => (
            <Box>
              <Grid2 container>
                {data.map((p) => (
                  <Grid2
                    key={`school-${p.process.configId}-process-${p.process.templateName}`}
                    size={{ xs: 12, lg: 6 }}
                  >
                    <Card>
                      <CardContent sx={{ width: '100%' }}>
                        <Stack>
                          <RouterLink
                            underline="none"
                            to={reactRouterRouteService.resolveLocation(RouteTemplates.manageOnboardingProcess, [
                              {
                                name: RouteParamNames.configId,
                                value: p.process.configId
                              },
                              {
                                name: RouteParamNames.processName,
                                value: p.process.templateName
                              }
                            ])}
                          >
                            <CardHeader
                              sx={{ cursor: 'pointer' }}
                              title={p.schoolName}
                              action={
                                <OnboardingStatusChip
                                  size="small"
                                  disabled
                                  status={p.process.status}
                                  nextDate={p.process.nextTargetDate}
                                  targetDate={p.process.finalTargetDate}
                                />
                              }
                            />
                          </RouterLink>
                          {p.process.commentsViewModel.comments.map((c, index) => (
                            <OnboardingCommentView key={`comment-${index}`} viewModel={c} />
                          ))}
                          {p.steps.map((s) => (
                            <Box key={`step-${s.stepSummary.templateName}`}>
                              <OnboardingStepSummaryView step={s.stepSummary} />
                              {s.comments.comments.map((c, index) => (
                                <OnboardingCommentView key={`step-comment-${index}`} viewModel={c} />
                              ))}
                            </Box>
                          ))}
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid2>
                ))}
              </Grid2>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
