import { ContentService, NavigationService } from '@insights/services';
import { SchoolDay } from '@shared/models/calendar';
import { AccountModel, SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { ContentStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import _, { chain, groupBy, orderBy, sortBy, uniq } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface WorkloadManagerInfo {
  readonly conflicts: WorkloadManagerConflictInfo[];
  readonly schoolYearConfiguration: SchoolYearConfigurationModel;
  readonly sectionsById: Record<string, SectionModel>;
}

export interface WorkloadManagerConflictInfo {
  readonly conflictId: string;
  readonly students: AccountModel[];
  readonly tasks: WorkloadManagerTaskInfo[];
}

export interface WorkloadManagerTaskInfo {
  readonly task: ContentDefinitionModel;
  readonly section: SectionModel;
  readonly teachers: AccountModel[];
  readonly publishedAt: Date;
}

export interface WorkloadManagerDetailViewModelBase<TData> {
  readonly configId: string;
  readonly fromDay: SchoolDay;
  readonly toDay: SchoolDay;
  readonly studentIdsAtThreshold: string[];
  readonly studentIdsOverThreshold: string[];
  readonly includeExamOnly: boolean;

  readonly data: IPromiseBasedObservable<TData>;

  close(): void;
}

export abstract class AppWorkloadManagerDetailViewModelBase<TData>
  implements WorkloadManagerDetailViewModelBase<TData>
{
  protected constructor(
    protected readonly _contentStore: ContentStore,
    protected readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    protected readonly _navigationService: NavigationService,
    protected readonly _contentService: ContentService,
    protected readonly _onSuccess: () => void,
    protected readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly fromDay: SchoolDay,
    public readonly toDay: SchoolDay,
    public readonly includeExamOnly: boolean,
    public readonly studentIdsAtThreshold: string[],
    public readonly studentIdsOverThreshold: string[]
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<TData> {
    return fromPromise(this.loadData());
  }

  close() {
    this._onSuccess();
  }

  protected abstract loadData(): Promise<TData>;

  protected async computeConflicts(studentIds: string[]): Promise<WorkloadManagerInfo | undefined> {
    if (studentIds.length === 0) {
      return undefined;
    }

    const [schoolYearConfiguration, sectionsById, slaveTasks] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this.configId),
      this._schoolYearConfigurationStore.getSectionsById(this.configId),
      this._contentService.getImportantSlaveTasks(
        this.configId,
        studentIds,
        this.fromDay.day,
        this.toDay.day,
        this.includeExamOnly
      )
    ]);

    // Create a map of all the student Ids matching the same set of task. Each entry
    // defines a conflict. We will use this list to build the final output.
    const studentIdsByCombinedMasterContentIds = new Map<string, Set<string>>();
    const tasksByStudentId = groupBy(slaveTasks, (t) => t.ownerId);

    Object.entries(tasksByStudentId).forEach(([ownerId, ownerTasks]) => {
      const combinedMasterContentIds = sortBy(
        ownerTasks.map((task) => task.masterContent!.id),
        (id) => id
      ).join('|');

      const ownerIds = studentIdsByCombinedMasterContentIds.get(combinedMasterContentIds) ?? new Set<string>();
      ownerIds.add(ownerId);

      studentIdsByCombinedMasterContentIds.set(combinedMasterContentIds, ownerIds);
    });

    // Get the list of section Ids of all the replica tasks (student tasks).
    // This is to load the section in one operation.
    const sectionIds = uniq(slaveTasks.map((task) => task.sectionId));

    // Get the list of task Ids (master content) of all the replica tasks (student tasks).
    // This is to load all the master tasks in one operation.
    const masterContentIds = uniq(slaveTasks.map((task) => task.masterContent!.id));

    // Load the students, sections and master tasks
    const [students, sections, masterContents] = await Promise.all([
      this._schoolYearConfigurationStore.getAccountsForIds(this.configId, studentIds, false),
      this._schoolYearConfigurationStore.getSectionsByIds(this.configId, sectionIds),
      this._contentStore.getContentsForIds(masterContentIds)
    ]);

    // Get the list of all the default teachers of all the sections.
    // This is to load them all in one operation.
    const teacherIds = chain(sections)
      .flatMap((section) => section.teacherIds)
      .value();
    const teachers = await this._schoolYearConfigurationStore.getAccountsForIds(this.configId, teacherIds, false);

    // Create maps of all the data to search more efficently
    const studentsById = new Map(students.map((s) => [s.id, s]));
    const sectionsFromSlaveTasksById = new Map(sections.map((s) => [s.id, s]));
    const masterContentsById = new Map(masterContents.map((c) => [c.id, c]));
    const teachersById = new Map(teachers.map((t) => [t.id, t]));

    // Loop through all the conflicts and generate the final output sorted by
    // published at ascending.
    const conflicts = [...studentIdsByCombinedMasterContentIds.entries()].map<WorkloadManagerConflictInfo>(
      ([combinedMasterContentIds, ownerIds]) => {
        return {
          conflictId: combinedMasterContentIds,
          students: [...ownerIds].map((ownerId) => studentsById.get(ownerId)!),
          tasks: orderBy(
            _.compact(
              combinedMasterContentIds.split('|').map<WorkloadManagerTaskInfo | undefined>((masterContentId) => {
                const masterContent = masterContentsById.get(masterContentId);

                if (masterContent == null) {
                  // This is an unlikely situation, but which still happened once, where the master
                  // task managed to become private, becoming invisible here.
                  return undefined;
                }

                let section = sectionsFromSlaveTasksById.get(masterContent.sectionId);

                if (section == null) {
                  // Another unlikely situation: A master content exists for a section not found
                  // in any replica task.
                  console.error(
                    `Master content ${masterContent.id} has section ${masterContent.sectionId}, which was not found in any replica task.`
                  );

                  // We get that section from the full list.
                  section = sectionsById[masterContent.sectionId];

                  if (section == null) {
                    console.error('This section is not found either in all sections, skipping this master content.');
                    return undefined;
                  }
                }

                return {
                  task: masterContent,
                  section: section,
                  // If there is no published at, fallback to the assignment day.
                  // NOTE: This is only useful for now since the publishedAt is a new
                  //       field, and we do not have data for it now. New tasks will
                  //       have this field set.
                  publishedAt: masterContent.publishTarget?.publishedAt ?? masterContent.assignmentDay.asDate,
                  teachers: chain(section.teacherIds)
                    .map((id) => teachersById.get(id))
                    .compact()
                    .value()
                };
              })
            ),
            [(t) => t.publishedAt],
            ['asc']
          )
        };
      }
    );

    return {
      schoolYearConfiguration,
      sectionsById,
      conflicts
    };
  }
}
