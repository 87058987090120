import { EditableDayCustomizationDialog } from '@insights/components';
import {
  CustomizableViewModel,
  ExternalAssociationListFilters,
  PublishedTasksDetailSectionInfo,
  PublishedTasksDetailTaskInfo,
  SelectedOnboardingOwnership,
  StudentsFilters,
  TeacherDashboardFilters,
  TeacherDetailsFilters,
  WorkloadEmbeddedFilters,
  WorkloadManagerTaskInfo
} from '@insights/viewmodels';
import * as Views from '@insights/views';
import { SchoolDay } from '@shared/models/calendar';
import { AccountModel, SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { ExternalAccount } from '@shared/models/connectors';
import { ContentDefinitionModel } from '@shared/models/content';
import {
  AutomatedImport,
  AutomatedImportResult,
  EditableAutomatedTransformationImport,
  EditableExternalFileSource,
  ExternalFileSource,
  ImportData,
  ImportSession,
  Schema,
  SchemaImportOption,
  SourceData
} from '@shared/models/import';
import { OnboardingProcess, OnboardingQuestion, OnboardingStep } from '@shared/models/onboarding/interfaces';
import { OnboardingParticipantKind, Role, SubscriptionEntitlement } from '@shared/models/types';
import { DialogCancelled, ModalService } from '@shared/services';
import { ReactRouterRouteService } from '@shared/web/services';
import { Location, NavigateFunction } from 'react-router-dom';
import { RouteParamNames, RouteTemplates } from '../Routes';
import { UrlUtils } from '../utils';
import { InsightsEnvironmentService } from './InsightsEnvironmentService';
import { NavigationService } from './NavigationService';

export class WebNavigationService implements NavigationService {
  constructor(
    private readonly reactRouterRouteServiceResolver: () => ReactRouterRouteService,
    private readonly modalServiceResolver: () => ModalService,
    private readonly _environmentService: InsightsEnvironmentService
  ) {}

  navigateToDashboard(configId: string, navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.dashboard, [
      { name: RouteParamNames.configId, value: configId }
    ]);

    navigate(location);
    return Promise.resolve();
  }

  navigateToLogin(navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.login);

    navigate(location);
    return Promise.resolve();
  }

  navigateToNewSchool(navigate: NavigateFunction, sourceConfigId: string | undefined): Promise<void> {
    const location =
      (sourceConfigId?.length ?? 0) === 0
        ? this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.new)
        : this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.newFrom, [
            { name: RouteParamNames.sourceConfigId, value: sourceConfigId! }
          ]);

    navigate(location);
    return Promise.resolve();
  }

  navigateToSchoolConfigurations(navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.schoolConfigurations);

    navigate(location);
    return Promise.resolve();
  }

  navigateToOnboardingDashboard(navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.onboardingDashboard);

    navigate(location);
    return Promise.resolve();
  }

  navigateToUsers(navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.users);

    navigate(location);
    return Promise.resolve();
  }

  navigateToSettings(navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.settings);

    navigate(location);
    return Promise.resolve();
  }

  navigateToManageSchool(configId: string, navigate: NavigateFunction): Promise<void> {
    // This navigation is generic, and its final destination can change.
    // For now, we go to the calendar, but it could be changed to sections.
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.manageRoot, [
      { name: RouteParamNames.configId, value: configId }
    ]);

    navigate(location);
    return Promise.resolve();
  }

  navigateToOnboardingProcess(configId: string, processName: string, navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.manageOnboardingProcess, [
      { name: RouteParamNames.configId, value: configId },
      { name: RouteParamNames.processName, value: processName }
    ]);

    navigate(location);
    return Promise.resolve();
  }

  navigateToOnboardingStep(
    configId: string,
    processName: string,
    stepName: string,
    navigate: NavigateFunction
  ): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.manageOnboardingStep, [
      { name: RouteParamNames.configId, value: configId },
      { name: RouteParamNames.processName, value: processName },
      { name: RouteParamNames.templateName, value: stepName }
    ]);

    navigate(location);
    return Promise.resolve();
  }

  navigateToOnboardingProcessEdition(process: OnboardingProcess): Promise<OnboardingProcess | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.OnboardingProcessEditionDialog, { process });
  }

  navigateToAddOnboardingProcess(configId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.AddOnboardingProcessDialog, { configId });
  }

  navigateToOnboardingStepEdition(
    step: OnboardingStep,
    processName: string
  ): Promise<OnboardingStep | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.OnboardingStepEditionDialog, { step, processName });
  }

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  navigateToAddOnboardingStep(configId: string): Promise<string | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.AddOnboardingStepDialog, { configId });
  }

  navigateToOnboardingQuestionEdition(
    question: OnboardingQuestion,
    processName: string,
    stepName: string
  ): Promise<OnboardingStep | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.OnboardingQuestionEditionDialog, {
      question,
      processName,
      stepName
    });
  }

  navigateToAddOnboardingQuestion(configId: string, stepName: string): Promise<OnboardingStep | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.AddOnboardingQuestionDialog, { configId, stepName });
  }

  navigateToSelectOwnership(
    configId: string,
    processName: string,
    currentClientId: string | undefined,
    currentAgentId: string | undefined,
    currentFollowerIds: string[],
    participantKind: OnboardingParticipantKind,
    canApplyToSteps = false
  ): Promise<SelectedOnboardingOwnership | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.SelectOnboardingOwnershipDialog, {
      configId,
      processName,
      currentClientId,
      currentAgentId,
      currentFollowerIds,
      participantKind,
      canApplyToSteps
    });
  }

  navigateToRenameOrCopyOnboardingProcess(
    currentName: string,
    configId: string,
    isCopy: boolean
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  ): Promise<string | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.RenameOrCopyOnboardingTemplateDialog, {
      templateKind: 'process',
      currentName,
      configId,
      isCopy
    });
  }

  navigateToRenameOrCopyOnboardingStep(
    currentName: string,
    configId: string,
    isCopy: boolean
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  ): Promise<string | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.RenameOrCopyOnboardingTemplateDialog, {
      templateKind: 'step',
      currentName,
      configId,
      isCopy
    });
  }

  navigateToRenameOrCopyOnboardingQuestion(
    currentName: string,
    configId: string,
    stepId: string,
    isCopy: boolean
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  ): Promise<string | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.RenameOrCopyOnboardingTemplateDialog, {
      templateKind: 'question',
      currentName,
      configId,
      stepId,
      isCopy
    });
  }

  navigateToOnboardingHistory(configId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.OnboardingHistoryDialog, { configId });
  }

  navigateToEditSchoolYearConfigurationAssessmentPlanning(
    schoolYearConfiguration: SchoolYearConfigurationModel
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.EditSchoolYearConfigurationAssessmentPlanningDialog, {
      schoolYearConfiguration
    });
  }

  navigateToEditSchoolYearConfigurationDates(
    schoolYearConfiguration: SchoolYearConfigurationModel
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.EditSchoolYearConfigurationDatesDialog, {
      schoolYearConfiguration
    });
  }

  navigateToEditSchoolYearConfigurationInformation(
    schoolYearConfiguration: SchoolYearConfigurationModel
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.EditSchoolYearConfigurationInformationDialog, {
      schoolYearConfiguration
    });
  }

  navigateToEditSchoolYearConfigurationLinks(
    schoolYearConfiguration: SchoolYearConfigurationModel
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.EditSchoolYearConfigurationLinksDialog, {
      schoolYearConfiguration
    });
  }

  navigateToEditSchoolYearConfigurationThresholds(
    schoolYearConfiguration: SchoolYearConfigurationModel,
    sectionsGradeLevels: string[],
    studentsGradeLevels: string[]
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.EditSchoolYearConfigurationThresholdsDialog, {
      schoolYearConfiguration,
      sectionsGradeLevels,
      studentsGradeLevels
    });
  }

  navigateToEditFeatures(schoolYearConfiguration: SchoolYearConfigurationModel): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.EditFeaturesDialog, {
      schoolYearConfiguration
    });
  }

  navigateToAddEntitlement(
    configId: string,
    availableEntitlements: SubscriptionEntitlement[]
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.AddEntitlementDialog, {
      configId,
      availableEntitlements
    });
  }

  navigateToCreateDemoCopy(schoolYearConfiguration: SchoolYearConfigurationModel): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.SchoolYearConfigurationDemoCopyDialog, {
      schoolYearConfiguration
    });
  }

  navigateToSessionsPerDay(
    configId: string,
    accountId: string,
    accountType: 'student' | 'teacher'
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.SessionsPerDayDialog, {
      configId,
      accountId,
      accountType
    });
  }

  navigateToStudentPlannerStatus(configId: string, studentId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.StudentDailyPlannerStatusDialog, { configId, studentId });
  }

  navigateToPublishedTasksByGradeDetail(
    configId: string,
    gradeLevel: string,
    gradeLevelSectionCount: number,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    tasks: ContentDefinitionModel[]
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.PublishedTasksByGradeDetail, {
      configId,
      gradeLevel,
      gradeLevelSectionCount,
      fromDay,
      toDay,
      tasks
    });
  }

  navigateToPublishedTasksBySectionDetail(
    configId: string,
    section: SectionModel,
    sectionStudentCount: number,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    tasks: ContentDefinitionModel[]
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.PublishedTasksBySectionDetail, {
      configId,
      section,
      sectionStudentCount,
      fromDay,
      toDay,
      tasks
    });
  }

  navigateToPublishedTaskDetail(
    sectionInfo: PublishedTasksDetailSectionInfo,
    taskInfo: PublishedTasksDetailTaskInfo,
    schoolYearConfiguration: SchoolYearConfigurationModel,
    sectionsById: Record<string, SectionModel>
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.PublishedTaskDetailDialog, {
      sectionInfo,
      taskInfo,
      schoolYearConfig: schoolYearConfiguration,
      sectionsById
    });
  }

  navigateToSectionCourseOccurrenceDetail(
    configId: string,
    sectionId: string,
    schoolDay: SchoolDay
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.SectionCourseOccurrenceDetail, {
      configId,
      sectionId,
      schoolDay
    });
  }

  navigateToSectionDetails(configId: string, sectionId: string, navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.sectionDetails, [
      { name: RouteParamNames.configId, value: configId },
      { name: RouteParamNames.sectionId, value: sectionId }
    ]);

    navigate(location);
    return Promise.resolve();
  }

  navigateToSectionCreation(configId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.SectionEditionDialog, {
      configId,
      sectionIds: []
    });
  }

  navigateToSectionEdition(configId: string, sectionIds: string[]): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.SectionEditionDialog, {
      configId,
      sectionIds
    });
  }

  navigateToSectionStudentsEdition(configId: string, sectionId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.SectionStudentsEditionDialog, { configId, sectionId });
  }

  navigateToSectionStudentsEditionSectionSelection(
    configId: string,
    sectionId: string
  ): Promise<string[] | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.SectionStudentsEditionSectionSelectionDialog, {
      configId,
      sectionId
    });
  }

  navigateToSectionStudentsEditionIdsSelection(): Promise<string[] | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.SectionStudentsEditionIdsSelectionDialog, {});
  }

  navigateToSectionTeachersEdition(configId: string, sectionId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.SectionTeachersEditionDialog, { configId, sectionId });
  }

  navigateToAccountCreation(configId: string, roles: Role[]): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.AccountEditionDialog, {
      configId,
      accountId: '',
      roles
    });
  }

  navigateToAccountEdition(configId: string, accountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.AccountEditionDialog, {
      configId,
      accountId,
      roles: []
    });
  }

  navigateToAccountSectionsEdition(configId: string, accountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.AccountSectionsEditionDialog, { configId, accountId });
  }

  navigateToParentChildrenEdition(configId: string, parentId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ParentChildrenEditionDialog, { configId, parentId });
  }

  navigateToAccountSectionsEditionCopy(configId: string, accountId: string): Promise<string[] | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.AccountSectionsEditionCopyDialog, { configId, accountId });
  }

  navigateToTeacherPlanning(configId: string, accountId: string): Promise<string[] | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.TeacherPlanningDialog, {
      configId,
      accountId
    });
  }

  navigateToSectionSchedulesEdition(configId: string, sectionId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.SectionSchedulesEditionDialog, { configId, sectionId });
  }

  navigateToManageSectionConflicts(configId: string, sectionId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ManageSectionConflictsDialog, { configId, sectionId });
  }

  navigateToStudentDetails(configId: string, studentId: string, navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.studentDetails, [
      { name: RouteParamNames.configId, value: configId },
      { name: RouteParamNames.studentId, value: studentId }
    ]);

    navigate(location);
    return Promise.resolve();
  }

  navigateToStudentsFilters(initialFilters?: StudentsFilters): Promise<StudentsFilters | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.StudentsFilterDialog, {
      initialFilters
    });
  }

  navigateToTeacherDetails(configId: string, teacherId: string, navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.teacherDetails, [
      { name: RouteParamNames.configId, value: configId },
      { name: RouteParamNames.teacherId, value: teacherId }
    ]);

    navigate(location);
    return Promise.resolve();
  }

  navigateToTeacherDetailsFilters(
    configId: string,
    teacherId: string,
    initialFilters?: TeacherDetailsFilters
  ): Promise<TeacherDetailsFilters | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.TeacherDetailsFilterDialog, {
      configId,
      teacherId,
      initialFilters
    });
  }

  navigateToTeacherDashboardFilters(
    configId: string,
    teacherId: string,
    initialFilters?: TeacherDashboardFilters
  ): Promise<TeacherDashboardFilters | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.TeacherDashboardFilterDialog, {
      configId,
      teacherId,
      initialFilters
    });
  }

  navigateToWorkloadEmbeddedFilters(
    configId: string,
    teacherId: string,
    initialFilters?: WorkloadEmbeddedFilters
  ): Promise<WorkloadEmbeddedFilters | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.WorkloadEmbeddedFilterDialog, {
      configId,
      teacherId,
      initialFilters
    });
  }

  navigateToWorkloadManagerByGradeDetail(
    configId: string,
    gradeLevel: string,
    gradeLevelStudentCount: number,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    includeExamOnly: boolean,
    studentIdsAtThreshold: string[],
    studentIdsOverThreshold: string[]
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.WorkloadManagerByGradeDetail, {
      configId,
      gradeLevel,
      gradeLevelStudentCount,
      fromDay: fromDay,
      toDay: toDay,
      includeExamOnly,
      studentIdsAtThreshold,
      studentIdsOverThreshold
    });
  }

  navigateToWorkloadManagerBySectionDetail(
    configId: string,
    sectionId: string,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    includeExamOnly: boolean,
    studentIdsAtThreshold: string[],
    studentIdsOverThreshold: string[]
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.WorkloadManagerBySectionDetail, {
      configId,
      sectionId,
      fromDay: fromDay,
      toDay: toDay,
      includeExamOnly,
      studentIdsAtThreshold,
      studentIdsOverThreshold
    });
  }

  navigateToWorkloadManagerStudentListDetail(students: AccountModel[]): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.WorkloadManagerStudentListDialog, { students });
  }

  navigateToWorkloadManagerTaskDetail(
    taskInfo: WorkloadManagerTaskInfo,
    schoolYearConfig: SchoolYearConfigurationModel,
    sectionsById: Record<string, SectionModel>
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.WorkloadManagerTaskDetailsDialog, {
      taskInfo,
      schoolYearConfig,
      sectionsById
    });
  }

  navigateToUserSchoolConfigurations(): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.UserSchoolConfigurationsDialog, {});
  }

  navigateToImportSession(configId: string, sessionId: string, navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.manageImportSessionDetails, [
      { name: RouteParamNames.configId, value: configId },
      { name: RouteParamNames.sessionId, value: sessionId }
    ]);

    navigate(location);
    return Promise.resolve();
  }

  navigateToImportSessionTransformation(
    configId: string,
    sessionId: string,
    label: string,
    navigate: NavigateFunction
  ): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(
      RouteTemplates.manageImportSessionTransformation,
      [
        { name: RouteParamNames.configId, value: configId },
        { name: RouteParamNames.sessionId, value: sessionId },
        { name: RouteParamNames.label, value: label }
      ]
    );

    navigate(location);
    return Promise.resolve();
  }

  navigateToImportSessionCreate(configId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ImportSessionCreateScreen, { configId });
  }

  navigateToImportSessionEdit(session: ImportSession): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ImportSessionEditDialog, { session });
  }

  navigateToImportSessionImportFromOtherSchool(configId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ImportSessionImportFromSchoolDialog, { configId });
  }

  navigateToImportSessionAddOrEditExpectedFile(
    configId: string,
    session: ImportSession,
    fileLabel?: string
  ): Promise<ImportSession | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ImportSessionAddOrEditExpectedFileDialog, {
      configId,
      session,
      fileLabel
    });
  }

  navigateToImportSessionFileEdit(
    configId: string,
    session: ImportSession,
    fileLabel: string
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ImportSessionFileEditDialog, {
      configId,
      session,
      fileLabel
    });
  }

  navigateToImportSessionFileProcessLink(
    configId: string,
    session: ImportSession,
    fileLabel: string
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ImportSessionFileProcessLinkDialog, {
      configId,
      session,
      fileLabel
    });
  }

  navigateToImportSessionFileDirectData(
    configId: string,
    session: ImportSession,
    fileLabel: string
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ImportSessionFileDirectDataDialog, {
      configId,
      session,
      fileLabel
    });
  }

  navigateToImportSessionFileManualLink(
    configId: string,
    session: ImportSession,
    fileLabel: string
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ImportSessionFileManualLinkDialog, {
      configId,
      session,
      fileLabel
    });
  }

  navigateToImportSessionFileOrderedHeaders(
    configId: string,
    session: ImportSession,
    fileLabel: string
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ImportSessionFileOrderedHeadersDialog, {
      configId,
      session,
      fileLabel
    });
  }

  navigateToImportSessionAddOrEditConcatenation(
    configId: string,
    session: ImportSession,
    concatenationLabel?: string
  ): Promise<ImportSession | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ImportSessionAddOrEditConcatenationDialog, {
      configId,
      session,
      concatenationLabel
    });
  }

  navigateToImportSessionAddOrEditTransformation(
    configId: string,
    session: ImportSession,
    transformationLabel?: string,
    schemas?: Schema[]
  ): Promise<ImportSession | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ImportSessionAddOrEditTransformationDialog, {
      configId,
      session,
      transformationLabel,
      schemas
    });
  }

  navigateToImportSessionSourceData(
    configId: string,
    sessionId: string,
    label: string,
    navigate: NavigateFunction
  ): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(
      RouteTemplates.manageImportSessionSourceData,
      [
        { name: RouteParamNames.configId, value: configId },
        { name: RouteParamNames.sessionId, value: sessionId },
        { name: RouteParamNames.label, value: label }
      ]
    );

    navigate(location);
    return Promise.resolve();
  }

  navigateToImportDataDetails(
    configId: string,
    sessionId: string,
    label: string,
    navigate: NavigateFunction
  ): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.manageImportData, [
      { name: RouteParamNames.configId, value: configId },
      { name: RouteParamNames.sessionId, value: sessionId },
      { name: RouteParamNames.label, value: label }
    ]);

    navigate(location);
    return Promise.resolve();
  }

  navigateToImportDataIncidentsSelection(
    configId: string,
    sessionId: string,
    importData: ImportData,
    data: SourceData,
    isCompleteData: boolean,
    options: SchemaImportOption[]
  ): Promise<ImportData | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ImportDataIncidentsSelectionScreen, {
      configId,
      sessionId,
      importData,
      data,
      isCompleteData,
      options
    });
  }

  navigateToAutomatedImportCreateOrEdit(
    configId: string,
    importSessionId: string,
    automatedImport?: AutomatedImport
  ): Promise<AutomatedImport | 'cancelled'> {
    return this.modalServiceResolver().showModal(Views.AutomatedImportCreateOrEditDialog, {
      configId,
      importSessionId,
      automatedImport
    });
  }

  navigateToAutomatedImportDetails(
    configId: string,
    sessionId: string,
    automatedImportId: string,
    navigate: NavigateFunction
  ): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(
      RouteTemplates.manageAutomatedImportDetails,
      [
        { name: RouteParamNames.configId, value: configId },
        { name: RouteParamNames.sessionId, value: sessionId },
        { name: RouteParamNames.automatedImportId, value: automatedImportId }
      ]
    );

    navigate(location);
    return Promise.resolve();
  }

  navigateToAutomatedTransformationImportCreateOrEdit(
    importSession: ImportSession,
    automatedTransformationImport: EditableAutomatedTransformationImport
  ): Promise<void | 'cancelled'> {
    return this.modalServiceResolver().showModal(Views.AutomatedTransformationImportDialog, {
      importSession,
      automatedTransformationImport
    });
  }

  navigateToAutomatedImportResultDetails(
    importSession: ImportSession,
    automatedImport: AutomatedImport,
    result: AutomatedImportResult
  ): Promise<void | 'cancelled'> {
    return this.modalServiceResolver().showModal(Views.AutomatedImportResultDetailsDialog, {
      importSession,
      automatedImport,
      result
    });
  }

  navigateToExternalAccounts(configId: string, navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.manageExternalAccounts, [
      { name: RouteParamNames.configId, value: configId }
    ]);

    navigate(location);
    return Promise.resolve();
  }

  navigateToBlackbaudAccount(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.BlackbaudAccountDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToBlackbaudSettings(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.BlackbaudAccountSettingsDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToBlackbaudMappings(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.BlackbaudMappingsDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToBlackbaudSkyAccount(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.BlackbaudSkyAccountDialog, { configId, externalAccountId });
  }

  navigateToBlackbaudSkyAccountSetup(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.BlackbaudSkyAccountSetupDialog, { configId, externalAccountId });
  }

  navigateToBlackbaudSkyMappings(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.BlackbaudSkyAccountMappingsDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToCalendarAccount(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.CalendarAccountDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToCanvasAccount(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.CanvasAccountDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToCanvasSetup(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.CanvasAccountSetupDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToCanvasSettings(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.CanvasAccountSettingsDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToGoogleAccount(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.GoogleAccountDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToGoogleSettings(
    configId: string,
    externalAccountId: string,
    isNewAccount: boolean
  ): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.GoogleAccountSettingsDialog, {
      configId,
      externalAccountId,
      isNewAccount
    });
  }

  navigateToSchoologyAccount(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.SchoologyAccountDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToVeracrossAccount(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.VeracrossAccountDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToVeracrossV3Account(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.VeracrossV3AccountDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToManageBacAccount(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ManageBacAccountDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToManageBacSettings(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ManageBacAccountSettingsDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToManageBacMappings(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ManageBacMappingsDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToMicrosoftTeamsAccount(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.MicrosoftTeamsAccountDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToMicrosoftTeamsSettings(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.MicrosoftTeamsAccountSettingsDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToMicrosoftTeamsMappings(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.MicrosoftTeamsMappingsDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToMoodleAccount(configId: string, externalAccountId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.MoodleAccountDialog, {
      configId,
      externalAccountId
    });
  }

  navigateToExternalAssociations(
    configId: string,
    externalAccountId: string,
    navigate: NavigateFunction
  ): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.manageExternalAssociations, [
      { name: RouteParamNames.configId, value: configId },
      { name: RouteParamNames.externalAccountId, value: externalAccountId }
    ]);

    navigate(location);
    return Promise.resolve();
  }

  navigateToExternalAssociationsFilters(
    configId: string,
    initialFilters?: ExternalAssociationListFilters
  ): Promise<ExternalAssociationListFilters | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ExternalAssociationListFiltersDialog, {
      configId,
      initialFilters
    });
  }

  navigateToChangeIntegrations(configId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ChangeIntegrationsDialog, {
      configId
    });
  }

  navigateToExternalContentMappings(configId: string): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ExternalContentMappingsDialog, {
      configId
    });
  }

  navigateToEditSchoolCalendarDayCustomization(customizable: CustomizableViewModel): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(EditableDayCustomizationDialog, {
      customizable
    });
  }

  navigateToPlannerExternal(configId: string, accountId: string): Promise<void> {
    try {
      const url = `${this._environmentService.studyoUrl}/c/${configId}/a/${accountId}/agenda/weekly`;
      window.open(url, '_blank');
    } catch (error) {
      console.error('An error occurred while trying to navigate to the planner', error);
    }

    return Promise.resolve();
  }

  navigateToStudyo(): Promise<void> {
    try {
      const url = `${this._environmentService.studyoUrl}`;
      window.open(url, '_blank');
    } catch (error) {
      console.error('An error occurred while trying to navigate to Studyo', error);
    }

    return Promise.resolve();
  }

  navigateToErrorNotificationSettings(externalAccount: ExternalAccount): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.EditErrorNotificationSettingsDialog, { externalAccount });
  }

  navigateToThrottleSettings(externalAccount: ExternalAccount): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.EditThrottleSettingsDialog, { externalAccount });
  }

  navigateToScheduledAutoMatchSettings(externalAccount: ExternalAccount): Promise<void | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ScheduledAutoMatchSettingsDialog, { externalAccount });
  }

  navigateToExternalFileSourceEdition(
    editableSource: EditableExternalFileSource
  ): Promise<ExternalFileSource | DialogCancelled> {
    return this.modalServiceResolver().showModal(Views.ExternalFileSourceEditionDialog, { editableSource });
  }

  redirectToLogin(navigate: NavigateFunction): Promise<void> {
    const location: Location = {
      pathname: this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.login),
      key: '',
      state: undefined,
      hash: '',
      search: ''
    };
    this.removeAuthenticationInformation(location);
    navigate(location, { replace: true });

    return Promise.resolve();
  }

  redirectToLanding(navigate: NavigateFunction): Promise<void> {
    const location = this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.landing);
    navigate(location, { replace: true });

    return Promise.resolve();
  }

  completeLogin(navigate: NavigateFunction, referrer?: string): Promise<void> {
    let newLocation: Location = {
      pathname: this.reactRouterRouteServiceResolver().resolveLocation(RouteTemplates.landing),
      search: '',
      hash: '',
      key: '',
      state: undefined
    };

    if (referrer != null) {
      const queryParamsIndex = referrer.indexOf('?');

      // NOTE:  We need to split the query params from the hostname to properly create a location object.
      const pathname = referrer.substring(0, queryParamsIndex > -1 ? queryParamsIndex : undefined);
      const search = queryParamsIndex > -1 ? referrer.substring(queryParamsIndex) : undefined;

      newLocation = { ...newLocation, pathname, search: search ?? '' };
    }

    this.removeAuthenticationInformation(newLocation);
    navigate(newLocation, { replace: true });
    return Promise.resolve();
  }

  async redirectToReferrerOrLanding(location: Location, navigate: NavigateFunction, referrer?: string): Promise<void> {
    const reactRouterRouteService = this.reactRouterRouteServiceResolver();

    if (referrer != null) {
      navigate(referrer, { replace: true });
    } else {
      const ref = this.extractReferrer(location);
      navigate(ref ?? reactRouterRouteService.resolveLocation(RouteTemplates.landing), { replace: true });
    }

    return Promise.resolve();
  }

  extractReferrer(location: Location): string | undefined {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    const referrer = location.state?.referrer;

    if (referrer == null) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { pathname, search } = referrer;

    if (pathname == null) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return pathname + search;
  }

  private removeAuthenticationInformation(location: Location) {
    // Ensure we remove the authentication information (contained in the `hash` property)
    location.hash = '';

    // Remove the `completion`
    location.search = UrlUtils.removeQueryParamValue(location.search ?? '', 'completion');
  }
}
