import { MaximumCycleDayTitleLength, SchoolCalendarEditCycleDaysViewModel } from '@insights/viewmodels';
import { SxProps, TextField, Typography, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column, Row } from '../layout';

export interface EditableCycleDayTitlesProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarEditCycleDaysViewModel;
}

export const EditableCycleDayTitles = observer((props: EditableCycleDayTitlesProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx = [], className, style } = props;
  const titles = viewModel.cycleDayTitles;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Root sx={sx} className={className} style={style} horizontalContentAlignment="left">
      <Typography variant="subtitle2">{strings.customLabels}</Typography>
      <Row wrap="wrap" verticalContentAlignment="top">
        {titles.map((title, index) => (
          <TextField
            key={`cycle-day-title-${index}`}
            label={`${index + 1}`}
            value={title}
            className="text"
            onChange={(event) => {
              titles[index] = event.target.value;
              viewModel.cycleDayTitles = titles;
            }}
            slotProps={{
              input: { inputProps: { maxLength: MaximumCycleDayTitleLength } },
              inputLabel: { shrink: true }
            }}
          />
        ))}
      </Row>
    </Root>
  );
});

const Root = styled(Column)(() => ({
  '.text': {
    maxWidth: 100,
    marginRight: 4,
    marginBottom: 4
  }
}));
