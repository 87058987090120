import { ExternalFileSource as PBExternalFileSource } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { EditableModelEx, EditableStringProperty, EditableValuePropertyEx } from '../../editables';
import { ExternalFileSource } from '../interfaces';
import { GrpcExternalFileSource } from './GrpcExternalFileSource';

export class EditableExternalFileSource extends EditableModelEx<PBExternalFileSource> implements ExternalFileSource {
  private readonly _baseUrl: EditableStringProperty<PBExternalFileSource>;
  private readonly _port: EditableValuePropertyEx<number, PBExternalFileSource>;
  private readonly _username: EditableStringProperty<PBExternalFileSource>;
  private readonly _password: EditableStringProperty<PBExternalFileSource>;

  static createNew() {
    const pbSource = new PBExternalFileSource();
    return new EditableExternalFileSource(new GrpcExternalFileSource(pbSource), true);
  }

  constructor(
    private readonly _originalSource: ExternalFileSource,
    isNew = false
  ) {
    super(_originalSource.toProtobuf(), isNew);

    this._baseUrl = this.addStringField(_originalSource.baseUrl, (host, value) => (host.baseUrl = value));
    this._port = this.addValueField(_originalSource.port, (host, value) => (host.port = value));
    this._username = this.addStringField(_originalSource.username, (host, value) => (host.username = value));
    this._password = this.addStringField(_originalSource.password, (host, value) => (host.password = value));
  }

  get id() {
    return this._originalSource.id;
  }

  get baseUrl() {
    return this._baseUrl.value;
  }

  set baseUrl(value: string) {
    this._baseUrl.value = value;
  }

  get port() {
    return this._port.value;
  }

  set port(value: number) {
    this._port.value = value;
  }

  get username() {
    return this._username.value;
  }

  set username(value: string) {
    this._username.value = value;
  }

  get password() {
    return this._password.value;
  }

  set password(value: string) {
    this._password.value = value;
  }
}
