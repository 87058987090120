import { SectionUtils, caseInsensitiveAccentInsensitiveCompare } from '@insights/utils';
import {
  ExternalAssociationInfo,
  ExternalAssociationListViewModel,
  ExternalAssociationViewModel
} from '@insights/viewmodels';
import { AutoMatchAssociationsDialog } from '@insights/views';
import { MTableToolbar } from '@material-table/core';
import TestIcon from '@mui/icons-material/Biotech';
import UnmatchAllIcon from '@mui/icons-material/ClearAll';
import ApplyAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import FilterIcon from '@mui/icons-material/FilterList';
import AutoMatchIcon from '@mui/icons-material/FindReplace';
import ErrorNotificationSettingsIcon from '@mui/icons-material/Notifications';
import RefreshIcon from '@mui/icons-material/Refresh';
import ThrottleIcon from '@mui/icons-material/Timer';
import ForgetIcon from '@mui/icons-material/VisibilityOff';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Badge,
  Button,
  IconButton,
  Input,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  SxProps,
  TextField,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { Day } from '@shared/models/types';
import { ConnectorComponentsStrings } from '@shared/resources/strings/insights/components/ConnectorComponentsStrings';
import { Observer, observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { InsightsMaterialTable } from '../InsightsMaterialTable';
import { MoreActionMenu } from '../MoreActionMenu';
import { SectionName } from '../SectionName';
import { Column, Expanded, LoadingContainer, Row } from '../layout';
import { DateTimePickerLocalizationProvider } from '../utils';
import { EditableExternalSection } from './EditableExternalSection';
import { EditableOwner } from './EditableOwner';
import { ExternalAssociationListItemLastUpdate } from './ExternalAssociationListItemLastUpdate.tsx';

const TableStateKey = 'ExternalAssociationList';

export interface ExternalAssociationListProps {
  sx?: SxProps;
  viewModel: ExternalAssociationListViewModel;
  className?: string;
  style?: CSSProperties;
  title?: string;
}

export const ExternalAssociationList = observer((props: ExternalAssociationListProps) => {
  const { accountService, localizationService } = useInsightsServices();
  const { className, style, title, viewModel, sx } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;
  const theme = useTheme();

  return (
    <Root
      sx={sx}
      className={className}
      style={style}
      isOpen={viewModel.hasOperation}
      isBusy={viewModel.isBusy}
      title={strings.savingChanges}
      errorMessage={viewModel.errorMessage}
      onDismissError={() => viewModel.dismissError()}
    >
      <Column>
        <DateTimePickerLocalizationProvider>
          <InsightsMaterialTable
            stateKey={TableStateKey}
            // This is to disable the card contour
            components={{
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>,
              Toolbar: (p) => (
                <Observer>
                  {() => (
                    <Row verticalContentAlignment="center">
                      <TextField
                        type="date"
                        label={strings.defaultMinDate}
                        className={'dateTextField'}
                        value={viewModel.defaultMinimumDate?.asDateString}
                        onChange={(e) => {
                          const day = Day.fromDateString(e.target.value);
                          if (day != null) {
                            viewModel.defaultMinimumDate = day;
                          }
                        }}
                        slotProps={{ input: { inputProps: { className: 'dateInput' } }, inputLabel: { shrink: true } }}
                      />
                      <TextField
                        type="date"
                        label={strings.defaultMaxDate}
                        className={'dateTextField'}
                        value={viewModel.defaultMaximumDate?.asDateString}
                        onChange={(e) => {
                          const day = Day.fromDateString(e.target.value);
                          if (day != null) {
                            viewModel.defaultMaximumDate = day;
                          }
                        }}
                        slotProps={{ input: { inputProps: { className: 'dateInput' } }, inputLabel: { shrink: true } }}
                      />

                      <Tooltip title={strings.applyToAll}>
                        <IconButton
                          disabled={viewModel.associations.length === 0}
                          onClick={() =>
                            viewModel.applyDatesToAll(viewModel.defaultMinimumDate, viewModel.defaultMaximumDate)
                          }
                          style={{ marginRight: 48 }}
                        >
                          <ApplyAllIcon />
                        </IconButton>
                      </Tooltip>
                      {(viewModel.isDataIncomplete || viewModel.hasFailedAssociations) && (
                        <Tooltip
                          title={
                            viewModel.isDataIncomplete
                              ? strings.incompleteDataTooltip
                              : strings.failedAssociationsTooltip
                          }
                        >
                          {viewModel.canContactOwnerAboutBrokenConnection ? (
                            <IconButton
                              style={{ marginLeft: 24 }}
                              onClick={() => void viewModel.contactOwnerAboutBrokenConnection()}
                            >
                              <ErrorIcon color={viewModel.isDataIncomplete ? 'error' : 'warning'} />
                            </IconButton>
                          ) : (
                            <ErrorIcon style={{ marginLeft: 24 }} />
                          )}
                        </Tooltip>
                      )}
                      <MoreActionMenu
                        badgeProps={{
                          variant: 'dot',
                          invisible: !viewModel.isSnoozed && !viewModel.hasThrottling,
                          color: 'primary'
                        }}
                      >
                        {!viewModel.isLinkOnly && (
                          <>
                            <MenuItem onClick={() => viewModel.startAutoMatch()}>
                              <ListItemIcon>
                                <AutoMatchIcon />
                              </ListItemIcon>
                              <ListItemText primary={strings.autoMatchButton} />
                            </MenuItem>
                            <MenuItem onClick={() => void viewModel.showScheduledAutoMatchSettings()}>
                              <ListItemIcon>
                                <AutoMatchIcon />
                              </ListItemIcon>
                              <ListItemText primary={strings.scheduledAutoMatchButton} />
                            </MenuItem>
                          </>
                        )}
                        <MenuItem onClick={() => viewModel.unmatchAll()} style={{ marginRight: 48 }}>
                          <ListItemIcon>
                            <UnmatchAllIcon />
                          </ListItemIcon>
                          <ListItemText primary={strings.unmatchAllButton} />
                        </MenuItem>
                        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                          <MenuItem onClick={() => void viewModel.showEditErrorNotificationSettings()}>
                            <ListItemIcon>
                              <Badge variant="dot" invisible={!viewModel.isSnoozed} color="primary">
                                <ErrorNotificationSettingsIcon />
                              </Badge>
                            </ListItemIcon>
                            <ListItemText primary={strings.errorNotificationSettingsButton} />
                          </MenuItem>
                          <MenuItem onClick={() => void viewModel.showEditThrottleSettings()}>
                            <ListItemIcon>
                              <Badge variant="dot" invisible={!viewModel.hasThrottling} color="primary">
                                <ThrottleIcon />
                              </Badge>
                            </ListItemIcon>
                            <ListItemText primary={strings.throttleSettingsButton} />
                          </MenuItem>
                          <MenuItem onClick={() => void viewModel.forgetTasks()}>
                            <ListItemIcon>
                              <ForgetIcon />
                            </ListItemIcon>
                            <ListItemText primary={strings.forgetAboutAllTasks} />
                          </MenuItem>
                        </AuthorizationRoleCondition>
                      </MoreActionMenu>
                      <Expanded>
                        <MTableToolbar {...p} />
                      </Expanded>
                      <Tooltip title={strings.filtersTooltip}>
                        <IconButton onClick={() => void viewModel.showFilters()}>
                          <Badge
                            badgeContent={viewModel.hasFilters ? 1 : 0}
                            color="primary"
                            showZero={false}
                            variant="dot"
                          >
                            <FilterIcon />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                      {viewModel.warning && (
                        <Tooltip title={viewModel.warning}>
                          <WarningIcon sx={{ ml: 1 }} color="warning" />
                        </Tooltip>
                      )}
                      <Button
                        sx={{ ml: 1 }}
                        variant="contained"
                        color="secondary"
                        disabled={!viewModel.hasChanges}
                        onClick={() => void viewModel.applyChanges()}
                      >
                        {strings.save}
                      </Button>
                      <Button
                        sx={{ ml: 1 }}
                        variant="contained"
                        color="secondary"
                        disabled={!viewModel.hasChanges}
                        onClick={() => viewModel.resetChanges()}
                      >
                        {strings.discard}
                      </Button>
                    </Row>
                  )}
                </Observer>
              )
            }}
            title={title ?? ''}
            columns={[
              {
                title: strings.section,
                defaultSort: 'asc',
                customSort: (a: ExternalAssociationInfo, b: ExternalAssociationInfo) => {
                  if (a.viewModel.section == null) {
                    return b.viewModel.section == null ? 0 : 1;
                  } else if (b.viewModel.section == null) {
                    return -1;
                  }

                  return (
                    caseInsensitiveAccentInsensitiveCompare(
                      SectionUtils.formatTitle(a.viewModel.section),
                      SectionUtils.formatTitle(b.viewModel.section),
                      localizationService.currentLocale
                    ) ||
                    caseInsensitiveAccentInsensitiveCompare(
                      a.viewModel.section.importId,
                      b.viewModel.section.importId,
                      undefined,
                      true
                    )
                  );
                },
                customFilterAndSearch: viewModel.customFilterAndSearch.bind(viewModel),
                render: (association: ExternalAssociationInfo) => (
                  <Observer>
                    {() => (
                      <>
                        {association.viewModel.section != null && (
                          <SectionName
                            title={association.viewModel.section.title}
                            color={association.viewModel.section.color}
                            subInformation1={association.viewModel.section.importId}
                            subInformation2={association.viewModel.section.sectionNumber}
                          />
                        )}
                        {association.viewModel.section == null && <Typography>{strings.none}</Typography>}
                      </>
                    )}
                  </Observer>
                )
              },
              {
                title: strings.externalSection,
                sorting: false,
                render: (association: ExternalAssociationInfo) => (
                  <Observer>
                    {() =>
                      viewModel.isLinkOnly ? (
                        <Input
                          value={association.viewModel.externalLink}
                          onChange={(event) =>
                            association.viewModel.setExternalLink(
                              event.target.value,
                              viewModel.defaultMinimumDate,
                              viewModel.defaultMaximumDate
                            )
                          }
                        />
                      ) : (
                        <EditableExternalSection
                          viewModel={association.viewModel}
                          externalSections={viewModel.externalSections}
                          onChange={(externalSectionId) =>
                            association.viewModel.setExternalSection(
                              externalSectionId,
                              viewModel.defaultMinimumDate,
                              viewModel.defaultMaximumDate
                            )
                          }
                        />
                      )
                    }
                  </Observer>
                )
              },
              {
                title: strings.owner,
                sorting: false,
                render: (association: ExternalAssociationInfo) => (
                  <Observer>
                    {() => (
                      <>
                        {association.viewModel.hasExternalSection && (
                          <EditableOwner viewModel={association.viewModel} />
                        )}
                      </>
                    )}
                  </Observer>
                )
              },
              {
                title: strings.minMaxDates,
                sorting: false,
                render: (association: ExternalAssociationInfo) => (
                  <Observer>
                    {() => (
                      <>
                        {association.viewModel.hasExternalSection && (
                          <div>
                            <TextField
                              className={'dateTextField'}
                              type="date"
                              value={association.viewModel.minimumDate?.asDateString}
                              onChange={(e) => (association.viewModel.minimumDate = Day.fromDateString(e.target.value))}
                              label=""
                              slotProps={{ input: { inputProps: { className: 'dateInput' } } }}
                            />
                            <TextField
                              className={'dateTextField'}
                              type="date"
                              value={association.viewModel.maximumDate?.asDateString}
                              onChange={(e) => (association.viewModel.maximumDate = Day.fromDateString(e.target.value))}
                              label=""
                              slotProps={{ input: { inputProps: { className: 'dateInput' } } }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </Observer>
                )
              },
              {
                title: strings.lastUpdate,
                sorting: false,
                render: (association: ExternalAssociationInfo) => (
                  <Observer>
                    {() => <ExternalAssociationListItemLastUpdate association={association.viewModel} />}
                  </Observer>
                )
              },
              {
                title: '',
                sorting: false,
                width: '1%',
                render: (association: ExternalAssociationInfo) => (
                  <Observer>
                    {() => (
                      <>
                        {renderActions(
                          association.viewModel,
                          accountService.isAllowed(['super-admin']),
                          viewModel,
                          strings
                        )}
                      </>
                    )}
                  </Observer>
                )
              }
            ]}
            data={viewModel.associations}
            options={{
              paging: true,
              pageSize: 10,
              rowStyle: { backgroundColor: '#fff', verticalAlign: 'center' },
              headerStyle: {
                fontSize: theme.typography.body2.fontSize
              },
              emptyRowsWhenPaging: false,
              actionsColumnIndex: -1,
              draggable: false,
              padding: 'dense'
            }}
            localization={localizationService.localizedStrings.insights.materialTable}
          />
          <AutoMatchAssociationsDialog viewModel={viewModel} />
        </DateTimePickerLocalizationProvider>
      </Column>
    </Root>
  );
});

function renderActions(
  association: ExternalAssociationViewModel,
  isRootAdmin: boolean,
  viewModel: ExternalAssociationListViewModel,
  strings: ConnectorComponentsStrings
) {
  if (!association.hasAssociation) {
    return undefined;
  }

  const isDisabled = !association.hasAssociation || !association.hasExternalSection || association.isSyncing;

  return (
    <Stack direction="row">
      <Tooltip title={strings.sync}>
        <IconButton disabled={isDisabled} onClick={() => void association.syncAssociation(false)}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      {association.hasAssociation && isRootAdmin && (
        <>
          <Tooltip title={strings.forgetAboutAssociationTasks}>
            <IconButton disabled={isDisabled} onClick={() => void viewModel.forgetTasks(association.associationId)}>
              <ForgetIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={strings.testSync}>
            <IconButton disabled={isDisabled} onClick={() => void association.testAssociation()}>
              <TestIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Stack>
  );
}

const Root = styled(LoadingContainer)(({ theme }) => ({
  '.dateTextField': {
    marginRight: theme.spacing(2)
  },
  '.dateInput': {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 'lighter'
  },
  '.item': {
    minHeight: 30
  }
}));
