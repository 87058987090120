import { ExternalFileSourcesViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Card, CardContent, CardHeader, Divider, IconButton, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';

export interface ExternalFileSourcesProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: ExternalFileSourcesViewModel;
}

export const ExternalFileSources = observer((props: ExternalFileSourcesProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.settings;

  return (
    <Card sx={{ ...sx, display: 'block' }} className={className} style={style}>
      <CardHeader title={strings.externalSourcesTitle} />
      <Divider />
      <CardContent>
        <Box>
          {viewModel.externalSources.map((s) => (
            <Box key={s.id} display="flex" flexDirection="row" alignItems="center">
              <Box flex={1}>
                <Typography>{s.baseUrl}</Typography>
              </Box>
              <IconButton onClick={() => void viewModel.editExternalFileSource(s)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => void viewModel.deleteExternalFileSource(s.id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}

          <Divider sx={{ mt: 1, mb: 2 }} />

          <Button variant="contained" onClick={() => void viewModel.addExternalFileSource()}>
            {strings.addExternalFileSourceButton}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
});
