import { SxProps, styled } from '@mui/material';
import clsx from 'clsx';
import { CSSProperties, ReactNode } from 'react';

export interface ExpandedProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  hideOverflow?: boolean;
}

export function Expanded(props: ExpandedProps) {
  const style = {
    flex: 1,
    ...props.style
  };

  const overflow = props.hideOverflow ? 'overflowHidden' : '';

  return (
    <Root className={clsx(overflow, props.className)} style={style}>
      {props.children}
    </Root>
  );
}

/**
 * Expands the child on the main axis of the parent flex container. The `flex` property
 * allows controlling the flex factor. The default value of the `flex` is 1. This component
 * only takes 1 child and it will be forced to take all of the available space.
 */
const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: 0,
  '& >:first-of-type': {
    flex: 1
  },

  '&.overflowHidden': {
    overflow: 'hidden'
  }
}));
