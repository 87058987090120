import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { AccountOptionsViewModel } from '@insights/viewmodels';
import { Card, CardContent, Checkbox, FormControlLabel, Stack, SxProps, Typography } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CreateSchoolAccount } from './CreateSchoolAccount';

export interface CreateSchoolAccountsProps {
  sx?: SxProps;
  className?: string;
  viewModel: AccountOptionsViewModel;
}

export const CreateSchoolAccounts = observer(({ sx = [], className, viewModel }: CreateSchoolAccountsProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.createSchool;

  return (
    <Card sx={sx} className={className}>
      <CardContent sx={{ width: '100%' }}>
        <Stack spacing={2}>
          <Typography variant="h5">{strings.accountsSection}</Typography>
          {viewModel.canKeepAccounts && (
            <>
              <Typography variant="h6">{strings.adminAccountsSubSection}</Typography>
              {viewModel.schoolAdminAccounts.length === 0 ? (
                <Typography variant="body2">{strings.noAdminAccounts}</Typography>
              ) : (
                <Stack spacing="0px">
                  {viewModel.schoolAdminAccounts.length > 1 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={viewModel.keepAllSchoolAdminAccounts}
                          onChange={(e) => (viewModel.keepAllSchoolAdminAccounts = e.target.checked)}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontStyle: 'italic',
                            color: (theme) => theme.palette.text.secondary
                          }}
                        >
                          {strings.keepAllAdminAccountsLabel}
                        </Typography>
                      }
                    />
                  )}
                  {viewModel.schoolAdminAccounts.map((a) => (
                    <FormControlLabel
                      key={`admin-account-${a.id}`}
                      control={
                        <Checkbox
                          checked={viewModel.keptSchoolAdminAccountIds.includes(a.id)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              viewModel.keptSchoolAdminAccountIds = _.union(viewModel.keptSchoolAdminAccountIds, [
                                a.id
                              ]);
                            } else {
                              viewModel.keptSchoolAdminAccountIds = viewModel.keptSchoolAdminAccountIds.filter(
                                (id) => id != a.id
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            alignItems: 'center'
                          }}
                        >
                          <Typography>{AccountUtils.getDisplayLastFirstName(a)}</Typography>
                          <Typography
                            sx={{ color: (theme) => theme.palette.text.secondary }}
                          >{` (${a.email})`}</Typography>
                        </Stack>
                      }
                    />
                  ))}
                </Stack>
              )}
              <Typography variant="h6">{strings.teacherAccountsSubSection}</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={viewModel.shouldKeepTeacherAccounts}
                    onChange={(e) => (viewModel.shouldKeepTeacherAccounts = e.target.checked)}
                  />
                }
                label={strings.keepTeacherAccountsLabel(viewModel.teacherAccounts.length)}
              />
            </>
          )}
          {viewModel.shouldCreateNewAdminAccount && (
            <CreateSchoolAccount title={strings.schoolAdminSection} viewModel={viewModel.newSchoolAdminAccount} />
          )}
          <CreateSchoolAccount title={strings.studyoStaffSubSection} viewModel={viewModel.staffAdminAccount} />
          <CreateSchoolAccount title={strings.studyoBotSubSection} viewModel={viewModel.studyoBotAccount} />
        </Stack>
      </CardContent>
    </Card>
  );
});
