import { Box, Button, SxProps } from '@mui/material';
import { EditableOnboardingQuestion } from '@shared/models/onboarding/implementations';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { OnboardingChoiceEdition } from './OnboardingChoiceEdition';

export interface OnboardingChoicesEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  editableQuestion: EditableOnboardingQuestion;
}

export const OnboardingChoicesEdition = observer((props: OnboardingChoicesEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], editableQuestion } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <Box sx={sx} className={className} style={style}>
      {editableQuestion.editableChoices.map((choice, index) => (
        <OnboardingChoiceEdition key={`onboarding-choice-${index}`} choice={choice} sx={{ mb: 2 }} />
      ))}
      <Button variant="contained" onClick={() => editableQuestion.addChoice()}>
        {strings.addChoiceLabel}
      </Button>
    </Box>
  );
});
