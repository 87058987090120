export interface PresenterErrorStrings {
  readonly buy: string;
  readonly contactUs: string;
  readonly defaultTitle: string;
  readonly logout: string;
  readonly parentAccessDeniedMessage: string;
  readonly parentAccessDeniedTitle: string;
  readonly reload: string;
  readonly viewOtherProfiles: string;
}

export const EnglishPresenterErrorStrings: PresenterErrorStrings = {
  buy: 'Subscribe',
  contactUs: 'Get some help',
  defaultTitle: 'An error occurred',
  logout: 'Logout',
  parentAccessDeniedMessage: "To access your child's planner, you must subscribe to Studyo.",
  parentAccessDeniedTitle: 'Not Authorized',
  reload: 'Refresh page',
  viewOtherProfiles: 'Other profiles'
};

export const FrenchPresenterErrorStrings: PresenterErrorStrings = {
  buy: 'S’abonner',
  contactUs: 'Obtenir de l’aide',
  defaultTitle: 'Une erreur est survenue',
  logout: 'Se déconnecter',
  parentAccessDeniedMessage: 'Pour accéder à l’agenda de votre enfant, vous devez vous abonner à Studyo.',
  parentAccessDeniedTitle: 'Non-autorisé',
  reload: 'Rafraîchir la page',
  viewOtherProfiles: 'Autres profils'
};
