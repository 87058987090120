import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { computed, makeObservable } from 'mobx';
import { BaseModel } from '../../Model';
import { DashboardStepComments } from '../interfaces';
import { GrpcOnboardingComment } from './GrpcOnboardingComment';
import { GrpcOnboardingStepSummary } from './GrpcOnboardingStepSummary';

export class GrpcDashboardStepComments extends BaseModel<PB.DashboardStepComments> implements DashboardStepComments {
  constructor(pb: PB.DashboardStepComments) {
    super(pb);
    makeObservable(this);
  }

  @computed
  get step() {
    const pbStep = this._pb.step;
    return pbStep && new GrpcOnboardingStepSummary(pbStep);
  }

  @computed
  get comments() {
    return this._pb.comments.map((c) => new GrpcOnboardingComment(c));
  }
}
