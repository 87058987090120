import {
  EnglishPlannerContentMoveStrings,
  FrenchPlannerContentMoveStrings,
  PlannerContentMoveStrings
} from './PlannerContentMoveStrings';
import {
  EnglishPlannerFilterOptionStrings,
  FrenchPlannerFilterOptionStrings,
  PlannerFilterOptionsStrings
} from './PlannerFilterOptionsStrings';
import {
  EnglishPlannerOptionMenuStrings,
  FrenchPlannerOptionMenuStrings,
  PlannerOptionMenuStrings
} from './PlannerOptionMenuStrings';
import {
  EnglishPlannerPeriodEditStrings,
  FrenchPlannerPeriodEditStrings,
  PlannerPeriodEditStrings
} from './PlannerPeriodEditStrings';
import {
  EnglishPlannerPeriodInfoStrings,
  FrenchPlannerPeriodInfoStrings,
  PlannerPeriodInfoStrings
} from './PlannerPeriodInfoStrings';
import {
  EnglishPlannerSectionInfoStrings,
  FrenchPlannerSectionInfoStrings,
  PlannerSectionInfoStrings
} from './PlannerSectionInfoString';

export interface PlannerStrings {
  readonly periodRemainingInTerm: (count: number) => string;
  readonly moveContent: string;
  readonly copyContent: string;
  readonly sectionFilterTitle: string;
  readonly sectionFilterClose: string;
  readonly contentFilterTitle: string;
  readonly contentFilterClose: string;
  readonly contentFilterButton: string;
  readonly sectionFilterButton: string;

  readonly periodInfoStrings: PlannerPeriodInfoStrings;
  readonly periodEditStrings: PlannerPeriodEditStrings;
  readonly contentMoveStrings: PlannerContentMoveStrings;
  readonly optionMenu: PlannerOptionMenuStrings;
  readonly filterOptions: PlannerFilterOptionsStrings;
  readonly sectionInfo: PlannerSectionInfoStrings;
}

export const EnglishPlannerStrings: PlannerStrings = {
  periodInfoStrings: EnglishPlannerPeriodInfoStrings,
  periodEditStrings: EnglishPlannerPeriodEditStrings,
  contentMoveStrings: EnglishPlannerContentMoveStrings,
  optionMenu: EnglishPlannerOptionMenuStrings,
  filterOptions: EnglishPlannerFilterOptionStrings,
  sectionInfo: EnglishPlannerSectionInfoStrings,

  moveContent: 'Move',
  copyContent: 'Copy',
  contentFilterTitle: 'Filter Contents',
  contentFilterClose: 'Close',
  sectionFilterTitle: 'Filter Sections',
  sectionFilterClose: 'Close',
  contentFilterButton: 'Tasks',
  sectionFilterButton: 'Section',
  periodRemainingInTerm: (count: number) => (count === 0 ? 'Last in term' : `${count} remaining in term`)
};

export const FrenchPlannerStrings: PlannerStrings = {
  periodInfoStrings: FrenchPlannerPeriodInfoStrings,
  periodEditStrings: FrenchPlannerPeriodEditStrings,
  contentMoveStrings: FrenchPlannerContentMoveStrings,
  optionMenu: FrenchPlannerOptionMenuStrings,
  filterOptions: FrenchPlannerFilterOptionStrings,
  sectionInfo: FrenchPlannerSectionInfoStrings,

  contentFilterTitle: 'Filtres du contenu',
  contentFilterClose: 'Fermer',
  sectionFilterTitle: 'Filtrer les cours',
  sectionFilterClose: 'Fermer',
  contentFilterButton: 'Tâches',
  sectionFilterButton: 'Groupes',

  moveContent: 'Déplacer',
  copyContent: 'Copier',
  periodRemainingInTerm: (count: number) => {
    if (count === 0) {
      return 'Dernière de l’étape';
    } else if (count > 1) {
      return `${count} restantes à l’étape`;
    } else {
      return `${count} restante à l’étape`;
    }
  }
};
