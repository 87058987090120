import { ImportSessionSourceRowList, ObservablePresenter, PageHeaderBar } from '@insights/components';
import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ImportSessionSourceDataScreenProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const ImportSessionSourceDataScreen = observer((props: ImportSessionSourceDataScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  const params = useParams();

  const sessionId = params.sessionId ?? '';
  const label = params.label ?? '';
  const viewModel = useMemo(() => viewModelFactory.createImportSessionSourceData(sessionId, label), [sessionId, label]);

  return (
    <Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingSourceDataMessage}
          errorMessage={strings.sourceDataErrorMessage}
          render={(data) => (
            <Box display="flex" flexDirection="column">
              <ImportSessionSourceRowList
                rows={data.rows}
                useNonPropFont={data.isTextFile}
                columnNames={data.columnNames}
                title={`${data.label} - ${data.title}`}
                tableStateKey="SourceDataSourceRows"
              />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
