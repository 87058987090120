import { SchoolYearConfiguration_Term as PBTerm } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { BaseModel, SerializableModel } from '../Model';
import { Day } from '../types';

export interface TermModel extends SerializableModel<PBTerm> {
  readonly tag: string;
  readonly startDay: Day;
  readonly endDay: Day;
}

export class Term extends BaseModel<PBTerm> implements TermModel {
  constructor(pb: PBTerm) {
    super(pb);
  }

  get tag(): string {
    return this._pb.tag;
  }

  get startDay(): Day {
    const pbDay = this._pb.startDay;

    if (pbDay == null) {
      throw new Error('Invalid Term.startDay value received from backend');
    }

    return new Day(pbDay);
  }

  get endDay(): Day {
    const pbDay = this._pb.endDay;

    if (pbDay == null) {
      throw new Error('Invalid Term.endDay value received from backend');
    }

    return new Day(pbDay);
  }
}
