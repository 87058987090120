import { AccountProfile as PBAccountProfile } from '@buf/studyo_studyo.bufbuild_es/studyo/type_account_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableStringProperty, EditableValuePropertyEx } from '../editables';
import { Color } from '../types';
import { protobufFromColor } from '../types/EnumConversion';
import { AccountProfileModel } from './AccountProfile';

export class EditableAccountProfile extends EditableModelEx<PBAccountProfile> implements AccountProfileModel {
  private _avatarColor: EditableValuePropertyEx<Color, PBAccountProfile>;
  private _privateFirstName: EditableStringProperty<PBAccountProfile>;
  private _privateLastName: EditableStringProperty<PBAccountProfile>;
  private _publicEmail: EditableStringProperty<PBAccountProfile>;

  constructor(
    private readonly _originalAccountProfile: AccountProfileModel,
    isNew = false
  ) {
    super(_originalAccountProfile.toProtobuf(), isNew);
    makeObservable(this);

    this._avatarColor = this.addValueField(
      _originalAccountProfile.avatarColor,
      (pb, value) => (pb.avatarColor = protobufFromColor(value))
    );
    this._privateFirstName = this.addStringField(
      _originalAccountProfile.privateFirstName,
      (pb, value) => (pb.privateFirstName = value),
      {
        trim: true
      }
    );
    this._privateLastName = this.addStringField(
      _originalAccountProfile.privateLastName,
      (pb, value) => (pb.privateLastName = value),
      {
        trim: true
      }
    );
    this._publicEmail = this.addStringField(
      _originalAccountProfile.publicEmail,
      (pb, value) => (pb.publicEmail = value),
      { trim: true }
    );
  }

  @computed
  get avatarColor() {
    return this._avatarColor.value;
  }

  set avatarColor(value: Color) {
    this._avatarColor.value = value;
  }

  @computed
  get privateFirstName() {
    return this._privateFirstName.value;
  }

  set privateFirstName(value: string) {
    this._privateFirstName.value = value;
  }

  @computed
  get privateLastName() {
    return this._privateLastName.value;
  }

  set privateLastName(value: string) {
    this._privateLastName.value = value;
  }

  @computed
  get publicEmail(): string {
    return this._publicEmail.value;
  }

  set publicEmail(value: string) {
    this._publicEmail.value = value;
  }
}
