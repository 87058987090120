export interface PlannerSectionInfoStrings {
  readonly more: string;
  readonly close: string;
  readonly sendEmailAllStudents: string;
  readonly sendEmailAllParents: string;
  readonly sendEmailAll: string;
  readonly searchPlaceholder: string;
  readonly noStudents: string;
  readonly sendEmailToStudent: string;
  readonly sendEmailToParent: string;

  studentCount: (count: number) => string;
}

export const EnglishPlannerSectionInfoStrings: PlannerSectionInfoStrings = {
  more: 'More…',
  close: 'Close',
  sendEmailAllStudents: 'Send email to all students',
  sendEmailAllParents: 'Send email to all parents',
  sendEmailAll: 'Send email to all',
  searchPlaceholder: 'Search students…',
  noStudents: 'No students',
  sendEmailToStudent: 'Student',
  sendEmailToParent: 'Parent(s)',

  studentCount: (count) => `${count} student${count > 1 ? 's' : ''}`
};

export const FrenchPlannerSectionInfoStrings: PlannerSectionInfoStrings = {
  more: 'Plus d’options…',
  close: 'Fermer',
  sendEmailAllStudents: 'Envoyer un courriel à tous les élèves',
  sendEmailAllParents: 'Envoyer un courriel à tous les parents',
  sendEmailAll: 'Envoyer un courriel à tous',
  searchPlaceholder: 'Rechercher des élèves…',
  noStudents: 'Aucun élève',
  sendEmailToStudent: 'Élève',
  sendEmailToParent: 'Parent(s)',

  studentCount: (count) => (count === 0 ? 'Aucun élève' : `${count} élève${count > 1 ? 's' : ''}`)
};
