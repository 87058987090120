import CopyIcon from '@mui/icons-material/FileCopy';
import { Box, IconButton, Snackbar, SxProps, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';

export interface CopyIdButtonProps {
  sx?: SxProps;
  className?: string;
  id: string;
}

export const CopyIdButton = observer((props: CopyIdButtonProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, id } = props;
  const strings = localizationService.localizedStrings.insights.components;
  const [showToast, setShowToast] = useState(false);

  async function copyIdToClipboard(id: string) {
    await navigator.clipboard.writeText(id);
    setShowToast(true);
  }

  return (
    <Box sx={sx} className={className}>
      <Tooltip title={strings.copyId}>
        <IconButton onClick={() => void copyIdToClipboard(id)}>
          <CopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        message={strings.copyIdNotification}
      />
    </Box>
  );
});
