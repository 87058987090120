export interface PeriodsOptionMenuStrings {
  readonly title: string;
  readonly close: string;
  readonly displayedElementsTitle: string;
  readonly displayAnnouncedDayTitle: string;
}

export const EnglishPeriodsOptionMenuStrings: PeriodsOptionMenuStrings = {
  title: 'Options',
  close: 'Close',
  // displayed elements
  displayedElementsTitle: 'Displayed Elements',
  displayAnnouncedDayTitle: 'Show announcement day icon'
};

export const FrenchPeriodsOptionMenuStrings: PeriodsOptionMenuStrings = {
  title: 'Options',
  close: 'Fermer',
  // displayed elements
  displayedElementsTitle: 'Éléments affichés',
  displayAnnouncedDayTitle: 'Afficher l’icône de date d’annonce'
};
