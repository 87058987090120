import { LocalizationService } from '@shared/resources/services';
import { BaseStartUpService } from '@shared/services';
import { AccountService } from './AccountService';
import { SettingsStore } from './SettingsStore';

export class AppInsightsStartUpService extends BaseStartUpService {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _accountService: AccountService,
    private readonly _settings: SettingsStore
  ) {
    super();
  }

  protected async initServices(): Promise<void> {
    // AccountService uses SettingsStore, initialize in that order.
    await Promise.all([this._localizationService.initialize(), this._settings.initialize()]);
    await this._accountService.startSilentLogin();
  }
}
