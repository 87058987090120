import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { computed, makeObservable } from 'mobx';
import { BaseModel } from '../../Model';
import { DashboardStep } from '../interfaces';
import { GrpcDashboardQuestion } from './GrpcDashboardQuestion';
import { GrpcOnboardingStepSummary } from './GrpcOnboardingStepSummary';

export class GrpcDashboardStep extends BaseModel<PB.DashboardStep> implements DashboardStep {
  constructor(pb: PB.DashboardStep) {
    super(pb);
    makeObservable(this);
  }

  @computed
  get step() {
    const pbStep = this._pb.step;
    return pbStep && new GrpcOnboardingStepSummary(pbStep);
  }

  @computed
  get questions() {
    return this._pb.questions.map((q) => new GrpcDashboardQuestion(q));
  }
}
