import { SchemaImportOption as PBSchemaImportOption } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { BaseModel } from '../../Model';
import { SchemaImportOption } from '../interfaces';

export class GrpcSchemaImportOption extends BaseModel<PBSchemaImportOption> implements SchemaImportOption {
  constructor(pb: PBSchemaImportOption) {
    super(pb);
  }

  get name(): string {
    return this._pb.name;
  }

  get localizedDescription(): string {
    return this._pb.description;
  }
}
