import { createContext } from 'react';
import { ImageService, ThemeService } from './resources/services';

export interface SharedServicesContextValues {
  readonly imageService: ImageService;
  readonly themeService: ThemeService;
}

// Passing casting to any to prevent setting a default value. The runtime should define these (app, storybook, etc.)
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument
export const SharedServicesContext = createContext<SharedServicesContextValues>({} as any);
