import { ConfirmationDialog, MessageDialog } from '@insights/components';
import { DialogCancelled, ModalService } from '@shared/services';
import { AlertConfirmationParameters, AlertService } from './AlertService';

export class WebAlertService implements AlertService {
  constructor(private readonly _modalServiceResolver: () => ModalService) {}

  showConfirmation(parameters: AlertConfirmationParameters): Promise<void | DialogCancelled> {
    return this._modalServiceResolver().showModal(ConfirmationDialog, {
      title: parameters.title,
      message: parameters.message,
      okButtonCaption: parameters.okButtonCaption,
      cancelButtonCaption: parameters.cancelButtonCaption,
      isMessageHtml: parameters.isMessageHtml,
      displayCheckbox: parameters.displayCheckbox
    });
  }

  showMessage(parameters: {
    title?: string;
    message: string;
    okButtonCaption?: string;
  }): Promise<void | DialogCancelled> {
    return this._modalServiceResolver().showModal(MessageDialog, {
      title: parameters.title,
      message: parameters.message,
      okButtonCaption: parameters.okButtonCaption
    });
  }
}
