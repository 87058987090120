import { ApplicationName } from '@insights/components';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, SxProps, Toolbar, useTheme } from '@mui/material';
import { Observer, observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { Outlet } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { UserMenu } from './UserMenu';

export interface AuthenticatedLayoutProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const AuthenticatedLayout = observer((props: AuthenticatedLayoutProps) => {
  const { viewModelFactory, settingsStore } = useInsightsServices();
  const { sx = [], className, style } = props;

  const theme = useTheme();

  const toggleDrawer = () => {
    settingsStore.sidenavOpen = !settingsStore.sidenavOpen;
  };

  return (
    <Box sx={sx} className={className} style={style} display="flex" flexDirection="column">
      <AppBar position={'relative'} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Box height="100%" width="100%" display="flex" flexDirection="row" alignItems="center">
            {/* NOTE: We need this Observer to encapsulate the hasSidenav
                        observable to prevent to many render on the AuthenticatedLayout.
                        We could have created a component for it but the margin (via the container)
                        is really layout specific and needs to be present only if there is a sidenav.
                        Leaving the condition to display the margin if there is a sidenav will
                        generate the same issue we are trying to prevent. */}
            <Observer>
              {() => (
                <>
                  {settingsStore.sidenavCollapsed && settingsStore.hasSidenav && (
                    <IconButton sx={{ mr: 1 }} onClick={toggleDrawer} color="inherit">
                      <MenuIcon />
                    </IconButton>
                  )}
                </>
              )}
            </Observer>

            <ApplicationName size="large" color="dark" />

            <Box flex={1} />

            <UserMenu viewModel={viewModelFactory.createUserMenu()} />
          </Box>
        </Toolbar>
      </AppBar>

      <Box flex={1}>
        <Outlet />
      </Box>
    </Box>
  );
});
