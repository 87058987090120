import { OnboardingVariableResolver } from '@insights/viewmodels';
import { Box, SxProps } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { OnboardingText } from '@shared/models/onboarding/interfaces';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { OnboardingTypography } from './OnboardingTypography';

export interface OnboardingTypographySelectorProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  texts: OnboardingText[];
  variableResolver?: OnboardingVariableResolver;
  variant?: Variant;
  disabled?: boolean;
}

export const OnboardingTypographySelector = observer((props: OnboardingTypographySelectorProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], texts, variant, variableResolver, disabled } = props;
  const text = texts.find((t) => t.languageCode === localizationService?.currentLocale) ?? texts[0];
  const textValue = variableResolver?.parseText(text.value) ?? text.value;

  return (
    <Box sx={sx} className={className} style={style}>
      <OnboardingTypography text={textValue} format={text.format} variant={variant} disabled={disabled} />
    </Box>
  );
});
