import { AccountService } from '@insights/services';
import { EditableImportSession } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { computed, makeObservable, observable, runInAction } from 'mobx';

export interface ImportSessionCreateScreenViewModel {
  readonly isExecuting: boolean;
  readonly hasError: boolean;
  name: string;
  description: string;

  create: () => Promise<void>;
  cancel: () => void;
}

export class AppImportSessionCreateScreenViewModel implements ImportSessionCreateScreenViewModel {
  private readonly _session: EditableImportSession;
  @observable private _isExecuting = false;
  @observable private _hasError = false;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _importSessionStore: ImporterStore,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private _onSuccess: () => void,
    private _onCancel: () => void
  ) {
    makeObservable(this);
    this._session = EditableImportSession.createNew(_configId);
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @computed
  get hasError() {
    return this._hasError;
  }

  @computed
  get name() {
    return this._session.name;
  }

  set name(value: string) {
    this._session.name = value;
  }

  @computed
  get description() {
    return this._session.description;
  }

  set description(value: string) {
    this._session.description = value;
  }

  async create() {
    runInAction(() => {
      this._isExecuting = true;
      this._hasError = false;
    });

    try {
      // Complete the session.
      this._session.managerEmail = this._accountService.userEmail;
      this._session.sourceKind = 'other';

      // No need for the data in the origin screen.
      await this._importSessionStore.createOrUpdateImportSession(this._session, false);

      this._onSuccess();
    } catch (error) {
      console.error(error);

      runInAction(() => {
        this._isExecuting = false;
        this._hasError = true;
      });
    }
  }

  cancel() {
    this._onCancel();
  }
}
