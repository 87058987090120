export interface CourseSelectionStrings {
  cancel: string;
  chooseValue: string;
  filtersLabel: string;
  filterSubtitle: string;
  gradeLevelTitle: string;
  group: string;
  searchPlaceholder: string;
  none: string;
  save: string;
  taughtCourseSectionTooltip: string;
  autoEnrolledCourseSectionTooltip: string;

  savingTitle: string;
  savingDescription: string;
  savedTitle: string;
  savedDescription: string;
  saveErrorTitle: string;
  saveErrorDescription: string;
  savePermanentErrorDescription: string;

  sectionNumberTitle: string;
  selectedCoursesTitle: string;
  teacherAsStudentWarning: string;
  teacherTitle: string;
  title: string;

  unsavedChangesTitle: string;
  unsavedChangesDescription: string;
  unsavedChangesStayTitle: string;
  unsavedChangesDiscardTitle: string;
}

export const EnglishCourseSelectionStrings: CourseSelectionStrings = {
  cancel: 'Cancel',
  chooseValue: 'Choose value',
  filtersLabel: 'Find by:',
  filterSubtitle: 'Select your grade level and section number to begin your course selection.',
  gradeLevelTitle: 'Grade level',
  group: 'Group',
  searchPlaceholder: 'Search courses by title, teacher name or homeroom…',
  none: 'None',
  save: 'Save modifications',
  taughtCourseSectionTooltip: 'You teach this course',
  autoEnrolledCourseSectionTooltip: "You've been auto-enrolled in this course by your school",

  savingTitle: 'Please wait…',
  savingDescription: 'Saving your course selection',
  savedTitle: 'Success',
  savedDescription: 'Your course selection was saved successfully!',
  saveErrorTitle: 'Oops, something went wrong!',
  saveErrorDescription: 'An error occurred while saving your course selection. Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented the save. Please reload and try again.',

  sectionNumberTitle: 'Section number',
  selectedCoursesTitle: 'The courses currently selected are listed below.',
  teacherAsStudentWarning: 'AS A STUDENT',
  teacherTitle: 'Teacher',
  title: 'My planner configuration',

  unsavedChangesTitle: 'Unsaved changes',
  unsavedChangesDescription: 'The changes made to your course selection will be lost. Are you sure you wish to cancel?',
  unsavedChangesStayTitle: 'No',
  unsavedChangesDiscardTitle: 'Discard'
};

export const FrenchCourseSelectionStrings: CourseSelectionStrings = {
  cancel: 'Annuler',
  chooseValue: 'Veuillez choisir',
  filtersLabel: 'Trouver par :',
  filterSubtitle:
    'Sélectionnez d’abord votre niveau et votre numéro de groupe pour voir la sélection de cours. Votre liste de cours déjà sélectionnée apparaît ci-dessous.',
  gradeLevelTitle: 'Niveau scolaire',
  group: 'Groupe',
  searchPlaceholder: 'Rechercher des cours par titre, nom d’enseignant ou foyer…',
  none: 'Aucun',
  save: 'Enregister les modifications',
  taughtCourseSectionTooltip: 'Vous enseignez ce cours',
  autoEnrolledCourseSectionTooltip: 'Vous avez été ajouté automatiquement à ce cours par votre école',

  savingTitle: 'Veuillez patienter…',
  savingDescription: 'Sauvegarde de la sélection des cours',
  savedTitle: 'Succès',
  savedDescription: 'La sélection de cours a été sauvegardée!',
  saveErrorTitle: 'Oups, une erreur est survenue!',
  saveErrorDescription: 'La sélection de cours n’a pu être sauvegardée. Veuillez réessayer.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la sauvegarde. Veuillez rafraichir et réessayer.',

  sectionNumberTitle: 'Numéro de groupe',
  selectedCoursesTitle: 'La liste des cours déjà sélectionnés apparaît ci-dessous.',
  teacherAsStudentWarning: 'EN TANT QU’ÉLÈVE',
  teacherTitle: 'Enseignant(e)',
  title: `Configuration de l'horaire`,

  unsavedChangesTitle: 'Changements non-sauvegardés',
  unsavedChangesDescription:
    'Les changements effectués à votre sélection de cours seront perdus. Souhaitez-vous annuler?',
  unsavedChangesStayTitle: 'Non',
  unsavedChangesDiscardTitle: 'Abandonner'
};
