import { AppMapStorageProperty, AppStorageModel, MapStorageProperty, StorageModel } from '@insights/utils';
import { Storage } from '@shared/services';
import { action, computed, makeObservable } from 'mobx';

const IsExpandedStorageKey = 'CardPreferencesIsExpanded';

export interface CardPreferences extends StorageModel {
  readonly cardStateIsExpanded: ReadonlyMap<string, boolean>;

  setIsExpanded: (cardKey: string, isExpanded: boolean) => void;
}

export class AppCardPreferences extends AppStorageModel implements CardPreferences {
  private _cardStateIsExpanded: MapStorageProperty<string, boolean>;

  constructor(storage: Storage) {
    super(storage);

    makeObservable(this);

    this.setStorageProperties([
      (this._cardStateIsExpanded = new AppMapStorageProperty<string, boolean>(storage, IsExpandedStorageKey))
    ]);
  }

  @computed
  get cardStateIsExpanded(): ReadonlyMap<string, boolean> {
    return this._cardStateIsExpanded.value;
  }

  @action
  setIsExpanded(cardKey: string, isExpanded: boolean) {
    this._cardStateIsExpanded.value = new Map<string, boolean>(
      this._cardStateIsExpanded.value.set(cardKey, isExpanded)
    );
  }
}
