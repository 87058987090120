class DragManager {
  private _draggedData: unknown;
  private _dataType: string | undefined;
  private _canCopyMove = false;
  private _isMoving = false;

  get draggedData(): unknown {
    return this._draggedData;
  }

  get draggedDataType() {
    return this._dataType;
  }

  get canCopyMove() {
    return this._canCopyMove;
  }

  get isMoving() {
    return this._isMoving;
  }

  setDraggedData(type: string, value: unknown, canCopyMove?: boolean) {
    if (this._draggedData != null) {
      console.warn(
        'Trying to set dragged data while data is still being dragged. Try calling clearDragData beforehand'
      );
    } else {
      this._draggedData = value;
      this._dataType = type;
      this._canCopyMove = canCopyMove ?? false;
      // Move by default when supporting both.
      this._isMoving = this._canCopyMove;
    }
  }

  clearDragData() {
    this._draggedData = undefined;
    this._dataType = undefined;
    this._isMoving = false;
    this._canCopyMove = false;
  }

  updateIsMoving(isMoving?: boolean) {
    if (isMoving != null && this._canCopyMove) {
      this._isMoving = isMoving;
    }

    return this._isMoving;
  }
}

export const dragManager = new DragManager();
