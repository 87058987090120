import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_sky_pb';
import { BaseModel } from '../Model';
import { GrpcBlackbaudSkyContentRepresentation } from './GrpcBlackbaudSkyContentRepresentation';
import { BlackbaudSkyAssignmentTypeMappingModel } from './interfaces';

export class GrpcBlackbaudSkyAssignmentTypeMapping
  extends BaseModel<BC.AssignmentTypeMapping>
  implements BlackbaudSkyAssignmentTypeMappingModel
{
  constructor(pb: BC.AssignmentTypeMapping) {
    super(pb);
  }

  get typeId() {
    return this._pb.typeId;
  }

  get typeName() {
    return this._pb.typeName;
  }

  get representation() {
    const pb = this._pb.representation;
    return pb != null ? new GrpcBlackbaudSkyContentRepresentation(pb) : undefined;
  }
}
