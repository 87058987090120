import { WorkloadManagerTaskInfo } from './WorkloadManagerDetailViewModelBase';

export interface WorkloadManagerTaskDetailsViewModel {
  taskInfo: WorkloadManagerTaskInfo;

  close: () => void;
}

export class AppWorkloadManagerTaskDetailsViewModel implements WorkloadManagerTaskDetailsViewModel {
  constructor(
    public readonly taskInfo: WorkloadManagerTaskInfo,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {}

  close() {
    this._onSuccess();
  }
}
