export interface OnboardingVariableResolver {
  parseText(text: string): string;
}

export class DefaultOnboardingVariableResolver implements OnboardingVariableResolver {
  constructor(private readonly _configId: string) {}

  parseText(text: string): string {
    // We use a very simple approach, since we only support two variables for now!
    return (
      text
        // This variable is useful to link to the same Insights configuration.
        .replace(/%config%/g, `${window.location.origin}/configs/${this._configId}`)
        // This variable could be used in the future to link to the same config in Studyo.
        .replace(/%configid%/g, this._configId)
    );
  }
}
