import '@formatjs/intl-enumerator/polyfill';
import { replayIntegration } from '@sentry/capacitor';
import { BrowserOptions, init as sentryInit } from '@sentry/react';
import { SharedServicesContext } from '@shared/SharedServicesContext.tsx';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { InsightsServiceContainer } from './InsightsServiceContainer.ts';
import { InsightsServicesContext } from './InsightsServicesContext';
import { router } from './Router.tsx';

// The `public/environment.js` scripts, loaded via the ≤head≥ tag in `index.html`, injects those environment
// variables into the global Window object.
declare global {
  interface StudyoEnv {
    environmentName: string;
    version: string;
    buildNumber: string;
    apiV3Url: string;
    killSwitchUrl: string;
    authServiceUrl: string;
    authClientId: string;
    intercomAppId: string;
    googleClientId: string;
    microsoftClientId: string;
    mixpanelToken: string;
    studyoUrl: string;
  }

  interface Window {
    STUDYO_ENV: StudyoEnv;
  }
}

const sentryOptions: BrowserOptions = {
  dsn: 'https://fb02ca00626cd037ef20db21532ce47d@o187895.ingest.sentry.io/4505761432141824',
  integrations: [replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

if (window.STUDYO_ENV != null) {
  sentryOptions.release = `insights-web@${window.STUDYO_ENV.version}+${window.STUDYO_ENV.buildNumber}`;
  sentryOptions.environment = window.STUDYO_ENV.environmentName;
}

sentryInit(sentryOptions);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <InsightsServicesContext.Provider value={InsightsServiceContainer.services}>
      <SharedServicesContext.Provider value={{ ...InsightsServiceContainer.services }}>
        <RouterProvider router={router} />
      </SharedServicesContext.Provider>
    </InsightsServicesContext.Provider>
  </StrictMode>
);
