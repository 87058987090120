import { MaximumSectionAutoEnrollTagLength } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  IconButton,
  MenuItem,
  Select,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';

export interface EditableAutoEnrollTagsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  tags: string[];
  availableTags: string[];
  error?: string;
  onChange: (tags: string[]) => void;
  disabled?: boolean;
}

export const EditableAutoEnrollTags = observer((props: EditableAutoEnrollTagsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, availableTags, tags, error, onChange, disabled } = props;
  const strings = localizationService.localizedStrings.insights.components.sections;

  function addTag() {
    onChange(tags.slice().concat('gradeLevel='));
  }

  return (
    <Root sx={sx} size="small" className={className} style={style}>
      <TableHead>
        <TableRow>
          <TableCell className="cell" colSpan={2}>
            {strings.autoEnrollTagsLabel}
          </TableCell>
          <TableCell className="cell">
            <IconButton disabled={disabled} onClick={() => addTag()}>
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tags.map((tag, index) => renderTag(tag, index, tags, availableTags, onChange, disabled, localizationService))}
      </TableBody>
      {(error?.length ?? 0) > 0 && (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={3}>
              <Typography variant="caption" sx={{ color: (theme) => theme.palette.error.main }}>
                {error}
              </Typography>
            </TableCell>
          </TableRow>
        </TableFooter>
      )}
    </Root>
  );
});

function renderTag(
  tag: string,
  index: number,
  allTags: string[],
  availableTags: string[],
  onChange: (tags: string[]) => void,
  disabled: boolean | undefined,
  localizationService: LocalizationService
) {
  const strings = localizationService.localizedStrings.insights.components.sections;

  const parts = tag.split('=');
  const canEdit = parts.length == 2 && availableTags.includes(parts[0]);

  function deleteTag(index: number) {
    // Do not modify the original array (and splice works "in place" and returns the deleted items, because, you know...)
    const tags = allTags.slice();
    tags.splice(index, 1);
    onChange(tags);
  }

  function updateTag(tag: string, value: string, index: number) {
    // Do not modify the original array
    const tags = allTags.slice();
    tags[index] = `${tag}=${value}`;
    onChange(tags);
  }

  return (
    <TableRow key={`tag-row-${index}`}>
      <TableCell className="cell">
        {canEdit ? (
          <Select
            value={parts[0]}
            disabled={disabled}
            onChange={(event) => updateTag(event.target.value, parts[1], index)}
          >
            {availableTags.map((at) => (
              <MenuItem key={`tag-key-${at}`} value={at}>
                {strings.getReadableAutoEnrollTag(at)}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography>{parts[0]}</Typography>
        )}
      </TableCell>
      <TableCell className="cell">
        {canEdit ? (
          <TextField
            value={parts[1]}
            disabled={disabled}
            slotProps={{ input: { inputProps: { maxLength: MaximumSectionAutoEnrollTagLength } } }}
            onChange={(event) => updateTag(parts[0], event.target.value, index)}
          />
        ) : (
          <Typography>{parts[1] ?? ''}</Typography>
        )}
      </TableCell>
      <TableCell className="cell">
        <IconButton disabled={disabled} onClick={() => deleteTag(index)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

const Root = styled(Table)(({ theme }) => ({
  '.cell': {
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0
  }
}));
