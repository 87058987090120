export interface PlannerOptionMenuStrings {
  readonly title: string;
  readonly close: string;
  readonly periodHeightSectionTitle: string;
  readonly smallPeriodHeightOptionTitle: string;
  readonly mediumPeriodHeightOptionTitle: string;
  readonly largePeriodHeightOptionTitle: string;
  readonly displayedElementsSectionTitle: string;
  readonly displayAnnouncedDayTitle: string;
  readonly showWeekendOptionTitle: string;
}

export const EnglishPlannerOptionMenuStrings: PlannerOptionMenuStrings = {
  title: 'Options',
  close: 'Close',
  periodHeightSectionTitle: 'Period Height',
  smallPeriodHeightOptionTitle: 'Small',
  mediumPeriodHeightOptionTitle: 'Medium',
  largePeriodHeightOptionTitle: 'Large',
  displayedElementsSectionTitle: 'Displayed Elements',
  displayAnnouncedDayTitle: 'Show announcement day icon',
  showWeekendOptionTitle: 'Show weekends'
};

export const FrenchPlannerOptionMenuStrings: PlannerOptionMenuStrings = {
  title: 'Options',
  close: 'Fermer',
  periodHeightSectionTitle: 'Hauteur des périodes',
  smallPeriodHeightOptionTitle: 'Petite',
  mediumPeriodHeightOptionTitle: 'Moyenne',
  largePeriodHeightOptionTitle: 'Grande',
  displayedElementsSectionTitle: 'Éléments affichés',
  displayAnnouncedDayTitle: 'Afficher l’icône de date d’annonce',
  showWeekendOptionTitle: 'Afficher les fins de semaine'
};
