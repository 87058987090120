import * as TC from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/teams_pb';
import * as TPB from '@buf/studyo_studyo.bufbuild_es/studyo/type_teams_pb';
import { Teams } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/teams_connect';
import { MicrosoftTeamsTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class MicrosoftTeamsGrpcTransport extends BaseGrpcTransport implements MicrosoftTeamsTransport {
  async fetchTeamsExternalAccountDetails(externalAccountId: string): Promise<TPB.AccountDetails> {
    console.log('Fetching Microsoft Teams external account details...');

    const request = new TC.GetExternalAccountDetailsRequest();
    request.externalAccountId = externalAccountId ?? '';

    const response = await this.performRequest(Teams, Teams.methods.getExternalAccountDetails, request);

    console.log('Successfully fetched Microsoft Teams external account details.');

    return response.accountDetails!;
  }

  async createOrUpdateTeamsExternalAccount(
    externalAccountId: string | undefined,
    configId: string,
    accountName: string,
    tenantId: string
  ): Promise<TC.CreateOrUpdateExternalAccountResponse> {
    console.log('Creating or updating Microsoft Teams external account...');

    const request = new TC.CreateOrUpdateExternalAccountRequest();
    request.externalAccountId = externalAccountId ?? '';
    request.configId = configId;
    request.accountName = accountName;
    request.tenantId = tenantId;

    const response = await this.performRequest(Teams, Teams.methods.createOrUpdateExternalAccount, request);

    console.log('Successfully created or updated Microsoft Teams external account.');

    return response;
  }

  async updateTeamsExternalAccountSettings(
    externalAccountId: string | undefined,
    configId: string,
    mappings: TPB.AssignmentCategoryMapping[],
    ignoreUnmappedCategories: boolean,
    customClassesFilter: string
  ): Promise<void> {
    console.log('Updating Microsoft Teams external account settings...');

    const request = new TC.UpdateExternalAccountSettingsRequest();
    request.externalAccountId = externalAccountId ?? '';
    request.configId = configId;
    request.mappings = mappings;
    request.ignoreUnmappedCategories = ignoreUnmappedCategories;
    request.customClassesFilter = customClassesFilter;

    await this.performRequest(Teams, Teams.methods.updateExternalAccountSettings, request);

    console.log('Successfully updated Microsoft Teams external account settings.');
  }

  async fetchTeamsAssignmentCategories(externalAccountId: string): Promise<TPB.AssignmentCategory[]> {
    console.log('Fetching Microsoft Teams assignment categories...');

    const request = new TC.GetAssignmentCategoriesRequest();
    request.externalAccountId = externalAccountId ?? '';

    const response = await this.performRequest(Teams, Teams.methods.getAssignmentCategories, request);

    console.log('Successfully fetched Microsoft Teams assignment catgeories.');

    return response.categories;
  }
}
