import { NavigationPrompt, Schedules, SchoolCalendar, SpecialDays, Terms } from '@insights/components';
import { SchoolCalendarViewModel } from '@insights/viewmodels';
import { Box, Paper, SxProps, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SchoolCalendarLayoutProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarViewModel;
}

export const SchoolCalendarLayout = observer((props: SchoolCalendarLayoutProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, viewModel, sx } = props;
  const strings = localizationService.localizedStrings.insights.views.calendar;

  // For now, every time we navigate here, the first tab is selected.
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Box sx={sx} display="flex" flexDirection="row" className={className} style={style}>
      <Box height="100%" overflow="auto">
        <SchoolCalendar viewModel={viewModel} sx={{ minWidth: 500, margin: 2 }} />
      </Box>

      <Box flex={1} padding={1} maxWidth={1000}>
        <Paper sx={{ height: '100%', width: '100%', p: 2 }}>
          <Box height="100%" width="100%" display="flex" flexDirection="column">
            <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(Number(newValue))}>
              <Tab label={strings.specialDays} />
              <Tab label={strings.bellTimes} />
              <Tab label={strings.terms} />
            </Tabs>

            {selectedTab === 0 && (
              <Box height="100%" width="100%" overflow="auto">
                <SpecialDays viewModel={viewModel} allowEdit />
              </Box>
            )}

            {selectedTab === 1 && (
              <Box height="100%" width="100%" overflow="auto">
                <Schedules viewModel={viewModel} allowEdit />
              </Box>
            )}

            {selectedTab === 2 && (
              <Box height="100%" width="100%" overflow="auto">
                <Terms viewModel={viewModel} />
              </Box>
            )}
          </Box>
        </Paper>
      </Box>

      <NavigationPrompt when={viewModel.hasChanges} />
    </Box>
  );
});
