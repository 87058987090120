import { ConfigurationImages } from '@shared/resources/images/studyo';
import bellImage from '@shared/web/images/assets/studyo/ui_bell.svg?url';
import bellOutlineImage from '@shared/web/images/assets/studyo/ui_bell_outline.svg?url';
import classAddImage from '@shared/web/images/assets/studyo/ui_class_add.svg?url';
import classesListImage from '@shared/web/images/assets/studyo/ui_classes_list.svg?url';
import classesOptionImage from '@shared/web/images/assets/studyo/ui_classes_option.svg?url';
import configFirstDayImage from '@shared/web/images/assets/studyo/ui_config_first_day.svg?url';
import configLastDayImage from '@shared/web/images/assets/studyo/ui_config_last_day.svg?url';
import configListAddImage from '@shared/web/images/assets/studyo/ui_config_list_add.svg?url';
import removeObjectImage from '@shared/web/images/assets/studyo/ui_remove_object.svg?url';
import scheduleDetailsActionsImage from '@shared/web/images/assets/studyo/ui_schedule_details_actions.svg?url';
import schedulesOptionImage from '@shared/web/images/assets/studyo/ui_schedules_option.svg?url';
import schoolInfoOptionImage from '@shared/web/images/assets/studyo/ui_school_info_option.svg?url';
import specialDayListImage from '@shared/web/images/assets/studyo/ui_special_day_list.svg?url';
import specialDaysOptionImage from '@shared/web/images/assets/studyo/ui_special_days_option.svg?url';
import timetableCycleDaysImage from '@shared/web/images/assets/studyo/ui_timetable_cycle_days.svg?url';
import timetableDaysOfWeekImage from '@shared/web/images/assets/studyo/ui_timetable_days_of_week.svg?url';

export class WebConfigurationImages implements ConfigurationImages {
  get schoolInfoOption() {
    return schoolInfoOptionImage;
  }

  get schedulesOption() {
    return schedulesOptionImage;
  }

  get classesOption() {
    return classesOptionImage;
  }

  get specialDaysOption() {
    return specialDaysOptionImage;
  }

  get firstDayIcon() {
    return configFirstDayImage;
  }

  get lastDayIcon() {
    return configLastDayImage;
  }

  get bellIcon() {
    return bellImage;
  }

  get outlinedBellIcon() {
    return bellOutlineImage;
  }

  get addIcon() {
    return configListAddImage;
  }

  get scheduleOptionMenuIcon() {
    return scheduleDetailsActionsImage;
  }

  get addPeriodIcon() {
    return classAddImage;
  }

  get classListEditIcon() {
    return classesListImage;
  }

  get timetableCycleDayIcon() {
    return timetableCycleDaysImage;
  }

  get timetableDayOfWeekIcon() {
    return timetableDaysOfWeekImage;
  }

  get addSectionToPeriodIcon() {
    return classAddImage;
  }

  get removeSectionFromPeriodIcon() {
    return removeObjectImage;
  }

  get specialDayListEditIcon() {
    return specialDayListImage;
  }

  get removeScheduleFromSpecialDay() {
    return removeObjectImage;
  }
}
