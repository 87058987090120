import { DateTimePickerLocalizationProvider, InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SchoolYearConfigurationDemoCopyDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  schoolYearConfiguration: SchoolYearConfigurationModel;
}

export const SchoolYearConfigurationDemoCopyDialog = observer((props: SchoolYearConfigurationDemoCopyDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx = [], style, schoolYearConfiguration, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;
  const navigate = useNavigate();

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createSchoolYearConfigurationDemoCopy(schoolYearConfiguration, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <DateTimePickerLocalizationProvider>
        <Dialog
          sx={sx}
          open={true}
          onClose={() => viewModel.cancel()}
          maxWidth="sm"
          fullWidth={true}
          className={className}
          style={style}
        >
          <form
            noValidate
            onSubmit={(e) => {
              // This is to prevent the page from reloading on submit
              e.preventDefault();
              return false;
            }}
          >
            <DialogTitle>{strings.createDemoCopyTitle}</DialogTitle>
            <DialogContent dividers>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12 }}>
                  <Typography>{strings.createDemoCopyMessage}</Typography>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                  <TextField
                    fullWidth
                    label={strings.newSchoolNameLabel}
                    value={viewModel.newSchoolName}
                    onChange={(e) => (viewModel.newSchoolName = e.target.value)}
                  />
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                  <TextField
                    fullWidth
                    label={strings.baseAccountEmailLabel}
                    helperText={strings.baseAccountEmailHint}
                    value={viewModel.baseAccountEmail}
                    onChange={(e) => (viewModel.baseAccountEmail = e.target.value)}
                  />
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                  <FormControl fullWidth>
                    <InputLabel shrink id="language-label">
                      {strings.newSchoolLanguageLabel}
                    </InputLabel>
                    <Select
                      labelId="language-label"
                      value={viewModel.languageCode}
                      onChange={(e) => (viewModel.languageCode = String(e.target.value))}
                    >
                      <MenuItem value="en">
                        {/* no localization needed */}
                        <Typography>English</Typography>
                      </MenuItem>
                      <MenuItem value="fr">
                        {/* no localization needed */}
                        <Typography>Français</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid2>
              </Grid2>
            </DialogContent>
            <DialogActions>
              <InsightsButton isExecuting={viewModel.isCreatingCopy} onClick={() => viewModel.cancel()}>
                {strings.cancelButtonCaption}
              </InsightsButton>
              <InsightsButton
                isSubmit
                showSpinnerOnExecuting
                isDisabled={!viewModel.canCreateCopy}
                isExecuting={viewModel.isCreatingCopy}
                onClick={() => void viewModel.create(navigate)}
              >
                {strings.createCopyButtonLabel}
              </InsightsButton>
            </DialogActions>
          </form>
        </Dialog>
      </DateTimePickerLocalizationProvider>
    </ThemeProvider>
  );
});
