import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import LastYearIcon from '@mui/icons-material/EventRepeat';
import { Button, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

export interface LastAnswerTooltipProps {
  sx?: SxProps;
  className?: string;
  answer?: string;
  onCopy?: () => void;
}

export const LastAnswerTooltip = observer((props: LastAnswerTooltipProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], answer = '', onCopy } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <Tooltip
      title={
        <Stack>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 'bold'
            }}
          >
            {strings.lastYearAnswerTooltip}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              whiteSpace: 'pre-line'
            }}
          >
            {answer}
          </Typography>
          {onCopy != null && (
            <Button sx={{ mt: 1 }} variant="contained" color="info" size="small" onClick={onCopy}>
              {strings.copyAnswerButton}
            </Button>
          )}
        </Stack>
      }
    >
      <LastYearIcon sx={sx} className={className} fontSize="small" color="info" />
    </Tooltip>
  );
});
