import { EditableImportSession, EditableSourceFile, ImportSession } from '@shared/models/import';
import { OnboardingQuestion, OnboardingStep } from '@shared/models/onboarding/interfaces';
import { ImporterStore, OnboardingStore } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { DefaultOnboardingVariableResolver, OnboardingVariableResolver } from '../onboarding';

export interface OnboardingQuestionInfo {
  readonly question: OnboardingQuestion;
  readonly step: OnboardingStep;
}

export interface ImportSessionFileProcessLinkViewModel {
  readonly hasUploadedFile: boolean;
  readonly availableQuestions: OnboardingQuestionInfo[];
  readonly question: OnboardingQuestion | undefined;

  readonly variableResolver: OnboardingVariableResolver;

  setQuestionByName(name: string): void;
}

export interface ImportSessionFileProcessLinkDialogViewModel {
  readonly data: IPromiseBasedObservable<ImportSessionFileProcessLinkViewModel>;

  readonly canSave: boolean;
  readonly isExecuting: boolean;
  readonly hasError: boolean;
  save: () => Promise<void>;
  cancel: () => void;
}

export class AppImportSessionFileProcessLinkViewModel implements ImportSessionFileProcessLinkViewModel {
  private readonly _hasUploadedFile: boolean;
  @observable private _question?: OnboardingQuestion;

  constructor(
    private readonly _configId: string,
    private readonly _editableSession: EditableImportSession,
    private readonly _editableFile: EditableSourceFile,
    private readonly _onboardingSteps: OnboardingStep[],
    selectedQuestion?: OnboardingQuestion
  ) {
    makeObservable(this);
    this._hasUploadedFile = _editableFile.url.length > 0 && !_editableFile.url.startsWith('process://');
    this._question = selectedQuestion;
  }

  get hasUploadedFile() {
    return this._hasUploadedFile;
  }

  @computed
  get availableQuestions() {
    return _.flatten(
      this._onboardingSteps.map((s) =>
        s.questions.filter((q) => q.kind === 'file' || q.kind === 'large-text').map((q) => ({ question: q, step: s }))
      )
    );
  }

  @computed
  get question() {
    return this._question;
  }

  @computed
  get variableResolver() {
    return new DefaultOnboardingVariableResolver(this._configId);
  }

  @action
  setQuestionByName(name: string) {
    const info = this.availableQuestions.find((info) => info.question.templateName === name);
    this._question = info?.question;
    this._editableFile.url =
      info == null ? '' : `process://${this._configId}/${info.step.templateName}/${info.question.templateName}`;
  }
}

export class AppImportSessionFileProcessLinkDialogViewModel implements ImportSessionFileProcessLinkDialogViewModel {
  private readonly _editableSession: EditableImportSession;
  private readonly _editableFile: EditableSourceFile;
  private readonly _originalUrl: string;
  @observable private _isExecuting = false;
  @observable private _error: Error | undefined;

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _onboardingStore: OnboardingStore,
    private readonly _configId: string,
    session: ImportSession,
    sourceFileLabel: string,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._editableSession = new EditableImportSession(session);
    const editableFile = this._editableSession.expectedFiles.find((f) => f.label === sourceFileLabel);

    if (editableFile == null) {
      throw new Error('Cannot find a file with that label.');
    }

    this._editableFile = editableFile;
    this._originalUrl = editableFile.url;
  }

  @computed
  get data() {
    return fromPromise(this.loadData());
  }

  @computed
  get canSave() {
    return this._editableFile.hasChanges;
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @computed
  get hasError() {
    return this._error != null;
  }

  @action
  async save() {
    if (!this._editableFile.hasChanges) {
      return;
    }

    this._isExecuting = true;
    this._error = undefined;

    try {
      // We need to keep the original url in previous urls.
      if (this._originalUrl.length > 0) {
        this._editableFile.addPreviousUrl(this._originalUrl);
      }

      // We simply need to save the session.
      await this._importSessionStore.createOrUpdateImportSession(this._editableSession, false);

      runInAction(() => {
        this._isExecuting = false;
        this._error = undefined;
      });

      this._onSuccess();
    } catch (error) {
      runInAction(() => {
        this._isExecuting = false;
        this._error = error as Error;
      });
    }
  }

  cancel() {
    this._onCancel();
  }

  private async loadData(): Promise<ImportSessionFileProcessLinkViewModel> {
    const processes = await this._onboardingStore.getProcesses(this._configId);
    const stepNames = _.chain(processes.map((p) => p.steps.map((s) => s.templateName)))
      .flatten()
      .uniq()
      .value();
    const steps = await Promise.all(stepNames.map((name) => this._onboardingStore.getStep(name, this._configId)));

    let selectedQuestion: OnboardingQuestion | undefined = undefined;

    if (this._editableFile.url.startsWith('process://')) {
      const parts = this._editableFile.url.substring(10).split('/');

      if (parts.length == 3) {
        if (parts[0] === this._configId) {
          const step = steps.find((s) => s.templateName === parts[1]);

          if (step != null) {
            selectedQuestion = step.questions.find((q) => q.templateName === parts[2]);
          } else {
            console.warn('Unknown step name in process question url');
          }
        } else {
          console.warn('Process question url points to a different configuration');
        }
      } else {
        console.warn('Invalid process question url');
      }
    }

    return new AppImportSessionFileProcessLinkViewModel(
      this._configId,
      this._editableSession,
      this._editableFile,
      steps,
      selectedQuestion
    );
  }
}
