import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_sky_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableValuePropertyEx } from '../editables';
import { ContentIcon, ContentWorkloadLevel } from '../types';
import { protobufFromContentIcon, protobufFromContentWorkloadLevel } from '../types/EnumConversion';
import { GrpcTeamsContentRepresentation } from './GrpcTeamsContentRepresentation';
import { BlackbaudSkyContentRepresentationModel, TeamsContentRepresentationModel } from './interfaces';

export class EditableBlackbaudSkyContentRepresentation
  extends EditableModelEx<BC.ContentRepresentation>
  implements BlackbaudSkyContentRepresentationModel
{
  private _icon: EditableValuePropertyEx<ContentIcon, BC.ContentRepresentation>;
  private _workloadLevel: EditableValuePropertyEx<ContentWorkloadLevel, BC.ContentRepresentation>;

  static createNew(
    icon: ContentIcon = 'homework',
    workloadLevel: ContentWorkloadLevel = 'regular'
  ): EditableBlackbaudSkyContentRepresentation {
    const pb = new BC.ContentRepresentation();
    pb.icon = protobufFromContentIcon(icon);
    pb.workloadLevel = protobufFromContentWorkloadLevel(workloadLevel);

    return new EditableBlackbaudSkyContentRepresentation(new GrpcTeamsContentRepresentation(pb), true);
  }

  constructor(
    readonly originalRepresentation: TeamsContentRepresentationModel,
    readonly isNew = false
  ) {
    super(originalRepresentation.toProtobuf(), isNew);
    makeObservable(this);

    this._icon = this.addValueField(
      originalRepresentation.icon,
      (pb, value) => (pb.icon = protobufFromContentIcon(value))
    );
    this._workloadLevel = this.addValueField(
      originalRepresentation.workloadLevel,
      (pb, value) => (pb.workloadLevel = protobufFromContentWorkloadLevel(value))
    );
  }

  @computed
  get icon() {
    return this._icon.value;
  }

  set icon(value: ContentIcon) {
    this._icon.value = value;
  }

  @computed
  get workloadLevel() {
    return this._workloadLevel.value;
  }

  set workloadLevel(value: ContentWorkloadLevel) {
    this._workloadLevel.value = value;
  }
}
