import { RouteParamNames, RouteTemplates } from '@insights/Routes';
import { SectionInfo } from '@insights/models';
import { SxProps, Tooltip, Typography } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { cleanDiacritics } from '../utils';
import { RouterLink } from './RouterLink';
import { Column, Container, Row } from './layout';

export interface SectionListTeachersCellProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  sectionInfo: SectionInfo;
}

// This is the first step into unifying the common parts of SectionList and EditableSectionList.
// It will be done on a case by case situation.
export const SectionListTeachersCell = observer(
  ({ sx = [], className, style, configId, sectionInfo }: SectionListTeachersCellProps) => {
    const { localizationService, reactRouterRouteService } = useInsightsServices();
    const insightsStrings = localizationService.localizedStrings.insights;
    const sectionsStrings = insightsStrings.components.sections;

    const sortedTeachers = _.sortBy(
      (sectionInfo.teachers ?? []).map((teacherInfo) => ({
        teacher: teacherInfo,
        displayName: AccountUtils.getDisplayLastFirstName(teacherInfo.account, insightsStrings.noName)
      })),
      (teacherInfo) => [cleanDiacritics(teacherInfo.displayName).toLowerCase()]
    );
    const moreCount = sortedTeachers.length > 3 ? sortedTeachers.length - 2 : 0;
    const visibleTeachers = moreCount > 0 ? _.take(sortedTeachers, 2) : sortedTeachers;
    const hiddenTeachers = moreCount > 0 ? _.drop(sortedTeachers, 2) : [];

    return (
      <Container sx={{ ...sx, my: 0.5, minHeight: 30 }} className={className} style={style}>
        <Row verticalContentAlignment="center">
          <Column>
            {visibleTeachers.map((teacherInfo) => (
              <RouterLink
                key={`${sectionInfo.id}-${teacherInfo.teacher.account.id}`}
                variant="body1"
                to={reactRouterRouteService.resolveLocation(RouteTemplates.teacherDetails, [
                  { name: RouteParamNames.configId, value: configId },
                  {
                    name: RouteParamNames.teacherId,
                    value: teacherInfo.teacher.account.id
                  }
                ])}
              >
                {teacherInfo.displayName}
              </RouterLink>
            ))}
            {hiddenTeachers.length > 0 && (
              <Tooltip
                title={
                  <Column>
                    {hiddenTeachers.map((teacherInfo) => (
                      <Typography key={`hidden-teacher-${teacherInfo.teacher.id}`}>
                        {teacherInfo.displayName}
                      </Typography>
                    ))}
                  </Column>
                }
              >
                <Typography
                  sx={{
                    fontStyle: 'italic'
                  }}
                >
                  {sectionsStrings.moreTeachers(moreCount)}
                </Typography>
              </Tooltip>
            )}
          </Column>
        </Row>
      </Container>
    );
  }
);
