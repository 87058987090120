import { SchoolCalendarImportFromViewModel } from '@insights/viewmodels';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { InsightsButton } from '../InsightsButton';
import { ImportFromConfiguration } from './ImportFromConfiguration';

export interface SchoolCalendarImportFromDialogProps {
  viewModel: SchoolCalendarImportFromViewModel;
}

export const SchoolCalendarImportFromDialog = observer((props: SchoolCalendarImportFromDialogProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel } = props;

  if (!viewModel.isActive) {
    return <div />;
  }

  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Dialog open={true} onClose={() => viewModel.close()} maxWidth="sm" fullWidth={true}>
      <DialogTitle>{strings.importFromSchoolTitle}</DialogTitle>
      <DialogContent>
        <ImportFromConfiguration viewModel={viewModel} />
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <InsightsButton sx={{ m: 1 }} onClick={() => viewModel.close()}>
            {strings.cancel}
          </InsightsButton>
          <InsightsButton
            sx={{ m: 1 }}
            isDefault
            isDisabled={!viewModel.canImportData}
            onClick={() => viewModel.importData()}
          >
            {strings.import}
          </InsightsButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
});
