import {
  AccountSectionsEdition,
  Column,
  Container,
  Expanded,
  InsightsButton,
  ObservableActionsPresenter,
  ObservablePresenter,
  Row,
  Scroll,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Dialog, DialogActions, DialogContent, SxProps, ThemeProvider, Typography, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface AccountSectionsEditionDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  accountId: string;
}

export const AccountSectionsEditionDialog = observer((props: AccountSectionsEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], accountId, className, style, configId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.account;

  // This view consumes the same viewModel as AccountEditionDialog
  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createAccountEdition(configId, accountId, [], onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="xl"
        fullWidth={true}
        PaperProps={{ sx: { height: '100vh' } }}
        onClose={() => void viewModel.close()}
      >
        <DialogContent>
          <ObservablePresenter
            data={viewModel.data}
            render={(data) => (
              <Column>
                <Expanded>
                  <Scroll
                    sx={{
                      // This is to reset the bottom margin of the last card
                      '& >:last-child': {
                        marginBottom: 2
                      }
                    }}
                    direction="y"
                  >
                    <AccountSectionsEdition viewModel={data} />
                  </Scroll>
                </Expanded>
              </Column>
            )}
          />
        </DialogContent>

        <DialogActions>
          <ObservableActionsPresenter
            data={viewModel.data}
            render={(data) => (
              <Expanded>
                <Row verticalContentAlignment="center" horizontalContentAlignment="right">
                  <InsightsButton sx={{ m: 1 }} onClick={() => void data.copyStudentSections()}>
                    {strings.copySelectedSectionsButtonText}
                  </InsightsButton>

                  <Expanded />

                  <Container sx={{ m: 1 }}>
                    <Column horizontalContentAlignment="left">
                      {data.messages.map((m) => (
                        <Typography key={m} variant="caption" sx={{ color: (theme) => theme.palette.error.main }}>
                          {m}
                        </Typography>
                      ))}
                    </Column>
                  </Container>

                  <InsightsButton sx={{ m: 1 }} onClick={() => void viewModel.close()}>
                    {strings.cancel}
                  </InsightsButton>
                  <InsightsButton sx={{ m: 1 }} isDefault onClick={() => void data.apply()} isDisabled={!data.canSave}>
                    {strings.save}
                  </InsightsButton>
                </Row>
              </Expanded>
            )}
            renderError={() => <InsightsButton onClick={() => void viewModel.close()}>{strings.close}</InsightsButton>}
          />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
