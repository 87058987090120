import { AutomatedTransformationImportViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  IconButton,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface AcceptedIncidentListProps {
  sx?: SxProps;
  className?: string;
  viewModel: AutomatedTransformationImportViewModel;
}

export const AcceptedIncidentList = observer(({ sx = [], className, viewModel }: AcceptedIncidentListProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <Table sx={sx} className={className}>
      <TableHead>
        <TableRow>
          <TableCell>{strings.incidentCodeColumn}</TableCell>
          <TableCell>{strings.incidentMinCountColumn}</TableCell>
          <TableCell>{strings.incidentMaxCountColumn}</TableCell>
          <TableCell>{strings.incidentTextColumn}</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {viewModel.transformationImport.incidentTolerances.map((t, index) => (
          <TableRow key={`incident-tolerance-${index}`}>
            <TableCell>
              <Autocomplete
                sx={{ minWidth: 200 }}
                fullWidth
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={viewModel.incidents}
                getOptionLabel={(v) => (typeof v === 'string' ? v : `${v.code}: ${v.message}`)}
                value={t.code}
                onChange={(_, v) => (t.code = typeof v === 'string' ? v : (v?.code ?? ''))}
                onInputChange={(_, v) => {
                  const match = viewModel.incidents.find((i) => i.code === v);

                  if (match != null) {
                    t.code = match.code;
                  }
                }}
                renderInput={(p) => <TextField {...p} />}
              />
            </TableCell>
            <TableCell>
              <TextField
                type="number"
                value={t.minimumCount}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (!Number.isNaN(value)) {
                    t.minimumCount = value;
                  }
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                type="number"
                value={t.maximumCount}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (!Number.isNaN(value)) {
                    t.maximumCount = value;
                  }
                }}
              />
            </TableCell>
            <TableCell>
              <TextField value={t.incidentText} onChange={(e) => (t.incidentText = e.target.value)} />
            </TableCell>
            <TableCell>
              <IconButton onClick={() => viewModel.deleteIncidentTolerance(t)}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={4} />
          <TableCell>
            <IconButton onClick={() => viewModel.addIncidentTolerance()}>
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
});
