import * as MB from '@buf/studyo_studyo.bufbuild_es/studyo/type_managebac_pb';
import { BaseModel } from '../Model';
import { contentIconFromProtobuf, contentWorkloadLevelFromProtobuf } from '../types/EnumConversion';
import { ManageBacContentRepresentationModel } from './interfaces';

export class GrpcManageBacContentRepresentation
  extends BaseModel<MB.ContentRepresentation>
  implements ManageBacContentRepresentationModel
{
  constructor(pb: MB.ContentRepresentation) {
    super(pb);
  }

  get icon() {
    return contentIconFromProtobuf(this._pb.icon);
  }

  get workloadLevel() {
    return contentWorkloadLevelFromProtobuf(this._pb.workloadLevel);
  }
}
