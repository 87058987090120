import { Label } from '@insights/components';
import { SchoolYearConfigurationScreenInfo } from '@insights/viewmodels';
import { Stack, SxProps, Typography } from '@mui/material';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SchoolYearConfigurationDatesProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  data: SchoolYearConfigurationScreenInfo;
}

export const SchoolYearConfigurationDates = observer((props: SchoolYearConfigurationDatesProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], style, data } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;

  return (
    <Stack sx={sx} className={className} style={style} spacing={2}>
      {/* Start Date */}
      <Stack>
        <Label>{strings.startDateFieldLabel}</Label>
        <Typography>
          {data.schoolYearConfiguration.startDay.formattedString(
            localizationService.localizedStrings.models.dateFormats.mediumUnabridged
          )}
        </Typography>
      </Stack>
      {/* End Date */}
      <Stack>
        <Label>{strings.endDateFieldLabel}</Label>
        <Typography>
          {data.schoolYearConfiguration.endDay.formattedString(
            localizationService.localizedStrings.models.dateFormats.mediumUnabridged
          )}
        </Typography>
      </Stack>
      {/* Teacher Access Date */}
      {data.schoolYearConfiguration.teacherAvailabilityDate != null && (
        <Stack>
          <Label>{strings.teachersAccessDate}</Label>
          <Typography>
            {format(
              data.schoolYearConfiguration.teacherAvailabilityDate,
              localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime
            )}
          </Typography>
        </Stack>
      )}
      {/* Student Access Date */}
      {data.schoolYearConfiguration.studentAvailabilityDate != null && (
        <Stack>
          <Label>{strings.studentsAccessDate}</Label>
          <Typography>
            {format(
              data.schoolYearConfiguration.studentAvailabilityDate,
              localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime
            )}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
});
