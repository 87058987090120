import { MultipleValues } from '@insights/viewmodels';
import { Box, Button, InputLabel, SxProps, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, ReactElement, useState } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface MultipleValuesControlProps<T> {
  /**
   * The field control value.
   */
  value: T | MultipleValues;
  /**
   * The value to use when rendering the control when the `value` is `MultipleValues`.
   */
  defaultValue: T;
  /**
   * Indicates to disable the MultipleValues button. Default is false.
   */
  disabled?: boolean;
  /**
   * The label to display with the MultipleValues button. Optional.
   */
  label?: string;
  style?: CSSProperties;
  className?: string;
  sx?: SxProps;

  /**
   * Render the controlled field
   * @param value The value to render the field with.
   */
  renderControl: (value: T) => ReactElement;
  /**
   * Render the MultipleValues button. If the function is not specified, the `defaultRender` will be used.
   * @param defaultRender The default MultipleValues button render
   */
  renderMultipleValues?: (defaultRender: () => ReactElement) => ReactElement;
}

export const MultipleValuesControl = observer(
  <T,>({
    sx = [],
    value,
    defaultValue,
    disabled,
    label,
    style,
    className,
    renderControl,
    renderMultipleValues
  }: MultipleValuesControlProps<T>) => {
    const { localizationService } = useInsightsServices();
    const theme = useTheme();
    const [showMultipleValues, setShowMultipleValues] = useState(true);
    const strings = localizationService.localizedStrings.insights.components;

    if (showMultipleValues && value === 'multiple-values') {
      const defaultRender = () => (
        <Box sx={sx} style={style} className={className}>
          {label && (
            <InputLabel disabled={disabled} sx={{ ...theme.typography.caption }}>
              {label}
            </InputLabel>
          )}
          <Button sx={{ fontWeight: 400 }} disabled={disabled} onClick={() => setShowMultipleValues(false)}>
            {strings.multipleValues}
          </Button>
        </Box>
      );

      return renderMultipleValues?.(defaultRender) ?? defaultRender();
    }

    return renderControl(value === 'multiple-values' ? defaultValue : value);
  }
);
