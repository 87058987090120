import { ExternalSection as PBExternalSection } from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import { externalAccountKindFromProtobuf } from '../types/EnumConversion';
import { ExternalSection } from './interfaces/ExternalSection';

export class GrpcExternalSection implements ExternalSection {
  constructor(private readonly pb: PBExternalSection) {}

  get id() {
    return this.pb.id;
  }

  get kind() {
    return externalAccountKindFromProtobuf(this.pb.kind);
  }

  get title() {
    return this.pb.title;
  }

  get code() {
    return this.pb.code;
  }

  get group() {
    return this.pb.group;
  }

  get level() {
    return this.pb.level;
  }

  get term() {
    return this.pb.term;
  }

  get extraData(): string {
    return this.pb.extraData;
  }
}
