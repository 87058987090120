import { css } from '@emotion/css';
import { Stack, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers';
import { EditableOnboardingAnswer, OnboardingAnswer } from '@shared/models/onboarding';
import { formatDate, startOfSecond } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';
import { LastAnswerTooltip } from './LastAnswerTooltip.tsx';

export interface DateQuestionProps {
  sx?: SxProps;
  className?: string;
  answer: EditableOnboardingAnswer;
  lastYearAnswer?: OnboardingAnswer;
  disabled?: boolean;
}

export const DateQuestion = observer((props: DateQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], answer, lastYearAnswer, disabled } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const format = localizationService.localizedStrings.models.dateFormats.mediumUnabridged;

  const normalClassName = css({ fontWeight: '400' });
  const disabledClassName = css({ fontWeight: '400', color: grey[500] });

  // Note on the "startOf('s')" below: When navigating in the DatePicker's dialog with
  // the keyboard to select a day, the returned value contains fractions of a second, which
  // later throws an assertion error with protobuf's Timestamp, but only when actually
  // sending that value on the wire via a gRPC service. Since it is initially a quirk of
  // the DatePicker, I prefer to have the fix here.
  return (
    <Stack sx={sx} className={className} direction="row" spacing={2}>
      <DatePicker
        disabled={disabled}
        slotProps={{
          textField: {
            InputProps: {
              classes: { disabled: disabledClassName, root: normalClassName }
            },
            placeholder: strings.noneLabel
          },
          actionBar: {
            actions: ['clear', 'cancel', 'accept']
          }
        }}
        localeText={{
          okButtonLabel: strings.okLabel,
          cancelButtonLabel: strings.cancelLabel,
          clearButtonLabel: strings.clearLabel
        }}
        format={format}
        value={answer.dateValue ?? null}
        onChange={(e) => (answer.dateValue = e != null ? startOfSecond(e) : undefined)}
      />
      {lastYearAnswer?.dateValue != null && <LastAnswerTooltip answer={formatDate(lastYearAnswer.dateValue, format)} />}
    </Stack>
  );
});
