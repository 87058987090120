import * as GPB from '@buf/studyo_studyo.bufbuild_es/studyo/services/generator_pb';
import { SchoolYearConfiguration, Section } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { IcsConfiguration, SchoolDay } from '@buf/studyo_studyo.bufbuild_es/studyo/type_generator_pb';
import { Generator } from '@buf/studyo_studyo.connectrpc_es/studyo/services/generator_connect';
import { SymmetricBoolMatrix } from '@shared/models/SymmetricBoolMatrix';
import { GeneratorTransport, IcsUrls, SyncTokenResult } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class GeneratorGrpcTransport extends BaseGrpcTransport implements GeneratorTransport {
  async fetchSchoolDays(
    configId: string,
    sectionIds: string[],
    preferredScheduleTag?: string,
    syncToken?: string
  ): Promise<SyncTokenResult<SchoolDay[]>> {
    console.log(`Fetching generated calendar with parameters: [configId: ${configId}]...`);

    const request = new GPB.GetConfigGeneratedCalendarRequest({
      configId,
      sectionIds,
      preferredScheduleTag: preferredScheduleTag ?? '',
      syncToken: syncToken ?? ''
    });

    const response = await this.performRequest(Generator, Generator.methods.getConfigGeneratedCalendar, request);

    console.log(`Successfully fetched generated calendar with parameters: [configId: ${configId}].`);

    return {
      result: response.schoolDays,
      syncToken: response.syncToken
    };
  }

  async fetchVolatileSchoolDays(
    config: SchoolYearConfiguration,
    sections: Section[],
    preferredScheduleTag?: string
  ): Promise<SchoolDay[]> {
    console.log(`Fetching volatile calendar from SchoolYearConfiguration...`);

    const request = new GPB.GenerateCalendarFromVolatileConfigRequest({
      config,
      sections,
      preferredScheduleTag: preferredScheduleTag ?? ''
    });

    const response = await this.performRequest(
      Generator,
      Generator.methods.generateCalendarFromVolatileConfig,
      request
    );

    console.log(`Successfully fetched volatile calendar.`);

    return response.schoolDays;
  }

  async fetchConfigIcsUrls(configId: string, accountId: string, ignoreFreePeriods: boolean) {
    console.log(`Fetching config ics URL for account ${accountId}`);

    const request = new GPB.GetPublicIcsUrlRequest();
    const icsConfig = new IcsConfiguration();
    icsConfig.configId = configId;
    icsConfig.accountId = accountId;
    icsConfig.ignoreFreePeriods = ignoreFreePeriods;

    request.icsConfiguration = icsConfig;

    const response = await this.performRequest(Generator, Generator.methods.getPublicIcsUrl, request);

    const icsUrlsReturningResponse: IcsUrls = {
      httpUrl: response.publicHttpsUrl,
      webcalUrl: response.publicWebcalUrl
    };

    console.log(`successfully fetched ICS URL!`);
    return icsUrlsReturningResponse;
  }

  async fetchConfigSectionSchedulingConflicts(
    configId: string,
    syncToken?: string
  ): Promise<SyncTokenResult<SymmetricBoolMatrix | undefined>> {
    console.log(`Fetching section scheduling conflicts for config ${configId}...`);

    const request = new GPB.GetConfigSectionSchedulingConflictsRequest();
    request.configId = configId;

    if (syncToken != null) {
      request.syncToken = syncToken;
    }

    const response = await this.performRequest(
      Generator,
      Generator.methods.getConfigSectionSchedulingConflicts,
      request
    );

    console.log(`Successfully fetched section scheduling conflicts.`);

    return {
      result: new SymmetricBoolMatrix(response.sectionIds, response.symmetricBoolMatrix),
      syncToken: response.syncToken
    };
  }
}
