import { Autocomplete, Box, Chip, Stack, SxProps, TextField, Typography } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AccountModel } from '@shared/models/config';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';

export interface SelectOnboardingMultiOwnershipProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  label: string;
  selectedAccounts: AccountModel[];
  availableAccounts: AccountModel[];
  onSelection: (accountIds: string[]) => void;
}

export const SelectOnboardingMultiOwnership = observer((props: SelectOnboardingMultiOwnershipProps) => {
  const { sx = [], className, style, label, selectedAccounts, availableAccounts, onSelection } = props;

  return (
    <Box sx={sx} className={className} style={style}>
      <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.secondary }}>
        {label}
      </Typography>
      <Autocomplete
        multiple
        limitTags={2}
        value={selectedAccounts.map((a) => ({
          id: a.id,
          label: AccountUtils.getDisplayLastFirstName(a)
        }))}
        options={availableAccounts.map((a) => ({
          id: a.id,
          label: AccountUtils.getDisplayLastFirstName(a)
        }))}
        isOptionEqualToValue={(option, value) => option.id == value.id}
        renderInput={(params) => <TextField {...params} />}
        onChange={(_, values) => onSelection(values.map((v) => v.id))}
        size="small"
        renderTags={(values, getTagProps) => (
          <Stack direction="row">
            {values.map((v, index) => (
              <Chip {...getTagProps({ index })} key={v.id} label={v.label} size="small" />
            ))}
          </Stack>
        )}
      />
    </Box>
  );
});
