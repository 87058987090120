import { ProfileMenuImages } from '@shared/resources/images/studyo';
import configureSchoolImage from '@shared/web/images/assets/studyo/ui_configure_school.svg';
import impersonateSelectionImage from '@shared/web/images/assets/studyo/ui_impersonate_selection.svg';
import impersonatingImage from '@shared/web/images/assets/studyo/ui_impersonating.svg';
import preferencesImage from '@shared/web/images/assets/studyo/ui_preferences.svg';
import schoolCodeImage from '@shared/web/images/assets/studyo/ui_school_code.svg';
import schoolLinksImage from '@shared/web/images/assets/studyo/ui_school_links.svg';
import selectSectionsImage from '@shared/web/images/assets/studyo/ui_select_sections.svg';
import shareConfigImage from '@shared/web/images/assets/studyo/ui_share_config.svg';
import supportImage from '@shared/web/images/assets/studyo/ui_support.svg';
import syncImage from '@shared/web/images/assets/studyo/ui_sync.svg';

export class WebProfileMenuImages implements ProfileMenuImages {
  get configureSchool() {
    return configureSchoolImage;
  }

  get impersonateSelection() {
    return impersonateSelectionImage;
  }

  get impersonatingIndicator() {
    return impersonatingImage;
  }

  get preferences() {
    return preferencesImage;
  }

  get schoolLinks() {
    return schoolLinksImage;
  }

  get sectionSelection() {
    return selectSectionsImage;
  }

  get shareConfig() {
    return shareConfigImage;
  }

  get support() {
    return supportImage;
  }

  get synchronize() {
    return syncImage;
  }

  get useSchoolCode() {
    return schoolCodeImage;
  }
}
