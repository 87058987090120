import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { Memoize } from 'fast-typescript-memoize';
import { BaseModel } from '../../Model';
import { onboardingOperationKindFromProtobuf } from '../../types/EnumConversion';
import { OnboardingHistoryEntry } from '../interfaces';

export class GrpcOnboardingHistoryEntry extends BaseModel<PB.OnboardingHistoryEntry> implements OnboardingHistoryEntry {
  constructor(pb: PB.OnboardingHistoryEntry) {
    super(pb);
  }

  get configId() {
    return this._pb.configId;
  }

  get processId() {
    return this._pb.elementId.case === 'processId' ? this._pb.elementId.value : undefined;
  }

  get stepId() {
    return this._pb.elementId.case === 'stepId' ? this._pb.elementId.value : undefined;
  }

  get questionId() {
    return this._pb.elementId.case === 'questionId' ? this._pb.elementId.value : undefined;
  }

  get templateName() {
    return this._pb.templateName;
  }

  get kind() {
    return onboardingOperationKindFromProtobuf(this._pb.kind);
  }

  get userId() {
    return this._pb.userId;
  }

  get data() {
    return this._pb.data;
  }

  get description() {
    return this._pb.readableDescription;
  }

  @Memoize()
  get createdAt() {
    return this._pb.createdAt?.toDate() ?? new Date();
  }
}
