import { AccountInfo, EditableAccountInfo } from '@insights/models';
import { AccountModel } from '@shared/models/config';
import { Locale } from '@shared/resources/services';
import { caseInsensitiveAccentInsensitiveCompare } from './StringUtils';

export const AccountInfoUtils = {
  compareNames: (a: AccountInfo | EditableAccountInfo, b: AccountInfo | EditableAccountInfo, locale?: Locale) =>
    compareNames(a.account, b.account, locale),

  compareEmails: (a: AccountInfo | EditableAccountInfo, b: AccountInfo | EditableAccountInfo) =>
    a.account.email.localeCompare(b.account.email),

  compareGrades: (a: AccountInfo | EditableAccountInfo, b: AccountInfo | EditableAccountInfo, locale?: Locale) =>
    a.account.gradeLevel.localeCompare(b.account.gradeLevel, locale, { numeric: true })
};

function compareNames(a: AccountModel, b: AccountModel, locale?: Locale): number {
  return (
    caseInsensitiveAccentInsensitiveCompare(a.lastName.trim(), b.lastName.trim(), locale) ||
    caseInsensitiveAccentInsensitiveCompare(a.firstName.trim(), b.firstName.trim(), locale) ||
    a.email.localeCompare(b.email, locale, { sensitivity: 'base' })
  );
}
