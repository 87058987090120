import * as C from '@buf/studyo_studyo.bufbuild_es/studyo/type_canvas_pb';
import { EditableCanvasAssignmentGroupMapping } from '@shared/models/connectors/EditableCanvasAssignmentGroupMapping';
import { CanvasExternalAccountDetails } from '@shared/models/connectors/interfaces';
import { CanvasAssignmentGroupMappingModel } from '@shared/models/connectors/interfaces/CanvasAssignmentGroupMappingModel';
import { FullyEditableListProperty } from '@shared/models/editables';
import { LocalizationService } from '@shared/resources/services';
import { CanvasConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface CanvasAccountSettingsViewModel extends ExternalAccountEditionViewModel {
  isIgnoringUnmappedGroups: boolean;

  readonly assignmentGroupMappings: EditableCanvasAssignmentGroupMapping[];
  readonly availableGroupNames: string[];
  addAssignmentGroupMapping(groupName: string): void;
}

export class AppCanvasAccountSettingsViewModel
  extends BaseExternalAccountEditionViewModel
  implements CanvasAccountSettingsViewModel
{
  @observable private _isIgnoringUnmappedGroups?: boolean;

  // We use an editable field to hold changes, but it doesn't have a host to apply to.
  private _mappings: FullyEditableListProperty<
    C.AssignmentGroupMapping,
    CanvasAssignmentGroupMappingModel,
    EditableCanvasAssignmentGroupMapping,
    unknown
  >;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _canvasStore: CanvasConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    public readonly availableGroupNames: string[],
    private readonly _originalDetails: CanvasExternalAccountDetails
  ) {
    super();

    makeObservable(this);

    this._mappings = new FullyEditableListProperty(
      _originalDetails.mappings.map((m) => new EditableCanvasAssignmentGroupMapping(m)),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }

  @computed
  get isIgnoringUnmappedGroups() {
    return this._isIgnoringUnmappedGroups ?? this._originalDetails.isIgnoringUnmappedGroups;
  }

  set isIgnoringUnmappedGroups(value: boolean) {
    this._isIgnoringUnmappedGroups = value;
    this.onChange();
  }

  @computed
  get assignmentGroupMappings() {
    return this._mappings.values;
  }

  @action
  addAssignmentGroupMapping(groupName: string) {
    this._mappings.addItem(EditableCanvasAssignmentGroupMapping.createNew(groupName));
  }

  get hasChanges() {
    return this._hasChanges || this._mappings.isChanged;
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    // We allow applying even when there are no changes, because it's a two-step edition.
    if (!this.hasChanges) {
      this._onSuccess();
      return;
    }

    this.beginApplying();

    try {
      await this._canvasStore.updateCanvasAccountSettings(
        this._configId,
        this._externalAccountId,
        this._originalDetails.subAccountId,
        this._originalDetails.termIds,
        this._originalDetails.isShowingSections,
        this._originalDetails.requiredCourseCodePrefix,
        this.assignmentGroupMappings,
        this.isIgnoringUnmappedGroups
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._mappings.reset();
    this._isIgnoringUnmappedGroups = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this.hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
