import { ActiveUsers, ObservablePresenter } from '@insights/components';
import { PeriodDisplay } from '@insights/enums';
import { ActiveTeachersViewModel } from '@insights/viewmodels';
import { Box, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { MinMediumChartHeight, TeachersColor, TeachersLightColor } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ActiveTeachersProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: ActiveTeachersViewModel;
}

export const ActiveTeachers = observer((props: ActiveTeachersProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.teachers;

  const periodCaption = useMemo(() => {
    switch (viewModel.periodDisplay) {
      case PeriodDisplay.Daily:
        return strings.dailyActiveTeachers;

      case PeriodDisplay.Weekly:
        return strings.weeklyActiveTeachers;

      case PeriodDisplay.Monthly:
        return strings.monthlyActiveTeachers;

      default:
        return '';
    }
  }, [viewModel.periodDisplay, localizationService.currentLocale]);

  const periodSubcaption = useMemo(() => {
    switch (viewModel.periodDisplay) {
      case PeriodDisplay.Daily:
        return strings.numberOfDailyActiveTeachers;

      case PeriodDisplay.Weekly:
        return strings.numberOfWeeklyActiveTeachers;

      case PeriodDisplay.Monthly:
        return strings.numberOfMonthlyActiveTeachers;

      default:
        return '';
    }
  }, [viewModel.periodDisplay, localizationService.currentLocale]);

  return (
    <Box sx={{ ...sx, height: MinMediumChartHeight, minWidth: 600 }} className={className} style={style}>
      <ObservablePresenter
        sx={{ height: '100%', width: '100%' }}
        data={viewModel.data}
        loadingMessage={strings.loadingActiveTeachersMessage}
        errorMessage={strings.loadingActiveTeachersErrorMessage}
        render={(data) => {
          if (!data.hasSchoolStarted) {
            return (
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <Typography variant="h5">{strings.schoolNotStartedMessage}</Typography>
              </Box>
            );
          }

          if (data.activeTeachersAny.length === 0 && data.activeTeachersPublished.length === 0) {
            return (
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <Typography variant="h5">{strings.noActiveTeachers}</Typography>
              </Box>
            );
          }

          return (
            <ActiveUsers
              caption={periodCaption}
              subcaption={periodSubcaption}
              interactedUsersData={data.activeTeachersPublished}
              interactedUsersTooltipSuffix={strings.publishedTaskTooltip}
              interactedUsersColor={TeachersColor}
              interactedUsersLegendTitle={strings.publishedTaskTitle}
              anyUsersData={data.activeTeachersAny}
              anyUsersTooltipSuffix={strings.openedStudyoTooltip}
              anyUsersColor={TeachersLightColor}
              anyUsersLegendTitle={strings.openedStudyoTitle}
              periodDisplay={viewModel.periodDisplay}
              maximumValue={data.configGlobalStats.accounts.teacherClaimed}
            />
          );
        }}
      />
    </Box>
  );
});
