import { CardActionArea, SxProps, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';

export interface TopIndicatorProps {
  sx?: SxProps;
  className?: string;
  message: string;
  backgroundColor: string;
  display: boolean;
  onPress?: () => void;
}

export const TopIndicator = observer((props: TopIndicatorProps) => {
  const { backgroundColor, onPress, sx = [], className, display, message } = props;
  const theme = useTheme();

  return (
    <CardActionArea
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        height: !display ? 0 : undefined,
        backgroundColor,
        color: theme.palette.getContrastText(backgroundColor),
        px: display ? 2 : 0,
        py: display ? 1 : 0
      }}
      className={className}
      onClick={() => onPress?.()}
      disabled={onPress == null}
    >
      <Typography
        sx={{
          flex: 1,
          textAlign: 'center',
          color: 'inherit',
          userSelect: 'none'
        }}
      >
        {message}
      </Typography>
    </CardActionArea>
  );
});
