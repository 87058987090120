import { AlertService } from '@insights/services';
import { OnboardingHistoryEntry } from '@shared/models/onboarding/interfaces';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface OnboardingHistoryViewModel {
  readonly entries: OnboardingHistoryEntry[];
}

export interface OnboardingHistoryDialogViewModel {
  readonly data: IPromiseBasedObservable<OnboardingHistoryViewModel>;

  close(): void;
}

class AppOnboardingHistoryViewModel implements OnboardingHistoryViewModel {
  constructor(readonly entries: OnboardingHistoryEntry[]) {}
}

export class AppOnboardingHistoryDialogViewModel implements OnboardingHistoryDialogViewModel {
  @observable private _innerViewModel: OnboardingHistoryViewModel | undefined;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _onSuccess: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<OnboardingHistoryViewModel> {
    return fromPromise(this.loadData());
  }

  close(): void {
    this._onSuccess();
  }

  private async loadData(): Promise<OnboardingHistoryViewModel> {
    const entries = await this._onboardingStore.getSchoolHistory(
      this._configId,
      this._localizationService.currentLocale
    );

    return runInAction(() => (this._innerViewModel = new AppOnboardingHistoryViewModel(entries)));
  }
}
