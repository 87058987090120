import { ExternalGradingCondition } from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import {
  ContentDefinition_ContentIcon,
  ContentDefinition_WorkloadLevel
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { ExternalContentMappingsViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowIcon from '@mui/icons-material/ForwardOutlined';
import {
  IconButton,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { AllBufContentIcons, AllBufContentWorkloadLevels, AllExternalGradingConditions } from '@shared/models/types';
import { contentIconFromProtobuf, contentWorkloadLevelFromProtobuf } from '@shared/models/types/EnumConversion';
import { observer } from 'mobx-react-lite';

export interface ExternalContentMappingKeywordsProps {
  sx?: SxProps;
  className?: string;
  viewModel: ExternalContentMappingsViewModel;
}

export const ExternalContentMappingKeywords = observer(
  ({ sx = [], className, viewModel }: ExternalContentMappingKeywordsProps) => {
    const { localizationService } = useInsightsServices();
    const strings = localizationService.localizedStrings.insights.components.connectors;
    const contentsStrings = localizationService.localizedStrings.models.contents;

    return (
      <Table sx={sx} className={className}>
        <TableHead>
          <TableCell width="25%">{strings.keywordTitle}</TableCell>
          <TableCell width="20%">{strings.gradingConditionTitle}</TableCell>
          <TableCell width="25%">{strings.iconTitle}</TableCell>
          <TableCell width="20%">{strings.workloadLevelTitle}</TableCell>
          <TableCell width="10%" />
        </TableHead>
        {viewModel.keywords.length > 0 && (
          <TableBody>
            {viewModel.keywords.map((m, i) => (
              <TableRow key={`keyword-row-${i}`}>
                <TableCell>
                  <TextField fullWidth value={m.keyword} onChange={(event) => (m.keyword = event.target.value)} />
                </TableCell>
                <TableCell>
                  <Stack direction="row">
                    <Select
                      fullWidth
                      value={m.gradingCondition}
                      onChange={(event) =>
                        (m.gradingCondition = event.target.value as unknown as ExternalGradingCondition)
                      }
                    >
                      {AllExternalGradingConditions.map((condition) => (
                        <MenuItem key={`${condition}-grading-condition`} value={condition}>
                          {strings.externalGradingCondition(condition)}
                        </MenuItem>
                      ))}
                    </Select>
                    <ArrowIcon className={'arrow'} />
                  </Stack>
                </TableCell>
                <TableCell>
                  <Select
                    fullWidth
                    displayEmpty
                    value={m.representation?.icon ?? 'none'}
                    onChange={(event) => m.setIcon(event.target.value as ContentDefinition_ContentIcon)}
                  >
                    <MenuItem key={'no-icon'} value="none">
                      {strings.none}
                    </MenuItem>
                    {AllBufContentIcons.map((icon) => (
                      <MenuItem key={`icon-${icon}`} value={icon}>
                        <Stack
                          direction="row"
                          sx={{
                            alignItems: 'center'
                          }}
                        >
                          <TaskIcon
                            icon={contentIconFromProtobuf(icon)}
                            workloadLevel={contentWorkloadLevelFromProtobuf(
                              m.representation?.workloadLevel ?? ContentDefinition_WorkloadLevel.REGULAR
                            )}
                          />
                          <Typography
                            sx={{
                              ml: 1
                            }}
                          >
                            {contentsStrings.defaultTitle(contentIconFromProtobuf(icon))}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  {m.representation != null && (
                    <Select
                      fullWidth
                      value={m.representation.workloadLevel}
                      onChange={(event) =>
                        (m.representation!.workloadLevel = event.target.value as ContentDefinition_WorkloadLevel)
                      }
                    >
                      {AllBufContentWorkloadLevels.map((level) => (
                        <MenuItem key={`level-${level}`} value={level}>
                          {contentsStrings.workloadLevelString(contentWorkloadLevelFromProtobuf(level))}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => viewModel.deleteKeyword(i)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
        <TableFooter>
          <TableRow>
            <TableCell colSpan={5}>
              <IconButton onClick={() => viewModel.addKeyword('')}>
                <AddIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    );
  }
);
