import { SectionStudentsEditionIdsSelectionViewModel } from '@insights/viewmodels';
import { SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface SectionStudentsEditionIdsSelectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: SectionStudentsEditionIdsSelectionViewModel;
}

export const SectionStudentsEditionIdsSelection = observer((props: SectionStudentsEditionIdsSelectionProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  return (
    <TextField
      sx={sx}
      className={className}
      fullWidth
      multiline
      rows={5}
      label={strings.studentIdsLabel}
      value={viewModel.ids}
      onChange={(event) => (viewModel.ids = event.target.value)}
    />
  );
});
