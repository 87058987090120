import { TaskGenericImages } from '@shared/resources/images/studyo';
import calendarImage from '@shared/web/images/assets/studyo/ui_calendar.svg?url';
import taskActiveImage from '@shared/web/images/assets/studyo/ui_task_active.svg?url';
import taskCompletedImage from '@shared/web/images/assets/studyo/ui_task_completed.svg?url';
import trashImage from '@shared/web/images/assets/studyo/ui_trash.svg?url';
import { WebContentBoxImages } from './WebContentBoxImages';

export class WebTaskGenericImages implements TaskGenericImages {
  private _contentBoxImages = new WebContentBoxImages();

  get stepCalendar() {
    return calendarImage;
  }

  get trash() {
    return trashImage;
  }

  get activeButtonIcon() {
    return taskActiveImage;
  }

  get completedButtonIcon() {
    return taskCompletedImage;
  }

  get contentBox() {
    return this._contentBoxImages;
  }
}
