import { AccountService, AlertService, NavigationService, SettingsStore } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import {
  DashboardProcess,
  DashboardProcessComments,
  OnboardingComment,
  OnboardingQuestion
} from '@shared/models/onboarding/interfaces';
import { ConfigState, OnboardingStatus } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores/interfaces';
import { startOfDay, subDays, subYears } from 'date-fns';
import { keyBy } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { NavigateFunction } from 'react-router-dom';
import {
  AppOnboardingProcessViewModel,
  AppOnboardingStepSummaryViewModel,
  DefaultOnboardingVariableResolver,
  EmptyOnboardingCommentsViewModel,
  OnboardingProcessViewModel,
  OnboardingStepSummaryViewModel,
  OnboardingVariableResolver
} from './onboarding';

export interface OnboardingDashboardStepViewModel {
  readonly stepSummary: OnboardingStepSummaryViewModel;
  readonly questions: OnboardingQuestion[];
  readonly comments: OnboardingComment[];
}

export interface OnboardingDashboardProcessViewModel {
  readonly process: OnboardingProcessViewModel;
  readonly steps: OnboardingDashboardStepViewModel[];
  readonly comments: OnboardingComment[];
  readonly schoolName: string;
  readonly schoolStartDate: string;
  readonly configState: ConfigState;

  readonly variableResolver: OnboardingVariableResolver;

  navigateToProcess(navigate: NavigateFunction): Promise<void>;
}

export interface OnboardingDashboardViewModel {
  readonly processStatuses: OnboardingStatus[];
  readonly stepStatuses: OnboardingStatus[];
  readonly minimumDate: Date | undefined;
  setStatuses(
    processStatuses: OnboardingStatus[],
    stepStatuses: OnboardingStatus[],
    minimumDate: Date | undefined
  ): void;

  searchTerm: string;
  readonly activeSearchTerm: string;

  search(): void;
  resetSearch(): void;

  readonly data: IPromiseBasedObservable<OnboardingDashboardProcessViewModel[]>;
}

export class AppOnboardingDashboardProcessViewModel implements OnboardingDashboardProcessViewModel {
  private readonly _accountsById: Record<string, AccountModel>;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _settings: SettingsStore,
    private readonly _dashboardProcess: DashboardProcess,
    private readonly _dashboardProcessComments: DashboardProcessComments | undefined
  ) {
    makeObservable(this);
    this._accountsById = keyBy(_dashboardProcess.accounts, (a) => a.id);
  }

  @computed
  get process() {
    return new AppOnboardingProcessViewModel(
      this._onboardingStore,
      this._accountService,
      this._navigationService,
      this._alertService,
      this._localizationService,
      this._settings,
      this._dashboardProcess.process!.configId,
      this._accountsById,
      this._dashboardProcess.process!,
      new EmptyOnboardingCommentsViewModel()
    );
  }

  @computed
  get steps() {
    return this._dashboardProcess.steps
      .filter((s) => s.step != null && (!s.step.isDependantLocked || s.step.isForcedVisible))
      .map((s) => ({
        stepSummary: new AppOnboardingStepSummaryViewModel(
          this._accountService,
          this._navigationService,
          this._dashboardProcess.process!.configId,
          this.process,
          s.step!,
          this._accountsById
        ),
        questions: s.questions.map((q) => q.question!),
        comments:
          this._dashboardProcessComments?.steps?.find((c) => c.step?.templateName == s.step?.templateName)?.comments ??
          []
      }));
  }

  @computed
  get comments() {
    return this._dashboardProcessComments?.comments ?? [];
  }

  @computed
  get schoolName() {
    return this._dashboardProcess.schoolName;
  }

  @computed
  get schoolStartDate() {
    return this._dashboardProcess.schoolStartDate;
  }

  @computed
  get configState() {
    return this._dashboardProcess.configState;
  }

  @computed
  get variableResolver() {
    return new DefaultOnboardingVariableResolver(this._dashboardProcess.process!.configId);
  }

  navigateToProcess(navigate: NavigateFunction): Promise<void> {
    return this._navigationService.navigateToOnboardingProcess(
      this.process.configId,
      this.process.templateName,
      navigate
    );
  }
}

export class AppOnboardingDashboardViewModel implements OnboardingDashboardViewModel {
  @observable private _processStatuses: OnboardingStatus[] = ['in-progress'];
  @observable private _stepStatuses: OnboardingStatus[] = ['not-started', 'in-progress', 'completed'];
  @observable private _minimumDate: Date | undefined = startOfDay(subYears(new Date(), 1));
  @observable private _searchTerm = '';
  @observable private _activeSearchTerm = '';

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _settings: SettingsStore
  ) {
    makeObservable(this);
  }

  @computed
  get data() {
    return fromPromise(this.loadData());
  }

  @computed
  get processStatuses() {
    return this._processStatuses;
  }

  @computed
  get stepStatuses() {
    return this._stepStatuses;
  }

  @computed
  get minimumDate() {
    return this._minimumDate;
  }

  @computed
  get searchTerm() {
    return this._searchTerm;
  }

  set searchTerm(value: string) {
    this._searchTerm = value;
  }

  @computed
  get activeSearchTerm() {
    return this._activeSearchTerm;
  }

  @action
  setStatuses(
    processStatuses: OnboardingStatus[],
    stepStatuses: OnboardingStatus[],
    minimumDate: Date | undefined
  ): void {
    this._processStatuses = processStatuses;
    this._stepStatuses = stepStatuses;
    this._minimumDate = minimumDate;
  }

  @action
  search() {
    this._activeSearchTerm = this._searchTerm;
  }

  @action
  resetSearch() {
    this._searchTerm = this._activeSearchTerm = '';
  }

  private async loadData(): Promise<OnboardingDashboardProcessViewModel[]> {
    const [dashboard, processesComments] = await Promise.all([
      this._activeSearchTerm.length === 0
        ? await this._onboardingStore.getDashboard(this._processStatuses, this._stepStatuses, this._minimumDate)
        : await this._onboardingStore.searchDashboard(
            this._activeSearchTerm,
            this._processStatuses,
            this._stepStatuses,
            this._minimumDate
          ),

      await this._onboardingStore.getDashboardProcessesComments(subDays(new Date(), 7))
    ]);

    const commentsByProcess = keyBy(processesComments, (pc) => pc.process?.id ?? '');

    return dashboard.processes.map(
      (p) =>
        new AppOnboardingDashboardProcessViewModel(
          this._onboardingStore,
          this._accountService,
          this._navigationService,
          this._alertService,
          this._localizationService,
          this._settings,
          p,
          commentsByProcess[p.process?.id ?? ''] ?? []
        )
    );
  }
}
