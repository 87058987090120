import { CurrentPageInfo, ObservablePresenter, PageFooter, WeekPagingNavigation } from '@insights/components';
import FilterIcon from '@mui/icons-material/FilterList';
import { Badge, Box, Card, Grid2, IconButton, SxProps, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook';
import {
  PublishedTasksBySection,
  SectionsCourseOccurrencesStatus,
  WorkloadManagerByGrade,
  WorkloadManagerBySection
} from '../metrics';

export interface WorkloadEmbeddedProps {
  sx?: SxProps;
  className?: string;
}

export const WorkloadEmbedded = observer((props: WorkloadEmbeddedProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.teachers;

  const params = useParams();
  const configId = params.configId ?? '';
  const teacherId = params.teacherId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createWorkloadEmbedded(configId, teacherId), [configId, teacherId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Workload Manager (iOS)' });
  });

  return (
    <Box sx={sx} className={className}>
      <ObservablePresenter
        sx={{ width: '100%', height: '100%' }}
        data={viewModel.data}
        render={(data) => {
          const hasSections = data.teacherTaughtSectionIds.length > 0;

          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  overflow: 'auto',
                  padding: 2
                }}
              >
                <Grid2 container>
                  {/* Course Occurrences Status */}
                  {hasSections && (
                    <Grid2 size={{ xs: 12 }}>
                      <Card>
                        <SectionsCourseOccurrencesStatus
                          sx={{ width: '100%', height: '100%' }}
                          viewModel={viewModelFactory.createSectionsCourseOccurrencesStatus(
                            viewModel.configId,
                            data.displayedSectionIds,
                            viewModel.pagination
                          )}
                          displaySectionName={true}
                          displayWeekNavigation={false}
                        />
                      </Card>
                    </Grid2>
                  )}

                  {/* Published Tasks */}
                  {hasSections && (
                    <Grid2 size={{ xs: 12 }}>
                      <Card>
                        <PublishedTasksBySection
                          sx={{ width: '100%', height: '100%' }}
                          viewModel={viewModelFactory.createPublishedTasksBySection(
                            viewModel.configId,
                            data.displayedSectionIds,
                            viewModel.pagination
                          )}
                          displaySectionName={true}
                          displayWeekNavigation={false}
                        />
                      </Card>
                    </Grid2>
                  )}

                  {/* Workload Manager (by sections) */}
                  {hasSections && (
                    <Grid2 size={{ xs: 12 }}>
                      <Card>
                        <WorkloadManagerBySection
                          sx={{ width: '100%', height: '100%' }}
                          viewModel={viewModelFactory.createWorkloadManagerBySection(
                            viewModel.configId,
                            data.displayedSectionIds,
                            viewModel.pagination
                          )}
                          displaySectionName={true}
                          displayWeekNavigation={false}
                        />
                      </Card>
                    </Grid2>
                  )}

                  {/* Workload Manager (by grade levels) */}
                  <Grid2 size={{ xs: 12 }}>
                    <Card>
                      <WorkloadManagerByGrade
                        sx={{ width: '100%', height: '100%' }}
                        viewModel={viewModelFactory.createWorkloadManagerByGrade(
                          viewModel.configId,
                          viewModel.pagination
                        )}
                        displayWeekNavigation={false}
                      />
                    </Card>
                  </Grid2>
                </Grid2>
              </Box>
              <PageFooter dense>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <CurrentPageInfo pagination={viewModel.pagination} />

                  <Box
                    sx={{
                      flex: 1
                    }}
                  />

                  <WeekPagingNavigation pagination={viewModel.pagination} />

                  <Tooltip title={strings.filterButtonTooltip}>
                    <IconButton onClick={() => void viewModel.showFilters()}>
                      <Badge badgeContent={data.filterCount} color="primary" showZero={false} variant="dot">
                        <FilterIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Box>
              </PageFooter>
            </Box>
          );
        }}
      />
    </Box>
  );
});
