import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { OnboardingQuestionViewModel } from '@insights/viewmodels';
import RenameIcon from '@mui/icons-material/CallSplit';
import DeleteIcon from '@mui/icons-material/Delete';
import AnsweredQuestionIcon from '@mui/icons-material/Done';
import RequiredAnsweredQuestionIcon from '@mui/icons-material/DoneOutline';
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/FileCopy';
import InfoIcon from '@mui/icons-material/Info';
import QuestionIcon from '@mui/icons-material/LabelOutlined';
import UnlockIcon from '@mui/icons-material/LockOpen';
import ChangedIcon from '@mui/icons-material/MoreHoriz';
import ReorderIcon from '@mui/icons-material/Reorder';
import SaveIcon from '@mui/icons-material/Save';
import RequiredQuestionIcon from '@mui/icons-material/StarBorder';
import IssueQuestionIcon from '@mui/icons-material/Warning';
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  styled,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material';
import { yellow } from '@mui/material/colors';
import { LocalizationService } from '@shared/resources/services';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';

import { useInsightsServices } from '../../UseInsightsServicesHook';
import { MoreActionMenu } from '../MoreActionMenu';
import { OnboardingTypographySelector } from './OnboardingTypographySelector';
import { PreviousAnswers } from './PreviousAnswers.tsx';
import {
  DateQuestion,
  DateTimeQuestion,
  FileQuestion,
  MultiLineTextFieldQuestion,
  MultipleChoiceQuestion,
  SensitiveTextFieldQuestion,
  SingleChoiceQuestion,
  TextFieldQuestion,
  TimeQuestion
} from './questions';

export interface OnboardingQuestionViewProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: OnboardingQuestionViewModel;
  canEdit: boolean;
}

export const OnboardingQuestionView = observer((props: OnboardingQuestionViewProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel, canEdit } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: viewModel.id });

  const containerStyle = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  const dragHandleStyle: CSSProperties = {
    cursor: 'move',
    touchAction: 'none'
  };

  const [previousAnswersAnchorElement, setPreviousAnswersAnchorElement] = useState<HTMLElement | undefined>(undefined);

  const getDependenceLabel = (questionName: string, answer: string) => {
    return answer?.length > 0 ? `${questionName} = ${answer}` : questionName;
  };

  const togglePopover = (anchor: HTMLElement) =>
    setPreviousAnswersAnchorElement(previousAnswersAnchorElement == null ? anchor : undefined);

  return (
    <Root ref={setNodeRef} sx={sx} className={clsx(className, 'root')} style={{ ...style, ...containerStyle }}>
      {viewModel.isLocked && canEdit && (
        <Box className={'lockedHeader'}>
          <Typography variant="caption">
            {getDependenceLabel(viewModel.dependantQuestionName, viewModel.dependantQuestionAnswer)}
          </Typography>
        </Box>
      )}
      <Box className={clsx(viewModel.isLocked && canEdit && 'locked')} display="flex" flexDirection="row">
        <Box className={'indicatorBox'}>
          {viewModel.isUpdating && <CircularProgress thickness={3} color="secondary" size={16} />}
          {!viewModel.isUpdating &&
            renderIcon(
              viewModel.answer.hasAnswer,
              viewModel.isRequired,
              viewModel.hasChanged,
              viewModel.hasIssue,
              localizationService
            )}
        </Box>
        <Box flex={1}>
          <OnboardingTypographySelector
            texts={viewModel.description}
            variableResolver={viewModel.variableResolver}
            disabled={viewModel.isDisabled}
          />
          {renderInput(viewModel)}
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          {viewModel.canForceAnswer &&
            (viewModel.isDisabled ? (
              <Tooltip title={strings.forceAnswer}>
                <IconButton onClick={() => viewModel.forceEdition()}>
                  <UnlockIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={strings.saveForcedAnswer}>
                <IconButton onClick={() => void viewModel.forceSave()} disabled={!viewModel.hasChanged}>
                  <SaveIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ))}
          {viewModel.canShowPreviousAnswers && (
            <Tooltip title={strings.viewPreviousAnswers}>
              <IconButton onClick={(e) => togglePopover(e.currentTarget)}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {viewModel.canShowPreviousAnswers && (
            <Popover
              open={previousAnswersAnchorElement != null}
              onClose={() => setPreviousAnswersAnchorElement(undefined)}
              anchorEl={previousAnswersAnchorElement}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <PreviousAnswers viewModel={viewModel} />
            </Popover>
          )}
          {canEdit && (
            <>
              <MoreActionMenu>
                <MenuItem onClick={() => void viewModel.navigateToEditQuestion()}>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText>{strings.editQuestionLabel}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => void viewModel.renameQuestion()}>
                  <ListItemIcon>
                    <RenameIcon />
                  </ListItemIcon>
                  <ListItemText>{strings.getRenameTemplateLabel(viewModel.templateName)}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => void viewModel.deleteQuestion()}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>{strings.deleteQuestionLabel}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => void viewModel.copyQuestion()}>
                  <ListItemIcon>
                    <CopyIcon />
                  </ListItemIcon>
                  <ListItemText>{strings.copyQuestionLabel}</ListItemText>
                </MenuItem>
              </MoreActionMenu>

              <ReorderIcon style={{ ...dragHandleStyle, margin: 10 }} {...attributes} {...listeners} />
            </>
          )}
        </Box>
      </Box>
    </Root>
  );
});

function renderIcon(
  hasAnswer: boolean,
  isRequired: boolean,
  hasChanged: boolean,
  hasIssue: boolean,
  localizationService: LocalizationService
) {
  if (hasIssue) {
    const strings = localizationService.localizedStrings.insights.views.onboarding;
    return (
      <Tooltip title={strings.errorSavingAnswerTooltip}>
        <IssueQuestionIcon fontSize="small" color="error" />
      </Tooltip>
    );
  }

  const color = hasAnswer ? 'disabled' : 'secondary';

  return hasChanged ? (
    <ChangedIcon fontSize="small" color="secondary" />
  ) : isRequired ? (
    hasAnswer ? (
      <RequiredAnsweredQuestionIcon fontSize="small" color={color} />
    ) : (
      <RequiredQuestionIcon fontSize="small" color={color} />
    )
  ) : hasAnswer ? (
    <AnsweredQuestionIcon fontSize="small" color={color} />
  ) : (
    <QuestionIcon fontSize="small" color={color} />
  );
}

function renderInput(viewModel: OnboardingQuestionViewModel) {
  switch (viewModel.kind) {
    case 'simple-text':
      return (
        <TextFieldQuestion
          className="question"
          answer={viewModel.answer}
          lastYearAnswer={viewModel.lastYearAnswer}
          disabled={viewModel.isDisabled}
        />
      );
    case 'large-text':
      return (
        <MultiLineTextFieldQuestion
          className="question"
          answer={viewModel.answer}
          lastYearAnswer={viewModel.lastYearAnswer}
          disabled={viewModel.isDisabled}
          showLargerEdit={viewModel.canEditInLargerBox}
        />
      );
    case 'single-choice':
      return <SingleChoiceQuestion className="question" viewModel={viewModel} />;
    case 'multiple-choice':
      return <MultipleChoiceQuestion className="question" viewModel={viewModel} />;
    case 'date':
      return (
        <DateQuestion
          className="question"
          answer={viewModel.answer}
          lastYearAnswer={viewModel.lastYearAnswer}
          disabled={viewModel.isDisabled}
        />
      );
    case 'date-time':
      return (
        <DateTimeQuestion
          className="question"
          answer={viewModel.answer}
          lastYearAnswer={viewModel.lastYearAnswer}
          disabled={viewModel.isDisabled}
        />
      );
    case 'time':
      return (
        <TimeQuestion
          className="question"
          answer={viewModel.answer}
          lastYearAnswer={viewModel.lastYearAnswer}
          disabled={viewModel.isDisabled}
        />
      );
    case 'file':
      // Note: No "last year's answer" for files.
      return <FileQuestion className="question" viewModel={viewModel} />;
    case 'sensitive-simple-text':
      // Note: No "last year's answer" either for sensitive questions.
      return (
        <SensitiveTextFieldQuestion
          className="question"
          answer={viewModel.answer}
          onViewAnswer={() => viewModel.viewSensitiveAnswer()}
          disabled={viewModel.isDisabled}
          clientId={viewModel.clientId}
        />
      );
  }
}

const Root = styled(Box)(({ theme }) => ({
  '.root': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  '.lockedHeader': {
    backgroundColor: yellow[100],
    marginLeft: 30,
    padding: theme.spacing(1),
    display: 'inline-flex'
  },
  '.locked': {
    backgroundColor: yellow[100]
  },
  '.indicatorBox': {
    width: 30
  },
  '.description': {
    marginBottom: theme.spacing(2)
  },
  '.question': {
    maxWidth: 600,
    marginRight: theme.spacing(2)
  }
}));
