import { SectionInfo, accountInfoFromModel } from '@insights/models';
import { AccountService, NavigationService } from '@insights/services';
import { CustomFilterUtils, NamedFilter } from '@insights/utils';
import { SectionModel } from '@shared/models/config';
import { AdminOrStaffAuthorizationRoles } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { CsvExportViewModelStrings } from '@shared/resources/strings/insights/viewModels/CsvExportViewModelStrings';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface SectionsViewModel {
  readonly sections: SectionInfo[];

  readonly namedFilters: NamedFilter<SectionInfo>[];

  addSection(): Promise<void>;
  editSections(sections: SectionModel[]): Promise<void>;
  editStudents(section: SectionModel): Promise<void>;
  editTeachers(section: SectionModel): Promise<void>;
  editSectionSchedules(section: SectionModel): Promise<void>;
  manageSectionConflicts(section: SectionModel): Promise<void>;

  exportToCsv(): void;
}

export interface LoadingSectionsViewModel {
  readonly configId: string;
  readonly data: IPromiseBasedObservable<SectionsViewModel>;
  readonly shouldLimitAccess?: boolean;
}

export class AppSectionsViewModel implements SectionsViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    public readonly sections: SectionInfo[]
  ) {}

  get namedFilters(): NamedFilter<SectionInfo>[] {
    return [
      {
        prefix: 'level:',
        isMatch: this.isLevelMatch.bind(this)
      },
      {
        prefix: 'locked:',
        isMatch: this.isLockedMatch.bind(this)
      }
    ];
  }

  async addSection() {
    await this._navigationService.navigateToSectionCreation(this._configId);
  }

  async editSections(sections: SectionModel[]) {
    await this._navigationService.navigateToSectionEdition(
      this._configId,
      sections.map((s) => s.id)
    );
  }

  async editStudents(section: SectionModel) {
    await this._navigationService.navigateToSectionStudentsEdition(this._configId, section.id);
  }

  async editTeachers(section: SectionModel) {
    await this._navigationService.navigateToSectionTeachersEdition(this._configId, section.id);
  }

  async editSectionSchedules(section: SectionModel) {
    await this._navigationService.navigateToSectionSchedulesEdition(this._configId, section.id);
  }

  async manageSectionConflicts(section: SectionModel) {
    await this._navigationService.navigateToManageSectionConflicts(this._configId, section.id);
  }

  exportToCsv(): void {
    const csvConfig = mkConfig({
      filename: 'sections-' + this._configId,
      useKeysAsHeaders: true
    });
    const strings = this._localizationService.localizedStrings.insights.viewModels.csvExports;

    const data = this.sections.map((s) => this.getExportedData(s, strings));
    download(csvConfig)(generateCsv(csvConfig)(data));
  }

  private isLevelMatch(info: SectionInfo, value: string): boolean {
    return info.section?.gradeLevel == value;
  }

  private isLockedMatch(info: SectionInfo, value: string): boolean {
    return info.section?.isLocked === CustomFilterUtils.valueAsBoolean(value, true);
  }

  private getExportedData(info: SectionInfo, strings: CsvExportViewModelStrings) {
    const section = info.section;

    return {
      [strings.importId]: section?.importId ?? `? ${info.id}`,
      [strings.title]: section?.title ?? '',
      [strings.shortTitle]: section?.shortTitle ?? '',
      [strings.gradeLevel]: section?.gradeLevel ?? '',
      [strings.sectionNumber]: section?.sectionNumber ?? '',
      [strings.associatedSectionNumbers]: section?.associatedSectionNumbers.join(',') ?? '',
      [strings.defaultRoomName]: section?.defaultRoomName ?? '',
      [strings.defaultTeacherId]:
        info.teachers?.find((t) => t.account.id === section?.defaultTeacherId)?.account.managedIdentifier ?? ''
    };
  }
}

export class AppLoadingSectionsViewModel implements LoadingSectionsViewModel {
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    public readonly configId: string,
    public readonly shouldLimitAccess?: boolean
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<SectionsViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<SectionsViewModel> {
    let sections: SectionModel[] = [];

    if (this.shouldLimitAccess === true && !this._accountService.isAllowed(AdminOrStaffAuthorizationRoles)) {
      // For now, we assume it's a teacher. We also assume it's only one!
      const teacherId = this._accountService.getAccountIdForConfigRole(this.configId, 'teacher');

      if (teacherId != null) {
        sections = await this._schoolYearConfigurationStore.getTaughtSectionsForTeacherId(this.configId, teacherId);
      }
    } else {
      // Non-admin staff will have limited priviledges.
      sections = await this._schoolYearConfigurationStore.getSections(this.configId);
    }

    const infos = await Promise.all(
      sections.map<Promise<SectionInfo>>(async (section) => {
        const [students, teachers] = await Promise.all([
          this._schoolYearConfigurationStore.getStudentsForSection(this.configId, section, false),
          this._schoolYearConfigurationStore.getTeachersForSection(this.configId, section, false)
        ]);

        return {
          id: section.id,
          section: section,
          students: students.map(accountInfoFromModel),
          teachers: teachers.map(accountInfoFromModel)
        };
      })
    );

    return new AppSectionsViewModel(this._navigationService, this._localizationService, this.configId, infos);
  }
}
