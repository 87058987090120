import {
  Column,
  Container,
  Expanded,
  InsightsButton,
  ObservableActionsPresenter,
  ObservablePresenter,
  Row,
  SectionStudentsEditionSectionSelection,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Dialog, DialogActions, DialogContent, DialogTitle, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SectionStudentsEditionSectionSelectionDialogProps extends DialogResult<string[]> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  sectionId: string;
}

export const SectionStudentsEditionSectionSelectionDialog = observer(
  (props: SectionStudentsEditionSectionSelectionDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { sx = [], style, className, sectionId, configId, onCancel, onSuccess } = props;
    const strings = localizationService.localizedStrings.insights.views.metrics.sections;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createSectionStudentsEditionSectionSelection(configId, sectionId, onSuccess!, onCancel!)
    );

    const close = () => {
      void viewModel.close();
    };

    return (
      <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
        <Dialog
          sx={sx}
          className={className}
          style={style}
          open={true}
          maxWidth="xs"
          fullWidth={true}
          onClose={() => close()}
          PaperProps={{ sx: { height: '75vh' } }}
        >
          <DialogTitle sx={{ pb: 0 }}>{strings.sectionSelection}</DialogTitle>

          <DialogContent>
            <ObservablePresenter
              sx={{ height: '100%' }}
              data={viewModel.data}
              render={(d) => (
                <Container sx={{ height: '100%' }}>
                  <Column>
                    <SectionStudentsEditionSectionSelection sx={{ height: '100%' }} viewModel={d} />
                  </Column>
                </Container>
              )}
            />
          </DialogContent>

          <DialogActions>
            <ObservableActionsPresenter
              data={viewModel.data}
              render={(data) => (
                <Column>
                  <Row verticalContentAlignment="center">
                    <Expanded />
                    <InsightsButton sx={{ m: 1 }} onClick={() => close()}>
                      {strings.cancel}
                    </InsightsButton>
                    <InsightsButton
                      sx={{ m: 1 }}
                      onClick={() => void data.import()}
                      isDisabled={data.selectedSection == null}
                      isDefault
                    >
                      {strings.importFromSectionConfirm}
                    </InsightsButton>
                  </Row>
                </Column>
              )}
              renderError={() => <InsightsButton onClick={() => close()}>{strings.close}</InsightsButton>}
            />
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
);
