import { OverlaysImages } from '@shared/resources/images/studyo';
import assignmentImage from '@shared/web/images/assets/studyo/task_overlay_assignment.svg?url';
import cancelledImage from '@shared/web/images/assets/studyo/task_overlay_cancelled.svg?url';
import circleImage from '@shared/web/images/assets/studyo/task_overlay_circle.svg?url';
import completedImage from '@shared/web/images/assets/studyo/task_overlay_completed.svg?url';
import errorImage from '@shared/web/images/assets/studyo/task_overlay_error.svg?url';
import halfCircleImage from '@shared/web/images/assets/studyo/task_overlay_half_circle.svg?url';
import opaqueImage from '@shared/web/images/assets/studyo/task_overlay_opaque.svg?url';
import publishedImage from '@shared/web/images/assets/studyo/task_overlay_published.svg?url';
import publishedPendingImage from '@shared/web/images/assets/studyo/task_overlay_published_pending.svg?url';
import stepsImage from '@shared/web/images/assets/studyo/task_overlay_steps.svg?url';

export class WebOverlaysImages implements OverlaysImages {
  get assignment() {
    return assignmentImage;
  }

  get cancelled() {
    return cancelledImage;
  }

  get circle() {
    return circleImage;
  }

  get halfCircle() {
    return halfCircleImage;
  }

  get completed() {
    return completedImage;
  }

  get opaque() {
    return opaqueImage;
  }

  get prePublished() {
    return publishedPendingImage;
  }

  get published() {
    return publishedImage;
  }

  get steps() {
    return stepsImage;
  }

  get error() {
    return errorImage;
  }
}
