import * as MPB from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/managebac_pb';
import * as TMPB from '@buf/studyo_studyo.bufbuild_es/studyo/type_managebac_pb';
import { ManageBac } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/managebac_connect';
import { ManageBacTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class ManageBacGrpcTransport extends BaseGrpcTransport implements ManageBacTransport {
  async fetchManageBacExternalAccountDetails(
    externalAccountId: string
  ): Promise<MPB.GetExternalAccountDetailsResponse> {
    console.log('Fetching ManageBac external account...');

    const request = new MPB.GetExternalAccountDetailsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(ManageBac, ManageBac.methods.getExternalAccountDetails, request);

    console.log('Successfully fetched ManageBac external account.');

    return response;
  }

  async createOrUpdateManageBacExternalAccount(
    externalAccountId: string | undefined,
    configId: string,
    serverUrl: string,
    authToken: string,
    accountName: string
  ): Promise<MPB.CreateOrUpdateExternalAccountResponse> {
    console.log('Creating or updating ManageBac external account...');

    const request = new MPB.CreateOrUpdateExternalAccountRequest();
    request.externalAccountId = externalAccountId ?? '';
    request.configId = configId;
    request.serverUrl = serverUrl;
    request.authToken = authToken;
    request.accountName = accountName;

    const response = await this.performRequest(ManageBac, ManageBac.methods.createOrUpdateExternalAccount, request);

    console.log('Successfully created or updated ManageBac external account.');

    return response;
  }

  async updateManageBacExternalAccountSettings(
    externalAccountId: string,
    configId: string,
    termIds: bigint[],
    assignmentKindMappings: TMPB.AssignmentKindMapping[],
    isIgnoringUnmappedKinds: boolean
  ): Promise<MPB.UpdateExternalAccountSettingsResponse> {
    console.log('Updating ManageBac external account settings...');

    const request = new MPB.UpdateExternalAccountSettingsRequest();
    request.externalAccountId = externalAccountId;
    request.configId = configId;
    request.termIds = termIds;
    request.assignmentKindMappings = assignmentKindMappings;
    request.isIgnoringUnmappedKinds = isIgnoringUnmappedKinds;

    const response = await this.performRequest(ManageBac, ManageBac.methods.updateExternalAccountSettings, request);

    console.log('Successfully updated ManageBac external account settings.');

    return response;
  }
}
