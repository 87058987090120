import {
  InsightsButton,
  ObservablePresenter,
  OnboardingTypographySelector,
  SelectOnboardingMultiOwnership,
  SelectOnboardingOwnership,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { SelectedOnboardingOwnership } from '@insights/viewmodels';
import StudyoParticipantIcon from '@mui/icons-material/HeadsetMic';
import BothParticipantsIcon from '@mui/icons-material/People';
import ClientParticipantIcon from '@mui/icons-material/Person';
import {
  Box,
  Checkbox,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  styled,
  SxProps,
  ThemeProvider,
  Tooltip,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { OnboardingParticipantKind } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DialogResult } from '@shared/services';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SelectOnboardingOwnershipDialogProps extends DialogResult<SelectedOnboardingOwnership> {
  sx?: SxProps;
  configId: string;
  processName: string;
  currentClientId?: string;
  currentAgentId?: string;
  currentFollowerIds: string[];
  participantKind: OnboardingParticipantKind;
  canApplyToSteps: boolean;
  className?: string;
  style?: CSSProperties;
}

export const SelectOnboardingOwnershipDialog = observer((props: SelectOnboardingOwnershipDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const {
    className,
    style,
    configId,
    sx = [],
    processName,
    canApplyToSteps,
    currentAgentId,
    currentClientId,
    currentFollowerIds,
    participantKind,
    onSuccess,
    onCancel
  } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createSelectOnboardingOwnershipDialog(
      configId,
      processName,
      currentClientId,
      currentAgentId,
      currentFollowerIds,
      participantKind,
      canApplyToSteps,
      onSuccess!,
      onCancel!
    )
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Root
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => viewModel.cancel()}
      >
        <DialogTitle>{strings.selectOwnersTitle}</DialogTitle>

        <DialogContent>
          <ObservablePresenter
            data={viewModel.data}
            render={(data) => (
              <Box>
                {data.canSelectClient && (
                  <SelectOnboardingOwnership
                    className="ownershipSelector"
                    label={strings.selectClientLabel}
                    availableAccounts={data.accounts}
                    selectedAccount={data.selectedClient}
                    onSelection={(id) => data.selectClient(id)}
                  />
                )}
                {data.canSelectAgent && (
                  <SelectOnboardingOwnership
                    className="ownershipSelector"
                    label={strings.selectAgentLabel}
                    availableAccounts={data.accounts}
                    selectedAccount={data.selectedAgent}
                    onSelection={(id) => data.selectAgent(id)}
                  />
                )}
                <SelectOnboardingMultiOwnership
                  className="multiOwnershipSelector"
                  label={strings.selectFollowersLabel}
                  availableAccounts={data.accounts}
                  selectedAccounts={data.selectedFollowers}
                  onSelection={(ids) => data.selectFollowers(ids)}
                />
                {/* If there are no steps, we don't display the process checkbox either. It's selected
            by default and there is no need to allow deselecting it. */}
                {data.steps.length > 0 && (
                  <Box>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Tooltip title={strings.selectEverythingTooltip}>
                        <Checkbox
                          checked={data.isEverythingSelected}
                          onChange={(event) => (data.isEverythingSelected = event.target.checked)}
                        />
                      </Tooltip>
                      <Typography variant="caption">{strings.selectEverythingLabel}</Typography>
                    </Box>
                    <Box>
                      <FormControlLabel
                        className="stepCheckbox"
                        control={
                          <Checkbox
                            checked={data.isProcessSelected}
                            onChange={(event) => (data.isProcessSelected = event.target.checked)}
                          />
                        }
                        label={
                          <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography variant="body1">{strings.selectableProcess}</Typography>
                          </Box>
                        }
                      />
                    </Box>
                    {data.steps.map((s) => (
                      <Box key={`step-name-${s.templateName}`}>
                        <FormControlLabel
                          className="stepCheckbox"
                          control={
                            <Checkbox
                              checked={data.selectedStepNames.includes(s.templateName)}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  data.selectedStepNames = _.union(data.selectedStepNames, [s.templateName]);
                                } else {
                                  data.selectedStepNames = data.selectedStepNames.filter((n) => n != s.templateName);
                                }
                              }}
                            />
                          }
                          label={
                            <Box display="flex" flexDirection="row" alignItems="center">
                              {renderParticipantsIcon(s.participants, localizationService)}
                              <OnboardingTypographySelector className="stepTitle" variant="body1" texts={s.title} />
                            </Box>
                          }
                        />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )}
          />
        </DialogContent>

        <DialogActions>
          <InsightsButton onClick={() => viewModel.cancel()}>{strings.cancelLabel}</InsightsButton>
          <InsightsButton isDefault isDisabled={!viewModel.canConfirm} onClick={() => viewModel.confirm()}>
            {strings.confirmLabel}
          </InsightsButton>
        </DialogActions>
      </Root>
    </ThemeProvider>
  );
});

function renderParticipantsIcon(participant: OnboardingParticipantKind, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  switch (participant) {
    case 'studyo-only':
      return (
        <Tooltip title={strings.participantStudyoTooltip}>
          <StudyoParticipantIcon sx={{ m: 1, pt: 0.5 }} fontSize="small" color="disabled" />
        </Tooltip>
      );
    case 'client-only':
      return (
        <Tooltip title={strings.participantClientTooltip}>
          <ClientParticipantIcon sx={{ m: 1, pt: 0.5 }} fontSize="small" color="secondary" />
        </Tooltip>
      );
    case 'studyo-and-client':
      return (
        <Tooltip title={strings.participantStudyoAndClientTooltip}>
          <BothParticipantsIcon sx={{ m: 1, pt: 0.5 }} fontSize="small" color="secondary" />
        </Tooltip>
      );
  }
}

const Root = styled(Dialog)(({ theme }) => ({
  '.ownershipSelector': {
    marginBottom: theme.spacing(2)
  },
  '.multiOwnershipSelector': {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(3)
  },
  '.stepCheckbox': {
    marginLeft: theme.spacing(0)
  },
  '.stepTitle': {
    margin: theme.spacing(1)
  },
  '.participantChip': {
    marginRight: theme.spacing(1),
    borderColor: grey[500],
    color: grey[500]
  }
}));
