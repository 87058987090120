export interface ProfileEditStrings {
  readonly title: string;
  readonly cancel: string;
  readonly save: string;
  readonly generalInfoSectionTitle: string;
  readonly firstnamePlaceholder: string;
  readonly lastnamePlaceholder: string;
  readonly profileColorSectionTitle: string;

  readonly unsavedChangesTitle: string;
  readonly unsavedChangesDescription: string;
  readonly unsavedChangesStayTitle: string;
  readonly unsavedChangesDiscardTitle: string;

  readonly savingTitle: string;
  readonly savingDescription: string;
  readonly savedTitle: string;
  readonly savedDescription: string;
  readonly saveErrorTitle: string;
  readonly saveErrorDescription: string;
  readonly savePermanentErrorDescription: string;
}

export const EnglishProfileEditStrings: ProfileEditStrings = {
  title: 'Edit Profile',
  cancel: 'Cancel',
  save: 'Save',
  generalInfoSectionTitle: 'General Informations',
  firstnamePlaceholder: 'First Name',
  lastnamePlaceholder: 'Last Name',
  profileColorSectionTitle: 'Profile Color',

  unsavedChangesTitle: 'Unsaved changes',
  unsavedChangesDescription: 'The changes made to your profile will be lost. Are you sure you wish to cancel?',
  unsavedChangesStayTitle: 'No',
  unsavedChangesDiscardTitle: 'Discard',

  savingTitle: 'Please wait…',
  savingDescription: 'Saving your profile',
  savedTitle: 'Success',
  savedDescription: 'Your profile was saved successfully!',
  saveErrorTitle: 'Oops, something went wrong!',
  saveErrorDescription: 'An error occurred while saving your profile. Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented the save. Please reload and try again.'
};

export const FrenchProfileEditStrings: ProfileEditStrings = {
  title: 'Édition du profil',
  cancel: 'Annuler',
  save: 'Sauvegarder',
  generalInfoSectionTitle: 'Informations générales',
  firstnamePlaceholder: 'Prénom',
  lastnamePlaceholder: 'Nom',
  profileColorSectionTitle: 'Couleur de profil',

  unsavedChangesTitle: 'Changements non-sauvegardés',
  unsavedChangesDescription: 'Les changements effectués à votre profil seront perdus. Souhaitez-vous annuler?',
  unsavedChangesStayTitle: 'Non',
  unsavedChangesDiscardTitle: 'Abandonner',

  savingTitle: 'Veuillez patienter…',
  savingDescription: 'Sauvegarde de votre profil en cours',
  savedTitle: 'Succès',
  savedDescription: 'Votre profil a été sauvegardé!',
  saveErrorTitle: 'Oups, une erreur est survenue!',
  saveErrorDescription: 'Votre profil n’a pu être sauvegardé. Veuillez réessayer.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la sauvegarde. Veuillez rafraichir et réessayer.'
};
