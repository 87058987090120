import { OnboardingQuestionViewModel } from '@insights/viewmodels';
import LastYearIcon from '@mui/icons-material/EventRepeat';
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SingleChoiceQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: OnboardingQuestionViewModel;
}

export const SingleChoiceQuestion = observer((props: SingleChoiceQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const languageCode = localizationService.currentLocale;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <FormControl sx={sx} className={className} style={style}>
      <RadioGroup>
        {viewModel.choices.map((choice) => (
          <FormControlLabel
            key={`q-${viewModel.templateName}-c-${choice.value}`}
            value={choice.value}
            control={
              <Radio
                sx={{ paddingY: '6px' }}
                disabled={viewModel.isDisabled}
                checked={viewModel.answer.singleChoiceValue === choice.value}
                onChange={() => (viewModel.answer.singleChoiceValue = choice.value)}
                onClick={() => {
                  if (viewModel.answer.singleChoiceValue === choice.value) {
                    viewModel.answer.singleChoiceValue = '';
                  }
                }}
              />
            }
            label={
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography fontWeight="400" alignItems="center">
                  {choice.label.find((l) => l.languageCode === languageCode)?.value ?? ''}
                </Typography>
                {viewModel.lastYearAnswer?.singleChoiceValue === choice.value && (
                  <Tooltip title={strings.lastYearAnswerTooltip}>
                    <LastYearIcon color="info" fontSize="small" />
                  </Tooltip>
                )}
              </Stack>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
});
