export interface MonthlyOptionMenuStrings {
  readonly close: string;
  readonly title: string;

  // Content Types
  readonly contentDisplaySectionTitle: string;
  readonly contentDisplayNotes: string;
  readonly contentDisplayTasks: string;
}

export const EnglishMonthlyOptionMenuStrings: MonthlyOptionMenuStrings = {
  close: 'Close',
  title: 'Options',

  // Content Types
  contentDisplaySectionTitle: 'Displayed Content Types',
  contentDisplayNotes: 'Notes',
  contentDisplayTasks: 'Tasks'
};

export const FrenchMonthlyOptionMenuStrings: MonthlyOptionMenuStrings = {
  close: 'Fermer',
  title: 'Options',

  // Content Types
  contentDisplaySectionTitle: 'Type de contenu affiché',
  contentDisplayNotes: 'Notes',
  contentDisplayTasks: 'Tâches'
};
