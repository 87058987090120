import { SchoolYearConfiguration_Link as PBLink } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableStringProperty, EditableValuePropertyEx } from '../editables';
import { Link, LinkModel } from './Link';

export class EditableLink extends EditableModelEx<PBLink> implements LinkModel {
  private _sortPosition: EditableValuePropertyEx<number, PBLink>;
  private _text: EditableStringProperty<PBLink>;
  private _url: EditableStringProperty<PBLink>;

  static createNew(nextSortPosition: number): EditableLink {
    const pb = new PBLink();
    pb.sortPosition = nextSortPosition;

    return new EditableLink(new Link(pb), true);
  }

  constructor(originalLink: LinkModel, isNew = false) {
    super(originalLink.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._sortPosition = new EditableValuePropertyEx(
        originalLink.sortPosition,
        (pb, value) => (pb.sortPosition = value)
      )),
      (this._text = new EditableStringProperty(originalLink.text, (pb, value) => (pb.text = value), {
        trim: true
      })),
      (this._url = new EditableStringProperty(originalLink.url, (pb, value) => (pb.url = value), {
        trim: true
      }))
    ]);
  }

  @computed
  get sortPosition() {
    return this._sortPosition.value;
  }

  set sortPosition(value: number) {
    this._sortPosition.value = value;
  }

  @computed
  get text() {
    return this._text.value;
  }

  set text(value: string) {
    this._text.value = value;
  }

  @computed
  get url() {
    return this._url.value;
  }

  set url(value: string) {
    this._url.value = value;
  }

  clone(): EditableLink {
    const pb = this.toProtobuf();
    return new EditableLink(new Link(pb));
  }
}
