import { AlertService, ContentService, NavigationService, SettingsStore } from '@insights/services';
import { caseInsensitiveAccentInsensitiveCompare, cleanDiacritics } from '@insights/utils';
import { AccountUtils, ContentDefinitionUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { AccountModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { AllContentWorkloadLevels, Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { CsvExportViewModelStrings } from '@shared/resources/strings/insights/viewModels/CsvExportViewModelStrings';
import { CalendarStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { Dictionary, chain, flatMap, groupBy, orderBy, uniq } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  AppPaginatedViewModel,
  AppPaginationViewModel,
  PaginatedViewModel,
  PaginationViewModel
} from './PaginatedViewModel';

export interface PublishedTasksByGradeInfo {
  readonly minDay: Day;
  readonly maxDay: Day;
  readonly gradeLevels: PublishedTasksByGradeGradeInfo[];
  readonly schoolDays: SchoolDay[];
}

export interface PublishedTasksByGradeGradeInfo {
  readonly gradeLevel: string;
  readonly sections: Map<string, SectionModel>;
}

export interface PublishedTasksByGradePageInfo {
  readonly schoolDays: SchoolDay[];
  readonly dayInfos: PublishedTasksByGradeColumnInfo[];
  readonly weekInfos: PublishedTasksByGradeColumnInfo[];
}

export interface PublishedTasksByGradeColumnInfo {
  readonly gradeLevel: string;
  readonly schoolDay?: SchoolDay;
  readonly publishedTasks: ContentDefinitionModel[];
}

export interface PublishedTasksByGradeViewModel extends PaginatedViewModel {
  readonly configId: string;

  readonly data: IPromiseBasedObservable<PublishedTasksByGradeInfo>;
  readonly pageData: IPromiseBasedObservable<PublishedTasksByGradePageInfo>;

  importantTasksOnly: boolean;

  readonly isExporting: boolean;

  editAssessmentPlanningDates: () => Promise<void>;
  showDetail: (
    configId: string,
    gradeLevel: string,
    gradeLevelSectionCount: number,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    tasks: ContentDefinitionModel[]
  ) => Promise<void>;
  exportToCsv: () => Promise<void>;
}

export class AppPublishedTasksByGradeViewModel extends AppPaginatedViewModel implements PublishedTasksByGradeViewModel {
  @observable private _isExporting = false;

  constructor(
    public readonly configId: string,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _calendarStore: CalendarStore,
    private readonly _settingsStore: SettingsStore,
    private readonly _navigationService: NavigationService,
    private readonly _contentService: ContentService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    paginationViewModel?: PaginationViewModel
  ) {
    super(paginationViewModel);
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<PublishedTasksByGradeInfo> {
    return fromPromise(this.loadData());
  }

  @computed
  get pageData(): IPromiseBasedObservable<PublishedTasksByGradePageInfo> {
    return fromPromise(this.loadPageData());
  }

  @computed
  get importantTasksOnly(): boolean {
    return this._settingsStore.workloadPreferences.importantTasksOnly;
  }

  set importantTasksOnly(value: boolean) {
    this._settingsStore.workloadPreferences.importantTasksOnly = value;
  }

  @computed
  get isExporting(): boolean {
    return this._isExporting;
  }

  async editAssessmentPlanningDates(): Promise<void> {
    const config = await this._schoolYearConfigurationStore.getConfig(this.configId);
    await this._navigationService.navigateToEditSchoolYearConfigurationAssessmentPlanning(config);
  }

  async showDetail(
    configId: string,
    gradeLevel: string,
    gradeLevelSectionCount: number,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    tasks: ContentDefinitionModel[]
  ): Promise<void> {
    await this._navigationService.navigateToPublishedTasksByGradeDetail(
      configId,
      gradeLevel,
      gradeLevelSectionCount,
      fromDay,
      toDay,
      tasks
    );
  }

  @action
  async exportToCsv(): Promise<void> {
    this._isExporting = true;

    try {
      const [config, sections, sectionsById, teachersById] = await Promise.all([
        this._schoolYearConfigurationStore.getConfigSummary(this.configId),
        this._schoolYearConfigurationStore.getSections(this.configId),
        this._schoolYearConfigurationStore.getSectionsById(this.configId),
        this._schoolYearConfigurationStore.getTeachersById(this.configId, false)
      ]);

      const sectionIds = sections.map((s) => s.id);

      const tasks = await this._contentService.getMasterTasks(
        this.configId,
        sectionIds,
        config.startDay,
        config.endDay,
        false
      );

      const csvConfig = mkConfig({
        filename: 'published-tasks-' + this.configId,
        useKeysAsHeaders: true
      });
      const strings = this._localizationService.localizedStrings.insights.viewModels.csvExports;
      const data = tasks.map((task) => this.getTaskRow(task, sectionsById, teachersById, strings));

      download(csvConfig)(generateCsv(csvConfig)(data));
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.metrics;

      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isExporting = false));
    }
  }

  private getTaskRow(
    task: ContentDefinitionModel,
    sectionsById: Record<string, SectionModel>,
    teachersById: Record<string, AccountModel>,
    strings: CsvExportViewModelStrings
  ) {
    const section = sectionsById[task.sectionId];
    const teacher = teachersById[task.ownerId];

    return {
      [strings.sectionId]: section?.importId ?? '',
      [strings.sectionTitle]: section?.title ?? '',
      [strings.gradeLevel]: section?.gradeLevel ?? '',
      [strings.teacherId]: teacher?.managedIdentifier ?? '',
      [strings.teacherName]: AccountUtils.getDisplayFirstLastName(teacher, ''),
      [strings.title]: task.title,
      [strings.description]: task.notes,
      [strings.icon]: task.icon,
      [strings.workloadLevel]: task.workloadLevel,
      [strings.assignmentDay]: task.assignmentDay.asString,
      [strings.dueDay]: task.dueDay.asString,
      [strings.publishedDay]: Day.fromDate(task.publishTarget?.publishedAt)?.asString ?? ''
    };
  }

  private async loadData(): Promise<PublishedTasksByGradeInfo> {
    const [config, sections, sectionsById, schoolDays, students] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this.configId),
      this._schoolYearConfigurationStore.getSections(this.configId),
      this._schoolYearConfigurationStore.getSectionsById(this.configId),
      this._calendarStore.getSchoolDays(this.configId, []),
      this._schoolYearConfigurationStore.getStudents(this.configId, false)
    ]);

    if (this.pagination == null) {
      this.setPagination(new AppPaginationViewModel(config.startDay, config.endDay));
    }

    const gradeLevels =
      config.gradeLevelSource === 'account'
        ? this.computeGradeLevelsPerAccount(students, sections, sectionsById)
        : this.computeGradeLevelsPerSection(sections);

    return {
      minDay: config.startDay,
      maxDay: config.endDay,
      schoolDays: schoolDays,
      gradeLevels: gradeLevels.sort((a, b) => {
        if (!a.gradeLevel.trim()) {
          return 1;
        }
        if (!b.gradeLevel.trim()) {
          return -1;
        }

        return caseInsensitiveAccentInsensitiveCompare(a.gradeLevel, b.gradeLevel, undefined, true);
      })
    };
  }

  private async loadPageData(): Promise<PublishedTasksByGradePageInfo> {
    const currentPage = this.pagination?.currentPage;

    if (currentPage == null) {
      return {
        schoolDays: [],
        dayInfos: [],
        weekInfos: []
      };
    }

    const importantTasksOnly = this.importantTasksOnly;
    const data = await this.data;

    // IMPORTANT: The setTimeout is temporary, and it is there to work around the UI hang we are
    //            currently experimenting with the processing of the page data. This should be removed
    //            after the refactor to a more synchronous approach.
    return new Promise((resolve) => {
      const fetchData = async () => {
        // Get the sections ids of all the sections of all the displayed grade levels
        const sectionIds = uniq(flatMap(data.gradeLevels.map((gradeLevel) => [...gradeLevel.sections.keys()])));

        const currentPageTasks = await this._contentService.getMasterTasks(
          this.configId,
          sectionIds,
          currentPage.startDay,
          currentPage.endDay,
          importantTasksOnly
        );
        const sortedTasks = orderBy(
          currentPageTasks,
          (t) => [
            AllContentWorkloadLevels.indexOf(t.workloadLevel),
            cleanDiacritics(
              ContentDefinitionUtils.getDisplayTitleForContent(t, this._localizationService.localizedStrings)
            ).toLowerCase()
          ],
          ['desc', 'asc']
        );

        const schoolDays = data.schoolDays.filter((sd) => sd.day.isWithin(currentPage.startDay, currentPage.endDay));
        const sectionTasksByDay = groupBy(sortedTasks, (t) => t.dueDay.asString);

        resolve({
          schoolDays: schoolDays,
          dayInfos: this.computeDayInfos(schoolDays, data.gradeLevels, sectionTasksByDay),
          weekInfos: this.computeWeekInfos(data.gradeLevels, sortedTasks)
        });
      };

      setTimeout(() => void fetchData(), 200);
    });
  }

  private computeDayInfos(
    schoolDays: SchoolDay[],
    gradeLevels: PublishedTasksByGradeGradeInfo[],
    tasksByDay: Dictionary<ContentDefinitionModel[]>
  ): PublishedTasksByGradeColumnInfo[] {
    return flatMap(
      schoolDays.map<PublishedTasksByGradeColumnInfo[]>((sd) => {
        return gradeLevels.map<PublishedTasksByGradeColumnInfo>((gradeLevelInfo) => {
          // Get the tasks for the day
          const tasksForDay = tasksByDay[sd.day.asString] || [];

          return {
            schoolDay: sd,
            gradeLevel: gradeLevelInfo.gradeLevel,
            publishedTasks: tasksForDay.filter((t) => gradeLevelInfo.sections.has(t.sectionId))
          };
        });
      })
    );
  }

  private computeWeekInfos(
    gradeLevels: PublishedTasksByGradeGradeInfo[],
    tasksForWeek: ContentDefinitionModel[]
  ): PublishedTasksByGradeColumnInfo[] {
    return gradeLevels.map<PublishedTasksByGradeColumnInfo>((gradeLevelInfo) => {
      return {
        gradeLevel: gradeLevelInfo.gradeLevel,
        publishedTasks: tasksForWeek.filter((t) => gradeLevelInfo.sections.has(t.sectionId))
      };
    });
  }

  private computeGradeLevelsPerAccount(
    students: AccountModel[],
    sections: SectionModel[],
    sectionsById: Record<string, SectionModel>
  ): PublishedTasksByGradeGradeInfo[] {
    const studentsByGradeLevel = chain(students)
      .filter((student) => student.gradeLevel.length > 0)
      .groupBy((student) => student.gradeLevel)
      .value();

    return [...Object.entries(studentsByGradeLevel)].map<PublishedTasksByGradeGradeInfo>(
      ([gradeLevel, gradeLevelStudents]) => {
        let gradeLevelSectionIds = chain(gradeLevelStudents)
          .map((student) => student.selectedSectionIds)
          .flatMap()
          .uniq()
          .value();

        const autoEnrollSectionIds = chain(sections)
          .filter(
            (section) =>
              section.autoEnrollRoles.includes('student') || section.autoEnrollTags.includes(`gradeLevel=${gradeLevel}`)
          )
          .map((section) => section.id)
          .uniq()
          .value();

        if (autoEnrollSectionIds.length > 0) {
          gradeLevelSectionIds = uniq(gradeLevelSectionIds.concat(autoEnrollSectionIds));
        }

        return {
          gradeLevel: gradeLevel,
          sections: new Map(gradeLevelSectionIds.map((sId) => [sId, sectionsById[sId]]))
        };
      }
    );
  }

  private computeGradeLevelsPerSection(sections: SectionModel[]): PublishedTasksByGradeGradeInfo[] {
    const sectionsByGradeLevel = chain(sections)
      .filter((section) => section.gradeLevel != null && section.gradeLevel.length > 0)
      .groupBy((section) => section.gradeLevel)
      .value();

    return [...Object.entries(sectionsByGradeLevel)].map<PublishedTasksByGradeGradeInfo>(
      ([gradeLevel, gradeSections]) => {
        return {
          gradeLevel: gradeLevel,
          sections: new Map(gradeSections.map((s) => [s.id, s]))
        };
      }
    );
  }
}
