import { InsightsButton, ObservablePresenter, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Autocomplete,
  Box,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import { SchoolYearConfigurationSummary } from '@shared/models/config';
import { ImportSession } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ImportSessionImportFromSchoolDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  className?: string;
  style?: CSSProperties;
}

export const ImportSessionImportFromSchoolDialog = observer((props: ImportSessionImportFromSchoolDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx = [], configId, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createImportSessionImportFromSchool(configId, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="xs"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <DialogTitle>{strings.importSessionTitle}</DialogTitle>

          <DialogContent dividers>
            <Box height="100%" width="100%" display="flex" flexDirection="column" overflow="visible">
              <FormControl sx={{ mb: 2 }} fullWidth>
                <InputLabel htmlFor="school-year-select">{strings.schoolYearLabel}</InputLabel>
                <Select
                  inputProps={{ id: 'school-year-select' }}
                  value={viewModel.selectedYear}
                  onChange={(e) => (viewModel.selectedYear = e.target.value as number)}
                >
                  {viewModel.years.map((y) => (
                    <MenuItem key={`year-${y}`} value={y}>
                      {y}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <ObservablePresenter
                indicatorsSize="small"
                data={viewModel.configurations}
                errorMessage={strings.loadingConfigurationsError}
                render={(configurations) => (
                  <Autocomplete
                    sx={{ mb: 2 }}
                    options={configurations}
                    value={viewModel.selectedConfiguration}
                    onChange={(_, v: SchoolYearConfigurationSummary | null) =>
                      (viewModel.selectedConfiguration = v ?? undefined)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label={strings.schoolLabel}
                        slotProps={{ inputLabel: { shrink: true } }}
                      />
                    )}
                    getOptionLabel={(c) => c.schoolName}
                    noOptionsText={strings.noSchoolConfiguration}
                  />
                )}
              />

              <ObservablePresenter
                indicatorsSize="small"
                data={viewModel.sessions}
                errorMessage={strings.sessionsErrorMessage}
                render={(sessions) => (
                  <Autocomplete
                    sx={{ mb: 2 }}
                    options={sessions}
                    value={viewModel.selectedSession}
                    onChange={(_, v: ImportSession | null) => (viewModel.selectedSession = v ?? undefined)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label={strings.importSessionLabel}
                        slotProps={{ inputLabel: { shrink: true } }}
                      />
                    )}
                    getOptionLabel={(s) => s.name}
                    noOptionsText={
                      viewModel.selectedConfiguration == null ? strings.noSchoolSelected : strings.noSession
                    }
                  />
                )}
              />
            </Box>

            {viewModel.hasError && (
              <Typography variant="caption" sx={{ color: (theme) => theme.palette.error.main }}>
                {strings.importSessionFromSchoolError}
              </Typography>
            )}
          </DialogContent>

          <DialogActions>
            <InsightsButton isExecuting={viewModel.isImporting} onClick={() => void viewModel.cancel()}>
              {strings.cancel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={!viewModel.canImport}
              isExecuting={viewModel.isImporting}
              onClick={() => void viewModel.import()}
            >
              {strings.importSessionButton}
            </InsightsButton>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
});
