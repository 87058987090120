export interface PlannerPeriodInfoStrings {
  readonly move: string;
  readonly close: string;
  readonly cancel: string;
  readonly save: string;
  readonly moveToOption: string;
  readonly moveQuickOptionHeader: string;
  readonly movePreviousPeriodOption: string;
  readonly unskipAndMovePreviousFromNextPeriodOption: string;
  readonly moveNextPeriodOption: string;
  readonly skipPeriodAndMoveNextOption: string;
  readonly confirmDialogCancel: string;
  readonly confirmDialogConfirm: string;
  readonly confirmDialogTitle: string;
  readonly confirmDialogDescription: string;
  readonly periodInfoSectionTitle: string;
  readonly classOfTerm: (ordinal: number) => string;
  readonly classLeftInYear: (remaining: number) => string;
  readonly classLeftInTerm: (remaining: number) => string;
  readonly noteSectionTitle: string;
  readonly notePlaceholder: (canEdit: boolean) => string;

  readonly savingTitle: string;
  readonly savingDescription: string;
  readonly savedTitle: string;
  readonly savedDescription: string;
  readonly saveErrorTitle: string;
  readonly saveErrorDescription: string;
  readonly savePermanentErrorDescription: string;

  readonly moveSavingTitle: string;
  readonly moveSavingDescription: string;
  readonly moveSavedTitle: string;
  readonly moveSavedDescription: string;
  readonly moveSaveErrorTitle: string;
  readonly moveSaveErrorDescription: string;
  readonly moveSavePermanentErrorDescription: string;
}

export const EnglishPlannerPeriodInfoStrings: PlannerPeriodInfoStrings = {
  move: 'Shift…',
  close: 'Close',
  cancel: 'Cancel',
  save: 'Save',
  moveToOption: 'Shift content…',
  moveQuickOptionHeader: 'Quick shift (titles, tasks and notes, here to end):',
  movePreviousPeriodOption: 'Shift content backward',
  unskipAndMovePreviousFromNextPeriodOption: 'Unskip period and shift content backward',
  moveNextPeriodOption: 'Shift content forward',
  skipPeriodAndMoveNextOption: 'Skip period and shift content forward',
  confirmDialogCancel: 'No',
  confirmDialogConfirm: 'Leave',
  confirmDialogTitle: 'Unsaved Changes',
  confirmDialogDescription:
    'If you leave this page, the changes made to the notes will be lost. Are you sure you wish to leave this page?',
  periodInfoSectionTitle: 'Informations',

  classOfTerm: (ordinal) => (ordinal > 0 ? `Class ${ordinal} of term` : 'No class'),
  classLeftInYear: (remaining) => `${remaining} left in year`,
  classLeftInTerm: (remaining) => `${remaining} left in term`,
  noteSectionTitle: 'Notes',
  notePlaceholder: (canEdit) => (canEdit ? 'Enter a note…' : 'No notes'),

  savingTitle: 'Please wait…',
  savingDescription: 'Saving your note',
  savedTitle: 'Success',
  savedDescription: 'The note was saved successfully!',
  saveErrorTitle: 'Oops, something went wrong!',
  saveErrorDescription: 'An error occurred while saving your note. Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented saving your note. Please reload and try again.',

  moveSavingTitle: 'Please wait…',
  moveSavingDescription: 'Shifting contents',
  moveSavedTitle: 'Success',
  moveSavedDescription: 'The contents were shifted successfully!',
  moveSaveErrorTitle: 'Oops, something went wrong!',
  moveSaveErrorDescription: 'An error occurred while shifting your contents. Please try again.',
  moveSavePermanentErrorDescription: 'A change made elsewhere prevented the save. Please reload and try again.'
};

export const FrenchPlannerPeriodInfoStrings: PlannerPeriodInfoStrings = {
  move: 'Décaler…',
  close: 'Fermer',
  cancel: 'Annuler',
  save: 'Sauvegarder',
  moveToOption: 'Décaler le contenu…',
  moveQuickOptionHeader: 'Décalage rapide (titres, tâches et notes, d’ici à la fin d’année):',
  movePreviousPeriodOption: 'Décaler le contenu vers le passé',
  unskipAndMovePreviousFromNextPeriodOption: 'Rétablir la période et décaler le contenu vers le passé',
  moveNextPeriodOption: 'Décaler le contenu vers le futur',
  skipPeriodAndMoveNextOption: 'Sauter la période et décaler le contenu vers le futur',
  confirmDialogCancel: 'Non',
  confirmDialogConfirm: 'Abandonner',
  confirmDialogTitle: 'Changements non-sauvegardés',
  confirmDialogDescription:
    'Si vous quittez cette page, les changements effectués à la note seront perdus. ' +
    'Êtes-vous certain de vouloir continuer?',
  periodInfoSectionTitle: 'Informations',

  classOfTerm: (ordinal) => (ordinal > 0 ? `Cours ${ordinal} de l’étape` : 'Aucun cours'),
  classLeftInYear: (remaining) => `${remaining} restant${remaining > 1 ? 's' : ''} à l’année`,
  classLeftInTerm: (remaining) => `${remaining} restant${remaining > 1 ? 's' : ''} à l’étape`,
  noteSectionTitle: 'Notes',
  notePlaceholder: (canEdit) => (canEdit ? 'Entrez une note…' : 'Aucune note'),

  savingTitle: 'Veuillez patienter…',
  savingDescription: 'Sauvegarde de la note en cours',
  savedTitle: 'Succès',
  savedDescription: 'La note a été sauvegardée!',
  saveErrorTitle: 'Oups, une erreur est survenue!',
  saveErrorDescription: 'La note n’a pu être sauvegardée. Veuillez réessayer.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la sauvegarde de la note. Veuillez rafraichir et réessayer.',

  moveSavingTitle: 'Veuillez patienter…',
  moveSavingDescription: 'Décalage du contenu en cours',
  moveSavedTitle: 'Succès',
  moveSavedDescription: 'Le contenu a été décalé avec succès!',
  moveSaveErrorTitle: 'Oups, une erreur est survenue!',
  moveSaveErrorDescription: 'Le contenu n’a pu être décalé. Veuillez réessayer.',
  moveSavePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la sauvegarde. Veuillez rafraichir et réessayer.'
};
