import {
  EnglishTimelineFilterOptionStrings,
  FrenchTimelineFilterOptionStrings,
  TimelineFilterOptionStrings
} from './TimelineFiltersOptionsStrings';
import {
  EnglishTimelineOptionMenuStrings,
  FrenchTimelineOptionMenuStrings,
  TimelineOptionMenuStrings
} from './TimelineOptionMenuStrings';

export interface TimelineStrings {
  taskListTitle: string;
  todayTasks: string;
  todoTasks: string;
  completedTasks: string;
  canceledTasks: string;
  noRemainingTasks: string;
  loadingTasks: string;
  lateTasksSectionTitle: string;
  todayTasksSectionTitle: string;
  tomorrowTasksSectionTitle: string;
  thisWeekTasksSectionTitle: string;
  nextWeekTasksSectionTitle: string;
  noTitle: string;
  laterTasksSectionTitle: string;
  numberOfDays: string;

  listEdit: string;
  cancelListEdit: string;
  markSelectionAsDone: string;
  markSelectionAsNotDone: string;
  moveAllPlannedDates: string;
  movePlannedCancelLabel: string;
  movePlannedConfirmLabel: string;
  movePlannedDatesTitle: string;
  movePlannedDatesMessage: string;
  movingPlannedDatesTitle: string;
  movingPlannedDatesDescription: string;
  movedPlannedDatesTitle: string;
  movedPlannedDatesDescription: string;
  failedMovingPlannedDatesTitle: string;
  failedMovingPlannedDatesDescription: string;
  failedPermanentMovingPlannedDatesDescription: string;
  search: string;
  selectAllTasks: string;
  selectPastTasks: string;

  readonly filterOptions: TimelineFilterOptionStrings;
  readonly optionMenu: TimelineOptionMenuStrings;
}

export const EnglishTimelineStrings: TimelineStrings = {
  taskListTitle: 'Tasks',
  canceledTasks: 'Deleted',
  completedTasks: 'Done',
  noRemainingTasks: `You don't have any tasks.`,
  loadingTasks: 'Loading your tasks…',
  todayTasks: 'Today',
  todoTasks: 'To do',
  lateTasksSectionTitle: 'Late',
  todayTasksSectionTitle: 'Today',
  tomorrowTasksSectionTitle: 'Tomorrow',
  thisWeekTasksSectionTitle: 'This week',
  nextWeekTasksSectionTitle: 'Next Week',
  noTitle: 'Untitled',
  laterTasksSectionTitle: 'Later',
  numberOfDays: 'd',

  listEdit: 'Edit',
  cancelListEdit: 'Cancel',
  markSelectionAsDone: 'Complete',
  markSelectionAsNotDone: 'To do',
  moveAllPlannedDates: 'Plan all today',
  movePlannedCancelLabel: 'Cancel',
  movePlannedConfirmLabel: 'Yes, Move planned dates',
  movePlannedDatesTitle: 'Catch up with your planning',
  movePlannedDatesMessage: 'Are you sure you want to move forward the planning date of all upcoming tasks to today?',
  movingPlannedDatesTitle: 'In progress',
  movingPlannedDatesDescription: 'Moving your planned dates...',
  movedPlannedDatesTitle: 'Done!',
  movedPlannedDatesDescription: 'Your planned dates were adjusted successfully.',
  failedMovingPlannedDatesTitle: 'Error',
  failedMovingPlannedDatesDescription: 'An unexpected error occurred while adjusting your planned dates.',
  failedPermanentMovingPlannedDatesDescription:
    'A change made elsewhere prevented adjusting your planned dates. Please reload and try again.',
  search: 'Search',
  selectAllTasks: 'Select all',
  selectPastTasks: 'Select late',

  filterOptions: EnglishTimelineFilterOptionStrings,
  optionMenu: EnglishTimelineOptionMenuStrings
};

export const FrenchTimelineStrings: TimelineStrings = {
  taskListTitle: 'Tâches',
  canceledTasks: 'Effacées',
  completedTasks: 'Complétées',
  noRemainingTasks: `Vous n'avez aucune tâche.`,
  loadingTasks: 'Chargement de vos tâches…',
  todayTasks: 'Aujourd’hui',
  todoTasks: 'À faire',
  lateTasksSectionTitle: 'En retard',
  todayTasksSectionTitle: 'Aujourd’hui',
  tomorrowTasksSectionTitle: 'Demain',
  thisWeekTasksSectionTitle: 'Cette semaine',
  nextWeekTasksSectionTitle: 'Semaine prochaine',
  noTitle: 'Sans titre',
  laterTasksSectionTitle: 'Plus tard',
  numberOfDays: 'j',

  listEdit: 'Éditer',
  cancelListEdit: 'Annuler',
  markSelectionAsDone: 'Compléter',
  markSelectionAsNotDone: 'À faire',
  moveAllPlannedDates: 'Planifier tout auj.',
  movePlannedCancelLabel: 'Annuler',
  movePlannedConfirmLabel: 'Oui, Déplacer les dates de planif.',
  movePlannedDatesTitle: 'Rattraper votre planification',
  movePlannedDatesMessage:
    'Êtes-vous certain de vouloir avancer la date de planification de toutes vos tâches à venir?',
  movingPlannedDatesTitle: 'En cours',
  movingPlannedDatesDescription: 'Déplacement de vos dates de planification...',
  movedPlannedDatesTitle: 'Terminé!',
  movedPlannedDatesDescription: 'Vos dates de planification ont été ajustées avec succès.',
  failedMovingPlannedDatesTitle: 'Erreur',
  failedMovingPlannedDatesDescription:
    'Une erreur inattendue est survenue à l’ajustement de vos dates de planification.',
  failedPermanentMovingPlannedDatesDescription:
    'Un changement fait ailleurs a empêché l’ajustement de vos dates de planification. Veuillez rafraichir et réessayer.',
  search: 'Rechercher',
  selectAllTasks: 'Tout sélectionner',
  selectPastTasks: 'En retard',

  filterOptions: FrenchTimelineFilterOptionStrings,
  optionMenu: FrenchTimelineOptionMenuStrings
};
