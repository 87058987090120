import { MenuItem, Select, SxProps, Typography } from '@mui/material';
import { AllClassroomGradingConditions, ClassroomGradingCondition } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface ClassroomGradingConditionSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  selectedGradingCondition: ClassroomGradingCondition;
  onChange: (gradingCondition: ClassroomGradingCondition) => void;
}

export const ClassroomGradingConditionSelector = observer((props: ClassroomGradingConditionSelectorProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, selectedGradingCondition, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Select
      sx={sx}
      className={className}
      style={style}
      fullWidth
      value={selectedGradingCondition}
      onChange={(event) => {
        onChange(event.target.value as ClassroomGradingCondition);
      }}
    >
      {AllClassroomGradingConditions.map((condition) => (
        <MenuItem key={`${condition}-grading-condition`} value={condition}>
          <Typography>{strings.localizedClassroomGradingCondition(condition)}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
});
