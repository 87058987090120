import { SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';

export interface ClassroomMatchingValueSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  matchingValue: string | undefined;
  onChange: (value: string | undefined) => void;
}

export const ClassroomMatchingValueSelector = observer((props: ClassroomMatchingValueSelectorProps) => {
  const { sx = [], className, style, matchingValue, onChange } = props;

  return (
    <TextField
      sx={sx}
      className={className}
      style={style}
      fullWidth
      value={matchingValue}
      onChange={(event) => onChange(event.target.value)}
    />
  );
});
