import { action, computed, makeObservable, observable } from 'mobx';

export interface ExternalAccountEditionViewModel {
  readonly hasNext?: boolean;
  readonly hasChanges: boolean;
  readonly isApplying: boolean;
  readonly errors: string[];

  applyChanges(): Promise<void>;
  resetChanges(): void;
  cancelChanges(): void;
}

export class BaseExternalAccountEditionViewModel {
  @observable protected _hasChanges = false;
  @observable protected _isApplying = false;
  @observable protected _errors: string[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  get hasChanges() {
    return this._hasChanges;
  }

  @computed
  get isApplying() {
    return this._isApplying;
  }

  @computed
  get errors() {
    return this._errors;
  }

  @action
  protected addError(error: string) {
    this._errors.push(error);
  }

  @action
  protected beginApplying() {
    this._isApplying = true;
  }

  @action
  protected endApplying() {
    this._isApplying = false;
  }

  @action
  protected onChange() {
    this._hasChanges = true;
    this._errors = [];
  }

  @action
  protected onReset() {
    this._hasChanges = false;
    this._errors = [];
  }
}
