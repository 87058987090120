export function wait(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), ms));
}

/**
 * Get `yes/no` value from boolean
 * @param value The boolean value
 * @param useShort Indicates to use `y/n` instead of `yes/no`
 */
export function booleanToYesNo(value: boolean, useShort?: boolean): string {
  return value ? (useShort ? 'y' : 'yes') : useShort ? 'n' : 'no';
}
