import * as VC from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/veracross_v3_pb';
import { VeracrossV3ExternalAccountDetails } from './interfaces';

export class GrpcVeracrossV3ExternalAccountDetails implements VeracrossV3ExternalAccountDetails {
  constructor(private readonly _pb: VC.GetExternalAccountDetailsResponse) {}

  get clientId() {
    return this._pb.clientId;
  }

  get clientSecret() {
    return this._pb.clientSecret;
  }

  get schoolRoute() {
    return this._pb.schoolRoute;
  }

  get schoolyear() {
    return this._pb.schoolYear;
  }

  get portalUrl() {
    return this._pb.portalUrl;
  }
}
