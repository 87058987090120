import { OnboardingComment as PBOnboardingComment } from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableStringProperty, EditableValuePropertyEx } from '../../editables';
import { CommentEffect, OnboardingTextFormat } from '../../types';
import { protobufFromCommentEffect, protobufFromOnboardingTextFormat } from '../../types/EnumConversion';
import { OnboardingComment } from '../interfaces';
import { GrpcOnboardingComment } from './GrpcOnboardingComment';

export class EditableOnboardingComment extends EditableModelEx<PBOnboardingComment> implements OnboardingComment {
  private _format: EditableValuePropertyEx<OnboardingTextFormat, PBOnboardingComment>;
  private _effect: EditableValuePropertyEx<CommentEffect, PBOnboardingComment>;
  private _message: EditableStringProperty<PBOnboardingComment>;
  private _isPrivate: EditableValuePropertyEx<boolean, PBOnboardingComment>;

  static createNew(
    processName: string,
    stepName: string,
    configId: string,
    fromAccountId: string,
    textFormat: OnboardingTextFormat = 'markdown'
  ): EditableOnboardingComment {
    const pb = new PBOnboardingComment();
    pb.processName = processName;
    pb.stepName = stepName;
    pb.configId = configId;
    pb.fromAccountId = fromAccountId;

    pb.format = protobufFromOnboardingTextFormat(textFormat);

    return new EditableOnboardingComment(new GrpcOnboardingComment(pb), true);
  }

  constructor(
    private readonly _originalComment: OnboardingComment,
    isNew = false
  ) {
    super(_originalComment.toProtobuf(), isNew);

    makeObservable(this);

    this._message = this.addStringField(_originalComment.message, (pb, value) => (pb.message = value));
    this._format = this.addValueField(
      _originalComment.format,
      (pb, value) => (pb.format = protobufFromOnboardingTextFormat(value))
    );
    this._effect = this.addValueField(
      _originalComment.effect,
      (pb, value) => (pb.effect = protobufFromCommentEffect(value))
    );
    this._isPrivate = this.addValueField(_originalComment.isPrivate, (pb, value) => (pb.isPrivate = value));
  }

  get id() {
    return this._originalComment.id;
  }

  get processName() {
    return this._originalComment.processName;
  }

  get stepName() {
    return this._originalComment.stepName;
  }

  get configId() {
    return this._originalComment.configId;
  }

  get fromAccountId() {
    return this._originalComment.fromAccountId;
  }

  get fromUserId() {
    return this._originalComment.fromUserId;
  }

  @computed
  get message() {
    return this._message.value;
  }

  set message(value: string) {
    this._message.value = value;
  }

  @computed
  get format() {
    return this._format.value;
  }

  set format(value: OnboardingTextFormat) {
    this._format.value = value;
  }

  @computed
  get effect() {
    return this._effect.value;
  }

  set effect(value: CommentEffect) {
    this._effect.value = value;
  }

  @computed
  get isPrivate() {
    return this._isPrivate.value;
  }

  set isPrivate(value: boolean) {
    this._isPrivate.value = value;
  }

  get createdAt() {
    return this._originalComment.createdAt;
  }
}
