import {
  EmptyTeacherDashboardFilters,
  EmptyTeacherDetailsFilters,
  EmptyWorkloadEmbeddedFilters,
  TeacherDashboardFilters,
  TeacherDetailsFilters,
  WorkloadEmbeddedFilters
} from '@insights/viewmodels';
import { Storage } from '@shared/services';
import { AppBaseStore, BaseStore } from '@shared/services/stores';
import { Dictionary } from 'lodash';
import { IComputedValue, computed } from 'mobx';

const TeacherDetailFiltersStorageKey = 'TeacherDetailsFilters';
const TeacherDashboardFiltersStorageKey = 'TeacherDashboardFilters';
const WorkloadEmbeddedFiltersStorageKey = 'WorkloadEmbeddedFilters';

export interface ConfigPreferences extends BaseStore {
  getTeacherDetailsFilters: (teacherId: string) => Promise<TeacherDetailsFilters>;
  saveTeacherDetailsFilters: (teacherId: string, filters: TeacherDetailsFilters) => Promise<void>;

  getTeacherDashboardFilters: (teacherId: string) => Promise<TeacherDashboardFilters>;
  saveTeacherDashboardFilters: (teacherId: string, filters: TeacherDashboardFilters) => Promise<void>;

  getWorkloadEmbeddedFilters: (teacherId: string) => Promise<WorkloadEmbeddedFilters>;
  saveWorkloadEmbeddedFilters: (teacherId: string, filters: WorkloadEmbeddedFilters) => Promise<void>;
}

export class AppConfigPreferences extends AppBaseStore implements ConfigPreferences {
  constructor(
    private readonly _configId: string,
    private readonly _storage: Storage
  ) {
    super(`AppConfigPrefences[${_configId}]`);
  }

  getTeacherDetailsFilters(teacherId: string): Promise<TeacherDetailsFilters> {
    return this.getMemoizedTeacherDetailsFilters(teacherId).get();
  }

  async saveTeacherDetailsFilters(teacherId: string, filters: TeacherDetailsFilters): Promise<void> {
    const storageKey = this.getStorageKeyForConfig(TeacherDetailFiltersStorageKey);
    const currentFilters = (await this._storage.get<Dictionary<string>>(storageKey)) ?? {};

    currentFilters[teacherId] = JSON.stringify(filters);

    await this._storage.set(storageKey, currentFilters);
    this.invalidate();
  }

  getTeacherDashboardFilters(teacherId: string): Promise<TeacherDashboardFilters> {
    return this.getMemoizedTeacherDashboardFilters(teacherId).get();
  }

  async saveTeacherDashboardFilters(teacherId: string, filters: TeacherDashboardFilters): Promise<void> {
    const storageKey = this.getStorageKeyForConfig(TeacherDashboardFiltersStorageKey);
    const currentFilters = (await this._storage.get<Dictionary<string>>(storageKey)) ?? {};

    currentFilters[teacherId] = JSON.stringify(filters);

    await this._storage.set(storageKey, currentFilters);
    this.invalidate();
  }

  getWorkloadEmbeddedFilters(teacherId: string): Promise<WorkloadEmbeddedFilters> {
    return this.getMemoizedWorkloadEmbeddedFilters(teacherId).get();
  }

  async saveWorkloadEmbeddedFilters(teacherId: string, filters: WorkloadEmbeddedFilters): Promise<void> {
    const storageKey = this.getStorageKeyForConfig(WorkloadEmbeddedFiltersStorageKey);
    const currentFilters = (await this._storage.get<Dictionary<string>>(storageKey)) ?? {};
    currentFilters[teacherId] = JSON.stringify(filters);

    await this._storage.set(storageKey, currentFilters);
    this.invalidate();
  }

  async clear() {
    await Promise.all([
      this.clearTeacherDetailsFilters(),
      this.clearTeacherDashboardFilters(),
      this.clearWorkloadEmbeddedFilters()
    ]);

    await super.clear();
  }

  private async clearTeacherDetailsFilters(): Promise<void> {
    const storageKey = this.getStorageKeyForConfig(TeacherDetailFiltersStorageKey);
    await this._storage.delete(storageKey);
  }

  private async clearTeacherDashboardFilters(): Promise<void> {
    const storageKey = this.getStorageKeyForConfig(TeacherDashboardFiltersStorageKey);
    await this._storage.delete(storageKey);
  }

  private async clearWorkloadEmbeddedFilters(): Promise<void> {
    const storageKey = this.getStorageKeyForConfig(WorkloadEmbeddedFiltersStorageKey);
    await this._storage.delete(storageKey);
  }

  private getStorageKeyForConfig(key: string): string {
    return this._configId != null ? `${key}.${this._configId}` : key;
  }

  private getMemoizedTeacherDetailsFilters = this.memoize(
    (teacherId: string): IComputedValue<Promise<TeacherDetailsFilters>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const storageKey = this.getStorageKeyForConfig(TeacherDetailFiltersStorageKey);
          const currentFilters = (await this._storage.get<Dictionary<string>>(storageKey)) ?? {};

          if (currentFilters != null) {
            const teacherFilters = currentFilters[teacherId];

            if (teacherFilters != null) {
              return JSON.parse(teacherFilters) as TeacherDetailsFilters;
            }
          }

          return EmptyTeacherDetailsFilters;
        })
      )
  );

  private getMemoizedTeacherDashboardFilters = this.memoize(
    (teacherId: string): IComputedValue<Promise<TeacherDashboardFilters>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const storageKey = this.getStorageKeyForConfig(TeacherDashboardFiltersStorageKey);
          const currentFilters = (await this._storage.get<Dictionary<string>>(storageKey)) ?? {};

          if (currentFilters != null) {
            const teacherFilters = currentFilters[teacherId];

            if (teacherFilters != null) {
              return JSON.parse(teacherFilters) as TeacherDashboardFilters;
            }
          }

          return EmptyTeacherDashboardFilters;
        })
      )
  );

  private getMemoizedWorkloadEmbeddedFilters = this.memoize(
    (teacherId: string): IComputedValue<Promise<WorkloadEmbeddedFilters>> =>
      computed(() =>
        this.withInvalidate(async () => {
          const storageKey = this.getStorageKeyForConfig(WorkloadEmbeddedFiltersStorageKey);
          const currentFilters = (await this._storage.get<Dictionary<string>>(storageKey)) ?? {};

          if (currentFilters != null) {
            const teacherFilters = currentFilters[teacherId];

            if (teacherFilters != null) {
              return JSON.parse(teacherFilters) as WorkloadEmbeddedFilters;
            }
          }

          return EmptyWorkloadEmbeddedFilters;
        })
      )
  );
}
