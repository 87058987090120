import { Storage } from '@shared/services';
import { runInAction } from 'mobx';
import { AppStorageProperty, StorageProperty } from './StorageProperty';

export type MapStorageProperty<TKey, TValue> = StorageProperty<Map<TKey, TValue>>;

export class AppMapStorageProperty<TKey, TValue>
  extends AppStorageProperty<Map<TKey, TValue>>
  implements MapStorageProperty<TKey, TValue>
{
  constructor(storage: Storage, storageKey: string, defaultValue: Map<TKey, TValue> = new Map<TKey, TValue>()) {
    super(storage, storageKey, defaultValue);
  }

  protected async loadFromStorage(): Promise<void> {
    const value = await this._storage.get<IterableIterator<[TKey, TValue]>>(this._storageKey);
    if (value != null) {
      runInAction(() => (this._value = new Map<TKey, TValue>(value)));
    }
  }

  protected async saveToStorage(): Promise<void> {
    const value = Array.from(this.value.entries());
    await this._storage.set(this._storageKey, value);
    this.invalidate();
  }
}
