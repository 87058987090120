import { NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { ManageBacConnectorStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  AppManageBacAccountSettingsViewModel,
  ManageBacAccountSettingsViewModel
} from './ManageBacAccountSettingsViewModel';

export interface ManageBacAccountSettingsDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly settings: IPromiseBasedObservable<ManageBacAccountSettingsViewModel>;
}

export class AppManageBacAccountSettingsDialogViewModel implements ManageBacAccountSettingsDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _manageBacStore: ManageBacConnectorStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get settings(): IPromiseBasedObservable<ManageBacAccountSettingsViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<ManageBacAccountSettingsViewModel> {
    const details = await this._manageBacStore.getManageBacAccountDetails(this.externalAccountId);

    return new AppManageBacAccountSettingsViewModel(
      this._localizationService,
      this._manageBacStore,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      details
    );
  }
}
