import { GrpcVeracrossV3ExternalAccountDetails, VeracrossV3ExternalAccountDetails } from '@shared/models/connectors';
import { VeracrossV3Transport } from '../../../transports';
import { ConnectorsStore, VeracrossV3ConnectorStore } from '../../interfaces';
import { AppBaseStore } from '../AppBaseStore';

export class AppVeracrossV3ConnectorStore extends AppBaseStore implements VeracrossV3ConnectorStore {
  constructor(
    private readonly _transport: VeracrossV3Transport,
    connectorStore: ConnectorsStore
  ) {
    super('AppVeracrossV3ConnectorStore', false, connectorStore);
  }

  async getVeracrossAccountDetails(externalAccountId: string): Promise<VeracrossV3ExternalAccountDetails> {
    const details = await this._transport.fetchVeracrossExternalAccountDetails(externalAccountId);

    return new GrpcVeracrossV3ExternalAccountDetails(details);
  }

  async createOrUpdateVeracrossAccount(
    configId: string,
    externalAccountId: string,
    accountName: string,
    clientId: string,
    clientSecret: string,
    schoolRoute: string,
    schoolYear: number,
    portalUrl: string
  ): Promise<void> {
    await this._transport.createOrUpdateVeracrossExternalAccount(
      externalAccountId,
      configId,
      clientId,
      clientSecret,
      schoolRoute,
      schoolYear,
      portalUrl,
      accountName
    );
    this.invalidate();
  }
}
