import DateIcon from '@mui/icons-material/CalendarToday';
import { Box, Button, IconButton, Popover, SxProps, Tooltip } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { startOfDay } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface OnboardingTargetDateProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  targetDate: Date;
  onChange: (targetDate: Date) => Promise<void>;
}

export const OnboardingTargetDate = observer((props: OnboardingTargetDateProps) => {
  const { localizationService } = useInsightsServices();
  const { sx } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>(undefined);
  const [targetDate, setTargetDate] = useState(props.targetDate);

  function changeDate(date: Date) {
    setTargetDate(date);
  }

  function togglePopover(anchor: HTMLElement) {
    setAnchorElement(anchorElement == null ? anchor : undefined);
  }

  function saveDate() {
    void props.onChange(targetDate);
    closePopover();
  }

  function closePopover() {
    setAnchorElement(undefined);
    setTargetDate(props.targetDate);
  }

  return (
    <>
      <Tooltip title={strings.setTargetDateTooltip}>
        <IconButton sx={sx} onClick={(e) => togglePopover(e.currentTarget)}>
          <DateIcon />
        </IconButton>
      </Tooltip>

      <Popover
        open={anchorElement != null}
        onClose={() => closePopover()}
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <StaticDatePicker
          value={targetDate}
          onChange={(e) => changeDate(startOfDay(e!))}
          slotProps={{ actionBar: { actions: [] } }}
        />
        <Box display="flex" flexDirection="row-reverse" p={1}>
          <Button onClick={() => closePopover()}>{strings.cancelLabel}</Button>
          <Button disabled={props.targetDate === targetDate} onClick={() => saveDate()}>
            {strings.saveLabel}
          </Button>
        </Box>
      </Popover>
    </>
  );
});
