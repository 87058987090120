export interface CreateSchoolStrings {
  accountsSection: string;
  adminAccountsSubSection: string;
  calendarNotice: string;
  calendarSection: string;
  commentsLabel: string;
  createSchoolButton: string;
  daysPerCycle: string;
  demoLabel: string;
  emailLabel: string;
  endDateLabel: string;
  firstNameLabel: string;
  generalSection: string;
  importsSection: string;
  incompleteAccountWarning: string;
  incompleteSectionWarning: string;
  invalidAccountWarning: string;
  invalidDaysPerCycleWarning: string;
  invalidDaysWarning: string;
  invalidMinutesPerPeriodWarning: string;
  invalidPeriodsPerDayWarning: string;
  invalidSchoolNameWarning: string;
  invalidSectionWarning: string;
  keepAllAdminAccountsLabel: string;
  keepAllImportSessionsLabel: string;
  keepAllLockedSectionsLabel: string;
  keepCalendarLabel: string;
  keepSectionSchedulesLabel: string;
  keepTeacherAccountsLabel: (count: number) => string;
  languageEnglish: string;
  languageFrench: string;
  languageLabel: string;
  lastNameLabel: string;
  loadingErrorMessage: string;
  loadingMessage: string;
  lockedSectionsSubSection: string;
  minutesPerPeriod: string;
  newCalendarSubSection: string;
  noAdminAccounts: string;
  noLockedSections: string;
  noOnboardingProcessesLabel: string;
  onboardingProcessNameLabel: string;
  onboardingSection: string;
  periodsPerDay: string;
  schoolAdminSection: string;
  schoolNameLabel: string;
  sectionShortTitleLabel: string;
  sectionsSection: string;
  sectionTitleLabel: string;
  startDateLabel: string;
  studyoBotSubSection: string;
  studyoStaffSubSection: string;
  tagsLabel: string;
  teacherAccountsSubSection: string;
  trainingSectionSubSection: string;
}

export const EnglishCreateSchoolStrings: CreateSchoolStrings = {
  accountsSection: 'Accounts',
  adminAccountsSubSection: 'Admin accounts',
  calendarNotice: 'You will be able to customize and add bell times later',
  calendarSection: 'Calendar and Bell Times',
  commentsLabel: 'Comments',
  createSchoolButton: 'Create School',
  daysPerCycle: 'Days per cycle',
  demoLabel: 'This is a demo school',
  emailLabel: 'Email',
  endDateLabel: 'Ending date',
  firstNameLabel: 'First name',
  generalSection: 'General',
  importsSection: 'Import Recipes',
  incompleteAccountWarning: 'First name, last name and email must all be set or be empty',
  incompleteSectionWarning: 'Title and short title must both be set or empty',
  invalidAccountWarning: 'First name, last name and email cannot be empty',
  invalidDaysPerCycleWarning: 'The value can be from 1 to 90',
  invalidDaysWarning: 'The end day must come after the start day',
  invalidMinutesPerPeriodWarning: 'The minimum value is 5 and the total minutes x periods must not exceed 16 hours',
  invalidPeriodsPerDayWarning: 'The value can be from 1 to 12',
  invalidSchoolNameWarning: 'A school name is required',
  invalidSectionWarning: 'Title and short title cannot be empty',
  keepAllAdminAccountsLabel: 'Keep all admin accounts',
  keepAllImportSessionsLabel: 'Keep all import recipes',
  keepAllLockedSectionsLabel: 'Keep all locked sections',
  keepCalendarLabel: 'Keep existing calendar',
  keepSectionSchedulesLabel: 'Keep section schedules',
  keepTeacherAccountsLabel: (count) => `Keep other existing teacher accounts (${count})`,
  languageEnglish: 'English',
  languageFrench: 'French',
  languageLabel: 'Language',
  lastNameLabel: 'Last name',
  loadingErrorMessage: 'Unexpected error',
  loadingMessage: 'Loading…',
  lockedSectionsSubSection: 'Locked Sections to Keep',
  minutesPerPeriod: 'Minutes per period',
  newCalendarSubSection: 'New calendar and bell times',
  noAdminAccounts: 'No admin accounts in the original school',
  noLockedSections: 'No locked sections in the original school',
  noOnboardingProcessesLabel: 'No onboarding process found',
  onboardingProcessNameLabel: 'Onboarding process',
  onboardingSection: 'Onboarding',
  periodsPerDay: 'Periods per day',
  schoolAdminSection: 'School Admin',
  schoolNameLabel: 'School name',
  sectionShortTitleLabel: 'Short title',
  sectionsSection: 'Sections',
  sectionTitleLabel: 'Title',
  startDateLabel: 'Starting date',
  studyoBotSubSection: 'Studyo Bot teacher (training)',
  studyoStaffSubSection: 'Studyo Staff Admin',
  tagsLabel: 'Tags',
  teacherAccountsSubSection: 'Teacher accounts',
  trainingSectionSubSection: 'Training Section'
};

export const FrenchCreateSchoolStrings: CreateSchoolStrings = {
  accountsSection: 'Fiches utilisateur',
  adminAccountsSubSection: 'Fiches administrateur',
  calendarNotice: 'Vous pourrez ajuster et ajouter des horaires plus tard',
  calendarSection: 'Calendrier et horaires de périodes',
  commentsLabel: 'Commentaires',
  createSchoolButton: 'Créer l’école',
  daysPerCycle: 'Jours par cycle',
  demoLabel: 'Ceci est une école démo',
  emailLabel: 'Courriel',
  endDateLabel: 'Date de fin',
  firstNameLabel: 'Prénom',
  generalSection: 'Général',
  importsSection: 'Recettes d’importation',
  incompleteAccountWarning: 'Le prénom, nom de famille et courriel doivent tous contenir une valeur ou être vides',
  incompleteSectionWarning: 'Le titre et titre court doivent tous les deux contenir une valeur ou être vides',
  invalidAccountWarning: 'Le prénom, nom de famille et courriel ne peuvent être vides',
  invalidDaysPerCycleWarning: 'La valeur doit être incluse entre 1 et 90',
  invalidDaysWarning: 'La date de fin doit suivre la date de début',
  invalidMinutesPerPeriodWarning: 'La valeur minimum est 5 et le total minutes x périodes ne peut dépasser 16 heures',
  invalidPeriodsPerDayWarning: 'La valeur doit être incluse entre 1 et 12',
  invalidSchoolNameWarning: 'Un nom d’école est requis',
  invalidSectionWarning: 'Le titre et titre court ne peuvent être vides',
  keepAllAdminAccountsLabel: 'Conserver toutes les fiches administrateur',
  keepAllImportSessionsLabel: 'Conserver toutes les recettes d’importation',
  keepAllLockedSectionsLabel: 'Conserver tous les groupes-matière verrouillés',
  keepCalendarLabel: 'Conserver le calendrier',
  keepSectionSchedulesLabel: 'Conserver les horaires de ces groupes-matière',
  keepTeacherAccountsLabel: (count) => `Conserver les autres fiches enseignant (${count})`,
  languageEnglish: 'Anglais',
  languageFrench: 'Français',
  languageLabel: 'Langue',
  lastNameLabel: 'Nom de famille',
  loadingErrorMessage: 'Erreur inattendue',
  loadingMessage: 'Chargement…',
  lockedSectionsSubSection: 'Groupes-matières verrouillés à conserver',
  minutesPerPeriod: 'Minutes par période',
  newCalendarSubSection: 'Nouveau calendrier et nouvel horaire',
  noAdminAccounts: 'Aucun administrateur dans l’école source',
  noLockedSections: 'Aucun groupe-matière verrouillé dans l’école source',
  noOnboardingProcessesLabel: 'Aucun processus d’embarquement trouvé',
  onboardingProcessNameLabel: 'Processus d’embarquement',
  onboardingSection: 'Embarquement',
  periodsPerDay: 'Périodes par jour',
  schoolNameLabel: 'Nom de l’école',
  schoolAdminSection: 'Administrateur de l’école',
  sectionShortTitleLabel: 'Titre court',
  sectionsSection: 'Groupes-matière',
  sectionTitleLabel: 'Titre',
  startDateLabel: 'Date de début',
  studyoBotSubSection: 'Enseignant Studyo Bot (formation)',
  studyoStaffSubSection: 'Adminsitrateur employé de Studyo',
  tagsLabel: 'Étiquettes',
  teacherAccountsSubSection: 'Fiches enseignant',
  trainingSectionSubSection: 'Groupe-matière de formation'
};
