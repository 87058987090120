import { AlertService, ContentService, NavigationService, SettingsStore } from '@insights/services';
import { SectionUtils, caseInsensitiveAccentInsensitiveCompare } from '@insights/utils';
import { AccountUtils, ContentDefinitionUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { AccountModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { CalendarStore, ContentStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import _, { Dictionary, chain, flatMap, groupBy, uniq } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  AppPaginatedViewModel,
  AppPaginationViewModel,
  PaginatedViewModel,
  PaginationViewModel
} from './PaginatedViewModel';

export interface PublishedTasksBySectionInfo {
  readonly minDay: Day;
  readonly maxDay: Day;
  readonly sections: PublishedTasksBySectionSectionInfo[];
  readonly schoolDays: SchoolDay[];
}

export interface PublishedTasksBySectionSectionInfo {
  readonly section: SectionModel;
  readonly students: Map<string, AccountModel>;
}

export interface PublishedTasksBySectionPageInfo {
  readonly schoolDays: SchoolDay[];
  readonly dayInfos: PublishedTasksBySectionColumnInfo[];
  readonly weekInfos: PublishedTasksBySectionColumnInfo[];
}

export interface PublishedTasksBySectionColumnInfo {
  readonly sectionId: string;
  readonly schoolDay?: SchoolDay;
  readonly publishedTasks: ContentDefinitionModel[];
}

export interface PublishedTasksBySectionViewModel extends PaginatedViewModel {
  readonly configId: string;
  readonly sectionIds: string[];

  readonly data: IPromiseBasedObservable<PublishedTasksBySectionInfo>;
  readonly pageData: IPromiseBasedObservable<PublishedTasksBySectionPageInfo>;

  importantTasksOnly: boolean;

  readonly isExporting: boolean;

  editAssessmentPlanningDates: () => Promise<void>;
  showDetail: (
    configId: string,
    section: SectionModel,
    sectionStudentCount: number,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    tasks: ContentDefinitionModel[]
  ) => Promise<void>;
  exportTasksByStudentToCsv: () => Promise<void>;
}

export class AppPublishedTasksBySectionViewModel
  extends AppPaginatedViewModel
  implements PublishedTasksBySectionViewModel
{
  @observable private _isExporting = false;

  constructor(
    public readonly configId: string,
    public readonly sectionIds: string[],
    private readonly _localizationService: LocalizationService,
    private readonly _alertService: AlertService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _calendarStore: CalendarStore,
    private readonly _settingsStore: SettingsStore,
    private readonly _navigationService: NavigationService,
    private readonly _contentService: ContentService,
    private readonly _contentStore: ContentStore,
    paginationViewModel?: PaginationViewModel
  ) {
    super(paginationViewModel);
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<PublishedTasksBySectionInfo> {
    return fromPromise(this.loadData());
  }

  @computed
  get pageData(): IPromiseBasedObservable<PublishedTasksBySectionPageInfo> {
    return fromPromise(this.loadPageData());
  }

  @computed
  get importantTasksOnly(): boolean {
    return this._settingsStore.workloadPreferences.importantTasksOnly;
  }

  set importantTasksOnly(value: boolean) {
    this._settingsStore.workloadPreferences.importantTasksOnly = value;
  }

  @computed
  get isExporting(): boolean {
    return this._isExporting;
  }

  async editAssessmentPlanningDates(): Promise<void> {
    const config = await this._schoolYearConfigurationStore.getConfig(this.configId);
    await this._navigationService.navigateToEditSchoolYearConfigurationAssessmentPlanning(config);
  }

  async showDetail(
    configId: string,
    section: SectionModel,
    sectionStudentCount: number,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    tasks: ContentDefinitionModel[]
  ): Promise<void> {
    await this._navigationService.navigateToPublishedTasksBySectionDetail(
      configId,
      section,
      sectionStudentCount,
      fromDay,
      toDay,
      tasks
    );
  }

  @action
  async exportTasksByStudentToCsv(): Promise<void> {
    this._isExporting = true;

    const strings = this._localizationService.localizedStrings.insights.viewModels.metrics;
    const contentStrings = this._localizationService.localizedStrings.models.contents;
    const csvStrings = this._localizationService.localizedStrings.insights.viewModels.csvExports;

    try {
      const [data, pageData] = await Promise.all([this.data, this.pageData]);
      const firstDay = pageData.schoolDays[0]?.day;
      const lastDay = pageData.schoolDays[pageData.schoolDays.length - 1]?.day;

      if (firstDay == null || lastDay == null) {
        return;
      }

      const [tasks, studentsById, sectionsById, teachersById] = await Promise.all([
        this.getStudentTasks(data, firstDay, lastDay, this.importantTasksOnly),
        this._schoolYearConfigurationStore.getStudentsById(this.configId, false),
        this._schoolYearConfigurationStore.getSectionsById(this.configId),
        this._schoolYearConfigurationStore.getTeachersById(this.configId, false)
      ]);

      if (tasks.length === 0) {
        await this._alertService.showMessage({
          title: strings.noTasksToExportTitle,
          message: strings.noTasksToExport
        });
        return;
      }

      const tasksInfo = _.orderBy(
        tasks
          .map((task) => ({
            task,
            student: studentsById[task.ownerId],
            section: sectionsById[task.sectionId]
          }))
          .map((info) => ({
            [csvStrings.studentName]: AccountUtils.getDisplayLastFirstName(
              info.student,
              info.student?.managedIdentifier ?? ''
            ),
            [csvStrings.dueDay]: info.task.dueDay.asString,
            [csvStrings.title]: ContentDefinitionUtils.getDisplayTitleForContent(
              info.task,
              this._localizationService.localizedStrings
            ),
            [csvStrings.workloadLevel]: contentStrings.workloadLevelString(info.task.workloadLevel),
            [csvStrings.sectionTitle]: SectionUtils.formatTitle(info.section, info.section.importId),
            [csvStrings.teacherName]: AccountUtils.getDisplayLastFirstName(
              teachersById[info.section.defaultTeacherId],
              ''
            ),
            [csvStrings.period]: info.task.duePeriodTag
          })),
        (info) => [info[csvStrings.studentName], info[csvStrings.dueDay], info[csvStrings.period]]
      );

      const filename = `tasks-by-student-${firstDay.asDateString}-${lastDay.asDateString}`;

      const csvConfig = mkConfig({
        filename,
        useKeysAsHeaders: true
      });

      download(csvConfig)(generateCsv(csvConfig)(tasksInfo));
    } catch (error) {
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isExporting = false));
    }
  }

  private async loadData(): Promise<PublishedTasksBySectionInfo> {
    const [config, sections, schoolDays] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this.configId),
      this._schoolYearConfigurationStore.getSectionsByIds(this.configId, this.sectionIds),
      this._calendarStore.getSchoolDays(this.configId, this.sectionIds)
    ]);

    if (this.pagination == null) {
      this.setPagination(new AppPaginationViewModel(config.startDay, config.endDay));
    }

    return {
      minDay: config.startDay,
      maxDay: config.endDay,
      schoolDays: schoolDays,
      sections: (
        await Promise.all(
          sections.map<Promise<PublishedTasksBySectionSectionInfo>>(async (section) => {
            const studentsForSection = await this._schoolYearConfigurationStore.getStudentsForSection(
              this.configId,
              section,
              false
            );

            return {
              section,
              students: new Map(studentsForSection.map((s) => [s.id, s]))
            };
          })
        )
      ).sort((a, b) => {
        if (!a.section.title.trim()) {
          return 1;
        }
        if (!b.section.title.trim()) {
          return -1;
        }

        return (
          caseInsensitiveAccentInsensitiveCompare(
            SectionUtils.formatTitle(a.section),
            SectionUtils.formatTitle(b.section)
          ) ||
          caseInsensitiveAccentInsensitiveCompare(a.section.sectionNumber, b.section.sectionNumber, undefined, true)
        );
      })
    };
  }

  private async loadPageData(): Promise<PublishedTasksBySectionPageInfo> {
    const pageRange = this.pagination?.currentPage;

    if (pageRange == null) {
      return {
        schoolDays: [],
        dayInfos: [],
        weekInfos: []
      };
    }

    const importantTasksOnly = this.importantTasksOnly;
    const data = await this.data;

    const studentTasks = await this.getStudentTasks(data, pageRange.startDay, pageRange.endDay, importantTasksOnly);

    // From those tasks, extrack the masterContentId (we are interested in loading those).
    const masterContentIds = chain(studentTasks)
      .map((task) => task.masterContent!.id)
      .uniq()
      .value();

    // Load all the master contents
    const masterTasks = new Map(
      (await this._contentStore.getContentsForIds(masterContentIds)).map((task) => [task.id, task])
    );

    const schoolDays = data.schoolDays.filter((sd) => sd.day.isWithin(pageRange.startDay, pageRange.endDay));
    const publishedTasksByDay = groupBy(studentTasks, (t) => t.dueDay.asString);

    return {
      schoolDays: schoolDays,
      dayInfos: this.computeDayInfos(schoolDays, data.sections, publishedTasksByDay, masterTasks),
      weekInfos: this.computeWeekInfos(data.sections, studentTasks, masterTasks)
    };
  }

  private computeDayInfos(
    schoolDays: SchoolDay[],
    sections: PublishedTasksBySectionSectionInfo[],
    tasksByDay: Dictionary<ContentDefinitionModel[]>,
    masterTasks: Map<string, ContentDefinitionModel>
  ): PublishedTasksBySectionColumnInfo[] {
    return flatMap(
      schoolDays.map<PublishedTasksBySectionColumnInfo[]>((sd) => {
        return sections.map<PublishedTasksBySectionColumnInfo>((sectionInfo) => {
          // Get the tasks for the day
          const tasksForDay = tasksByDay[sd.day.asString] || [];

          return {
            schoolDay: sd,
            sectionId: sectionInfo.section.id,
            publishedTasks: chain(tasksForDay)
              .filter((task) => sectionInfo.students.has(task.ownerId))
              .map((task) => masterTasks.get(task.masterContent!.id))
              .compact()
              .uniqBy((task) => task.id)
              .filter((task) => task.isPublished)
              .value()
          };
        });
      })
    );
  }

  private async getStudentTasks(
    data: PublishedTasksBySectionInfo,
    startDay: Day,
    endDay: Day,
    importantTasksOnly: boolean
  ): Promise<ContentDefinitionModel[]> {
    // Get the student ids of all the students of all the displayed sections
    const studentIds = uniq(flatMap(data.sections.map((section) => [...section.students.keys()])));

    // Get the selected section ids of those same students. The API returns active tasks even if
    // their section is not in the student's selection.
    const studentsSectionIds = uniq(
      flatMap(flatMap(data.sections.map((section) => [...section.students.values()])), (a) => a.selectedSectionIds)
    );

    // Get all the replica tasks for the students. This is all the task for the week.
    return await this._contentService.getSlaveTasks(
      this.configId,
      studentIds,
      studentsSectionIds,
      startDay,
      endDay,
      importantTasksOnly
    );
  }
  private computeWeekInfos(
    sections: PublishedTasksBySectionSectionInfo[],
    tasksForWeek: ContentDefinitionModel[],
    masterTasks: Map<string, ContentDefinitionModel>
  ): PublishedTasksBySectionColumnInfo[] {
    return sections.map<PublishedTasksBySectionColumnInfo>((sectionInfo) => {
      return {
        sectionId: sectionInfo.section.id,
        publishedTasks: chain(tasksForWeek)
          .filter((task) => sectionInfo.students.has(task.ownerId))
          .map((task) => masterTasks.get(task.masterContent!.id))
          .compact()
          .uniqBy((task) => task.id)
          .filter((task) => task.isPublished)
          .value()
      };
    });
  }
}
