import * as am4core from '@amcharts/amcharts4/core';
import { Theme } from '@mui/material';

export function createChartTitle(theme: Theme, marginBottom = 0): am4core.Label {
  const label = new am4core.Label();
  label.fontSize = theme.typography.h6.fontSize;
  label.fontWeight = '300';
  label.opacity = 0.54; // Matches the current material UI theme secondary text
  label.align = 'center';
  label.marginBottom = marginBottom;

  return label;
}

export function createChartSubTitle(theme: Theme, marginBottom = 0): am4core.Label {
  const label = new am4core.Label();
  label.fontSize = theme.typography.subtitle2.fontSize;
  label.fontWeight = '300';
  label.marginBottom = marginBottom;
  label.opacity = 0.54; // Matches the current material UI theme secondary text

  return label;
}
