import { MicrosoftTeamsAccountViewModel } from '@insights/viewmodels';
import { Link, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { InsightsButton } from '../InsightsButton';
import { Column, Expanded, Row } from '../layout';

export interface MicrosoftTeamsAccountProps {
  sx?: SxProps;
  className?: string;
  viewModel: MicrosoftTeamsAccountViewModel;
}

export const MicrosoftTeamsAccount = observer((props: MicrosoftTeamsAccountProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Column sx={sx} className={className}>
      <Typography sx={{ mb: 1 }}>{strings.microsoftTeamsAuthorizationMessage}</Typography>

      {viewModel.isExistingAccount && (
        <Typography variant="body2" sx={{ color: (theme) => theme.palette.primary.main, mb: 1 }}>
          {strings.microsoftTeamsAuthorizationExistingAccount}
        </Typography>
      )}

      <Link variant="button" href={viewModel.authorizationLink}>
        {strings.microsoftTeamsAuthorizationButton}
      </Link>

      <Row sx={{ m: 2 }}>
        <Expanded />

        <InsightsButton sx={{ m: 1 }} onClick={() => viewModel.cancelChanges()}>
          {strings.cancel}
        </InsightsButton>
      </Row>
    </Column>
  );
});
