export interface ContentDistributeStrings {
  readonly cancel: string;
  readonly distribute: string;

  readonly distributeToOptionsTitle: string;
  readonly distributeSameDayOptionTitle: string;
  readonly distributeSamePeriodOptionTitle: string;
  readonly distributeSameOccurrenceOptionTitle: string;
  readonly distributeCancelOptionTitle: string;

  readonly savingTitle: string;
  readonly savingDescription: string;
  readonly savedTitle: string;
  readonly savedDescription: string;
  readonly saveErrorTitle: string;
  readonly saveErrorDescription: string;
  readonly savePermanentErrorDescription: string;
}

export const EnglishContentDistributeStrings: ContentDistributeStrings = {
  cancel: 'Cancel',
  distribute: 'Distribute',

  distributeToOptionsTitle: 'Distribute on…',
  distributeSameDayOptionTitle: 'Same day',
  distributeSamePeriodOptionTitle: 'Same period',
  distributeSameOccurrenceOptionTitle: 'Same occurrence',
  distributeCancelOptionTitle: 'Cancel',

  savingTitle: 'Please wait…',
  savingDescription: 'Distributing this task.',
  savedTitle: 'Success',
  savedDescription: 'The task was distributed successfully!',
  saveErrorTitle: 'Oops, something went wrong!',
  saveErrorDescription: 'An error occurred while distributing this task. Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented the save. Please reload and try again.'
};

export const FrenchContentDistributeStrings: ContentDistributeStrings = {
  cancel: 'Annuler',
  distribute: 'Distribuer',

  distributeToOptionsTitle: 'Distribuer…',
  distributeSameDayOptionTitle: 'Le même jour',
  distributeSamePeriodOptionTitle: 'La même période',
  distributeSameOccurrenceOptionTitle: 'La même occurrence',
  distributeCancelOptionTitle: 'Annuler',

  savingTitle: 'Veuillez patienter…',
  savingDescription: 'Distribution de cette tâche en cours',
  savedTitle: 'Succès',
  savedDescription: 'La tâche a été distribuée!',
  saveErrorTitle: 'Oups, une erreur est survenue!',
  saveErrorDescription: 'La tâche n’a pu être distribuée. Veuillez réessayer.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la sauvegarde. Veuillez rafraichir et réessayer.'
};
