import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface BaseProcessingViewModel {
  readonly hasOperation: boolean;
  readonly isBusy: boolean;
  readonly errorMessage?: string;

  dismissError(): void;
}

export abstract class AppBaseProcessingViewModel implements BaseProcessingViewModel {
  @observable private _hasOperation = false;
  @observable private _isBusy = false;
  @observable private _errorMessage?: string;

  constructor() {
    makeObservable(this);
  }

  @computed
  get hasOperation() {
    // This is not calculated from isBusy || errorMessage, to avoid hiding the progress indicator before the overlay.
    return this._hasOperation;
  }

  @computed
  get isBusy() {
    return this._isBusy;
  }

  @computed
  get errorMessage() {
    return this._errorMessage;
  }

  @action
  dismissError() {
    if (!this._isBusy) {
      // Just hide to avoid flicker!
      this._hasOperation = false;
    }
  }

  @action
  protected async process(processing: () => Promise<void>): Promise<void> {
    // Reset values, then show.
    this._errorMessage = undefined;
    this._isBusy = true;
    this._hasOperation = true;

    try {
      await processing();

      runInAction(() => {
        // Just hide to avoid flicker!
        this._hasOperation = false;
      });
    } catch (error) {
      runInAction(() => {
        this._isBusy = false;
        this._errorMessage = (error as Error).message;
      });
    }
  }
}
