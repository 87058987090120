import { SchemaSuffix as PBSchemaSuffix } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { computed, makeObservable } from 'mobx';
import { BaseModel } from '../../Model';
import { SchemaImportOption, SchemaSuffix } from '../interfaces';
import { GrpcSchemaImportOption } from './GrpcSchemaImportOption';

export class GrpcSchemaSuffix extends BaseModel<PBSchemaSuffix> implements SchemaSuffix {
  constructor(pb: PBSchemaSuffix) {
    super(pb);
    makeObservable(this);
  }

  get name(): string {
    return this._pb.name;
  }

  get localizedDescription(): string {
    return this._pb.description;
  }

  get excludedFieldNames(): string[] {
    return this._pb.excludedFieldNames;
  }

  @computed
  get importOptions(): SchemaImportOption[] {
    return this._pb.importOptions.map((o) => new GrpcSchemaImportOption(o));
  }
}
