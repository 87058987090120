import { PageHeaderBar } from '@insights/components';
import { ParentDashboardViewModel } from '@insights/viewmodels';
import { Box, Button, Link, Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect } from 'react';
import { useInsightsServices } from '../../../../UseInsightsServicesHook';

export interface ParentDashboardProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: ParentDashboardViewModel;
}

export const ParentDashboard = observer((props: ParentDashboardProps) => {
  const { analyticsService, localizationService } = useInsightsServices();
  const { className, style, sx } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.dashboards;
  const studyoUrl = window.STUDYO_ENV?.studyoUrl ?? '';

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Parent Dashboard' });
  });

  return (
    <Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <Box padding={2} flex={1} overflow="auto">
        <Stack spacing={2} alignItems="start">
          <Typography variant="h4">{strings.connectedParentTitle}</Typography>
          <Typography>
            {strings.connectedParentMessage}
            <Link href={studyoUrl}>studyo.app</Link>.
          </Typography>
          <Button variant="contained" href={studyoUrl}>
            {strings.openStudyoButton}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
});
