import { SyncResults } from '@shared/models/connectors';

export interface ConnectorViewModelsStrings {
  confirmContactOwnerMessage: (email: string) => string;
  confirmContactOwnerTitle: string;
  customExpressionLabel: string;
  endOfTitleExpressionLabel: string;
  enterAccessTokenAgain: string;
  enterAuthenticationTokenAgain: string;
  enterClientSecretAgain: string;
  enterConsumerSecretAgain: string;
  enterPasswordAgain: string;
  failedToSync: string;
  incompleteKindMappings: string;
  invalidSyncPauseResumeTimesMessage: string;
  invalidSyncPauseResumeTimesTitle: string;
  lastCharacterExpressionLabel: string;
  lastSuccessfullUpdate: string;
  lastWordExpressionLabel: string;
  lastWordInParenthesisExpressionLabel: string;
  missingOwner: string;
  noChangesToSaveError: string;
  ownerContactedMessage: string;
  ownerContactedTitle: string;
  runAutoMatchResultsMessage: (added: number, confirmed: number) => string;
  runAutoMatchResultsTitle: string;
  serverError: string;
  tasksForgottenMessage: (count: bigint) => string;
  tasksForgottenTitle: string;
  testAssociationResultsMessage: (updated: number, removed: number, total: number, results: string[]) => string;
  testAssociationResultsTitle: string;
  unexpectedError: string;
  unexpectedErrorTitle: string;
  unsavedChangesWarning: string;

  getQueueMessage(position: number): string;
  getSyncMessage(results: SyncResults): string;
}

export const EnglishConnectorViewModelsStrings: ConnectorViewModelsStrings = {
  confirmContactOwnerMessage: (email) =>
    `Are you sure you wish to send a notification email to [${email}] about a Google Classroom connection issue?`,
  confirmContactOwnerTitle: 'Please confirm',
  customExpressionLabel: 'Custom expression',
  endOfTitleExpressionLabel: 'Match the end of the title (no space required)',
  enterAccessTokenAgain: 'You must enter the access token again.',
  enterAuthenticationTokenAgain: 'You must enter the authentication token again.',
  enterClientSecretAgain: 'You must enter the client secret again.',
  enterConsumerSecretAgain: 'You must enter the consumer secret again.',
  enterPasswordAgain: 'You must enter the password again.',
  failedToSync: 'Failed to queue synchronization',
  incompleteKindMappings: 'One or more kind mappings are incomplete.',
  invalidSyncPauseResumeTimesMessage: 'Either none or both the pause and resume times must be set.',
  invalidSyncPauseResumeTimesTitle: 'Invalid times',
  lastCharacterExpressionLabel: 'Match only the last character in the title (including emojis)',
  lastSuccessfullUpdate: 'Last succeeded ',
  lastWordExpressionLabel: 'Match the last word in the title (no parenthesis)',
  lastWordInParenthesisExpressionLabel: 'Match the last word, in parenthesis, in the title',
  missingOwner: 'At least one association is missing a teacher',
  noChangesToSaveError: 'There are no changes to save.',
  ownerContactedMessage: 'An email was sent to the owner.',
  ownerContactedTitle: 'Success',
  runAutoMatchResultsMessage: (a, c) =>
    `${a} assocation${a === 1 ? ' was added' : 's were added'} and ${c} ${c === 1 ? 'was confirmed' : 'were confirmed'}`,
  runAutoMatchResultsTitle: 'Auto-match completed',
  serverError: 'Server error: ',
  tasksForgottenMessage: (count) =>
    count === 0n
      ? 'There were no known tasks so far.'
      : count === 1n
        ? '1 task was removed from the list of known tasks. It will get updated on the next sync.'
        : `${count} tasks were removed from the list of known tasks. They will get updated on the next sync.`,
  tasksForgottenTitle: 'Done',
  testAssociationResultsMessage: (updated, removed, total, results) =>
    `Updated: ${updated}, removed: ${removed}, total: ${total}\n\n${results.join('\n')}`,
  testAssociationResultsTitle: 'Results',
  unexpectedError: 'An unexpected error occurred.',
  unexpectedErrorTitle: 'Oops',
  unsavedChangesWarning: 'You have unsaved changes. Are you sure you want to cancel?',

  getQueueMessage: (position: number) => {
    return `In queue at #${position}`;
  },
  getSyncMessage: (results: SyncResults) => {
    return `Updated ${results.updated} / ${results.total} (removed ${results.removed})`;
  }
};

export const FrenchConnectorViewModelsStrings: ConnectorViewModelsStrings = {
  confirmContactOwnerMessage: (email) =>
    `Êtes-vous certain de vouloir envoyer un courriel de notification à [${email}] au sujet d’une connexion Google Classroom brisée?`,
  confirmContactOwnerTitle: 'Veuillez confirmer',
  customExpressionLabel: 'Expression personnalisée',
  endOfTitleExpressionLabel: 'La fin du titre (pas d’espace requis)',
  enterAccessTokenAgain: 'Vous devez entrer le jeton d’accès à nouveau.',
  enterAuthenticationTokenAgain: 'Vous devez entrer le jeton d’authentification à nouveau.',
  enterClientSecretAgain: 'Vous devez entrer le secret client à nouveau.',
  enterConsumerSecretAgain: 'Vous devez entrer le secret partagé à nouveau.',
  enterPasswordAgain: 'Vous devez entrer le mot de passe à nouveau.',
  failedToSync: 'Échec au lancement de la synchronisation',
  incompleteKindMappings: 'Au moins une transposition de type de tâche est incomplète.',
  invalidSyncPauseResumeTimesMessage:
    'Aucune ou les deux heures de pause et reprise de synchronisation doivent être assignées.',
  invalidSyncPauseResumeTimesTitle: 'Heures invalides',
  lastCharacterExpressionLabel: 'Le dernier caractère dans le titre (incluant les émojis)',
  lastSuccessfullUpdate: 'Dernier succès ',
  lastWordExpressionLabel: 'Le dernier mot dans le titre (sans parenthèses)',
  lastWordInParenthesisExpressionLabel: 'Le dernier mot dans le titre, entre parenthèses',
  missingOwner: 'Il manque un enseignant à au moins une association',
  noChangesToSaveError: 'Aucun changement à enregistrer.',
  ownerContactedMessage: 'Un courriel de notification fut envoyé.',
  ownerContactedTitle: 'Succès',
  runAutoMatchResultsMessage: (a, c) =>
    `${a} assocation${a < 2 ? ' fut ajoutée' : 's furent ajoutées'} et ${c} ${c < 2 ? 'fut confirmée' : 'furent confirmées'}`,
  runAutoMatchResultsTitle: 'Auto-correspondance complétée',
  serverError: 'Erreur du serveur: ',
  tasksForgottenMessage: (count) =>
    count === 0n
      ? 'Il n’existe aucune tâche importée jusqu’à présent.'
      : count === 1n
        ? '1 tâche fut retirée de la liste des tâches connues. Elle sera mise à jour à la prochaine synchronisation.'
        : `${count} tâches furent retirées de la liste des tâches connues. Elles seront mises à jour à la prochaine synchronisation.`,
  tasksForgottenTitle: 'Complété',
  testAssociationResultsMessage: (updated, removed, total, results) =>
    `Mis à jour: ${updated}, retiré: ${removed}, total: ${total}\n\n${results.join('\n')}`,
  testAssociationResultsTitle: 'Résultats',
  unexpectedError: 'Une erreur inattendue est survenue.',
  unexpectedErrorTitle: 'Oups',
  unsavedChangesWarning: 'Certaines données n’ont pas été enregistrées. Désirez-vous vraiment annuler ?',

  getQueueMessage: (position: number) => {
    return `En file à #${position}`;
  },
  getSyncMessage: (results: SyncResults) => {
    return `${results.updated} / ${results.total} mis à jour (${results.removed} retiré${
      results.removed > 1 ? 's' : ''
    })`;
  }
};
