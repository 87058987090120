import { AccountService, AlertService } from '@insights/services';
import { EditableOnboardingAnswer } from '@shared/models/onboarding/implementations';
import {
  OnboardingAnswer,
  OnboardingQuestion,
  OnboardingText,
  QuestionChoice
} from '@shared/models/onboarding/interfaces';
import { OnboardingQuestionKind } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { OnboardingStepViewModel } from './OnboardingStepViewModel';
import { DefaultOnboardingVariableResolver, OnboardingVariableResolver } from './OnboardingVariableResolver';

export interface OnboardingQuestionViewModel {
  readonly id: string;
  readonly questionId: string;
  readonly templateName: string;
  readonly description: OnboardingText[];
  readonly kind: OnboardingQuestionKind;
  readonly choices: QuestionChoice[];
  readonly answer: EditableOnboardingAnswer;
  readonly lastYearAnswer: OnboardingAnswer | undefined;
  readonly canShowPreviousAnswers: boolean;
  readonly previousAnswers: OnboardingAnswer[];
  readonly isRequired: boolean;

  readonly isLocked: boolean;
  readonly dependantQuestionName: string;
  readonly dependantQuestionAnswer: string;

  readonly isDisabled: boolean;
  readonly canForceAnswer: boolean;
  readonly hasChanged: boolean;
  readonly isUpdating: boolean;
  readonly hasIssue: boolean;
  readonly clientId?: string;
  readonly canEditInLargerBox: boolean;

  readonly variableResolver: OnboardingVariableResolver;

  forceEdition: () => void;
  forceSave: () => Promise<void>;
  navigateToEditQuestion: () => Promise<void>;
  renameQuestion: () => Promise<void>;
  copyQuestion: () => Promise<void>;
  viewSensitiveAnswer: () => Promise<void>;
  deleteQuestion: () => Promise<void>;
  uploadFile: (dataUrl: string, fileName?: string) => Promise<void>;
  clearFile: () => Promise<void>;
}

export class AppOnboardingQuestionViewModel implements OnboardingQuestionViewModel {
  @observable private readonly _question: OnboardingQuestion;
  @observable private readonly _answer: EditableOnboardingAnswer;
  @observable private _forcedEdition = false;
  @observable private _isUpdating = false;
  @observable private _hasIssue = false;
  private _timeout: ReturnType<typeof setTimeout> | undefined;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _accountService: AccountService,
    private readonly _localizationService: LocalizationService,
    private readonly _alertService: AlertService,
    private readonly _configId: string,
    private readonly _stepViewModel: OnboardingStepViewModel,
    question: OnboardingQuestion
  ) {
    makeObservable(this);
    this._question = question;
    this._answer =
      question.answer != null
        ? new EditableOnboardingAnswer(question.answer)
        : EditableOnboardingAnswer.createNew(question.kind);

    reaction(
      () => ({
        hasChanges: this._answer.hasFieldChanges,
        isForced: this._forcedEdition,
        iterations: this._answer.changeIteration
      }),
      (info) => {
        clearTimeout(this._timeout);

        if (info.hasChanges && !info.isForced) {
          this._timeout = setTimeout(() => void this.saveAnswer(), 1000);
        }

        this._hasIssue = false;
      }
    );
  }

  @computed
  get id() {
    return this._question.templateName;
  }

  @computed
  get questionId() {
    return this._question.id;
  }

  @computed
  get templateName() {
    return this._question.templateName;
  }

  @computed
  get description() {
    return this._question.description;
  }

  @computed
  get kind() {
    return this._question.kind;
  }

  @computed
  get choices() {
    return this._question.choices;
  }

  @computed
  get answer() {
    return this._answer;
  }

  @computed
  get lastYearAnswer() {
    // We only provide last year's answer when steps are in progress.
    return this._stepViewModel.status === 'in-progress' ? this._question.lastYearAnswer : undefined;
  }

  @computed
  get canShowPreviousAnswers() {
    return this._question.kind !== 'sensitive-simple-text' && this._question.previousAnswers.length > 0;
  }

  @computed
  get previousAnswers() {
    return this._question.previousAnswers;
  }

  @computed
  get isRequired() {
    return this._question.isRequired;
  }

  @computed
  get isLocked() {
    return this._question.isDependantLocked;
  }

  @computed
  get dependantQuestionName() {
    return this._question.dependantQuestionName;
  }

  @computed
  get dependantQuestionAnswer() {
    return this._question.dependantQuestionAnswer;
  }

  @computed
  private get shouldBeDisabled() {
    return (
      this._question.isDependantLocked ||
      this._stepViewModel.status === 'completed' ||
      this._stepViewModel.status === 'archived' ||
      this._stepViewModel.isProcessArchived
    );
  }

  @computed
  get isDisabled() {
    return !this._forcedEdition && this.shouldBeDisabled;
  }

  @computed
  get canForceAnswer(): boolean {
    return (
      this.shouldBeDisabled &&
      !this._stepViewModel.isProcessArchived &&
      (this._accountService.isAllowed(['super-admin']) || this._accountService.isAllowedAll(['admin', 'studyo-staff']))
    );
  }

  @computed
  get hasChanged() {
    return this._answer.hasFieldChanges;
  }

  @computed
  get isUpdating() {
    return this._isUpdating;
  }

  @computed
  get hasIssue() {
    return this._hasIssue;
  }

  @computed
  get variableResolver() {
    return new DefaultOnboardingVariableResolver(this._configId);
  }

  @computed
  get clientId() {
    return this._stepViewModel.client?.id;
  }

  @computed
  get canEditInLargerBox() {
    return (
      this._accountService.isAllowed(['super-admin']) || this._accountService.isAllowedAll(['admin', 'studyo-staff'])
    );
  }

  @action
  forceEdition(): void {
    // Only Studyo Staff admins or root admins can do this.
    if (this.canForceAnswer) {
      this._forcedEdition = true;
    }
  }

  async forceSave(): Promise<void> {
    if (this._forcedEdition) {
      // This will reset the inner step, thus regenerate all question view-models.
      await this.saveAnswer();
    }
  }

  navigateToEditQuestion(): Promise<void> {
    return this._stepViewModel.navigateToEditQuestion(this._question);
  }

  renameQuestion(): Promise<void> {
    return this._stepViewModel.renameQuestion(this._question);
  }

  copyQuestion(): Promise<void> {
    return this._stepViewModel.copyQuestion(this._question);
  }

  async viewSensitiveAnswer(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;

    try {
      const answer = await this._onboardingStore.getSensitiveAnswer(
        this._question.templateName,
        this._configId,
        this._stepViewModel.templateName,
        true
      );

      const previousSensitiveAnswers = answer.previousAnswers.filter((a) => a.kind === 'sensitive-simple-text');

      if (answer.answer?.kind === 'sensitive-simple-text') {
        let message =
          this._question.answer != null
            ? strings.sensitiveAnswerMessage + '\n ► ' + answer.answer.sensitiveSimpleTextValue + '\n'
            : '';

        if (previousSensitiveAnswers.length > 0) {
          message =
            message +
            '\n' +
            strings.sensitivePreviousAnswersMessage +
            '\n' +
            previousSensitiveAnswers.map((a) => `► ${a.sensitiveSimpleTextValue}`).join('\n');
        }

        await this._alertService.showMessage({
          title: strings.sensitiveAnswerTitle,
          message
        });
      } else {
        await this._alertService.showMessage({
          title: strings.noSensitiveAnswerAvailableTitle,
          message: strings.noSensitiveAnswerAvailableMessage
        });
      }
    } catch {
      // We assume any error is about access denied.
      await this._alertService.showMessage({
        title: strings.cannotViewSensitiveAnswerTitle,
        message: strings.cannotViewSensitiveAnswerMessage
      });
    }
  }

  deleteQuestion(): Promise<void> {
    return this._stepViewModel.deleteQuestion(this._question);
  }

  async uploadFile(dataUrl: string, fileName?: string): Promise<void> {
    this.answer.fileValue = await this._onboardingStore.uploadQuestionFile(
      dataUrl,
      this._question.templateName,
      this._configId,
      this._stepViewModel.templateName,
      fileName
    );
  }

  async clearFile(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
    const answer = await this._alertService.showConfirmation({
      title: strings.confirmRemoveFileTitle,
      message: strings.confirmRemoveFileMessage
    });

    if (answer !== 'cancelled') {
      runInAction(() => (this.answer.fileValue = ''));
    }
  }

  @action
  private async saveAnswer(): Promise<void> {
    clearTimeout(this._timeout);
    this._isUpdating = true;

    // This call doesn't throw errors. It returns false if failed to save the answer.
    const success = await this._stepViewModel.updateQuestionAnswer(this._question, this._answer);

    runInAction(() => {
      this._isUpdating = false;
      this._hasIssue = !success;
    });
  }
}
