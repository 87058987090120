import { EnglishInsightsStrings, FrenchInsightsStrings, InsightsStrings } from './insights/InsightsStrings';
import { EnglishModelsStrings, FrenchModelsStrings, ModelsStrings } from './models/ModelsStrings';
import { EnglishStudyoStrings, FrenchStudyoStrings, StudyoStrings } from './studyo/StudyoStrings';

export interface LocalizedStrings {
  readonly models: ModelsStrings;
  readonly studyo: StudyoStrings;
  readonly insights: InsightsStrings;
}

export const EnglishLocalizedStrings: LocalizedStrings = {
  models: EnglishModelsStrings,
  studyo: EnglishStudyoStrings,
  insights: EnglishInsightsStrings
};

export const FrenchLocalizedStrings: LocalizedStrings = {
  models: FrenchModelsStrings,
  studyo: FrenchStudyoStrings,
  insights: FrenchInsightsStrings
};
