import { LocalizationService } from '@shared/resources/services';
import { SchoologyConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface SchoologyAccountViewModel extends ExternalAccountEditionViewModel {
  accountName: string;
  serverUrl: string;
  consumerKey: string;
  consumerSecret: string;
}

export class AppSchoologyAccountViewModel
  extends BaseExternalAccountEditionViewModel
  implements SchoologyAccountViewModel
{
  @observable private _accountName?: string;
  @observable private _serverUrl?: string;
  @observable private _consumerKey?: string;
  @observable private _consumerSecret?: string;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _schoologyStore: SchoologyConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    private readonly _originalAccountName: string,
    private readonly _originalServerUrl: string,
    private readonly _originalConsumerKey: string,
    private readonly _originalConsumerSecret: string
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get accountName() {
    return this._accountName ?? this._originalAccountName;
  }

  set accountName(value: string) {
    this._accountName = value;
    this.onChange();
  }

  @computed
  get serverUrl() {
    return this._serverUrl ?? this._originalServerUrl;
  }

  set serverUrl(value: string) {
    this._serverUrl = value;
    this.onChange();
  }

  @computed
  get consumerKey() {
    return this._consumerKey ?? this._originalConsumerKey;
  }

  set consumerKey(value: string) {
    this._consumerKey = value;
    this.onChange();
  }

  @computed
  get consumerSecret() {
    return this._consumerSecret ?? this._originalConsumerSecret;
  }

  set consumerSecret(value: string) {
    this._consumerSecret = value;
    this.onChange();
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this._hasChanges) {
      console.error('Applying without changes. Ignoring...');
      this._onSuccess();
      return;
    }

    // The "original" token is not really the token, but a placeholder.
    // When editing details, the user must repeat the token.
    if (this._consumerSecret === this._originalConsumerSecret) {
      this.addError(strings.enterConsumerSecretAgain);
      return;
    }

    this.beginApplying();

    try {
      await this._schoologyStore.createOrUpdateSchoologyAccount(
        this._configId,
        this._externalAccountId,
        this.accountName,
        this.serverUrl,
        this.consumerKey,
        this.consumerSecret
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._accountName = undefined;
    this._serverUrl = undefined;
    this._consumerKey = undefined;
    this._consumerSecret = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this._hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
