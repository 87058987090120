import { AccountService, AlertService, NavigationService, SettingsStore } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppOnboardingCommentsViewModel } from './OnboardingCommentsViewModel';
import { AppOnboardingProcessViewModel, OnboardingProcessViewModel } from './OnboardingProcessViewModel';

export interface OnboardingProcessesScreenViewModel {
  readonly processes: IPromiseBasedObservable<OnboardingProcessViewModel[]>;
  readonly isLockedContentVisible: boolean;

  addProcess: () => Promise<void>;
  toggleLockedContentVisibility: () => void;
}

export class AppOnboardingProcessesScreenViewModel implements OnboardingProcessesScreenViewModel {
  @observable private _changeIteration = 0;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _settings: SettingsStore,
    private readonly _configId: string,
    private readonly _processName?: string
  ) {
    makeObservable(this);
  }

  @computed
  get processes(): IPromiseBasedObservable<OnboardingProcessViewModel[]> {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this._changeIteration;
    return fromPromise(this.loadData());
  }

  @computed
  get isLockedContentVisible(): boolean {
    return !this._settings.onboardingPreferences.hideLockedContent;
  }

  async addProcess(): Promise<void> {
    const result = await this._navigationService.navigateToAddOnboardingProcess(this._configId);

    if (result !== 'cancelled') {
      this._onboardingStore.invalidate();
    }
  }

  @action
  toggleLockedContentVisibility(): void {
    this._settings.onboardingPreferences.hideLockedContent = !this._settings.onboardingPreferences.hideLockedContent;
  }

  private async loadData(): Promise<OnboardingProcessViewModel[]> {
    if (this._processName != null) {
      // We're only viewing a specific process.
      const [process, teachersAndStaffById] = await Promise.all([
        this._onboardingStore.getProcess(this._processName, this._configId),
        this._schoolYearConfigurationStore.getTeachersAndStaffById(this._configId, false)
      ]);

      const comments = await this._onboardingStore.getProcessComments(this._processName, this._configId);

      return [
        new AppOnboardingProcessViewModel(
          this._onboardingStore,
          this._accountService,
          this._navigationService,
          this._alertService,
          this._localizationService,
          this._settings,
          this._configId,
          teachersAndStaffById,
          process,
          new AppOnboardingCommentsViewModel(
            this._onboardingStore,
            this._accountService,
            this._navigationService,
            this._alertService,
            this._localizationService,
            process.templateName,
            process.status,
            '',
            process.agentId,
            this._configId,
            teachersAndStaffById,
            comments,
            true,
            undefined,
            () => runInAction(() => this._changeIteration++)
          )
        )
      ];
    } else {
      // We're viewing all processes.
      const [processes, teachersAndStaffById] = await Promise.all([
        this._onboardingStore.getProcesses(this._configId),
        this._schoolYearConfigurationStore.getTeachersAndStaffById(this._configId, false)
      ]);

      const commentsPerProcess = await Promise.all(
        processes.map((p) => this._onboardingStore.getProcessComments(p.templateName, this._configId))
      );

      return processes.map(
        (process, index) =>
          new AppOnboardingProcessViewModel(
            this._onboardingStore,
            this._accountService,
            this._navigationService,
            this._alertService,
            this._localizationService,
            this._settings,
            this._configId,
            teachersAndStaffById,
            process,
            new AppOnboardingCommentsViewModel(
              this._onboardingStore,
              this._accountService,
              this._navigationService,
              this._alertService,
              this._localizationService,
              process.templateName,
              process.status,
              '',
              process.agentId,
              this._configId,
              teachersAndStaffById,
              commentsPerProcess[index],
              true,
              undefined,
              () => runInAction(() => this._changeIteration++)
            )
          )
      );
    }
  }
}
