import { css } from '@emotion/css';
import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { BehaviourSummaryItemType } from '../models';

export interface BehaviourSummaryItemTooltipProps {
  sx?: SxProps;
  className?: string;
  type: BehaviourSummaryItemType;
}

export const BehaviourSummaryItemTooltip = observer(
  ({ sx = [], className, type }: BehaviourSummaryItemTooltipProps) => {
    const { localizationService, accountService } = useInsightsServices();
    const strings = localizationService.localizedStrings.insights.components.behaviourSummary;

    let tooltip = '';
    let greyTooltip: string | undefined = undefined;
    let greenTooltip: string | undefined = undefined;
    let yellowTooltip: string | undefined = undefined;
    let redTooltip: string | undefined = undefined;

    switch (type) {
      case 'app-open':
        tooltip = strings.appOpenTooltip;
        greyTooltip = strings.appOpenGreyTooltip;
        greenTooltip = strings.appOpenGreenTooltip;
        yellowTooltip = strings.appOpenYellowTooltip;
        redTooltip = strings.appOpenRedTooltip;
        break;

      case 'task-completion':
        tooltip = strings.taskCompletionTooltip;
        greenTooltip = strings.taskCompletionGreenTooltip;
        yellowTooltip = strings.taskCompletionYellowTooltip;
        redTooltip = strings.taskCompletionRedTooltip;
        break;

      case 'parent-invitation':
        tooltip = strings.invitesParentTooltip;
        greyTooltip = strings.invitesParentGreyTooltip;
        greenTooltip = strings.invitesParentGreenTooltip;
        yellowTooltip = strings.invitesParentYellowTooltip;
        redTooltip = strings.invitesParentRedTooltip;
        break;

      default:
        throw new Error('Unknown type');
    }

    const theme = useTheme();
    const tooltipBoxClassName = css({ marginRight: theme.spacing(1), width: 12, height: 12 });

    return (
      <Box sx={sx} className={className}>
        <Typography fontWeight="500" variant="body2">
          {tooltip}
        </Typography>
        {greyTooltip != null && (
          <Box display="flex" alignItems="center">
            <Box className={tooltipBoxClassName} bgcolor={grey[300]} borderRadius={50}>
              &nbsp;
            </Box>
            <Typography variant="body2">{greyTooltip}</Typography>
          </Box>
        )}
        {greenTooltip != null && (
          <Box display="flex" alignItems="center">
            <Box className={tooltipBoxClassName} bgcolor={green[400]} borderRadius={50}>
              &nbsp;
            </Box>
            <Typography variant="body2">{greenTooltip}</Typography>
          </Box>
        )}
        {yellowTooltip != null && (
          <Box display="flex" alignItems="center">
            <Box className={tooltipBoxClassName} bgcolor={orange[400]} borderRadius={50}>
              &nbsp;
            </Box>
            <Typography variant="body2">{yellowTooltip}</Typography>
          </Box>
        )}
        {redTooltip != null && (
          <Box display="flex" alignItems="center">
            <Box className={tooltipBoxClassName} bgcolor={red[400]} borderRadius={50}>
              &nbsp;
            </Box>
            <Typography variant="body2">{redTooltip}</Typography>
          </Box>
        )}

        {!accountService.isFeatureAllowed('organizational-quotient') && (
          <Box marginTop={2}>
            <Typography variant="body2" fontWeight="500">
              {strings.featureNotAvailableTooltipNote}
            </Typography>
            <Typography variant="body2" fontWeight="500">
              {strings.featureNotAvailableTooltip}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }
);
