import { SchoolYearConfiguration_WorkloadThreshold as PBWorkloadThreshold } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { BaseModel, SerializableModel } from '../Model';

export interface WorkloadThresholdModel extends SerializableModel<PBWorkloadThreshold> {
  readonly gradeLevel: string;
  readonly dailyThreshold: number;
  readonly weeklyThreshold: number;
}

export class WorkloadThreshold extends BaseModel<PBWorkloadThreshold> implements WorkloadThresholdModel {
  constructor(pb: PBWorkloadThreshold) {
    super(pb);
  }

  get gradeLevel(): string {
    return this._pb.gradeLevel;
  }

  get dailyThreshold(): number {
    return this._pb.dailyThreshold;
  }

  get weeklyThreshold(): number {
    return this._pb.weeklyThreshold;
  }
}
