export class DataStructureUtils {
  static convertDictionaryToMap<T>(dict: Record<string, T>): Map<string, T> {
    const map = new Map<string, T>();

    Object.keys(dict).forEach((key) => {
      map.set(key, dict[key]);
    });

    return map;
  }
}
