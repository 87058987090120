import { AutomatedImportResult, SourceIndex } from '@shared/models/import';
import { DayOfWeek, Time } from '@shared/models/types';

const timeParts = (milliseconds: number) => ({
  minutes: String(Math.floor(milliseconds / 60000)),
  seconds: String(Math.floor((milliseconds % 60000) / 1000)).padStart(2, '0'),
  milliseconds: String(milliseconds % 1000).padStart(3, '0')
});

export interface ImportComponentsStrings {
  add: string;
  addAllOrderedColumns: string;
  addColumnTooltip: string;
  addConcatenationTooltip: string;
  addedRows: string;
  addedValues: string;
  additiveAutomatedImport: string;
  addAutomatedImportTooltip: string;
  addAutomatedTransformationImportTooltip: string;
  addExpectedFileTooltip: string;
  addNewSession: string;
  addNextOrderedColumn: string;
  addTransformationTooltip: string;
  ansi: string;
  automatedImportHistoryTitle: string;
  automatedImportsLabel: string;
  automatedImportsTitle: string;
  automatedImportTimes: (days: DayOfWeek[], time?: Time) => string;
  automatedTransformationImportsTitle: string;
  availableHeadersLabel: string;
  cancel: string;
  columnAndRowCount: (columnCount: number, rowCount: number) => string;
  data: string;
  deleteAutomatedImport: string;
  deleteAutomatedTransformationImport: string;
  deleteColumnTooltip: string;
  description: string;
  destructiveAutomatedImport: string;
  destructiveTitleSuffix: string;
  edit: string;
  editAutomatedImport: string;
  editAutomatedTransformation: string;
  editAutomatedTransformations: string;
  editCommentTitle: string;
  editConcatenationOptions: string;
  editFileOptions: string;
  editFileUrl: string;
  editFileTitle: (filename: string) => string;
  editOrderedHeaders: string;
  editOrderedHeadersMessage: string;
  editOrderedHeadersTitle: string;
  editSessionDetails: string;
  editTransformationRecipe: string;
  editTransformationOptions: string;
  encoding: string;
  errorMessage: string;
  excel: string;
  executeAutomatedImportManuallyTooltip: string;
  expectedFiles: string;
  fileCount: string;
  globalIncidents: string;
  hashChanged: string;
  incidentsSummary: string;
  indexes: string;
  isCompleteData: string;
  isCompleteDataWarning: string;
  isHeader: string;
  importDataButton: (isDestructive: boolean) => string;
  importDataError: string;
  importDataSuccess: string;
  importDuration: (milliseconds: number) => string;
  isSubstitution: string;
  isVisible: string;
  kind: string;
  lastAutomatedImport: (result?: AutomatedImportResult) => string;
  lastImported: (time: Date) => string;
  linkFileManually: string;
  linkFileManuallyTitle: string;
  linkFileToConfiguration: string;
  linkFileToConfigurationTitle: string;
  linkFileToQuestion: string;
  linkFileToQuestionTitle: string;
  moveColumnDownTooltip: string;
  moveColumnUpTooltip: string;
  name: string;
  noAutomatedImports: string;
  noAutomatedTransformationImportResults: string;
  noFileSelected: string;
  noHeaders: string;
  noSession: string;
  noTransformation: string;
  noUrlPlaceholder: string;
  noValues: string;
  operation: string;
  orderedHeadersLabel: string;
  other: string;
  parameters: string;
  pdf: string;
  removedRows: string;
  removedValues: string;
  removeConcatenation: string;
  removeFile: string;
  removeTransformation: string;
  renderingTime: (milliseconds: number) => string;
  resetChangesTooltip: string;
  save: string;
  saveChangesTooltip: string;
  schemaField: string;
  selectFile: string;
  skippedAutomatedTransformation: string;
  skippedRows: string;
  sourceLabel: string;
  sourceRows: string;
  summaryCopiedToClipboardToast: string;
  targetSchema: string;
  targetSchemaValue(schema: string): string;
  testData: string;
  text: string;
  transformationColumns: string;
  transformationCount: string;
  transformationSourceAndIndexes(sourceLabel: string, indexes: SourceIndex[]): string;
  transformations: string;
  unicode16: string;
  unknown: string;
  updateDataTooltip: string;
  updatedRows: string;
  upload: string;
  utf8: string;
  values: (count: number) => string;
  viewData: string;
  xml: string;
  zip: string;

  importData(schema: string): string;
  testImportData(schema: string): string;
}

function englishDayName(day: DayOfWeek) {
  switch (day) {
    case 'monday':
      return 'Monday';
    case 'tuesday':
      return 'Tuesday';
    case 'wednesday':
      return 'Wednesday';
    case 'thursday':
      return 'Thursday';
    case 'friday':
      return 'Friday';
    case 'saturday':
      return 'Saturday';
    case 'sunday':
      return 'Sunday';
  }
}

export const EnglishImportComponentsStrings: ImportComponentsStrings = {
  add: 'Add',
  addAllOrderedColumns: 'Add all remaining columns',
  addColumnTooltip: 'Add a new operation (Ctrl-Alt-A)',
  addConcatenationTooltip: 'Add a new concatenation',
  addedRows: 'Added rows',
  addedValues: 'Added',
  additiveAutomatedImport: 'Additive: Data will be added or updated but not removed',
  addAutomatedImportTooltip: 'Add a new automated import',
  addAutomatedTransformationImportTooltip: 'Add a new automated transformation import',
  addExpectedFileTooltip: 'Add a new expected file',
  addNewSession: 'Add new session',
  addNextOrderedColumn: 'Add next column',
  addTransformationTooltip: 'Add a new transformation',
  ansi: 'Ansi',
  automatedImportHistoryTitle: 'Import history',
  automatedImportsLabel: 'Automated imports',
  automatedImportsTitle: 'Automated Imports',
  automatedImportTimes: (days, time) =>
    days.length === 0
      ? 'No days selected'
      : time == null
        ? 'No time selected'
        : `Every ${days.map((day) => englishDayName(day)).join(', ')} at ${time.formattedString('h:mm a')}`,
  automatedTransformationImportsTitle: 'Automated Transformation Imports',
  availableHeadersLabel: 'Visible headers in current file',
  cancel: 'Cancel',
  columnAndRowCount: (columnCount, rowCount) =>
    `${columnCount || 'no'} column${columnCount === 1 ? '' : 's'} - ${rowCount || 'no'} row${
      rowCount === 1 ? '' : 's'
    }`,
  data: 'Data',
  deleteAutomatedImport: 'Delete automated import',
  deleteAutomatedTransformationImport: 'Delete automated transformation import',
  deleteColumnTooltip: 'Delete operation',
  description: 'Description',
  destructiveAutomatedImport: 'Destructive: Data will be added, updated or removed',
  destructiveTitleSuffix: ' - Destructive',
  edit: 'Edit',
  editAutomatedImport: 'Edit automated import options',
  editAutomatedTransformation: 'Edit automated transformation import',
  editAutomatedTransformations: 'View details and results',
  editCommentTitle: 'Comment about this operation',
  editConcatenationOptions: 'Edit concatenation settings',
  editFileOptions: 'Edit file settings',
  editFileUrl: 'Upload new file',
  editFileTitle: (filename) => `Upload file for "${filename}"`,
  editOrderedHeaders: 'Edit and sort expected headers',
  editOrderedHeadersMessage:
    'You can declare which columns you expect in this file, and in which order they should be seen.',
  editOrderedHeadersTitle: 'Ordered Headers',
  editSessionDetails: 'Edit session details',
  editTransformationRecipe: 'Edit transformation recipe',
  editTransformationOptions: 'Edit transformation settings',
  encoding: 'Encoding',
  errorMessage: 'An error occurred',
  excel: 'Excel',
  executeAutomatedImportManuallyTooltip: 'Execute automated import now!',
  expectedFiles: 'Expected Files',
  fileCount: 'Number of files',
  globalIncidents: 'Summary',
  hashChanged: ' (CHANGED)',
  incidentsSummary: 'Incidents summary',
  indexes: 'Indexes',
  isCompleteData: 'Enable destructive mode',
  isCompleteDataWarning:
    'The destructive mode will delete all entries that are not in the new file. Proceed with caution.',
  isHeader: 'Header?',
  importDataButton: (isDestructive) => `Import - ${isDestructive ? 'Overwrite everything' : 'Add and update'}`,
  importDataError:
    'An error occurred while importing the data. Please fix the reported incidents or ignore them during the import.',
  importDataSuccess: 'The data was successfully imported!',
  importDuration: (milliseconds) => `${(milliseconds / 1000).toLocaleString('en', { maximumFractionDigits: 1 })} sec.`,
  isSubstitution: 'Substitution?',
  isVisible: 'Visible?',
  kind: 'Kind',
  lastAutomatedImport: (result) =>
    result == null
      ? 'Not yet imported'
      : `Last imported ${result.endTime.toLocaleString('en', { dateStyle: 'short', timeStyle: 'medium' })}, ${
          result.isFullySuccessful ? 'successful' : 'incomplete'
        }`,
  lastImported: (time) => ` / Last imported ${time.toLocaleString('en', { dateStyle: 'short', timeStyle: 'medium' })}`,
  linkFileManually: 'Edit file link manually',
  linkFileManuallyTitle: 'Editing file link manually',
  linkFileToConfiguration: 'Link to an existing configuration',
  linkFileToConfigurationTitle: 'Linking to a configuration',
  linkFileToQuestion: 'Link to onboarding question',
  linkFileToQuestionTitle: 'Linking to an onboarding question',
  moveColumnDownTooltip: 'Move operation down',
  moveColumnUpTooltip: 'Move operation up',
  name: 'Name',
  noAutomatedImports: 'No automated imports executed yet',
  noAutomatedTransformationImportResults: 'No results for transformations',
  noFileSelected: 'No file selected…',
  noHeaders: '(None)',
  noSession: 'No session',
  noTransformation: 'No transformation',
  noUrlPlaceholder: 'No file set…',
  noValues: 'The selected sections were not changed',
  operation: 'Operation',
  orderedHeadersLabel: 'Ordered headers',
  other: 'Other',
  parameters: 'Parameters',
  pdf: 'Pdf',
  removedRows: 'Removed rows',
  removedValues: 'Removed',
  removeConcatenation: 'Remove concatenation',
  removeFile: 'Remove file',
  removeTransformation: 'Remove transformation',
  renderingTime: (t) => {
    const { minutes, seconds, milliseconds } = timeParts(t);
    return `rendered in ${minutes}:${seconds}.${milliseconds}`;
  },
  resetChangesTooltip: 'Discard all changes',
  save: 'Save',
  saveChangesTooltip: 'Save all changes (Ctrl-Alt-S)',
  schemaField: 'Schema Field',
  selectFile: 'Select file',
  skippedAutomatedTransformation: 'Skipped',
  skippedRows: 'Skipped rows',
  sourceLabel: 'Source Label',
  sourceRows: 'Source rows',
  summaryCopiedToClipboardToast: 'Summary of incidents copied to clipboard',
  targetSchema: 'Target Schema',
  targetSchemaValue: (schema) => (schema.length > 0 ? `${schema} schema` : '(no target schema)'),
  testData: 'Test importing',
  text: 'Text',
  transformationColumns: 'Transformation Columns',
  transformationCount: 'Number of transformations',
  transformationSourceAndIndexes: (sourceLabel, indexes) =>
    indexes.length === 0
      ? `Source label ${sourceLabel || 'EMPTY'}`
      : `Source label ${sourceLabel || 'EMPTY'} - Indexes: ${indexes
          .map((i) => `${i.label} on #${i.columnIndex}`)
          .join(' + ')}`,
  transformations: 'Transformations',
  unicode16: 'Unicode',
  unknown: 'Unknown',
  updateDataTooltip: 'Update transformed data (Ctrl-Alt-U)',
  updatedRows: 'Updated rows',
  upload: 'Upload',
  utf8: 'UTF8',
  values: (value) => `${value == 0 ? 'No' : value} value${value > 1 ? 's' : ''}`,
  viewData: 'View data',
  xml: 'Xml',
  zip: 'Zip',

  importData: (schema) => `Import data - ${schema}`,
  testImportData: (schema) => `Test import data - ${schema}`
};

function frenchDayName(day: DayOfWeek) {
  switch (day) {
    case 'monday':
      return 'lundi';
    case 'tuesday':
      return 'mardi';
    case 'wednesday':
      return 'mercredi';
    case 'thursday':
      return 'jeudi';
    case 'friday':
      return 'vendredi';
    case 'saturday':
      return 'samedi';
    case 'sunday':
      return 'dimanche';
  }
}

export const FrenchImportComponentsStrings: ImportComponentsStrings = {
  add: 'Ajouter',
  addAllOrderedColumns: 'Ajouter les autres colonnes',
  addColumnTooltip: 'Ajouter une nouvelle opération (Ctrl-Alt-A)',
  addConcatenationTooltip: 'Ajouter une concaténation',
  addedRows: 'Nouvelles entrées',
  addedValues: 'Ajouts',
  additiveAutomatedImport: 'Additif: Les données seront ajoutées ou modifiées mais ne seront pas effacées',
  addAutomatedImportTooltip: 'Ajouter une importation automatisée',
  addAutomatedTransformationImportTooltip: 'Ajouter une nouvelle importation automatisée de transformation',
  addExpectedFileTooltip: 'Ajouter un fichier attendu',
  addNewSession: 'Ajouter une session',
  addNextOrderedColumn: 'Ajouter la prochaine colonne',
  addTransformationTooltip: 'Ajouter une transformation',
  ansi: 'Ansi',
  automatedImportHistoryTitle: 'Historique des importations',
  automatedImportsLabel: 'Importations automatisées',
  automatedImportsTitle: 'Importations automatisées',
  automatedImportTimes: (days, time) =>
    days.length === 0
      ? 'Aucune journée sélectionnée'
      : time == null
        ? 'Aucune heure assignée'
        : `Chaque ${days.map((day) => frenchDayName(day)).join(', ')} à ${time.formattedString('H:mm')}`,
  automatedTransformationImportsTitle: 'Importations automatisées de transformations',
  availableHeadersLabel: 'En-têtes visibles dans le fichier courant',
  cancel: 'Annuler',
  columnAndRowCount: (columnCount, rowCount) =>
    `${columnCount || 'aucune'} colonne${columnCount > 1 ? 's' : ''} - ${rowCount || 'aucune'} rangée${
      rowCount > 1 ? 's' : ''
    }`,
  data: 'Données',
  deleteAutomatedImport: 'Effacer l’importation automatisée',
  deleteAutomatedTransformationImport: 'Effacer l’importation automatisée de transformation',
  deleteColumnTooltip: 'Effacer l’opération',
  description: 'Description',
  destructiveAutomatedImport: 'Destructif: Les données seront ajoutées, modifiées ou effacées',
  destructiveTitleSuffix: ' - Destructif',
  edit: 'Modifier',
  editAutomatedImport: 'Modifier les options d’importation automatisée',
  editAutomatedTransformation: 'Modifier l’importation automatisée de transformation',
  editAutomatedTransformations: 'Voir les détails et les résultats',
  editCommentTitle: 'Commentaire sur cette opération',
  editConcatenationOptions: 'Changer les options de concaténation',
  editFileOptions: 'Changer les options du fichier',
  editFileUrl: 'Téléverser un nouveau fichier',
  editFileTitle: (filename) => `Soumettre un fichier pour « ${filename} »`,
  editOrderedHeaders: 'Éditer et trier les entêtes attendues',
  editOrderedHeadersMessage:
    'Vous pouvez définir quelles colonnes sont attendues dans ce fichier, et dans quel ordre elles doivent être montrées.',
  editOrderedHeadersTitle: 'En-têtes triées',
  editSessionDetails: 'Modifier les détails de la session',
  editTransformationRecipe: 'Modifier la recette de transformation',
  editTransformationOptions: 'Changer les options de la transformation',
  encoding: 'Encodage',
  errorMessage: 'Une erreur est survenue',
  excel: 'Excel',
  executeAutomatedImportManuallyTooltip: 'Lancer l’importation automatique maintenant!',
  expectedFiles: 'Fichiers attendus',
  fileCount: 'Nombre de fichiers',
  globalIncidents: 'Sommaire',
  hashChanged: ' (CHANGÉ)',
  incidentsSummary: 'Résumé des incidents',
  isCompleteData: 'Activer le mode destructif',
  isCompleteDataWarning:
    'Le mode destructif supprimera les entrées qui ne se trouvent pas dans le nouveau fichier. Procédez avec prudence.',
  indexes: 'Indexes',
  isHeader: 'En-tête?',
  importDataButton: (isDestructive) => `Importer - ${isDestructive ? 'Tout remplacer' : 'Ajouter et mettre à jour'}`,
  importDataError:
    'Une erreur est survenue lors de l’importation des données. Veuillez vérifier les incidents ou ignorer ces incidents lors de l’importation.',
  importDataSuccess: 'Les données ont été importées avec succès!',
  importDuration: (milliseconds) => `${(milliseconds / 1000).toLocaleString('fr', { maximumFractionDigits: 1 })} sec.`,
  isSubstitution: 'Substituer ?',
  isVisible: 'Visible ?',
  kind: 'type',
  lastAutomatedImport: (result) =>
    result == null
      ? 'Pas encore importé'
      : `Dernière importation ${result.endTime.toLocaleString('en', { dateStyle: 'short', timeStyle: 'medium' })}, ${
          result.isFullySuccessful ? 'avec succès' : 'incomplète'
        }`,
  lastImported: (time) =>
    ` / Dernière importation ${time.toLocaleString('fr', { dateStyle: 'short', timeStyle: 'medium' })}`,
  linkFileManually: 'Éditer le lien manuellement',
  linkFileManuallyTitle: 'Édition du lien manuellement',
  linkFileToConfiguration: 'Lier à une configuration existante',
  linkFileToConfigurationTitle: 'Liaison à une configuration',
  linkFileToQuestion: 'Lier à une question d’embarquement',
  linkFileToQuestionTitle: 'Liaison à une question d’embarquement',
  moveColumnDownTooltip: 'Déplacer l’opération vers le bas',
  moveColumnUpTooltip: 'Déplacer l’opération vers le haut',
  name: 'Name',
  noAutomatedImports: 'Aucune importation automatisée pour le moment',
  noAutomatedTransformationImportResults: 'Aucun résultat de transformations',
  noFileSelected: 'Aucun fichier sélectionné…',
  noHeaders: '(Aucune)',
  noSession: 'Aucune session',
  noTransformation: 'Aucune transformation',
  noUrlPlaceholder: 'Aucun fichier assigné…',
  noValues: 'Aucun changement dans les groupes-matière sélectionnés',
  operation: 'Opération',
  orderedHeadersLabel: 'En-têtes triées',
  other: 'Autre',
  parameters: 'paramètres',
  pdf: 'Pdf',
  removedRows: 'Entrées supprimées',
  removedValues: 'Retraits',
  removeConcatenation: 'Retirer la concaténation',
  removeFile: 'Retirer le fichier',
  removeTransformation: 'Retirer la transformation',
  renderingTime: (t) => {
    const { minutes, seconds, milliseconds } = timeParts(t);
    return `généré en ${minutes}:${seconds}.${milliseconds}`;
  },
  resetChangesTooltip: 'Abandonner les changements',
  save: 'Sauvegarder',
  saveChangesTooltip: 'Sauvegarder les changements (Ctrl-Alt-S)',
  schemaField: 'Champ de schéma',
  selectFile: 'Choisir un fichier',
  skippedAutomatedTransformation: 'Ignorée',
  skippedRows: 'Entrées ignorées',
  sourceLabel: 'Étiquette source',
  sourceRows: 'Source',
  summaryCopiedToClipboardToast: 'Sommaire des incidents copié dans le presse-papier',
  targetSchema: 'Schéma cible',
  targetSchemaValue: (schema) => (schema.length > 0 ? `Schéma ${schema}` : '(aucune schéma cible)'),
  testData: 'Tester l’importation',
  text: 'Texte',
  transformationColumns: 'Colonnes de tansformations',
  transformationCount: 'Nombre de transformations',
  transformationSourceAndIndexes: (sourceLabel, indexes) =>
    indexes.length === 0
      ? `Étiquette source ${sourceLabel || 'VIDE'}`
      : `Étiquette source ${sourceLabel || 'VIDE'} - Index: ${indexes
          .map((i) => `${i.label} on #${i.columnIndex}`)
          .join(' + ')}`,
  transformations: 'Transformations',
  unicode16: 'Unicode',
  unknown: 'Inconnu',
  updateDataTooltip: 'Mettre à jour les données (Ctrl-Alt-U)',
  updatedRows: 'Entrées mises à jour',
  upload: 'Soumettre',
  utf8: 'UTF8',
  values: (value) => `${value == 0 ? 'Aucune' : value} valeur${value > 1 ? 's' : ''}`,
  viewData: 'Voir les données',
  xml: 'Xml',
  zip: 'Zip',

  importData: (schema) => `Importer les données - ${schema}`,
  testImportData: (schema) => `Tester l’importation des données - ${schema}`
};
