import { AccountService, AlertService, NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppExternalAccountListViewModel, ExternalAccountListViewModel } from './ExternalAccountListViewModel';

export interface ExternalAccountsScreenViewModel {
  readonly configId: string;
  readonly accountList: IPromiseBasedObservable<ExternalAccountListViewModel>;
}

export class AppExternalAccountsScreenViewModel implements ExternalAccountsScreenViewModel {
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _accountService: AccountService,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get accountList(): IPromiseBasedObservable<ExternalAccountListViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<ExternalAccountListViewModel> {
    const [summary, accounts, teachersById] = await Promise.all([
      this._schoolYearConfigurationStore.getConfigSummary(this.configId),
      this._connectorsStore.getExternalAccounts(this.configId),
      this._schoolYearConfigurationStore.getTeachersById(this.configId, true)
    ]);

    return new AppExternalAccountListViewModel(
      this._connectorsStore,
      this._navigationService,
      this._alertService,
      this._localizationService,
      this._accountService,
      this.configId,
      accounts,
      summary.enabledIntegrations,
      teachersById
    );
  }
}
