export interface StudentMetricViewsStrings {
  behaviourHistoryCaption: string;
  behaviourHistorySubcaption: string;
  connectedStudents: string;
  dailyActiveStudents: string;
  dailyPlannerStatus: string;
  dailyPlannerStatusEmptyMessage: string;
  dailyPlannerStatusSubcaption: string;
  interactedWithTaskTitle: string;
  interactedWithTaskTooltip: string;
  loadingActiveStudentsErrorMessage: string;
  loadingActiveStudentsMessage: string;
  loadingStudentAccountsErrorMessage: string;
  loadingStudentAccountsMessage: string;
  loadingStudentBehavioursMessage: string;
  loadingStudentBehavioursErrorMessage: string;
  loadingStudentDailyErrorMessage: string;
  loadingStudentDailyMessage: string;
  loadingStudentDetailsErrorMessage: string;
  loadingStudentDetailsMessage: string;
  loadingStudentsErrorMessage: string;
  loadingStudentSessionsPerDayErrorMessage: string;
  loadingStudentSessionsPerDayMessage: string;
  loadingStudentsMessage: string;
  monthlyActiveStudents: string;
  schoolNotStartedMessage: string;
  noActiveStudentsMessage: string;
  noName: string;
  noOQDataMessage: string;
  numberOfConnectedStudents: string;
  numberOfDailyActiveStudents: string;
  numberOfMonthlyActiveStudents: string;
  numberOfWeeklyActiveStudents: string;
  openedStudyoTitle: string;
  openedStudyoTooltip: string;
  pastMonth: string;
  pastWeek: string;
  selectedSections: string;
  sessionsPerDay: string;
  sessionsPerDayEmptyMessage: string;
  sessionsPerDaySubcaption: string;
  students: string;
  studentsExpected: string;
  studentsTotal: string;
  thisYear: string;
  viewPlanner: string;
  weeklyActiveStudents: string;
}

export const EnglishStudentMetricViewsStrings: StudentMetricViewsStrings = {
  behaviourHistoryCaption: 'Behaviours over time',
  behaviourHistorySubcaption: 'Historical data for the student’s behaviours',
  connectedStudents: 'connected students',
  dailyActiveStudents: 'Daily Active Students',
  dailyPlannerStatus: 'Daily Planner Status',
  dailyPlannerStatusEmptyMessage: 'There are no tasks',
  // String below is copied from old strings, seems like an odd way to say it.
  dailyPlannerStatusSubcaption: 'In what state does the student leave their planner each night?',
  interactedWithTaskTitle: 'Interacted with a task',
  interactedWithTaskTooltip: 'interacted with a task',
  loadingActiveStudentsErrorMessage: 'An error has occurred while loading active students. Please reload.',
  loadingActiveStudentsMessage: 'Loading active students. Please wait...',
  loadingStudentAccountsErrorMessage: 'An error has occurred while loading student accounts. Please reload.',
  loadingStudentAccountsMessage: 'Loading student accounts',
  loadingStudentBehavioursMessage: 'Loading data. Please wait...',
  loadingStudentBehavioursErrorMessage: 'An error has occurred while loading the data. Please reload.',
  loadingStudentDailyErrorMessage: 'An error has occurred while loading daily planner status. Please reload.',
  loadingStudentDailyMessage: 'Loading daily planner status. Please wait...',
  loadingStudentDetailsErrorMessage: 'An error has occurred while loading student details. Please reload.',
  loadingStudentDetailsMessage: 'Loading student details. Please wait...',
  loadingStudentsErrorMessage: 'An error has occurred while loading the student details',
  loadingStudentSessionsPerDayErrorMessage: 'An error has occurred while loading sessions per day. Please reload.',
  loadingStudentSessionsPerDayMessage: 'Loading sessions per day. Please wait...',
  loadingStudentsMessage: 'Loading students. Please wait...',
  monthlyActiveStudents: 'Monthly Active Students',
  schoolNotStartedMessage: 'School has not started yet.',
  noActiveStudentsMessage: 'There are no active students.',
  noName: '(no name)',
  noOQDataMessage: 'There is no data to display.',
  numberOfConnectedStudents: 'Number of connected students',
  numberOfDailyActiveStudents: 'Number of active students per day',
  numberOfMonthlyActiveStudents: 'Number of active students per month',
  numberOfWeeklyActiveStudents: 'Number of active students per weeks',
  openedStudyoTitle: 'Opened Studyo',
  openedStudyoTooltip: 'opened Studyo',
  pastWeek: 'Past Week',
  pastMonth: 'Past Month',
  selectedSections: 'Selected Sections',
  sessionsPerDay: 'Sessions per Day',
  sessionsPerDayEmptyMessage: 'There are no sessions',
  sessionsPerDaySubcaption: 'Number of times per day the student opened Studyo',
  students: 'Students',
  studentsExpected: 'students expected',
  studentsTotal: 'student records',
  thisYear: 'This Year',
  viewPlanner: 'View planner',
  weeklyActiveStudents: 'Weekly Active Students'
};

export const FrenchStudentMetricViewsStrings: StudentMetricViewsStrings = {
  behaviourHistoryCaption: 'Comportements dans le temps',
  behaviourHistorySubcaption: 'Données historiques pour les comportements de l’élève',
  connectedStudents: 'élèves connectés',
  dailyActiveStudents: 'Élèves Quotidiens Actifs',
  dailyPlannerStatus: 'État du planificateur quotidien',
  dailyPlannerStatusEmptyMessage: 'Il n’y a pas de tâches',
  // String below is copied from old strings, seems like an odd way to say it.
  dailyPlannerStatusSubcaption: 'État du planificateur de chaque élève?',
  interactedWithTaskTitle: 'Ont interagi avec une tâche',
  interactedWithTaskTooltip: 'ont interagi avec une tâche',
  loadingActiveStudentsErrorMessage:
    'Une erreur est survenue lors du chargement des élèves actifs. Merci de recharger.',
  loadingActiveStudentsMessage: 'Chargement des élèves actifs. Merci de patienter...',
  loadingStudentAccountsErrorMessage:
    'Une erreur est survenue lors du chargement des comptes d’élèves. Merci de recharger.',
  loadingStudentAccountsMessage: 'Chargement des comptes d’élèves',
  loadingStudentBehavioursMessage: 'Chargement des données. Merci de patienter...',
  loadingStudentBehavioursErrorMessage: 'Une erreur est survenue lors du chargement des données. Merci de recharger.',
  loadingStudentDailyErrorMessage:
    'Une erreur est survenue lors du chargement de l’état de planification. Merci de recharger.',
  loadingStudentDailyMessage: 'Chargement de l’état de planification. Merci de patienter...',
  loadingStudentDetailsErrorMessage:
    'Une erreur est survenue lors du chargement des détails d’élèves. Merci de recharger.',
  loadingStudentDetailsMessage: 'Chargement des détails d’élèves. Merci de patienter...',
  loadingStudentsErrorMessage: 'Une erreur est survenue lors du chargement des élèves',
  loadingStudentSessionsPerDayErrorMessage:
    'Une erreur est survenue lors du chargement des sessions par jour. Merci de recharger.',
  loadingStudentSessionsPerDayMessage: 'Chargement des sessions par jour. Merci de patienter...',
  loadingStudentsMessage: 'Chargement des élèves. Merci de patienter...',
  monthlyActiveStudents: 'Élèves actifs (mensuel)',
  schoolNotStartedMessage: 'L’école n’a pas encore débuté.',
  noActiveStudentsMessage: 'Il n’y a pas d’élèves actifs.',
  noName: '(sans nom)',
  noOQDataMessage: 'Il n’y a pas de données à afficher.',
  numberOfConnectedStudents: 'Nombre d’élèves connectés',
  numberOfDailyActiveStudents: 'Nombre d’élèves actifs par jour',
  numberOfMonthlyActiveStudents: 'Nombre d’élèves actifs par mois',
  numberOfWeeklyActiveStudents: 'Nombre d’élèves actifs par semaine',
  openedStudyoTitle: 'Ont ouvert Studyo',
  openedStudyoTooltip: 'ont ouvert Studyo',
  pastWeek: 'Dernière semaine',
  pastMonth: 'Dernier mois',
  selectedSections: 'Groupes-matière sélectionnés',
  sessionsPerDay: 'Sessions par jour',
  sessionsPerDayEmptyMessage: 'Il n’y a pas de sessions',
  sessionsPerDaySubcaption: 'Nombre d’ouvertures de Studyo par jour',
  students: 'Élèves',
  studentsExpected: 'élèves prévus',
  studentsTotal: 'fiches élève',
  thisYear: 'Cette année',
  viewPlanner: 'Voir agenda',
  weeklyActiveStudents: 'Élèves actifs par semaine'
};
