import { ConfigGlobalStats as PBConfigGlobalStats } from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';
import { Memoize } from 'fast-typescript-memoize';
import { AccountStats } from './AccountStats';
import { ActiveUserStats } from './ActiveUserStats';

export class ConfigGlobalStats {
  private _pb: PBConfigGlobalStats;

  constructor(pb: PBConfigGlobalStats) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  @Memoize()
  get accounts(): AccountStats {
    const accounts = this._pb.accounts;

    if (accounts == null) {
      throw new Error('Invalid operation: Server returned null accounts stats');
    }

    return new AccountStats(accounts);
  }

  @Memoize()
  get activeTeachers(): ActiveUserStats {
    const teachers = this._pb.activeTeachers;

    if (teachers == null) {
      throw new Error('Invalid operation: Server returned null teachers stats');
    }

    return new ActiveUserStats(teachers);
  }

  @Memoize()
  get activeStudents(): ActiveUserStats {
    const students = this._pb.activeStudents;

    if (students == null) {
      throw new Error('Invalid operation: Server returned null students stats');
    }

    return new ActiveUserStats(students);
  }
}
