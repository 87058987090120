import { SchoolCalendarViewModel } from '@insights/viewmodels';
import DayConfigurationIcon from '@mui/icons-material/Today';
import { Box, SxProps, Typography } from '@mui/material';
import { DayConfigurationModel } from '@shared/models/config';
import { AllDayOfWeek } from '@shared/models/types';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column, Row } from '../layout';

export interface DayConfigurationsListProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarViewModel;
  dayConfigurations: DayConfigurationModel[];
}

export const DayConfigurationsList = observer(
  ({ sx = [], className, style, viewModel, dayConfigurations }: DayConfigurationsListProps) => {
    const { localizationService } = useInsightsServices();

    function orderByDayOfWeek(dayConfiguration: DayConfigurationModel) {
      return dayConfiguration.dayOfWeek != null ? AllDayOfWeek.indexOf(dayConfiguration.dayOfWeek) : null;
    }

    const sortedDayConfigurations = orderBy<DayConfigurationModel>(dayConfigurations, [
      (dc) => orderByDayOfWeek(dc),
      'day',
      'cycleDay'
    ]);

    function getDayConfigurationTitle(dayConfiguration: DayConfigurationModel): string {
      const strings = localizationService.localizedStrings;

      if (dayConfiguration.day != null) {
        return dayConfiguration.day.formattedString(strings.models.dateFormats.short);
      } else if (dayConfiguration.dayOfWeek != null) {
        return strings.models.dayOfWeek.localizedDayOfWeek(dayConfiguration.dayOfWeek);
      } else if (dayConfiguration.cycleDay != null) {
        return viewModel.getCycleDay(dayConfiguration.cycleDay).longTitle;
      } else {
        return strings.insights.components.calendar.unknownDayConfig;
      }
    }

    return (
      <Column sx={sx} className={className} style={style} horizontalContentAlignment="left">
        {sortedDayConfigurations.map((dayConfiguration) => {
          const displayIcon =
            dayConfiguration.day != null || dayConfiguration.dayOfWeek != null || dayConfiguration.cycleDay != null;

          return (
            <Row
              key={`day-configuration-${dayConfiguration.id}`}
              horizontalContentAlignment="center"
              verticalContentAlignment="center"
            >
              {displayIcon && <DayConfigurationIcon fontSize="small" />}
              <Box ml={1}>
                <Typography variant="caption" color="white">
                  {getDayConfigurationTitle(dayConfiguration)}
                </Typography>
              </Box>
            </Row>
          );
        })}
      </Column>
    );
  }
);
