import { IncidentTolerance as PBIncidentTolerance } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { EditableModelEx, EditableStringProperty, EditableValuePropertyEx } from '@shared/models/editables';
import { computed, makeObservable } from 'mobx';
import { IncidentTolerance } from '../interfaces';
import { GrpcIncidentTolerance } from './GrpcAutomatedImport';

export class EditableIncidentTolerance extends EditableModelEx<PBIncidentTolerance> implements IncidentTolerance {
  private readonly _code: EditableStringProperty<PBIncidentTolerance>;
  private readonly _minimumCount: EditableValuePropertyEx<number, PBIncidentTolerance>;
  private readonly _maximumCount: EditableValuePropertyEx<number, PBIncidentTolerance>;
  private readonly _incidentText: EditableStringProperty<PBIncidentTolerance>;

  static createNew(min?: number, max?: number) {
    const pb = new PBIncidentTolerance();
    pb.minimumCount = min ?? 0;
    pb.maximumCount = max ?? 20;

    return new EditableIncidentTolerance(new GrpcIncidentTolerance(pb), true);
  }

  constructor(
    private readonly _originalTolerance: IncidentTolerance,
    isNew?: boolean
  ) {
    super(_originalTolerance.toProtobuf(), isNew);

    makeObservable(this);

    this._code = this.addStringField(_originalTolerance.code, (pb, value) => (pb.code = value));
    this._minimumCount = this.addValueField(_originalTolerance.minimumCount, (pb, value) => (pb.minimumCount = value));
    this._maximumCount = this.addValueField(_originalTolerance.maximumCount, (pb, value) => (pb.maximumCount = value));
    this._incidentText = this.addStringField(_originalTolerance.incidentText, (pb, value) => (pb.incidentText = value));
  }

  @computed
  get code() {
    return this._code.value;
  }

  set code(value: string) {
    this._code.value = value;
  }

  @computed
  get minimumCount() {
    return this._minimumCount.value;
  }

  set minimumCount(value: number) {
    this._minimumCount.value = value;
  }

  @computed
  get maximumCount() {
    return this._maximumCount.value;
  }

  set maximumCount(value: number) {
    this._maximumCount.value = value;
  }

  @computed
  get incidentText() {
    return this._incidentText.value;
  }

  set incidentText(value: string) {
    this._incidentText.value = value;
  }
}
