export interface NoSchoolConfigurationsViewStrings {
  title: string;
  errorOnOurPartText: string;
  errorOnOurPartLetUsKnowLink: string;
  usefulLinks: string;
  usefulLinksStudyo: string;
}

export const EnglishNoSchoolConfigurationsViewStrings: NoSchoolConfigurationsViewStrings = {
  title: 'Sorry, you do not have any school configurations.',
  errorOnOurPartText: 'If you think this is an error on our part, please ', // The space at the end is important
  errorOnOurPartLetUsKnowLink: 'let us know',
  usefulLinks: 'The following links might be useful:',
  usefulLinksStudyo: 'Go to Studyo'
};

export const FrenchNoSchoolConfigurationsViewStrings: NoSchoolConfigurationsViewStrings = {
  title: 'Désolé, vous n’avez pas de configuration d’école.',
  errorOnOurPartText: 'Si vous pensez que c’est une erreur de notre part, svp ', // The space at the end is important
  errorOnOurPartLetUsKnowLink: 'commiquez avec nous',
  usefulLinks: 'Les liens suivants peuvent vous être utiles:',
  usefulLinksStudyo: 'Aller à Studyo'
};
