import { NavigationService } from '@insights/services';
import { SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { chain } from 'lodash';
import {
  AppPublishedTasksDetailViewModelBase,
  PublishedTasksDetailInfo,
  PublishedTasksDetailViewModelBase
} from './PublishedTasksDetailViewModelBase';

export interface PublishedTasksBySectionDetailViewModel extends PublishedTasksDetailViewModelBase {
  readonly section: SectionModel;
  readonly sectionStudentCount: number;
}

export class AppPublishedTasksBySectionDetailViewModel
  extends AppPublishedTasksDetailViewModelBase
  implements PublishedTasksBySectionDetailViewModel
{
  constructor(
    public readonly section: SectionModel,
    public readonly sectionStudentCount: number,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    tasks: ContentDefinitionModel[],
    configId: string,
    onSuccess: () => void,
    onCancel: () => void,
    navigationService: NavigationService
  ) {
    super(configId, onSuccess, onCancel, tasks, navigationService);
  }

  protected async loadData(): Promise<PublishedTasksDetailInfo> {
    const sectionIds = chain(this._tasks)
      .map((t) => t.sectionId)
      .uniq()
      .value();

    const [schoolYearConfiguration, sections, sectionsById] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this.configId),
      this._schoolYearConfigurationStore.getSectionsByIds(this.configId, sectionIds),
      this._schoolYearConfigurationStore.getSectionsById(this.configId)
    ]);

    // Get the list of all the teachers of all the sections.
    // This is to load them all in one operation.
    const teacherIds = chain(sections)
      .flatMap((section) => section.teacherIds)
      .value();
    const teachers = await this._schoolYearConfigurationStore.getAccountsForIds(this.configId, teacherIds, false);

    // Create maps of all the data to search more efficiently
    const teachersById = new Map(teachers.map((t) => [t.id, t]));

    const sectionInfos = chain(sections)
      .map((s) => ({
        section: s,
        tasks: chain(this._tasks)
          .filter((t) => t.sectionId === s.id)
          .map((t) => ({
            task: t,
            publishedAt: t.publishTarget?.publishedAt ?? t.assignmentDay.asDate
          }))
          .value(),
        teachers: chain(s.teacherIds)
          .map((id) => teachersById.get(id))
          .compact()
          .value()
      }))
      .value();

    return {
      schoolYearConfiguration,
      sections: sectionInfos,
      sectionsById
    };
  }
}
