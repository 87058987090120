import { OrganizationalBehavioursImages } from '@shared/resources/images/insights';
import appOpenImage from '@shared/web/images/assets/insights/app-open.svg';
import taskCompletionImage from '@shared/web/images/assets/insights/task-completion.svg';

export class WebOrganizationalBehavioursImages implements OrganizationalBehavioursImages {
  get appOpen() {
    return appOpenImage;
  }

  get taskCompletion() {
    return taskCompletionImage;
  }
}
