import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ImportSessionFileOrderedHeadersDialogViewModel, OrderedHeaderViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import AddAllIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/Delete';
import ReorderIcon from '@mui/icons-material/Reorder';
import { Box, IconButton, SxProps, TextField, Tooltip, Typography } from '@mui/material';
import { ReorderableList } from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface ImportSessionFileOrderedHeadersProps {
  viewModel: ImportSessionFileOrderedHeadersDialogViewModel;
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

interface SortableElementData {
  readonly header: OrderedHeaderViewModel;
}

interface SortableContainerData {
  readonly headers: OrderedHeaderViewModel[];
  readonly onOrderChanged: (oldIndex: number, newIndex: number) => void;
}

const SortableHeader = ({ header }: SortableElementData) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: header.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const dragHandleStyle: CSSProperties = {
    cursor: 'move',
    touchAction: 'none'
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <Box sx={{ mb: 1 }} display="flex" flexDirection="row" alignItems="center">
        <TextField fullWidth value={header.value} onChange={(e) => (header.value = e.target.value)} />
        <IconButton onClick={() => header.remove()}>
          <RemoveIcon fontSize="small" color={header.isExisting ? 'primary' : 'error'} />
        </IconButton>

        <ReorderIcon sx={{ ml: 1, mt: 1.5 }} {...attributes} {...listeners} style={dragHandleStyle} />
      </Box>
    </Box>
  );
};

const SortableHeaders = ({ headers, onOrderChanged }: SortableContainerData) => (
  <Box>
    <ReorderableList
      items={headers}
      renderItem={(header) => <SortableHeader header={header} />}
      onOrderChanged={onOrderChanged}
    />
  </Box>
);

export const ImportSessionFileOrderedHeaders = observer((props: ImportSessionFileOrderedHeadersProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  return (
    <Box sx={sx} className={className} style={style}>
      <Typography mb={2}>{strings.editOrderedHeadersMessage}</Typography>

      <Typography variant="caption">{strings.availableHeadersLabel}</Typography>
      <Typography mb={2} variant="body2">
        {viewModel.currentHeaders ?? strings.noHeaders}
      </Typography>

      <Typography variant="caption">{strings.orderedHeadersLabel}</Typography>

      <SortableHeaders
        headers={viewModel.headers}
        onOrderChanged={(oldIndex, newIndex) => viewModel.moveHeader(oldIndex, newIndex)}
      />

      <Box display="flex" flexDirection="row-reverse">
        <Tooltip title={strings.addAllOrderedColumns}>
          <IconButton onClick={() => viewModel.addAllHeaders()}>
            <AddAllIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={strings.addNextOrderedColumn}>
          <IconButton onClick={() => viewModel.addHeader()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
});
