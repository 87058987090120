import { AlertService } from '@insights/services';
import { EditableLink, EditableSchoolYearConfiguration, SchoolYearConfigurationModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { max } from 'lodash';
import { action, computed, makeObservable, runInAction } from 'mobx';
import { AppEditViewModelBase, EditViewModelBase } from '../../EditViewModelBase';

export type SchoolYearConfigurationLinksFields = '';

export interface EditSchoolYearConfigurationLinksDialogViewModel
  extends EditViewModelBase<SchoolYearConfigurationLinksFields> {
  links: EditableLink[];
  addLink(): void;
  uploadFile(dataUrl: string, fileName?: string): Promise<void>;
}

export class AppEditSchoolYearConfigurationLinksDialogViewModel
  extends AppEditViewModelBase<SchoolYearConfigurationLinksFields>
  implements EditSchoolYearConfigurationLinksDialogViewModel
{
  private readonly _editableSchoolYearConfiguration: EditableSchoolYearConfiguration;

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _onboardingStore: OnboardingStore,
    private readonly _schoolYearConfiguration: SchoolYearConfigurationModel,
    alertService: AlertService,
    localizationService: LocalizationService,
    onSuccess: () => void,
    onCancel: () => void
  ) {
    super(alertService, localizationService, onSuccess, onCancel);
    makeObservable(this);
    this._editableSchoolYearConfiguration = new EditableSchoolYearConfiguration(_schoolYearConfiguration);
  }

  @computed
  get hasChanges(): boolean {
    return this._editableSchoolYearConfiguration.hasChanges;
  }

  @computed
  get links(): EditableLink[] {
    return this._editableSchoolYearConfiguration.editableLinks;
  }

  @action
  addLink() {
    const nextPosition = (max(this._editableSchoolYearConfiguration.links.map((link) => link.sortPosition)) ?? 0) + 1;

    this._editableSchoolYearConfiguration.addLink(EditableLink.createNew(nextPosition));
  }

  getFieldValueCore(field: SchoolYearConfigurationLinksFields) {
    switch (field) {
      default:
        throw new Error('Unknown field');
    }
  }

  setFieldValueCore(field: SchoolYearConfigurationLinksFields) {
    switch (field) {
      default:
        throw new Error('Unknown field');
    }
  }

  async uploadFile(dataUrl: string, fileName?: string): Promise<void> {
    const resetSubmitting = this.useIsSubmitting();

    try {
      // We're using the onboarding store for this.
      const fileUrl = await this._onboardingStore.uploadResourceFile(
        dataUrl,
        this._schoolYearConfiguration.id,
        fileName
      );

      runInAction(() => {
        const nextPosition =
          (max(this._editableSchoolYearConfiguration.links.map((link) => link.sortPosition)) ?? 0) + 1;

        const link = EditableLink.createNew(nextPosition);
        link.url = fileUrl;

        this._editableSchoolYearConfiguration.addLink(link);
      });
    } finally {
      resetSubmitting();
    }
  }

  protected async saveCore(): Promise<void> {
    await this._schoolYearConfigurationStore.saveConfig(this._editableSchoolYearConfiguration);
  }

  protected validateFields() {
    // Nothing to validate
  }
}
