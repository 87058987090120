import { Day, DayOfWeek } from '@shared/models/types';
import { Locale } from '@shared/resources/services';
import { LocalizedStrings } from '@shared/resources/strings/LocalizedStrings';
import { dateService } from '@shared/services';
import { differenceInCalendarDays } from 'date-fns';

export class DateUtils {
  /**
   * This function will return the number of days between 2 Day objects.
   * If the first Day is before the second, it will return a negative number.
   */
  static numberOfDaysBetween(first: Day, other: Day): number {
    // dayjs issue: https://github.com/iamkun/dayjs/issues/384
    // As a temp fix, we work with hours.
    return differenceInCalendarDays(first.asDate, other.asDate);
  }

  static relativeDayLabelBetweenDay(first: Day, other: Day, strings: LocalizedStrings, currentLocale: Locale): string {
    switch (this.numberOfDaysBetween(first, other)) {
      case -2:
        return currentLocale == 'fr' ? strings.models.day.twoDaysAgo : first.asString;
      case -1:
        return strings.models.day.yesterday;
      case 0:
        return strings.models.day.today;
      case 1:
        return strings.models.day.tomorrow;
      default:
        return first.asString;
    }
  }

  /**
   * Compare 2 Day objects and return a number indicating if it's equal, greater or smaller than
   * @param day Day you want to compare
   * @param other The other day
   * @returns A number, 0 if both are equals, 1 if day if greater than other and -1 if it's smaller than other.
   */
  static compareDayWithOther(day: Day, other: Day): number {
    if (day.year < other.year) {
      return -1;
    } else if (day.year > other.year) {
      return 1;
    } else if (day.month < other.month) {
      return -1;
    } else if (day.month > other.month) {
      return 1;
    } else if (day.day < other.day) {
      return -1;
    } else if (day.day > other.day) {
      return 1;
    } else {
      return 0;
    }
  }

  static dayIsWithin(day: Day, start: Day, end: Day): boolean {
    return this.compareDayWithOther(day, start) >= 0 && this.compareDayWithOther(day, end) <= 0;
  }

  static isYesterday(day: Day): boolean {
    return this.numberOfDaysBetween(day, dateService.today) === -1;
  }

  static isToday(day: Day): boolean {
    return day.isSame(dateService.today);
  }

  static isTomorrow(day: Day): boolean {
    return this.numberOfDaysBetween(day, dateService.today) === 1;
  }

  static isWeekend(day: Day): boolean {
    const dayOfWeek = day.dayOfWeek;
    return dayOfWeek === 'sunday' || dayOfWeek == 'saturday';
  }

  static isLate(day: Day): boolean {
    return this.numberOfDaysBetween(day, dateService.today) < 0;
  }

  static isThisWeek(day: Day): boolean {
    // A little math trick.
    const today = dateService.today;
    const index =
      this.numberOfDaysBetween(today, day) -
      this.dayOfWeekNumberValue(today.dayOfWeek) +
      this.dayOfWeekNumberValue(day.dayOfWeek);

    return index <= 0 && index > -7;
  }

  static isNextWeek(day: Day): boolean {
    // A little math trick.
    const today = dateService.today;
    const index =
      this.numberOfDaysBetween(today, day) -
      this.dayOfWeekNumberValue(today.dayOfWeek) +
      this.dayOfWeekNumberValue(day.dayOfWeek);

    return index <= -7 && index > -14;
  }

  static daysAreInSameMonth(day1: Day, day2: Day) {
    return day1.month === day2.month && day1.year === day2.year;
  }

  static dayOfWeekNumberValue(dow: DayOfWeek): number {
    switch (dow) {
      case 'sunday':
        return 0;
      case 'monday':
        return 1;
      case 'tuesday':
        return 2;
      case 'wednesday':
        return 3;
      case 'thursday':
        return 4;
      case 'friday':
        return 5;
      case 'saturday':
        return 6;
    }
  }

  static getStartOfWeekDayForDay(day: Day): Day {
    let delta = 0;

    switch (day.dayOfWeek) {
      case 'sunday':
        return day;
      case 'monday':
        delta = 1;
        break;
      case 'tuesday':
        delta = 2;
        break;
      case 'wednesday':
        delta = 3;
        break;
      case 'thursday':
        delta = 4;
        break;
      case 'friday':
        delta = 5;
        break;
      case 'saturday':
        delta = 6;
        break;
    }

    return day.substractDays(delta);
  }
}
