import { LocalizationService } from '@shared/resources/services';
import { BlackbaudConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface BlackbaudAccountViewModel extends ExternalAccountEditionViewModel {
  accountName: string;
  serverUrl: string;
  username: string;
  password: string;
}

export class AppBlackbaudAccountViewModel
  extends BaseExternalAccountEditionViewModel
  implements BlackbaudAccountViewModel
{
  @observable private _accountName?: string;
  @observable private _serverUrl?: string;
  @observable private _username?: string;
  @observable private _password?: string;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _blackbaudStore: BlackbaudConnectorStore,
    private readonly _onSuccess: (externalAccountId: string) => Promise<void>,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    private readonly _originalAccountName: string,
    private readonly _originalServerUrl: string,
    private readonly _originalUsername: string,
    private readonly _originalToken: string
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get accountName() {
    return this._accountName ?? this._originalAccountName;
  }

  set accountName(value: string) {
    this._accountName = value;
    this.onChange();
  }

  @computed
  get serverUrl() {
    return this._serverUrl ?? this._originalServerUrl;
  }

  set serverUrl(value: string) {
    this._serverUrl = value;
    this.onChange();
  }

  @computed
  get username() {
    return this._username ?? this._originalUsername;
  }

  set username(value: string) {
    this._username = value;
    this.onChange();
  }

  @computed
  get password() {
    return this._password ?? '';
  }

  set password(value: string) {
    this._password = value;
    this.onChange();
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this._hasChanges) {
      console.error('Applying without changes. Ignoring...');
      await this._onSuccess(this._externalAccountId);
      return;
    }

    this.beginApplying();

    try {
      const newId = await this._blackbaudStore.createOrUpdateBlackbaudAccount(
        this._configId,
        this._externalAccountId,
        this.accountName,
        this.serverUrl,
        this.username,
        this.password
      );

      await this._onSuccess(newId);
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._accountName = undefined;
    this._serverUrl = undefined;
    this._username = undefined;
    this._password = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this._hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
