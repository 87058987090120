import { SchoolYearConfiguration_Schedule as PBSchedule } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { BaseModel, SerializableModel } from '../Model';
import { Color } from '../types';
import { colorFromProtobuf } from '../types/EnumConversion';
import { SchedulePeriod, SchedulePeriodModel } from './SchedulePeriod';

export interface ScheduleModel extends SerializableModel<PBSchedule> {
  readonly id: string;
  readonly tag: string;
  readonly tags: string[];
  readonly title: string;
  readonly periods: SchedulePeriodModel[];
  readonly color: Color;
  readonly isHidden: boolean;
  readonly isNoSchool: boolean;
}

export class Schedule extends BaseModel<PBSchedule> implements ScheduleModel {
  constructor(pb: PBSchedule) {
    super(pb);
  }

  get id(): string {
    return this._pb.id;
  }

  get tag(): string {
    return this._pb.tag;
  }

  get tags(): string[] {
    return this._pb.tags;
  }

  get title(): string {
    return this._pb.title;
  }

  get periods(): SchedulePeriodModel[] {
    return this._pb.periods.map((p) => new SchedulePeriod(p));
  }

  get color(): Color {
    return colorFromProtobuf(this._pb.color);
  }

  get isHidden(): boolean {
    return this._pb.isHidden;
  }

  get isNoSchool(): boolean {
    return this._pb.isNoSchoolSchedule;
  }
}
