export interface AskNameStrings {
  confirm: string;
  errorMessage: string;
  firstName: string;
  lastName: string;
  subTitle: string;
  title: string;

  readonly savingTitle: string;
  readonly savingDescription: string;
  readonly savedTitle: string;
  readonly savedDescription: string;
  readonly saveErrorTitle: string;
  readonly saveErrorDescription: string;
  readonly savePermanentErrorDescription: string;
}

export const EnglishAskNameStrings: AskNameStrings = {
  confirm: 'Confirm',
  errorMessage: 'An error occurred, please try again',
  firstName: 'First name',
  lastName: 'Last name',
  subTitle: 'Your teacher needs your full name, well written:',
  title: 'Complete your information',

  savingTitle: 'Please wait…',
  savingDescription: 'Saving you informations',
  savedTitle: 'Success',
  savedDescription: 'Your information were saved successfully!',
  saveErrorTitle: 'Oops, something went wrong!',
  saveErrorDescription: 'An error occurred while saving your information. Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented the save. Please reload and try again.'
};

export const FrenchAskNameStrings: AskNameStrings = {
  confirm: 'Confirmer',
  errorMessage: 'Une erreur est survenue, veuillez réessayer',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  subTitle: 'Ton enseignant a besoin de ton nom complet, bien orthographié:',
  title: 'Complète tes informations',

  savingTitle: 'Veuillez patienter…',
  savingDescription: 'Sauvegarde de vos informations en cours',
  savedTitle: 'Succès',
  savedDescription: 'Vos informations ont été sauvegardées!',
  saveErrorTitle: 'Oups, une erreur est survenue!',
  saveErrorDescription: 'Vos informations n’ont pu être sauvegardées. Veuillez réessayer.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la sauvegarde. Veuillez rafraichir et réessayer.'
};
