import { Integration, PublishStatus } from '@shared/models/types';

export interface TaskInfoStrings {
  actionsButtonTitle: string;
  assessmentFilteredTask: string;
  attachmentsTitle: string;
  close: string;
  completeButtonText: string;
  editButton: string;
  fromTooltip: string;
  isPrivateMessage: string;
  menuActionCopy: string;
  menuActionDelete: string;
  menuActionUndelete: string;
  menuActionMarkUndone: string;
  menuActionPublish: string;
  menuActionPublishGroup: (linkedTask: boolean) => string;
  menuActionUnpublish: string;
  menuActionRepeat: string;
  menuActionDistribute: string;
  notesTitle: string;
  noCourseTitle: string;
  noLoadMessage: string;
  stepsTitle: string;
  taskPublishedTooltipMore: (count: number) => string;
  taskPublishedTooltipTitle: string;
  taskWithNoSectionTitle: string;
  teacherNotesTitle: string;

  linkedTaskCount: (count: number) => string;
  unlink: string;

  externalSourceButtonTitle: (source: Integration) => string;

  longTimeSpanAlertTitle: string;
  longTimeSpanAlertDescription: string;
  longTimeSpanAlertPublish: string;

  publishingTitle: string;
  publishingDescription: string;
  publishedTitle: string;
  publishedDescription: string;
  publishErrorTitle: string;
  publishErrorDescription: string;
  publishPermanentErrorDescription: string;

  unpublishingTitle: string;
  unpublishingDescription: string;
  unpublishedTitle: string;
  unpublishedDescription: string;
  unpublishErrorTitle: string;
  unpublishErrorDescription: string;
  unpublishPermanentErrorDescription: string;

  publishingBanner: string;
  unpublishingBanner: string;
  publishErrorBanner: string;
  rootAdminBanner: string;
  publishErrorDetailsLabel: string;
  publishErrorDetailsTitle: string;
  lastPublishingLabel: string;
  lastPublishedLabel: string;
  lastPublishTraceId: string;
  never: string;
  publishStatus: (status?: PublishStatus) => string;

  deleteConfirmTitle: string;
  deleteConfirmDescription: string;
  deleteConfirmStayTitle: string;
  deleteConfirmDiscardTitle: string;

  unlinkConfirmTitle: string;
  unlinkConfirmDescription: string;
  unlinkConfirmStayTitle: string;
  unlinkConfirmProceedTitle: string;
}

export const EnglishTaskInfoStrings: TaskInfoStrings = {
  actionsButtonTitle: 'Actions…',
  attachmentsTitle: 'Attachments',
  assessmentFilteredTask:
    'This task is not yet visible to students as it’s due date is beyond the assessment planning date set by your administrator.',
  close: 'Close',
  completeButtonText: 'Mark as done',
  editButton: 'Edit',
  fromTooltip: 'From',
  isPrivateMessage: 'This task is private.',
  menuActionCopy: 'Copy',
  menuActionDelete: 'Delete',
  menuActionUndelete: 'Reactivate',
  menuActionMarkUndone: 'Mark as not done',
  menuActionPublish: 'Publish to…',
  menuActionPublishGroup: (linked) => `Publish to group${linked ? 's' : ''}`,
  menuActionUnpublish: 'Unpublish',
  menuActionRepeat: 'Repeat',
  menuActionDistribute: 'Distribute',
  notesTitle: 'Notes',
  noCourseTitle: 'No Section',
  noLoadMessage: 'This task will auto-complete after its due date.',
  stepsTitle: 'Steps',
  taskPublishedTooltipMore: (count) => `and ${count} more`,
  taskPublishedTooltipTitle: 'Published to:',
  taskWithNoSectionTitle: 'Personal',
  teacherNotesTitle: "Teacher's notes",

  linkedTaskCount: (count) => {
    if (count === 1) {
      // Though this should never happen, we still return a readable string.
      console.warn('Unexpected linked tasks count of 1');
      return 'This task appears once.';
    } else if (count === 2) {
      return 'This task appears twice.';
    } else {
      return `This task appears ${count} times.`;
    }
  },
  unlink: 'Unlink',

  externalSourceButtonTitle: (source) => {
    switch (source) {
      case 'blackbaud':
        return 'View in Blackbaud';
      case 'canvas':
        return 'View in Canvas';
      case 'google-classroom':
      case 'google-classroom-connector':
        return 'View in Google Classroom';
      case 'gradeslam':
        return 'View in Gradeslam';
      case 'managebac-connector':
        return 'View in ManageBac';
      case 'microsoft-teams-connector':
        return 'View in Teams';
      case 'moodle-connector':
        return 'View in Moodle';
      case 'schoology-connector':
        return 'View in Schoology';
      case 'veracross':
      case 'veracross-connector':
        return 'View in Veracross';
      default:
        return 'Open external link';
    }
  },

  longTimeSpanAlertTitle: 'Long lasting task',
  longTimeSpanAlertDescription:
    'It is strongly recommended that the announcement date be no more than 14 days prior to the due date. This simplifies the task view for students and helps them plan more efficiently. Do you wish to continue?',
  longTimeSpanAlertPublish: 'Publish',

  publishingTitle: 'Please wait…',
  publishingDescription: 'Publishing task to group',
  publishedTitle: 'Success',
  publishedDescription: 'The task was published successfully to the group',
  publishErrorTitle: 'Oops, something went wrong!',
  publishErrorDescription: 'An error occurred while publishing your task. Please try again.',
  publishPermanentErrorDescription: 'A change made elsewhere prevented publishing. Please reload and try again.',

  unpublishingTitle: 'Please wait…',
  unpublishingDescription: 'Unpublishing task',
  unpublishedTitle: 'Success',
  unpublishedDescription: 'The task was unpublished successfully',
  unpublishErrorTitle: 'Oops, something went wrong!',
  unpublishErrorDescription: 'An error occurred while unpublishing your task. Please try again.',
  unpublishPermanentErrorDescription: 'A change made elsewhere prevented unpublishing. Please reload and try again.',

  publishingBanner: 'The task is being published…',
  unpublishingBanner: 'The task is being unpublished…',
  publishErrorBanner: 'An error occurred publishing or unpublishing the task. We will retry periodically.',
  rootAdminBanner: 'Publishing details are available',
  publishErrorDetailsLabel: 'Details',
  publishErrorDetailsTitle: 'Publishing error details',
  lastPublishingLabel: 'Last tentative',
  lastPublishedLabel: 'Last success',
  lastPublishTraceId: 'Trace id',
  never: 'Never',
  publishStatus: (status) => {
    switch (status ?? 'not-published') {
      case 'not-published':
        return 'Not published';
      case 'published':
        return 'Published successfully';
      case 'publishing':
        return 'Publishing/unpublishing';
      case 'publish-error':
        return 'Publish error';
    }
  },

  deleteConfirmTitle: 'Delete task',
  deleteConfirmDescription: 'Are you sure you wish to delete this task?',
  deleteConfirmStayTitle: 'No',
  deleteConfirmDiscardTitle: 'Delete',

  unlinkConfirmTitle: 'Unlink occurrence',
  unlinkConfirmDescription: 'Changes to other occurrences will no longer affect this one. Do you wish to proceed?',
  unlinkConfirmStayTitle: 'No',
  unlinkConfirmProceedTitle: 'Unlink'
};

export const FrenchTaskInfoStrings: TaskInfoStrings = {
  actionsButtonTitle: 'Actions…',
  assessmentFilteredTask:
    'Cette tâche n’est pas encore visible pour les élèves, car elle est due après la date de visibilité définie par votre gestionnaire.',
  attachmentsTitle: 'Pièces jointes',
  close: 'Fermer',
  completeButtonText: 'Compléter',
  editButton: 'Modifier',
  fromTooltip: 'De ',
  isPrivateMessage: 'Cette tâche est privée.',
  menuActionCopy: 'Copier',
  menuActionDelete: 'Supprimer',
  menuActionUndelete: 'Réactiver',
  menuActionMarkUndone: 'Marquer comme non-complétée',
  menuActionPublish: 'Publier à…',
  menuActionPublishGroup: (linked) => `Publier au${linked ? 'x' : ''} groupe${linked ? 's' : ''}`,
  menuActionUnpublish: 'Dépublier',
  menuActionRepeat: 'Répéter',
  menuActionDistribute: 'Distribuer',
  notesTitle: 'Notes',
  noCourseTitle: 'Aucun cours',
  noLoadMessage: 'Cette tâche sera automatiquement complétée une fois échue.',
  stepsTitle: 'Étapes',
  taskPublishedTooltipMore: (count) => `et ${count} autres`,
  taskPublishedTooltipTitle: 'Publiée à:',
  taskWithNoSectionTitle: 'Personnel',
  teacherNotesTitle: 'Notes de l’enseignant',

  linkedTaskCount: (count) => `Cette tâche apparaît ${count} fois.`,
  unlink: 'Dissocier',

  externalSourceButtonTitle: (source) => {
    switch (source) {
      case 'blackbaud':
        return 'Afficher dans Blackbaud';
      case 'canvas':
        return 'Afficher dans Canvas';
      case 'google-classroom':
      case 'google-classroom-connector':
        return 'Afficher dans Google Classroom';
      case 'gradeslam':
        return 'Afficher dans Gradeslam';
      case 'managebac-connector':
        return 'Afficher dans ManageBac';
      case 'microsoft-teams-connector':
        return 'Afficher dans Teams';
      case 'moodle-connector':
        return 'Afficher dans Moodle';
      case 'schoology-connector':
        return 'Afficher dans Schoology';
      case 'veracross':
      case 'veracross-connector':
        return 'Afficher dans Veracross';
      default:
        return 'Afficher le lien externe';
    }
  },

  longTimeSpanAlertTitle: 'Tâche de longue durée',
  longTimeSpanAlertDescription:
    "Il est fortement recommandé d’avoir une date d’annonce d'au plus 14 jours avant la date d’échéance. Ceci permet d’alléger la vue des tâches des élèves et les aider à mieux planifier. Voulez-vous continuer?",
  longTimeSpanAlertPublish: 'Publier',

  publishingTitle: 'Veuillez patienter…',
  publishingDescription: 'Publication de la tâche en cours',
  publishedTitle: 'Succès',
  publishedDescription: 'La tâche a été publiée!',
  publishErrorTitle: 'Oups, une erreur est survenue!',
  publishErrorDescription: 'La tâche n’a pu être publiée. Veuillez réessayer.',
  publishPermanentErrorDescription:
    'Un changement fait ailleurs a empêché la publication. Veuillez rafraichir et réessayer.',

  unpublishingTitle: 'Veuillez patienter…',
  unpublishingDescription: 'Dépublication de la tâche en cours',
  unpublishedTitle: 'Succès',
  unpublishedDescription: 'La tâche a été dépubliée!',
  unpublishErrorTitle: 'Oups, une erreur est survenue!',
  unpublishErrorDescription: 'La tâche n’a pu être dépubliée. Veuillez réessayer.',
  unpublishPermanentErrorDescription:
    'Un changement fait ailleurs a empêché la dépublication. Veuillez rafraichir et réessayer.',

  publishingBanner: 'La tâche est en cours de publication…',
  unpublishingBanner: 'La tâche est en cours de dépublication…',
  publishErrorBanner: 'Une erreur est survenue à la publication ou dépublication. Nous réessaierons périodiquement.',
  rootAdminBanner: 'Détails de publication disponibles',
  publishErrorDetailsLabel: 'Détails',
  publishErrorDetailsTitle: 'Détails d’erreur de publication',
  lastPublishingLabel: 'Dernière tentative',
  lastPublishedLabel: 'Dernier succès',
  lastPublishTraceId: 'Id de trace',
  never: 'Jamais',
  publishStatus: (status) => {
    switch (status ?? 'not-published') {
      case 'not-published':
        return 'Non publiée';
      case 'published':
        return 'Publiée avec succès';
      case 'publishing':
        return 'En publication/dépublication';
      case 'publish-error':
        return 'Erreur de publication';
    }
  },

  deleteConfirmTitle: 'Supprimer la tâche',
  deleteConfirmDescription: 'Êtes vous sûr de vouloir supprimer cette tâche?',
  deleteConfirmStayTitle: 'Non',
  deleteConfirmDiscardTitle: 'Supprimer',

  unlinkConfirmTitle: 'Dissocier l’occurrence',
  unlinkConfirmDescription:
    'Les changements effectués aux autres occurrences n’affecteront plus cette tâche. Souhaitez-vous procéder?',
  unlinkConfirmStayTitle: 'Non',
  unlinkConfirmProceedTitle: 'Dissocier'
};
