import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import { ImportSession } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ImportSessionEditDialogProps extends DialogResult<void> {
  sx?: SxProps;
  session: ImportSession;
  className?: string;
  style?: CSSProperties;
}

export const ImportSessionEditDialog = observer((props: ImportSessionEditDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], style, className, session, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createImportSessionEdit(session, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="sm"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <DialogTitle>{strings.editSessionTitle}</DialogTitle>

          <DialogContent dividers>
            <Box height="100%" width="100%" display="flex" flexDirection="column" overflow="visible">
              <TextField
                sx={{ mb: 2 }}
                label={strings.sessionNameLabel}
                value={viewModel.name}
                fullWidth
                required
                autoFocus
                onChange={(e) => (viewModel.name = e.target.value)}
              />

              <TextField
                sx={{ mb: 2 }}
                label={strings.sessionDescriptionLabel}
                value={viewModel.description}
                multiline
                fullWidth
                onChange={(e) => (viewModel.description = e.target.value)}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            {viewModel.hasError && (
              <Typography
                flex={1}
                variant="caption"
                sx={{ color: (theme) => theme.palette.error.main }}
                style={{ textAlign: 'end' }}
              >
                {strings.editSessionError}
              </Typography>
            )}
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => void viewModel.cancel()}>
              {strings.editSessionCancelButtonLabel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={!viewModel.canSave}
              isExecuting={viewModel.isExecuting}
              onClick={() => void viewModel.save()}
            >
              {strings.editSessionSaveButtonLabel}
            </InsightsButton>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
});
