export interface SectionViewModelStrings {
  deleteConfirmation: string;
  sectionInUseByStudents: string;
  sectionReferencedByActiveContent: string;
  unexpectedError: string;
}

export const EnglishSectionViewModelStrings: SectionViewModelStrings = {
  deleteConfirmation: 'Are you sure you want to delete this section?',
  sectionInUseByStudents: 'Cannot delete a section that is selected by students.',
  sectionReferencedByActiveContent: 'Cannot delete a section that contains active tasks or notes.',
  unexpectedError: 'An unexpected error occurred: '
};

export const FrenchSectionViewModelStrings: SectionViewModelStrings = {
  deleteConfirmation: 'Êtes-vous certain de vouloir effacer ce groupe-matière?',
  sectionInUseByStudents: 'Impossible d’effacer un groupe-matière en utilisation par un élève.',
  sectionReferencedByActiveContent:
    'Impossible d’effacer un groupe-matière qui contient des tâches actives ou des notes.',
  unexpectedError: 'Une erreur inattendue est survenue: '
};
