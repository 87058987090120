import { computed, makeObservable, observable } from 'mobx';

export interface SectionStudentsEditionIdsSelectionViewModel {
  ids: string;
  readonly parsedIds: string[];

  proceed(): void;
  close(): void;
}

export class AppSectionStudentsEditionIdsSelectionViewModel implements SectionStudentsEditionIdsSelectionViewModel {
  @observable private _ids = '';

  constructor(
    private readonly _onSuccess: (studentIds: string[]) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get ids() {
    return this._ids;
  }

  set ids(value: string) {
    this._ids = value;
  }

  @computed
  get parsedIds() {
    return this._ids.split(/[,\s]/g).filter((id) => id.length > 0);
  }

  proceed() {
    this._onSuccess(this.parsedIds);
  }

  close() {
    this._onCancel();
  }
}
