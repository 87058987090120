import { AlertService } from '@insights/services';
import { SuggestedTags } from '@insights/utils';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { EditableSchoolYearConfiguration, SchoolYearConfigurationModel } from '@shared/models/config';
import { ConfigState, Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { action, computed, makeObservable } from 'mobx';
import { AppEditViewModelBase, EditViewModelBase } from '../../EditViewModelBase';
import {
  MaximumCommentsLength,
  MaximumOnboardingCodeLength,
  MaximumSchoolNameLength,
  MaximumSchoolTitleLength
} from '../calendar';

export type SchoolYearConfigurationInformationFields =
  | 'schoolName'
  | 'schoolTitle'
  | 'comments'
  | 'onboardingCode'
  | 'onboardingCodeEmailDomain'
  | 'demoSchool'
  | 'demoDay'
  | 'timezone'
  | 'language'
  | 'state'
  | 'tags'
  | 'expectedStudentCount';

export interface EditSchoolYearConfigurationInformationDialogViewModel
  extends EditViewModelBase<SchoolYearConfigurationInformationFields> {
  readonly schoolDisplayTitle: string;
  readonly minDemoDay: Day;
  readonly maxDemoDay: Day;
  readonly suggestedTags: string[];
}

export class AppEditSchoolYearConfigurationInformationDialogViewModel
  extends AppEditViewModelBase<SchoolYearConfigurationInformationFields>
  implements EditSchoolYearConfigurationInformationDialogViewModel
{
  private readonly _editableSchoolYearConfiguration: EditableSchoolYearConfiguration;

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    schoolYearConfiguration: SchoolYearConfigurationModel,
    alertService: AlertService,
    localizationService: LocalizationService,
    onSuccess: () => void,
    onCancel: () => void
  ) {
    super(alertService, localizationService, onSuccess, onCancel);
    makeObservable(this);
    this._editableSchoolYearConfiguration = new EditableSchoolYearConfiguration(schoolYearConfiguration);
  }

  @computed
  get schoolDisplayTitle(): string {
    return SchoolYearConfigurationUtils.displayTitle(this._editableSchoolYearConfiguration);
  }

  @computed
  get minDemoDay() {
    return this._editableSchoolYearConfiguration.startDay;
  }

  @computed
  get maxDemoDay() {
    return this._editableSchoolYearConfiguration.endDay;
  }

  get suggestedTags(): string[] {
    return SuggestedTags;
  }

  @computed
  get hasChanges(): boolean {
    return this._editableSchoolYearConfiguration.hasChanges;
  }

  getFieldValueCore(field: SchoolYearConfigurationInformationFields): unknown {
    switch (field) {
      case 'schoolName':
        return this._editableSchoolYearConfiguration.schoolName;
      case 'schoolTitle':
        return this._editableSchoolYearConfiguration.title;
      case 'comments':
        return this._editableSchoolYearConfiguration.comments;
      case 'onboardingCode':
        return this._editableSchoolYearConfiguration.managedOnboardCode;
      case 'onboardingCodeEmailDomain':
        return this._editableSchoolYearConfiguration.onboardingCodeEmailDomain;
      case 'demoSchool':
        return this._editableSchoolYearConfiguration.isDemo;
      case 'demoDay':
        return this._editableSchoolYearConfiguration.demoDay?.asDate;
      case 'timezone':
        return this._editableSchoolYearConfiguration.timezone;
      case 'language':
        return this._editableSchoolYearConfiguration.language;
      case 'state':
        return this._editableSchoolYearConfiguration.state;
      case 'tags':
        return this._editableSchoolYearConfiguration.tags;
      case 'expectedStudentCount':
        return this._editableSchoolYearConfiguration.expectedStudentCount;
      default:
        throw new Error('Unkwown field');
    }
  }

  setFieldValueCore(field: SchoolYearConfigurationInformationFields, value: unknown) {
    switch (field) {
      case 'schoolName':
        this._editableSchoolYearConfiguration.schoolName = String(value);
        this.validateSchoolName();
        break;
      case 'schoolTitle':
        this._editableSchoolYearConfiguration.title = String(value);
        this.validateSchoolTitle();
        break;
      case 'comments':
        this._editableSchoolYearConfiguration.comments = String(value);
        this.validateComments();
        break;
      case 'onboardingCode':
        this._editableSchoolYearConfiguration.managedOnboardCode = String(value);
        this.validateOnboardingCode();
        break;
      case 'onboardingCodeEmailDomain':
        this._editableSchoolYearConfiguration.onboardingCodeEmailDomain = String(value);
        break;
      case 'demoSchool': {
        const isDemo = Boolean(value);
        this._editableSchoolYearConfiguration.isDemo = isDemo;
        // Does not need validation
        if (!isDemo) {
          this._editableSchoolYearConfiguration.demoDay = undefined;
        }
        break;
      }
      case 'demoDay':
        this._editableSchoolYearConfiguration.demoDay = value != null ? Day.fromDate(value as Date) : undefined;
        // No validation either
        break;
      case 'timezone':
        this._editableSchoolYearConfiguration.timezone = String(value);
        // Does not need validation
        break;
      case 'language':
        this._editableSchoolYearConfiguration.language = String(value);
        // Does not need validation
        break;
      case 'state':
        this._editableSchoolYearConfiguration.state = value as ConfigState;
        // Does not need validation
        break;
      case 'tags':
        this._editableSchoolYearConfiguration.tags = value as string[];
        // Does not need validation
        break;
      case 'expectedStudentCount':
        this._editableSchoolYearConfiguration.expectedStudentCount = value as number;
        // Does not need validation
        break;
      default:
        throw new Error('Unknown field');
    }
  }

  protected async saveCore(): Promise<void> {
    await this._schoolYearConfigurationStore.saveConfig(this._editableSchoolYearConfiguration);
  }

  protected validateFields() {
    this.validateSchoolName();
    this.validateSchoolTitle();
    this.validateComments();
    this.validateOnboardingCode();
  }

  @action
  private validateSchoolName() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.schoolYearConfiguration;

    const fieldName = 'schoolName';
    const fieldValue = this.getFieldValue(fieldName);
    let fieldError: string | undefined = undefined;

    if (fieldValue == null) {
      fieldError = strings.requiredField;
    } else {
      const fieldValueAsString = fieldValue as string;

      if (fieldValueAsString.length != null && fieldValueAsString.length > MaximumSchoolNameLength) {
        fieldError = strings.fieldTooLong(MaximumSchoolNameLength);
      }
    }

    this.setError(fieldName, fieldError);
  }

  @action
  private validateSchoolTitle() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.schoolYearConfiguration;

    const fieldName = 'schoolTitle';
    const fieldValue = this.getFieldValue(fieldName);
    let fieldError: string | undefined = undefined;

    const fieldValueAsString = fieldValue as string;
    if (fieldValueAsString.length != null && fieldValueAsString.length > MaximumSchoolTitleLength) {
      fieldError = strings.fieldTooLong(MaximumSchoolTitleLength);
    }

    this.setError(fieldName, fieldError);
  }

  @action
  private validateComments() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.schoolYearConfiguration;

    const fieldName = 'comments';
    const fieldValue = this.getFieldValue(fieldName);
    let fieldError: string | undefined = undefined;

    const fieldValueAsString = fieldValue as string;
    if (fieldValueAsString.length != null && fieldValueAsString.length > MaximumCommentsLength) {
      fieldError = strings.fieldTooLong(MaximumCommentsLength);
    }

    this.setError(fieldName, fieldError);
  }

  @action
  private validateOnboardingCode() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.schoolYearConfiguration;

    const fieldName = 'onboardingCode';
    const fieldValue = this.getFieldValue(fieldName);
    let fieldError: string | undefined = undefined;

    const fieldValueAsString = fieldValue as string;
    if (fieldValueAsString.length != null && fieldValueAsString.length > MaximumOnboardingCodeLength) {
      fieldError = strings.fieldTooLong(MaximumOnboardingCodeLength);
    }

    this.setError(fieldName, fieldError);
  }
}
