import {
  BlackbaudAccount,
  Column,
  Container,
  ErrorIndicator,
  Expanded,
  InsightsButton,
  LoadingObservablePresenter,
  Scroll,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Box, Dialog, DialogContent, DialogTitle, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface BlackbaudAccountDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  externalAccountId: string;
}

export const BlackbaudAccountDialog = observer((props: BlackbaudAccountDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], configId, externalAccountId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createBlackbaudAccount(configId, externalAccountId, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog sx={sx} open={true} fullWidth maxWidth="sm">
        <DialogTitle>{strings.blackbaudAccount}</DialogTitle>

        <DialogContent>
          <LoadingObservablePresenter
            data={viewModel.account}
            loadingMessage={strings.loadingBlackbaudMessage}
            render={(data) => (
              <Scroll direction="y">
                <Container sx={{ p: 0.5 }}>
                  <Column>
                    <Expanded>
                      <BlackbaudAccount viewModel={data} />
                    </Expanded>
                  </Column>
                </Container>
              </Scroll>
            )}
            renderError={() => (
              <Column>
                <ErrorIndicator size="normal" message={strings.loadingError} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    m: 1
                  }}
                >
                  <InsightsButton isDefault onClick={() => onCancel!()}>
                    {strings.cancelButton}
                  </InsightsButton>
                </Box>
              </Column>
            )}
          />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
});
