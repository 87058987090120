import { NavigationService, SettingsStore } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { groupBy } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { Thresholds } from '../../../Constants';
import { AppPaginatedViewModel, PaginatedViewModel, PaginationViewModel } from './PaginatedViewModel';

export interface WorkloadManagerViewModelBase<TData, TPageData> extends PaginatedViewModel {
  readonly configId: string;

  readonly data: IPromiseBasedObservable<TData>;
  readonly pageData: IPromiseBasedObservable<TPageData>;

  examOnly: boolean;

  editAssessmentPlanningDates: () => Promise<void>;
}

export abstract class AppWorkloadManagerViewModelBase<TData, TPageData>
  extends AppPaginatedViewModel
  implements WorkloadManagerViewModelBase<TData, TPageData>
{
  protected constructor(
    public readonly configId: string,
    protected readonly _navigationService: NavigationService,
    protected readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    protected readonly _settingsStore: SettingsStore,
    paginationViewModel?: PaginationViewModel
  ) {
    super(paginationViewModel);
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<TData> {
    return fromPromise(this.loadData());
  }

  @computed
  get pageData(): IPromiseBasedObservable<TPageData> {
    return fromPromise(this.loadPageData());
  }

  @computed
  get examOnly(): boolean {
    return this._settingsStore.workloadPreferences.examOnly;
  }

  set examOnly(value: boolean) {
    this._settingsStore.workloadPreferences.examOnly = value;
  }

  async editAssessmentPlanningDates(): Promise<void> {
    const config = await this._schoolYearConfigurationStore.getConfig(this.configId);
    await this._navigationService.navigateToEditSchoolYearConfigurationAssessmentPlanning(config);
  }

  protected abstract loadData(): Promise<TData>;
  protected abstract loadPageData(): Promise<TPageData>;

  protected computeThresholds(
    studentTasks: ContentDefinitionModel[],
    threshold: number,
    students: Map<string, AccountModel>
  ): { studentIdsAtThreshold: string[]; studentIdsOverThreshold: string[] } {
    if (threshold === Thresholds.maxThreshold) {
      return { studentIdsAtThreshold: [], studentIdsOverThreshold: [] };
    }

    const attendingStudentTasks = studentTasks.filter((task) => students.has(task.ownerId));
    const attendingStudentTasksByStudentId = groupBy(attendingStudentTasks, (task) => task.ownerId);

    const studentIdsAtThreshold = Object.entries(attendingStudentTasksByStudentId)
      .filter(([, tasks]) => tasks.length === threshold)
      .map(([ownerId]) => ownerId);

    const studentIdsOverThreshold = Object.entries(attendingStudentTasksByStudentId)
      .filter(([, tasks]) => tasks.length > threshold)
      .map(([ownerId]) => ownerId);

    return { studentIdsAtThreshold, studentIdsOverThreshold };
  }
}
