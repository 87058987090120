import { AccountInfo, StudentBehaviorAggregationInfo } from '@insights/models';
import { AlertService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { computed, makeObservable } from 'mobx';
import { AppBehaviorAggregationViewModel, BehaviorAggregationViewModel } from './BehaviorAggregationViewModel';
import { BaseExportableViewModel, ExportableViewModel } from './ExportableViewModel';

export interface FilteredStudentsViewModel extends ExportableViewModel {
  readonly students: AccountInfo[];
  readonly hasStudents: boolean;

  readonly opensAppBehavior?: BehaviorAggregationViewModel;
  readonly completesTasksBehavior?: BehaviorAggregationViewModel;
  readonly invitesParentBehavior?: BehaviorAggregationViewModel;
}

export class AppFilteredStudentsViewModel extends BaseExportableViewModel implements FilteredStudentsViewModel {
  protected readonly _opensAppBehavior: BehaviorAggregationViewModel | undefined;
  private readonly _completesTasksBehavior: BehaviorAggregationViewModel | undefined;
  private readonly _invitesParentBehavior: BehaviorAggregationViewModel | undefined;

  constructor(
    localizationService: LocalizationService,
    alertService: AlertService,
    private readonly _students: AccountInfo[],
    behaviors?: StudentBehaviorAggregationInfo
  ) {
    super(localizationService, alertService);
    makeObservable(this);

    if (behaviors != null) {
      this._opensAppBehavior = new AppBehaviorAggregationViewModel(behaviors.opensAppBehavior);
      this._completesTasksBehavior = new AppBehaviorAggregationViewModel(behaviors.completesTasksBehavior);

      if (behaviors.invitesParentBehavior != null) {
        this._invitesParentBehavior = new AppBehaviorAggregationViewModel(behaviors.invitesParentBehavior);
      }
    }
  }

  @computed
  get students(): AccountInfo[] {
    let students = this._students;

    if (this._opensAppBehavior != null && this._opensAppBehavior.activeFilters.length > 0) {
      students = students.filter(
        (s) => s.oqProfile != null && this._opensAppBehavior!.activeFilters.includes(s.oqProfile.opensTheApp)
      );
    }

    if (this._completesTasksBehavior != null && this._completesTasksBehavior.activeFilters.length > 0) {
      students = students.filter(
        (s) => s.oqProfile != null && this._completesTasksBehavior!.activeFilters.includes(s.oqProfile.marksTasksAsDone)
      );
    }

    if (this._invitesParentBehavior != null && this._invitesParentBehavior.activeFilters.length > 0) {
      students = students.filter(
        (s) => s.invitesParent != null && this._invitesParentBehavior!.activeFilters.includes(s.invitesParent)
      );
    }

    return students;
  }

  get hasStudents(): boolean {
    // Not affected by filtering.
    return this._students.length > 0;
  }

  @computed
  get opensAppBehavior(): BehaviorAggregationViewModel | undefined {
    return this._opensAppBehavior;
  }

  @computed
  get completesTasksBehavior(): BehaviorAggregationViewModel | undefined {
    return this._completesTasksBehavior;
  }

  @computed
  get invitesParentBehavior(): BehaviorAggregationViewModel | undefined {
    return this._invitesParentBehavior;
  }

  readonly canExport = true;

  @computed
  get exportTooltip(): string {
    return this._localizationService.localizedStrings.insights.viewModels.metrics.exportStudentsTooltip;
  }

  protected readonly filename = 'Students';

  protected async getExportedData(): Promise<Record<string, string>[]> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.csvExports;

    return Promise.resolve(
      this.students.map((s) => ({
        [strings.id]: s.account.managedIdentifier,
        [strings.lastName]: s.account.lastName,
        [strings.firstName]: s.account.firstName,
        [strings.email]: s.account.email,
        [strings.gradeLevel]: s.account.gradeLevel,
        [strings.opensApp]: s.oqProfile?.opensTheApp ?? '',
        [strings.completesTasks]: s.oqProfile?.marksTasksAsDone ?? '',
        [strings.invitedParent]: s.invitesParent ?? ''
      }))
    );
  }
}
