import { SubscriptionEntitlement } from '@shared/models/types';

export interface FeaturesViewModelStrings {
  confirmRemoveEntitlementMessage: (entitlement: SubscriptionEntitlement) => string;
  confirmRemoveEntitlementTitle: string;
  entitlementLabel: (entitlement: SubscriptionEntitlement) => string;
  unexpectedErrorMessage: string;
  unexpectedErrorTitle: string;
}

export const EnglishFeaturesViewModelStrings: FeaturesViewModelStrings = {
  confirmRemoveEntitlementMessage(e) {
    return `Are you sure you want to remove entitlement ${this.entitlementLabel(e)}?`;
  },
  confirmRemoveEntitlementTitle: 'Please Confirm',
  entitlementLabel(e) {
    switch (e) {
      case 'parent-access':
        return 'Parent Access';
      default:
        return 'Unknown';
    }
  },
  unexpectedErrorMessage: 'An unexpected error occurred. Please try again later. Error: ',
  unexpectedErrorTitle: 'Oops'
};

export const FrenchFeaturesViewModelStrings: FeaturesViewModelStrings = {
  confirmRemoveEntitlementMessage(e) {
    return `Êtes-vous certain de vouloir effacer la permission ${this.entitlementLabel(e)}?`;
  },
  confirmRemoveEntitlementTitle: 'Veuillez confirmer',
  entitlementLabel(e) {
    switch (e) {
      case 'parent-access':
        return 'Accès Parent';
      default:
        return 'Inconnue';
    }
  },
  unexpectedErrorMessage: 'Une erreur inattendue est survenue. Veuillez essayer un peu plus tard. Erreur: ',
  unexpectedErrorTitle: 'Oups'
};
