import { ImportSessionDataListViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import ConcatenationIcon from '@mui/icons-material/Layers';
import PlayIcon from '@mui/icons-material/PlayArrowRounded';
import ReorderHeadersIcon from '@mui/icons-material/Sort';
import TransformationIcon from '@mui/icons-material/Transform';
import ViewIcon from '@mui/icons-material/ViewList';
import {
  Box,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { MoreActionMenu } from '../MoreActionMenu';
import { Container } from '../layout';

export interface ImportSessionDataListProps {
  viewModel: ImportSessionDataListViewModel;
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const ImportSessionDataList = observer((props: ImportSessionDataListProps) => {
  const { localizationService } = useInsightsServices();
  const { style, className, viewModel, sx } = props;
  const strings = localizationService.localizedStrings.insights.components.import;
  const navigate = useNavigate();

  return (
    <Container sx={sx} className={className} style={style}>
      <Box flexDirection="column">
        <CardHeader
          title={strings.data}
          action={
            <AuthorizationRoleCondition allowedRoles={['super-admin']}>
              <Tooltip title={strings.addConcatenationTooltip}>
                <IconButton onClick={() => void viewModel.addConcatenation()}>
                  <ConcatenationIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={strings.addTransformationTooltip}>
                <IconButton onClick={() => void viewModel.addTransformation()}>
                  <TransformationIcon />
                </IconButton>
              </Tooltip>
            </AuthorizationRoleCondition>
          }
        />

        <CardContent>
          {viewModel.data.map(({ data, file, concatenation, transformation }, index) => {
            const hasSchema = data.targetSchema.length > 0;
            const columnCount = data.rows[0]?.columns.length ?? data.columnNames.length;
            const globalErrors = data.incidents.map((i) => `${i.message} (${i.code})`);
            const hasGlobalErrors = globalErrors.length > 0;

            // Dim transformations without schemas.
            const iconColor = transformation?.targetSchema.length === 0 ? 'disabled' : 'primary';
            const titleColor = transformation?.targetSchema.length === 0 ? 'textSecondary' : 'textPrimary';

            return (
              <Fragment key={`data-row-${data.label}-${data.targetSchema}`}>
                <Box my={1} flexDirection="row" display="flex" alignItems="flex-start">
                  {(file && <FileIcon sx={{ mr: 1 }} color={iconColor} />) ??
                    (concatenation && <ConcatenationIcon sx={{ mr: 1 }} color={iconColor} />) ??
                    (transformation && <TransformationIcon sx={{ mr: 1 }} color={iconColor} />)}

                  <Typography sx={{ mr: 2 }} variant="subtitle1" color={titleColor} fontWeight="700">
                    {data.label}
                  </Typography>

                  <Box flex={1} px={1}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography variant="subtitle1" color={titleColor}>
                        {file?.name ?? concatenation?.name ?? transformation?.name}
                      </Typography>
                      {data.hashChangedSinceLastImport && (
                        <Typography variant="caption" sx={{ color: (theme) => theme.palette.secondary.main }}>
                          &nbsp;{strings.hashChanged}
                        </Typography>
                      )}
                      {hasGlobalErrors && (
                        <Tooltip title={globalErrors.join('\n')}>
                          <ErrorIcon sx={{ ml: 1 }} fontSize="small" />
                        </Tooltip>
                      )}
                    </Box>
                    <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.secondary }}>
                      {file?.description ?? transformation?.description}
                    </Typography>
                    {transformation && (
                      <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.text.secondary }}>
                        {strings.transformationSourceAndIndexes(
                          transformation.sourceLabel,
                          transformation.indexedSources
                        )}
                      </Typography>
                    )}
                    <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.text.secondary }}>
                      {strings.targetSchemaValue(data.targetSchema)}
                      {data.lastImportTime != null && strings.lastImported(data.lastImportTime)}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: (theme) => theme.palette.text.secondary, fontStyle: 'italic' }}
                    >
                      {strings.columnAndRowCount(columnCount, data.rows.length)}&nbsp;-&nbsp;
                      {strings.renderingTime(Number(data.renderingTimeMilliseconds))}
                    </Typography>
                  </Box>

                  <MoreActionMenu>
                    <MenuItem onClick={() => void viewModel.viewData(data, navigate)}>
                      <ListItemIcon>
                        <ViewIcon />
                      </ListItemIcon>
                      <ListItemText>{strings.viewData}</ListItemText>
                    </MenuItem>

                    {file && (
                      <MenuItem onClick={() => void viewModel.editOrderedHeaders(file)}>
                        <ListItemIcon>
                          <ReorderHeadersIcon />
                        </ListItemIcon>
                        <ListItemText>{strings.editOrderedHeaders}</ListItemText>
                      </MenuItem>
                    )}

                    {hasSchema && (
                      <MenuItem onClick={() => void viewModel.testData(data, navigate)}>
                        <ListItemIcon>
                          <PlayIcon />
                        </ListItemIcon>
                        <ListItemText>{strings.testData}</ListItemText>
                      </MenuItem>
                    )}

                    <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                      {concatenation && (
                        <MenuItem onClick={() => void viewModel.editConcatenationOptions(concatenation)}>
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText>{strings.editConcatenationOptions}</ListItemText>
                        </MenuItem>
                      )}
                      {concatenation && (
                        <MenuItem onClick={() => void viewModel.removeConcatenation(concatenation)}>
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <ListItemText>{strings.removeConcatenation}</ListItemText>
                        </MenuItem>
                      )}
                      {transformation && (
                        <MenuItem onClick={() => void viewModel.editTransformationOptions(transformation)}>
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText>{strings.editTransformationOptions}</ListItemText>
                        </MenuItem>
                      )}
                      {transformation && (
                        <MenuItem onClick={() => void viewModel.editTransformationRecipe(transformation, navigate)}>
                          <ListItemIcon>
                            <TransformationIcon />
                          </ListItemIcon>
                          <ListItemText>{strings.editTransformationRecipe}</ListItemText>
                        </MenuItem>
                      )}
                      {transformation && (
                        <MenuItem onClick={() => void viewModel.removeTransformation(transformation)}>
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <ListItemText>{strings.removeTransformation}</ListItemText>
                        </MenuItem>
                      )}
                    </AuthorizationRoleCondition>
                  </MoreActionMenu>
                </Box>

                {index < viewModel.data.length - 1 && <Divider />}
              </Fragment>
            );
          })}
        </CardContent>
      </Box>
    </Container>
  );
});
