export interface CreateConfigStrings {
  readonly enterCode: string;
  readonly goBack: string;
  readonly learnAboutToday: string;
  readonly loggedInAs: string;
  readonly logout: string;
  readonly noCoursesMatched: string;
  readonly noCoursesSelected: string;
  readonly subtitle: string;
  readonly teacherOrParentNotice: string;
  readonly teacherOrParentSubtitle: string;
  readonly titleInitialConfig: string;
  readonly titleNewConfig: string;
}

export const EnglishCreateConfigStrings: CreateConfigStrings = {
  enterCode: 'Enter a code',
  goBack: 'Go back to planner',
  learnAboutToday: 'Learn about Studyo Go',
  loggedInAs: 'Logged in as',
  logout: 'Log out',
  noCoursesMatched: 'No courses were matched',
  noCoursesSelected: 'You currently have no selected courses.',
  subtitle: 'Enter a STUDENT code to join a Studyo school\nor use Studyo Go to manage your own tasks',
  teacherOrParentNotice:
    'Parents must use the same email as the one they were invited on.\nTeachers and students must use the school email that was provided to us.',
  teacherOrParentSubtitle: 'Expected something else?',
  titleInitialConfig: 'Welcome!',
  titleNewConfig: 'Add a new school'
};

export const FrenchCreateConfigStrings: CreateConfigStrings = {
  enterCode: 'Entrer un code',
  goBack: 'Retour à l’agenda',
  learnAboutToday: 'En savoir plus sur Studyo Go',
  loggedInAs: 'Connecté avec',
  logout: 'Déconnexion',
  noCoursesMatched: 'Aucun cours ne correspond',
  noCoursesSelected: 'Vous n’avez aucun cours sélectionné présentement.',
  subtitle:
    'Entrez le code d’ÉLÈVE pour rejoindre une école Studyo\nou utilisez Studyo Go pour gérer vos propres tâches',
  teacherOrParentNotice:
    'Les parents doivent utiliser le même courriel que celui sur lequel ils furent invités.\nLes enseignants et les élèves doivent utiliser le courriel d’école qui nous a été fourni.',
  teacherOrParentSubtitle: 'Vous n’avez pas de code?',
  titleInitialConfig: 'Bienvenue!',
  titleNewConfig: 'Ajouter une nouvelle école'
};
