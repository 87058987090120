export interface BehaviourSummaryStrings {
  appOpenChartLabel: string;
  appOpenFilterTitle: string;
  appOpenTooltip: string;
  appOpenGreyTooltip: string;
  appOpenGreenTooltip: string;
  appOpenYellowTooltip: string;
  appOpenRedTooltip: string;
  invitesParentTooltip: string;
  invitesParentGreyTooltip: string;
  invitesParentGreenTooltip: string;
  invitesParentYellowTooltip: string;
  invitesParentRedTooltip: string;
  taskCompletionChartLabel: string;
  taskCompletionFilterTitle: string;
  taskCompletionTooltip: string;
  taskCompletionGreenTooltip: string;
  taskCompletionYellowTooltip: string;
  taskCompletionRedTooltip: string;
  featureNotAvailableTooltipNote: string;
  featureNotAvailableTooltip: string;
}

export const EnglishBehaviourSummaryStrings: BehaviourSummaryStrings = {
  appOpenChartLabel: 'Opens the app',
  appOpenFilterTitle: 'Opens the app',
  appOpenTooltip: 'Opens the app',
  appOpenGreyTooltip: 'Not connected yet',
  appOpenGreenTooltip: 'Opens Studyo regularly',
  appOpenYellowTooltip: 'Opens Studyo often',
  appOpenRedTooltip: 'Opens Studyo infrequently',
  invitesParentTooltip: 'Invites parent',
  invitesParentGreyTooltip: 'None invited',
  invitesParentGreenTooltip: 'Parent connected',
  invitesParentYellowTooltip: 'Parent confirmed',
  invitesParentRedTooltip: 'Parent invited',
  taskCompletionChartLabel: 'Completes tasks',
  taskCompletionFilterTitle: 'Completes tasks',
  taskCompletionTooltip: 'Completes tasks',
  taskCompletionGreenTooltip: 'No late tasks',
  taskCompletionYellowTooltip: 'Only a few late tasks',
  taskCompletionRedTooltip: 'Many late tasks',
  featureNotAvailableTooltipNote: 'NOTE',
  featureNotAvailableTooltip: 'Your subscription does not allow you to access this feature. Contact us to get it!'
};

export const FrenchBehaviourSummaryStrings: BehaviourSummaryStrings = {
  appOpenChartLabel: 'Ouvre l’application',
  appOpenFilterTitle: 'Ouvre l’application',
  appOpenTooltip: 'Ouvre l’application',
  appOpenGreyTooltip: 'Jamais connecté',
  appOpenGreenTooltip: 'Ouvre Studyo régulièrement',
  appOpenYellowTooltip: 'Ouvre Studyo assez souvent',
  appOpenRedTooltip: 'Ouvre Studyo peu souvent',
  invitesParentTooltip: 'Invite son parent',
  invitesParentGreyTooltip: 'Aucune invitation',
  invitesParentGreenTooltip: 'Parent connecté',
  invitesParentYellowTooltip: 'Parent confirmé',
  invitesParentRedTooltip: 'Parent invité',
  taskCompletionChartLabel: 'Complète ses tâches',
  taskCompletionFilterTitle: 'Complète ses tâches',
  taskCompletionTooltip: 'Complète ses tâches',
  taskCompletionGreenTooltip: 'Aucune tâche en retard',
  taskCompletionYellowTooltip: 'Peu de tâches en retard',
  taskCompletionRedTooltip: 'Plusieurs tâches en retard',
  featureNotAvailableTooltipNote: 'NOTE',
  featureNotAvailableTooltip:
    'Votre abonnement ne vous donne pas accès à cette fonctionnalité. Contactez-nous pour l’obtenir!'
};
