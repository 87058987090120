import { ExternalAccount as PBExternalAccount } from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import { Time } from '../types';
import { externalAccountKindFromProtobuf } from '../types/EnumConversion';
import { GrpcAutoMatchEntry } from './GrpcAutoMatchEntry';
import { AutoMatchEntry, ExternalAccount } from './interfaces';

export class GrpcExternalAccount implements ExternalAccount {
  constructor(private readonly _pb: PBExternalAccount) {}

  get id() {
    return this._pb.id;
  }

  get configId() {
    return this._pb.configId;
  }

  get accountId() {
    return this._pb.accountId;
  }

  get externalUserId() {
    return this._pb.userId;
  }

  get kind() {
    return externalAccountKindFromProtobuf(this._pb.kind);
  }

  get authCode() {
    return this._pb.authCode;
  }

  get email() {
    return this._pb.email;
  }

  get name() {
    return this._pb.name;
  }

  get associationsCount() {
    return this._pb.associationsCount;
  }

  get failedAssociationsCount() {
    return this._pb.failedAssociationsCount;
  }

  get snoozeErrorsUntil() {
    return this._pb.snoozeErrorsUntil?.toDate();
  }

  get autoMatchHistory(): AutoMatchEntry[] {
    return this._pb.autoMatchHistory.map((entry) => new GrpcAutoMatchEntry(entry));
  }

  get pauseSyncWhenSnoozed(): boolean {
    return this._pb.pauseSyncWhenSnoozed;
  }

  get skippedSyncCycleCount(): number {
    return this._pb.skippedSyncCycleCount;
  }

  get syncPauseTime(): Time | undefined {
    return Time.fromPB(this._pb.syncPauseTime);
  }

  get syncResumeTime(): Time | undefined {
    return Time.fromPB(this._pb.syncResumeTime);
  }

  get millisecondsBetweenSyncs(): number {
    return this._pb.millisecondsBetweenSyncs;
  }

  get isScheduledAutoMatchEnabled(): boolean {
    return this._pb.isScheduledAutoMatchEnabled;
  }
}
