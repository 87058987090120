import { ImportDataDetailsResultsViewModel, ImportDataDetailsViewModel } from '@insights/viewmodels';
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  SxProps,
  Typography,
  useTheme
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { ObservablePresenter } from '../ObservablePresenter';
import { ImportDataDetailsResults } from './ImportDataDetailsResults';

export interface ImportDataDetailsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: ImportDataDetailsViewModel;
}

const globalOptionsHeight = 40;

export const ImportDataDetails = observer((props: ImportDataDetailsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;
  const theme = useTheme();

  const renderData = (data: ImportDataDetailsResultsViewModel) => {
    return (
      <Card sx={{ maxHeight: `calc(100% - ${globalOptionsHeight}px - ${theme.spacing(1)})`, overflow: 'auto' }}>
        <ImportDataDetailsResults viewModel={data} sx={{ height: 'fit-content', width: '100%' }} />
      </Card>
    );
  };

  return (
    <Box sx={sx} className={className} style={style} padding={2} height="100%" width="100%">
      <Box display="flex" flexDirection="row" ml={3} sx={{ maxWidth: 800 }} alignItems="center">
        <Box flex={1}>
          <FormControlLabel
            label={viewModel.isDestructive ? strings.destructiveOnLabel : strings.destructiveOffLabel}
            control={
              <Switch
                checked={viewModel.isDestructive}
                onChange={(e) => (viewModel.isDestructive = e.target.checked)}
              />
            }
          />
        </Box>
        {viewModel.availableOptions.length > 0 && (
          <Box flex={1}>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="options-select">
                {strings.schemaImportOptions}
              </InputLabel>
              <Select
                multiple
                value={viewModel.selectedOptionNames}
                inputProps={{ id: 'options-select', placeholder: strings.noImportOption }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  }
                }}
                onChange={(e) => (viewModel.selectedOptionNames = e.target.value as unknown as string[])}
                renderValue={() => <Typography variant="body2">{viewModel.selectedOptionNames.join(', ')}</Typography>}
              >
                {viewModel.availableOptions.map((o) => (
                  <MenuItem key={`option-${o.name}`} value={o.name}>
                    <Checkbox size="small" checked={viewModel.selectedOptionNames.includes(o.name)} />
                    <Typography variant="body2">
                      {o.name} : {o.localizedDescription}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Box>

      <ObservablePresenter
        data={viewModel.importData}
        loadingMessage={strings.loadingImportDataMessage}
        errorMessage={strings.importDataErrorMessage}
        render={renderData}
      />
    </Box>
  );
});
