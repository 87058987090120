import * as CC from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/canvas_pb';
import { Memoize } from 'fast-typescript-memoize';
import { GrpcCanvasAssignmentGroupMapping } from './GrpcCanvasAssignmentGroupMapping';
import { CanvasExternalAccountDetails } from './interfaces/CanvasExternalAccountDetails';

export class GrpcCanvasExternalAccountDetails implements CanvasExternalAccountDetails {
  constructor(private readonly _pb: CC.GetExternalAccountDetailsResponse) {}

  get serverUrl() {
    return this._pb.serverUrl;
  }

  get accessToken() {
    return this._pb.token;
  }

  get subAccountId() {
    return this._pb.subAccountId;
  }

  get isShowingSections() {
    return this._pb.isShowingSections;
  }

  @Memoize()
  get mappings() {
    return this._pb.assignmentGroupMappings.map((m) => new GrpcCanvasAssignmentGroupMapping(m));
  }

  get isIgnoringUnmappedGroups() {
    return this._pb.isIgnoringUnmappedGroups;
  }

  get requiredCourseCodePrefix() {
    return this._pb.requiredCourseCodePrefix;
  }

  get termIds() {
    return this._pb.termIds;
  }
}
