import {
  AnswerChoice as PBAnswerChoice,
  LocalizedText as PBLocalizedText,
  TextFormat as PBTextFormat
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableStringProperty, FullyEditableListProperty } from '../../editables';
import { OnboardingText, QuestionChoice } from '../interfaces';
import { EditableOnboardingText } from './EditableOnboardingText';
import { GrpcQuestionChoice } from './GrpcQuestionChoice';

export class EditableQuestionChoice extends EditableModelEx<PBAnswerChoice> implements QuestionChoice {
  private _label: FullyEditableListProperty<PBLocalizedText, OnboardingText, EditableOnboardingText, PBAnswerChoice>;
  private _value: EditableStringProperty<PBAnswerChoice>;

  static createNew(rank: number) {
    const en = new PBLocalizedText();
    en.format = PBTextFormat.PAINT_TEXT;
    en.languageCode = 'en';
    en.value = `Choice number ${rank}`;

    const fr = new PBLocalizedText();
    fr.format = PBTextFormat.PAINT_TEXT;
    fr.languageCode = 'fr';
    fr.value = `Choix numéro ${rank}`;

    const pb = new PBAnswerChoice();
    pb.localizedLabels = [en, fr];
    pb.value = `value${rank}`;

    return new EditableQuestionChoice(new GrpcQuestionChoice(pb), true);
  }

  constructor(originalChoice: QuestionChoice, isNew = false) {
    super(originalChoice.toProtobuf(), isNew);

    makeObservable(this);

    this._label = this.addEditableListField(
      originalChoice.label.map((l) => new EditableOnboardingText(l)),
      (pb, values) => (pb.localizedLabels = values)
    );

    this._value = this.addStringField(originalChoice.value, (pb, value) => (pb.value = value));
  }

  @computed
  get label(): OnboardingText[] {
    return this._label.values;
  }

  get editableLabel(): EditableOnboardingText[] {
    return this._label.values;
  }

  @computed
  get value(): string {
    return this._value.value;
  }

  set value(value: string) {
    this._value.value = value;
  }
}
