import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Checkbox,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import { ImportSession } from '@shared/models/import';
import { AllFileEncodings, ExposedFileKinds, FileEncoding, FileKind } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ImportSessionAddOrEditExpectedFileDialogProps extends DialogResult<ImportSession> {
  sx?: SxProps;
  configId: string;
  session: ImportSession;
  fileLabel?: string;
}

export const ImportSessionAddOrEditExpectedFileDialog = observer(
  (props: ImportSessionAddOrEditExpectedFileDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { session, configId, fileLabel, sx = [], onSuccess, onCancel } = props;
    const strings = localizationService.localizedStrings.insights.views.imports;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createImportSessionAddOrEditExpectedFile(configId, session, fileLabel ?? '', onSuccess!, onCancel!)
    );

    return (
      <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
        <Dialog sx={sx} open={true} maxWidth="sm" fullWidth onClose={() => viewModel.cancel()}>
          <DialogTitle>{strings.addExpectedFileTitle}</DialogTitle>

          <DialogContent dividers>
            <TextField
              sx={{ mb: 1 }}
              label={strings.labelLabel}
              slotProps={{ input: { inputProps: { maxLength: 1 } } }}
              autoFocus
              value={viewModel.label}
              helperText={viewModel.labelError}
              error={viewModel.labelError.length > 0}
              onChange={(e) => (viewModel.label = e.target.value)}
            />
            <TextField
              sx={{ mb: 1 }}
              fullWidth
              label={strings.fileNameLabel}
              slotProps={{ input: { inputProps: { maxLength: 50 } } }}
              value={viewModel.name}
              onChange={(e) => (viewModel.name = e.target.value)}
            />
            <TextField
              sx={{ mb: 1 }}
              fullWidth
              multiline
              label={strings.fileDescriptionLabel}
              slotProps={{ input: { inputProps: { maxLength: 250 } } }}
              value={viewModel.description}
              onChange={(e) => (viewModel.description = e.target.value)}
            />
            <FormControl sx={{ mb: 1 }} fullWidth>
              <InputLabel htmlFor="file-kind-select">{strings.fileKindLabel}</InputLabel>
              <Select
                value={viewModel.kind}
                inputProps={{ id: 'file-kind-select' }}
                onChange={(e) => (viewModel.kind = e.target.value as FileKind)}
              >
                {ExposedFileKinds.map((kind) => (
                  <MenuItem key={`file-kind-${kind}`} value={kind}>
                    <Typography>{strings.fileKind(kind)}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {viewModel.showEncoding && (
              <FormControl sx={{ mb: 1 }} fullWidth>
                <InputLabel htmlFor="file-encoding-select">{strings.fileEncodingLabel}</InputLabel>
                <Select
                  value={viewModel.encoding}
                  inputProps={{ id: 'file-encoding-select' }}
                  onChange={(e) => (viewModel.encoding = e.target.value as FileEncoding)}
                >
                  {AllFileEncodings.map((encoding) => (
                    <MenuItem key={`file-encoding-${encoding}`} value={encoding}>
                      <Typography>{strings.fileEncoding(encoding)}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {viewModel.showHasHeader && (
              <FormControlLabel
                sx={{ mb: 1 }}
                label={strings.fileHasHeaderLabel}
                control={
                  <Checkbox checked={viewModel.hasHeader} onChange={(e) => (viewModel.hasHeader = e.target.checked)} />
                }
              />
            )}
            {viewModel.showTopRowsSkipped && (
              <TextField
                sx={{ mb: 1 }}
                fullWidth
                label={strings.topRowsSkippedLabel}
                type="number"
                value={viewModel.topRowsSkipped}
                onChange={(e) => (viewModel.topRowsSkipped = Number(e.target.value))}
              />
            )}
            {viewModel.showSheetName && (
              <TextField
                sx={{ mb: 1 }}
                fullWidth
                label={strings.sheetNameLabel}
                slotProps={{ input: { inputProps: { maxLength: 50 } } }}
                value={viewModel.sheetName}
                onChange={(e) => (viewModel.sheetName = e.target.value)}
              />
            )}
          </DialogContent>

          <DialogActions>
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => viewModel.cancel()}>
              {strings.cancel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isExecuting={viewModel.isExecuting}
              isDisabled={!viewModel.canAdd}
              onClick={() => void viewModel.saveFile()}
            >
              {viewModel.isNew ? strings.addButton : strings.saveButton}
            </InsightsButton>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
);
