import { CommandKind, ConnectionStatus } from '@insights/viewmodels';

export interface ConnectorViewsStrings {
  anyTeacher: string;
  anyTerm: string;
  applyButton: string;
  autoMatchCommands: string;
  autoMatchCount: string;
  autoMatchEntriesTitle: string;
  autoMatchExternalSectionPatternHelp: string;
  autoMatchExternalSectionPatternLabel: string;
  autoMatchHistoryButton: string;
  autoMatchInstructions: string;
  autoMatchSectionPatternHelp: string;
  autoMatchSectionPatternLabel: string;
  autoMatchSkipAlreadyMatched: string;
  autoMatchTitle: string;
  blackbaudAccount: string;
  blackbaudMappings: string;
  blackbaudSettings: string;
  blackbaudSkyMappings: string;
  blackbaudSkySettings: string;
  calendarAccount: string;
  cancelButton: string;
  canvasAccount: string;
  canvasSettings: string;
  canvasSetup: string;
  changeIntegrationsTitle: string;
  clearButton: string;
  closeFilters: string;
  errorNotificationSettings: string;
  externalAssociationListFiltersDialogTitle: string;
  externalKeywordMappingTitle: string;
  googleAccount: string;
  googleSettings: string;
  loadingError: string;
  loadingBlackbaudMessage: string;
  loadingBlackbaudSkyMessage: string;
  loadingCalendarAccountMessage: string;
  loadingCanvasMessage: string;
  loadingExternalAccountErrorMessage: string;
  loadingExternalAccountsMessage: string;
  loadingExternalAssociationsErrorMessage: string;
  loadingExternalAssociationsMessage: string;
  loadingExternalContentMappingsError: string;
  loadingExternalContentMappingsMessage: string;
  loadingGoogleMessage: string;
  loadingIntegrationsMessage: string;
  loadingIntegrationsError: string;
  loadingManageBacAccountMessage: string;
  loadingManageBacSettingsMessage: string;
  loadingMicrosoftTeamsMessage: string;
  loadingMoodleAccountMessage: string;
  loadingSchoologyAccountMessage: string;
  loadingVeracrossAccountMessage: string;
  manageBacAccount: string;
  manageBacMappings: string;
  manageBacSettings: string;
  microsoftTeamsAccount: string;
  microsoftTeamsMappings: string;
  microsoftTeamsSettings: string;
  millisecondsBetweenSyncsLabel: string;
  moodleAccount: string;
  okButton: string;
  pauseSyncWhileSnoozed: string;
  resetFilters: string;
  runButton: string;
  saveButton: string;
  scheduledAutoMatchEnabledLabel: string;
  scheduledAutoMatchSettings: string;
  schoologyAccount: string;
  selectConnectionStatusLabel: string;
  selectTeacherLabel: string;
  selectTermLabel: string;
  skippedSyncCycles: string;
  skippedSyncCyclesHelperText: (count: number) => string;
  snoozeErrorsUntilFieldLabel: string;
  syncPauseTime: string;
  syncResumeTime: string;
  throttleSettings: string;
  veracrossAccount: string;

  getAutoMatchCommandDescription(command: CommandKind): string;
  getConnectionStatusLabel(status: ConnectionStatus): string;
}

export const EnglishConnectorViewsStrings: ConnectorViewsStrings = {
  anyTeacher: 'Any teacher',
  anyTerm: 'Any term',
  applyButton: 'Apply',
  autoMatchCommands: `Here are the available commands: `,
  autoMatchCount: 'Number of matches: ',
  autoMatchEntriesTitle: 'Active auto-match entries',
  autoMatchExternalSectionPatternHelp: 'Variables: ',
  autoMatchExternalSectionPatternLabel: 'External section pattern',
  autoMatchHistoryButton: 'Previously used patterns',
  autoMatchInstructions:
    'Using variables, construct a text representation of each Studyo section that can match a text representation of external sections.',
  autoMatchSectionPatternHelp: 'Variables: ',
  autoMatchSectionPatternLabel: 'Studyo section pattern',
  autoMatchSkipAlreadyMatched: 'Ignore sections already matched',
  autoMatchTitle: 'Auto-match Studyo sections and external sections',
  blackbaudAccount: 'Blackbaud Account',
  blackbaudMappings: 'Blackbaud Assignment Kinds',
  blackbaudSettings: 'Blackbaud Settings',
  blackbaudSkyMappings: 'Blackbaud Sky Assignment Types',
  blackbaudSkySettings: 'Blackbaud Sky Settings',
  calendarAccount: 'iCal Calendar Account',
  cancelButton: 'Cancel',
  canvasAccount: 'Canvas Account',
  canvasSettings: 'Canvas Assignment Groups',
  canvasSetup: 'Canvas Account Options',
  changeIntegrationsTitle: 'Enabled Integrations',
  clearButton: 'Clear',
  closeFilters: 'Close',
  errorNotificationSettings: 'Error Notification Settings',
  externalAssociationListFiltersDialogTitle: 'Filter associations',
  externalKeywordMappingTitle: 'Assignment mapping',
  googleAccount: 'Google Account',
  googleSettings: 'Google Classroom Classwork Settings',
  loadingError: 'An error occurred while loading this account',
  loadingBlackbaudMessage: 'Loading Blackbaud account. Please wait…',
  loadingBlackbaudSkyMessage: 'Loading Blackbaud Sky account. Please wait…',
  loadingCalendarAccountMessage: 'Loading iCal Calendar account. Please wait…',
  loadingCanvasMessage: 'Loading Canvas account. Please wait…',
  loadingExternalAccountErrorMessage: 'An error occurred while loading external accounts',
  loadingExternalAccountsMessage: 'Loading external accounts. Please wait…',
  loadingExternalAssociationsErrorMessage: 'An error occurred while loading external associations',
  loadingExternalAssociationsMessage: 'Loading external associations. Please wait…',
  loadingExternalContentMappingsMessage: 'Loading global keyword mappings. Please wait…',
  loadingExternalContentMappingsError: 'An error occurred while loading global keyword mappings',
  loadingGoogleMessage: 'Loading Google account. Please wait…',
  loadingIntegrationsError: 'An error occurred while loading school settings',
  loadingIntegrationsMessage: 'Loading school settings. Please wait…',
  loadingManageBacAccountMessage: 'Loading ManageBac account. Please wait…',
  loadingManageBacSettingsMessage: 'Loading ManageBac settings. Please wait…',
  loadingMicrosoftTeamsMessage: 'Loading Microsoft Teams settings. Please wait…',
  loadingMoodleAccountMessage: 'Loading Moodle account. Please wait…',
  loadingSchoologyAccountMessage: 'Loading Schoology account. Please wait…',
  loadingVeracrossAccountMessage: 'Loading Veracross account. Please wait…',
  manageBacAccount: 'ManageBac Account',
  manageBacMappings: 'ManageBac Assignment Mappings',
  manageBacSettings: 'ManageBac Settings',
  microsoftTeamsAccount: 'Microsoft Teams Account',
  microsoftTeamsMappings: 'Microsoft Teams Assignment Categories',
  microsoftTeamsSettings: 'Microsoft Teams Settings',
  millisecondsBetweenSyncsLabel: 'Delay between syncs (milliseconds)',
  moodleAccount: 'Moodle Account',
  okButton: 'Ok',
  pauseSyncWhileSnoozed: 'Pause synchronization while snoozed',
  resetFilters: 'Reset',
  runButton: 'Run Now!',
  saveButton: 'Save',
  scheduledAutoMatchEnabledLabel: 'Schedule automatic matching using patterns below',
  scheduledAutoMatchSettings: 'Scheduled auto-match',
  schoologyAccount: 'Schoology Account',
  selectConnectionStatusLabel: 'Connection status',
  selectTeacherLabel: 'Teacher',
  selectTermLabel: 'Term',
  skippedSyncCycles: 'Skipped sync cycles',
  skippedSyncCyclesHelperText(count) {
    return count === 0
      ? 'No sync cycles will be skipped'
      : `For every ${count + 1} sync cycles, ${count} will be skipped`;
  },
  snoozeErrorsUntilFieldLabel: 'Snooze errors until',
  syncPauseTime: 'Pause syncs at',
  syncResumeTime: 'Resume syncs at',
  throttleSettings: 'Throttling options',
  veracrossAccount: 'Veracross Account',

  getAutoMatchCommandDescription: (command) => {
    switch (command) {
      case '<':
        return 'Trim start up to param';
      case '>':
        return 'Trim end from param';
      case '(':
        return 'Keep start up to param';
      case ')':
        return 'Keep end from param';
      case '#':
        return 'Pick a number of chars at an optional index';
      case '+':
        return 'Uppercase';
      case '-':
        return 'Lowercase';
      default:
        return '';
    }
  },
  getConnectionStatusLabel: (status: ConnectionStatus) => {
    switch (status) {
      case 'any':
        return 'Any';
      case 'connected':
        return 'Connected';
      case 'connected-error':
        return 'Connected with errors';
      case 'connected-success':
        return 'Connected and valid';
      case 'not-connected':
        return 'Not connected';
      case 'connected-orphan':
        return 'Connected to deleted section';
      case 'connected-unowned':
        return 'Connected without a teacher';
      default:
        return 'Unknown';
    }
  }
};

export const FrenchConnectorViewsStrings: ConnectorViewsStrings = {
  anyTeacher: 'Tous les enseignants',
  anyTerm: 'Tous les semestres',
  applyButton: 'Appliquer',
  autoMatchCommands: `Voici les commandes disponibles : `,
  autoMatchCount: 'Correspondances: ',
  autoMatchEntriesTitle: 'Formules d’auto-correspondance actives',
  autoMatchExternalSectionPatternHelp: 'Variables: ',
  autoMatchExternalSectionPatternLabel: 'Motif pour matières externes',
  autoMatchHistoryButton: 'Motifs utilisés précédemment',
  autoMatchInstructions:
    'En utilisant les variables, construisez une représentation textuelle de chaque matière-groupe Studyo qui peut correspondre à la représentation textuelle d’une matière externe.',
  autoMatchSectionPatternHelp: 'Variables: ',
  autoMatchSectionPatternLabel: 'Motif pour matières-groupes Studyo',
  autoMatchSkipAlreadyMatched: 'Ignorer les sections déjà associées',
  autoMatchTitle: 'Correspondance automatique des matières',
  blackbaudAccount: 'Compte Blackbaud',
  blackbaudMappings: 'Groupes d’assignation Blackbaud',
  blackbaudSettings: 'Réglages Blackbaud',
  blackbaudSkyMappings: 'Types d’assignation Blackbaud Sky',
  blackbaudSkySettings: 'Réglages Blackbaud Sky',
  calendarAccount: 'Compte calendrier iCal',
  cancelButton: 'Annuler',
  canvasAccount: 'Compte Canvas',
  canvasSettings: 'Groupes d’assignation Canvas',
  canvasSetup: 'Options de compte Canvas',
  changeIntegrationsTitle: 'Intégrations actives',
  clearButton: 'Effacer',
  closeFilters: 'Fermer',
  errorNotificationSettings: 'Réglages des notifications des erreurs',
  externalAssociationListFiltersDialogTitle: 'Filtrer les associations',
  externalKeywordMappingTitle: 'Transformation des devoirs',
  googleAccount: 'Compte Google',
  googleSettings: 'Traitement des travaux Google Classroom',
  loadingError: 'Une erreur est survenue en chargeant ce compte',
  loadingBlackbaudMessage: 'Chargement du compte Blackbaud. Merci de patienter…',
  loadingBlackbaudSkyMessage: 'Chargement du compte Blackbaud Sky. Merci de patienter…',
  loadingCalendarAccountMessage: 'Chargement du compte calendrier iCal. Merci de patienter…',
  loadingCanvasMessage: 'Chargement du compte Canvas. Merci de patienter…',
  loadingExternalAccountErrorMessage: 'Une erreur est survenue en chargeant les comptes externes',
  loadingExternalAccountsMessage: 'Chargement des comptes externes. Merci de patienter…',
  loadingExternalAssociationsErrorMessage: 'Une erreur est survenue en chargeant les associations externes',
  loadingExternalAssociationsMessage: 'Chargement des associations externes. Merci de patienter…',
  loadingExternalContentMappingsMessage: 'Chargement des transformations par mot-clé. Merci de patienter…',
  loadingExternalContentMappingsError: 'Une erreur est survenue pendant le chargement des transformations par mot-clé',
  loadingGoogleMessage: 'Chargement du compte Google. Merci de patienter…',
  loadingIntegrationsError: 'Une erreur est survenue pendant le chargement des options',
  loadingIntegrationsMessage: 'Chargement des options de l’école. Merci de patienter…',
  loadingManageBacAccountMessage: 'Chargement du compte ManageBac. Merci de patienter…',
  loadingManageBacSettingsMessage: 'Chargement des options ManageBac. Merci de patienter…',
  loadingMicrosoftTeamsMessage: 'Chargement du compte Microsoft Teams. Merci de patienter…',
  loadingMoodleAccountMessage: 'Chargement du compte Moodle. Merci de patienter…',
  loadingSchoologyAccountMessage: 'Chargement du compte Schoology. Merci de patienter…',
  loadingVeracrossAccountMessage: 'Chargement du compte Veracross. Merci de patienter…',
  manageBacAccount: 'Compte ManageBac',
  manageBacMappings: 'Groupes d’assignation ManageBac',
  manageBacSettings: 'Options ManageBac',
  microsoftTeamsAccount: 'Compte Microsoft Teams',
  microsoftTeamsMappings: 'Catégories d’assignation Microsoft Teams',
  microsoftTeamsSettings: 'Options Microsoft Teams',
  millisecondsBetweenSyncsLabel: 'Délai entre synchronisations (milli-secondes)',
  moodleAccount: 'Compte Moodle',
  okButton: 'Ok',
  pauseSyncWhileSnoozed: 'Interrompre la synchronisation pendant la veille',
  resetFilters: 'Réinitialiser',
  runButton: 'Exécuter!',
  saveButton: 'Enregistrer',
  scheduledAutoMatchEnabledLabel: 'Céduler la correspondance automatique avec les formules ici-bas',
  scheduledAutoMatchSettings: 'Correspondance automatique cédulée',
  schoologyAccount: 'Compte Schoology',
  selectConnectionStatusLabel: 'État de la connexion',
  selectTeacherLabel: 'Enseignant',
  selectTermLabel: 'Semestre',
  skippedSyncCycles: 'Cycles de synchronisation sautés',
  skippedSyncCyclesHelperText(count) {
    return count === 0
      ? 'Aucun cycle de synchronisation ne sera sauté'
      : `Pour chaque ${count + 1} cycles de synchronisation, ${count} ${count === 1 ? 'sera' : 'seront'} sauté${
          count === 1 ? '' : 's'
        }`;
  },
  snoozeErrorsUntilFieldLabel: 'Mettre les erreurs en veille jusqu’au',
  syncPauseTime: 'Arrêter la synchronisation à',
  syncResumeTime: 'Reprendre la synchronisation à',
  throttleSettings: 'Options de réduction d’appels',
  veracrossAccount: 'Compte Veracross',

  getAutoMatchCommandDescription: (command: CommandKind) => {
    switch (command) {
      case '<':
        return 'Retirer du début jusqu’au paramètre';
      case '>':
        return 'Retirer du paramètre jusqu’à la fin';
      case '(':
        return 'Garder du début jusqu’au paramètre';
      case ')':
        return 'Garder du paramètre jusqu’à la fin';
      case '#':
        return 'Sélectionner un nombre de caractères à un index optionnel';
      case '+':
        return 'Majuscule';
      case '-':
        return 'Minuscule';
      default:
        return '';
    }
  },
  getConnectionStatusLabel: (status: ConnectionStatus) => {
    switch (status) {
      case 'any':
        return 'Tous';
      case 'connected':
        return 'Connectés';
      case 'connected-error':
        return 'Connectés avec erreur';
      case 'connected-success':
        return 'Connectés et valides';
      case 'not-connected':
        return 'Non connectés';
      case 'connected-orphan':
        return 'Connectés à un groupe-matière effacé';
      case 'connected-unowned':
        return 'Connecté sans enseignant';
      default:
        return 'Inconnu';
    }
  }
};
