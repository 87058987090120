import * as VC from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/veracross_pb';
import { VeracrossExternalAccountDetails } from './interfaces/VeracrossExternalAccountDetails';

export class GrpcVeracrossExternalAccountDetails implements VeracrossExternalAccountDetails {
  constructor(private readonly pb: VC.GetExternalAccountDetailsResponse) {}

  get serverUrl() {
    return this.pb.serverUrl;
  }

  get username() {
    return this.pb.username;
  }

  get password() {
    return this.pb.password;
  }
}
