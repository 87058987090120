import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { computed, makeObservable } from 'mobx';
import { BaseModel } from '../../Model';
import { Account } from '../../config';
import { DashboardProcessComments } from '../interfaces';
import { GrpcDashboardStepComments } from './GrpcDashboardStepComments';
import { GrpcOnboardingComment } from './GrpcOnboardingComment';
import { GrpcOnboardingProcess } from './GrpcOnboardingProcess';

export class GrpcDashboardProcessComments
  extends BaseModel<PB.DashboardProcessComments>
  implements DashboardProcessComments
{
  constructor(pb: PB.DashboardProcessComments) {
    super(pb);
    makeObservable(this);
  }

  @computed
  get process() {
    const pbProcess = this._pb.process;
    return pbProcess && new GrpcOnboardingProcess(pbProcess);
  }

  @computed
  get steps() {
    return this._pb.steps.map((s) => new GrpcDashboardStepComments(s));
  }

  get schoolName() {
    return this._pb.schoolName;
  }

  @computed
  get comments() {
    return this._pb.comments.map((c) => new GrpcOnboardingComment(c));
  }

  @computed
  get accounts() {
    return this._pb.accounts.map((a) => new Account(a));
  }
}
