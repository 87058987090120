import { PremiumFeature, SubscriptionEntitlement } from '@shared/models/types';

export interface FeaturesViewStrings {
  addEntitlementButtonTooltip: string;
  addEntitlementDialogTitle: string;
  adminConsoleCheckboxLabel: string;
  cancelButtonCaption: string;
  editFeaturesButtonTooltip: string;
  editFeaturesDialogTitle: string;
  entitlementLabel: string;
  featureListCardTitle: string;
  loadingErrorMessage: string;
  loadingMessage: string;
  noEntitlements: string;
  organizationalQuotient: string;
  parentAccessLabel: string;
  publishedTasksWidgetCheckboxLabel: string;
  requiredEntitlementsTitle: string;
  saveButtonCaption: string;
  stripePriceIdLabel: string;
  studentEnteredTasks: string;
  workloadManagerWidget: string;
  getEntitlementLabel: (entitlement: SubscriptionEntitlement) => string;
  getFeatureLabel: (feature: PremiumFeature) => string;
}

export const EnglishFeaturesViewStrings: FeaturesViewStrings = {
  addEntitlementButtonTooltip: 'Add Entitlement',
  addEntitlementDialogTitle: 'Add Entitlement',
  adminConsoleCheckboxLabel: 'Administrative console',
  cancelButtonCaption: 'Cancel',
  editFeaturesButtonTooltip: 'Edit features',
  editFeaturesDialogTitle: 'Included Features',
  entitlementLabel: 'Entitlement',
  featureListCardTitle: 'Included Features',
  loadingErrorMessage: 'An error occurred while loading the data. Please reload.',
  loadingMessage: 'Loading data. Please wait…',
  noEntitlements: 'No Entitlements Configured',
  organizationalQuotient: 'Organizational quotient',
  parentAccessLabel: 'Parent Access',
  publishedTasksWidgetCheckboxLabel: 'Published tasks widget',
  requiredEntitlementsTitle: 'Available Entitlements',
  saveButtonCaption: 'Save',
  stripePriceIdLabel: 'Stripe Price Id',
  studentEnteredTasks: 'Student entered tasks',
  workloadManagerWidget: 'Workload Manager Widget',

  getEntitlementLabel(e) {
    switch (e) {
      case 'parent-access':
        return 'Parent Access';
      default:
        return 'Unknown';
    }
  },

  getFeatureLabel(f) {
    switch (f) {
      case 'admin-console':
        return this.adminConsoleCheckboxLabel;
      case 'all-published-tasks-widget':
        return this.publishedTasksWidgetCheckboxLabel;
      case 'organizational-quotient':
        return this.organizationalQuotient;
      case 'parent-access':
        return this.parentAccessLabel;
      case 'student-entered-tasks-widget':
        return this.studentEnteredTasks;
      case 'workload-manager-widget':
        return this.workloadManagerWidget;
      default:
        return 'Unknown';
    }
  }
};

export const FrenchFeaturesViewStrings: FeaturesViewStrings = {
  addEntitlementButtonTooltip: 'Ajouter une permission',
  addEntitlementDialogTitle: 'Ajouter une permission',
  adminConsoleCheckboxLabel: 'Console de gestion',
  cancelButtonCaption: 'Annuler',
  editFeaturesButtonTooltip: 'Éditer les fonctionnalités',
  editFeaturesDialogTitle: 'Fonctionnalités incluses',
  entitlementLabel: 'Permission',
  featureListCardTitle: 'Fonctionnalités incluses',
  loadingErrorMessage: 'Une erreur est survenue lors du chargement des données. Merci de recharger.',
  loadingMessage: 'Chargement des données. Merci de patienter…',
  noEntitlements: 'Aucune permission configurée',
  organizationalQuotient: 'Quotient organisationnel',
  parentAccessLabel: 'Accès parents',
  publishedTasksWidgetCheckboxLabel: 'Widget des tâches publiées',
  requiredEntitlementsTitle: 'Permissions disponibles',
  saveButtonCaption: 'Sauvegarder',
  stripePriceIdLabel: 'Identifiant de prix Stripe',
  studentEnteredTasks: 'Tâches entrées par les élèves',
  workloadManagerWidget: 'Widget des charges de travail',

  getEntitlementLabel(e) {
    switch (e) {
      case 'parent-access':
        return 'Accès Parent';
      default:
        return 'Inconnue';
    }
  },

  getFeatureLabel(f) {
    switch (f) {
      case 'admin-console':
        return this.adminConsoleCheckboxLabel;
      case 'all-published-tasks-widget':
        return this.publishedTasksWidgetCheckboxLabel;
      case 'organizational-quotient':
        return this.organizationalQuotient;
      case 'parent-access':
        return this.parentAccessLabel;
      case 'student-entered-tasks-widget':
        return this.studentEnteredTasks;
      case 'workload-manager-widget':
        return this.workloadManagerWidget;
      default:
        return 'Inconnu';
    }
  }
};
