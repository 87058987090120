export interface PreferencesStrings {
  readonly title: string;
  readonly close: string;
  readonly deleteAccount: string;

  readonly deleteAccountConfirmationTitle: string;
  readonly deleteAccountConfirmationMessage: string;
  readonly deleteAccountConfirmationYes: string;
  readonly deleteAccountConfirmationNo: string;

  readonly deleteAccountResponseTitle: string;
  readonly deleteAccountResponseMessage: string;

  readonly generalOptionsTitle: string;
  readonly newTasksOptionsTitle: string;
  readonly accountOptionsTitle: string;

  readonly languaguePreferenceTitle: string;
  readonly languageEnglishOption: string;
  readonly languageFrenchOption: string;

  readonly announcementDateTodayTitle: string;
  readonly newTaskDueNextPeriodTitle: string;

  readonly themeDarkOption: string;
  readonly themeLightOption: string;
  readonly themeSystemOption: string;
  readonly themeTitle: string;

  readonly unexpectedErrorTitle: string;
  readonly unexpectedErrorMessage: string;
}

export const EnglishPreferencesStrings: PreferencesStrings = {
  title: 'Preferences',
  close: 'Close',
  deleteAccount: 'Delete Account',

  deleteAccountConfirmationTitle: 'Delete Account',
  deleteAccountConfirmationMessage:
    'Are you sure you want to delete your Studyo account, including all the data you own?\n\nTHIS ACTION IS PERMANENT AND CANNOT BE UNDONE.',
  deleteAccountConfirmationYes: 'Yes, delete my account',
  deleteAccountConfirmationNo: 'Cancel',

  deleteAccountResponseTitle: 'Request submitted',
  deleteAccountResponseMessage:
    'A request to delete your account has been sent. You will receive an email once it has been deleted.',

  generalOptionsTitle: 'General',
  newTasksOptionsTitle: 'New Tasks',
  accountOptionsTitle: 'Your Account',

  languaguePreferenceTitle: 'Language',
  languageEnglishOption: 'English',
  languageFrenchOption: 'Français',

  announcementDateTodayTitle: 'Announced today',
  newTaskDueNextPeriodTitle: 'Due next class',

  themeDarkOption: 'Dark',
  themeLightOption: 'Light',
  themeSystemOption: 'System',
  themeTitle: 'Theme color',

  unexpectedErrorTitle: 'Error',
  unexpectedErrorMessage: 'An error occurred while sending the request. Please try again later or contact support.'
};

export const FrenchPreferencesStrings: PreferencesStrings = {
  title: 'Préférences',
  close: 'Fermer',
  deleteAccount: 'Effacer mon compte',

  deleteAccountConfirmationTitle: 'Effacer mon compte',
  deleteAccountConfirmationMessage:
    'Êtes-vous certain de vouloir supprimer votre compte Studyo ainsi que toutes les données que vous possédez?\n\nCETTE ACTION EST PERMANENTE ET NE PEUT PAS ÊTRE RENVERSÉE.',
  deleteAccountConfirmationYes: 'Oui, effacez mon compte',
  deleteAccountConfirmationNo: 'Annuler',

  deleteAccountResponseTitle: 'Requête envoyée',
  deleteAccountResponseMessage:
    'La demande de suppression de compte a été transmise. Vous recevrez un courriel lorsque votre compte aura été supprimé.',

  generalOptionsTitle: 'Général',
  newTasksOptionsTitle: 'Nouvelles tâches',
  accountOptionsTitle: 'Votre compte',

  languaguePreferenceTitle: 'Langue',
  languageEnglishOption: 'English',
  languageFrenchOption: 'Français',

  announcementDateTodayTitle: 'Annoncées aujourd’hui',
  newTaskDueNextPeriodTitle: 'Dues au prochain cours',

  themeDarkOption: 'Sombre',
  themeLightOption: 'Clair',
  themeSystemOption: 'Système',
  themeTitle: 'Thème de couleur',

  unexpectedErrorTitle: 'Erreur',
  unexpectedErrorMessage:
    'Une erreur est survenue lors de l’envoi de la demande. Veuillez réessayer plus tard ou contacter le support.'
};
