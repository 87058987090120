import { ExternalContentMapping } from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import {
  AutoMatchEntry,
  ExternalAccount,
  ExternalAssociation,
  ExternalSection,
  GrpcExternalAccount,
  GrpcExternalAssociation,
  GrpcExternalSection,
  QueueStatus,
  SyncResults,
  TestSyncResults
} from '@shared/models/connectors';
import { Day, ExternalAccountKind, Time } from '@shared/models/types';
import { protobufFromDate, protobufFromExternalAccountKind } from '@shared/models/types/EnumConversion';
import { Dictionary, keyBy } from 'lodash';
import { IComputedValue, computed } from 'mobx';
import * as T from '../../transports';
import {
  BlackbaudConnectorStore,
  BlackbaudSkyConnectorStore,
  CalendarsConnectorStore,
  CanvasConnectorStore,
  ClassroomConnectorStore,
  ConnectorsStore,
  ManageBacConnectorStore,
  MicrosoftTeamsConnectorStore,
  MoodleConnectorStore,
  SchoologyConnectorStore,
  StudyoInternalConnectorStore,
  VeracrossConnectorStore,
  VeracrossV3ConnectorStore
} from '../interfaces';
import { AppBaseStore } from './AppBaseStore';
import {
  AppBlackbaudConnectorStore,
  AppCalendarsConnectorStore,
  AppCanvasConnectorStore,
  AppClassroomConnectorStore,
  AppManageBacConnectorStore,
  AppMoodleConnectorStore,
  AppSchoologyConnectorStore,
  AppVeracrossConnectorStore,
  AppVeracrossV3ConnectorStore
} from './connectors';
import { AppBlackbaudSkyConnectorStore } from './connectors/AppBlackbaudSkyConnectorStore';
import { AppMicrosoftTeamsConnectorStore } from './connectors/AppMicrosoftTeamsConnectorStore';
import { AppStudyoInternalConnectorStore } from './connectors/AppStudyoInternalConnectorStore';

export class AppConnectorsStore extends AppBaseStore implements ConnectorsStore {
  blackbaud: BlackbaudConnectorStore;
  blackbaudSky: BlackbaudSkyConnectorStore;
  calendars: CalendarsConnectorStore;
  classroom: ClassroomConnectorStore;
  canvas: CanvasConnectorStore;
  manageBac: ManageBacConnectorStore;
  moodle: MoodleConnectorStore;
  schoology: SchoologyConnectorStore;
  studyoInternal: StudyoInternalConnectorStore;
  teams: MicrosoftTeamsConnectorStore;
  veracross: VeracrossConnectorStore;
  veracrossV3: VeracrossV3ConnectorStore;

  constructor(
    private readonly _transport: T.ConnectorManagerTransport,
    blackbaudTransport: T.BlackbaudTransport,
    blackbaudSkyTransport: T.BlackbaudSkyTransport,
    calendarsTransport: T.CalendarsTransport,
    canvasTransport: T.CanvasTransport,
    googleClassroomTransport: T.GoogleClassroomTransport,
    manageBacTransport: T.ManageBacTransport,
    microsoftTeamsTransport: T.MicrosoftTeamsTransport,
    moodleTransport: T.MoodleTransport,
    schoologyTransport: T.SchoologyTransport,
    studyoInternalConnectorTransport: T.StudyoInternalConnectorTransport,
    veracrossTransport: T.VeracrossTransport,
    veracrossV3Transport: T.VeracrossV3Transport
  ) {
    super('AppConnectorsStore');

    this.blackbaud = new AppBlackbaudConnectorStore(blackbaudTransport, this);
    this.blackbaudSky = new AppBlackbaudSkyConnectorStore(blackbaudSkyTransport, this);
    this.calendars = new AppCalendarsConnectorStore(calendarsTransport, this);
    this.canvas = new AppCanvasConnectorStore(canvasTransport, this);
    this.classroom = new AppClassroomConnectorStore(googleClassroomTransport, this);
    this.manageBac = new AppManageBacConnectorStore(manageBacTransport, this);
    this.moodle = new AppMoodleConnectorStore(moodleTransport, this);
    this.schoology = new AppSchoologyConnectorStore(schoologyTransport, this);
    this.studyoInternal = new AppStudyoInternalConnectorStore(studyoInternalConnectorTransport, this);
    this.teams = new AppMicrosoftTeamsConnectorStore(microsoftTeamsTransport, this);
    this.veracross = new AppVeracrossConnectorStore(veracrossTransport, this);
    this.veracrossV3 = new AppVeracrossV3ConnectorStore(veracrossV3Transport, this);
  }

  async getExternalAccounts(configId: string): Promise<ExternalAccount[]> {
    return this.getMemoizedExternalAccounts(configId).get();
  }

  async getExternalAccount(configId: string, externalAccountId: string): Promise<ExternalAccount> {
    const dictionary = await this.getMemoizedExternalAccountsById(configId).get();

    return dictionary[externalAccountId];
  }

  async updateSnoozeAccountErrors(
    configId: string,
    externalAccountId: string,
    snoozeErrorsUntil: Date | undefined,
    pauseSyncWhenSnoozed: boolean
  ): Promise<void> {
    await this._transport.updateSnoozeAccountErrors(
      configId,
      externalAccountId,
      snoozeErrorsUntil ? protobufFromDate(snoozeErrorsUntil) : snoozeErrorsUntil,
      pauseSyncWhenSnoozed
    );
    this.invalidate();
  }

  async throttleAccountSync(
    configId: string,
    externalAccountId: string,
    skippedSyncCycleCount: number,
    syncPauseTime: Time | undefined,
    syncResumeTime: Time | undefined,
    millisecondsBetweenSyncs: number
  ): Promise<void> {
    await this._transport.throttleAccountSync(
      configId,
      externalAccountId,
      skippedSyncCycleCount,
      syncPauseTime?.asPB,
      syncResumeTime?.asPB,
      millisecondsBetweenSyncs
    );
  }

  async deleteExternalAccount(externalAccountId: string): Promise<void> {
    await this._transport.deleteExternalAccount(externalAccountId);
    this.invalidate();
  }

  async getExternalSections(externalAccountId: string): Promise<ExternalSection[]> {
    return this.getMemoizedExternalSections(externalAccountId).get();
  }

  async getExternalSection(externalAccountId: string, externalSectionId: string): Promise<ExternalSection> {
    const dictionary = await this.getMemoizedExternalSectionsById(externalAccountId).get();

    return dictionary[externalSectionId];
  }

  async getExternalSectionsById(externalAccountId: string): Promise<Dictionary<ExternalSection>> {
    return this.getMemoizedExternalSectionsById(externalAccountId).get();
  }

  async getExternalAssociations(configId: string, externalAccountId: string): Promise<ExternalAssociation[]> {
    const associations = await this._transport.fetchExternalAssociations(configId, externalAccountId);

    return associations.map((a) => new GrpcExternalAssociation(a));
  }

  async getExternalAssociationBySectionId(
    configId: string,
    externalAccountId: string,
    sectionId: string
  ): Promise<ExternalAssociation> {
    const dictionary = await this.getMemoizedExternalAssociationsBySectionId(configId, externalAccountId).get();

    return dictionary[sectionId];
  }

  async getExternalAssociationsBySectionId(
    configId: string,
    externalAccountId: string
  ): Promise<Dictionary<ExternalAssociation>> {
    return this.getMemoizedExternalAssociationsBySectionId(configId, externalAccountId).get();
  }

  async createExternalAssociation(
    configId: string,
    sectionId: string,
    ownerId: string,
    kind: ExternalAccountKind,
    externalSectionId: string,
    externalAccountId: string,
    minDate: Day,
    maxDate: Day
  ): Promise<ExternalAssociation> {
    const association = await this._transport.createExternalAssociation(
      configId,
      sectionId,
      ownerId,
      protobufFromExternalAccountKind(kind),
      externalSectionId,
      externalAccountId,
      minDate.asPB,
      maxDate.asPB
    );

    return new GrpcExternalAssociation(association);
  }

  async updateExternalAssociation(
    externalAssociationId: string,
    ownerId: string,
    minDate: Day,
    maxDate: Day
  ): Promise<ExternalAssociation> {
    const association = await this._transport.updateExternalAssociation(
      externalAssociationId,
      ownerId,
      minDate.asPB,
      maxDate.asPB
    );

    return new GrpcExternalAssociation(association);
  }

  async deleteExternalAssociation(externalAssociationId: string): Promise<void> {
    await this._transport.deleteExternalAssociation(externalAssociationId);
  }

  async queueExternalAssociationSync(externalAssociationId: string, forceRefresh: boolean): Promise<QueueStatus> {
    // We fake the time.
    const time = new Date();
    const response = await this._transport.queueExternalAssociationSync(externalAssociationId, forceRefresh);

    return {
      position: response.queuePosition,
      size: response.queueSize,
      time
    };
  }

  async syncExternalAssociation(externalAssociationId: string, forceRefresh: boolean): Promise<SyncResults> {
    const response = await this._transport.syncExternalAssociation(externalAssociationId, forceRefresh);

    return {
      updated: response.updatedCount,
      removed: response.removedCount,
      total: response.totalCount
    };
  }

  async testExternalAssociation(externalAssociationId: string): Promise<TestSyncResults> {
    const response = await this._transport.testExternalAssociation(externalAssociationId);

    return {
      updated: response.updatedCount,
      removed: response.removedCount,
      total: response.totalCount,
      results: response.results
    };
  }

  async resetKnownElements(
    configId: string,
    externalAccountId: string,
    externalAssociationId?: string
  ): Promise<bigint> {
    const count = await this._transport.resetKnownElements(configId, externalAccountId, externalAssociationId);

    return count;
  }

  async addAutoMatch(
    externalAccountId: string,
    studyoPattern: string,
    externalPattern: string
  ): Promise<ExternalAccount> {
    const account = await this._transport.addAutoMatch(externalAccountId, studyoPattern, externalPattern);

    // This invalidates the full account list, as we don't have a customized list of observable accounts.
    this.invalidate();

    return new GrpcExternalAccount(account);
  }

  async setScheduledAutoMatch(
    externalAccountId: string,
    isScheduledAutoMatchEnabled: boolean,
    autoMatchHistory: AutoMatchEntry[]
  ): Promise<ExternalAccount> {
    const account = await this._transport.setScheduledAutoMatch(
      externalAccountId,
      isScheduledAutoMatchEnabled,
      autoMatchHistory.map((h) => h.toProtobuf())
    );

    // This invalidates the full account list, as we don't have a customized list of observable accounts.
    this.invalidate();

    return new GrpcExternalAccount(account);
  }

  async runAutoMatch(externalAccountId: string, configId: string): Promise<{ added: number; confirmed: number }> {
    const response = await this._transport.runAutoMatch(externalAccountId, configId);

    // This invalidates the full account list, as we don't have a customized list of observable accounts,
    // but at least we do it only if associations added.
    if (response.addedAssociationCount > 0) {
      this.invalidate();
    }

    return { added: response.addedAssociationCount, confirmed: response.confirmedAssociationCount };
  }

  async getExternalContentMappings(configId: string): Promise<ExternalContentMapping[]> {
    return await this._transport.getExternalContentMappings(configId, []);
  }

  async addExternalContentMapping(mapping: ExternalContentMapping): Promise<void> {
    await this._transport.addExternalContentMapping(mapping);
  }

  async updateExternalContentMapping(mapping: ExternalContentMapping): Promise<void> {
    await this._transport.updateExternalContentMapping(mapping);
  }

  private getMemoizedExternalAccountsById = this.memoize(
    (configId: string): IComputedValue<Promise<Dictionary<ExternalAccount>>> =>
      computed(() =>
        this.withInvalidate(() => this.getExternalAccounts(configId).then((accounts) => keyBy(accounts, (a) => a.id)))
      )
  );

  private getMemoizedExternalSectionsById = this.memoize(
    (externalAccountId: string): IComputedValue<Promise<Record<string, ExternalSection>>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getExternalSections(externalAccountId).then((sections) => keyBy(sections, (s) => s.id))
        )
      )
  );

  private getMemoizedExternalAssociationsBySectionId = this.memoize(
    (configId: string, externalAccountId: string): IComputedValue<Promise<Dictionary<ExternalAssociation>>> =>
      computed(() =>
        this.withInvalidate(() =>
          this.getExternalAssociations(configId, externalAccountId).then((associations) =>
            keyBy(associations, (a) => a.sectionId)
          )
        )
      )
  );

  private getMemoizedExternalAccounts = this.memoize(
    (configId: string): IComputedValue<Promise<ExternalAccount[]>> =>
      computed(() =>
        this.withInvalidate(() =>
          this._transport
            .fetchExternalAccounts(configId)
            .then((accounts) => accounts.map((a) => new GrpcExternalAccount(a)))
        )
      )
  );

  private getMemoizedExternalSections = this.memoize(
    (externalAccountId: string): IComputedValue<Promise<ExternalSection[]>> =>
      computed(() =>
        this.withInvalidate(() =>
          this._transport
            .fetchExternalSections(externalAccountId)
            .then((sections) => sections.map((s) => new GrpcExternalSection(s)))
        )
      )
  );
}
