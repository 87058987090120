import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_pb';
import { BaseModel } from '../Model';
import { contentIconFromProtobuf, contentWorkloadLevelFromProtobuf } from '../types/EnumConversion';
import { BlackbaudKindMappingModel } from './interfaces';

export class GrpcBlackbaudKindMapping extends BaseModel<BC.KindMapping> implements BlackbaudKindMappingModel {
  constructor(pb: BC.KindMapping) {
    super(pb);
  }

  get kindId() {
    return this._pb.kindId;
  }

  get kindName() {
    return this._pb.kindName;
  }

  get icon() {
    return contentIconFromProtobuf(this._pb.icon);
  }

  get workloadLevel() {
    return contentWorkloadLevelFromProtobuf(this._pb.workloadLevel);
  }

  // isImportant is not exposed
}
