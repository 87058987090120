import { MaterialTableData } from '@insights/models';
import { AccountService, AlertService, NavigationService } from '@insights/services';
import { cleanDiacritics, CustomFilterUtils, SectionUtils } from '@insights/utils';
import { AccountUtils } from '@shared/components/utils';
import { AccountModel, SectionModel } from '@shared/models/config';
import { AutoMatchEntry, ExternalAccount, ExternalSection } from '@shared/models/connectors';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore } from '@shared/services/stores';
import { isAfter } from 'date-fns';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppBaseProcessingViewModel, BaseProcessingViewModel } from '../../BaseProcessingViewModel';
import {
  ConnectionStatus,
  EmptyExternalAssociationListFilters,
  ExternalAssociationListFilters
} from './ExternalAssociationListFilterDialogViewModel';
import { ExternalAssociationViewModel } from './ExternalAssociationViewModel';

export type CommandKind = '<' | '>' | '(' | ')' | '#' | '+' | '-' | '@';
export const AllCommandKinds: CommandKind[] = ['<', '>', '(', ')', '#', '+', '-', '@'];

export interface Variable<T> {
  readonly name: string;
  getField: (item: T) => string;
  replace: (item: T, pattern: string) => string;
}

class AppVariable<T> implements Variable<T> {
  constructor(
    public readonly name: string,
    public readonly getField: (item: T) => string
  ) {}

  replace(item: T, pattern: string): string {
    const field = this.getField(item);
    const length = this.name.length;

    // Can't use reg. expressions here efficiently (transformation before replace)
    // We always assume a variable appears only once in a pattern
    let index = pattern.indexOf(`{${this.name}`);

    while (index >= 0) {
      const end = pattern.indexOf('}', index);

      if (end < 0) {
        break;
      }

      // We assume \\ starts the command part, but we could be dealing with "{titlefoobar\\> }". For
      // simplicity here, we ignore "foobar".
      const processedField = this.processCommands(
        field,
        pattern
          .substring(index + 1 + length, end)
          .split('\\\\')
          .slice(1)
      );

      pattern = pattern.substring(0, index) + processedField + pattern.substring(end + 1);

      index = pattern.indexOf(`{${this.name}`);
    }

    return pattern;
  }

  private processCommands(field: string, commands: string[]): string {
    return _.reduce(
      commands,
      (field, command) => {
        if (command.length == 0) {
          return field;
        }

        const commandParam = command.substring(1);
        let index = 0;

        switch (command[0]) {
          case '<': // Trim start up to param
            index = field.indexOf(commandParam);

            if (index >= 0) {
              return field.substring(index + commandParam.length);
            }

            // Trimmed... to end!
            return '';

          case '>': // Trim end from param
            index = field.lastIndexOf(commandParam);

            if (index >= 0) {
              return field.substring(0, index);
            }

            // Trimmed... to beginning!
            return '';

          case '(': // Keep start up to param
            index = field.indexOf(commandParam);

            if (index >= 0) {
              return field.substring(0, index);
            }

            break;

          case ')': // Keep end from param
            index = field.lastIndexOf(commandParam);

            if (index >= 0) {
              return field.substring(index + commandParam.length);
            }
            break;

          // Pick a number of chars at an optional index
          case '#': {
            const args = commandParam.split(',');
            const length = parseInt(args[0]);
            index = args.length > 1 ? parseInt(args[1]) : 0;

            if (Number.isNaN(length) || Number.isNaN(index) || length <= 0) {
              return '';
            }

            index = Math.max(0, index);
            return field.substring(index, length);
          }

          case '+': // Uppercase
            return field.toLocaleUpperCase();

          case '-': // Lowercase
            return field.toLocaleLowerCase();

          case '@': // key=value|key=value|... field, extract one key's value
            return (
              field
                .split('|')
                .map((pair) => pair.split('='))
                .find((pair) => pair[0] == commandParam)?.[1] ?? ''
            );
        }

        return field;
      },
      field
    );
  }
}

// MaterialTable requires a simple serializable root object with an id.
// Child properties don't need to be serializable.
export interface ExternalAssociationInfo extends MaterialTableData {
  readonly viewModel: ExternalAssociationViewModel;
}

export interface ExternalSectionInfo extends MaterialTableData {
  readonly section: ExternalSection;
}

export interface ExternalAssociationListViewModel extends BaseProcessingViewModel {
  customFilterAndSearch(filter: string, item: ExternalAssociationInfo): boolean;
  searchText: string;

  readonly associations: ExternalAssociationInfo[];
  readonly externalSections: ExternalSectionInfo[];
  readonly isDataIncomplete: boolean;
  readonly hasFailedAssociations: boolean;
  readonly canContactOwnerAboutBrokenConnection: boolean;
  defaultMinimumDate: Day;
  defaultMaximumDate: Day;

  readonly isLinkOnly: boolean;
  readonly isAutoMatching: boolean;
  readonly variables: Variable<SectionModel>[];
  readonly externalVariables: Variable<ExternalSection>[];
  autoMatchSectionPattern: string;
  autoMatchExternalSectionPattern: string;
  shouldSkipAlreadyMatched: boolean;
  readonly examples: string[];
  readonly externalExamples: string[];
  readonly matchCount: number;
  readonly isSnoozed: boolean;
  readonly hasThrottling: boolean;
  readonly autoMatchHistory: AutoMatchEntry[];
  readonly autoMatchHistoryAnchor?: HTMLElement;
  readonly isAutoMatchHistoryOpen: boolean;

  readonly hasChanges: boolean;
  readonly warning?: string;

  applyDatesToAll(minDate: Day, maxDate: Day): void;

  startAutoMatch(): void;
  applyAutoMatch(): void;
  cancelAutoMatch(): void;
  unmatchAll(): void;

  readonly hasFilters: boolean;
  showFilters(): Promise<void>;

  applyChanges(): Promise<void>;
  resetChanges(): void;

  showEditErrorNotificationSettings(): Promise<void>;
  showEditThrottleSettings(): Promise<void>;
  showScheduledAutoMatchSettings(): Promise<void>;
  forgetTasks(associationId?: string): Promise<void>;

  toggleAutoMatchHistory(anchor?: HTMLElement): void;
  selectAutoMatchEntry(entry: AutoMatchEntry): void;

  contactOwnerAboutBrokenConnection(): Promise<void>;
}

export class AppExternalAssociationListViewModel
  extends AppBaseProcessingViewModel
  implements ExternalAssociationListViewModel
{
  @observable private _searchText = '';
  @observable private _defaultMinimumDate: Day | undefined;
  @observable private _defaultMaximumDate: Day | undefined;
  @observable private _isAutoMatching = false;
  @observable private _autoMatchHistoryAnchor?: HTMLElement;
  @observable private _sectionPattern: string;
  @observable private _externalSectionPattern: string;
  @observable private _matchCount = 0;
  @observable private _examples: string[] = [];
  @observable private _externalExamples: string[] = [];
  @observable private _shouldSkipAlreadyMatched = true;
  @observable private _filters: ExternalAssociationListFilters = EmptyExternalAssociationListFilters;
  @observable private _externalAccount: ExternalAccount;

  private readonly _variables: Variable<SectionModel>[];
  private readonly _externalVariables: Variable<ExternalSection>[];
  // This doesn't need to be observable.
  private _hasAutoMatched = false;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _accountService: AccountService,
    private readonly _configId: string,
    externalAccount: ExternalAccount,
    private readonly _associations: ExternalAssociationViewModel[],
    private readonly _externalSections: ExternalSection[],
    private readonly _minimumDate: Day,
    private readonly _maximumDate: Day,
    teachersById: Record<string, AccountModel>,
    public readonly isDataIncomplete: boolean,
    initialTeacherIdFilter: string | undefined
  ) {
    super();

    makeObservable(this);

    this._variables = [
      new AppVariable<SectionModel>('title', (s) => s.title),
      new AppVariable<SectionModel>('id', (s) => s.importId),
      new AppVariable<SectionModel>('group', (s) => s.sectionNumber),
      new AppVariable<SectionModel>('grade', (s) => s.gradeLevel),
      new AppVariable<SectionModel>('firstname', (s) => teachersById[s.teacherIds[0]]?.firstName ?? ''),
      new AppVariable<SectionModel>('lastname', (s) => teachersById[s.teacherIds[0]]?.lastName ?? ''),
      new AppVariable<SectionModel>(
        'period',
        (s) =>
          _.chain(s.schedules)
            .map((sc) => sc.masterSchedule?.periodTag)
            .filter((tag) => tag != null && tag.length > 0)
            .groupBy((tag) => tag)
            .orderBy((g) => g.length, 'desc')
            .map((g) => g[0])
            .head()
            .value() ?? ''
      )
    ];

    this._externalVariables = [
      new AppVariable<ExternalSection>('title', (es) => es.title),
      new AppVariable<ExternalSection>('code', (es) => es.code),
      new AppVariable<ExternalSection>('group', (es) => es.group),
      new AppVariable<ExternalSection>('grade', (es) => es.level),
      new AppVariable<ExternalSection>('term', (es) => es.term),
      new AppVariable<ExternalSection>('id', (es) => es.id),
      new AppVariable<ExternalSection>('extraData', (es) => es.extraData)
    ];

    this._sectionPattern = this._variables.map((v) => `{${v.name}}`).join(' ');
    this._externalSectionPattern = this._externalVariables.map((v) => `{${v.name}}`).join(' ');

    if (initialTeacherIdFilter != null) {
      this._filters = { ...this._filters, teacherId: initialTeacherIdFilter };
    }

    this._externalAccount = externalAccount;
  }

  @computed
  get hasFailedAssociations() {
    return (
      this.associations.find(
        (a) =>
          a.viewModel.section != null &&
          a.viewModel.externalSection != null &&
          a.viewModel.externalSection != 'unknown' &&
          a.viewModel.lastUpdate != null &&
          a.viewModel.lastUpdateResult === false
      ) != null
    );
  }

  @computed
  get canContactOwnerAboutBrokenConnection() {
    return (
      this._externalAccount.kind === 'google' &&
      this._externalAccount.email.length > 0 &&
      this._accountService.isRootAdmin
    );
  }

  get isLinkOnly() {
    return this._externalAccount.kind === 'calendars';
  }

  @computed
  get searchText() {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
  }

  @computed
  get associations() {
    let associations = this._associations;

    const teacherId = this._filters.teacherId;

    if (teacherId != null && teacherId.length > 0) {
      associations = associations.filter((a) => a.section?.teacherIds?.includes(teacherId));
    }

    const termTag = this._filters.term?.tag;

    if (termTag != null && termTag.length > 0) {
      associations = associations.filter((a) => a.section?.schedules?.some((sc) => sc.termTag === termTag));
    }

    const status = this._filters.connectionStatus;

    if (status != 'any') {
      associations = associations.filter((a) => this.verifyMatchConnectionStatus(a, status));
    }

    return associations.map<ExternalAssociationInfo>((a) => ({
      id: a.associationId ?? a.section?.id ?? '',
      viewModel: a
    }));
  }

  @computed
  private get sortedExternalSectionModels(): ExternalSection[] {
    return _.sortBy(this._externalSections, (s) => [cleanDiacritics(s.title)]);
  }

  @computed
  get externalSections() {
    return this.sortedExternalSectionModels.map<ExternalSectionInfo>((section) => ({
      id: section.id,
      section
    }));
  }

  @computed
  get defaultMinimumDate() {
    return this._defaultMinimumDate ?? this._filters.term?.startDay ?? this._minimumDate;
  }

  set defaultMinimumDate(day: Day) {
    this._defaultMinimumDate = day;
  }

  @computed
  get defaultMaximumDate() {
    return this._defaultMaximumDate ?? this._filters.term?.endDay ?? this._maximumDate;
  }

  set defaultMaximumDate(day: Day) {
    this._defaultMaximumDate = day;
  }

  @computed
  get isAutoMatching() {
    return this._isAutoMatching;
  }

  get variables() {
    return this._variables;
  }

  get externalVariables() {
    return this._externalVariables;
  }

  @computed
  get autoMatchSectionPattern() {
    return this._sectionPattern;
  }

  set autoMatchSectionPattern(value: string) {
    this._sectionPattern = value;
    this.updateAutoMatchSummary();
  }

  @computed
  get autoMatchExternalSectionPattern() {
    return this._externalSectionPattern;
  }

  set autoMatchExternalSectionPattern(value: string) {
    this._externalSectionPattern = value;
    this.updateAutoMatchSummary();
  }

  @computed
  get shouldSkipAlreadyMatched() {
    return this._shouldSkipAlreadyMatched;
  }

  set shouldSkipAlreadyMatched(value: boolean) {
    this._shouldSkipAlreadyMatched = value;
    this.updateAutoMatchSummary();
  }

  @computed
  get examples() {
    return this._examples;
  }

  @computed
  get externalExamples() {
    return this._externalExamples;
  }

  @computed
  get matchCount() {
    return this._matchCount;
  }

  @computed
  get isSnoozed(): boolean {
    if (this._externalAccount.snoozeErrorsUntil == null) {
      return false;
    }

    return isAfter(this._externalAccount.snoozeErrorsUntil, new Date());
  }

  @computed
  get hasThrottling(): boolean {
    return this._externalAccount.skippedSyncCycleCount > 0 || this._externalAccount.syncPauseTime != null;
  }

  @computed
  get autoMatchHistory(): AutoMatchEntry[] {
    return this._externalAccount.autoMatchHistory;
  }

  @computed
  get autoMatchHistoryAnchor(): HTMLElement | undefined {
    return this._autoMatchHistoryAnchor;
  }

  @computed
  get isAutoMatchHistoryOpen(): boolean {
    return this._autoMatchHistoryAnchor != null;
  }

  @computed
  get hasChanges() {
    // All associations considered for changes
    return this._associations.findIndex((s) => s.hasChanges) >= 0;
  }

  @computed
  get warning() {
    // To keep the message short, it's always the most important issue.
    if (this._associations.find((a) => a.hasAssociation && a.owner == null) != null) {
      return this._localizationService.localizedStrings.insights.viewModels.connectors.missingOwner;
    }

    return undefined;
  }

  @computed
  get hasFilters() {
    return (
      (this._filters.teacherId?.length ?? 0) > 0 ||
      (this._filters.term?.tag?.length ?? 0) > 0 ||
      this._filters.connectionStatus != 'any'
    );
  }

  customFilterAndSearch(filter: string, item: ExternalAssociationInfo) {
    return CustomFilterUtils.customFilterAndSearch(filter, item, () =>
      item.viewModel.section == null
        ? []
        : [
            SectionUtils.formatTitle(item.viewModel.section, ''),
            // gradeLevel, sectionNumber and defaultRoomName ignored, as it could
            // confuse users since they're not displayed.
            item.viewModel.section.importId,
            ...(item.viewModel.teachers?.map((t) => AccountUtils.getDisplayLastFirstName(t)) || '')
          ]
    );
  }

  applyDatesToAll(minDate: Day, maxDate: Day) {
    this.associations
      .filter(
        (a) =>
          a.viewModel.hasExternalSection &&
          (a.viewModel.hasAssociation || a.viewModel.externalSection != null) &&
          this.filterItem(a)
      )
      .forEach(({ viewModel }) => {
        viewModel.minimumDate = minDate;
        viewModel.maximumDate = maxDate;
      });
  }

  @action
  startAutoMatch() {
    this._isAutoMatching = true;
    this.updateAutoMatchSummary();
  }

  @action
  applyAutoMatch() {
    const matches = this.getMatches();
    matches.forEach((match) =>
      match.association.setExternalSection(match.externalSection.id, this.defaultMinimumDate, this.defaultMaximumDate)
    );

    // We only want to update the auto-match history if changes actually applied.
    this._hasAutoMatched = true;

    this._isAutoMatching = false;
  }

  @action
  cancelAutoMatch() {
    this._isAutoMatching = false;
  }

  @action
  unmatchAll() {
    this.associations
      .filter((a) => a.viewModel.hasExternalSection && a.viewModel.externalSection != null && this.filterItem(a))
      .forEach(({ viewModel }) => viewModel.setExternalSection(undefined));
  }

  async showFilters(): Promise<void> {
    const result = await this._navigationService.navigateToExternalAssociationsFilters(this._configId, this._filters);

    if (result !== 'cancelled') {
      /**
       * Reset the default min and max dates if the selected term changed
       * to force the usage of the new term date instead of the custom dates
       */
      if (result.term?.tag !== this._filters.term?.tag) {
        runInAction(() => {
          this._defaultMinimumDate = undefined;
          this._defaultMaximumDate = undefined;
        });
      }

      runInAction(() => (this._filters = result));
    }
  }

  @action
  async applyChanges(): Promise<void> {
    await this.process(async () => {
      // Though it's a centralized "apply", each child view-model can update itself based on changes.
      // We do this one by one to avoid multiple errors (fail on first) but do not want them to
      // update right away, otherwise the whole list will refresh after each.
      try {
        for (const association of this._associations) {
          await association.applyChanges();
        }
      } finally {
        for (const association of this._associations) {
          association.confirmChanges();
        }
      }

      if (this._hasAutoMatched) {
        this._hasAutoMatched = false;
        // Do not cause this to fail the whole process.
        try {
          const account = await this._connectorsStore.addAutoMatch(
            this._externalAccount.id,
            this._sectionPattern,
            this._externalSectionPattern
          );
          // This reloads the whole list. Too bad.
          runInAction(() => (this._externalAccount = account));
        } catch (error) {
          console.error('Failed to add auto-match to the history');
          console.error(error);
        }
      }
    });
  }

  @action
  resetChanges(): void {
    this._associations.forEach((s) => s.resetChanges());
  }

  @action
  async showEditErrorNotificationSettings(): Promise<void> {
    if ((await this._navigationService.navigateToErrorNotificationSettings(this._externalAccount)) !== 'cancelled') {
      const externalAccount = await this._connectorsStore.getExternalAccount(this._configId, this._externalAccount.id);
      runInAction(() => (this._externalAccount = externalAccount));
    }
  }

  @action
  async showEditThrottleSettings(): Promise<void> {
    if ((await this._navigationService.navigateToThrottleSettings(this._externalAccount)) !== 'cancelled') {
      const externalAccount = await this._connectorsStore.getExternalAccount(this._configId, this._externalAccount.id);
      runInAction(() => (this._externalAccount = externalAccount));
    }
  }

  @action
  async showScheduledAutoMatchSettings(): Promise<void> {
    if ((await this._navigationService.navigateToScheduledAutoMatchSettings(this._externalAccount)) !== 'cancelled') {
      const externalAccount = await this._connectorsStore.getExternalAccount(this._configId, this._externalAccount.id);
      runInAction(() => (this._externalAccount = externalAccount));
    }
  }

  async forgetTasks(associationId?: string): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    try {
      const count = await this._connectorsStore.resetKnownElements(
        this._configId,
        this._externalAccount.id,
        associationId
      );

      await this._alertService.showMessage({
        title: strings.tasksForgottenTitle,
        message: strings.tasksForgottenMessage(count)
      });
    } catch (error) {
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + '\n' + (error as Error).message
      });
    }
  }

  @action
  toggleAutoMatchHistory(anchor?: HTMLElement): void {
    this._autoMatchHistoryAnchor = anchor;
  }

  @action
  selectAutoMatchEntry(entry: AutoMatchEntry): void {
    this._sectionPattern = entry.studyoPattern;
    this._externalSectionPattern = entry.externalPattern;
    this._autoMatchHistoryAnchor = undefined;
    this.updateAutoMatchSummary();
  }

  async contactOwnerAboutBrokenConnection(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (
      (await this._alertService.showConfirmation({
        title: strings.confirmContactOwnerTitle,
        message: strings.confirmContactOwnerMessage(this._externalAccount.email)
      })) !== 'cancelled'
    ) {
      try {
        await this._connectorsStore.classroom.sendBrokenConnectionEmail(
          this._externalAccount.id,
          this._localizationService.currentLocale
        );
        await this._alertService.showMessage({
          title: strings.ownerContactedTitle,
          message: strings.ownerContactedMessage
        });
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + '\n' + (error as Error).message
        });
      }
    }
  }
  @action
  private updateAutoMatchSummary() {
    if (!this.isAutoMatching) {
      return;
    }

    const matches = this.getMatches();

    // If we have matches, examples come from them.
    if (matches.length > 0) {
      const indexes = this.getSampleIndexes(matches.length);
      this._examples = indexes.map((i) => matches[i].pattern);
      this._externalExamples = this._examples.slice();
    } else {
      const sections = _.compact(this.associations.map(({ viewModel }) => viewModel.section));
      const externalSections = this.sortedExternalSectionModels;

      if (sections.length > 0) {
        const indexes = this.getSampleIndexes(sections.length);
        this._examples = indexes.map((i) => this.applyPattern(sections[i]));
      }

      if (externalSections.length > 0) {
        const externalIndexes = this.getSampleIndexes(externalSections.length);
        this._externalExamples = externalIndexes.map((i) => this.applyExternalPattern(externalSections[i]));
      }
    }

    this._matchCount = matches.length;
  }

  private getSampleIndexes(count: number): number[] {
    if (count < 5) {
      return [...Array(count).keys()];
    }

    return [0, Math.floor(count / 4), Math.floor(count / 2), Math.floor((count / 4) * 3), count - 1];
  }

  private getMatches(): {
    association: ExternalAssociationViewModel;
    section: SectionModel;
    externalSection: ExternalSection;
    pattern: string;
  }[] {
    // Empty patterns in here, but we make sure not to consider them below.
    const externalSectionsByPattern = _.keyBy(this._externalSections, (ea) => this.applyExternalPattern(ea));
    return _.compact(
      this.associations
        .filter(
          (a) =>
            a.viewModel.section != null &&
            (!this._shouldSkipAlreadyMatched || a.viewModel.externalSection == null) &&
            this.filterItem(a)
        )
        .map(({ viewModel }) => {
          const pattern = this.applyPattern(viewModel.section!);
          const match = pattern.length > 0 ? externalSectionsByPattern[pattern] : undefined;

          if (match != null) {
            return {
              association: viewModel,
              section: viewModel.section!,
              externalSection: match,
              pattern
            };
          } else {
            return undefined;
          }
        })
    );
  }

  private applyPattern(section: SectionModel): string {
    return _.reduce(this._variables, (acc, variable) => variable.replace(section, acc), this._sectionPattern);
  }

  private applyExternalPattern(externalSection: ExternalSection): string {
    return _.reduce(
      this._externalVariables,
      (acc, variable) => variable.replace(externalSection, acc),
      this._externalSectionPattern
    );
  }

  private filterItem(item: ExternalAssociationInfo): boolean {
    return this._searchText.length === 0 || this.customFilterAndSearch(this._searchText, item);
  }

  private verifyMatchConnectionStatus(item: ExternalAssociationViewModel, status: ConnectionStatus): boolean {
    switch (status) {
      case 'connected':
        return item.hasAssociation;

      case 'not-connected':
        return !item.hasAssociation;

      case 'connected-success':
        // Those in progress are always displayed
        return item.hasAssociation && item.lastUpdateResult !== false;

      case 'connected-error':
        // Those in progress are always displayed
        return item.hasAssociation && item.lastUpdate != null && item.lastUpdateResult !== true;

      case 'connected-orphan':
        return item.hasAssociation && item.section == null;

      case 'connected-unowned':
        return item.hasAssociation && item.owner == null;

      default:
        return true;
    }
  }
}
