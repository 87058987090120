import { SectionColorSetting as PBSectionColorSetting } from '@buf/studyo_studyo.bufbuild_es/studyo/type_account_pb';
import { BaseModel, SerializableModel } from '../Model';
import { Color } from '../types';
import { colorFromProtobuf } from '../types/EnumConversion';

export interface SectionColorSettingModel extends SerializableModel<PBSectionColorSetting> {
  readonly sectionId: string;
  readonly color: Color;
}

export class SectionColorSetting extends BaseModel<PBSectionColorSetting> implements SectionColorSettingModel {
  constructor(pb: PBSectionColorSetting) {
    super(pb);
  }

  get sectionId(): string {
    return this._pb.sectionId;
  }

  get color(): Color {
    // The index is the same as the PB.Color enum values.
    return colorFromProtobuf(this._pb.colorIndex);
  }
}
