import { OnboardingAnswer as PBOnboardingAnswer } from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { uniq } from 'lodash';
import { computed, makeObservable } from 'mobx';
import {
  EditableModelEx,
  EditableNullableDatePropertyEx,
  EditableStringArrayProperty,
  EditableStringProperty
} from '../../editables';
import { OnboardingQuestionKind, Time } from '../../types';
import { protobufFromDate, protobufFromOnboardingQuestionKind } from '../../types/EnumConversion';
import { OnboardingAnswer } from '../interfaces';
import { GrpcOnboardingAnswer } from './GrpcOnboardingAnswer';

export class EditableOnboardingAnswer extends EditableModelEx<PBOnboardingAnswer> implements OnboardingAnswer {
  private _simpleTextValue: EditableStringProperty<PBOnboardingAnswer>;
  private _largeTextValue: EditableStringProperty<PBOnboardingAnswer>;
  private _singleChoiceValue: EditableStringProperty<PBOnboardingAnswer>;
  private _multipleChoicesValue: EditableStringArrayProperty<PBOnboardingAnswer>;
  private _dateValue: EditableNullableDatePropertyEx<PBOnboardingAnswer>;
  private _dateTimeValue: EditableNullableDatePropertyEx<PBOnboardingAnswer>;
  private _timeValue: EditableStringProperty<PBOnboardingAnswer>;
  private _fileValue: EditableStringProperty<PBOnboardingAnswer>;
  private _sensitiveSimpleTextValue: EditableStringProperty<PBOnboardingAnswer>;

  static createNew(kind: OnboardingQuestionKind): EditableOnboardingAnswer {
    const pb = new PBOnboardingAnswer();
    pb.kind = protobufFromOnboardingQuestionKind(kind);

    return new EditableOnboardingAnswer(new GrpcOnboardingAnswer(pb), true);
  }

  constructor(
    private readonly originalAnswer: OnboardingAnswer,
    isNew = false
  ) {
    super(originalAnswer.toProtobuf(), isNew);

    makeObservable(this);

    this._simpleTextValue = this.addStringField(
      originalAnswer.kind === 'simple-text' ? originalAnswer.simpleTextValue : '',
      (pb, value) => {
        if (this.kind === 'simple-text') {
          pb.answer = { case: 'simpleTextAnswer', value };
        }
      }
    );
    this._largeTextValue = this.addStringField(
      originalAnswer.kind === 'large-text' ? originalAnswer.largeTextValue : '',
      (pb, value) => {
        if (this.kind === 'large-text') {
          pb.answer = { case: 'largeTextAnswer', value };
        }
      }
    );
    this._singleChoiceValue = this.addStringField(
      originalAnswer.kind === 'single-choice' ? originalAnswer.singleChoiceValue : '',
      (pb, value) => {
        if (this.kind === 'single-choice') {
          pb.answer = { case: 'singleChoiceAnswer', value };
        }
      }
    );
    this._multipleChoicesValue = this.addStringArrayField(
      originalAnswer.kind === 'multiple-choice' ? originalAnswer.multipleChoicesValue : [],
      (pb, value) => {
        if (this.kind === 'multiple-choice') {
          pb.answer = { case: 'multipleChoiceAnswer', value: value.join(',') };
        }
      }
    );
    this._dateValue = this.addNullableDateField(
      originalAnswer.kind === 'date' ? originalAnswer.dateValue : undefined,
      (pb, value) => {
        if (this.kind === 'date') {
          pb.answer =
            value != null
              ? {
                  case: 'dateAnswer',
                  value: protobufFromDate(value)
                }
              : { case: undefined, value: undefined };
        }
      }
    );
    this._dateTimeValue = this.addNullableDateField(
      originalAnswer.kind === 'date-time' ? originalAnswer.dateTimeValue : undefined,
      (pb, value) => {
        if (this.kind === 'date-time') {
          pb.answer =
            value != null
              ? {
                  case: 'dateTimeAnswer',
                  value: protobufFromDate(value)
                }
              : { case: undefined, value: undefined };
        }
      }
    );
    this._timeValue = this.addStringField(
      originalAnswer.kind === 'time' ? originalAnswer.timeValue : '',
      (pb, value) => {
        if (this.kind === 'time') {
          pb.answer = { case: 'timeAnswer', value };
        }
      }
    );
    this._fileValue = this.addStringField(
      originalAnswer.kind === 'file' ? originalAnswer.fileValue : '',
      (pb, value) => {
        if (this.kind === 'file') {
          pb.answer = { case: 'fileAnswer', value };
        }
      }
    );
    this._sensitiveSimpleTextValue = this.addStringField(
      originalAnswer.kind === 'sensitive-simple-text' ? originalAnswer.sensitiveSimpleTextValue : '',
      (pb, value) => {
        if (this.kind === 'sensitive-simple-text') {
          pb.answer = { case: 'sensitiveSimpleTextAnswer', value };
        }
      }
    );
  }

  @computed
  get kind(): OnboardingQuestionKind {
    // The kind is not editable.
    return this.originalAnswer.kind;
  }

  @computed
  get simpleTextValue(): string {
    return this._simpleTextValue.value;
  }

  set simpleTextValue(value: string) {
    if (this.kind !== 'simple-text') {
      throw new Error('The answer kind does not support modifying this value.');
    }

    this._simpleTextValue.value = value;
  }

  @computed
  get largeTextValue(): string {
    return this._largeTextValue.value;
  }

  set largeTextValue(value: string) {
    if (this.kind !== 'large-text') {
      throw new Error('The answer kind does not support modifying this value.');
    }

    this._largeTextValue.value = value;
  }

  @computed
  get singleChoiceValue(): string {
    return this._singleChoiceValue.value;
  }

  set singleChoiceValue(value: string) {
    if (this.kind !== 'single-choice') {
      throw new Error('The answer kind does not support modifying this value.');
    }

    this._singleChoiceValue.value = value;
  }

  @computed
  get multipleChoicesValue(): string[] {
    return this._multipleChoicesValue.value;
  }

  set multipleChoicesValue(value: string[]) {
    if (this.kind !== 'multiple-choice') {
      throw new Error('The answer kind does not support modifying this value.');
    }

    this._multipleChoicesValue.value = value;
  }

  addMultipleChoicesValue(value: string) {
    this.multipleChoicesValue = uniq(this.multipleChoicesValue.concat(value));
  }

  removeMultipleChoicesValue(value: string) {
    this.multipleChoicesValue = this.multipleChoicesValue.filter((v) => v !== value);
  }

  @computed
  get dateValue(): Date | undefined {
    return this._dateValue.value;
  }

  set dateValue(value: Date | undefined) {
    if (this.kind !== 'date') {
      throw new Error('The answer kind does not support modifying this value.');
    }

    this._dateValue.value = value;
  }

  @computed
  get dateTimeValue(): Date | undefined {
    return this._dateTimeValue.value;
  }

  set dateTimeValue(value: Date | undefined) {
    if (this.kind !== 'date-time') {
      throw new Error('The answer kind does not support modifying this value.');
    }

    this._dateTimeValue.value = value;
  }

  @computed
  get timeValue(): string {
    return this._timeValue.value;
  }

  set timeValue(value: string) {
    if (this.kind !== 'time') {
      throw new Error('The answer kind does not support modifying this value.');
    }

    if (value.length > 0 && Time.fromString(value) == null) {
      throw new Error('Invalid time format.');
    }

    this._timeValue.value = value;
  }

  @computed
  get timeValueAsDate(): Date | undefined {
    const time = Time.fromString(this.timeValue);
    return time?.asDate;
  }

  set timeValueAsDate(value: Date | undefined) {
    const time = Time.fromDate(value);
    this.timeValue = time?.asString ?? '';
  }

  @computed
  get fileValue(): string {
    return this._fileValue.value;
  }

  set fileValue(value: string) {
    if (this.kind !== 'file') {
      throw new Error('The answer kind does not support modifying this value.');
    }

    this._fileValue.value = value;
  }

  @computed
  get sensitiveSimpleTextValue(): string {
    return this._sensitiveSimpleTextValue.value;
  }

  set sensitiveSimpleTextValue(value: string) {
    if (this.kind !== 'sensitive-simple-text') {
      throw new Error('The answer kind does not support modifying this value.');
    }

    this._sensitiveSimpleTextValue.value = value;
  }

  @computed
  get hasAnswer(): boolean {
    switch (this.kind) {
      case 'simple-text':
        return this.simpleTextValue.length > 0;
      case 'large-text':
        return this.largeTextValue.length > 0;
      case 'single-choice':
        return this.singleChoiceValue.length > 0;
      case 'multiple-choice':
        // We assume that values within are not empty.
        return this.multipleChoicesValue.length > 0;
      case 'date':
        return this.dateValue != null;
      case 'date-time':
        return this.dateTimeValue != null;
      case 'time':
        return this.timeValue.length > 0;
      case 'file':
        return this.fileValue.length > 0;
      case 'sensitive-simple-text':
        return this.sensitiveSimpleTextValue.length > 0;
    }
  }
}
