import { EditableTransformationColumn, Operation, SchemaField } from '@shared/models/import';
import _ from 'lodash';
import { action, computed, makeObservable } from 'mobx';
import {
  AppEditableTransformationParameterViewModel,
  EditableTransformationParameterViewModel
} from './EditableTransformationParameterViewModel';
import { EditableTransformationViewModel, FocusedField } from './EditableTransformationViewModel';

export interface EditableTransformationColumnViewModel {
  readonly id: string;
  readonly availableOperations: Operation[];
  readonly availableSchemaFields: SchemaField[];

  readonly index: number;
  readonly operation: Operation;
  setOperationByName(name: string): void;
  readonly parameters: EditableTransformationParameterViewModel[];
  readonly schemaFields: string[];
  setSchemaFields(names: string[]): void;
  schemaFieldsAsColumnNames: string;
  isSubstitution: boolean;
  comment: string;
  readonly hasComment: boolean;
  readonly focusedField: FocusedField;
  setFocusedField(field?: FocusedField): void;

  readonly hasChanges: boolean;

  delete(): void;
  resetChanges(): void;
  editComment(): void;
}

export class AppEditableTransformationColumnViewModel implements EditableTransformationColumnViewModel {
  private readonly _operationsByName: _.Dictionary<Operation>;

  constructor(
    private readonly _parent: EditableTransformationViewModel,
    private readonly _editableColumn: EditableTransformationColumn,
    public readonly id: string,
    public readonly index: number,
    public readonly availableOperations: Operation[],
    public readonly availableSchemaFields: SchemaField[],
    private readonly _onChange: () => void,
    private readonly _onEditComment: (column: EditableTransformationColumnViewModel) => void
  ) {
    makeObservable(this);
    this._operationsByName = _.keyBy(availableOperations, (o) => o.name);

    // In the old format, an operation could contain directly a single parameter of the column
    // to create. In the new format, the operation name will be empty, and the first parameter
    // will contain the text/variable to build. There is a new operation named "" that matches
    // this. Though the old format is still supported by transformations, we move to the new
    // format to encourage it, and ease implementation of the UI.
    if (this._operationsByName[_editableColumn.operation] == null && _editableColumn.parameters.length === 0) {
      // This will cause the column to already have changes, which is what we want.
      _editableColumn.parameters = [_editableColumn.operation];
      _editableColumn.operation = '';
    }
  }

  @computed
  get operation() {
    return this._operationsByName[this._editableColumn.operation] ?? this.availableOperations[0];
  }

  @action
  setOperationByName(name: string) {
    const operation = this._operationsByName[name];

    if (operation == null) {
      throw new Error('Unknown operation name.');
    }

    this._editableColumn.operation = operation.name;

    // Even though the values might not correspond, we try to keep as many parameters
    // as possible.
    this._editableColumn.parameters = operation.parameters.map((_, i) =>
      i < this._editableColumn.parameters.length ? this._editableColumn.parameters[i] : ''
    );

    // It doesn't make sense to update the data now, parameters will require changes.
    this._onChange();
  }

  @computed
  get parameters() {
    const operation = this.operation;
    return this._editableColumn.parameters.map(
      (_, i) =>
        new AppEditableTransformationParameterViewModel(
          this._editableColumn,
          operation.parameters[i],
          i,
          this._onChange
        )
    );
  }

  @computed
  get schemaFields() {
    return this._editableColumn.targetSchemaField.split('|').filter((f) => f.length > 0);
  }

  @action
  setSchemaFields(names: string[]) {
    // This gets called with names in the original order, with either the new selection last, or
    // the unselection removed.
    this._editableColumn.targetSchemaField = names.join('|');
    this._onChange();
  }

  @computed
  get schemaFieldsAsColumnNames(): string {
    return this._editableColumn.targetSchemaField;
  }

  set schemaFieldsAsColumnNames(value: string) {
    this._editableColumn.targetSchemaField = value;
    this._onChange();
  }

  @computed
  get isSubstitution() {
    return this._editableColumn.isSubstitution;
  }

  set isSubstitution(value: boolean) {
    this._editableColumn.isSubstitution = value;
    this._onChange();
  }

  @computed
  get comment(): string {
    return this._editableColumn.comment;
  }

  set comment(value: string) {
    this._editableColumn.comment = value;
    this._onChange();
  }

  @computed
  get hasComment(): boolean {
    return this._editableColumn.comment.length > 0;
  }

  @computed
  get focusedField(): FocusedField {
    return this._parent.focusedColumnIndex === this.index ? this._parent.focusedField : 'none';
  }

  @action
  setFocusedField(field?: FocusedField) {
    if (field != null) {
      this._parent.setFocused(this, field);
    }
  }

  @computed
  get hasChanges(): boolean {
    return this._editableColumn.hasChanges;
  }

  @action
  delete() {
    this._editableColumn.markAsDeleted();
    this._parent.resetFocused();
    this._onChange();
  }

  @action
  resetChanges() {
    this._editableColumn.resetChanges();
    // No need to call _onChange here, we're called by the parent view-model.
  }

  @action
  editComment() {
    this._onEditComment(this);
  }
}
