export interface RequiresFeatureConditionStrings {
  defaultTitle: string;
  defaultMessage: string;
}

export const EnglishRequiresFeatureConditionStrings: RequiresFeatureConditionStrings = {
  defaultTitle: 'Feature not available',
  defaultMessage: 'Your subscription does not allow you to access this feature. Contact us to get it!'
};

export const FrenchRequiresFeatureConditionStrings: RequiresFeatureConditionStrings = {
  defaultTitle: 'Fonctionnalité non disponible',
  defaultMessage: 'Votre abonnement ne vous donne pas accès à cette fonctionnalité. Contactez-nous pour l’obtenir!'
};
