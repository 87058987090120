export interface RedirectsStrings {
  readonly contactingStripeMessage: string;
  readonly errorSubtitle: string;
  readonly errorTitle: string;
  readonly successSubtitle: string;
  readonly successTitle: string;
  readonly goToHomepageButtonTitle: string;
}

export const EnglishRedirectsStrings: RedirectsStrings = {
  contactingStripeMessage: 'Processing…',
  errorSubtitle:
    'An unexpected issue occurred while contacting our payment processor. Please contact support if this issue persists.',
  errorTitle: 'Sorry',
  successSubtitle: 'You can now connect to Studyo.\nMake sure to use the same email you were invited with.',
  successTitle: 'Thank you!',
  goToHomepageButtonTitle: 'Go to homepage'
};

export const FrenchRedirectsStrings: RedirectsStrings = {
  contactingStripeMessage: 'En traitement…',
  errorSubtitle:
    'Une erreur imprévue est survenue en tentant de contacter notre service de paiements. Si le problème persiste, veuillez contacter le support.',
  errorTitle: 'Nos excuses',
  successSubtitle:
    'Vous pouvez maintenant vous connecter à Studyo.\nAssurez-vous d’utiliser le même courriel que celui avec lequel vous avez été invité.',
  successTitle: 'Merci!',
  goToHomepageButtonTitle: 'Retourner à la page principale'
};
