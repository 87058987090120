import DropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Button,
  Grid2,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  SxProps,
  Typography,
  useTheme
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { isSxArray } from '@shared/components/utils';
import { AllOnboardingStatuses, AllStepOnboardingStatuses, OnboardingStatus } from '@shared/models/types';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useRef, useState } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';

export interface OnboardingStatusSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  selectedProcessStatuses: OnboardingStatus[];
  selectedStepStatuses: OnboardingStatus[];
  minimumDate: Date | undefined;
  setStatuses: (
    processStatuses: OnboardingStatus[],
    stepStatuses: OnboardingStatus[],
    minimumDate: Date | undefined
  ) => void;
}

export const OnboardingStatusSelector = observer((props: OnboardingStatusSelectorProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], setStatuses, selectedProcessStatuses, selectedStepStatuses, minimumDate } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [processStatuses, setProcessStatuses] = useState<OnboardingStatus[]>(selectedProcessStatuses.slice());
  const [stepStatuses, setStepStatuses] = useState<OnboardingStatus[]>(selectedStepStatuses.slice());
  const [localMinimumDate, setMinimumDate] = useState<Date | null>(minimumDate ?? null);

  const readableProcessStatuses =
    processStatuses.map((s) => strings.getProcessStatusLabel(s, false)).join('+') || strings.anyStatus;
  const readableStepStatuses =
    stepStatuses.map((s) => strings.getStepStatusLabel(s, false)).join('+') || strings.anyStatus;
  const readableMinimumDate =
    localMinimumDate != null
      ? format(localMinimumDate, localizationService.localizedStrings.models.dateFormats.medium)
      : strings.noneLabel;

  function toggleProcessStatus(status: OnboardingStatus) {
    if (processStatuses.includes(status)) {
      setProcessStatuses(processStatuses.filter((s) => s !== status));
    } else {
      setProcessStatuses([...processStatuses, status]);
    }
  }

  function toggleStepStatus(status: OnboardingStatus) {
    if (stepStatuses.includes(status)) {
      setStepStatuses(stepStatuses.filter((s) => s !== status));
    } else {
      setStepStatuses([...stepStatuses, status]);
    }
  }

  function applyChanges() {
    // Also make copies here, as we're still there for the next "open".
    // Otherwise, the viewmodel now has our copy.
    setStatuses(processStatuses.slice(), stepStatuses.slice(), localMinimumDate ?? undefined);
    setIsOpen(false);
  }

  function cancelChanges() {
    // Make sure to use copies to not affect the original arrays on push.
    setProcessStatuses(selectedProcessStatuses.slice());
    setStepStatuses(selectedStepStatuses.slice());
    setIsOpen(false);
  }

  return (
    <>
      <Box
        className={className}
        style={style}
        sx={[
          {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <Button ref={buttonRef} variant="text" style={{ textTransform: 'none' }} onClick={() => setIsOpen(!isOpen)}>
          <Stack
            sx={{
              alignItems: 'flex-start',
              mr: 2
            }}
          >
            <Typography variant="caption">{strings.processStatuses}</Typography>
            <Typography variant="body2">{readableProcessStatuses}</Typography>
          </Stack>
          <Stack
            sx={{
              alignItems: 'flex-start',
              mr: 2
            }}
          >
            <Typography variant="caption">{strings.stepStatuses}</Typography>
            <Typography variant="body2">{readableStepStatuses}</Typography>
          </Stack>
          <Stack
            sx={{
              alignItems: 'flex-start',
              mr: 2
            }}
          >
            <Typography variant="caption">{strings.minimumDate}</Typography>
            <Typography variant="body2">{readableMinimumDate}</Typography>
          </Stack>
          <DropDownIcon fontSize="small" />
        </Button>
      </Box>
      <Popover
        open={isOpen}
        onClose={() => cancelChanges()}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Grid2 container sx={{ minWidth: theme.breakpoints.values.sm, p: 2 }} spacing={2}>
          <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
            <Typography variant="subtitle1">{strings.processStatuses}</Typography>
            <List dense sx={{ rowGap: 0.5, display: 'flex', flexDirection: 'column' }}>
              {AllOnboardingStatuses.map((s) => (
                <ListItemButton
                  key={`process-status-${s}`}
                  selected={processStatuses.includes(s)}
                  onClick={() => toggleProcessStatus(s)}
                  sx={{ borderRadius: 1 }}
                >
                  <ListItemText>{strings.getProcessStatusLabel(s, false)}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
            <Typography variant="subtitle1">{strings.stepStatuses}</Typography>
            <List dense sx={{ rowGap: 0.5, display: 'flex', flexDirection: 'column' }}>
              {AllStepOnboardingStatuses.map((s) => (
                <ListItemButton
                  key={`step-status-${s}`}
                  selected={stepStatuses.includes(s)}
                  onClick={() => toggleStepStatus(s)}
                  sx={{ borderRadius: 1 }}
                >
                  <ListItemText>{strings.getStepStatusLabel(s, false)}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
            <Typography variant="subtitle1">{strings.minimumDate}</Typography>
            <DatePicker
              slotProps={{
                actionBar: {
                  actions: ['clear', 'cancel', 'accept']
                },
                textField: {
                  fullWidth: true
                }
              }}
              localeText={{
                okButtonLabel: strings.okLabel,
                cancelButtonLabel: strings.cancelLabel,
                clearButtonLabel: strings.clearLabel
              }}
              format={localizationService.localizedStrings.models.dateFormats.mediumUnabridged}
              value={localMinimumDate}
              onChange={(v) => setMinimumDate(v)}
            />
            <Typography variant="caption">{strings.minimumDateNotice}</Typography>
          </Grid2>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'center'
            }}
          >
            <Button variant="contained" sx={{ m: 1 }} onClick={() => cancelChanges()}>
              {strings.cancelLabel}
            </Button>
            <Button variant="contained" sx={{ m: 1 }} color="primary" onClick={() => applyChanges()}>
              {strings.applyLabel}
            </Button>
          </Box>
        </Grid2>
      </Popover>
    </>
  );
});
