import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import am4lang_fr_FR from '@amcharts/amcharts4/lang/fr_FR';
import { DefaultAnimationDuration, DefaultAnimationEasing } from '@insights/theme';
import {
  createChartSubTitle as utilsCreateChartSubTitle,
  createChartTitle as utilsCreateChartTitle
} from '@insights/utils';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useLayoutEffect, useRef } from 'react';
import { v4 } from 'uuid';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { LayoutType } from './LayoutType.ts';

type Chart = am4charts.GaugeChart;
type Axis = am4charts.ValueAxis<am4charts.AxisRendererCircular>;

interface ChartElements {
  chart: Chart;
  title: am4core.Label;
  subTitle: am4core.Label;
  axis: Axis;
  valueRange: am4charts.ValueAxisDataItem;
  valueHand: am4charts.ClockHand;
  referenceValueRange: am4charts.ValueAxisDataItem;
  referenceValueHand: am4charts.ClockHand;
  remainingRange: am4charts.ValueAxisDataItem;
  minValueRange: am4charts.ValueAxisDataItem;
  maxValueRange: am4charts.ValueAxisDataItem;
  valueLabel: am4core.Label;
  handPropertyChangedEventDisposer: am4core.IDisposer;
  handReferenceChangedEventDisposer: am4core.IDisposer;
}

export interface ValuePresenterProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  caption: string;
  subcaption?: string;
  lowerLimitCaption?: string;
  upperLimitCaption?: string;
  value: number;
  valueColor: string;
  referenceValue?: number;
  referenceValueColor?: string;
  maximum: number;
  layoutType: LayoutType;
  tooltipText?: string;
}

export const ValuePresenter = observer((props: ValuePresenterProps) => {
  const { localizationService } = useInsightsServices();
  const {
    sx = [],
    className,
    style,
    value,
    referenceValue,
    referenceValueColor,
    valueColor,
    caption,
    subcaption,
    maximum,
    tooltipText
  } = props;
  const theme = useTheme();

  const chartElements = useRef<ChartElements | null>(null);

  const chartId = `value-presenter-chart-container-${v4()}`;

  function createChart() {
    const chart = am4core.create(chartId, am4charts.GaugeChart);
    chart.innerRadius = am4core.percent(75);

    chart.tooltipHTML = tooltipText ?? '';
    chart.tooltip!.background.fill = am4core.color(valueColor);
    chart.tooltip!.background.fillOpacity = 0.9;
    chart.tooltip!.getFillFromObject = false;
    chart.tooltip!.fontWeight = '300';
    chart.tooltip!.fontSize = theme.typography.body1.fontSize;
    chart.tooltipY = 120; // NOTE: This makes the tooltip appears on top of the big value number

    const axis = createChartAxis(chart, maximum);
    const { remainingRange, referenceValueRange, valueRange, minValueRange, maxValueRange } = createChartRanges(
      chart,
      axis,
      maximum,
      referenceValueColor,
      valueColor,
      theme
    );

    const { valueHand, handPropertyChangedEventDisposer, referenceValueHand, handReferenceChangedEventDisposer } =
      createChartHand(
        chart,
        axis,
        (ev) => onChartValueHandPropertyChanged(valueRange, axis, ev),
        (ev) => onChartReferenceValueHandPropertyChanged(referenceValueRange, axis, ev)
      );

    const valueLabel = createChartValueLabel(chart, value);
    const { title, subTitle } = createChartTitle(chart, theme);

    chartElements.current = {
      chart,
      axis,
      title,
      subTitle,
      handPropertyChangedEventDisposer,
      referenceValueHand,
      handReferenceChangedEventDisposer,
      maxValueRange,
      minValueRange,
      referenceValueRange,
      valueRange,
      valueHand,
      valueLabel,
      remainingRange
    };
  }

  function onChartValueHandPropertyChanged(
    range: am4charts.ValueAxisDataItem,
    axis: Axis,
    ev: { type: string; target: am4charts.ClockHand }
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    range.endValue = ev.target.value;
    axis.invalidate();
  }

  function onChartReferenceValueHandPropertyChanged(
    range: am4charts.ValueAxisDataItem,
    axis: Axis,
    ev: { type: string; target: am4charts.ClockHand }
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    range.endValue = ev.target.value;
    axis.invalidate();
  }

  function updateChartTitle() {
    if (chartElements.current == null) {
      return;
    }

    const { title, subTitle } = chartElements.current;
    const marginBottom = Number(theme.spacing(3).replace('px', ''));

    title.text = caption;
    title.marginBottom = marginBottom;

    if (subcaption != null) {
      title.marginBottom = 0;

      subTitle.text = subcaption;
      subTitle.marginBottom = marginBottom;
    }
  }

  function updateChartValue() {
    if (chartElements.current == null) {
      return;
    }
    const { valueHand, referenceValueHand, valueLabel, maxValueRange, axis } = chartElements.current;

    const valueHandAnimation = new am4core.Animation(
      valueHand,
      { property: 'value', to: value },
      DefaultAnimationDuration,
      DefaultAnimationEasing
    );
    valueHandAnimation.start();

    if (referenceValue != null) {
      const referenceValueHandAnimation = new am4core.Animation(
        referenceValueHand,
        { property: 'value', to: referenceValue },
        DefaultAnimationDuration,
        DefaultAnimationEasing
      );
      referenceValueHandAnimation.start();
    }

    valueLabel.text = value.toString();
    maxValueRange.value = maximum;
    maxValueRange.label.text = maximum.toString();
    axis.hidden = value === 0;
  }

  useLayoutEffect(() => {
    createChart();
    updateChartValue();
    updateChartTitle();
  }, []);

  useEffect(() => {
    const currentLocaleChangeDisposer = autorun(() => {
      const chart = chartElements.current?.chart;
      if (chart != null) {
        chart.language.locale = localizationService.currentLocale === 'fr' ? am4lang_fr_FR : am4lang_en_US;
      }
    });

    return () => {
      currentLocaleChangeDisposer();
      chartElements.current?.handPropertyChangedEventDisposer.dispose();
      chartElements.current?.handReferenceChangedEventDisposer.dispose();
      chartElements.current?.chart.dispose();
    };
  }, []);

  useEffect(() => {
    updateChartValue();
    updateChartTitle();
  }, [caption, subcaption, value, referenceValue, maximum]);

  return <Box id={chartId} sx={{ ...sx, height: '100%', width: '100%' }} className={className} style={style} />;
});

function createChartAxis(chart: Chart, maximum: number) {
  const axis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
  axis.min = 0;
  axis.max = maximum;
  axis.strictMinMax = true;
  axis.renderer.labels.template.disabled = true;
  axis.renderer.ticks.template.disabled = true;
  axis.renderer.grid.template.disabled = true;
  return axis;
}

function createChartRanges(
  _chart: Chart,
  axis: Axis,
  maximum: number,
  referenceValueColor: string | undefined,
  valueColor: string,
  theme: Theme
) {
  // IMPORTANT: The order of creation here is important since we are stacking ranges on top of each other

  const remainingRange = axis.axisRanges.create();
  remainingRange.value = 0;
  remainingRange.endValue = maximum;
  remainingRange.axisFill.fillOpacity = 1;
  remainingRange.axisFill.fill = am4core.color(grey[300]);

  const referenceValueRange = axis.axisRanges.create();
  referenceValueRange.value = 0;
  referenceValueRange.endValue = 0;
  referenceValueRange.axisFill.fillOpacity = 1;
  referenceValueRange.axisFill.fill = am4core.color(referenceValueColor);

  const valueRange = axis.axisRanges.create();
  valueRange.value = 0;
  valueRange.endValue = 0;
  valueRange.axisFill.fillOpacity = 1;
  valueRange.axisFill.fill = am4core.color(valueColor);

  const minValueRange = axis.axisRanges.create();
  minValueRange.value = 0;
  minValueRange.label.text = '0';
  minValueRange.label.fontSize = theme.typography.body1.fontSize;
  minValueRange.label.fontWeight = '300';
  minValueRange.label.opacity = 0.54; // Matches the current material UI theme secondary text

  const maxValueRange = axis.axisRanges.create();
  maxValueRange.label.fontSize = theme.typography.body1.fontSize;
  maxValueRange.label.fontWeight = '300';
  maxValueRange.label.opacity = 0.54; // Matches the current material UI theme secondary text

  return { remainingRange, referenceValueRange, valueRange, minValueRange, maxValueRange };
}

function createChartHand(
  chart: Chart,
  axis: Axis,
  onChartValueHandPropertyChanged: (ev: { type: string; target: am4charts.ClockHand }) => void,
  onChartReferenceValueHandPropertyChanged: (ev: { type: string; target: am4charts.ClockHand }) => void
) {
  const valueHand = chart.hands.push(new am4charts.ClockHand());
  valueHand.axis = axis;
  valueHand.pin.disabled = true;
  valueHand.hand.disabled = true;

  const handPropertyChangedEventDisposer = valueHand.events.on('propertychanged', onChartValueHandPropertyChanged);

  const referenceValueHand = chart.hands.push(new am4charts.ClockHand());
  referenceValueHand.axis = axis;
  referenceValueHand.pin.disabled = true;
  referenceValueHand.hand.disabled = true;

  const handReferenceChangedEventDisposer = referenceValueHand.events.on(
    'propertychanged',
    onChartReferenceValueHandPropertyChanged
  );

  return { valueHand, handPropertyChangedEventDisposer, referenceValueHand, handReferenceChangedEventDisposer };
}

function createChartValueLabel(chart: Chart, value: number) {
  const valueLabel = chart.radarContainer.createChild(am4core.Label);
  valueLabel.isMeasured = false;
  valueLabel.fontSize = '2em';
  valueLabel.fontWeight = '300';
  valueLabel.horizontalCenter = 'middle';
  valueLabel.verticalCenter = 'bottom';
  valueLabel.text = value.toString();
  return valueLabel;
}

function createChartTitle(chart: Chart, theme: Theme) {
  const title = utilsCreateChartTitle(theme);
  const subTitle = utilsCreateChartSubTitle(theme);

  chart.titles.push(subTitle);
  chart.titles.push(title);
  return { title, subTitle };
}
