import {
  BlackbaudMappings,
  Column,
  Container,
  ErrorIndicator,
  Expanded,
  InsightsButton,
  LoadingObservablePresenter,
  Row,
  Scroll,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Dialog, DialogContent, DialogTitle, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface BlackbaudMappingsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  externalAccountId: string;
}

export const BlackbaudMappingsDialog = observer((props: BlackbaudMappingsDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style, configId, externalAccountId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createBlackbaudMappings(configId, externalAccountId, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog sx={sx} open={true} className={className} style={style} fullWidth maxWidth="md">
        <DialogTitle>{strings.blackbaudMappings}</DialogTitle>
        <DialogContent>
          <LoadingObservablePresenter
            data={viewModel.mappings}
            loadingMessage={strings.loadingBlackbaudMessage}
            render={(data) => (
              <Scroll direction="y">
                <Container sx={{ p: 0.5 }}>
                  <Column>
                    <Expanded>
                      <BlackbaudMappings viewModel={data} />
                    </Expanded>
                  </Column>
                </Container>
              </Scroll>
            )}
            renderError={() => (
              <Column>
                <ErrorIndicator size="normal" message={strings.loadingError} />
                <Row horizontalContentAlignment="right">
                  <InsightsButton isDefault onClick={() => onCancel!()}>
                    {strings.cancelButton}
                  </InsightsButton>
                </Row>
              </Column>
            )}
          />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
});
