import {
  ObservablePresenter,
  SchoolDayHeader,
  SchoolWeekHeader,
  SectionName,
  StudentTasksThresholdTable,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { WorkloadManagerInfo } from '@insights/viewmodels';
import {
  Box,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider
} from '@mui/material';
import { SchoolDay } from '@shared/models/calendar';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface WorkloadManagerByGradeDetailProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  gradeLevel: string;
  gradeLevelStudentCount: number;
  fromDay: SchoolDay;
  toDay: SchoolDay;
  includeExamOnly: boolean;
  studentIdsAtThreshold: string[];
  studentIdsOverThreshold: string[];
}

export const WorkloadManagerByGradeDetail = observer((props: WorkloadManagerByGradeDetailProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const {
    sx = [],
    className,
    style,
    fromDay,
    toDay,
    gradeLevel,
    gradeLevelStudentCount,
    studentIdsOverThreshold,
    studentIdsAtThreshold,
    onCancel,
    onSuccess,
    includeExamOnly,
    configId
  } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createWorkloadManagerByGradeDetail(
      configId,
      gradeLevel,
      gradeLevelStudentCount,
      fromDay,
      toDay,
      includeExamOnly,
      studentIdsAtThreshold,
      studentIdsOverThreshold,
      onSuccess!,
      onCancel!
    )
  );
  const isWeek = !fromDay.day.isSame(toDay.day);

  function renderTable(conflictsInfo: WorkloadManagerInfo, overloaded: boolean) {
    const header = overloaded ? strings.overloadedStudents : strings.studentsAtThreshold;

    return (
      <StudentTasksThresholdTable
        sx={{ height: '100%', width: '100%', mb: 4 }}
        header={header}
        conflictsInfo={conflictsInfo}
        studentsAreOverloaded={overloaded}
      />
    );
  }

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        onClose={() => viewModel.close()}
        scroll="paper"
        className={className}
        style={style}
      >
        <DialogTitle>
          {/* Ensure the overflow is visible so that the badge is displayed */}
          <Box display="flex" flexDirection="row" alignItems="center" overflow="visible">
            <SectionName
              color="darker-bmgray"
              title={viewModel.gradeLevel}
              subInformation1={strings.localizedNumberOfStudents(viewModel.gradeLevelStudentCount)}
              size="medium"
            />

            <Box flex={1} />

            {!isWeek && <SchoolDayHeader schoolDay={fromDay} displayType="page-header" />}
            {isWeek && <SchoolWeekHeader schoolDay={fromDay} displayType="page-header" />}
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <ObservablePresenter
            sx={{
              minHeight: 100,

              // This is to reset the bottom margin of the last card
              '& >:last-child': {
                marginBottom: 2
              }
            }}
            data={viewModel.data}
            render={(data) => (
              <Box
                sx={{
                  minHeight: 100,

                  // This is to reset the bottom margin of the last card
                  '& >:last-child': {
                    marginBottom: 2
                  }
                }}
              >
                {(data.overThresholdConflicts?.conflicts.length ?? 0) > 0 &&
                  renderTable(data.overThresholdConflicts!, true)}
                {(data.atThresholdConflicts?.conflicts.length ?? 0) > 0 &&
                  renderTable(data.atThresholdConflicts!, false)}
              </Box>
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => viewModel.close()}>
            {strings.close}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
