import * as SPB from '@buf/studyo_studyo.bufbuild_es/studyo/services/schools_pb';
import { Account, AccountSummary } from '@buf/studyo_studyo.bufbuild_es/studyo/type_account_pb';
import {
  CourseOccurrenceConfiguration_Customization,
  SchoolYearConfiguration,
  SchoolYearConfigurationSummary
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { Schools } from '@buf/studyo_studyo.connectrpc_es/studyo/services/schools_connect';
import { MoveCourseOccurrencesParamsModel } from '@shared/models/calendar';
import {
  protobufFromConfigType,
  protobufFromMoveCourseOccurrencesDirection
} from '@shared/models/types/EnumConversion';
import { SchoolTransport, SyncTokenResult } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class SchoolGrpcTransport extends BaseGrpcTransport implements SchoolTransport {
  async moveCourseOccurrences(params: MoveCourseOccurrencesParamsModel) {
    console.log('Moving course occurrences with params:', params);

    const request = new SPB.MoveCourseOccurrencesRequest();
    request.configId = params.configId;
    request.sectionId = params.sectionId;
    request.startOrdinal = params.startOrdinal;
    request.moveDirection = protobufFromMoveCourseOccurrencesDirection(params.moveDirection);
    request.untilDay = params.untilDay != null ? params.untilDay.asPB : undefined;
    request.moveTitles = params.moveTitles;
    request.moveTasks = params.moveTasks;
    request.moveNotes = params.moveNotes;

    await this.performRequest(Schools, Schools.methods.moveCourseOccurrences, request);

    console.log('Successfully moved course occurrences with params:', params);

    return Promise.resolve();
  }

  async customizeCourseOccurrence(
    configId: string,
    sectionId: string,
    customization: CourseOccurrenceConfiguration_Customization
  ) {
    console.log(`Customizing occurrence [${customization.ordinal}] for section id [${sectionId}]`);

    const request = new SPB.CustomizeCourseOccurrencesRequest();
    request.configId = configId;
    request.sectionId = sectionId;
    request.customizations = [customization];

    await this.performRequest(Schools, Schools.methods.customizeCourseOccurrences, request);

    console.log(`Successfully customized occurrence [${customization.ordinal}] for section id [${sectionId}]`);

    return Promise.resolve();
  }

  async fetchConfig(
    configId: string,
    anonymize: boolean,
    syncToken?: string
  ): Promise<SchoolYearConfiguration | undefined> {
    console.log(`Fetching configuration with parameters: [configId: ${configId}]...`);

    const request = new SPB.GetConfigRequest();
    request.configId = configId;
    request.anonymize = anonymize;

    if (syncToken != null) {
      request.syncToken = syncToken;
    }

    const response = await this.performRequest(Schools, Schools.methods.getConfig, request);

    console.log(`Successfully fetched configuration with parameters: [configId: ${configId}].`);

    return response.config;
  }

  async fetchConfigSummary(configId: string, anonymize: boolean): Promise<SchoolYearConfigurationSummary> {
    console.log(`Fetching configuration summary with parameters: [configId: ${configId}]...`);

    const request = new SPB.GetConfigSummaryRequest();
    request.configId = configId;
    request.anonymize = anonymize;

    const response = await this.performRequest(Schools, Schools.methods.getConfigSummary, request);

    console.log(`Successfully fetched configuration summary with parameters: [configId: ${configId}].`);

    const pbConfig = response.config;

    if (pbConfig == null) {
      throw new Error('Unexpected result from backend: A GetConfigSummary request did not return a configuration.');
    }

    return pbConfig;
  }

  async fetchConfigs(
    startYear: number,
    syncToken?: string
  ): Promise<SyncTokenResult<SchoolYearConfigurationSummary[]>> {
    console.log('Fetching configurations...');

    const request = new SPB.GetConfigsRequest();
    request.type = protobufFromConfigType('managed');
    request.startYear = startYear;

    if (syncToken != null) {
      request.syncToken = syncToken;
    }

    const response = await this.performRequest(Schools, Schools.methods.getConfigs, request);

    console.log('Successfully fetched configurations.');

    return {
      result: response.configs,
      syncToken: response.syncToken
    };
  }

  async validateConfig(configId: string): Promise<string[]> {
    console.log(`Validating configuration with parameters: [configId: ${configId}]...`);
    const request = new SPB.ValidateConfigRequest();
    request.config = { case: 'configId', value: configId };

    const response = await this.performRequest(Schools, Schools.methods.validateConfig, request);

    console.log('Successfully validated configuration.');

    return response.validationErrors;
  }

  async fetchAccount(accountId: string, anonymize: boolean): Promise<Account | undefined> {
    console.log(`Fetching account with parameters: [accountId: ${accountId}, anonymize: ${anonymize}]...`);

    const request = new SPB.GetAccountRequest();
    request.accountId = accountId;
    request.anonymize = anonymize;

    const response = await this.performRequest(Schools, Schools.methods.getAccount, request);

    console.log(`Successfully fetched account with parameters: [accountId: ${accountId}, anonymize: ${anonymize}].`);

    return response.account;
  }

  async fetchAccountSummary(accountId: string, anonymize: boolean): Promise<AccountSummary | undefined> {
    console.log(`Fetching account summary with parameters: [accountId: ${accountId}, anonymize: ${anonymize}]...`);

    const request = new SPB.GetAccountSummaryRequest();
    request.accountId = accountId;
    request.anonymize = anonymize;

    const response = await this.performRequest(Schools, Schools.methods.getAccountSummary, request);

    console.log(
      `Successfully fetched account summary with parameters: [accountId: ${accountId}, anonymize: ${anonymize}].`
    );

    return response.account;
  }

  async fetchAccounts(configId: string, anonymize: boolean, syncToken?: string): Promise<SyncTokenResult<Account[]>> {
    console.log(
      `Fetching accounts with parameters: [configId: ${configId}, anonymize: ${anonymize}, syncToken: ${syncToken}]...`
    );

    const request = new SPB.GetAccountsRequest();
    request.configId = configId;
    request.anonymize = anonymize;

    if (syncToken != null) {
      request.syncToken = syncToken;
    }

    const response = await this.performRequest(Schools, Schools.methods.getAccounts, request);

    console.log(
      `Successfully fetched accounts with parameters: [configId: ${configId}, anonymize: ${anonymize}, syncToken: ${syncToken}].`
    );

    return {
      result: response.accounts,
      syncToken: response.syncToken
    };
  }

  async createOrUpdateConfig(config: SchoolYearConfiguration): Promise<SchoolYearConfiguration> {
    const id = config.id;
    console.log(id.length > 0 ? `Updating configuration [${id}]` : 'Creating new configuration');

    const request = new SPB.CreateOrUpdateConfigRequest();
    request.config = config;

    const response = await this.performRequest(Schools, Schools.methods.createOrUpdateConfig, request);

    const pbConfig = response.config;

    if (pbConfig == null) {
      throw new Error('Unexpected result from backend: A CreateOrUpdateConfig request did not return a configuration.');
    }

    return pbConfig;
  }

  async useOnboardingCode(code: string): Promise<SPB.UseOnboardingCodeResponse> {
    const request = new SPB.UseOnboardingCodeRequest();
    request.code = code;

    // Exceptionally, we're returning the response directly.
    return await this.performRequest(Schools, Schools.methods.useOnboardingCode, request);
  }

  async validateOnboardingCode(code: string) {
    const request = new SPB.ValidateOnboardingCodeRequest();
    request.code = code;

    // Exceptionally, we're returning the response directly.
    return await this.performRequest(Schools, Schools.methods.validateOnboardingCode, request);
  }

  async createOrUpdateAccount(account: Account): Promise<Account> {
    const id = account.id;
    console.log(id.length > 0 ? `Updating account [${id}]` : 'Creating new account');

    const request = new SPB.CreateOrUpdateAccountRequest();
    request.account = account;

    const response = await this.performRequest(Schools, Schools.methods.createOrUpdateAccount, request);

    const pbAccount = response.account;

    if (pbAccount == null) {
      throw new Error('Unexpected result from backend: A CreateOrUpdateAccount request did not return an account.');
    }

    return pbAccount;
  }

  async deleteAccount(account: Account): Promise<void> {
    const id = account.id;
    console.log(`Deleting account [${id}]`);

    const request = new SPB.DeleteAccountRequest();
    request.accountId = id;

    await this.performRequest(Schools, Schools.methods.deleteAccount, request);
  }

  async undeleteAccount(account: Account): Promise<void> {
    const id = account.id;
    console.log(`Undeleting account [${id}]`);

    const request = new SPB.UndeleteAccountRequest();
    request.accountId = id;

    await this.performRequest(Schools, Schools.methods.undeleteAccount, request);
  }

  async purgeDeletedAccounts(configId: string): Promise<number> {
    console.log(`Purging deleted accounts for [${configId}]`);

    const request = new SPB.PurgeDeletedAccountsRequest();
    request.configId = configId;

    const response = await this.performRequest(Schools, Schools.methods.purgeDeletedAccounts, request);

    return response.purgedAccountsCount;
  }

  async inviteParent(userId: string, email: string): Promise<void> {
    console.log(`Account [${userId}] attempting to invite parent email [${email}]`);

    const request = new SPB.InviteParentRequest();
    request.parentEmail = email;
    request.studentAccountId = userId;

    await this.performRequest(Schools, Schools.methods.inviteParent, request);
  }

  async invalidateCachedConfig(configId: string): Promise<void> {
    console.log(`Invalidating configuration [${configId}]`);

    const request = new SPB.InvalidateCachedConfigRequest();
    request.configId = configId;

    await this.performRequest(Schools, Schools.methods.invalidateCachedConfig, request);
  }
}
