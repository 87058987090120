export interface SupportStrings {
  title: string;
  subtitle: string;
  title_welcome: string;
  subtitle_welcome: string;
  watch_tutorials_button: string;
  read_documentation_button: string;
  get_some_support_button: string;
  back: string;
  enter: string;
  tutorialsURL: string;
  documentationURL: string;
  contactUsURL: string;
}

export const EnglishSupportStrings: SupportStrings = {
  title: "We're here to help!",
  subtitle: 'Pick the option that suits you best',
  title_welcome: 'Welcome!',
  subtitle_welcome:
    'You are now part of the Studyo family!\n\nIf you need any help, feel free to explore our various resources:',
  watch_tutorials_button: 'Watch some tutorials',
  read_documentation_button: 'Read the documentation',
  get_some_support_button: 'Get some support',
  back: 'Back to Studyo',
  enter: 'Start enjoying Studyo',
  tutorialsURL: 'https://studyo.co/tutorials/',
  documentationURL: 'https://studyo.co/support/#docs',
  contactUsURL: 'https://studyo.co/contact/'
};

export const FrenchSupportStrings: SupportStrings = {
  title: 'Nous pouvons vous aider!',
  subtitle: 'Choisissez l’option qui vous convient le mieux',
  title_welcome: 'Bienvenue!',
  subtitle_welcome:
    'Vous faites maintenant partie de la grande famille de Studyo!\n\nSi vous avez besoin d’aide, n’hésitez pas à consulter nos différentes ressources:',
  watch_tutorials_button: 'Regardez des tutoriels',
  read_documentation_button: 'Lire la documentation',
  get_some_support_button: 'Obtenir du support',
  back: 'Retour à Studyo',
  enter: 'Entrer dans Studyo',
  tutorialsURL: 'https://studyo.co/tutoriels/',
  documentationURL: 'https://studyo.co/fr/aide/#docs',
  contactUsURL: 'https://studyo.co/fr/contact-fr/'
};
