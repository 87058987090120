import {
  DashboardMetricViewsStrings,
  EnglishDashboardMetricViewsStrings,
  FrenchDashboardMetricViewsStrings
} from './DashboardMetricViews';
import {
  EnglishParentMetricViewsStrings,
  FrenchParentMetricViewsStrings,
  ParentMetricViewsStrings
} from './ParentMetricViews';
import {
  EnglishSectionMetricViewsStrings,
  FrenchSectionMetricViewsStrings,
  SectionMetricViewsStrings
} from './SectionMetricViews';
import {
  EnglishSessionsPerDayDialogStrings,
  FrenchSessionsPerDayDialogStrings,
  SessionsPerDayDialogStrings
} from './SessionsPerDayDialogStrings';
import {
  EnglishStudentDailyPlannerStatusDialogStrings,
  FrenchStudentDailyPlannerStatusDialogStrings,
  StudentDailyPlannerStatusDialogStrings
} from './StudentDailyPlannerStatusDialogStrings';
import {
  EnglishStudentMetricViewsStrings,
  FrenchStudentMetricViewsStrings,
  StudentMetricViewsStrings
} from './StudentMetricViews';
import {
  EnglishTeacherMetricViewsStrings,
  FrenchTeacherMetricViewsStrings,
  TeacherMetricViewsStrings
} from './TeacherMetricViews';
import {
  EnglishWorkloadMetricViewsStrings,
  FrenchWorkloadMetricViewsStrings,
  WorkloadMetricViewsStrings
} from './WorkloadMetricViews';

export interface MetricViewsStrings {
  dashboards: DashboardMetricViewsStrings;
  students: StudentMetricViewsStrings;
  teachers: TeacherMetricViewsStrings;
  parents: ParentMetricViewsStrings;
  sections: SectionMetricViewsStrings;
  workload: WorkloadMetricViewsStrings;
  sessionsPerDayDialog: SessionsPerDayDialogStrings;
  studentDailyPlannerStatusDialog: StudentDailyPlannerStatusDialogStrings;
}

export const EnglishMetricViewsStrings: MetricViewsStrings = {
  dashboards: EnglishDashboardMetricViewsStrings,
  parents: EnglishParentMetricViewsStrings,
  students: EnglishStudentMetricViewsStrings,
  teachers: EnglishTeacherMetricViewsStrings,
  sections: EnglishSectionMetricViewsStrings,
  workload: EnglishWorkloadMetricViewsStrings,
  sessionsPerDayDialog: EnglishSessionsPerDayDialogStrings,
  studentDailyPlannerStatusDialog: EnglishStudentDailyPlannerStatusDialogStrings
};

export const FrenchMetricViewsStrings: MetricViewsStrings = {
  dashboards: FrenchDashboardMetricViewsStrings,
  parents: FrenchParentMetricViewsStrings,
  students: FrenchStudentMetricViewsStrings,
  teachers: FrenchTeacherMetricViewsStrings,
  sections: FrenchSectionMetricViewsStrings,
  workload: FrenchWorkloadMetricViewsStrings,
  sessionsPerDayDialog: FrenchSessionsPerDayDialogStrings,
  studentDailyPlannerStatusDialog: FrenchStudentDailyPlannerStatusDialogStrings
};
