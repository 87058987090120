import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { BaseModel } from '../../Model';
import { commentEffectFromProtobuf, onboardingTextFormatFromProtobuf } from '../../types/EnumConversion';
import { OnboardingComment } from '../interfaces';

export class GrpcOnboardingComment extends BaseModel<PB.OnboardingComment> implements OnboardingComment {
  constructor(pb: PB.OnboardingComment) {
    super(pb);
  }

  get id() {
    return this._pb.id;
  }

  get processName() {
    return this._pb.processName;
  }

  get stepName() {
    return this._pb.stepName;
  }

  get configId() {
    return this._pb.configId;
  }

  get fromAccountId() {
    return this._pb.fromAccountId;
  }

  get fromUserId() {
    return this._pb.fromUserId;
  }

  get message() {
    return this._pb.message;
  }

  get format() {
    return onboardingTextFormatFromProtobuf(this._pb.format);
  }

  get effect() {
    return commentEffectFromProtobuf(this._pb.effect);
  }

  get isPrivate() {
    return this._pb.isPrivate;
  }

  get createdAt() {
    return this._pb.createdAt?.toDate();
  }
}
