import { ManageableRole } from '@insights/enums';
import { EditableAccountInfo } from '@insights/models';
import { AccountService, NavigationService } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { Role } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppEditableAccountsViewModel, EditableAccountsViewModel } from './EditableAccountsViewModel';

export interface EditableAccountsScreenViewModel {
  readonly configId: string;
  readonly manageableRole: ManageableRole;
  readonly data: IPromiseBasedObservable<EditableAccountsViewModel>;
  readonly shouldLimitAccess?: boolean;
}

export abstract class AppEditableAccountsScreenViewModel implements EditableAccountsScreenViewModel {
  protected constructor(
    protected readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    protected readonly _accountService: AccountService,
    protected readonly _navigationService: NavigationService,
    protected readonly _localizationService: LocalizationService,
    public readonly configId: string,
    public readonly manageableRole: ManageableRole,
    public readonly shouldLimitAccess?: boolean
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<EditableAccountsViewModel> {
    return fromPromise(this.loadData());
  }

  get roles(): Role[] {
    switch (this.manageableRole) {
      case 'teacher':
        return ['teacher'];
      case 'student':
        return ['student'];
      case 'parent':
        return ['parent'];
      case 'staff':
        return ['school-staff', 'studyo-staff'];
    }
  }

  protected abstract loadAccountInfos(accounts: AccountModel[]): Promise<EditableAccountInfo[]>;

  protected async getLimitedAccounts(): Promise<AccountModel[]> {
    // By default, for any given account section, we only see our own account of that kind.
    const accountIds = _.chain(this.roles)
      .map((role) => this._accountService.getAccountIdForConfigRole(this.configId, role))
      .compact()
      .value();

    return await this._schoolYearConfigurationStore.getAccountsForIds(this.configId, accountIds, false);
  }

  private async loadData(): Promise<EditableAccountsViewModel> {
    let accounts: AccountModel[];

    if (this.shouldLimitAccess === true && !this._accountService.isAllowed(['super-admin', 'admin'])) {
      accounts = await this.getLimitedAccounts();
    } else {
      accounts = await this._schoolYearConfigurationStore.getAccountsForRoles(this.configId, this.roles, true);
    }

    const accountInfos = await this.loadAccountInfos(accounts);

    return new AppEditableAccountsViewModel(
      this._accountService,
      this._navigationService,
      this._localizationService,
      this.configId,
      accountInfos,
      this.manageableRole,
      this.roles
    );
  }
}
