import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Checkbox,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import { ImportSession } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ImportSessionAddOrEditConcatenationDialogProps extends DialogResult<ImportSession> {
  sx?: SxProps;
  configId: string;
  session: ImportSession;
  concatenationLabel?: string;
}

export const ImportSessionAddOrEditConcatenationDialog = observer(
  (props: ImportSessionAddOrEditConcatenationDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { sx = [], configId, concatenationLabel, session, onSuccess, onCancel } = props;
    const strings = localizationService.localizedStrings.insights.views.imports;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createImportSessionAddOrEditConcatenation(
        configId,
        session,
        concatenationLabel ?? '',
        onSuccess!,
        onCancel!
      )
    );

    return (
      <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
        <Dialog sx={sx} open={true} maxWidth="sm" fullWidth onClose={() => viewModel.cancel()}>
          <DialogTitle>{strings.addConcatenationTitle}</DialogTitle>

          <DialogContent dividers>
            <TextField
              sx={{ mb: 1 }}
              label={strings.labelLabel}
              slotProps={{ input: { inputProps: { maxLength: 1 } } }}
              autoFocus
              value={viewModel.label}
              helperText={viewModel.labelError}
              error={viewModel.labelError.length > 0}
              onChange={(e) => (viewModel.label = e.target.value)}
            />
            <TextField
              sx={{ mb: 1 }}
              fullWidth
              label={strings.concatenationNameLabel}
              slotProps={{ input: { inputProps: { maxLength: 50 } } }}
              value={viewModel.name}
              onChange={(e) => (viewModel.name = e.target.value)}
            />
            <FormControl sx={{ mb: 1 }} fullWidth>
              <InputLabel htmlFor="source-labels-select">{strings.sourceLabelsLabel}</InputLabel>
              <Select
                multiple
                fullWidth
                value={viewModel.sourceLabels}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  }
                }}
                onChange={(e) => (viewModel.sourceLabels = e.target.value as string[])}
                renderValue={() => viewModel.sourceLabels.join(', ')}
                inputProps={{ id: 'source-labels-select' }}
              >
                {viewModel.availableSources.map((source) => (
                  <MenuItem key={`source-label-${source.label}`} value={source.label}>
                    <Checkbox size="small" checked={viewModel.sourceLabels.includes(source.label)} />
                    <Typography variant="body1">{`${source.label} - ${source.name} (${source.columnCount})`}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{ mb: 1 }}
              fullWidth
              label={strings.concatenatedColumnCountLabel}
              type="number"
              value={viewModel.columnCount}
              onChange={(e) => (viewModel.columnCount = Number(e.target.value))}
            />
            <FormControlLabel
              sx={{ mb: 1 }}
              label={strings.useCommonColumnNamesLabel}
              control={
                <Checkbox
                  checked={viewModel.useColumnNames}
                  onChange={(e) => (viewModel.useColumnNames = e.target.checked)}
                />
              }
            />
          </DialogContent>

          <DialogActions>
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => viewModel.cancel()}>
              {strings.cancel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isExecuting={viewModel.isExecuting}
              isDisabled={!viewModel.canSave}
              onClick={() => void viewModel.saveConcatenation()}
            >
              {viewModel.isNew ? strings.addButton : strings.saveButton}
            </InsightsButton>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
);
