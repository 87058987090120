import {
  LocalizedText as PBLocalizedText,
  OnboardingStepSummary as PBOnboardingStepSummary
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { computed, makeObservable } from 'mobx';
import {
  EditableModelEx,
  EditableStringProperty,
  EditableValuePropertyEx,
  FullyEditableListProperty
} from '../../editables';
import { OnboardingParticipantKind, OnboardingStatus } from '../../types';
import { protobufFromOnboardingParticipantKind, protobufFromOnboardingStatus } from '../../types/EnumConversion';
import { OnboardingStepSummary, OnboardingText } from '../interfaces';
import { EditableOnboardingText } from './EditableOnboardingText';

export class EditableOnboardingStepSummary
  extends EditableModelEx<PBOnboardingStepSummary>
  implements OnboardingStepSummary
{
  private _templateName: EditableStringProperty<PBOnboardingStepSummary>;
  private _status: EditableValuePropertyEx<OnboardingStatus, PBOnboardingStepSummary>;
  private _participants: EditableValuePropertyEx<OnboardingParticipantKind, PBOnboardingStepSummary>;
  private _title: FullyEditableListProperty<
    PBLocalizedText,
    OnboardingText,
    EditableOnboardingText,
    PBOnboardingStepSummary
  >;
  private _targetDays: EditableValuePropertyEx<number, PBOnboardingStepSummary>;
  private _clientId: EditableStringProperty<PBOnboardingStepSummary>;
  private _agentId: EditableStringProperty<PBOnboardingStepSummary>;
  private _isRepeatable: EditableValuePropertyEx<boolean, PBOnboardingStepSummary>;
  private _dependantStepName: EditableStringProperty<PBOnboardingStepSummary>;

  constructor(
    private readonly _originalStep: OnboardingStepSummary,
    isNew = false
  ) {
    super(_originalStep.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._templateName = new EditableStringProperty(
        _originalStep.templateName,
        (pb, value) => (pb.templateName = value),
        { trim: true }
      )),
      (this._status = new EditableValuePropertyEx(
        _originalStep.status,
        (pb, value) => (pb.status = protobufFromOnboardingStatus(value))
      )),
      (this._participants = new EditableValuePropertyEx(
        _originalStep.participants,
        (pb, value) => (pb.participantKind = protobufFromOnboardingParticipantKind(value))
      )),
      (this._title = new FullyEditableListProperty(
        _originalStep.title.map((t) => new EditableOnboardingText(t)),
        (pb, values) => (pb.localizedTitles = values)
      )),
      (this._targetDays = new EditableValuePropertyEx(
        _originalStep.targetDays,
        (pb, value) => (pb.targetDays = value)
      )),
      (this._clientId = new EditableStringProperty(_originalStep.clientId, (pb, value) => (pb.clientId = value))),
      (this._agentId = new EditableStringProperty(_originalStep.agentId, (pb, value) => (pb.agentId = value))),
      (this._isRepeatable = new EditableValuePropertyEx(
        _originalStep.isRepeatable,
        (pb, value) => (pb.isRepeatable = value)
      )),
      (this._dependantStepName = new EditableStringProperty(
        _originalStep.dependantStepName,
        (pb, value) => (pb.dependantStepName = value),
        { trim: true }
      ))
    ]);
  }

  get id(): string {
    return this._originalStep.id;
  }

  get configId(): string {
    return this._originalStep.configId;
  }

  @computed
  get templateName(): string {
    return this._templateName.value;
  }

  set templateName(value: string) {
    this._templateName.value = value;
  }

  @computed
  get status(): OnboardingStatus {
    return this._status.value;
  }

  set status(value: OnboardingStatus) {
    this._status.value = value;
  }

  @computed
  get participants(): OnboardingParticipantKind {
    return this._participants.value;
  }

  set participants(value: OnboardingParticipantKind) {
    this._participants.value = value;
  }

  @computed
  get title(): OnboardingText[] {
    return this._title.values;
  }

  @computed
  get editableTitle(): EditableOnboardingText[] {
    return this._title.values;
  }

  @computed
  get targetDays(): number {
    return this._targetDays.value;
  }

  set targetDays(value: number) {
    this._targetDays.value = value;
  }

  @computed
  get clientId(): string {
    return this._clientId.value;
  }

  set clientId(value: string) {
    this._clientId.value = value;
  }

  @computed
  get agentId(): string {
    return this._agentId.value;
  }

  set agentId(value: string) {
    this._agentId.value = value;
  }

  get followerIds(): string[] {
    return this._originalStep.followerIds;
  }

  @computed
  get isRepeatable(): boolean {
    return this._isRepeatable.value;
  }

  set isRepeatable(value: boolean) {
    this._isRepeatable.value = value;
  }

  @computed
  get dependantStepName(): string {
    return this._dependantStepName.value;
  }

  set dependantStepName(value: string) {
    this._dependantStepName.value = value;
  }

  get dependantQuestionName(): string {
    return this._originalStep.dependantQuestionName;
  }

  get dependantQuestionAnswer(): string {
    return this._originalStep.dependantQuestionAnswer;
  }

  get isDependantLocked(): boolean {
    return this._originalStep.isDependantLocked;
  }

  get questionNames(): string[] {
    return this._originalStep.questionNames;
  }

  get isCustomized(): boolean {
    return this._originalStep.isCustomized;
  }

  get targetDate(): Date | undefined {
    return this._originalStep.targetDate;
  }

  get isBlocked(): boolean {
    return this._originalStep.isBlocked;
  }

  get isForcedVisible(): boolean {
    return this._originalStep.isForcedVisible;
  }
}
