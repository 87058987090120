import { InsightsButton, ObservablePresenter, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider,
  Typography
} from '@mui/material';
import { DialogResult } from '@shared/services';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface OnboardingHistoryDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  className?: string;
  style?: CSSProperties;
}

export const OnboardingHistoryDialog = observer((props: OnboardingHistoryDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx = [], configId, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const dateFormat = localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createOnboardingHistoryDialog(configId, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="md"
        fullWidth={true}
        onClose={() => viewModel.close()}
      >
        <DialogTitle>{strings.addStepTitle}</DialogTitle>
        <DialogContent>
          <ObservablePresenter
            data={viewModel.data}
            render={(data) => (
              <Box>
                {data.entries.map((h, index) => (
                  <Box key={`history-entry-${index}`}>
                    <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.secondary }}>
                      {format(h.createdAt, dateFormat)}
                    </Typography>
                    <Typography>{h.description}</Typography>
                  </Box>
                ))}
              </Box>
            )}
          />
        </DialogContent>
        <DialogActions>
          <InsightsButton isDefault onClick={() => viewModel.close()}>
            {strings.okLabel}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
