import { SchoolYearConfiguration_SchedulePeriod as PBSchedulePeriod } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableStringProperty, EditableTimePropertyEx, EditableValuePropertyEx } from '../editables';
import { PeriodKind, Time } from '../types';
import { protobufFromPeriodKind } from '../types/EnumConversion';
import { SchedulePeriod, SchedulePeriodModel } from './SchedulePeriod';

export class EditableSchedulePeriod extends EditableModelEx<PBSchedulePeriod> implements SchedulePeriodModel {
  private _tag: EditableStringProperty<PBSchedulePeriod>;
  private _startTime: EditableTimePropertyEx<PBSchedulePeriod>;
  private _endTime: EditableTimePropertyEx<PBSchedulePeriod>;
  private _kind: EditableValuePropertyEx<PeriodKind, PBSchedulePeriod>;
  private _skipOccurenceCount: EditableValuePropertyEx<boolean, PBSchedulePeriod>;

  static createNew(tag: string, startTime: Time): EditableSchedulePeriod {
    return EditableSchedulePeriod.create(
      tag,
      startTime,
      Time.create({
        hour: Math.min(23, startTime.hour + 1),
        minute: startTime.hour < 23 ? startTime.minute : 59
      }),
      'class'
    );
  }

  static create(tag: string, startTime: Time, endTime: Time, kind: PeriodKind) {
    const pb = new PBSchedulePeriod();
    pb.tag = tag;
    pb.startTime = startTime.asPB;
    pb.endTime = endTime.asPB;
    pb.kind = protobufFromPeriodKind(kind);

    return new EditableSchedulePeriod(new SchedulePeriod(pb), true);
  }

  constructor(originalSchedulePeriod: SchedulePeriodModel, isNew = false) {
    super(originalSchedulePeriod.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._tag = new EditableStringProperty(originalSchedulePeriod.tag, (pb, value) => (pb.tag = value), {
        trim: true
      })),
      (this._startTime = new EditableTimePropertyEx(
        originalSchedulePeriod.startTime,
        (pb, value) => (pb.startTime = value.asPB)
      )),
      (this._endTime = new EditableTimePropertyEx(
        originalSchedulePeriod.endTime,
        (pb, value) => (pb.endTime = value.asPB)
      )),
      (this._kind = new EditableValuePropertyEx(
        originalSchedulePeriod.kind,
        (pb, value) => (pb.kind = protobufFromPeriodKind(value))
      )),
      (this._skipOccurenceCount = new EditableValuePropertyEx(
        originalSchedulePeriod.skipOccurrenceCount,
        (pb, value) => (pb.skipOccurrenceCount = value)
      ))
    ]);
  }

  @computed
  get tag() {
    return this._tag.value;
  }

  set tag(value: string) {
    this._tag.value = value;
  }

  @computed
  get startTime() {
    return this._startTime.value;
  }

  set startTime(value: Time) {
    this._startTime.value = value;
  }

  @computed
  get endTime() {
    return this._endTime.value;
  }

  set endTime(value: Time) {
    this._endTime.value = value;
  }

  @computed
  get kind() {
    return this._kind.value;
  }

  set kind(value: PeriodKind) {
    this._kind.value = value;
  }

  @computed
  get skipOccurrenceCount(): boolean {
    return this._skipOccurenceCount.value;
  }

  set skipOccurrenceCount(value: boolean) {
    this._skipOccurenceCount.value = value;
  }

  clone(): EditableSchedulePeriod {
    const pb = this.toProtobuf();
    return new EditableSchedulePeriod(new SchedulePeriod(pb));
  }
}
