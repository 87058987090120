import { NavigationService } from '@insights/services';
import { Schema, SchemaImportOption, SourceData } from '@shared/models/import/interfaces';
import { ImporterStore } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  AppImportDataDetailsResultsViewModel,
  ImportDataDetailsResultsViewModel
} from './ImportDataDetailsResultsViewModel';

export interface ImportDataDetailsViewModel {
  readonly importData: IPromiseBasedObservable<ImportDataDetailsResultsViewModel>;
  isDestructive: boolean;
  readonly availableOptions: SchemaImportOption[];
  selectedOptionNames: string[];
}

export class AppImportDataDetailsViewModel implements ImportDataDetailsViewModel {
  @observable private _isDestructive = true;
  @observable private _selectedOptionNames: string[] = [];

  private readonly _rootSchema: string | undefined;
  private readonly _schemaSuffixes: string[] = [];

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _navigationService: NavigationService,
    private readonly _configId: string,
    private readonly _sessionId: string,
    private readonly _data: SourceData,
    private readonly _schemas: Schema[],
    defaultImportOptions: string[]
  ) {
    makeObservable(this);
    this._selectedOptionNames = defaultImportOptions;

    const schemaParts = _data.targetSchema.split(':').filter((p) => p.length > 0);

    if (schemaParts.length > 0) {
      this._rootSchema = schemaParts[0];
      this._schemaSuffixes = schemaParts.slice(1).map((p) => `:${p}`);
    }
  }

  @computed
  get importData(): IPromiseBasedObservable<ImportDataDetailsResultsViewModel> {
    return fromPromise(this.loadData(this._isDestructive, this._selectedOptionNames));
  }

  @computed
  get isDestructive() {
    return this._isDestructive;
  }

  set isDestructive(value: boolean) {
    this._isDestructive = value;
  }

  @computed // Evaluate once, doesn't change
  get availableOptions(): SchemaImportOption[] {
    const schema = this._schemas.find((s) => s.name === this._rootSchema);

    if (schema == null) {
      return [];
    }

    const suffixOptions = _.flattenDeep(
      schema.suffixGroups.map((g) =>
        g.suffixes.filter((s) => this._schemaSuffixes.includes(s.name)).map((s) => s.importOptions)
      )
    );

    return _.uniqBy(schema.importOptions.concat(suffixOptions), (o) => o.name);
  }

  @computed
  get selectedOptionNames(): string[] {
    return this._selectedOptionNames;
  }

  set selectedOptionNames(values: string[]) {
    this._selectedOptionNames = values;
  }

  private async loadData(isDestructive: boolean, options: string[]): Promise<ImportDataDetailsResultsViewModel> {
    let importData = await this._importSessionStore.importData(
      this._configId,
      this._sessionId,
      this._data,
      true,
      isDestructive,
      [],
      options
    );

    const hasFatalIncident = Boolean(_.find(importData.incidents, { severity: 'fatal-error' }));

    /**
     * When there are non-fatal incidents,
     * we call import data again since otherwise we won't have the updated rows old values
     */
    if (!hasFatalIncident && !importData.isSuccessful) {
      const allowedIncidentCodes = importData.incidents.map((incident) => incident.code);

      importData = await this._importSessionStore.importData(
        this._configId,
        this._sessionId,
        this._data,
        true,
        isDestructive,
        allowedIncidentCodes,
        options
      );
    }

    return new AppImportDataDetailsResultsViewModel(
      this._navigationService,
      this._importSessionStore,
      this._configId,
      this._sessionId,
      importData,
      this._data,
      isDestructive,
      this.availableOptions.filter((o) => this._selectedOptionNames.includes(o.name))
    );
  }
}
