import { sumBy } from 'lodash';
import { ReactElement, useLayoutEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { VirtualizedListRow, VirtualizedRow } from './VirtualizedListRow';

export interface ListSection {
  rowCount: number;
}

export interface VirtualizedListProps {
  className?: string;

  /**
   * The sections to display.
   */
  sections: ListSection[];

  /**
   * Get row height
   * @param row The row for which to get height. Optional
   */
  getRowHeight: (row?: VirtualizedRow) => number;

  /**
   * Render the section's header
   * @param section The section index
   */
  renderHeader: (section: number) => ReactElement;

  /**
   * Render section's row
   * @param section The section's index
   * @param row The row's index in the section
   */
  renderItem: (section: number, row: number) => ReactElement;
}

export const VirtualizedList = (props: VirtualizedListProps) => {
  const { className, getRowHeight, renderHeader, renderItem, sections } = props;
  const rowCount = sumBy(sections, (section) => section.rowCount + 1);

  const listRef = useRef<VariableSizeList>(null);

  // Force refresh the list since otherwise the items size won't be updated
  useLayoutEffect(() => {
    listRef.current?.resetAfterIndex(0, false);
  }, []);

  return (
    <AutoSizer className={className}>
      {(size) => (
        <VariableSizeList
          ref={listRef}
          width={size.width ?? 0}
          height={size.height ?? 0}
          itemCount={rowCount}
          itemSize={(index) => getRowHeight(getRowForIndex(index, sections))}
        >
          {(rowProps) => (
            <VirtualizedListRow
              {...rowProps}
              row={getRowForIndex(rowProps.index, sections)}
              renderHeader={renderHeader}
              renderItem={renderItem}
            />
          )}
        </VariableSizeList>
      )}
    </AutoSizer>
  );
};

/**
 * Get row at index.
 * @param index The index of the row.
 * @param sections The data of the list grouped by section.
 */
function getRowForIndex(index: number, sections: ListSection[]): VirtualizedRow | undefined {
  let row: VirtualizedRow | undefined;
  let previousRow = 0;
  let currentRow = 0;

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    // Header + elements of section;
    currentRow += section.rowCount + 1;

    // Row is in section.
    if (currentRow > index) {
      // Header
      if (index === previousRow) {
        row = { section: i, index: undefined, isHeader: true };
      } else {
        /* An element in section */
        const elementIndex = index - previousRow - 1;
        row = { section: i, index: elementIndex };
      }

      break;
    }

    previousRow = currentRow;
  }

  return row;
}
