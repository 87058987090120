import {
  SchoolYearConfiguration_Schedule as PBSchedule,
  SchoolYearConfiguration_SchedulePeriod as PBSchedulePeriod
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { action, computed, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import {
  EditableModelEx,
  EditableStringArrayProperty,
  EditableStringProperty,
  EditableValuePropertyEx,
  FullyEditableListProperty
} from '../editables';
import { Color } from '../types';
import { protobufFromColor } from '../types/EnumConversion';
import { EditableSchedulePeriod } from './EditableSchedulePeriod';
import { Schedule, ScheduleModel } from './Schedule';
import { SchedulePeriodModel } from './SchedulePeriod';

export class EditableSchedule extends EditableModelEx<PBSchedule> implements ScheduleModel {
  private _tag: EditableStringProperty<PBSchedule>;
  private _title: EditableStringProperty<PBSchedule>;
  private _periods: FullyEditableListProperty<
    PBSchedulePeriod,
    SchedulePeriodModel,
    EditableSchedulePeriod,
    PBSchedule
  >;
  private _color: EditableValuePropertyEx<Color, PBSchedule>;
  private _isHidden: EditableValuePropertyEx<boolean, PBSchedule>;
  private _isNoSchool: EditableValuePropertyEx<boolean, PBSchedule>;
  private _tags: EditableStringArrayProperty<PBSchedule>;

  static createNew(): EditableSchedule {
    const pb = new PBSchedule();
    pb.id = uuidv4();

    // All other values are left empty.
    return new EditableSchedule(new Schedule(pb), true);
  }

  static cloneAsNew(original: ScheduleModel, shouldChangeTitle = false): EditableSchedule {
    const pb = original.toProtobuf();
    pb.id = uuidv4();

    if (shouldChangeTitle) {
      pb.title = pb.title + ' COPY';
    }

    return new EditableSchedule(new Schedule(pb), true);
  }

  constructor(
    private readonly _originalSchedule: ScheduleModel,
    isNew = false
  ) {
    super(_originalSchedule.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._tag = new EditableStringProperty(_originalSchedule.tag, (pb, value) => (pb.tag = value), {
        trim: true
      })),
      (this._title = new EditableStringProperty(_originalSchedule.title, (pb, value) => (pb.title = value), {
        trim: true
      })),
      (this._periods = new FullyEditableListProperty(
        _originalSchedule.periods.map((period) => new EditableSchedulePeriod(period)),
        (pb, values) => (pb.periods = values)
      )),
      (this._color = new EditableValuePropertyEx(
        _originalSchedule.color,
        (pb, value) => (pb.color = protobufFromColor(value))
      )),
      (this._isHidden = new EditableValuePropertyEx(_originalSchedule.isHidden, (pb, value) => (pb.isHidden = value))),
      (this._isNoSchool = new EditableValuePropertyEx(
        _originalSchedule.isNoSchool,
        (pb, value) => (pb.isNoSchoolSchedule = value)
      )),
      (this._tags = new EditableStringArrayProperty(_originalSchedule.tags, (pb, values) => (pb.tags = values), {
        trim: true
      }))
    ]);
  }

  get id(): string {
    return this._originalSchedule.id;
  }

  @computed
  get tag(): string {
    return this._tag.value;
  }

  set tag(value: string) {
    this._tag.value = value;
  }

  @computed
  get tags(): string[] {
    return this._tags.value;
  }

  set tags(values: string[]) {
    this._tags.value = values;
  }

  @computed
  get title(): string {
    return this._title.value;
  }

  set title(value: string) {
    this._title.value = value;
  }

  @computed
  get periods(): SchedulePeriodModel[] {
    return this._periods.values;
  }

  @computed
  get editablePeriods(): EditableSchedulePeriod[] {
    return this._periods.values;
  }

  @computed
  get allEditablePeriods(): EditableSchedulePeriod[] {
    return this._periods.allValues;
  }

  @computed
  get color(): Color {
    return this._color.value;
  }

  set color(value: Color) {
    this._color.value = value;
  }

  @computed
  get isHidden(): boolean {
    return this._isHidden.value;
  }

  set isHidden(value: boolean) {
    this._isHidden.value = value;
  }

  @computed
  get isNoSchool(): boolean {
    return this._isNoSchool.value;
  }

  set isNoSchool(value: boolean) {
    this._isNoSchool.value = value;
  }

  @action
  addPeriod(period: EditableSchedulePeriod) {
    this._periods.addItem(period);
  }

  @action
  sortPeriods() {
    this._periods.sortBy((p) => p.startTime.asString);
  }

  clone() {
    const pb = this.toProtobuf();
    return new EditableSchedule(new Schedule(pb));
  }
}
