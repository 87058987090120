import {
  AccountOQMetrics,
  ConfigGlobalStats,
  DataPoint,
  SectionMetrics,
  StudentDailyTaskCounters
} from '@shared/models/metrics';
import { ActiveUsersPeriod } from '@shared/models/types';
import { flatMap, sortBy } from 'lodash';
import { IComputedValue, computed } from 'mobx';
import { MetricsTransport } from '../../transports';
import { MetricsStore } from '../interfaces';
import { AppBaseStore } from './AppBaseStore';

export class AppMetricsStore extends AppBaseStore implements MetricsStore {
  constructor(private readonly _transport: MetricsTransport) {
    super('AppMetricsStore');
  }

  getAccountOQMetrics(configId: string, accountIds: string[], includeHistory: boolean): Promise<AccountOQMetrics[]> {
    return this.getMemoizedAccountOQMetrics(configId, accountIds, includeHistory).get();
  }

  getDailyAccountSessions(configId: string, accountId: string): Promise<DataPoint[]> {
    return this.getMemoizedDailyAccountSessions(configId, accountId).get();
  }

  getActiveTeachersAny(configId: string, period: ActiveUsersPeriod): Promise<DataPoint[]> {
    return this.getMemoizedActiveTeachersAny(configId, period).get();
  }

  getActiveTeachersPublished(configId: string, period: ActiveUsersPeriod): Promise<DataPoint[]> {
    return this.getMemoizedActiveTeachersPublished(configId, period).get();
  }

  getConfigGlobalStats(configId: string): Promise<ConfigGlobalStats> {
    return this.getMemoizedConfigGlobalStats(configId).get();
  }

  getActiveStudentsAny(configId: string, period: ActiveUsersPeriod): Promise<DataPoint[]> {
    return this.getMemoizedActiveStudentsAny(configId, period).get();
  }

  getActiveStudentsInteracted(configId: string, period: ActiveUsersPeriod): Promise<DataPoint[]> {
    return this.getMemoizedActiveStudentsInteracted(configId, period).get();
  }

  getStudentDailyTaskMetrics(configId: string, accountId: string): Promise<StudentDailyTaskCounters[]> {
    return this.getMemoizedStudentDailyTaskMetrics(configId, accountId).get();
  }

  getSectionMetrics(configId: string, sectionIds: string[]): Promise<SectionMetrics[]> {
    return this.getMemoizedSectionMetrics(configId, sectionIds).get();
  }

  private getMemoizedAccountOQMetrics = this.memoize(
    (configId: string, accountIds: string[], includeHistory: boolean): IComputedValue<Promise<AccountOQMetrics[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbAccountOQMetrics = await this._transport.fetchAccountOQMetrics(
              configId,
              accountIds,
              includeHistory
            );

            return pbAccountOQMetrics.map((m) => new AccountOQMetrics(m));
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedDailyAccountSessions = this.memoize(
    (configId: string, accountId: string): IComputedValue<Promise<DataPoint[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbSessions = await this._transport.fetchAccountSessions(
              configId,
              accountId,
              'per-day',
              true,
              true,
              true
            );

            // Even though we filter, it's easier to work with models.
            const sessions = pbSessions.map((s) => new DataPoint(s));

            // Fill the blanks with the empty sessions and sort by date
            return sortBy(sessions, (session) => session.startOfPeriod);
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedActiveStudentsAny = this.memoize(
    (configId: string, period: ActiveUsersPeriod): IComputedValue<Promise<DataPoint[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbUsers = await this._transport.fetchActiveUsers(configId, 'students-any', period, true, true, true);
            return pbUsers.map((u) => new DataPoint(u));
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedActiveStudentsInteracted = this.memoize(
    (configId: string, period: ActiveUsersPeriod): IComputedValue<Promise<DataPoint[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbUsers = await this._transport.fetchActiveUsers(
              configId,
              'students-interacted',
              period,
              true,
              true,
              true
            );
            return pbUsers.map((u) => new DataPoint(u));
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedActiveTeachersAny = this.memoize(
    (configId: string, period: ActiveUsersPeriod): IComputedValue<Promise<DataPoint[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbUsers = await this._transport.fetchActiveUsers(configId, 'teachers-any', period, true, true, true);
            return pbUsers.map((u) => new DataPoint(u));
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedActiveTeachersPublished = this.memoize(
    (configId: string, period: ActiveUsersPeriod): IComputedValue<Promise<DataPoint[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbUsers = await this._transport.fetchActiveUsers(
              configId,
              'teachers-published',
              period,
              true,
              true,
              true
            );
            return pbUsers.map((u) => new DataPoint(u));
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedConfigGlobalStats = this.memoize(
    (configId: string): IComputedValue<Promise<ConfigGlobalStats>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbStats = await this._transport.fetchConfigGlobalStats(configId);
            return new ConfigGlobalStats(pbStats);
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedStudentDailyTaskMetrics = this.memoize(
    (configId: string, accountId: string): IComputedValue<Promise<StudentDailyTaskCounters[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbDailyTasks = await this._transport.fetchStudentDailyTaskMetrics(
              configId,
              [accountId],
              true,
              true,
              true
            );

            return flatMap(
              pbDailyTasks.map((pbDailyTask) =>
                pbDailyTask.dailyTaskCounters.map((c) => new StudentDailyTaskCounters(c))
              )
            );
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );

  private getMemoizedSectionMetrics = this.memoize(
    (configId: string, sectionIds: string[]): IComputedValue<Promise<SectionMetrics[]>> =>
      computed(
        () =>
          this.withInvalidate(async () => {
            const pbSectionMetrics = await this._transport.fetchSectionMetrics(configId, sectionIds);
            return pbSectionMetrics.map((pbSectionMetric) => new SectionMetrics(pbSectionMetric));
          }),
        // We want to keep the result of the fetch in cache even if there is no observer anymore
        { keepAlive: true }
      )
  );
}
