import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ImportSessionCreateScreenProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  className?: string;
  style?: CSSProperties;
}

export const ImportSessionCreateScreen = observer((props: ImportSessionCreateScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx = [], configId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createImportSessionCreate(configId, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="xs"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <DialogTitle>{strings.createSessionTitle}</DialogTitle>

          <DialogContent dividers>
            <Box height="100%" width="100%" display="flex" flexDirection="column" overflow="visible">
              <TextField
                sx={{ mb: 2 }}
                label={strings.sessionNameLabel}
                value={viewModel.name}
                fullWidth
                required
                autoFocus
                onChange={(e) => (viewModel.name = e.target.value)}
              />

              <TextField
                sx={{ mb: 2 }}
                label={strings.sessionDescriptionLabel}
                value={viewModel.description}
                multiline
                fullWidth
                onChange={(e) => (viewModel.description = e.target.value)}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            {viewModel.hasError && (
              <Typography
                flex={1}
                variant="caption"
                sx={{ color: (theme) => theme.palette.error.main }}
                style={{ textAlign: 'end' }}
              >
                {strings.createSessionError}
              </Typography>
            )}
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => void viewModel.cancel()}>
              {strings.createSessionCancelButtonLabel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={viewModel.name.length === 0}
              isExecuting={viewModel.isExecuting}
              onClick={() => void viewModel.create()}
            >
              {strings.createSessionCreateButtonLabel}
            </InsightsButton>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
});
