import { CanvasExternalAccountDetails, CanvasSubAccount, CanvasTerm } from '@shared/models/connectors/interfaces';
import { LocalizationService } from '@shared/resources/services';
import { CanvasConnectorStore } from '@shared/services/stores';
import { keyBy } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface CanvasAccountSetupViewModel extends ExternalAccountEditionViewModel {
  subAccountId: bigint;
  termIds: bigint[];
  readonly termNames: string;
  shouldDisplaySections: boolean;
  requiredCourseCodePrefix: string;

  readonly availableSubAccounts: CanvasSubAccount[];
  readonly availableTerms: CanvasTerm[];
}

export class AppCanvasAccountSetupViewModel
  extends BaseExternalAccountEditionViewModel
  implements CanvasAccountSetupViewModel
{
  @observable private _subAccountId?: bigint;
  @observable private _termIds?: bigint[];
  @observable private _shouldDisplaySections = true;
  @observable private _requiredCourseCodePrefix?: string;
  private _termsById: Record<number, CanvasTerm>;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _canvasStore: CanvasConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    public readonly availableSubAccounts: CanvasSubAccount[],
    public readonly availableTerms: CanvasTerm[],
    private readonly _originalDetails: CanvasExternalAccountDetails
  ) {
    super();
    makeObservable(this);
    this._termsById = keyBy(availableTerms, (t) => Number(t.id));
  }

  @computed
  get subAccountId() {
    return this._subAccountId ?? this._originalDetails.subAccountId;
  }

  set subAccountId(value: bigint) {
    this._subAccountId = value;
    this.onChange();
  }

  @computed
  get termIds() {
    return this._termIds ?? this._originalDetails.termIds;
  }

  set termIds(values: bigint[]) {
    this._termIds = values;
    this.onChange();
  }

  @computed
  get termNames() {
    return this.termIds.map((id) => this._termsById[Number(id)]?.name ?? '?').join(', ');
  }

  @computed
  get shouldDisplaySections() {
    return this._shouldDisplaySections ?? this._originalDetails.isShowingSections;
  }

  set shouldDisplaySections(value: boolean) {
    this._shouldDisplaySections = value;
    this.onChange();
  }

  @computed
  get requiredCourseCodePrefix() {
    return this._requiredCourseCodePrefix ?? this._originalDetails.requiredCourseCodePrefix;
  }

  set requiredCourseCodePrefix(value: string) {
    this._requiredCourseCodePrefix = value;
    this.onChange();
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    // We allow applying even when there are no changes, because it's a two-step edition.
    if (!this.hasChanges) {
      this._onSuccess();
      return;
    }

    this.beginApplying();

    try {
      await this._canvasStore.updateCanvasAccountSettings(
        this._configId,
        this._externalAccountId,
        this.subAccountId,
        this.termIds,
        this.shouldDisplaySections,
        this.requiredCourseCodePrefix,
        // Those are edited in the next screen
        this._originalDetails.mappings,
        this._originalDetails.isIgnoringUnmappedGroups
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._subAccountId = undefined;
    this._termIds = undefined;
    this._shouldDisplaySections = true;
    this._requiredCourseCodePrefix = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this.hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
