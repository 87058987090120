import { Label } from '@insights/components';
import { SchoolYearConfigurationScreenInfo } from '@insights/viewmodels';
import { Grid2, Stack, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, Fragment } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SchoolYearConfigurationAssessmentPlanningProps {
  sx?: SxProps;
  className?: string;
  data: SchoolYearConfigurationScreenInfo;
  style?: CSSProperties;
}

export const SchoolYearConfigurationAssessmentPlanning = observer(
  (props: SchoolYearConfigurationAssessmentPlanningProps) => {
    const { localizationService } = useInsightsServices();
    const { className, sx = [], data, style } = props;
    const strings = localizationService.localizedStrings.insights.views.managedGeneral;
    const configStrings = localizationService.localizedStrings.models.schoolYearConfig;

    return (
      <Stack sx={sx} className={className} style={style} spacing={2}>
        {/* Grade level source label */}
        <Stack>
          <Label>{strings.gradeLevelSourceAssessmentPlanningTitle}</Label>
          <Typography>
            {/* Assessment planning always uses the sections' grade level for now. */}
            {configStrings.gradeLevelSourceTitle('section')}
          </Typography>
        </Stack>

        <Grid2 container>
          {/* Grade Level Column Title */}
          <Grid2 size={{ xs: 4, xl: 3 }}>
            <Label>{strings.gradeLevelColumnTitle}</Label>
          </Grid2>
          {/* Assessment Date Column Title */}
          <Grid2 size={{ xs: 4, xl: 3 }}>
            <Label>{strings.assessmentPlanningVisibilityDateColumnTitle}</Label>
          </Grid2>
          <Grid2 size={{ xs: 4, xl: 6 }} />

          {data.visibilityDates.map((planning) => (
            <Fragment key={planning.gradeLevel}>
              {/* Grade Level */}
              <Grid2 size={{ xs: 4, xl: 3 }}>
                <Typography>{planning.gradeLevel}</Typography>
              </Grid2>
              {/* Visibility Day */}
              <Grid2 size={{ xs: 4, xl: 3 }}>
                <Typography>
                  {planning.visibilityDate.formattedString(
                    localizationService.localizedStrings.models.dateFormats.mediumUnabridged
                  )}
                </Typography>
              </Grid2>
              {/* Filler */}
              <Grid2 size={{ xs: 4, xl: 6 }} />
            </Fragment>
          ))}
        </Grid2>
      </Stack>
    );
  }
);
