import {
  ImportSessionSourceRowList,
  ImportSessionTransformationColumnList,
  ObservablePresenter,
  PageHeaderBar
} from '@insights/components';
import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ImportSessionTransformationDetailsScreenProps {
  sx?: SxProps;

  className?: string;
  style?: CSSProperties;
}

export const ImportSessionTransformationDetailsScreen = observer(
  (props: ImportSessionTransformationDetailsScreenProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { className, style, sx } = props;
    const strings = localizationService.localizedStrings.insights.views.imports;

    const params = useParams();
    const configId = params.configId ?? '';
    const sessionId = params.sessionId ?? '';
    const label = params.label ?? '';
    const viewModel = useMemo(
      () => viewModelFactory.createImportSessionTransformation(configId, sessionId, label),
      [configId, sessionId, label]
    );

    return (
      <Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
        <PageHeaderBar />

        <Box flex={1}>
          <ObservablePresenter
            sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
            data={viewModel.transformation}
            loadingMessage={strings.loadingTransformationMessage}
            errorMessage={strings.transformationErrorMessage}
            render={(data) => (
              <Box display="flex" flexDirection="column">
                {data.lookupData.map((lookup, index) => (
                  <ImportSessionSourceRowList
                    sx={{ mb: 1 }}
                    key={`indexed-data-${index}`}
                    rows={lookup.data?.rows ?? []}
                    columnNames={lookup.data?.columnNames ?? []}
                    title={strings.lookupSourceDataTitle(lookup.label, lookup.name, lookup.columnIndexes)}
                    tableStateKey={`TransformationDetailsIndexedRows-${lookup.name}`}
                  />
                ))}
                {data.mainSourceData && (
                  <ImportSessionSourceRowList
                    sx={{ mb: 1 }}
                    rows={data.mainSourceData.rows}
                    useNonPropFont={data.isMainSourceTextFile}
                    columnNames={data.mainSourceData.columnNames}
                    title={`${data.mainSourceData.label} - ${data.mainSourceName}`}
                    pageSize={5}
                    tableStateKey="TransformationDetailsSourceRows"
                  />
                )}
                <ImportSessionTransformationColumnList sx={{ mb: 1 }} transformation={data} />
                {data.targetData && (
                  <ImportSessionSourceRowList
                    sx={{ mb: 1 }}
                    rows={data.targetData.rows}
                    globalIncidents={data.targetData.incidents}
                    columnNames={data.targetData.columnNames}
                    title={strings.transformedData}
                    pageSize={5}
                    showIncidents
                    tableStateKey="TransformationDetailsTargetRows"
                  />
                )}
              </Box>
            )}
          />
        </Box>
      </Box>
    );
  }
);
