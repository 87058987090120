import { Box, styled, SxProps } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';

export interface ContainerProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  hideOverflow?: boolean;
}

export const Container = (props: ContainerProps) => {
  return (
    <Root
      sx={{ ...props.sx, overflow: props.hideOverflow ? 'hidden' : undefined }}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </Root>
  );
};

/**
 * Allows adjusting the `margin` and `padding` of the child element. This component
 * only takes 1 child and it will be forced to take all of the available space.
 */
const Root = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: 0,
  '& >:first-of-type': {
    flex: 1
  }
}));
