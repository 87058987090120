export interface RoutesStrings {
  blackbaudConsent: string;
  dashboard: string;
  home: string;
  login: string;
  logout: string;
  new: string;
  manageAutomatedImportDetails: string;
  manageAutomatedImportResultDetails: string;
  manageCalendar: string;
  manageExternalAccounts: string;
  manageExternalAssociations: string;
  manageFeatures: string;
  manageGeneral: string;
  manageImportData: string;
  manageImportSessionDetails: string;
  manageImportSessions: string;
  manageImportSessionSourceData: string;
  manageImportSessionTransformation: string;
  manageOnboarding: string;
  manageOnboardingProcess: string;
  manageOnboardingStep: string;
  manageParents: string;
  manageSections: string;
  manageStaff: string;
  manageStudents: string;
  manageTeachers: string;
  onboardingDashboard: string;
  onboardingDashboardComments: string;
  schoolConfigurations: string;
  sectionDetails: string;
  sections: string;
  settings: string;
  studentDetails: string;
  students: string;
  teacherDetails: string;
  teachers: string;
  teamsConsent: string;
  users: string;
  workload: string;
}

export const EnglishRoutesStrings: RoutesStrings = {
  blackbaudConsent: 'Blackbaud Sky Connector',
  dashboard: 'Dashboard',
  home: 'Home',
  login: 'Login',
  logout: 'Logout',
  new: 'New School',
  manageAutomatedImportDetails: 'Automated Import',
  manageAutomatedImportResultDetails: 'Results',
  manageCalendar: 'Calendar',
  manageExternalAccounts: 'External Accounts',
  manageExternalAssociations: 'External Associations',
  manageFeatures: 'Features',
  manageGeneral: 'General',
  manageImportData: 'Import Data',
  manageImportSessionDetails: 'Import Session',
  manageImportSessions: 'Import Sessions',
  manageImportSessionSourceData: 'Source Data',
  manageImportSessionTransformation: 'Import Transormation',
  manageOnboarding: 'Onboarding',
  manageOnboardingProcess: 'Onboarding',
  manageOnboardingStep: 'Step',
  manageParents: 'Parents',
  manageSections: 'Sections',
  manageStaff: 'Staff',
  manageStudents: 'Students',
  manageTeachers: 'Teachers',
  onboardingDashboard: 'Onboarding Dashboard',
  onboardingDashboardComments: 'Onboarding Comments',
  schoolConfigurations: 'School Configurations',
  sectionDetails: 'Section Details',
  sections: 'Sections',
  settings: 'Global Options',
  studentDetails: 'Student Details',
  students: 'Students',
  teacherDetails: 'Teacher Details',
  teachers: 'Teachers',
  teamsConsent: 'Teams Connector',
  users: 'Users',
  workload: 'Workload'
};

export const FrenchRoutesStrings: RoutesStrings = {
  blackbaudConsent: 'Connecteur Blackbaud Sky',
  dashboard: 'Tableau de bord',
  home: 'Accueil',
  login: 'Connexion',
  logout: 'Déconnexion',
  new: 'Nouvelle école',
  manageAutomatedImportDetails: 'Importation automatisée',
  manageAutomatedImportResultDetails: 'Résultats',
  manageCalendar: 'Calendrier',
  manageExternalAccounts: 'Comptes externes',
  manageExternalAssociations: 'Associations externes',
  manageFeatures: 'Fonctionnalités',
  manageGeneral: 'Général',
  manageImportData: 'Importer les données',
  manageImportSessionDetails: 'Importation',
  manageImportSessions: 'Importations',
  manageImportSessionSourceData: 'Données Source',
  manageImportSessionTransformation: 'Transformation',
  manageOnboarding: 'Embarquement',
  manageOnboardingProcess: 'Embarquement',
  manageOnboardingStep: 'Étape',
  manageParents: 'Parents',
  manageSections: 'Groupes-matière',
  manageStaff: 'Personnel',
  manageStudents: 'Élèves',
  manageTeachers: 'Enseignants',
  onboardingDashboard: 'Embarquement',
  onboardingDashboardComments: 'Commentaires d’embarquement',
  schoolConfigurations: 'Configurations d’école',
  sectionDetails: 'Détails du groupe-matière',
  sections: 'Groupes-matière',
  settings: 'Options globales',
  studentDetails: 'Détails de l’élève',
  students: 'Élèves',
  teacherDetails: 'Détails de l’enseignant',
  teachers: 'Enseignants',
  teamsConsent: 'Connecteur Teams',
  users: 'Utilisateurs',
  workload: 'Charge de travail'
};
