import { Incident as PBIncident } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { BaseModel } from '../../Model';
import { IncidentArea, IncidentSeverity } from '../../types';
import { incidentAreaFromProtobuf, incidentSeverityFromProtobuf } from '../../types/EnumConversion';
import { Incident } from '../interfaces';

export class GrpcIncident extends BaseModel<PBIncident> implements Incident {
  constructor(pb: PBIncident) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get area(): IncidentArea {
    return incidentAreaFromProtobuf(this._pb.area);
  }

  get severity(): IncidentSeverity {
    return incidentSeverityFromProtobuf(this._pb.severity);
  }

  get code(): string {
    return this._pb.code;
  }

  get message(): string {
    return this._pb.message;
  }

  get lineNumber(): number {
    return this._pb.lineNumber;
  }

  get line(): string {
    return this._pb.line;
  }

  get relatedIdentifiers(): string[] {
    return this._pb.relatedIdentifiers;
  }
}
