export interface OnboardingViewModelStrings {
  cannotViewSensitiveAnswerMessage: string;
  cannotViewSensitiveAnswerTitle: string;
  confirmDeleteCommentMessage: string;
  confirmDeleteCommentTitle: string;
  confirmDeleteProcessMessage: string;
  confirmDeleteProcessTitle: string;
  confirmDeleteQuestionForAllMessage: string;
  confirmDeleteQuestionMessage: string;
  confirmDeleteQuestionTitle: string;
  confirmDeleteStepForAllMessage: string;
  confirmDeleteStepMessage: string;
  confirmDeleteStepTitle: string;
  confirmRemoveFileMessage: string;
  confirmRemoveFileTitle: string;
  copyProcessTitle: string;
  copyQuestionTitle: string;
  copyStepTitle: string;
  deleteButtonLabel: string;
  noSensitiveAnswerAvailableMessage: string;
  noSensitiveAnswerAvailableTitle: string;
  renameProcessTitle: string;
  renameQuestionTitle: string;
  renameStepTitle: string;
  sensitiveAnswerMessage: string;
  sensitiveAnswerTitle: string;
  sensitivePreviousAnswersMessage: string;
  templateNameAlreadyExistsMessage: string;
  unexpectedError: string;
  unexpectedErrorTitle: string;
}

export const EnglishOnboardingViewModelStrings: OnboardingViewModelStrings = {
  cannotViewSensitiveAnswerMessage:
    'It seems you are not authorized to view this answer. If you require access to that answer, please describe your situation below in the comments section.',
  cannotViewSensitiveAnswerTitle: 'Access Denied',
  confirmDeleteCommentMessage: 'Are you sure you want to delete this comment?',
  confirmDeleteCommentTitle: 'Please confirm',
  confirmDeleteProcessMessage: 'Are you sure you want to delete this process?',
  confirmDeleteProcessTitle: 'Please confirm',
  confirmDeleteQuestionForAllMessage:
    'Are you sure you want to delete this question? This will remove it from all schools using the same step template.',
  confirmDeleteQuestionMessage: 'Are you sure you want to delete this question?',
  confirmDeleteQuestionTitle: 'Please confirm',
  confirmDeleteStepForAllMessage:
    'Are you sure you want to delete this step? This will remove it from all schools using the same process template.',
  confirmDeleteStepMessage: 'Are you sure you want to delete this step?',
  confirmDeleteStepTitle: 'Please confirm',
  confirmRemoveFileMessage: 'Are you sure you want to remove the file from this question?',
  confirmRemoveFileTitle: 'Please confirm',
  copyProcessTitle: 'Copy process template',
  copyQuestionTitle: 'Copy question template',
  copyStepTitle: 'Copy step template',
  deleteButtonLabel: 'Delete!',
  noSensitiveAnswerAvailableMessage: 'There are no sensitive answers available for this question kind.',
  noSensitiveAnswerAvailableTitle: 'Oops',
  renameProcessTitle: 'Rename process template',
  renameQuestionTitle: 'Rename question template',
  renameStepTitle: 'Rename step template',
  sensitiveAnswerMessage: 'The answer to this sensitive question is: ',
  sensitiveAnswerTitle: 'Answer',
  sensitivePreviousAnswersMessage: 'Previous answers were: ',
  templateNameAlreadyExistsMessage: 'This template name already exists.',
  unexpectedError: 'An unexpected error occurred: ',
  unexpectedErrorTitle: 'Oops'
};

export const FrenchOnboardingViewModelStrings: OnboardingViewModelStrings = {
  cannotViewSensitiveAnswerMessage:
    'Il semble que vous n’ayez pas accès à cette réponse. Si vous nécessitez un accès à cette réponse, veuillez nous expliquer pourquoi plus bas dans la section des commentaires.',
  cannotViewSensitiveAnswerTitle: 'Accès refusé',
  confirmDeleteCommentMessage: 'Êtes-vous certain de vouloir effacer ce commentaire?',
  confirmDeleteCommentTitle: 'Veuillez confirmer',
  confirmDeleteProcessMessage: 'Êtes-vous certain de vouloir effacer ce processus?',
  confirmDeleteProcessTitle: 'Veuillez confirmer',
  confirmDeleteQuestionForAllMessage:
    'Êtes-vous certain de vouloir effacer cette question? Ceci la retirera de toute école utilisant le même modèle d’étape.',
  confirmDeleteQuestionMessage: 'Êtes-vous certain de vouloir effacer cette question?',
  confirmDeleteQuestionTitle: 'Veuillez confirmer',
  confirmDeleteStepForAllMessage:
    'Êtes-vous certain de vouloir effacer cette étape? Ceci la retirera de toute école utilisant le même modèle de processus.',
  confirmDeleteStepMessage: 'Êtes-vous certain de vouloir effacer cette étape?',
  confirmDeleteStepTitle: 'Veuillez confirmer',
  confirmRemoveFileMessage: 'Êtes-vous certain de vouloir retirer le fichier de cette question?',
  confirmRemoveFileTitle: 'Veuillez confirmer',
  copyProcessTitle: 'Copier un modèle de processus',
  copyQuestionTitle: 'Copier un modèle de question',
  copyStepTitle: 'Copier un modèle d’étape',
  deleteButtonLabel: 'Effacer!',
  noSensitiveAnswerAvailableMessage: 'Il n’y a pas de réponse sensible disponible pour ce type de question.',
  noSensitiveAnswerAvailableTitle: 'Oups',
  renameProcessTitle: 'Renommer un modèle de processus',
  renameQuestionTitle: 'Renommer un modèle de question',
  renameStepTitle: 'Renommer un modèle d’étape',
  sensitiveAnswerMessage: 'La réponse à cette question sensible est: ',
  sensitiveAnswerTitle: 'Réponse',
  sensitivePreviousAnswersMessage: 'Les réponses précédentes étaient: ',
  templateNameAlreadyExistsMessage: 'Ce nom de modèle existe déjà.',
  unexpectedError: 'Une erreur inattendue est survenue: ',
  unexpectedErrorTitle: 'Oups'
};
