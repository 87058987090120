import { DialogCancelled, DialogResult, ModalElement, ModalService } from '@shared/services';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ComponentType } from 'react';

export class AppModalService implements ModalService {
  @observable private _modalStack: ModalElement[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  get modals(): ModalElement[] {
    return this._modalStack;
  }

  @computed
  get isDisplayingModal() {
    return this._modalStack.length > 0;
  }

  showModal<TResult, TProps>(Modal: ComponentType<TProps>, props: TProps): Promise<DialogCancelled | TResult> {
    return new Promise((resolve) => {
      const index = this._modalStack.length;
      const results: DialogResult<TResult> = {
        onSuccess: (value) => {
          this.closeModal(index);
          resolve(value!);
        },
        onCancel: () => {
          this.closeModal(index);
          resolve('cancelled');
        }
      };

      runInAction(() =>
        this._modalStack.push({
          element: <Modal {...props} {...results} />,
          results
        })
      );
    });
  }

  popModal() {
    const modal = this._modalStack.pop();
    if (modal != null) {
      modal.results.onCancel!();
    }
  }

  @action
  closeAllModals() {
    let modal: ModalElement | undefined;

    while ((modal = this._modalStack.pop()) != null) {
      modal.results.onCancel!();
    }
  }

  @action
  private closeModal(index: number) {
    if (index >= 0) {
      if (index != -1) {
        this._modalStack.splice(index, 1);
      }
    } else {
      this._modalStack.pop();
    }
  }
}
