import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import ExpandIcon from '@mui/icons-material/ArrowForwardIosSharp';
import SuccessIcon from '@mui/icons-material/Check';
import FailureIcon from '@mui/icons-material/Close';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  SxProps,
  Theme,
  ThemeProvider,
  Typography
} from '@mui/material';
import { AutomatedImport, AutomatedImportResult, ImportSession } from '@shared/models/import';
import { IncidentSeverity } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface AutomatedImportResultDetailsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  importSession: ImportSession;
  automatedImport: AutomatedImport;
  result: AutomatedImportResult;
}

export const AutomatedImportResultDetailsDialog = observer(
  ({
    sx = [],
    className,
    importSession,
    automatedImport,
    result,
    onCancel
  }: AutomatedImportResultDetailsDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const strings = localizationService.localizedStrings.insights.views.imports;
    const compStrings = localizationService.localizedStrings.insights.components.import;
    const dateStrings = localizationService.localizedStrings.models.dateFormats;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createAutomatedImportResultDetails(onCancel!, importSession, automatedImport, result)
    );

    return (
      <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
        <Dialog
          sx={sx}
          open={true}
          onClose={() => viewModel.cancel()}
          maxWidth="md"
          fullWidth={true}
          className={className}
        >
          <form
            noValidate
            onSubmit={(e) => {
              // This is to prevent the page from reloading on submit
              e.preventDefault();
              return false;
            }}
          >
            <DialogTitle>
              <Stack direction="row">
                {viewModel.result.isFullySuccessful ? (
                  <SuccessIcon color="success" sx={{ mr: 2 }} />
                ) : (
                  <FailureIcon color="error" sx={{ mr: 2 }} />
                )}
                <Typography>
                  {format(viewModel.result.startTime, dateStrings.mediumUnabridgedWithLongTime)} -{' '}
                  {format(viewModel.result.endTime, dateStrings.longTime)}
                </Typography>
              </Stack>
            </DialogTitle>

            <DialogContent dividers>
              {viewModel.transformationResults.length === 0 ? (
                <Typography>{compStrings.noAutomatedTransformationImportResults}</Typography>
              ) : (
                <Stack>
                  {viewModel.transformationResults.map((info, index) => (
                    <Box
                      key={`transformation-result-${index}`}
                      flexDirection="row"
                      display="flex"
                      alignItems="flex-start"
                      mb={1}
                    >
                      {info.result.isSuccessful ? (
                        <SuccessIcon color="success" sx={{ mr: 2 }} />
                      ) : (
                        <FailureIcon color="error" sx={{ mr: 2 }} />
                      )}
                      <Stack>
                        <Typography fontWeight={700} mr={2}>
                          {info.result.label} - {info.transformation?.name ?? strings.unknownTransformation}
                        </Typography>
                        {info.result.isSkipped ? (
                          <Typography>{compStrings.skippedAutomatedTransformation}</Typography>
                        ) : (
                          <Typography>
                            {format(info.result.startTime, dateStrings.mediumUnabridgedWithLongTime)} -{' '}
                            {format(info.result.endTime, dateStrings.longTime)}
                          </Typography>
                        )}
                        {info.result.globalIncidents.map((incident, incidentIndex) => (
                          <Stack key={`incident-${incidentIndex}`} direction="row">
                            <Typography
                              variant="caption"
                              sx={{ color: (theme) => colorFromIncidentSeverity(incident.severity, theme) }}
                              width={50}
                            >
                              {incident.code}
                            </Typography>
                            <Typography variant="caption">{incident.message}</Typography>
                          </Stack>
                        ))}
                        {info.result.entityListResults.map((er) => (
                          <Accordion disableGutters elevation={0} sx={{ mt: 1 }} key={`entity-list-${er.listKind}`}>
                            <StyledAccordionSummary>
                              <Typography variant="body2">
                                {strings.entityListKind(er.listKind)}:{' '}
                                {strings.entityCounts(er.entityCount, er.incidents.length)}
                              </Typography>
                            </StyledAccordionSummary>
                            {er.incidents.length > 0 && (
                              <AccordionDetails sx={{ py: 0 }}>
                                <Stack>
                                  {er.incidents.map((incident, incidentIndex) => (
                                    <Stack key={`entity-incident-${er.listKind}-${incidentIndex}`} direction="row">
                                      <Typography
                                        variant="caption"
                                        sx={{ color: (theme) => colorFromIncidentSeverity(incident.severity, theme) }}
                                        width={50}
                                      >
                                        {incident.code}
                                      </Typography>
                                      <Typography variant="caption">{incident.message}</Typography>
                                    </Stack>
                                  ))}
                                </Stack>
                              </AccordionDetails>
                            )}
                          </Accordion>
                        ))}
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              )}
            </DialogContent>
            <DialogActions>
              <InsightsButton onClick={() => viewModel.cancel()}>{strings.close}</InsightsButton>
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>
    );
  }
);

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<ExpandIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

function colorFromIncidentSeverity(severity: IncidentSeverity, theme: Theme): string {
  switch (severity) {
    case 'fatal-error':
    case 'error':
      return theme.palette.error.main;
    case 'warning':
      return theme.palette.warning.main;
    default:
      return theme.palette.text.disabled;
  }
}
