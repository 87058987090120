import { AskNameStrings, EnglishAskNameStrings, FrenchAskNameStrings } from './AskNameStrings';
import { ConfigLinksStrings, EnglishConfigLinksStrings, FrenchConfigLinksStrings } from './ConfigLinksStrings';
import {
  CourseSelectionStrings,
  EnglishCourseSelectionStrings,
  FrenchCourseSelectionStrings
} from './CourseSelectionStrings';
import { EnglishGearMenuShareStrings, FrenchGearMenuShareStrings, GearMenuShareStrings } from './GearMenuShareStrings';
import {
  EnglishImpersonateStudentSelectionStrings,
  FrenchImpersonateStudentSelectionStrings,
  ImpersonateStudentSelectionStrings
} from './ImpersonateStudentSelectionStrings';
import {
  EnglishOtherProfilesListStrings,
  FrenchOtherProfilesListStrings,
  OtherProfilesListStrings
} from './OtherProfilesListStrings';
import { EnglishPreferencesStrings, FrenchPreferencesStrings, PreferencesStrings } from './PreferencesStrings';
import { EnglishProfileEditStrings, FrenchProfileEditStrings, ProfileEditStrings } from './ProfileEditStrings';
import { EnglishProfileMenuStrings, FrenchProfileMenuStrings, ProfileMenuStrings } from './ProfileMenuStrings';

export interface SettingStrings {
  readonly askName: AskNameStrings;
  readonly courseSelection: CourseSelectionStrings;
  readonly gearMenuShare: GearMenuShareStrings;
  readonly impersonateStudentSelection: ImpersonateStudentSelectionStrings;
  readonly links: ConfigLinksStrings;
  readonly otherProfilesList: OtherProfilesListStrings;
  readonly preferences: PreferencesStrings;
  readonly profileMenu: ProfileMenuStrings;
  readonly profileEdit: ProfileEditStrings;
}

export const EnglishSettingStrings: SettingStrings = {
  askName: EnglishAskNameStrings,
  courseSelection: EnglishCourseSelectionStrings,
  gearMenuShare: EnglishGearMenuShareStrings,
  impersonateStudentSelection: EnglishImpersonateStudentSelectionStrings,
  links: EnglishConfigLinksStrings,
  otherProfilesList: EnglishOtherProfilesListStrings,
  preferences: EnglishPreferencesStrings,
  profileMenu: EnglishProfileMenuStrings,
  profileEdit: EnglishProfileEditStrings
};

export const FrenchSettingStrings: SettingStrings = {
  askName: FrenchAskNameStrings,
  courseSelection: FrenchCourseSelectionStrings,
  gearMenuShare: FrenchGearMenuShareStrings,
  impersonateStudentSelection: FrenchImpersonateStudentSelectionStrings,
  links: FrenchConfigLinksStrings,
  otherProfilesList: FrenchOtherProfilesListStrings,
  preferences: FrenchPreferencesStrings,
  profileMenu: FrenchProfileMenuStrings,
  profileEdit: FrenchProfileEditStrings
};
