import { css } from '@emotion/css';
import { CustomizationData, SchoolCalendarCycleDayViewModel } from '@insights/viewmodels';
import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import yellow from '@mui/material/colors/yellow';
import { isSxArray } from '@shared/components/utils';
import { Droppable } from '@shared/rxp/drag-drop';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';
import { Row } from '../layout';
import { CustomizationsList } from './CustomizationsList';

export interface SchoolCalendarCycleDayProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCalendarCycleDayViewModel;
}

export const SchoolCalendarCycleDay = observer((props: SchoolCalendarCycleDayProps) => {
  const { viewModel, sx = [], className } = props;
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const onDropCustomization = (data: unknown) => {
    setIsDraggingOver(false);
    void props.viewModel.applyCustomizations(data as CustomizationData);
  };

  const editCustomizations = () => props.viewModel.editCustomizations();

  const rootClassname = css(
    !viewModel.isEmptyPlaceholder
      ? {
          backgroundColor: viewModel.hasCustomization ? yellow[100] : 'white',
          borderColor: isDraggingOver ? grey[600] : grey[100],
          borderWidth: 1,
          borderStyle: 'solid'
        }
      : {}
  );

  const containerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    cursor: viewModel.isEmptyPlaceholder ? 'default' : 'pointer'
  };

  return (
    <Box
      className={clsx(className, rootClassname)}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Droppable
        acceptedType="customization"
        handleDrop={onDropCustomization}
        handleDragOver={() => setIsDraggingOver(true)}
        handleDragLeave={() => setIsDraggingOver(false)}
        sx={{ flex: 1 }}
      >
        <Tooltip title={viewModel.hasCustomization ? <CustomizationsList viewModel={viewModel} /> : ''}>
          <Box onClick={() => void editCustomizations()} style={containerStyle}>
            <Row horizontalContentAlignment="stretch">
              <Typography
                sx={{
                  flex: 1
                }}
              >
                {viewModel.label}
              </Typography>
            </Row>
          </Box>
        </Tooltip>
      </Droppable>
    </Box>
  );
});
