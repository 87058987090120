import { AccountUtils } from '@shared/components/utils';
import { AccountModel, SectionModel } from '@shared/models/config';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { cleanDiacritics } from '../../../utils';

export interface SelectableSectionInfo {
  section: SectionModel;
  teacher?: AccountModel;
}

export interface LoadingSectionStudentsEditionSectionSelectionViewModel {
  readonly configId: string;
  readonly sectionId: string;

  readonly data: IPromiseBasedObservable<SectionStudentsEditionSectionSelectionViewModel>;

  close(): void;
}

export interface SectionStudentsEditionSectionSelectionViewModel {
  searchText: string;
  selectedSection: SelectableSectionInfo | undefined;
  readonly results: SelectableSectionInfo[];
  import(): Promise<void>;
}

export class AppLoadingSectionStudentsEditionSectionSelectionViewModel
  implements LoadingSectionStudentsEditionSectionSelectionViewModel
{
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _onSuccess: (studentIds: string[]) => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly sectionId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<SectionStudentsEditionSectionSelectionViewModel> {
    return fromPromise(this.loadData());
  }

  close() {
    this._onCancel();
  }

  private async loadData() {
    const [sections, teachersById] = await Promise.all([
      this._schoolYearConfigurationStore.getSections(this.configId),
      this._schoolYearConfigurationStore.getTeachersById(this.configId, false)
    ]);
    const resolvedSections = _(sections)
      .filter((s) => s.id != this.sectionId)
      .sortBy([(s) => s.title, (s) => s.importId])
      .map((section) => ({
        section,
        teacher: teachersById[section.defaultTeacherId || section.teacherIds[0]]
      }))
      .value();

    return new AppSectionStudentsEditionSectionSelectionViewModel(
      this._schoolYearConfigurationStore,
      this._onSuccess,
      this.configId,
      resolvedSections,
      teachersById
    );
  }
}

export class AppSectionStudentsEditionSectionSelectionViewModel
  implements SectionStudentsEditionSectionSelectionViewModel
{
  @observable private _searchText = '';
  @observable private _selectedSection: SelectableSectionInfo | undefined;

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _onSuccess: (studentIds: string[]) => void,
    private readonly _configId: string,
    private readonly _sections: SelectableSectionInfo[],
    private readonly _teachersById: Record<string, AccountModel>
  ) {
    makeObservable(this);
  }

  @computed
  get searchText() {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
  }

  @computed
  get selectedSection() {
    return this._selectedSection;
  }

  set selectedSection(value: SelectableSectionInfo | undefined) {
    this._selectedSection = value;
  }

  @computed
  get results(): SelectableSectionInfo[] {
    const lowerSearchText = cleanDiacritics(this.searchText.toLowerCase());

    // Sections are already sorted by title and importId
    return this._sections.filter((s) => {
      const teachers = s.section.teacherIds.map((id) => this._teachersById[id]);

      return (
        cleanDiacritics(s.section.title.toLowerCase()).includes(lowerSearchText) ||
        cleanDiacritics(s.section.importId.toLowerCase()).includes(lowerSearchText) ||
        teachers.some((t) =>
          cleanDiacritics(AccountUtils.getDisplayFirstLastName(t).toLowerCase()).includes(lowerSearchText)
        )
      );
    });
  }

  async import() {
    if (this.selectedSection != null) {
      const students = await this._schoolYearConfigurationStore.getStudentsForSectionId(
        this._configId,
        this.selectedSection.section.id,
        false
      );
      const studentIds = students.map((s) => s.id);
      this._onSuccess(studentIds);
    }
  }
}
