import * as MB from '@buf/studyo_studyo.bufbuild_es/studyo/type_managebac_pb';
import { Memoize } from 'fast-typescript-memoize';
import { GrpcManageBacAssignmentKindMapping } from './GrpcManageBacAssignmentKindMapping';
import { ManageBacExternalAccountDetails } from './interfaces';

export class GrpcManageBacExternalAccountDetails implements ManageBacExternalAccountDetails {
  // We tolerate an undefined protobuf message.
  constructor(private readonly _pb: MB.AccountDetails | undefined) {}

  get serverUrl() {
    return this._pb?.serverUrl ?? '';
  }

  get authToken() {
    return this._pb?.authToken ?? '';
  }

  get termIds() {
    return this._pb?.termIds ?? [];
  }

  @Memoize()
  get kindMappings() {
    return this._pb?.assignmentKindMappings.map((m) => new GrpcManageBacAssignmentKindMapping(m)) ?? [];
  }

  get isIgnoringUnmappedKinds() {
    return this._pb?.isIgnoringUnmappedKinds ?? false;
  }
}
