import { Section_Schedule_Master as PBScheduleMaster } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableStringProperty } from '../editables';
import { SectionScheduleMaster, SectionScheduleMasterModel } from './SectionScheduleMaster';

export class EditableSectionScheduleMaster
  extends EditableModelEx<PBScheduleMaster>
  implements SectionScheduleMasterModel
{
  private _periodTag: EditableStringProperty<PBScheduleMaster>;
  private _scheduleTag: EditableStringProperty<PBScheduleMaster>;

  static createNew() {
    return new EditableSectionScheduleMaster(new SectionScheduleMaster(new PBScheduleMaster()), true);
  }

  constructor(_originalMaster: SectionScheduleMasterModel, isNew = false) {
    super(_originalMaster.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._periodTag = new EditableStringProperty(_originalMaster.periodTag, (pb, value) => (pb.periodTag = value), {
        trim: true
      })),
      (this._scheduleTag = new EditableStringProperty(
        _originalMaster.scheduleTag,
        (pb, value) => (pb.scheduleTag = value),
        {
          trim: true
        }
      ))
    ]);
  }

  @computed
  get periodTag(): string {
    return this._periodTag.value;
  }

  set periodTag(value: string) {
    this._periodTag.value = value;
  }

  @computed
  get scheduleTag(): string {
    return this._scheduleTag.value;
  }

  set scheduleTag(value: string) {
    this._scheduleTag.value = value;
  }

  protected createProtobuf() {
    return new PBScheduleMaster();
  }
}
