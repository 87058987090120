export interface SchoolCreationViewModelStrings {
  evening: string;
  missingSchoolNameErrorMessage: string;
  noSchool: string;
  notEnoughDaysPerCycleErrorMessage: string;
  notEnoughMinutesPerPeriodErrorMessage: string;
  notEnoughPeriodsPerDayErrorMessage: string;
  regular: string;
  startDateAfterEndDateErrorMessage: string;
  tooManyDaysPerCycleErrorMessage: string;
  tooManyMinutesPerPeriodErrorMessage: string;
  tooManyPeriodsPerDayErrorMessage: string;
  unexpectedErrorCreatingAccountMessage: (error: Error) => string;
  unexpectedErrorCreatingAccountTitle: string;
  weekend: string;
}

export const EnglishSchoolCreationViewModelStrings: SchoolCreationViewModelStrings = {
  evening: 'Evening',
  missingSchoolNameErrorMessage: 'You must provide a school name.',
  noSchool: 'No school',
  notEnoughDaysPerCycleErrorMessage: 'Invalid number of days per cycle.',
  notEnoughMinutesPerPeriodErrorMessage: 'Invalid number of minutes per period.',
  notEnoughPeriodsPerDayErrorMessage: 'Invalid number of periods per day.',
  regular: 'Regular',
  startDateAfterEndDateErrorMessage: 'The start date cannot be after the end date.',
  tooManyDaysPerCycleErrorMessage: 'The maximum number of days per cycle is 90.',
  tooManyMinutesPerPeriodErrorMessage: 'Too many minutes per period for the specified number of periods.',
  tooManyPeriodsPerDayErrorMessage: 'The maximum number of periods per day is 12.',
  unexpectedErrorCreatingAccountMessage: (error) =>
    `An unexpected error occurred while creating accounts in the new configuration. Not all selected accounts were created. The error is: ${error.message}`,
  unexpectedErrorCreatingAccountTitle: 'Oops',
  weekend: 'Weekend'
};

export const FrenchSchoolCreationViewModelStrings: SchoolCreationViewModelStrings = {
  evening: 'Soirée',
  missingSchoolNameErrorMessage: 'Vous devez fournir un nom pour l’école.',
  noSchool: 'Pas de cours',
  notEnoughDaysPerCycleErrorMessage: 'Nombre de jours-cycles invalide.',
  notEnoughMinutesPerPeriodErrorMessage: 'Nombre de minutes par période invalide.',
  notEnoughPeriodsPerDayErrorMessage: 'Nombre de périodes par jour invalide.',
  regular: 'Régulier',
  startDateAfterEndDateErrorMessage: 'La date de début ne peut être après la date de fin.',
  tooManyDaysPerCycleErrorMessage: 'Le nombre maximum de jours par cycle est de 90.',
  tooManyMinutesPerPeriodErrorMessage: 'Durée des périodes trop élevée pour le nombre de périodes.',
  tooManyPeriodsPerDayErrorMessage: 'Le nombre maximum de périodes par jour est de 12.',
  unexpectedErrorCreatingAccountMessage: (error) =>
    `Une erreur imprévue est survenue à la création d’une fiche dans la nouvelle école. Certaines fiches sélectionnées ne sont donc pas créées. L’erreur est: ${error.message}`,
  unexpectedErrorCreatingAccountTitle: 'Oups',
  weekend: 'Fin de semaine'
};
