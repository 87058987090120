import { computed, makeObservable } from 'mobx';
import { InsightsEnvironmentService } from './InsightsEnvironmentService';

export class WebEnvironmentService implements InsightsEnvironmentService {
  constructor() {
    makeObservable(this);
  }

  @computed
  get apiV3Url() {
    return window.STUDYO_ENV?.apiV3Url ?? '';
  }

  @computed
  get authClientId() {
    return window.STUDYO_ENV?.authClientId ?? '';
  }

  @computed
  get authServiceUrl() {
    return window.STUDYO_ENV?.authServiceUrl ?? '';
  }

  @computed
  get buildNumber() {
    return window.STUDYO_ENV?.buildNumber ?? '';
  }

  @computed
  get environmentName() {
    return window.STUDYO_ENV?.environmentName ?? '';
  }

  @computed
  get formattedVersionNumber() {
    let version = `${this.version}`;

    if (this.buildNumber !== '') {
      version += `.${this.buildNumber}`;
    }

    if (this.environmentName !== '') {
      version += `.${this.environmentName}`;
    }

    return version;
  }

  @computed
  get intercomAppId() {
    return window.STUDYO_ENV?.intercomAppId ?? '';
  }

  @computed
  get killSwitchUrl() {
    return window.STUDYO_ENV?.killSwitchUrl ?? '';
  }

  @computed
  get studyoUrl() {
    return window.STUDYO_ENV?.studyoUrl ?? '';
  }

  @computed
  get version() {
    return window.STUDYO_ENV?.version ?? '';
  }

  @computed
  get googleClientId() {
    return window.STUDYO_ENV?.googleClientId ?? '';
  }

  @computed
  get microsoftClientId() {
    return window.STUDYO_ENV?.microsoftClientId ?? '';
  }

  @computed
  get mixpanelToken() {
    return window.STUDYO_ENV?.mixpanelToken;
  }
}
