import { Stack, SxProps, Typography } from '@mui/material';
import { DayConfigurationModel } from '@shared/models/config';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface OccurrencesSummaryProps {
  sx?: SxProps;
  className?: string;
  occurrences: DayConfigurationModel[];
}

export const OccurrencesSummary = observer(({ sx = [], className, occurrences }: OccurrencesSummaryProps) => {
  if (occurrences.length === 0) {
    return undefined;
  }

  const days = occurrences.filter((o) => o.day != null).map((o) => o.day!);
  const cycleDays = occurrences.filter((o) => o.cycleDay != null).map((o) => o.cycleDay!);
  const daysOfWeek = occurrences.filter((o) => o.dayOfWeek != null).map((o) => o.dayOfWeek!);

  const { localizationService } = useInsightsServices();
  const modelStrings = localizationService.localizedStrings.models;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Stack sx={sx} className={className}>
      <Typography variant="caption">{strings.occurrencesLabel}</Typography>
      {days.length > 0 && (
        <Typography
          sx={{
            textOverflow: 'ellipsis'
          }}
        >
          {days.map((d) => d.formattedString(modelStrings.dateFormats.short)).join(', ')}
        </Typography>
      )}
      {cycleDays.length > 0 && (
        <Typography
          sx={{
            textOverflow: 'ellipsis'
          }}
        >
          {strings.cycleDaysEnumeration}
          {cycleDays.map((cd) => `${cd}`).join(', ')}
        </Typography>
      )}
      {daysOfWeek.length > 0 && (
        <Typography
          sx={{
            textOverflow: 'ellipsis'
          }}
        >
          {daysOfWeek.map((dow) => modelStrings.dayOfWeek.localizedDayOfWeek(dow)).join(', ')}
        </Typography>
      )}
    </Stack>
  );
});
