import { CourseOccurrence as PBCourseOccurrence } from '@buf/studyo_studyo.bufbuild_es/studyo/type_generator_pb';

export class CourseOccurrence {
  private _pb: PBCourseOccurrence;

  constructor(pb: PBCourseOccurrence) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  get sectionId(): string {
    return this._pb.sectionId;
  }

  get ordinal(): number {
    return this._pb.ordinal;
  }

  get normalizedOrdinal(): number {
    return this._pb.normalizedOrdinal;
  }

  get termOrdinal(): number {
    return this._pb.termOrdinal;
  }

  get termRemaining(): number {
    return this._pb.termRemaining;
  }

  get yearRemaining(): number {
    return this._pb.yearRemaining;
  }

  get skipped(): boolean {
    return this._pb.skipped;
  }

  get roomName(): string {
    return this._pb.roomName;
  }

  get customRoomName(): string {
    return this._pb.customRoomName;
  }

  get customTitle(): string {
    return this._pb.customTitle;
  }

  get teacherIds(): string[] {
    return this._pb.teacherIds;
  }

  //
  // Utility properties
  //

  get displayTitle(): string {
    return this.customTitle.length > 0
      ? this.customTitle
      : this.skipped
        ? 'X' // No need to be localized
        : `${this.normalizedOrdinal}`;
  }
}
