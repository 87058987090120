export interface ParentMetricViewsStrings {
  connectedParents: string;
  invitationsAccepted: string;
  invitedParents: string;
  loadingParentMessage: string;
  numberOfConnectedParents: string;
  parentErrorMessage: string;
  parents: string;
}

export const EnglishParentMetricViewsStrings: ParentMetricViewsStrings = {
  connectedParents: 'connected parents',
  invitationsAccepted: 'invitations accepted',
  invitedParents: 'invited parents',
  loadingParentMessage: 'Loading the parent accounts',
  numberOfConnectedParents: 'Number of connected parents',
  parentErrorMessage: 'An error occurred while loading the parent accounts. Please reload.',
  parents: 'Parents'
};

export const FrenchParentMetricViewsStrings: ParentMetricViewsStrings = {
  connectedParents: 'parents connectés',
  invitationsAccepted: 'invitations acceptées',
  invitedParents: 'parents invités',
  loadingParentMessage: 'Chargement des comptes parents',
  numberOfConnectedParents: 'Nombre de parents connectés',
  parentErrorMessage: 'Une erreur est survenue lors du chargement. Merci de recharger.',
  parents: 'Parents'
};
