import {
  FileEncoding as PBFileEncoding,
  FileKind as PBFileKind,
  SourceFile as PBSourceFile
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { action, computed, makeObservable } from 'mobx';
import {
  EditableLiveStringArrayProperty,
  EditableModelEx,
  EditableStringArrayProperty,
  EditableStringProperty,
  EditableValuePropertyEx
} from '../../editables';
import { FileEncoding, FileKind } from '../../types';
import { protobufFromImportSourceFileEncoding, protobufFromImportSourceFileKind } from '../../types/EnumConversion';
import { SourceFile } from '../interfaces';
import { GrpcSourceFile } from './GrpcSourceFile';

export class EditableSourceFile extends EditableModelEx<PBSourceFile> implements SourceFile {
  private readonly _name: EditableStringProperty<PBSourceFile>;
  private readonly _description: EditableStringProperty<PBSourceFile>;
  private readonly _label: EditableStringProperty<PBSourceFile>;
  private readonly _kind: EditableValuePropertyEx<FileKind, PBSourceFile>;
  private readonly _isVisible: EditableValuePropertyEx<boolean, PBSourceFile>;
  private readonly _hasHeader: EditableValuePropertyEx<boolean, PBSourceFile>;
  private readonly _orderedHeaders: EditableLiveStringArrayProperty<PBSourceFile>;
  private readonly _url: EditableStringProperty<PBSourceFile>;
  private readonly _previousUrls: EditableStringArrayProperty<PBSourceFile>;
  private readonly _expectedEncoding: EditableValuePropertyEx<FileEncoding, PBSourceFile>;
  private readonly _sheetName: EditableStringProperty<PBSourceFile>;
  private readonly _topRowsSkipped: EditableValuePropertyEx<number, PBSourceFile>;

  static createNew() {
    const pb = new PBSourceFile();
    pb.expectedEncoding = PBFileEncoding.UTF8;
    pb.hasHeader = true;
    pb.isVisible = true;
    pb.kind = PBFileKind.CommaSeparatedValue;

    return new EditableSourceFile(new GrpcSourceFile(pb), true);
  }

  constructor(
    private readonly _originalSourceFile: SourceFile,
    isNew = false
  ) {
    super(_originalSourceFile.toProtobuf(), isNew);

    makeObservable(this);

    this._name = this.addStringField(_originalSourceFile.name, (host, value) => (host.name = value), { trim: true });
    this._description = this.addStringField(
      _originalSourceFile.description,
      (host, value) => (host.description = value)
    );
    this._label = this.addStringField(_originalSourceFile.label, (host, value) => (host.label = value), { trim: true });
    this._kind = this.addValueField<FileKind>(
      _originalSourceFile.kind,
      (host, value) => (host.kind = protobufFromImportSourceFileKind(value))
    );
    this._isVisible = this.addValueField<boolean>(
      _originalSourceFile.isVisible,
      (host, value) => (host.isVisible = value)
    );
    this._hasHeader = this.addValueField<boolean>(
      _originalSourceFile.hasHeader,
      (host, value) => (host.hasHeader = value)
    );
    this._orderedHeaders = this.addLiveStringArrayField(
      _originalSourceFile.orderedHeaders,
      (host, values) => (host.orderedHeaders = values)
    );
    this._url = this.addStringField(_originalSourceFile.url, (host, value) => (host.url = value));
    this._previousUrls = this.addStringArrayField(
      _originalSourceFile.previousUrls,
      (host, values) => (host.previousUrls = values)
    );
    this._expectedEncoding = this.addValueField<FileEncoding>(
      _originalSourceFile.expectedEncoding,
      (host, value) => (host.expectedEncoding = protobufFromImportSourceFileEncoding(value))
    );
    this._sheetName = this.addStringField(_originalSourceFile.sheetName, (host, value) => (host.sheetName = value), {
      trim: true
    });
    this._topRowsSkipped = this.addValueField(
      _originalSourceFile.topRowsSkipped,
      (host, value) => (host.topRowsSkipped = value)
    );
  }

  get id() {
    return this._originalSourceFile.id;
  }

  @computed
  get name() {
    return this._name.value;
  }

  set name(value: string) {
    this._name.value = value;
  }

  @computed
  get description() {
    return this._description.value;
  }

  set description(value: string) {
    this._description.value = value;
  }

  @computed
  get label() {
    return this._label.value;
  }

  set label(value: string) {
    this._label.value = value;
  }

  @computed
  get kind() {
    return this._kind.value;
  }

  set kind(value: FileKind) {
    this._kind.value = value;
  }

  @computed
  get isVisible() {
    return this._isVisible.value;
  }

  set isVisible(value: boolean) {
    this._isVisible.value = value;
  }

  @computed
  get hasHeader() {
    return this._hasHeader.value;
  }

  set hasHeader(value: boolean) {
    this._hasHeader.value = value;
  }

  @computed
  get orderedHeaders() {
    return this._orderedHeaders.value;
  }

  set orderedHeaders(values: string[]) {
    this._orderedHeaders.value = values;
  }

  @action
  setOrderedHeaderByIndex(value: string, index: number): void {
    this._orderedHeaders.setItem(value, index);
  }

  @action
  moveOrderedHeader(oldIndex: number, newIndex: number): void {
    this._orderedHeaders.moveItem(oldIndex, newIndex);
  }

  @action
  removeOrderedHeader(index: number): void {
    this._orderedHeaders.removeItem(index);
  }

  @computed
  get url() {
    return this._url.value;
  }

  set url(value: string) {
    this._url.value = value;
  }

  @computed
  get previousUrls() {
    return this._previousUrls.value;
  }

  set previousUrls(values: string[]) {
    this._previousUrls.value = values;
  }

  @action
  addPreviousUrl(url: string) {
    this._previousUrls.value = this._previousUrls.value.concat(url);
  }

  @computed
  get expectedEncoding() {
    return this._expectedEncoding.value;
  }

  set expectedEncoding(value: FileEncoding) {
    this._expectedEncoding.value = value;
  }

  @computed
  get sheetName(): string {
    return this._sheetName.value;
  }

  set sheetName(value: string) {
    this._sheetName.value = value;
  }

  @computed
  get topRowsSkipped(): number {
    return this._topRowsSkipped.value;
  }

  set topRowsSkipped(value: number) {
    this._topRowsSkipped.value = value;
  }
}
