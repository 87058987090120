import { AccountSettings as PBAccountSettings } from '@buf/studyo_studyo.bufbuild_es/studyo/type_account_pb';
import { Memoize } from 'fast-typescript-memoize';
import { BaseModel, SerializableModel } from '../Model';
import { SectionColorSetting, SectionColorSettingModel } from './SectionColorSetting';

export interface AccountSettingsModel extends SerializableModel<PBAccountSettings> {
  readonly selectedSectionIds: string[];
  readonly sectionColors: SectionColorSettingModel[];
}

export class AccountSettings extends BaseModel<PBAccountSettings> implements AccountSettingsModel {
  constructor(pb: PBAccountSettings) {
    super(pb);
  }

  get selectedSectionIds(): string[] {
    return this._pb.selectedSectionIds;
  }

  @Memoize()
  get sectionColors(): SectionColorSettingModel[] {
    return this._pb.sectionColors.map((sc) => new SectionColorSetting(sc));
  }
}
