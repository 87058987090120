import { NavigationService } from '@insights/services';
import { DayOfWeek } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { action, computed, makeObservable } from 'mobx';
import { CustomizableViewModel } from './CustomizableViewModel';
import { CustomizationData } from './CustomizationData';
import { SchoolCalendarViewModel } from './SchoolCalendarViewModel';

export interface SchoolCalendarDayOfWeekViewModel extends CustomizableViewModel {
  readonly dayOfWeek: DayOfWeek;
}

export class AppSchoolCalendarDayOfWeekViewModel implements SchoolCalendarDayOfWeekViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    public readonly dayOfWeek: DayOfWeek,
    public readonly calendar: SchoolCalendarViewModel
  ) {
    makeObservable(this);
  }

  @computed
  get dayConfigurations() {
    return this.calendar.dayConfigurationsByDayOfWeek[this.dayOfWeek] || [];
  }

  @computed
  get hasCustomization() {
    return this.dayConfigurations.length > 0;
  }

  @computed
  get specialDaysById() {
    return this.calendar.specialDaysById;
  }

  @computed
  get schedulesById() {
    return this.calendar.schedulesById;
  }

  @computed
  get longTitle(): string {
    return this._localizationService.localizedStrings.models.dayOfWeek.localizedDayOfWeek(this.dayOfWeek);
  }

  async editCustomizations(): Promise<void> {
    await this._navigationService.navigateToEditSchoolCalendarDayCustomization(this);
  }

  @action
  async applyCustomizations(data: CustomizationData): Promise<void> {
    // It's never the case so far, but we could support multiple operations, like clear all and add this.
    if (data.clearSchedules) {
      await this.calendar.clearSchedulesFromDayOfWeek(this.dayOfWeek);
    }
    if (data.clearSpecialDays) {
      await this.calendar.clearSpecialDaysFromDayOfWeek(this.dayOfWeek);
    }
    if (data.specialDay != null) {
      await this.calendar.addSpecialDayToDayOfWeek(this.dayOfWeek, data.specialDay);
    }
    if (data.schedule != null) {
      await this.calendar.addScheduleToDayOfWeek(this.dayOfWeek, data.schedule);
    }
    if (data.specialDayIdToRemove != null) {
      await this.calendar.removeSpecialDayFromDayOfWeek(this.dayOfWeek, data.specialDayIdToRemove);
    }
    if (data.scheduleIdToRemove != null) {
      await this.calendar.removeScheduleFromDayOfWeek(this.dayOfWeek, data.scheduleIdToRemove);
    }
  }

  clone(): CustomizableViewModel {
    return new AppSchoolCalendarDayOfWeekViewModel(
      this._navigationService,
      this._localizationService,
      this.dayOfWeek,
      this.calendar.clone(true)
    );
  }
}
