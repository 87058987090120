import { AccountModel, AccountSummary, SectionModel } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { StudyoObjectId } from '@shared/services/stores';
import _ from 'lodash';
import { AccountUtils } from './AccountUtils';

export class SectionUtils {
  /**
   * Returns the color in which the content should be displayed. It looks for a custom color for the section.
   * If it doesn't find any, the default color of `section` will be returned. Finally, tt will return undefined if the
   * content due section id is the same as the `displayedSectionId`, or if the task doesn't
   * have a due section.
   *
   * @param {(SectionModel | undefined)} section The due section of the content.
   * @param {AccountModel} account The current account. Used to get customized section colors.
   * @param {(string | undefined)} displayedSectionId The section in which the content will be displayed.
   * @returns {(Color | undefined)}
   * @memberof BaseContentsViewModel
   */
  static getSectionColor(
    section: SectionModel | undefined,
    account: AccountModel,
    displayedSectionId: string | undefined
  ): Color | undefined {
    const sectionCustomization = account.customizedSectionColorsBySectionId;
    let contentColor: Color | undefined = undefined;

    if (section != null && section.id !== '' && section.id !== displayedSectionId) {
      const customColor = sectionCustomization[section.id];

      if (customColor != null) {
        contentColor = customColor;
      } else {
        contentColor = section.color;
      }
    }

    return contentColor;
  }

  static getTeacherNames(section: SectionModel, accountsById: Map<StudyoObjectId, AccountModel>): string {
    const teachers = _.chain(section.teacherIds)
      .map((tId) => accountsById.get(tId))
      .compact()
      .value();

    const firstTeacher = this.getFirstTeacher(section, teachers);

    if (firstTeacher == null) {
      return '';
    }

    const firstDisplayName = AccountUtils.getDisplayLastFirstName(firstTeacher, firstTeacher.visibleEmail);

    if (teachers.length > 1) {
      return `${firstDisplayName} + ${teachers.length - 1}`;
    } else {
      return firstDisplayName;
    }
  }

  static getFirstTeacher<T extends AccountModel | AccountSummary>(
    section: SectionModel,
    teachers: T[] | undefined
  ): T | undefined {
    if (teachers == null || teachers.length === 0) {
      return undefined;
    }

    if (section.defaultTeacherId.length > 0) {
      // Using for..of has we get an error while trying to use find. This is due to teachers being a union type.
      for (const teacher of teachers) {
        if (teacher.id === section.defaultTeacherId) {
          return teacher;
        }
      }
    }

    return teachers[0];
  }

  static getSectionShortTeacherInfo<T extends AccountModel | AccountSummary>(
    section: SectionModel,
    teachers: T[] | undefined,
    fallbackValue = ''
  ): string {
    if (teachers == null) {
      return fallbackValue;
    }

    const firstTeacher = this.getFirstTeacher(section, teachers);
    const teacherName = firstTeacher != null ? AccountUtils.getDisplayLastFirstName(firstTeacher) : '';

    return teachers.length > 1 ? `${teacherName} (+${teachers.length - 1})` : teacherName;
  }
}
