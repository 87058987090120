import {
  AnalyticsConfigInfo,
  AnalyticsEvent,
  AnalyticsPage,
  AnalyticsService,
  AnalyticsUserInfo
} from './AnalyticsService';

export class MultiAnalyticsService<TPage extends AnalyticsPage<string>, TEvent extends AnalyticsEvent<string>>
  implements AnalyticsService<TPage, TEvent>
{
  constructor(private readonly analyticsServices: AnalyticsService<TPage, TEvent>[]) {}

  setUserInfo(info: AnalyticsUserInfo) {
    this.analyticsServices.forEach((s) => s.setUserInfo(info));
  }

  clearUserInfo() {
    this.analyticsServices.forEach((s) => s.clearUserInfo());
  }

  setConfigInfo(info: AnalyticsConfigInfo) {
    this.analyticsServices.forEach((s) => s.setConfigInfo(info));
  }

  clearConfigInfo() {
    this.analyticsServices.forEach((s) => s.clearConfigInfo());
  }

  trackPage(page: TPage): void {
    this.analyticsServices.forEach((s) => s.trackPage(page));
  }

  trackEvent(event: TEvent): void {
    this.analyticsServices.forEach((s) => s.trackEvent(event));
  }
}
