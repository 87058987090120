import { CustomizationData, SchoolCalendarDayViewModel } from '@insights/viewmodels';
import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import { brown, grey, yellow } from '@mui/material/colors';
import { DraggableSpecialDaySymbolGrid } from '@shared/components/special_day_symbols';
import { Droppable, dragManager } from '@shared/rxp/drag-drop';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';
import { Row } from '../layout';
import { CustomizationsList } from './CustomizationsList';

export interface SchoolCalendarDayProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCalendarDayViewModel;
}

export const SchoolCalendarDay = observer((props: SchoolCalendarDayProps) => {
  const { viewModel, sx = [], className } = props;
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const acceptedType = viewModel.schoolDay == null ? '' : 'customization';

  const containerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  };

  const onDropCustomization = (data: unknown) => {
    setIsDraggingOver(false);
    void viewModel.applyCustomizations(data as CustomizationData, dragManager.isMoving);
  };

  const editCustomizations = () => viewModel.editCustomizations();

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderColor: isDraggingOver ? grey[600] : grey[100],
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundColor: viewModel.isInMonth ? (viewModel.hasCustomization ? yellow[100] : 'white') : grey[300],
        ...sx
      }}
    >
      <Droppable
        acceptedType={acceptedType}
        handleDrop={onDropCustomization}
        handleDragOver={() => setIsDraggingOver(true)}
        handleDragLeave={() => setIsDraggingOver(false)}
        sx={{ flex: 1 }}
      >
        <Tooltip title={viewModel.hasCustomization ? <CustomizationsList viewModel={viewModel} /> : ''}>
          <Box
            style={containerStyle}
            onClick={() => void editCustomizations()}
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Row verticalContentAlignment="center">
              <Typography sx={{ flex: 1 }}>{viewModel.schoolDay?.day.formattedString('d') ?? ''}</Typography>

              {viewModel.cycleDayTitle != null ? (
                <Typography
                  variant="caption"
                  sx={{
                    color: 'white',
                    backgroundColor: grey[500],
                    borderRadius: 1,
                    px: '2px'
                  }}
                >
                  {viewModel.cycleDayTitle}
                </Typography>
              ) : (
                viewModel.cycleDayNumber && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'white',
                      backgroundColor: brown[300],
                      borderRadius: 1,
                      px: '2px'
                    }}
                  >
                    {viewModel.cycleDayNumber}
                  </Typography>
                )
              )}
            </Row>

            <Box
              sx={{
                flex: 1
              }}
            />

            <DraggableSpecialDaySymbolGrid
              displayKind="linear"
              alwaysDisplaySymbol={true}
              displayNoneSymbol={true}
              squareSize={16}
              specialDays={viewModel.specialDays}
              maxSymbolCount={8}
              draggableDataSelector={(o) =>
                ({
                  specialDay: viewModel.specialDaysById[o.specialDayId],
                  sourceDay: viewModel.schoolDay?.day
                }) as CustomizationData
              }
              draggableType="customization"
              canCopyMove={true}
            />
          </Box>
        </Tooltip>
      </Droppable>
    </Box>
  );
});
