import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { IPromiseBasedObservable } from 'mobx-utils';
import { CSSProperties, ReactNode } from 'react';
import { LoadingIndicator } from './LoadingIndicator';

export interface LoadingObservablePresenterProps<T> {
  className?: string;
  style?: CSSProperties;
  data: IPromiseBasedObservable<T>;
  render: (data: T) => ReactNode;
  renderError: (error: Error) => ReactNode;
  loadingIndicator?: boolean;
  loadingMessage?: string;
  indicatorsSize?: 'small' | 'normal';
  displayPreviousData?: boolean;
}

export const LoadingObservablePresenter = observer(<T,>(props: LoadingObservablePresenterProps<T>) => {
  const {
    className,
    style,
    displayPreviousData = true,
    data,
    render,
    renderError,
    loadingIndicator = true,
    indicatorsSize,
    loadingMessage
  } = props;

  return data.case({
    pending: () => {
      if (displayPreviousData && data.value != null) {
        // If fetching, but we still have the previous data and want to display it.
        return (
          <Box className={className} style={style}>
            {render(data.value as T)}
          </Box>
        );
      } else {
        return (
          <Box
            className={className}
            style={style}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            {loadingIndicator && <LoadingIndicator size={indicatorsSize} message={loadingMessage} />}
          </Box>
        );
      }
    },
    rejected: (error: Error) => {
      console.error(`An IPromisedBasedObservable failed with "${error.message}".`);
      return (
        <Box className={className} style={style}>
          {renderError(error)}
        </Box>
      );
    },
    fulfilled: () => {
      console.log('An IPromisedBasedObservable completed successfully.');
      return (
        <Box className={className} style={style}>
          {render(data.value as T)}
        </Box>
      );
    }
  });
});
