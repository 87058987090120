import { SpecialDayOccurrence as PBSpecialDayOccurrence } from '@buf/studyo_studyo.bufbuild_es/studyo/type_generator_pb';
import { Color, SpecialDaySymbol } from '../types';
import { colorFromProtobuf, specialDaySymbolFromProtobuf } from '../types/EnumConversion';

export class SpecialDayOccurrence {
  private _pb: PBSpecialDayOccurrence;

  constructor(pb: PBSpecialDayOccurrence) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  get title(): string {
    return this._pb.title;
  }

  get symbol(): SpecialDaySymbol {
    return specialDaySymbolFromProtobuf(this._pb.symbol);
  }

  get color(): Color {
    return colorFromProtobuf(this._pb.color);
  }

  get specialDayId(): string {
    return this._pb.specialDayId;
  }
}
