import { SectionColorSetting as PBSectionColorSetting } from '@buf/studyo_studyo.bufbuild_es/studyo/type_account_pb';

import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableValuePropertyEx } from '../editables';
import { Color } from '../types';
import { protobufFromColor } from '../types/EnumConversion';
import { SectionColorSetting, SectionColorSettingModel } from './SectionColorSetting';

export class EditableSectionColorSetting
  extends EditableModelEx<PBSectionColorSetting>
  implements SectionColorSettingModel
{
  private _color: EditableValuePropertyEx<Color, PBSectionColorSetting>;

  static createNew(sectionId: string): EditableSectionColorSetting {
    const pb = new PBSectionColorSetting();
    pb.sectionId = sectionId;

    return new EditableSectionColorSetting(new SectionColorSetting(pb), true);
  }

  constructor(
    private readonly _originalSetting: SectionColorSettingModel,
    isNew = false
  ) {
    super(_originalSetting.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      // Exposed as "number index" in protobuf, but it's the same value as PB.Color.
      (this._color = new EditableValuePropertyEx<Color, PBSectionColorSetting>(
        _originalSetting.color,
        (pb, value) => (pb.colorIndex = protobufFromColor(value))
      ))
    ]);
  }

  get sectionId() {
    return this._originalSetting.sectionId;
  }

  @computed
  get color() {
    return this._color.value;
  }

  set color(value: Color) {
    this._color.value = value;
  }
}
