import { UserProfile } from '@shared/models/user';
import { Storage } from '../../Storage';
import { UserTransport } from '../../transports';
import { SearchForUserProfilesWithTextResult, UserStore } from '../interfaces';
import { AppBaseStore } from './AppBaseStore';

export class AppUserStore extends AppBaseStore implements UserStore {
  constructor(
    private readonly _localStorageResolver: () => Storage,
    private readonly _userTransport: UserTransport
  ) {
    super('AppUserStore');
  }

  async getUserProfile(): Promise<UserProfile> {
    const pbProfile = await this._userTransport.fetchUserProfile();
    return new UserProfile(pbProfile);
  }

  async getUserProfileById(userId: string): Promise<UserProfile | undefined> {
    const profilePb = await this._userTransport.fetchUserProfileForUserId(userId);
    if (profilePb == null) {
      return undefined;
    }
    return new UserProfile(profilePb);
  }

  async searchForUserProfilesWithEmail(email: string, configId?: string): Promise<UserProfile[]> {
    const profilesPb = await this._userTransport.fetchUserProfilesForEmail(email, true, configId);
    return profilesPb.map((pb) => new UserProfile(pb));
  }

  async searchForUserProfilesWithText(
    searchText: string,
    configId?: string
  ): Promise<SearchForUserProfilesWithTextResult> {
    const result = await this._userTransport.fetchUserProfilesForSearchText(searchText, true, configId);
    return {
      userProfiles: result[0].map((pb) => new UserProfile(pb)),
      partialResults: result[1]
    };
  }

  async requestPermanentUserDataDeletion(userId: string): Promise<void> {
    await this._userTransport.requestPermanentUserDataDeletion(userId);
  }

  async deleteUser(userId: string): Promise<void> {
    await this._userTransport.deleteUser(userId);
  }
}
