export interface UsersViewModelStrings {
  readonly deleteUserConfirmationMessage: (userId: string, email: string) => string;
  readonly deleteUserConfirmationTitle: string;
  readonly deleteUserErrorMessage: string;
  readonly deleteUserErrorTitle: string;
}

export const EnglishUsersViewModelStrings: UsersViewModelStrings = {
  deleteUserConfirmationMessage: (userId, email) =>
    `Are you sure you want to delete user with id ${userId} and email ${email}?`,
  deleteUserConfirmationTitle: 'Please confirm',
  deleteUserErrorMessage: 'An unexpected error occurred while deleting the user: ',
  deleteUserErrorTitle: 'Oops'
};

export const FrenchUsersViewModelStrings: UsersViewModelStrings = {
  deleteUserConfirmationMessage: (userId, email) =>
    `Êtes-vous certain de vouloir effacer l’usager à l’identifiant ${userId} et au courriel ${email}?`,
  deleteUserConfirmationTitle: 'Veuillez confirmer',
  deleteUserErrorMessage: 'Une erreur inattendue est survenue à l’effacement de l’usager: ',
  deleteUserErrorTitle: 'Oups'
};
