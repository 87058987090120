import { AccountService, AlertService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { CalendarStore, ContentStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import _ from 'lodash';
import { computed, makeObservable, observable, runInAction } from 'mobx';

export interface TeacherExportsViewModel {
  readonly canExportNotes: boolean;
  readonly isExportingNotes: boolean;

  exportNotes: () => Promise<void>;
}

export class AppTeacherExportsViewModel implements TeacherExportsViewModel {
  @observable private _isExporting = false;

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _contentStore: ContentStore,
    private readonly _calendarStore: CalendarStore,
    private readonly _accountService: AccountService,
    private readonly _localizationService: LocalizationService,
    private readonly _alertService: AlertService,
    public readonly configId: string,
    public readonly teacherId: string
  ) {
    makeObservable(this);
  }

  @computed
  get canExportNotes(): boolean {
    return this._accountService.isRootAdmin || this._accountService.isAccount([this.teacherId]);
  }

  @computed
  get isExportingNotes(): boolean {
    return this._isExporting;
  }

  async exportNotes(): Promise<void> {
    this._isExporting = true;

    const strings = this._localizationService.localizedStrings.insights.viewModels.metrics;
    const csvStrings = this._localizationService.localizedStrings.insights.viewModels.csvExports;

    try {
      const [notes, sectionsById] = await Promise.all([
        this._contentStore.getContents({
          configId: this.configId,
          accountIds: [this.teacherId],
          kindsToInclude: ['note']
        }),
        this._schoolYearConfigurationStore.getSectionsById(this.configId)
      ]);

      if (notes.length === 0) {
        await this._alertService.showMessage({
          title: strings.nothingToExportTitle,
          message: strings.nothingToExport
        });
        return;
      }

      const sectionIds = _.uniq(notes.map((n) => n.sectionId).filter((id) => id.length > 0));
      // No need for a preferred schedule tag, we're only interested in occurrences.
      const schoolDays = await this._calendarStore.getSchoolDays(this.configId, sectionIds);
      const occurrencesByDate = _.groupBy(
        _.flatten(
          schoolDays.map((sd) =>
            _.flatten(sd.periods.map((p) => p.courseOccurrences.map((occurrence) => ({ day: sd.day, occurrence }))))
          )
        ),
        (info) => info.day.asDateString
      );

      const noteInfos = _.orderBy(notes, (n) => [n.dueDay.asDateString, n.duePeriodTag]).map((note) => ({
        [csvStrings.date]: note.dueDay.asString,
        [csvStrings.period]: note.duePeriodTag,
        [csvStrings.sectionId]: sectionsById[note.sectionId]?.importId ?? '',
        [csvStrings.sectionTitle]: sectionsById[note.sectionId]?.title ?? '',
        [csvStrings.sectionOccurrenceTitle]:
          occurrencesByDate[note.dueDay.asDateString]?.find((o) => o.occurrence.sectionId === note.sectionId)
            ?.occurrence.displayTitle ?? '',
        [csvStrings.notes]: note.notes
      }));

      const filename = `notes-${this.teacherId}`;
      const csvConfig = mkConfig({
        filename,
        useKeysAsHeaders: true
      });
      download(csvConfig)(generateCsv(csvConfig)(noteInfos));
    } catch (error) {
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isExporting = false));
    }
  }
}
