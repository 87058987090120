import { Box, SxProps } from '@mui/material';
import { EditableOnboardingProcess } from '@shared/models/onboarding/implementations';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { OnboardingTextsEdition } from './OnboardingTextsEdition';

export interface OnboardingProcessEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  editableProcess: EditableOnboardingProcess;
  onFileUpload?: (dataUrl: string, fileName?: string) => Promise<void>;
}

export const OnboardingProcessEdition = observer((props: OnboardingProcessEditionProps) => {
  const { className, style, sx = [], editableProcess, onFileUpload } = props;

  return (
    <Box sx={sx} className={className} style={style}>
      <OnboardingTextsEdition texts={editableProcess.editableDescription} onFileUpload={onFileUpload} />
    </Box>
  );
});
