export interface CsvExportViewModelStrings {
  readonly assignmentDay: string;
  readonly associatedSectionNumbers: string;
  readonly completed: string;
  readonly completesTasks: string;
  readonly date: string;
  readonly defaultRoomName: string;
  readonly defaultTeacherId: string;
  readonly description: string;
  readonly dueDay: string;
  readonly email: string;
  readonly firstName: string;
  readonly gradeLevel: string;
  readonly icon: string;
  readonly id: string;
  readonly importId: string;
  readonly invitedParent: string;
  readonly lastName: string;
  readonly notes: string;
  readonly opensApp: string;
  readonly period: string;
  readonly planned: string;
  readonly publishedDay: string;
  readonly sectionId: string;
  readonly sectionNumber: string;
  readonly sectionOccurrenceTitle: string;
  readonly sectionTitle: string;
  readonly seen: string;
  readonly shortTitle: string;
  readonly stepsAdded: string;
  readonly studentId: string;
  readonly studentName: string;
  readonly teacherId: string;
  readonly teacherName: string;
  readonly title: string;
  readonly workloadLevel: string;
}

export const EnglishCsvExportViewModelStrings: CsvExportViewModelStrings = {
  assignmentDay: 'Assigned',
  associatedSectionNumbers: 'Associated Groups',
  completed: 'Completed',
  completesTasks: 'Completes Tasks',
  date: 'Date',
  defaultRoomName: 'Default Room',
  defaultTeacherId: 'Default Teacher Id',
  description: 'Description',
  dueDay: 'Due',
  email: 'Email',
  firstName: 'First name',
  gradeLevel: 'Grade level',
  icon: 'Icon',
  id: 'Id',
  importId: 'Import Id',
  invitedParent: 'Invited parent',
  lastName: 'Last name',
  notes: 'Notes',
  opensApp: 'Opens app',
  period: 'Period',
  planned: 'Planned',
  publishedDay: 'First published',
  sectionId: 'Section Id',
  sectionNumber: 'Section Number',
  sectionOccurrenceTitle: 'Occurrence',
  sectionTitle: 'Section Title',
  seen: 'Seen',
  shortTitle: 'Short Title',
  stepsAdded: 'Steps added',
  studentId: 'Student Id',
  studentName: 'Student Name',
  teacherId: 'Teacher Id',
  teacherName: 'Teacher Name',
  title: 'Title',
  workloadLevel: 'Workload Level'
};

export const FrenchCsvExportViewModelStrings: CsvExportViewModelStrings = {
  assignmentDay: 'Annoncée',
  associatedSectionNumbers: 'Groupes associés',
  completed: 'Complétée',
  completesTasks: 'Complète ses tâches',
  date: 'Date',
  defaultRoomName: 'Local par défaut',
  defaultTeacherId: 'Enseignant par défaut',
  description: 'Description',
  dueDay: 'Due',
  email: 'Courriel',
  firstName: 'Prénom',
  gradeLevel: 'Niveau',
  icon: 'Icône',
  id: 'Identifiant',
  importId: 'Identifiant',
  invitedParent: 'Parent invité',
  lastName: 'Nom',
  notes: 'Notes',
  opensApp: 'Ouvre l’app',
  period: 'Période',
  planned: 'Planifiée',
  publishedDay: 'Première publication',
  sectionId: 'Groupe-matière',
  sectionNumber: 'Groupe',
  sectionOccurrenceTitle: 'Occurrence',
  sectionTitle: 'Titre',
  seen: 'Vue',
  shortTitle: 'Titre court',
  stepsAdded: 'Étapes ajoutées',
  studentId: 'Id élève',
  studentName: 'Nom élève',
  teacherId: 'Id enseignant',
  teacherName: 'Nom enseignant',
  title: 'Titre',
  workloadLevel: 'Niveau de charge'
};
