export interface RoutesStrings {
  home: string;
  login: string;
  logout: string;
  onboardingConfigCreation: string;
  onboardingUseCode: string;
  agenda: string;
  agendaDaily: string;
  agendaWeekly: string;
  agendaMonthly: string;
  agendaYearly: string;
  agendaTimeline: string;
  agendaPlanner: string;
  agendaPeriods: string;
}

export const EnglishRoutesStrings: RoutesStrings = {
  home: 'Home',
  login: 'Login',
  logout: 'Logout',
  onboardingConfigCreation: 'Configuration Creation',
  onboardingUseCode: 'Enter a Code',
  agenda: 'Planner',
  agendaDaily: 'Day',
  agendaWeekly: 'Week',
  agendaMonthly: 'Month',
  agendaYearly: 'Year',
  agendaTimeline: 'Tasks',
  agendaPlanner: 'Teacher',
  agendaPeriods: 'Periods'
};

export const FrenchRoutesStrings: RoutesStrings = {
  home: 'Accueil',
  login: 'Connexion',
  logout: 'Déconnexion',
  onboardingConfigCreation: 'Création de configuration',
  onboardingUseCode: 'Saisir un code',
  agenda: 'Agenda',
  agendaDaily: 'Jour',
  agendaWeekly: 'Semaine',
  agendaMonthly: 'Mois',
  agendaYearly: 'Année',
  agendaTimeline: 'Tâches',
  agendaPlanner: 'Prof',
  agendaPeriods: 'Périodes'
};
