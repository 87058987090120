import { BlackbaudMappingsViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowIcon from '@mui/icons-material/ForwardOutlined';
import {
  Box,
  IconButton,
  InputLabel,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { BlackbaudAssignmentKind, EditableBlackbaudKindMapping } from '@shared/models/connectors';
import { AllContentWorkloadLevels, ContentIcon } from '@shared/models/types';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { BlackbaudAssignmentKindSelector } from './BlackbaudAssignmentKindSelector';
import { ContentIconSelector } from './ContentIconSelector';
import { ContentWorkloadLevelSelector } from './ContentWorkloadLevelSelector';

export interface BlackbaudAssignmentKindMappingsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: BlackbaudMappingsViewModel;
}

export const BlackbaudAssignmentKindMappings = observer((props: BlackbaudAssignmentKindMappingsProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  function addKind(viewModel: BlackbaudMappingsViewModel, kind: BlackbaudAssignmentKind | undefined): void {
    // We actually add this new kind mapping
    if (kind != null) {
      viewModel.addKindMapping(kind);
    }
  }

  function updateKind(kindMapping: EditableBlackbaudKindMapping, kind: BlackbaudAssignmentKind | undefined): void {
    kindMapping.kindId = kind?.id ?? 0n;
    kindMapping.kindName = kind?.name ?? '';
  }

  function updateIcon(kindMapping: EditableBlackbaudKindMapping, icon: ContentIcon): void {
    kindMapping.icon = icon;
  }

  return (
    <Root className={className} style={style}>
      <TableHead>
        <TableRow>
          <TableCell className={clsx('cell', 'kindNameCell')}>
            <InputLabel shrink>{strings.kindNameTitle}</InputLabel>
          </TableCell>
          <TableCell className={clsx('cell', 'iconCell')}>
            <InputLabel shrink>{strings.iconTitle}</InputLabel>
          </TableCell>
          <TableCell className={clsx('cell', 'workloadCell')}>
            <InputLabel shrink>{strings.workloadLevelTitle}</InputLabel>
          </TableCell>
          <TableCell className={clsx('cell', 'actionCell')} />
        </TableRow>
      </TableHead>
      <TableBody>
        {viewModel.kindMappings.map((m, index) => (
          <TableRow key={`kind-${index}`}>
            <TableCell className={clsx('cell', 'kindNameCell')}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <BlackbaudAssignmentKindSelector
                  availableKinds={viewModel.availableKinds}
                  selectedKindId={Number(m.kindId)}
                  onChange={(kind) => updateKind(m, kind)}
                />
                <ArrowIcon className="arrow" />
              </Box>
            </TableCell>
            <TableCell className={clsx('cell', 'iconCell')}>
              <ContentIconSelector
                selectedIcon={m.icon}
                workloadLevel={m.workloadLevel}
                onChange={(icon) => updateIcon(m, icon)}
              />
            </TableCell>
            <TableCell className={clsx('cell', 'workloadCell')}>
              <ContentWorkloadLevelSelector
                selectedLevel={m.workloadLevel}
                availableLevels={AllContentWorkloadLevels}
                onChange={(level) => (m.workloadLevel = level)}
              />
            </TableCell>
            <TableCell className={clsx('cell', 'actionCell')}>
              <IconButton onClick={() => m.markAsDeleted()}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow className="nextRow">
          <TableCell className={clsx('cell', 'kindNameCell')}>
            <BlackbaudAssignmentKindSelector
              availableKinds={viewModel.availableKinds}
              selectedKindId={(viewModel.nextAssignmentKind && Number(viewModel.nextAssignmentKind.id)) ?? 0}
              onChange={(kind) => addKind(viewModel, kind)}
            />
          </TableCell>
          <TableCell className={clsx('cell', 'iconCell')} />
          <TableCell className={clsx('cell', 'workloadCell')} />
          <TableCell className={clsx('cell', 'actionCell')} />
        </TableRow>
      </TableFooter>
    </Root>
  );
});

const Root = styled(Table)(({ theme }) => ({
  '.cell': {
    padding: '2px 8px 2px 0px',
    border: 'none',
    verticalAlign: 'bottom'
  },
  '.kindNameCell': {
    width: '35%'
  },
  '.iconCell': {
    width: '35%'
  },
  '.workloadCell': {
    width: '15%'
  },
  '.actionCell': {
    width: '15%'
  },
  '.nextRow': {
    backgroundColor: grey[200]
  },
  '.arrow': {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));
