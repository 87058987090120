import { EditableAccountInfo } from '@insights/models';
import { AccountService, NavigationService } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { AppSectionsViewModel } from '../metrics';
import { AppEditableAccountsScreenViewModel } from './EditableAccountsScreenViewModel';

export class AppEditableParentsScreenViewModel extends AppEditableAccountsScreenViewModel {
  constructor(
    schoolYearConfigurationStore: SchoolYearConfigurationStore,
    accountService: AccountService,
    navigationService: NavigationService,
    localizationService: LocalizationService,
    configId: string,
    shouldLimitAccess?: boolean
  ) {
    super(
      schoolYearConfigurationStore,
      accountService,
      navigationService,
      localizationService,
      configId,
      'parent',
      shouldLimitAccess
    );
  }

  protected async getLimitedAccounts(): Promise<AccountModel[]> {
    if (this._accountService.isAllowed(['studyo-staff', 'school-staff'])) {
      // School and Studyo staff can view all parents. They'll get limited access to changes.
      return await this._schoolYearConfigurationStore.getParents(this.configId, false);
    } else {
      const teacherId = this._accountService.getAccountIdForConfigRole(this.configId, 'teacher');

      if (teacherId != null) {
        // Teachers see all parents of students in their sections.
        const sections = await this._schoolYearConfigurationStore.getTaughtSectionsForTeacherId(
          this.configId,
          teacherId
        );
        const sectionIds = sections.map((s) => s.id);
        const [students, parents] = await Promise.all([
          this._schoolYearConfigurationStore.getStudentsForSectionIds(this.configId, sectionIds, false),
          this._schoolYearConfigurationStore.getParents(this.configId, false)
        ]);
        const studentIds = new Set(students.map((s) => s.id));

        return parents.filter(
          (p) =>
            p.childrenAccountIds.some((id) => studentIds.has(id)) ||
            p.childrenAccountPendingVerificationIds.some((id) => studentIds.has(id))
        );
      } else {
        return await super.getLimitedAccounts();
      }
    }
  }

  protected async loadAccountInfos(accounts: AccountModel[]): Promise<EditableAccountInfo[]> {
    // We must include deleted student accounts, to see matches between non-deleted parents and deleted students.
    const studentsById = await this._schoolYearConfigurationStore.getStudentsById(this.configId, true);

    return Promise.resolve(
      accounts.map<EditableAccountInfo>((account) => {
        const children = account.childrenAccountIds.map((id) => studentsById[id]);
        const pendingChildren = account.childrenAccountPendingVerificationIds.map((id) => studentsById[id]);

        return {
          id: account.id,
          account,
          sections: new AppSectionsViewModel(this._navigationService, this._localizationService, this.configId, []),
          parents: [],
          children,
          pendingChildren
        };
      })
    );
  }
}
