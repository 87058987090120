import * as MB from '@buf/studyo_studyo.bufbuild_es/studyo/type_managebac_pb';
import { EditableManageBacAssignmentKindMapping } from '@shared/models/connectors';
import {
  ManageBacAssignmentKindMappingModel,
  ManageBacExternalAccountDetails
} from '@shared/models/connectors/interfaces';
import { FullyEditableListProperty } from '@shared/models/editables';
import { LocalizationService } from '@shared/resources/services';
import { ManageBacConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface ManageBacMappingsViewModel extends ExternalAccountEditionViewModel {
  readonly assignmentKindMappings: EditableManageBacAssignmentKindMapping[];
  addingKindName: string;
  readonly canAddKind: boolean;
  addAssignmentKindMapping(): void;

  isIgnoringUnmappedKinds: boolean;
}

export class AppManageBacMappingsViewModel
  extends BaseExternalAccountEditionViewModel
  implements ManageBacMappingsViewModel
{
  @observable private _isIgnoringUnmappedKinds?: boolean;
  @observable private _addingKindName = '';

  // We use an editable field to hold changes, but it doesn't have a host to apply to.
  private _mappings: FullyEditableListProperty<
    MB.AssignmentKindMapping,
    ManageBacAssignmentKindMappingModel,
    EditableManageBacAssignmentKindMapping,
    unknown
  >;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _manageBacStore: ManageBacConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    private readonly _originalDetails: ManageBacExternalAccountDetails
  ) {
    super();

    makeObservable(this);

    this._mappings = new FullyEditableListProperty(
      _originalDetails.kindMappings.map((km) => new EditableManageBacAssignmentKindMapping(km)),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }

  @computed
  get isIgnoringUnmappedKinds() {
    return this._isIgnoringUnmappedKinds ?? this._originalDetails.isIgnoringUnmappedKinds;
  }

  set isIgnoringUnmappedKinds(value: boolean) {
    this._isIgnoringUnmappedKinds = value;
    this.onChange();
  }

  @computed
  get assignmentKindMappings() {
    return this._mappings.values;
  }

  @computed
  get addingKindName() {
    return this._addingKindName;
  }

  set addingKindName(value: string) {
    this._addingKindName = value;
  }

  @computed
  get canAddKind() {
    return (
      this._addingKindName.length > 0 && this._mappings.values.find((m) => m.kind === this._addingKindName) == null
    );
  }

  @action
  addAssignmentKindMapping() {
    this._mappings.addItem(EditableManageBacAssignmentKindMapping.createNew(this._addingKindName));
    this._addingKindName = '';
  }

  get hasChanges() {
    return this._hasChanges || this._mappings.isChanged;
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this.hasChanges) {
      console.error('Applying without changes. Ignoring...');
      this._onSuccess();
      return;
    }

    this.beginApplying();

    try {
      await this._manageBacStore.updateManageBacAccountSettings(
        this._configId,
        this._externalAccountId,
        this._originalDetails.termIds,
        this.assignmentKindMappings,
        this.isIgnoringUnmappedKinds
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._mappings.reset();
    this._isIgnoringUnmappedKinds = false;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this.hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
