import { InsightsImages } from '@shared/resources/images/insights';
import { StudyoImages } from '@shared/resources/images/studyo';
import { ImageService } from '@shared/resources/services';
import { WebInsightsImages } from '../images/insights';
import { WebStudyoImages } from '../images/studyo';

export class WebImageService implements ImageService {
  private readonly _studyoImages = new WebStudyoImages();
  private readonly _insightsImages = new WebInsightsImages();

  get studyoImages(): StudyoImages {
    return this._studyoImages;
  }

  get insightsImages(): InsightsImages {
    return this._insightsImages;
  }
}
