import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_sky_pb';
import { BlackbaudSkyTokenInformation } from './interfaces';

export class GrpcBlackbaudSkyTokenInformation implements BlackbaudSkyTokenInformation {
  constructor(private readonly _pb: BC.TokenInformation) {}

  get accessTokenExpiration(): Date {
    const date = this._pb.accessTokenExpiration?.toDate();

    if (date == null) {
      console.error('Missing access token expiration date');
      return new Date(2000, 1, 1);
    }

    return date;
  }

  get refreshTokenExpiration(): Date {
    const date = this._pb.refreshTokenExpiration?.toDate();

    if (date == null) {
      console.error('Missing refresh token expiration date');
      return new Date(2000, 1, 1);
    }

    return date;
  }

  get environmentId(): string {
    return this._pb.environmentId;
  }

  get environmentName(): string {
    return this._pb.environmentName;
  }

  get legalEntityId(): string {
    return this._pb.legalEntityId;
  }

  get legalEntityName(): string {
    return this._pb.legalEntityName;
  }

  get userId(): string {
    return this._pb.userId;
  }

  get email(): string {
    return this._pb.email;
  }

  get lastName(): string {
    return this._pb.lastName;
  }

  get firstName(): string {
    return this._pb.firstName;
  }
}
