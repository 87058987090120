import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { IPromiseBasedObservable } from 'mobx-utils';
import { CSSProperties, ReactNode } from 'react';
import { ErrorIndicator } from './ErrorIndicator';
import { LoadingIndicator } from './LoadingIndicator';

export interface ObservablePresenterProps<T> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  data: IPromiseBasedObservable<T>;
  render: (data: T) => ReactNode;
  loadingIndicator?: boolean;
  loadingMessage?: string;
  errorMessage?: string;
  indicatorsSize?: 'small' | 'normal';
  displayPreviousData?: boolean;
}

export const ObservablePresenter = observer(<T,>(props: ObservablePresenterProps<T>) => {
  const {
    sx = [],
    className,
    style,
    displayPreviousData = true,
    data,
    render,
    loadingIndicator = true,
    indicatorsSize,
    loadingMessage,
    errorMessage
  } = props;

  return data.case({
    pending: () => {
      if (displayPreviousData && data.value != null) {
        // If fetching, but we still have the previous data and want to display it.
        return (
          <Box sx={sx} className={className} style={style}>
            {render(data.value as T)}
          </Box>
        );
      } else {
        return (
          <Box
            sx={sx}
            className={className}
            style={style}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            {loadingIndicator && <LoadingIndicator size={indicatorsSize} message={loadingMessage} />}
          </Box>
        );
      }
    },
    rejected: (error: Error) => {
      console.error(`An IPromisedBasedObservable failed with "${error.message}".`);
      return (
        <Box
          sx={sx}
          className={className}
          style={style}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <ErrorIndicator size={indicatorsSize} message={errorMessage} />
        </Box>
      );
    },
    fulfilled: (value) => {
      console.log('An IPromisedBasedObservable completed successfully.');
      return (
        <Box sx={sx} className={className} style={style}>
          {render(value)}
        </Box>
      );
    }
  });
});
