import {
  BlackbaudAssignmentKind,
  BlackbaudExternalAccountDetails,
  BlackbaudKindMappingModel,
  BlackbaudSchoolLevel,
  BlackbaudSchoolYear,
  GrpcBlackbaudAssignmentKind,
  GrpcBlackbaudExternalAccountDetails,
  GrpcBlackbaudSchoolLevel,
  GrpcBlackbaudSchoolYear
} from '@shared/models/connectors';
import { BlackbaudTransport } from '../../../transports';
import { BlackbaudConnectorStore, ConnectorsStore } from '../../interfaces';
import { AppBaseStore } from '../AppBaseStore';

export class AppBlackbaudConnectorStore extends AppBaseStore implements BlackbaudConnectorStore {
  constructor(
    private readonly _transport: BlackbaudTransport,
    connectorStore: ConnectorsStore
  ) {
    super('AppBlackbaudConnectorStore', false, connectorStore);
  }

  async getBlackbaudAccountDetails(externalAccountId: string): Promise<BlackbaudExternalAccountDetails> {
    const details = await this._transport.fetchBlackbaudExternalAccountDetails(externalAccountId);

    return new GrpcBlackbaudExternalAccountDetails(details);
  }

  async createOrUpdateBlackbaudAccount(
    configId: string,
    externalAccountId: string,
    accountName: string,
    serverUrl: string,
    username: string,
    password: string
  ): Promise<string> {
    const response = await this._transport.createOrUpdateBlackbaudExternalAccount(
      externalAccountId,
      configId,
      serverUrl,
      username,
      password,
      accountName
    );
    this.invalidate();

    return response.externalAccountId;
  }

  async updateBlackbaudAccountSettings(
    configId: string,
    externalAccountId: string,
    yearLabel: string,
    levelId: bigint,
    levelName: string,
    kindMappings: BlackbaudKindMappingModel[],
    includeNonLeadSections: boolean
  ): Promise<void> {
    await this._transport.updateBlackbaudExternalAccountSettings(
      externalAccountId,
      configId,
      yearLabel,
      levelId,
      levelName,
      kindMappings.map((m) => m.toProtobuf()),
      includeNonLeadSections
    );
    this.invalidate();
  }

  async getBlackbaudSchoolYears(externalAccountId: string): Promise<BlackbaudSchoolYear[]> {
    const years = await this._transport.fetchBlackbaudSchoolYears(externalAccountId);
    return years.map((year) => new GrpcBlackbaudSchoolYear(year));
  }

  async getBlackbaudSchoolLevels(externalAccountId: string): Promise<BlackbaudSchoolLevel[]> {
    const levels = await this._transport.fetchBlackbaudSchoolLevels(externalAccountId);
    return levels.map((level) => new GrpcBlackbaudSchoolLevel(level));
  }

  async getBlackbaudAssignmentKinds(externalAccountId: string): Promise<BlackbaudAssignmentKind[]> {
    const kinds = await this._transport.fetchBlackbaudAssignmentKinds(externalAccountId);
    return kinds.map((kind) => new GrpcBlackbaudAssignmentKind(kind));
  }
}
