import {
  Section_Schedule as PBSchedule,
  Section_Schedule_Custom as PBScheduleCustom,
  Section_Schedule_Master as PBScheduleMaster
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { dateService } from '@shared/services/DateService';
import _ from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import {
  EditableChildNullablePropertyEx,
  EditableModelEx,
  EditableNullableDayPropertyEx,
  EditableNullableValuePropertyEx,
  EditableStringArrayProperty,
  EditableStringProperty
} from '../editables';
import { Day, DayOfWeek } from '../types';
import { protobufFromDayOfWeek } from '../types/EnumConversion';
import { EditableSectionScheduleCustom } from './EditableSectionScheduleCustom';
import { EditableSectionScheduleMaster } from './EditableSectionScheduleMaster';
import { SectionSchedule, SectionScheduleModel } from './SectionSchedule';
import { SectionScheduleCustomModel } from './SectionScheduleCustom';
import { SectionScheduleMasterModel } from './SectionScheduleMaster';

export type EditableSectionScheduleField =
  | 'cycle-day'
  | 'day-of-week'
  | 'day'
  | 'period-tag'
  | 'schedule-tag'
  | 'custom-start-time'
  | 'custom-end-time'
  | 'custom-display-tag';

export type SectionScheduleDayCase = 'cycle-day' | 'day-of-week' | 'specific-day';

export const AllSectionScheduleDayCase: SectionScheduleDayCase[] = ['cycle-day', 'day-of-week', 'specific-day'];

export class EditableSectionSchedule extends EditableModelEx<PBSchedule> implements SectionScheduleModel {
  @observable private _dayCase: SectionScheduleDayCase;

  private _roomName: EditableStringProperty<PBSchedule>;
  private _effectiveFromDay: EditableNullableDayPropertyEx<PBSchedule>;
  private _effectiveToDay: EditableNullableDayPropertyEx<PBSchedule>;
  private _dayOfWeek: EditableNullableValuePropertyEx<DayOfWeek, PBSchedule>;
  private _cycleDay: EditableNullableValuePropertyEx<number, PBSchedule>;
  private _day: EditableNullableDayPropertyEx<PBSchedule>;
  private _masterSchedule: EditableChildNullablePropertyEx<
    PBScheduleMaster,
    SectionScheduleMasterModel,
    EditableSectionScheduleMaster,
    PBSchedule
  >;
  private _customSchedule: EditableChildNullablePropertyEx<
    PBScheduleCustom,
    SectionScheduleCustomModel,
    EditableSectionScheduleCustom,
    PBSchedule
  >;
  private _teacherIds: EditableStringArrayProperty<PBSchedule>;
  private _termTag: EditableStringProperty<PBSchedule>;

  static createNew(teacherId: string, termTag?: string, periodTag?: string): EditableSectionSchedule {
    const pb = new PBSchedule();
    pb.id = uuidv4();
    // We must have a valid initial cycle day, we use the first.
    pb.appliesTo = { case: 'cycleDay', value: 1 };

    if (teacherId.length > 0) {
      pb.teacherIds = [teacherId];
    }

    if (periodTag != null) {
      const masterPB = new PBScheduleMaster();
      masterPB.periodTag = periodTag;
      pb.schedule = { case: 'master', value: masterPB };
    }
    pb.termTag = termTag ?? '';

    return new EditableSectionSchedule(new SectionSchedule(pb), true);
  }

  constructor(
    private readonly _originalSectionSchedule: SectionScheduleModel,
    isNew = false
  ) {
    super(_originalSectionSchedule.toProtobuf(), isNew);

    makeObservable(this);

    this._dayCase =
      _originalSectionSchedule.dayOfWeek != null
        ? 'day-of-week'
        : _originalSectionSchedule.day != null
          ? 'specific-day'
          : 'cycle-day';

    // Some existing schedules have neither a master nor custom schedule... ¯\_(ツ)_/¯
    // We favor masters. Expressed like this just in case both wouldn't be null (not expected).
    const isMaster = _originalSectionSchedule.masterSchedule != null || _originalSectionSchedule.customSchedule == null;

    this.setFields([
      (this._roomName = new EditableStringProperty(
        _originalSectionSchedule.roomName,
        (pb, value) => (pb.roomName = value),
        {
          trim: true
        }
      )),
      (this._effectiveFromDay = new EditableNullableDayPropertyEx(
        _originalSectionSchedule.effectiveFromDay,
        (pb, value) => (pb.effectiveFromDay = value?.asPB)
      )),
      (this._effectiveToDay = new EditableNullableDayPropertyEx(
        _originalSectionSchedule.effectiveToDay,
        (pb, value) => (pb.effectiveToDay = value?.asPB)
      )),
      (this._dayOfWeek = new EditableNullableValuePropertyEx(
        _originalSectionSchedule.dayOfWeek,
        (pb, value) => {
          if (value != null) {
            pb.appliesTo = { case: 'dayOfWeek', value: protobufFromDayOfWeek(value) };
          }
        },
        this._dayCase !== 'day-of-week'
      )),
      (this._cycleDay = new EditableNullableValuePropertyEx(
        _originalSectionSchedule.cycleDay,
        (pb, value) => {
          if (value != null) {
            pb.appliesTo = { case: 'cycleDay', value: value };
          }
        },
        this._dayCase !== 'cycle-day'
      )),
      (this._day = new EditableNullableDayPropertyEx(
        _originalSectionSchedule.day,
        (pb, value) => {
          if (value != null) {
            pb.appliesTo = { case: 'day', value: value.asPB };
          }
        },
        this._dayCase !== 'specific-day'
      )),
      (this._masterSchedule = new EditableChildNullablePropertyEx<
        PBScheduleMaster,
        SectionScheduleMasterModel,
        EditableSectionScheduleMaster,
        PBSchedule
      >(
        _originalSectionSchedule.masterSchedule,
        (model) =>
          model == null ? EditableSectionScheduleMaster.createNew() : new EditableSectionScheduleMaster(model),
        (pb, pbValue) => {
          if (pbValue != null) {
            pb.schedule = { case: 'master', value: pbValue };
          }
        },
        !isMaster
      )),
      (this._customSchedule = new EditableChildNullablePropertyEx<
        PBScheduleCustom,
        SectionScheduleCustomModel,
        EditableSectionScheduleCustom,
        PBSchedule
      >(
        _originalSectionSchedule.customSchedule,
        (model) =>
          model == null ? EditableSectionScheduleCustom.createNew(8) : new EditableSectionScheduleCustom(model),
        (pb, pbValue) => {
          if (pbValue != null) {
            pb.schedule = { case: 'custom', value: pbValue };
          }
        },
        isMaster
      )),
      (this._teacherIds = new EditableStringArrayProperty(
        _originalSectionSchedule.teacherIds,
        (pb, values) => (pb.teacherIds = values),
        {
          trim: true
        }
      )),
      (this._termTag = new EditableStringProperty(
        _originalSectionSchedule.termTag,
        (pb, value) => (pb.termTag = value),
        {
          trim: true
        }
      ))
    ]);
  }

  get id(): string {
    return this._originalSectionSchedule.id;
  }

  @computed
  get roomName(): string {
    return this._roomName.value;
  }

  set roomName(value: string) {
    this._roomName.value = value;
  }

  @computed
  get effectiveFromDay(): Day | undefined {
    return this._effectiveFromDay.value;
  }

  set effectiveFromDay(value: Day | undefined) {
    this._effectiveFromDay.value = value;
  }

  @computed
  get effectiveToDay(): Day | undefined {
    return this._effectiveToDay.value;
  }

  set effectiveToDay(value: Day | undefined) {
    this._effectiveToDay.value = value;
  }

  @computed
  get dayCase() {
    return this._dayCase;
  }

  set dayCase(value: SectionScheduleDayCase) {
    this._dayCase = value;

    this._cycleDay.isDisabled = value !== 'cycle-day';
    this._dayOfWeek.isDisabled = value !== 'day-of-week';
    this._day.isDisabled = value !== 'specific-day';

    if (value === 'cycle-day' && this._cycleDay.value == null) {
      this._cycleDay.value = 1;
    } else if (value === 'day-of-week' && this._dayOfWeek.value == null) {
      this._dayOfWeek.value = 'monday';
    } else if (value === 'specific-day' && this._day.value == null) {
      this._day.value = dateService.today;
    }
  }

  @computed
  get dayOfWeek(): DayOfWeek | undefined {
    return this._dayOfWeek.value;
  }

  set dayOfWeek(value: DayOfWeek | undefined) {
    this._dayOfWeek.value = value;

    if (value != null) {
      // Failsafe if UI doesn't have "dayCase" picker.
      this._dayCase = 'day-of-week';
    }
  }

  @computed
  get cycleDay(): number | undefined {
    return this._cycleDay.value;
  }

  set cycleDay(value: number | undefined) {
    this._cycleDay.value = value;

    if (value != null) {
      // Failsafe if UI doesn't have "dayCase" picker.
      this._dayCase = 'cycle-day';
    }
  }

  @computed
  get day(): Day | undefined {
    return this._day.value;
  }

  set day(value: Day | undefined) {
    this._day.value = value;

    if (value != null) {
      // Failsafe if UI doesn't have "dayCase" picker.
      this._dayCase = 'specific-day';
    }
  }

  @computed
  get appliesTo(): DayOfWeek | number | Day {
    const value = this.dayOfWeek ?? this.cycleDay ?? this.day;

    if (value == null) {
      throw new Error(
        "Invalid operation: This editable section schedule doesn't have a day of week, cycle day or day."
      );
    }

    return value;
  }

  @computed
  get isMaster() {
    return !this._masterSchedule.isDisabled;
  }

  set isMaster(value: boolean) {
    this._masterSchedule.isDisabled = !value;
    this._customSchedule.isDisabled = value;
  }

  @computed
  get masterSchedule(): SectionScheduleMasterModel | undefined {
    return this._masterSchedule.value;
  }

  @computed
  get editableMasterSchedule(): EditableSectionScheduleMaster {
    return this._masterSchedule.editableValue;
  }

  @computed
  get customSchedule(): SectionScheduleCustomModel | undefined {
    return this._customSchedule.value;
  }

  @computed
  get editableCustomSchedule(): EditableSectionScheduleCustom {
    return this._customSchedule.editableValue;
  }

  @computed
  get schedule(): SectionScheduleMasterModel | SectionScheduleCustomModel {
    const value = this.masterSchedule ?? this.customSchedule;

    if (value == null) {
      throw new Error("Invalid operation: This editable section schedule doesn't have a master or custom schedule.");
    }

    return value;
  }

  @computed
  get teacherIds(): string[] {
    return this._teacherIds.value;
  }

  set teacherIds(value: string[]) {
    this._teacherIds.value = value;
  }

  @computed
  get termTag(): string {
    return this._termTag.value;
  }

  set termTag(value: string) {
    this._termTag.value = value;
  }

  @computed
  get invalidFields(): EditableSectionScheduleField[] {
    return _.compact<EditableSectionScheduleField>([
      this._dayCase === 'cycle-day' && this.cycleDay == null ? 'cycle-day' : undefined,
      this._dayCase === 'day-of-week' && this.dayOfWeek == null ? 'day-of-week' : undefined,
      this._dayCase === 'specific-day' && this.day == null ? 'day' : undefined,
      this.masterSchedule?.periodTag.length === 0 ? 'period-tag' : undefined,
      // We return both as invalid.
      this.customSchedule?.endTime.isBefore(this.customSchedule.startTime) ? 'custom-start-time' : undefined,
      this.customSchedule?.endTime.isBefore(this.customSchedule.startTime) ? 'custom-end-time' : undefined,
      this.customSchedule?.displayPeriodTag.length === 0 ? 'custom-display-tag' : undefined
    ]);
  }
}
