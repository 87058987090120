import { SourceFile as PBSourceFile } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { BaseModel } from '../../Model';
import { FileEncoding, FileKind } from '../../types';
import { importSourceFileEncodingFromProtobuf, importSourceFileKindFromProtobuf } from '../../types/EnumConversion';
import { SourceFile } from '../interfaces';

export class GrpcSourceFile extends BaseModel<PBSourceFile> implements SourceFile {
  constructor(pb: PBSourceFile) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  get name(): string {
    return this._pb.name;
  }

  get description(): string {
    return this._pb.description;
  }

  get label(): string {
    return this._pb.label;
  }

  get kind(): FileKind {
    return importSourceFileKindFromProtobuf(this._pb.kind);
  }

  get isVisible(): boolean {
    return this._pb.isVisible;
  }

  get hasHeader(): boolean {
    return this._pb.hasHeader;
  }

  get orderedHeaders(): string[] {
    return this._pb.orderedHeaders;
  }

  get url(): string {
    return this._pb.url;
  }

  get previousUrls(): string[] {
    return this._pb.previousUrls;
  }

  get expectedEncoding(): FileEncoding {
    return importSourceFileEncodingFromProtobuf(this._pb.expectedEncoding);
  }

  get sheetName(): string {
    return this._pb.sheetName;
  }

  get topRowsSkipped(): number {
    return this._pb.topRowsSkipped;
  }

  // <MaterialTable> that support in-place updates uses JSON.stringify to get a JSON
  // object with properties. This fails with Typescript objects exposing getters. ¯\_(ツ)_/¯
  // Fortunately, JSON.stringify calls toJSON when present. Since SourceFile items are
  // directly editable with an "onUpdated" pattern, we must implement toJSON here,
  // instead of having to expose EditableSourceFile items in the first place.
  // TODO: https://dev.azure.com/studyodev/Studyo/_workitems/edit/1113
  toJSON(): Record<string, unknown> {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      label: this.label,
      kind: this.kind,
      isVisible: this.isVisible,
      hasHeader: this.hasHeader,
      orderedHeaders: this.orderedHeaders,
      url: this.url,
      previousUrls: this.previousUrls,
      expectedEncoding: this.expectedEncoding
    };
  }
}
