import * as GC from '@buf/studyo_studyo.bufbuild_es/studyo/type_classroom_pb';
import { computed, makeObservable } from 'mobx';
import {
  EditableChildNullablePropertyEx,
  EditableModelEx,
  EditableStringProperty,
  EditableValuePropertyEx
} from '../editables';
import { ClassroomGradingCondition } from '../types';
import { protobufFromClassroomGradingCondition } from '../types/EnumConversion';
import { EditableClassroomContentRepresentation } from './EditableClassroomContentRepresentation';
import { GrpcClassroomCourseWorkMapping } from './GrpcClassroomCourseWorkMapping';
import { ClassroomContentRepresentationModel, ClassroomCourseWorkMappingModel } from './interfaces';

export class EditableClassroomCourseWorkMapping
  extends EditableModelEx<GC.CourseWorkMapping>
  implements ClassroomCourseWorkMappingModel
{
  private _matchingValue: EditableStringProperty<GC.CourseWorkMapping>;
  private _condition: EditableValuePropertyEx<ClassroomGradingCondition, GC.CourseWorkMapping>;
  private _representation: EditableChildNullablePropertyEx<
    GC.ContentRepresentation,
    ClassroomContentRepresentationModel,
    EditableClassroomContentRepresentation,
    GC.CourseWorkMapping
  >;

  static createNew(matchingValue: string): EditableClassroomCourseWorkMapping {
    const pb = new GC.CourseWorkMapping();
    pb.matchingValue = matchingValue;

    return new EditableClassroomCourseWorkMapping(new GrpcClassroomCourseWorkMapping(pb), true);
  }

  constructor(
    readonly originalMapping: ClassroomCourseWorkMappingModel,
    readonly isNew = false
  ) {
    super(originalMapping.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._matchingValue = new EditableStringProperty(
        originalMapping.matchingValue,
        (pb, value) => (pb.matchingValue = value)
      )),
      (this._condition = new EditableValuePropertyEx(
        originalMapping.gradingCondition,
        (pb, value) => (pb.gradingCondition = protobufFromClassroomGradingCondition(value))
      )),
      (this._representation = new EditableChildNullablePropertyEx(
        originalMapping.representation,
        (model) =>
          model == null
            ? EditableClassroomContentRepresentation.createNew()
            : new EditableClassroomContentRepresentation(model),
        (pb, pbValue) => {
          if (pbValue == null) {
            pb.contentRepresentation = undefined;
          } else {
            pb.contentRepresentation = pbValue;
          }
        },
        originalMapping.representation == null
      ))
    ]);
  }

  @computed
  get matchingValue() {
    return this._matchingValue.value;
  }

  set matchingValue(value: string) {
    this._matchingValue.value = value;
  }

  @computed
  get gradingCondition() {
    return this._condition.value;
  }

  set gradingCondition(value: ClassroomGradingCondition) {
    this._condition.value = value;
  }

  @computed
  get representation() {
    return this._representation.value;
  }

  set representation(value: ClassroomContentRepresentationModel | undefined) {
    this._representation.value = value;
  }

  @computed
  get editableRepresentation() {
    return this._representation.editableValue;
  }
}
