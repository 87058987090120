import {
  BrowserDetectorStrings,
  EnglishBrowserDetectorStrings,
  FrenchBrowserDetectorStrings
} from './BrowserDetectorStrings';
import { EnglishErrorBoundaryString, ErrorBoundaryStrings, FrenchErrorBoundaryString } from './ErrorBoundaryStrings';
import { EnglishForbiddenString, ForbiddenStrings, FrenchForbiddenString } from './ForbiddenStrings';
import { EnglishNavigationStrings, FrenchNavigationStrings, NavigationStrings } from './NavigationStrings';
import { EnglishNotFoundStrings, FrenchNotFoundStrings, NotFoundStrings } from './NotFoundStrings';
import {
  EnglishPresenterErrorStrings,
  FrenchPresenterErrorStrings,
  PresenterErrorStrings
} from './PresenterErrorStrings';
import { EnglishRedirectsStrings, FrenchRedirectsStrings, RedirectsStrings } from './RedirectsStrings';
import { EnglishRoutesStrings, FrenchRoutesStrings, RoutesStrings } from './RoutesStrings';
import { AgendaStrings, EnglishAgendaStrings, FrenchAgendaStrings } from './agenda/AgendaStrings';
import { ContentsStrings, EnglishContentsStrings, FrenchContentsStrings } from './contents/ContentsStrings';
import { EnglishOnboardingStrings, FrenchOnboardingStrings, OnboardingStrings } from './onboarding/OnboardingStrings';
import { EnglishPreparingStrings, FrenchPreparingStrings, PreparingStrings } from './preparing/PreparingStrings';
import { EnglishUtilsStrings, FrenchUtilsStrings, UtilsStrings } from './utils/UtilsStrings';

export interface StudyoStrings {
  readonly agenda: AgendaStrings;
  readonly contents: ContentsStrings;
  readonly navigation: NavigationStrings;
  readonly onboarding: OnboardingStrings;
  readonly preparing: PreparingStrings;
  readonly utils: UtilsStrings;
  readonly notFound: NotFoundStrings;
  readonly forbidden: ForbiddenStrings;
  readonly errorBoundary: ErrorBoundaryStrings;
  readonly browserDetector: BrowserDetectorStrings;
  readonly routes: RoutesStrings;
  readonly presenterErrorStrings: PresenterErrorStrings;
  readonly redirects: RedirectsStrings;

  applicationName: string;
  copyright: (year: number) => string;
}

export const EnglishStudyoStrings: StudyoStrings = {
  agenda: EnglishAgendaStrings,
  contents: EnglishContentsStrings,
  navigation: EnglishNavigationStrings,
  onboarding: EnglishOnboardingStrings,
  preparing: EnglishPreparingStrings,
  utils: EnglishUtilsStrings,
  notFound: EnglishNotFoundStrings,
  forbidden: EnglishForbiddenString,
  errorBoundary: EnglishErrorBoundaryString,
  browserDetector: EnglishBrowserDetectorStrings,
  routes: EnglishRoutesStrings,
  presenterErrorStrings: EnglishPresenterErrorStrings,
  redirects: EnglishRedirectsStrings,

  applicationName: 'Studyo',
  copyright: (year) => `© Intuitic Inc. 2013-${year}`
};

export const FrenchStudyoStrings: StudyoStrings = {
  agenda: FrenchAgendaStrings,
  contents: FrenchContentsStrings,
  navigation: FrenchNavigationStrings,
  onboarding: FrenchOnboardingStrings,
  preparing: FrenchPreparingStrings,
  utils: FrenchUtilsStrings,
  notFound: FrenchNotFoundStrings,
  forbidden: FrenchForbiddenString,
  errorBoundary: FrenchErrorBoundaryString,
  browserDetector: FrenchBrowserDetectorStrings,
  routes: FrenchRoutesStrings,
  presenterErrorStrings: FrenchPresenterErrorStrings,
  redirects: FrenchRedirectsStrings,

  applicationName: 'Studyo',
  copyright: (year) => `© Intuitic Inc. 2013-${year}`
};
