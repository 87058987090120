import { PageRangeInfo } from '@insights/models';
import { Day } from '@shared/models/types';
import { dateService } from '@shared/services';
import { action, computed, makeObservable, observable } from 'mobx';
import { DefaultPageLength } from '../../../Constants';

export interface PaginatedViewModel {
  readonly pagination: PaginationViewModel | undefined;
}

export interface PaginationViewModel {
  readonly currentPage: PageRangeInfo;
  readonly pageLength: number;
  readonly startDayOfWeek: number;

  readonly canGoToFirstPage: boolean;
  readonly canGoToLastPage: boolean;
  readonly canGoToPreviousPage: boolean;
  readonly canGoToNextPage: boolean;

  goToFirstPage(): void;
  goToLastPage(): void;
  goToPreviousPage(): void;
  goToNextPage(): void;
  goToDefaultPage(): void;
  goToDayPage(day: Day): void;
}

export abstract class AppPaginatedViewModel implements PaginatedViewModel {
  @observable private _pagination?: PaginationViewModel;

  protected constructor(pagination?: PaginationViewModel) {
    makeObservable(this);
    this._pagination = pagination;
  }

  @computed
  get pagination(): PaginationViewModel | undefined {
    return this._pagination;
  }

  @action
  protected setPagination(value: PaginationViewModel) {
    this._pagination = value;
  }
}

export class AppPaginationViewModel implements PaginationViewModel {
  @observable private _currentPage: PageRangeInfo;

  constructor(
    private readonly _minDay: Day,
    private readonly _maxDay: Day,
    public readonly pageLength: number = DefaultPageLength,
    public readonly startDayOfWeek = 1 // Monday
  ) {
    makeObservable(this);
    this._currentPage = this.getDefaultPage();
  }

  @computed
  private get pageDelta(): number {
    // Avoid having to do -1 every time.
    return this.pageLength - 1;
  }

  @computed
  get currentPage(): PageRangeInfo {
    return this._currentPage;
  }

  @computed
  get canGoToFirstPage(): boolean {
    return this._currentPage.startDay.isAfter(this._minDay);
  }

  @computed
  get canGoToLastPage(): boolean {
    return this._currentPage.endDay.isBefore(this._maxDay);
  }

  @computed
  get canGoToPreviousPage(): boolean {
    return this.canGoToFirstPage;
  }

  @computed
  get canGoToNextPage(): boolean {
    return this.canGoToLastPage;
  }

  @action
  goToFirstPage(): void {
    const startDay = this.getStartDay(this._minDay);
    this._currentPage = {
      startDay,
      endDay: startDay.addDays(this.pageDelta)
    };
  }

  @action
  goToLastPage(): void {
    const startDay = this.getStartDay(this._maxDay);
    this._currentPage = {
      startDay,
      endDay: startDay.addDays(this.pageDelta)
    };
  }

  @action
  goToPreviousPage(): void {
    const startDay = this.getStartDay(this.currentPage.startDay.substractDays(this.pageLength));
    this._currentPage = {
      startDay,
      endDay: startDay.addDays(this.pageDelta)
    };
  }

  @action
  goToNextPage(): void {
    const startDay = this.getStartDay(this.currentPage.startDay.addDays(this.pageLength));
    this._currentPage = {
      startDay,
      endDay: startDay.addDays(this.pageDelta)
    };
  }

  @action
  goToDefaultPage(): void {
    this._currentPage = this.getDefaultPage();
  }

  @action
  goToDayPage(day: Day): void {
    const startDay = this.getStartDay(day);
    this._currentPage = {
      startDay,
      endDay: startDay.addDays(this.pageDelta)
    };
  }

  private getDefaultPage(): PageRangeInfo {
    const day = this.getStartDay(dateService.today);

    return {
      startDay: day,
      endDay: day.addDays(this.pageDelta)
    };
  }

  private getStartDay(targetDay: Day): Day {
    let day = targetDay;

    if (day.isBefore(this._minDay)) {
      day = this._minDay;
    } else if (day.isAfter(this._maxDay)) {
      day = this._maxDay;
    }

    // We only align on the start day of week if the length is a full week.
    // Otherwise, the start day of any page can vary and can even change while
    // moving. E.g.:
    // * Starts on today May 5th
    // * Page length is 3 days
    // * Min date is May 1st
    // => Starts with page [May 5th - May 7th]
    // => Previous page moves to [May 2nd - May 4th]
    // => Previous page clips at [May 1st - May 3rd]
    // => Next page moves to [May 4th - May 6th]
    if (this.pageLength === 7) {
      day = day.substractDays(Math.abs(this.startDayOfWeek - day.dayOfWeekNumber));
    }

    return day;
  }
}
