import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import { SubscriptionEntitlement } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface AddEntitlementDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  availableEntitlements: SubscriptionEntitlement[];
}

export const AddEntitlementDialog = observer((props: AddEntitlementDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx = [], availableEntitlements, configId, onSuccess, onCancel, style } = props;
  const strings = localizationService.localizedStrings.insights.views.features;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createAddEntitlement(configId, availableEntitlements, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="xs"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <DialogTitle>{strings.addEntitlementDialogTitle}</DialogTitle>

          <DialogContent dividers>
            <FormControl fullWidth>
              <InputLabel htmlFor="entitlement-select">{strings.entitlementLabel}</InputLabel>
              <Select
                value={viewModel.entitlement}
                inputProps={{ id: 'entitlement-select' }}
                onChange={(e) => (viewModel.entitlement = e.target.value as SubscriptionEntitlement)}
              >
                {availableEntitlements.map((e) => (
                  <MenuItem key={`entitlement-${e}`} value={e}>
                    <Typography>{strings.getEntitlementLabel(e)}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              sx={{ mt: 1 }}
              label={strings.stripePriceIdLabel}
              value={viewModel.stripePriceId}
              onChange={(e) => (viewModel.stripePriceId = e.target.value)}
            />
          </DialogContent>

          <DialogActions>
            <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
              {strings.cancelButtonCaption}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={!viewModel.canSave}
              isExecuting={viewModel.isSubmitting}
              onClick={() => void viewModel.save()}
            >
              {strings.saveButtonCaption}
            </InsightsButton>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
});
