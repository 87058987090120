import { ImportSessionFileProcessLinkViewModel } from '@insights/viewmodels';
import { Box, Link, MenuItem, SxProps, TextField, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface ImportSessionFileProcessLinkProps {
  viewModel: ImportSessionFileProcessLinkViewModel;
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const ImportSessionFileProcessLink = observer((props: ImportSessionFileProcessLinkProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <Box sx={sx} className={className} style={style}>
      {viewModel.hasUploadedFile && (
        <Typography mb={2} sx={{ color: (theme) => theme.palette.error.main }}>
          {strings.fileAlreadyHasUrlWarning}
        </Typography>
      )}
      <TextField
        fullWidth
        label={strings.onboardingQuestionLabel}
        select
        value={viewModel.question?.templateName}
        onChange={(e) => viewModel.setQuestionByName(e.target.value)}
      >
        {viewModel.availableQuestions.map((info) => (
          <MenuItem
            key={`onboarding-question-${info.question.templateName}`}
            sx={{
              fontStyle: info.question.answer == null ? 'italic' : undefined,
              backgroundColor: info.question.isDependantLocked ? yellow[100] : undefined
            }}
            value={info.question.templateName}
          >
            {info.question.templateName}
          </MenuItem>
        ))}
      </TextField>
      {viewModel.question != null && (
        <Box mt={1} display="flex">
          {viewModel.question.kind === 'file' &&
            (viewModel.question.answer?.fileValue != null ? (
              <Link
                href={viewModel.question.answer.fileValue}
                target="_blank"
                noWrap
                sx={{ width: '100%', direction: 'rtl', textAlign: 'left' }}
              >
                {viewModel.question.answer.fileValue}
              </Link>
            ) : (
              <Typography variant="body2">{strings.noAnswer}</Typography>
            ))}
          {viewModel.question.kind === 'large-text' && <Typography variant="body2">{strings.textContent}</Typography>}
        </Box>
      )}
    </Box>
  );
});
