import {
  EditableDayConfiguration,
  EditableSchedule,
  EditableSchoolYearConfiguration,
  EditableSpecialDay,
  EditableTerm,
  SchoolYearConfigurationModel,
  SchoolYearConfigurationSummary
} from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { dateService } from '@shared/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { differenceInCalendarDays } from 'date-fns';
import _ from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export type ImportMethod = 'none' | 'add' | 'replace';
export type DayConfigurationImportMethod = 'none' | 'special-days-only' | 'schedules-only' | 'all';

export interface SchoolCalendarImportFromViewModel {
  isActive: boolean;
  readonly editableConfig: EditableSchoolYearConfiguration;

  year: number;
  readonly configurations: IPromiseBasedObservable<SchoolYearConfigurationSummary[]>;

  sourceConfigId: string;
  readonly sourceConfig: IPromiseBasedObservable<SchoolYearConfigurationModel | undefined>;

  specialDaysImportMethod: ImportMethod;
  bellTimesImportMethod: ImportMethod;
  termsImportMethod: ImportMethod;
  dayConfigurationsImportMethod: DayConfigurationImportMethod;
  weeksOffset: number;

  readonly canEditDayConfigurationsImportMethod: boolean;
  readonly canImportData: boolean;

  importData(): void;
  close(): void;
}

export class AppSchoolCalendarImportFromViewModel implements SchoolCalendarImportFromViewModel {
  @observable isActive = false;
  @observable private _year: number;
  @observable private _sourceConfigId = '';
  @observable private _specialDaysImportMethod: ImportMethod = 'none';
  @observable private _bellTimesImportMethod: ImportMethod = 'none';
  @observable private _termsImportMethod: ImportMethod = 'none';
  @observable
  private _dayConfigurationsImportMethod: DayConfigurationImportMethod = 'none';
  @observable private _weeksOffset: number | undefined;

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _localizationService: LocalizationService,
    public readonly editableConfig: EditableSchoolYearConfiguration,
    private readonly _onApplied: () => void
  ) {
    makeObservable(this);
    const today = dateService.today;
    this._year = today.month > 7 ? today.year : today.year - 1;
  }

  @computed
  get year(): number {
    return this._year;
  }

  set year(value: number) {
    this._year = Math.max(value, 2015);
    // Forget any selected source config
    this._sourceConfigId = '';
  }

  @computed
  get configurations(): IPromiseBasedObservable<SchoolYearConfigurationSummary[]> {
    return fromPromise(this.loadConfigurations(this._year));
  }

  @computed
  get sourceConfigId(): string {
    return this._sourceConfigId;
  }

  set sourceConfigId(value: string) {
    this._sourceConfigId = value;
    this._weeksOffset = undefined;
  }

  @computed
  get sourceConfig(): IPromiseBasedObservable<SchoolYearConfigurationModel | undefined> {
    if (this._sourceConfigId.length === 0) {
      return fromPromise(Promise.resolve(undefined));
    } else {
      return fromPromise(this._schoolYearConfigurationStore.getConfig(this._sourceConfigId));
    }
  }

  @computed
  get specialDaysImportMethod(): ImportMethod {
    return this._specialDaysImportMethod;
  }

  set specialDaysImportMethod(value: ImportMethod) {
    this._specialDaysImportMethod = value;

    if (value === 'none') {
      switch (this._dayConfigurationsImportMethod) {
        case 'all':
          this._dayConfigurationsImportMethod = 'schedules-only';
          break;
        case 'special-days-only':
          this._dayConfigurationsImportMethod = 'none';
      }
    }
  }

  @computed
  get bellTimesImportMethod(): ImportMethod {
    return this._bellTimesImportMethod;
  }

  set bellTimesImportMethod(value: ImportMethod) {
    this._bellTimesImportMethod = value;

    if (value === 'none') {
      switch (this._dayConfigurationsImportMethod) {
        case 'all':
          this._dayConfigurationsImportMethod = 'special-days-only';
          break;
        case 'schedules-only':
          this._dayConfigurationsImportMethod = 'none';
      }
    }
  }

  @computed
  get termsImportMethod(): ImportMethod {
    return this._termsImportMethod;
  }

  set termsImportMethod(value: ImportMethod) {
    this._termsImportMethod = value;
  }

  @computed
  get dayConfigurationsImportMethod(): DayConfigurationImportMethod {
    return this._dayConfigurationsImportMethod;
  }

  set dayConfigurationsImportMethod(value: DayConfigurationImportMethod) {
    this._dayConfigurationsImportMethod = value;
  }

  @computed
  get defaultWeeksOffset(): number {
    if (this.sourceConfig.state !== 'fulfilled') {
      return 0;
    }

    const oldStart = this.sourceConfig.value?.startDay.asDate;

    if (oldStart == null) {
      return 0;
    }

    const daysOffset = differenceInCalendarDays(this.editableConfig.startDay.asDate, oldStart);
    return (daysOffset - (daysOffset % 7)) / 7;
  }

  @computed
  get weeksOffset(): number {
    return this._weeksOffset ?? this.defaultWeeksOffset;
  }

  set weeksOffset(value: number) {
    if (!Number.isNaN(value)) {
      this._weeksOffset = value;
    }
  }

  @computed
  get canEditDayConfigurationsImportMethod(): boolean {
    return this._specialDaysImportMethod !== 'none' || this._bellTimesImportMethod !== 'none';
  }

  @computed
  get canImportData(): boolean {
    return (
      this.sourceConfig.state === 'fulfilled' &&
      this.sourceConfig.value != null &&
      (this._specialDaysImportMethod != 'none' ||
        this._bellTimesImportMethod != 'none' ||
        this._termsImportMethod != 'none')
    );
  }

  @action
  importData(): void {
    if (!this.canImportData) {
      throw new Error('Invalid operation. Nothing to import.');
    }

    const source = this.sourceConfig;

    if (source.state != 'fulfilled' || source.value == null) {
      throw new Error('Not ready to import.');
    }

    const sourceConfig = source.value;
    const copySuffix = this._localizationService.localizedStrings.insights.viewModels.calendar.copySuffix;

    // Start with bell times, so we can keep potential links in special days.
    // When importing both, we remap new ids.
    const newScheduleIdsByOldId = new Map<string, string>();

    if (this._bellTimesImportMethod !== 'none') {
      let names = new Set();

      if (this._bellTimesImportMethod === 'replace') {
        const ids = new Set(this.editableConfig.schedules.map((s) => s.id));

        this.editableConfig.editDayConfigurations(
          (dc) => ids.has(dc.scheduleId),
          (edc) => edc.markAsDeleted()
        );
        this.editableConfig.editAllSchedules((s) => s.markAsDeleted());
      } else {
        names = new Set(this.editableConfig.schedules.map((s) => s.title));
      }

      sourceConfig.schedules.forEach((s) => {
        const copy = EditableSchedule.cloneAsNew(s);

        this.editableConfig.addSchedule(copy);

        if (names.has(copy.title)) {
          // No deeper collision checks.
          copy.title = copy.title + copySuffix;
        }

        newScheduleIdsByOldId.set(s.id, copy.id);
      });
    }

    // Keep track of old vs new special day ids, so we can
    // potentially recreate day configurations as well.
    const newSpecialDayIdsByOldId = new Map<string, string>();

    if (this._specialDaysImportMethod !== 'none') {
      let names = new Set();

      if (this._specialDaysImportMethod === 'replace') {
        const ids = new Set(this.editableConfig.specialDays.map((sd) => sd.id));

        this.editableConfig.editDayConfigurations(
          (dc) => ids.has(dc.specialDayId),
          (edc) => edc.markAsDeleted()
        );
        this.editableConfig.editAllSpecialDays((sd) => sd.markAsDeleted());
      } else {
        names = new Set(this.editableConfig.specialDays.map((sd) => sd.title));
      }

      sourceConfig.specialDays.forEach((sd) => {
        const copy = EditableSpecialDay.cloneAsNew(sd);

        newSpecialDayIdsByOldId.set(sd.id, copy.id);
        copy.scheduleIds = copy.scheduleIds.map((id) => newScheduleIdsByOldId.get(id) ?? id);

        if (names.has(copy.title)) {
          // No deeper collision checks.
          copy.title = copy.title + copySuffix;
        }

        this.editableConfig.addSpecialDay(copy);
      });
    }

    // Now import day configurations, which depend on the two above.
    if (this._dayConfigurationsImportMethod !== 'none') {
      const offset = this.weeksOffset;

      // Simpler & faster to loop two times without ifs inside.
      if (this._dayConfigurationsImportMethod !== 'schedules-only') {
        sourceConfig.dayConfigurations
          .filter((dc) => dc.specialDayId.length > 0)
          .forEach((dc) => {
            // We ignore special day ids that are not found (broken source config?).
            const specialDayId = newSpecialDayIdsByOldId.get(dc.specialDayId);

            if (specialDayId != null) {
              const copy = EditableDayConfiguration.cloneAsNew(dc);

              if (copy.day != null) {
                // We ignore day-bound day configurations that fall outside new school bounds.
                const day = copy.day.addWeeks(offset);

                if (day.isBefore(this.editableConfig.startDay) || day.isAfter(this.editableConfig.endDay)) {
                  return;
                }

                copy.day = day;
              }

              copy.specialDayId = specialDayId;

              this.editableConfig.addDayConfiguration(copy);
            }
          });
      }

      if (this._dayConfigurationsImportMethod !== 'special-days-only') {
        sourceConfig.dayConfigurations
          .filter((dc) => dc.scheduleId.length > 0)
          .forEach((dc) => {
            // We ignore schedule ids that are not found (broken source config?).
            const scheduleId = newScheduleIdsByOldId.get(dc.scheduleId);

            if (scheduleId != null) {
              const copy = EditableDayConfiguration.cloneAsNew(dc);

              if (copy.day != null) {
                // We ignore day-bound day configurations that fall outside new school bounds.
                const day = copy.day.addWeeks(offset);

                if (day.isBefore(this.editableConfig.startDay) || day.isAfter(this.editableConfig.endDay)) {
                  return;
                }

                copy.day = day;
              }

              copy.scheduleId = scheduleId;

              this.editableConfig.addDayConfiguration(copy);
            }
          });
      }
    }

    // At this point, special days could be pointing to removed schedules. We try to rematch by name.
    // We index all schedules, from source and removed from target, to handle two scenarios:
    //  - Replaced bell times without importing special days => The existing special days must be remapped.
    //  - Added special days without importing bell times => The new special days must be remapped.
    const oldSchedulesById = _.keyBy(sourceConfig.schedules.concat(this.editableConfig.deletedSchedules), (s) => s.id);
    const newScheduleIds = new Set(this.editableConfig.schedules.map((s) => s.id));
    const newSchedulesByTitle = _.groupBy(this.editableConfig.schedules, (s) => s.title);

    this.editableConfig.editAllSpecialDays((sd) => {
      sd.scheduleIds = _.compact(
        sd.scheduleIds.map((id) => {
          if (newScheduleIds.has(id)) {
            return id;
          }

          const oldSchedule = oldSchedulesById[id];

          if (oldSchedule == null) {
            // That's a severe error, but we must live with it.
            console.error(`Special day ${sd.id} was pointing to an unknown schedule ${id}`);
            return null;
          }

          const newSchedules = newSchedulesByTitle[oldSchedule.title];

          if (newSchedules == null || newSchedules.length === 0) {
            // No match by name, we forget.
            return null;
          }

          // We pick the first.
          return newSchedules[0].id;
        })
      );
    });

    if (this._termsImportMethod !== 'none') {
      if (this._termsImportMethod === 'replace') {
        this.editableConfig.editAllTerms((t) => t.markAsDeleted());
      } else {
        this.editableConfig.editTerms(
          (t) => sourceConfig.terms.some((st) => st.tag === t.tag),
          (t) => t.markAsDeleted()
        );
      }

      // Though terms don't have ids, we keep the "cloneAsNew" logic, as good practice.
      sourceConfig.terms.forEach((t) => this.editableConfig.addTerm(EditableTerm.cloneAsNew(t)));
    }

    this.close();
    this._onApplied();
  }

  @action
  close(): void {
    this.isActive = false;
  }

  private async loadConfigurations(year: number): Promise<SchoolYearConfigurationSummary[]> {
    const allConfigs = await this._schoolYearConfigurationStore.getConfigs(year);

    return allConfigs.filter((c) => c.state !== 'archived');
  }
}
