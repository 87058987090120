import { AnalyticsEvent, AnalyticsPage } from '@shared/services/analytics';
import { IntercomEventProperties, WebIntercomAnalyticsService } from '@shared/web/services';
import { InsightsAnalyticsEventCategory, InsightsAnalyticsPage, InsightsAnalyticsService } from './analytics';

interface InsightsEventProperties extends IntercomEventProperties {
  activeScreen?: string;
}

export class InsightsWebIntercomAnalyticsService
  extends WebIntercomAnalyticsService<
    AnalyticsPage<InsightsAnalyticsPage>,
    AnalyticsEvent<InsightsAnalyticsEventCategory>,
    InsightsEventProperties
  >
  implements InsightsAnalyticsService
{
  private _activePage: AnalyticsPage<InsightsAnalyticsPage> | undefined;

  trackPage(page: AnalyticsPage<InsightsAnalyticsPage>) {
    this._activePage = page;
    super.trackPage(page);
  }

  protected buildPageProperties() {
    return {};
  }

  protected buildEventProperties(event: AnalyticsEvent<InsightsAnalyticsEventCategory>): InsightsEventProperties {
    return {
      eventCategory: event.action.category,
      eventLabel: event.label,
      activeScreen: this._activePage?.name ?? 'n/a'
    };
  }
}
