export interface TaskEditStrings {
  attachments: string;
  attachmentsTitle: string;
  copySteps: string;
  duePeriodTagSelectionScreenTitle: string;
  editCancel: string;
  editSave: string;
  iconSelectionScreenTitle: string;
  important: string;
  noneSection: string;
  noPeriodTag: string;
  notesPlaceholder: string;
  private: string;
  publishOnCreation: string;
  reorderSteps: string;
  screenTitleCreate: string;
  screenTitleEdit: string;
  section: string;
  sectionSelectionScreenTitle: string;
  stepDateSelectionTitle: (title: string) => string;
  steps: string;
  stepTitlePlaceholder: string;
  stepUnselectDate: string;
  teacherSteps: string;
  workloadLevel: string;
  workloadLevelSelectionScreenTitle: string;

  longTimeSpanAlertTitle: string;
  longTimeSpanAlertDescription: string;

  unsavedChangesTitle: string;
  unsavedChangesDescription: string;
  unsavedChangesStayTitle: string;
  unsavedChangesDiscardTitle: string;

  savingTitle: string;
  savingDescription: string;
  savedTitle: string;
  savedDescription: string;
  saveErrorTitle: string;
  saveErrorDescription: string;
  savePermanentErrorDescription: string;

  linkedTaskSaveTitle: string;
  linkedTaskSaveDescription: string;
  linkedTaskSaveApplyToAll: string;
  linkedTaskSaveUnlinkAndApply: string;
  linkedTaskSaveCancel: string;
}

export const EnglishTaskEditStrings: TaskEditStrings = {
  attachments: 'Attachments',
  attachmentsTitle: 'Attachments',
  copySteps: 'Copy',
  duePeriodTagSelectionScreenTitle: 'Period',
  editCancel: 'Cancel',
  editSave: 'Save',
  iconSelectionScreenTitle: 'Type',
  important: 'Important',
  noneSection: 'None',
  noPeriodTag: 'None',
  notesPlaceholder: 'Notes…',
  private: 'Private',
  publishOnCreation: 'Publish to section now',
  reorderSteps: 'Reorder steps',
  screenTitleCreate: 'Create Task',
  screenTitleEdit: 'Edit Task',
  section: 'Section',
  sectionSelectionScreenTitle: 'Section',
  stepDateSelectionTitle: EnglishStepDateSelectionTitle,
  steps: 'Steps',
  stepTitlePlaceholder: 'Step title',
  stepUnselectDate: 'No specific date',
  teacherSteps: 'Suggested steps',
  workloadLevel: 'Workload',
  workloadLevelSelectionScreenTitle: 'Load',

  longTimeSpanAlertTitle: 'Long lasting task',
  longTimeSpanAlertDescription:
    'It is strongly recommended that the announcement date be no more than 14 days prior to the due date. This simplifies the task view for students and helps them plan more efficiently. Do you wish to continue?',

  unsavedChangesTitle: 'Unsaved changes',
  unsavedChangesDescription: 'The changes made to this task will be lost. Are you sure you wish to cancel?',
  unsavedChangesStayTitle: 'No',
  unsavedChangesDiscardTitle: 'Discard',

  savingTitle: 'Please wait…',
  savingDescription: 'Saving your task',
  savedTitle: 'Success',
  savedDescription: 'The task was saved successfully!',
  saveErrorTitle: 'Oops, something went wrong!',
  saveErrorDescription: 'An error occurred while saving your task. Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented the save. Please reload and try again.',

  linkedTaskSaveTitle: 'Save Changes',
  linkedTaskSaveDescription: 'This task has multiple occurrences. What would you like to do?',
  linkedTaskSaveApplyToAll: 'Apply to all occurrences',
  linkedTaskSaveUnlinkAndApply: 'Apply to this occurrence only',
  linkedTaskSaveCancel: 'Cancel'
};

function EnglishStepDateSelectionTitle(title: string): string {
  return `Set a date for '${title || 'this step'}'`;
}

export const FrenchTaskEditStrings: TaskEditStrings = {
  attachments: 'Pièces jointes',
  attachmentsTitle: 'Pièces jointes',
  copySteps: 'Copier',
  duePeriodTagSelectionScreenTitle: 'Période',
  editCancel: 'Annuler',
  editSave: 'Sauvegarder',
  iconSelectionScreenTitle: 'Type',
  important: 'Importante',
  noneSection: 'Aucun',
  noPeriodTag: 'Aucune',
  notesPlaceholder: 'Notes…',
  private: 'Privée',
  publishOnCreation: 'Publier au groupe maintenant',
  reorderSteps: 'Réordonner',
  screenTitleCreate: 'Création de tâche',
  screenTitleEdit: 'Édition de tâche',
  section: 'Cours',
  sectionSelectionScreenTitle: 'Cours',
  stepDateSelectionTitle: FrenchStepDateSelectionTitle,
  steps: 'Étapes',
  stepTitlePlaceholder: 'Nom de l’étape',
  stepUnselectDate: 'Aucune date spécifique',
  teacherSteps: 'Étapes suggérées',
  workloadLevel: 'Charge de travail',
  workloadLevelSelectionScreenTitle: 'Charge',

  longTimeSpanAlertTitle: 'Tâche de longue durée',
  longTimeSpanAlertDescription:
    "Il est fortement recommandé d’avoir une date d’annonce d'au plus 14 jours avant la date d’échéance. Ceci permet d’alléger la vue des tâches des élèves et les aider à mieux planifier. Voulez-vous continuer?",

  unsavedChangesTitle: 'Changements non-sauvegardés',
  unsavedChangesDescription: 'Les changements effectués à cette tâche seront perdus. Souhaitez-vous annuler?',
  unsavedChangesStayTitle: 'Non',
  unsavedChangesDiscardTitle: 'Abandonner',

  savingTitle: 'Veuillez patienter…',
  savingDescription: 'Sauvegarde de la tâche en cours',
  savedTitle: 'Succès',
  savedDescription: 'La tâche a été sauvegardée!',
  saveErrorTitle: 'Oups, une erreur est survenue!',
  saveErrorDescription: 'La tâche n’a pu être sauvegardée. Veuillez réessayer.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la sauvegarde. Veuillez rafraichir et réessayer.',

  linkedTaskSaveTitle: 'Sauvegarde',
  linkedTaskSaveDescription: 'Cette tâche a plusieurs occurrences. Que souhaitez-vous faire?',
  linkedTaskSaveApplyToAll: 'Appliquer à toutes les occurrences',
  linkedTaskSaveUnlinkAndApply: 'Appliquer à cette occurrence seulement',
  linkedTaskSaveCancel: 'Annuler'
};

function FrenchStepDateSelectionTitle(title: string): string {
  return `Assigner une date à « ${title || 'cette étape'} »`;
}
