import { ContentService, NavigationService, SettingsStore } from '@insights/services';
import { SectionUtils, caseInsensitiveAccentInsensitiveCompare } from '@insights/utils';
import { SchoolDay } from '@shared/models/calendar';
import { AccountModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { Day } from '@shared/models/types';
import { CalendarStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { Dictionary, flatMap, groupBy, uniq } from 'lodash';
import { Thresholds } from '../../../Constants';
import { AppPaginationViewModel, PaginationViewModel } from './PaginatedViewModel';
import { AppWorkloadManagerViewModelBase, WorkloadManagerViewModelBase } from './WorkloadManagerViewModelBase';

export interface WorkloadManagerBySectionInfo {
  readonly minDay: Day;
  readonly maxDay: Day;
  readonly sections: WorkloadManagerBySectionSectionInfo[];
  readonly schoolDays: SchoolDay[];
}

export interface WorkloadManagerBySectionSectionInfo {
  readonly section: SectionModel;
  readonly dailyThreshold: number;
  readonly weeklyThreshold: number;
  readonly students: Map<string, AccountModel>;
}

export interface WorkloadManagerBySectionPageInfo {
  readonly schoolDays: SchoolDay[];
  readonly dayInfos: WorkloadManagerBySectionColumnInfo[];
  readonly weekInfos: WorkloadManagerBySectionColumnInfo[];
}

export interface WorkloadManagerBySectionColumnInfo {
  readonly sectionId: string;
  readonly schoolDay?: SchoolDay;
  readonly hasCourseOccurrence: boolean;
  readonly publishedTaskCount: number;
  readonly studentIdsAtThreshold: string[];
  readonly studentIdsOverThreshold: string[];
}

export interface WorkloadManagerBySectionViewModel
  extends WorkloadManagerViewModelBase<WorkloadManagerBySectionInfo, WorkloadManagerBySectionPageInfo> {
  readonly sectionIds: string[];

  showDetail(
    sectionId: string,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    studentIdsAtThreshold: string[],
    studentIdsOverThreshold: string[]
  ): Promise<void>;
}

export class AppWorkloadManagerBySectionViewModel
  extends AppWorkloadManagerViewModelBase<WorkloadManagerBySectionInfo, WorkloadManagerBySectionPageInfo>
  implements WorkloadManagerBySectionViewModel
{
  constructor(
    public readonly configId: string,
    public readonly sectionIds: string[],
    private readonly _calendarStore: CalendarStore,
    private readonly _contentService: ContentService,
    navigationService: NavigationService,
    schoolYearConfigurationStore: SchoolYearConfigurationStore,
    settingsStore: SettingsStore,
    paginationViewModel?: PaginationViewModel
  ) {
    super(configId, navigationService, schoolYearConfigurationStore, settingsStore, paginationViewModel);
  }

  async showDetail(
    sectionId: string,
    fromDay: SchoolDay,
    toDay: SchoolDay,
    studentIdsAtThreshold: string[],
    studentIdsOverThreshold: string[]
  ): Promise<void> {
    await this._navigationService.navigateToWorkloadManagerBySectionDetail(
      this.configId,
      sectionId,
      fromDay,
      toDay,
      this.examOnly,
      studentIdsAtThreshold,
      studentIdsOverThreshold
    );
  }

  protected async loadData(): Promise<WorkloadManagerBySectionInfo> {
    const [config, sections, schoolDays] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this.configId),
      this._schoolYearConfigurationStore.getSectionsByIds(this.configId, this.sectionIds),
      this._calendarStore.getSchoolDays(this.configId, this.sectionIds)
    ]);

    if (this.pagination == null) {
      this.setPagination(new AppPaginationViewModel(config.startDay, config.endDay));
    }

    const thresholdsByGrade = new Map(config.workloadThresholds.map((t) => [t.gradeLevel, t]));

    return {
      minDay: config.startDay,
      maxDay: config.endDay,
      schoolDays: schoolDays,
      sections: (
        await Promise.all(
          sections.map<Promise<WorkloadManagerBySectionSectionInfo>>(async (section) => {
            const studentsForSection = await this._schoolYearConfigurationStore.getStudentsForSection(
              this.configId,
              section,
              false
            );
            const thresholds = thresholdsByGrade.get(section.gradeLevel);

            return {
              section,
              dailyThreshold:
                (thresholds?.dailyThreshold ?? config.dailyWorkloadThreshold) || Thresholds.defaultDailyThreshold,
              weeklyThreshold:
                (thresholds?.weeklyThreshold ?? config.weeklyWorkloadThreshold) || Thresholds.defaultWeeklyThreshold,
              students: new Map(studentsForSection.map((s) => [s.id, s]))
            };
          })
        )
      ).sort((a, b) => {
        if (a.section.title.trim().length === 0) {
          return 1;
        }
        if (b.section.title.trim().length === 0) {
          return -1;
        }

        return (
          caseInsensitiveAccentInsensitiveCompare(
            SectionUtils.formatTitle(a.section),
            SectionUtils.formatTitle(b.section)
          ) ||
          caseInsensitiveAccentInsensitiveCompare(a.section.sectionNumber, b.section.sectionNumber, undefined, true)
        );
      })
    };
  }

  protected async loadPageData(): Promise<WorkloadManagerBySectionPageInfo> {
    const pageRange = this.pagination?.currentPage;

    if (pageRange == null) {
      return {
        schoolDays: [],
        dayInfos: [],
        weekInfos: []
      };
    }

    const examOnly = this.examOnly;
    const data = await this.data;

    // Get the student ids of all the students of all the displayed sections
    const studentIds = uniq(flatMap(data.sections.map((section) => [...section.students.keys()])));

    const [studentTasksForWeek, publishedTasksForWeek] = await Promise.all([
      this._contentService.getImportantSlaveTasks(
        this.configId,
        studentIds,
        pageRange.startDay,
        pageRange.endDay,
        examOnly
      ),
      this._contentService.getImportantMasterTasks(
        this.configId,
        this.sectionIds,
        pageRange.startDay,
        pageRange.endDay,
        examOnly
      )
    ]);

    const schoolDays = data.schoolDays.filter((sd) => sd.day.isWithin(pageRange.startDay, pageRange.endDay));
    const studentTasksByDay = groupBy(studentTasksForWeek, (t) => t.dueDay.asString);
    const publishedTasksByDay = groupBy(publishedTasksForWeek, (t) => t.dueDay.asString);

    return {
      schoolDays: schoolDays,
      dayInfos: this.computeDayInfos(schoolDays, data.sections, studentTasksByDay, publishedTasksByDay),
      weekInfos: this.computeWeekInfos(data.sections, studentTasksForWeek, publishedTasksForWeek)
    };
  }

  private computeDayInfos(
    schoolDays: SchoolDay[],
    sections: WorkloadManagerBySectionSectionInfo[],
    tasksByDay: Dictionary<ContentDefinitionModel[]>,
    publishedTasksByDay: Dictionary<ContentDefinitionModel[]>
  ): WorkloadManagerBySectionColumnInfo[] {
    return flatMap(
      schoolDays.map<WorkloadManagerBySectionColumnInfo[]>((sd) => {
        return sections.map<WorkloadManagerBySectionColumnInfo>((sectionInfo) => {
          // Get the published tasks for the intersection of the day and section
          const publishedTasksForDay = publishedTasksByDay[sd.day.asString] || [];
          const publishedTasksForSection = publishedTasksForDay.filter((t) => t.sectionId === sectionInfo.section.id);

          // Get the tasks for the day
          const tasksForDay = tasksByDay[sd.day.asString] || [];

          const { studentIdsAtThreshold, studentIdsOverThreshold } = this.computeThresholds(
            tasksForDay,
            sectionInfo.dailyThreshold,
            sectionInfo.students
          );

          return {
            schoolDay: sd,
            sectionId: sectionInfo.section.id,
            studentIdsAtThreshold: studentIdsAtThreshold,
            studentIdsOverThreshold: studentIdsOverThreshold,
            hasCourseOccurrence: sd.hasCourseOccurrence(sectionInfo.section.id),
            publishedTaskCount: publishedTasksForSection.length
          };
        });
      })
    );
  }

  private computeWeekInfos(
    sections: WorkloadManagerBySectionSectionInfo[],
    tasksForWeek: ContentDefinitionModel[],
    publishedTasks: ContentDefinitionModel[]
  ): WorkloadManagerBySectionColumnInfo[] {
    return sections.map<WorkloadManagerBySectionColumnInfo>((sectionInfo) => {
      const publishedTasksForSection = publishedTasks.filter((t) => t.sectionId === sectionInfo.section.id);

      const { studentIdsAtThreshold, studentIdsOverThreshold } = this.computeThresholds(
        tasksForWeek,
        sectionInfo.weeklyThreshold,
        sectionInfo.students
      );

      return {
        sectionId: sectionInfo.section.id,
        studentIdsAtThreshold: studentIdsAtThreshold,
        studentIdsOverThreshold: studentIdsOverThreshold,
        hasCourseOccurrence: false,
        publishedTaskCount: publishedTasksForSection.length
      };
    });
  }
}
