import * as APB from '@buf/studyo_studyo.bufbuild_es/studyo/services/auth_pb';
import { UserProfile } from '@buf/studyo_studyo.bufbuild_es/studyo/type_auth_pb';
import { Auth } from '@buf/studyo_studyo.connectrpc_es/studyo/services/auth_connect';
import { UserTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class UserGrpcTransport extends BaseGrpcTransport implements UserTransport {
  async fetchUserProfile(): Promise<UserProfile> {
    console.log(`Fetching user profile...`);

    const request = new APB.GetUserProfileRequest();

    const response = await this.performRequest(Auth, Auth.methods.getUserProfile, request);

    console.log(`Successfully fetched user profile.`);

    if (!response.userProfile) {
      throw new Error('Unexpected result from backend: a GetUserProfile request did not return a user profile.');
    }

    return response.userProfile;
  }

  async fetchUserProfileForUserId(userId: string): Promise<UserProfile> {
    console.log(`Fetching user profile for user id ${userId}...`);

    const request = new APB.GetUserProfileRequest();
    request.userId = userId;

    const response = await this.performRequest(Auth, Auth.methods.getUserProfile, request);

    console.log(`Successfully fetched user profile.`);

    if (!response.userProfile) {
      throw new Error('Unexpected result from backend: a GetUserProfile request did not return a user profile.');
    }

    return response.userProfile;
  }

  async fetchUserProfilesForEmail(
    email: string,
    includeConfigSummaries: boolean,
    configId?: string
  ): Promise<UserProfile[]> {
    console.log(`Fetching user profiles for email ${email}...`);

    const request = new APB.GetUserProfilesRequest();

    request.predicate = { case: 'email', value: email };
    request.includeConfigSummaries = includeConfigSummaries;

    if (configId != null) {
      request.configId = configId;
    }

    const response = await this.performRequest(Auth, Auth.methods.getUserProfiles, request);

    console.log(`Successfully fetched user profiles.`);

    return response.userProfiles;
  }

  async fetchUserProfilesForSearchText(
    searchText: string,
    includeConfigSummaries: boolean,
    configId?: string
  ): Promise<[UserProfile[], boolean]> {
    console.log(`Fetching user profiles for search text ${searchText}...`);

    const request = new APB.GetUserProfilesRequest();

    request.predicate = { case: 'searchText', value: searchText };
    request.includeConfigSummaries = includeConfigSummaries;

    if (configId != null) {
      request.configId = configId;
    }

    const response = await this.performRequest(Auth, Auth.methods.getUserProfiles, request);

    console.log(`Successfully fetched user profiles.`);

    return [response.userProfiles, response.partialResults];
  }

  async requestPermanentUserDataDeletion(userId: string): Promise<void> {
    console.log(`Requesting permanent user data deletion for ${userId}...`);

    const identification = new APB.UserIdentification();
    identification.identifier = { case: 'userId', value: userId };

    const request = new APB.RequestPermanentUserDataDeletionRequest();
    request.userIdentification = identification;

    await this.performRequest(Auth, Auth.methods.requestPermanentUserDataDeletion, request);

    console.log(`Successfully requested permanent user data deletion.`);
  }

  async deleteUser(userId: string): Promise<void> {
    console.log(`Deleting user ${userId}...`);

    const request = new APB.DeleteUserRequest({ userId: userId });

    await this.performRequest(Auth, Auth.methods.deleteUser, request);

    console.log(`Successfully deleted user.`);
  }
}
