import { Day } from '@shared/models/types';
import { add, addMinutes, getHours, getMinutes, getSeconds, startOfDay } from 'date-fns';
import { isEqual } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

/**
 * Strict thresholds to make humanize to be precise
 * @see https://day.js.org/docs/en/customization/relative-time#relative-time-thresholds-and-rounding
 */
// const thresholds = [
//   { l: 's', r: 59, d: 'second' },
//   { l: 'm', r: 1 },
//   { l: 'mm', r: 59, d: 'minute' },
//   { l: 'h', r: 1 },
//   { l: 'hh', r: 23, d: 'hour' },
//   { l: 'd', r: 1 },
//   { l: 'dd', r: 29, d: 'day' },
//   { l: 'M', r: 1 },
//   { l: 'MM', r: 11, d: 'month' },
//   { l: 'y' },
//   { l: 'yy', d: 'year' },
// ];
// dayjs.extend(relativeTime, { thresholds });

class DateService {
  private _mockedDateTime = new Date();
  private _realDateTime = new Date();

  @observable private _isMocked = false;
  @observable private _mockedDate: Date;
  @observable private _realDate: Date;

  constructor() {
    makeObservable(this);
    this._mockedDate = startOfDay(this._mockedDateTime);
    this._realDate = startOfDay(this._realDateTime);

    // We update every minute, just to catch the midnight change.
    // In a future version, we might expose the date and time in
    // this service as well.
    setInterval(() => {
      this._mockedDateTime = addMinutes(this._mockedDateTime, 1);
      this._realDateTime = new Date();

      // mobx can't compare Dayjs instances. They're all different, so we
      // avoid setting observable fields for nothing.
      const mockedDate = startOfDay(this._mockedDateTime);
      const realDate = startOfDay(this._realDateTime);

      runInAction(() => {
        if (!isEqual(mockedDate, this._mockedDate)) {
          this._mockedDate = mockedDate;
        }

        if (!isEqual(realDate, this._realDate)) {
          this._realDate = realDate;
        }
      });
    }, 60000);
  }

  @computed
  get isMocked() {
    return this._isMocked;
  }

  @computed
  get today() {
    return Day.fromDate(this._isMocked ? this._mockedDate : this._realDate)!;
  }

  @computed
  get realToday() {
    return Day.fromDate(this._realDate)!;
  }

  @action
  mockToday(today: Day) {
    const todayAsDate = today.asDate;

    this._isMocked = true;
    this._mockedDateTime = add(todayAsDate, {
      hours: getHours(this._realDateTime),
      minutes: getMinutes(this._realDateTime),
      seconds: getSeconds(this._realDateTime)
    });
    this._mockedDate = todayAsDate;
  }

  @action
  unmockToday() {
    this._isMocked = false;
  }
}

export const dateService = new DateService();
