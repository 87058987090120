import { AccountService } from '@insights/services';
import { computed, makeObservable } from 'mobx';

export interface NotFoundViewModel {
  readonly isLoggedIn: boolean;
}

export class AppNotFoundViewModel implements NotFoundViewModel {
  constructor(private readonly _accountService: AccountService) {
    makeObservable(this);
  }

  @computed
  get isLoggedIn(): boolean {
    return this._accountService.isLoggedIn;
  }
}
