import { ImportSessionListViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid2,
  IconButton,
  InputLabel,
  Stack,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { AutomatedImportItem } from './AutomatedImportItem.tsx';

export interface ImportSessionListProps {
  viewModel: ImportSessionListViewModel;
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title?: string;
}

export const ImportSessionList = (props: ImportSessionListProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;
  const navigate = useNavigate();

  return (
    <Grid2 container sx={sx} className={className} style={style}>
      {viewModel.sessions.map((sessionInfo) => (
        <Grid2 key={`session-${sessionInfo.session.id}`} size={{ xs: 12, sm: 6 }}>
          <Card sx={{ display: 'block' }}>
            <CardHeader
              title={
                <Button
                  sx={{ textTransform: 'none', p: 0 }}
                  variant="text"
                  onClick={() => void viewModel.navigateToSessionDetails(sessionInfo.session.id, navigate)}
                >
                  <Typography variant="h6">{sessionInfo.session.name}</Typography>
                </Button>
              }
              action={
                <Tooltip title={strings.editSessionDetails}>
                  <IconButton onClick={() => void viewModel.editSessionDetails(sessionInfo.session)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              }
            />
            <Divider />
            <CardContent>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12 }}>
                  <InputLabel shrink>{strings.description}</InputLabel>
                  <Typography
                    sx={{
                      whiteSpace: 'pre-line'
                    }}
                  >
                    {sessionInfo.session.description}
                  </Typography>
                </Grid2>

                <Grid2 size={{ xs: 6 }}>
                  <InputLabel shrink>{strings.fileCount}</InputLabel>
                  <Typography>{sessionInfo.session.expectedFiles.length}</Typography>
                </Grid2>
                <Grid2 size={{ xs: 6 }}>
                  <InputLabel shrink>{strings.transformationCount}</InputLabel>
                  <Typography>{sessionInfo.session.transformations.length}</Typography>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                  <InputLabel shrink>{strings.automatedImportsLabel}</InputLabel>
                  {sessionInfo.automatedImports.automatedImports.length == 0 ? (
                    <Typography>{strings.noAutomatedImports}</Typography>
                  ) : (
                    <Stack>
                      {sessionInfo.automatedImports.automatedImports.map((automatedImport) => (
                        <AutomatedImportItem
                          key={`automated-import-${automatedImport.id}`}
                          viewModel={sessionInfo.automatedImports}
                          automatedImport={automatedImport}
                        />
                      ))}
                    </Stack>
                  )}
                </Grid2>
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
      ))}
      {viewModel.sessions.length === 0 && (
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <Card sx={{ display: 'block' }}>
            <CardHeader
              title={strings.noSession}
              action={
                <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                  <Tooltip title={strings.addNewSession}>
                    <IconButton onClick={() => void viewModel.addSession()}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </AuthorizationRoleCondition>
              }
            />
          </Card>
        </Grid2>
      )}
    </Grid2>
  );
};
