export interface NoteEditStrings {
  readonly title: string;
  readonly notesPlaceholder: string;
  readonly noSectionTitle: string;
  displayPeriodTag: (tag: string) => string;

  readonly cancel: string;
  readonly save: string;
  readonly close: string;

  readonly teacherEmailMenuTitle: string;
  readonly teacherEmailMenuMessage: string;

  readonly unsavedChangesTitle: string;
  readonly unsavedChangesDescription: string;
  readonly unsavedChangesStayTitle: string;
  readonly unsavedChangesDiscardTitle: string;

  readonly savingTitle: string;
  readonly savingDescription: string;
  readonly savedTitle: string;
  readonly savedDescription: string;
  readonly saveErrorTitle: string;
  readonly saveErrorDescription: string;
  readonly savePermanentErrorDescription: string;
}

export const EnglishNoteEditStrings: NoteEditStrings = {
  title: 'Notes',
  notesPlaceholder: 'Notes…',
  noSectionTitle: 'Free',
  displayPeriodTag: (tag) => `Period ${tag}`,

  cancel: 'Cancel',
  save: 'Save',
  close: 'Close',

  teacherEmailMenuMessage: 'Select which teacher you want to join',
  teacherEmailMenuTitle: 'Email',

  unsavedChangesTitle: 'Unsaved changes',
  unsavedChangesDescription: 'The changes made to this note will be lost. Are you sure you wish to cancel?',
  unsavedChangesStayTitle: 'No',
  unsavedChangesDiscardTitle: 'Discard',

  savingTitle: 'Please wait…',
  savingDescription: 'Saving your note',
  savedTitle: 'Success',
  savedDescription: 'The note was saved successfully!',
  saveErrorTitle: 'Oops, something went wrong!',
  saveErrorDescription: 'An error occurred while saving your note. Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented the save. Please reload and try again.'
};

export const FrenchNoteEditStrings: NoteEditStrings = {
  title: 'Notes',
  notesPlaceholder: 'Notes…',
  noSectionTitle: 'Libre',
  displayPeriodTag: (tag) => `Période ${tag}`,

  cancel: 'Annuler',
  save: 'Sauvegarder',
  close: 'Fermer',

  teacherEmailMenuMessage: 'Sélectionnez quel enseignant joindre',
  teacherEmailMenuTitle: 'Courriel',

  unsavedChangesTitle: 'Changements non-sauvegardés',
  unsavedChangesDescription: 'Les changements effectués à cette note seront perdus. Souhaitez-vous annuler?',
  unsavedChangesStayTitle: 'Non',
  unsavedChangesDiscardTitle: 'Abandonner',

  savingTitle: 'Veuillez patienter…',
  savingDescription: 'Sauvegarde de la note en cours',
  savedTitle: 'Succès',
  savedDescription: 'La note a été sauvegardée!',
  saveErrorTitle: 'Oups, une erreur est survenue!',
  saveErrorDescription: 'La note n’a pu être sauvegardée. Veuillez réessayer.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la sauvegarde. Veuillez rafraichir et réessayer.'
};
