import * as C from '@buf/studyo_studyo.bufbuild_es/studyo/type_canvas_pb';
import { computed, makeObservable } from 'mobx';
import {
  EditableChildNullablePropertyEx,
  EditableModelEx,
  EditableStringProperty,
  EditableValuePropertyEx
} from '../editables';
import { CanvasGradingCondition } from '../types';
import { protobufFromCanvasGradingCondition } from '../types/EnumConversion';
import { EditableCanvasContentRepresentation } from './EditableCanvasContentRepresentation';
import { GrpcCanvasAssignmentGroupMapping } from './GrpcCanvasAssignmentGroupMapping';
import { CanvasAssignmentGroupMappingModel, CanvasContentRepresentationModel } from './interfaces';

export class EditableCanvasAssignmentGroupMapping
  extends EditableModelEx<C.AssignmentGroupMapping>
  implements CanvasAssignmentGroupMappingModel
{
  private _name: EditableStringProperty<C.AssignmentGroupMapping>;
  private _condition: EditableValuePropertyEx<CanvasGradingCondition, C.AssignmentGroupMapping>;
  private _representation: EditableChildNullablePropertyEx<
    C.ContentRepresentation,
    CanvasContentRepresentationModel,
    EditableCanvasContentRepresentation,
    C.AssignmentGroupMapping
  >;

  static createNew(groupName: string): EditableCanvasAssignmentGroupMapping {
    const pb = new C.AssignmentGroupMapping();
    pb.assignmentGroupName = groupName;

    return new EditableCanvasAssignmentGroupMapping(new GrpcCanvasAssignmentGroupMapping(pb), true);
  }

  constructor(
    readonly originalMapping: CanvasAssignmentGroupMappingModel,
    readonly isNew = false
  ) {
    super(originalMapping.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._name = new EditableStringProperty(originalMapping.name, (pb, value) => (pb.assignmentGroupName = value))),
      (this._condition = new EditableValuePropertyEx(
        originalMapping.gradingCondition,
        (pb, value) => (pb.gradingCondition = protobufFromCanvasGradingCondition(value))
      )),
      (this._representation = new EditableChildNullablePropertyEx(
        originalMapping.representation,
        (model) =>
          model == null
            ? EditableCanvasContentRepresentation.createNew()
            : new EditableCanvasContentRepresentation(model),
        (pb, pbValue) => {
          pb.contentRepresentation = pbValue;
        },
        originalMapping.representation == null
      ))
    ]);
  }

  @computed
  get name() {
    return this._name.value;
  }

  set name(value: string) {
    this._name.value = value;
  }

  @computed
  get gradingCondition() {
    return this._condition.value;
  }

  set gradingCondition(value: CanvasGradingCondition) {
    this._condition.value = value;
  }

  @computed
  get representation() {
    return this._representation.value;
  }

  set representation(value: CanvasContentRepresentationModel | undefined) {
    this._representation.value = value;
  }

  @computed
  get editableRepresentation() {
    return this._representation.editableValue;
  }
}
