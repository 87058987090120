import {
  ObservablePresenter,
  PublishedTasksDetailTaskItem,
  SchoolDayHeader,
  SchoolWeekHeader,
  SectionName,
  useViewModelRef,
  VirtualizedList,
  VirtualizedRow
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { SectionUtils } from '@insights/utils';
import {
  PublishedTasksByGradeDetailViewModel,
  PublishedTasksDetailInfo,
  PublishedTasksDetailSectionInfo,
  PublishedTasksDetailTaskInfo
} from '@insights/viewmodels';
import {
  Box,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { SectionUtils as SharedSectionUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { SectionColors } from '@shared/models/Colors';
import { ContentDefinitionModel } from '@shared/models/content';
import { LocalizationService } from '@shared/resources/services';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface PublishedTasksByGradeDetailProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  gradeLevel: string;
  gradeLevelSectionCount: number;
  fromDay: SchoolDay;
  toDay: SchoolDay;
  tasks: ContentDefinitionModel[];
}

export const PublishedTasksByGradeDetail = observer((props: PublishedTasksByGradeDetailProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const {
    sx = [],
    className,
    style,
    fromDay,
    toDay,
    tasks,
    gradeLevel,
    gradeLevelSectionCount,
    configId,
    onCancel,
    onSuccess
  } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createPublishedTasksByGradeDetail(
      configId,
      gradeLevel,
      gradeLevelSectionCount,
      tasks,
      onSuccess!,
      onCancel!
    )
  );

  const isWeek = !fromDay.day.isSame(toDay.day);

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        maxWidth="md"
        fullWidth={true}
        onClose={() => void viewModel.close()}
        scroll="paper"
        className={className}
        style={style}
      >
        <DialogTitle>
          {/* Ensure the overflow is visible so that the badge is displayed */}
          <Box display="flex" flexDirection="row" alignItems="center" overflow="visible">
            <SectionName
              color="darker-bmgray"
              title={viewModel.gradeLevel}
              subInformation1={strings.localizedNumberOfSections(viewModel.gradeLevelSectionCount)}
              size="medium"
            />

            <Box flex={1} />

            {!isWeek && <SchoolDayHeader schoolDay={fromDay} displayType="page-header" />}
            {isWeek && <SchoolWeekHeader schoolDay={fromDay} displayType="page-header" />}
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <ObservablePresenter
            sx={{ minHeight: 100 }}
            data={viewModel.data}
            render={(data) => renderData(data, viewModel, localizationService)}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => void viewModel.close()}>
            {strings.close}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});

function renderData(
  data: PublishedTasksDetailInfo,
  viewModel: PublishedTasksByGradeDetailViewModel,
  localizationService: LocalizationService
) {
  /**
   * Total height of space in screen not occupied by the virtualized list.
   * No choice to do this to force the virtualized list to take as much space as possible
   * since we can't specify a height of 100% on a virtualized list.
   */
  const screenContentHeight =
    dialogVerticalMargin * 2 +
    dialogTitleHeight +
    dialogFooterHeight +
    dialogContentVerticalPadding * 2 +
    dividerHeight * 2;

  return (
    <Box width="100%" height={`calc(100vh - ${screenContentHeight}px)`}>
      <VirtualizedList
        sections={data.sections.map((section) => ({
          rowCount: section.tasks.length
        }))}
        getRowHeight={getRowHeight}
        renderHeader={(section) => renderHeader(data.sections[section], section === 0, localizationService)}
        renderItem={(section, index) =>
          renderItem(data.sections[section], data.sections[section].tasks[index], data, viewModel)
        }
      />
    </Box>
  );
}

function renderHeader(
  sectionInfo: PublishedTasksDetailSectionInfo,
  isFirst: boolean,
  localizationService: LocalizationService
) {
  const strings = localizationService.localizedStrings.insights.components.tasks;

  return (
    <Box marginTop={isFirst ? 0 : 4}>
      <Box bgcolor={grey[100]}>
        <SectionName
          title={SectionUtils.formatTitle(sectionInfo.section)}
          subInformation1={sectionInfo.section.sectionNumber}
          subInformation2={SharedSectionUtils.getSectionShortTeacherInfo(
            sectionInfo.section,
            sectionInfo.teachers,
            strings.noTeacher
          )}
          color={sectionInfo.section.color}
          mode="one-line"
          fontWeight="400"
        />
      </Box>
    </Box>
  );
}

function renderItem(
  sectionInfo: PublishedTasksDetailSectionInfo,
  taskInfo: PublishedTasksDetailTaskInfo,
  data: PublishedTasksDetailInfo,
  viewModel: PublishedTasksByGradeDetailViewModel
) {
  return (
    <Box borderLeft={`4px solid ${SectionColors.get(sectionInfo.section.color)}`} paddingLeft={1} overflow="hidden">
      <PublishedTasksDetailTaskItem
        sx={{ my: 0.5 }}
        taskInfo={taskInfo}
        onClick={() =>
          void viewModel.showDetail(sectionInfo, taskInfo, data.schoolYearConfiguration, data.sectionsById)
        }
        schoolYearConfig={data.schoolYearConfiguration}
        sectionsById={data.sectionsById}
      />
    </Box>
  );
}

function getRowHeight(row?: VirtualizedRow): number {
  return row == null ? 0 : row.isHeader ? 30 + (row.section === 0 ? 0 : 32) : 49;
}

/**
 * Height of elements composing the dialog
 */
const dialogVerticalMargin = 32;
const dialogTitleHeight = 99.5;
const dialogFooterHeight = 52.5;
const dialogContentVerticalPadding = 16;
const dividerHeight = 1;
