import { AccountInfo, SectionInfo } from '@insights/models';
import { NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppSectionsViewModel } from '../metrics';

export interface LoadingAccountsSectionsEditionCopyViewModel {
  readonly configId: string;
  readonly accountId: string;

  readonly data: IPromiseBasedObservable<AccountSectionsEditionCopyViewModel>;

  close(): void;
}

export interface AccountSectionsEditionCopyViewModel {
  readonly students: AccountInfo[];
  readonly configId: string;

  copy(student: AccountInfo): Promise<void>;
}

export class AppLoadingAccountSectionsEditionCopyViewModel implements LoadingAccountsSectionsEditionCopyViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _localizationService: LocalizationService,
    private readonly _onSuccess: (sectionIds: string[]) => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly accountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<AccountSectionsEditionCopyViewModel> {
    return fromPromise(this.loadData());
  }

  close() {
    this._onCancel();
  }

  private async loadData() {
    const students = await this._schoolYearConfigurationStore.getStudents(this.configId, false);

    const accounts = await Promise.all(
      students.map<Promise<AccountInfo>>(async (student) => {
        const sections = await this._schoolYearConfigurationStore.getSectionsForStudent(this.configId, student);

        return {
          id: student.id,
          account: student,
          sections: new AppSectionsViewModel(
            this._navigationService,
            this._localizationService,
            this.configId,
            sections.map<SectionInfo>((section) => ({ id: section.id, section }))
          ),
          parents: [],
          children: [],
          pendingChildren: []
        };
      })
    );

    return new AppAccountsSectionsEditionCopyViewModel(this._onSuccess, accounts, this.configId);
  }
}

export class AppAccountsSectionsEditionCopyViewModel implements AccountSectionsEditionCopyViewModel {
  constructor(
    private readonly _onSuccess: (studentIds: string[]) => void,
    readonly students: AccountInfo[],
    readonly configId: string
  ) {}

  async copy(student: AccountInfo) {
    const sectionIds = student.sections?.sections.map((s) => s.id) ?? [];
    this._onSuccess(sectionIds);
    return Promise.resolve();
  }
}
