import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/services/importer_pb';
import * as IPB from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { Importer } from '@buf/studyo_studyo.connectrpc_es/studyo/services/importer_connect';
import { SourceFileUploadUrlResponse } from '@shared/models/import';
import { ImporterTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class ImporterGrpcTransport extends BaseGrpcTransport implements ImporterTransport {
  async fetchImportSessions(configId: string): Promise<IPB.ImportSession[]> {
    console.log(`Fetching import sessions for [configId: ${configId}]...`);

    const request = new PB.GetImportSessionsRequest();
    request.configId = configId;

    const response = await this.performRequest(Importer, Importer.methods.getImportSessions, request);

    console.log('Successfully fetched import sessions.');

    return response.sessions;
  }

  async fetchImportSession(sessionId: string, includeData: boolean): Promise<IPB.ImportSession> {
    console.log(`Fetching import session for [sessionId: ${sessionId}, includeData: ${includeData}]...`);

    const request = new PB.GetImportSessionRequest();
    request.sessionId = sessionId;
    request.includeData = includeData;

    const response = await this.performRequest(Importer, Importer.methods.getImportSession, request);

    console.log('Successfully fetched import session.');

    const pbSession = response.session;

    if (pbSession == null) {
      throw new Error('Unexpected result from backend: A GetImportSession request did not return a session.');
    }

    return pbSession;
  }

  async fetchImportOperations(): Promise<IPB.Operation[]> {
    console.log('Fetching import operations...');

    const request = new PB.GetOperationsRequest();
    const response = await this.performRequest(Importer, Importer.methods.getOperations, request);

    console.log('Successfully fetched operations.');

    return response.operations;
  }

  async fetchImportSchemas(languageCode: string): Promise<IPB.Schema[]> {
    console.log('Fetching import schemas...');

    const request = new PB.GetSchemasRequest();
    request.languageCode = languageCode;

    const response = await this.performRequest(Importer, Importer.methods.getSchemas, request);

    console.log('Successfully fetched schemas.');

    return response.schemas;
  }

  async createOrUpdateImportSession(session: IPB.ImportSession, returnData: boolean): Promise<IPB.ImportSession> {
    const id = session.id;

    if (id.length > 0) {
      console.log(`Updating import session [id: ${id}]...`);
    } else {
      console.log(`Creating import session...`);
    }

    const request = new PB.CreateOrUpdateImportSessionRequest();
    request.session = session;
    request.includeData = returnData;

    const response = await this.performRequest(Importer, Importer.methods.createOrUpdateImportSession, request);

    console.log('Successfully created or updated import session.');

    const pbSession = response.session;

    if (pbSession == null) {
      throw new Error(
        'Unexpected result from backend: A CreateOrUpdateImportSession request did not return a session.'
      );
    }

    return pbSession;
  }

  async deleteImportSession(_configId: string, sessionId: string): Promise<void> {
    console.log(`Deleting import session [id: ${sessionId}]`);

    // The configId is not required in the call, but it should probably have been.
    // Still forcing to provide it here.
    const request = new PB.DeleteImportSessionRequest();
    request.sessionId = sessionId;

    await this.performRequest(Importer, Importer.methods.deleteImportSession, request);

    console.log('Successfully deleted import session');
  }

  async uploadData(
    configId: string,
    fileName: string,
    fileLabel: string,
    sessionId: string
  ): Promise<SourceFileUploadUrlResponse> {
    const request = new PB.UploadDataRequest();
    request.configId = configId;
    request.fileName = fileName;
    request.importSessionId = sessionId;
    request.label = fileLabel;

    const response = await this.performRequest(Importer, Importer.methods.uploadData, request, {
      shouldRetryWithFreshAccessToken: false
    });

    return {
      uploadUrl: response.uploadUrl,
      downloadUrl: response.downloadUrl,
      contentType: response.contentType
    };
  }

  async transformData(sources: IPB.SourceData[], transformation: IPB.Transformation): Promise<IPB.SourceData> {
    console.log(`Transforming data for ${transformation.label}.`);

    const request = new PB.TransformDataRequest();
    request.sources = sources;
    request.transformation = transformation;

    // This call doesn't update anything, it just calculates the transformation.
    const response = await this.performRequest(Importer, Importer.methods.transformData, request);

    console.log('Successfully transformed data.');

    const data = response.result;

    if (data == null) {
      throw new Error('Unexpected result from backend: A TransformData request did not return a SourceData.');
    }

    return data;
  }

  async importData(
    configId: string,
    sourceSessionId: string,
    data: IPB.SourceData,
    isVerificationOnly: boolean,
    isCompleteData: boolean,
    allowedIncidentCodes: string[],
    options: string[]
  ): Promise<PB.ImportDataResponse> {
    if (isVerificationOnly) {
      console.log('Testing import data...');
    } else {
      console.log('Importing data...');
    }

    const request = new PB.ImportDataRequest({
      configId,
      sourceSessionId,
      data,
      isVerificationOnly,
      isCompleteData,
      allowedIncidentCodes,
      options
    });

    const response = await this.performRequest(Importer, Importer.methods.importData, request, {
      shouldRetryWithFreshAccessToken: false
    });

    if (isVerificationOnly) {
      console.log('Successfully tested import data.');
    } else {
      console.log('Successfully imported data.');
    }

    return response;
  }

  async fetchExternalFileSources(): Promise<IPB.ExternalFileSource[]> {
    const request = new PB.GetExternalFileSourcesRequest();

    const response = await this.performRequest(Importer, Importer.methods.getExternalFileSources, request);

    return response.sources;
  }

  async createOrUpdateExternalFileSource(source: IPB.ExternalFileSource): Promise<IPB.ExternalFileSource> {
    const request = new PB.CreateOrUpdateExternalFileSourceRequest();
    request.source = source;

    const response = await this.performRequest(Importer, Importer.methods.createOrUpdateExternalFileSource, request);

    const newSource = response.source;

    if (newSource == null) {
      throw new Error(
        'Unexpected result from backend: A CreateOrUpdateExternalFileSource request did not return an ExternalFileSource.'
      );
    }

    return newSource;
  }

  async deleteExternalFileSource(id: string): Promise<void> {
    const request = new PB.DeleteExternalFileSourceRequest();
    request.id = id;

    await this.performRequest(Importer, Importer.methods.deleteExternalFileSource, request);
  }

  async fetchAutomatedImports(configId: string, importSessionId: string): Promise<IPB.AutomatedImport[]> {
    console.log('Fetching automated imports...');

    const request = new PB.GetAutomatedImportsRequest();
    request.configId = configId;
    request.importSessionId = importSessionId;

    const response = await this.performRequest(Importer, Importer.methods.getAutomatedImports, request);

    console.log('Successfully fetched automated imports.');

    return response.automatedImports;
  }

  async fetchAutomatedImport(
    configId: string,
    importSessionId: string,
    automatedImportId: string
  ): Promise<IPB.AutomatedImport> {
    console.log('Fetching automated import...');

    const request = new PB.GetAutomatedImportRequest();
    request.configId = configId;
    request.importSessionId = importSessionId;
    request.automatedImportId = automatedImportId;

    const response = await this.performRequest(Importer, Importer.methods.getAutomatedImport, request);

    console.log('Successfully fetched automated import.');

    return response.automatedImport!;
  }

  async createOrUpdateAutomatedImport(
    configId: string,
    importSessionId: string,
    automatedImport: IPB.AutomatedImport
  ): Promise<IPB.AutomatedImport> {
    console.log('Creating or updating automated import...');

    const request = new PB.CreateOrUpdateAutomatedImportRequest();
    request.configId = configId;
    request.importSessionId = importSessionId;
    request.automatedImport = automatedImport;

    const response = await this.performRequest(Importer, Importer.methods.createOrUpdateAutomatedImport, request);

    console.log('Successfully created or updated automated import.');

    return response.automatedImport!;
  }

  async deleteAutomatedImport(configId: string, importSessionId: string, automatedImportId: string): Promise<void> {
    console.log('Deleting automated import...');

    const request = new PB.DeleteAutomatedImportRequest();
    request.configId = configId;
    request.importSessionId = importSessionId;
    request.automatedImportId = automatedImportId;

    await this.performRequest(Importer, Importer.methods.deleteAutomatedImport, request);

    console.log('Successfully deleted automated import.');
  }

  async executeAutomatedImport(
    configId: string,
    importSessionId: string,
    automatedImportId: string,
    shouldWaitForResult: boolean
  ): Promise<IPB.AutomatedImportResult | undefined> {
    console.log('Executing automated import...');

    const request = new PB.ExecuteAutomatedImportRequest();
    request.configId = configId;
    request.importSessionId = importSessionId;
    request.automatedImportId = automatedImportId;
    request.shouldWaitForResult = shouldWaitForResult;

    const response = await this.performRequest(Importer, Importer.methods.executeAutomatedImport, request);

    console.log('Successfully executed automated import.');

    return response.result;
  }

  async fetchIncidentCodes(languageCode: string): Promise<IPB.Incident[]> {
    console.log('Fetching incident codes...');

    const request = new PB.GetIncidentCodesRequest();
    request.languageCode = languageCode;

    const response = await this.performRequest(Importer, Importer.methods.getIncidentCodes, request);

    console.log('Successfully fetched incident codes.');

    return response.incidents;
  }
}
