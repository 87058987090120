export interface IOSAccessTokenProvider {
  subscribe(fn: (token: string) => void): void;
  getAccessToken(): Promise<string>;
  setAccessToken(token: string): void;
}

// Here's one of the resource that inspired the way to share the access token between the iOS and the web app.
// Link: https://www.innominds.com/blog/wkwebview-to-native-code-interaction
export class AppIOSAccessTokenProvider implements IOSAccessTokenProvider {
  private _subscriptions: ((token: string) => void)[] = [];

  subscribe(fn: (token: string) => void): void {
    this._subscriptions.push(fn);
  }

  getAccessToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment
      const { webkit } = window as any;

      if (webkit != null) {
        const setToken = (value: string) => {
          resolve(value);
        };
        this._subscriptions.push(setToken);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        webkit.messageHandlers.getAccessTokenFromProvider.postMessage({});
      } else {
        reject(new Error('Cannot find webkit instance'));
      }
    });
  }

  setAccessToken(token: string): void {
    this._subscriptions.forEach((fn) => fn(token));
    this._subscriptions = [];
  }
}
