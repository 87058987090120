import {
  CanvasAssignmentGroupMappingModel,
  CanvasExternalAccountDetails,
  CanvasSubAccount,
  CanvasTerm,
  GrpcCanvasExternalAccountDetails,
  GrpcCanvasSubAccount,
  GrpcCanvasTerm
} from '@shared/models/connectors';
import { CanvasTransport } from '../../../transports';
import { CanvasConnectorStore, ConnectorsStore } from '../../interfaces';
import { AppBaseStore } from '../AppBaseStore';

export class AppCanvasConnectorStore extends AppBaseStore implements CanvasConnectorStore {
  constructor(
    private readonly _transport: CanvasTransport,
    connectorStore: ConnectorsStore
  ) {
    super('AppCanvasConnectorStore', false, connectorStore);
  }

  async getCanvasAccountDetails(externalAccountId: string): Promise<CanvasExternalAccountDetails> {
    const details = await this._transport.fetchCanvasExternalAccountDetails(externalAccountId);

    return new GrpcCanvasExternalAccountDetails(details);
  }

  async createOrUpdateCanvasAccount(
    configId: string,
    externalAccountId: string,
    accountName: string,
    serverUrl: string,
    accessToken: string
  ): Promise<string> {
    const response = await this._transport.createOrUpdateCanvasExternalAccount(
      externalAccountId,
      configId,
      serverUrl,
      accessToken,
      accountName
    );
    this.invalidate();

    return response.externalAccountId;
  }

  async updateCanvasAccountSettings(
    configId: string,
    externalAccountId: string,
    subAccountId: bigint,
    termIds: bigint[],
    shouldDisplaySections: boolean,
    requiredCourseCodePrefix: string,
    assignmentGroupMappings: CanvasAssignmentGroupMappingModel[],
    isIgnoringUnmappedGroups: boolean
  ): Promise<void> {
    await this._transport.updateCanvasExternalAccountSettings(
      externalAccountId,
      configId,
      subAccountId,
      termIds,
      shouldDisplaySections,
      requiredCourseCodePrefix,
      assignmentGroupMappings.map((agm) => agm.toProtobuf()),
      isIgnoringUnmappedGroups
    );
    this.invalidate();
  }

  async getCanvasSubAccounts(externalAccountId: string): Promise<CanvasSubAccount[]> {
    const accounts = await this._transport.fetchCanvasSubAccounts(externalAccountId);
    return accounts.map((account) => new GrpcCanvasSubAccount(account));
  }

  async getCanvasTerms(externalAccountId: string): Promise<CanvasTerm[]> {
    const terms = await this._transport.fetchCanvasTerms(externalAccountId);
    return terms.map((term) => new GrpcCanvasTerm(term));
  }

  async getCanvasAssignmentGroupNames(externalAccountId: string): Promise<string[]> {
    const groups = await this._transport.fetchCanvasAssignmentGroupNames(externalAccountId);
    return groups;
  }
}
