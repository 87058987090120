import { InsightsEnvironmentService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';

export interface MicrosoftTeamsAccountViewModel {
  readonly isExistingAccount: boolean;
  readonly authorizationLink: string;

  cancelChanges(): void;
}

export class AppMicrosoftTeamsAccountViewModel implements MicrosoftTeamsAccountViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _environmentService: InsightsEnvironmentService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string
  ) {}

  get isExistingAccount() {
    return this._externalAccountId.length > 0 && this._externalAccountId !== 'new';
  }

  get authorizationLink() {
    // We use an absolute link without the authority, so we point to ourself.
    // Note: Can't reference RouteTemplates (under web) from here (shared), so hardcoded "teamsconsent" in redirect url.
    const actualExternalAccountId = this._externalAccountId === 'new' ? '' : this._externalAccountId;
    return `https://login.microsoftonline.com/common/adminconsent?client_id=${this._environmentService.microsoftClientId}&state=${this._configId},${actualExternalAccountId}&redirect_uri=${window.location.origin}/teamsconsent`;
  }

  cancelChanges() {
    this._onCancel();
  }
}
