import { Checkbox, FormControlLabel, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface ClassroomCourseAliasesSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  isFetchingCourseAliases: boolean;
  onChange: (value: boolean) => void;
}

export const ClassroomCourseAliasesSelector = observer((props: ClassroomCourseAliasesSelectorProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, isFetchingCourseAliases, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <FormControlLabel
      sx={sx}
      className={className}
      style={style}
      label={strings.classroomIncludeCourseAliasesLabel}
      control={
        <Checkbox
          color="primary"
          checked={isFetchingCourseAliases}
          onChange={(event) => onChange(event.target.checked)}
        />
      }
    />
  );
});
