import { NavigationService } from '@insights/services';
import { SchoolDay, SpecialDayOccurrence } from '@shared/models/calendar';
import { DayConfigurationModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { action, computed, makeObservable } from 'mobx';
import { CustomizableViewModel } from './CustomizableViewModel';
import { CustomizationData } from './CustomizationData';
import { SchoolCalendarViewModel } from './SchoolCalendarViewModel';

export interface SchoolCalendarDayViewModel extends CustomizableViewModel {
  readonly schoolDay?: SchoolDay;
  readonly cycleDayTitle?: string;
  readonly cycleDayNumber?: number;
  readonly specialDays: SpecialDayOccurrence[];
  readonly isInMonth: boolean;
}

export class AppSchoolCalendarDayViewModel implements SchoolCalendarDayViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    public readonly schoolDay: SchoolDay | undefined,
    public readonly isInMonth: boolean,
    public readonly calendar: SchoolCalendarViewModel
  ) {
    makeObservable(this);
  }

  get cycleDayTitle() {
    if (this.schoolDay == null) {
      return undefined;
    }

    const title = this.schoolDay.displayCycleDayTitle;

    if (title.trim().length === 0) {
      return undefined;
    }

    return title;
  }

  get cycleDayNumber() {
    return this.schoolDay?.cycleDay !== 0 ? this.schoolDay?.cycleDay : undefined;
  }

  get specialDays() {
    return this.schoolDay?.specialDays ?? [];
  }

  @computed
  get dayConfigurations(): DayConfigurationModel[] {
    if (this.schoolDay == null) {
      return [];
    }

    return this.calendar.dayConfigurationsByDay[this.schoolDay.day.asString] || [];
  }

  @computed
  get longTitle(): string {
    return (
      this.schoolDay?.day.formattedString(this._localizationService.localizedStrings.models.dateFormats.long) ?? ''
    );
  }

  @computed
  get hasCustomization() {
    return this.dayConfigurations.length > 0;
  }

  @computed
  get specialDaysById() {
    return this.calendar.specialDaysById;
  }

  @computed
  get schedulesById() {
    return this.calendar.schedulesById;
  }

  async editCustomizations(): Promise<void> {
    await this._navigationService.navigateToEditSchoolCalendarDayCustomization(this);
  }

  @action
  async applyCustomizations(data: CustomizationData, isMoving?: boolean): Promise<void> {
    if (this.schoolDay == null) {
      return;
    }

    if (data.clearSchedules) {
      await this.calendar.clearSchedulesFromDay(this.schoolDay.day);
    }
    if (data.clearSpecialDays) {
      await this.calendar.clearSpecialDaysFromDay(this.schoolDay.day);
    }
    if (data.specialDay != null) {
      const alsoRemove = data.sourceDay != null && !data.sourceDay.isSame(this.schoolDay.day) && isMoving === true;

      await this.calendar.addSpecialDayToDay(this.schoolDay.day, data.specialDay, alsoRemove);

      if (alsoRemove) {
        await this.calendar.removeSpecialDayFromDay(data.sourceDay!, data.specialDay.id);
      }
    }
    if (data.schedule != null) {
      await this.calendar.addScheduleToDay(this.schoolDay.day, data.schedule);
    }
    if (data.specialDayIdToRemove != null) {
      await this.calendar.removeSpecialDayFromDay(this.schoolDay.day, data.specialDayIdToRemove);
    }
    if (data.scheduleIdToRemove != null) {
      await this.calendar.removeScheduleFromDay(this.schoolDay.day, data.scheduleIdToRemove);
    }
  }

  clone(): CustomizableViewModel {
    return new AppSchoolCalendarDayViewModel(
      this._navigationService,
      this._localizationService,
      this.schoolDay,
      this.isInMonth,
      this.calendar.clone(true)
    );
  }
}
