import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import { SectionScheduleUtils } from '@shared/components/utils';
import { SectionScheduleModel } from '@shared/models/config';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface SectionSchedulesSummaryProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  schedules: SectionScheduleModel[];
}

export const SectionSchedulesSummary = observer((props: SectionSchedulesSummaryProps) => {
  const { localizationService } = useInsightsServices();
  const { schedules, sx = [], className, style } = props;
  const schedulesByTerm = _.groupBy(schedules, (s) => s.termTag);
  const strings = localizationService.localizedStrings.insights.components.sections;

  return (
    <Box sx={sx} className={className} style={style}>
      <Tooltip
        title={
          <Box display="flex" flexDirection="column">
            {schedules.map((schedule) => (
              <Typography key={`schedule-summary-${schedule.id}`} variant="caption">
                {SectionScheduleUtils.getScheduleDigest(schedule, localizationService)}
              </Typography>
            ))}
          </Box>
        }
      >
        <Box display="flex" flexDirection="column">
          {Object.keys(schedulesByTerm).map((termTag) => (
            <Typography key={`schedule-summary-${termTag}`} variant="caption">
              {strings.getScheduleCountPerTerm(termTag, schedulesByTerm[termTag].length)}
            </Typography>
          ))}
        </Box>
      </Tooltip>
    </Box>
  );
});
