export interface ConfigLinksStrings {
  title: string;
  close: string;
}

export const EnglishConfigLinksStrings: ConfigLinksStrings = {
  title: 'Links',
  close: 'Close'
};

export const FrenchConfigLinksStrings: ConfigLinksStrings = {
  title: 'Liens',
  close: 'Fermer'
};
