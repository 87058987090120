import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { ListChildComponentProps } from 'react-window';

export interface VirtualizedRow {
  /**
   * The section index
   */
  section: number;

  /**
   * The item index. `undefined` when `isHeader` is `true`
   */
  index: number | undefined;

  /**
   * Indicates the row is a header. Optional.
   */
  isHeader?: boolean;
}

export interface VirtualizedListRowProps extends ListChildComponentProps {
  /**
   * The row to render. Optional.
   */
  row: VirtualizedRow | undefined;

  /**
   * Render section's header
   * @param section The section's index
   */
  renderHeader: (section: number) => ReactNode;

  /**
   * Render section's row
   * @param section The section's index
   * @param row The row's index in the section
   */
  renderItem: (section: number, row: number) => ReactNode;
}

export const VirtualizedListRow = (props: VirtualizedListRowProps) => {
  const { style, row, renderHeader, renderItem } = props;

  if (row != null) {
    return (
      <Box style={style} overflow="hidden">
        {row.isHeader ? renderHeader(row.section) : renderItem(row.section, row.index!)}
      </Box>
    );
  }

  console.warn("Rendering a row which doesn't exist in the data.");
  return null;
};
