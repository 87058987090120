import { InsightsButton, ObservablePresenter, OnboardingStepEdition, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider,
  Typography
} from '@mui/material';
import { OnboardingStep } from '@shared/models/onboarding/interfaces';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface OnboardingStepEditionDialogProps extends DialogResult<OnboardingStep> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  step: OnboardingStep;
  processName: string;
}

export const OnboardingStepEditionDialog = observer((props: OnboardingStepEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx = [], step, processName, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const isCustomized = step.isCustomized;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createOnboardingStepEditionDialog(step, processName, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        onClose={() => viewModel.cancel()}
      >
        <DialogTitle>{strings.editStepTitle}</DialogTitle>
        <DialogContent>
          <ObservablePresenter
            data={viewModel.data}
            render={(data) => <OnboardingStepEdition sx={{ mb: 1 }} viewModel={data} />}
          />

          {isCustomized && (
            <Box display="flex" flexDirection="row-reverse" sx={{ mt: 1 }}>
              <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.error.main }}>
                {strings.customizedStepNotice}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <InsightsButton onClick={() => viewModel.cancel()}>{strings.cancelLabel}</InsightsButton>
          <InsightsButton
            isDefault={isCustomized}
            isDisabled={!viewModel.canSave}
            onClick={() => void viewModel.save(false)}
          >
            {isCustomized ? strings.saveForThisSchoolButton : strings.saveOnlyForThisSchoolButton}
          </InsightsButton>
          <InsightsButton
            isDefault={!isCustomized}
            isDisabled={!viewModel.canSave || isCustomized}
            onClick={() => void viewModel.save(true)}
          >
            {strings.saveForAllButton}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
