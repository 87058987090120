export interface WorkloadStrings {
  readonly title: string;
  readonly loadingTitle: string;
  readonly errorMessage: string;
  readonly refresh: string;
  readonly openInsights: string;
}

export const EnglishWorkloadStrings: WorkloadStrings = {
  title: 'Workload',
  loadingTitle: 'Loading data…',
  errorMessage: 'Something went wrong. Please try again.',
  refresh: 'Refresh',
  openInsights: 'Open Insights…'
};

export const FrenchWorkloadStrings: WorkloadStrings = {
  title: 'Charge de travail',
  loadingTitle: 'Chargement…',
  errorMessage: 'Une erreur est survenue. Veuillez réessayer.',
  refresh: 'Rafraîchir',
  openInsights: 'Ouvrir Insights…'
};
