export interface PeriodPrioritySelectorStrings {
  readonly title: string;
  readonly freePeriodTitle: string;
  readonly close: string;
}

export const EnglishPeriodPrioritySelectorStrings: PeriodPrioritySelectorStrings = {
  title: 'Select a course…',
  freePeriodTitle: 'Free',
  close: 'Close'
};

export const FrenchPeriodPrioritySelectorStrings: PeriodPrioritySelectorStrings = {
  title: 'Choisir un cours…',
  freePeriodTitle: 'Libre',
  close: 'Fermer'
};
