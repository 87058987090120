import {
  AuthorizationRoleCondition,
  CurrentPageInfo,
  ObservablePresenter,
  PageFooter,
  PageHeaderBar,
  PageHeaderDetailBar,
  SectionList,
  WeekPagingNavigation
} from '@insights/components';
import FilterIcon from '@mui/icons-material/FilterList';
import NotesIcon from '@mui/icons-material/NoteAlt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Badge, Box, Button, Card, Grid2, IconButton, Link, SxProps, Tooltip, Typography } from '@mui/material';
import { AccountUtils, isSxArray } from '@shared/components/utils';
import { AdminAuthorizationRoles } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties, Fragment, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook';
import { PublishedTasksBySection } from './PublishedTasksBySection';
import { SectionsCourseOccurrencesStatus } from './SectionsCourseOccurrencesStatus';
import { TeacherSessionsPerDay } from './TeacherSessionsPerDay';
import { WorkloadManagerBySection } from './WorkloadManagerBySection';

export interface TeacherDetailsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const TeacherDetails = observer((props: TeacherDetailsProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.teachers;

  const params = useParams();
  const configId = params.configId ?? '';
  const teacherId = params.teacherId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createTeacherDetails(configId, teacherId), [configId, teacherId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Teacher Details' });
  });

  return (
    <Box
      className={className}
      style={style}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <PageHeaderBar>
        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
          <Button onClick={() => void viewModel.navigateToPlanner()}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Typography>{strings.viewPlanner}</Typography>

              <OpenInNewIcon sx={{ ml: 0.5 }} color="action" />
            </Box>
          </Button>
        </AuthorizationRoleCondition>
      </PageHeaderBar>
      <Box
        sx={{
          flex: 1
        }}
      >
        <ObservablePresenter
          sx={{ height: '100%', width: '100%' }}
          data={viewModel.data}
          loadingMessage={strings.loadingTeacherDetailsMessage}
          errorMessage={strings.loadingTeacherDetailsErrorMessage}
          render={(data) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              {/* Teacher Info */}
              <PageHeaderDetailBar>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  {/* Teacher name and email */}
                  <Box>
                    <Typography sx={{ fontSize: '2em', lineHeight: 'normal', fontWeight: 300 }}>
                      {AccountUtils.getDisplayLastFirstName(data.accountInfo.account, strings.noName)}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <Link variant="body1" target="_top" href={`mailto:${data.accountInfo.account.email}`}>
                        {data.accountInfo.account.email}
                      </Link>
                      {data.accountInfo.account.profile.publicEmail.length > 0 && (
                        <Link
                          sx={{ ml: 2 }}
                          variant="body1"
                          target="_top"
                          href={`mailto:${data.accountInfo.account.profile.publicEmail}`}
                        >
                          ✉️&nbsp;{data.accountInfo.account.profile.publicEmail}
                        </Link>
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      flex: 1
                    }}
                  />

                  {viewModel.exports.canExportNotes && (
                    <Tooltip title={strings.exportNotesTooltip}>
                      <IconButton sx={{ marginRight: 2 }} onClick={() => void viewModel.exports.exportNotes()}>
                        <NotesIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip title={strings.filterButtonTooltip}>
                    <IconButton onClick={() => void viewModel.showFilters()}>
                      <Badge badgeContent={data.filterCount} color="primary" showZero={false} variant="dot">
                        <FilterIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Box>
              </PageHeaderDetailBar>

              {/* Content */}
              <Box
                sx={{
                  flex: 1,
                  overflow: 'auto',
                  padding: 2
                }}
              >
                <Grid2
                  container
                  sx={{
                    alignItems: 'stretch'
                  }}
                >
                  {/* Daily Account Sessions */}
                  <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                    <Grid2 size={{ xs: 12 }}>
                      <Card>
                        <TeacherSessionsPerDay
                          sx={{ height: '100%', width: '100%' }}
                          viewModel={viewModelFactory.createDailyAccountSessions(
                            viewModel.configId,
                            viewModel.teacherId
                          )}
                        />
                      </Card>
                    </Grid2>
                  </AuthorizationRoleCondition>

                  {data.accountInfo.sections && (
                    <Fragment>
                      {/* Course Occurrences Status */}
                      <Grid2 size={{ xs: 12 }}>
                        <Card>
                          <SectionsCourseOccurrencesStatus
                            sx={{ height: '100%', width: '100%' }}
                            viewModel={viewModelFactory.createSectionsCourseOccurrencesStatus(
                              viewModel.configId,
                              data.displayedSectionIds,
                              viewModel.pagination
                            )}
                            displaySectionName={true}
                            displayWeekNavigation={false}
                          />
                        </Card>
                      </Grid2>

                      {/* Published Tasks */}
                      <Grid2 size={{ xs: 12 }}>
                        <Card>
                          <PublishedTasksBySection
                            sx={{ height: '100%', width: '100%' }}
                            viewModel={viewModelFactory.createPublishedTasksBySection(
                              viewModel.configId,
                              data.displayedSectionIds,
                              viewModel.pagination
                            )}
                            displaySectionName={true}
                            displayWeekNavigation={false}
                          />
                        </Card>
                      </Grid2>

                      {/* Workload Manager */}
                      <Grid2 size={{ xs: 12 }}>
                        <Card>
                          <WorkloadManagerBySection
                            sx={{ height: '100%', width: '100%' }}
                            viewModel={viewModelFactory.createWorkloadManagerBySection(
                              viewModel.configId,
                              data.displayedSectionIds,
                              viewModel.pagination
                            )}
                            displaySectionName={true}
                            displayWeekNavigation={false}
                          />
                        </Card>
                      </Grid2>

                      {/* Sections */}
                      {data.accountInfo.sections.sections.length > 0 && (
                        <Grid2 size={{ xs: 12 }}>
                          <Card>
                            <SectionList
                              sx={{ height: '100%', width: '100%' }}
                              title={strings.taughtSections}
                              sections={data.accountInfo.sections}
                              usePagination={false}
                              configId={viewModel.configId}
                            />
                          </Card>
                        </Grid2>
                      )}
                    </Fragment>
                  )}
                </Grid2>
              </Box>

              <PageFooter dense>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <CurrentPageInfo pagination={viewModel.pagination} />

                  <Box
                    sx={{
                      flex: 1
                    }}
                  />

                  <WeekPagingNavigation pagination={viewModel.pagination} />
                </Box>
              </PageFooter>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
