export interface PeriodFilterOptionsStrings {
  readonly close: string;
  readonly reset: string;
  readonly title: string;

  readonly includeTitle: string;
  readonly published: string;
  readonly notPublished: string;

  readonly showTitle: string;
  readonly exams: string;
  readonly homework: string;
  readonly others: string;

  readonly minimumWorkloadTitle: string;

  readonly onlyTitle: string;
  readonly withAttachments: string;

  readonly displayAccouncedIcons: string;
}

export const EnglishPeriodFilterOptionStrings: PeriodFilterOptionsStrings = {
  close: 'Close',
  reset: 'Reset filters',
  title: 'Task Filters',

  includeTitle: 'Include',
  published: 'Published',
  notPublished: 'Not Published',

  showTitle: 'Show',
  exams: 'Exams',
  homework: 'Homework',
  others: 'Others',

  minimumWorkloadTitle: 'Minimum Workload',

  onlyTitle: 'Only',
  withAttachments: 'With Attachments',

  displayAccouncedIcons: 'Announcement icons'
};

export const FrenchPeriodFilterOptionStrings: PeriodFilterOptionsStrings = {
  close: 'Fermer',
  reset: 'Enlever les filtres',
  title: 'Filtrer les tâches',

  includeTitle: 'Inclure',
  published: 'Publiées',
  notPublished: 'Personnelles',

  showTitle: 'Afficher',
  exams: 'Examens',
  homework: 'Devoirs',
  others: 'Autres',

  minimumWorkloadTitle: 'Charge minimale',

  onlyTitle: 'Afficher seulement',
  withAttachments: 'Avec pièces jointes',

  displayAccouncedIcons: 'Icônes d’annonce'
};
