import { AlertService, NavigationService } from '@insights/services';
import { AutomatedImport, ImportSession } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

export interface AutomatedImportListViewModel {
  readonly automatedImports: AutomatedImport[];

  addAutomatedImport(): Promise<void>;
  editAutomatedImport(imp: AutomatedImport): Promise<void>;
  editAutomatedTransformations(imp: AutomatedImport, navigate: NavigateFunction): Promise<void>;
  deleteAutomatedImportRequest(imp: AutomatedImport): Promise<void>;
}

export class AppAutomatedImportListViewModel implements AutomatedImportListViewModel {
  @observable private _automatedImports: AutomatedImport[];

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _session: ImportSession,
    automatedImports: AutomatedImport[]
  ) {
    makeObservable(this);
    this._automatedImports = automatedImports;
  }

  @computed
  get automatedImports() {
    return this._automatedImports;
  }

  async addAutomatedImport(): Promise<void> {
    const result = await this._navigationService.navigateToAutomatedImportCreateOrEdit(
      this._session.configId,
      this._session.id
    );

    if (result !== 'cancelled') {
      await this.reloadAutomatedImports();
    }
  }

  async editAutomatedImport(imp: AutomatedImport): Promise<void> {
    const result = await this._navigationService.navigateToAutomatedImportCreateOrEdit(
      this._session.configId,
      this._session.id,
      imp
    );

    if (result !== 'cancelled') {
      await this.reloadAutomatedImports();
    }
  }

  async editAutomatedTransformations(imp: AutomatedImport, navigate: NavigateFunction): Promise<void> {
    await this._navigationService.navigateToAutomatedImportDetails(
      this._session.configId,
      this._session.id,
      imp.id,
      navigate
    );
  }

  async deleteAutomatedImportRequest(imp: AutomatedImport): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.import;
    const result = await this._alertService.showConfirmation({
      title: strings.confirmDeleteAutomatedImportTitle,
      message: strings.confirmDeleteAutomatedImportMessage(imp.name)
    });

    if (result !== 'cancelled') {
      try {
        await this._importSessionStore.deleteAutomatedImport(this._session.configId, this._session.id, imp.id);

        await this.reloadAutomatedImports();
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedErrorMessage + (error as Error).message
        });
      }
    }
  }

  @action
  private async reloadAutomatedImports() {
    try {
      const automatedImports = await this._importSessionStore.getAutomatedImports(
        this._session.configId,
        this._session.id
      );

      runInAction(() => (this._automatedImports = automatedImports));
    } catch (error) {
      // Instead of showing a useless message saying "we couldn't load what we successfully saved",
      // we force the store to invalidate. This will reload the import session as well, too bad.
      console.error(error);
      this._importSessionStore.invalidate();
    }
  }
}
