import { AccountInfo, accountInfoFromModel } from '@insights/models';
import { AccountService, NavigationService } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface TeachersViewModel {
  readonly configId: string;
  readonly data: IPromiseBasedObservable<AccountInfo[]>;
  readonly shouldLimitAccess?: boolean;
}

export class AppTeachersViewModel implements TeachersViewModel {
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    public readonly configId: string,
    public readonly shouldLimitAccess?: boolean
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<AccountInfo[]> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<AccountInfo[]> {
    let teachers: AccountModel[] = [];

    if (this.shouldLimitAccess === true && !this._accountService.isAllowed(['super-admin', 'admin'])) {
      // For now, we assume it's a teacher. We also assume it's only one!
      const teacherId = this._accountService.getAccountIdForConfigRole(this.configId, 'teacher');

      if (teacherId != null) {
        const teachersById = await this._schoolYearConfigurationStore.getTeachersById(this.configId, false);
        teachers = [teachersById[teacherId]];
      }
    } else {
      teachers = await this._schoolYearConfigurationStore.getTeachers(this.configId, false);
    }

    return teachers.map(accountInfoFromModel);
  }
}
