import { ExternalAccountList, ObservablePresenter, PageHeaderBar } from '@insights/components';
import { Box, Card, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ExternalAccountsScreenProps {
  sx?: SxProps;

  className?: string;
  style?: CSSProperties;
}

export const ExternalAccountsScreen = observer((props: ExternalAccountsScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createExternalAccounts(configId), [configId]);

  return (
    <Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, height: '100%', width: '100%', overflow: 'auto' }}
          data={viewModel.accountList}
          loadingMessage={strings.loadingExternalAccountsMessage}
          errorMessage={strings.loadingExternalAccountErrorMessage}
          render={(data) => (
            <Box display="flex" flexDirection="column">
              <Card sx={{ minWidth: MinLayoutWidth }}>
                <ExternalAccountList sx={{ width: '100%', height: '100%' }} viewModel={data} />
              </Card>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
