export interface DayStrings {
  twoDaysAgo: string;
  yesterday: string;
  today: string;
  tomorrow: string;
}

export const EnglishDayStrings: DayStrings = {
  today: 'Today',
  yesterday: 'Yesterday',
  tomorrow: 'Tomorrow',
  twoDaysAgo: '2 days ago'
};

export const FrenchDayStrings: DayStrings = {
  today: "aujourd'hui",
  yesterday: 'hier',
  tomorrow: 'demain',
  twoDaysAgo: 'avant-hier'
};
