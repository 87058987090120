import { OnboardingQuestionEditionViewModel } from '@insights/viewmodels';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid2,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SxProps,
  TextField
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { OnboardingChoicesEdition } from './OnboardingChoicesEdition';
import { OnboardingTextsEdition } from './OnboardingTextsEdition';

export interface OnboardingQuestionEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: OnboardingQuestionEditionViewModel;
}

export const OnboardingQuestionEdition = observer((props: OnboardingQuestionEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const { editableQuestion, dependableQuestionNamesInStep, otherDependableQuestionNames } = viewModel;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const hasChoices = editableQuestion.kind === 'single-choice' || editableQuestion.kind === 'multiple-choice';
  const hasDependantQuestion = editableQuestion.dependantQuestionName.length > 0;

  return (
    <Box sx={sx} className={className} style={style}>
      <OnboardingTextsEdition
        texts={editableQuestion.editableDescription}
        rows={5}
        onFileUpload={(d, f) => viewModel.uploadFile(d, f)}
      />
      {hasChoices && <OnboardingChoicesEdition editableQuestion={editableQuestion} />}
      <FormControlLabel
        sx={{ mt: 1, mb: 2 }}
        label={strings.isAnswerRequiredLabel}
        control={
          <Checkbox
            checked={editableQuestion.isRequired}
            onChange={(e) => (editableQuestion.isRequired = e.target.checked)}
          />
        }
      />
      <Grid2
        container
        spacing={1}
        sx={{
          alignItems: 'flex-end'
        }}
      >
        <Grid2 size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="dependant-question-name-select" shrink>
              {strings.dependantQuestionNameLabel}
            </InputLabel>
            <Select
              sx={{ backgroundColor: '#eef' }}
              inputProps={{ id: 'dependant-question-name-select' }}
              value={editableQuestion.dependantQuestionName}
              onChange={(e) => (editableQuestion.dependantQuestionName = e.target.value)}
            >
              <MenuItem key="no-dependable-question-name" value="">
                {strings.noDependantQuestionLabel}
              </MenuItem>
              <ListSubheader sx={{ margin: 0, lineHeight: '2em', paddingTop: 1 }}>
                {strings.questionsInThisStepLabel}
              </ListSubheader>
              {dependableQuestionNamesInStep.map((name) => (
                <MenuItem key={`dependable-question-name-${name}`} value={name}>
                  {name}
                </MenuItem>
              ))}
              {otherDependableQuestionNames.map((others, index) => [
                <ListSubheader key={`other-questions${index}`} sx={{ margin: 0, lineHeight: '2em', paddingTop: 1 }}>
                  {strings.questionsInOtherStepLabel(others.stepName)}
                </ListSubheader>,
                others.questionNames.map((name) => (
                  <MenuItem key={`dependable-question-name-${name}`} value={name}>
                    {name}
                  </MenuItem>
                ))
              ])}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 4 }}>
          <TextField
            fullWidth
            slotProps={{ input: { inputProps: { style: { backgroundColor: '#eef' } } }, inputLabel: { shrink: true } }}
            disabled={!hasDependantQuestion}
            label={strings.dependantQuestionAnswerLabel}
            value={editableQuestion.dependantQuestionAnswer}
            onChange={(e) => (editableQuestion.dependantQuestionAnswer = e.target.value)}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 4 }}>
          <FormControlLabel
            label={strings.isHiddenWhenDependantLabel}
            disabled={!hasDependantQuestion}
            control={
              <Checkbox
                checked={editableQuestion.isHiddenWhenDependant}
                onChange={(e) => (editableQuestion.isHiddenWhenDependant = e.target.checked)}
              />
            }
          />
        </Grid2>
      </Grid2>
    </Box>
  );
});
