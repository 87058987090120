import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_sky_pb';
import { computed, makeObservable } from 'mobx';
import {
  EditableChildNullablePropertyEx,
  EditableModelEx,
  EditableStringProperty,
  EditableValuePropertyEx
} from '../editables';
import { EditableBlackbaudSkyContentRepresentation } from './EditableBlackbaudSkyContentRepresentation';
import { GrpcBlackbaudSkyAssignmentTypeMapping } from './GrpcBlackbaudSkyAssignmentTypeMapping';
import { BlackbaudSkyAssignmentTypeMappingModel, BlackbaudSkyContentRepresentationModel } from './interfaces';

export class EditableBlackbaudSkyAssignmentTypeMapping
  extends EditableModelEx<BC.AssignmentTypeMapping>
  implements BlackbaudSkyAssignmentTypeMappingModel
{
  private _typeId: EditableValuePropertyEx<bigint, BC.AssignmentTypeMapping>;
  private _typeName: EditableStringProperty<BC.AssignmentTypeMapping>;
  private _representation: EditableChildNullablePropertyEx<
    BC.ContentRepresentation,
    BlackbaudSkyContentRepresentationModel,
    EditableBlackbaudSkyContentRepresentation,
    BC.AssignmentTypeMapping
  >;

  static createNew(name: string): EditableBlackbaudSkyAssignmentTypeMapping {
    const pb = new BC.AssignmentTypeMapping();
    pb.typeName = name;

    return new EditableBlackbaudSkyAssignmentTypeMapping(new GrpcBlackbaudSkyAssignmentTypeMapping(pb), true);
  }

  constructor(
    readonly originalMapping: BlackbaudSkyAssignmentTypeMappingModel,
    readonly isNew = false
  ) {
    super(originalMapping.toProtobuf(), isNew);
    makeObservable(this);

    this._typeId = this.addValueField(originalMapping.typeId, (pb, value) => (pb.typeId = value));
    this._typeName = this.addStringField(originalMapping.typeName, (pb, value) => (pb.typeName = value));
    this._representation = this.addNullableChildField(
      originalMapping.representation,
      (model) =>
        model == null
          ? EditableBlackbaudSkyContentRepresentation.createNew()
          : new EditableBlackbaudSkyContentRepresentation(model),
      (pb, pbValue) => {
        pb.representation = pbValue;
      },
      originalMapping.representation == null
    );
  }

  @computed
  get typeId() {
    return this._typeId.value;
  }

  set typeId(value: bigint) {
    this._typeId.value = value;
  }

  @computed
  get typeName() {
    return this._typeName.value;
  }

  set typeName(value: string) {
    this._typeName.value = value;
  }

  @computed
  get representation() {
    return this._representation.value;
  }

  set representation(value: BlackbaudSkyContentRepresentationModel | undefined) {
    this._representation.value = value;
  }

  @computed
  get editableRepresentation() {
    return this._representation.editableValue;
  }
}
