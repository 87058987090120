import { NavigationService } from '@insights/services';
import { DayConfigurationModel, ScheduleModel, SpecialDayModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { action, computed, makeObservable } from 'mobx';
import { CustomizableViewModel } from './CustomizableViewModel';
import { CustomizationData } from './CustomizationData';
import { SchoolCalendarViewModel } from './SchoolCalendarViewModel';

export interface SchoolCalendarCycleDayViewModel extends CustomizableViewModel {
  readonly cycleDay: number;
  readonly label: string;
  readonly title: string;
  readonly isEmptyPlaceholder: boolean;
}

export class EmptySchoolCalendarCycleDayViewModel implements SchoolCalendarCycleDayViewModel {
  constructor(public readonly calendar: SchoolCalendarViewModel) {}

  readonly cycleDay = 0;

  readonly label = '';

  readonly title = '';

  readonly hasCustomization = false;

  readonly isEmptyPlaceholder = true;

  get dayConfigurations(): DayConfigurationModel[] {
    return [];
  }

  get specialDaysById(): Record<string, SpecialDayModel> {
    return {};
  }

  get schedulesById(): Record<string, ScheduleModel> {
    return {};
  }

  readonly longTitle = '';

  async editCustomizations(): Promise<void> {
    // Nothing to edit
  }

  async applyCustomizations(): Promise<void> {
    // Nothing to apply
  }

  clone(): CustomizableViewModel {
    return this;
  }
}

export class AppSchoolCalendarCycleDayViewModel implements SchoolCalendarCycleDayViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    public readonly cycleDay: number,
    public readonly title: string,
    public readonly calendar: SchoolCalendarViewModel
  ) {
    makeObservable(this);
  }

  get label() {
    return this.title.length === 0 ? `${this.cycleDay}` : `${this.cycleDay}: ${this.title}`;
  }

  @computed
  get dayConfigurations() {
    return this.calendar.dayConfigurationsByCycleDay[this.cycleDay] || [];
  }

  @computed
  get hasCustomization() {
    return this.dayConfigurations.length > 0;
  }

  @computed
  get specialDaysById() {
    return this.calendar.specialDaysById;
  }

  @computed
  get schedulesById() {
    return this.calendar.schedulesById;
  }

  @computed
  get longTitle(): string {
    return this._localizationService.localizedStrings.insights.components.calendar.cycleDayLongTitle(this.label);
  }

  readonly isEmptyPlaceholder = false;

  async editCustomizations(): Promise<void> {
    await this._navigationService.navigateToEditSchoolCalendarDayCustomization(this);
  }

  @action
  async applyCustomizations(data: CustomizationData): Promise<void> {
    // It's never the case so far, but we could support multiple operations, like clear all and add this.
    if (data.clearSchedules) {
      await this.calendar.clearSchedulesFromCycleDay(this.cycleDay);
    }
    if (data.clearSpecialDays) {
      await this.calendar.clearSpecialDaysFromCycleDay(this.cycleDay);
    }
    if (data.specialDay != null) {
      await this.calendar.addSpecialDayToCycleDay(this.cycleDay, data.specialDay);
    }
    if (data.schedule != null) {
      await this.calendar.addScheduleToCycleDay(this.cycleDay, data.schedule);
    }
    if (data.specialDayIdToRemove != null) {
      await this.calendar.removeSpecialDayFromCycleDay(this.cycleDay, data.specialDayIdToRemove);
    }
    if (data.scheduleIdToRemove != null) {
      await this.calendar.removeScheduleFromCycleDay(this.cycleDay, data.scheduleIdToRemove);
    }
  }

  clone(): CustomizableViewModel {
    return new AppSchoolCalendarCycleDayViewModel(
      this._navigationService,
      this._localizationService,
      this.cycleDay,
      this.title,
      this.calendar.clone(true)
    );
  }
}
