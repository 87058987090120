export interface ErrorBoundaryStrings {
  readonly content: string;
  readonly reloadApp: string;
}

export const EnglishErrorBoundaryString: ErrorBoundaryStrings = {
  content: 'An error occurred. The Studyo team has been informed of the problem.',
  reloadApp: 'Reload Studyo Insights'
};

export const FrenchErrorBoundaryString: ErrorBoundaryStrings = {
  content: 'Une erreur est survenue. L’équipe de Studyo a été informée du problème.',
  reloadApp: 'Redémarrer Studyo Insights'
};
