import { AutomatedTransformationImportViewModel } from '@insights/viewmodels';
import { SxProps, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface ExpectedEntityListProps {
  sx?: SxProps;
  className?: string;
  viewModel: AutomatedTransformationImportViewModel;
}

export const ExpectedEntityList = observer(({ sx = [], className, viewModel }: ExpectedEntityListProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <Table sx={sx} className={className}>
      <TableHead>
        <TableRow>
          <TableCell>{strings.entityListKindColumn}</TableCell>
          <TableCell>{strings.entityMinCountColumn}</TableCell>
          <TableCell>{strings.entityMaxCountColumn}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {viewModel.transformationImport.entityCountTolerances.map((t) => (
          <TableRow key={`entity-tolerance-${t.listKind}`}>
            <TableCell>{strings.entityListKind(t.listKind)}</TableCell>
            <TableCell>
              <TextField
                type="number"
                value={t.minimumCount}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (!Number.isNaN(value)) {
                    t.minimumCount = value;
                  }
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                type="number"
                value={t.maximumCount}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (!Number.isNaN(value)) {
                    t.maximumCount = value;
                  }
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});
