export interface PlannerContentMoveStrings {
  readonly cancel: string;
  readonly save: string;
  readonly title: string;
  readonly effectSectionTitle: string;
  readonly moveNextEffect: string;
  readonly movePreviousEffect: string;
  readonly skipPeriodEffect: string;
  readonly movePreviousNotice: string;
  readonly moveNextNotice: string;
  readonly markAsSkipped: string;
  readonly includeOptionsTitle: string;
  readonly includeTitles: string;
  readonly includeTasks: string;
  readonly includeNotes: string;
  readonly includeTitlesNotice: string;
  readonly untilSectionTitle: string;
  readonly endOfYearOption: string;
  readonly specificDateOption: string;

  readonly savingTitle: string;
  readonly savingDescription: string;
  readonly savedTitle: string;
  readonly savedDescription: string;
  readonly saveErrorTitle: string;
  readonly saveErrorDescription: string;
  readonly savePermanentErrorDescription: string;
}

export const EnglishPlannerContentMoveStrings: PlannerContentMoveStrings = {
  cancel: 'Cancel',
  save: 'Save',
  title: 'Shift Content',
  effectSectionTitle: 'Effect',
  movePreviousEffect: 'Shift content backward',
  moveNextEffect: 'Shift content forward',
  skipPeriodEffect: 'Skip period and shift content forward',
  moveNextNotice: 'Periods already marked as skipped are unaffected.',
  movePreviousNotice: 'Future periods already marked as skipped are unaffected.',
  markAsSkipped: 'Mark this period as skipped',
  includeOptionsTitle: 'Content to include',
  includeTitles: 'Titles',
  includeTasks: 'Tasks',
  includeNotes: 'Notes',
  includeTitlesNotice: "The previous period (skipped or not) will inherit this period's title and skipped status.",
  untilSectionTitle: 'Until',
  endOfYearOption: 'End of year',
  specificDateOption: 'A specific date',

  savingTitle: 'Please wait…',
  savingDescription: 'Moving contents',
  savedTitle: 'Success',
  savedDescription: 'The contents were moved successfully!',
  saveErrorTitle: 'Oops, something went wrong!',
  saveErrorDescription: 'An error occurred while moving your contents. Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented the save. Please reload and try again.'
};

export const FrenchPlannerContentMoveStrings: PlannerContentMoveStrings = {
  cancel: 'Annuler',
  save: 'Sauvegarder',
  title: 'Décaler le contenu',
  effectSectionTitle: 'Effet',
  movePreviousEffect: 'Décaler le contenu vers le passé',
  moveNextEffect: 'Décaler le contenu vers le futur',
  skipPeriodEffect: 'Sauter la période et décaler le contenu vers le futur',
  moveNextNotice: 'Les périodes déjà marquées comme sautées demeurent intactes.',
  movePreviousNotice: 'Les périodes futures déjà marquées comme sautées demeurent intactes.',
  markAsSkipped: 'Marquer cette période comme sautée',
  includeOptionsTitle: 'Contenu à inclure',
  includeTitles: 'Titres',
  includeTasks: 'Tâches',
  includeNotes: 'Notes',
  includeTitlesNotice: 'La période précédente (sautée ou non) héritera du titre et de l’état sauté de cette période.',
  untilSectionTitle: 'Jusqu’à',
  endOfYearOption: 'Fin de l’année',
  specificDateOption: 'Une date spécifique',

  savingTitle: 'Veuillez patienter…',
  savingDescription: 'Décalage du contenu en cours',
  savedTitle: 'Succès',
  savedDescription: 'Le contenu a été décalé avec succès!',
  saveErrorTitle: 'Oups, une erreur est survenue!',
  saveErrorDescription: 'Le contenu n’a pu être décalé. Veuillez réessayer.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la sauvegarde. Veuillez rafraichir et réessayer.'
};
