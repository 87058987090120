import { AccountViewsStrings, EnglishAccountViewsStrings, FrenchAccountViewsStrings } from './AccountViewsStrings';
import { CalendarViewsStrings, EnglishCalendarViewsStrings, FrenchCalendarViewsStrings } from './CalendarViewsStrings';
import {
  ConnectorViewsStrings,
  EnglishConnectorViewsStrings,
  FrenchConnectorViewsStrings
} from './ConnectorViewsStrings';
import { CreateSchoolStrings, EnglishCreateSchoolStrings, FrenchCreateSchoolStrings } from './CreateSchoolStrings';
import { EnglishFeaturesViewStrings, FeaturesViewStrings, FrenchFeaturesViewStrings } from './FeaturesViewStrings';
import { EnglishForbiddenViewStrings, ForbiddenViewStrings, FrenchForbiddenViewStrings } from './ForbiddenViewStrings';
import { EnglishImportViewsStrings, FrenchImportViewsStrings, ImportViewsStrings } from './ImportViewsStrings';
import {
  EnglishManagedGeneralViewStrings,
  FrenchManagedGeneralViewStrings,
  ManagedGeneralViewStrings
} from './ManagedGeneralViewStrings';
import {
  EnglishNoSchoolConfigurationsViewStrings,
  FrenchNoSchoolConfigurationsViewStrings,
  NoSchoolConfigurationsViewStrings
} from './NoSchoolConfigurationsViewStrings';
import { EnglishNotFoundViewStrings, FrenchNotFoundViewStrings, NotFoundViewStrings } from './NotFoundViewStrings';
import {
  EnglishOnboardingViewsStrings,
  FrenchOnboardingViewsStrings,
  OnboardingViewsStrings
} from './OnboardingViewsStrings';
import { EnglishSettingsViewStrings, FrenchSettingsViewStrings, SettingsViewStrings } from './SettingsViewStrings';
import { EnglishMetricViewsStrings, FrenchMetricViewsStrings, MetricViewsStrings } from './metrics/MetricViewsStrings';

export interface ViewsStrings {
  readonly account: AccountViewsStrings;
  readonly calendar: CalendarViewsStrings;
  readonly connectors: ConnectorViewsStrings;
  readonly createSchool: CreateSchoolStrings;
  readonly features: FeaturesViewStrings;
  readonly forbidden: ForbiddenViewStrings;
  readonly imports: ImportViewsStrings;
  readonly managedGeneral: ManagedGeneralViewStrings;
  readonly metrics: MetricViewsStrings;
  readonly noSchoolConfigurations: NoSchoolConfigurationsViewStrings;
  readonly notFound: NotFoundViewStrings;
  readonly onboarding: OnboardingViewsStrings;
  readonly settings: SettingsViewStrings;

  readonly accounts: string;
  readonly activeParents: string;
  readonly activeStudents: string;
  readonly activeTeachers: string;
  readonly blockedTooltip: string;
  readonly close: string;
  readonly completedTooltip: string;
  readonly configuration: string;
  readonly configurationsTitle: string;
  readonly copyId: string;
  readonly copyIdNotification: string;
  readonly createNewSchool: string;
  readonly daysPerCycle: string;
  readonly deletedAccount: string;
  readonly deleteUser: string;
  readonly demoMode: string;
  readonly editAccountDetails: string;
  readonly editAccountSections: string;
  readonly email: string;
  readonly endDate: string;
  readonly getSupport: string;
  readonly idOrEmail: string;
  readonly integrations: string;
  readonly language: string;
  readonly lateTooltip: string;
  readonly loadingSchoolConfigErrorMessage: string;
  readonly loadingSchoolConfigMessage: string;
  readonly lockedTooltip: string;
  readonly logout: string;
  readonly manageThisSchool: string;
  readonly minutesPerPeriod: string;
  readonly newSchool: string;
  readonly notStartedTooltip: string;
  readonly onboardingDashboard: string;
  readonly periodsPerDay: string;
  readonly school: string;
  readonly schoolConfigs: string;
  readonly schoolName: string;
  readonly schoolTitle: string;
  readonly search: string;
  readonly searchResults: string;
  readonly searchText: string;
  readonly selectAccountsToKeep: string;
  readonly selectAllAccountsTooltip: string;
  readonly selectSchoolConfig: string;
  readonly selectSchoolConfigTitle: string;
  readonly settingsMenu: string;
  readonly showDemoSchools: string;
  readonly startDate: string;
  readonly status: string;
  readonly steps: string;
  readonly username: string;
  readonly users: string;
  readonly usersPartial: string;
  readonly valid: string;
  readonly viewComments: string;
  readonly viewPlanner: string;
  readonly year: string;
}

export const EnglishViewsStrings: ViewsStrings = {
  account: EnglishAccountViewsStrings,
  calendar: EnglishCalendarViewsStrings,
  connectors: EnglishConnectorViewsStrings,
  createSchool: EnglishCreateSchoolStrings,
  features: EnglishFeaturesViewStrings,
  forbidden: EnglishForbiddenViewStrings,
  imports: EnglishImportViewsStrings,
  managedGeneral: EnglishManagedGeneralViewStrings,
  metrics: EnglishMetricViewsStrings,
  noSchoolConfigurations: EnglishNoSchoolConfigurationsViewStrings,
  notFound: EnglishNotFoundViewStrings,
  onboarding: EnglishOnboardingViewsStrings,
  settings: EnglishSettingsViewStrings,

  accounts: 'Accounts',
  activeParents: 'Active Parents',
  activeStudents: 'Active Students',
  activeTeachers: 'Active Teachers',
  blockedTooltip: 'Blocked',
  close: 'Close',
  completedTooltip: 'Completed',
  configuration: 'Configuration',
  configurationsTitle: 'Schools',
  copyId: 'Copy ID to clipboard',
  copyIdNotification: 'The ID was copied to the clipboard!',
  createNewSchool: 'Create new school',
  daysPerCycle: 'Days per Cycle',
  deletedAccount: 'This account is deleted',
  deleteUser: 'Delete user',
  demoMode: 'Demo',
  editAccountDetails: 'Edit account details',
  editAccountSections: 'Edit account sections',
  email: 'Email',
  endDate: 'End Date',
  getSupport: 'Get support',
  idOrEmail: 'Id or email',
  integrations: 'Connectors',
  language: 'Language',
  lateTooltip: 'Late',
  loadingSchoolConfigErrorMessage: 'An error occurred while loading the school configurations. Please reload.',
  loadingSchoolConfigMessage: 'Loading school configurations. Please wait…',
  lockedTooltip: 'Dependency not resolved',
  logout: 'Log Out',
  manageThisSchool: 'Manage this school',
  minutesPerPeriod: 'Minutes Per Period',
  newSchool: 'New School',
  notStartedTooltip: 'Not started, without dependencies',
  onboardingDashboard: 'Onboarding Dashboard',
  periodsPerDay: 'Periods per Day',
  school: 'School',
  schoolConfigs: 'School Configurations',
  schoolName: 'School Name',
  schoolTitle: 'School Title (optional)',
  search: 'Search',
  searchResults: 'Search results for: ',
  searchText: 'Search text',
  selectAccountsToKeep: 'Admin records to keep',
  selectAllAccountsTooltip: 'Select/Unselect all records',
  selectSchoolConfig: 'Select school configuration…',
  selectSchoolConfigTitle: 'Select school configuration',
  settingsMenu: 'Global Settings',
  showDemoSchools: 'Demo Schools',
  startDate: 'Start Date',
  status: 'Status',
  steps: 'Steps',
  username: 'Username',
  users: 'Users',
  usersPartial: 'Users (partial results)',
  valid: 'Valid',
  viewComments: 'View comments',
  viewPlanner: 'View planner',
  year: 'Year:'
};

export const FrenchViewsStrings: ViewsStrings = {
  account: FrenchAccountViewsStrings,
  calendar: FrenchCalendarViewsStrings,
  connectors: FrenchConnectorViewsStrings,
  createSchool: FrenchCreateSchoolStrings,
  features: FrenchFeaturesViewStrings,
  forbidden: FrenchForbiddenViewStrings,
  imports: FrenchImportViewsStrings,
  managedGeneral: FrenchManagedGeneralViewStrings,
  metrics: FrenchMetricViewsStrings,
  noSchoolConfigurations: FrenchNoSchoolConfigurationsViewStrings,
  notFound: FrenchNotFoundViewStrings,
  onboarding: FrenchOnboardingViewsStrings,
  settings: FrenchSettingsViewStrings,

  accounts: 'Comptes',
  activeParents: 'Parents actifs',
  activeStudents: 'Élèves actifs',
  activeTeachers: 'Enseignants actifs',
  blockedTooltip: 'Bloquée',
  close: 'Fermer',
  completedTooltip: 'Complétée',
  configuration: 'Configuration',
  configurationsTitle: 'Écoles',
  copyId: 'Copier l’identifiant',
  copyIdNotification: 'L’identifiant est copié dans le presse-papier!',
  createNewSchool: 'Créer une nouvelle configuration',
  daysPerCycle: 'Jour par cycle',
  deletedAccount: 'Cette fiche est effacée',
  deleteUser: 'Effacer l’usager',
  demoMode: 'Démo',
  editAccountDetails: 'Modifier les détails',
  editAccountSections: 'Modifier les groupes-mat.',
  email: 'Courriel',
  endDate: 'Date de fin',
  getSupport: 'Obtenir de l’aide',
  idOrEmail: 'Id ou courriel',
  integrations: 'Connecteurs',
  language: 'Langue',
  lateTooltip: 'En retard',
  loadingSchoolConfigErrorMessage: 'Une erreur est survenue en chargeant la configuration. Merci de recharger.',
  loadingSchoolConfigMessage: 'Chargement de la configuration. Merci de patienter…',
  lockedTooltip: 'Dépendance non résolue',
  logout: 'Fermer la session',
  manageThisSchool: 'Gérer cette école',
  minutesPerPeriod: 'Minutes par période',
  newSchool: 'Nouvelle école',
  notStartedTooltip: 'Non démarrée et sans dépendance',
  onboardingDashboard: 'État de l’embarquement',
  periodsPerDay: 'Périodes par jour',
  school: 'École',
  schoolConfigs: 'Configurations d’école',
  schoolName: 'Nom de l’école',
  schoolTitle: 'Titre de l’école (optionnel)',
  search: 'Recherche',
  searchResults: 'Résultat de la recherche pour: ',
  searchText: 'Recherche texte',
  selectAccountsToKeep: 'Choisir les fiches admin à conserver',
  selectAllAccountsTooltip: 'Cocher/décocher toutes les fiches',
  selectSchoolConfig: 'Sélectionner une configuration…',
  selectSchoolConfigTitle: 'Sélectionner la configuration',
  settingsMenu: 'Options générales',
  showDemoSchools: 'Écoles démo',
  startDate: 'Date de début',
  status: 'État',
  steps: 'Étapes',
  username: 'Nom d’utilisateur',
  users: 'Utilisateurs',
  usersPartial: 'Utilisateurs (résultats partiels)',
  valid: 'Valide',
  viewComments: 'Voir les commentaires',
  viewPlanner: 'Voir agenda',
  year: 'Année:'
};
