import * as CC from '@buf/studyo_studyo.bufbuild_es/studyo/type_canvas_pb';
import { CanvasTerm } from './interfaces';

export class GrpcCanvasTerm implements CanvasTerm {
  constructor(private readonly pb: CC.Term) {}

  get id() {
    return this.pb.id;
  }

  get name() {
    return this.pb.name;
  }

  get startDate() {
    const date = this.pb.startAt;
    return date?.toDate();
  }

  get endDate() {
    const date = this.pb.endAt;
    return date?.toDate();
  }

  get state() {
    return this.pb.state;
  }
}
