import { Typography } from '@mui/material';
import { SpecialDayModel } from '@shared/models/config';
import { CycleDayEffect } from '@shared/models/types';
import { CalendarComponentsStrings } from '@shared/resources/strings/insights/components/CalendarComponentsStrings';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface DisplayCycleDayEffectProps {
  specialDay: SpecialDayModel;
}

function cycleDayEffectLabel(effect: CycleDayEffect, strings: CalendarComponentsStrings) {
  switch (effect) {
    case 'preserve':
      return '';
    case 'insert':
      return strings.shiftToNextShort;
    case 'sequence':
      return strings.skipShort;
    case 'reset':
      return strings.restartShort;
    case 'exception':
      return strings.exceptionEffectShort;
    default:
      return '';
  }
}

export const DisplayCycleDayEffect = observer((props: DisplayCycleDayEffectProps) => {
  const { specialDay } = props;
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return <Typography variant="caption">{cycleDayEffectLabel(specialDay.cycleDayEffect, strings)}</Typography>;
});
