import { css } from '@emotion/css';
import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  SxProps,
  ThemeProvider,
  createTheme,
  useTheme
} from '@mui/material';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { AuthorizationRole } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ParentsLightColor, StudentsLightColor, TeachersLightColor } from '../../Constants';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface UserSchoolConfigurationsDialogProps extends DialogResult<void> {
  sx?: SxProps;
}

export const UserSchoolConfigurationsDialog = observer((props: UserSchoolConfigurationsDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views;
  const theme = useTheme();

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createUserSchoolConfigurations(onSuccess!, onCancel!)
  );

  const navigate = useNavigate();
  const params = useParams();
  const currentConfigId = params.configId ?? '';

  const chipLabelClassName = css({ fontSize: theme.typography.body2.fontSize, fontWeight: 300 });

  function chipColorFromRole(role: AuthorizationRole): string | undefined {
    switch (role) {
      case 'teacher':
        return TeachersLightColor;

      case 'student':
        return StudentsLightColor;

      case 'parent':
        return ParentsLightColor;

      default:
        return undefined;
    }
  }

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog sx={sx} open fullWidth maxWidth="sm" scroll="paper" onClose={() => void viewModel.close()}>
        <DialogTitle>{strings.selectSchoolConfigTitle}</DialogTitle>

        <DialogContent dividers>
          <Card sx={{ m: 0, px: 0 }}>
            <List disablePadding sx={{ height: '100%', width: '100%' }}>
              {viewModel.schoolConfigurations.map((config, index, items) => (
                <Fragment key={config.schoolConfiguration.id}>
                  <ListItemButton
                    selected={config.schoolConfiguration.id === currentConfigId}
                    onClick={() => void viewModel.navigateToConfig(config.schoolConfiguration.id, navigate)}
                  >
                    <Box flex={1} display="flex" flexDirection="row" alignItems="center">
                      <ListItemText
                        sx={{ flex: 1 }}
                        primary={config.schoolConfiguration.schoolName}
                        secondary={SchoolYearConfigurationUtils.displayTitle(config.schoolConfiguration)}
                      />

                      {config.roles.map((role) => {
                        const chipColor = chipColorFromRole(role);
                        const chipStyle: CSSProperties =
                          (chipColor != null && {
                            borderColor: chipColor
                          }) ||
                          {};

                        return (
                          <Box key={role} marginLeft={1}>
                            <Chip
                              variant="outlined"
                              label={localizationService.localizedStrings.models.account.roleTitleForAuthorizationRole(
                                role
                              )}
                              sx={{ cursor: 'pointer' }}
                              classes={{ label: chipLabelClassName }}
                              style={chipStyle}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </ListItemButton>
                  {index < items.length - 1 && <Divider />}
                </Fragment>
              ))}
            </List>
          </Card>
        </DialogContent>

        <DialogActions>
          <InsightsButton onClick={() => void viewModel.close()}>{strings.close}</InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
