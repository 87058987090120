import * as CPB from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/canvas_pb';
import * as TCPB from '@buf/studyo_studyo.bufbuild_es/studyo/type_canvas_pb';
import { Canvas } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/canvas_connect';
import { CanvasTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class CanvasGrpcTransport extends BaseGrpcTransport implements CanvasTransport {
  async fetchCanvasExternalAccountDetails(externalAccountId: string): Promise<CPB.GetExternalAccountDetailsResponse> {
    console.log('Fetching Canvas external account...');

    const request = new CPB.GetExternalAccountDetailsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(Canvas, Canvas.methods.getExternalAccountDetails, request);

    console.log('Successfully fetched Canvas external account.');

    return response;
  }

  async createOrUpdateCanvasExternalAccount(
    externalAccountId: string | undefined,
    configId: string,
    serverUrl: string,
    token: string,
    accountName: string
  ): Promise<CPB.CreateOrUpdateExternalAccountResponse> {
    console.log('Creating or updating Canvas external account...');

    const request = new CPB.CreateOrUpdateExternalAccountRequest();
    request.externalAccountId = externalAccountId ?? '';
    request.configId = configId;
    request.serverUrl = serverUrl;
    request.token = token;
    request.accountName = accountName;

    const response = await this.performRequest(Canvas, Canvas.methods.createOrUpdateExternalAccount, request);

    console.log('Successfully created or updated Canvas external account.');

    return response;
  }

  async updateCanvasExternalAccountSettings(
    externalAccountId: string,
    configId: string,
    subAccountId: bigint,
    termIds: bigint[],
    shouldDisplaySections: boolean,
    requiredCourseCodePrefix: string,
    assignmentGroupMappings: TCPB.AssignmentGroupMapping[],
    isIgnoringUnmappedGroups: boolean
  ): Promise<CPB.UpdateExternalAccountSettingsResponse> {
    console.log('Updating Canvas external account settings...');

    const request = new CPB.UpdateExternalAccountSettingsRequest();
    request.externalAccountId = externalAccountId;
    request.configId = configId;
    request.subAccountId = subAccountId;
    request.termIds = termIds;
    request.isShowingSections = shouldDisplaySections;
    request.requiredCourseCodePrefix = requiredCourseCodePrefix;
    request.assignmentGroupMappings = assignmentGroupMappings;
    request.isIgnoringUnmappedGroups = isIgnoringUnmappedGroups;

    const response = await this.performRequest(Canvas, Canvas.methods.updateExternalAccountSettings, request);

    console.log('Successfully updated Canvas external account settings.');

    return response;
  }

  async fetchCanvasSubAccounts(externalAccountId: string): Promise<TCPB.Account[]> {
    console.log('Fetching Canvas sub-accounts...');

    const request = new CPB.GetAccountsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(Canvas, Canvas.methods.getAccounts, request);

    console.log('Successfully fetched Canvas sub-accounts.');

    return response.accounts;
  }

  async fetchCanvasTerms(externalAccountId: string): Promise<TCPB.Term[]> {
    console.log('Fetching Canvas terms...');

    const request = new CPB.GetTermsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(Canvas, Canvas.methods.getTerms, request);

    console.log('Successfully fetched Canvas terms.');

    return response.terms;
  }

  async fetchCanvasAssignmentGroupNames(externalAccountId: string): Promise<string[]> {
    console.log('Fetching Canvas assignment group names...');

    const request = new CPB.GetAssignmentGroupsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(Canvas, Canvas.methods.getAssignmentGroups, request);

    response.groups.forEach((gl) => console.log(gl.weight));

    console.log('Successfully fetched Canvas assignment group names.');

    return response.uniqueGroupNames;
  }
}
