import { EntityListKind, FileEncoding, FileKind } from '@shared/models/types';

export interface ImportViewsStrings {
  addConcatenationTitle: string;
  addExpectedFileTitle: string;
  addTransformationTitle: string;
  addButton: string;
  addNewSession: string;
  cancel: string;
  close: string;
  concatenatedColumnCountLabel: string;
  concatenationNameLabel: string;
  directDataConfigurationLabel: string;
  directDataTableNameLabel: string;
  deleteAutomatedImportTootip: string;
  deleteSessionTooltip: string;
  destructiveOffConfirmation: string;
  destructiveOffLabel: string;
  destructiveOnConfirmation: string;
  destructiveOnLabel: string;
  fileAlreadyHasUrlWarning: string;
  fileDescriptionLabel: string;
  fileEncodingLabel: string;
  fileEncoding: (encoding: FileEncoding) => string;
  fileHasHeaderLabel: string;
  fileKindLabel: string;
  fileKind: (kind: FileKind) => string;
  fileNameLabel: string;
  fileUrlLabel: string;
  importDataButton: string;
  importDataErrorMessage: string;
  importSessionButton: string;
  importSessionFromOtherSchool: string;
  importSessionFromSchoolError: string;
  importSessionLabel: string;
  importSessions: string;
  importSessionTitle: string;
  incidentsSelection: string;
  incidentsToAllowSelectionMessage: string;
  indexedColumnLabel: string;
  indexedData: string;
  indexedSourceDescription: (label: string, columnIndex: number) => string;
  indexedSourcesLabel: string;
  isSubstitutionLabel: string;
  labelLabel: string;
  loadingAutomatedImportError: string;
  loadingAutomatedImportMessage: string;
  loadingConfigurationsError: string;
  loadingImportDataMessage: string;
  loadingSessionMessage: string;
  loadingSessionsMessage: string;
  loadingSourceDataMessage: string;
  loadingTransformationMessage: string;
  lookupSourceDataTitle: (label: string, name: string, columnIndexes: number[]) => string;
  noAnswer: string;
  noImportOption: string;
  noIncident: string;
  noIncidentToAllow: string;
  noIndexedSource: string;
  noOperation: string;
  noSchema: string;
  noSchoolConfiguration: string;
  noSchoolSelected: string;
  noSession: string;
  onboardingQuestionLabel: string;
  otherIncidents: string;
  saveButton: string;
  schemaImportOptions: string;
  schoolLabel: string;
  schoolYearLabel: string;
  selectedImportOptions: string;
  sessionErrorMessage: string;
  sessionsErrorMessage: string;
  sheetNameLabel: string;
  sourceData: string;
  sourceDataErrorMessage: string;
  sourceLabelLabel: string;
  sourceLabelsLabel: string;
  suggestedImportOptions: string;
  targetColumnNames: string;
  targetColumnNamesHint: string;
  targetSchemaLabel: string;
  textContent: string;
  topRowsSkippedLabel: string;
  transformationDescriptionLabel: string;
  transformationErrorMessage: string;
  transformationNameLabel: string;
  transformedData: string;
  useCommonColumnNamesLabel: string;

  // Create/edit sessions
  createSessionTitle: string;
  createSessionCancelButtonLabel: string;
  createSessionCreateButtonLabel: string;
  editSessionTitle: string;
  editSessionCancelButtonLabel: string;
  editSessionSaveButtonLabel: string;
  includeParentsLabel: string;
  sessionDescriptionLabel: string;
  sessionNameLabel: string;
  scheduleDayKindLabel: string;
  scheduleDayKindCycleDayLabel: string;
  scheduleDayKindDayOfWeekLabel: string;
  scheduleDayKindDateLabel: string;
  schedulePeriodKindLabel: string;
  schedulePeriodKindPeriodTag: string;
  schedulePeriodKindTime: string;
  scheduleTeachersGroupingLabel: string;
  studentSectionsGroupingLabel: string;
  createSessionError: string;
  editSessionError: string;

  // Create/edit automated imports
  acceptedIncidentsHeader: string;
  automatedImportDaysLabel: string;
  automatedImportNameLabel: string;
  automatedImportTimeLabel: string;
  automatedTransformationImportLabelLabel: string;
  cancelAutomatedImportButtonLabel: string;
  createAutomatedImportButtonLabel: string;
  createAutomatedImportTitle: string;
  createAutomatedTransformationImportTitle: string;
  editAutomatedImportButtonLabel: string;
  editAutomatedImportTitle: string;
  editAutomatedTransformationImportTitle: string;
  entityCounts: (entityCount: number, incidentCount: number) => string;
  entityListKind: (listKind: EntityListKind) => string;
  entityListKindColumn: string;
  entityMaxCountColumn: string;
  entityMinCountColumn: string;
  expectedEntitiesHeader: string;
  incidentCodeColumn: string;
  incidentMaxCountColumn: string;
  incidentMinCountColumn: string;
  incidentTextColumn: string;
  isCompleteDataLabel: string;
  unexpectedAutomatedImportError: string;
  unknownTransformation: string;

  parentsSourceFileTitle: string;
  parentsSourceFileDescription: string;
  teachersSourceFileTitle: string;
  teachersSourceFileDescription: string;
  sectionsSourceFileTitle: string;
  sectionsSourceFileDescription: string;
  schedulesSourceFileTitle: string;
  schedulesSourceFileDescription: (
    scheduleDayKind: string,
    schedulePeriodKind: string,
    groupTeachers: boolean
  ) => string;
  studentsSourceFileTitle: string;
  studentsSourceFileDescription: (groupSections: boolean) => string;
}

export const EnglishImportViewsStrings: ImportViewsStrings = {
  addConcatenationTitle: 'Add a concatenation',
  addExpectedFileTitle: 'Add an expected file',
  addTransformationTitle: 'Add a transformation',
  addButton: 'Add',
  addNewSession: 'Add new session',
  cancel: 'Cancel',
  close: 'Close',
  concatenatedColumnCountLabel: 'Number of columns to keep (0 for all)',
  concatenationNameLabel: 'Name',
  deleteAutomatedImportTootip: 'Delete this automated import',
  deleteSessionTooltip: 'Delete this session',
  destructiveOffConfirmation: 'The import will be additive, only adding or updating entries.',
  destructiveOffLabel: 'Additive mode (do not remove anything)',
  destructiveOnConfirmation:
    'The import will be destructive, adding new entries, updating existing entries and removing entries not found anymore in the data.',
  destructiveOnLabel: 'Destructive mode (remove what is not found anymore)',
  directDataConfigurationLabel: 'Configuration',
  directDataTableNameLabel: 'Table',
  fileAlreadyHasUrlWarning:
    'This file already points to an uploaded file. Linking it to a question will remove that url.',
  fileDescriptionLabel: 'Description',
  fileEncodingLabel: 'File encoding',
  fileEncoding: (encoding) => {
    switch (encoding) {
      case 'utf8':
        return 'UTF8';
      case 'unicode16':
        return 'Unicode';
      case 'ansi':
        return 'Ansi / Windows 1252';
      case 'unknown':
        return 'Unknown';
    }
  },
  fileHasHeaderLabel: 'This file contains a header row',
  fileKindLabel: 'File kind',
  fileKind: (kind) => {
    switch (kind) {
      case 'csv':
        return 'Comma-separated values';
      case 'excel':
        return 'Excel';
      case 'pdf':
        return 'Pdf';
      case 'text':
        return 'Simple text';
      case 'xml':
        return 'Xml';
      case 'zip':
        return 'Zip archive';
      case 'direct-data':
        return 'Direct data';
      case 'other':
        return 'Other';
    }
  },
  fileNameLabel: 'Name',
  fileUrlLabel: 'File link',
  importDataButton: 'Import',
  importDataErrorMessage: 'An error occurred while loading import data',
  importSessionButton: 'Import',
  importSessionFromOtherSchool: 'Import session from another school',
  importSessionFromSchoolError: 'An unexpected error occurred while saving a copy of the selected import session',
  importSessionLabel: 'Import session',
  importSessions: 'Import sessions',
  importSessionTitle: 'Import Session',
  incidentsSelection: 'Import - Final validation',
  incidentsToAllowSelectionMessage: 'Please select the incidents to ignore during the import:',
  indexedColumnLabel: 'Indexed column (0-based)',
  indexedData: 'Indexed Data',
  indexedSourceDescription: (label, columnIndex) => `Label ${label}, column #${columnIndex}`,
  indexedSourcesLabel: 'Other indexed sources',
  isSubstitutionLabel: '',
  labelLabel: 'Label (single letter)',
  loadingAutomatedImportError: 'An error occurred while loading automated import details',
  loadingAutomatedImportMessage: 'Loading automated import details. Please wait…',
  loadingConfigurationsError: 'An error occurred while loading school configurations',
  loadingImportDataMessage: 'Loading data. Please wait…',
  loadingSessionMessage: 'Loading session. Please wait…',
  loadingSessionsMessage: 'Loading sessions. Please wait…',
  loadingSourceDataMessage: 'Loading source data. Please wait…',
  loadingTransformationMessage: 'Loading transformation. Please wait…',
  lookupSourceDataTitle: (label, name, columnIndexes) => `Look-up ${label} @ ${columnIndexes.join(',')} - ${name}`,
  noAnswer: 'No answer',
  noImportOption: 'None',
  noIncident: 'There are no incidents in the test import. You can safely import the data.',
  noIncidentToAllow:
    'There are no errors in the test import. You can import the data, given the other incidents are acceptable.',
  noIndexedSource: '(none)',
  noOperation: 'As is (no operation)',
  noSchema: '(none)',
  noSchoolConfiguration: 'No school found in that year',
  noSchoolSelected: 'Please select a school first',
  noSession: 'No import session found in that school',
  onboardingQuestionLabel: 'Onboarding question',
  otherIncidents: "Incidents that don't prevent an import:",
  saveButton: 'Save',
  schemaImportOptions: 'Import options',
  schoolLabel: 'School',
  schoolYearLabel: 'School year',
  selectedImportOptions: 'Selected import options:',
  sessionErrorMessage: 'An error occurred while loading import session details',
  sessionsErrorMessage: 'An error occurred while loading import sessions',
  sheetNameLabel: 'Sheet name (using the first when empty)',
  sourceData: 'Source Data',
  sourceDataErrorMessage: 'An error occurred while loading source data',
  sourceLabelLabel: 'Source data label',
  sourceLabelsLabel: 'Source labels to regroup',
  suggestedImportOptions: 'Default import options',
  targetColumnNames: 'Column name(s)',
  targetColumnNamesHint: 'Name|Name|…',
  targetSchemaLabel: 'Target schema',
  textContent: 'Text answer',
  topRowsSkippedLabel: 'Rows to skip atop (before any header)',
  transformationDescriptionLabel: 'Description',
  transformationErrorMessage: 'An error occurred while loading import session transformation',
  transformationNameLabel: 'Name',
  transformedData: 'Transformed Data',
  useCommonColumnNamesLabel: 'Use common column names',

  // Create/edit sessions
  createSessionTitle: 'Add New Session',
  createSessionCancelButtonLabel: 'Cancel',
  createSessionCreateButtonLabel: 'Create',
  editSessionTitle: 'Edit session',
  editSessionCancelButtonLabel: 'Cancel',
  editSessionSaveButtonLabel: 'Save',
  includeParentsLabel: 'Include a parents file',
  sessionDescriptionLabel: 'Description',
  sessionNameLabel: 'Name',
  scheduleDayKindLabel: 'Schedule day kind',
  scheduleDayKindCycleDayLabel: 'Cycle day',
  scheduleDayKindDayOfWeekLabel: 'Day of week',
  scheduleDayKindDateLabel: 'Date',
  schedulePeriodKindLabel: 'Schedule period kind',
  schedulePeriodKindPeriodTag: 'Period tag',
  schedulePeriodKindTime: 'Start and end times',
  scheduleTeachersGroupingLabel: 'Schedule teachers require grouping',
  studentSectionsGroupingLabel: 'Student sections require grouping',
  createSessionError: 'An unexpected error occurred',
  editSessionError: 'An unexpected error occurred',

  // Create/edit automated imports
  acceptedIncidentsHeader: 'Accepted Incidents',
  automatedImportDaysLabel: 'Days of the week',
  automatedImportNameLabel: 'Name',
  automatedImportTimeLabel: 'Time',
  automatedTransformationImportLabelLabel: 'Transformation Label',
  cancelAutomatedImportButtonLabel: 'Cancel',
  createAutomatedImportButtonLabel: 'Create',
  createAutomatedImportTitle: 'Create Automated Import',
  createAutomatedTransformationImportTitle: 'Create Transformation Import',
  editAutomatedImportButtonLabel: 'Save',
  editAutomatedImportTitle: 'Edit Automated Import',
  editAutomatedTransformationImportTitle: 'Edit Transformation Import',
  entityCounts: (entityCount, incidentCount) =>
    `${entityCount} record${entityCount === 1 ? '' : 's'} / ${incidentCount} incident${incidentCount === 1 ? '' : 's'}`,
  entityListKind: (listKind) => {
    switch (listKind) {
      case 'added-entities':
        return 'Added entities';
      case 'updated-entities':
        return 'Updated entities';
      case 'removed-entities':
        return 'Removed entities';
      case 'skipped-entities':
        return 'Skipped entities';
    }
  },
  entityListKindColumn: 'Kind',
  entityMaxCountColumn: 'Max',
  entityMinCountColumn: 'Min',
  expectedEntitiesHeader: 'Expected Entities',
  incidentCodeColumn: 'Code',
  incidentMaxCountColumn: 'Max',
  incidentMinCountColumn: 'Min',
  incidentTextColumn: 'Containing',
  isCompleteDataLabel: 'Destructive: Remove data not found anymore',
  unexpectedAutomatedImportError: 'An unexpected error occurred',
  unknownTransformation: '(Unknown transformation)',

  parentsSourceFileTitle: 'Parents',
  parentsSourceFileDescription: 'Regular parents file with children grouping',
  teachersSourceFileTitle: 'Teachers',
  teachersSourceFileDescription: 'Regular teachers file',
  sectionsSourceFileTitle: 'Sections',
  sectionsSourceFileDescription: 'Regular sections file',
  schedulesSourceFileTitle: 'Schedules',
  schedulesSourceFileDescription: (scheduleDayKind, schedulePeriodKind, groupTeachers) =>
    `Regular schedules file by ${scheduleDayKind} and ${schedulePeriodKind}${
      groupTeachers ? ' with teachers grouping' : ''
    }`,
  studentsSourceFileTitle: 'Students',
  studentsSourceFileDescription: (groupSections) =>
    `Regular students file${groupSections ? ' with student section grouping' : ''}`
};

export const FrenchImportViewsStrings: ImportViewsStrings = {
  addConcatenationTitle: 'Ajouter une concaténation',
  addExpectedFileTitle: 'Ajouter un fichier source',
  addTransformationTitle: 'Ajouter une transformation',
  addButton: 'Ajouter',
  addNewSession: 'Ajouter une session',
  cancel: 'Annuler',
  close: 'Fermer',
  concatenatedColumnCountLabel: 'Nombre de colonnes à conserver (0 pour toutes)',
  concatenationNameLabel: 'Nom',
  deleteAutomatedImportTootip: 'Effacer cette importation automatisée',
  deleteSessionTooltip: 'Effacer cette session',
  destructiveOffConfirmation: 'L’importation sera additive et ne fera qu’ajouter ou mettre à jour des entrées.',
  destructiveOffLabel: 'Additif (ne rien effacer)',
  destructiveOnConfirmation:
    'L’importation sera destructive et pourra ajouter ou mettre à jour des entrées, mais aussi effacer les entrées qui ne se trouvent plus dans les données.',
  destructiveOnLabel: 'Destructif (effacer ce qui n’apparaît plus)',
  directDataConfigurationLabel: 'Configuration',
  directDataTableNameLabel: 'Table',
  fileAlreadyHasUrlWarning:
    'Ce fichier contient déjà un lien vers un fichier téléversé. Le lier à une question va retirer ce lien.',
  fileDescriptionLabel: 'Description',
  fileEncodingLabel: 'Encodage du fichier',
  fileEncoding: (encoding) => {
    switch (encoding) {
      case 'utf8':
        return 'UTF8';
      case 'unicode16':
        return 'Unicode';
      case 'ansi':
        return 'Ansi / Windows 1252';
      case 'unknown':
        return 'Inconnu';
    }
  },
  fileHasHeaderLabel: 'Ce fichier contient une entête',
  fileKindLabel: 'Type de fichier',
  fileKind: (kind) => {
    switch (kind) {
      case 'csv':
        return 'Valeurs séparées par virgules (CSV)';
      case 'excel':
        return 'Excel';
      case 'pdf':
        return 'Pdf';
      case 'text':
        return 'Texte simple';
      case 'xml':
        return 'Xml';
      case 'zip':
        return 'Archive Zip';
      case 'direct-data':
        return 'Données directes';
      case 'other':
        return 'Autre';
    }
  },
  fileNameLabel: 'Nom',
  fileUrlLabel: 'Lien vers le fichier',
  importDataButton: 'Importer',
  importDataErrorMessage: 'Une erreur est survenue lors du chargement des données de l’importation',
  importSessionButton: 'Importer',
  importSessionFromOtherSchool: 'Importer une session d’une autre école',
  importSessionFromSchoolError:
    'Une erreur imprévue est survenue à la sauvegarde d’une copie de la recette d’importation choisie',
  importSessionLabel: 'Session d’importation',
  importSessions: 'Sessions d’importation',
  importSessionTitle: 'Importer une session',
  incidentsSelection: 'Importation - Validation finale',
  incidentsToAllowSelectionMessage: 'Veuillez sélectionner les incidents à ignorer lors de l’importation:',
  indexedColumnLabel: 'Colonne indexée (base 0)',
  indexedData: 'Données indexées',
  indexedSourceDescription: (label, columnIndex) => `Étiquette ${label}, colonne #${columnIndex}`,
  indexedSourcesLabel: 'Autres sources indexées',
  isSubstitutionLabel: '',
  labelLabel: 'Étiquette (une lettre)',
  loadingAutomatedImportError: 'Une erreur est survenue lors du chargement de l’importation automatisée',
  loadingAutomatedImportMessage: 'Chargement de l’importation automatisée. Merci de patienter…',
  loadingConfigurationsError: 'Une erreur est survenue lors du chargement des écoles',
  loadingImportDataMessage: 'Chargement des données. Merci de patienter…',
  loadingSessionMessage: 'Chargement de la session. Merci de patienter…',
  loadingSessionsMessage: 'Chargement des sessions. Merci de patienter…',
  loadingSourceDataMessage: 'Chargement des données source. Merci de patienter…',
  loadingTransformationMessage: 'Chargement de la transformation. Merci de patienter…',
  lookupSourceDataTitle: (label, name, columnIndexes) => `Index ${label} @ ${columnIndexes.join(',')} - ${name}`,
  noAnswer: 'Pas de réponse',
  noImportOption: 'Aucune',
  noIncident: 'Il n’y a aucun incident dans l’importation test. Vous pouvez lancer l’importation en toute securité.',
  noIncidentToAllow:
    'Il n’y a aucune erreur dans l’importation test. Si les autres incidents sont acceptables, vous pouvez lancer l’importation.',
  noIndexedSource: '(aucune)',
  noOperation: 'Tel quel (sans opération)',
  noSchema: '(aucun)',
  noSchoolConfiguration: 'Aucune école trouvée dans cette année',
  noSchoolSelected: 'Veuillez choisir une école',
  noSession: 'Aucune session d’importation dans cette école',
  onboardingQuestionLabel: 'Question d’embarquement',
  otherIncidents: 'Ces incidents n’empêchent pas l’importation:',
  saveButton: 'Sauvegarder',
  schemaImportOptions: 'Options d’importation',
  schoolLabel: 'École',
  schoolYearLabel: 'Année scolaire',
  selectedImportOptions: 'Options d’importation actives:',
  sessionErrorMessage: 'Une erreur est survenue lors du chargement des détails de l’importation',
  sessionsErrorMessage: 'Une erreur est survenue lors du chargement de la session d’importation',
  sheetNameLabel: 'Nom de feuille (la première lorsque vide)',
  sourceData: 'Données sources',
  sourceDataErrorMessage: 'Une erreur est survenue lors du chargement des données source',
  sourceLabelLabel: 'Étiquette source',
  sourceLabelsLabel: 'Étiquettes source à regrouper',
  suggestedImportOptions: 'Options d’importation par défaut',
  targetColumnNames: 'Nom(s) de colonne',
  targetColumnNamesHint: 'Nom|Nom|…',
  targetSchemaLabel: 'Schéma cible',
  textContent: 'Réponse textuelle',
  topRowsSkippedLabel: 'Rangées à ignorer au début (avant toute entête)',
  transformationDescriptionLabel: 'Description',
  transformationErrorMessage: 'Une erreur est survenue lors du chargement de la transformation de l’importation',
  transformationNameLabel: 'Nom',
  transformedData: 'Données transformées',
  useCommonColumnNamesLabel: 'Utiliser les noms de colonne en commun',

  // Create sessions
  createSessionTitle: 'Ajouter une session',
  createSessionCancelButtonLabel: 'Annuler',
  createSessionCreateButtonLabel: 'Créer',
  editSessionTitle: 'Modifier cette session',
  editSessionCancelButtonLabel: 'Annuler',
  editSessionSaveButtonLabel: 'Enregistrer',
  includeParentsLabel: 'Ajouter un fichier de parents',
  sessionDescriptionLabel: 'Description',
  sessionNameLabel: 'Nom',
  scheduleDayKindLabel: 'Type de jour des horaires',
  scheduleDayKindCycleDayLabel: 'Jour cycle',
  scheduleDayKindDayOfWeekLabel: 'Jour de la semaine',
  scheduleDayKindDateLabel: 'Date',
  schedulePeriodKindLabel: 'Type de période des horaires',
  schedulePeriodKindPeriodTag: 'Tags de période',
  schedulePeriodKindTime: 'Heures de début et fin',
  scheduleTeachersGroupingLabel: 'Groupement des enseignants d’horaires requis',
  studentSectionsGroupingLabel: 'Groupement des cours étudiants requis',
  createSessionError: 'Une erreur imprévue est survenue',
  editSessionError: 'Une erreur imprévue est survenue',

  // Create/edit automated imports
  acceptedIncidentsHeader: 'Incidents autorisés',
  automatedImportDaysLabel: 'Jours de la semaine',
  automatedImportNameLabel: 'Nom',
  automatedImportTimeLabel: 'Heure',
  automatedTransformationImportLabelLabel: 'Étiquette de transformation',
  cancelAutomatedImportButtonLabel: 'Annuler',
  createAutomatedImportButtonLabel: 'Créer',
  createAutomatedImportTitle: 'Créer importation automatisée',
  createAutomatedTransformationImportTitle: 'Créer importation de transformation',
  editAutomatedImportButtonLabel: 'Enregistrer',
  editAutomatedImportTitle: 'Modifier importation automatisée',
  editAutomatedTransformationImportTitle: 'Modifier importation de transformation',
  entityCounts: (entityCount, incidentCount) =>
    `${entityCount} fiche${entityCount < 2 ? '' : 's'} / ${incidentCount} incident${incidentCount < 2 ? '' : 's'}`,
  entityListKind: (listKind) => {
    switch (listKind) {
      case 'added-entities':
        return 'Entités ajoutées';
      case 'updated-entities':
        return 'Entitées mises à jour';
      case 'removed-entities':
        return 'Entitées effacées';
      case 'skipped-entities':
        return 'Entités ignorées';
    }
  },
  entityListKindColumn: 'Type',
  entityMaxCountColumn: 'Max',
  entityMinCountColumn: 'Min',
  expectedEntitiesHeader: 'Entités attendues',
  incidentCodeColumn: 'Code',
  incidentMaxCountColumn: 'Max',
  incidentMinCountColumn: 'Min',
  incidentTextColumn: 'Contenant',
  isCompleteDataLabel: 'Destructif: Effacer les données qui ne sont plus là',
  unexpectedAutomatedImportError: 'Une erreur imprévue est survenue',
  unknownTransformation: '(Transformation inconnue)',

  parentsSourceFileTitle: 'Parents',
  parentsSourceFileDescription: 'Fichier régulier pour les parents en groupant les enfants',
  teachersSourceFileTitle: 'Enseignants',
  teachersSourceFileDescription: 'Fichier régulier pour les enseignants',
  sectionsSourceFileTitle: 'Groupes-matière',
  sectionsSourceFileDescription: 'Fichier régulier pour les groupes-matière',
  schedulesSourceFileTitle: 'Horaires',
  schedulesSourceFileDescription: (scheduleDayKind, schedulePeriodKind, groupTeachers) =>
    `Fichier régulier d’horaires par ${scheduleDayKind} et ${schedulePeriodKind}${
      groupTeachers ? ' en groupant les enseignants' : ''
    }`,
  studentsSourceFileTitle: 'Élèves',
  studentsSourceFileDescription: (groupSections) =>
    `Fichier régulier pour les élèves${groupSections ? ' en groupant les cours' : ''}`
};
