export interface SaveDialogStrings {
  retry: string;
  ok: string;
}

export const EnglishSaveDialogStrings: SaveDialogStrings = {
  retry: 'Retry',
  ok: 'OK'
};

export const FrenchSaveDialogStrings: SaveDialogStrings = {
  retry: 'Réessayer',
  ok: 'OK'
};
