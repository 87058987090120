import { AlertService, NavigationService } from '@insights/services';
import { EditableImportSession, ImportSession, SourceFile } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { orderBy } from 'lodash';
import { computed, makeObservable } from 'mobx';

export interface ImportSessionFileListViewModel {
  readonly expectedFiles: SourceFile[];

  addExpectedFile(): Promise<void>;
  uploadFile(file: SourceFile): Promise<void>;
  linkFileToProcess(file: SourceFile): Promise<void>;
  linkFileToConfiguration(file: SourceFile): Promise<void>;
  linkFileManually(file: SourceFile): Promise<void>;
  editFileOptions(file: SourceFile): Promise<void>;
  removeFile(file: SourceFile): Promise<void>;
}

export class AppImportSessionFileListViewModel implements ImportSessionFileListViewModel {
  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _session: ImportSession
  ) {
    makeObservable(this);
  }

  @computed
  get expectedFiles() {
    return orderBy(this._session.expectedFiles, (f) => f.label);
  }

  async addExpectedFile(): Promise<void> {
    const result = await this._navigationService.navigateToImportSessionAddOrEditExpectedFile(
      this._session.configId,
      this._session
    );

    if (result !== 'cancelled') {
      this._importSessionStore.invalidate();
    }
  }

  async uploadFile(file: SourceFile): Promise<void> {
    const result = await this._navigationService.navigateToImportSessionFileEdit(
      this._session.configId,
      this._session,
      file.label
    );

    if (result !== 'cancelled') {
      this._importSessionStore.invalidate();
    }
  }

  async linkFileToProcess(file: SourceFile): Promise<void> {
    const result = await this._navigationService.navigateToImportSessionFileProcessLink(
      this._session.configId,
      this._session,
      file.label
    );

    if (result !== 'cancelled') {
      this._importSessionStore.invalidate();
    }
  }

  async linkFileToConfiguration(file: SourceFile): Promise<void> {
    const result = await this._navigationService.navigateToImportSessionFileDirectData(
      this._session.configId,
      this._session,
      file.label
    );

    if (result !== 'cancelled') {
      this._importSessionStore.invalidate();
    }
  }

  async linkFileManually(file: SourceFile): Promise<void> {
    const result = await this._navigationService.navigateToImportSessionFileManualLink(
      this._session.configId,
      this._session,
      file.label
    );

    if (result !== 'cancelled') {
      this._importSessionStore.invalidate();
    }
  }

  async editFileOptions(file: SourceFile): Promise<void> {
    const result = await this._navigationService.navigateToImportSessionAddOrEditExpectedFile(
      this._session.configId,
      this._session,
      file.label
    );

    if (result !== 'cancelled') {
      this._importSessionStore.invalidate();
    }
  }

  async removeFile(file: SourceFile): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.import;
    const response = await this._alertService.showConfirmation({
      title: strings.confirmDeleteExpectedFileTitle,
      message: strings.confirmDeleteExpectedFileMessage(file.name, file.label)
    });

    if (response !== 'cancelled') {
      try {
        const session = new EditableImportSession(this._session);
        const fileToRemove = session.expectedFiles.find((f) => f.label === file.label);

        if (fileToRemove == null) {
          throw new Error('Could not find file with same label.');
        }

        fileToRemove.markAsDeleted();

        // No need for the data, we invalidate after.
        await this._importSessionStore.createOrUpdateImportSession(session, false);
        this._importSessionStore.invalidate();
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedErrorMessage + (error as Error).message
        });
      }
    }
  }
}
