import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SystemStyleObject } from '@mui/system';
import { MouseEvent } from 'react';

/**
 * Used to add event propagation interruption to an event handler,
 * while reducing boilerplate code and without needing to define
 * handler methods just to stop event propagation.
 *
 * To use:
 * import { withoutPropagation }  from '../../utils/ViewUtils';
 * ...
 * <RX.View onPress={withoutPropagation(() => doSomething)} ...
 * or
 * <RX.View onPress={withoutPropagation(handler)} ...
 *
 */
export const withoutPropagation = (handler: () => void) => (event: MouseEvent) => {
  event.stopPropagation(); // stop propagation of the event passed
  handler(); // invoke the handler
};

export function isSxArray(
  sx: SxProps<Theme> | undefined
): sx is readonly (boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>))[] {
  return Array.isArray(sx);
}
