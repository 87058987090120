import { SchoolYearConfiguration_SchedulePeriod as PBSchedulePeriod } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { BaseModel, SerializableModel } from '../Model';
import { PeriodKind, Time } from '../types';
import { periodKindFromProtobuf } from '../types/EnumConversion';

export interface SchedulePeriodModel extends SerializableModel<PBSchedulePeriod> {
  readonly tag: string;
  readonly startTime: Time;
  readonly endTime: Time;
  readonly kind: PeriodKind;
  readonly skipOccurrenceCount: boolean;
}

export class SchedulePeriod extends BaseModel<PBSchedulePeriod> implements SchedulePeriodModel {
  constructor(pb: PBSchedulePeriod) {
    super(pb);
  }

  get tag(): string {
    return this._pb.tag;
  }

  get startTime(): Time {
    const pbTime = this._pb.startTime;

    if (pbTime == null) {
      throw new Error('Invalid SchedulePeriod.startTime value received from backend');
    }

    return new Time(pbTime);
  }

  get endTime(): Time {
    const pbTime = this._pb.endTime;

    if (pbTime == null) {
      throw new Error('Invalid SchedulePeriod.endTime value received from backend');
    }

    return new Time(pbTime);
  }

  get kind(): PeriodKind {
    return periodKindFromProtobuf(this._pb.kind);
  }

  get skipOccurrenceCount(): boolean {
    return this._pb.skipOccurrenceCount;
  }
}
