import { QueueStatus as PBQueueStatus } from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import { QueueStatus } from './interfaces/QueueStatus';

export class GrpcQueueStatus implements QueueStatus {
  constructor(private readonly pb: PBQueueStatus) {}

  get position() {
    return this.pb.position;
  }

  get size() {
    return this.pb.size;
  }

  get time() {
    return this.pb.time?.toDate();
  }
}
