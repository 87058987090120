import { SectionInfo } from '@insights/models';
import { CustomFilterUtils, NamedFilter, SectionUtils } from '@insights/utils';
import { SectionsViewModel } from '@insights/viewmodels';
import { MTableAction, MTableActions, Column as TableColumn } from '@material-table/core';
import EnrollmentIcon from '@mui/icons-material/HowToReg';
import { Box, ListItemText, MenuItem, Snackbar, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { SectionModel } from '@shared/models/config';
import { AdminAuthorizationRoles, AdminOrTeacherAuthorizationRoles } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { ScreenType } from '@shared/services';
import { ReactRouterRouteService } from '@shared/web/services';
import { compact, isArray } from 'lodash';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import moize from 'moize';
import { CSSProperties, useRef, useState } from 'react';
import { DefaultTablePageSizes } from '../../Constants';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { InsightsAction, InsightsMaterialTable, TableRef } from '../InsightsMaterialTable';
import { MoreActionMenu } from '../MoreActionMenu';
import { SectionListTeachersCell } from '../SectionListTeachersCell';
import { SectionName } from '../SectionName';
import { Container, Expanded, Row } from '../layout';
import { SectionSchedulesSummary } from './SectionSchedulesSummary';

const TableStateKey = 'EditableSectionList';
const MoreActionKey = 'more';

export interface EditableSectionListProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title?: string;
  configId: string;
  sections: SectionsViewModel;
  usePagination?: boolean;
}

export const EditableSectionList = observer((props: EditableSectionListProps) => {
  const { accountService, localizationService, reactRouterRouteService, screenService } = useInsightsServices();
  const { sx = [], configId, className, style, title, sections, usePagination = true } = props;
  const theme = useTheme();
  const insightsStrings = localizationService.localizedStrings.insights;
  const sectionsStrings = insightsStrings.components.sections;
  const canCopyIds = accountService.isAllowed(['super-admin']);

  const [showToast, setShowToast] = useState(false);
  const [selectionEnabled, setSelectionEnabled] = useState(false);
  const tableRef = useRef<TableRef | undefined>();

  // We can memoize this info as it doesn't change.
  const getSearchableFields = useRef(
    moize(
      (s: SectionInfo) =>
        s.section != null
          ? [
              SectionUtils.formatTitle(s.section),
              s.section.gradeLevel,
              s.section.sectionNumber,
              s.section.defaultRoomName,
              s.section.importId,
              ...(s.teachers?.map((t) => AccountUtils.getDisplayLastFirstName(t.account)) ?? '')
            ]
          : [],
      {
        isSerialized: true,
        serializer: (s: SectionInfo[]) => [s[0].id]
      }
    )
  );

  // Screen sizes are taking into consideration that the navigation bar collapses when < extraLarge.
  const columns = [
    getTitleColumn(
      configId,
      getSearchableFields.current,
      sections.namedFilters,
      localizationService,
      reactRouterRouteService
    ),
    getTeachersColumn(configId, localizationService, screenService.screenType < ScreenType.medium),
    getGradeColumn(localizationService, screenService.screenType < ScreenType.large),
    getGroupColumn(localizationService, screenService.screenType < ScreenType.large),
    getRoomColumn(localizationService, screenService.screenType < ScreenType.extraLarge),
    getStudentCountColumn(localizationService, screenService.screenType < ScreenType.large),
    getSchedulesColumn(localizationService, screenService.screenType < ScreenType.large)
  ];

  const canAdd = accountService.isAllowed(AdminOrTeacherAuthorizationRoles);
  const canMultiEdit = accountService.isAllowed(AdminAuthorizationRoles);
  const canEdit = (section: SectionInfo) =>
    accountService.isAllowed(AdminAuthorizationRoles) ||
    accountService.isAccount([section.section?.defaultTeacherId ?? '']);
  const canEditTeachers = accountService.isAllowed(AdminAuthorizationRoles);
  const canManageConflicts = accountService.isAllowed(AdminAuthorizationRoles);

  const sectionSelectionAction: InsightsAction<SectionInfo> = {
    icon: selectionEnabled ? 'check_box' : 'check_box_outline_blank',
    tooltip: selectionEnabled
      ? sectionsStrings.disableSectionSelectionTooltip
      : sectionsStrings.enableSectionSelectionTooltip,
    onClick: toggleSelectionEnabled,
    disabled: !canMultiEdit
  };

  const actions: InsightsAction<SectionInfo>[] = [
    {
      key: MoreActionKey,
      icon: 'more_vert',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {},
      hidden: selectionEnabled
    },
    {
      icon: 'edit',
      position: 'toolbarOnSelect',
      tooltip: sectionsStrings.batchEditSections,
      onClick: (_, data) => void editSections(data),
      disabled: !canMultiEdit
    },
    {
      icon: 'add',
      isFreeAction: true,
      tooltip: sectionsStrings.addSectionTooltip,
      onClick: () => void sections.addSection(),
      disabled: !canAdd
    },
    {
      ...sectionSelectionAction,
      isFreeAction: true
    },
    {
      ...sectionSelectionAction,
      position: 'toolbarOnSelect'
    }
  ];

  if (accountService.isAllowed(['super-admin'])) {
    actions.push({
      icon: 'arrow_downward',
      isFreeAction: true,
      tooltip: sectionsStrings.exportToCsv,
      onClick: () => sections.exportToCsv()
    });
  }

  async function copyIdToClipboard(section: SectionModel): Promise<void> {
    await navigator.clipboard.writeText(section.id);
    setShowToast(true);
  }

  function editSections(data: SectionInfo | SectionInfo[]): Promise<void> {
    const sectionsInfo = isArray(data) ? data : [data];
    return sections.editSections(compact(sectionsInfo.map((s) => s.section)));
  }

  function toggleSelectionEnabled() {
    setSelectionEnabled(!selectionEnabled);
    // Clear the current selection
    tableRef.current?.onAllSelected(false);
  }

  return (
    <Box sx={sx} className={className} style={style}>
      <Container>
        <InsightsMaterialTable
          tableRef={tableRef}
          stateKey={TableStateKey}
          // This is to disable the card contour
          components={{
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>,
            Actions: (p) => (
              <Box display="flex">
                <MTableActions {...p} />
              </Box>
            ),
            Action: (p) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (p.action.hidden) {
                return null;
              }

              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (p.action.key === MoreActionKey) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const data = p.data as SectionInfo;
                const canEditThis = canEdit(data);

                return (
                  <Expanded>
                    <Row horizontalContentAlignment="right">
                      <AuthorizationRoleCondition allowedRoles={AdminOrTeacherAuthorizationRoles}>
                        <MoreActionMenu>
                          {canEditThis && (
                            <>
                              <MenuItem onClick={() => void editSections(data)}>
                                <ListItemText primary={sectionsStrings.editDetails} />
                              </MenuItem>
                              <MenuItem onClick={() => void sections.editStudents(data.section!)}>
                                <ListItemText primary={sectionsStrings.editStudents} />
                              </MenuItem>
                            </>
                          )}
                          {canEditTeachers && (
                            <MenuItem onClick={() => void sections.editTeachers(data.section!)}>
                              <ListItemText primary={sectionsStrings.editTeachers} />
                            </MenuItem>
                          )}
                          {canEditThis && (
                            <MenuItem onClick={() => void sections.editSectionSchedules(data.section!)}>
                              <ListItemText primary={sectionsStrings.editSchedules} />
                            </MenuItem>
                          )}
                          {canManageConflicts && (
                            <MenuItem onClick={() => void sections.manageSectionConflicts(data.section!)}>
                              <ListItemText primary={sectionsStrings.manageConflicts} />
                            </MenuItem>
                          )}
                          {canCopyIds && (
                            <MenuItem onClick={() => void copyIdToClipboard(data.section!)}>
                              <ListItemText primary={sectionsStrings.copyId} />
                            </MenuItem>
                          )}
                        </MoreActionMenu>
                      </AuthorizationRoleCondition>
                    </Row>
                  </Expanded>
                );
              }

              return <MTableAction {...p} />;
            }
          }}
          title={title ?? ''}
          columns={columns}
          actions={actions}
          data={sections.sections}
          options={{
            pageSize: 50,
            pageSizeOptions: DefaultTablePageSizes,
            rowStyle: { backgroundColor: '#fff', verticalAlign: 'top' },
            headerStyle: { fontSize: theme.typography.body2.fontSize },
            emptyRowsWhenPaging: false,
            paging: usePagination,
            actionsColumnIndex: -1,
            draggable: false,
            selection: selectionEnabled
          }}
          localization={{
            ...localizationService.localizedStrings.insights.materialTable,
            header: { actions: '' }
          }}
        />
      </Container>
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => runInAction(() => setShowToast(false))}
        message={sectionsStrings.copyIdNotification}
      />
    </Box>
  );
});

function getTitleColumn(
  configId: string,
  getSearchableFields: (section: SectionInfo) => string[],
  namedFilters: NamedFilter<SectionInfo>[],
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService
): TableColumn<SectionInfo> {
  const insightsStrings = localizationService.localizedStrings.insights;
  const sectionsStrings = insightsStrings.components.sections;

  return {
    title: sectionsStrings.title,
    defaultSort: 'asc',
    customSort: (a: SectionInfo, b: SectionInfo) => SectionUtils.compareTitles(a, b, localizationService.currentLocale),
    customFilterAndSearch: (filter: string, section: SectionInfo) =>
      section.section != null &&
      CustomFilterUtils.customFilterAndSearch(filter, section, getSearchableFields, namedFilters),
    render: (rowData: SectionInfo) => {
      const linkTo =
        rowData.section != null
          ? reactRouterRouteService.resolveLocation(RouteTemplates.sectionDetails, [
              { name: RouteParamNames.configId, value: configId },
              { name: RouteParamNames.sectionId, value: rowData.id }
            ])
          : undefined;

      return (
        <Container sx={{ my: 0.5, minHeight: 30 }}>
          <Row verticalContentAlignment="center">
            <SectionName
              title={
                rowData.section != null ? SectionUtils.formatTitle(rowData.section) : sectionsStrings.unknownSection
              }
              color={rowData.section?.color}
              linkTo={linkTo}
              subInformation1={rowData.section?.importId}
            />
          </Row>
        </Container>
      );
    }
  };
}

function getTeachersColumn(
  configId: string,
  localizationService: LocalizationService,
  isHidden: boolean
): TableColumn<SectionInfo> {
  const sectionsStrings = localizationService.localizedStrings.insights.components.sections;

  return {
    title: sectionsStrings.teachers,
    width: '12.5%',
    customSort: (a: SectionInfo, b: SectionInfo) =>
      SectionUtils.compareTeachers(a, b, localizationService.currentLocale),
    render: (rowData: SectionInfo) => <SectionListTeachersCell configId={configId} sectionInfo={rowData} />,
    hidden: isHidden
  };
}

type ConditionalColumnRenderer = (
  localizationService: LocalizationService,
  isHidden: boolean
) => TableColumn<SectionInfo>;

const getGradeColumn: ConditionalColumnRenderer = (localizationService, isHidden) => {
  const insightsStrings = localizationService.localizedStrings.insights;
  const sectionsStrings = insightsStrings.components.sections;

  return {
    title: sectionsStrings.grade,
    width: '12.5%',
    customSort: (a: SectionInfo, b: SectionInfo) => SectionUtils.compareGrades(a, b, localizationService.currentLocale),
    render: (rowData: SectionInfo) => {
      return (
        <Container sx={{ my: 0.5, minHeight: 30 }}>
          <Row verticalContentAlignment="center">
            <Typography variant="body1">{rowData.section?.gradeLevel}</Typography>
          </Row>
        </Container>
      );
    },
    hidden: isHidden
  };
};

const getGroupColumn: ConditionalColumnRenderer = (localizationService, isHidden) => {
  const insightsStrings = localizationService.localizedStrings.insights;
  const sectionsStrings = insightsStrings.components.sections;

  return {
    title: sectionsStrings.group,
    width: '12.5%',
    customSort: (a: SectionInfo, b: SectionInfo) => SectionUtils.compareGroups(a, b, localizationService.currentLocale),
    render: (rowData: SectionInfo) => {
      return (
        <Container sx={{ my: 0.5, minHeight: 30 }}>
          <Row verticalContentAlignment="center">
            <Typography variant="body1">{rowData.section?.sectionNumber}</Typography>
          </Row>
        </Container>
      );
    },
    hidden: isHidden
  };
};

const getRoomColumn: ConditionalColumnRenderer = (localizationService, isHidden) => {
  const insightsStrings = localizationService.localizedStrings.insights;
  const sectionsStrings = insightsStrings.components.sections;

  return {
    title: sectionsStrings.room,
    width: '12.5%',
    customSort: (a: SectionInfo, b: SectionInfo) =>
      SectionUtils.compareDefaultRooms(a, b, localizationService.currentLocale),
    render: (rowData: SectionInfo) => {
      return (
        <Container sx={{ my: 0.5, minHeight: 30 }}>
          <Row verticalContentAlignment="center">
            <Typography variant="body1">{rowData.section?.defaultRoomName}</Typography>
          </Row>
        </Container>
      );
    },
    hidden: isHidden
  };
};

const getStudentCountColumn: ConditionalColumnRenderer = (localizationService, isHidden) => {
  const componentsStrings = localizationService.localizedStrings.insights.components;

  return {
    title: componentsStrings.sections.studentCount,
    type: 'numeric',
    width: '12.5%',
    customSort: (a: SectionInfo, b: SectionInfo) =>
      (a.students != null ? a.students.length : 0) - (b.students != null ? b.students.length : 0),
    render: (rowData: SectionInfo) => {
      const hasAutoEnrollment =
        rowData.section != null &&
        (rowData.section.autoEnrollRoles.length > 0 || rowData.section.autoEnrollTags.length > 0);

      return (
        <Container sx={{ my: 0.5, minHeight: 30 }}>
          <Row verticalContentAlignment="center" horizontalContentAlignment="right">
            {hasAutoEnrollment && (
              <Tooltip
                title={
                  <div style={{ whiteSpace: 'pre-line' }}>
                    {componentsStrings.getAutoEnrollmentRolesAndTags(
                      rowData.section.autoEnrollRoles,
                      rowData.section.autoEnrollTags
                    )}
                  </div>
                }
              >
                <EnrollmentIcon fontSize="small" style={{ marginRight: 5 }} />
              </Tooltip>
            )}
            <Typography variant="body1">{rowData.students != null ? rowData.students.length : 0}</Typography>
          </Row>
        </Container>
      );
    },
    hidden: isHidden
  };
};

const getSchedulesColumn: ConditionalColumnRenderer = (localizationService, isHidden) => {
  const sectionsStrings = localizationService.localizedStrings.insights.components.sections;

  return {
    title: sectionsStrings.schedules,
    sorting: false,
    width: '12.5%',
    render: (rowData: SectionInfo) => (
      <Container sx={{ my: 0.5, minHeight: 30 }}>
        <Row verticalContentAlignment="center">
          {rowData.section != null && <SectionSchedulesSummary schedules={rowData.section.schedules} />}
        </Row>
      </Container>
    ),
    hidden: isHidden
  };
};
