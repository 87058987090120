import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { computed, makeObservable } from 'mobx';
import { BaseModel } from '../../Model';
import { DashboardQuestion } from '../interfaces';
import { GrpcOnboardingAnswer } from './GrpcOnboardingAnswer';
import { GrpcOnboardingQuestion } from './GrpcOnboardingQuestion';

export class GrpcDashboardQuestion extends BaseModel<PB.DashboardQuestion> implements DashboardQuestion {
  constructor(pb: PB.DashboardQuestion) {
    super(pb);
    makeObservable(this);
  }

  @computed
  get question() {
    const pbQuestion = this._pb.question;
    return pbQuestion && new GrpcOnboardingQuestion(pbQuestion);
  }

  @computed
  get answer() {
    const pbAnswer = this._pb.answer;
    return pbAnswer && new GrpcOnboardingAnswer(pbAnswer);
  }
}
