import { ManageBacAccountSettingsViewModel } from '@insights/viewmodels';
import { SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';
import { ManageBacTermIds } from './ManageBacTermIds';

export interface ManageBacAccountSettingsProps {
  sx?: SxProps;
  className?: string;
  viewModel: ManageBacAccountSettingsViewModel;
}

export const ManageBacAccountSettings = observer((props: ManageBacAccountSettingsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Column sx={sx} className={className}>
      <Typography variant="subtitle1">{strings.manageBacTermIdsTitle}</Typography>
      <ManageBacTermIds sx={{ m: 0, mb: 1 }} viewModel={viewModel} />
      <Typography variant="caption">{strings.manageBacTermIdsNotice}</Typography>

      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
