import { CourseOccurrenceConfiguration_Customization } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { BaseModel, SerializableModel } from '../Model';

export interface CourseOccurrenceCustomizationModel
  extends SerializableModel<CourseOccurrenceConfiguration_Customization> {
  readonly ordinal: number;
  readonly skipped: boolean;
  readonly title: string;
  readonly roomName: string;
}

export class CourseOccurrenceCustomization
  extends BaseModel<CourseOccurrenceConfiguration_Customization>
  implements CourseOccurrenceCustomizationModel
{
  constructor(pb: CourseOccurrenceConfiguration_Customization) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get ordinal(): number {
    return this._pb.ordinal;
  }

  get skipped(): boolean {
    return this._pb.skipped;
  }

  get title(): string {
    return this._pb.title;
  }

  get roomName(): string {
    return this._pb.roomName;
  }
}
