import { NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppCalendarAccountViewModel, CalendarAccountViewModel } from './CalendarAccountViewModel';

export interface CalendarAccountDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly account: IPromiseBasedObservable<CalendarAccountViewModel>;
}

export class AppCalendarAccountDialogViewModel implements CalendarAccountDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get account(): IPromiseBasedObservable<CalendarAccountViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<CalendarAccountViewModel> {
    if (this.externalAccountId === 'new') {
      return new AppCalendarAccountViewModel(
        this._localizationService,
        this._connectorsStore.calendars,
        this._onSuccess,
        this._onCancel,
        this.configId,
        '',
        'iCal Calendar'
      );
    }

    const account = await this._connectorsStore.getExternalAccount(this.configId, this.externalAccountId);

    return new AppCalendarAccountViewModel(
      this._localizationService,
      this._connectorsStore.calendars,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      account.name
    );
  }
}
