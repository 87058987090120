import { DailyPlannerStatusChart, ObservablePresenter, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  SxProps,
  ThemeProvider,
  Typography
} from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { MinMediumChartHeight, StudentsColor, StudentsDarkColor, StudentsLightColor } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface StudentDailyPlannerStatusDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  configId: string;
  studentId: string;
}

export const StudentDailyPlannerStatusDialog = observer((props: StudentDailyPlannerStatusDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx = [], style, studentId, configId, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.studentDailyPlannerStatusDialog;
  const studentStrings = localizationService.localizedStrings.insights.views.metrics.students;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createStudentDailyTaskCounters(configId, studentId)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        scroll="paper"
        onClose={() => onSuccess!()}
        className={className}
        style={style}
      >
        <DialogContent sx={{ height: MinMediumChartHeight }}>
          <ObservablePresenter
            sx={{ height: '100%', width: '100%' }}
            data={viewModel.data}
            loadingMessage={strings.loadingMessage}
            errorMessage={strings.loadingErrorMessage}
            render={(data) => {
              if (data.length === 0) {
                return (
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <Typography variant="h5">{studentStrings.dailyPlannerStatusEmptyMessage}</Typography>
                  </Box>
                );
              }

              return (
                <DailyPlannerStatusChart
                  caption={studentStrings.dailyPlannerStatus}
                  subcaption={studentStrings.dailyPlannerStatusSubcaption}
                  data={data}
                  completedTasksColor={StudentsColor}
                  pastDueTasksColor={StudentsLightColor}
                  cumulativeColor={StudentsDarkColor}
                />
              );
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => onSuccess!()}>
            {strings.closeButtonCaption}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
