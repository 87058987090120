import { ManageSectionConflictsViewModel, PeriodOccurrence } from '@insights/viewmodels';
import PreviousDayIcon from '@mui/icons-material/ArrowLeft';
import NextDayIcon from '@mui/icons-material/ArrowRight';
import SkipIcon from '@mui/icons-material/CancelPresentation';
import SkipAllDayIcon from '@mui/icons-material/HighlightOff';
import SkipAllConflictsIcon from '@mui/icons-material/JoinLeft';
import RestoreIcon from '@mui/icons-material/PresentToAll';
import NextConflictDayIcon from '@mui/icons-material/SkipNext';
import PreviousConflictDayIcon from '@mui/icons-material/SkipPrevious';
import { Box, IconButton, Menu, MenuItem, Stack, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import {
  SpecialDaySymbolAndColor,
  SpecialDaySymbolGrid,
  SpecialDaySymbolImage
} from '@shared/components/special_day_symbols';
import { SectionColors } from '@shared/models/Colors';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useLayoutEffect, useRef, useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column } from '../layout';

// Arbitrary value to make sure any horizontal scrollbar appears under last row.
const ScrollbarWidth = 20;

const PixelsPerMinute = 1.6;
const ColumnWidth = 220;

export interface ManageSectionConflictsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: ManageSectionConflictsViewModel;
}

export const ManageSectionConflicts = observer((props: ManageSectionConflictsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const theme = useTheme();
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;
  const locale = localizationService.currentLocale;
  const dateFormats = localizationService.localizedStrings.models.dateFormats;

  const firstHour = Math.floor(viewModel.minStartMinutes / 60);
  const lastHour = Math.floor(viewModel.maxEndMinutes / 60);
  const hours = Array(lastHour - firstHour + 1)
    .fill(0)
    .map((_, i) => firstHour + i);

  const startMinutes = firstHour * 60;
  const diffMinutes = (lastHour + 1) * 60 - startMinutes;
  const fullHeight = diffMinutes * PixelsPerMinute;
  const dayTitle =
    viewModel.activeDay.formattedString(dateFormats.dayOfWeek) +
    ', ' +
    viewModel.activeDay.formattedString(dateFormats.short);
  const visibleSpecialDays = viewModel.activeSchoolDay?.visibleSpecialDays ?? [];
  const specialDaysInfo = visibleSpecialDays.map<SpecialDaySymbolAndColor>((sd) => ({
    symbol: sd.symbol,
    color: SectionColors.get(sd.color) ?? SectionColors.get('medium-bmgray')
  }));

  const [skipMenuAnchorElement, setSkipMenuAnchorElement] = useState<HTMLElement | undefined>();
  const skipMenuOccurrence = useRef<PeriodOccurrence | undefined>();

  const handleKeyPress = (e: KeyboardEvent) => {
    const previousDay = viewModel.previousConflictDay ?? viewModel.previousDay;
    const nextDay = viewModel.nextConflictDay ?? viewModel.nextDay;

    if (e.code === 'ArrowLeft' && previousDay != null) {
      viewModel.activeDay = previousDay;
    } else if (e.code === 'ArrowRight' && nextDay != null) {
      viewModel.activeDay = nextDay;
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const openSkipMenu = (occurrence: PeriodOccurrence, currentTarget: HTMLElement) => {
    skipMenuOccurrence.current = occurrence;
    setSkipMenuAnchorElement(currentTarget);
  };

  const closeSkipMenu = () => {
    setSkipMenuAnchorElement(undefined);
    skipMenuOccurrence.current = undefined;
  };

  const skipOccurrence = async (alsoMoveContents: boolean) => {
    if (skipMenuOccurrence.current != null) {
      await viewModel.skipOccurrence(skipMenuOccurrence.current, alsoMoveContents);
    }

    closeSkipMenu();
  };

  return (
    <Box sx={sx} className={className} style={style}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflowY: 'auto'
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            top: 0,
            left: 0,
            position: 'sticky',
            flexShrink: 0,
            zIndex: 3,
            backgroundColor: theme.palette.background.paper
          }}
        >
          <IconButton
            disabled={viewModel.previousConflictDay == null || viewModel.isSkippingOccurrences}
            onClick={() => (viewModel.activeDay = viewModel.previousConflictDay!)}
          >
            <PreviousConflictDayIcon fontSize="small" />
          </IconButton>
          <IconButton
            disabled={viewModel.previousDay == null || viewModel.isSkippingOccurrences}
            onClick={() => (viewModel.activeDay = viewModel.previousDay!)}
          >
            <PreviousDayIcon />
          </IconButton>
          <Box
            sx={{
              width: 300,
              alignContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              position: 'relative'
            }}
          >
            <Tooltip
              title={
                visibleSpecialDays.length > 0 ? (
                  <Box>
                    {visibleSpecialDays.map((specialDay, index) => (
                      <Box
                        key={`special-day-${specialDay.symbol}-${index}`}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <SpecialDaySymbolImage
                          symbol={specialDay.symbol}
                          color={SectionColors.get(specialDay.color) ?? SectionColors.get('medium-bmgray')}
                          squareSize={16}
                          alwaysDisplaySymbol={true}
                        />
                        <Box
                          sx={{
                            marginLeft: 0.5
                          }}
                        >
                          <Typography variant="body2">{specialDay.title}</Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  ''
                )
              }
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mx: 1
                  }}
                >
                  {dayTitle}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    left: 8,
                    top: 6,
                    width: 16,
                    height: 16,
                    borderRadius: 8,
                    backgroundColor: grey[200]
                  }}
                >
                  <Typography variant="caption">{viewModel.activeSchoolDay?.displayCycleDayTitle}</Typography>
                </Box>

                <Box sx={{ position: 'absolute', right: 8, top: 8 }}>
                  <SpecialDaySymbolGrid
                    symbols={specialDaysInfo}
                    displayKind="linear"
                    squareSize={12}
                    alwaysDisplaySymbol={true}
                    displayNoneSymbol={false}
                  />
                </Box>
              </Box>
            </Tooltip>
          </Box>
          <IconButton
            disabled={viewModel.nextDay == null || viewModel.isSkippingOccurrences}
            onClick={() => (viewModel.activeDay = viewModel.nextDay!)}
          >
            <NextDayIcon />
          </IconButton>
          <IconButton
            disabled={viewModel.nextConflictDay == null || viewModel.isSkippingOccurrences}
            onClick={() => (viewModel.activeDay = viewModel.nextConflictDay!)}
          >
            <NextConflictDayIcon fontSize="small" />
          </IconButton>
          <Tooltip title={strings.skipConflictingPeriodsTooltip} placement="top">
            <IconButton
              disabled={!viewModel.canSkipConflictingOccurrences || viewModel.isSkippingOccurrences}
              onClick={() => void viewModel.skipConflictingOccurrences()}
              sx={{ ml: 4 }}
            >
              <SkipAllConflictsIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={strings.skipDayPeriodsTooltip} placement="top">
            <IconButton
              disabled={!viewModel.canSkipDayOccurrences || viewModel.isSkippingOccurrences}
              onClick={() => void viewModel.skipDayOccurrences()}
            >
              <SkipAllDayIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
        <Box
          sx={{
            height: fullHeight + ScrollbarWidth,
            display: 'flex',
            overflowX: 'auto'
          }}
        >
          <Box
            sx={{
              width: 60,
              height: fullHeight,
              top: 0,
              left: 0,
              position: 'sticky',
              flexShrink: 0,
              zIndex: 2,
              backgroundColor: theme.palette.background.paper
            }}
          >
            {hours.map((hour) => (
              <Box
                key={`hour-column-${hour}`}
                style={{
                  top: (hour - firstHour) * 60 * PixelsPerMinute,
                  height: 60 * PixelsPerMinute,
                  left: 0,
                  right: 0,
                  borderTopWidth: 1,
                  borderTopStyle: 'dotted'
                }}
                sx={{
                  position: 'absolute'
                }}
              >
                <Typography variant="caption">
                  {new Date(2022, 1, 1, hour, 0).toLocaleTimeString(locale, {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </Typography>
              </Box>
            ))}
          </Box>

          {viewModel.columns.map((column, index) => (
            <Box
              key={`period-column-${index}`}
              sx={{
                width: ColumnWidth,
                flexShrink: 0,
                height: fullHeight,
                position: 'relative',
                backgroundColor: column.isTarget ? blue[100] : grey[100]
              }}
            >
              {renderHourBars(hours, firstHour, PixelsPerMinute, index)}
              {column.occurrences.map((occurrence, index) => {
                const cellStyle: CSSProperties = {
                  top: (occurrence.startMinutes - startMinutes) * PixelsPerMinute,
                  height: occurrence.durationMinutes * PixelsPerMinute,
                  left: 0,
                  right: 0,
                  borderWidth: 3,
                  borderStyle: 'solid',
                  borderColor: SectionColors.get(occurrence.section.color),
                  color: occurrence.occurrence.skipped ? 'black' : 'white',
                  backgroundColor: occurrence.occurrence.skipped
                    ? grey[300]
                    : SectionColors.get(occurrence.section.color)
                };

                return (
                  <Box
                    key={`occurrence-${index}`}
                    style={cellStyle}
                    sx={{
                      position: 'absolute',
                      marginX: 1,
                      padding: 0.5
                    }}
                  >
                    <Column hideOverflow={true}>
                      <Box
                        sx={{
                          marginRight: 3,
                          overflow: 'hidden'
                        }}
                      >
                        <Typography variant="body2" noWrap style={{ fontWeight: 'bold' }}>
                          {occurrence.section.title}
                        </Typography>
                      </Box>
                      <Typography variant="caption">{occurrence.teacherName}</Typography>
                      <Typography variant="caption">
                        {occurrence.section.importId} - {occurrence.section.sectionNumber}
                      </Typography>
                    </Column>
                    <Typography sx={{ position: 'absolute', bottom: 0, right: theme.spacing(0.5) }} variant="caption">
                      {occurrence.occurrence.displayTitle}
                    </Typography>
                    {occurrence.occurrence.skipped ? (
                      <IconButton
                        size="small"
                        sx={{ position: 'absolute', top: theme.spacing(0.5), right: theme.spacing(0.5) }}
                        disabled={viewModel.isSkippingOccurrences}
                        onClick={() => void viewModel.restoreOccurrence(occurrence)}
                      >
                        <RestoreIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        sx={{ position: 'absolute', top: theme.spacing(0.5), right: theme.spacing(0.5) }}
                        disabled={viewModel.isSkippingOccurrences}
                        onClick={(e) => openSkipMenu(occurrence, e.currentTarget)}
                      >
                        <SkipIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                );
              })}
            </Box>
          ))}
        </Box>
        <Menu anchorEl={skipMenuAnchorElement} open={Boolean(skipMenuAnchorElement)} onClose={() => closeSkipMenu()}>
          <MenuItem disabled>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {strings.skipMenuTitle}
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => void skipOccurrence(false)}>{strings.skipNoMoveMenu}</MenuItem>
          <MenuItem onClick={() => void skipOccurrence(true)}>{strings.skipAndMoveMenu}</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
});

function renderHourBars(hours: number[], startHour: number, ppm: number, columnIndex: number) {
  return (
    <>
      {hours.map((hour) => (
        <Box
          key={`hour-bar-${columnIndex}-${hour}`}
          style={{
            top: (hour - startHour) * 60 * ppm,
            left: 0,
            right: 0,
            borderTopWidth: 1,
            borderTopStyle: 'dotted'
          }}
          sx={{
            position: 'absolute'
          }}
        />
      ))}
    </>
  );
}
