import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_sky_pb';
import { BaseModel } from '../Model';
import { ContentIcon, ContentWorkloadLevel } from '../types';
import { contentIconFromProtobuf, contentWorkloadLevelFromProtobuf } from '../types/EnumConversion';
import { BlackbaudSkyContentRepresentationModel } from './interfaces';

export class GrpcBlackbaudSkyContentRepresentation
  extends BaseModel<BC.ContentRepresentation>
  implements BlackbaudSkyContentRepresentationModel
{
  constructor(pb: BC.ContentRepresentation) {
    super(pb);
  }

  get icon(): ContentIcon {
    return contentIconFromProtobuf(this._pb.icon);
  }

  get workloadLevel(): ContentWorkloadLevel {
    return contentWorkloadLevelFromProtobuf(this._pb.workloadLevel);
  }
}
