import { DataPoint as PBDataPoint } from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';
import { Memoize } from 'fast-typescript-memoize';

export class DataPoint {
  private _pb: PBDataPoint;

  /**
   * Use a `DataPointStruct` when creating a model without a protobuf object.
   */
  constructor(pb: PBDataPoint) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  @Memoize()
  get startOfPeriod(): Date {
    const startOfPeriod = this._pb.startOfPeriod;
    if (startOfPeriod == null) {
      throw new Error('Invalid data from backend: DataPoint has an undefined startOfPeriod.');
    }

    return startOfPeriod.toDate();
  }

  get value(): number {
    return this._pb.value;
  }
}
