import {
  ObservablePresenter,
  OnboardingStatusChip,
  OnboardingStatusSelector,
  OnboardingTypographySelector,
  OptionalRouterLink,
  PageHeaderBar,
  RouterLink
} from '@insights/components';
import { OnboardingDashboardStepViewModel } from '@insights/viewmodels';
import ArchivedIcon from '@mui/icons-material/Archive';
import ClearIcon from '@mui/icons-material/Clear';
import StudyoParticipantIcon from '@mui/icons-material/HeadsetMic';
import PreparingIcon from '@mui/icons-material/HourglassEmpty';
import BothParticipantsIcon from '@mui/icons-material/People';
import ClientParticipantIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import ActiveIcon from '@mui/icons-material/ThumbUp';
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { ConfigState, OnboardingParticipantKind } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { differenceInCalendarDays } from 'date-fns';
import { last } from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface OnboardingDashboardProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const OnboardingDashboard = observer((props: OnboardingDashboardProps) => {
  const { localizationService, reactRouterRouteService, viewModelFactory } = useInsightsServices();
  const { className, sx = [], style } = props;
  const strings = localizationService.localizedStrings.insights.views;

  const viewModel = useMemo(() => viewModelFactory.createOnboardingDashboard(), []);

  return (
    <Root sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar>
        <Box display="flex" flexDirection="row" alignItems="center">
          <OnboardingStatusSelector
            className={'statusSelector'}
            selectedProcessStatuses={viewModel.processStatuses}
            selectedStepStatuses={viewModel.stepStatuses}
            minimumDate={viewModel.minimumDate}
            setStatuses={viewModel.setStatuses.bind(viewModel)}
          />
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              viewModel.search();
            }}
          >
            <TextField
              className={'search'}
              placeholder={strings.search}
              value={viewModel.searchTerm}
              onChange={(e) => (viewModel.searchTerm = e.target.value)}
            />
            <IconButton size="small" onClick={() => viewModel.search()}>
              <SearchIcon />
            </IconButton>
            {viewModel.activeSearchTerm.length > 0 && (
              <IconButton size="small" onClick={() => viewModel.resetSearch()}>
                <ClearIcon />
              </IconButton>
            )}
          </form>
        </Box>
        <RouterLink
          underline="none"
          to={reactRouterRouteService.resolveLocation(RouteTemplates.onboardingDashboardComments)}
        >
          {strings.viewComments}
        </RouterLink>
      </PageHeaderBar>

      <Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingSchoolConfigMessage}
          errorMessage={strings.loadingSchoolConfigErrorMessage}
          render={(data) => (
            <Box>
              {viewModel.activeSearchTerm.length > 0 && (
                <Typography className={'searchResultsLabel'} variant="subtitle1">
                  {strings.searchResults + viewModel.activeSearchTerm}
                </Typography>
              )}
              <TableContainer component={Paper} className={'table'}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '20%' }}>{strings.school}</TableCell>
                      <TableCell style={{ width: '50%' }}>{strings.steps}</TableCell>
                      <TableCell style={{ width: '10%' }}>{strings.startDate}</TableCell>
                      <TableCell style={{ width: '20%' }}>{strings.status}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item) => (
                      <TableRow key={item.process.id} className={'tableRow'}>
                        <TableCell>
                          <RouterLink
                            underline="none"
                            to={reactRouterRouteService.resolveLocation(RouteTemplates.manageOnboardingProcess, [
                              {
                                name: RouteParamNames.configId,
                                value: item.process.configId
                              },
                              {
                                name: RouteParamNames.processName,
                                value: item.process.templateName
                              }
                            ])}
                          >
                            <Box display="flex" flexDirection="column" alignItems="left">
                              {item.schoolName}
                              <Typography variant="caption" className={'comment'}>
                                {last(item.comments)?.message}
                              </Typography>
                            </Box>
                          </RouterLink>
                        </TableCell>
                        <TableCell>
                          {item.steps
                            .filter((s) => s.stepSummary.status === 'in-progress')
                            .map((step) => (
                              <OptionalRouterLink
                                key={`step-list-${step.stepSummary.id}`}
                                underline="none"
                                disabled={!step.stepSummary.canNavigate}
                                to={reactRouterRouteService.resolveLocation(RouteTemplates.manageOnboardingStep, [
                                  {
                                    name: RouteParamNames.configId,
                                    value: step.stepSummary.configId
                                  },
                                  {
                                    name: RouteParamNames.processName,
                                    value: step.stepSummary.processName
                                  },
                                  {
                                    name: RouteParamNames.templateName,
                                    value: step.stepSummary.templateName
                                  }
                                ])}
                              >
                                <Box display="flex" flexDirection="column" alignItems="left">
                                  <Box display="flex" flexDirection="row" alignItems="center">
                                    {renderParticipantsIcon(step.stepSummary.participants, localizationService)}
                                    <OnboardingTypographySelector
                                      variant="body2"
                                      texts={step.stepSummary.title}
                                      variableResolver={item.variableResolver}
                                      sx={{ mr: 1 }}
                                    />
                                    {step.stepSummary.status !== 'completed' &&
                                      step.stepSummary.targetDate != null &&
                                      differenceInCalendarDays(step.stepSummary.targetDate, new Date()) < 0 && (
                                        <Tooltip title={strings.lateTooltip} placement="top">
                                          <Typography variant="body2">🕦</Typography>
                                        </Tooltip>
                                      )}
                                    {step.stepSummary.isBlocked && (
                                      <Tooltip title={strings.blockedTooltip} placement="top">
                                        <Typography variant="body2">🛑</Typography>
                                      </Tooltip>
                                    )}
                                    {step.stepSummary.isLocked && (
                                      <Tooltip title={strings.lockedTooltip} placement="top">
                                        <Typography variant="body2">🔒</Typography>
                                      </Tooltip>
                                    )}
                                    {step.stepSummary.status === 'completed' && (
                                      <Tooltip title={strings.completedTooltip} placement="top">
                                        <Typography variant="body2">✅</Typography>
                                      </Tooltip>
                                    )}
                                    {!step.stepSummary.isLocked && step.stepSummary.status === 'not-started' && (
                                      <Tooltip title={strings.notStartedTooltip} placement="top">
                                        <Typography variant="body2">🟡</Typography>
                                      </Tooltip>
                                    )}
                                  </Box>
                                  <Typography variant="caption" className="comment">
                                    {last(step.comments)?.message}
                                  </Typography>
                                </Box>
                              </OptionalRouterLink>
                            ))}
                        </TableCell>
                        <TableCell>{item.schoolStartDate}</TableCell>
                        <TableCell>
                          <Box display="flex" flexDirection="column">
                            <Box
                              className="statusChip"
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <OnboardingStatusChip
                                size="small"
                                disabled
                                status={item.process.status}
                                nextDate={item.process.nextTargetDate}
                                targetDate={item.process.finalTargetDate}
                              />
                              {renderConfigStateIcon(item.configState)}
                            </Box>
                            <LinearProgress
                              className="progress"
                              variant="determinate"
                              color="secondary"
                              value={computeProgressValue(item.steps)}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        />
      </Box>
    </Root>
  );
});

function computeProgressValue(steps: OnboardingDashboardStepViewModel[]) {
  const totalSteps = steps.filter((s) => !s.stepSummary.isLocked).length;
  const completedSteps = steps.filter((s) => !s.stepSummary.isLocked && s.stepSummary.status === 'completed').length;

  return totalSteps === 0 ? 0 : (completedSteps / totalSteps) * 100;
}

function renderParticipantsIcon(participant: OnboardingParticipantKind, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  switch (participant) {
    case 'studyo-only':
      return (
        <Tooltip title={strings.participantStudyoTooltip}>
          <StudyoParticipantIcon className="participantIcon" fontSize="small" color="disabled" />
        </Tooltip>
      );
    case 'client-only':
      return (
        <Tooltip title={strings.participantClientTooltip}>
          <ClientParticipantIcon className="participantIcon" fontSize="small" color="secondary" />
        </Tooltip>
      );
    case 'studyo-and-client':
      return (
        <Tooltip title={strings.participantStudyoAndClientTooltip}>
          <BothParticipantsIcon className="participantIcon" fontSize="small" color="secondary" />
        </Tooltip>
      );
  }
}

function renderConfigStateIcon(configState: ConfigState) {
  switch (configState) {
    case 'preparing':
      return <PreparingIcon fontSize="small" color="secondary" />;

    case 'active':
      return <ActiveIcon fontSize="small" color="secondary" />;

    case 'archived':
      return <ArchivedIcon fontSize="small" color="secondary" />;
  }
}

const Root = styled(Box)(({ theme }) => ({
  '.statusSelector': {
    marginRight: theme.spacing(1)
  },
  '.search': {
    marginLeft: theme.spacing(2)
  },
  '.searchResultsLabel': {
    marginLeft: theme.spacing(1)
  },
  '.table': {
    marginBottom: theme.spacing(6)
  },
  '.tableRow': {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    verticalAlign: 'top'
  },
  '.participantIcon': {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0.5)
  },
  '.comment': {
    color: theme.palette.primary.light,
    maxHeight: '1.6em'
  },
  '.statusChip': {
    marginTop: theme.spacing(1)
  },
  '.progress': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));
