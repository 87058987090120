import { PaginationViewModel } from '@insights/viewmodels';
import { Box, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';

export interface CurrentPageInfoProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  pagination: PaginationViewModel | undefined;
}

export const CurrentPageInfo = observer((props: CurrentPageInfoProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], pagination: currentPageProvider } = props;

  const dateFormats = localizationService.localizedStrings.models.dateFormats;
  const currentPage = currentPageProvider?.currentPage;

  return (
    <Box sx={sx} className={className} style={style}>
      <Typography noWrap component="div">
        {/* Start Day */}
        <Typography sx={{ color: (theme) => theme.palette.text.secondary }} variant="body2" display="inline" noWrap>
          {`${currentPage?.startDay.formattedString(dateFormats.dayOfWeek)} `}
        </Typography>
        <Typography variant="body1" display="inline" noWrap>
          {`${currentPage?.startDay.formattedString(dateFormats.shortUnabridged)}`}
        </Typography>

        <Typography
          sx={{ color: (theme) => theme.palette.text.secondary }}
          display="inline"
          variant="body1"
        >{` – `}</Typography>

        {/* End Day */}
        <Typography sx={{ color: (theme) => theme.palette.text.secondary }} variant="body2" display="inline" noWrap>
          {`${currentPage?.endDay.formattedString(dateFormats.dayOfWeek)} `}
        </Typography>
        <Typography variant="body1" display="inline" noWrap>
          {`${currentPage?.endDay.formattedString(dateFormats.shortUnabridged)}`}
        </Typography>
      </Typography>
    </Box>
  );
});
