import { AccountModel } from '@shared/models/config';
import { OnboardingStepSummary } from '@shared/models/onboarding/interfaces';
import { OnboardingParticipantKind } from '@shared/models/types';
import { OnboardingStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { compact } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface SelectedOnboardingOwnership {
  readonly clientId: string | undefined;
  readonly agentId: string | undefined;
  readonly followerIds: string[];
  readonly isProcessSelected: boolean;
  readonly selectedStepNames: string[];
}

export interface SelectOnboardingOwnershipViewModel {
  readonly accounts: AccountModel[];
  readonly selectedClient?: AccountModel;
  readonly selectedAgent?: AccountModel;
  readonly selectedFollowers: AccountModel[];

  readonly steps: OnboardingStepSummary[];
  selectedStepNames: string[];
  isProcessSelected: boolean;
  isEverythingSelected: boolean;

  readonly canSelectClient: boolean;
  readonly canSelectAgent: boolean;
  readonly canConfirm: boolean;

  selectClient(accountId: string | undefined): void;
  selectAgent(accountId: string | undefined): void;
  selectFollowers(accountIds: string[]): void;
}

export interface SelectOnboardingOwnershipDialogViewModel {
  readonly data: IPromiseBasedObservable<SelectOnboardingOwnershipViewModel>;
  readonly canConfirm: boolean;

  confirm(): void;
  cancel(): void;
}

class AppSelectOnboardingOwnershipViewModel implements SelectOnboardingOwnershipViewModel {
  @observable private _selectedClient: AccountModel | undefined;
  @observable private _selectedAgent: AccountModel | undefined;
  @observable private _selectedFollowers: AccountModel[] = [];
  @observable private _isProcessSelected = true;
  @observable private _selectedStepNames: string[] = [];

  constructor(
    private readonly _accountsById: Record<string, AccountModel>,
    readonly accounts: AccountModel[],
    selectedClientId: string | undefined,
    selectedAgentId: string | undefined,
    selectedFollowerIds: string[],
    private readonly _participantKind: OnboardingParticipantKind,
    readonly steps: OnboardingStepSummary[]
  ) {
    makeObservable(this);
    this.selectClient(selectedClientId);
    this.selectAgent(selectedAgentId);
    this.selectFollowers(selectedFollowerIds);
  }

  @computed
  get selectedClient() {
    return this._selectedClient;
  }

  @computed
  get selectedAgent() {
    return this._selectedAgent;
  }

  @computed
  get selectedFollowers(): AccountModel[] {
    return this._selectedFollowers;
  }

  @computed
  get isProcessSelected() {
    return this._isProcessSelected;
  }

  set isProcessSelected(value: boolean) {
    this._isProcessSelected = value;
  }

  @computed
  get selectedStepNames() {
    return this._selectedStepNames;
  }

  set selectedStepNames(value: string[]) {
    this._selectedStepNames = value;
  }

  @computed
  get isEverythingSelected() {
    return this._isProcessSelected && this.steps.length === this._selectedStepNames.length;
  }

  set isEverythingSelected(value: boolean) {
    if (value) {
      this._isProcessSelected = true;
      this._selectedStepNames = this.steps.map((s) => s.templateName);
    } else {
      this._isProcessSelected = false;
      this._selectedStepNames = [];
    }
  }

  get canSelectClient() {
    return this._participantKind !== 'studyo-only';
  }

  get canSelectAgent() {
    return this._participantKind !== 'client-only';
  }

  @computed
  get canConfirm() {
    return this._isProcessSelected || this._selectedStepNames.length > 0;
  }

  @action
  selectClient(accountId: string | undefined) {
    if (accountId != null) {
      this._selectedClient = this._accountsById[accountId];
    } else {
      this._selectedClient = undefined;
    }
  }

  @action
  selectAgent(accountId: string | undefined) {
    if (accountId != null) {
      this._selectedAgent = this._accountsById[accountId];
    } else {
      this._selectedAgent = undefined;
    }
  }

  @action
  selectFollowers(accountIds: string[]) {
    this._selectedFollowers = compact(accountIds.map((id) => this._accountsById[id]));
  }
}

export class AppSelectOnboardingOwnershipDialogViewModel implements SelectOnboardingOwnershipDialogViewModel {
  @observable private _innerViewModel?: SelectOnboardingOwnershipViewModel;

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _onboardingStore: OnboardingStore,
    private readonly _configId: string,
    private readonly _processName: string,
    private readonly _selectedClientId: string | undefined,
    private readonly _selectedAgentId: string | undefined,
    private readonly _selectedFollowerIds: string[],
    private readonly _participantKind: OnboardingParticipantKind,
    private readonly _canApplyToSteps: boolean,
    private readonly _onSuccess: (selection: SelectedOnboardingOwnership) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<SelectOnboardingOwnershipViewModel> {
    return fromPromise(this.loadData());
  }

  @computed
  get canConfirm() {
    return this._innerViewModel?.canConfirm ?? false;
  }

  private async loadData(): Promise<SelectOnboardingOwnershipViewModel> {
    const [teachersAndStaff, teachersAndStaffById] = await Promise.all([
      this._schoolYearConfigurationStore.getTeachersAndStaff(this._configId, false),
      this._schoolYearConfigurationStore.getTeachersAndStaffById(this._configId, false)
    ]);

    let steps: OnboardingStepSummary[] = [];

    if (this._canApplyToSteps) {
      const process = await this._onboardingStore.getProcess(this._processName, this._configId);
      steps = process.steps;
    }
    const inner = new AppSelectOnboardingOwnershipViewModel(
      teachersAndStaffById,
      teachersAndStaff,
      this._selectedClientId,
      this._selectedAgentId,
      this._selectedFollowerIds,
      this._participantKind,
      steps
    );

    return runInAction(() => (this._innerViewModel = inner));
  }

  confirm() {
    this._onSuccess({
      clientId: this._innerViewModel?.selectedClient?.id,
      agentId: this._innerViewModel?.selectedAgent?.id,
      followerIds: this._innerViewModel?.selectedFollowers.map((a) => a.id) ?? [],
      isProcessSelected: this._innerViewModel?.isProcessSelected ?? false,
      selectedStepNames: this._innerViewModel?.selectedStepNames ?? []
    });
  }

  cancel() {
    this._onCancel();
  }
}
