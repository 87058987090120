import { AuthorizationRoleCondition } from '@insights/components';
import { UserMenuViewModel } from '@insights/viewmodels';
import ArchivedIcon from '@mui/icons-material/Archive';
import PendingIcon from '@mui/icons-material/HourglassEmpty';
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
  Switch,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material';
import { EmployeeAuthorizationRoles } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface UserMenuProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: UserMenuViewModel;
}

export const UserMenu = observer((props: UserMenuProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views;
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleDemoMode = () => {
    viewModel.toggleDemoMode();
    setIsOpen(false);
  };

  const navigateToUserSchoolConfigurations = () => {
    void viewModel.navigateToUserSchoolConfigurations();
    setIsOpen(false);
  };

  const navigateToManageSchool = () => {
    if (viewModel.currentConfigId != null) {
      void viewModel.navigateToManageSchool(viewModel.currentConfigId, navigate);
    }

    setIsOpen(false);
  };

  const navigateToSchoolConfigurations = () => {
    void viewModel.navigateToSchoolConfigurations(navigate);

    setIsOpen(false);
  };

  const navigateToOnboardingDashboard = () => {
    void viewModel.navigateToOnboardingDashboard(navigate);

    setIsOpen(false);
  };

  const navigateToUsers = () => {
    void viewModel.navigateToUsers(navigate);

    setIsOpen(false);
  };

  const navigateToSettings = () => {
    void viewModel.navigateToSettings(navigate);

    setIsOpen(false);
  };

  const handleSwitchLanguage = () => {
    void viewModel.switchLanguage();
    setIsOpen(false);
  };

  const handleLogout = async () => {
    await viewModel.logout();
  };

  const showSupport = () => {
    setIsOpen(false);
    if (window.Intercom != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Intercom('show');
    }
  };

  return (
    <Root sx={sx} className={className} style={style} display="flex" flexDirection="row" alignItems="center">
      {viewModel.currentConfigState !== 'active' && (
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <Tooltip sx={{ ml: 1 }} title={strings.managedGeneral.getConfigStateLabel(viewModel.currentConfigState)}>
            <IconButton onClick={navigateToManageSchool}>
              {viewModel.currentConfigState === 'archived' ? (
                <ArchivedIcon className={'stateIcon'} />
              ) : (
                <PendingIcon className={'stateIcon'} />
              )}
            </IconButton>
          </Tooltip>
        </AuthorizationRoleCondition>
      )}

      <Button ref={anchorEl} onClick={handleToggle}>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Typography variant="body2" className={'userName'} gutterBottom={false} noWrap={true}>
            {viewModel.userInfo.username}
          </Typography>
          <Typography variant="body2" className={'schoolInformation'} gutterBottom={false} noWrap={true}>
            {viewModel.currentConfigTitle}
          </Typography>
        </Box>
        <Avatar sx={{ ml: 1 }} className={'avatar'}>
          {viewModel.userInfo.initials}
        </Avatar>
      </Button>

      <RaisedPopper open={isOpen} anchorEl={anchorEl.current} transition={true} placement="bottom-end">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'top right' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {viewModel.userInfo.hasSchoolConfigurations && (
                    <MenuItem onClick={navigateToUserSchoolConfigurations}>
                      <ListItemText primary={strings.selectSchoolConfig} />
                    </MenuItem>
                  )}
                  {viewModel.currentConfigId && (
                    <AuthorizationRoleCondition allowedRoles={EmployeeAuthorizationRoles}>
                      <MenuItem onClick={navigateToManageSchool}>
                        <ListItemText primary={strings.manageThisSchool} />
                      </MenuItem>
                    </AuthorizationRoleCondition>
                  )}
                  <MenuItem onClick={handleSwitchLanguage}>
                    <>
                      {/* no need for localization */}
                      {viewModel.currentLocale === 'en' && <ListItemText primary="Basculer en français" />}
                      {viewModel.currentLocale === 'fr' && <ListItemText primary="Switch to English" />}
                    </>
                  </MenuItem>
                  <MenuItem onClick={showSupport}>
                    <ListItemText primary={strings.getSupport} />
                  </MenuItem>
                  <Divider />
                  <AuthorizationRoleCondition allowedRoles={['super-admin', 'studyo-staff']}>
                    <MenuItem onClick={navigateToOnboardingDashboard}>
                      <ListItemText primary={strings.onboardingDashboard} />
                    </MenuItem>
                    <Divider />
                  </AuthorizationRoleCondition>
                  {/* Studyo Staff users can toggle the demo mode, but only show that priviledge
                      when currently viewing a school. */}
                  {viewModel.currentConfigId ? (
                    <AuthorizationRoleCondition allowedRoles={['super-admin', 'studyo-staff']}>
                      <MenuItem onClick={toggleDemoMode}>
                        <Box flex={1} display="flex" flexDirection="row" alignItems="center">
                          <ListItemText primary={strings.demoMode} />
                          <Box flex={1} />
                          <Switch checked={viewModel.demoMode} />
                        </Box>
                      </MenuItem>
                    </AuthorizationRoleCondition>
                  ) : (
                    <AuthorizationRoleCondition allowedRoles={['super-admin']} allowRootAdmins>
                      <MenuItem onClick={toggleDemoMode}>
                        <Box flex={1} display="flex" flexDirection="row" alignItems="center">
                          <ListItemText primary={strings.demoMode} />
                          <Box flex={1} />
                          <Switch checked={viewModel.demoMode} />
                        </Box>
                      </MenuItem>
                    </AuthorizationRoleCondition>
                  )}
                  <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                    <MenuItem onClick={navigateToSchoolConfigurations}>
                      <ListItemText primary={strings.schoolConfigs} />
                    </MenuItem>
                    <MenuItem onClick={navigateToUsers}>
                      <ListItemText primary={strings.users} />
                    </MenuItem>
                    <MenuItem onClick={navigateToSettings}>
                      <ListItemText primary={strings.settingsMenu} />
                    </MenuItem>
                    <Divider />
                  </AuthorizationRoleCondition>
                  <MenuItem onClick={() => void handleLogout()}>
                    <ListItemText primary={strings.logout} />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </RaisedPopper>
    </Root>
  );
});

const Root = styled(Box)(({ theme }) => ({
  '.userName': {
    color: theme.palette.primary.contrastText,
    fontWeight: 500
  },
  '.schoolInformation': {
    color: theme.palette.primary.contrastText
  },
  '.avatar': {
    backgroundColor: theme.palette.secondary.main
  },
  '.stateIcon': {
    color: theme.palette.primary.contrastText
  },
  '.menuSubHeader': {
    outline: 'none'
  },
  '.divider': {
    outline: 'none'
  }
}));

const RaisedPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1
}));
