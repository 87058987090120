import { OnboardingCommentViewModel } from '@insights/viewmodels';
import AttachIcon from '@mui/icons-material/Attachment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Snackbar,
  styled,
  SxProps,
  TextField,
  Typography
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { AccountUtils } from '@shared/components/utils';
import { format } from 'date-fns';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { OnboardingTypography } from './OnboardingTypography';

export interface OnboardingCommentViewProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: OnboardingCommentViewModel;
}

export const OnboardingCommentView = observer((props: OnboardingCommentViewProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const dateFormat = localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime;

  const [showToast, setShowToast] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileReader = useRef(new FileReader());
  const localFilename = useRef<string | undefined>(undefined);

  useEffect(() => {
    fileReader.current.onload = () => {
      void viewModel
        .uploadFile(fileReader.current.result as string, localFilename.current)
        .then(() => setShowToast(true));
    };
  }, [fileReader.current]);

  const openFileInput = () => {
    requestAnimationFrame(() => {
      if (fileInputRef.current != null) {
        fileInputRef.current.click();
      }
    });
  };

  const onUploadFile = (files: FileList | null) => {
    if (files != null) {
      const file = files[0];

      if (file != null) {
        readFile(file);
      }
    }
  };

  const readFile = (file: File) => {
    localFilename.current = file.name;
    fileReader.current.readAsDataURL(file);
  };

  const backgroundColor = viewModel.isNew ? undefined : viewModel.isPrivate ? '#fdd' : grey[200];

  return (
    <Box sx={{ backgroundColor, ...sx }} className={className} style={style} p={1} mb={1} maxWidth={800}>
      <input
        type="file"
        id="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => onUploadFile(e.target.files)}
      />

      {viewModel.isNew ? (
        <Box display="flex" flexDirection="row" alignItems="center" paddingBottom={1}>
          <Box flexGrow={1}>
            <Typography variant="body2" fontWeight={500}>
              {strings.addCommentTitle}
            </Typography>
          </Box>
          <AuthorizationRoleCondition allowedRoles={['super-admin', 'studyo-staff']}>
            <IconButton size="small" onClick={openFileInput}>
              <AttachIcon />
            </IconButton>
          </AuthorizationRoleCondition>
        </Box>
      ) : (
        <Box display="flex" flexDirection="row" alignItems="center" paddingBottom={1}>
          <Box flexGrow={1}>
            <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.secondary }}>
              {AccountUtils.getDisplayLastFirstName(viewModel.from, strings.fromAdmin)}
            </Typography>
          </Box>
          <Typography mr={1} variant="caption" sx={{ color: (theme) => theme.palette.text.secondary }}>
            {format(viewModel.date!, dateFormat)}
          </Typography>
          {viewModel.canEdit &&
            (viewModel.isMine ? (
              <>
                {/* To avoid button movement, the attachment button is the only one appearing
                      when editing. The edit button simply becomes disabled. */}
                {viewModel.isEditing && (
                  <AuthorizationRoleCondition allowedRoles={['super-admin', 'studyo-staff']}>
                    <IconButton size="small" onClick={openFileInput}>
                      <AttachIcon />
                    </IconButton>
                  </AuthorizationRoleCondition>
                )}
                <IconButton size="small" disabled={viewModel.isEditing} onClick={() => viewModel.edit()}>
                  <EditIcon />
                </IconButton>
                <IconButton size="small" onClick={() => void viewModel.delete()}>
                  <DeleteIcon />
                </IconButton>
              </>
            ) : (
              <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                <IconButton size="small" onClick={() => void viewModel.delete()}>
                  <DeleteIcon />
                </IconButton>
              </AuthorizationRoleCondition>
            ))}
        </Box>
      )}
      {viewModel.isEditing ? (
        <>
          <Paper>
            <TextField
              fullWidth
              multiline
              value={viewModel.editableComment.message}
              onChange={(e) => (viewModel.editableComment.message = e.target.value)}
            />
          </Paper>
          {viewModel.isStepBlocked === true && (
            <FormControlLabel
              label={
                <Box display="flex" flexDirection="row">
                  <Typography>{strings.isUnblockingLabelPrefix}</Typography>
                  &nbsp;
                  <Typography color={green[700]}>{strings.isUnblockingLabelSuffix}</Typography>
                </Box>
              }
              control={
                <Checkbox
                  checked={viewModel.editableComment.effect === 'unblocks-step'}
                  onChange={(e) => (viewModel.editableComment.effect = e.target.checked ? 'unblocks-step' : 'none')}
                />
              }
            />
          )}
          {viewModel.isStepBlocked === false && (
            <FormControlLabel
              label={
                <Box display="flex" flexDirection="row">
                  <Typography>{strings.isBlockingLabelPrefix}</Typography>
                  &nbsp;
                  <Typography sx={{ color: (theme) => theme.palette.error.main }}>
                    {strings.isBlockingLabelSuffix}
                  </Typography>
                </Box>
              }
              control={
                <Checkbox
                  checked={viewModel.editableComment.effect === 'blocks-step'}
                  onChange={(e) => (viewModel.editableComment.effect = e.target.checked ? 'blocks-step' : 'none')}
                />
              }
            />
          )}
          <Box py={1} display="flex" flexDirection="row" alignItems="top">
            <Box flexGrow={1}>
              {viewModel.isStudyoUser && (
                <FormControlLabel
                  label={<Typography variant="caption">{strings.isPrivateLabel}</Typography>}
                  control={
                    <Checkbox
                      checked={viewModel.editableComment.isPrivate}
                      onChange={(e) => (viewModel.editableComment.isPrivate = e.target.checked)}
                    />
                  }
                />
              )}
            </Box>
            <ActionButton
              size="small"
              variant="contained"
              disabled={!viewModel.canSave}
              onClick={() => void viewModel.save()}
            >
              {strings.addCommentLabel}
            </ActionButton>
            <ActionButton
              size="small"
              variant="contained"
              disabled={!viewModel.canCancel}
              onClick={() => viewModel.cancel()}
            >
              {strings.cancelCommentLabel}
            </ActionButton>
          </Box>
          {viewModel.isStudyoUser && viewModel.isEditing && (
            <Box sx={{ backgroundColor: '#ddf' }} mt={1}>
              <OnboardingTypography
                format={viewModel.editableComment.format}
                text={viewModel.editableComment.message}
              />
            </Box>
          )}
        </>
      ) : (
        <OnboardingTypography text={viewModel.parsedMessage} format={viewModel.format} />
      )}
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => runInAction(() => setShowToast(false))}
        message={strings.fileUrlInClipboardToast}
      />
    </Box>
  );
});

const ActionButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}));
