import { AlertService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface ExportableViewModel {
  readonly canExport: boolean;
  readonly isExporting: boolean;
  readonly exportTooltip: string;

  exportToCsv: () => Promise<void>;
}

export abstract class BaseExportableViewModel implements ExportableViewModel {
  @observable private _isExporting = false;

  protected constructor(
    protected readonly _localizationService: LocalizationService,
    protected readonly _alertService: AlertService
  ) {
    makeObservable(this);
  }

  abstract get canExport(): boolean;
  abstract get exportTooltip(): string;

  @computed
  get isExporting(): boolean {
    return this._isExporting;
  }

  protected abstract get filename(): string;

  protected abstract getExportedData(): Promise<Record<string, string>[]>;

  @action
  async exportToCsv(): Promise<void> {
    this._isExporting = true;
    const strings = this._localizationService.localizedStrings.insights.viewModels.metrics;

    try {
      const data = await this.getExportedData();

      if (data.length === 0) {
        await this._alertService.showMessage({
          title: strings.nothingToExportTitle,
          message: strings.nothingToExport
        });

        return;
      }

      const csvConfig = mkConfig({
        filename: this.filename,
        useKeysAsHeaders: true
      });

      download(csvConfig)(generateCsv(csvConfig)(data));
    } catch (error) {
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isExporting = false));
    }
  }
}
