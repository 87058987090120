import { AlertService } from '@insights/services';
import { SectionUtils } from '@insights/utils';
import { AccountUtils } from '@shared/components/utils';
import {
  CourseOccurrence,
  EditableCourseOccurrenceCustomization,
  MoveCourseOccurrencesParamsModel,
  SchoolDay,
  SchoolDayPeriod
} from '@shared/models/calendar';
import { AccountModel, SectionModel } from '@shared/models/config';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { CalendarStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface PeriodOccurrence {
  readonly period: SchoolDayPeriod;
  readonly occurrence: CourseOccurrence;
  readonly section: SectionModel;
  readonly teacherName: string;
  readonly startMinutes: number;
  readonly durationMinutes: number;
}

export interface PeriodColumn {
  readonly isTarget: boolean;
  readonly occurrences: PeriodOccurrence[];
}

export interface ManageSectionConflictsViewModel {
  activeDay: Day;
  readonly activeSchoolDay: SchoolDay | undefined;
  readonly targetSection: SectionModel;
  readonly columns: PeriodColumn[];
  readonly minStartMinutes: number;
  readonly maxEndMinutes: number;

  readonly previousDay?: Day;
  readonly previousConflictDay?: Day;
  readonly nextDay?: Day;
  readonly nextConflictDay?: Day;

  readonly canSkipConflictingOccurrences: boolean;
  readonly canSkipDayOccurrences: boolean;
  readonly isSkippingOccurrences: boolean;

  skipOccurrence(occurrence: PeriodOccurrence, alsoMoveContents: boolean): Promise<void>;
  skipConflictingOccurrences(): Promise<void>;
  skipDayOccurrences(): Promise<void>;
  restoreOccurrence(occurrence: PeriodOccurrence): Promise<void>;
}

export interface ManageSectionConflictsDialogViewModel {
  readonly data: IPromiseBasedObservable<ManageSectionConflictsViewModel>;

  close(): void;
}

export class AppManageSectionConflictsViewModel implements ManageSectionConflictsViewModel {
  @observable private _schoolDays: SchoolDay[];
  @observable private _currentDay: Day;
  @observable private _isSkippingOccurrences = false;

  constructor(
    private readonly _calendarStore: CalendarStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _sectionsById: Record<string, SectionModel>,
    private readonly _teachersById: Record<string, AccountModel>,
    public readonly targetSection: SectionModel,
    private readonly _sectionIds: string[],
    schoolDays: SchoolDay[],
    startingDay?: Day
  ) {
    makeObservable(this);
    this._schoolDays = schoolDays;
    this._currentDay = startingDay ?? this.getStartingDay();
  }

  @computed
  get activeDay(): Day {
    return this._currentDay;
  }

  set activeDay(value: Day) {
    this._currentDay = value;
  }

  @computed
  get activeSchoolDay(): SchoolDay | undefined {
    return this._schoolDays.find((sd) => sd.day.isSame(this._currentDay));
  }

  @computed
  get columns(): PeriodColumn[] {
    const schoolDay = this.activeSchoolDay;

    if (schoolDay == null) {
      return [];
    }

    const columns: PeriodColumn[] = [{ isTarget: true, occurrences: [] }];

    schoolDay.periods.forEach((period) => {
      period.courseOccurrences.forEach((occurrence) => {
        const section = this._sectionsById[occurrence.sectionId];

        if (section != null) {
          const isTarget = occurrence.sectionId === this.targetSection.id;
          const startMinutes = period.startTime.hour * 60 + period.startTime.minute;
          const periodOccurrence: PeriodOccurrence = {
            period,
            occurrence,
            section,
            teacherName: AccountUtils.getDisplayLastFirstName(this._teachersById[section.defaultTeacherId]),
            startMinutes,
            durationMinutes: period.endTime.hour * 60 + period.endTime.minute - startMinutes
          };

          let emptyColumn = columns.find(
            (column) =>
              column.isTarget === isTarget &&
              column.occurrences.find((o) => this.timesOverlap(o.period, period)) == null
          );

          if (emptyColumn == null) {
            emptyColumn = { isTarget, occurrences: [periodOccurrence] };
            columns.push(emptyColumn);
          } else {
            emptyColumn.occurrences.push(periodOccurrence);
          }
        }
      });
    });

    return columns;
  }

  @computed
  get minStartMinutes(): number {
    const exactMin =
      _.min(_.flatten(this.columns.map((column) => column.occurrences.map((occurrence) => occurrence.startMinutes)))) ??
      0;
    return Math.floor(exactMin / 60) * 60;
  }

  @computed
  get maxEndMinutes(): number {
    return (
      _.max(
        _.flatten(
          this.columns.map((column) =>
            column.occurrences.map((occurrence) => occurrence.startMinutes + occurrence.durationMinutes)
          )
        )
      ) ?? 0
    );
  }

  @computed
  get previousDay(): Day | undefined {
    let index = this._schoolDays.findIndex((sd) => sd.day.isSame(this._currentDay));

    while (--index >= 0) {
      const sd = this._schoolDays[index];

      if (sd.hasCourseOccurrence(this.targetSection.id)) {
        return sd.day;
      }
    }

    return undefined;
  }

  @computed
  get nextDay(): Day | undefined {
    let index = this._schoolDays.findIndex((sd) => sd.day.isSame(this._currentDay));

    while (++index < this._schoolDays.length) {
      const sd = this._schoolDays[index];

      if (sd.hasCourseOccurrence(this.targetSection.id)) {
        return sd.day;
      }
    }

    return undefined;
  }

  @computed
  get previousConflictDay(): Day | undefined {
    let index = this._schoolDays.findIndex((sd) => sd.day.isSame(this._currentDay));

    while (--index >= 0) {
      const sd = this._schoolDays[index];

      if (this.isConflictDay(sd)) {
        return sd.day;
      }
    }

    return undefined;
  }

  @computed
  get nextConflictDay(): Day | undefined {
    let index = this._schoolDays.findIndex((sd) => sd.day.isSame(this._currentDay));

    while (++index < this._schoolDays.length) {
      const sd = this._schoolDays[index];

      if (this.isConflictDay(sd)) {
        return sd.day;
      }
    }

    return undefined;
  }

  @computed
  get canSkipConflictingOccurrences() {
    return this.conflictingOccurrences.length > 0;
  }

  @computed
  get canSkipDayOccurrences() {
    return this.dayOccurrences.length > 0;
  }

  @computed
  get isSkippingOccurrences() {
    return this._isSkippingOccurrences;
  }

  @computed
  private get targetTimes() {
    return _.flatten(
      this.columns
        .filter((c) => c.isTarget)
        .map((c) => c.occurrences.map((o) => ({ start: o.startMinutes, end: o.startMinutes + o.durationMinutes })))
    );
  }

  @computed
  private get conflictingOccurrences() {
    return _.flatten(
      this.columns
        .filter((c) => !c.isTarget)
        .map((c) =>
          c.occurrences.filter(
            (o) =>
              !o.occurrence.skipped &&
              this.targetTimes.find((t) => o.startMinutes < t.end && o.startMinutes + o.durationMinutes > t.start) !=
                null
          )
        )
    );
  }

  @computed
  private get dayOccurrences() {
    return _.flatten(
      this.columns.filter((c) => !c.isTarget).map((c) => c.occurrences.filter((o) => !o.occurrence.skipped))
    );
  }

  @action
  async skipOccurrence(occurrence: PeriodOccurrence, alsoMoveContents: boolean): Promise<void> {
    this._isSkippingOccurrences = true;

    try {
      await this.innerSkipOccurrence(occurrence, alsoMoveContents);
      await this.reloadSchoolDays();
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.sections;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isSkippingOccurrences = false));
    }
  }

  async skipConflictingOccurrences(): Promise<void> {
    if (this.conflictingOccurrences.length > 0) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.sections;
      const result = await this._alertService.showConfirmation({
        title: strings.confirmSkipConflictingOccurrencesTitle,
        message: strings.confirmSkipConflictingOccurrencesMessage(
          this.conflictingOccurrences.length,
          SectionUtils.formatTitle(this.targetSection)
        )
      });

      if (result !== 'cancelled') {
        let hasSkipped = false;
        this._isSkippingOccurrences = true;

        try {
          // We skip one by one.
          for (const occurrence of this.conflictingOccurrences) {
            await this.innerSkipOccurrence(occurrence, false);
            hasSkipped = true;
          }

          await this.reloadSchoolDays();
        } catch (error) {
          const strings = this._localizationService.localizedStrings.insights.viewModels.sections;
          await this._alertService.showMessage({
            title: strings.unexpectedErrorTitle,
            message: strings.unexpectedErrorMessage + (error as Error).message
          });

          if (hasSkipped) {
            await this.reloadSchoolDays();
          }
        } finally {
          runInAction(() => (this._isSkippingOccurrences = false));
        }
      }
    }
  }

  async skipDayOccurrences(): Promise<void> {
    if (this.dayOccurrences.length > 0) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.sections;
      const result = await this._alertService.showConfirmation({
        title: strings.confirmSkipDayOccurrencesTitle,
        message: strings.confirmSkipDayOccurrencesMessage(
          this.dayOccurrences.length,
          SectionUtils.formatTitle(this.targetSection)
        )
      });

      if (result !== 'cancelled') {
        let hasSkipped = false;
        this._isSkippingOccurrences = true;

        try {
          // We skip one by one.
          for (const occurrence of this.dayOccurrences) {
            await this.innerSkipOccurrence(occurrence, false);
            hasSkipped = true;
          }

          await this.reloadSchoolDays();
        } catch (error) {
          const strings = this._localizationService.localizedStrings.insights.viewModels.sections;
          await this._alertService.showMessage({
            title: strings.unexpectedErrorTitle,
            message: strings.unexpectedErrorMessage + (error as Error).message
          });

          if (hasSkipped) {
            await this.reloadSchoolDays();
          }
        } finally {
          runInAction(() => (this._isSkippingOccurrences = false));
        }
      }
    }
  }

  async restoreOccurrence(occurrence: PeriodOccurrence): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.sections;

    // The only restore we support is moving back titles from the next occurrence,
    // but that occurrence must not be skipped. Otherwise, a teacher must perform
    // restoration from their teacher view.
    const occurrences = _.flatten(
      this._schoolDays.map((sd) =>
        _.flatten(sd.periods.map((p) => p.courseOccurrences.filter((co) => co.sectionId === occurrence.section.id)))
      )
    );

    let previousOccurrence: CourseOccurrence | undefined;

    // We do a reference comparison to find the current occurrence.
    const nextOccurrence = occurrences
      .map((o) => {
        try {
          return [previousOccurrence, o];
        } finally {
          previousOccurrence = o;
        }
      })
      .find((pair) => pair[0] === occurrence.occurrence)?.[1];

    try {
      if (nextOccurrence == null) {
        // We just need to edit that occurrence.
        const editableCustomization = EditableCourseOccurrenceCustomization.createNew(occurrence.occurrence);
        editableCustomization.skipped = false;

        await this._calendarStore.customizeCourseOccurrence(
          this._configId,
          occurrence.section.id,
          editableCustomization
        );
      } else if (!nextOccurrence.skipped) {
        const params: MoveCourseOccurrencesParamsModel = {
          configId: this._configId,
          sectionId: occurrence.section.id,
          moveDirection: 'left',
          moveNotes: false,
          moveTasks: false,
          moveTitles: true,
          startOrdinal: nextOccurrence.ordinal,
          untilDay: undefined
        };

        await this._calendarStore.moveCourseOccurrences(params);
      } else {
        await this._alertService.showMessage({
          title: strings.teacherRequiredTitle,
          message: strings.teacherRequiredMessage
        });

        return;
      }

      await this.reloadSchoolDays();
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.sections;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    }
  }

  private async innerSkipOccurrence(occurrence: PeriodOccurrence, alsoMoveContents: boolean): Promise<void> {
    const params: MoveCourseOccurrencesParamsModel = {
      configId: this._configId,
      sectionId: occurrence.section.id,
      moveDirection: 'right-skip',
      moveNotes: alsoMoveContents,
      moveTasks: alsoMoveContents,
      moveTitles: true,
      startOrdinal: occurrence.occurrence.ordinal,
      untilDay: undefined
    };

    await this._calendarStore.moveCourseOccurrences(params);
  }

  private async reloadSchoolDays(): Promise<void> {
    const schoolDays = await this._calendarStore.getSchoolDays(this._configId, this._sectionIds);
    runInAction(() => (this._schoolDays = schoolDays));
  }

  private getStartingDay(): Day {
    const day =
      this._schoolDays.find((sd) => this.isConflictDay(sd))?.day ??
      this._schoolDays.find((sd) => sd.hasCourseOccurrence(this.targetSection.id))?.day ??
      this._schoolDays.find((sd) => !sd.isPadding)?.day;

    if (day == null) {
      throw new Error('No days to display');
    }

    return day;
  }

  private isConflictDay(schoolDay: SchoolDay): boolean {
    return (
      schoolDay.periods.find(
        (p) =>
          p.courseOccurrences.find((co) => co.sectionId === this.targetSection.id) != null &&
          (p.courseOccurrences.length > 1 || p.conflictingPeriodIds.length > 0)
      ) != null
    );
  }

  private timesOverlap(p1: SchoolDayPeriod, p2: SchoolDayPeriod): boolean {
    const startEnd = p1.startTime.compare(p2.endTime);
    const endStart = p1.endTime.compare(p2.startTime);

    // -1 and 1 OR 1 and -1
    return startEnd != endStart && startEnd + endStart === 0;
  }
}

export class AppManageSectionConflictsDialogViewModel implements ManageSectionConflictsDialogViewModel {
  constructor(
    private readonly _schoolStore: SchoolYearConfigurationStore,
    private readonly _calendarStore: CalendarStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _targetSectionId: string,
    private readonly _startingDay: Day | undefined,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {}

  get data(): IPromiseBasedObservable<ManageSectionConflictsViewModel> {
    return fromPromise(this.loadData());
  }

  close() {
    this._onSuccess();
  }

  private async loadData(): Promise<ManageSectionConflictsViewModel> {
    const [students, sectionsById, teachersById] = await Promise.all([
      this._schoolStore.getStudentsForSectionId(this._configId, this._targetSectionId, false),
      this._schoolStore.getSectionsById(this._configId),
      this._schoolStore.getTeachersById(this._configId, false)
    ]);

    const targetSection = sectionsById[this._targetSectionId];

    if (targetSection == null) {
      throw new Error('Invalid section id');
    }

    const autoEnrolledSections = await Promise.all(
      students.map((student) => this._schoolStore.getAutoEnrolledSectionsForStudent(this._configId, student))
    );
    const sectionIds = _.uniq(
      _.flatten(
        students
          .map((student) => student.selectedSectionIds)
          .concat(autoEnrolledSections.map((sections) => sections.map((section) => section.id)))
      )
    );

    const schoolDays = await this._calendarStore.getSchoolDays(this._configId, sectionIds);

    return new AppManageSectionConflictsViewModel(
      this._calendarStore,
      this._alertService,
      this._localizationService,
      this._configId,
      sectionsById,
      teachersById,
      targetSection,
      sectionIds,
      schoolDays,
      this._startingDay
    );
  }
}
