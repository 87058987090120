import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Checkbox,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  ThemeProvider,
  Typography
} from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useState } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { InsightsButton } from './InsightsButton';

export interface ConfirmationDialogProps extends DialogResult<void> {
  className?: string;
  style?: CSSProperties;
  title?: string;
  message: string;
  isMessageHtml?: boolean;
  displayCheckbox?: boolean;
  okButtonCaption?: string;
  cancelButtonCaption?: string;
}

export const ConfirmationDialog = observer((props: ConfirmationDialogProps) => {
  const { localizationService } = useInsightsServices();
  const {
    className,
    style,
    onCancel,
    onSuccess,
    title,
    message,
    isMessageHtml = false,
    okButtonCaption,
    cancelButtonCaption,
    displayCheckbox = false
  } = props;
  const strings = localizationService.localizedStrings.insights.components.confirmationDialog;
  const [isChecked, setIsChecked] = useState(false);

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        open={true}
        onClose={() => onCancel!()}
        maxWidth="sm"
        fullWidth={true}
        className={className}
        style={style}
      >
        <DialogTitle>{title ?? strings.defaultTitle}</DialogTitle>

        <DialogContent>
          <Stack direction="row" alignItems="center" spacing={1}>
            {displayCheckbox && <Checkbox value={isChecked} onChange={() => setIsChecked(true)} />}

            {isMessageHtml ? (
              <Typography flex={1} component="span">
                <div dangerouslySetInnerHTML={{ __html: message }} />
              </Typography>
            ) : (
              <Typography flex={1}>{message}</Typography>
            )}
          </Stack>
        </DialogContent>

        <DialogActions>
          <InsightsButton onClick={() => onCancel!()}>
            {cancelButtonCaption ?? strings.defaultCancelButtonCaption}
          </InsightsButton>
          <InsightsButton isDefault onClick={() => onSuccess!()} isDisabled={displayCheckbox && !isChecked}>
            {okButtonCaption ?? strings.defaultOkButtonCaption}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
