export interface BrowserDetectorStrings {
  title: string;
  errorOnOurPartText: string;
  errorOnOurPartLetUsKnowLink: string;
  useOneOfTheFollowingBrowser: string;
}

export const EnglishBrowserDetectorStrings: BrowserDetectorStrings = {
  title: 'Sorry, looks like you are using a browser not supported by Studyo Insights.',
  errorOnOurPartText: 'If you think this is an error on our part, please ', // The space at the end is important
  errorOnOurPartLetUsKnowLink: 'let us know',
  useOneOfTheFollowingBrowser: 'Please use one of the following browsers to continue:'
};

export const FrenchBrowserDetectorStrings: BrowserDetectorStrings = {
  title: 'Désolé, vous semblez utiliser un navigateur non-supporté par Studyo Insights.',
  errorOnOurPartText: 'Si vous pensez que c’est une erreur de notre part, svp ', // The space at the end is important
  errorOnOurPartLetUsKnowLink: 'commiquez avec nous',
  useOneOfTheFollowingBrowser: 'Veuillez utiliser un des navigateurs suivants pour continuer:'
};
