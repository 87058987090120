import * as GC from '@buf/studyo_studyo.bufbuild_es/studyo/type_classroom_pb';
import { BaseModel } from '../Model';
import { contentIconFromProtobuf, contentWorkloadLevelFromProtobuf } from '../types/EnumConversion';
import { ClassroomContentRepresentationModel } from './interfaces';

export class GrpcClassroomContentRepresentation
  extends BaseModel<GC.ContentRepresentation>
  implements ClassroomContentRepresentationModel
{
  constructor(pb: GC.ContentRepresentation) {
    super(pb);
  }

  get icon() {
    return contentIconFromProtobuf(this._pb.icon);
  }

  get workloadLevel() {
    return contentWorkloadLevelFromProtobuf(this._pb.workloadLevel);
  }
}
