import { MoodleAccountViewModel } from '@insights/viewmodels';
import { SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';

export interface MoodleAccountProps {
  sx?: SxProps;
  className?: string;
  viewModel: MoodleAccountViewModel;
}

export const MoodleAccount = observer((props: MoodleAccountProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx = [], className } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Column sx={sx} className={className}>
      <TextField
        sx={{ m: 1 }}
        label={strings.accountName}
        value={viewModel.accountName}
        onChange={(event) => (viewModel.accountName = event.target.value)}
      />
      <TextField
        sx={{ m: 1 }}
        label={strings.serverUrl}
        value={viewModel.serverUrl}
        onChange={(event) => (viewModel.serverUrl = event.target.value)}
      />
      <TextField
        sx={{ m: 1 }}
        label={strings.accessToken}
        type="password"
        value={viewModel.accessToken}
        autoComplete="new-password"
        onChange={(event) => (viewModel.accessToken = event.target.value)}
      />
      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
