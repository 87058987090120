import { ManageSideNavigation, RequiresFeatureCondition } from '@insights/components';
import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface ManageLayoutProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const ManageLayout = observer((props: ManageLayoutProps) => {
  const { analyticsService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createSchoolConfiguration(configId), [configId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Manage' });
  });

  return (
    <Box sx={sx} display="flex" flexDirection="row" alignItems="stretch" className={className} style={style}>
      <ManageSideNavigation sx={{ flexShrink: 0 }} configId={viewModel.configId} demoMode={viewModel.demoMode} />

      <Box flexGrow={1} display="flex">
        <RequiresFeatureCondition
          sx={{ flex: 1 }}
          requiredFeature="admin-console"
          featureNotAvailableContent={{ padding: 2, fill: true }}
        >
          <Outlet />
        </RequiresFeatureCondition>
      </Box>
    </Box>
  );
});
