import { Autocomplete, Box, SxProps, TextField } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AccountModel } from '@shared/models/config';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface SelectOnboardingOwnershipProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  label: string;
  selectedAccount?: AccountModel;
  availableAccounts: AccountModel[];
  onSelection: (accountId?: string) => void;
}

export const SelectOnboardingOwnership = observer((props: SelectOnboardingOwnershipProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, label, selectedAccount, availableAccounts, onSelection } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <Box className={className} style={style}>
      <Autocomplete
        selectOnFocus
        options={availableAccounts}
        value={selectedAccount}
        onChange={(_, v: AccountModel | null) => onSelection(v?.id)}
        renderInput={(params) => (
          <TextField {...params} fullWidth label={label} slotProps={{ inputLabel: { shrink: true } }} />
        )}
        getOptionLabel={(account) => AccountUtils.getDisplayLastFirstName(account)}
        noOptionsText={strings.accountNotFoundLabel}
      />
    </Box>
  );
});
