import { TransformationColumn as PBTransformationColumn } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { BaseModel } from '../../Model';
import { TransformationColumn } from '../interfaces';

export class GrpcTransformationColumn extends BaseModel<PBTransformationColumn> implements TransformationColumn {
  constructor(pb: PBTransformationColumn) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get operation(): string {
    return this._pb.operation;
  }

  get parameters(): string[] {
    return this._pb.parameters;
  }

  get targetSchemaField(): string {
    return this._pb.targetSchemaField;
  }

  get isSubstitution(): boolean {
    return this._pb.isSubstitution;
  }

  get comment(): string {
    return this._pb.comment;
  }
}
