import { Box, CircularProgress, SxProps, Typography } from '@mui/material';
import { CSSProperties } from 'react';

export interface LoadingIndicatorProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  message?: string;
  size?: 'tiny' | 'small' | 'normal';
  color?: 'primary' | 'secondary' | 'inherit';
}

export function LoadingIndicator(props: LoadingIndicatorProps) {
  const { sx = [], className, style, message, size, color } = props;

  const isNormal = size == null || size === 'normal';
  const isTiny = size && size === 'tiny';

  const progressSize = isNormal ? 40 : isTiny ? 24 : 30;
  const messageVariant = isNormal ? 'h5' : 'h6';

  return (
    <Box
      sx={sx}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className={className}
      style={style}
    >
      <CircularProgress color={color ?? 'secondary'} size={progressSize} />

      {props.message != null && (
        <Box marginTop={2}>
          <Typography variant={messageVariant} textAlign="center" fontWeight="300">
            {message}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
