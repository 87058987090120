import { ThemeKind } from '@shared/resources/themes';
import { StudyoTheme } from './StudyoTheme';

interface StudyoThemeColors {
  readonly clear: string;

  readonly white: string;
  readonly gray1: string;
  readonly gray2: string;
  readonly gray3: string;
  readonly gray4: string;
  readonly gray5: string;
  readonly black: string;

  readonly blue1: string;
  readonly blue2: string;

  readonly yellow1: string;
  readonly yellow2: string;

  readonly green1: string;
  readonly green2: string;

  readonly red1: string;
  readonly red2: string;

  readonly orange1: string;
  readonly orange2: string;
}

interface StudyoSementicColors {
  readonly debugColor: string;

  readonly mainBackgroundColor: string;
  readonly mainForegroundColor: string;

  // Theme-based switch between mainBackgroundColor and contentBackgroundColor
  readonly alternateMainBackgroundColor: string;

  // Opposites of main colors
  readonly contrastBackgroundColor: string;
  readonly contrastForegroundColor: string;

  // Slightly different than main colors
  readonly contentBackgroundColor: string;
  readonly contentForegroundColor: string;

  // Theme-based switch between mainBackgroundColor and contentBackgroundColor
  readonly alternateContentBackgroundColor: string;

  // Opposites of content colors
  readonly alternateContrastBackgroundColor: string;
  readonly alternateContrastForegroundColor: string;

  // Dialog colors, which should either match the main or content colors.
  readonly dialogBackgroundColor: string;
  readonly dialogForegroundColor: string;

  // The dimmed color can be used as a placeholder text color or a standing out
  // background color. It's an "in the middle" color.
  readonly dimmedColor: string;

  readonly paperBackgroundColor: string;

  readonly separatorColor: string;

  readonly disabledBackgroundColor: string;
  readonly disabledForegroundColor: string;

  // These can be used either as background or foreground colors.
  // Use colors above as complementary.
  readonly accentColor: string;
  readonly secondaryColor: string;
  readonly selectedColor: string;
  readonly errorColor: string;
  readonly focusColor: string;
  readonly warningColor: string;
  readonly successColor: string;
}

export const AppStudyoLightThemeColors: StudyoThemeColors = {
  clear: 'rgba(0, 0, 0, 0)',

  white: '#FFF',
  gray1: '#EEE',
  gray2: '#DDD',
  gray3: '#ABABAB',
  gray4: '#848484',
  gray5: '#3C3C3C',
  black: '#000',

  blue1: '#3685FA',
  blue2: '#5664FF',

  yellow1: '#FEFFE6',
  yellow2: '#FFC81F',

  green1: '#6BA53F',
  green2: '#81D135',

  red1: '#E72930',
  red2: '#D0021B',

  orange1: '#F5A623',
  orange2: '#FF8000'
};

export const AppStudyoDarkThemeColors: StudyoThemeColors = {
  clear: 'rgba(0, 0, 0, 0)',

  white: '#FFF',
  gray1: '#DDD',
  gray2: '#ABABAB',
  gray3: '#848484',
  gray4: '#3C3C3C',
  gray5: '#111',
  black: '#000',

  blue1: '#3685FA',
  blue2: '#5664FF',

  yellow1: '#852',
  yellow2: '#202018',

  green1: '#6BA53F',
  green2: '#81D135',

  red1: '#E72930',
  red2: '#D0021B',

  orange1: '#F5A623',
  orange2: '#FF8000'
};

export const AppStudyoThemeDimensions = {
  baseThicknessValue: 8,
  fontSizes: {
    large: 24,
    medium: 20,
    normal: 16,
    small: 14,
    extraSmall: 12
  }
};

const AppStudyoLightSementicColors: StudyoSementicColors = {
  debugColor: '#0FF',

  mainBackgroundColor: AppStudyoLightThemeColors.white,
  mainForegroundColor: AppStudyoLightThemeColors.black,

  // Same as main on light theme
  alternateMainBackgroundColor: AppStudyoLightThemeColors.white,

  contrastBackgroundColor: AppStudyoLightThemeColors.black,
  contrastForegroundColor: AppStudyoLightThemeColors.white,

  contentBackgroundColor: AppStudyoLightThemeColors.gray1,
  contentForegroundColor: AppStudyoLightThemeColors.black,

  // Same as normal content on light theme
  alternateContentBackgroundColor: AppStudyoLightThemeColors.gray1,

  alternateContrastBackgroundColor: AppStudyoLightThemeColors.gray5,
  alternateContrastForegroundColor: AppStudyoLightThemeColors.gray1,

  // Same as main on light theme
  dialogBackgroundColor: 'transparent',
  dialogForegroundColor: AppStudyoLightThemeColors.black,

  dimmedColor: AppStudyoLightThemeColors.gray3,

  paperBackgroundColor: AppStudyoLightThemeColors.yellow1,

  separatorColor: AppStudyoLightThemeColors.gray2,

  disabledBackgroundColor: AppStudyoLightThemeColors.gray2,
  disabledForegroundColor: AppStudyoLightThemeColors.gray4,

  accentColor: AppStudyoLightThemeColors.blue1,
  secondaryColor: AppStudyoLightThemeColors.gray4,
  selectedColor: AppStudyoLightThemeColors.blue2,
  errorColor: AppStudyoLightThemeColors.red1,
  focusColor: AppStudyoLightThemeColors.orange1,
  warningColor: AppStudyoLightThemeColors.yellow2,
  successColor: AppStudyoLightThemeColors.green2
};

const AppStudyoDarkSementicColors: StudyoSementicColors = {
  debugColor: '#0FF',

  mainBackgroundColor: AppStudyoDarkThemeColors.gray5,
  mainForegroundColor: AppStudyoDarkThemeColors.white,

  // Same as content on dark theme
  alternateMainBackgroundColor: AppStudyoDarkThemeColors.black,

  contrastBackgroundColor: AppStudyoDarkThemeColors.white,
  // In dark, text is still white over contrasted backgrounds
  contrastForegroundColor: AppStudyoDarkThemeColors.white,

  contentBackgroundColor: AppStudyoDarkThemeColors.black,
  contentForegroundColor: AppStudyoDarkThemeColors.white,

  // Same as main on dark theme
  alternateContentBackgroundColor: AppStudyoDarkThemeColors.gray5,

  alternateContrastBackgroundColor: AppStudyoDarkThemeColors.gray1,
  alternateContrastForegroundColor: AppStudyoDarkThemeColors.gray5,

  // Same as content on dark theme
  dialogBackgroundColor: 'transparent',
  dialogForegroundColor: AppStudyoDarkThemeColors.white,

  dimmedColor: AppStudyoDarkThemeColors.gray3,

  paperBackgroundColor: AppStudyoDarkThemeColors.yellow2,

  separatorColor: AppStudyoDarkThemeColors.gray4,

  disabledBackgroundColor: AppStudyoDarkThemeColors.gray4,
  disabledForegroundColor: AppStudyoDarkThemeColors.gray1,

  accentColor: AppStudyoDarkThemeColors.blue2,
  secondaryColor: AppStudyoDarkThemeColors.gray2,
  selectedColor: AppStudyoDarkThemeColors.blue1,
  errorColor: AppStudyoDarkThemeColors.red2,
  focusColor: AppStudyoDarkThemeColors.orange2,
  warningColor: AppStudyoDarkThemeColors.yellow1,
  successColor: AppStudyoDarkThemeColors.green1
};

export const createAppStudyoTheme: (kind: ThemeKind) => StudyoTheme = (kind) => {
  const colors = kind === 'dark' ? AppStudyoDarkThemeColors : AppStudyoLightThemeColors;
  const sementicColors = kind === 'dark' ? AppStudyoDarkSementicColors : AppStudyoLightSementicColors;

  return {
    kind,
    // Note: These five colors are not used in the app. They currenty are set to values
    // also used as background colors. If we ever use them one day, might need to revise.
    mainAccentColor: sementicColors.accentColor,
    mainTextColor: sementicColors.mainForegroundColor,
    secondaryTextColor: sementicColors.secondaryColor,
    mainErrorTextColor: sementicColors.errorColor,
    mainWarningTextColor: sementicColors.warningColor,

    todaySymbolBackgroundColor: colors.red1,

    periods: {
      contentBoxBackgroundColor: sementicColors.mainBackgroundColor,
      freePeriodColor: sementicColors.disabledBackgroundColor,
      freePeriodTextColor: sementicColors.mainForegroundColor,
      freePeriodShadowColor: sementicColors.secondaryColor,
      skippedPeriodColor: sementicColors.dimmedColor,
      periodWithSectionTextColor: sementicColors.contrastForegroundColor
    },

    formButtons: {
      darkBackgroundColor: sementicColors.accentColor,
      darkTextColor: sementicColors.contrastForegroundColor,
      darkActivityIndicatorColor: sementicColors.contrastForegroundColor,

      lightBackgroundColor: sementicColors.contentBackgroundColor,
      lightTextColor: sementicColors.contentForegroundColor,
      lightActivityIndicatorColor: sementicColors.contentForegroundColor
    },

    formTextInput: {
      backgroundColor: sementicColors.mainBackgroundColor,
      borderColor: sementicColors.separatorColor
    },

    dialogs: {
      headerDefaultColor: sementicColors.dialogBackgroundColor,
      contentBackgroundColor: sementicColors.dialogBackgroundColor,

      buttons: {
        destructiveButtonTextColor: sementicColors.errorColor,
        destructiveButtonBackgroundColor: colors.clear,
        destructiveButtonActivityIndicatorColor: sementicColors.errorColor,
        destructiveButtonHoveredColor: sementicColors.contentBackgroundColor,

        normalButtonTextColor: sementicColors.accentColor,
        normalButtonBackgroundColor: colors.clear,
        normalButtonActivityIndicatorColor: sementicColors.accentColor,
        normalButtonHoveredColor: sementicColors.contentBackgroundColor,

        disabledButtonTextColor: sementicColors.disabledForegroundColor,
        disableButtonBackgroundColor: colors.clear,
        disabledButtonActivityIndicatorColor: sementicColors.disabledForegroundColor,
        disabledButtonHoveredColor: sementicColors.contentBackgroundColor
      }
    },

    baseThicknessValue: AppStudyoThemeDimensions.baseThicknessValue,
    fontSizes: AppStudyoThemeDimensions.fontSizes,

    navigation: {
      bottomTabBarBackgroundColor: sementicColors.mainBackgroundColor,
      separatorColor: sementicColors.separatorColor,
      header: {
        defaultButtonColor: sementicColors.accentColor,
        defaultBackgroundColor: sementicColors.contentBackgroundColor
      }
    },

    agenda: {
      rootBackgroundColor: sementicColors.mainBackgroundColor,

      header: {
        backgroundColor: sementicColors.mainBackgroundColor,
        todayCycleDayTextColor: sementicColors.contrastForegroundColor,
        cycleDayTextColor: sementicColors.alternateContrastForegroundColor,
        dateRowBackgroundColor: sementicColors.mainBackgroundColor,
        cycleDayTextTransparentBackgroundColor: colors.clear
      },

      daily: {
        backgroundColor: sementicColors.contentBackgroundColor,
        headerSeparatorColor: sementicColors.separatorColor
      },

      weekly: {
        backgroundColor: sementicColors.contentBackgroundColor
      },

      month: {
        headerBackgroundColor: sementicColors.mainBackgroundColor,
        separatorColor: sementicColors.separatorColor,
        dayOfWeekHeaderColor: sementicColors.mainBackgroundColor,
        monthBackgroundColor: sementicColors.contentBackgroundColor,
        dayBackgroundColor: sementicColors.mainBackgroundColor,
        cycleDayTitleWithFullSpecialDaySymbolsColor: sementicColors.mainForegroundColor,
        cycleDayTitleWithNonFullSpecialDaySymbolsColor: sementicColors.contrastForegroundColor,
        dayTextColor: sementicColors.contrastForegroundColor,
        todayTextColor: sementicColors.mainForegroundColor
      },

      yearly: {
        day: {
          cycleDayTextColor: sementicColors.secondaryColor,
          normalTextColor: sementicColors.mainForegroundColor,
          symbolsTextColor: colors.white,
          todaySymbolColor: colors.red1,
          todaySymbolTextColor: colors.white,
          weekendTextColor: sementicColors.disabledForegroundColor
        },

        month: {
          separatorColor: sementicColors.mainBackgroundColor,
          dayOfWeekTextColor: sementicColors.secondaryColor,
          weekendTextColor: sementicColors.dimmedColor
        },

        header: {
          backgroundColor: sementicColors.mainBackgroundColor,
          separatorColor: sementicColors.separatorColor
        },

        backgroundColor: sementicColors.contentBackgroundColor
      },

      timeline: {
        collectionViewDayViewBackgroundColor: sementicColors.alternateContentBackgroundColor,
        collectionViewSeparatorColor: sementicColors.separatorColor,
        contentInfoContainerBackgroundColor: sementicColors.alternateContentBackgroundColor,
        courseTextColor: sementicColors.contrastForegroundColor,
        headerBackgroundColor: sementicColors.mainBackgroundColor,
        iconContainerBackgroundColor: sementicColors.mainBackgroundColor,
        listBackgroundColor: sementicColors.alternateMainBackgroundColor,
        sectionHeaderTextColor: sementicColors.contrastForegroundColor,
        separatorColor: sementicColors.separatorColor,
        stateTextColor: sementicColors.contrastForegroundColor,
        stepListItemBorderColor: sementicColors.contentBackgroundColor,
        stepListItemNormalTitleTextColor: sementicColors.contentForegroundColor,
        taskIconPlannedColor: sementicColors.dimmedColor
      },

      calendarHeaderTodayButtonColor: sementicColors.secondaryColor,
      calendarHeaderNavigationButtonColor: sementicColors.secondaryColor,
      calendarHeaderActionButtonsBackgroundColor: sementicColors.contentBackgroundColor,
      calendarHeaderButtonColor: sementicColors.accentColor,
      currentTimeIndicatorTextColor: sementicColors.contrastForegroundColor,
      calendarHeaderConfigInformationBoxTextColor: sementicColors.secondaryColor,
      calendarHeaderConfigInformationViewingAsTextColor: colors.red1,
      calendarHeaderFilterButtonsBackgroundColor: sementicColors.contentBackgroundColor,
      dateSwitcherBackgroundColor: sementicColors.dialogBackgroundColor,
      dayAndWeekHeaderBackgroundColor: sementicColors.mainBackgroundColor,

      planner: {
        backgroundColor: sementicColors.contentBackgroundColor,
        separatorColor: sementicColors.separatorColor,
        dragOverIndicatorColor: sementicColors.disabledBackgroundColor,

        header: {
          backgroundColor: sementicColors.mainBackgroundColor
        },

        grid: {
          backgroundColor: sementicColors.contentBackgroundColor,
          dayHeaderBackgroundColor: sementicColors.mainBackgroundColor,
          cellWithPeriodBackgroundColor: sementicColors.mainBackgroundColor,
          cellWithoutPeriodBackgroundColor: sementicColors.disabledBackgroundColor,
          gridSeparatorColor: sementicColors.separatorColor,
          gridCellSeparatorColor: sementicColors.dimmedColor,
          topLeftEmptyViewColor: sementicColors.mainBackgroundColor,
          cycleDayStartColor: colors.orange2,
          weekendLineColor: sementicColors.disabledForegroundColor
        }
      }
    },

    contents: {
      allDayTasksBoxBackgroundColor: sementicColors.mainBackgroundColor,
      displayableContentBoxBackgroundColor: sementicColors.mainBackgroundColor,
      displayableDragPreviewBackgroundColor: sementicColors.mainBackgroundColor,
      displayableDragPreviewBorderColor: sementicColors.separatorColor,
      displayableContentPlusBackgroundColor: sementicColors.separatorColor,
      displayableContentMoreBackgroundColor: sementicColors.separatorColor,
      displayableContentMoreWithOverflowBackgroundColor: sementicColors.dimmedColor,
      displayableContentPlusColor: sementicColors.contentForegroundColor,
      noteTextInputBackgroundColor: colors.clear,
      taskIconSelectionBackgroundColor: sementicColors.contentBackgroundColor,
      taskIconSelectionItemSelectedColor: sementicColors.selectedColor,
      taskSectionSelectionItemSelectedColor: sementicColors.selectedColor,
      notesBackgroundColor: sementicColors.paperBackgroundColor,

      attachments: {
        listItemBorderColor: sementicColors.contentBackgroundColor,
        boxBorderColor: sementicColors.secondaryColor,
        boxTextColor: sementicColors.secondaryColor,

        add: {
          backgroundColor: sementicColors.mainBackgroundColor,
          disabledBackgroundColor: sementicColors.disabledBackgroundColor,
          dragHoveredColor: sementicColors.contentBackgroundColor,
          buttonsHoveredColor: sementicColors.contentBackgroundColor,
          loadingIndicatorColor: sementicColors.accentColor
        }
      },

      icons: {
        assignmentColor: sementicColors.dimmedColor,
        completedColor: sementicColors.successColor,
        completedOverlayColor: sementicColors.successColor,
        importantColor: sementicColors.errorColor,
        normalBackgroundColor: sementicColors.mainBackgroundColor,
        normalColor: sementicColors.mainForegroundColor,
        notesBackgroundColor: sementicColors.paperBackgroundColor,
        personalColor: sementicColors.secondaryColor,
        sharedColor: sementicColors.selectedColor,
        unreadColor: sementicColors.selectedColor,
        workloadMajorBackgroundColor: sementicColors.errorColor,
        workloadMajorIconColor: sementicColors.contrastForegroundColor,
        workloadMediumIconColor: sementicColors.errorColor,
        publishErrorColor: sementicColors.errorColor
      },

      states: {
        active: sementicColors.separatorColor,
        late: sementicColors.errorColor,
        today: sementicColors.focusColor
      },

      steps: {
        iconsColor: sementicColors.accentColor,
        deleteButtonColor: sementicColors.errorColor,
        dateSelectionSeparator: sementicColors.separatorColor,
        schoolDayPickerBackgroundColor: sementicColors.dialogBackgroundColor
      },

      taskDueBox: {
        duePeriodBoxTagColor: sementicColors.secondaryColor,
        duePeriodBoxEmptyTagColor: sementicColors.secondaryColor,
        iconBoxTitleTextColor: sementicColors.disabledForegroundColor,
        normalTextColor: sementicColors.mainForegroundColor,
        periodTitleTextColor: sementicColors.disabledForegroundColor,
        selectedIconColor: sementicColors.selectedColor,
        selectedTextColor: sementicColors.selectedColor,
        dueLineColor: sementicColors.separatorColor
      },

      taskInfo: {
        assessmentPlanningBackgroundColor: sementicColors.disabledBackgroundColor,
        backgroundColor: sementicColors.dialogBackgroundColor,
        borderColor: sementicColors.contentBackgroundColor,
        completeButtonBackgroundColor: sementicColors.dialogBackgroundColor,
        completeButtonTintColor: sementicColors.successColor,
        completeButtonHoveredColor: sementicColors.contentBackgroundColor,
        isPrivateMessageBackgroundColor: sementicColors.disabledBackgroundColor,
        linkedIndicatorBackgroundColor: sementicColors.disabledBackgroundColor,
        noLoadMessageBackgroundColor: sementicColors.disabledBackgroundColor,
        unlinkButtonTextColor: sementicColors.accentColor,
        notesBackgroundColor: sementicColors.dialogBackgroundColor,
        bannerColor: sementicColors.disabledBackgroundColor
      },

      taskEdit: {
        backgroundColor: sementicColors.dialogBackgroundColor,
        copyStepButtonTextColor: sementicColors.selectedColor,
        iconColor: sementicColors.contentForegroundColor,
        occurrenceBoxDueInfoBackgroundColor: sementicColors.mainBackgroundColor,
        optionBarTextColor: sementicColors.contentForegroundColor
      },

      publish: {
        studentIcon: sementicColors.mainForegroundColor,
        contentInfoBoxBackgroundColor: sementicColors.mainBackgroundColor,
        studentSelectionBackgroundColor: sementicColors.mainBackgroundColor
      }
    },

    lists: {
      checkmarkColor: sementicColors.accentColor,
      disclosureColor: sementicColors.secondaryColor,
      infoButtonColor: sementicColors.accentColor,
      listBackgroundColor: sementicColors.dialogBackgroundColor,
      listItemBackgroundColor: sementicColors.dialogBackgroundColor,
      listItemHoveredBackgroundColor: sementicColors.contentBackgroundColor,
      sectionBackgroundColor: sementicColors.dialogBackgroundColor,
      sectionFooterBackgroundColor: sementicColors.dialogBackgroundColor,
      sectionHeaderBackgroundColor: sementicColors.dialogBackgroundColor,
      separatorColor: sementicColors.separatorColor,
      textInputBackgroundColor: colors.clear
    },

    settings: {
      profileMenu: {
        backgroundColor: sementicColors.dialogBackgroundColor,
        headerColor: sementicColors.dialogForegroundColor
      },

      courseSelection: {
        backgroundColor: sementicColors.dialogBackgroundColor,
        deleteButtonColor: sementicColors.errorColor,
        filterCellBackgroundColor: sementicColors.dialogBackgroundColor,
        filterCellChooseColor: sementicColors.dimmedColor,
        filterCellTextColor: sementicColors.dialogForegroundColor,
        listItemBackgroundColor: sementicColors.dialogBackgroundColor,
        listItemGroupTextColor: sementicColors.secondaryColor,
        listItemTitleTextColor: sementicColors.dialogForegroundColor,
        textColor: sementicColors.dialogForegroundColor
      },

      impersonateSelection: {
        backgroundColor: sementicColors.mainBackgroundColor
      },

      gearMenuShare: {
        backgroundColor: sementicColors.mainBackgroundColor,
        emailPlaceholderColor: sementicColors.separatorColor,
        errorMessageColor: sementicColors.errorColor,
        separatorLineColor: sementicColors.separatorColor,
        subtitleColor: sementicColors.mainForegroundColor,
        titleColor: sementicColors.mainForegroundColor
      },

      support: {
        doneButton: {
          backgroundColor: sementicColors.mainBackgroundColor,
          borderColor: sementicColors.accentColor,
          textColor: sementicColors.accentColor
        },

        welcomeTextColor: sementicColors.successColor
      },

      askName: {
        backgroundColor: sementicColors.mainBackgroundColor
      },

      versionNumberTextColor: sementicColors.disabledForegroundColor
    },

    utils: {
      avatarView: {
        initialsTextColor: sementicColors.contrastForegroundColor,
        defaultBackgroundColor: sementicColors.disabledBackgroundColor,
        impersonatingIconBackgroundColor: sementicColors.mainBackgroundColor,
        readonlyBorderColor: sementicColors.errorColor,
        borderColor: sementicColors.mainBackgroundColor
      },

      dropdownSelector: {
        backgroundColor: sementicColors.mainBackgroundColor
      },

      bottomOptionsBar: {
        defaultBackgroundColor: sementicColors.contentBackgroundColor,
        itemDefaultTintColor: sementicColors.accentColor
      },

      indicators: {
        noInternet: {
          backgroundColor: sementicColors.warningColor,
          textColor: sementicColors.mainForegroundColor
        },
        updateAvailable: {
          backgroundColor: sementicColors.warningColor,
          textColor: sementicColors.contrastForegroundColor
        },
        impersonating: {
          backgroundColor: sementicColors.selectedColor,
          textColor: sementicColors.contrastForegroundColor
        }
      },

      schoolDayPicker: {
        todayColor: sementicColors.errorColor,
        separatorViewColor: sementicColors.dialogForegroundColor,
        backgroundColor: sementicColors.dialogBackgroundColor,
        todayTextColor: sementicColors.contentForegroundColor,
        highlightedColor: sementicColors.debugColor,
        enabledDayTextColor: sementicColors.mainForegroundColor,
        disabledDayTextColor: sementicColors.disabledForegroundColor
      },

      specialDayCycleDayView: {
        darkCycleDayTitle: sementicColors.mainForegroundColor,
        lightCycleDayTitle: sementicColors.contrastForegroundColor
      },

      studyoButton: {
        blueVariant: {
          hoveredBackgroundColor: sementicColors.contentBackgroundColor,
          indicatorColor: sementicColors.contentForegroundColor,
          normalColor: sementicColors.accentColor,
          shadowColor: sementicColors.alternateContrastBackgroundColor,
          textColor: sementicColors.contrastForegroundColor
        },

        whiteVariant: {
          hoveredBackgroundColor: sementicColors.contentBackgroundColor,
          indicatorColor: sementicColors.contentForegroundColor,
          normalColor: sementicColors.mainBackgroundColor,
          shadowColor: sementicColors.contrastBackgroundColor,
          textColor: sementicColors.accentColor
        }
      },

      studyoRadioButton: {
        normalColor: sementicColors.accentColor,
        selectedColor: sementicColors.selectedColor,
        disabledColor: sementicColors.disabledForegroundColor
      },

      studyoTextInput: {
        backgroundColor: sementicColors.mainBackgroundColor,
        borderColor: sementicColors.separatorColor
      },

      tabView: {
        backgroundColor: sementicColors.dialogBackgroundColor,
        tabBackgroundColor: sementicColors.dialogBackgroundColor,
        tabsRowBorderColor: sementicColors.separatorColor,

        content: {
          activityIndicatorTextColor: sementicColors.separatorColor,
          activityIndicatorIOSColor: sementicColors.separatorColor,
          activityIndicatorOtherColor: sementicColors.selectedColor
        },

        tab: {
          selected: {
            borderColor: sementicColors.selectedColor,
            titleColor: sementicColors.selectedColor
          },

          notSelected: {
            borderColor: sementicColors.contrastForegroundColor,
            titleColor: sementicColors.mainForegroundColor
          }
        }
      },

      optionsDialog: {
        elementBackgroundColor: sementicColors.mainBackgroundColor,
        elementHoveredBackgroundColor: sementicColors.contentBackgroundColor,
        normalElementTextColor: sementicColors.accentColor,
        destructiveElementTextColor: sementicColors.errorColor,
        disabledElementTextColor: sementicColors.disabledForegroundColor
      },

      disabledOverlay: sementicColors.mainBackgroundColor,

      toast: {
        infoBackgroundColor: sementicColors.contentBackgroundColor,
        infoTextColor: sementicColors.contentForegroundColor,
        warningBackgroundColor: sementicColors.warningColor,
        warningTextColor: sementicColors.contentForegroundColor,
        errorBackgroundColor: sementicColors.errorColor,
        errorTextColor: sementicColors.contrastForegroundColor,
        successBackgroundColor: sementicColors.successColor,
        successTextColor: sementicColors.contrastForegroundColor
      }
    },

    extensions: {
      horizontalPagedView: {
        arrowColor: sementicColors.secondaryColor
      },

      toggleSwitch: {
        trackColorOn: sementicColors.accentColor,
        trackColorOff: sementicColors.separatorColor
      }
    },

    onboarding: {
      separatorLineColor: sementicColors.separatorColor,

      login: {
        containerBackgroundColor: sementicColors.mainBackgroundColor,
        studyoLogoColor: sementicColors.secondaryColor,
        versionNumberColor: sementicColors.separatorColor,
        errorColor: sementicColors.errorColor,
        languageButtonTextColor: sementicColors.secondaryColor
      },

      useCode: {
        borderColor: sementicColors.alternateContrastBackgroundColor,
        codePlaceholderColor: sementicColors.separatorColor,
        errorMessageColor: sementicColors.errorColor,
        logoutShadowColor: sementicColors.dimmedColor,
        subtitleColor: sementicColors.mainForegroundColor,
        titleColor: sementicColors.mainForegroundColor
      },

      today: {
        logoColor: kind === 'dark' ? '#FFFFFF' : '#9A4FD7'
      }
    },

    forbiddenContainerBackgroundColor: sementicColors.contentBackgroundColor
  };
};
