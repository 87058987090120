import { AccountService, NavigationService, SchoolYearConfigurationInfo } from '@insights/services';
import { NavigateFunction } from 'react-router-dom';

export interface UserSchoolConfigurationsViewModel {
  schoolConfigurations: SchoolYearConfigurationInfo[];

  navigateToConfig: (configId: string, navigate: NavigateFunction) => Promise<void>;
  close: () => void;
}

export class AppUserSchoolConfigurationsViewModel implements UserSchoolConfigurationsViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {}

  get schoolConfigurations(): SchoolYearConfigurationInfo[] {
    return this._accountService.visibleSchoolConfigurations;
  }

  async navigateToConfig(configId: string, navigate: NavigateFunction): Promise<void> {
    await this._navigationService.navigateToDashboard(configId, navigate);
    this._onSuccess();
  }

  close() {
    this._onCancel();
  }
}
