import * as C from '@buf/studyo_studyo.bufbuild_es/studyo/type_canvas_pb';
import { BaseModel } from '../Model';
import { contentIconFromProtobuf, contentWorkloadLevelFromProtobuf } from '../types/EnumConversion';
import { CanvasContentRepresentationModel } from './interfaces/CanvasContentRepresentationModel';

export class GrpcCanvasContentRepresentation
  extends BaseModel<C.ContentRepresentation>
  implements CanvasContentRepresentationModel
{
  constructor(pb: C.ContentRepresentation) {
    super(pb);
  }

  get icon() {
    return contentIconFromProtobuf(this._pb.icon);
  }

  get workloadLevel() {
    return contentWorkloadLevelFromProtobuf(this._pb.workloadLevel);
  }
}
