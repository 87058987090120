export interface PreparingStrings {
  explanation: string;
  explanation2: string;
  logOut: string;
  title: string;
  viewProfiles: string;
}

export const EnglishPreparingStrings: PreparingStrings = {
  explanation: 'Your school is still being prepared for the new school year.',
  explanation2: 'Please come back a little later.',
  logOut: 'Log out',
  title: 'School creation in progress',
  viewProfiles: 'Other profiles'
};

export const FrenchPreparingStrings: PreparingStrings = {
  explanation: 'Votre école est toujours en préparation pour la nouvelle année scolaire.',
  explanation2: 'Veuillez revenir un peu plus tard.',
  logOut: 'Déconnexion',
  title: 'École en préparation',
  viewProfiles: 'Autres profils'
};
