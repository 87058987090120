import { AccountModel, AccountSummary } from '@shared/models/config';
import { LocalizedStrings } from '@shared/resources/strings/LocalizedStrings';
import { AccountData } from '@shared/services/stores';

export class AccountUtils {
  static getDisplayLastFirstName(
    account: AccountModel | AccountSummary | undefined,
    fallbackValue = '',
    useProfileWhenAvailable = false
  ): string {
    if (account == null) {
      return fallbackValue;
    }

    let firstName = account.profile.privateFirstName?.trim() ?? '';
    let lastName = account.profile.privateLastName?.trim() ?? '';

    // Both fields must be set and non-empty to use them.
    if (!useProfileWhenAvailable || firstName.length === 0 || lastName.length === 0) {
      firstName = account.firstName.trim();
      lastName = account.lastName.trim();
    }

    if (lastName.length > 0) {
      if (firstName.length > 0) {
        return `${lastName}, ${firstName}`;
      } else {
        return lastName;
      }
    } else if (firstName.length > 0) {
      return firstName;
    } else {
      return fallbackValue;
    }
  }

  static getDisplayFirstLastName(
    account: AccountModel | AccountSummary | undefined,
    fallbackValue = '',
    useProfileWhenAvailable = false
  ): string {
    if (account == null) {
      return fallbackValue;
    }

    let firstName = account.profile.privateFirstName?.trim() ?? '';
    let lastName = account.profile.privateLastName?.trim() ?? '';

    // Both fields must be set and non-empty to use them.
    if (!useProfileWhenAvailable || firstName.length === 0 || lastName.length === 0) {
      firstName = account.firstName.trim();
      lastName = account.lastName.trim();
    }

    const name = `${firstName} ${lastName}`.trim();

    return name.length === 0 ? fallbackValue : name;
  }

  static getEmailBody(sectionTitle: string, data: AccountData, strings: LocalizedStrings): string {
    let message = '\n\n\n\n';

    const currentUserIsParent = data.impersonatingRole === 'parent';
    if (currentUserIsParent) {
      message += strings.studyo.agenda.parentOf;
      message += '\n';
    }

    const userName = AccountUtils.getDisplayFirstLastName(data.account);
    const userEmail = data.account.email;

    message += userName;
    message += '\n';

    message += userEmail;
    message += '\n';

    message += sectionTitle;

    return message;
  }
}
