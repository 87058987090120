import { AccountInfo, StudentBehaviorAggregationInfo } from '@insights/models';
import { AccountModel } from '@shared/models/config';
import { OQValue } from '@shared/models/types';

export function getAllBehaviorAggregations(
  students: AccountInfo[],
  parentsByChildId: Record<string, AccountModel[]>
): StudentBehaviorAggregationInfo {
  let opensGreyCount = 0;
  let opensGreenCount = 0;
  let opensYellowCount = 0;
  let opensRedCount = 0;
  let tasksGreyCount = 0;
  let tasksGreenCount = 0;
  let tasksYellowCount = 0;
  let tasksRedCount = 0;
  let parentsGreyCount = 0;
  let parentsGreenCount = 0;
  let parentsYellowCount = 0;
  let parentsRedCount = 0;

  students.forEach((s) => {
    switch (s.oqProfile?.opensTheApp) {
      case 'none':
        opensGreyCount++;
        break;
      case 'green':
        opensGreenCount++;
        break;
      case 'yellow':
        opensYellowCount++;
        break;
      case 'red':
        opensRedCount++;
        break;
    }

    switch (s.oqProfile?.marksTasksAsDone) {
      case 'none':
        // Counted even if not expected.
        tasksGreyCount++;
        break;
      case 'green':
        tasksGreenCount++;
        break;
      case 'yellow':
        tasksYellowCount++;
        break;
      case 'red':
        tasksRedCount++;
        break;
    }

    const parents = parentsByChildId[s.account.id];

    if (parents == null || parents.length === 0) {
      parentsGreyCount++;
    } else if (parents.find((p) => p.userId.length > 0) != null) {
      parentsGreenCount++;
    } else if (parents.find((p) => p.childrenAccountIds?.includes(s.account.id)) != null) {
      parentsYellowCount++;
    } else {
      parentsRedCount++;
    }
  });

  // Any 0 is replaced with undefined.
  return {
    opensAppBehavior: {
      greyCount: opensGreyCount || undefined,
      greenCount: opensGreenCount || undefined,
      yellowCount: opensYellowCount || undefined,
      redCount: opensRedCount || undefined
    },
    completesTasksBehavior: {
      greyCount: tasksGreyCount || undefined,
      greenCount: tasksGreenCount || undefined,
      yellowCount: tasksYellowCount || undefined,
      redCount: tasksRedCount || undefined
    },
    invitesParentBehavior: {
      greyCount: parentsGreyCount || undefined,
      greenCount: parentsGreenCount || undefined,
      yellowCount: parentsYellowCount || undefined,
      redCount: parentsRedCount || undefined
    }
  };
}

export function getParentInvitationOQValue(
  account: AccountModel,
  parentsByChildId: Record<string, AccountModel[]>
): OQValue {
  const parents = parentsByChildId[account.id];

  if (parents == null || parents.length === 0) {
    return 'none';
  } else if (parents.find((p) => p.userId.length > 0) != null) {
    return 'green';
  } else if (parents.find((p) => p.childrenAccountIds?.includes(account.id)) != null) {
    return 'yellow';
  } else {
    return 'red';
  }
}
