import { Box, SxProps } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { SpecialDayOccurrence } from '@shared/models/calendar';
import { Draggable } from '@shared/rxp/drag-drop';
import { observer } from 'mobx-react-lite';
import { SpecialDaySymbolImage } from './SpecialDaySymbolImage';

export type DraggableSpecialDaySymbolGridDisplayKind = 'grid' | 'linear';

export interface DraggableSpecialDaySymbolGridProps<TData> {
  sx?: SxProps;
  className?: string;
  displayKind?: DraggableSpecialDaySymbolGridDisplayKind;
  specialDays: SpecialDayOccurrence[];
  squareSize?: number;
  alwaysDisplaySymbol?: boolean;
  displayNoneSymbol?: boolean;
  maxSymbolCount?: number;
  draggableDataSelector: (occurrence: SpecialDayOccurrence) => TData;
  draggableType: string;
  canCopyMove?: boolean;
}

/**
 *
 * A view which display draggable symbols of a special day together in two layout.
 */

export const DraggableSpecialDaySymbolGrid = observer(<TData,>(props: DraggableSpecialDaySymbolGridProps<TData>) => {
  const {
    sx = [],
    className,
    specialDays,
    alwaysDisplaySymbol,
    maxSymbolCount = 4,
    displayNoneSymbol = false,
    displayKind,
    squareSize = 36,
    draggableDataSelector,
    draggableType,
    canCopyMove
  } = props;

  const filteredSpecialDays = displayNoneSymbol ? specialDays : specialDays.filter((sd) => sd.symbol !== 'none');

  const renderGrid = () => {
    return (
      <Box
        className={className}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          ...sx,
          width: squareSize * 2 + 4,
          height: squareSize * 2 + 4
        }}
      >
        {filteredSpecialDays.slice(0, maxSymbolCount).map((sd) => (
          <Box key={`${sd.specialDayId}`} sx={{ width: squareSize, height: squareSize, margin: '1px' }}>
            <Draggable data={draggableDataSelector(sd)} type={draggableType} canCopyMove={canCopyMove}>
              <SpecialDaySymbolImage
                squareSize={squareSize}
                symbol={sd.symbol}
                color={SectionColors.get(sd.color) ?? SectionColors.get('medium-bmgray')}
                alwaysDisplaySymbol={alwaysDisplaySymbol}
              />
            </Draggable>
          </Box>
        ))}
      </Box>
    );
  };

  const renderLinear = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          ...sx
        }}
      >
        {filteredSpecialDays.slice(0, maxSymbolCount).map((sd) => (
          <Box key={`${sd.specialDayId}`} sx={{ width: squareSize, height: squareSize, mx: '1px', mb: '1px' }}>
            <Draggable data={draggableDataSelector(sd)} type={draggableType} canCopyMove={canCopyMove}>
              <SpecialDaySymbolImage
                squareSize={squareSize}
                symbol={sd.symbol}
                color={SectionColors.get(sd.color) ?? SectionColors.get('medium-bmgray')}
                alwaysDisplaySymbol={alwaysDisplaySymbol}
              />
            </Draggable>
          </Box>
        ))}
      </Box>
    );
  };

  return displayKind === 'grid' ? renderGrid() : renderLinear();
});
