export interface LinkedTasksDeleteStrings {
  readonly title: string;
  readonly selectAll: string;
  readonly deselectAll: string;
  readonly cancel: string;
  readonly delete: string;
  readonly noCourse: string;
  readonly periodTag: (tag: string) => string;

  readonly deleteConfirmTitle: string;
  readonly deleteConfirmDescription: string;
  readonly deleteConfirm: string;
  readonly deleteConfirmCancel: string;

  readonly savingTitle: string;
  readonly savingDescription: string;
  readonly savedTitle: string;
  readonly savedDescription: string;
  readonly saveErrorTitle: string;
  readonly saveErrorDescription: string;
  readonly savePermanentErrorDescription: string;
}

export const EnglishLinkedTasksDeleteStrings: LinkedTasksDeleteStrings = {
  title: 'Select occurrences to delete',
  selectAll: 'Select all',
  deselectAll: 'Deselect all',
  cancel: 'Cancel',
  delete: 'Delete',
  noCourse: 'No Section',
  periodTag: (tag) => `Period ${tag}`,

  deleteConfirmTitle: 'Delete occurrences',
  deleteConfirmDescription: 'Are you sure wou want to delete the selected occurences?',
  deleteConfirm: 'Delete',
  deleteConfirmCancel: 'Cancel',

  savingTitle: 'Deleting occurrences',
  savingDescription: 'Deleting the selected occurrences. Please wait.',
  savedTitle: 'Success!',
  savedDescription: 'The selected occurrences have been deleted',
  saveErrorTitle: 'Oops! An error occurred',
  saveErrorDescription: 'Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented the deletion. Please reload and try again.'
};

export const FrenchLinkedTasksDeleteStrings: LinkedTasksDeleteStrings = {
  title: 'Sélectionner les occurrences à supprimer',
  selectAll: 'Tout sélectionner',
  deselectAll: 'Tout désélectionner',
  cancel: 'Annuler',
  delete: 'Supprimer',
  noCourse: 'Aucun cours',
  periodTag: (tag) => `Période ${tag}`,

  deleteConfirmTitle: 'Supprimer les occurrences',
  deleteConfirmDescription: 'Êtes-vous certain de vouloir supprimer les occurrences sélectionnées?',
  deleteConfirm: 'Supprimer',
  deleteConfirmCancel: 'Annuler',

  savingTitle: 'Suppression des occurrences',
  savingDescription: 'Veuillez patienter pendant que nous supprimons les occurrences sélectionnées.',
  savedTitle: 'Succès!',
  savedDescription: 'Les occurrences sélectionnées ont été supprimées.',
  saveErrorTitle: 'Oups! Une erreur est survenue',
  saveErrorDescription: 'Veuillez réessayer plus tard.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la suppression. Veuillez rafraichir et réessayer.'
};
