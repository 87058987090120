import { Message } from '@bufbuild/protobuf';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { SerializableModel } from '../Model';
import { EditableModelEx, EditableStringArrayProperty } from '../editables';
import { TrackedModel } from './TrackedModel';

export class TrackedEditableModel<
  TProtobuf extends Message<TProtobuf>,
  TModel extends SerializableModel<TProtobuf> & TrackedModel
> extends EditableModelEx<TProtobuf> {
  private _manualChanges: EditableStringArrayProperty<TProtobuf>;

  constructor(original: TModel, applyManualChanges: (host: TProtobuf, values: string[]) => void, isNew = false) {
    super(original.toProtobuf(), isNew);

    makeObservable(this);

    this._manualChanges = this.addStringArrayField(original.manualChanges, applyManualChanges, {
      trim: true
    });
  }

  @computed
  get manualChanges() {
    return this._manualChanges.value;
  }

  set manualChanges(values: string[]) {
    this._manualChanges.value = values;
  }

  protected addManualChanges(fieldName: string) {
    this._manualChanges.value = _.chain(this._manualChanges.value).union([fieldName]).value();
  }
}
