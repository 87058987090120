import { OnboardingParticipantKind, OnboardingQuestionKind, OnboardingStatus } from '@shared/models/types';

export interface OnboardingViewsStrings {
  accountNotFoundLabel: string;
  addChoiceLabel: string;
  addCommentLabel: string;
  addCommentTitle: string;
  addProcessLabel: string;
  addProcessTitle: string;
  addQuestionLabel: string;
  addQuestionTitle: string;
  addStepLabel: string;
  addStepTitle: string;
  answered: string;
  answerKindLabel: string;
  anyStatus: string;
  applyLabel: string;
  assignToAllSteps: string;
  cancelCommentLabel: string;
  cancelLabel: string;
  changeSensitiveAnswerButton: string;
  clearLabel: string;
  commentsTitle: string;
  completeStepButton: string;
  incompleteAnswersLabel: string;
  confirmLabel: string;
  copyAnswerButton: string;
  copyButtonLabel: string;
  copyProcessLabel: string;
  copyQuestionLabel: string;
  copyStepLabel: string;
  currentTemplateNameLabel: string;
  customizedProcessNotice: string;
  customizedQuestionNotice: string;
  customizedStepNotice: string;
  deleteProcessLabel: string;
  deleteQuestionLabel: string;
  dependantQuestionAnswerLabel: string;
  dependantQuestionNameLabel: string;
  dependantStepNameLabel: string;
  descriptionEditionTitle: string;
  descriptionTitle: string;
  editProcessLabel: string;
  editProcessTitle: string;
  editQuestionLabel: string;
  editQuestionTitle: string;
  editStepLabel: string;
  editStepTitle: string;
  errorSavingAnswerTooltip: string;
  fileNotProvided: string;
  fileProvided: string;
  fileUrlInClipboardToast: string;
  forceAnswer: string;
  fromAdmin: string;
  hideLockedContentTooltip: string;
  invalidText: string;
  isAnswerRequiredLabel: string;
  isBlockingLabelPrefix: string;
  isBlockingLabelSuffix: string;
  isHiddenWhenDependantLabel: string;
  isPrivateLabel: string;
  isRepeatableLabel: string;
  isUnblockingLabelPrefix: string;
  isUnblockingLabelSuffix: string;
  lastYearAnswerTooltip: string;
  loadingProcessMessage: string;
  loadingProcessErrorMessage: string;
  loadingStepMessage: string;
  loadingStepErrorMessage: string;
  minimumDate: string;
  minimumDateNotice: string;
  newTemplateNameLabel: string;
  noAnswers: string;
  noClientOwnerLabel: string;
  noClientOwnerShortLabel: string;
  noDependantQuestionLabel: string;
  noDependantStepLabel: string;
  noneLabel: string;
  noProcessesTitle: string;
  noStudyoAgentLabel: string;
  noStudyoAgentShortLabel: string;
  noTargetDayLabel: string;
  notAnswered: string;
  okLabel: string;
  participantsLabel: string;
  participantClientTooltip: string;
  participantStudyoAndClientTooltip: string;
  participantStudyoTooltip: string;
  previousAnswersHeader: string;
  processStatuses: string;
  processTitle: string;
  questionChoiceValueLabel: string;
  questionsInOtherStepLabel: (stepName: string) => string;
  questionsInThisStepLabel: string;
  renameButtonLabel: string;
  repeatStepButton: string;
  replaceFileButton: string;
  saveLabel: string;
  saveForAllButton: string;
  saveForcedAnswer: string;
  saveOnlyForThisSchoolButton: string;
  saveForThisSchoolButton: string;
  schoolHistoryTitle: string;
  selectableProcess: string;
  selectAgentLabel: string;
  selectClientLabel: string;
  selectEverythingLabel: string;
  selectEverythingTooltip: string;
  selectFileButton: string;
  selectFollowersLabel: string;
  selectOwnersTitle: string;
  selectProcessTemplateLabel: string;
  selectQuestionTemplateLabel: string;
  selectStepTemplateLabel: string;
  sensitiveAnswerLabel: string;
  sensitiveAnswerTitle: string;
  setSensitiveAnswerButton: string;
  setTargetDateTooltip: string;
  statusArchivedTooltip: string;
  statusCompletedTooltip: string;
  statusInProgressTooltip: string;
  statusNotStartedTooltip: string;
  stepNotAccessible: string;
  stepStatuses: string;
  stepsTitle: string;
  suggestSupportButton: string;
  suggestSupportLabel: string;
  tabEditLabel: string;
  tabPreviewLabel: string;
  targetDaysLabel: string;
  textFormatHtml: string;
  textFormatMarkdown: string;
  textFormatPlainText: string;
  titleTitle: string;
  toggleForcedVisibilityOff: string;
  toggleForcedVisibilityOn: string;
  updateCommentLabel: string;
  viewHistoryTooltip: string;
  viewPreviousAnswers: string;
  viewSensitiveAnswer: string;

  getAnswerKindLabel: (kind: OnboardingQuestionKind) => string;
  getCreateProcessLabel: (processName: string) => string;
  getCreateStepLabel: (stepName: string) => string;
  getCreateQuestionLabel: (questionName: string) => string;
  getParticipantKindLabel: (kind: OnboardingParticipantKind) => string;
  getProcessStatusLabel: (status: OnboardingStatus, isBlocked: boolean) => string;
  getRenameTemplateLabel: (name: string) => string;
  getStepStatusLabel: (status: OnboardingStatus, isBlocked: boolean) => string;
}

export const EnglishOnboardingViewsStrings: OnboardingViewsStrings = {
  accountNotFoundLabel: 'No matches',
  addChoiceLabel: 'Add an answer choice',
  addCommentLabel: 'Post',
  addCommentTitle: 'Add a new comment:',
  addProcessLabel: 'Add Process',
  addProcessTitle: 'Add Process',
  addQuestionLabel: 'Add Question',
  addQuestionTitle: 'Add Question',
  addStepLabel: 'Add Step',
  addStepTitle: 'Add Step',
  answered: 'Answered',
  answerKindLabel: 'Answer kind',
  anyStatus: 'Any',
  applyLabel: 'Apply',
  assignToAllSteps: 'Assign to all steps',
  cancelCommentLabel: 'Cancel',
  cancelLabel: 'Cancel',
  changeSensitiveAnswerButton: 'Change Answer',
  clearLabel: 'Clear',
  commentsTitle: 'Questions & Comments',
  completeStepButton: 'This step is completed!',
  incompleteAnswersLabel: 'Some required questions have no answer',
  confirmLabel: 'Confirm',
  copyAnswerButton: 'Copy!',
  copyButtonLabel: 'Copy',
  copyProcessLabel: 'Make a copy of this process template',
  copyQuestionLabel: 'Make a copy of this question template',
  copyStepLabel: 'Make a copy of this step template',
  currentTemplateNameLabel: 'Current template name',
  customizedProcessNotice:
    'This process has diverged from the original process template. Changes will only affect this school.',
  customizedQuestionNotice:
    'This question has diverged from the original question template. Changes will only affect this school.',
  customizedStepNotice: 'This step has diverged from the original step template. Changes will only affect this school.',
  deleteProcessLabel: 'Delete this process',
  deleteQuestionLabel: 'Delete this question',
  dependantQuestionAnswerLabel: 'Specific answer that unlocks this question',
  dependantQuestionNameLabel: 'Question that must be answered before unlocking this one',
  dependantStepNameLabel: 'Activate this step when that other step completes',
  descriptionEditionTitle: 'Main description',
  descriptionTitle: 'Description',
  editProcessLabel: 'Edit this process',
  editProcessTitle: 'Edit current process',
  editQuestionLabel: 'Edit this question',
  editQuestionTitle: 'Edit question',
  editStepLabel: 'Edit this step',
  editStepTitle: 'Edit step',
  errorSavingAnswerTooltip:
    'An unexpected error occurred while saving your answer. Make sure your network connection is active.',
  fileNotProvided: 'A file was not provided yet',
  fileProvided: 'A file was provided',
  fileUrlInClipboardToast: 'Your file was uploaded successfully. Its url is now in the clipboard.',
  forceAnswer: 'Allow answer even if disabled',
  fromAdmin: 'Admin',
  hideLockedContentTooltip: 'Hide or show locked steps and questions',
  invalidText: 'Unsupported or empty text content',
  isAnswerRequiredLabel: 'An answer is required',
  isBlockingLabelPrefix: 'This step is ',
  isBlockingLabelSuffix: 'BLOCKED',
  isHiddenWhenDependantLabel: 'Hide question until unlocked',
  isPrivateLabel: 'Private - Only for Studyo',
  isRepeatableLabel: 'This step can be repeated',
  isUnblockingLabelPrefix: 'The blocking situation is ',
  isUnblockingLabelSuffix: 'RESOLVED',
  lastYearAnswerTooltip: "Last year's answer",
  loadingProcessErrorMessage: 'There are no processes available.',
  loadingProcessMessage: 'Loading onboarding process…',
  loadingStepErrorMessage: 'This step is not available for the moment.',
  loadingStepMessage: 'Loading onboarding step…',
  minimumDate: 'Minimum date',
  minimumDateNotice: 'The minimum date when a process or step was last updated.',
  newTemplateNameLabel: 'New template name to create',
  noAnswers: 'No answers',
  noClientOwnerLabel: 'No client assigned',
  noClientOwnerShortLabel: 'no client',
  noDependantQuestionLabel: '(None)',
  noDependantStepLabel: '(None)',
  noneLabel: 'None',
  noProcessesTitle: 'No active onboarding process',
  noStudyoAgentLabel: 'No Studyo agent assigned',
  noStudyoAgentShortLabel: 'no agent',
  noTargetDayLabel: 'No target day',
  notAnswered: 'Not answered yet',
  okLabel: 'Ok',
  previousAnswersHeader: 'Previous answers:',
  processStatuses: 'Process status',
  processTitle: 'Onboarding with Studyo',
  participantsLabel: 'Who can fill in and complete this step?',
  participantClientTooltip: 'For School',
  participantStudyoAndClientTooltip: 'For School and Studyo',
  participantStudyoTooltip: 'For Studyo',
  questionChoiceValueLabel: 'Value to save',
  questionsInOtherStepLabel: (stepName: string) => `In step ${stepName}`,
  questionsInThisStepLabel: 'In this step',
  renameButtonLabel: 'Rename',
  repeatStepButton: 'Repeat this step',
  replaceFileButton: 'Replace with a new file',
  saveLabel: 'Save',
  saveForAllButton: 'Save for all',
  saveForcedAnswer: 'Save this new answer',
  saveOnlyForThisSchoolButton: 'Save only for this school',
  saveForThisSchoolButton: 'Save for this school',
  schoolHistoryTitle: 'History of changes',
  selectableProcess: 'The process',
  selectAgentLabel: 'Select Studyo agent',
  selectClientLabel: 'Select client',
  selectEverythingLabel: 'Apply to the process and all steps',
  selectEverythingTooltip: 'Select/Unselect everything',
  selectFileButton: 'Select file to upload',
  selectFollowersLabel: 'Select other followers',
  selectOwnersTitle: 'Select ownership',
  selectProcessTemplateLabel: 'Select a process template',
  selectQuestionTemplateLabel: 'Select a question template',
  selectStepTemplateLabel: 'Select a step template',
  sensitiveAnswerLabel: 'Please enter your answer',
  sensitiveAnswerTitle: 'Sensitive answer',
  setSensitiveAnswerButton: 'Answer',
  setTargetDateTooltip: 'Change the target date',
  statusArchivedTooltip: 'Archived',
  statusCompletedTooltip: 'Completed',
  statusInProgressTooltip: 'In progress',
  statusNotStartedTooltip: 'Not started',
  stepNotAccessible: 'This step is not available for the moment.',
  stepStatuses: 'Step status',
  stepsTitle: 'Steps',
  suggestSupportButton: 'our online support service',
  suggestSupportLabel: 'Now that your onboarding is completed, we recommend you use ',
  tabEditLabel: 'Edit',
  tabPreviewLabel: 'Preview',
  targetDaysLabel: 'Expected number of days to complete',
  textFormatHtml: 'HTML',
  textFormatMarkdown: 'Markdown',
  textFormatPlainText: 'Plain Text',
  titleTitle: 'Title',
  toggleForcedVisibilityOff: 'Use default visibility',
  toggleForcedVisibilityOn: 'Make this step always visible',
  updateCommentLabel: 'Update',
  viewHistoryTooltip: 'View history of changes',
  viewPreviousAnswers: 'View previous answers',
  viewSensitiveAnswer: 'View the answer',

  getAnswerKindLabel: (kind: OnboardingQuestionKind) => {
    switch (kind) {
      case 'simple-text':
        return 'Simple text (single line)';
      case 'large-text':
        return 'Large text (multiple lines)';
      case 'single-choice':
        return 'Single selection from multiple choices';
      case 'multiple-choice':
        return 'Multiple selections from choices';
      case 'date':
        return 'Date';
      case 'date-time':
        return 'Date and time';
      case 'time':
        return 'Time of day';
      case 'file':
        return 'Upload a file';
      case 'sensitive-simple-text':
        return 'Protected simple text';
    }
  },
  getCreateProcessLabel: (processName: string) => {
    return `Create a new process named "${processName}"`;
  },
  getCreateStepLabel: (stepName: string) => {
    return `Create a new step named "${stepName}"`;
  },
  getCreateQuestionLabel: (questionName: string) => {
    return `Create a new question named "${questionName}"`;
  },
  getParticipantKindLabel: (kind: OnboardingParticipantKind) => {
    switch (kind) {
      case 'studyo-only':
        return 'Studyo agents only';
      case 'studyo-and-client':
        return 'Studyo agents and clients';
      case 'client-only':
        return 'Clients only';
    }
  },
  getProcessStatusLabel: (status: OnboardingStatus, isBlocked = false) => {
    switch (status) {
      case 'not-started':
        return 'Not Started';
      case 'in-progress':
        return isBlocked ? 'Blocked' : 'In Progress';
      case 'completed':
        return 'Completed';
      case 'archived':
        return 'Archived';
    }
  },
  getRenameTemplateLabel: (name: string) => `Rename template (${name})`,
  getStepStatusLabel: (status: OnboardingStatus, isBlocked = false) => {
    switch (status) {
      case 'not-started':
        return 'Not Started';
      case 'in-progress':
        return isBlocked ? 'Blocked' : 'In Progress';
      case 'completed':
        return 'Completed';
      case 'archived':
        return 'Archived';
    }
  }
};

export const FrenchOnboardingViewsStrings: OnboardingViewsStrings = {
  accountNotFoundLabel: 'Aucune correspondance',
  addChoiceLabel: 'Ajouter un choix de réponse',
  addCommentLabel: 'Publier',
  addCommentTitle: 'Ajouter un commentaire:',
  addProcessLabel: 'Ajouter un processus',
  addProcessTitle: 'Ajouter un processus',
  addQuestionLabel: 'Ajouter une question',
  addQuestionTitle: 'Ajouter une question',
  addStepLabel: 'Ajouter une étape',
  addStepTitle: 'Ajouter une étape',
  selectEverythingLabel: 'Appliquer au processus et toutes ses étapes',
  answered: 'Répondue',
  answerKindLabel: 'Type de réponse',
  anyStatus: 'Tous',
  applyLabel: 'Appliquer',
  assignToAllSteps: 'Assigner à toutes les étapes',
  cancelCommentLabel: 'Annuler',
  cancelLabel: 'Annuler',
  changeSensitiveAnswerButton: 'Changer la réponse',
  clearLabel: 'Vider',
  commentsTitle: 'Questions et commentaires',
  completeStepButton: 'Cette étape est complétée!',
  incompleteAnswersLabel: 'Des questions requises n’ont pas de réponse',
  confirmLabel: 'Confirmer',
  copyAnswerButton: 'Copier!',
  copyButtonLabel: 'Copier',
  copyProcessLabel: 'Créer une copie de ce modèle de processus',
  copyQuestionLabel: 'Créer une copie de ce modèle de question',
  copyStepLabel: 'Créer une copie de ce modèle d’étape',
  currentTemplateNameLabel: 'Nom courrant du modèle',
  customizedProcessNotice: 'Ce processus a divergé du modèle original. Les changements n’affecteront que cette école.',
  customizedQuestionNotice:
    'Cette question a divergé du modèle original. Les changements n’affecteront que cette école.',
  customizedStepNotice: 'Cette étape a divergé du modèle original. Les changements n’affecteront que cette école.',
  deleteProcessLabel: 'Effacer ce processus',
  deleteQuestionLabel: 'Effacer cette question',
  dependantQuestionAnswerLabel: 'Réponse spécifique qui débarre cette question',
  dependantQuestionNameLabel: 'Question qui doit être répondue avant de débarrer celle-ci',
  dependantStepNameLabel: 'Activer cette étape lorsque cette autre complète',
  descriptionEditionTitle: 'Description principale',
  descriptionTitle: 'Description',
  editProcessLabel: 'Modifier ce processus',
  editProcessTitle: 'Modifier le processus',
  editQuestionLabel: 'Modifier cette question',
  editQuestionTitle: 'Modifier la question',
  editStepLabel: 'Modifier cette étape',
  editStepTitle: 'Modifier l’étape',
  errorSavingAnswerTooltip:
    'Une erreur inattendue est survenue pendant la sauvegarde de votre réponse. Assurez-vous que votre connexion réseau est fonctionnelle.',
  fileNotProvided: 'Aucun fichier fourni pour le moment',
  fileProvided: 'Un fichier a été fourni',
  fileUrlInClipboardToast: 'Votre fichier a été téléversé avec succès. Son adresse web est dans le presse-papier.',
  forceAnswer: 'Permettre une réponse même si désactivée',
  fromAdmin: 'Admin',
  hideLockedContentTooltip: 'Cacher ou afficher les étapes et questions barrées',
  invalidText: 'Type de texte inattendu ou vide',
  isAnswerRequiredLabel: 'Une réponse est requise',
  isBlockingLabelPrefix: 'Cette étape doit être condidérée ',
  isBlockingLabelSuffix: 'BLOQUÉE',
  isHiddenWhenDependantLabel: 'Cacher cette question tant qu’elle est barrée',
  isPrivateLabel: 'Privé - que pour Studyo',
  isRepeatableLabel: 'Cette étape peut être répétée',
  isUnblockingLabelPrefix: 'La situation qui bloquait cette étape est ',
  isUnblockingLabelSuffix: 'RÉSOLUE',
  lastYearAnswerTooltip: 'Réponse de l’an passé',
  loadingProcessErrorMessage: 'Aucune processus d’embarquement n’est disponible pour l’instant.',
  loadingProcessMessage: 'Chargement du processus d’embarquement…',
  loadingStepErrorMessage: 'Cette étape d’embarquement n’est pas disponible pour l’instant.',
  loadingStepMessage: 'Chargement de l’étape d’embarquement…',
  minimumDate: 'Date minimum',
  minimumDateNotice: 'La date minimum à laquelle un processus ou une étape fut mise à jour.',
  newTemplateNameLabel: 'Nouveau nom de modèle à créer',
  noAnswers: 'Aucune réponse',
  noClientOwnerLabel: 'Choisir responsable',
  noClientOwnerShortLabel: 'aucun resp.',
  noDependantQuestionLabel: '(Aucune)',
  noDependantStepLabel: '(Aucune)',
  noneLabel: 'Aucune',
  noProcessesTitle: 'Aucun processus d’embarquement en cours',
  noStudyoAgentLabel: 'Choisir agent Studyo',
  noStudyoAgentShortLabel: 'aucun agent',
  noTargetDayLabel: 'Pas de date cible',
  notAnswered: 'Sans réponse',
  okLabel: 'Ok',
  previousAnswersHeader: 'Réponses précédentes:',
  participantsLabel: 'Qui peut remplir et compléter cette étape?',
  participantClientTooltip: 'Pour l’école',
  participantStudyoAndClientTooltip: 'Pour l’école et Studyo',
  participantStudyoTooltip: 'Pour Studyo',
  processStatuses: 'État de processus',
  processTitle: 'Embarquer avec Studyo',
  questionChoiceValueLabel: 'Valeur à sauvegarder',
  questionsInOtherStepLabel: (stepName: string) => `Dans l’étape ${stepName}`,
  questionsInThisStepLabel: 'Dans cette étape',
  renameButtonLabel: 'Renommer',
  repeatStepButton: 'Répéter cette étape',
  replaceFileButton: 'Remplacer par un nouveau fichier',
  saveLabel: 'Enregistrer',
  saveForAllButton: 'Enregistrer pour tous',
  saveForcedAnswer: 'Enregistrer cette nouvelle réponse',
  saveOnlyForThisSchoolButton: 'Enregistrer que pour cette école',
  saveForThisSchoolButton: 'Enregistrer pour cette école',
  schoolHistoryTitle: 'Historique des changements',
  selectableProcess: 'Le processus',
  selectAgentLabel: 'Choisir l’agent de Studyo',
  selectClientLabel: 'Choisir le client',
  selectEverythingTooltip: 'Cocher/décocher tout',
  selectFileButton: 'Choisissez le fichier à téléverser',
  selectFollowersLabel: 'Choisir d’autres abonnés',
  selectOwnersTitle: 'Sélection des responsables',
  selectProcessTemplateLabel: 'Choisissez un modèle de processus',
  selectQuestionTemplateLabel: 'Choisissez un modèle de question',
  selectStepTemplateLabel: 'Choisissez un modèle d’étape',
  sensitiveAnswerLabel: 'Veuillez entrer votre réponse',
  sensitiveAnswerTitle: 'Réponse sensible',
  setSensitiveAnswerButton: 'Répondre',
  setTargetDateTooltip: 'Changer la date cible',
  statusArchivedTooltip: 'Archivée',
  statusCompletedTooltip: 'Complétée',
  statusInProgressTooltip: 'En cours',
  statusNotStartedTooltip: 'Non démarrée',
  stepNotAccessible: 'Cette étape n’est pas disponible pour l’instant.',
  stepStatuses: 'État d’étape',
  stepsTitle: 'Étapes',
  suggestSupportButton: 'notre service d’aide en ligne',
  suggestSupportLabel: 'Maintenant que votre embarquement est complété, nous vous encourageons à utiliser ',
  tabEditLabel: 'Édition',
  tabPreviewLabel: 'Visualisation',
  targetDaysLabel: 'Nombre prévu de jours pour compléter',
  textFormatHtml: 'HTML',
  textFormatMarkdown: 'Markdown',
  textFormatPlainText: 'Texte brut', // Preferred over "Texte en clair"
  titleTitle: 'Titre',
  toggleForcedVisibilityOff: 'Utiliser la visibilité par défaut',
  toggleForcedVisibilityOn: 'Rendre cette étape toujours visible',
  updateCommentLabel: 'Mettre à jour',
  viewHistoryTooltip: 'Voir l’historique des changements',
  viewPreviousAnswers: 'Voir les réponses précédentes',
  viewSensitiveAnswer: 'Voir la réponse',

  getAnswerKindLabel: (kind: OnboardingQuestionKind) => {
    switch (kind) {
      case 'simple-text':
        return 'Texte simple (une ligne)';
      case 'large-text':
        return 'Texte détaillé (plusieurs lignes)';
      case 'single-choice':
        return 'Un choix parmi plusieurs options';
      case 'multiple-choice':
        return 'Un ou plusieurs choix parmi plusieurs options';
      case 'date':
        return 'Date';
      case 'date-time':
        return 'Date et heure';
      case 'time':
        return 'Heure';
      case 'file':
        return 'Téléverser un fichier';
      case 'sensitive-simple-text':
        return 'Texte simple protégé';
    }
  },
  getCreateProcessLabel: (processName: string) => {
    return `Créer un nouveau processus appelé "${processName}"`;
  },
  getCreateStepLabel: (stepName: string) => {
    return `Créer une nouvelle étape appelée "${stepName}"`;
  },
  getCreateQuestionLabel: (questionName: string) => {
    return `Créer une nouvelle question appelée "${questionName}"`;
  },
  getParticipantKindLabel: (kind: OnboardingParticipantKind) => {
    switch (kind) {
      case 'studyo-only':
        return 'Agents Studyo seulement';
      case 'studyo-and-client':
        return 'Agents Studyo et clients';
      case 'client-only':
        return 'Clients seulement';
    }
  },
  getProcessStatusLabel: (status: OnboardingStatus, isBlocked = false) => {
    // Un processus, donc masculin
    switch (status) {
      case 'not-started':
        return 'Pas démarré';
      case 'in-progress':
        return isBlocked ? 'Bloqué' : 'En cours';
      case 'completed':
        return 'Complété';
      case 'archived':
        return 'Archivé';
    }
  },
  getRenameTemplateLabel: (name: string) => `Renommer le modèle (${name})`,
  getStepStatusLabel: (status: OnboardingStatus, isBlocked = false) => {
    // Une étape, donc féminin
    switch (status) {
      case 'not-started':
        return 'Pas démarrée';
      case 'in-progress':
        return isBlocked ? 'Bloquée' : 'En cours';
      case 'completed':
        return 'Complétée';
      case 'archived':
        return 'Archivée';
    }
  }
};
