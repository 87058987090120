import { ObservablePresenter } from '@insights/components';
import { BlackbaudSkyAuthorizationRedirectViewModel } from '@insights/viewmodels';
import MappingsIcon from '@mui/icons-material/Category';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button, styled, SxProps, Typography } from '@mui/material';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface BlackbaudSkyAuthorizationRedirectScreenProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const BlackbaudSkyAuthorizationRedirectScreen = observer(
  (props: BlackbaudSkyAuthorizationRedirectScreenProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { sx = [], className, style } = props;
    const strings = localizationService.localizedStrings.insights.views.settings;

    const location = useLocation();
    const { search } = location;
    const viewModel = useMemo(() => viewModelFactory.createBlackbaudSkyRedirect(search), [search]);
    const navigate = useNavigate();

    return (
      <Root sx={sx} className={className} style={style} display="flex" flexDirection="column">
        <Box flex={1}>
          <ObservablePresenter
            sx={{ p: 2, width: '100%', height: '100%' }}
            data={viewModel.data}
            loadingMessage={strings.completingBlackbaudSkyAuthorizationMessage}
            errorMessage={strings.completingBlackbaudSkyAuthorizationErrorMessage}
            render={(data) => renderData(data, localizationService, navigate)}
          />
        </Box>
      </Root>
    );
  }
);

function renderData(
  data: BlackbaudSkyAuthorizationRedirectViewModel,
  localizationService: LocalizationService,
  navigate: NavigateFunction
) {
  return data.tokens == null
    ? renderFailure(data, localizationService, navigate)
    : !data.hasAccess
      ? renderPartialSuccess(data, localizationService, navigate)
      : renderSuccess(data, localizationService, navigate);
}

function renderFailure(
  data: BlackbaudSkyAuthorizationRedirectViewModel,
  localizationService: LocalizationService,
  navigate: NavigateFunction
) {
  const strings = localizationService.localizedStrings.insights.views.settings;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography variant="h4">{strings.cancelledBlackbaudSkyAuthorizationTitle}</Typography>
      <Typography className="message" variant="subtitle1" align="center">
        {strings.cancelledBlackbaudSkyAuthorizationMessage}
      </Typography>
      <Button variant="contained" color="primary" onClick={() => void data.navigateToExternalAccounts(navigate)}>
        {strings.returnToExternalAccountsButton}
      </Button>
    </Box>
  );
}

function renderPartialSuccess(
  data: BlackbaudSkyAuthorizationRedirectViewModel,
  localizationService: LocalizationService,
  navigate: NavigateFunction
) {
  const strings = localizationService.localizedStrings.insights.views.settings;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography variant="h4">{strings.completedBlackbaudSkyAuthorizationPartiallyTitle}</Typography>
      <Typography className="message" variant="subtitle1" align="center">
        {strings.completedBlackbaudSkyAuthorizationPartiallyMessage}
      </Typography>
      <Button variant="contained" color="primary" onClick={() => void data.navigateToExternalAccounts(navigate)}>
        {strings.returnToExternalAccountsButton}
      </Button>
    </Box>
  );
}

function renderSuccess(
  data: BlackbaudSkyAuthorizationRedirectViewModel,
  localizationService: LocalizationService,
  navigate: NavigateFunction
) {
  const strings = localizationService.localizedStrings.insights.views.settings;

  const nextStepStrings = strings.completedBlackbaudSkyAuthorizationNextStepsMessage.split('✂️');

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography variant="h4">{strings.completedBlackbaudSkyAuthorizationTitle}</Typography>
      <Typography className="message" variant="subtitle1" align="center">
        {strings.completedBlackbaudSkyAuthorizationMessage}
      </Typography>
      <Typography className="message" align="center">
        {nextStepStrings[0]}
        <SettingsIcon fontSize="small" />
        {nextStepStrings[1]}
        <MappingsIcon fontSize="small" />
        {nextStepStrings[2]}
      </Typography>
      <Button variant="contained" color="primary" onClick={() => void data.navigateToExternalAccounts(navigate)}>
        {strings.returnToExternalAccountsButton}
      </Button>
    </Box>
  );
}

const Root = styled(Box)(({ theme }) => ({
  '.message': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: theme.breakpoints.values.sm
  }
}));
