import { DayConfigurationModel, ScheduleModel, SpecialDayModel } from '@shared/models/config';
import { find } from 'lodash';
import { action, computed, makeObservable } from 'mobx';
import { CustomizableViewModel } from './CustomizableViewModel';
import { SchoolCalendarViewModel } from './SchoolCalendarViewModel';

export interface EditableDayCustomizationDialogViewModel {
  readonly customizable: CustomizableViewModel;
  readonly calendar: SchoolCalendarViewModel;
  readonly title: string;
  readonly hasDayConfigurations: boolean;
  readonly specialDays: SpecialDayModel[];
  readonly schedules: ScheduleModel[];

  isSpecialDayDeleted(specialDay: SpecialDayModel): boolean;
  isScheduleDeleted(schedule: ScheduleModel): boolean;
  isSpecialDayChanged(specialDay: SpecialDayModel): boolean;
  isScheduleChanged(schedule: ScheduleModel): boolean;
  addSpecialDay(specialDay: SpecialDayModel): void;
  addSchedule(schedule: ScheduleModel): void;
  removeDayConfiguration(dayConfiguration: DayConfigurationModel): void;
  save(): void;
  cancel(): void;
}

export class AppEditableDayCustomizationDialogViewModel implements EditableDayCustomizationDialogViewModel {
  readonly customizable: CustomizableViewModel;

  constructor(
    private readonly _originalCustomizable: CustomizableViewModel,
    private _onSuccess: () => void,
    private _onCancel: () => void
  ) {
    makeObservable(this);
    this.customizable = _originalCustomizable.clone();
  }

  @computed
  get calendar() {
    return this.customizable.calendar;
  }

  get title() {
    return this.customizable.longTitle;
  }

  @computed
  get hasDayConfigurations(): boolean {
    return this.customizable.hasCustomization;
  }

  @computed
  get specialDays(): SpecialDayModel[] {
    const allSpecialDays = this.calendar.editableConfig.allSpecialDays;
    return allSpecialDays.filter(
      (specialDay) =>
        !find(this.customizable.dayConfigurations, {
          specialDayId: specialDay.id
        })
    );
  }

  @computed
  get schedules(): ScheduleModel[] {
    const allSchedules = this.calendar.editableConfig.allSchedules;
    return allSchedules.filter((schedule) => !find(this.customizable.dayConfigurations, { scheduleId: schedule.id }));
  }

  isSpecialDayDeleted(specialDay: SpecialDayModel): boolean {
    return this.calendar.editableConfig.getIsSpecialDayDeleted(specialDay);
  }

  isScheduleDeleted(schedule: ScheduleModel): boolean {
    return this.calendar.editableConfig.getIsScheduleDeleted(schedule);
  }

  isSpecialDayChanged(specialDay: SpecialDayModel): boolean {
    return this.calendar.editableConfig.getIsSpecialDayChanged(specialDay);
  }

  isScheduleChanged(schedule: ScheduleModel): boolean {
    return this.calendar.editableConfig.getIsScheduleChanged(schedule);
  }

  addSpecialDay(specialDay: SpecialDayModel) {
    void this.customizable.applyCustomizations({
      specialDay
    });
  }

  addSchedule(schedule: ScheduleModel) {
    void this.customizable.applyCustomizations({ schedule });
  }

  removeDayConfiguration(dayConfiguration: DayConfigurationModel) {
    void this.customizable.applyCustomizations({
      scheduleIdToRemove: dayConfiguration.scheduleId.length > 0 ? dayConfiguration.scheduleId : undefined,
      specialDayIdToRemove: dayConfiguration.specialDayId.length > 0 ? dayConfiguration.specialDayId : undefined
    });
  }

  @action
  cancel() {
    this._onCancel();
  }

  @action
  save() {
    this._originalCustomizable.calendar.editableConfig.copyChangesFrom(this.calendar.editableConfig);
    void this._originalCustomizable.calendar.updateSchoolDays();
    this._onSuccess();
  }
}
