import { SectionMetrics as PBSectionMetrics } from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';
import { Memoize } from 'fast-typescript-memoize';
import { CourseOccurrenceTaskCounters } from './CourseOccurrenceTaskCounters';

export class SectionMetrics {
  private _pb: PBSectionMetrics;

  constructor(pb: PBSectionMetrics) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  get sectionId(): string {
    return this._pb.sectionId;
  }

  get studentCount(): number {
    return this._pb.studentCount;
  }

  @Memoize()
  get tasksByCourseOccurrence(): CourseOccurrenceTaskCounters[] {
    return this._pb.tasksByCourseOccurrence.map((t) => new CourseOccurrenceTaskCounters(t));
  }
}
