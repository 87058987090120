import {
  Column,
  Expanded,
  InsightsButton,
  Row,
  SectionStudentsEditionIdsSelection,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Dialog, DialogActions, DialogContent, DialogTitle, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SectionStudentsEditionIdsSelectionDialogProps extends DialogResult<string[]> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const SectionStudentsEditionIdsSelectionDialog = observer(
  (props: SectionStudentsEditionIdsSelectionDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { sx = [], style, className, onCancel, onSuccess } = props;
    const strings = localizationService.localizedStrings.insights.views.metrics.sections;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createSectionStudentsEditionIdsSelection(onSuccess!, onCancel!)
    );

    const close = () => viewModel.close();

    return (
      <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
        <Dialog sx={sx} className={className} style={style} open={true} maxWidth="xs" fullWidth={true} onClose={close}>
          <DialogTitle sx={{ pb: 0 }}>{strings.sectionSelection}</DialogTitle>

          <DialogContent>
            <SectionStudentsEditionIdsSelection sx={{ height: '100%' }} viewModel={viewModel} />
          </DialogContent>

          <DialogActions>
            <Column>
              <Row verticalContentAlignment="center">
                <Expanded />
                <InsightsButton sx={{ m: 1 }} onClick={close}>
                  {strings.cancel}
                </InsightsButton>
                <InsightsButton
                  sx={{ m: 1 }}
                  onClick={() => viewModel.proceed()}
                  isDisabled={viewModel.parsedIds.length === 0}
                  isDefault
                >
                  {strings.addStudentsFromIdsConfirm}
                </InsightsButton>
              </Row>
            </Column>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
);
