import { SettingsStore } from '@insights/services';
import { SchoolYearConfigurationSummary } from '@shared/models/config';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface SchoolConfigurationViewModel {
  readonly configId: string;
  readonly data: IPromiseBasedObservable<SchoolYearConfigurationSummary>;

  demoMode: boolean;
}

export class AppSchoolConfigurationViewModel implements SchoolConfigurationViewModel {
  constructor(
    private readonly schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly settingsStore: SettingsStore,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<SchoolYearConfigurationSummary> {
    return fromPromise(this.schoolYearConfigurationStore.getConfigSummary(this.configId));
  }

  @computed
  get demoMode(): boolean {
    return this.settingsStore.demoMode;
  }

  set demoMode(value: boolean) {
    this.settingsStore.demoMode = value;
  }
}
