import * as BPB from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_sky_pb';
import { NavigationService } from '@insights/services';
import {
  BlackbaudSkyAssignmentTypeMappingModel,
  BlackbaudSkyExternalAccountDetails,
  EditableBlackbaudSkyAssignmentTypeMapping,
  EditableBlackbaudSkyContentRepresentation
} from '@shared/models/connectors';
import { FullyEditableListProperty } from '@shared/models/editables';
import { ContentIcon, ContentWorkloadLevel } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { BlackbaudSkyConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface BlackbaudSkyAccountMappingsViewModel extends ExternalAccountEditionViewModel {
  isIgnoringUnmappedTypes: boolean;
  readonly mappings: EditableBlackbaudSkyAssignmentTypeMapping[];

  // We do not have access to all assignment types, so we only support adding names.
  addMapping(typeName: string): void;
  setIcon(mapping: EditableBlackbaudSkyAssignmentTypeMapping, icon?: ContentIcon): void;
  setWorkloadLevel(mapping: EditableBlackbaudSkyAssignmentTypeMapping, workloadLevel: ContentWorkloadLevel): void;
}

export interface BlackbaudSkyAccountMappingsDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly mappings: IPromiseBasedObservable<BlackbaudSkyAccountMappingsViewModel>;
}

export class AppBlackbaudSkyAccountMappingsViewModel
  extends BaseExternalAccountEditionViewModel
  implements BlackbaudSkyAccountMappingsViewModel
{
  @observable private _isIgnoringUnmappedTypes?: boolean;

  // We use an editable field to hold changes, but it doesn't have a host to apply to.
  private _mappings: FullyEditableListProperty<
    BPB.AssignmentTypeMapping,
    BlackbaudSkyAssignmentTypeMappingModel,
    EditableBlackbaudSkyAssignmentTypeMapping,
    unknown
  >;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _blackbaudSkyStore: BlackbaudSkyConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    private readonly _originalDetails: BlackbaudSkyExternalAccountDetails
  ) {
    super();
    makeObservable(this);
    this._mappings = new FullyEditableListProperty(
      _originalDetails.mappings.map((m) => new EditableBlackbaudSkyAssignmentTypeMapping(m)),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }

  @computed
  get isIgnoringUnmappedTypes() {
    return this._isIgnoringUnmappedTypes ?? this._originalDetails.ignoreUnmappedAssignmentTypes;
  }

  set isIgnoringUnmappedTypes(value: boolean) {
    this._isIgnoringUnmappedTypes = value;
    this.onChange();
  }

  @computed
  get mappings() {
    return this._mappings.values;
  }

  @action
  addMapping(typeName: string) {
    this._mappings.addItem(EditableBlackbaudSkyAssignmentTypeMapping.createNew(typeName));
  }

  @action
  setIcon(mapping: EditableBlackbaudSkyAssignmentTypeMapping, icon?: ContentIcon) {
    if (mapping.representation == null) {
      if (icon == null) {
        return;
      }

      mapping.representation = EditableBlackbaudSkyContentRepresentation.createNew(
        icon,
        this.getDefaultWorkloadLevel(icon)
      );
    } else if (icon == null) {
      mapping.representation = undefined;
    } else {
      mapping.editableRepresentation.icon = icon;
    }
  }

  @action
  setWorkloadLevel(mapping: EditableBlackbaudSkyAssignmentTypeMapping, workloadLevel: ContentWorkloadLevel) {
    if (mapping.representation == null) {
      console.error('It should be impossible to set a workload without setting an icon first');
      mapping.representation = EditableBlackbaudSkyContentRepresentation.createNew('homework', workloadLevel);
    } else {
      mapping.editableRepresentation.workloadLevel = workloadLevel;
    }
  }

  get hasChanges() {
    return this._hasChanges || this._mappings.isChanged;
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this.hasChanges) {
      console.error('Applying without changes. Ignoring...');
      this._onSuccess();
      return;
    }

    this.beginApplying();

    try {
      await this._blackbaudSkyStore.updateSettings(
        this._configId,
        this._externalAccountId,
        this.mappings,
        this.isIgnoringUnmappedTypes
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._mappings.reset();
    this._isIgnoringUnmappedTypes = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this.hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }

  private getDefaultWorkloadLevel(icon: ContentIcon): ContentWorkloadLevel {
    switch (icon) {
      case 'exam':
        return 'major';
      case 'minitest':
        return 'medium';
      case 'reminder':
        return 'none';
      default:
        return 'regular';
    }
  }
}

export class AppBlackbaudSkyAccountMappingsDialogViewModel implements BlackbaudSkyAccountMappingsDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _blackbaudSkyStore: BlackbaudSkyConnectorStore,
    private readonly _navigationService: NavigationService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string
  ) {
    makeObservable(this);
  }

  @computed
  get mappings(): IPromiseBasedObservable<BlackbaudSkyAccountMappingsViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<BlackbaudSkyAccountMappingsViewModel> {
    const details = await this._blackbaudSkyStore.getAccountDetails(this.externalAccountId);

    return new AppBlackbaudSkyAccountMappingsViewModel(
      this._localizationService,
      this._blackbaudSkyStore,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      details
    );
  }
}
