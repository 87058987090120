import { SchoolYearConfiguration_DayConfiguration as PBDayConfiguration } from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { computed, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import {
  EditableModelEx,
  EditableNullableDayPropertyEx,
  EditableNullableValuePropertyEx,
  EditableStringProperty
} from '../editables';
import { Day, DayOfWeek } from '../types';
import { protobufFromDayOfWeek } from '../types/EnumConversion';
import { DayConfiguration, DayConfigurationModel } from './DayConfiguration';
import { ScheduleModel } from './Schedule';
import { SpecialDayModel } from './SpecialDay';

export class EditableDayConfiguration extends EditableModelEx<PBDayConfiguration> implements DayConfigurationModel {
  private _scheduleId: EditableStringProperty<PBDayConfiguration>;
  private _specialDayId: EditableStringProperty<PBDayConfiguration>;
  private _dayOfWeek: EditableNullableValuePropertyEx<DayOfWeek, PBDayConfiguration>;
  private _cycleDay: EditableNullableValuePropertyEx<number, PBDayConfiguration>;
  private _day: EditableNullableDayPropertyEx<PBDayConfiguration>;

  static createWithSpecialDayForDay(day: Day, specialDay: SpecialDayModel) {
    const pb = new PBDayConfiguration();
    pb.id = uuidv4();
    pb.appliesTo = { case: 'day', value: day.asPB };
    pb.specialDayId = specialDay.id;

    return new EditableDayConfiguration(new DayConfiguration(pb), true);
  }

  static createWithSpecialDayForDayOfWeek(dayOfWeek: DayOfWeek, specialDay: SpecialDayModel) {
    const pb = new PBDayConfiguration();
    pb.id = uuidv4();
    pb.appliesTo = { case: 'dayOfWeek', value: protobufFromDayOfWeek(dayOfWeek) };
    pb.specialDayId = specialDay.id;

    return new EditableDayConfiguration(new DayConfiguration(pb), true);
  }

  static createWithSpecialDayForCycleDay(cycleDay: number, specialDay: SpecialDayModel) {
    const pb = new PBDayConfiguration();
    pb.id = uuidv4();
    pb.appliesTo = { case: 'cycleDay', value: cycleDay };
    pb.specialDayId = specialDay.id;

    return new EditableDayConfiguration(new DayConfiguration(pb), true);
  }

  static createWithScheduleForDay(day: Day, schedule: ScheduleModel) {
    const pb = new PBDayConfiguration();
    pb.id = uuidv4();
    pb.appliesTo = { case: 'day', value: day.asPB };
    pb.scheduleId = schedule.id;

    return new EditableDayConfiguration(new DayConfiguration(pb), true);
  }

  static createWithScheduleForDayOfWeek(dayOfWeek: DayOfWeek, schedule: ScheduleModel) {
    const pb = new PBDayConfiguration();
    pb.id = uuidv4();
    pb.appliesTo = { case: 'dayOfWeek', value: protobufFromDayOfWeek(dayOfWeek) };
    pb.scheduleId = schedule.id;

    return new EditableDayConfiguration(new DayConfiguration(pb), true);
  }

  static createWithScheduleForCycleDay(cycleDay: number, schedule: ScheduleModel) {
    const pb = new PBDayConfiguration();
    pb.id = uuidv4();
    pb.appliesTo = { case: 'cycleDay', value: cycleDay };
    pb.scheduleId = schedule.id;

    return new EditableDayConfiguration(new DayConfiguration(pb), true);
  }

  static cloneAsNew(original: DayConfigurationModel): EditableDayConfiguration {
    const pb = original.toProtobuf();
    pb.id = uuidv4();

    return new EditableDayConfiguration(new DayConfiguration(pb), true);
  }

  constructor(
    private readonly _originalDayConfiguration: DayConfigurationModel,
    isNew = false
  ) {
    super(_originalDayConfiguration.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._scheduleId = new EditableStringProperty(
        _originalDayConfiguration.scheduleId,
        (pb, value) => (pb.scheduleId = value),
        {
          trim: true
        }
      )),
      (this._specialDayId = new EditableStringProperty(
        _originalDayConfiguration.specialDayId,
        (pb, value) => (pb.specialDayId = value),
        {
          trim: true
        }
      )),
      (this._dayOfWeek = new EditableNullableValuePropertyEx(_originalDayConfiguration.dayOfWeek, (pb, value) => {
        if (value == null) {
          pb.appliesTo = { case: undefined, value: undefined };
        } else {
          pb.appliesTo = { case: 'dayOfWeek', value: protobufFromDayOfWeek(value) };
        }
      })),
      (this._cycleDay = new EditableNullableValuePropertyEx(_originalDayConfiguration.cycleDay, (pb, value) => {
        if (value == null) {
          pb.appliesTo = { case: undefined, value: undefined };
        } else {
          pb.appliesTo = { case: 'cycleDay', value: value };
        }
      })),
      (this._day = new EditableNullableDayPropertyEx(_originalDayConfiguration.day, (pb, value) => {
        if (value == null) {
          pb.appliesTo = { case: undefined, value: undefined };
        } else {
          pb.appliesTo = { case: 'day', value: value.asPB };
        }
      }))
    ]);
  }

  get id(): string {
    return this._originalDayConfiguration.id;
  }

  @computed
  get scheduleId(): string {
    return this._scheduleId.value;
  }

  set scheduleId(value: string) {
    this._scheduleId.value = value;
  }

  @computed
  get specialDayId(): string {
    return this._specialDayId.value;
  }

  set specialDayId(value: string) {
    this._specialDayId.value = value;
  }

  @computed
  get dayOfWeek(): DayOfWeek | undefined {
    return this._dayOfWeek.value;
  }

  set dayOfWeek(value: DayOfWeek | undefined) {
    this._dayOfWeek.value = value;
  }

  @computed
  get cycleDay(): number | undefined {
    return this._cycleDay.value;
  }

  set cycleDay(value: number | undefined) {
    this._cycleDay.value = value;
  }

  @computed
  get day(): Day | undefined {
    return this._day.value;
  }

  set day(value: Day | undefined) {
    this._day.value = value;
  }
}
