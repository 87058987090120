import { ImportSessionFileManualLinkDialogViewModel } from '@insights/viewmodels';
import { Box, SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface ImportSessionFileManualLinkProps {
  viewModel: ImportSessionFileManualLinkDialogViewModel;
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const ImportSessionFileManualLink = observer((props: ImportSessionFileManualLinkProps) => {
  const { localizationService } = useInsightsServices();
  const { sx = [], className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <Box sx={sx} className={className} style={style}>
      <TextField
        fullWidth
        label={strings.fileUrlLabel}
        value={viewModel.link}
        onChange={(e) => (viewModel.link = e.target.value)}
      />
    </Box>
  );
});
