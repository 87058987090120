import { SourceData, SourceFile, SourceRow, Transformation } from '@shared/models/import';

export interface ImportSessionSourceDataViewModel {
  readonly label: string;
  readonly title: string;
  readonly rows: SourceRow[];
  readonly columnNames: string[];
  readonly isTextFile: boolean;
}

export class AppImportSessionSourceDataViewModel implements ImportSessionSourceDataViewModel {
  constructor(
    private readonly _data: SourceData,
    private readonly _file?: SourceFile,
    private readonly _transformation?: Transformation
  ) {}

  get rows(): SourceRow[] {
    return this._data.rows;
  }

  get columnNames(): string[] {
    return this._data.columnNames;
  }

  get label(): string {
    return this._data.label;
  }

  get title(): string {
    return this._file?.name ?? this._transformation?.name ?? '';
  }

  get isTextFile(): boolean {
    return this._file?.kind === 'text';
  }
}
