import * as MO from '@buf/studyo_studyo.bufbuild_es/studyo/type_moodle_pb';
import { MoodleExternalAccountDetails } from './interfaces/MoodleExternalAccountDetails';

export class GrpcMoodleExternalAccountDetails implements MoodleExternalAccountDetails {
  constructor(private readonly pb: MO.AccountDetails) {}

  get serverUrl() {
    return this.pb.serverUrl;
  }

  get accessToken() {
    return this.pb.accessToken;
  }
}
