import { ConfigGlobalStats_ActiveUserStats as PBActiveUserStats } from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';

export class ActiveUserStats {
  private _pb: PBActiveUserStats;

  constructor(pb: PBActiveUserStats) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  get lastWeek(): number {
    return this._pb.lastWeek;
  }

  get previousWeek(): number {
    return this._pb.previousWeek;
  }

  get lastMonth(): number {
    return this._pb.lastMonth;
  }

  get previousMonth(): number {
    return this._pb.previousMonth;
  }

  get currentSchoolYear(): number {
    return this._pb.currentSchoolYear;
  }

  //
  // Calculated Properties
  //

  get lastWeekVariation(): number {
    return getPercentVariation(this.lastWeek, this.previousWeek);
  }

  get lastMonthVariation(): number {
    return getPercentVariation(this.lastMonth, this.previousMonth);
  }
}

function getPercentVariation(current: number, previous: number): number {
  // When 'previous' is 0, return a 0% variation instead of a gigantic number
  return previous != 0 ? Math.round(((current - previous) / previous) * 100) : 0;
}
