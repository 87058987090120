import {
  AddAttachmentsImages,
  DocumentTypesImages,
  ExternalImages,
  IconImages,
  OverlaysImages,
  TaskGenericImages,
  TasksImages
} from '@shared/resources/images/studyo';
import { WebAddAttachmentsImages } from './WebAddAttachmentsImages';
import { WebDocumentTypesImages } from './WebDocumentTypesImages';
import { WebExternalImages } from './WebExternalImages';
import { WebOverlaysImages } from './WebOverlaysImages';
import { WebPrivateIconImages } from './WebPrivateIconImages';
import { WebRegularIconImages } from './WebRegularIconImages';
import { WebTaskGenericImages } from './WebTaskGenericImages';

export class WebTasksImages implements TasksImages {
  private readonly _generic = new WebTaskGenericImages();
  private readonly _external = new WebExternalImages();
  private readonly _privateIcons = new WebPrivateIconImages();
  private readonly _regularIcons = new WebRegularIconImages();
  private readonly _overlays = new WebOverlaysImages();
  private readonly _documentTypes = new WebDocumentTypesImages();
  private readonly _addAttachments = new WebAddAttachmentsImages();

  get generic(): TaskGenericImages {
    return this._generic;
  }

  get external(): ExternalImages {
    return this._external;
  }

  get privateIcons(): IconImages {
    return this._privateIcons;
  }

  get regularIcons(): IconImages {
    return this._regularIcons;
  }

  get overlays(): OverlaysImages {
    return this._overlays;
  }

  get documentTypes(): DocumentTypesImages {
    return this._documentTypes;
  }

  get addAttachments(): AddAttachmentsImages {
    return this._addAttachments;
  }
}
