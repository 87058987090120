import * as CC from '@buf/studyo_studyo.bufbuild_es/studyo/type_canvas_pb';
import { CanvasSubAccount } from './interfaces';

export class GrpcCanvasSubAccount implements CanvasSubAccount {
  constructor(private readonly pb: CC.Account) {}

  get id() {
    return this.pb.id;
  }

  get name() {
    return this.pb.name;
  }
}
