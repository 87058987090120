import { PeriodDisplay, activeUsersPeriodFromPeriodDisplay } from '@insights/enums';
import { SettingsStore } from '@insights/services';
import { ConfigGlobalStats, DataPoint } from '@shared/models/metrics';
import { Day } from '@shared/models/types/Day';
import { MetricsStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface ActiveTeachersData {
  hasSchoolStarted: boolean;
  configGlobalStats: ConfigGlobalStats;
  activeTeachersPublished: DataPoint[];
  activeTeachersAny: DataPoint[];
}

export interface ActiveTeachersViewModel {
  readonly configId: string;
  readonly periodDisplay: PeriodDisplay;

  readonly data: IPromiseBasedObservable<ActiveTeachersData>;
}

export class AppActiveTeachersViewModel implements ActiveTeachersViewModel {
  constructor(
    private readonly metricsStore: MetricsStore,
    private readonly configStore: SchoolYearConfigurationStore,
    private readonly settingsStore: SettingsStore,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<ActiveTeachersData> {
    return fromPromise(this.loadData());
  }

  @computed
  get periodDisplay(): PeriodDisplay {
    return this.settingsStore.periodDisplay;
  }

  private async loadData(): Promise<ActiveTeachersData> {
    const periodDisplay = this.settingsStore.periodDisplay;

    const result = await Promise.all([
      this.metricsStore.getConfigGlobalStats(this.configId),
      this.metricsStore.getActiveTeachersPublished(this.configId, activeUsersPeriodFromPeriodDisplay(periodDisplay)),
      this.metricsStore.getActiveTeachersAny(this.configId, activeUsersPeriodFromPeriodDisplay(periodDisplay)),
      this.configStore.getConfigSummary(this.configId)
    ]);

    return {
      hasSchoolStarted: result[3].startDay.isSameOrBefore(Day.fromDate(new Date())!),
      configGlobalStats: result[0],
      activeTeachersPublished: result[1],
      activeTeachersAny: result[2]
    };
  }
}
