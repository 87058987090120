import {
  ImportSessionFileDirectData,
  InsightsButton,
  ObservablePresenter,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider,
  Typography
} from '@mui/material';
import { ImportSession } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ImportSessionFileDirectDataDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  session: ImportSession;
  fileLabel: string;
  className?: string;
  style?: CSSProperties;
}

export const ImportSessionFileDirectDataDialog = observer((props: ImportSessionFileDirectDataDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { style, className, sx = [], session, fileLabel, onSuccess, configId, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createImportSessionFileDirectDataLink(configId, session, fileLabel, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="md"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <DialogTitle>{strings.linkFileToConfigurationTitle}</DialogTitle>

          <DialogContent dividers>
            <ObservablePresenter
              data={viewModel.data}
              render={(data) => <ImportSessionFileDirectData viewModel={data} />}
            />
          </DialogContent>

          <DialogActions>
            {viewModel.hasError && (
              <Typography
                flex={1}
                variant="caption"
                sx={{ color: (theme) => theme.palette.error.main }}
                style={{ textAlign: 'end' }}
              >
                {strings.errorMessage}
              </Typography>
            )}
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => void viewModel.cancel()}>
              {strings.cancel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={!viewModel.canSave}
              isExecuting={viewModel.isExecuting}
              onClick={() => void viewModel.save()}
            >
              {strings.save}
            </InsightsButton>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
});
