import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider,
  Typography
} from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { InsightsButton } from './InsightsButton';

export interface MessageDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  title?: string;
  message: string;
  okButtonCaption?: string;
}

export const MessageDialog = observer((props: MessageDialogProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], style, onCancel, onSuccess, title, message, okButtonCaption } = props;
  const strings = localizationService.localizedStrings.insights.components.messageDialog;

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog
        sx={sx}
        open={true}
        onClose={() => onCancel!()}
        maxWidth="sm"
        fullWidth={true}
        className={className}
        style={style}
      >
        <DialogTitle>{title ?? strings.defaultTitle}</DialogTitle>

        <DialogContent>
          <Box>
            <Typography whiteSpace="pre-line">{message}</Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <InsightsButton isDefault onClick={() => onSuccess!()}>
            {okButtonCaption ?? strings.defaultOkButtonCaption}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
