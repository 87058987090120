import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  Typography,
  styled
} from '@mui/material';
import { CSSProperties, ReactNode } from 'react';
import { InsightsButton } from '../InsightsButton';

export interface LoadingContainerProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  isOpen: boolean;
  isBusy: boolean;
  title?: string;
  errorMessage?: string;
  onDismissError: () => void;
}

export function LoadingContainer(props: LoadingContainerProps) {
  const { isOpen, isBusy, title, errorMessage, onDismissError, sx } = props;

  return (
    <Root sx={sx} className={props.className} style={props.style}>
      <div className="innerContainer">{props.children}</div>
      <Dialog open={isOpen}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          {isBusy && <CircularProgress />}
          {errorMessage && <Typography variant="h6">{errorMessage}</Typography>}
        </DialogContent>
        <DialogActions>
          {errorMessage && <InsightsButton onClick={onDismissError}>Dismiss</InsightsButton>}
        </DialogActions>
      </Dialog>
    </Root>
  );
}

/**
 * Allows adjusting the `margin` and `padding` of the child element. This component
 * only takes 1 child and it will be forced to take all of the available space.
 */
const Root = styled(Box)(() => ({
  '.innerContainer': {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 0,
    '& >:first-of-type': {
      flex: 1
    }
  },
  '.progressContainer': {
    position: 'absolute',
    width: 300,
    height: 200,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto'
  },
  '.progress': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto'
  },
  '.message': {
    position: 'relative',
    textAlign: 'center',
    fontWeight: 300
  }
}));
