import {
  GrpcManageBacExternalAccountDetails,
  ManageBacAssignmentKindMappingModel,
  ManageBacExternalAccountDetails
} from '@shared/models/connectors';
import { ManageBacTransport } from '../../../transports';
import { ConnectorsStore, ManageBacConnectorStore } from '../../interfaces';
import { AppBaseStore } from '../AppBaseStore';

export class AppManageBacConnectorStore extends AppBaseStore implements ManageBacConnectorStore {
  constructor(
    private readonly _transport: ManageBacTransport,
    connectorStore: ConnectorsStore
  ) {
    super('AppManageBacConnectorStore', false, connectorStore);
  }

  async getManageBacAccountDetails(externalAccountId: string): Promise<ManageBacExternalAccountDetails> {
    const details = await this._transport.fetchManageBacExternalAccountDetails(externalAccountId);

    return new GrpcManageBacExternalAccountDetails(details.accountDetails);
  }

  async createOrUpdateManageBacAccount(
    configId: string,
    externalAccountId: string,
    accountName: string,
    serverUrl: string,
    authToken: string
  ): Promise<string> {
    const response = await this._transport.createOrUpdateManageBacExternalAccount(
      externalAccountId,
      configId,
      serverUrl,
      authToken,
      accountName
    );
    this.invalidate();

    return response.externalAccountId;
  }

  async updateManageBacAccountSettings(
    configId: string,
    externalAccountId: string,
    termIds: bigint[],
    assignmentKindMappings: ManageBacAssignmentKindMappingModel[],
    isIgnoringUnmappedKinds: boolean
  ): Promise<void> {
    await this._transport.updateManageBacExternalAccountSettings(
      externalAccountId,
      configId,
      termIds,
      assignmentKindMappings.map((akm) => akm.toProtobuf()),
      isIgnoringUnmappedKinds
    );
    this.invalidate();
  }
}
