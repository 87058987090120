export interface AccountComponentsStrings {
  accountLegendLabel: string;
  accountLegendTooltipLines: string[];
  associationTitle: string;
  autoEnrolled: string;
  availableSections: string;
  availableStudents: string;
  behavioursTitle: string;
  cancel: string;
  canManageSchool: string;
  children: string;
  connectedUserLabel: string;
  connectedUserTooltip: string;
  copyId: string;
  copyIdNotification: string;
  deleteAccountButtonTitle: string;
  deleteAccountConfirmationMessage: string;
  deleteAccountConfirmationNo: string;
  deleteAccountConfirmationTitle: string;
  deleteAccountConfirmationYes: string;
  deleteAccountErrorMessage: string;
  deleteAccountErrorTitle: string;
  deletedAccountTooltip: string;
  editChildren: string;
  editDetails: string;
  editSections: string;
  email: string;
  exportToCsv: string;
  filterTooltip: string;
  firstName: string;
  grade: string;
  gradeTitle: string;
  group: string;
  groupTitle: string;
  hasAutoEnrollment: string;
  importIdentifier: string;
  isLockedLabel: string;
  language: string;
  lastName: string;
  manageTeacherPlanning: string;
  missingSectionWarning: string;
  modify: string;
  modifyPeriodTitle: string;
  moveCurrentRoomNameLabel: string;
  moveCurrentSkip: string;
  moveCurrentTitleLabel: string;
  moveCurrentTitlePlaceholder: (defaultTitle: string) => string;
  moveCurrentUnskip: string;
  moveTitlesLeft: string;
  moveTitlesLeftNotice: string;
  moveTitlesLeftUntil: string;
  moveTitlesLeftWarning: string;
  moveTitlesRight: string;
  moveTitlesRightNotice: string;
  moveTitlesRightUntil: string;
  moveTitlesRightWarning: string;
  moveUntilEndOfSchoolNotice: string;
  name: string;
  none: string;
  noneF: string;
  noneLabel: string;
  optedOutOfParentReports: string;
  parents: string;
  pendingChildTooltip: string;
  preferredScheduleTag: string;
  privateFirstName: string;
  privateLastName: string;
  privateNotice: string;
  publicEmail: string;
  publicEmailPlaceholder: string;
  role: string;
  section: string;
  sections: string;
  sectionsEditionTitle: string;
  selectedChildren: string;
  selectedSections: string;
  teacherSelectedSectionsWarning: string;
  teacherSelectedSectionsWarning2: string;
  teacherSelectedSectionsWarningTitle: string;
  title: string;
  undeleteAccountButtonTitle: string;
  undeleteAccountErrorMessage: string;
  undeleteAccountErrorTitle: string;
  unknownSection: string;
  userIdPrompt: string;
  viewPlanner: string;

  deleteAccountDemoErrorMessage: (error: Error) => string;
  getAddAccountTooltip: (accountType: string) => string;
  getThresholdHelperText: (disabledThresholdValue: number) => string;
  undeleteAccountDemoErrorMessage: (error: Error) => string;
}

export const EnglishAccountComponentsStrings: AccountComponentsStrings = {
  accountLegendLabel: 'Legend',
  accountLegendTooltipLines: ['Sections in blue are locked', 'Sections in grey are auto-enrolled'],
  associationTitle: 'Assoc.',
  autoEnrolled: 'Auto-enrolled',
  availableSections: 'Available sections',
  availableStudents: 'Available students',
  behavioursTitle: 'Behaviours',
  cancel: 'Cancel',
  canManageSchool: 'Can manage this school',
  children: 'Children',
  connectedUserLabel: 'Connected user',
  connectedUserTooltip: 'Connected user',
  copyId: 'Copy ID to clipboard',
  copyIdNotification: 'The ID was copied to the clipboard!',
  deleteAccountButtonTitle: 'Delete this account',
  deleteAccountConfirmationMessage: 'Are you sure you want to delete this account?',
  deleteAccountConfirmationNo: 'No',
  deleteAccountConfirmationTitle: 'Confirm',
  deleteAccountConfirmationYes: 'Yes',
  deleteAccountErrorMessage: 'An error occurred while deleting this account. Please try again.',
  deleteAccountErrorTitle: 'Oops, something went wrong!',
  deletedAccountTooltip: 'Deleted account',
  editChildren: 'Edit children',
  editDetails: 'Edit details',
  editSections: 'Edit sections',
  email: 'Email',
  exportToCsv: 'Export as CSV',
  filterTooltip: 'Filter the list',
  firstName: 'First Name',
  grade: 'grade',
  gradeTitle: 'Grade',
  group: 'group',
  groupTitle: 'Group',
  hasAutoEnrollment:
    "This section uses auto-enrollment. You probably don't need to add it explicitly. This is only required in particular cases.",
  importIdentifier: 'Import Identifier (SIS code)',
  isLockedLabel: 'Locked (cannot be deleted when importing data)',
  language: 'Language',
  lastName: 'LastName',
  manageTeacherPlanning: 'Manage schedule',
  missingSectionWarning:
    'At least one selected section does not exist anymore. Proceeding to save will fix this issue by removing the unknown sections from the account.',
  modify: 'Modify',
  modifyPeriodTitle: 'Modify Period',
  moveCurrentRoomNameLabel: 'Room name',
  moveCurrentSkip: 'Mark this period as skipped',
  moveCurrentTitleLabel: 'Period title',
  moveCurrentTitlePlaceholder: (defaultTitle) => `The default title would be '${defaultTitle}'`,
  moveCurrentUnskip: 'Mark this period as active',
  moveTitlesLeft: 'Move titles to their previous period',
  moveTitlesLeftNotice:
    'This period will not be marked as skipped anymore, and will receive the title of the next active period and so forth. Any other skipped period remains unaffected.',
  moveTitlesLeftUntil: 'From the next period until',
  moveTitlesLeftWarning: "ONLY USE THIS IF THE TEACHER IS AWARE. This can affect the teacher's planning.",
  moveTitlesRight: 'Move titles to their next period',
  moveTitlesRightNotice: 'Any skipped period remains unaffected.',
  moveTitlesRightUntil: 'From this period until',
  moveTitlesRightWarning: "ONLY USE THIS IF THE TEACHER IS AWARE. This can affect the teacher's planning.",
  moveUntilEndOfSchoolNotice: '(end of school)',
  name: 'Name',
  none: 'None',
  noneF: 'None',
  noneLabel: '(none)',
  optedOutOfParentReports: 'Opted out of parent reports',
  parents: 'Parents',
  pendingChildTooltip: 'The parent did not accept the invitation yet.',
  preferredScheduleTag: 'Preferred schedule tag for free periods',
  privateFirstName: 'Profile First Name',
  privateLastName: 'Profile Last Name',
  privateNotice: '(these values are only visible to that user)',
  publicEmail: 'Visible email',
  publicEmailPlaceholder: 'Using default email when empty',
  role: 'Role',
  section: 'Section',
  sections: 'Sections',
  sectionsEditionTitle: 'Section selection for ',
  selectedChildren: 'Children',
  selectedSections: 'Selected sections',
  teacherSelectedSectionsWarning:
    'You are NOT editing taught sections. You are assigning sections to a teacher as if they were STUDENTS.',
  teacherSelectedSectionsWarning2: 'To add teachers to a section, go to that section and select "Edit teachers".',
  teacherSelectedSectionsWarningTitle: 'Warning!',
  title: 'Title',
  undeleteAccountButtonTitle: 'Undelete this account',
  undeleteAccountErrorMessage: 'An error occurred while undeleting this account. Please try again.',
  undeleteAccountErrorTitle: 'Oops, something went wrong!',
  unknownSection: '[Unknown section]',
  userIdPrompt: 'Please enter the new user ID:',
  viewPlanner: 'View planner',

  deleteAccountDemoErrorMessage: (error) => `Deleting an account is not allowed in demo mode: ${error.message}`,
  getAddAccountTooltip: (accountType) => `Add ${accountType}`,
  getThresholdHelperText: (disabledThresholdValue) => `Set the values to ${disabledThresholdValue} to disable`,
  undeleteAccountDemoErrorMessage: (error) => `Undeleting an account is not allowed in demo mode: ${error.message}`
};

export const FrenchAccountComponentsStrings: AccountComponentsStrings = {
  accountLegendLabel: 'Légende',
  accountLegendTooltipLines: [
    'Les groupes-matière en bleu sont verrouillés',
    'Les groupes-matière en gris sont auto-inscrits'
  ],
  associationTitle: 'Assoc.',
  autoEnrolled: 'Auto-inscrit',
  availableSections: 'Groupes-matière disponibles',
  availableStudents: 'Élèves disponibles',
  behavioursTitle: 'Comportements',
  cancel: 'Annuler',
  canManageSchool: 'Peut gérer cette école',
  children: 'Enfants',
  connectedUserLabel: 'Utilisateur lié',
  connectedUserTooltip: 'Utilisateur lié',
  copyId: 'Copier l’identifiant',
  copyIdNotification: 'L’identifiant est copié dans le presse-papier!',
  deleteAccountButtonTitle: 'Supprimer cette fiche',
  deleteAccountConfirmationMessage: 'Êtes-vous certain de vouloir supprimer cette fiche?',
  deleteAccountConfirmationNo: 'Non',
  deleteAccountConfirmationTitle: 'Confirmation',
  deleteAccountConfirmationYes: 'Oui',
  deleteAccountErrorMessage: 'Cette fiche n’a pu être supprimée. Veuillez réessayer.',
  deleteAccountErrorTitle: 'Oops, une erreur est survenue!',
  deletedAccountTooltip: 'Fiche supprimée',
  editChildren: 'Modifier les enfants',
  editDetails: 'Modifier les détails',
  editSections: 'Modifier les groupes-matière',
  email: 'Courriel',
  exportToCsv: 'Exporter en CSV',
  filterTooltip: 'Filtrer la liste',
  firstName: 'Prénom',
  grade: 'niveau',
  gradeTitle: 'Niveau',
  group: 'groupe',
  groupTitle: 'Groupe',
  hasAutoEnrollment:
    'Ce groupe-matière utilise l’auto-abonnement. Vous n’avez probablement pas besoin de l’ajouter explicitement. Ceci n’est requis que dans des cas particuliers.',
  importIdentifier: 'Identifiant d’importation (code SIS)',
  isLockedLabel: 'Verrouillée (ne peut être effacée par une importation)',
  language: 'Langue',
  lastName: 'Nom',
  manageTeacherPlanning: 'Gérer l’horaire',
  missingSectionWarning:
    'Au moins un groupe-matière sélectionné n’existe plus. L’enregistrement résoudra ce problème en supprimant les groupes-matière inconnus de la fiche.',
  modify: 'Modifier',
  modifyPeriodTitle: 'Modifier la période',
  moveCurrentRoomNameLabel: 'Local',
  moveCurrentSkip: 'Marquer cette période comme sautée',
  moveCurrentTitleLabel: 'Titre de période',
  moveCurrentTitlePlaceholder: (defaultTitle) => `Le titre par défaut serait '${defaultTitle}'`,
  moveCurrentUnskip: 'Marquer cette période comme active',
  moveTitlesLeft: 'Décaler les titres vers leur période précédente respective',
  moveTitlesLeftNotice:
    'Cette période ne sera plus marquée comme sautée et héritera du titre de la prochaine période active. Toute autre période sautée demeurera inchangée.',
  moveTitlesLeftUntil: 'À partir de la période suivante, jusqu’au',
  moveTitlesLeftWarning:
    'N’UTILISEZ CECI QU’EN ACCORD AVEC L’ENSEIGNANT. Ceci peut affecter sa planification de cours.',
  moveTitlesRight: 'Décaler les titres vers leur prochaine période respective',
  moveTitlesRightNotice: 'Toute période sautée demeurera inchangée',
  moveTitlesRightUntil: 'À partir de cette période, jusqu’au',
  moveTitlesRightWarning:
    'N’UTILISEZ CECI QU’EN ACCORD AVEC L’ENSEIGNANT. Ceci peut affecter sa planification de cours.',
  moveUntilEndOfSchoolNotice: '(fin des classes)',
  name: 'Nom',
  none: 'Aucun',
  noneF: 'Aucune',
  noneLabel: '(aucun)',
  optedOutOfParentReports: 'Exclure des rapports aux parents',
  parents: 'Parents',
  pendingChildTooltip: 'Le parent n’a toujours pas accepté l’invitation.',
  preferredScheduleTag: 'Étiquette d’horaire à prioriser pour les périodes libres',
  privateFirstName: 'Prénom du profil',
  privateLastName: 'Nom du profil',
  privateNotice: '(ces valeurs ne sont visibles qu’à cet usager)',
  publicEmail: 'Courriel visible',
  publicEmailPlaceholder: 'Courriel principal utilisé si vide',
  role: 'Rôle',
  section: 'Groupe-matière',
  sections: 'Groupes-matière',
  sectionsEditionTitle: 'Sélection de groupes-matière pour ',
  selectedChildren: 'Enfants',
  selectedSections: 'Groupes-matière sélectionnés',
  teacherSelectedSectionsWarning:
    'Vous n’éditez PAS les matières enseignées par cet enseignant. Vous assignez à un enseignant des groupes-matière comme s’il était un ÉLÈVE.',
  teacherSelectedSectionsWarning2:
    'Pour modifier l’enseignant d’un groupe-matière, allez au groupe-matière et choisissez "Modifier les enseignants".',
  teacherSelectedSectionsWarningTitle: 'Attention!',
  title: 'Titre',
  undeleteAccountButtonTitle: 'Restaurer cette fiche',
  undeleteAccountErrorMessage: 'Cette fiche n’a pu être restaurée. Veuillez réessayer.',
  undeleteAccountErrorTitle: 'Oups, une erreur est survenue!',
  unknownSection: '[Groupe-matière inconnu]',
  userIdPrompt: 'Veuillez entrer le nouvel identifiant d’usager:',
  viewPlanner: 'Voir agenda',

  deleteAccountDemoErrorMessage: (error) =>
    `La suppression de fiche n’est pas autorisée en mode démo: ${error.message}`,
  getAddAccountTooltip: (accountType) => `Ajouter un ${accountType}`,
  getThresholdHelperText: (disabledThresholdValue) =>
    `Affecter les valeurs à ${disabledThresholdValue} pour les désactiver`,
  undeleteAccountDemoErrorMessage: (error) =>
    `La restauration de fiche n’est pas autorisée en mode démo: ${error.message}`
};
