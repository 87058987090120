import { Box, SxProps, Typography } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { ReactNode } from 'react';
import { InformationText } from './InformationText';

export interface SectionProps {
  sx?: SxProps;
  header: string;
  className?: string;
  headerClassName?: string;
  headerSx?: SxProps;
  footerNote?: string;
  children?: ReactNode;
}

export function Section({
  sx = [],
  className,
  headerClassName,
  headerSx = [],
  header,
  footerNote,
  children
}: SectionProps) {
  return (
    <Box sx={sx} className={className} display="flex" flexDirection="column">
      <Typography
        variant="h5"
        sx={[{ color: (theme) => theme.palette.text.secondary }, ...(isSxArray(headerSx) ? headerSx : [headerSx])]}
        fontWeight="300"
        className={headerClassName}
      >
        {header}
      </Typography>

      {children}

      {footerNote && <InformationText className={headerClassName} text={footerNote} />}
    </Box>
  );
}
