interface ColumnDefinition {
  readonly name: string;
  // Though for now, there's always only one suffix that makes an optional column useful,
  // there could be more in the future.
  readonly suffixes?: string[] | '*';
  readonly asTooltip?: true;
}

const AccountColumns: ColumnDefinition[] = [
  { name: 'Id' },
  { name: 'ManagedIdentifier', suffixes: '*' },
  { name: 'Role', suffixes: '*' },
  { name: 'Email', suffixes: '*' },
  { name: 'FirstName', suffixes: '*' },
  { name: 'LastName', suffixes: '*' },
  { name: 'Language', suffixes: '*' },
  { name: 'AllowNonVerifiedEmailAutomatch' },
  { name: 'SelectedSectionImportIds', suffixes: ['Student'], asTooltip: true },
  { name: 'ActualSelectedSectionIds' },
  { name: 'ActualSectionColors' },
  { name: 'GradeLevel', suffixes: ['Student'] },
  { name: 'ChildManagedIdentifiers', suffixes: ['Parent'] },
  { name: 'ChildAccountIds' },
  { name: 'IsLocked' }
];

const SectionColumns: ColumnDefinition[] = [
  { name: 'Id' },
  { name: 'ImportId', suffixes: '*' },
  { name: 'Title', suffixes: '*' },
  { name: 'GradeLevel', suffixes: '*' },
  { name: 'SectionNumber', suffixes: '*' },
  { name: 'DefaultTeacherManagedIdentifier', suffixes: '*' },
  { name: 'DefaultRoomName', suffixes: '*' },
  { name: 'AssociatedSectionNumbers', suffixes: '*' },
  { name: 'AutoEnrollRoles' }, // Always hidden for now, not used in imports
  { name: 'Color', suffixes: '*' },
  { name: 'ShortTitle', suffixes: '*' },
  { name: 'ActualTeacherId' },
  { name: 'ManualChanges' }
];

const ScheduleColumns: ColumnDefinition[] = [
  { name: 'Id' },
  { name: 'ImportId', suffixes: '*' },
  { name: 'CycleDay', suffixes: ['CycleDay'] },
  { name: 'DayOfWeek', suffixes: ['DayOfWeek'] },
  { name: 'Day', suffixes: ['Day'] },
  { name: 'MasterPeriodTag', suffixes: ['Master'] },
  { name: 'MasterScheduleTag', suffixes: ['Master'] },
  { name: 'CustomStartTime', suffixes: ['Custom'] },
  { name: 'CustomEndTime', suffixes: ['Custom'] },
  { name: 'CustomDisplayPeriodTag', suffixes: ['Custom'] },
  { name: 'EffectiveFromDay' }, // Always hidden for now, not used in imports
  { name: 'EffectiveToDay' }, // Always hidden for now, not used in imports
  { name: 'TeacherManagedIdentifiers', suffixes: '*' },
  { name: 'RoomName', suffixes: '*' },
  { name: 'TermTag', suffixes: '*' },
  { name: 'ActualSectionId' },
  { name: 'ActualTeacherIds' }
];

function getRootSchemaAndSuffixes(targetSchema: string): {
  root: string;
  suffixes: string[];
} {
  // A rogue comma could have been added by mistake (fixed in Story 17385).
  const parts = targetSchema.split(':').map((p) => (p.endsWith(',') ? p.substring(0, p.length - 1) : p));

  switch (parts[0]) {
    case 'Teacher':
      return { root: 'Account', suffixes: ['Teacher'] };
    case 'Student':
      return { root: 'Account', suffixes: ['Student'] };
    case 'Parent':
      return { root: 'Account', suffixes: ['Parent'] };
    case 'Section.Schedule':
      // No suffixes implies CycleDay and Master suffixes.
      return {
        root: 'Section.Schedule',
        suffixes: parts.length === 1 ? ['CycleDay', 'Master'] : parts.slice(1)
      };
    default:
      return { root: parts[0], suffixes: parts.slice(1) };
  }
}

export function getColumnNamesForSchema(targetSchema: string): string[] {
  const { root, suffixes } = getRootSchemaAndSuffixes(targetSchema);

  let columns: ColumnDefinition[] = [];

  switch (root) {
    case 'Account':
      columns = AccountColumns;
      break;
    case 'Section':
      columns = SectionColumns;
      break;
    case 'Section.Schedule':
      columns = ScheduleColumns;
      break;
    default:
      console.warn(`Column names not found for schema '${targetSchema}'`);
      return [];
  }

  return columns.map((c) => {
    // Column names that start with an underline are not displayed.
    // Column names that start with an ~ are displayed as a tooltip.
    if (c.suffixes != null && (c.suffixes === '*' || c.suffixes.some((s) => suffixes.includes(s)))) {
      return c.asTooltip ? `~${c.name}` : c.name;
    } else {
      return `_${c.name}`;
    }
  });
}
