import { AppModalService } from '@shared/web/services';
import { InsightsServicesContextValues } from './InsightsServicesContext.tsx';
import { AppInsightsServiceProvider } from './InsightsWebServices.ts';
import { AppViewModelFactory } from './services';

export class InsightsServiceContainer {
  private static _instance: InsightsServiceContainer | undefined;
  private readonly _services: InsightsServicesContextValues;

  static get instance(): InsightsServiceContainer {
    if (this._instance == null) {
      this._instance = new InsightsServiceContainer();
    }

    return this._instance;
  }

  static get services(): InsightsServicesContextValues {
    return this.instance.services;
  }

  constructor() {
    const modalService = new AppModalService();
    const serviceProvider = new AppInsightsServiceProvider(modalService);
    const viewModelFactory = new AppViewModelFactory();
    this._services = { ...serviceProvider, viewModelFactory, modalService };
  }

  get services(): InsightsServicesContextValues {
    return this._services;
  }
}
