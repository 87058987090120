import { AccountService, NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppGoogleAccountSettingsViewModel, GoogleAccountSettingsViewModel } from './GoogleAccountSettingsViewModel';

export interface GoogleAccountSettingsDialogViewModel {
  readonly configId: string;
  readonly externalAccountId: string;
  readonly settings: IPromiseBasedObservable<GoogleAccountSettingsViewModel>;
}

export class AppGoogleAccountSettingsDialogViewModel implements GoogleAccountSettingsDialogViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _navigationService: NavigationService,
    private readonly _accountService: AccountService,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    public readonly configId: string,
    public readonly externalAccountId: string,
    public readonly isNewAccount: boolean
  ) {
    makeObservable(this);
  }

  @computed
  get settings(): IPromiseBasedObservable<GoogleAccountSettingsViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<GoogleAccountSettingsViewModel> {
    const details = await this._connectorsStore.classroom.getGoogleAccountDetails(this.externalAccountId);

    return new AppGoogleAccountSettingsViewModel(
      this._localizationService,
      this._connectorsStore,
      this._accountService,
      this._onSuccess,
      this._onCancel,
      this.configId,
      this.externalAccountId,
      this.isNewAccount,
      details.settings
    );
  }
}
