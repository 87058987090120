import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { Memoize } from 'fast-typescript-memoize';
import { BaseModel } from '../../Model';
import { OnboardingText, QuestionChoice } from '../interfaces';
import { GrpcOnboardingText } from './GrpcOnboardingText';

export class GrpcQuestionChoice extends BaseModel<PB.AnswerChoice> implements QuestionChoice {
  constructor(pb: PB.AnswerChoice) {
    super(pb);
  }

  @Memoize()
  get label(): OnboardingText[] {
    return this._pb.localizedLabels.map((t) => new GrpcOnboardingText(t));
  }

  get value(): string {
    return this._pb.value;
  }
}
