import { BehaviourHistoryRangeDataPoint, BehaviourSummaryItemType } from '@insights/models';
import { OQValue } from '@shared/models/types';
import { MetricsStore } from '@shared/services/stores';
import { addDays } from 'date-fns';
import { chain, head, last } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

interface BehaviourHistoryDataPoint {
  type: BehaviourSummaryItemType;
  color: OQValue;
  date: Date;
}

export interface StudentBehaviourHistoryViewModel {
  readonly configId: string;
  readonly studentId: string;

  readonly data: IPromiseBasedObservable<BehaviourHistoryRangeDataPoint[]>;
}

export class AppStudentBehaviourHistoryViewModel implements StudentBehaviourHistoryViewModel {
  constructor(
    public readonly configId: string,
    public readonly studentId: string,
    private readonly _metricsStore: MetricsStore
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<BehaviourHistoryRangeDataPoint[]> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<BehaviourHistoryRangeDataPoint[]> {
    const accountOQMetrics = head(await this._metricsStore.getAccountOQMetrics(this.configId, [this.studentId], true));

    if (accountOQMetrics?.oqHistory == null) {
      return [];
    }

    const openTheAppMetrics = chain(accountOQMetrics.oqHistory.opensTheAppList)
      .orderBy((i) => i.startOfPeriod.getTime())
      .map(
        (i) =>
          ({
            type: 'app-open',
            color: i.value,
            date: i.startOfPeriod
          }) as BehaviourHistoryDataPoint
      )
      .value();

    const taskCompletionMetrics = chain(accountOQMetrics.oqHistory.marksTasksAsDoneList)
      .orderBy((i) => i.startOfPeriod.getTime())
      .map(
        (i) =>
          ({
            type: 'task-completion',
            color: i.value,
            date: i.startOfPeriod
          }) as BehaviourHistoryDataPoint
      )
      .value();

    return chain(this.groupValues(openTheAppMetrics)).concat(this.groupValues(taskCompletionMetrics)).value();
  }

  private groupValues(values: BehaviourHistoryDataPoint[]): BehaviourHistoryRangeDataPoint[] {
    return chain(values)
      .reduce((result: BehaviourHistoryRangeDataPoint[], current) => {
        const previous = last(result);

        if (previous) {
          // Always update the previous range's end date.
          // This is to fill gap in the dates, if any, to
          // display continuous data in the chart.
          const endDate = previous?.color === current.color ? addDays(current.date, 1) : current.date;

          result[result.length - 1] = {
            ...previous,
            endDate
          };
        }

        if (previous?.color !== current.color) {
          // The value (color) changed. Create a new range entry.
          result.push({
            type: current.type,
            color: current.color,
            startDate: current.date,
            endDate: addDays(current.date, 1)
          });
        }

        return result;
      }, [])
      .value();
  }
}
