import { AddOnboardingQuestionViewModel } from '@insights/viewmodels';
import {
  Autocomplete,
  Box,
  createFilterOptions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField
} from '@mui/material';
import { AllOnboardingQuestionKinds, OnboardingQuestionKind } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { OnboardingQuestionReadOnlyView } from './OnboardingQuestionReadOnlyView';

interface QuestionOption {
  readonly name: string;
  readonly displayName?: string;
}

function isQuestionOption(value: string | QuestionOption | null): value is QuestionOption {
  return value != null && (value as QuestionOption).name != null;
}

const filter = createFilterOptions<QuestionOption>();

export interface AddOnboardingQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: AddOnboardingQuestionViewModel;
}

export const AddOnboardingQuestion = observer((props: AddOnboardingQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <Box sx={sx} className={className} style={style}>
      <Autocomplete
        freeSolo
        selectOnFocus
        handleHomeEndKeys
        value={viewModel.selectedQuestionName}
        onChange={(_, v) => {
          viewModel.selectedQuestionName = isQuestionOption(v) ? v.name : (v ?? '');
        }}
        filterOptions={(o, p) => {
          const filtered = filter(o, p);
          const { inputValue } = p;

          if (inputValue.length > 0 && !viewModel.availableQuestions.some((q) => q.templateName == inputValue)) {
            filtered.push({ name: inputValue, displayName: strings.getCreateQuestionLabel(inputValue) });
          }

          return filtered;
        }}
        options={viewModel.availableQuestions.map<QuestionOption>((q) => ({ name: q.templateName }))}
        getOptionLabel={(o) => (isQuestionOption(o) ? (o.displayName ?? o.name) : o)}
        renderInput={(params) => <TextField {...params} fullWidth label={strings.selectQuestionTemplateLabel} />}
      />
      {viewModel.isNewNameSelected && (
        <FormControl sx={{ mt: 2 }} fullWidth>
          <InputLabel htmlFor="answer-kind-select" shrink>
            {strings.answerKindLabel}
          </InputLabel>
          <Select
            labelId="answer-kind-select"
            value={viewModel.selectedAnswerKind}
            onChange={(e) => (viewModel.selectedAnswerKind = e.target.value as OnboardingQuestionKind)}
          >
            {AllOnboardingQuestionKinds.map((kind) => (
              <MenuItem value={kind} key={`answer-kind-${kind}`}>
                {strings.getAnswerKindLabel(kind)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {viewModel.selectedQuestion != null && (
        <OnboardingQuestionReadOnlyView
          sx={{ mt: 2 }}
          question={viewModel.selectedQuestion}
          variableResolver={viewModel.variableResolver}
        />
      )}
    </Box>
  );
});
