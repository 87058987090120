import { TransformationSchemaViewModel } from '@insights/viewmodels';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SxProps,
  Typography
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface TransformationSchemaProps {
  sx?: SxProps;
  viewModel: TransformationSchemaViewModel;
  className?: string;
  style?: CSSProperties;
}

export const TransformationSchema = observer((props: TransformationSchemaProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <Box sx={sx} className={className} style={style}>
      <FormControl fullWidth>
        <InputLabel htmlFor="target-schema-select">{strings.targetSchemaLabel}</InputLabel>
        <Select
          value={viewModel.targetRootSchema}
          inputProps={{ id: 'target-schema-select' }}
          onChange={(e) => (viewModel.targetRootSchema = e.target.value)}
          renderValue={(v) => viewModel.availableSchemas.find((s) => s.name == v)?.description ?? strings.noSchema}
        >
          <MenuItem key={`target-schema-none`} value="">
            <Typography>{strings.noSchema}</Typography>
          </MenuItem>
          {viewModel.availableSchemas.map((schema) => (
            <MenuItem key={`target-schema-${schema.name}`} value={schema.name}>
              <Typography>{schema.description}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {viewModel.availableSuffixes.map((group, i) => (
        <FormControl key={`suffix-group-${i}`} sx={{ mt: 1 }} fullWidth>
          <FormLabel>{group.localizedDescription}</FormLabel>
          {group.suffixes.length > 1 ? (
            <RadioGroup row>
              {group.suffixes.map((suffix) => (
                <FormControlLabel
                  key={`suffix-item-${suffix.name}`}
                  label={suffix.localizedDescription}
                  control={
                    <Radio
                      checked={viewModel.targetSuffixes.includes(suffix.name)}
                      onChange={() => viewModel.addSuffix(suffix.name)}
                    />
                  }
                />
              ))}
            </RadioGroup>
          ) : (
            <>
              {group.suffixes.map((suffix) => (
                <FormControlLabel
                  key={`suffix-item-${suffix.name}`}
                  label={suffix.localizedDescription}
                  control={
                    <Checkbox
                      checked={viewModel.targetSuffixes.includes(suffix.name)}
                      onChange={() => viewModel.toggleSuffix(suffix.name)}
                    />
                  }
                />
              ))}
            </>
          )}
        </FormControl>
      ))}
      {viewModel.availableOptions.length > 0 && (
        <Box mt={1}>
          <FormControl fullWidth>
            <InputLabel htmlFor="options-select">{strings.suggestedImportOptions}</InputLabel>
            <Select
              multiple
              value={viewModel.selectedOptionNames}
              inputProps={{ id: 'options-select', placeholder: strings.noImportOption }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                }
              }}
              onChange={(e) => (viewModel.selectedOptionNames = e.target.value as unknown as string[])}
              renderValue={() => <Typography variant="body2">{viewModel.selectedOptionNames.join(', ')}</Typography>}
            >
              {viewModel.availableOptions.map((o) => (
                <MenuItem key={`option-${o.name}`} value={o.name}>
                  <Checkbox size="small" checked={viewModel.selectedOptionNames.includes(o.name)} />
                  <Typography variant="body2">
                    {o.name} : {o.localizedDescription}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
});
