import { OnboardingQuestionViewModel } from '@insights/viewmodels';
import { Box, Button, Chip, Link, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useRef } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface FileQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: OnboardingQuestionViewModel;
}

export const FileQuestion = observer((props: FileQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const hasFile = viewModel.answer.hasAnswer;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileReader = useRef(new FileReader());
  const localFilename = useRef<string | undefined>(undefined);

  useEffect(() => {
    fileReader.current.onload = () => {
      void viewModel.uploadFile(fileReader.current.result as string, localFilename.current);
    };
  }, [fileReader.current]);

  const openFileInput = () => {
    requestAnimationFrame(() => {
      if (fileInputRef.current != null) {
        fileInputRef.current.click();
      }
    });
  };

  const onUploadFile = (files: FileList | null) => {
    if (files != null) {
      const file = files[0];

      if (file != null) {
        readFile(file);
      }
    }
  };

  const readFile = (file: File) => {
    localFilename.current = file.name;
    fileReader.current.readAsDataURL(file);
  };

  return (
    <Box sx={sx} className={className} style={style}>
      <input
        type="file"
        id="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => onUploadFile(e.target.files)}
      />
      {hasFile && (
        <Chip
          color="primary"
          variant="outlined"
          label={
            <Link href={viewModel.answer.fileValue} target="_blank" rel="noreferrer">
              {decodeURI(viewModel.answer.fileValue.replace(/^.*[\\/]/, ''))}
            </Link>
          }
          onDelete={!viewModel.isDisabled ? () => void viewModel.clearFile() : undefined}
        />
      )}
      <Box mt={1}>
        <Button disabled={viewModel.isDisabled} variant="outlined" color="primary" onClick={openFileInput}>
          {hasFile ? strings.replaceFileButton : strings.selectFileButton}
        </Button>
      </Box>
    </Box>
  );
});
