import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { computed, makeObservable } from 'mobx';
import { BaseModel } from '../../Model';
import { Dashboard } from '../interfaces';
import { GrpcDashboardProcess } from './GrpcDashboardProcess';

export class GrpcDashboard extends BaseModel<PB.Dashboard> implements Dashboard {
  constructor(pb: PB.Dashboard) {
    super(pb);
    makeObservable(this);
  }

  @computed
  get processes() {
    return this._pb.processes.map((p) => new GrpcDashboardProcess(p));
  }
}
