import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_pb';
import { ContentDefinition_ContentIcon as PBContentIcon } from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModelEx, EditableStringProperty, EditableValuePropertyEx } from '../editables';
import { ContentIcon, ContentWorkloadLevel } from '../types';
import { protobufFromContentIcon, protobufFromContentWorkloadLevel } from '../types/EnumConversion';
import { GrpcBlackbaudKindMapping } from './GrpcBlackbaudKindMapping';
import { BlackbaudKindMappingModel } from './interfaces';

export class EditableBlackbaudKindMapping extends EditableModelEx<BC.KindMapping> implements BlackbaudKindMappingModel {
  private _kindId: EditableValuePropertyEx<bigint, BC.KindMapping>;
  private _kindName: EditableStringProperty<BC.KindMapping>;
  private _icon: EditableValuePropertyEx<ContentIcon, BC.KindMapping>;
  private _workloadLevel: EditableValuePropertyEx<ContentWorkloadLevel, BC.KindMapping>;

  static createNew(kindId: bigint, kindName: string): EditableBlackbaudKindMapping {
    const pb = new BC.KindMapping();
    pb.kindId = kindId;
    pb.kindName = kindName;
    pb.icon = PBContentIcon.HOMEWORK;

    return new EditableBlackbaudKindMapping(new GrpcBlackbaudKindMapping(pb), true);
  }

  constructor(
    readonly originalKindMapping: BlackbaudKindMappingModel,
    readonly isNew = false
  ) {
    super(originalKindMapping.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._kindId = new EditableValuePropertyEx(originalKindMapping.kindId, (pb, value) => (pb.kindId = value))),
      (this._kindName = new EditableStringProperty(originalKindMapping.kindName, (pb, value) => (pb.kindName = value), {
        trim: true
      })),
      (this._icon = new EditableValuePropertyEx(
        originalKindMapping.icon,
        (pb, value) => (pb.icon = protobufFromContentIcon(value))
      )),
      (this._workloadLevel = new EditableValuePropertyEx(
        originalKindMapping.workloadLevel,
        (pb, value) => (pb.workloadLevel = protobufFromContentWorkloadLevel(value))
      ))
    ]);
  }

  @computed
  get kindId() {
    return this._kindId.value;
  }

  set kindId(value: bigint) {
    this._kindId.value = value;
  }

  @computed
  get kindName() {
    return this._kindName.value;
  }

  set kindName(value: string) {
    this._kindName.value = value;
  }

  @computed
  get icon() {
    return this._icon.value;
  }

  set icon(value: ContentIcon) {
    this._icon.value = value;
  }

  @computed
  get workloadLevel() {
    return this._workloadLevel.value;
  }

  set workloadLevel(value: ContentWorkloadLevel) {
    this._workloadLevel.value = value;
  }

  @computed
  get isIncomplete() {
    return this.kindId === 0n || this.kindName.length === 0 || this.workloadLevel === 'unknown';
  }
}
