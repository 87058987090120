export interface NavigationStrings {
  headerBackButton: string;
  headerCancelButton: string;
  headerCloseButton: string;
  headerDoneButton: string;
  headerSaveButton: string;
}

export const EnglishNavigationStrings: NavigationStrings = {
  headerBackButton: 'Back',
  headerCancelButton: 'Cancel',
  headerCloseButton: 'Close',
  headerDoneButton: 'Done',
  headerSaveButton: 'Save'
};

export const FrenchNavigationStrings: NavigationStrings = {
  headerBackButton: 'Retour',
  headerCancelButton: 'Annuler',
  headerCloseButton: 'Fermer',
  headerDoneButton: 'OK',
  headerSaveButton: 'Sauvegarder'
};
