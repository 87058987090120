import {
  Column,
  Container,
  ErrorIndicator,
  Expanded,
  LoadingObservablePresenter,
  MicrosoftTeamsMappings,
  Row,
  Scroll,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Button, Dialog, DialogContent, DialogTitle, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface MicrosoftTeamsMappingsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  externalAccountId: string;
}

export const MicrosoftTeamsMappingsDialog = observer((props: MicrosoftTeamsMappingsDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], configId, externalAccountId, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createMicrosoftTeamsMappings(configId, externalAccountId, onSuccess!, onCancel!)
  );
  const data = viewModel.mappings;

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <Dialog sx={sx} open={true} fullWidth maxWidth="md">
        <DialogTitle>{strings.microsoftTeamsMappings}</DialogTitle>
        <DialogContent>
          <LoadingObservablePresenter
            data={data}
            loadingMessage={strings.loadingMicrosoftTeamsMessage}
            render={(data) => (
              <Scroll direction="y">
                <Container sx={{ p: 0.5 }}>
                  <Column>
                    <Expanded>
                      <MicrosoftTeamsMappings viewModel={data} />
                    </Expanded>
                  </Column>
                </Container>
              </Scroll>
            )}
            renderError={() => (
              <Column>
                <ErrorIndicator size="normal" message={strings.loadingError} />
                <Row horizontalContentAlignment="right">
                  <Button variant="contained" onClick={() => onCancel!()}>
                    {strings.cancelButton}
                  </Button>
                </Row>
              </Column>
            )}
          />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
});
