import * as BPB from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/blackbaud_sky_pb';
import {
  UpdateExternalAccountSettingsResponse,
  UpdateExternalAccountSetupResponse
} from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/blackbaud_sky_pb';
import * as TBPB from '@buf/studyo_studyo.bufbuild_es/studyo/type_blackbaud_sky_pb';
import { BlackbaudSky } from '@buf/studyo_studyo.connectrpc_es/studyo/connectors/blackbaud_sky_connect';
import { BlackbaudSkyTransport } from '../interfaces';
import { BaseGrpcTransport } from './BaseGrpcTransport';

export class BlackbaudSkyGrpcTransport extends BaseGrpcTransport implements BlackbaudSkyTransport {
  async getExternalAccountDetails(externalAccountId: string): Promise<BPB.GetExternalAccountDetailsResponse> {
    const request = new BPB.GetExternalAccountDetailsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(BlackbaudSky, BlackbaudSky.methods.getExternalAccountDetails, request);

    return response;
  }

  async createOrUpdateExternalAccount(
    externalAccountId: string | undefined,
    configId: string,
    name: string
  ): Promise<BPB.CreateOrUpdateExternalAccountResponse> {
    const request = new BPB.CreateOrUpdateExternalAccountRequest();
    request.externalAccountId = externalAccountId ?? '';
    request.configId = configId;
    request.accountName = name;

    const response = await this.performRequest(
      BlackbaudSky,
      BlackbaudSky.methods.createOrUpdateExternalAccount,
      request
    );

    return response;
  }

  async getAuthorizationUrl(externalAccountId: string, configId: string, redirectUrl: string): Promise<string> {
    const request = new BPB.GetBlackbaudAuthorizationUrlRequest();
    request.externalAccountId = externalAccountId;
    request.configId = configId;
    request.redirectUrl = redirectUrl;

    const response = await this.performRequest(
      BlackbaudSky,
      BlackbaudSky.methods.getBlackbaudAuthorizationUrl,
      request
    );

    return response.url;
  }

  async completeAuthorization(
    state: string,
    authorizationCode: string,
    error: string
  ): Promise<BPB.CompleteAuthorizationResponse> {
    const request = new BPB.CompleteAuthorizationRequest();
    request.state = state;
    request.authorizationCode = authorizationCode;
    request.error = error;

    const response = await this.performRequest(BlackbaudSky, BlackbaudSky.methods.completeAuthorization, request);

    return response;
  }

  async updateExternalAccountSetup(
    externalAccountId: string,
    configId: string,
    schoolYearLabel: string,
    schoolLevelId: bigint,
    portalAddress: string
  ): Promise<UpdateExternalAccountSetupResponse> {
    const request = new BPB.UpdateExternalAccountSetupRequest();
    request.externalAccountId = externalAccountId;
    request.configId = configId;
    request.schoolYearLabel = schoolYearLabel;
    request.schoolLevelId = schoolLevelId;
    request.portalAddress = portalAddress;

    const response = await this.performRequest(BlackbaudSky, BlackbaudSky.methods.updateExternalAccountSetup, request);

    return response;
  }

  async updateExternalAccountSettings(
    externalAccountId: string,
    configId: string,
    mappings: TBPB.AssignmentTypeMapping[],
    ignoreUnmappedAssignmentTypes: boolean
  ): Promise<UpdateExternalAccountSettingsResponse> {
    const request = new BPB.UpdateExternalAccountSettingsRequest();
    request.externalAccountId = externalAccountId;
    request.configId = configId;
    request.mappings = mappings;
    request.ignoreUnmappedAssignmentTypes = ignoreUnmappedAssignmentTypes;

    const response = await this.performRequest(
      BlackbaudSky,
      BlackbaudSky.methods.updateExternalAccountSettings,
      request
    );

    return response;
  }

  async getSchoolYears(externalAccountId: string): Promise<TBPB.SchoolYear[]> {
    const request = new BPB.GetSchoolYearsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(BlackbaudSky, BlackbaudSky.methods.getSchoolYears, request);

    return response.years;
  }

  async getSchoolLevels(externalAccountId: string): Promise<TBPB.SchoolLevel[]> {
    const request = new BPB.GetSchoolLevelsRequest();
    request.externalAccountId = externalAccountId;

    const response = await this.performRequest(BlackbaudSky, BlackbaudSky.methods.getSchoolLevels, request);

    return response.levels;
  }
}
