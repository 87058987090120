import { SchoolYearConfigurationSummary } from '@shared/models/config';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppPaginatedViewModel, AppPaginationViewModel, PaginatedViewModel } from './PaginatedViewModel';

export interface WorkloadInfo {
  config: SchoolYearConfigurationSummary;
}

export interface WorkloadViewModel extends PaginatedViewModel {
  readonly configId: string;
  readonly data: IPromiseBasedObservable<WorkloadInfo>;
}

export class AppWorkloadViewModel extends AppPaginatedViewModel implements WorkloadViewModel {
  constructor(
    public readonly configId: string,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<WorkloadInfo> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<WorkloadInfo> {
    const config = await this._schoolYearConfigurationStore.getConfigSummary(this.configId);

    this.setPagination(new AppPaginationViewModel(config.startDay, config.endDay));

    return { config };
  }
}
