export interface LinkedTasksPublishStrings {
  readonly title: string;
  readonly cancel: string;
  readonly publish: string;
  readonly periodTag: (tag: string) => string;

  readonly nonePublish: string;
  readonly allPublish: string;
  readonly somePublish: (selected: number, total: number) => string;

  readonly longTimeSpanAlertTitle: string;
  readonly longTimeSpanAlertDescription: string;

  readonly unsavedChangesTitle: string;
  readonly unsavedChangesDescription: string;
  readonly unsavedChangesStayTitle: string;
  readonly unsavedChangesDiscardTitle: string;

  readonly savingTitle: string;
  readonly savingDescription: string;
  readonly savedTitle: string;
  readonly savedDescription: string;
  readonly saveErrorTitle: string;
  readonly saveErrorDescription: string;
  readonly savePermanentErrorDescription: string;
}

export const EnglishLinkedTasksPublishStrings: LinkedTasksPublishStrings = {
  title: 'Select occurrences to publish',
  cancel: 'Cancel',
  publish: 'Publish',
  periodTag: (tag) => `Period ${tag}`,

  nonePublish: 'None',
  allPublish: 'All',
  somePublish: (selected, total) => `${selected} / ${total} students`,

  longTimeSpanAlertTitle: 'Long lasting task',
  longTimeSpanAlertDescription:
    'It is strongly recommended that the announcement date be no more than 14 days prior to the due date. This simplifies the task view for students and helps them plan more efficiently. Do you wish to continue?',

  unsavedChangesTitle: 'Unsaved changes',
  unsavedChangesDescription: 'The changes made will be lost. Are you sure you wish to cancel?',
  unsavedChangesDiscardTitle: 'Discard',
  unsavedChangesStayTitle: 'No',

  savingTitle: 'Publishing occurrences',
  savingDescription: 'Publishing the selected occurrences. Please wait.',
  savedTitle: 'Success!',
  savedDescription: 'The selected occurrences have been published',
  saveErrorTitle: 'Oops! An error occurred',
  saveErrorDescription: 'Please try again.',
  savePermanentErrorDescription: 'A change made elsewhere prevented publishing. Please reload and try again.'
};

export const FrenchLinkedTasksPublishStrings: LinkedTasksPublishStrings = {
  title: 'Sélectionner les occurrences à publier',
  cancel: 'Annuler',
  publish: 'Publier',
  periodTag: (tag) => `Période ${tag}`,

  nonePublish: 'Aucun',
  allPublish: 'Tous',
  somePublish: (selected, total) => `${selected} / ${total} élève${total > 1 ? 's' : ''}`,

  longTimeSpanAlertTitle: 'Tâche de longue durée',
  longTimeSpanAlertDescription:
    "Il est fortement recommandé d’avoir une date d’annonce d'au plus 14 jours avant la date d’échéance. Ceci permet d’alléger la vue des tâches des élèves et les aider à mieux planifier. Voulez-vous continuer?",

  unsavedChangesTitle: 'Changements non-sauvegardés',
  unsavedChangesDescription: 'Les changements effectués seront perdus. Souhaitez-vous annuler?',
  unsavedChangesStayTitle: 'Non',
  unsavedChangesDiscardTitle: 'Abandonner',

  savingTitle: 'Publication des occurrences',
  savingDescription: 'Veuillez patienter pendant que nous publions les occurrences sélectionnées.',
  savedTitle: 'Succès!',
  savedDescription: 'Les occurrences sélectionnées ont été publiées.',
  saveErrorTitle: 'Oups! Une erreur est survenue',
  saveErrorDescription: 'Veuillez réessayer plus tard.',
  savePermanentErrorDescription:
    'Un changement fait ailleurs a empêché la publication. Veuillez rafraichir et réessayer.'
};
