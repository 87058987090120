import { AlertService } from '@insights/services';
import {
  EditableImportSession,
  EditableSourceFile,
  EditableTransformation,
  EditableTransformationColumn,
  Operation,
  Schema,
  SchemaField,
  SourceData
} from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { v4 as uuidV4 } from 'uuid';
import {
  AppEditableTransformationColumnViewModel,
  EditableTransformationColumnViewModel
} from './EditableTransformationColumnViewModel';

// number is parameter rank
export type FocusedField = 'none' | 'schema' | 'operation' | number | 'substitution';

export interface LookupData {
  readonly data: SourceData | undefined;
  readonly label: string;
  readonly name: string;
  readonly columnIndexes: number[];
}

export interface EditableTransformationViewModel {
  readonly mainSourceData: SourceData | undefined;
  readonly mainSourceName: string;
  readonly isMainSourceTextFile: boolean;
  readonly lookupData: LookupData[];
  readonly targetData: SourceData | undefined;

  readonly label: string;
  readonly name: string;
  readonly hasTargetSchema: boolean;

  readonly editableColumns: EditableTransformationColumnViewModel[];
  readonly editingCommentColumn: EditableTransformationColumnViewModel | undefined;
  editingComment: string;

  readonly focusedColumnIndex: number;
  readonly focusedField: FocusedField;
  setFocused(column: EditableTransformationColumnViewModel, field: FocusedField): void;
  resetFocused(): void;

  addColumn(): void;
  moveColumn(oldIndex: number, newIndex: number): void;

  readonly isExecuting: boolean;
  readonly hasChanges: boolean;
  readonly hasChangesSinceLastUpdate: boolean;

  updateData(): Promise<void>;
  saveChanges(): Promise<void>;
  resetChanges(): void;
  applyComment(): void;
  cancelComment(): void;
}

export class AppEditableTransformationViewModel implements EditableTransformationViewModel {
  private readonly _schemaByName: _.Dictionary<Schema>;
  private readonly _schemaByAlias: _.Dictionary<Schema>;
  private readonly _dataByLabel: _.Dictionary<SourceData>;
  private readonly _fileByLabel: _.Dictionary<EditableSourceFile>;
  private readonly _transformationByLabel: _.Dictionary<EditableTransformation>;
  private readonly _session: EditableImportSession;
  private readonly _transformation: EditableTransformation;

  @observable private _targetData: SourceData | undefined;
  @observable private _isExecuting = false;
  @observable private _hasChangesSinceLastUpdate = false;
  @observable private _editingComment = '';
  @observable private _editingCommentColumn: EditableTransformationColumnViewModel | undefined;
  @observable private _focusedColumnIndex = 0;
  @observable private _focusedField: FocusedField = 'schema';

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _localizationService: LocalizationService,
    private readonly _alertService: AlertService,
    session: EditableImportSession,
    private readonly _transformationLabel: string,
    private readonly _operations: Operation[],
    private readonly _schemas: Schema[]
  ) {
    makeObservable(this);

    this._schemaByName = _.keyBy(_schemas, (s) => s.name);
    this._schemaByAlias = _.keyBy(
      _schemas.filter((s) => s.alias.length > 0),
      (s) => s.alias
    );

    this._session = session;
    this._transformation = this.getTransformation();

    this._dataByLabel = _.keyBy(session.data, (d) => d.label);
    this._fileByLabel = _.keyBy(session.expectedFiles, (f) => f.label);
    this._transformationByLabel = _.keyBy(session.transformations, (t) => t.label);

    // Only this data can change locally. The rest would fully update on invalidation (when saving).
    this._targetData = this._dataByLabel[this._transformationLabel];
  }

  @computed
  get mainSourceData() {
    return this._dataByLabel[this._transformation.sourceLabel];
  }

  @computed
  get mainSourceName() {
    return (
      this._fileByLabel[this._transformation.sourceLabel]?.name ??
      this._transformationByLabel[this._transformation.sourceLabel]?.name ??
      ''
    );
  }

  @computed
  get isMainSourceTextFile(): boolean {
    return this._fileByLabel[this._transformation.sourceLabel]?.kind === 'text';
  }

  @computed
  get lookupData() {
    const sourcesByLabel = _.groupBy(this._transformation.indexedSources, (s) => s.label);
    const labels = Object.keys(sourcesByLabel);
    return labels.map((label) => ({
      data: this._dataByLabel[label],
      label,
      name: this._fileByLabel[label]?.name ?? this._transformationByLabel[label]?.name ?? '',
      columnIndexes: sourcesByLabel[label].map((s) => s.columnIndex)
    }));
  }

  @computed
  get targetData() {
    return this._targetData;
  }

  @computed
  get label() {
    return this._transformation.label;
  }

  @computed
  get name() {
    return this._transformation.name;
  }

  @computed
  get hasTargetSchema() {
    return this._transformation.targetSchema.length > 0;
  }

  @computed
  get editableColumns(): EditableTransformationColumnViewModel[] {
    return this._transformation.columns.map(
      (column, index) =>
        new AppEditableTransformationColumnViewModel(
          this,
          column,
          uuidV4(),
          index,
          this._operations,
          this.availableFields,
          () => runInAction(() => (this._hasChangesSinceLastUpdate = true)),
          (c) =>
            runInAction(() => {
              this._editingComment = c.comment;
              this._editingCommentColumn = c;
            })
        )
    );
  }

  @computed
  get editingCommentColumn(): EditableTransformationColumnViewModel | undefined {
    return this._editingCommentColumn;
  }

  @computed
  get editingComment(): string {
    return this._editingComment;
  }

  set editingComment(value: string) {
    this._editingComment = value;
  }

  @computed
  get focusedColumnIndex(): number {
    return this._focusedColumnIndex;
  }

  @computed
  get focusedField() {
    return this._focusedField;
  }

  @action
  setFocused(column: EditableTransformationColumnViewModel, field: FocusedField): void {
    this._focusedColumnIndex = column.index;
    this._focusedField = field;
  }

  @action
  resetFocused(): void {
    this._focusedColumnIndex = -1;
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @computed
  get hasChanges() {
    return this._transformation.hasChanges;
  }

  @computed
  get hasChangesSinceLastUpdate() {
    return this._hasChangesSinceLastUpdate;
  }

  @computed
  private get availableFields(): SchemaField[] {
    if (this._transformation.targetSchema.length === 0) {
      return [];
    }

    const schemaParts = this._transformation.targetSchema.split(':');
    const schema = this._schemaByName[schemaParts[0]] ?? this._schemaByAlias[schemaParts[0]];

    if (schema == null) {
      return [];
    }

    const schemaSuffixes = new Set(schemaParts.slice(1).map((p) => `:${p}`));
    const excludedFieldNames = _.flatten(
      schema.suffixGroups.map((g) =>
        _.flatten(g.suffixes.filter((s) => schemaSuffixes.has(s.name)).map((s) => s.excludedFieldNames))
      )
    );

    return schema.fields.filter((f) => !excludedFieldNames.includes(f.name));
  }

  @action
  addColumn(): void {
    const column = EditableTransformationColumn.createNew();
    this._transformation.addColumn(column);
    this._focusedColumnIndex = this._transformation.columns.length - 1;
    this._focusedField = 'schema';
    // Even though it's a meaningless column until it gets edited, we still consider
    // this a change. updateData won't get called anyway until something changes within
    // the column.
    this._hasChangesSinceLastUpdate = true;
  }

  @action
  moveColumn(oldIndex: number, newIndex: number) {
    this._transformation.moveColumn(oldIndex, newIndex);
    // Note: This doesn't help keep the focus on the moved row, issue with react-sortable.
    this._focusedColumnIndex = newIndex;
    this._hasChangesSinceLastUpdate = true;
  }

  @action
  async updateData(): Promise<void> {
    if (this.mainSourceData != null && this._hasChangesSinceLastUpdate) {
      this._isExecuting = true;
      // We reset the flag even if the next call fails, to avoid call loops.
      this._hasChangesSinceLastUpdate = false;

      try {
        const newData = await this._importSessionStore.transformData(
          this.mainSourceData,
          this.lookupData.map((l) => l.data),
          this._transformation
        );

        runInAction(() => {
          this._targetData = newData;
        });
      } catch (error) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.import;
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedErrorMessage + (error as Error).message
        });
      } finally {
        runInAction(() => (this._isExecuting = false));
      }
    }
  }

  @action
  async saveChanges(): Promise<void> {
    if (!this.hasChanges) {
      return;
    }

    this._isExecuting = true;

    try {
      // No need for the returned data, we'll invalidate.
      await this._importSessionStore.createOrUpdateImportSession(this._session, false);

      // This will cause our parent view-model to refresh and create a new self.
      // No need to set _hasChangesSinceLastUpdated!
      this._importSessionStore.invalidate();
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.import;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedErrorMessage + (error as Error).message
      });
    } finally {
      runInAction(() => (this._isExecuting = false));
    }
  }

  @action
  resetChanges() {
    this._transformation.resetChanges();
    this._targetData = this._dataByLabel[this._transformationLabel];
    // Since the target data is reset, we can forget changes since last updated.
    this._hasChangesSinceLastUpdate = false;
  }

  @action
  applyComment(): void {
    this._editingCommentColumn!.comment = this._editingComment;
    this._editingCommentColumn = undefined;
  }

  @action
  cancelComment(): void {
    this._editingCommentColumn = undefined;
  }

  private getTransformation(): EditableTransformation {
    const transformation = this._session.transformations.find((t) => t.label === this._transformationLabel);

    if (transformation == null) {
      throw new Error('Could not find a transformation with that label.');
    }

    return transformation;
  }
}
