import { ImportSessionFileDirectDataViewModel } from '@insights/viewmodels';
import { Autocomplete, Stack, SxProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface ImportSessionFileDirectDataProps {
  viewModel: ImportSessionFileDirectDataViewModel;
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const ImportSessionFileDirectData = observer(
  ({ className, style, sx = [], viewModel }: ImportSessionFileDirectDataProps) => {
    const { localizationService } = useInsightsServices();
    const strings = localizationService.localizedStrings.insights.views.imports;

    return (
      <Stack direction="row" sx={sx} className={className} style={style} spacing={1} alignItems="end">
        <Typography pb={0.5}>studyo://</Typography>
        <Autocomplete
          sx={{ flex: 2 }}
          freeSolo
          autoSelect
          options={viewModel.availableConfigurations}
          value={viewModel.selectedConfigurationId}
          onChange={(_, v) => (viewModel.selectedConfigurationId = typeof v === 'string' ? v : (v?.id ?? ''))}
          getOptionLabel={(o) => (typeof o === 'string' ? o : o.label)}
          renderInput={(params) => <TextField {...params} label={strings.directDataConfigurationLabel} />}
        />
        <Typography pb={0.5}>/</Typography>
        <Autocomplete
          sx={{ flex: 1 }}
          freeSolo
          autoSelect
          options={viewModel.availableTables}
          value={viewModel.selectedTableName}
          onChange={(_, v) => (viewModel.selectedTableName = typeof v === 'string' ? v : (v?.name ?? ''))}
          getOptionLabel={(o) => (typeof o === 'string' ? o : o.label)}
          renderInput={(params) => <TextField {...params} label={strings.directDataTableNameLabel} />}
        />
      </Stack>
    );
  }
);
